import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() barValue: any;
  grades: any = [
    { value: 0, label: '0-40', width: '40%' ,color: { r: 0, g: 128, b: 0 } },
    { value: 40, label: '40-45', width: '5%', color: { r: 255, g: 115, b: 0 }  }, 
    { value: 45, label: '45-60', width: '15%', color: { r: 255, g: 100, b: 0 }  }, 
    { value: 60, label: '60-75', width: '15%' , color: { r: 255, g: 100, b: 0 } }, 
    { value: 75, label: '75-90', width: '15%', color: { r: 200, g: 0, b: 0 } },
    { value: 90, label: '90-100', width: '10%', color: { r: 175, g: 0, b: 0 } } ,
    { value: 100, label: '100-100', width: '0%' ,color: { r: 0, g: 0, b: 0 } },
  ];
  colorSteps: any[] = [];

  ngOnInit(): void {
    this.generateColorBar();
  }

  generateColorBar() {
    this.colorSteps = this.grades.map(grade => ({
      id: 'step' + grade.value,
      width: grade.width,
      color: `rgb(${grade.color.r}, ${grade.color.g}, ${grade.color.b})`,
      gradeWidth: grade.width,
      label: grade.value,
    }));
  }
  
  getPointerPosition(): string {
    return `calc(${this.barValue}% )`;
  }
  
}
