import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { Subject, takeUntil } from "rxjs";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { LoaderService } from "app/_services/loader.service";
import { BaseRequestService } from "app/_services/base.service";
import { MyToastrService } from "app/_services/toastr.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { UserService } from "app/core/user/user.service";
import { CommonService } from "app/_services/common.service";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "app/material.module";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { FuseAlertComponent } from "@fuse/components/alert";
import { DirectivesModule } from "app/-directives/-directives.module";
import { SkeletonComponent } from "../skeleton/skeleton.component";
import { FuseCardComponent } from "../../../../@fuse/components/card";
import { FuseHighlightComponent } from "../../../../@fuse/components/highlight";
import { MatRadioChange, MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { ModalService } from "app/_services/modal.service";
import { ModalComponent } from "../modal.component";


@Component({
    selector: "install-probes-agents",
    templateUrl: "./install-probes-agents.component.html",
    styleUrls: ["./install-probes-agents.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, FormsModule, DirectivesModule, ModalComponent, MatRadioModule, SkeletonComponent, MaterialModule, AppFilterPipeModule, FuseAlertComponent, FuseScrollbarDirective, FuseCardComponent, FuseHighlightComponent,],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallProbesAgentsComponent implements OnInit {
    @Output() closeCallback = new EventEmitter();
    @Input() showClose = true;
    @ViewChild("drawer") drawer: MatDrawer;
    uploadfile: any = null;
    @ViewChild('dropzonefile', { static: false }) private dropzonefile: ElementRef;
    fileName: any = '';
    podid = ''
    drawerMode: "over" | "side" = "side";
    drawerOpened: boolean = true;
    panels: any[] = [];
    selectedValueType: any = 'exe';
    selectedPanel: string = "probe";
    isLoading = false;
    agentHelp: any = {
        probe: "A Probe Agent is typically installed on any machine in the network and will scan all assets in that defined network.",
        lightweight: "A Lightweight agent is installed on an end user’s system and will only scan that particular asset (useful in work-from-home scenarios when the user is not on the corporate/office network).",
        scan: "Scan Agent will perform the scan only once and return the results without setting it up as a service. A scan agent will help to do a one-time scan.",
    };
    agentHref: any = {
        probe:
            "https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1631420520/Agent+Configuration#Probe-Agent",
        lightweight:
            "https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1631420520/Agent+Configuration#Lightweight-Agent",
        scan: "https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1631420520/Agent+Configuration#Lightweight-Agent",
    };

    apiKey: any = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    currentSecret: any = {};
    agentURL = "";
    agentCommands: any = {
        darwin: "",
        mac: "",
        windows: "./cybercnsagent.exe",
        linux: "sudo ./cybercnsagent_linux",
        arm: "sudo ./cybercnsagent_arm",
        'arm-32': "sudo ./cybercnsagent_arm",
    };
    showCmd: boolean = false;
    agent = {
        code: "",
        count: 100,
        installationMode: "self",
        installationModeone: "self",
    };

    radioList: any = [
        {
            value: 'probe',
            label: 'Probe',
            svgIcon: "mat_outline:devices",
        },
        {
            value: 'lightweight',
            label: 'Lightweight',
            svgIcon: "mat_outline:laptop_chromebook",
        },
        {
            value: 'scan',
            label: 'Scan',
            svgIcon: "mat_outline:scanner",
        }
    ];
    tenantId: any;
    user_secret: any;
    probeCmd: string;
    lightweightCmd: string;
    scanCmd: string;
    iscopied: boolean;
    agentTypeone: any;
    probeCmdHtml: string;
    lightweightCmdHtml: string;
    scanCmdHtml: string;
    stepsList: any = {};
    private urlHash: any = {};
    osCommands = {};
    private windowsUrl: any;
    private linuxUrl: any;
    private macUrl: any;
    command: any = '';
    msiRequired = false;
    isScanSelected: boolean = false;
    inc = 0;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _loaderService: LoaderService,
        private _toast: MyToastrService,
        private uS: UserService,
        private _baseService: BaseRequestService,
        public _modalService: ModalService,
        public cs: CommonService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.podid = localStorage.getItem('_pid')
        this.getsecretkeys();
    }

    getsecretkeys() {
        this._baseService.doRequest(`/r/user/generate_user_token`, 'get', null)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res: any) => {
                    this.user_secret = res.token;
                    if (this.user_secret) {
                        this.updatedatas();
                    }
                    this._changeDetectorRef.detectChanges();
                },
                (error: any) => {
                    this._toast.sToast('error', 'Error fetching user token');
                    this.updatedatas();
                }
            );
    }


    updatedatas() {
        this.panels = [
            {
                id: "probe",
                icon: "mat_outline:devices",
                title: "Probe",
            },
            {
                id: "lightweight",
                icon: "mat_outline:laptop_chromebook",
                title: "Lightweight",
            },
            {
                id: "scan",
                icon: "mat_outline:scanner",
                title: "Scan",
            },
        ];
        this.uS.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            let s = Object.keys(user["urn:zitadel:iam:org:project:roles"])
            let tenantid = Object.keys(user["urn:zitadel:iam:org:project:roles"][s[0]])[0]
            this.tenantId = tenantid;
            this.updateCommand('windows');
            this._changeDetectorRef.markForCheck();
        });


        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes("lg")) {
                    this.drawerMode = "side";
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = "over";
                    this.drawerOpened = false;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Navigate to the panel
     *
     * @param panel
     */
    goToPanel(panel: string): void {
        this.selectedPanel = panel;

        // Close the drawer on 'over' mode
        if (this.drawerMode === "over") {
            this.drawer.close();
        }
    }

    /**
     * Get the details of the panel
     *
     * @param id
     */
    getPanelInfo(id: string): any {
        return this.panels.find((panel) => panel.id === id);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    redirectUrl(selected: any): void {
        window.open(this.agentHref[selected], "_blank");
    }

    installCommand($event: any): void {
        let executePermission: string;
        let executePermissionscan: string;
        const linuxRm = 'cd ..;rm -rf ccnsonetimescan;';
        const windowRm = 'Set-Location ..;Remove-Item ccnsonetimescan -Recurse;';
        if ($event === 'darwin') {
            // executePermission = `macurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=darwin" | tr -d '"');sudo mkdir /Applications/CyberCNS/;cd /Applications/CyberCNS; sudo curl -k $macurl -o cybercnsagent_darwin; \r\nsudo chmod +x cybercnsagent_darwin;\n`;
            // executePermissionscan = `macurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=darwin" | tr -d '"'); curl -k $macurl -o cybercnsagent_darwin;mkdir ccnsonetimescan;mv cybercnsagent_darwin ccnsonetimescan;cd ccnsonetimescan;\r\nchmod +x cybercnsagent_darwin;\n`;
            executePermission = `macurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=darwinpkg" | tr -d '"'); sudo curl -k $macurl -o cybercnsagent_darwin.pkg; sudo installer -pkg ./cybercnsagent_darwin.pkg -target /; sudo /opt/install.sh `;
            executePermissionscan = `macurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=darwinpkg" | tr -d '"');sudo curl -k $macurl -o cybercnsagent_darwin.pkg; sudo installer -pkg ./cybercnsagent_darwin.pkg -target /; sudo /opt/install.sh `;
        } else if ($event === 'linux') {
            executePermission = `linuxurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=linux" | tr -d '"'); curl -k $linuxurl -o cybercnsagent_linux; \r\nchmod +x cybercnsagent_linux;\n`;
            executePermissionscan = `linuxurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=linux" | tr -d '"'); curl -k $linuxurl -o cybercnsagent_linux; mkdir ccnsonetimescan;mv cybercnsagent_linux ccnsonetimescan;cd ccnsonetimescan; \r\nchmod +x cybercnsagent_linux;\n`;
        } else if ($event === 'arm') {
            executePermission = `armurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=arm" | tr -d '"'); curl -k $armurl -o cybercnsagent_arm;  \r\nchmod +x cybercnsagent_arm;\n`;
            executePermissionscan = `armurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=arm" | tr -d '"'); curl -k $armurl -o cybercnsagent_arm; mkdir ccnsonetimescan;mv cybercnsagent_arm ccnsonetimescan;cd ccnsonetimescan;  \r\nchmod +x cybercnsagent_arm;\n`;
        } else if ($event === 'arm-32') {
            executePermission = `armurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=arm32" | tr -d '"'); curl -k $armurl -o cybercnsagent_arm;  \r\nchmod +x cybercnsagent_arm;\n`;
            executePermissionscan = `armurl=$(curl -L -s -g "${environment.configHost}/api/v4/configuration/agentlink?ostype=arm32" | tr -d '"'); curl -k $armurl -o cybercnsagent_arm; mkdir ccnsonetimescan;mv cybercnsagent_arm ccnsonetimescan;cd ccnsonetimescan;  \r\nchmod +x cybercnsagent_arm;\n`;
        } else if ($event === 'windows') {
            if (!this.msiRequired) {
                executePermission = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; \r\n $source = (Invoke-RestMethod -Method "Get" -URI "${environment.configHost}/api/v4/configuration/agentlink?ostype=windows");\r\n$destination = 'cybercnsagent.exe';\r\nInvoke-WebRequest -Uri $source -OutFile $destination;\r\n`;
                executePermissionscan = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; \r\n $source = (Invoke-RestMethod -Method "Get" -URI "${environment.configHost}/api/v4/configuration/agentlink?ostype=windows");\r\n$destination = 'cybercnsagent.exe';mkdir ccnsonetimescan;cd ccnsonetimescan;\r\nInvoke-WebRequest -Uri $source -OutFile $destination;\r\n`;
            } else {
                executePermission = `Invoke-WebRequest -Uri $source -OutFile $destination; Start-Process msiexec -ArgumentList '/i "cybercnsagent.msi" /quiet WRAPPED_ARGUMENTS=`;
                executePermissionscan = `Invoke-WebRequest -Uri $source -OutFile $destination; Start-Process msiexec -ArgumentList '/i "cybercnsagent.msi" /quiet WRAPPED_ARGUMENTS=`;
            }
        }

        if ($event !== "windows") {
            this.probeCmd = `${executePermission} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -e ${this.tenantId} -j ${this.user_secret} -i;`;
            this.scanCmd = `${executePermissionscan} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -a ${this.currentSecret.clientid} -s companyOnboardCode -b domainName $customerid -m Scan;`;
            this.scanCmd = ($event === 'windows') ? `${this.scanCmd}${windowRm}` : `${this.scanCmd}${linuxRm}`;
        } else {
            if (!this.msiRequired) {
                this.probeCmd = `${executePermission} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -e ${this.tenantId} -j ${this.user_secret} -i;`;
                this.scanCmd = `${executePermissionscan} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -a ${this.currentSecret.clientid} -s companyOnboardCode -b domainName $customerid -m Scan;`;
                this.scanCmd = ($event === 'windows') ? `${this.scanCmd}${windowRm}` : `${this.scanCmd}${linuxRm}`;
            } else {
                const msiPermission = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;`
                const msiSource = `$source = (Invoke-RestMethod -Method "Get" -URI "${environment.configHost}/api/v4/configuration/agentlink?ostype=windows&msi_required=true");`
                const msiDest = `$destination = 'cybercnsagent.msi';`;
                const msiWebReq = `Invoke-WebRequest -Uri $source -OutFile $destination;`;
                const msiEnd = `-Wait`;
                this.probeCmd = `${msiPermission} ${msiSource} ${msiDest} ${executePermission}"-c ${this.cs.currentScope.id} -e ${this.tenantId} -j ${this.user_secret} -i"' ${msiEnd}`;
                this.lightweightCmd = `${msiPermission} ${msiSource} ${msiDest} ${executePermission}"-c ${this.cs.currentScope.id} -e ${this.tenantId} -j ${this.user_secret} -i"' ${msiEnd}`;
            }
        }
        const urls = {
            windows: `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;
              $source =  "${this.windowsUrl}";
              $destination = 'cybercnsagent.exe';
              Invoke-WebRequest -Uri $source -OutFile $destination;
              ./cybercnsagent.exe -c ${this.cs.currentScope.id} -e ${this.tenantId} -j '${this.user_secret}' -i;`,
            darwin: `curl "${this.macUrl}" -o cybercnsagent_darwin; chmod +x cybercnsagent_darwin; -c ${this.cs.currentScope.id} -e ${this.tenantId} -j '${this.user_secret}' -i;`
        }
        this.probeCmdHtml = this.probeCmd; // urls[$event];
        this._changeDetectorRef.markForCheck();
        this.showCmd = true;
        this.apiKey = [
            {
                icon: "mat_outline:developer_board",
                name: "Company ID",
                key: this.cs.currentScope.id
            },
            {
                icon: "mat_outline:insert_chart",
                name: "Tenant ID",
                key: this.tenantId
            },
            {
                icon: "mat_outline:vpn_key",
                name: "Secret",
                key: this.user_secret
            }
        ]
    }

    selectedTab($event: MatTabChangeEvent): void {
        let type = ($event.tab.textLabel.toLocaleLowerCase() === 'mac') ? 'darwin' : $event.tab.textLabel.toLocaleLowerCase();
        this.updateCommand(type);
    }

    dWIAgent(): void {
        this._loaderService.display(true);
        this.isLoading = true;
        this._baseService.doRequest(
            `/r/company/get_web_installer?company_id=${this.cs.currentScope.id}&os_type=${this.agentTypeone}`,
            'get').pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
                this._loaderService.display(false);
                if (result.status) {
                    this.isLoading = false; this._changeDetectorRef.detectChanges();
                    window.open(result.message, '_blank');
                } else {
                    if (this.inc === 0) {
                        this._toast.sToast('info', 'The Web Installer is currently generating, please wait a moment.');
                    }
                    if (this.inc < 5) {
                        this._loaderService.display(true, 'In-Progress...');
                        setTimeout(() => {
                            this._loaderService.display(false);
                            this._loaderService.display(true);
                            this.inc++; this.dWIAgent();
                        }, 25000);
                    } else {
                        this._toast.sToast('error', 'In Queue, Please try again after few minutes.');
                        this.isLoading = false; this._changeDetectorRef.detectChanges();
                    }
                }
            }, (error: any) => {
                this.isLoading = false; this._changeDetectorRef.detectChanges();
                this._loaderService.display(false);
            });
    }

    dAgent(value?: any): void {
        if (this.agentURL) {
            window.open(this.agentURL, '_blank');
            return;
        }
        let reqData;
        if (this.selectedValueType === 'exe' && value) {
            reqData = this.agentTypeone
        } else if (this.selectedValueType === 'msi' && value) {
            reqData = `${this.agentTypeone}&msi_required=true `
        } else {
            reqData = this.agentTypeone
        }
        this._loaderService.display(true);
        //TODO: change darwin to darwinpkg for signed pkg file download
        if (reqData === 'darwin') { reqData = 'darwinpkg'; }
        if (reqData === 'arm-32') { reqData = 'arm32'; }
        this._baseService.doRequest(`/r/company/agentlink?region=use&os_type=${reqData}`,
            'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this._loaderService.display(false);
                window.open(result.message, '_blank');
            });


    }

    copyClip(cmd: string): void {
        this.iscopied = true;
        const el = document.createElement("textarea");
        el.value = cmd;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this._toast.sToast("success", "Copied to clipboard");
    }

    copyCmdClip(): void {
        switch (this.selectedPanel) {
            case "probe":
                this.copyClip(this.probeCmdHtml);
                break;
            case "lightweight":
                this.copyClip(this.lightweightCmd);
                break;
            case "scan":
                this.copyClip(this.scanCmd);
                break;
        }
    }

    updateCommand($event: any): void {
        this.showCmd = false;
        $event = ($event.value) ? $event.value : $event;
        if ($event === "darwin") {
            this.agentTypeone = "darwinpkg";
        } else {
            this.agentTypeone = $event;
        }
        if (this.urlHash[$event]) {
            this.command = this.osCommands[$event];
            this._changeDetectorRef.markForCheck();
            this.installCommand($event);
            return;
        }
        this.installCommand($event);
        /*this._loaderService.display(true, 'Getting agent link...');
        this._baseService.doRequest(`/r/company/agentlink?region=use&os_type=${$event}`,
            'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this._loaderService.display(false);
                if ($event === 'windows') {
                    this.windowsUrl = result.message;
                } else if ($event === 'linux') {
                    this.linuxUrl = result.message;
                } else if ($event === 'darwin') {
                    this.macUrl = result.message;
                } else if ($event === 'arm') {
                    this.macUrl = result.message;
                }
                this.installCommand($event);
            })*/
    }
    radioButtonChange($event: MatRadioChange) {
        this.selectedValueType = $event.value;
        this.isScanSelected = $event.value === 'scan';
        if ($event.value === 'msi') {
            this.msiRequired = true
            this.updateCommand('windows');
        } else if ($event.value === 'exe') {
            this.msiRequired = false
            this.updateCommand('windows');
        }
    }

    radioButtonChange1($event: MatRadioChange) {
        this.selectedValueType = $event.value;
        this.isScanSelected = $event.value === 'scan';
        if ($event.value === 'exe') {
            this.updateCommand('darwin');
        }
    }

    radioButtonChange2($event: MatRadioChange) {
        this.selectedValueType = $event.value;
        this.isScanSelected = $event.value === 'scan';
        if ($event.value === 'exe') {
            this.updateCommand('linux');
        }
    }

    radioButtonChange3($event: MatRadioChange) {
        this.selectedValueType = $event.value;
        this.isScanSelected = $event.value === 'scan';
        if ($event.value === 'exe') {
            this.updateCommand('arm');
        }
    }

    downloadscan(type: any) {
        console.log('type', type)
        this._loaderService.display(true);
        this._baseService.doRequest(`/report_builder/download_assessment_agent`, 'get', null,
            { file_name: `OneTimeScan_${type}.zip` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this._loaderService.display(false);
                if (res.status) {
                    window.open(res.message, '_blank');
                }
            });
    }

    upload_onetime_scan() {
        this._modalService.open('uploadAssessment1');
    }


    uploadFn($event: any, key: any): void {
        if (!$event.target.files.length) {
            this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
            return;
        }
        const allowedTypes = ["json", 'application/json'];
        const file = $event.target.files[0];

        if (!allowedTypes.includes(file.type)) {
            this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
            return;
        }
        try {
            this.fileName = $event.target.files[0].name;
            // @ts-ignore
            this[key] = $event.target.files[0];
        } catch (error) {
            this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
        }

    }

    uploadAssetData(): void {
        if (!this.uploadfile) {
            this._toast.sToast("error", `No file selected.`);
            return;
        }
        this._loaderService.display(true);
        const formData = new FormData();
        formData.append("uploadfile", this.uploadfile);
        formData.append("company_id", this.cs.currentScope.id);
        this._baseService.uploadFormData("/w/company/upload_asset_data", formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (responseData: any) => {
                    this._loaderService.display(false);
                    if (responseData.status) {
                        this._toast.sToast("success", `Asset data has been updated successfully.`);
                        this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
                        this._changeDetectorRef.detectChanges();
                        this._modalService.close('uploadAssessment1')
                    } else {
                        const data = (responseData.message) ? responseData.message : responseData.data; this._toast.sToast('error', data);
                    }
                },
                (error: any) => {
                    console.error("Error posting file to API:", error);
                    this._loaderService.display(false);
                    this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
                    this._changeDetectorRef.detectChanges();
                }
            );
    }
}

