import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DynamicSettingsService
{
    // Private
    private _settings: BehaviorSubject<any | null> = new BehaviorSubject(null);

    //Event Emitters
    public dsTabEmitter = new EventEmitter();
    public reportTabEmitter = new EventEmitter();
    public assetEmitter = new EventEmitter();
    public assetColViewEmitter = new EventEmitter();
    public adEmitter = new EventEmitter();
    public scanProfileEmitter = new EventEmitter();
    public scanResultEmitter = new EventEmitter();
    public azureSyncEmitter = new EventEmitter();
    public o365SyncEmitter = new EventEmitter();
    public microSyncEmitter = new EventEmitter();
    public asmEmitter = new EventEmitter();
    public piiScanEmitter = new EventEmitter();
    public ticketingTemplateEmitter = new EventEmitter();  
    public adetailsEmitter = new EventEmitter();
    public complianceEmitter = new EventEmitter();
    public agentEmitter = new EventEmitter();
    public exConfigEmitter = new EventEmitter();
    public schedulerTabEmitter = new EventEmitter();
    public schedulerEmitter = new EventEmitter();
    public externalTabEmitter = new EventEmitter();
    public piiTabEmitter = new EventEmitter();
    public assetToggleEmitter = new EventEmitter();
    public setActionChanged = new EventEmitter();
    public appBaseEmitter = new EventEmitter();
    public customReportEmitter = new EventEmitter();
    public reportEmitter = new EventEmitter();
    public schedReportEmitter = new EventEmitter();
    public reportBuilderEmitter = new EventEmitter();
    public exAssetEmitter = new EventEmitter();
    public vulAssetEmitter = new EventEmitter();

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    /**
     * Get setting by id
     */
    getSettingsById(id: string): Observable<any>
    {
        return this._httpClient.get<any>('api/common/settings', {params: {id}}).pipe(
            map((setting) => {

                // Update the course
                this._settings.next(setting);

                // Return the course
                return setting;
            }),
            switchMap((setting) => {

                if ( !setting )
                {
                    return throwError('Could not found course with id of ' + id + '!');
                }

                return of(setting);
            })
        );
    }
}
