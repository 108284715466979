import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {Observable, of, Subject, takeUntil} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {Router} from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {BaseRequestService} from "../_services/base.service";
import {LoaderService} from "../_services/loader.service";
import jwt_decode from "jwt-decode";
import {UserService} from "../core/user/user.service";

export abstract class StatehandlerProcessorService {
    public abstract createState(url: string): Observable<string | undefined>;
    public abstract restoreState(state?: string): void;
}

@Injectable()
export class StatehandlerProcessorServiceImpl
    implements StatehandlerProcessorService
{
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private location: Location, private _router: Router,
                private oauth: OAuthService, private bs: BaseRequestService,
                private ls: LoaderService,
                private userService: UserService) {}

    public createState(url: string): Observable<string> {
        let s = this.location.prepareExternalUrl(url);
        const externalUrl = (s.indexOf('sign-in?tenant_name') > -1 ) ? '/sign-in' : s;
        const urlId = uuidv4();
        sessionStorage.setItem(urlId, externalUrl);
        return of(urlId);
    }

    public restoreState(state?: string): void {
        if (state === undefined) {
            return;
        }
        let url = sessionStorage.getItem(state);
        if (url === null) {
            return;
        }
        /*if (url === '/sign-in' || url === '/sign-out') {
            url = '/auth-grant';
        }*/
        const data : any = this.setup();
        // window.location.href = window.location.origin + url;
    }

    async setup(): Promise<any> {
        try {
             if (this.oauth.hasValidAccessToken()) {
                 var token = localStorage.getItem('zitadel:id_token');
                 if (token) {
                     this.userService.userd$ = of(jwt_decode(token));
                     this.userService._user_roles = Object.keys(jwt_decode(token)["urn:zitadel:iam:org:project:roles"]);
                     const il = localStorage.getItem('initLogin');
                     if (!il) {
                        const params: any = {};
                        const response = await this.bs.doRequest(`/w/user/login`, 'post',  params, null).toPromise();
                        if (response.status) {
                            localStorage.setItem('initLogin', '1');
                        }
                    }
                 }
             }
        } catch (error) {
            throw error;
        }
    }

    private setups(): void {
        if (this.oauth.hasValidAccessToken()) {
            var token = localStorage.getItem('zitadel:id_token');
            if (token) {
                this.userService.userd$ = of(jwt_decode(token));
                this.userService._user_roles = Object.keys(jwt_decode(token)["urn:zitadel:iam:org:project:roles"]);
                const il = localStorage.getItem('initLogin');
                if (!il) {
                    this.bs.doRequest(`/w/user/login`, 'post', {})
                        .pipe(takeUntil(this._unsubscribeAll)).subscribe((r: any) => {
                            localStorage.setItem('initLogin', '1');
                            this._unsubscribeAll.next(null);
                            this._unsubscribeAll.complete();
                    }, (error: any) => {
                        console.log(error);
                    });
                }
            }
        } else {
            this.setup();
        }
    }
}
