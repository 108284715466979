<div class="flex items-center justify-center mt-8 space-x-2" *ngIf="loadingCon.loading">
    <div aria-label="Loading..." role="status">
        <svg class="animate-spin w-5 h-5 stroke-white light:stroke-slate-500" viewBox="0 0 256 256">
            <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
            </line>
            <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="24">
            </line>
            <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
            </line>
            <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="24">
            </line>
            <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
            </line>
            <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="24">
            </line>
            <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
            </line>
            <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="24">
            </line>
        </svg>
    </div>
    <span class="text-medium font-medium text-white-600 light:text-slate-500">Loading...</span>
</div>
<div class="flex items-center justify-center mt-8" *ngIf="loadingCon.nodata">
    <div class="flex flex-col items-center justify-center gap-y-2">
        <img ngSrc="/assets/images/empty.png" alt="" class="w-20" height="512" width="512">
        <div> No data available!</div>
    </div>
</div>
