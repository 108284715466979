import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { settings as settingsData, assessment_settings as assessmentsettingsData } from 'app/mock-api/dynamic-settings/data';
import assign from 'lodash-es/assign';
import cloneDeep from 'lodash-es/cloneDeep';
import { of } from "rxjs";
import jwt_decode from "jwt-decode";
import { UserService } from 'app/core/user/user.service';
import { CommonService } from 'app/_services/common.service';

@Injectable({ providedIn: 'root' })
export class SettingsMockApi {
    private _settings: any = settingsData;
    private _assessment_settings: any = assessmentsettingsData;
    settings: any = settingsData;
    objectKeys = Object.keys;
    currentRoles: any = [];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, private uS: UserService, private _cs: CommonService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ settings - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/settings')
            .reply(({ request }) => {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the settings
                const settings = (!this._cs.is_assessment) ? cloneDeep(this._settings) : cloneDeep(this._assessment_settings);

                // Find the settings
                let setting = [];

                let result = settings.find(item => item.id === id);
                if (result) {
                    setting = result.settings;
                }

                // Return the response
                return [200, setting];
            });
    }
}
