import {
  CommonModule,
  NgFor,
  NgClass,
  NgSwitch,
  NgSwitchCase,
  NgIf,
  DatePipe,
} from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { FuseScrollbarDirective } from "@fuse/directives/scrollbar";
import { TranslocoModule } from "@ngneat/transloco";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";
import { BaseRequestService } from "app/_services/base.service";
import { LoaderService } from "app/_services/loader.service";
import { ModalService } from "app/_services/modal.service";
import { MyToastrService } from "app/_services/toastr.service";
import { MaterialModule } from "app/material.module";
import { TableComponent } from "app/modules/shared/table/table.component";
import { Subject, Subscription, takeUntil } from "rxjs";
import { TimelineComponent } from "../../../shared/timeline/timeline.component";
import { AsmResultsComponent } from "../../attack-surface-mapper/asm-results/asm-results.component";
import { Router } from "@angular/router";
import { ShowMoreLessComponent } from "app/modules/shared/show-more-less/show-more-less.component";
import { CommonService } from "app/_services/common.service";
import { DynamicSettingsService } from "app/layout/common/dynamic-settings/dynamic-settings.service";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { P } from "@angular/cdk/keycodes";
import { PaginatorWidgetComponent } from "app/modules/shared/paginator-widget/paginator-widget.component";

@Component({
  selector: "app-job-view",
  // changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ShowMoreLessComponent,
    TranslocoModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    AsmResultsComponent,
    FuseScrollbarDirective,
    NgIf,
    ButtonModule,
    AppFilterPipeModule,
    MaterialModule,
    TableComponent,
    TimelineComponent,
    PaginatorWidgetComponent
  ],
  templateUrl: "./job-view.component.html",
  styleUrls: ["./job-view.component.scss"],
})
export class JobViewComponent implements OnInit, OnDestroy, AfterViewInit {
  cView: any = "jobs";
  @ViewChild("drawer") drawer: MatDrawer;
  @Input() jobCondition: any;
  @Input() title: any;
  @Input() isShowHyperLink: any;
  @Input() actionMenuItems: any = [];
  @Input() isActionButton: any;
  isGlobal: any = false;
  searchFilter: any = "";
  checkCompleted: boolean = false;
  checkInProcess: boolean = false;
  checkFailed: boolean = false;
  drawerPosition: "start" | "end" = "end";
  drawerMode: "over" | "side" = "over";
  drawerOpened: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  currentData: any = {};
  apiCallSuccessful: boolean = false;
  currentDataCheck: boolean = false;
  reportCurrentDataCheck: boolean = false;
  reportCurrentData: any = [];
  showtable: boolean = false;
  objectKeys = Object.keys;
  scanTableOptions: any = {};
  suppressiontable: any = {};
  tableData: any = {};
  pObj: any = {};
  private action: Subscription;
  currentStep = 0;
  totalSteps = 10;
  timelineCount: any = null;
  _reportDetails: any = [];
  pageOptions: any = [5, 10, 20, 30, 50, 100];
  pageSize: any = 50;
  pageTotal: any = 0;
  pageIndex = 0;
  loading = false;
  jobEnum: any = {
    'pii': `type IN ('PIISCAN','reset_company_data')`,
    'agents': `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN', 'REMOTEINSTALL', 'COMPLIANCE', 'VALIDATECREDS', 'FIREWALLSCAN', 'migrate_agent_company','reset_company_data','AssetsScan', 'Probe Discovery Settings CSV Upload')`,
    'assets': `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN', 'REMOTEINSTALL', 'COMPLIANCE', 'VALIDATECREDS', 'SUPPRESSION', 'FIREWALLSCAN', 'migrate_agent_company','reset_company_data')`,
    'deprecated-assets': `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN', 'REMOTEINSTALL', 'COMPLIANCE', 'VALIDATECREDS', 'FIREWALLSCAN')`,
    'scheduler': `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN', 'EXTERNALSCAN', 'COMPLIANCE', 'FIREWALLSCAN', 'ATTACKSURFACESCAN', 'PIISCAN') and scheduler_id != 0`,
    'azure': `type='ENTRAIDSYNC'`,
    "external-assets": `type IN ('EXTERNALSCAN', 'External Scan CSV Upload')`,
    'ad-details': `type IN ('FULLSCAN','ADSCAN')`,
    'firewalls': `type='FIREWALLSCAN'`,
    "attack-surface-mapper": `type='ATTACKSURFACESCAN'`,
    "standard-reports": `type='StandardReports'`,
    "report-builder": `type='CustomReports'`,
    "compliance-standards": `type='COMPLIANCE'`,
    "vulnerabilities": `type='SUPPRESSION'`,
    "compliance-check-master": `type='COMPLIANCE'`,
    "m365-risk-summary": `type='O365ASSESSMENT'`
  };
  teminateJobs: any = ['external-assets', 'azure', 'attack-surface-mapper'];
  showAgent: any = ['firewalls', 'agents', 'compliance-standards', 'compliance-check-master', 'pii', 'assets', 'scheduler'];
  showScheduler: any = ['firewalls', 'agents', 'compliance-standards', 'compliance-check-master', 'assets', 'scheduler', 'external-assets', 'attack-surface-mapper', 'pii'];

  jobAPI: any = ['azure', 'attack-surface-mapper'];
  reportJobType: any = ['StandardReports', 'CustomReports'];
  jobDetails: any = ['ENTRAIDSYNC'];
  disableDownloadButton: boolean = false;
  /**
   * Constructor
   */
  constructor(
    public modalService: ModalService,
    private _bs: BaseRequestService,
    public cs: CommonService,
    private ls: LoaderService,
    private toast: MyToastrService,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ds: DynamicSettingsService,
    public confirmDialog: FuseConfirmationService
  ) {
    this._ds.reportEmitter
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
       this.reportEmitterAction(res);
      });
    this.cs.selectedSiteChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.initTable();
      });
  }

  reportEmitterAction(res: any): void {
    if (res.action.target === "details") {
      this.searchFilter = "";
      if (res.row && res.row.length) {
        if (res.row.length > 1) {
          this.toast.sToast("info", "Please select only one record.");
        } else {
          this.currentData = {};
          this.viewJobDeatils(res.row);
          this._changeDetectorRef.detectChanges();
        }
      } else {
        this.toast.sToast("info", "Please select at least one record.");
      }
    }
    if (res.action.target === "recreate-job") {
      if (res.row && res.row.length) {
        if (res.row.length > 1) {
          this.toast.sToast("info", "Please select only one record.");
        } else {
          const checkFailed = res.row.every((report: any) => report.status === "failed" || report.status === "pushedtoqueue");
          if (checkFailed) {
            this.generatedReCreateJob(res.row);
          } else {
            this.toast.sToast(
              "info",
              'Re-Create can be done only for Failed job.'
            );
          }
        }
      } else {
        this.toast.sToast("info", "Please select at least one record.");
      }
    }
    if (res.action.target === "terminate") {
      if (res.row && res.row.length) {
        const checkInProcess = res.row.every((report: any) => report.status === "inprogress" || report.status === "in progress" || report.status === "pushedtoqueue");
        if (checkInProcess) {
          this.generatedReportTerminate(res.row);
        } else {
          this.toast.sToast(
            "info",
            "Terminate can be done only for In-Progress job"
          );
        }
      } else {
        this.toast.sToast("info", "Please select at least one record.");
      }
    }
    if (res.action.target === "report-download") {
      if (res.row && res.row.length) {
        if (res.row.length > 10) {
          this.toast.sToast("info", "Please select a maximum of 10 reports only");
        } else {
          const checkCompleted = res.row.every((report: any) => report.status === "completed");
          if (checkCompleted) {
            this.generatedDownloadReport(res.row);
          } else {
            this.toast.sToast(
              "info",
              `Job status: Failed. Report download unavailable at the moment. Please Select Only Completed Reports.`
            );
          }
        }
      } else {
        this.toast.sToast("info", "Please select at least one record.");
      }
    }
  }

  ngOnInit(): void {
    this.initTable();
  }
  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }
  initTable(): void {
    const router = this.router.url.split("/");
    this.jobCondition = router.length > 0 ? this.jobEnum[router[router.length - 1]] : "";
    let showLink = (this.title || router[router.length - 1] === 'attack-surface-mapper') ? true : true;
    showLink = (this.title || router[router.length - 1] !== 'm365-risk-summary') ? showLink : false;
    const showAgent = (this.showAgent.indexOf(router[router.length - 1]) === -1) ? true : false;
    const showScheduler = (this.showScheduler.indexOf(router[router.length - 1]) === -1) ? true : false;
    if (this.isActionButton && this.teminateJobs.indexOf(router[router.length - 1]) > -1) {
      this.isActionButton = false; this.actionMenuItems = [];
    }
    let api_url = '/r/company/jobs_view';
    if (this.jobAPI.indexOf(router[router.length - 1]) > -1 && !this.title) {
      api_url = '/r/company/jobs';
    } else if (this.title) {
      api_url = (this.cs.currentScope === '*') ? '/r/company/global_jobs_view' : '/r/company/report_jobs_view';
    }
    this.scanTableOptions = {
      columns: [
        {
          header: "Created",
          columnDef: "created",
          filter: "utcToLocale",
          "cType": "date",
          isHyperlink: showLink,
          cell: "(element: any) => ${element.created}",
          order: 0,
          width: "150px",
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true
        },
        {
          header: "Updated",
          columnDef: "updated",
          filter: "utcToLocale",
          "cType": "date",
          isHyperlink: showLink,
          cell: "(element: any) => ${element.updated}",
          order: 0,
          width: "150px",
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true
        },
        {
          header: "Job Type",
          columnDef: "type",
          cType: "string",
          filter: "enumString",
          isNoVisible: this.title ? true : false,
          cell: "(element: any) => `${element.type}`",
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Scheduler Name",
          columnDef: "name",
          cType: "agg",
          filter: "",
          cell: "(element: any) => `${element.name}`",
          order: 1,
          visible: true,
          isToolTip: false,
          isNoVisible: showScheduler,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Report Name",
          columnDef: "report_name",
          cType: "agg",
          cell: "(element: any) => `${element.report_name}`",
          order: 1,
          visible: true,
          isNoVisible: this.title ? false : true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: "Report Type",
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          isNoVisible: this.title ? false : true,
          columnDef: "report_type",
          cType: "agg",
          filter: "",
          cell: "(element: any) => `${element.report_type}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Agent Name",
          columnDef: "agent_host_name",
          cType: "agg",
          filter: "",
          cell: "(element: any) => `${element.agent_host_name}`",
          order: 2,
          visible: true,
          isNoVisible: showAgent,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          clipboard: true,
          width: '180px'
        },
        {
          header: "Job Id",
          columnDef: "job_id",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.job_id}`",
          order: 2,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Status",
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          columnDef: "status",
          cType: "agg",
          filter: "",
          cell: "(element: any) => `${element.status}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          width: '120px'
        },
        {
          header: "Description",
          columnDef: "description",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.description}`",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
          isShowMoreData: true
        },
        {
          header: "Download",
          columnDef: "download",
          filter: "",
          cell: "(element: any) => `${element.download}`",
          order: 0,
          cType: "agg",
          align: "center",
          isHyperlink: true,
          visible: true,
          isNoVisible: this.title ? false : true,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isClass: true,
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: true,
          imgPath: "",
          iconPath: "mat_solid:download_for_offline",
          iconPathCondition: {
            key: 'status', value: 'completed'
          },
          isSort: false,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: "updated", direction: "desc" },
      _pageData: [],
      tableOptions: {
        title: this.title ? this.title : "Job(s)",
        isServerSide: false,
        selectText: "job(s)",
        isNoAdd: true,
        loading: true,
        floatingFilter: true,
        rowSelection: (this.title || this.isActionButton) ? true : false,
        isHideGlobal: this.actionMenuItems && this.actionMenuItems.length && this.isActionButton ? true : false,
        showAction: this.actionMenuItems && this.actionMenuItems.length && this.isActionButton ? true : false,
        actionMenuItems: this.actionMenuItems,
        pagination: true,
        // isUserRestCheck: true,
        isNotSiteChange: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        dataMapping: [
          {
            s: "job_details",
            d: "report_name",
            filter: "statstovuls:displayReportName",
          },
          {
            s: "job_details",
            d: "report_type",
            filter: "statstovuls:fileType",
          },
        ],
        serverSide: {
          // url: this.title ? "/r/company/report_jobs_view" : "/r/company/jobs_view",
          url: api_url,
          condition: this.jobCondition,
        },
      },
      customText: [
        {
          status: "initiated",
          DisplayText: "Initiated",
          class: "bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50",
        },
        {
          status: "inprogress",
          DisplayText: "In Progress",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "in progress",
          DisplayText: "In Progress",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "completed",
          DisplayText: "Completed",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "failed",
          DisplayText: "Failed",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pushedtoqueue",
          DisplayText: "Pushed To Queue",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
        {
          status: "terminated",
          DisplayText: "Terminated",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pdf",
          DisplayText: "PDF",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "xlsx",
          DisplayText: "XLSX",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "docx",
          DisplayText: "DOCX",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "pptx",
          DisplayText: "PPTX",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
      ],
      sColumns: [
        {
          header: 'Job Status', columnDef: 'status', cType: 'string', isDropdown: true, gloNoSearch: true,
          dataSource: [
            { text: 'Initiated', value: 'initiated' },
            { text: 'In Progress', value: 'in progress' },
            { text: 'Completed', value: 'completed' },
            { text: 'Failed', value: 'failed' },
            { text: 'Terminated', value: 'terminated' }
          ],
        },
      ],
      changeValue: new Subject<any>(),
    };
    const data = Object.assign({}, this.scanTableOptions);
    data.pageData = []; data.tableOptions.pageTotal = 0;
    this.scanTableOptions = {}; this._changeDetectorRef.detectChanges();
    this.scanTableOptions = data; this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.detectChanges();
    setTimeout(() => {
      this._changeDetectorRef.detectChanges();
    }, 3000);
  }
  trackByFn(index: number, item: any): any {
    return (item && item.id) || index;
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.objectKeys(this.pObj).forEach((obj: any) => {
      if (obj) {
        this.pObj[obj].unsubscribe();
      }
    });

    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  downloadJobs() {
    const csv = this.convertArrayOfObjectsToCSV(this.currentData.job_details);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'scan_job_details.csv');
    document.body.appendChild(link);
    link.click();
  }

  downloadJobsDetails(): void {
    this.ls.display(true);
    this.disableDownloadButton = true;
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
    let condition: any = { condition: `job_id='${this.currentData.job_id}'`, skip: 0, limit: 2000, file_name: `scan_job_details.csv`,fieldmap: [
      { "job_id": "Job Id" },
      { "ip": "IP" },
      { "description": "Description" },
      { "protocol": "Protocol" },
      { "type": "Type" },
      { "company_id": "Company id" },
      { "tenantid": "Tenantid" },
      { "created": "Created" },
      { "ip_address": "IP address" }
    ],order_by: `created` }
    this._bs
      .doRequest(`/report_queries/job_details_view`, "get", null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.disableDownloadButton = false;
          window.open(res.message, '_blank');
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }
  convertArrayOfObjectsToCSV(data: any[]): string {
    if (!data || data.length === 0) {
      return '';
    }

    const csvArray = [];
    const headers = ['Description', 'Protocol', 'Job time', 'IP address', 'Reason'];
    csvArray.push(headers.join(','));
    data.forEach(item => {
      if (item) {
        const row = headers.map(header => {
          const key = header.toLowerCase().replace(' ', '_');
          if (item.hasOwnProperty(key) && item[key] !== null && item[key] !== undefined) {
            return key === 'ip_address' && Array.isArray(item[key]) ? item[key].join(';') : (key === 'job_time' ? this.formatDate(item[key]) : item[key]);
          } else {
            return '';
          }
        });
        csvArray.push(row.join(','));
      }
    });
    return csvArray.join('\n');
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString();
  }
  linkClick($event: any) {
    if ($event.col === "download") {
      const params = {
        job_id: [$event.row.job_id],
        isGlobal: (this.cs.currentScope === '*') ? true : false
      };
      this.ls.display(true);
      this._bs
        .doRequest(`/report_builder/get_report_link`, "get", null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.ls.display(false);
          if (res.status) {
            window.open(res.message, "_blank");
          } else {
            const data = res.message ? res.message : res.data;
            this.toast.sToast("error", data);
          }
        });
    } else {
      this.ls.display(true);
      if ($event.row.type === "ATTACKSURFACESCAN") {
        const condition = {
          condition: "company_id='" + this.cs.currentScope.id + "'",
        };
        if ($event.row.job_details && $event.row.job_details.length) {
          condition.condition +=
            " and " +
            "website='" +
            $event.row.job_details[0].domain +
            "'";
        }
        this.currentData = null;
        this.pObj.asm = this._bs
          .doRequest(
            "/r/company/attack_surface_results",
            "get",
            null,
            condition
          )
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.ls.display(false);
              try {
                if (result.data && result.data.length) {
                  if (!result.data[0].mx.error) {
                    this.currentData = result.data[0];
                    this.apiCallSuccessful = true;
                    this.cView = "results";
                    this._changeDetectorRef.detectChanges();
                  } else {
                    this.toast.sToast("error", result.data[0].mx.error);
                  }
                } else {
                  this.toast.sToast("error", "No results found.");
                }
              } catch (e) {
                console.log(e);
              }
            }
          });
      } else {
        this.viewDetails($event.row);
        this._changeDetectorRef.detectChanges();
      }

    }
  }

  viewDetails(job: any): void {
    this.drawerOpened = true;
    this.currentStep = 0;
    this.currentDataCheck = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();
      this.currentData = job;
      if(job.type !== 'ENTRAIDSYNC'){
        this.getData();
      }else {
        this.ls.display(false);
        this.currentData = job;
      }
      this._changeDetectorRef.detectChanges();
    }
  }

  viewJobDeatils(job: any) {
    this.searchFilter = "";
    this.reportCurrentDataCheck = true;
    this.drawerOpened = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();
      this.reportCurrentData.push(job);
    }
    this._changeDetectorRef.detectChanges();
  }
  toggleChange($event: any): void {
    this.cView = "jobs";
  }
  closeCallBack($event: any): void {
    this.cView = "jobs";
  }
  actionCall(idata: any): void {
    if (idata.action.emitterAction) {
      idata.row = [idata.row];
      // @ts-ignore
      this._ds[idata.action.emitterAction].next(idata);
      return;
    }
    if (idata.action.text === "Download Report") {
      if (idata.row.status.toLowerCase() !== "completed") {
        this.toast.sToast(
          "error",
          `Job status: ${idata.row.status}. Report download unavailable at the moment.`
        );
        return;
      }
      const params = {
        job_id: idata.row.job_id,
        isGlobal: (this.cs.currentScope === '*') ? true : false
      };
      this.ls.display(true);
      this._bs
        .doRequest(`/report_builder/get_report_link`, "get", null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.ls.display(false);
          if (res.status) {
            window.open(res.message, "_blank");
            setTimeout(() => this.ls.tableProgress(false));
          } else {
            const data = res.message ? res.message : res.data;
            this.toast.sToast("error", data);
          }
        });
    }
    if (idata.action.text === "Terminate") {
      this.isGlobal = false;
      const checkInProcess = idata.row.status === "initiated" || idata.row.status === "in progress" || idata.row.status === "inprogress" || idata.row.status === "started";
      if (checkInProcess) {
        if (idata.row.type === "ATTACKSURFACESCAN") {
          this.terminateAttackSurfaceJob(idata);
        } else {
          this.terminateJob(idata);
        }
      } else {
        this.toast.sToast(
          "info",
          "Terminate can be done only for In-Progress job"
        );
      }
    }
    if (idata.action.text === "Details") {
      this.viewJobDeatils(idata.row);
    }
  }

  globalActionCall(data: any): void {
    if (data.action.emitterAction) {
      // @ts-ignore
      this._ds[data.action.emitterAction].next(data);
      return;
    }
    if (data.action.text === 'Terminate') {
      this.isGlobal = true;
      this.terminateJob(data);
    }
  }

  terminateJob(data: any) {
    const jobConfirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: "Are you sure you want to terminate these Jobs?",
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" },
      },
    });

    jobConfirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        let reqData: any = {};
        if (!this.isGlobal) {
          if (!data.row.agent_id) {
            this.toast.sToast("error", "Agent Id should be present!");
            return;
          }
          reqData.bulk_terminate = [{
            company_id: data.row.company_id,
            agent_id: data.row.agent_id,
            job_id: data.row.job_id,
            job_type: data.row.type
          }];
        } else {
          const bulkTerminateData = data.row.filter((row: any) =>
            row.agent_id && (row.status === "initiated" || row.status === "inprogress" || row.status === "in progress" || row.status === "started")
          ).map((row: any) => ({
            company_id: row.company_id,
            agent_id: row.agent_id,
            job_id: row.job_id,
            job_type: row.type
          }));
          reqData.bulk_terminate = bulkTerminateData;
        }
        reqData.message = "terminate_job";
        this._bs.doRequest(`/w/company/reset_agents`, "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast("success", "Terminated successfully");
              setTimeout(() => this.cs.selectedTagChange.next({}));
            } else {
              const errorMessage = result.message ? result.message : result.data;
              this.toast.sToast("error", errorMessage);
            }
          });
      }
    });
  }

  terminateAttackSurfaceJob(idata: any): void {
    const reqData = { id: idata.row.id, data: { status: 'terminated' } };
    this._bs
      .doRequest(`/r/company/jobs`, "put", reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          this.toast.sToast("success", "Terminated successfully");
          setTimeout(() => this.cs.selectedTagChange.next({}));
        } else {
          const data = result.message ? result.message : result.data;
          this.toast.sToast("error", data);
        }
      });
  }

  closeCurrentData() {
    this.currentDataCheck = false;
    this.drawer.toggle();
  }
  closeReportCurrentData() {
    this.reportCurrentDataCheck = false;
    this.reportCurrentData = [];
    this.drawer.toggle();
  }
  generatedDownloadReport(data: any) {
    const titleName = `Confirmation`;
    const message = `Are you sure you want to download the Report?`;
    const cancelText = "No";
    const acceptText = "Yes";
    const confirmation = this.confirmDialog.open({
      title: titleName,
      message: message,
      icon: { show: false, name: "", color: "primary" },
      actions: {
        confirm: { show: true, label: acceptText, color: "primary" },
        cancel: { show: true, label: cancelText },
      },
      dismissible: false,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const id = data.map((obj: any) => obj.job_id)
        const reqData: any = {
          job_id: id,
          company_id: this.cs.currentScope !== '*' ? parseInt(this.cs.currentScope.id) : undefined,
          isGlobal: (this.cs.currentScope === '*') ? true : false
        };
        this.ls.display(true);
        this._changeDetectorRef.detectChanges();
        this._bs
          .doRequest(`/report_builder/get_report_link`, "get", null, reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.ls.display(false);
            if (res.status) {
              setTimeout(() => this.cs.selectedTagChange.next({}));
              this._changeDetectorRef.detectChanges();
              window.open(res.message, "_blank");
            } else {
              const data = res.message ? res.message : res.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }
  generatedReCreateJob(data: any) {
    const reGenCreateConfirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: "Are you sure you want to Re-Create Job these Job?",
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" },
      },
    });
    reGenCreateConfirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const reqData: any = {
          job_id: data[0].job_id,
          isGlobal: (this.cs.currentScope === '*') ? true : false,
        };
        this._bs
          .doRequest(`/report_builder/recreate_report_job`, "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast("success", "Re-Create successfully");
              setTimeout(() => this.cs.selectedTagChange.next({}));
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }
  generatedReportTerminate(data: any) {
    const reportConfirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: "Are you sure you want to terminate these Job?",
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" },
      },
    });
    reportConfirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const reqData: any = {
          job_id: data.map(
            (obj: any) => obj.job_id
          ),
          isGlobal: (this.cs.currentScope === '*') ? true : false
        };
        this._bs
          .doRequest(`/report_builder/terminate_report_job`, "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast("success", "Terminated successfully");
              setTimeout(() => this.cs.selectedTagChange.next({}));
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }
  getData(): void {
    this.ls.display(true);
    this._reportDetails = [];
    let skip = 0;
    let limit = this.pageSize;
    if (this.pageIndex) {
      skip = this.pageIndex * this.pageSize;
      limit = this.pageSize;
    }
    this.loading = true; this._changeDetectorRef.detectChanges();
    let condition: any = { condition: `job_id='${this.currentData.job_id}'`, skip, limit, order_by: 'updated desc' }
    this._bs
      .doRequest(`/report_queries/job_details`, "get", null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.ls.display(false);
        if (result.status) {
          this.loading = false;
          if (result.total === 0) { this.currentStep = 0 };
          this.pageTotal = result.total;
          try {
            this._reportDetails = result.data;
          } catch (e) {
            console.log(e);
          }
          this._changeDetectorRef.detectChanges();
        }
      });
  }

  pageChanged(event: any): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getData();
  }
}
