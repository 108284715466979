<div [formGroup]="forms">
    <div [ngSwitch]="formElement.type">
        <!-- Query Builder - External Dependency -->
        <div *ngIf="formElement.type =='querybuilder' && outputValue[formElement.condition]">
            <app-query-builder (outputValueChange)="querychanges($event, formElement,outputValue)"
                [listofquery]="outputValue[formElement.key]" [formElement]="formElement"
                [outputValue]=[outputValue[formElement.condition]] [trigger]="trigger">
            </app-query-builder>
        </div>

        <!-- Text -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'text'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="formElement.type"
                [readonly]="formElement.readonly" [required]="formElement.required"
                [(ngModel)]="outputValue[formElement.key]" [minlength]="formElement.min" [maxlength]="formElement.max"
                [formControlName]="formElement.key" (keydown)="onKeydownMain($event)"
                [autocomplete]="(formElement.label === 'username')? 'new-username' : 'off'">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>

        <!-- IP -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'ip'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="'text'"
                [readonly]="formElement.readonly" [required]="formElement.required"
                [(ngModel)]="outputValue[formElement.key]" [minlength]="formElement.min" [maxlength]="formElement.max"
                [formControlName]="formElement.key" (keydown)="onKeydownMain($event)"
                [autocomplete]="(formElement.label === 'username')? 'new-username' : 'off'">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>

        <!-- Email -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'email'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="formElement.type"
                appEmailCheck [readonly]="formElement.readonly" [required]="formElement.required"
                [(ngModel)]="outputValue[formElement.key]" [formControlName]="formElement.key"
                (keydown)="onKeydownMain($event)"
                [autocomplete]="(formElement.label === 'username')? 'new-username' : 'off'">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>

        <!-- Number -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'number'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="formElement.type"
                [readonly]="formElement.readonly" [required]="formElement.required" [min]="formElement.min"
                [max]="formElement.max" [(ngModel)]="outputValue[formElement.key]" autocomplete="off"
                [formControlName]="formElement.key">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>

        <!-- int -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'int'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="'number'"
                [readonly]="formElement.readonly" [required]="formElement.required"
                [(ngModel)]="outputValue[formElement.key]" autocomplete="off" [formControlName]="formElement.key">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>

        <!-- integer -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'integer'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="'number'"
                [readonly]="formElement.readonly" [required]="formElement.required"
                [(ngModel)]="outputValue[formElement.key]" autocomplete="off" [formControlName]="formElement.key">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>

        <!-- HiddenTextBox -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm display-none" *ngSwitchCase="'hidden'">
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="'text'"
                [required]="formElement.required" [(ngModel)]="outputValue[formElement.key]" [readonly]="true"
                [formControlName]="formElement.key">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>
        <!-- HiddenTextBox -->

        <!-- Password -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'spassword'">
            <mat-label>{{formElement.label}}</mat-label>
            <input appPasswordCheck matInput [placeholder]="formElement.label" [id]="formElement.key" type="password"
                [required]="formElement.required" autocomplete="new-password" [(ngModel)]="outputValue[formElement.key]"
                [formControlName]="formElement.key">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>
        <!-- Password -->

        <!-- Password -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'password'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="formElement.type"
                [required]="formElement.required" autocomplete="new-password" [(ngModel)]="outputValue[formElement.key]"
                *ngIf="outputValue['credential_type'] === 'FIREWALLCREDENTIAL'"
                [formControlName]="formElement.key" (focus)="clearPassword($event)" (blur)="restorePassword($event)">

            <input matInput [placeholder]="formElement.label" [id]="formElement.key" [type]="formElement.type"
                [required]="formElement.required" autocomplete="new-password" [(ngModel)]="outputValue[formElement.key]"
                *ngIf="outputValue['credential_type'] !== 'FIREWALLCREDENTIAL'"
                [formControlName]="formElement.key">
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>
        <!-- Password -->

        <!-- DropDown -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'select'">
            <mat-label>{{formElement.label}}</mat-label>
            <mat-select [(ngModel)]="outputValue[formElement.key]" [placeholder]="formElement.label"
                [formControlName]="formElement.key" (valueChange)="callBack($event, formElement.callback, formElement)"
                [id]="formElement.key">
                <ng-container *ngIf="!formElement.dKey">
                    <mat-option *ngFor="let item of formElement.options" [id]="item.key"
                        [value]="item.value">{{item.key}}</mat-option>
                </ng-container>
                <ng-container *ngIf="formElement.dKey && formElement.dValue">
                    <mat-option *ngFor="let item of formElement.options" [id]="item[formElement.dKey]"
                        [value]="item[formElement.dValue]">{{item[formElement.dKey]}}</mat-option>
                </ng-container>
            </mat-select>
            <mat-hint *ngIf="formElement.example" class="p-0">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
            </mat-error>
        </mat-form-field>
        <ng-container
            *ngIf="!formElement.multiple && formElement.type =='dropdown' && (formElement.condition === undefined
                 || (outputValue[formElement.condition] && outputValue[formElement.condition].length>0
                  && (formElement.condition_values === undefined || formElement.condition_values.includes(outputValue[formElement.condition]))))">
            <div class="flex flex-row items-center">
                <mat-form-field floatLabel="always" class="mb-4 sForm" *ngSwitchCase="'dropdown'" [ngClass]="{
                'w-[90%]': formElement.search_element && formElement.search_element.search,
                'w-full': (!formElement.search_element || !formElement.search_element.search)}">
                    <mat-label>{{formElement.label}}</mat-label>
                    <mat-select #singleSelect [placeholder]="formElement.label" [id]="formElement.key"
                        [(ngModel)]="outputValue[formElement.key]" [formControlName]="formElement.key"
                        (selectionChange)="callBack($event.value, formElement.key, formElement)">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="entityFilterCtrl"
                                [placeholderLabel]="formElement.label" [noEntriesFoundLabel]="'No matching found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>

                        <ng-container *ngIf="!formElement.dKey">
                            <mat-option *ngIf="formElement.showNone && !formElement.required" value="">None</mat-option>
                            <mat-option [matTooltip]="item.key"
                                *ngFor="let item of formElement.options | sortBy:'asc':'key' | search: entityFilterCtrl.value"
                                [id]="item.key" [value]="item.value">{{item.key}}
                            </mat-option>
                        </ng-container>
                        <ng-container *ngIf="formElement.dKey && formElement.dValue">
                            <mat-option *ngIf="formElement.showNone && !formElement.required" value="">None</mat-option>
                            <mat-option [matTooltip]="item[formElement.dValue]"
                                *ngFor="let item of formElement.options | sortBy:'asc': formElement.dValue | search: entityFilterCtrl.value"
                                [id]="item[formElement.dKey]"
                                [value]="item[formElement.dValue]">{{item[formElement.dKey]}}
                            </mat-option>
                        </ng-container>

                    </mat-select>
                    <mat-hint *ngIf="formElement.example">
                        <mat-icon>info_outline</mat-icon>
                        {{formElement.example}}</mat-hint>
                    <mat-error>
                        {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
                    </mat-error>
                </mat-form-field>
                <span [matMenuTriggerFor]="menuRef" #searchMenuTrigger="matMenuTrigger"></span>
                <span class="mt-2" *ngIf="formElement.search_element && formElement.search_element.parameters && formElement.search_element.parameters.search">
                    <button mat-icon-button="" matTooltip="Search" (click)="searchParamsFn()">
                        <mat-icon class="mat-24">search</mat-icon></button>
                </span>
            </div>
        </ng-container>
        <ng-container
            *ngIf="formElement.multiple && (formElement.condition === undefined || (outputValue[formElement.condition] && outputValue[formElement.condition].length>0 && (formElement.condition_values === undefined || formElement.condition_values.includes(outputValue[formElement.condition]))))">
            <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'dropdown'">
                <mat-label>{{formElement.label}}</mat-label>
                <mat-select #multiSelect [placeholder]="formElement.label" [id]="formElement.key"
                    [(ngModel)]="outputValue[formElement.key]" [formControlName]="formElement.key" multiple
                    (selectionChange)="callBack($event.value, formElement.key, formElement)">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="entityFilterCtrl" [placeholderLabel]="formElement.label"
                            [noEntriesFoundLabel]="'No matching found'" [searching]="searching"></ngx-mat-select-search>
                    </mat-option>

                    <ng-container *ngIf="!formElement.dKey">
                        <mat-option [matTooltip]="item.key"
                            *ngFor="let item of formElement.options | sortBy:'asc':'key' | search: entityFilterCtrl.value"
                            [id]="item.key" [value]="item.value">{{item.key}}
                        </mat-option>
                    </ng-container>
                    <ng-container *ngIf="formElement.dKey && formElement.dValue">
                        <mat-option [matTooltip]="item[formElement.dValue]"
                            *ngFor="let item of formElement.options | sortBy:'asc': formElement.dValue | search: entityFilterCtrl.value"
                            [id]="item[formElement.dKey]" [value]="item[formElement.dValue]">{{item[formElement.dKey]}}
                        </mat-option>
                    </ng-container>

                </mat-select>
                <mat-hint *ngIf="formElement.example">
                    <mat-icon>info_outline</mat-icon>
                    {{formElement.example}}</mat-hint>
                <mat-error>
                    {{formElement.label}} is required. <span class="ml10">{{formElement.errmsg}}</span>
                </mat-error>
            </mat-form-field>
        </ng-container>

        <!-- Color -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'color'">
            <mat-label>{{formElement.label}}</mat-label>
            <input [type]="formElement.type" [formControlName]="formElement.name" [placeholder]="formElement.label"
                [id]="formElement.key" [required]="formElement.required" [(ngModel)]="outputValue[formElement.key]"
                matInput>
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
        </mat-form-field>


        <ng-container *ngSwitchCase="'sdropdown'">
            <div class="d-flex flex-row">
                <!-- Search Menu -->
                <!--<span [matMenuTriggerFor]="menuRef" #searchMenuTrigger="matMenuTrigger"></span>
                <span class="ml-3 mt-2" *ngIf="this.formElement.search_element && this.formElement.search_element.show">
                  <button  mat-icon-button="" matTooltip="Search" (click)="searchParamsFn()">
                    <mat-icon class="mat-24">search</mat-icon></button>
                </span>-->
                <!-- Search Menu -->
            </div>
        </ng-container>
        <!-- DropDown -->

        <!--Chip list-->
        <ng-container *ngSwitchCase="'chiptext'">
            <tag-list [keywords]="outputValue[formElement.key]" [ff]="formElement"
                (result)="updateKey($event, formElement.key)" [id]="formElement.key"></tag-list>
        </ng-container>

        <!-- HelpText -->
        <div *ngSwitchCase="'helptext'" class="mat-card mb-4">
            <p class="help" *ngIf="!formElement.ishtml">{{formElement.label}}</p>
            <div class="flex flex-row items-center ml-0">
                <div class="flex flex-row" *ngIf="formElement.ishtml" [innerHTML]="formElement.label | safeHTML">
                </div>
                <span class="ml-2 mt-2 cursor-pointer" *ngIf="formElement.help_url"
                    (click)="openHelpTab(formElement.help_url)">
                    <mat-icon class="mat-18 mat-primary">info_outline</mat-icon>
                </span>
            </div>
        </div>
        <!-- HelpText -->
        <!-- CheckBox -->
        <div class="w-full mb-4 sForm" *ngSwitchCase="'checkbox'">
            <mat-checkbox color="primary" [(ngModel)]="outputValue[formElement.key]" [id]="formElement.key"
                [value]="formElement.value" [formControlName]="formElement.key">
                {{formElement.label}}
            </mat-checkbox>
        </div>
        <!-- CheckBox -->

        <!-- SlideToggle -->
        <div class="w-full mb-4 sForm" *ngSwitchCase="'slidetoggle'">
            <mat-slide-toggle [(ngModel)]="outputValue[formElement.key]" [id]="formElement.key"
                [formControlName]="formElement.key"> {{formElement.label}}
            </mat-slide-toggle>
        </div>
        <!-- SlideToggle -->

        <!-- RadioButton -->
        <div class="w-full mb-4 sForm" *ngSwitchCase="'radiobutton'">
            <mat-radio-group [(ngModel)]="outputValue[formElement.key]" [formControlName]="formElement.key"
                aria-label="Select an option">
                <mat-radio-button *ngFor="let items of formElement.options" [id]="items.key" [value]="items.value">
                    {{items.key}}
                </mat-radio-button>

            </mat-radio-group>
        </div>
        <!-- RadioButton -->

        <!--Custom Radio Group-->
        <div *ngSwitchCase="'custom-radiogroup'" [ngClass]="{
            'grid-cols-1': formElement.span === 1,
            'grid-cols-2': formElement.span === 2,
            'grid-cols-3': formElement.span === 3,
            'grid-cols-4': formElement.span === 4,}" class="grid  gap-6 w-full">
            <mat-radio-group class="invisible absolute w-0 h-0 pointer-events-none"
                [(ngModel)]="outputValue[formElement.key]"
                (change)="callBack($event, formElement.callback, formElement.key)" [formControlName]="formElement.key"
                #planRadioGroup="matRadioGroup">
                <ng-container *ngFor="let rad of formElement.radioList">
                    <mat-radio-button (change)="callBack($event, formElement.callback, formElement.key)"
                        [value]="rad.value"></mat-radio-button>
                </ng-container>
            </mat-radio-group>
            <ng-container *ngFor="let rad of formElement.radioList; trackBy: trackByFn">
                <div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card"
                    [ngClass]="{'ring ring-inset ring-primary': planRadioGroup.value === rad.value}"
                    (click)="planRadioGroup.value = rad.value; outputValue[formElement.key] = rad.value; callBack(rad.value, formElement.callback, formElement.key)">
                    <ng-container *ngIf="planRadioGroup.value === rad.value">
                        <mat-icon class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary"
                            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                    </ng-container>
                    <i *ngIf="rad.isIcon" class="fab {{rad.icon}} fa-3x text-primary"></i>
                    <img *ngIf="rad.isImg" [src]="rad.img" class="w-30" alt="" />
                    <div class="font-semibold mt-4">{{rad.label}}</div>
                    <div class="mt-1 text-xs font-light whitespace-normal text-secondary">{{rad.details}}</div>
                    <div class="flex-auto"></div>
                </div>
            </ng-container>
        </div>
        <!--Custom Radio Group-->

        <!-- Copy To Clipboard -->
        <div class="" [ngClass]="{'col-span-2': spanLayout === 2,
           'col-span-3': spanLayout === 3, 'col-span-4': spanLayout === 4}" *ngSwitchCase="'copy-to-clipboard'">
            <fuse-alert class="max-h-36" fuseScrollbar [appearance]="formElement.appearance"
                [type]="formElement.alertType"> {{outputValue[formElement.key]}}
            </fuse-alert>
            <div class="flex flex-row justify-end items-center cursor-pointer text-sm mt-2  "
                (click)="copyClip(outputValue[formElement.key])">
                <i class=" fa fa-clipboard copyIcon float-right mr-2" aria-hidden="true"></i> Copy to clipboard
            </div>
        </div>

        <!--Alert View-->
        <div class="" [ngClass]="{'col-span-2': spanLayout === 2,
       'col-span-3': spanLayout === 3, 'col-span-4': spanLayout === 4}" *ngSwitchCase="'alert'">
            <fuse-alert class="max-h-36" fuseScrollbar [appearance]="formElement.appearance"
                [type]="formElement.alertType">
                <span class="font-light">{{formElement.title}}: </span> <span
                    class="font-medium">{{outputValue[formElement.key]}}</span>
            </fuse-alert>
        </div>
        <!--Alert View-->

        <!-- TextArea -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'textarea'">
            <mat-label>{{formElement.label}}</mat-label>
            <textarea matInput [(ngModel)]="outputValue[formElement.key]" [placeholder]="formElement.label"
                [formControlName]="formElement.key" [id]="formElement.key" [value]="formElement.value"></textarea>
            <mat-hint *ngIf="formElement.example">
                <mat-icon>info_outline</mat-icon>
                {{formElement.example}}</mat-hint>
            <mat-error>
                {{formElement.label}} is required
            </mat-error>
        </mat-form-field>
        <!-- TextArea -->

        <!-- DatePicker -->
        <mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngSwitchCase="'datepicker'">
            <mat-label>{{formElement.label}}</mat-label>
            <input matInput [(ngModel)]="outputValue[formElement.key]" [formControlName]="formElement.key"
                [matDatepicker]="picker" [placeholder]="formElement.label">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error> {{formElement.label}} is required</mat-error>
        </mat-form-field>
        <!-- DatePicker -->

        <div *ngSwitchCase="'text-multiple'">
            <p>{{formElement.label}}</p>
            <div class="flex flex-row justify-center items-center">
                <mat-form-field floatLabel="always" class="w-full mb-4 sForm">
                    <!-- <mat-label>{{formElement.label}}</mat-label> -->
                    <input matInput [(ngModel)]="multipletexts" id="multipletexts" name="multipletexts"
                        [formControlName]="formElement.key" [placeholder]="formElement.label">
                    <mat-error> {{formElement.label}} is required</mat-error>
                </mat-form-field>
                <mat-icon (click)="addItem(multipletexts, formElement, outputValue)">add</mat-icon>
            </div>
            <div class="flex flex-row justify-start items-center">
                <ng-container *ngFor="let textitems of outputValue[formElement.key];let ind= index">
                    <div data-te-chip-init data-te-ripple-init
                        class="[word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200">
                        {{textitems}}
                        <span (click)="removeitem(ind, formElement, outputValue)" data-te-chip-close
                            class="float-right w-4 cursor-pointer pl-[8px] text-[16px] text-[#afafaf] opacity-[.53] transition-all duration-200 ease-in-out hover:text-[#8b8b8b] dark:text-neutral-400 dark:hover:text-neutral-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="h-3 w-3">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>



<mat-menu #menuRef="matMenu" [hasBackdrop]="false">
    <div class="p-2"
        (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
        <div class="flex flex-row items-center">
            <div class="text-lg font-semibold tracking-tight">Search</div>
            <button class="ml-auto" mat-icon-button (click)="searchMenuTrigger.closeMenu()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <mat-divider class="my-2"></mat-divider>
        <div class="flex flex-col items-center w-full">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" *ngIf="formElement.searchParamsList && formElement.searchParamsList.indexOf('name') > -1">
                <mat-label>Name</mat-label>
                <input matInput [placeholder]="'Enter Name'" required name="name" [(ngModel)]="formElement.searchParams.name">
            </mat-form-field>
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                *ngIf="formElement.searchParamsList && formElement.searchParamsList.indexOf('pageSize') > -1">
                <mat-label>Page Size</mat-label>
                <input matInput [placeholder]="'Enter Name'" name="pageSize" [(ngModel)]="formElement.searchParams.pageSize"
                    type="number">
            </mat-form-field>
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" *ngIf="formElement.searchParamsList && formElement.searchParamsList.indexOf('page') > -1">
                <mat-label>Page</mat-label>
                <input matInput [placeholder]="'Enter Name'" name="page" [(ngModel)]="formElement.searchParams.page" type="number"
                    autocomplete="off">
            </mat-form-field>
        </div>
        <mat-divider class="my-4"></mat-divider>
        <div class="flex items-center justify-end">
            <button (click)="getSearchParameter(); searchMenuTrigger.closeMenu()" mat-stroked-button=""
                type="button" color="primary">Search</button>
        </div>
    </div>
</mat-menu>
