import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {NgForOf, NgIf} from "@angular/common";
import { MaterialModule } from 'app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-query-builder',
  standalone: true,
  imports: [CommonModule,FormsModule, MatIconModule,MatFormFieldModule, MatInputModule,NgForOf, NgIf,MaterialModule],
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent implements OnChanges{
  @Input() outputValue:any;
  @Input() formElement:any;
  @Input() trigger:any;
  collection_options:any=[];
  @Input() listofquery:any;
  @Output() valChange = new EventEmitter();
  @Output() outputValueChange = new EventEmitter<any>();
  querydataoptions=[{"name":'Equals', "type":["integer", "string"]}, { "type":["integer","string"],"name":"Not Equal"}, {"type":["string"],"name":"Contains"},{"name":"Between", "type":["integer"]}, {"name":"Lesser Than", "type":["integer"]}, {"name":"Lesser Than Or Equal", "type":["integer"]}, {"name":"In", "type":["integer"]}, {"name":"Not In", "type":["integer"]}, {"type":["integer"],"name":"Is Null"}, { "type":["integer"],"name":"Is Not Null"}, { "type":["string"],"name":"Is Empty"},{"type":["string"], "name":"Is Not Empty"}, 
]

ngOnChanges(changes: SimpleChanges):void{
  // if (changes.listofquery) {
  //   this.outputValueChange.emit(this.listofquery);
  // }
  if(!this.listofquery){
    this.listofquery= [
      { rule: "", attribute: "", conditions: "", values: "", rulenow: {} },
      // Add more query objects as needed
    ];
    
  }
  this.formElement.options.forEach(ele =>{
    if(ele.displayName == this.outputValue[0]){
        this.collection_options = ele.rules
    }
  })
}


ruleadd(querydata, rule){
  this.collection_options.forEach(ele =>{
    if(ele.displayName == querydata['rule']){
      ele.queryfields.forEach(da =>{
        if(da.field == rule.value){
          querydata.rulenow = da
        }
      })
    }
  })
  this.valuechanged()
}

addquery(){
  this.listofquery.push({ rule: "", attribute: "", conditions: "", values: "", rulenow: {} })
  this.valuechanged()

}

removeQuery(i){
this.listofquery.splice(i)
this.valuechanged()

}

valuechanged() {
  // let updatedObject = { ...this.listofquery };
// delete updatedObject.rulenow;
  this.outputValueChange.emit(this.listofquery);
}

}
