<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<div class="flex flex-auto justify-center w-full  bg-gray-200 dark:bg-card"> <!--sm:p-4 md:p-8-->

    <!-- Navigation -->
    <ng-container *ngIf="isScreenSmall">
        <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
            [navigation]="navigation.default" [opened]="false">
            <!-- Navigation header hook -->
            <ng-container fuseVerticalNavigationContentHeader>
                <!-- Logo -->
                <div class="flex items-center h-20 pt-6 px-8">
                    <img class="w-50" [src]="_bs.whitelabel().logo_dark" aria-label="dark" alt="">
                    <!--<div class="flex flex-row items-end justify-end">
                        <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
                    </div>-->
                </div>
            </ng-container>
        </fuse-vertical-navigation>
    </ng-container>

    <!-- Wrapper -->
    <div #_wrapper_ id="_wrapper_"
        class="flex flex-col flex-auto min-w-0 max-w-full  shadow-2xl dark:shadow-none overflow-hidden">
        <!--sm:rounded-xl-->
        <app-notify></app-notify>
        <!-- Header -->
        <div
            class="relative flex flex-0 items-center w-full h-14 sm:h-14 px-2 md:px-3 z-49 bg-card border-b dark:bg-default print:hidden">
            <ng-container *ngIf="!isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:mr-8">
                    <div class="hidden lg:flex">
                        <!-- Light version -->
                        <img class="dark:hidden  max-w-[12rem] h-[2.5rem] object-contain" [src]="_bs.whitelabel().logo"
                            aria-label="light" alt="Logo image">
                        <!-- Dark version -->
                        <img class="hidden dark:flex  max-w-[12rem] h-[2.5rem] object-contain"
                            [src]="_bs.whitelabel().logo_dark" aria-label="dark" alt="Logo image">
                        <!--<div class="flex flex-row items-end justify-end">
                        <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
                    </div>-->
                    </div>
                    <!-- Small version -->

                    <div class="flex lg:hidden">
                        <!-- Light version -->
                        <img class="dark:hidden  max-w-[10rem] h-[2.5rem] object-contain" [src]="_bs.whitelabel().logo"
                            aria-label="light" alt="Logo image">
                        <!-- Dark version -->
                        <img class="hidden dark:flex  max-w-[10rem] h-[2.5rem] object-contain"
                            [src]="_bs.whitelabel().logo_dark" aria-label="dark" alt="Logo image">
                        <!--<div class="flex flex-row items-end justify-end">
                        <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
                    </div>-->
                    </div>

                </div>
                <!-- Horizontal navigation -->
                <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'"
                    [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
            </ng-container>
            <!-- Navigation toggle button -->
            <ng-container *ngIf="isScreenSmall">
                <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                </button>
            </ng-container>
            <!-- Components -->
            <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
                <languages></languages>
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                <app-theme-switch class="hidden md:block"></app-theme-switch>
                <scope></scope>
                <shortcuts></shortcuts>
                <notifications  *ngIf="_us._user_roles.indexOf('admin') > -1"></notifications>
                <user></user>
            </div>
        </div>

        <!-- Content -->
        <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>
        <div class="flex flex-col flex-auto w-full bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <div class="flex flex-row items-center gap-2 text-gray-400 px-6 py-2 bg-card mb-1  shadow"
                *ngIf="currentPath && currentPath.length">
                <i (click)="redirectPage('/dashboard')" class="fas fa-home cursor-pointer hover:text-primary"></i>
                <span (click)="redirectPage('/metrics')" class="font-semibold uppercase hover:text-primary ng-star-inserted rounded border cursor-pointer text-md bg-green-100 px-1
                 dark:bg-gray-800 dark:text-white py-0.5 text-green-900">
                    <ng-container *ngIf="_cs.currentScope === '*'">GLOBAL</ng-container>
                    <ng-container *ngIf="_cs.currentScope !== '*'">{{_cs.currentScope.name}}</ng-container>
                </span>
                <span *ngFor="let s of currentPath; let last = last">
                    {{navHash[s]|uppercase}}
                    <i *ngIf="!last" class="ml-1 fas fa-chevron-right"></i>
                </span>
            </div> <!--*ngIf="_cs.isChecked === 'left'"-->

            <router-outlet *ngIf="_bs.user().email && !_cs.isComLoading">

            </router-outlet>
            <app-skeleton *ngIf="_cs.isComLoading">

            </app-skeleton>

        </div>

        <!-- Footer -->
        <div
            class="relative flex flex-0 items-center justify-start w-full h-14 sm:h-14 px-6 sm:px-8 z-49 bg-card border-t dark:bg-default print:hidden">
            <span class="font-medium text-secondary">{{_bs.whitelabel().terms_of_service | ellipsis:50}} &copy; {{currentYear}}</span>
        </div>
    </div>
</div>
