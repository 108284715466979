<mat-form-field class="w-full sm:w-1/2 mt-4 ml-4" [subscriptSizing]="'dynamic'">
    <mat-label>Select Credential</mat-label>
    <mat-select [(ngModel)]="credentialId" (selectionChange)="filterByCred($event)">
        <mat-option *ngFor="let cred of integrationCred" [value]="cred.id">{{ cred.name }}</mat-option>
    </mat-select>
</mat-form-field>

<div class="flex flex-col w-full min-w-0"
    *ngIf="cView === 'table' && integrationCred  && currentIntegration && intConfigTableOptions && intConfigTableOptions.tableOptions">
    <fuse-alert class="m-4" [appearance]="'border'" [type]="'info'">
        <span fuseAlertTitle>Note: </span>
            <span>The configuration synchronization process will automatically run once every 24 hours.</span>
    </fuse-alert>
    <stable [sTableOptions]="intConfigTableOptions" (addCallback)="addTableData()"
        (actionCallback)="actionCall($event)">
    </stable>
</div>
<div class="flex flex-col w-full min-w-0" *ngIf="cView === 'add'">
    <div class="flex overflow-y-auto flex-col-reverse p-4">
        <div class="flex flex-col flex-auto shrink p-2 bg-card dark:bg-transparent rounded-xl">
            <div class="flex flex-row items-center">
                <mat-form-field class="w-1/4 mr-4" [subscriptSizing]="'dynamic'">
                    <mat-label>Select {{currentIntegration.display}} Configuration</mat-label>
                    <mat-select [(ngModel)]="configuration" id="configurationId" name="configurationId"
                        (openedChange)="closeCurrentConfig($event)" (selectionChange)="getConfigQuestions($event)">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="searchConfigControl"
                                [placeholderLabel]="'Search Configuration'"
                                [noEntriesFoundLabel]="'No matching configuration found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="config.name" *ngFor="let config of configurations;"
                            [value]="config.id">
                            {{config.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-1/4 mr-4" [subscriptSizing]="'dynamic'">
                    <mat-label>Select {{currentIntegration.display}} Configuration Status</mat-label>
                    <mat-select [(ngModel)]="config_status" id="configStatusId" name="configStatusId">

                        <mat-option>
                            <ngx-mat-select-search [formControl]="searchConfigStatusControl"
                                [placeholderLabel]="'Search Configuration'"
                                [noEntriesFoundLabel]="'No matching configuration found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="status.description" *ngFor="let status of all_config_status;"
                            [value]="status.id">
                            {{status.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    (click)="openAddQuestion()">
                    Add Question
                </button>
            </div>
            <mat-divider class="my-4"></mat-divider>
            <ng-container *ngIf="questions">
                <div class="flex items-center gap-4 mb-4 px-10">
                    <h3 class="text-lg font-semibold w-[15vw]">Configuration Questions</h3>
            
                    <h3 class="text-lg font-semibold w-1/4">Select Configuration Answer</h3>
                </div>
                <div *ngFor="let que of questions; let i = index;" class="p-1 rounded-xl">
                    <div class="flex items-center flex-row gap-2 px-10">
                        <p class="whitespace-pre-wrap break-words text-sm font-bold w-[15vw]">{{ que.question }}</p>
                        <mat-form-field class="w-1/4" [subscriptSizing]="'dynamic'">
                            <mat-select [(ngModel)]="que.answer" name="answer{{i}}">
                                <mat-option [value]="'None'">None</mat-option>
                                <mat-option *ngFor="let ans of configAnswers" [value]="ans.answer">{{ ans.question
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="ml-2" mat-icon-button aria-label="delete" matTooltip="Delete"
                            (click)="deleteQuestion(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
            <div class="flex items-center mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="cancel()">
                    Cancel
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick
                    [disabled]="!configuration" [matTooltip]="'Save'" (click)="saveConfiguration()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<s-modal id="addNewQues">
    <mat-card class="modal w-[700px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Add Configuration Question</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('addNewQues');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form class="example-form px-4" #configNgForm="ngForm">
                <div class="flex flex-row items-center gap-2">
                    <mat-form-field class="w-full my-4" [subscriptSizing]="'dynamic'">
                        <mat-label>Question</mat-label>
                        <input matInput placeholder="Question" name="question" [(ngModel)]="newQuestion" required>
                    </mat-form-field>
                    <mat-form-field class="w-full my-4" [subscriptSizing]="'dynamic'">
                        <mat-label>Answer Type</mat-label>
                        <mat-select placeholder="Select Field Type" name="fieldType" [(ngModel)]="fieldtype" required>
                            <mat-option *ngFor="let type of default_fieldType" [value]="type.value">{{ type.label
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button type="button" id="saveButton" (click)="addQuestion()"
                        [disabled]="!newQuestion || !fieldtype" appPreventMultiClick class="ml-2 mt-6"
                        mat-flat-button color="primary">Add
                    </button>
                </div>
                <ng-container *ngIf="configurationNewQuestions && configurationNewQuestions.length">
                    <div class="relative overflow-x-auto sm:rounded-lg mt-4 w-full">
                        <table class="w-full text-sm text-left rtl:text-right">
                            <thead
                                class="text-medium font-medium bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b mt-2 tracking-tight leading-6 bg-card">
                                <tr>
                                    <th scope="col" class="pl-4 py-2">
                                        Question
                                    </th>
                                    <th scope="col" class="pl-4 py-2">
                                        Answer Type
                                    </th>
                                    <th scope="col" class="pl-4 py-2">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let app of configurationNewQuestions;">
                                    <td class="py-1 pl-4">
                                        {{app.question}}
                                    </td>
                                    <td class="py-1 pl-4">
                                        {{app.fieldType}}
                                    </td>
                                    <td class="py-1 pl-4">
                                        <button (click)="configurationNewQuestions.splice(i, 1)" type="button" mat-icon-button=""
                                            matTooltip="Delete record">
                                            <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t">
                        <button type="button" id="saveButton" (click)="createQuestion()"  appPreventMultiClick class="mr-2"
                            mat-flat-button color="primary">Save
                        </button>
                    </div>
                </ng-container>

            </form>
        </mat-card-content>
    </mat-card>
</s-modal>