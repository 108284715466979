<mat-form-field class="w-full sm:w-1/2 mt-4 ml-4" [subscriptSizing]="'dynamic'">
    <mat-label>Choose Credential</mat-label>
    <mat-select [(ngModel)]="selectedCred" (selectionChange)="filterByCred($event)">
        <ng-container *ngFor="let cred of integrationCred; trackBy: trackByFn">
            <mat-option [value]="cred.id">{{cred.name}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
<ng-container *ngIf="cView === 'table'">
    <div class="" fuseScrollbar>
        <stable [sTableOptions]="mappedComTableOptions" (addCallback)="addTableData()"
            (actionCallback)="actionCall($event)" *ngIf="this.mappedComTableOptions && selectedCred"></stable>
    </div>
</ng-container>

<ng-container *ngIf="cView === 'add'">
    <div class="flex-auto p-4 sm:p-4">
        <div class="max-w-full">
            <div class="prose prose-sm max-w-full">
                <div class="flex flex-row items-center">
                    <h2 class="">New Company Mapping</h2>
                    <button class="ml-auto" mat-icon-button (click)="cView = 'table'">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
            </div>
            <ng-container>
                <form class="flex flex-col p-8 bg-card shadow rounded overflow-hidden">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12">
                            <p class="text-lg font-medium">Import Companies from {{this.currentIntegration.display}}</p>
                        </div>
                        <div class="w-full mt-2">
                            <div class="flex items-start w-full mt-4">
                                <div class="flex flex-col w-9/12 pr-2">
                                    <mat-form-field [subscriptSizing]="'dynamic'">
                                        <mat-label>Choose Company</mat-label>
                                        <mat-select [multiple]="true" name="importCmp"
                                            [(ngModel)]="comMapImpForm.company_id" id="sourceCmp">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="searchSourceImpControl"
                                                    [placeholderLabel]="'Search Company'"
                                                    [noEntriesFoundLabel]="'No matching company found'"
                                                    [searching]="searching"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option [matTooltip]="company.name"
                                                *ngFor="let company of destCompany | search: searchSourceImpControl.value"
                                                [value]="company">
                                                {{company.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="flex flex-wrap items-center -m-1.5 mt-2">
                                        <ng-container
                                            *ngIf="comMapImpForm.company_id && comMapImpForm.company_id.length">
                                            <ng-container
                                                *ngFor="let cmp of comMapImpForm.company_id; let i = index; trackBy: trackByFn">
                                                <div
                                                    class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                                    <span
                                                        class="text-md font-medium whitespace-nowrap">{{cmp.name}}</span>
                                                    <mat-icon class="icon-size-4 ml-2 cursor-pointer"
                                                        (click)="removeSelected(i)"
                                                        [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div
                                        class="flex items-center justify-end py-4 pr-4 mt-6 pl-1 border-t bg-gray-50 dark:bg-transparent">
                                        <!-- Save -->
                                        <button class="" mat-flat-button [color]="'primary'" appPreventMultiClick
                                            [disabled]="!comMapImpForm.company_id || !comMapImpForm.company_id.length" [matTooltip]="'Save'"
                                            (click)="importSelectedCompanies()">
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div class="w-1/4 border-l">
                                    <span class="w-full">
                                        <app-dynamic-form [isCloseBtn]="false" (saveCallBack)="getCompany($event)"
                                            [fnBtnName]="'Get Companies'" [isCloseBtn]="false"
                                            *ngIf="companyFormElements && companyFormElements.length"
                                            [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                                        </app-dynamic-form>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</ng-container>

<s-modal id="editImportNonPsaInt">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Update Mapped Company</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('editImportNonPsaInt');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form class="example-form px-4" #editMapNgForm="ngForm">
                <div class="prose prose-sm max-w-3xl">
                    <h2 class="mt-2">Souce Company Name: {{ updateCompany.source_company_name}}</h2>
                </div>
                <mat-form-field class="w-full my-4" [subscriptSizing]="'dynamic'">
                    <mat-label>Choose Destination Company</mat-label>
                    <mat-select name="destEditCmp" id="destEditCmp" [(ngModel)]="edit_update_company">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="editDestControl" [placeholderLabel]="'Search Company'"
                                [noEntriesFoundLabel]="'No matching company found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="company.name"
                            *ngFor="let company of destCompany | search: editDestControl.value" [value]="company">
                            {{company.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="form-row flex items-center justify-end -mx-8 px-8">
                    <button type="button" id="saveButton" (click)="updateComMapping()" appPreventMultiClick class="mr-2" mat-flat-button
                        color="primary">Update
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>
