import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, CurrencyPipe, NgClass, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {FuseAlertComponent} from "../../../../@fuse/components/alert";
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {BaseRequestService} from "../../../_services/base.service";
import {CommonService} from "../../../_services/common.service";
import {Subject, Subscription, takeUntil} from "rxjs";
import {AppFilterPipeModule} from "../../../_filters/app.filter-pipe.module";
import {MaterialModule} from "../../../material.module";
import {PaginatorWidgetComponent} from "../paginator-widget/paginator-widget.component";
import {LoaderService} from "../../../_services/loader.service";
import {MyToastrService} from "../../../_services/toastr.service";
import { UserService } from 'app/core/user/user.service';
@Component({
    selector: 'app-plan-and-billing',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FuseAlertComponent, MatRadioModule,
        NgFor, NgClass, NgIf, MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule,
        MatOptionModule, MatButtonModule, CurrencyPipe, AppFilterPipeModule, NgOptimizedImage,
        MaterialModule, PaginatorWidgetComponent],
    templateUrl: './plan-and-billing.component.html',
    styleUrls: ['./plan-and-billing.component.scss']
})
export class PlanAndBillingComponent implements OnInit, OnDestroy {
    planBillingForm: UntypedFormGroup;
    plans: any[];
    searching = false;
    allComp: any;
    companies: any;
    sortReverse = true;
    sortType: any = 'total_assets';
    search: any;
    pageSize: number = 100;
    pageTotal: number; assetTotal: number;
    currentPageIndex: any = 0;
    user: any = {}
    stats: any = [
        {
            icon: 'fa-desktop',
            title: 'Active Asset(s)',
            text: 'Active Asset(s)',
            key: 'total_discovered_assets',
        },
        {
            icon: 'fa-desktop',
            title: 'External Asset(s)',
            text: 'External Asset(s)',
            key: 'total_external_assets',
        },
        {
            icon: 'fa-desktop',
            title: 'Firewall Asset(s)',
            text: 'Firewall Asset(s)',
            key: 'total_firewall_assets',
        },
        {
            icon: 'fa-desktop',
            title: 'Total Asset(s)',
            text: 'Total Asset(s)',
            key: 'total_assets',
        },
        {
            icon: 'fa-dollar-sign',
            title: 'Cost Per Tenant',
            text: 'Cost Per Tenant',
            key: 'accrued_cost',
        }
    ];
    costPlan: any = [
        {name: 'UPTO 1500', count: 1500, cost: 299},
        {name: 'UPTO 2500', count: 2500, cost: 399},
        {name: 'UPTO 5000', count: 5000, cost: 599},
        {name: 'UPTO 7500', count: 7500, cost: 899},
        {name: 'UPTO 10000', count: 10000, cost: 1199},
    ];
    cmpHash: any = {};
    costHash: any = {
        1500: 299, 2500: 399, 5000: 599, 7500: 899, 10000: 1199
    }
    assetCount: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder, public _bs: BaseRequestService,
        private cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
        private ls: LoaderService, private toast: MyToastrService, public _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.planBillingForm = this._formBuilder.group({
            plan: ['']
        });

        // Setup the plans
        this.plans = [
            {
                value: 'basic',
                label: 'Up to 1,500 Devices',
                details: 'Manage up to 1.5k devices',
                price: '299',
            },
            {
                value: 'small',
                label: 'Up to 2,500 Devices',
                details: 'Manage up to 2.5k devices',
                price: '399',
            },
            {
                value: 'team',
                label: 'Up to 5,000 Devices',
                details: 'Manage up to 5k devices',
                price: '599',
            },
            {
                value: 'pro',
                label: 'Up to 7,500 Devices',
                details: 'Manage up to 7.5k devices',
                price: '899',
            },
            {
                value: 'enterprise',
                label: 'Up to 10,000 Devices',
                details: 'Manage up to 10k devices',
                price: '1199',
            },
        ];
        this.getCompaniesStats();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    apiDownload(): void {
        const getFormattedDate = (): string => {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(today.getFullYear()).slice(-2); // Get the last two digits of the year
            return `${month}${day}${year}`;
        };
        const dateSuffix = getFormattedDate();
        const filename = `Billing-${dateSuffix}.csv`;
        const csvContent = this.convertToCSV(this.allComp);
        this.downloadCSV(csvContent, filename);
    }
    convertToCSV(data: any[]): string {
        if (data.length === 0) {
            return ''; 
        }
    
        // Define the column mapping
        const columnMapping: { [key: string]: string } = {
            name: 'Company Name',
            total_discovered_assets: 'Active Asset(s)',
            total_external_assets: 'External Asset(s)',
            total_firewall_assets: 'Firewall Asset(s)',
            total_assets: 'Total Asset(s)',
            accrued_cost: 'Cost Per Tenant'
        };
    
        // Get the ordered headers and their corresponding titles
        const orderedHeaders = Object.keys(columnMapping);
        const headers = orderedHeaders.map(key => columnMapping[key]);
    
        // Function to format values for CSV
        const formatValue = (value: any, fieldName: string): string => {
            if (value === undefined || value === null) {
                return '';
            }
            const strValue = String(value);
    
            // Add $ symbol for "Cost Per Tenant" column
            if (fieldName === 'accrued_cost') {
                return `"${'$' + strValue.replace(/"/g, '""')}"`;
            }
            return `"${strValue.replace(/"/g, '""')}"`;
        };
    
        // Build the CSV rows
        const csvRows = [
            headers.join(','), // Header row
            ...data.map(row =>
                orderedHeaders.map(fieldName => formatValue(row[fieldName], fieldName)).join(',')
            )
        ];
    
        // Join rows with newline characters
        return csvRows.join('\n'); 
    }
    
    downloadCSV(csvContent: string, fileName: string): void {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
    
        if (link.download !== undefined) { // feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    getCompaniesStats(search?: any): void {
        if (!this.cs.allComp || !this.cs.allComp.length) {
            setTimeout(() => this.getCompaniesStats(), 1000);
        }
        this.cmpHash = {};
        this.cs.allComp.forEach((c: any) => {
            this.cmpHash[c.id] = c;
        });
        this.ls.display(true);
        const order = (this.sortReverse) ? 'desc' : 'asc';
        this._bs.doRequest(`/r/report_queries/total_asset_count`, 'get', null, {
            condition: true,
            skip: 0,
            limit: 9999,
            order_by: `total_assets ${order}`
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                this.assetTotal = 0;
                this.allComp = [];
                if (res.status && res.data && res.data.length) {
                    this.pageTotal = this.cs.allComp.length;
                    res.data.forEach((c: any) => {
                        this.assetTotal += c.total_assets;
                    });
                    res.data.sort((a: any, b: any) => {
                        return this.cs.compare(a['total_assets'], b['total_assets'], false);
                    });
                    let cost = 299;
                    if (this.assetTotal <= 1500) {
                        cost = +(299/this.assetTotal);
                        this.planBillingForm.setValue({plan: 'basic'})
                    } else if (this.assetTotal > 1500 && this.assetTotal <= 2500) {
                        cost = +(399/this.assetTotal);
                        this.planBillingForm.setValue({plan: 'small'})
                    } else if (this.assetTotal > 2500 && this.assetTotal <= 5000) {
                        cost = +(599/this.assetTotal);
                        this.planBillingForm.setValue({plan: 'team'})
                    } else if (this.assetTotal > 5000 && this.assetTotal <= 7500) {
                        cost = +(899/this.assetTotal);
                        this.planBillingForm.setValue({plan: 'pro'})
                    } else if (this.assetTotal > 7500) {
                        cost = +(1199/this.assetTotal);
                        this.planBillingForm.setValue({plan: 'enterprise'})
                    }
                    res.data.forEach((c: any) => {
                        c.accrued_cost = (c.total_assets) ? +(c.total_assets * cost)?.toFixed(2) : 0;
                    });
                    this.allComp = res.data;
                    this._changeDetectorRef.detectChanges();
                    this.searching = false;
                } else {
                    this.toast.sToast('info', 'Processing... Please come back after sometime!')
                }
            });
    }


    getSummaryView(): void {
        // /report_queries/insights?&condition=true
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    sortFn(key: any): void {
        this.sortType = key;
        this.sortReverse = !this.sortReverse;
        this.getCompaniesStats();
    }

    pageChanged(event: any): void {
        this.currentPageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getCompaniesStats();
    }
}
