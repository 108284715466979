export const enum_data = {
    STATICIP: "Static IP",
    CIDR: "CIDR",
    DOMAIN: "Domain",
    IPRANGE: "IP Range",
    WINDOWS: "Windows",
    VMWARE: "VMWare",
    LINUX: "Linux",
    MAC: "macOS",
    NETWORKDEVICE: "Network Device",
    FIREWALLOS: "Firewall OS",
    SNMPV1: "SNMP v1",
    SNMPV2: "SNMP v2",
    SNMPV3: "SNMP v3",
    AD: "AD",
    ASSETCREDENTIAL: "Asset Credential",
    FIREWALLCREDENTIAL: "Firewall Credential",
    FULLSCAN: "Full Scan",
    SNMPSCAN: "SNMP Scan",
    ADSCAN: "AD Scan",
    NETWORKSCAN: "Network Scan",
    Probe_endpoint_CSV_Upload: "Probe Discovery Settings CSV Upload",
    CSV_Upload_Details: "External Scan CSV Upload",
    EXTERNALSCAN: "External Scan",
    PIISCAN: "PII Scan",
    FIREWALLSCAN:"Firewall Scan",
    COMPLIANCESCAN:"Compliance Scan",
    INITIATED: "Initiated",
    STARTED: "Started",
    INPROGRESS: "In Progress",
    "IN PROGRESS": "In Progress",
    SUCCESS: "Success",
    COMPLETED: "Completed",
    FAILED: "Failed",
    Open: "Open",
    Approved: "Approved",
    Rejected: "Rejected",
    synscan: "Syn Scan",
    connectscan: "Connect Scan",
    xmasscan: "Xmas Scan",
    windows: "Windows",
    darwin: "macOS",
    linux: "Linux",
    application: "Application",
    service: "Service",
    ATTACKSURFACESCAN: "Attack Surface Mapper",
    every_day: "Every Day",
    weekly: "Weekly",
    days_in_a_month: "Days In A Month",
    hourly: "Hourly",
    os: "OS",
    reset_company_data:"Delete Job",
    quickscan: "Quick Scan",
    deepscan: "Deep Scan",
    detailedscan: "Detailed Scan",
    driver: "Driver"
}

