<div class="flex mt-5 mb-4">
    <div [ngClass]="{'w-full': expandedIndex === null, 'w-1/2': expandedIndex !== null}" class="mt-4">
        <fuse-alert [type]="'info'" [appearance]="'border'" class="mb-2">
            <span fuseAlertTitle> Manual Tags can be deleted under Tag management. Auto Tags can be deleted under Tag
                Rules.</span>
        </fuse-alert>
        <div class="bg-card text-black dark:text-white flex justify-between items-center px-6 py-4 rounded-t-lg">
            <div class="flex items-center">
                <span class="text font-semibold">
                    Tags
                    <span class="font-normal ml-1 bg-blue-500 text-white rounded-full px-2">
                        {{ searchTerm ? filteredResultSets.length : resultSets.length }}
                    </span>
                </span>
            </div>
            <div class="flex items-center justify-end">
                <mat-form-field class="search-tool ml-4" floatLabel="never">
                    <input matInput name="search" [(ngModel)]="searchTerm" (ngModelChange)="filterTags()"
                        placeholder="Search tags" autocomplete="off">
                    <button *ngIf="!searchTerm || searchTerm === ''" aria-label="Search" mat-icon-button matSuffix>
                        <mat-icon>search</mat-icon>
                    </button>
                    <button (click)="searchTerm=''; filterTags();" *ngIf="searchTerm" aria-label="Clear" mat-icon-button
                        matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
        <div class="overflow-auto border bg-card dark:text-white rounded-b-lg" style="max-height: 328px;">
            <mat-toolbar class="select-tools h-[47px] rounded bg-[#d1e3ff] dark:bg-default flex sticky top-0 z-10"
                *ngIf="hasSelected1() || isAllSelected1()">
                <span> {{ selectedCount > 0 ? selectedCount : (searchTerm ? filteredResultSets.length :
                    resultSets.length) }} Tag(s) selected.</span>
                <div class="flex flex-1 place-content-end">
                    <ng-container>
                        <button mat-flat-button color="primary" id="gbtnSelction" mat-raised-button
                            [matMenuTriggerFor]="gactionSMenu" class="mini-form-field"
                            [disabled]="!isDeletable()" aria-label="global action with a menu">
                            Global Actions
                        </button>
                        <mat-menu #gactionSMenu="matMenu">
                            <ng-container>
                                <a id="enable" class="mat-primary flex justify-between items-center" mat-menu-item
                                    (click)="mulDeleteConfirm()">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </a>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                    <button class="gray" matSuffix mat-icon-button (click)="clearSelection();">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </mat-toolbar>
            <div *ngIf="filteredResultSets.length === 0" class="px-2 py-4 text-center text-gray-500">
                No data available
            </div>

            <div *ngIf="filteredResultSets.length > 0">
                <div class="px-2 py-4  border-b border flex">
                    <div class="w-1/12 flex items-center justify-center">
                        <input type="checkbox" [(ngModel)]="selectAll" (ngModelChange)="selectAllToggle1()"
                            class="form-checkbox h-4 w-4 text-blue-500" [checked]="selectAll && isAllSelected1()"
                            [indeterminate]="hasSelected1() && !isAllSelected1()" [disabled]="!isSelectable()">
                    </div>
                    <div class="w-9/12 flex justify-center items-center">
                        <span class="font-semibold cursor-pointer flex items-center" (click)="sortData('title')">
                            Name
                            <mat-icon class="ml-1 text-sm"
                                *ngIf="currentSortKey !== 'title' || currentSortDirection === 'asc'"
                                [ngClass]="{'text-blue-500': currentSortKey === 'title' && currentSortDirection === 'asc'}">
                                arrow_upward
                            </mat-icon>
                            <mat-icon class="ml-1 text-sm"
                                *ngIf="currentSortKey === 'title' && currentSortDirection === 'desc'"
                                [ngClass]="{'text-blue-500': currentSortKey === 'title' && currentSortDirection === 'desc'}">
                                arrow_downward
                            </mat-icon>
                        </span>
                    </div>
                    <div class="w-9/12 flex justify-center items-center" *ngIf="cs.currentScope === '*'">
                        <span class="font-semibold cursor-pointer flex items-center" (click)="sortData('company_name')">
                            Company Name
                            <mat-icon class="ml-1 text-sm"
                                *ngIf="currentSortKey !== 'company_name' || currentSortDirection === 'asc'"
                                [ngClass]="{'text-blue-500': currentSortKey === 'company_name' && currentSortDirection === 'asc'}">
                                arrow_upward
                            </mat-icon>
                            <mat-icon class="ml-1 text-sm"
                                *ngIf="currentSortKey === 'company_name' && currentSortDirection === 'desc'"
                                [ngClass]="{'text-blue-500': currentSortKey === 'company_name' && currentSortDirection === 'desc'}">
                                arrow_downward
                            </mat-icon>
                        </span>
                    </div>
                    <div class="w-9/12 flex justify-center items-center">
                        <span class="font-semibold cursor-pointer flex items-center" (click)="sortData('type')">
                            Type
                            <mat-icon class="ml-1 text-sm"
                                *ngIf="currentSortKey !== 'type' || currentSortDirection === 'asc'"
                                [ngClass]="{'text-blue-500': currentSortKey === 'typee' && currentSortDirection === 'asc'}">
                                arrow_upward
                            </mat-icon>
                            <mat-icon class="ml-1 text-sm"
                                *ngIf="currentSortKey === 'type' && currentSortDirection === 'desc'"
                                [ngClass]="{'text-blue-500': currentSortKey === 'type' && currentSortDirection === 'desc'}">
                                arrow_downward
                            </mat-icon>
                        </span>
                    </div>
                    <div class="w-2/12 flex justify-center items-center">
                        <span class="font-semibold">Action</span>
                    </div>
                </div>
                <div *ngFor="let prod of filteredResultSets; let i = index;" class="px-2 py-1 border-b flex flex-col">
                    <div class="flex">
                        <div class="w-1/12 flex items-center justify-center">
                            <input type="checkbox" [(ngModel)]="prod.selected" (ngModelChange)="updateSelectAllState()"
                                [disabled]="prod.type === 'auto'" class="form-checkbox h-4 w-4 text-blue-500">
                        </div>
                        <div class="w-9/12 flex justify-center items-center" (click)="toggleDetails(i,prod)">
                            <span class="text-primary cursor-pointer">{{ prod.title }}:{{ prod.value }}</span>
                        </div>
                        <div class="w-9/12 flex justify-center items-center" *ngIf="cs.currentScope === '*'">
                            <span>{{ prod.company_name }}</span>
                        </div>
                        <div class="w-9/12 flex justify-center items-center">
                            <span
                                class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide"
                                [ngClass]="{'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': prod.type === 'auto',
                                                'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': prod.type === 'manual'}">
                                <span class="leading-relaxed whitespace-nowrap">{{prod.type | uppercase}}</span>
                            </span>
                        </div>
                        <div (click)="deleteConfirm(prod)" class="w-2/12 flex justify-center items-center">
                            <button [disabled]="hasSelected1() || isAllSelected1() || prod.type === 'auto'"
                                [matTooltip]="prod.type === 'auto' ? 'Auto tag cannot be deleted' : null"
                                class="p-2 hover:bg-gray-200 rounded-full transition-colors">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-1/2 ml-4" *ngIf="expandedIndex !== null">
        <button class="absolute mb-8 right-0" (click)="expandedIndex = null" aria-label="Clear" mat-icon-button
            matTooltip="Close">
            <mat-icon>close</mat-icon>
        </button>
        <div class="mb-6">
            <stable [sTableOptions]="assetinfoTableOptions" *ngIf="assetinfoTableOptions.tableOptions" class="w-full">
            </stable>
        </div>
    </div>
</div>