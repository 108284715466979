<mat-tab-group #matTabGroup [selectedIndex]="selectedIndex" (selectedIndexChange)="tabFn($event);"
    (selectedIndexChange)="selectedIndex = $event" class="w-full min-h-[800px] overflow-auto" mat-stretch-tabs="false"
    [animationDuration]="'0'">
    <mat-tab label="Create Tags">
        <ng-template mat-tab-label>
            <span id="CreateTags"> Create Tags </span>
        </ng-template>
        <ng-template matTabContent>
            <stable aria-label="Rules" role="table" [sTableOptions]="tagTableOptions"
                *ngIf="tagTableOptions.tableOptions" (actionCallback)="tagactionCall($event)"
                (addCallback)="addTagData()"></stable>
        </ng-template>
    </mat-tab>
    <mat-tab label="Create Rules">
        <ng-template mat-tab-label>
            <span id="CreateRules"> Create Rules </span>
        </ng-template>
        <ng-template matTabContent>
            <div class="-mx-4">
                <stable aria-label="Rules" role="table" [sTableOptions]="tagRuleTableOptions"
                    *ngIf="tagRuleTableOptions.tableOptions" (actionCallback)="ruleactionCall($event)"
                    (addCallback)="addTagRuleData()"></stable>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<s-modal id="createTags">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium"> <span *ngIf="!currentTag.id">Add</span> <span
                    *ngIf="currentTag.id">Update</span> Tag</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('createTags')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form class="example-form px-4" #tagNgForm="ngForm" *ngIf="tag_details">
                <div class="w-full flex flex-row items-center gap-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Name</mat-label>
                        <input matInput [placeholder]="'Enter Name'" name="name" required autocomplete="off"
                            [(ngModel)]="tag_details.name">
                    </mat-form-field>
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Value</mat-label>
                        <input matInput [placeholder]="'Enter Value'" autocomplete="off" name="Value"
                            required [(ngModel)]="tag_details.value">
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="_mS.close('createTags')">
                        Cancel
                    </button>
                    <button type="button" id="saveButton" (click)="saveTags()" [disabled]="tagNgForm.invalid"
                        appPreventMultiClick class="mr-2" mat-flat-button color="primary">
                        <span *ngIf="!currentTag.id">Save</span> <span *ngIf="currentTag.id">Update</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>


<s-modal id="createTagsRule">
    <mat-card class="modal w-[53vw]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium"> <span *ngIf="!currentRuleTag.id">Add</span> <span
                    *ngIf="currentRuleTag.id">Update</span> Tag Rule</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('createTagsRule')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form #tagRuleNgForm="ngForm" *ngIf="tagRules">
                <div class="flex flex-row items-center gap-2 mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Name</mat-label>
                        <input matInput [placeholder]="'Enter Name'" name="Rulename" required autocomplete="off"
                            [(ngModel)]="tagRules.name">
                    </mat-form-field>
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Risk Score</mat-label>
                        <input matInput type="number" [placeholder]="'Enter Risk Score'" autocomplete="off" name="Score"
                            required [(ngModel)]="tagRules.risk_score">
                    </mat-form-field>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Collection</mat-label>
                        <mat-select (selectionChange)="changeCollection($event)" name="RuleCollection" required
                            [(ngModel)]="tagRules.collection">
                            <ng-container *ngFor="let data of collections;">
                                <mat-option [value]="data.value"> {{data.name}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Description</mat-label>
                        <textarea matInput rows="2" placeholder="Description" [(ngModel)]="tagRules.description"
                            name="RuleDescription"></textarea>
                    </mat-form-field>
                </div>
                <div class="flex flex-wrap flex-auto items-center mt-2 rounded-2xl" *ngIf="sColumns && sColumns.length">
                    <div class="text-lg font-medium tracking-tight leading-6 truncate mt-4">Rules</div>
                    <ejs-querybuilder #querybuilder class="row" width="100%">
                        <e-columns>
                            <e-column *ngFor="let c of sColumns; let i = index" [field]="c.field" [label]="c.label"
                                [operators]="customOperators" [type]="c.type"></e-column>
                        </e-columns>
                    </ejs-querybuilder>
                </div>
                <div class="w-full mb-2 mt-4">
                    <div class="flex flex-row gap-2 items-center flex-wrap">
                        <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
                            <mat-label>Select Tags</mat-label>
                            <mat-select name="SelectedTags" id="Include Tags" [(ngModel)]="selected_tag"
                                (openedChange)="closeCurrentTag($event)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="tagFilterCtrl" [placeholderLabel]="'Search Tags'"
                                        [noEntriesFoundLabel]="'No matching tag found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let tag of filterTags" [value]="tag">{{tag.name}}:
                                    {{tag.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button (click)="addSelectedTag()" type="button" class="mt-4" mat-icon-button=""
                            [disabled]="!selected_tag" matTooltip="Add Tag">
                            <mat-icon [ngClass]="selected_tag ? 'text-blue-500' : ''"
                                [svgIcon]="'heroicons_outline:plus-circle'">
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="tagRules.tags && tagRules.tags.length;">
                    <div class="font-semibold mt-2">Selected Tags</div>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="flex flex-row items-center gap-2 flex-wrap">
                        <ng-container *ngFor="let tag of tagRules.tags let i = index;">
                            <div class="flex items-start justify-start">
                                <span
                                    class="bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{tag.name}}:
                                    {{tag.value}}
                                    <button type="button" (click)="tagRules.tags.splice(i, 1)"
                                        class="inline-flex items-center p-1 ms-2 text-sm text-purple-400 bg-transparent rounded-sm hover:bg-purple-200 hover:text-purple-900 dark:hover:bg-purple-800 dark:hover:text-purple-300"
                                        data-dismiss-target="#badge-dismiss-purple" aria-label="Remove">
                                        <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Remove Tag</span>
                                    </button>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="flex items-center mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="_mS.close('createTagsRule');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                        (click)="saveTagRule()"
                        [disabled]="tagRuleNgForm.invalid || !tagRules || (!tagRules.tags || !tagRules.tags.length)"
                        [matTooltip]="'Save'">
                        Save
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>