import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/reports'
    { path: '', pathMatch: 'full', redirectTo: 'metrics' },

    // Redirect signed-in user to the '/reports'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    /*{
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'overview',
    },*/

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'support-sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'new-user',
                loadChildren: () =>
                    import('app/modules/auth/new-user/new-user.routes'),
            },
            {
                path: 'maintenance',
                loadChildren: () =>
                    import('app/modules/shared/maintenance/maintenance.routes'),
            },
            {
                path: 'unauthorized',
                loadChildren: () =>
                    import('app/modules/auth/unauthorized/unauthorized.routes'),
            },
            {
                path: 'login',
                loadChildren: () => import('app/modules/oauth2/oauth2-routing.module').then(m => m.Oauth2RoutingModule)
            },
            // Commenting this actual Signup Because we need to handle the signup for OnPrem where we are not keeping any regions etcc.. 
            // So I created a new component and making the routing to it
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
            // {
            //     path: 'sign-up',
            //     loadChildren: () =>
            //         import('app/new-signup/new-signup.routes'),
            // },
            {
                path: 'success',
                loadChildren: () =>
                    import('app/modules/auth/auth-grant/auth-grant.routes'),
            },
            {
                path: 'auth-grant',
                loadChildren: () =>
                    import('app/modules/auth/auth-grant/auth-grant.routes'),
            },
            {
                path: 'approveSuppression',
                loadChildren: () =>
                    import('app/modules/shared/approve-reject-suppression/approve-reject-suppression.routes'),
            },
            {
                path: 'rejectSuppression',
                loadChildren: () =>
                    import('app/modules/shared/approve-reject-suppression/approve-reject-suppression.routes'),
            },
            {
                path: 'custom-sign-in',
                loadChildren: () =>
                    import('app/modules/auth/custom-login/custom-login.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
        ],
    },
    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            /*{
                path: 'overview',
                data: { breadcrumb: { alias: 'Overview' } },
                loadChildren: () =>
                    import('app/modules/shared/summary-view/summary-view.routes'),
                runGuardsAndResolvers: 'always'
            },*/
            {
                path: 'overview',
                data: { breadcrumb: { alias: 'Dashboard' } },
                loadChildren: () =>
                    import('app/modules/shared/dashboard/dashboard.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'custom_dashboard',
                data: { breadcrumb: { alias: 'Custom Dashboard' } },
                loadChildren: () =>
                    import('app/modules/shared/custom-dashboard/custom-dashboard.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'metrics',
                data: { breadcrumb: { alias: 'Metrics' } },
                loadChildren: () =>
                    import('app/modules/shared/summary-view/summary-view.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'agents',
                data: { breadcrumb: { alias: 'Agents' } },
                loadChildren: () =>
                    import('app/modules/admin/companies/agents/agents.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'dashboard',
                data: { breadcrumb: { alias: 'Dashboard' } },
                loadChildren: () =>
                    import('app/modules/shared/dashboard/dashboard.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'vulnerabilities',
                data: { breadcrumb: { alias: 'Vulnerabilities' } },
                loadChildren: () =>
                    import('app/modules/admin/vulnerabilities/overview/overview.routes'),
            },
            {
                path: 'registry-vulnerabilities',
                data: { breadcrumb: { alias: 'Registry Vulnerabilities' } },
                loadChildren: () =>
                    import('app/modules/admin/vulnerabilities/registry-vulnerability/registry-vulnerability.routes'),
            },
            {
                path: 'application-vulnerabilities',
                data: { breadcrumb: { alias: 'Application Vulnerabilities' } },
                loadChildren: () =>
                    import(
                        'app/modules/admin/app-vulnerabilities/app-vulnerabilities.routes'
                    ),
            },
            {
                path: 'os-vulnerabilities',
                data: { breadcrumb: { alias: 'OS Vulnerabilities' } },
                loadChildren: () =>
                    import(
                        'app/modules/admin/vulnerabilities/os-vulnerabilities/os-vulnerabilities.routes'
                    ),
            },
            {
                path: 'vulnerability-by-os',
                data: { breadcrumb: { alias: 'Vulnerability by OS' } },
                loadChildren: () =>
                    import(
                        'app/modules/admin/vulnerabilities/vulnerability-by-os/vulnerability-by-os.routes'
                    ),
            },
            {
                path: 'network-scan-findings',
                data: { breadcrumb: { alias: 'Network Scan Findings' } },
                loadChildren: () =>
                    import(
                        'app/modules/shared/network-scan-findings/network-scan-findings.routes'
                    ),
            },
            {
                path: 'pii',
                data: { breadcrumb: { alias: 'PII' } },
                loadChildren: () =>
                    import('app/modules/shared/scan/pii-scan/pii-scan.routes'),
            },
            {
                path: 'patch-management',
                data: { breadcrumb: { alias: 'Patch Management' } },
                loadChildren: () =>
                    import('app/modules/shared/patching/patching.routes'),
            },
            {
                path: 'assessment-scan',
                data: { breadcrumb: { alias: 'Assessment Scan' } },
                loadChildren: () =>
                    import('app/modules/shared/assessment-scan/assessment-scan.routes'),
            },
            {
                path: 'onboarding',
                data: { breadcrumb: { alias: 'Company Onboarding' } },
                loadChildren: () =>
                    import('app/modules/admin/companies/company-onboarding/company-onboarding.routes'),
            },
            {
                path: 'attack-surface-mapper',
                data: { breadcrumb: { alias: 'Attack Surface Mapper' } },
                loadChildren: () =>
                    import('app/modules/admin/attack-surface-mapper/attack-surface-mapper.routes'),
            },
            {
                path: 'company',
                data: { breadcrumb: { alias: 'Company' } },
                loadChildren: () =>
                    import('app/modules/admin/company-overview/company-overview.routes'),
            },
            {
                path: 'problems-solutions',
                data: { breadcrumb: { alias: 'Problems' } },
                loadChildren: () =>
                    import('app/modules/admin/problems/problems.routes'),
            },
            {
                path: 'report-builder',
                data: { breadcrumb: { alias: 'Report Builder' } },
                loadChildren: () =>
                    import('app/modules/shared/report-builder/report-builder.routes'),
            },
            {
                path: 'report-customization',
                data: { breadcrumb: { alias: 'Report Customization' } },
                loadChildren: () =>
                    import('app/modules/shared/report-customization/report-customization.routes'),
            },
            /*{
                path: 'integrations',
                data: { breadcrumb: { alias: 'Integrations' } },
                loadChildren: () =>
                    import('app/modules/admin/integrations/integrations.routes'),
            },*/
            {
                path: 'network-scan-findings',
                data: { breadcrumb: { alias: 'Network Scan Findings' } },
                loadChildren: () =>
                    import(
                        'app/modules/shared/network-scan-findings/network-scan-findings.routes'
                    ),
            },

            {
                path: 'standard-reports',
                data: { breadcrumb: { alias: 'Standard Reports' } },
                loadChildren: () =>
                    import('app/modules/shared/standard-reports/standard-reports.routes'),
            },
            {
                path: 'report-builder',
                data: { breadcrumb: { alias: 'Report Builder' } },
                loadChildren: () =>
                    import('app/modules/shared/report-builder/report-builder.routes'),
            },
            {
                path: 'active-directory',
                data: { breadcrumb: { alias: 'Active Directory' } },
                children: [
                    {
                        path: 'problems-overview',
                        data: { breadcrumb: { alias: 'Overview' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/overview/overview.routes'
                            ),
                    },
                    {
                        path: 'summary',
                        data: { breadcrumb: { alias: 'Summary' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/summary/summary.routes'
                            ),
                    },
                    {
                        path: 'azure-summary',
                        data: { breadcrumb: { alias: 'Microsoft Entra ID Summary' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/azure-summary/azure-summary.routes'
                            ),
                    },
                    {
                        path: 'ad-details',
                        data: { breadcrumb: { alias: 'Active Directory Details' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/ad-details/ad-details.routes'
                            ),
                    },
                    {
                        path: 'ad-audit',
                        data: { breadcrumb: { alias: 'Audit' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/ad-audit/ad-audit.routes'
                            ),
                    },
                    {
                        path: 'audit-log',
                        data: { breadcrumb: { alias: 'Audit-Log' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/audit-log/audit-log.routes'
                            ),
                    },
                    {
                        path: 'azure',
                        data: { breadcrumb: { alias: 'Microsoft Entra ID Details' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/azure-ad-details/azure-ad-details.routes'
                            ),
                    },
                    {
                        path: 'microsoft',
                        data: { breadcrumb: { alias: 'Microsoft Secure Score' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/microsoft-secure-score/microsoft-secure-score.routes'
                            ),
                    },
                    {
                        path: 'm365-risk-summary',
                        data: { breadcrumb: { alias: 'Microsoft 365 Audit Report' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/m365-risk-summary/m365-risk-summary.routes'
                            ),
                    },
                ],
            },
            {
                path: 'asset',
                data: { breadcrumb: { alias: 'Assets' } },
                children: [
                    {
                        path: 'assets',
                        data: { breadcrumb: { alias: 'Assets', is_deprecated: false, asset_type: 'discovered' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/assets-view/assets-view.routes'
                            ),
                    },
                    {
                        path: 'firewalls',
                        data: { breadcrumb: { alias: 'Firewalls', is_deprecated: false } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/firewalls/firewalls.routes'
                            ),
                    },
                    {
                        path: 'other-assets',
                        data: { breadcrumb: { alias: 'Other Assets', is_deprecated: false, asset_type: 'other_asset' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/assets-view/assets-view.routes'
                            ),
                    },
                    {
                        path: 'problems',
                        data: { breadcrumb: { alias: 'Problems' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/problems/problems.routes'
                            ),
                    },
                    {
                        path: 'remediation-plan',
                        data: { breadcrumb: { alias: 'Solutions' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/remediation-plan/remediation-plan.routes'
                            ),
                    },
                    {
                        path: 'patch-compliance',
                        data: { breadcrumb: { alias: 'Pending OS Patches' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/patch-compliance/patch-compliance.routes'
                            ),
                    },
                    {
                        path: 'ports',
                        data: { breadcrumb: { alias: 'Ports' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ports/ports.routes'
                            ),
                    },
                    {
                        path: 'external-assets',
                        data: { breadcrumb: { alias: 'External Assets' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/external-scan/external-scan.routes'
                            ),
                    },
                    {
                        path: 'certificates',
                        data: { breadcrumb: { alias: 'Certificates' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/all-certs/all-certs.routes'
                            ),
                    },
                    {
                        path: 'deprecated-assets',
                        data: { breadcrumb: { alias: 'Deprecated Assets', is_deprecated: true, asset_type: 'discovered' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/assets-view/assets-view.routes'
                            ),
                    },
                    {
                        path: 'application-baseline-results',
                        data: { breadcrumb: { alias: 'Application Baseline Results' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/application-baseline/results/results.routes'
                            ),
                    },
                    // {
                    //     path: 'ticketing-template',
                    //     data: { breadcrumb: { alias: 'Ticketing Template' } },
                    //     loadChildren: () =>
                    //         import('app/modules/admin/ticketing-template/ticketing-template.routes'),
                    // },
                ],
            },
            {
                path: 'new-asset',
                data: { breadcrumb: { alias: 'Assets' } },
                children: [
                    {
                        path: 'assets',
                        data: { breadcrumb: { alias: 'Assets', is_deprecated: false, asset_type: 'discovered' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/new-assets/new-assets.route'
                            ),
                    },
                ],
            },
            {
                path: 'compliance',
                data: { breadcrumb: { alias: 'Compliance' } },
                children: [
                    {
                        path: 'compliance-check-master',
                        data: { breadcrumb: { alias: 'Compliance Check Master' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-check-master/compliance-check-master.routes'
                            ),
                    },
                    {
                        path: 'compliance-standards',
                        data: { breadcrumb: { alias: 'Compliance Standards' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-overview/compliance-overview.routes'
                            ),
                    },
                    {
                        path: 'compliance-report',
                        data: { breadcrumb: { alias: 'Compliance Report' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-report/compliance-report.routes'
                            ),
                    },
                    {
                        path: 'compliance-assessment',
                        data: { breadcrumb: { alias: 'Compliance Assessment' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/assessment/asseessment.routes'
                            ),
                    },
                    {
                        path: 'compliance-remediation',
                        data: { breadcrumb: { alias: 'Compliance Remediation' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-remediation/compliance-remediation.routes'
                            ),
                    }
                ],
            },
            {
                path: 'jobs',
                data: { breadcrumb: { alias: 'Jobs' } },
                loadChildren: () =>
                    import('app/modules/shared/new-jobs/new-jobs.routes'),
            },
            {
                path: 'approve_suppression',
                data: { breadcrumb: { alias: 'Approve Suppression' } },
                loadChildren: () =>
                    import('app/modules/shared/approve-reject-suppression/approve-reject-suppression.routes'),
            },
            {
                path: 'settings',
                data: { breadcrumb: { alias: 'Settings' } },
                loadChildren: () =>
                    import('app/modules/shared/settings-view/settings-view.routes'),
            },
            {
                path: 'scheduler',
                data: { breadcrumb: { alias: 'Scheduler' } },
                loadChildren: () =>
                    import('app/modules/admin/scheduler/scheduler.routes'),
            },
            {
                path: 'company-settings',
                data: { breadcrumb: { alias: 'Company Settings' } },
                loadChildren: () =>
                    import('app/modules/admin/companies/company-level-settings/company-level-settings.routes'),
            },
            {
                path: 'global-settings',
                data: { breadcrumb: { alias: 'Global Settings' } },
                loadChildren: () =>
                    import('app/modules/admin/global-level-settings/global-level-settings.routes'),
            },
            {
                path: 'ticketing-template',
                data: { breadcrumb: { alias: 'Ticketing Template' } },
                loadChildren: () =>
                    import('app/modules/admin/ticketing-template/ticketing-template.routes'),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'overview'
    }
];
