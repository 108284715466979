<div class="flex-auto  -mt-px pt-4 sm:pt-6">
  <div class="w-full  mx-auto">
    <!-- Tabs -->
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndex = $event"
      class="sm:px-2" mat-stretch-tabs="false" [animationDuration]="'0'">
      <mat-tab label="Mapped Discovery Settings">
        <ng-template matTabContent>
          <stable class="" aria-label="Mapped Discovery Settings" (addCallback)="addTableData()"
            [id]="discTableOptions.tableOptions.id" (actionCallback)="actioncallbackdisc($event)"
            [sTableOptions]="discTableOptions"></stable>
        </ng-template>
      </mat-tab>
      <mat-tab label="Mapped Credentials">
        <ng-template matTabContent>
          <stable class="" aria-label="Mapped Credentials"
            [id]="credTableOptions.tableOptions.id" (actionCallback)="actioncallbackcred($event)"
            [sTableOptions]="credTableOptions"></stable>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
        