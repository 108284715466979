<div class="py-2 grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2 pl-4 pr-2" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <div class="flex flex-row items-center bg-card ring-1 rounded" *ngFor="let firewall of firewallPolicy">
        <div class="flex flex-wrap px-3">
            <i class="fas fa-fire text-2xl"></i>
        </div>
        <div class="flex flex-col w-full  px-2 py-2">
            <div class="flex flex-row justify-between flex-wrap">
                <div class="text-sm font-semibold">{{firewall.policy_type}}</div>
                <div class="flex flex-wrap items-center">
                    <div *ngIf="firewall.policy_status === 1"
                        class="py-1 px-2 rounded-sm text-sm/[60%] font-semibold
                         text-green-900 bg-green-100 dark:text-green-50 dark:bg-green-500">
                        Enabled </div>
                    <div *ngIf="firewall.policy_status === 0"
                        class="py-1 px-2 rounded-sm text-sm/[60%] font-semibold
                         text-blue-900 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                        Disabled </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center text-xs font-mono w-full break-all" matTooltip="Path">
                {{firewall.path}}
            </div>
        </div>
    </div>
</div>
<app-loader [loadingCon]="loadingCon" *ngIf="!firewallPolicy || firewallPolicy.length < 0"></app-loader>

