import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation, WritableSignal, signal } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { UserService } from 'app/core/user/user.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    _agent_installation: any = { 'category': 'global', 'sub_category': 'settings', 'name': 'agent_installation', 'value': { value: true } };
    hasAdminRole: boolean = false;
    isAgentInstallationotification: boolean;
    notifications: any[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    msspTenant: WritableSignal<any> = signal({});


    adminNotification = {
        icon: 'heroicons_outline:bell-alert',
        title: 'Modifications in agent installation script',
        description: `ConnectSecure has a new agent fix coming out that provides extra security and allows partners to track agent installations.
        This update is scheduled to Go Live on September 1, 2024. We’re providing this information in advance to help you make any necessary changes at your end.
        Please note, this update will have NO impact on existing installed agents. 
        However, the modification to the agent installation script will be needed for New agent installations using RMM tool.`,
        acknowledged: false,
        docsLink: 'https://cybercns.atlassian.net/wiki/spaces/CVB/pages/2103410891/How+To+Install+ConnectSecure+V4+Agent'
    };

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        public _bs: BaseRequestService,
        private _toast: MyToastrService,
        private _ls: LoaderService,
        public confirmDialog: FuseConfirmationService,
        private _cs: CommonService,
        private uService: UserService,
    ) {
        this.getMsspRrequest();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.hasAdminRole = (this.uService._user_roles.indexOf('admin') !== -1 || this._bs.user().email === 'support@connectsecure.com') ? true : false;
        this.gettingmessagenotification()
    }

    gettingmessagenotification(): void {
        const params = {
            condition: `company_id IS NULL and category='global' and name='agent_installation'`,
            skip: 0,
            limit: 1
        };
        this._bs.doRequest("/r/company/settings", "get", null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response?.status && response?.data?.length > 0) {
                    const setting = response.data[0];
                    const isAgentInstallationEnabled = setting.value?.value === true;

                    if (isAgentInstallationEnabled) {
                        this.isAgentInstallationotification = true
                    }
                } else {
                    console.log("No data found or error in fetching data.");
                }
                this._changeDetectorRef.detectChanges();
            });
    }


    async getMsspRrequest() {
        const params: any = {
            condition: `status IN ('pending', 'Approved')`,
            skip: 0,
            limit: 2,
            order_by: 'updated desc'
        }
        const response = await this._bs.doRequest('/r/company/mssp_request', 'get', null, params).toPromise();
        if (response.status) {
            const pending = response.data.filter((x: any) => x.status === 'pending');
            const approved = response.data.filter((x: any) => x.status === 'Approved');
            this._bs.mssp_name = '';
            if (approved.length && !pending.length) {
                this._bs.mssp_name = approved[0].mssp_tenant_name;
            }
            if (response.status && pending.length) {
                this.msspTenant.set({
                    mssp_tenant_id: pending[0].mssp_tenant_id.toString(),
                    details: [{
                        icon: 'heroicons_outline:bell-alert',
                        title: 'MSSP Approval',
                        description: `Request to approve/reject adding your tenant under the Managed Security Service Provider (MSSP) - ${response.data[0].mssp_tenant_name}.`
                    }]
                });
                this._changeDetectorRef.detectChanges();
            } else {
                this.msspTenant.set({
                    mssp_tenant_id: null,
                    details: []
                });
            }
        } else {
            this.msspTenant.set({
                mssp_tenant_id: null,
                details: []
            });
        }

    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }




    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }



    async actionChange(status: any) {


        const confirmation = this.confirmDialog.open({
            title: 'MSSP Request',
            message: (status === 'Approved') ? `Are you sure you want to Approve?` : `Are you sure you want to Reject?`,
            actions: {
                confirm: {
                    label: 'Yes', color: "primary"
                }
            },
            icon: {
                show: true,
                name: 'heroicons_outline:information-circle',
                color: 'primary'
            },
            isReason: true,
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._ls.display(true);
                const request: any = {
                    mssp_tenant_id: this.msspTenant().mssp_tenant_id,
                    action: status,
                    reason: this._cs.reason
                }
                try {
                    this._bs.doRequest("/w/company/update_mssp_request", "post", request)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((response: any) => {
                            if (response.status) {
                                this._ls.display(false);
                                this._toast.sToast('success', (status === 'Approved') ? `MSSP request approved.` : `MSSP request rejected.`);
                                this._overlayRef.detach();
                                this._cs.reason = null;
                                this.getMsspRrequest();
                            } else {
                                this._ls.display(false);
                                const data = (response.message) ? response.message : response.data;
                                this._toast.sToast('error', data);
                            }
                        })

                } catch (error) {
                    // Handle errors here
                    throw error;
                }
            }
        })
    }

    onAcknowledgeChange(isChecked: boolean): void {
        this.adminNotification.acknowledged = isChecked;
        this._changeDetectorRef.detectChanges();
    }

    acknowledgeAdminNotification(): void {
        if (this.adminNotification.acknowledged) {
            this.saveSettings({ _agent_installation: this._agent_installation.value });
        }
    }

    saveSettings(value: any): void {
        Object.keys(value).forEach((key: string) => {
            const eventCopy: any = cloneDeep(this[key]);
            eventCopy.value = value[key];

            const method = eventCopy.id ? 'patch' : 'post';
            const msg = eventCopy.id ? 'Updated' : 'Saved';

            eventCopy.company_id = null;

            const request: any = { data: eventCopy };
            if (eventCopy.id) {
                request.id = eventCopy.id;
            }

            this._bs.doRequest('/w/company/settings', method, request)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        if (method === 'post') {
                            this[key].id = parseInt(result.id);
                        }
                        setTimeout(() => {
                            this._ls.display(false);
                            this._toast.sToast('success', `${msg} successfully!`);
                            this.gettingmessagenotification()
                            this._overlayRef.detach();
                            this._changeDetectorRef.markForCheck();
                        }, 3000);
                    } else {
                        const errorMessage = result.message ? result.message : result.data;
                        this._toast.sToast('error', errorMessage);
                        setTimeout(() => {
                            this._ls.display(false);
                        }, 3000);
                    }
                });
        });
    }
}
