
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { UserService } from 'app/core/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import { TableComponent } from "../../../shared/table/table.component";
import { BaseRequestService } from 'app/_services/base.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'app-mapping',
  standalone: true,
  imports: [CommonModule, TableComponent, MatButtonModule, MatButtonToggleModule, MatRippleModule, MatIconModule, MatMenuModule, MatTableModule, MatTabsModule],
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})
export class MappingComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  selectedIndex: number = 0
  agentdisco: any = {
    formElements: [
      {
        "name": "agent_id",
        "label": "Agent",
        "description": "Select Agent",
        "example": "Select Agent",
        "key": "agent_id",
        "required": true,
        "options": [],
        "schema": {
          "type": "dropdown",
        },
        "type": "dropdown"
      },
      {
        "name": "discovery_settings_id",
        "label": "Discovery Settings",
        "description": "Discovery Settings",
        "example": "Select Discovery Settings",
        "required": true,
        "callback": true,
        "key": "discovery_settings_id",
        "dKey": 'label',
        "dValue": 'value',
        "type": "dropdown",
        "options": [],
        "multiple": false,
        "schema": {
          "type": "select",
        }
      },
    ],
    saveBtn: 'Save',
    closeBtn: 'Cancel',
    valueOutput: {
      name: '',
      address_type: '',
      address: ''
    }
  }
  loadtable = false
  agentcred = {
    formElements: [
      {
        "name": "agent_id",
        "label": "Agent",
        "description": "Select Agent",
        "example": "Select Agent",
        "key": "agent_id",
        "required": true,
        "options": [],
        "schema": {
          "type": "dropdown",
        },
        "type": "dropdown"
      },
      {
        "name": "credentials_id",
        "label": "Credentials",
        "description": "Credentials",
        "example": "Select Credentials",
        "required": true,
        "callback": true,
        "key": "credentials_id",
        "dKey": 'label',
        "dValue": 'value',
        "type": "dropdown",
        "options": [],
        "multiple": false,
        "schema": {
          "type": "select",
        }
      },
    ],
    saveBtn: 'Save',
    closeBtn: 'Cancel',
    valueOutput: {
      name: '',
      address_type: '',
      address: ''
    }
  }
  discTableOptions: any = {
    columns: [
      { "header": "Discovery Settings Name", "columnDef": "name", "cType": "number", "filter": "", "cell": "(element: any) => ${element.name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Host Name", "columnDef": "host_name", "cType": "string", "filter": "", "cell": "(element: any) => ${element.host_name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false, "clipboard":true},
      { "header": "Agent ID", "columnDef": "agent_id", "cType": "number", "filter": "", "cell": "(element: any) => ${element.agent_id}", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Company ID", "columnDef": "company_id", "cType": "number", "filter": "", "cell": "(element: any) => ${element.company_id}", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Company Name", "columnDef": "company_name", "cType": "number", "filter": "", "cell": "(element: any) => ${element.company_name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ], sortOptions: { active: 'created', direction: 'desc' },
    _pageData: [],
    tableOptions: {
      title: 'Mapped Discovery Settings',
      isServerSide: false,
      selectText: 'Mapped Discovery Settings',
      loading: true,
      floatingFilter: true,
      rowSelection: false,
      showAction: true,
      actionMenuItems: [
        {
          text: 'Delete',
          icon: 'delete',
          callback: 'editFunction',
          hideLocal: false,
          isGlobal: false,
        }],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showFilter: true,
      showTagFilter: false,
      showhideList: true,
      refreshData: true,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/r/company/agent_discovery_settings',
        condition: '',
      }, id: 'agent_discoverysettings_mapping'

    },
    changeValue: new Subject<any>(),
  }
  credTableOptions: any = {
    columns: [
      { "header": "Credential Name", "columnDef": "name", "cType": "number", "filter": "", "cell": "(element: any) => ${element.name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Host Name", "columnDef": "host_name", "cType": "string", "filter": "", "cell": "(element: any) => ${element.host_name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false, "clipboard":true },
      { "header": "Agent ID", "columnDef": "agent_id", "cType": "number", "filter": "", "cell": "(element: any) => ${element.agent_id}", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Company ID", "columnDef": "company_id", "cType": "number", "filter": "", "cell": "(element: any) => ${element.company_id}", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Company Name", "columnDef": "company_name", "cType": "number", "filter": "", "cell": "(element: any) => ${element.company_name}", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ], sortOptions: { active: 'created', direction: 'desc' },
    _pageData: [],
    tableOptions: {
      title: 'Mapped Credentials',
      isServerSide: false,
      selectText: 'Mapped Credentials',
      loading: true,
      floatingFilter: true,
      rowSelection: false,
      showAction: true,
      actionMenuItems: [
        {
          text: 'Delete',
          id: 'delete',
          icon: 'delete',
          callback: 'editFunction',
          hideLocal: false,
          isGlobal: false,
        }],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showFilter: true,
      showTagFilter: false,
      showhideList: true,
      refreshData: true,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      id: 'agent_credentials_mapping',
      serverSide: {
        url: '/r/company/agent_credentials',
        condition: '',
      }
    },
    changeValue: new Subject<any>(),

  }
  constructor(public toast: MyToastrService, private _bs: BaseRequestService, private uS: UserService,
    private _changeDetectorRef: ChangeDetectorRef, public confirmDialog: FuseConfirmationService,) {

  }

  ngOnInit(): void {
    this.uS.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  actioncallbackdisc(data) {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this agent discovery settings mapping ?',
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        const reqParams = {
          setting_type: 'discovery_settings',
          company_id: data.row.company_id,
          agent_id: data.row.agent_id,
          id: data.row.id
        }
        this._bs.doRequest(`/d/company/delete_cred_discovery_mapping`, 'post', reqParams)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            if (res.status) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => this.discTableOptions.changeValue.next(true));
            } else {
              const data = (res.message)? res.message : res.data;
this.toast.sToast('error', data);
            }
          });
      }
    })
  }

  actioncallbackcred(data) {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this agent credentials mapping ?',
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        const reqParams = {
          setting_type: 'credentials',
          company_id: data.row.company_id,
          agent_id: data.row.agent_id,
          id: data.row.id
        }
        this._bs.doRequest(`/d/company/delete_cred_discovery_mapping`, 'post', reqParams)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            if (res.status) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => this.credTableOptions.changeValue.next(true));
            } else {
              const data = (res.message)? res.message : res.data;
this.toast.sToast('error', data);
            }
          });
      }
    })
  }
}
