import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSidenavModule, MatDrawer } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { FuseScrollbarDirective } from "@fuse/directives/scrollbar";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { TranslocoModule } from "@ngneat/transloco";
import { BaseRequestService } from "app/_services/base.service";
import { CommonService } from "app/_services/common.service";
import { LoaderService } from "app/_services/loader.service";
import { ModalService } from "app/_services/modal.service";
import { MyToastrService } from "app/_services/toastr.service";
import { DynamicSettingsService } from "app/layout/common/dynamic-settings/dynamic-settings.service";
import { MaterialModule } from "app/material.module";
import { Subject, Subscription, takeUntil } from "rxjs";
import { TableComponent } from "../../table/table.component";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@Component({
  selector: "app-patching-jobs",
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    MatSidenavModule,
    ReactiveFormsModule,
    FormsModule,
    FuseScrollbarDirective,
    MaterialModule,
    TableComponent,
    AppFilterPipeModule,
  ],
  templateUrl: "./patching-jobs.component.html",
  styleUrls: ["./patching-jobs.component.scss"],
})
export class PatchingJobsComponent implements OnInit, OnDestroy, AfterViewInit {
  cView: any = "jobs";
  @ViewChild("drawer") drawer: MatDrawer;
  @Input() jobCondition: any;
  @Input() title: any;
  @Input() isShowHyperLink: any;
  @Input() actionMenuItems: any = [];
  searchFilter: any = "";
  checkCompleted: boolean = false;
  checkInProcess: boolean = false;
  checkFailed: boolean = false;
  drawerPosition: "start" | "end" = "end";
  drawerMode: "over" | "side" = "over";
  drawerOpened: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  currentData: any = {};
  currentDataCheck: boolean = false;
  reportCurrentDataCheck: boolean = false;
  reportCurrentData: any = [];
  showtable: boolean = false;
  objectKeys = Object.keys;
  scanTableOptions: any = {};
  suppressiontable: any = {};
  tableData: any = {};
  pObj: any = {};
  private action: Subscription;
  jobEnum: any = {
    pii: `type='PIISCAN'`,
    assets: `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN', 'REMOTEINSTALL', 'COMPLIANCE')`,
    scheduler: `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN')`,
    azure: `type='AZUREADSYNC'`,
    firewalls: `type='FIREWALLSCAN'`,
    "external-assets": `type='EXTERNALSCAN'`,
    "attack-surface-mapper": `type='ATTACKSURFACESCAN'`,
    "standard-reports": `type='StandardReports'`,
    "report-builder": `type='CustomReports'`,
    "compliance-standards": `type='COMPLIANCE'`,
    "compliance-check-master": `type='COMPLIANCE'`,
  };

  /**
   * Constructor
   */
  constructor(
    public modalService: ModalService,
    private _bs: BaseRequestService,
    public cs: CommonService,
    private ls: LoaderService,
    private toast: MyToastrService,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ds: DynamicSettingsService,
    public confirmDialog: FuseConfirmationService
  ) {}

  ngOnInit(): void {
    this.initTable();
    setTimeout(() => {
      this._changeDetectorRef.detectChanges();
    }, 3000);
  }
  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }
  initTable(): void {
    this.scanTableOptions = {
      columns: [
        {
          header: "Created",
          columnDef: "created",
          filter: "utcToLocale",
          cType: "date",
          isHyperlink: true,
          cell: "(element: any) => ${element.created}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true,
        },
        {
          header: "Updated",
          columnDef: "updated",
          filter: "utcToLocale",
          isHyperlink: true,
          cell: "(element: any) => ${element.updated}",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true,
        },
        {
          header: "Software Name/KB",
          columnDef: "product_name",
          cType: "string",
          cell: "(element: any) => `${element.product_name}`",
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Scheduler Name",
          columnDef: "name",
          filter: "",
          cell: "(element: any) => `${element.name}`",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Patch Type",
          columnDef: "type",
          cType: "string",
          filter: "enumString",
          cell: "(element: any) => `${element.type}`",
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Id",
          columnDef: "job_id",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.job_id}`",
          order: 2,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Status",
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          columnDef: "status",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.status}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Description",
          columnDef: "msg",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.msg}`",
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          isstatuscontrol: true,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: "Company Name",
          columnDef: "company_name",
          cType: "string",
          cell: "(element: any) => `${element.company_name}`",
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          isaddExcel: true,
        },
      ],
      sortOptions: { active: "updated", direction: "desc" },
      _pageData: [],
      tableOptions: {
        title: "Patch Jobs",
        isServerSide: false,
        selectText: "job(s)",
        loading: true,
        floatingFilter: true,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: "/r/company/patch_job_view",
          condition: "",
        },
      },
      customText: [
        {
          status: "initiated",
          DisplayText: "Initiated",
          class: "bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50",
        },
        {
          status: "inprogress",
          DisplayText: "Inprogress",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "pending",
          DisplayText: "Pending",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "partial",
          DisplayText: "Partial",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "success",
          DisplayText: "Success",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "completed",
          DisplayText: "Completed",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "failed",
          DisplayText: "Failed",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "scheduled",
          DisplayText: "Scheduled",
          class: "bg-cyan-200 text-cyan-800 dark:bg-cyan-600 dark:text-cyan-50",
        },
        {
          status: "pushedtoqueue",
          DisplayText: "Pushed To Queue",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
        {
          status: "terminated",
          DisplayText: "Terminated",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pdf",
          DisplayText: "PDF",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "xlsx",
          DisplayText: "XLSX",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "docx",
          DisplayText: "DOCX",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "pptx",
          DisplayText: "PPTX",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
      ],
      changeValue: new Subject<any>(),
    };
    this._changeDetectorRef.detectChanges();
  }
  trackByFn(index: number, item: any): any {
    return (item && item.id) || index;
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.objectKeys(this.pObj).forEach((obj: any) => {
      if (obj) {
        this.pObj[obj].unsubscribe();
      }
    });

    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  sortPatchJobDetails(patchJobDetails: any[]): any[] {
    const statusOrder = [
      'success',
      'completed',
      'failed',
      'terminated',
      'running',
      'pushedtoqueue',
      'initiated',
      'inprogress',
      'pending',
      'partial',
      'scheduled'
    ];

    return patchJobDetails.sort((a, b) => {
      return statusOrder.indexOf(a.status?.toLowerCase()) - statusOrder.indexOf(b.status?.toLowerCase());
    });
  }

  linkClick($event: any) {
    this.searchFilter = "";
    $event.row.patchJobDetails = Object.values($event.row.patch_job_details);
    this.currentData = $event.row;
    this.drawerOpened = true;
    this.currentDataCheck = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();
      this._changeDetectorRef.detectChanges();
    }
  }
  toggleChange($event: any): void {
    this.cView = "jobs";
  }
  closeCallBack($event: any): void {
    this.cView = "jobs";
  }
  actionCall(idata: any): void {
    if (idata.action.text === "Download Report") {
      if (idata.row.status.toLowerCase() !== "completed") {
        this.toast.sToast(
          "error",
          `Job status: ${idata.row.status}. Report download unavailable at the moment.`
        );
        return;
      }
      const params = {
        job_id: idata.row.job_id,
      };
      this.ls.display(true);
      this._bs
        .doRequest(`/report_builder/get_report_link`, "get", null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.ls.display(false);
          if (res.status) {
            window.open(res.message, "_blank");
            setTimeout(() => this.ls.tableProgress(false));
          } else {
            const data = res.message ? res.message : res.data;
            this.toast.sToast("error", data);
          }
        });
    }
  }
  closeCurrentData() {
    this.currentDataCheck = false;
    this.drawer.toggle();
  }
  closeReportCurrentData() {
    this.reportCurrentDataCheck = false;
    this.reportCurrentData = [];
    this.drawer.toggle();
  }

  apiDownload(): void {
    const getFormattedDate = (): string => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = String(today.getFullYear()).slice(-2); 
        return `${month}${day}${year}`;
    };
    
    const dateSuffix = getFormattedDate();
    const filename = `Patching-Jobs_${dateSuffix}.csv`; 
    const csvContent = this.convertToCSV(this.currentData.patchJobDetails);
    this.downloadCSV(csvContent, filename);
}

convertToCSV(data: any[]): string {
    if (data.length === 0) {
        return ''; 
    }
    const columnMapping: { [key: string]: string } = {
        host_name: 'Asset Name',
        status: 'Status',
        to_version: 'To Version',
        status_msg: 'Patch Response',
    };

    const orderedHeaders = Object.keys(columnMapping);
    const headers = orderedHeaders.map(key => columnMapping[key]);

    const formatValue = (value: any, fieldName: string): string => {
        if (value === undefined || value === null) {
            return '';
        }
        const strValue = String(value);

        if (fieldName === 'ip_address') {
            return `"${Array.isArray(value) ? value.join(', ') : strValue}"`;
        }

        if (fieldName === 'accrued_cost') {
            return `"${'$' + strValue.replace(/"/g, '""')}"`;
        }
        
        return `"${strValue.replace(/"/g, '""')}"`;
    };

    const csvRows = [
        headers.join(','), 
        ...data.map(row =>
            orderedHeaders.map(fieldName => formatValue(row[fieldName], fieldName)).join(',')
        )
    ];
    return csvRows.join('\n'); 
}

downloadCSV(csvContent: string, fileName: string): void {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

}
