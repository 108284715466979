import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';
import { NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { MaterialModule } from "../../../material.module";
import { FuseAlertComponent } from "../../../../@fuse/components/alert";
import { TableComponent } from "../table/table.component";
import { LoaderService } from "../../../_services/loader.service";
import { BaseRequestService } from "../../../_services/base.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { CommonService } from "../../../_services/common.service";
import { Subject, takeUntil } from 'rxjs';
import { DirectivesModule } from 'app/-directives/-directives.module';

@Component({
    selector: 'app-support-ticket',
    standalone: true,
    imports: [CommonModule, DirectivesModule, RouterLink, MaterialModule, FuseAlertComponent, ReactiveFormsModule, TableComponent],
    animations: fuseAnimations,
    templateUrl: './support-ticket.component.html',
    styleUrls: ['./support-ticket.component.scss']
})

export class SupportTicketComponent implements OnInit, OnDestroy {
    currentTicket: any;
    sView = 'create-ticket';
    @ViewChild('supportNgForm') supportNgForm: NgForm;
    tickTableOptions: any = {
        columns: [
            {
                "header": "ID",
                "columnDef": "id",
                "cType": "string",
                "filter": "",
                "width": "50px",
                "cell": "(element: any) => `${element.id}`",
                "order": 2,
                "isHyperlink": true,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Subject",
                "columnDef": "subject",
                "cType": "string",
                "filter": "",
                "width": "200px",
                "cell": "(element: any) => `${element.subject}`",
                "order": 0,
                "isHyperlink": true,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Status",
                "columnDef": "status",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.status}`",
                "order": 1,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Priority",
                "columnDef": "priority",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.priority}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Description",
                "columnDef": "description",
                "cType": "string",
                "filter": "",
                "width": "300px",
                "cell": "(element: any) => `${element.description}`",
                "order": 10,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Email",
                "columnDef": "cust_email",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.cust_email}`",
                "order": 6,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Name",
                "columnDef": "cust_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.cust_name}`",
                "order": 7,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Created",
                "columnDef": "created_date",
                "cType": "number",
                "filter": "epochToDate",
                "cell": "(element: any) => `${element.created_date}`",
                "order": 8,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Updated",
                "columnDef": "updated_date",
                "cType": "number",
                "filter": "epochToDate",
                "cell": "(element: any) => `${element.updated_date}`",
                "order": 9,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Tags",
                "columnDef": "tags",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.tags}`",
                "order": 11,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Closed On",
                "columnDef": "closed_date",
                "cType": "number",
                "filter": "epochToDate",
                "cell": "(element: any) => `${element.closed_date}`",
                "order": 12,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Tid",
                "columnDef": "tid",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.tid}`",
                "order": 14,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }],
        sortOptions: { active: 'priority', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Support Tickets',
            isServerSide: false,
            selectText: 'ticket',
            loading: false,
            floatingFilter: true,
            rowSelection: true,
            showAction: false,
            actionMenuItems: [
                {
                    text: 'Suppress',
                    icon: 'remove_circle_outline',
                    callback: 'suppressFn',
                    isGlobal: true,
                },
                {
                    text: 'Unsuppress',
                    icon: 'find_replace',
                    callback: 'unsuppressFn',
                    isGlobal: true,
                },
            ],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/api/tickets',
                condition: ''
            },
        },
        customText: [
            {
                status: 'open',
                DisplayText: 'OPEN',
                color: '#ff3d0b',
                'text-color': '#ffffff'
            },
            {
                status: 'closed',
                DisplayText: 'CLOSED',
                color: '#4eff56',
                'text-color': '#2c2c2c'
            },
            {
                status: 'in progress',
                DisplayText: 'IN PROGRESS',
                color: '#fff4a6',
                'text-color': '#2c2c2c'
            },
            {
                status: 'pending',
                DisplayText: 'PENDING',
                color: '#ffb837',
                'text-color': '#2c2c2c'
            },
            {
                status: 'p1',
                DisplayText: 'P1',
                color: '#ff7578',
                'text-color': '#2c2c2c'
            },
            {
                status: 'p2',
                DisplayText: 'P2',
                color: '#ffca67',
                'text-color': '#2c2c2c'
            },
            {
                status: 'p3',
                DisplayText: 'P3',
                color: '#8095ff',
                'text-color': '#2c2c2c'
            }
        ],
        changeValue: new Subject<any>(),
    };
    alert: any;
    supportForm: UntypedFormGroup;
    summary: any = { closed: 0, opened: 0, total: 0 };
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder, private ls: LoaderService,
        private bs: BaseRequestService, private toast: MyToastrService,
        public cs: CommonService,
    ) {
    }

    ngOnDestroy(): void {
        throw new Error('Method not implemented.');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the support form
        this.supportForm = this._formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            subject: ['', Validators.required],
            message: ['', Validators.required],
        });
        this.getSummary();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Clear the form
     */
    clearForm(): void {
        // Reset the form
        this.supportNgForm.resetForm();
    }

    /**
     * Send the form
     */
    sendForm(): void {
        // Send your form here using http request
        console.log('Your message has been sent!');

        // Show a success message (it can also be an error message)
        // and remove it after 5 seconds
        this.alert = {
            type: 'success',
            message: 'Your request has been delivered! A member of our support staff will respond as soon as possible.',
        };

        setTimeout(() => {
            this.alert = null;
        }, 7000);

        // Clear the form
        this.clearForm();
    }

    closeTicket(): void {
        this.sView = 'support-center';
        this.currentTicket = undefined;
    }

    getSummary(): void {
        this.ls.display(true);
        this.bs.doRequest(`/api/tickets/summary`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    res.data.forEach((obj: any) => {
                        this.summary.total += obj.count;
                        this.summary[obj.status] = obj.count;
                    });
                    this.summary = res.data;
                } else {
                    const data = (res.message)? res.message : res.data;
this.toast.sToast('error', data);
                }
            });
    }
}
