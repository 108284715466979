import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, takeUntil } from 'rxjs';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { CommonService } from 'app/_services/common.service';

@Component({
  selector: 'app-custom-domain',
  standalone: true,
  imports: [CommonModule, MaterialModule, FuseAlertComponent, AppFilterPipeModule, FormsModule, ReactiveFormsModule, MatChipsModule, DirectivesModule],
  templateUrl: './custom-domain.component.html',
  styleUrls: ['./custom-domain.component.scss']
})
export class CustomDomainComponent implements OnInit, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  domain = false;
  customDomain: any = {
    ssl_cert: null, ssl_privkey: null, domain_name: null, allowed_ips: []
  };
  customDomainOriginal: any = {};
  updateIp = false;
  Objectkeys = Object.keys;
  allowedIps: any = [];
  dnsProviders: any = [
    {
      "name": "GoDaddy",
      "img": "godaddy.png",
      "url": "https://in.godaddy.com/help/add-an-a-record-19238"
    },
    {
      "name": "Namecheap",
      "img": "namecheap.png",
      "url": "https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain/"
    },
    {
      "name": "AWS",
      "img": "aws.png",
      "url": "https://docs.aws.amazon.com/managedservices/latest/ctref/management-directory-dns-add-a-record.html"
    },
    {
      "name": "Cloudflare",
      "img": "cloudflare.png",
      "url": "https://developers.cloudflare.com/dns/manage-dns-records/how-to/create-dns-records/"
    },
    {
      "name": "Google Domains",
      "img": "googledomains.png",
      "url": "https://support.google.com/domains/answer/3290350"
    },
    {
      "name": "Bluehost",
      "img": "bluehost.png",
      "url": "https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries"
    },
    {
      "name": "Network Solutions",
      "img": "networksolutions.png",
      "url": "https://www.networksolutions.com/knowledge"
    }
  ];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService, public _cs: CommonService,
    public confirmDialog: FuseConfirmationService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getDomainInformation();
  }

  getDomainInformation(): void {
    this.ls.display(true);
    this._bs.doRequest(`/r/company/custom_domains`, 'get', null, { condition: true })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        this.updateIp = false;
        this.customDomainOriginal = {};
        if (res.status) {
          if (res.data && res.data.length) {
            this.customDomainOriginal = res.data[0];
            this.domain = (res.data[0].configured_domain) ? true : false;
          } else {
            this.customDomain = {
              ssl_cert: null, ssl_privkey: null, domain_name: null, allowed_ips: []
            };
            this.domain = false;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }

  uploadDomainFile(event: any, key: string): void {
    // @ts-ignore
    this[key] = ''
    if (event.target.files.length > 0) {
      this.customDomain[key] = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (ev) => {
        // @ts-ignore
        this[key] = reader.result;
      };
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our key
    if (value) {
      const valid = this._cs.isIPInRange(value, "0.0.0.0", "255.255.255.255");
      if (!valid) {
        this.toast.sToast('error', 'IP address you entered is not valid.');
        return;
      } else {
        this.customDomain.allowed_ips.push(value);
      }
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  remove(key: any): void {
    const index = this.customDomain.allowed_ips.indexOf(key);
    if (index >= 0) {
      this.customDomain.allowed_ips.splice(index, 1);
    }
  }

  edit(key: any, event: MatChipEditedEvent) {
    const value = event.value.trim();
    // Remove key if it no longer has a name
    if (!value) {
      this.remove(key);
      return;
    }
    // Edit existing key
    const index = this.customDomain.allowed_ips.indexOf(key);
    if (index >= 0) {
      this.customDomain.allowed_ips[index] = value;
    }
  }

  async uploadDomain(type: any): Promise<any> {
    const msg = {
      upload: 'Are you sure you want to upload ?',
      remove: 'Are you sure you want to remove ?',
      update: 'Are you sure you want to allow these IPs ?'
    }
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: msg[type],
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe(async (result: any) => {
      if (result === 'confirmed') {
        const formData = new FormData();
        let successMsg = '';
        if (type === 'upload') {
          Object.keys(this.customDomain).forEach((obj: any) => {
            if (obj !== 'allowed_ips') {
              formData.append(obj, this.customDomain[obj]);
            } else {
              formData.append(obj, JSON.stringify(this.customDomain[obj].map((x: any) => x = x.toString())));
            }
          });
          successMsg = 'Uploaded Successfully';
        } else if (type === 'remove') {
          const deleteK: any = true;
          formData.append('domain_name', this.customDomainOriginal['configured_domain']);
          formData.append('delete', deleteK);
          successMsg = 'Removed Successfully';
        } else if (type === 'update') {
          formData.append('domain_name', this.customDomainOriginal['configured_domain']);
          formData.append('allowed_ips', JSON.stringify(this.customDomain.allowed_ips.map((x: any) => x = x.toString())));
          successMsg = 'Updated Successfully';
        }
        if (this.customDomainOriginal['configured_domain']) {
          formData.delete('domain_name');
          formData.append('domain_name', this.customDomainOriginal['configured_domain']);
        }
        try {
          this.ls.display(true);
          const response = await this._bs.uploadFormData('/w/company/custom_domain', formData).toPromise();
          this.ls.display(false);
          if (response.status) {
            this.toast.sToast('success', successMsg);
            setTimeout(() => {
              this.getDomainInformation();
              this.customDomain = {
                ssl_cert: null, ssl_privkey: null, domain_name: null, allowed_ips: []
              };
            }, 1000);
          } else {
            this.toast.sToast('error', response.message);
          }
        } catch (error) {
          // Handle errors here
          throw error;
        }
      }
    })
  }

  updateAllowededIp(): void {
    this.customDomain.allowed_ips = this.customDomainOriginal.allowed_ips;
    this.updateIp = true;
  }
}
