<div [matMenuTriggerFor]="dynamicMenu" [matTooltip]="data.name" [id]="data.id">
    <div class="flex items-center justify-center w-full h-full rounded-full gap-1 uppercase">
        <mat-icon color="primary" class="icon-size-4" [ngClass]="data.classes?.icon" [svgIcon]="data.icon"></mat-icon>
        <span class="text-xs">{{data.text}}</span>
    </div>
</div>
<mat-menu class="" [overlapTrigger]="false" #dynamicMenu="matMenu">
    <ng-container *ngFor="let item of data.menuItems;">
        <div class="my-1">
            <div class="cus-menu" mat-menu-item (click)="menuItemSelected(item)" [id]="item.id">
                <ng-container *ngIf="item.icon">
                    <mat-icon color="primary" class="icon-size-5 mr[12px]" [ngClass]="item.classes?.icon" [svgIcon]="item.icon"></mat-icon>
                </ng-container>
                <div class="text-sm font-medium">
                    <span [ngClass]="item.classes?.title">
                        {{item.name}}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</mat-menu>
