<div *ngIf="objectKeys(data)">
    <div class=" flex flex-col w-full min-w-0 sm:inset-0 sm:overflow-hidden" [@fadeInLeft]>
        <div class=" w-auto rounded-2xl dark:bg-default mb-2" #scrollableDiv>
            <div class="flex flex-col flex-auto min-w-0 w-full">
                <div class="flex flex-row items-center pl-4 mt-4">
                    <div class="flex items-center py-1 px-0.5">
                        <mat-icon class="icon-size-5 text-grey-400" [svgIcon]="'mat_outline:domain'"></mat-icon>
                        <div
                            class="text-2xl font-semibold tracking-tight ml-2 text-transparent bg-clip-text bg-primary to-emerald-600 from-sky-400">
                            {{data.website}}</div>
                    </div>
                    <button class="ml-auto" mat-icon-button (click)="closeCallback.emit({})">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <div *ngIf="objectKeys(data).length !== 0" class="flex-auto -mt-px pt-1 sm:pt-2 ">
                    <div class="w-full max-w-screen-lg mx-auto" fuseScrollbar>
                        <div class="mx-auto grid grid-cols-6 gap-5 px-10 text-center
                             sm:grid-cols-1 md:max-w-screen-lg md:grid-cols-6 md:px-0">
                            <ng-container *ngFor="let file of summary; trackBy:trackByFn">
                                <div class="flex aspect-square flex-col rounded-xl items-center
                                    justify-center space-y-3 bg-card p-4" (click)="navigateTarget(file)">
                                    <div class="text-4xl">
                                        <img class="h-10" [src]="file.img" alt="img">
                                    </div>
                                    <h3 class="text-5xl font-semibold">{{file.count}}</h3>
                                    <span class="text-sm font-light">{{file.title}}</span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="p-2">
                            <div class="flex flex-row gap-2 w-full" *ngIf="false">
                                <ng-container *ngFor="let file of summary; trackBy:trackByFn">
                                    <a class="flex flex-col w-32 h-32 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card duration-300 ease-out transform hover:-translate-y-1 hover:scale-105"
                                        href="javascript:" style="height: 170px !important;width: 171px !important;"
                                        (click)="navigateTarget(file)">
                                        <div class="aspect-[1/6]">
                                            <div class="flex flex-col items-center justify-center h-full">
                                                <!-- Icons -->
                                                <div class="relative">
                                                    <img style="width: 1.8rem; height: 1.5rem;" [src]="file.img" alt="">
                                                    <!-- <mat-icon class="icon-size-8 text-hint opacity-50"
                                                        [svgIcon]="file.img"></mat-icon> -->
                                                </div>
                                                <div
                                                    class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                                                    <div class="truncate flex justify-center text-center"
                                                        [matTooltip]="file.count">
                                                        <div class="fuse-vertical-navigation-item-badge">
                                                            <div class="fuse-vertical-navigation-item-badge-content px-2.5 font-bold text-center text-primary rounded-full"
                                                                style="font-size: x-large !important">
                                                                {{file.count}} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="whitespace-nowrap left-0 bottom-0 px-1.5 mt-2  rounded text-sm text-center font-semibold leading-5"
                                            [class.text-fuchsia-400]="file.title === 'Vulnerabilities'"
                                            [class.text-sky-400]="file.title === 'Open Ports'"
                                            [class.text-amber-400]="file.title === 'Target IPs'"
                                            [class.text-violet-400]="file.title === 'Emails'"
                                            [class.text-blue-400]="file.title === 'Usernames'"
                                            [class.text-emerald-400]="file.title === 'Subdomains'">
                                            {{file.title}}
                                        </div>
                                    </a>
                                </ng-container>
                            </div>
                            <div *ngIf="data.s3buckets && data.s3buckets[0].length > 0"
                                class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mt-6">
                                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                    S3 Buckets
                                    <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                        {{ data.s3buckets[0].length }}
                                    </span>
                                    <div class="overflow-x-auto mt-2">
                                        <div class="flex space-x-4">
                                            <ng-container *ngFor="let chunk of chunkedBuckets">
                                                <table class="min-w-max divide-y border">
                                                    <!-- <thead class="">
                                                        <tr>
                                                            <th
                                                                class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                S3 Bucket Name
                                                            </th>
                                                        </tr>
                                                    </thead> -->
                                                    <tbody class="divide-y">
                                                        <tr *ngFor="let bucket of chunk">
                                                            <td class="px-4 py-2 whitespace-nowrap">
                                                                <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                    target="_blank"
                                                                    class="text-blue-500 hover:text-blue-700">
                                                                    {{ bucket }}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="data.target_ips && data.target_ips.length > 0" #targetip
                                class="flex flex-col flex-auto overflow-hidden w-full mt-4">
                                <div class="flex flex-col sm:flex-row items-start justify-between">
                                    <div class="text-medium font-bold tracking-tight leading-6 truncate">
                                        Target IP Addresses <span
                                            class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            {{data.target_ips?.length }}
                                        </span></div>
                                </div>
                                <div class="flex flex-col mt-2">
                                    <app-ipv *ngFor="let ipData of data.target_ips" class="mb-4"
                                        [data]="ipData"></app-ipv>
                                </div>
                            </div>

                            <ng-container>
                                <div
                                    class="flex flex-col sm:flex-row flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div *ngIf="data.dns_records && data.dns_records.length > 0"
                                        class="flex flex-col flex-auto p-4 bg-white border border-gray-200 rounded-lg w-1/2 dark:bg-gray-800 dark:border-gray-700 mr-4">
                                        <div class="flex flex-col sm:flex-row items-start justify-between">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                DNS Records
                                                <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">{{
                                                    data.dns_records?.length }}</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-start border-t-2 mt-2 p-2">
                                            <table class="table-auto w-full">
                                                <tbody class="text-sm font-light">
                                                    <tr class="border-b"
                                                        *ngFor="let rec of data.dns_records; let i = index">
                                                        <td class="py-1 px-4 text-left">{{ rec[0] | camelToHuman: true
                                                            }}</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-globe text-green-400 pr-2"></i>
                                                            <span style="word-break: break-word;"
                                                                *ngIf="rec[1].length <= 50">{{ rec[1] }}</span>
                                                            <span style="word-break: break-word;"
                                                                *ngIf="rec[1].length > 50">
                                                                {{ expandedDnsRecords.has(i) ? rec[1] : (rec[1] |
                                                                slice:0:50) }}<span
                                                                    *ngIf="!expandedDnsRecords.has(i)">...</span>
                                                                <button (click)="toggleShowMoreDnsRecords(i)"
                                                                    class="text-blue-500 hover:text-blue-700">
                                                                    {{ expandedDnsRecords.has(i) ? 'Show Less' : 'Show
                                                                    More' }}
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div *ngIf="data.raw_headers && objectKeys(data.raw_headers).length"
                                        class="flex flex-col flex-auto p-4 bg-white border border-gray-200 rounded-lg w-1/2 ml-4 dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex flex-col sm:flex-row items-start justify-between">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                RAW Headers
                                                <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                                    {{ objectKeys(data.raw_headers).length }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-start border-t-2 mt-2 p-2">
                                            <table class="table-auto w-full">
                                                <tbody class="text-sm font-light">
                                                    <tr class="border-b"
                                                        *ngFor="let header of objectKeys(data.raw_headers)">
                                                        <td class="py-1 px-4 text-left">{{ header | camelToHuman: true
                                                            }}</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-globe text-green-400 pr-2"></i>
                                                            <!-- <a [href]="'https://' + data.raw_headers[header]"
                                                                target="_blank"
                                                                class="text-blue-500 hover:text-blue-700"> -->
                                                            <span style="word-break: break-word;"
                                                                *ngIf="data.raw_headers[header].length <= 50">{{
                                                                data.raw_headers[header] }}</span>
                                                            <span style="word-break: break-word;"
                                                                *ngIf="data.raw_headers[header].length > 50">
                                                                {{ showMoreRawHeaders[header] ?
                                                                data.raw_headers[header] : (data.raw_headers[header]
                                                                | slice:0:50) }}
                                                                <span *ngIf="!showMoreRawHeaders[header]">...</span>
                                                                <button
                                                                    (click)="toggleShowMoreRawHeaders($event, header)"
                                                                    class="text-blue-500 hover:text-blue-700">
                                                                    {{ showMoreRawHeaders[header] ? 'Show Less' :
                                                                    'Show More' }}
                                                                </button>
                                                            </span>
                                                            <!-- </a> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="data.subdomains && data.subdomains[0].length > 0">
                                <div class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4"
                                    #subdomains>
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        S3 Sub Domains
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            {{ data.subdomains[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of subdomains_name">
                                                    <table class="min-w-max divide-y  border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">
                                                                    Sub Domain Name
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y divide-200">
                                                            <tr *ngFor="let subdomain of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://' + subdomain" target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ subdomain }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="data.mx && data.mx.hosts && data.mx.hosts.length > 0">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full bg-white border border-gray-200 p-4 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="flex flex-row my-4">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                MX Records
                                                <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                                    {{ data.mx.hosts?.length }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table
                                            class="w-full text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead
                                                class="text-medium font-medium text-black bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        Address
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        HostName
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Preference
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let mxhost of data.mx.hosts">
                                                    <tr class="break-all border-b dark:border-gray-700">
                                                        <td
                                                            class="px-6 py-4 font-medium text-gray-900 dark:text-white max-w-[17rem]">
                                                            <ng-container
                                                                *ngFor="let address of mxhost.addresses; let i = index">
                                                                <!-- <a [href]="'http://' + address" target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    class="text-blue-500 hover:underline"> -->
                                                                {{ address }}
                                                                <!-- </a> -->
                                                                <span *ngIf="i < mxhost.addresses.length - 1">, </span>
                                                            </ng-container>
                                                        </td>
                                                        <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                            {{ mxhost.hostname }}
                                                        </td>
                                                        <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                            {{ mxhost.preference }}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-container>
                            
                            <ng-container
                                *ngIf="data && data.dmarc && data.dmarc.record && data.dmarc.record.length > 0">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full bg-white border border-gray-200 p-4 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="flex flex-row my-4">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                DMARC Records

                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table
                                            class="w-full text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead
                                                class="text-medium font-medium text-black bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        Record
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Valid
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Location
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <td
                                                    class="px-6 py-4 font-medium text-gray-900 dark:text-white max-w-[17rem]">
                                                    <ng-container>
                                                        {{ data.dmarc.record }}

                                                    </ng-container>
                                                </td>
                                                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">

                                                    <span *ngIf="data.dmarc.valid">
                                                        <img src="/assets/images/devices/tick.svg" class="w-[20px]"
                                                            alt="True">
                                                    </span>
                                                    <span *ngIf="!data.dmarc.valid">
                                                        <img src="/assets/images/devices/cross.svg" class="w-[20px]"
                                                            alt="False">
                                                    </span>
                                                </td>
                                                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                    {{data.dmarc.location }}
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="data && data.spf && data.spf.record && data.spf.record.length > 0">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full bg-white border border-gray-200 p-4 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="flex flex-row my-4">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                Sender Policy Framework(SPF) Records
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table
                                            class="w-full text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead
                                                class="text-medium font-medium text-black bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        Record
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Valid
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        DNS Lookups
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        DNS Void Lookups
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <td
                                                    class="px-6 py-4 font-medium text-gray-900 dark:text-white max-w-[17rem]">
                                                    <ng-container>
                                                        {{ data.spf.record }}

                                                    </ng-container>
                                                </td>
                                                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">

                                                    <span *ngIf="data.spf.valid">
                                                        <img src="/assets/images/devices/tick.svg" class="w-[20px]"
                                                            alt="True">
                                                    </span>
                                                    <span *ngIf="!data.spf.valid">
                                                        <img src="/assets/images/devices/cross.svg" class="w-[20px]"
                                                            alt="False">
                                                    </span>
                                                </td>
                                                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                    {{ data.spf.dns_lookups }}
                                                </td>
                                                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                    {{data.spf.dns_void_lookups }}
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-container>



                            <ng-container
                                *ngIf="data.guessed_emails && data.guessed_emails.length && data.guessed_emails[0].length > 0">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        Guessed Emails
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            <!-- {{ data.guessed_emails[0].length }} -->
                                            {{ data.guessed_emails[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of guessed_emails">
                                                    <table class="min-w-max divide-y border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                    Guessed Emails Name
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y">
                                                            <tr *ngFor="let bucket of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                        target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ bucket }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                            <ng-container *ngIf="data.emails && data.emails.length && data.emails[0].length > 0">
                                <div class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4"
                                    #emails>
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        Email(s)
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            <!-- {{ data["emails"][0].length }} -->
                                            {{ data.emails[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of Emails_update">
                                                    <table class="min-w-max divide-y border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                    Emails
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y">
                                                            <tr *ngFor="let bucket of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                        target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ bucket }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="data.hashes && data.hashes.length && data.hashes[0].length > 0">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        Hashes
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            <!-- {{ data["hashes"][0].length }} -->
                                            {{ data.hashes[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of hashes">
                                                    <table class="min-w-max divide-y border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                    Hashes Name
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y">
                                                            <tr *ngFor="let bucket of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                        target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ bucket }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="data.usernames && data.usernames.length && data.usernames[0].length > 0">
                                <div class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4"
                                    #usernames>
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        Username(s)
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            <!-- {{ data["usernames"][0].length }} -->
                                            {{ data.usernames[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of usernames_update">
                                                    <table class="min-w-max divide-y border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                    User Name
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y">
                                                            <tr *ngFor="let bucket of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                        target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ bucket }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="data.employees && data.employees.length && data.employees[0].length > 0">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        Employees
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            <!-- {{ data["employees"][0].length }} -->
                                            {{ data.employees[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of employees_name">
                                                    <table class="min-w-max divide-y border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                    Employees
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y">
                                                            <tr *ngFor="let bucket of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                        target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ bucket }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="data.creds && data.creds.length && data.creds[0].length > 0">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                        Creds
                                        <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm">
                                            <!-- {{ data["Creds"][0].length }} -->
                                            {{ data.Creds[0].length }}
                                        </span>
                                        <div class="overflow-x-auto mt-2">
                                            <div class="flex space-x-4">
                                                <ng-container *ngFor="let chunk of creds_name">
                                                    <table class="min-w-max divide-y border">
                                                        <!-- <thead class="">
                                                            <tr>
                                                                <th
                                                                    class="px-4 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                                                                    Creds
                                                                </th>
                                                            </tr>
                                                        </thead> -->
                                                        <tbody class="divide-y">
                                                            <tr *ngFor="let bucket of chunk">
                                                                <td class="px-4 py-2 whitespace-nowrap">
                                                                    <a [href]="'https://console.aws.amazon.com/s3/buckets/' + bucket"
                                                                        target="_blank"
                                                                        class="text-blue-500 hover:text-blue-700">
                                                                        {{ bucket }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="objectKeys(data).length === 0" class="flex flex-col items-center justify-center gap-y-2">
                    <img src="/assets/images/empty.png" alt="" class="w-20">
                    <div> No data available!</div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-skeleton *ngIf="!data && !showData">

</app-skeleton>