<div class="-mx-4">
    <!-- Table -->
    <stable aria-label="Credentials Table" role="table"
            *ngIf="credTableOptions && credTableOptions.tableOptions && credTableOptions.tableOptions.serverSide"
            (addCallback)="addTableData()" (actionCallback)="credactionCall($event)"
            [id]="credTableOptions.tableOptions.id"
            [sTableOptions]="credTableOptions">
    </stable>
</div>

<!-- Credential Screen Modal -->
<s-modal id="newCredential">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
        <!-- Left Side Description -->
        <div class="w-1/3 h-[75vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-10 font-bold tracking-wider">CREDENTIALS</p>
            <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Update your <br> assets credentials here,
            </p>
            <p class="mb-4 text-sm text-gray-200">Specify asset and credential types to refresh valid vulnerability
                scanning credentials.
                For Windows-Active Directory, input Domain Controller name,
                username, and password for authentication. Enhance security assessments with accurate configuration.</p>
            <div class="bg-blue-600/80 rounded-2xl p-3">
                <p class="mb-3 text-sm text-gray-200">
                    Vulnerability scanning is a proactive approach to identifying potential weaknesses and security
                    flaws in
                    an IT environment, enabling timely mitigation of risks.</p>
            </div>
        </div>
        <!-- Right Side Form Data -->
        <div #detailView class="w-2/3 h-[75vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <!-- Top Close Button -->
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('newCredential');">
                <mat-icon>close</mat-icon>
            </button>
            <!-- Heading -->
            <h2 class="mb-4 text-3xl font-bold">Credentials</h2>
            <!-- Form Data -->
            <form class="flex flex-col m-2 px-8 overflow-hidden" #credNgForm="ngForm">
                <!-- Name -->
                <div class="flex mt-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Name</mat-label>
                        <input matInput [placeholder]="'Enter Name'" name="name" id="name" required [(ngModel)]="cred.name"
                               [spellcheck]="false">
                    </mat-form-field>
                </div>
                <!-- OS Type -->
                <div class="flex mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>OS Type</mat-label>
                        <mat-select name="OsType" id="OsType" (selectionChange)="cred.credential_type = ''" required
                                    [(ngModel)]="cred.os_type">
                            <ng-container *ngFor="let data of osType">
                                <mat-option [id]="data.key" [value]="data.value"> {{data.key}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Credential Type -->
                <div class="flex mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Credential Type</mat-label>
                        <mat-select name="credType" id="credType" required [(ngModel)]="cred.credential_type" (selectionChange)="disableSubmit=true">
                            <ng-container *ngFor="let data of credentialType">
                                <mat-option [value]="data.value" [id]="data.key" *ngIf="data.allowOS.indexOf(cred.os_type) !== -1">
                                    {{data.key}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Firewall Type -->
                <mat-form-field class="mt-4 w-full" [subscriptSizing]="'dynamic'"
                                *ngIf="cred.credential_type === 'FIREWALLCREDENTIAL' && filterFirewallDetails">
                    <mat-label>Firewall Type</mat-label>
                    <mat-select name="firewallType"  id="firewallType" required [(ngModel)]="cred.firewall_type"
                                (selectionChange)="onSelectFirewall($event.value); disableSubmit=false">
                        <mat-option [id]="firewall" *ngFor="let firewall of Objectkeys(fireWallDetails)" [value]="firewall">
                            {{firewall | uppercase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--  -->
                <ng-container *ngIf="cred.credential_type === 'FIREWALLCREDENTIAL' && filterFirewallDetails">
                    <app-dynamic-form class="mt-4" [isSaveBtn]="false" [isCloseBtn]="false"
                                      *ngIf="filterFirewallDetails && filterFirewallDetails.length"
                                      [Valuesoutput]="firewallFilter"
                                      [listOfFormElements]="filterFirewallDetails">
                    </app-dynamic-form>
                </ng-container>
                <!-- Port -->
                <ng-container *ngIf="cred.credential_type === 'ASSETCREDENTIAL'">
                    <div class="flex mt-4" *ngIf="cred.os_type !== 'WINDOWS' && cred.os_type !== 'NETWORKDEVICE'">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Port</mat-label>
                            <input matInput [placeholder]="" text="number" name="port" id="port" required [(ngModel)]="cred.port"
                                   [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- Domain Name -->
                    <div class="flex mt-4" *ngIf="cred.os_type === 'WINDOWS'">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain Name</mat-label>
                            <input matInput [placeholder]="" name="DomainNameW" id="DomainNameW" [(ngModel)]="cred.domain_name"
                                   [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- User Name -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>User Name</mat-label>
                            <input matInput [placeholder]="" name="username" id="username" required [(ngModel)]="cred.user_name"
                                   [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- Password -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Password</mat-label>
                            <input matInput [placeholder]="" [required]="!cred.private_key" type="password"
                                   name="PasswordKey" id="PasswordKey" [(ngModel)]="cred.password" [spellcheck]="false"  (focus)="clearPassword()" (blur)="restorePassword()">
                        </mat-form-field>
                    </div>
                    <!-- Private Key -->
                    <div class="flex mt-4" *ngIf="cred.os_type !== 'WINDOWS' && cred.os_type !== 'NETWORKDEVICE'">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Private Key</mat-label>
                            <input matInput [placeholder]="" type="password" name="PrivateKey" id="PrivateKey"
                                   [required]="!cred.password" [(ngModel)]="cred.private_key" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- Domain Name / Domain Controller Name -->
                <ng-container *ngIf="cred.credential_type === 'AD'">
                    <!-- Domain Name -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain Name</mat-label>
                            <input matInput [placeholder]="" name="domainName" id="domainName"required [(ngModel)]="cred.domain_name"
                                   [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- Domain Controller Name -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain Controller Name</mat-label>
                            <input matInput [placeholder]="" name="ActiveDirectoryDCName" id="ActiveDirectoryDCName" required
                                   [(ngModel)]="cred.ad_dc_name" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- User Name -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>User Name</mat-label>
                            <input matInput [placeholder]="" name="UserName" id="UserName" required [(ngModel)]="cred.user_name"
                                   [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- Password -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="ADCredPassword" id="ADCredPassword" required
                                   [(ngModel)]="cred.password" [spellcheck]="false" (focus)="clearPassword()" (blur)="restorePassword()">
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- Community String -->
                <ng-container *ngIf="cred.credential_type === 'SNMPV1' || cred.credential_type === 'SNMPV2'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Community String</mat-label>
                            <input matInput [placeholder]="" type="password" name="CommunityString" id="CommunityString" required
                                   [(ngModel)]="cred.community_string" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- Credential_type === SNMPV3 -->
                <ng-container *ngIf="cred.credential_type === 'SNMPV3'">
                    <!-- Security Name -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Security Name</mat-label>
                            <input matInput [placeholder]="" name="securityName" id="securityName" required
                                   [(ngModel)]="cred.security_name" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- Auth Protocol -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Auth Protocol</mat-label>
                            <mat-select name="authProtocol" id="authProtocol" [(ngModel)]="cred.auth_protocol">
                                <ng-container *ngFor="let data of authProtocol">
                                    <mat-option [id]="data.label" [value]="data.value"> {{data.label}} </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Auth Password -->
                    <div class="flex mt-4" *ngIf="cred.auth_protocol">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Auth Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="Passwordauth" id="Passwordauth"
                                   [(ngModel)]="cred.auth_password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <!-- Privacy Protocol -->
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Privacy Protocol</mat-label>
                            <mat-select name="privacyProtocol" id="privacyProtocol" [(ngModel)]="cred.privacy_protocol">
                                <ng-container *ngFor="let data of privacyProtocol">
                                    <mat-option [id]="data.label" [value]="data.value"> {{data.label}} </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Privacy Password -->
                    <div class="flex mt-4" *ngIf="cred.privacy_protocol">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Privacy Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="Passwordpri" id="Passwordpri"
                                   [(ngModel)]="cred.privacy_password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- <ng-container *ngIf="cred.credential_type === 'FIREWALLCREDENTIAL'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Address Type</mat-label>
                            <mat-select name="addressType" required [(ngModel)]="cred.address_type">
                                <ng-container *ngFor="let data of ipType">
                                    <mat-option [value]="data.value"> {{data.key}} </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Address</mat-label>
                            <input *ngIf="cred.address_type !== 'STATICIP'" matInput [placeholder]="" name="address"
                                required [(ngModel)]="cred.address" [spellcheck]="false">
                            <input *ngIf="cred.address_type === 'STATICIP'" appIPCheck matInput [placeholder]=""
                                name="address" required [(ngModel)]="cred.address" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Port</mat-label>
                            <input matInput [placeholder]="" text="number" name="port" required [(ngModel)]="cred.port"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>User Name</mat-label>
                            <input matInput [placeholder]="" name="UserName" required [(ngModel)]="cred.user_name"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="PasswordKey"
                                [(ngModel)]="cred.password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>API Key</mat-label>
                            <input matInput [placeholder]="" type="password" name="APIKey" [(ngModel)]="cred.api_key"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container> -->
                <div
                    class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                            (click)="modalService.close('newCredential');">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button [disabled]="credNgForm.invalid || !disableSubmit" [color]="'primary'"
                            type="button" appPreventMultiClick [matTooltip]="'Save'" (click)="saveCred()">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>
