import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { CommonService } from 'app/_services/common.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { DynamicSettingsService } from 'app/layout/common/dynamic-settings/dynamic-settings.service';

@Component({
  selector: 'app-dynamic-menu',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.scss']
})
export class DynamicMenuComponent {
  @Input() data: any = {};

  /**
     * Constructor
     */
  constructor(
    private cs: CommonService, private toast: MyToastrService, private _ds: DynamicSettingsService,
  ) { }

  menuItemSelected(action: any) {
    if (action.isAssetViewAction) {
      this._ds.assetEmitter.next(action);
    } else if (action.target && !action.isIntegrationAction) {
      this._ds.dsTabEmitter.next(action);
    } else {
      this.cs.integrationAction.next(action);
    }
  }
}
