import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { MaterialModule } from 'app/material.module';
import { FormsModule, NgForm } from '@angular/forms';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'app/_services/common.service';
import { TableComponent } from "../table/table.component";
import { AssetDetailsComponent } from "../asset-details/asset-details.component";

@Component({
    selector: 'app-search-cve',
    standalone: true,
    imports: [CommonModule, MaterialModule, FormsModule, AppFilterPipeModule, TableComponent, AssetDetailsComponent],
    templateUrl: './search-cve.component.html',
    styleUrls: ['./search-cve.component.scss']
})
export class SearchCveComponent implements OnInit, OnDestroy {
    filterCVE: any;
    cveDetails: any = {};
    tcve: any = [];
    cAsset: any;
    cView: any = 'asset';
    asseTableOptions: any = {
        columns: [
            {
                "header": "IP",
                "columnDef": "ip",
                "filter": "",
                "cType": "string",
                "width": "w-[125px]",
                "cell": "(element: any) => `${element.ip}`",
                "order": 0,
                "visible": true,
                "isHyperlink": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                customImg: true,
                imgCol: 'icon',
                imgPath: '/assets/images/os/',
                "img": true,
                isClass: true,
                "class": "text-medium text-blue-900 dark:text-blue-400 font-bold",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Host Name",
                "columnDef": "host_name",
                "cType": "string",
                fillEmpty: 'ip',
                "filter": "",
                "cell": "(element: any) => `${element.host_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false,
                "clipboard":true
            },
            {
                header: 'Importance',
                cType: 'number',
                columnDef: 'importance',
                width: 'w-[80px]',
                iscolumnSearch: true,
                selectFilter: true,
                selectFilterArr: [
                    { name: 'Low', id: 'AssetLow', value: 25 },
                    { name: 'Medium', id: 'AssetMedium', value: 50 },
                    { name: 'High', id: 'AssetHigh', value: 75 },
                    { name: 'Critical', id: 'AssetCritical', value: 100 }
                ],
                filter: '',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                "header": "Agent Type",
                "columnDef": "agent_type",
                "filter": "",
                cType: "string",
                "cell": "(element: any) => `${element.agent_type}`",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Discovered Time",
                "columnDef": "created",
                "cType": "string",
                "filter": "utcToLocale",
                "cell": "(element: any) => `${element.created}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Last Scanned Time",
                "columnDef": "last_discovered_time",
                "cType": "string",
                "filter": "utcToLocale",
                "cell": "(element: any) => `${element.last_discovered_time}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Asset ID",
                "columnDef": "id",
                "cType": "number",
                "filter": "",
                "cell": "(element: any) => `${element.id}`",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Discovered Using",
                "columnDef": "discovered_protocols",
                "cType": "string",
                "filter": "discoveryproto",
                "width": "w-[210px]",
                "cell": "(element: any) => `${element.discovered_protocols}`",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Company Name",
                "columnDef": "company_name",
                "filter": "",
                "cell": "(element: any) => `${element.company_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'host_name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Assets',
            isServerSide: false,
            selectText: 'asset',
            loading: false,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            dataMapping: [
                { s: 'problem_stats', d: 'total_vuls_count', filter: 'statstovuls:total' },
                { s: 'problem_stats', d: 'critical_vuls', filter: 'statstovuls:critical' },
                { s: 'problem_stats', d: 'high_vuls', filter: 'statstovuls:high' },
                { s: 'problem_stats', d: 'medium_vuls', filter: 'statstovuls:medium' },
                { s: 'problem_stats', d: 'low_vuls', filter: 'statstovuls:low' },
                { s: 'problem_stats', d: 'cisa_vuls', filter: 'statstovuls:cisa' },
                { s: 'problem_stats', d: 'epss_vuls', filter: 'statstovuls:epss' },
                { s: 'last_discovered_time', d: 'asset_status', filter: 'assetstatus' }
            ],
            serverSide: {
                url: `/r/report_queries/get_assets_by_problem`,
                condition: '',
                isGlobal: true,
                type: 'get',
                isMergeApi: false,
                mergeurl: '/api/agent_status',
                mergeCondition: 'agent_ids',
                mergeKey: 'agent_id',
                srcKey: 'agent_id'
            },
            id: 'search_cve_assets'

        },
        customText: [
            {
                status: 1,
                DisplayText: 'Probe',
            },
            {
                status: 2,
                DisplayText: 'Lightweight Agent',
            },
            {
                status: 3,
                DisplayText: 'Lightweight Agent Installed',
            },
            {
                status: 4,
                DisplayText: 'External Scan Agent',
            },
            {
                range: { from: 0, to: 0 },
                color: '#c2ffc4',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 40 },
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                range: { from: 41, to: 60 },
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                range: { from: 61, to: 90 },
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                range: { from: 91, to: 99999 },
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 25,
                DisplayText: 'Low',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 50,
                DisplayText: 'Medium',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 75,
                DisplayText: 'High',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 100,
                DisplayText: 'Critical',
                color: '#92243e',
                'text-color': '#ffffff'
            }
        ],
        changeValue: new Subject<any>(),
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @ViewChild('cveFrm') cveFrm: NgForm;

    customText: any = [
        {
            name: 'epss_score',
            customText: [
                {
                    range: { from: 0.0, to: 0.85 },
                    color: '#67ace1',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.85, to: 0.90 },
                    color: '#ffe24b',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.90, to: 0.95 },
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.95, to: 1 },
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
            ]
        },
        {
            status: 'Critical',
            DisplayText: 'CRITICAL',
            color: '#92243e',
            'text-color': '#ffffff'
        },
        {
            status: 'High',
            DisplayText: 'HIGH',
            color: '#de4a50',
            'text-color': '#ffffff'
        },
        {
            status: 'Medium',
            DisplayText: 'MEDIUM',
            color: '#f18c43',
            'text-color': '#000000'
        },
        {
            status: 'Low',
            DisplayText: 'LOW',
            color: '#f8c851',
            'text-color': '#000000'
        },
        {
            status: 'Info',
            DisplayText: 'INFO',
            color: '#67ace1',
            'text-color': '#000000'
        },
        {
            range: { from: 0, to: 0.4 },
            color: '#6fb8f9',
            'text-color': '#000000'
        },
        {
            range: { from: 0.5, to: 0.74 },
            color: '#FCE94E',
            'text-color': '#000000'
        },
        {
            range: { from: 0.75, to: 1 },
            color: '#f8c851',
            'text-color': '#000000'
        },
        {
            range: { from: 1, to: 4.9 },
            color: '#ffe24b',
            'text-color': '#000000'
        },
        {
            range: { from: 5, to: 8.9 },
            color: '#de4a50',
            'text-color': '#ffffff'
        },
        {
            range: { from: 9, to: 10 },
            color: '#92243e',
            'text-color': '#ffffff'
        },

    ]
    constructor(private _bs: BaseRequestService, private _cs: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private ls: LoaderService, private toast: MyToastrService) {
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    back(): void {
        this.cAsset = undefined;
        this.cView = 'asset';
    }

    linkCall($event: any): void {
        if ($event.col === 'host_name' || $event.col === 'ip') {
            this.cAsset = $event.row;
            this.cView = 'details';
        }
    }

    ngOnInit(): void {
        if (this._cs.tcve && this._cs.tcve.length) {
            try {
                this.tcve = this._cs.tcve;
                this.filterCVE = this._cs.tcve[0].cve;
                setTimeout(() => {
                    this.cveChange({ value: this._cs.tcve[0].cve })
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    cveChange($event: any): void {
        this.ls.display(true);
        const problemName = `'CVE-${$event.value.substring(4)}'`;
        const url = '/r/report_queries/problems_info';
        const params = {
            condition: true,
            problem_name: problemName,
            skip: 0,
            limit: 1
        };
        this._bs.doRequest(url, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.cveDetails = res.data[0];
                        this.getAffectedAssets(`CVE-${$event.value.substring(4)}`);
                    } else {
                        this.cveDetails = {};
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    processSearchTrendingCVE(result: any): void {

    }

    getCEV(): void {
        this.ls.display(true);
        const problemName = `'CVE-${this.filterCVE}'`;
        const url = '/r/report_queries/problems_info';
        const params = {
            condition: true,
            problem_name: problemName,
            skip: 0,
            limit: 1
        };

        this._bs.doRequest(url, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.cveDetails = res.data[0]
                        this.getAffectedAssets(`CVE-${this.filterCVE}`);
                    } else {
                        this.cveDetails = {};
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    getAffectedAssets(filterCVE: any): void {
        const data = Object.assign({}, this.asseTableOptions);
        data.pageData = []; data.tableOptions.pageTotal = 0;
        this.asseTableOptions = {}; this._changeDetectorRef.detectChanges();
        data.tableOptions.serverSide.condition = '';
        if (this._cs.currentScope !== '*') {
            data.tableOptions.serverSide.condition = `company_id=${parseInt(this._cs.currentScope.id)}`;
        }
        data.tableOptions.serverSide.url = `/r/report_queries/get_assets_by_problem?problem_name=${filterCVE}`;
        this.asseTableOptions = data; this._changeDetectorRef.detectChanges();
    }

    backFn(): void {
        this.filterCVE = '';
        this.cveDetails = {};
        this.tcve = [];
    }

    getRangeColor(obj: any, key: any): any {
        let style: any = { 'background-color': '#000000', 'color': '#c2ffc4' };
        if (!this.customText) {
            return style;
        }
        const comT: any = this.customText.filter((x: any) => x.name === key);
        if (comT && comT.length) {
            comT[0].customText.forEach((r: any) => {
                if (r.range && r.range.from <= obj && r.range && r.range.to >= obj) {
                    style = { 'background-color': r.color, color: r['text-color'] };
                }
            });
        } else {
            this.customText.forEach((r: any) => {
                if (r.range && r.range.from <= obj && r.range && r.range.to >= obj) {
                    style = { 'background-color': r.color, color: r['text-color'] };
                }
            });
        }
        return style;
    }
}
