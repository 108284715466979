import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { LoaderComponent } from '../loader/loader.component';
import { ModalComponent } from '../modal.component';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, takeUntil } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-windows-compatible-check',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AppFilterPipeModule, MaterialModule, FuseAlertComponent, ModalComponent, DirectivesModule, FuseScrollbarDirective, LoaderComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './windows-compatible-check.component.html',
  styleUrls: ['./windows-compatible-check.component.scss']
})
export class WindowsCompatibleCheckComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() asset: any;

  @ViewChild('windowTableMatSort', { read: MatSort }) windowTableMatSort: MatSort;
  windowCompatible: any;
  windowDataSource: MatTableDataSource<any> = new MatTableDataSource();
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  windowsCheck = {
    'cpucheck': { name: 'CPU Check', icon: 'cpu_check' },
    'cpucore': { name: 'CPU Core', icon: 'cpu' },
    'disk_free_space': { name: 'Disk Space', icon: 'disk' },
    'display_resolution': { name: 'Graphics Resolution', icon: 'graphics' },
    'firmware_info': { name: 'Firmware Type', icon: 'firmware' },
    'processor_speed': { name: 'Processor Speed', icon: 'processor' },
    'secureboot_data': { name: 'Secureboot', icon: 'secureboot' },
    'tpm_version': { name: 'TPM Version', icon: 'tpm' },
    RAM: { name: 'RAM', icon: 'ram' },

  };
  windowCompatibleTableColumns: string[] = ['name', 'minimum_required', 'value', 'status'];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService, public _cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private ls: LoaderService, private toast: MyToastrService, public _modalService: ModalService,) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    // Make the data source sortable
    this.windowDataSource.sort = this.windowTableMatSort;
  }

  ngOnInit(): void {
    this.getWindowsCompatibleCheck();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  getWindowsCompatibleCheck(): void {
    if (!this.asset) {
      setTimeout(() => { this.getWindowsCompatibleCheck(); }, 2000);
      return;
    }
    this.ls.display(true);
    this._bs.doRequest(`/r/company/asset_windows_compatibility`, 'get', null,
      { condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`, skip: 0, limit: 20, order_by: 'name asc' })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.windowCompatible = res.data;
            this.windowDataSource.data = res.data;
            this.loadingCon.loading = null; this.loadingCon.nodata = null;
          } else {
            this.windowCompatible = []; this.windowDataSource.data = [];
            this.loadingCon.loading = null; this.loadingCon.nodata = true; this._changeDetectorRef.detectChanges();
          }
          this._changeDetectorRef.detectChanges();
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }
}
