import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { LoaderComponent } from '../loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ModalComponent } from '../modal.component';
import { ModalService } from 'app/_services/modal.service';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseAlertComponent } from '@fuse/components/alert';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-security-report-card',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AppFilterPipeModule, MaterialModule, FuseAlertComponent, ModalComponent, DirectivesModule, FuseScrollbarDirective, LoaderComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './security-report-card.component.html',
  styleUrls: ['./security-report-card.component.scss']
})
export class SecurityReportCardComponent implements OnInit, OnDestroy, AfterViewInit {
  securityReportCard: any = [];
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  @Input() asset: any;
  checkSecurityReport = false; showReportCard = false;
  @ViewChild('reportCardTable', { read: MatSort }) reportCardTableMatSort: MatSort;
  data: any;
  reportCardDataSource: MatTableDataSource<any> = new MatTableDataSource();
  reportCardTableColumns: string[] = ['name', 'evidence', 'suppress', 'grade'];

  @ViewChild('reportCardExTable', { read: MatSort }) reportCardExTableMatSort: MatSort;
  reportCardExDataSource: MatTableDataSource<any> = new MatTableDataSource();
  reportCardExTableColumns: string[] = ['name', 'unsuppress', 'status'];
  secGrade: any = { 5: 'gradea', 4: 'gradeb', 3: 'gradec', 2: 'graded', 1: 'gradef' };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  _security_report: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'security_report', 'value': [] };
  _security_report_global: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'security_report', 'value': [] };
  _security_report_company: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'security_report', 'value': [] };
  _security_report_asset: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'security_report', 'value': [] };
  excludeReportListAll: any = [];
  sSuppressType: 'global' | 'company' | 'asset' | '' = '';
  suppressItem: any = '';
  suppressType: any = '';
  unSuppressData: any = [];
  cStep = 0;
  constructor(private _bs: BaseRequestService, public _cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private ls: LoaderService, private toast: MyToastrService, public _modalService: ModalService,
    public confirmDialog: FuseConfirmationService,) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    // Make the data source sortable
    this.reportCardDataSource.sort = this.reportCardTableMatSort;
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.getSecurityMatrix();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  getComplianceReportCard(): void {
    this.ls.display(true);
    this._bs.doRequest(`/r/reports`, 'get', null,
      {
        skip: 0,
        limit: 20,
        name: 'asset_security_report_data',
        asset_id: this.asset.id,
        company_id: this.asset.company_id
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        this.securityReportCard = [];
        if (res.status) {
          if (res.data && res.data.length) {
            res.data = res.data.filter((x: any) => x.value !== "-1");
            this.securityReportCard = res.data;
            this.showReportCard = (res.data.length > 0) ? true : false;
            this.reportCardDataSource.data = this.securityReportCard;
            // const securityR = res.data.map((x: any) => x.name);
            // if(this._security_report_asset.value && this._security_report_asset.value.length &&  securityR.every((item: any) => !this._security_report_asset.value.includes(item))){
            //   this.unSuppressData = this._security_report_asset.value;
            // }
            // if(this._security_report_company.value && this._security_report_company.value.length &&  securityR.every((item: any) => !this._security_report_company.value.includes(item))){
            //   this.unSuppressData = this._security_report_company.value;
            // }
            // if(this._security_report_global.value && this._security_report_global.value.length &&  securityR.every((item: any) => !this._security_report_global.value.includes(item))){
            //   this.unSuppressData = this._security_report_global.value;
            // }
            this.unSuppressData = [...this._security_report_asset.value, ...this._security_report_company.value, ...this._security_report_global.value];
            this.unSuppressData = [...new Set(this.unSuppressData)];
            this.loadingCon.loading = null; this.loadingCon.nodata = null;
            this._changeDetectorRef.detectChanges();
          } else {

            this.securityReportCard = res.data;
            this.unSuppressData = [...this._security_report_asset.value, ...this._security_report_company.value, ...this._security_report_global.value];
            this.unSuppressData = [...new Set(this.unSuppressData)];
            this.loadingCon.loading = null; this.loadingCon.nodata = true; this._changeDetectorRef.detectChanges();
            this.securityReportCard = []
            this._changeDetectorRef.detectChanges();
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }
  getSecurityMatrix(): void {
    if (this._cs.securityMatrix && this._cs.securityMatrix.antiVirus) {
      this.getExcludeSecurityReport();
      return;
    }
    this.ls.display(true);
    this._bs.doRequest(`/r/company/jsonconfigs/reportcard_template`, 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this._cs.securityMatrix = res.data;
          this.getExcludeSecurityReport();
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }

  getExcludeSecurityReport(): void {
    this.ls.display(true);
    const params: any = { condition: true, skip: 0, limit: 3 };
    params.condition = `((company_id='${parseInt(this.asset.company_id)}' and asset_id IS NULL) or company_id IS NULL or (company_id='${parseInt(this.asset.company_id)}' and asset_id=${this.asset.id})) and name = 'security_report'`;
    if (this._cs.currentScope !== '*') {
      params.condition = `((company_id='${parseInt(this.asset.company_id)}' and asset_id IS NULL) or company_id IS NULL or (company_id='${parseInt(this.asset.company_id)}' and asset_id=${this.asset.id})) and name = 'security_report'`;
    }
    this._bs.doRequest(`/r/company/settings`, 'get', null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          const filterCmp = result.data.filter((s: any) => s.company_id && !s.asset_id);
          const filterGlo = result.data.filter((s: any) => !s.company_id && !s.asset_id);
          const filterAst = result.data.filter((s: any) => s.asset_id && s.company_id);
          this._security_report_global = { ...this._security_report, ...{ id: (filterGlo && filterGlo.length) ? filterGlo[0].id : null, value: (filterGlo && filterGlo.length) ? filterGlo[0].value : this._security_report.value } };
          this._security_report_company = { ...this._security_report, ...{ id: (filterCmp && filterCmp.length) ? filterCmp[0].id : null, value: (filterCmp && filterCmp.length) ? filterCmp[0].value : this._security_report.value } };
          this._security_report_asset = { ...this._security_report, ...{ id: (filterAst && filterAst.length) ? filterAst[0].id : null, value: (filterAst && filterAst.length) ? filterAst[0].value : this._security_report.value } };
          this.getComplianceReportCard();
        } else {
          this.ls.display(false);
          this.getComplianceReportCard();
        }
      })
  }

  updateExcludeReport(item: any, type: any): void {
    this.suppressType = type; this.suppressItem = item.name;
    this._modalService.open('suppressionSecurity');
    this._changeDetectorRef.detectChanges();
  }
  saveSettings(): void {
    let selectedItem: any = cloneDeep(this[`_security_report_${this.sSuppressType}`]['value']);
    if (this.suppressType === 'suppress') {
      selectedItem.push(this.suppressItem);
    } else {
      selectedItem = selectedItem.filter((x: any) => x !== this.suppressItem);
    }
    selectedItem = [...new Set(selectedItem)];
    if (this.sSuppressType === 'global') {
      this[`_security_report_${this.sSuppressType}`] = { ...this[`_security_report_${this.sSuppressType}`], ...{ company_id: null, asset_id: null } };
    } else if (this.sSuppressType === 'company') {
      this[`_security_report_${this.sSuppressType}`] = { ...this[`_security_report_${this.sSuppressType}`], ...{ company_id: (this._cs.currentScope === '*') ? this.asset.company_id : this._cs.currentScope.id, asset_id: null } };
    } else {
      this[`_security_report_${this.sSuppressType}`] = { ...this[`_security_report_${this.sSuppressType}`], ...{ company_id: (this._cs.currentScope === '*') ? this.asset.company_id : this._cs.currentScope.id, asset_id: this.asset.id } };
    }
    if (this[`_security_report_${this.sSuppressType}`]) {
      // @ts-ignore
      const $event: any = this[`_security_report_${this.sSuppressType}`];
      delete $event.hovered; delete $event.highlighted;
      if ($event.id === null) { delete $event.id };
      $event.value = selectedItem;
      this.ls.display(true);
      const method = ($event.id) ? 'patch' : 'post';
      const msg = ($event.id) ? 'Updated' : 'Saved';
      // $event.company_id = (this._cs.currentScope !== '*') ? this._cs.currentScope.id : null;
      const request: any = { data: $event };
      if ($event.id) { request.id = $event.id };
      if ((!selectedItem || !selectedItem.length) && $event.id) {
        this._bs.doRequest(`/d/company/settings/${$event.id}`, 'delete')
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', `Updated successfully!`);
                this.getSecurityMatrix(); this._modalService.close('suppressionSecurity');
                this.sSuppressType = ''; this.suppressItem = ''; this.sSuppressType = ''; this.unSuppressData = [];
                this._changeDetectorRef.detectChanges();
              }, 1000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
              setTimeout(() => {
                this.ls.display(false);
              }, 1000);
            }
          })
      } else {
        this._bs.doRequest('/w/company/settings', method, request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', `${msg} successfully!`);
                this.getSecurityMatrix(); this._modalService.close('suppressionSecurity');
                this.sSuppressType = ''; this.suppressItem = ''; this.sSuppressType = ''; this.unSuppressData = [];
                this._changeDetectorRef.detectChanges();
              }, 1000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
              setTimeout(() => {
                this.ls.display(false);
              }, 1000);
            }
          })
      }

    }
  }

  addUnsuppress(): void {
    this.sSuppressType = ''; this.suppressItem = ''; this.sSuppressType = '';
    this.suppressType = 'unsuppress'; this.cStep = 0; this.gotoBack();
    this._modalService.open('suppressionSecurity');
    this._changeDetectorRef.detectChanges();
  }

  selectChange(item: any): void {
    this.suppressItem = item;
    let count: any = 0; let Sitem: any = '';
    if (this._security_report_global.value && this._security_report_global.value.length && this._security_report_global.value.indexOf(item) > -1) {
      count = count + 1; Sitem = 'global';
    }
    if (this._security_report_company.value && this._security_report_company.value.length && this._security_report_company.value.indexOf(item) > -1) {
      count = count + 1; Sitem = 'company';
    }
    if (this._security_report_asset.value && this._security_report_asset.value.length && this._security_report_asset.value.indexOf(item) > -1) {
      count = count + 1; Sitem = 'asset';
    }
    if (count > 1) {
      this.cStep = 1;
      this._changeDetectorRef.detectChanges();
    } else {
      this.sSuppressType = Sitem;
      this.saveSettings();
    }

  }

  gotoBack(): void {
    this.cStep = 0;
    this._changeDetectorRef.detectChanges();
  }
}
