import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CommonModule, NgFor, NgIf, NgOptimizedImage } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { FuseScrollbarDirective } from "@fuse/directives/scrollbar";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";
import { MaterialModule } from "app/material.module";
import { TableComponent } from "app/modules/shared/table/table.component";
import { MyToastrService } from "../../../_services/toastr.service";
import { ActivatedRoute } from "@angular/router";
import { BaseRequestService } from "app/_services/base.service";
import { CommonService } from "app/_services/common.service";
import { LoaderService } from "app/_services/loader.service";
import { IntegrationsOverviewComponent } from "./integrations-overview/integrations-overview.component";
import { FormsModule } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { Subject, Subscription, takeUntil } from "rxjs";

@Component({
  selector: "app-integrations",
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MaterialModule,
    AppFilterPipeModule,
    TableComponent,
    MatSidenavModule,
    FuseScrollbarDirective,
    IntegrationsOverviewComponent,
    FormsModule,
    NgFor, NgIf
  ],
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
  animations: fuseAnimations,
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  @ViewChild('integrationOverview', { static: false }) integrationOverview: ElementRef;
  cIntegration: any;
  integrations: any = [];
  integrationTypeHash: any = {};
  public integrationSearch: any = "";
  categoryList: any = [];
  subs: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private cs: CommonService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private _bs: BaseRequestService,
    private toast: MyToastrService
  ) {
    this.cs.selectedSiteChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.getAllIntegrations();
      })
  }
  ngOnInit(): void {
    this.getAllIntegrations();
  }

  getAllIntegrations(): void {
    const a = localStorage.getItem('integrations');
    let integrations: any;
    try {
      integrations = JSON.parse(a);
    } catch (e) {
      console.log(e);
    }
    /*if (integrations && integrations.length) {
        this.integrations = integrations;
        this.categoryList = [...new Set(integrations.map((x: any) => x.integrationtype))].sort();
        this.categoryList.forEach((a: any) => {
            this.integrationTypeHash[a] = integrations.filter((x: any) => x.integrationtype === a);
        });
    } else {*/
    this.loaderService.display(true);
    const params: any = {};
    if (this.cs.currentScope !== '*') {
      params.company_id = this.cs.currentScope.id;
    }
    this._bs.doRequest(`/r/integration/get_integrations`, "get", null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.integrations = [];
        if (result.status) {
          localStorage.setItem('integrations', JSON.stringify(result.data));
          const patching = [
            {
              "actions": [],
              "authentication": {},
              "company_id_type": "string",
              "company_mapping_required": true,
              "company_search_type": null,
              "cred_count": 0,
              "cspauthentication": null,
              "description": "Microsoft Intune Integration",
              "display": "Microsoft Intune",
              "enable_configuration": false,
              "header": "{\"Headers\": {\"Content-Type\": \"application/json\"}",
              "hide_search": null,
              "image": "ms-intune",
              "import_agents": true,
              "import_companies": true,
              "import_sites": null,
              "integration_actions": null,
              "integrationtype": "Patch Management",
              "internal": false,
              "mapping_count": 0,
              "name": "Microsoft Intune",
              "notificationfunction": false,
              "rmm_action": null,
              "script_creation": null,
              "token_url": null
            },
            {
              "actions": [],
              "authentication": {},
              "company_id_type": "string",
              "company_mapping_required": true,
              "company_search_type": null,
              "cred_count": 0,
              "cspauthentication": null,
              "description": "Microsoft SCCM Integration",
              "display": "Microsoft SCCM",
              "enable_configuration": false,
              "header": "{\"Headers\": {\"Content-Type\": \"application/json\"}",
              "hide_search": null,
              "image": "ms-sccm",
              "import_agents": true,
              "import_companies": true,
              "import_sites": null,
              "integration_actions": null,
              "integrationtype": "Patch Management",
              "internal": false,
              "mapping_count": 0,
              "name": "Microsoft SCCM",
              "notificationfunction": false,
              "rmm_action": null,
              "script_creation": null,
              "token_url": null
            },
            {
              "actions": [],
              "authentication": {},
              "company_id_type": "string",
              "company_mapping_required": true,
              "company_search_type": null,
              "cred_count": 0,
              "cspauthentication": null,
              "description": "Patch My PC Integration",
              "display": "Patch My PC",
              "enable_configuration": false,
              "header": "{\"Headers\": {\"Content-Type\": \"application/json\"}",
              "hide_search": null,
              "image": "patch-my-pc",
              "import_agents": true,
              "import_companies": true,
              "import_sites": null,
              "integration_actions": null,
              "integrationtype": "Patch Management",
              "internal": false,
              "mapping_count": 0,
              "name": "Patch My PC",
              "notificationfunction": false,
              "rmm_action": null,
              "script_creation": null,
              "token_url": null
            }
          ]
          this.integrations = [...result.data, ...patching];
          this.categoryList = [...new Set(this.integrations.map((x: any) => x.integrationtype.toUpperCase()))].sort();
          this.categoryList.forEach((a: any) => {
            this.integrationTypeHash[a] = this.integrations.filter((x: any) => x.integrationtype.toUpperCase() === a);
          });
        } else {
          this.toast.sToast("error", "Integration not found!");
        }
      });
    // }
  }

  setCIntegration($event: any): void {
    this.cIntegration = undefined;
    this.cIntegration = $event;
    setTimeout(() => {
      this.cs.dynamicScrollTop.next({});
      this.integrationOverview.nativeElement.scrollTo(0, 0);
    }, 100);
  }

  toggleChange($event: any): void {
    this.cIntegration = undefined;
    this.getAllIntegrations();
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // this.subs.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
