<div class="">
    <div class="" *ngIf="!allKeysEmpty">
        <ng-container *ngFor="let item of Objectkeys(cAsset.finger_print); trackBy: trackByFn">
            <div class="ring ring-1 p-2 mx-4 rounded my-2" *ngIf="item && Objectkeys(cAsset.finger_print[item]).length">
                <div class="text-lg font-semibold leading-8 tracking-tight ml-4 mb-2 text-[#F79112]">
                    <i class="fas fa-link mr-1"></i>
                    {{cAsset.ip}} <span
                        *ngIf="cAsset.finger_print[item] && cAsset.finger_print[item]['port']"> - {{cAsset.finger_print[item]['port']}}</span>
                    /{{item}}
                </div>
                <mat-divider class="my-1"></mat-divider>
                <table class="table-auto pl-6">
                    <tbody class="text-sm font-light"
                        *ngFor="let data of Objectkeys(cAsset.finger_print[item]); trackBy: trackByFn">
                        <tr class="border-b bg-white dark:bg-gray-900" *ngIf="cAsset.finger_print[item][data] && cAsset.finger_print[item][data] !== 'null'">
                            <td class="py-1 px-4 text-left text-md font-bold w-[15vw]">
                                <span><i class="fas fa-podcast text-blue-400 pr-2"></i></span>
                                {{data}}
                            </td>
                            <td class="py-1 px-4 text-left font-light">
                                <ng-container
                                    *ngIf="cAsset.finger_print[item][data] && cAsset.finger_print[item][data] !== true && cAsset.finger_print[item][data] !== false">
                                    <span><i class="fas fa-search text-blue-400"></i></span>
                                    <span class="px-2 font-mono break-all"
                                        style="word-break: break-word; white-space: pre-wrap;">
                                        {{cAsset.finger_print[item][data]}}
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="cAsset.finger_print[item][data] !== null && (cAsset.finger_print[item][data] === true || cAsset.finger_print[item][data] === false)">
                                    <span><i class="fas fa-search text-blue-400"></i></span>
                                    <span class="ml-4 text-gray-700 text-sm font-bold rounded-md px-1.5 py-0.5"
                                        [ngClass]="{
                                        'bg-red-200': !cAsset.finger_print[item][data],
                                      'bg-green-200': cAsset.finger_print[item][data]
                                      }">
                                        <span class="text-xs" *ngIf="cAsset.finger_print[item][data]">Yes</span>
                                        <span class="text-xs" *ngIf="!cAsset.finger_print[item][data]">No</span>
                                    </span>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
    <div *ngIf="allKeysEmpty" class="flex flex-col items-center justify-center gap-y-2 h-[40vh]">
        <img src="/assets/images/empty.png" alt="" class="w-20">
        <div> No data available!</div>
    </div>
</div>