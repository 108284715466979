<div class="py-2" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <div class="rounded ring-1  py-2 px-2 my-3">
        <i class="fas fa-certificate m-2"></i><span class="font-light text-xl">Certificates</span>
        <table class="table-auto mb-4 border-b" *ngFor="let cert of certificates;">
            <tbody class="text-sm font-light">
                <tr class="border-b">
                    <td class="py-1 px-4 text-left w-[40%]">Port</td>
                    <td class="py-1 px-4 text-left font-light">
                        <span class="bg-blue-600 text-white rounded-full px-2 py-1">
                            <i class="fas fa-ethernet text-white pr-2"></i>
                            {{cert.port}}
                        </span>
                    </td>
                </tr>
                <tr class="border-b">
                    <td class="py-1 px-4 text-left w-[40%]">Issuer</td>
                    <td class="py-1 px-4 text-left font-light">
                        <i class="fas fa-chevron-right text-blue-400 dark:text-white-400 pr-2"></i>
                        <span>
                            {{decodeHtmlEntities(cert.issuer)}}
                        </span>
                    </td>
                </tr>
                <ng-container *ngFor="let prop of objectKeys(cert)">
                    <tr class="border-b" *ngIf="notAllowedKeys.indexOf(prop) === -1">
                        <td class="py-1 px-4 text-left w-[40%]">{{prop | camelToHuman: true}}</td>
                        <td class="py-1 px-4 text-left font-light break-all">
                            <i class="fas fa-chevron-right text-blue-400 dark:text-white-400 pr-2"></i>
                            <span *ngIf="prop.indexOf('not_valid') > -1">{{cert[prop] | utcToLocale}}</span>
                            <span class="break-all" *ngIf="prop.indexOf('not_valid') === -1 && prop.indexOf('ssl_') === -1">
                                {{cert[prop]}}
                            </span>
                            <span *ngIf="prop.indexOf('not_valid') === -1 && prop.indexOf('ssl_') > -1">
                                <span *ngIf="cert[prop]"
                                    class="bg-red-200 text-red-800 dark:bg-red-600 rounded px-1 dark:text-red-50">Yes</span>
                                <span *ngIf="!cert[prop] && cert[prop] !== null"
                                    class="bg-green-200 text-green-800 dark:bg-green-600 rounded px-1 dark:text-green-50">No</span>
                                <span *ngIf="cert[prop] === null"
                                    class="bg-red-200 text-red-800 dark:bg-red-600 rounded px-1 dark:text-red-50">Already
                                    Expired</span>
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
<ng-container *ngIf="showNoData && certificates && !certificates.length">
    <app-no-data [message]="'No data available!'" [color]="'black'"></app-no-data>
</ng-container>
<!-- <app-loader [loadingCon]="loadingCon" *ngIf="!certificates || certificates.length < 0"></app-loader> -->
