<div class="-mx-4"
    *ngIf="(_userService._user_roles.indexOf('admin') >-1 && (_bs.cUser().included === '*' || _bs.cUser().included === '' ) && !_bs.cUser().excluded) || (!_bs.cUser().included && !_bs.cUser().excluded)">
    <ng-container *ngIf="uView === 'table'">
        <stable class="" aria-label="User Table" role="table" *ngIf="userTableOption.tableOptions && showTable"
            (refreshCallback)="getUsers()" (addCallback)="newUserCall()" [sTableOptions]="userTableOption"
            (apiDataDownload)="apiDataDownload($event)" (hyperlinkCallback)="linkCall($event)"
            (actionCallback)="actioncallback($event)">
        </stable>
    </ng-container>
    <ng-container *ngIf="uView === 'add'">
        <div class="rounded-xl m-2 bg-card p-4">
            <form [formGroup]="contactForm" class="p-4">
                <div class="flex flex-row items-center px-6">
                    <div class="text-2xl font-semibold tracking-tight">Add New User</div>
                    <button class="ml-auto" mat-icon-button (click)="uView = 'table';">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <fuse-alert [appearance]="'border'" class="px-6 my-6" [type]="'info'">
                    Once you add the User here, an initialization email will be sent to the created user's Email
                    Address. Please complete the user initialization to activate the user.
                </fuse-alert>
                <div class="grid grid-cols-2 grid-cols-2 gap-4 px-6 mt-4">
                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>First Name</mat-label>
                            <mat-icon matPrefix class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                            <input matInput [formControlName]="'first_name'" required [placeholder]="'First Name'"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                            <mat-label>Last Name</mat-label>
                            <input matInput [formControlName]="'last_name'" required [placeholder]="'Last Name'">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix class="icon-size-5" [svgIcon]="'mat_solid:email'"></mat-icon>
                            <mat-label>Email</mat-label>
                            <input matInput [formControlName]="'email'" appEmailCheck required [placeholder]="'Email'">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Phone Number</mat-label>
                            <mat-icon matPrefix class="icon-size-5" [svgIcon]="'mat_solid:phone'"></mat-icon>
                            <input matInput type="number" [formControlName]="'mobile'" required
                                [placeholder]="'Phone Number'">
                        </mat-form-field>
                    </div>
                    <mat-form-field class="w-full" *ngIf="false">
                        <mat-label>Password</mat-label>
                        <input id="password" matInput type="password" required [formControlName]="'password'"
                            #passwordField>
                        <button mat-icon-button type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error>
                            Password must contain at least one uppercase letter, one lowercase letter, and one number.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full" *ngIf="false">
                        <mat-label>Password (Confirm)</mat-label>
                        <input id="password-confirm" matInput type="password" required
                            [formControlName]="'passwordConfirm'" #passwordConfirmField>
                        <button mat-icon-button type="button"
                            (click)="passwordConfirmField.type === 'password' ? passwordConfirmField.type = 'text' : passwordConfirmField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="contactForm.get('passwordConfirm').hasError('required')">
                            Password confirmation is required
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('passwordConfirm').hasError('mustMatch')">
                            Passwords must match
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Select Roles</mat-label>
                        <mat-select [multiple]="true" [formControlName]="'roles'" required placeholder="choose roles"
                            (selectionChange)="contactForm.get('roles').setValue(($event.value.includes('admin')) ? ['admin'] : $event.value);">
                            <mat-option disabled *ngIf="roles.length === 0">
                                <span class="dark:text-white">No Roles present</span>
                            </mat-option>
                            <mat-option *ngFor="let role of roles" [value]="role">{{role | uppercase}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="grid grid-cols-2 grid-cols-2 gap-4 px-6 mt-4">
                    <!-- &&  contactForm.get('roles').value.indexOf('admin') === -1 -->
                    <ng-container *ngIf="contactForm.get('roles').value && contactForm.get('roles').value.length">
                        <div class="flex flex-col">
                            <span class="font-semibold pt-2"> Company level access</span>
                            <mat-radio-group class="flex flex-row" [color]="'primary'"
                                [formControlName]="'company_type'">
                                <mat-radio-button class="mr-2" [value]="'include'">
                                    Allowed Companies <span
                                        *ngIf="!contactForm.get('companies').value.includes('*') && contactForm.get('company_type').value === 'include'">({{contactForm.get('companies').value.length}})</span>
                                </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'exclude'">
                                    Denied Companies <span
                                        *ngIf="!contactForm.get('companies').value.includes('*') && contactForm.get('company_type').value === 'exclude'">({{contactForm.get('companies').value.length}})</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                            *ngIf="contactForm.get('company_type').value">
                            <mat-label>Choose Company</mat-label>
                            <mat-select id="IncCmp" [multiple]="true" [formControlName]="'companies'" required
                                (selectionChange)="contactForm.get('companies').setValue(($event.value.includes('*')) ? ['*'] : $event.value);">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="includeControl"
                                        [placeholderLabel]="'Search Company'"
                                        [noEntriesFoundLabel]="'No matching company found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [matTooltip]="'All Companies'" value="*"
                                    *ngIf="contactForm.get('company_type').value === 'include'">All
                                    Companies</mat-option>
                                <mat-option [matTooltip]="company.name"
                                    *ngFor="let company of allCompanies | search: includeControl.value"
                                    [value]="company.id">
                                    {{company.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>
                <div
                    class="flex items-center mt-10 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" type="button" (click)="uView = 'table'">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
                        [disabled]="contactForm.invalid" (click)="saveUser()" [matTooltip]="'Save'">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </ng-container>
</div>
<div class="h-full"
    *ngIf="_userService._user_roles.indexOf('admin') >-1 && ((_bs.cUser().included !== '*' && _bs.cUser().included !== '' ) || _bs.cUser().excluded) && (_bs.cUser().included || _bs.cUser().excluded)">
    <div class="flex flex-col flex-auto items-center justify-center h-full">
        <div class="w-full max-w-md">
            <img src="/assets/images/forbidden.png" alt="Forbidden" width="100%" height="50%">
        </div>
        <div class="mb-4 text-4xl md:text-3xl font-extrabold tracking-tight leading-tight md:leading-none text-center">
            Unauthorized access.</div>
    </div>
</div>
<s-modal id="userRoleEdit">
    <mat-card class="modal w-[67vw]">
        <mat-card-header>
            <mat-card-title class="ml-4 font-bold">
                <!-- <div class="flex flex-col mb-4">
                    <div class="flex flex-row items-center">
                        <span class="text-medium text-secondary mr-2">User Name:</span>
                        <span class="text-medium font-bold">{{currentUser.first_name}} {{currentUser.last_name}}</span>
                    </div>
                    <div class="flex flex-row items-center">
                        <span class="text-medium text-secondary mr-2">Email:</span>
                        <span class="text-medium font-bold">{{currentUser.email}}</span>
                    </div>
                </div> -->
                <div class="text-xl font-semibold tracking-tight">Update User</div>
            </mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('userRoleEdit');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content fuseScrollbar>
            <fuse-alert [appearance]="'border'" class="px-4 mb-2 mt-4" [type]="'info'">
                <span fuseAlertTitle>Important Notice: User Role or Company Access Changes</span>
                <div class="flex flex-col item-center gap-1">
                    <span>If any modifications are made to a user's role or company access, please note that:</span>
                    <span class="font-bold" style="color: #0b67c2">Action Required:</span>
                    <span>Log out and log back in to see the changes take effect.</span>
                    <span class="font-bold" style="color: #0b67c2">Why:</span>
                    <span>This step is necessary to ensure that the updated permissions and access levels are properly
                        applied to the user's account.</span>
                </div>
            </fuse-alert>
            <form class="flex flex-col p-4 overflow-hidden" #updateForm="ngForm">
                <div class="grid grid-cols-2 gap-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>First Name</mat-label>
                        <input matInput [placeholder]="'Enter First Name'" name="fname" required
                            [(ngModel)]="currentUser.first_name">
                    </mat-form-field>
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Last Name</mat-label>
                        <input matInput [placeholder]="'Enter Last Name'" name="lname" required
                            [(ngModel)]="currentUser.last_name">
                    </mat-form-field>
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Email</mat-label>
                        <input matInput [placeholder]="'Enter Email'" name="email" required
                            [(ngModel)]="currentUser.email">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Select Roles</mat-label>
                        <mat-select [multiple]="true" [(ngModel)]="currentUser.roles" name="roles" required
                            (selectionChange)="currentUser.roles = ($event.value.includes('admin')) ? ['admin'] : $event.value;"
                            placeholder="choose roles">
                            <mat-option disabled *ngIf="roles.length === 0">
                                <span class="dark:text-white">No Roles present</span>
                            </mat-option>
                            <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <span class="font-semibold mt-4"> Company level access</span>
                <div class="grid grid-cols-2 gap-4">
                    <ng-container *ngIf="currentUser.roles">
                        <div class="flex flex-col mt-4 gt-sm:mt-0 gt-sm:ml-16">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" required name="aclCompanies"
                                [(ngModel)]="currentUser.company_type">
                                <mat-radio-button class="mr-2" [value]="'include'">
                                    Allowed Companies <span
                                        *ngIf="currentUser && currentUser.companies && !currentUser.companies.includes('*') && currentUser.company_type === 'include'">({{currentUser.companies.length}})</span>
                                </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'exclude'">
                                    Denied Companies <span
                                        *ngIf="currentUser && currentUser.companies && !currentUser.companies.includes('*') && currentUser.company_type === 'exclude'">({{currentUser.companies.length}})</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" *ngIf="currentUser.company_type">
                            <mat-label>Choose Company</mat-label>
                            <mat-select name="Company" id="IncCmp" required [multiple]="true"
                                [(ngModel)]="currentUser.companies"
                                (selectionChange)="currentUser.companies = ($event.value.includes('*')) ? ['*'] : $event.value;">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="includeControl"
                                        [placeholderLabel]="'Search Company'"
                                        [noEntriesFoundLabel]="'No matching company found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [matTooltip]="'All Companies'" value="*"
                                    *ngIf="currentUser.company_type === 'include'">All Companies</mat-option>
                                <mat-option [matTooltip]="company.name"
                                    *ngFor="let company of allCompanies | search: includeControl.value"
                                    [value]="company.id">
                                    {{company.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </ng-container>
                </div>
                <div class="flex items-center mt-10 -mx-6  p-4 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button type="button" [matTooltip]="'Cancel'"
                        (click)="modalService.close('userRoleEdit')">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button type="button" [color]="'primary'" appPreventMultiClick
                        [matTooltip]="'Update'" (click)="updateUserRoles()" [disabled]="updateForm.invalid">
                        Update
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>
<s-modal id="userAPIKey">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="ml-4 font-bold">
                <span class="text-medium font-bold">API Key for the User : {{currentUser.first_name}}
                    {{currentUser.last_name}}</span>
            </mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('userAPIKey');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content fuseScrollbar>
            <div class="flex flex-col mt-4 divide-y ">
                <ng-container *ngFor="let api of apiData; let first = first">
                    <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                        <div class="flex items-center">
                            <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                <ng-container>
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="api.icon">
                                    </mat-icon>
                                </ng-container>
                            </div>
                            <div class="ml-4">
                                <div class="font-medium">
                                    {{api.name}}
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                            <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                <span *ngIf="api.name === 'Client Secret'">{{api.key|ellipsis: 30}}</span>
                                <span *ngIf="api.name !== 'Client Secret'">{{api.key}}</span>
                            </div>
                            <div class="order-1 sm:order-2 sm:ml-3 mb-1">
                                <button mat-icon-button type="button" appPreventMultiClick
                                    (click)="_cs.copyClip(api.key)">
                                    <span>
                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="flex items-center py-4 mb-2 border-t dark:bg-transparent">
                <button class="ml-2" mat-flat-button type="button" [color]="'warn'" appPreventMultiClick
                    [matTooltip]="'Delete API Client'" (click)="deleteAPIKey()">
                    Delete API Client
                </button>
                <button class="ml-auto" mat-button type="button" [matTooltip]="'Close'"
                    (click)="modalService.close('userAPIKey')">
                    Close
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</s-modal>

<!-- Company Detail -->
<s-modal id="CompanyDetails">
    <mat-card class="modal w-[600px]">

        <div class="flex flex-row items-center px-6 my-4">
            <div class="text-xl font-semibold tracking-tight">Company Details</div>
            <button class="ml-auto" mat-icon-button (click)="modalService.close('CompanyDetails');searchTerm = ''">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <mat-card-content fuseScrollbar>
            <div class="bg-blue-100 dark:bg-card text-black dark:text-white flex justify-between items-center px-6 py-4 rounded-t-lg"
                *ngIf="userData">
                <div class="flex items-center">
                    <span class="text font-semibold">
                        {{companyModalTitle}}
                        <span class="font-normal ml-1 bg-blue-500 text-white rounded-full px-2">
                            {{userData.length}}
                        </span>
                    </span>
                </div>
                <mat-form-field class="w-50 mini-form-field ml-1" floatLabel="never">
                    <input matInput name="search" [(ngModel)]="searchTerm" (ngModelChange)="filterCompany()"
                        placeholder="Search Company" autocomplete="off">
                    <button *ngIf="!searchTerm || searchTerm === ''" aria-label="Search" mat-icon-button matSuffix>
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="overflow-auto border border-gray-300 dark:bg-card text-black dark:text-white rounded-b-lg"
                style="max-height: 328px;">
                <div *ngIf="userData && userData.length === 0" class="px-2 py-4 text-center text-gray-500">
                    No company found
                </div>
                <div *ngIf="userData && userData.length > 0">
                    <div class="px-2 py-4 bg-gray-200 border-b border-gray-300 flex sticky top-0 z-10">
                        <div class="w-1/12 flex items-center justify-center">
                            <span class="font-semibold  text-black">Sl.No</span>
                        </div>
                        <div class="w-9/12 flex justify-center items-center">
                            <span class="font-semibold  text-black">Company Name</span>
                        </div>
                    </div>
                    <div *ngFor="let prod of userData; let i = index;"
                        class="px-2 py-1 border-b border-gray-300 flex flex-col">
                        <div class="flex">
                            <div class="w-1/12 flex items-center justify-center">
                                <span class=" dark:text-white">{{i+1}}</span>
                            </div>
                            <div class="w-9/12 flex justify-start items-start ml-[165px]">
                                <span class=" dark:text-white">{{ prod}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</s-modal>