import {
    APP_INITIALIZER,
    EnvironmentProviders,
    importProvidersFrom,
    inject,
    Provider,
} from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import {
    StatehandlerProcessorService,
    StatehandlerProcessorServiceImpl,
} from 'app/services/statehandler-processor.service';
import {
    StatehandlerService,
    StatehandlerServiceImpl,
} from 'app/services/statehandler.service';
import { StorageService } from 'app/services/storage.service';
import {environment} from "../../../environments/environment";

const authProdConfig: AuthConfig = {
    scope: 'openid profile email offline_access urn:zitadel:iam:user:resourceowner',
    responseType: 'code',
    oidc: true,
    clientId: '',
    issuer: environment.authURL,
    redirectUri: `https://${window.location.hostname}/overview`,
    postLogoutRedirectUri: `https://${window.location.hostname}/sign-in`,
    requireHttps: true,
    sessionChecksEnabled: true,
};
const stateHandlerFn = (stateHandler: StatehandlerService) => {
    return () => {
        return stateHandler.initStateHandler();
    };
};

export const provideZitadel = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(
            OAuthModule.forRoot({
                resourceServer: {
                    allowedUrls: [
                        `${environment.authHost}/admin/v1`,
                        `${environment.authHost}/management/v1`,
                        `${environment.authHost}/auth/v1/`,
                    ],
                    sendAccessToken: true,
                },
            })
        ),
        {
            provide: AuthConfig,
            useValue: authProdConfig,
        },
        {
            provide: StatehandlerProcessorService,
            useClass: StatehandlerProcessorServiceImpl,
        },
        {
            provide: StatehandlerService,
            useClass: StatehandlerServiceImpl,
        },
        {
            provide: OAuthStorage,
            useClass: StorageService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: stateHandlerFn,
            multi: true,
            deps: [StatehandlerService],
        },
    ];
};
