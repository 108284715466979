<div class="flex flex-col items-end" fuseScrollbar>
    <mat-form-field appearance="fill" class="mini-form-field w-100">
        <mat-label>Compliance Type</mat-label>
        <mat-select id="selectedComplianceList" class="w-full mat-small round-select" [(ngModel)]="selectedCompliance"
            (ngModelChange)="handleChangeType($event)" placeholder="Choose Compliance">
            <mat-option *ngFor="let compliance of complianceList" [id]="compliance.name"
                [value]="compliance.table_name">
                <span *ngIf="compliance.table_name === 'pcids'" [matTooltip]="'PCI DSS'"
                    [matTooltipPosition]="'right'">PCI
                    DSS</span>
                <span *ngIf="compliance.table_name !== 'pcids'" [matTooltip]="compliance.name |uppercase"
                    [matTooltipPosition]="'right'">
                    {{compliance.name | uppercase}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="m-6" *ngIf="Objectkeys(complianceCount) && Objectkeys(complianceCount).length">
    <mat-button-toggle-group class="flex flex-wrap -m-2" [(ngModel)]="currentStatus" (change)="getCompliance($event)">
        <ng-container>
            <mat-button-toggle class="m-2" [value]="'total_compliant_count'">
                <i class="fas fa-check text-green-400 pr-2" *ngIf="currentStatus === 'total_compliant_count'"></i>
                <span class="text-md font-bold">All Compliant</span>
                <span class="text-md font-bold">({{complianceCount['total_compliant_count']}})</span>
            </mat-button-toggle>
            <mat-button-toggle class="m-2" [value]="'compliant_count'">
                <i class="fas fa-check text-green-400 pr-2" *ngIf="currentStatus === 'compliant_count'"></i>
                <span class="text-md font-bold">Compliant</span>
                <span class="text-md font-bold">({{complianceCount['compliant_count']}})</span>
            </mat-button-toggle>
            <mat-button-toggle class="m-2" [value]="'manual_compliant_count'">
                <i class="fas fa-check text-green-400 pr-2" *ngIf="currentStatus === 'manual_compliant_count'"></i>
                <span class="text-md font-bold">Manual Compliant</span>
                <span class="text-md font-bold">({{complianceCount['manual_compliant_count']}})</span>
            </mat-button-toggle>
            <mat-button-toggle class="m-2" [value]="'manual_non_compliant_count'">
                <i class="fas fa-check text-green-400 pr-2" *ngIf="currentStatus === 'manual_non_compliant_count'"></i>
                <span class="text-md font-bold">Manual Non Compliant</span>
                <span class="text-md font-bold">({{complianceCount['manual_non_compliant_count']}})</span>
            </mat-button-toggle>
            <mat-button-toggle class="m-2" [value]="'non_compliant_count'">
                <i class="fas fa-check text-green-400 pr-2" *ngIf="currentStatus === 'non_compliant_count'"></i>
                <span class="text-md font-bold">Non Compliant</span>
                <span class="text-md font-bold">({{complianceCount['non_compliant_count']}})</span>
            </mat-button-toggle>
        </ng-container>
    </mat-button-toggle-group>
</div>
<stable [sTableOptions]="assetCompTableOptions" (hyperlinkCallback)="linkCall($event)"
    *ngIf="this.showTable && assetCompTableOptions && assetCompTableOptions.tableOptions"></stable>


    <s-modal id="assetComplianceChecks">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="h-[80vh] w-[700px] rounded-xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="_ms.close('assetComplianceChecks');">
                    <mat-icon>close</mat-icon>
                </button>
                <div class="flex flex-col flex-wrap w-full">
                    <div class="flex-wrap">
                        <h2 class="my-2 text-xl font-semibold leading-8 tracking-tight">Compliance Checks Details</h2>
                        <div class="flex flex-row w-full gap-2">
                            <div class="flex flex-col flex-auto w-full mb-2"
                                *ngIf="selectedCompliance !== 'essential_eight'">
                                <div class="flex text-sm text-secondary">
                                    Compliance ID
                                </div>
                                <div class="flex text-sm break-all">
                                    {{SCompliance.benchmark}}
                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-2">
                                <div class="flex text-sm text-secondary">
                                    <span *ngIf="selectedCompliance !== 'essential_eight'">Sub Section</span> <span
                                        *ngIf="selectedCompliance === 'essential_eight'">Mitigation Strategy</span>
                                </div>
                                <div class="flex text-sm break-all">
                                    {{SCompliance.sub_section}}
                                </div>
                                <div class="flex flex-col w-full mt-3" *ngIf="SCompliance.remediation_type == 'Manual'">
                                    <span class="flex text-sm text-secondary">Remediation Steps</span>
                                    <span class="flex text-sm break-all">
                                        {{SCompliance.remediation_steps}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="relative">
                        <div class="flex flex-col w-full px-2 py-2 my-3 rounded ring-1"
                            *ngFor="let plan of complianceCheckMaster">
                            <div class="flex flex-row w-full gap-2">
                                <div class="flex flex-col w-full mb-4">
                                    <div class="flex text-sm text-secondary">
                                        Platform
                                    </div>
                                    <div class="flex text-sm break-all">
                                        {{plan.platform}}
                                    </div>
                                </div>
                                <div class="flex flex-col w-full mb-4">
                                    <div class="flex text-sm text-secondary">
                                        Maturity
                                    </div>
                                    <div class="flex text-sm break-all">
                                        {{plan.maturity}}
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-4">
                                <div class="flex text-sm text-secondary">
                                    Description
                                </div>
                                <div class="flex text-sm break-all">
                                    {{plan.description}}
                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-4" *ngIf="plan.gpo_path">
                                <div class="flex text-sm text-secondary">
                                    GPO Path
                                </div>
                                <div class="flex text-sm break">
                                    {{plan.gpo_path}}
                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-4">
                                <div class="flex text-sm text-secondary">
                                    Command
                                </div>
                                <div class="flex text-sm break-all" [innerHTML]="plan.command | safeHTML">
                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-2">
                                <div class="flex text-sm text-secondary">
                                    Expression
                                </div>
                                <div class="flex text-sm break-all">
                                    <show-more-less *ngIf="plan.expression" [type]="'string'"
                                        [inputString]="plan.expression"></show-more-less>
                                    <div *ngIf="!plan.expression">No data</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </s-modal>