<s-modal id="integrationActions">
  <div class="mx-auto w-full h-full flex max-w-lg flex-col md:max-w-none md:flex-row">
    <div class="w-1/3 h-auto max-w-md rounded-l-3xl
       bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4">
      <p class="mb-10 font-bold tracking-wider">Integration Actions</p>
      <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200">
    </div>
    <div class="w-[500px] h-auto rounded-r-3xl bg-card px-4 py-4">
      <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
        matTooltip="Close" (click)="modalService.close('integrationActions');clearingData()">
        <mat-icon>close</mat-icon>
      </button>
      <h2 class="mb-4 text-xl font-bold ml-4">Integration Actions</h2>
      <mat-divider class="my-2"></mat-divider>
      <form class="flex flex-col h-full w-full" #ticNgForm="ngForm">
        <ng-container *ngIf="sView === 'getIntegrations'">
          <mat-form-field class="w-auto mx-4 mt-4 mb-2" [subscriptSizing]="'dynamic'">
            <mat-label>Integration Mapping Credential</mat-label>
            <mat-select name="IntegrationMappingCredential" required [(ngModel)]="integration.name"
              (selectionChange)="getActions($event)">
              <ng-container *ngFor="let data of integrations">
                <mat-option [value]="data.name">
                  <div class="flex flex-row items-center">
                    <div class="relative flex flex-0 items-center justify-center w-7 h-7 mr-2" *ngIf="data.image">
                      <img alt="{{data.name}}" class="w-full h-full rounded-full object-cover ng-star-inserted"
                        src="/assets/images/providers/provider-{{ data.image }}.png">
                    </div>
                    <div class="text-md mr-2">{{data.display}}</div>
                    <div class="text-md mr-2"> - {{data.credential_name}}</div>
                  </div>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-auto mx-4 mb-2" [subscriptSizing]="'dynamic'">
            <mat-label>Action</mat-label>
            <mat-select name="Action" required [(ngModel)]="integration.action">
              <ng-container *ngFor="let item of currentActions">
                <mat-option [value]="item"> {{item | camelToHuman: true}} </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-auto mx-4 mb-2" [subscriptSizing]="'dynamic'">
            <mat-label>Select Integration Profile</mat-label>
            <mat-select name="Select Integration Profile" [(ngModel)]="integration.integration_rule_id">
              <ng-container *ngFor="let item of integrationProfile">
                <mat-option [value]="item.id"> {{item.name}} </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-auto mx-4 mb-2" [subscriptSizing]="'dynamic'" *ngIf="false">
            <mat-label>Choose Action Template</mat-label>
            <mat-select name="ChooseActionTemplate" required [(ngModel)]="actionTemplate">
              <mat-option value="manual">Manual Action Template</mat-option>
              <mat-option value="default">Default Action Template</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="flex items-center mt-10 py-4 pr-4 pl-1 border-t dark:bg-transparent">
            <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="modalService.close('integrationActions');clearingData()">
              Cancel
            </button>
            <!-- Save -->
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick [matTooltip]="'Next'"
              [disabled]="ticNgForm.invalid" (click)="getAction()">
              Next
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="sView === 'actionParams'">
          <fuse-alert class="w-auto mx-4 py-2" [appearance]="'border'" [type]="'info'" *ngIf="this.inputActionData && this.inputActionData.solution_ids && this.inputActionData.solution_ids.length > 1">
            <span fuseAlertTitle>Please note</span>
            <span class="text-md">You have selected multiple Solutions to create ticket. Upon ticket creation the System Events will
              be created for the number of Solution records selected which will include same description for each
              Event.</span>
          </fuse-alert>
          <app-dynamic-form [isSaveBtn]="false" [isCloseBtn]="false" [spanLayout]="1"
            *ngIf="formElements && formElements.length" (valueUpdate)="actionChange($event)"
            [listOfFormElements]="formElements" [Valuesoutput]="outputValue">
          </app-dynamic-form>
          <div class="flex items-center mt-10 py-4 pr-4 pl-1 border-t dark:bg-transparent">
            <button class="ml-auto" mat-button [matTooltip]="'Back'" (click)="sView = 'getIntegrations';">
              Back
            </button>
            <!-- Save -->
            <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="!disabledSubmit" type="button" appPreventMultiClick
              [matTooltip]="'Submit'" (click)="saveAction()">
              Submit
            </button>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</s-modal>
