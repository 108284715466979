import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { v4 as uuidv4 } from 'uuid';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'import-integration-credentials',
  standalone: true,
  imports: [CommonModule, MaterialModule, DynamicFormComponent, AppFilterPipeModule],
  templateUrl: './integration-credentials.component.html',
  styleUrls: ['./integration-credentials.component.scss']
})
export class ImportIntegrationCredentialsComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Output() updateCredFn = new EventEmitter();
  objectKeys = Object.keys;
  integrationsData: any = [];
  newCred = false;
  credData: any = {};
  credConf: any = {
    showAction: false,
    spanLayout: 2,
    formElements: [],
    closeBtn: 'Close',
    saveBtn: 'Save',
  };
  cred: any = {};

  hide: any = 'Yes';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
     * Constructor
     */
  constructor(
    private _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private cs: CommonService, private loaderService: LoaderService,
    private _bs: BaseRequestService,
    private toast: MyToastrService,
    private _fuseConfirmationService: FuseConfirmationService,
  ) {
  }


  ngOnInit(): void {
    this.initCredentails();
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  initCredentails(): void {
    this.loaderService.display(true);
    const condition = { condition: "integration_name='" + this.currentIntegration.name + "'" };
    this._bs.doRequest(`/r/integration/integration_credentials_view`, 'get', null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.updateCredFn.emit({ result: result.data });
          if (result.data && result.data.length) {
            this.integrationsData = result.data;
            this.credData = result.data[0];
            this.setCurrentCred(result.data[0]);
            this.newCred = false;
          } else {
            this.integrationsData = [];
            this.newCred = true;
            this.credConf.formElements = this.cs.processFormElements(this.currentIntegration.authentication.parameters);
            this._changeDetectorRef.detectChanges();
          }
        } else {
          const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
        }
      })
  }

  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  oAuthCredValidationCSP($event: any): void {
    const rToken = uuidv4();
    let url = this.currentIntegration.token_url;
    url = url.replace("{{tenant_id}}", $event.tenant_id);
    url = url.replace("{{csp_client_id}}", $event.csp_client_id);
    url = url.replace("{{nonce}}", rToken);
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${$event.client_id}`
    ).replace(/=/g, '');
    const urlCSP = `${url}&state=${stateVar}`;
    localStorage.removeItem('oAuthC');
    localStorage.setItem('ncsp', JSON.stringify($event));
    this._bs.o365window = window.open(
      urlCSP,
      'popup',
      'width=600,height=600'
    );
    this.checkIsOAuthCodeCSP();
  }

  checkIsOAuthCodeCSP(): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD CSP config');
      }
      const cred: any = { credential: { ...ncsp } };
      cred.credential.code = oAuthC;
      const data: any = { ...cred };
      data.integrationName = this.currentIntegration.name;
      this.saveADCSP(data);
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => {
      this.checkIsOAuthCodeCSP();
    }, 1000);
  }

  saveADCSP($event: any): void {
    if (this.currentIntegration.isoauth && !$event.credential) {
      this.oAuthCredValidationCSP($event);
      return;
    }
    this.objectKeys($event.credential).forEach((k: any) => {
      if ($event.credential[k] === undefined) {
        $event.credential[k] = null;
      }
    });
    const reqData: any = {
      name: $event.credential.name,
      integration_name: this.currentIntegration.name,
      params: $event.credential,
    }
    this.loaderService.display(true, 'Saving credential...');
    (this.credData.id && !this.newCred) ? reqData.id = this.credData.id : null;
    this._bs.doRequest('/w/integration/store_credential', 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          try {
            this.newCred = false;
            setTimeout(() => {
              let data = Object.assign({}, this.currentIntegration);
              this.currentIntegration = {};
              this.currentIntegration = data;
              this.initCredentails();
            }, 2000);
            this._changeDetectorRef.detectChanges();
          } catch (e) {
            console.log(e);
          }
        } else {
          const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
        }
      });
  }
  saveCred($event: any): void {
    if (this.currentIntegration.name === 'AzureADCSP') {
      this.oAuthCredValidationCSP($event);
      return;
    }
    this.objectKeys($event).forEach((k: any) => {
      if ($event[k] === undefined) {
        $event[k] = null;
      }
    });
    const reqData: any = {
      name: $event.name,
      integration_name: this.currentIntegration.name,
      params: $event
    };
    (this.credData.id && !this.newCred) ? reqData.id = this.credData.id : null;
    this._bs.doRequest('/w/integration/store_credential', 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          try {
            this.newCred = false;
            setTimeout(() => {
              let data = Object.assign({}, this.currentIntegration);
              this.currentIntegration = {};
              this.currentIntegration = data;
              this.initCredentails();
            }, 2000);
            this._changeDetectorRef.detectChanges();
          } catch (e) {
            console.log(e);
          }
        } else {
          const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
        }
      });
  }

  cancel(): void {
    this.initCredentails();
    this._changeDetectorRef.detectChanges();
  }

  setCurrentCred($event: any): void {
    this.credData = $event;
    this.cred = {};
    this.credConf.formElements = this.cs.processFormElements(this.currentIntegration.authentication.parameters);
    this.cred = $event.params; this.cred.name = $event.name;
    this._scrollCurrentStepElementIntoView();
  }

  delCred($event: any): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: 'Delete credential',
      message: 'Are you sure you want to delete this credential? This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete'
        }
      }
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((result) => {

      // If the confirm button pressed...
      if (result === 'confirmed') {
        this._bs.doRequest(`/d/integration/integration_credentials/${$event.id}`, 'delete')
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast('success', 'Credential has been deleted successfully.')
              setTimeout(() => {
                let data = Object.assign({}, this.currentIntegration);
                this.currentIntegration = {};
                this.currentIntegration = data;
                this.initCredentails();
              }, 2000);
            } else {
              const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
            }
          })
        // Delete the list
      }
    });
  }

  addCred(): void {
    this.newCred = true;
    this.cred = {};
    this.credConf.formElements = this.cs.processFormElements(this.currentIntegration.authentication.parameters);
    this._changeDetectorRef.detectChanges();
  }

  /**
     * Scrolls the current step element from
     * sidenav into the view. This only happens when
     * previous/next buttons pressed as we don't want
     * to change the scroll position of the sidebar
     * when the user actually clicks around the sidebar.
     *
     * @private
     */
  private _scrollCurrentStepElementIntoView(): void {
    setTimeout(() => {
      // Reset the element's scroll position to the top
      this._elementRef.nativeElement.scrollTop = 0;
    }, 1000);
  }
}
