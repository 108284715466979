import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MaterialModule } from 'app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { CommonService } from 'app/_services/common.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-backup-softwares',
  standalone: true,

  imports: [CommonModule, FuseAlertComponent, AppFilterPipeModule, FuseScrollbarDirective, DynamicFormComponent, FormsModule, NgIf, NgFor, MaterialModule, ReactiveFormsModule],
  templateUrl: './backup-softwares.component.html',
  styleUrls: ['./backup-softwares.component.scss']
})
export class BackupSoftwaresComponent implements OnInit, OnDestroy {
  hideTable: any = false;
  backupSoftware: any = {
    name: '',
    manufacturer: '',
    year_of_release: null,
    description: '',
    regex: false,
    operation: 'add',
    action: 'backup'
  };
  originalExcludedSoftware: any = { 'category': 'assets', 'sub_category': 'backup', 'name': 'excluded_backup_softwares', 'value': [] }
  originalGlobalSoftware: any = [];
  excludedSoftware: any = [];
  globalSoftware: any = [];
  globalSearch: any = '';
  excludedSearch: any = '';
  selectAll = false;
  selectAllExcluded = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _bs: BaseRequestService, private _cs: CommonService,
    private ls: LoaderService, private toast: MyToastrService,
    public confirmDialog: FuseConfirmationService
  ) { }

  ngOnInit(): void {
    this.getBackupSoftware();
  }
  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleAddMode(hideTable: boolean | null = null): void {
    if (hideTable === null) {
      this.hideTable = !this.hideTable;
    }
    else {
      this.hideTable = hideTable;
    }
    this.backupSoftware = {
      name: '',
      manufacturer: '',
      year_of_release: null,
      description: '',
      regex: false,
      operation: 'add',
      action: 'backup'
    };
    this._changeDetectorRef.markForCheck();
  }
  async saveBackupSoftware(): Promise<any> {
    const softwareNameExists = this.globalSoftware.some((software: any) => software.name === this.backupSoftware.name);
    if (softwareNameExists) {
      this.toast.sToast('info', 'Software Exists With Same Name');
      return;
    }
    const data = cloneDeep(this.backupSoftware);
    delete data.operation;
    delete data.action;
    data.company_id = null;
    this.ls.display(true);
    if (this._cs.currentScope !== '*') {
      data.company_id = parseInt(this._cs.currentScope.id);
    }
    const reqData = { data: data };
    const res = await this._bs.doRequest(`/w/company/backup_software`, 'post', reqData).toPromise();
    this.ls.display(false);
    if (res.status) {
      this.updateBackupSoftware();
      this.hideTable = false; this._changeDetectorRef.detectChanges();
    } else {
      const data = (res.message) ? res.message : res.data;
      this.toast.sToast('error', data);
    }
  }

  async updateBackupSoftware(): Promise<any> {
    const data = cloneDeep(this.backupSoftware);
    data.name = [data.name];
    delete data.manufacturer;
    delete data.year_of_release;
    delete data.edr_description;
    delete data.regex;
    data.company_id = null;
    this.ls.display(true);
    if (this._cs.currentScope !== '*') {
      data.company_id = parseInt(this._cs.currentScope.id);
    }
    const reqData = { data: data };
    const res = await this._bs.doRequest(`/w/company/update_backup`, 'post', reqData).toPromise();
    this.ls.display(false);
    if (res.status) {
      this.toast.sToast('success', ' Added successfully!');
      this.getBackupSoftware();
      this.resetAgent(reqData.data.company_id);
      this.hideTable = false; this._changeDetectorRef.detectChanges();
    } else {
      const data = (res.message) ? res.message : res.data;
      this.toast.sToast('error', data);
    }
  }

  async getBackupSoftware(): Promise<any> {
    try {
      this.ls.display(true);
      const params = { company_id: (this._cs.currentScope !== '*') ? parseInt(this._cs.currentScope.id) : undefined };
      const response = await this._bs.doRequest(`/r/company/backup_software_list`, 'get', null, params).toPromise();
      this.ls.display(false);
      if (response.status) {
        this.originalGlobalSoftware = response.data;
        try {
          const params: any = { condition: true, skip: 0, limit: 1 }
          if (this._cs.currentScope === '*') {
            params.condition = `company_id IS NULL and category='assets' and name='excluded_backup_softwares'`;
          } else {
            params.condition = `company_id=${parseInt(this._cs.currentScope.id)} and category='assets' and name='excluded_backup_softwares'`;
          }
          this.ls.display(true);
          const res = await this._bs.doRequest(`/r/company/settings`, 'get', null, params).toPromise();
          this.ls.display(false);
          if (res.status) {
            this.selectAll = false; this.globalSearch = null;
            this.selectAllExcluded = false; this.excludedSearch = null;
            if (res.data.length) {
              try {
                this.originalExcludedSoftware = { ...this.originalExcludedSoftware, ...{ id: res.data[0].id, value: res.data[0].value } };
                const value: any = cloneDeep(res.data[0].value);
                this.excludedSoftware = value.map((item: any) => ({ name: item, selected: false }));
                this.globalSoftware = this.originalGlobalSoftware.filter((s: any) => value.indexOf(s.name) === -1);
                this.globalSoftware.map((x: any) => x.selected = false);
                this._changeDetectorRef.detectChanges();
              } catch (e) {
                console.log(e);
              }
            } else {
              this.globalSoftware = this.originalGlobalSoftware;
              this.globalSoftware.map((x: any) => x.selected = false);
              this.excludedSoftware = [];
              this._changeDetectorRef.detectChanges();
            }
          } else {
            const data = (res.message) ? res.message : res.data;
            this.toast.sToast('error', data);
          }
        } catch (error) {
          // Handle errors here
          console.log(error);
        }
      } else {
        this.toast.sToast('error', response.message);
      }
    } catch (error) {
      // Handle errors here
      console.log(error);
    }
  }
  removeBackupSoftware(software: any): void {
    const confirmation = this.confirmDialog.open({
      title: "Remove Excluded Backup Software",
      message: "Please confirm if you want to remove the selected Backup Software from the excluded list?",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        this.originalExcludedSoftware.value = this.originalExcludedSoftware.value.filter((x: any) => x !== software);
        const $event: any = this.originalExcludedSoftware;
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', 'patch', request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected Backup Software is successfully removed from the Excluded list.');
                this.getBackupSoftware();
                this.resetAgent(request.data.company_id);
                this._changeDetectorRef.markForCheck();
              });
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }

    })
  }

  changeExclude(software: any) {
    const confirmation = this.confirmDialog.open({
      title: "Exclude Global Backup Software",
      message: "Please confirm if you want to exclude the selected Backup Software?'",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        if (this.originalExcludedSoftware.value && this.originalExcludedSoftware.value.length) {
          this.originalExcludedSoftware.value.push(software.name);
        } else {
          this.originalExcludedSoftware.value = [software.name];
        }
        const $event: any = this.originalExcludedSoftware;
        const method = ($event.id) ? 'patch' : 'post';
        const msg = ($event.id) ? 'Updated' : 'Saved';
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', method, request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected Backup software is excluded successfully');
                this.getBackupSoftware();
                this.removeBackupSoftware1([software.name]);
                this._changeDetectorRef.markForCheck();
              });
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }
    })
  }

  changeBulkExclude(software: any) {
    const confirmation = this.confirmDialog.open({
      title: "Exclude Global Backup Software",
      message: "Please confirm if you want to exclude the selected Backup Software?'",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        if (this.originalExcludedSoftware.value && this.originalExcludedSoftware.value.length) {
          this.originalExcludedSoftware.value = [...this.originalExcludedSoftware.value, ...this.globalSoftware.filter((x: any) => x.selected).map((y: any) => y.name)];
        } else {
          this.originalExcludedSoftware.value = this.globalSoftware.filter((x: any) => x.selected).map((y: any) => y.name);
        }
        const $event: any = this.originalExcludedSoftware;
        const method = ($event.id) ? 'patch' : 'post';
        const msg = ($event.id) ? 'Updated' : 'Saved';
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', method, request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected Backup software is excluded successfully');
                this.getBackupSoftware();
                this.resetAgent(request.data.company_id);
                this.removeBackupSoftware1(this.originalExcludedSoftware.value);
                this._changeDetectorRef.markForCheck();
              });
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }
    })
  }
  async removeBackupSoftware1(name: any): Promise<any> {
    const data = cloneDeep(this.backupSoftware);
    data.name = name;
    data.operation = 'removed';
    data.action = 'backup'
    delete data.description;
    delete data.manufacturer;
    delete data.regex;
    delete data.year_of_release;
    this.ls.display(true);
    if (this._cs.currentScope !== '*') {
      data.company_id = parseInt(this._cs.currentScope.id);
    }
    const reqData = { data: data };
    const res = await this._bs.doRequest(`/w/company/update_backup`, 'post', reqData).toPromise();
    this.ls.display(false);
    if (res.status) {
      this.resetAgent(reqData.data.company_id);
    } else {
      const errorData = (res.message) ? res.message : res.data;
      this.toast.sToast('error', errorData);
    }
  }

  removeExclude(software: any): void {
    const confirmation = this.confirmDialog.open({
      title: "Backup Software",
      message: "Please confirm if you want the Backup Software removed from the excluded list.",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        this.originalExcludedSoftware.value = this.originalExcludedSoftware.value.filter((x: any) => x !== software);
        const $event: any = this.originalExcludedSoftware;
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', 'patch', request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              const sId = this.originalGlobalSoftware.filter((x: any) => x.name === software);
              if (sId && sId.length) {
                if (sId[0].id !== 'default') {
                  if ((this._cs.currentScope === '*' && !sId[0].company_id)) {
                    this._bs.doRequest(`/d/company/backup_software/${parseInt(sId[0].id)}`, 'delete')
                      .pipe(takeUntil(this._unsubscribeAll))
                      .subscribe((result: any) => { });
                  }
                }
              }
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected Backup software successfully removed from excluded list.');
                this.getBackupSoftware();
                this.resetAgent(request.data.company_id);
                this._changeDetectorRef.markForCheck();
              }, 1000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }

    })
  }

  removeBulkExclude(software: any): void {
    const confirmation = this.confirmDialog.open({
      title: "Backup Software",
      message: "Please confirm if you want the Backup Software removed from the excluded list.",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        const software: any = this.excludedSoftware.filter((x: any) => x.selected).map((y: any) => y.name);
        this.originalExcludedSoftware.value = this.originalExcludedSoftware.value.filter((x: any) => software.indexOf(x) === -1);
        const $event: any = this.originalExcludedSoftware;
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', 'patch', request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              const eId: any = this.originalGlobalSoftware.filter((x: any) => software.indexOf(x.name) !== -1 && x.id !== 'default');
              if (eId && eId.length) {
                const reqData: any = {
                  ids: eId.map((x: any) => parseInt(x.id))
                }
                if (this._cs.currentScope !== '*') {
                  reqData.ids.filter((x: any) => x.company_id === parseInt(this._cs.currentScope.id));
                }
                if (reqData.ids && reqData.ids.length) {
                  this._bs.doRequest(`/d/company/backup_software/bulk_delete`, 'post', reqData)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => { });
                }
              }
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected Backup software successfully removed from excluded list.');
                this.getBackupSoftware();
                this.resetAgent(request.data.company_id);
                this._changeDetectorRef.markForCheck();
              }, 1000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }

    })
  }
  resetAgent(company_id: any): void {
    const reqData: any = {
      company_id: company_id,
      message: "edr_backup",
    };
    this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }

  hasSelected(): boolean {
    const len = this.globalSoftware.filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  isAllSelected(): boolean {
    if (this.selectAll) {
      return true;
    }
    const len = this.globalSoftware.filter((x: any) => x.selected).length;
    if (this.globalSoftware.length === len) {
      return true;
    }
    return false;
  }

  selectAllToggle(): void {
    this.globalSoftware.forEach((obj: any) => {
      obj.selected = this.selectAll;
    });
  }

  hasExcludedSelected(): boolean {
    const len = this.excludedSoftware.filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  isAllExcludedSelected(): boolean {
    if (this.selectAllExcluded) {
      return true;
    }
    const len = this.excludedSoftware.filter((x: any) => x.selected).length;
    if (this.excludedSoftware.length === len) {
      return true;
    }
    return false;
  }

  selectAllExcludedToggle(): void {
    this.excludedSoftware.forEach((obj: any) => {
      obj.selected = this.selectAllExcluded;
    });
  }
}
