import {Directive, ElementRef, HostListener} from '@angular/core';
import {MyToastrService} from "../_services/toastr.service";

@Directive({
  selector: '[appZitaPassCheck]'
})
export class ZitapassCheckDirective {

  constructor(private el: ElementRef, private toast: MyToastrService) {
  }

  PASSWORD_REGX = new RegExp('^(?=.*[!@#$%^&*()_+{}|:<>?~\\[\\]\\-\\\\=`;\',.\\/])(?=.*\\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$');

  @HostListener('focusout')

  onFocusOut(): boolean {
    const current: string = this.el.nativeElement.value;
    if (current !== '') {
      if (current && !String(current).match(this.PASSWORD_REGX)) {
        this.toast.sToast('error', 'Incorrect Password! Has to be at least 8 characters long.\n' +
            'Must include a symbol or punctuation mark.\n' +
            'Must include a digit.\n' +
            'Must include an uppercase character.\n' +
            'Must include a lowercase character.');
        this.el.nativeElement.focus();
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
}
