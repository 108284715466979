<div class="flex flex-col flex-auto p-2" *ngIf="showTagFilter">
  <ng-container>
    <div class="w-[80%] sm:min-w-full">
      <div class="flex justify-between items-center p-4">
        <div class="flex space-x-4">
          <div
            class="px-3 py-2 text-md font-semibold rounded ring-1 ring-gray-300 hover:bg-purple-400 hover:text-white cursor-pointer"
            [ngClass]="{'bg-primary text-white': rView === 'include'}" (click)="setRView('include')">
            Include Filter Tag
          </div>
          <div
            class="px-3 py-2 text-md font-semibold rounded ring-1 ring-gray-300 hover:bg-purple-400 hover:text-white cursor-pointer"
            [ngClass]="{'bg-primary text-white': rView === 'exclude'}" (click)="setRView('exclude')">
            Exclude Filter Tag
          </div>
        </div>
      </div>
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-icon matPrefix [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <mat-chip-grid #chipGrid aria-label="Enter Keywords">
          <mat-chip-row *ngFor="let key of selectedTags" (removed)="remove(key)"
            [aria-description]="'press enter to edit ' + key">
            {{key}}
            <button matChipRemove [attr.aria-label]="'remove ' + key">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input [placeholder]="'Search...'" #chipInput [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete" [matChipInputFor]="chipGrid" (keydown)="onKeydown($event)"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur">
        </mat-chip-grid>
      </mat-form-field>
      <mat-autocomplete class="max-h-128 mt-1 rounded" [autoSelectActiveOption]="false" [disableRipple]="true"
        #matAutocomplete="matAutocomplete">
        <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
          *ngIf="resultSets && !resultSets.length">
          No results found!
        </mat-option>
        <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
          <mat-optgroup class="flex items-center mt-2 px-2">
            <span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
          </mat-optgroup>
          <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
            <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
              [value]="result">
              <!-- Tags -->
              <ng-container *ngIf="resultSet.id === 'tags'">
                <ng-container *ngTemplateOutlet="tagResult; context: {$implicit: result}"></ng-container>
              </ng-container>
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-autocomplete>
      <div class="flex flex-row items-center mt-4">
        <button class="e-btn e-primary e-qb-button" (click)="onSubmit()">Submit</button>
        <button class="e-btn e-warning e-qb-button mx-2" (click)="resetTagRules()">Reset</button>
        <button class="e-btn e-secondary e-qb-button mx-2" (click)="toggleTagFilter()">Close</button>
      </div>
    </div>
  </ng-container>
  <!-- Tag list result template -->
  <ng-template #tagResult let-result>
    <div class="flex flex-col">
      <div class="truncate leading-normal">{{result.title}}: {{result.value}}</div>
      <!-- <div class="truncate leading-normal text-sm text-secondary">
            {{result.link}}
        </div> -->
    </div>
  </ng-template>
</div>