import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {permissions as rolePermission, User} from 'app/core/user/user.types';
import {catchError, map, Observable, of, ReplaySubject, Subject, take, takeUntil, tap} from 'rxjs';
import {assign, cloneDeep} from 'lodash-es';

@Injectable({providedIn: 'root'})
export class UserService {
    public userd$: Observable<any>;
    _user: ReplaySubject<any> = new ReplaySubject<any>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _permissions = rolePermission;
    _user_roles: any = [];

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: any) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<any> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<any> {
        return this.userd$.pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
        /* return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        ) */
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Get the current logged in user data
     */
    hasPermission(tname: any) {
        if (!tname) {
            return true
        }
        try {
            const rolenames = this._user_roles;
            const permissions = cloneDeep(this._permissions);
            const result = rolenames.flatMap((key: any) => permissions[key] || []);
            return rolenames.includes('admin') || rolenames.includes('itadmin') || result.includes((tname) ? tname.toLowerCase() : '');
        } catch (error) {
            return false;
        }
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: any): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
