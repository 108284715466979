import { Component, EventEmitter, Input, Output, OnChanges, ChangeDetectorRef, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule,Validators} from '@angular/forms';
import { MaterialModule } from "../../../../material.module";
import { NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AppFilterPipeModule } from "../../../../_filters/app.filter-pipe.module";
import { FuseAlertComponent } from "../../../../../@fuse/components/alert";
import { MyToastrService } from "../../../../_services/toastr.service";
import { DynamicQueryBuildersComponent } from '../dynamic-query-builders/dynamic-query-builders.component';
import { QueryBuilderComponent } from '../query-builder/query-builder.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { TagListComponent } from "../../tag-list/tag-list.component";
import { DirectivesModule } from 'app/-directives/-directives.module';
import { CommonService } from 'app/_services/common.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';

@Component({
  selector: 'app-dynamic-control',
  standalone: true,
  templateUrl: './dynamic-control.component.html',
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgSwitch,
    NgSwitchCase,
    MatChipsModule,
    NgForOf,
    NgIf,
    NgxMatSelectSearchModule,
    AppFilterPipeModule,
    FuseAlertComponent,
    QueryBuilderComponent, DirectivesModule,
    NgClass, DynamicQueryBuildersComponent, FuseScrollbarDirective, TagListComponent
  ],
  styleUrls: ['./dynamic-control.component.scss'],
})
export class DynamicControlComponent implements OnChanges {
  loading: boolean = false
  trigger: number = 1
  @Input() forms: any;
  @Input() formElement: any;
  @Output() companyChange = new EventEmitter();
  @Output() valChanges = new EventEmitter();
  @Output() querysChange = new EventEmitter();
  @Input() listOfFormElements: any;
  @Input() spanLayout = 1;
  @Input() outputValue: any;

  multipletexts: any = "";
  public multipletextsform: FormData = new FormData()
  constructor(private _ChangeDetectorRef: ChangeDetectorRef,
    private toast: MyToastrService, private _bs: BaseRequestService, private _ls: LoaderService, private _changeDetectorRef:ChangeDetectorRef,
    private _cs: CommonService) {
  }

  searching = false;
  public entityFilterCtrl: FormControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  removable = true;
  selectable = true;
  tempPassword : string = '';

  @ViewChild('menuRefMenuTrigger', { static: false })
  menuRefMenuTrigger: MatMenuTrigger;
  @ViewChild('searchMenuTrigger', { static: false }) searchMenuTrigger: MatMenuTrigger;
  searchActionParams: any = {};
  searchFormElements: any = [];
  searchParamsList: any = [];
  Objectkeys = Object.keys;
  searchParams: any = {
    pageSize: 100,
    page: 0,
    name: 'a',
    SR_Board_RecID: '',
    SR_Type_RecID: '',
    SR_SubType_RecID: ''
  };
  get isValid(): any {
    // @ts-ignore
    return this.forms.controls[this.formElement.key].valid;
  }
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  ngOnInit(): void {
    if (this.formElement && this.formElement.key === 'name') {
      this.forms.controls[this.formElement.key].setValidators([
          Validators.required,
          this.noWhitespaceValidator
      ]);
  }
    this.forms.statusChanges
      .pipe()
      .subscribe(() => this.onFormValid(this.forms.valid));

    if (this.formElement.search_element) {
      this.searchActionParams = this.formElement.search_element;
    }
  }
  private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    return !isWhitespace ? null : { whitespace: true };
}

  updateKey(value: any, key: any): void {
    this.outputValue[key] = value;
    this.forms.controls[key].setValue(value);
  }

  copyClip(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      this.toast.sToast('success', 'Copied to clipboard');
    } catch (err) {
      this.copyClipApi(text);
      console.error('Unable to copy text to clipboard: ', err);
    } finally {
      document.body.removeChild(textarea);
    }
  }

  copyClipApi(text: string): void {
    const clipboardItem = new ClipboardItem({ "text/plain": new Blob([text], { type: "text/plain" }) });

    // Access the Clipboard API and write the clipboard item
    navigator.clipboard.write([clipboardItem])
      .then(() => {
        this.toast.sToast('success', 'Copied to clipboard');
      })
      .catch((err) => {
        this.toast.sToast('error', 'Unable to copy text to clipboard: ', err);
      });
  }
  private onFormValid(status: any) {
    this._cs.formValidationEVE.next(status);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  /*ngOnChanges(): void {
    if (this.form === undefined) {
      this.form = this.formControlService.toFormControl(this.formElement);
    }
  }*/

  onKeydownMain(e: any): void {
    let i = 0;
    if (e.target.value.length < 1) {
      if (e.keyCode === 32) {
        return;
      }
    } else {
      if (e.keyCode === 32) {
        if (i !== 0) {
          return;
        }
        i++;
      } else {
        i = 0;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true; 
    setTimeout(() => {
      this.loading = false
      this._ChangeDetectorRef.detectChanges();
    }, 500);

    if (this.formElement && this.formElement.type == 'ip') {
      this.forms.controls[this.formElement.key].valueChanges.subscribe(newValue => {
        this.checkip(newValue)
      })
    }
    this._ChangeDetectorRef.detectChanges();
  }

  selectedFile(file: any, key: any): void {
    this.outputValue[key] = file;
  }

  callBack($event: any, callback: boolean, formElement: any): void {
    this.loading = true
    if (callback) {
      this.outputValue[formElement] = $event;
      this.trigger = this.trigger + 1
      var data = { value: $event, key: formElement.key, changedvalue: $event, field: formElement.key, datapassed: formElement, form: this.forms }
      this.valChanges.emit(data);
      this._ChangeDetectorRef.detectChanges()
      this.loading = false
    }
  }

  querychanges(updatedRules: any, formElement: any, outputValue: any): void {
    outputValue[formElement.name] = updatedRules
    this.forms.controls[formElement.name].setValue(updatedRules)
    // formElement.key = updatedRules
    this._ChangeDetectorRef.detectChanges()
    // this.form.controls[this.formElement.key].valid
    this.querysChange.emit(updatedRules);
  }

  checkip(event: any) {
    if (this.outputValue[this.formElement.condition] && this.formElement.condition == 'domain') {
      const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = domainPattern.test(event);
      if (!valid) {
        this.formElement.errmsg = "Invalid Domain address entered"
        this.forms.controls[this.formElement.key].setErrors({ 'invalid': true });
      }
    } else if (this.outputValue[this.formElement.condition] && (this.formElement.condition.toLowerCase() == 'staticip' || this.formElement.condition.toLowerCase() == 'ip')) {
      const valid = this.isIPInRange(event, "0.0.0.0", "255.255.255.255")
      if (!valid) {
        this.formElement.errmsg = "Invalid IP address entered"
        this.forms.controls[this.formElement.key].setErrors({ 'invalid': true });
      }
    }
    else if (this.outputValue[this.formElement.condition] && this.formElement.condition.toLowerCase() == 'iprange') {
      var ips = event.split("-")
      if (ips.length == 2) {
        let invalidlen = []
        ips.forEach(ele => {
          const valid = this.isIPInRange(ele, "0.0.0.0", "255.255.255.255")
          if (!valid) {
            this.formElement.errmsg = "Invalid IP Range entered"
            this.forms.controls[this.formElement.key].setErrors({ 'invalid': true });
            invalidlen.push('Invalid')
          }
        })
        if (invalidlen.length > 0) {
          this.formElement.errmsg = "Invalid IP Range entered"
          this.forms.controls[this.formElement.key].setErrors({ 'invalid': true });
        }
      } else {
        this.formElement.errmsg = "Invalid IP Range entered"
        this.forms.controls[this.formElement.key].setErrors({ 'invalid': true });
      }
    } else if (this.outputValue[this.formElement.condition] && this.formElement.condition.toLowerCase() == 'cidr') {
      let invalidlen = []
      const ipParts = event.split('/');
      const valid = this.isIPInRange(ipParts[0], "0.0.0.0", "255.255.255.255")
      if (!valid) {
        invalidlen.push('invalid')
      }
      const Pattern = /^\d+$/
      if (!Pattern.test(ipParts[1]) || parseInt(ipParts[1]) < 18 || parseInt(ipParts[1]) > 32) {
        invalidlen.push('invalid')
      }
      if (invalidlen.length > 0) {
        this.formElement.errmsg = "Invalid CIDR entered"
        this.forms.controls[this.formElement.key].setErrors({ 'invalid': true });
      }

    }
  }

  isIPInRange(ipAddress: any, startRange: any, endRange: any) {
    const ipParts = ipAddress.split('.');
    const startRangeParts = startRange.split('.');
    const endRangeParts = endRange.split('.');

    // Convert IP address parts to integers
    const ipInt = ipParts.map(Number);
    const startRangeInt = startRangeParts.map(Number);
    const endRangeInt = endRangeParts.map(Number);

    // Check if the IP address is within the specified range
    if (ipParts.length == 4) {
      for (let i = 0; i < 4; i++) {
        const Pattern = /^\d+$/
        if (!Pattern.test(ipInt[i]) || ipInt[i] < startRangeInt[i] || ipInt[i] > endRangeInt[i]) {
          return false;
        }
      }
    }
    else {
      return false
    }

    return true;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.outputValue[this.formElement.key].push(value);
    }
    event.chipInput!.clear();
  }

  remove(event: any): void {
    const index = this.outputValue[this.formElement.key].indexOf(event);

    if (index >= 0) {
      this.outputValue[this.formElement.key].splice(index, 1);
    }
  }

  edit(text: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(text);
      return;
    }
    const index = this.outputValue[this.formElement.key].indexOf(text);
    if (index >= 0) {
      this.outputValue[this.formElement.key][index] = value;
    }
  }

  removeitem(index: any, formelement: any, outputValue: any) {
    outputValue[formelement.key] = outputValue[formelement.key].filter(item => item !== outputValue[formelement.key][index]);
    this._ChangeDetectorRef.detectChanges();
  }

  addItem(value: any, formelement: any, outputValue: any) {
    outputValue[formelement.key].push(value)
    this.forms.controls[this.formElement.key].setValue(outputValue[formelement.key])
    this.multipletexts = "";
  }

  getSearchParameter(): void {
    this._ls.display(true, `Getting ${this.formElement.search_element.name}...`);
    let requestParams: any = {}; let action_params: any = {};
    if (this.formElement.search_element.parameters && this.formElement.search_element.parameters.parameters) {
      this.formElement.search_element.parameters.parameters.forEach((pObj: any) => {
        requestParams[pObj.name] = this.formElement.searchParams[pObj.name] || pObj.default;
      });
    }
    if (this.formElement.search_element.source && this.formElement.search_element.depends && this._cs.getDependSource(this.formElement.search_element.depends, this.outputValue)) {
      action_params = (this.formElement.search_element.source.parameter && this.formElement.search_element.source.parameter.length) ? this.formElement.search_element.source.parameter.reduce((result: any, key: any, index: any) => {
        result[key] = this.outputValue[this.formElement.search_element.depends[index]];
        return result;
      }, {}) : {};
    }
    requestParams = { ...requestParams, ...action_params };
    const params: any = {
      integration_id: this.formElement.search_element.integration_id,
      integration_name: this.formElement.search_element.integration_name,
      action_name: this.formElement.search_element.action_name,
      requestparams: requestParams
    }
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      this.formElement.options = [];
      if (item.status) {
        item.data.forEach((val: any) => {
          this.formElement.options.push({ key: val['name'] + '', value: val['id'] + '' });
        });
      } else {
        const data = (item.message) ? item.message : item.data;
        this.toast.sToast('error', data);
      }
    })
  }

  searchParamsFn(): void {
    if (this.formElement.search_element.parameters && this.formElement.search_element.parameters && this.formElement.search_element.parameters.parameters.length) {
      this.formElement.searchParams = (this.formElement.searchParams && this.Objectkeys(this.formElement.searchParams).length > 0) ? this.formElement.searchParams : {};
      this.formElement.search_element.parameters.parameters.forEach((obj: any) => {
        this.formElement.searchParams[obj.name] = this.formElement.searchParams[obj.name] ? this.formElement.searchParams[obj.name] : obj.default;
      });
    }
    this.formElement.searchParamsList = this.formElement.search_element?.parameters.parameters?.map((obj: any) => obj.name);
    this.searchMenuTrigger.openMenu();
  }

  clearPassword(pwd : any):void{
    this.tempPassword = pwd.target.value;
    // this.cred.password = '';
    // make the password as empty strin in html
    this.outputValue['password'] = '';
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
}

restorePassword(pwd : any):void{
    if (pwd.target.value === '') {
      // Assign the password to the field from tempassword
        // this.cred.password = this.tempPassword;
        this.outputValue['password'] = this.tempPassword;
        this._changeDetectorRef.detectChanges();
        this._changeDetectorRef.markForCheck();
    }
}
}
