<div class="bg-red-200 text-red-800 rounded"  [ngClass]="{
 'w-[100vw]': ( !cs.isNavigationOpen && (cs.checkLayout === 'centered' || cs.checkLayout === 'enterprise' || cs.checkLayout === 'material'  )), 
 'w-[95vw]':cs.isNavigationOpen }" *ngIf="show">     
   <div class="px-1 py-0.5 sm:px-1 sm:py-0.5 lg:px-3 text-sm">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex w-0 flex-1 items-center">
            <p class="text-center font-medium leading-5 sm:text-left">
              <span class="flex flex-row items-center" [ngClass]="{'w-[77vw]': ( !cs.isNavigationOpen && (cs.checkLayout === 'classic' || cs.checkLayout === 'classy' || cs.checkLayout === 'futuristic')), 'w-[97vw]': ( !cs.isNavigationOpen && !(cs.checkLayout === 'classic' || cs.checkLayout === 'classy' || cs.checkLayout === 'futuristic'||  cs.checkLayout === 'thin' || cs.checkLayout === 'dense' ||  cs.checkLayout === 'thin' || cs.checkLayout === 'dense' )), 'w-[95vw]':cs.isNavigationOpen }">                 
                 <span class="rounded-md bg-rose-400 px-2 text-white">Attention</span>
                  <marquee onmouseover="this.stop();" onmouseout="this.start();"
                      class="font-xs">
                      A critical vulnerability identified as
                      <span *ngFor="let ccve of cs.tcve;"> {{ccve.description}} ({{ccve.cve}}),</span>
                      which is currently being exploited.
                    </marquee>
                    <div class="flex col items-center">
                        <span (click)="searchCVE()"
                            class="rounded-md bg-rose-400 px-2 text-white ring-1 cursor-pointer">
                            <i class="fas fa-info-circle"></i>
                        </span>
                    </div>
              </span>
            </p>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button type="button" (click)="closeNotify()" matTooltip="Close"
                    class="m-0.5 mini-form-field -mr-1 flex rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
