import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { UserService } from 'app/core/user/user.service';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { cloneDeep } from 'lodash';
import { STableComponent } from 'app/modules/shared/s-table/s-table.component';

@Component({
  selector: 'app-remediated-details',
  standalone: true,
  imports: [CommonModule, TableComponent, STableComponent],
  templateUrl: './remediated-details.component.html',
  styleUrls: ['./remediated-details.component.scss']
})
export class RemediatedDetailsComponent {
  remediatedTableOptions: any = {}
  private subs: Subscription;
  asset_id: any;
  @Input() mode: any;
  @Input() asset_name: any;
  @Input() cAsset: any;
  showTable = false;
  originalReRecordData: any;
  // remeTableOptions: any = {};

  cView: any = 'remediated_record';
  currentData: any = {};
  asseTableOptions: any = {
    columns: [
      {
        "header": "Status",
        "columnDef": "online_status",
        "filter": "",
        width: 'w-[80px]',
        "cType": "agg",
        "cell": "(element: any) => `${element.online_status}`",
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        selectFilter: true,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false,
        statusicon: true,
        success: true,
        failure: false,
        successIconPath: '/assets/images/on.png',
        successToolTip: 'Online',
        failureIconPath: '/assets/images/off.png',
        failureToolTip: 'Offline',
        isDropdown: true,
        dataSource: [
          { text: 'Online', value: 'true' },
          { text: 'Offline', value: 'false' }
        ],
      },
      {
        "header": "IP",
        "columnDef": "ip",
        "filter": "",
        "cell": "(element: any) => `${element.ip}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        "header": "Host Name",
        "columnDef": "host_name",
        "cType": "string",
        fillEmpty: 'host_name',
        secFillEmpty: 'ip',
        "filter": "",
        "cell": "(element: any) => `${element.host_name}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        header: 'Importance',
        cType: 'number',
        columnDef: 'importance',
        iscolumnSearch: true,
        selectFilter: true,
        selectFilterArr: [
          { name: 'Low', id: 'AssetLow', value: 25 },
          { name: 'Medium', id: 'AssetMedium', value: 50 },
          { name: 'High', id: 'AssetHigh', value: 75 },
          { name: 'Critical', id: 'AssetCritical', value: 100 }
        ],
        filter: '', width: '200px',
        isColoredCntrl: true,
        isCustomText: true,
        visible: true,
        order: 5,
        isSort: true
      },
      {
        header: "Software Name",
        columnDef: "name",
        "cType": "string",
        filter: "",
        cell: "(element: any) => ${element.name}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        "header": "Detected On",
        "columnDef": "vul_discovered",
        "cType": "string",
        "filter": "utcToLocale",
        "cell": "(element: any) => `${element.vul_discovered}`",
        "order": 3,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      }, 
      { "header": "Remediated On", "columnDef": "remediated_on", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.remediated_on}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      {
        "header": "Company Name",
        "columnDef": "company_name",
        "cType": "string",
        "filter": "",
        "cell": "(element: any) => `${element.company_name}`",
        "order": 0,
        "visible": false,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
    ],
    sortOptions: { active: 'host_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Affected Assets',
      isServerSide: false,
      selectText: 'asset',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/r/report_queries/remediate_records_assets',
        condition: '',
        isGlobal: true,
      },
      id: 'remediated_affected_assets'
    },
    customText: [
      {
        status: 1,
        DisplayText: 'Probe',
      },
      {
        status: 2,
        DisplayText: 'Lightweight Agent',
      },
      {
        status: 3,
        DisplayText: 'Lightweight Agent Installed',
      },
      {
        status: 4,
        DisplayText: 'External Scan Agent',
      },
      {
        range: { from: 0, to: 0 },
        color: '#c2ffc4',
        'text-color': '#000000'
      },
      {
        range: { from: 1, to: 40 },
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        range: { from: 41, to: 60 },
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        range: { from: 61, to: 90 },
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        range: { from: 91, to: 99999 },
        color: '#92243e',
        'text-color': '#ffffff'
      },
      {
        status: 25,
        DisplayText: 'Low',
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        status: 50,
        DisplayText: 'Medium',
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        status: 75,
        DisplayText: 'High',
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        status: 100,
        DisplayText: 'Critical',
        color: '#92243e',
        'text-color': '#ffffff'
      },
      {
        status: true,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
      },
      {
        status: false,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
    ],
    changeValue: new Subject<any>(),
  };
  compTableOptions: any = {
    columns: [
      {
        "header": "Name",
        "columnDef": "company_name",
        "cType": "string",
        "filter": "",
        "cell": "(element: any) => `${element.company_name}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },

      {
        "header": "Assets",
        "columnDef": "affected_assets",
        "cType": "number",
        "filter": "",
        "isHyperlink": true,
        "cell": "(element: any) => `${element.affected_assets}`",
        "order": 0,
        align: "left",
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
    ],
    sortOptions: { active: 'company_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Affected Companies',
      isServerSide: false,
      selectText: 'company',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: `/r/report_queries/remediate_records_companies`,
        condition: '',
        isGlobal: true,
      },
      id: 'remediated_affected_company'
    },
    customText: [
      {
        status: 'Critical',
        DisplayText: 'CRITICAL',
        color: '#92243e',
        'text-color': '#ffffff'
      },
      {
        status: 'High',
        DisplayText: 'HIGH',
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        status: 'Medium',
        DisplayText: 'MEDIUM',
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        status: 'Low',
        DisplayText: 'LOW',
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        status: 'Info',
        DisplayText: 'INFO',
        color: '#67ace1',
        'text-color': '#000000'
      },
      {
        range: { from: 0, to: 0 },
        color: '#c2ffc4',
        'text-color': '#000000'
      },
      {
        range: { from: 1, to: 4.9 },
        color: '#ffe24b',
        'text-color': '#000000'
      },
      {
        range: { from: 5, to: 8.9 },
        color: '#ec9b0c',
        'text-color': '#000000'
      },
      {
        range: { from: 9, to: 10 },
        color: '#ff6546',
        'text-color': '#000000'
      },

    ],
    changeValue: new Subject<any>(),
  };
  integrationAsset: any = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  actionData: any = {};
  descriptionType: any = '';
  productData: any = [];
  /**
   * Constructor
   */
  constructor(public cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private _bs: BaseRequestService,
    private toast: MyToastrService,
    private loaderService: LoaderService,
  ) {
    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
      if (this.cs.selctedAsset) {
        this.asset_id = this.cs.selctedAsset;
      } else if (this.cs.selectedTableRows['Assets'] && this.cs.selectedTableRows['Assets'].length) {
        this.asset_id = this.cs.selectedTableRows['Assets'][0].id;
      }
      this.viewCategory('remediated_record');
    });
    // For Filtering
    this.cs.remDetailsFilterCal
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        setTimeout(() => {
          this.remediationRecordsFilterCal(res.value);
        });
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }
  ngOnInit(): void {
    if (this.cs.selctedAsset) {
      this.asset_id = this.cs.selctedAsset;
    } else if (this.cs.selectedTableRows['Assets'] && this.cs.selectedTableRows['Assets'].length) {
      this.asset_id = this.cs.selectedTableRows['Assets'][0].id;
    }
    this.viewCategory('remediated_record');
  }
  linkCall($event: any): void {
    this.currentData = $event.row;
    if ($event.col === 'affected_assets') { // || $event.col === 'asset_ids'
      if (!$event.row['affected_assets']) { return };
      const data = Object.assign({}, this.asseTableOptions);
      data.pageData = []; data.tableOptions.pageTotal = 0;
      this.asseTableOptions = {}; this._changeDetectorRef.detectChanges();
      data.tableOptions.serverSide.isGlobal = false;
      /*data.tableOptions.customPagination = {
        key: 'id',
        value: $event.row.affected_assets
      }*/
      if (this.currentData.asset_ids && this.currentData.asset_ids.length) {
        data.tableOptions.serverSide.condition = `id in (${this.currentData.asset_ids.join(',')})`;
      } else {
        if (this.currentData.publisher !== null) {
          data.tableOptions.serverSide.condition = `name='${this.currentData.name}' and os_name='${this.currentData.os_name}' and reason='${this.currentData.reason}' and publisher='${this.currentData.publisher}'`;
        }
        else {
          data.tableOptions.serverSide.condition = `name='${this.currentData.name}' and os_name='${this.currentData.os_name}' and reason='${this.currentData.reason}' and publisher IS NULL`;
        }
      }
      this.asseTableOptions = data; this.cView = 'asset'; this._changeDetectorRef.detectChanges();
    } else if ($event.col === 'affected_companies') { //  || $event.col === 'company_ids'
      if (!$event.row[$event.col]) { return };
      const data = Object.assign({}, this.compTableOptions);
      data.pageData = []; data.tableOptions.pageTotal = 0;
      this.compTableOptions = {}; this._changeDetectorRef.detectChanges();
      data.tableOptions.serverSide.isGlobal = false;
      /*data.tableOptions.customPagination = {
        key: 'id',
        value: $event.row.affected_companies
      }*/
      if (this.currentData.publisher !== null) {
        data.tableOptions.serverSide.condition = `name='${this.currentData.name}' and os_name='${this.currentData.os_name}' and reason='${this.currentData.reason}' and publisher='${this.currentData.publisher}'`;
      }
      else {
        data.tableOptions.serverSide.condition = `name='${this.currentData.name}' and os_name='${this.currentData.os_name}' and reason='${this.currentData.reason}' and publisher IS NULL`;
      }
      this.compTableOptions = data; this.cView = 'company'; this._changeDetectorRef.detectChanges();
    }
  }

  viewCategory(view: any): void {
    this.cView = view;
    const params: any = {};
    if (view === 'remediated_record') {
      if (this.asset_id && this.mode === 'asset') {
        params.asset_id = this.asset_id;
      } else {
        params.company_id = (this.cs.currentScope !== "*") ? parseInt(this.cs.currentScope.id) : undefined;
      }
      let url: any = '';
      if (this.cs.currentScope === '*' && this.mode !== 'asset') {
        url = '/r/get_data/remediate_records_global';
      } else if (this.cs.currentScope !== '*' && this.mode !== 'asset') {
        url = '/r/get_data/remediate_records_companies';
      } else if(this.cs.externalassetview == 'apichangeview'){
        url = '/r/get_data/remediate_records_external_asset';
      }
      else {
        url = '/r/get_data/remediate_records_asset';
      }
      const remediatedTableOptions: any = {
        columns: [
          {
            header: "Name",
            columnDef: "name",
            "cType": "string",
            filter: "",
            cell: "(element: any) => ${element.name}",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: false,
          },
          {
            "header": "Detected On",
            "columnDef": "created",
            "filter": "utcToLocale",
            "cType": "agg",
            "cell": "(element: any) => `${element.created}`",
            "order": 0,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
          },
          { "header": "Remediated On", "columnDef": "updated", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.updated}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
          {
            "header": "Affected Assets",
            "columnDef": "affected_assets",
            cType: "number",
            "filter": "",
            "isHyperlink": true,
            "cell": "(element: any) => `${element.affected_assets}`",
            "order": 0,
            align: "left",
            isNoVisible: (this.asset_id) ? true : false,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
          },
          {
            "header": "Affected Companies",
            "columnDef": "affected_companies",
            cType: "number",
            "filter": "",
            "isHyperlink": true,
            "cell": "(element: any) => `${element.affected_companies}`",
            isNoVisible: (this.asset_id) ? true : false,
            "order": 0,
            align: "left",
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
          },
          {
            header: 'Severity',
            cType: '',
            columnDef: 'severity',
            iscolumnSearch: true,
            filter: '', width: '100px',
            isColoredCntrl: true,
            isCustomText: true,
            visible: true,
            order: 5,
            isSort: true,
            isDropdown: true,
            isSeverity: true,
            dataSource: [
              { text: 'Critical', value: 'Critical' },
              { text: 'High', value: 'High' },
              { text: 'Medium', value: 'Medium' },
              { text: 'Low', value: 'Low' }
            ],
          },
          {
            header: "Os Name",
            columnDef: "os_name",
            "cType": "string",
            filter: "",
            cell: "(element: any) => ${element.os_name}",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: false,
          },
          {
            header: "Ports",
            columnDef: "ports",
            cType: "agg",
            filter: "",
            cell: "(element: any) => `${element.ports}`",
            order: 0,
            visible: true,
            isPortView: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false,
            isShowMoreShowLess: true
          },
          {
            header: "Publisher",
            columnDef: "publisher",
            "cType": "string",
            filter: "",
            cell: "(element: any) => ${element.publisher}",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: false,
          },
          {
            header: "Reason",
            columnDef: "reason",
            "cType": "string",
            filter: "",
            cell: "(element: any) => ${element.reason}",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: false,
          },
          {
            header: "Closed Tickets",
            columnDef: "closed_ticket_count",
            cType: "number",
            filter: "",
            cell: "(element: any) => `${element.closed_ticket_count}`",
            order: 0,
            visible: true,
            isToolTip: false,
            isTicketCountView: true,
            isOpenTicket: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false,
        },
        ],
        sortOptions: { active: "name", direction: "asc" },
        _pageData: [],
        overridePageLoad: true,
        tableOptions: {
          title: "Remediated Records",
          isServerSide: false,
          selectText: "remediated",
          loading: true,
          floatingFilter: true,
          rowSelection: false,
          isHideGlobal: true,
          showAction: false,
          isCustomSort: true,
          apiDownload: true,
          hideDownload: true,
          isDefaultSearch: true,
          isDefaultKeySearch: true,
          pagination: true,
          pageOptions: [5, 10, 25, 100],
          pageSize: 5,
          search: false,
          showFilter: true,
          showTagFilter: false,
          actionMenuItems: [],
          showhideList: true,
          refreshData: true,
          exportExcel: true,
          add: false,
          columnSearch: false,
          compareData: false,
          filterDownload: false,
          filterEmitCal: 'remDetailsFilterCal',
          // serverSide: {
          //   url: url,
          //   condition: '',
          //   type: 'post',
          //   params: params,
          //   isMergeApi: true,
          //   customFnCall: 'getTicketIds',
          //   requiredKeys: {
          //     asset_id: this.asset_id,
          //     filter_key: 'solution_id'
          //   }
          // }
        },
        customText: [
          {
            status: "Critical",
            DisplayText: "CRITICAL",
            color: "#92243e",
            "text-color": "#ffffff",
          },
          {
            status: "High",
            DisplayText: "HIGH",
            color: "#de4a50",
            "text-color": "#ffffff",
          },
          {
            status: "Medium",
            DisplayText: "MEDIUM",
            color: "#f18c43",
            "text-color": "#000000",
          },
          {
            status: "Low",
            DisplayText: "LOW",
            color: "#f8c851",
            "text-color": "#000000",
          },
          {
            status: "Info",
            DisplayText: "INFO",
            color: "#67ace1",
            "text-color": "#000000",
          },
        ],
        changeValue: new Subject<any>(),
      }
      this.showTable = false;
      const data = Object.assign({}, remediatedTableOptions);
      data.pageData = [];
      data.tableOptions.pageTotal = 0;
      if (this.cs.currentScope === '*' && this.mode !== 'asset') {
        data.tableOptions.title = 'Non Linux Remediated Records'
      } else if (this.cs.currentScope !== '*' && this.mode !== 'asset') {
        data.tableOptions.title = 'Non Linux Remediated Records'
      }else{
        data.tableOptions.title = 'Remediated Records'
      }
      this.remediatedTableOptions = {};
      this._changeDetectorRef.detectChanges();
      if (this.asset_id && this.mode === 'asset') {
        params.condition = `asset_id=${this.asset_id}`;
      }
      this.remediatedTableOptions = data;
      this.cView = 'remediated_record';
      this.remediatedTableOptions.columns.forEach((obj: any) => {
        delete obj.isNoVisible;
        if (this.mode === 'asset') {
          if (obj.columnDef === "affected_companies" || obj.columnDef === "affected_assets") {
            obj.isNoVisible = true;
          }
        }
        if (this.mode !== 'asset') {
          if (obj.columnDef === "updated" || obj.columnDef === "created") {
            obj.isNoVisible = true;
          }
        }
        if (this.cs.currentScope === '*' && this.mode !== 'asset') {
          if (obj.columnDef === "closed_ticket_count" || obj.columnDef === "open_ticket_count") {
            obj.isNoVisible = true;
          }
        }
      }
      )
      this._changeDetectorRef.detectChanges();
      this.loaderService.display(true);
      this.showHideLoadingT(true);
      this._bs.doRequest(url, 'get', null, params)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
          this.loaderService.display(false);
          if (result.status && result.data && result.data.length) {
            try {
              if (this.cs.currentScope !== '*' || this.asset_id) {
                try {
                  const asset_id = this.asset_id;
                  const ticket_status = 'Closed';
                  try {
                    if (this.cs.currentScope !== '*' || asset_id) {
                      const reqParams: any = {
                        condition: true,
                        skip: 0,
                        limit: 2000
                      };
                      reqParams.condition = (this.cs.currentScope !== "*") ? `company_id=${parseInt(this.cs.currentScope.id)}` : null;
                      try {
                        this.loaderService.display(true);
                        this._bs.doRequest(`/report_queries/event_tickets`, 'get', null, reqParams)
                          .pipe(takeUntil(this._unsubscribeAll)).subscribe((ticket: any) => {
                            this.loaderService.display(false);
                            if (ticket.status && ticket.data && ticket.data.length) {
                              if (asset_id) {
                                ticket.data = ticket.data.filter((obj: any) => {
                                  let assetIdsArray: number[] = [];
                                  if (obj.asset_ids && obj.asset_ids !== null && obj.asset_ids !== undefined) {
                                    if (Array.isArray(obj.asset_ids)) {
                                      assetIdsArray = obj.asset_ids.map(item => parseInt(item));
                                    } else if (typeof obj.asset_ids === 'string') {
                                      assetIdsArray = obj.asset_ids.split(',').map(item => parseInt(item));
                                    } else {
                                      assetIdsArray = [parseInt(obj.asset_ids)];
                                    }
                                    return assetIdsArray.includes(asset_id);
                                  } else if (obj.asset_id && obj.asset_id !== null) {
                                    assetIdsArray = obj.asset_id.toString().split(',').map(item => parseInt(item));
                                    return assetIdsArray.includes(asset_id);
                                  }
                                  return false;
                                });
                              }

                              result.data.forEach((obj: any) => {
                                obj.view_evidence = (asset_id) ? 'View Evidence' : undefined;
                                if (!obj.ticket_ids || !obj.ticket_ids.length) {
                                  obj.ticket_ids = [];
                                }
                                const data = { refid: obj.solution_id, asset_ids: obj.asset_ids };
                                const sortedData = ticket.data.sort((a: any, b: any) => (a.status === "Open" ? -1 : 1))
                                const { tickets, open_ticket_count, closed_ticket_count } = this.searchTicketStatusMaps(data, sortedData);
                                obj.ticket_status_map = tickets; obj.open_ticket_count = open_ticket_count; obj.closed_ticket_count = closed_ticket_count;
                              });

                              this.originalReRecordData = result.data;
                              this.remediatedTableOptions.pageData = result.data;
                              this.remediatedTableOptions.tableOptions.pageTotal = result.data.length;
                              this.remediatedTableOptions.tableOptions.pageSize = 0;
                              this.remediatedTableOptions.tableOptions.pagination = false;
                              this.remediatedTableOptions.tableOptions.loading = false;
                              this.showHideLoadingT(false);
                              this.showTable = true;
                            }
                            else {
                              result.data.forEach((obj: any) => {
                                obj.ticket_ids = [];
                                obj.ticket_status_map = []; obj.open_ticket_count = 0; obj.closed_ticket_count = 0;
                              });
                              this.originalReRecordData = result.data;
                              this.remediatedTableOptions.pageData = result.data;
                              this.remediatedTableOptions.tableOptions.pageTotal = result.data.length;
                              this.remediatedTableOptions.tableOptions.pageSize = 0;
                              this.remediatedTableOptions.tableOptions.pagination = false;
                              this.remediatedTableOptions.tableOptions.loading = false;
                              this.showHideLoadingT(false);
                              this.showTable = true;

                            }

                          })
                      }
                      catch (error) { console.log(error) }
                    }
                    else {
                      this.originalReRecordData = result.data;
                      this.remediatedTableOptions.pageData = result.data;
                      this.remediatedTableOptions.tableOptions.pageTotal = result.data.length;
                      this.remediatedTableOptions.tableOptions.pageSize = 0;
                      this.remediatedTableOptions.tableOptions.pagination = false;
                      this.remediatedTableOptions.tableOptions.loading = false;
                      this.showHideLoadingT(false);
                      this.showTable = true;
                    }
                  }
                  catch (error) {
                    console.log(error);
                  }
                }
                catch (error) {
                  console.log(error);
                }
              }
              else {
                this.originalReRecordData = result.data;
                this.remediatedTableOptions.pageData = result.data;
                this.remediatedTableOptions.tableOptions.pageTotal = result.data.length;
                this.remediatedTableOptions.tableOptions.pageSize = 0;
                this.remediatedTableOptions.tableOptions.pagination = false;
                this.remediatedTableOptions.tableOptions.loading = false;
                this.showHideLoadingT(false);
                this.showTable = true;
              }

            }
            catch (error) {
              console.log(error);
            }
            // direct keys available so commenting the below code
            /*result.data.map((x: any) => {
              x.affected_companies = x.company_ids.length;
              x.affected_assets = x.asset_ids.length;
            })*/

          }
          else {
            this.remediatedTableOptions.pageData = [];
            this.remediatedTableOptions.tableOptions.pageTotal = 0;
            this.remediatedTableOptions.tableOptions.pageSize = 0;
            this.remediatedTableOptions.tableOptions.pagination = false;
            this.remediatedTableOptions.tableOptions.loading = false;
            this.showHideLoadingT(false);
            this.showTable = true;
          }
        });
    }
  }
  convertArrayOfObjectsToCSV(data, columnMapping, dynamicKey) {
    const orderedKeys = Object.keys(columnMapping); // Get the ordered keys from columnMapping
    const escapeValue = (value) => {
      // Ensure value is a string, escape double quotes, and wrap in double quotes
      return `"${String(value).replace(/"/g, '""')}"`;
    };
    const orderedData = data.map(obj => {
      const orderedObj = {};
      orderedKeys.forEach(key => {
        const value = obj[key];
        if (key === dynamicKey) {
          if (typeof value === 'string') {
            orderedObj[columnMapping[key]] = escapeValue(value);
          } else if (Array.isArray(value)) {
            orderedObj[columnMapping[key]] = escapeValue(JSON.stringify(value));
          } else {
            orderedObj[columnMapping[key]] = '';
          }
        } else {
          orderedObj[columnMapping[key]] = value !== undefined && value !== null ? escapeValue(value) : '';
        }
      });
      return orderedObj;
    });

    const header = orderedKeys.map(key => escapeValue(columnMapping[key])).join(','); // Use ordered keys for the header
    const rows = orderedData.map(obj => orderedKeys.map(key => obj[columnMapping[key]]).join(','));
    const csvContent = [header, ...rows].join('\n');

    // For downloading the CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // Feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const dateStr = new Date().toLocaleDateString().replace(/\//g, '_') + '_'
        + new Date().toLocaleTimeString().replace(/\:/g, '_');
      link.setAttribute('download', `${dateStr}_remediated_records.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  apiDataDownload($event: any): void {
    const fieldmap = {};
    const columnsF = cloneDeep(this.remediatedTableOptions.columns);
    for (const col of columnsF) {
      if ((col.visible && !col.isNoVisible) || col.columnDef === 'ports') {
        // @ts-ignore
        fieldmap[col.columnDef] = col.header;
      }
    }
    const resp = cloneDeep(this.remediatedTableOptions.pageData);
    resp.map((x: any) => {
      // x.asset_ids = x.asset_ids.length;
      // x.company_ids = x.company_ids.length;
      x.publisher = (x.publisher) ? x.publisher.replaceAll(',', ' &') : '';
      const port = (x.ports && x.ports.length) ? x.ports.filter((x: any) => x !== null) : [];
      if (!port || !port.length) {
        x.ports = ''
      }
    })
    if (Array.isArray(resp) && resp.length > 0) {
      this.convertArrayOfObjectsToCSV(resp, fieldmap, 'ports');
    } else {
      this.toast.sToast("info", "No data available");
    }
    
  }

  remediationRecordsFilterCal(rule: any): void {
    const response = (rule) ? this.cs.filterConditionRule(this.originalReRecordData, rule) : this.originalReRecordData;
    this.cs.tableFilterCalBack.next({ value: response });
  }

  showHideLoadingT(status: boolean): void {
    const data = Object.assign({}, this.remediatedTableOptions);
    this.remediatedTableOptions = {};
    this._changeDetectorRef.detectChanges();
    this.remediatedTableOptions = data;
    this.remediatedTableOptions.tableOptions.loading = status;
    this._changeDetectorRef.detectChanges();
  }

  searchTicketStatusMaps(data: any, jsonData: any, ticket_status?: any) {
    const { refid, asset_ids } = data;
    const ticketStatusMaps: any = [];
    if (refid) {
      const refidArray: any = Array.isArray(refid) ? refid : refid.toString().split(',');
      jsonData.forEach((obj: any) => {
        if ((refidArray.some((id: any) => obj.source_ids && obj.source_ids.includes(id.toString().trim())) ||
          refidArray.some((id: any) => obj.software_id && obj.software_id.toString() === id.toString().trim())) &&
          refidArray.some((id: any) => (!obj.excluded_source || !obj.excluded_source.includes(id.toString().trim())))
        ) {
          if (ticket_status && ticket_status === obj.status) {
            ticketStatusMaps.push({ credential_id: obj.credential_id, status: obj.status, ticket_id: obj.ticket_id, ticket_number: obj.ticket_number });
          } else if (ticket_status === undefined) {
            ticketStatusMaps.push({ credential_id: obj.credential_id, status: obj.status, ticket_id: obj.ticket_id, ticket_number: obj.ticket_number });
          }
        }
      });
    }

    return this.removeDuplicateObjects(ticketStatusMaps);
  }

  removeDuplicateObjects(jsonArray: any) {
    const tickets: any = Object.values(jsonArray.reduce((acc: any, ticket: any) => {
        const { ticket_id, status } = ticket;
        if (!acc[ticket_id] || status === "Closed") {
            acc[ticket_id] = ticket;
        }
        return acc;
    }, {}));
    const open_tickets: any = tickets.filter((x: any) => x.status === "Open");
    const closed_tickets: any = tickets.filter((x: any) => x.status === "Closed");
    return { tickets: tickets, open_ticket_count: open_tickets.length, closed_ticket_count: closed_tickets.length }
  }
}
