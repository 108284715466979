<div class="p-4">
    <div class="-mx-4">
        <stable
            *ngIf="discoveryTableOptions && discoveryTableOptions.tableOptions && discoveryTableOptions.tableOptions.serverSide"
            aria-label="Discovery Settings" role="table" (parentCallback)="parentCallBack($event)"
            (addCallback)="addTableData()" (actionCallback)="discactionCall($event)"
            [sTableOptions]="discoveryTableOptions" [loadtable]="loadtable">
        </stable>
    </div>
    <div class="w-full flex flex-row h-1">&nbsp;</div>
    <credentials></credentials>
</div>
<s-modal id="uploadBulkIP">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[60vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-10 font-bold tracking-wider">Upload Bulk IP CSV File</p>
            <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px" />
        </div>
        <div class="w-2/3 h-[60vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <div class="flex flex-row items-center">
                <div class="text-xl font-semibold tracking-tight">Upload Bulk IP CSV File</div>
                <button class="ml-auto" mat-icon-button (click)="closeModel();">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <mat-divider class="my-4"></mat-divider>
            <fuse-alert [type]="'info'" [appearance]="'border'">
                Please note that discovery settings will be overwritten based on the name during file
                upload.
            </fuse-alert>
            <form #ipUpload="ngForm">
                <div class="mt-6">
                    <p class="text-xl">
                        Download sample csv file
                        <a target="_blank" class="text-blue-500 text-xl" href="/assets/files/bulk_ip_upload_sample.csv">here</a>.
                    </p>
                </div>
                <div>
                    <input #uploadFileInput type="file" class="w-100 mb-10 mt-5" name="file" accept=".csv"
                        (change)="uploadFile($event, 'uploadfile')" required />
                </div>

                <div class="dark:bg-transparent border-t flex flex-row items-end justify-end pt-4">
                    <button mat-raised-button class="mat-primary mr-2" (click)="uploadBulkIPCSV()"
                        [disabled]="!csvUpload.uploadfile" id="save11">
                        Upload
                    </button>
                    <button mat-stroked-button class="mat-warn" (click)="closeModel()" id="cancel11">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>
<s-modal id="newDiscoverySetting">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[75vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-10 font-bold tracking-wider">DISCOVERY SETTINGS</p>
            <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Update your <br> network scope here, </p>
            <p class="mb-4 text-sm text-gray-200">Customize your vulnerability scanning scope by inputting network
                details,
                IP ranges, CIDR ranges, Static IPs, and Domains in the discovery settings.
                This tailored configuration ensures accurate security assessments.</p>
            <div class="bg-blue-600/80 rounded-2xl p-3">
                <p class="mb-3 text-sm text-gray-200">
                    Discovery settings in vulnerability scanning are a fundamental component of the scanning process,
                    aimed at identifying and assessing potential security vulnerabilities across an organization's
                    digital assets.
                </p>
            </div>
        </div>
        <div class="w-2/3 h-[75vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('newDiscoverySetting');">
                <mat-icon>close</mat-icon>
            </button>
            <h2 class="mb-4 text-3xl font-bold">Discovery Settings</h2>
            <form class="flex flex-col m-2 px-2 overflow-hidden" #exNgForm="ngForm">
                <div class="flex mt-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Name</mat-label>
                        <input matInput [placeholder]="'Enter Name'" name="name" required
                            [(ngModel)]="cofigForm.name" [spellcheck]="false">
                    </mat-form-field>
                </div>
                <div class="flex mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Address Type</mat-label>
                        <mat-select (selectionChange)="onAddressTypeChange($event)"  required name="address_type"
                            [(ngModel)]="cofigForm.address_type">
                            <ng-container *ngFor="let data of addressTypes; trackBy: trackByFn">
                                <mat-option [value]="data.value"> {{data.label}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="cofigForm.address_type === 'STATICIP'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>IP Address</mat-label>
                            <input matInput [placeholder]="'Enter IP'" name="address" required
                                [(ngModel)]="cofigForm.address">
                            <mat-hint>Example: 192.168.0.0</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="cofigForm.address_type === 'IPRANGE'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>IP Range</mat-label>
                            <input matInput [placeholder]="'Enter IP Range'" name="ip_range" required
                                [(ngModel)]="cofigForm.address" (ngModelChange)="validateIpRange()">
                            <mat-hint *ngIf="!ipRangeError">Enter IP in range like 191.168.2.1-192.168.2.100</mat-hint>
                        </mat-form-field>
                    </div>
                    <p class="text-red-500 text-sm !important ml-2" *ngIf="ipRangeError">Please remove extra spaces</p>
                </ng-container>
                <ng-container *ngIf="cofigForm.address_type === 'CIDR'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>CIDR</mat-label>
                            <input matInput [placeholder]="'Enter CIDR'" name="cidr"
                                [(ngModel)]="cofigForm.address">
                            <mat-hint>Example: 10.12.23.14/31 (/value in range /31-/1)</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="cofigForm.address_type === 'DOMAIN'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain</mat-label>
                            <input matInput [placeholder]="'Enter Domain'" name="domain" required
                                [(ngModel)]="cofigForm.address" pattern="[^\s]*">
                            <mat-hint>Example: xyz.com</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <mat-checkbox class="mt-4" id="IPExcludeScan" *ngIf="cofigForm.address_type !== 'DOMAIN'"
                    color="primary" [id]="'is_excluded'" name="is_excluded" [(ngModel)]="cofigForm.is_excluded"
                    (change)="showOptions($event)">Exclude IP from scanning</mat-checkbox>
                <ng-container
                    *ngIf="cofigForm.address_type === 'CIDR' || cofigForm.address_type === 'IPRANGE' && showExclude">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Exclude MAC Address</mat-label>
                            <mat-chip-grid #chipPort aria-label="Enter Keywords">
                                <mat-chip-row *ngFor="let key of cofigForm.mac_address_exclude; trackBy: trackByFn" (removed)="remove(key)"
                                    [editable]="true" (edited)="edit(key, $event)"
                                    [aria-description]="'press enter to edit ' + key">
                                    {{key}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + key">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input [placeholder]="'Enter MAC Address'" [matChipInputFor]="chipPort"
                                    id="mac_address" name="mac_address"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                            </mat-chip-grid>
                            <mat-hint>Enter a MAC address and press comma or enter to add.</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="(cofigForm.address_type === 'CIDR' || cofigForm.address_type === 'IPRANGE')">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Enter Device IP For Credential Validation</mat-label>
                            <input matInput [placeholder]="'Enter comma seperated IPs'" id="target_ip"
                                name="target_ip" [(ngModel)]="cofigForm.target_ip">
                            <mat-hint>Example: 192.168.0.0,192.168.0.1</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div class="flex mt-4">
                    <fuse-alert [type]="'info'" [appearance]="'border'" class="my-2">
                        <div class="flex flex-col">
                            <span class="text-sm mb-2 font-medium text-gray-900 dark:text-white me-3">
                                In order for Tags to work, the Tag Name and Value
                                must be entered exactly, without spaces.
                            </span>
                            <span class="text-sm mb-2 font-medium text-gray-900 dark:text-white me-3">
                                For example, if the Tag
                                appears as
                                <span
                                    class="bg-purple-100 text-purple-800 text-sm font-bold px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                                    Asset Type: Workstation
                                </span>, then the <strong>Name</strong> field should be
                                entered as <strong>Asset Type</strong> and <strong>Value</strong> field should be
                                entered as
                                <strong>Workstation</strong> respectively
                            </span>
                        </div>
                    </fuse-alert>
                </div>
                <div class="flex mt-4">
                    <div class="flex flex-row w-full gap-2 items-center">
                        <div class="font-semibold my-2">Tags </div>
                        <button (click)="addTag()" type="button" mat-icon-button="" matTooltip="Add Record">
                            <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                        </button>
                    </div>
                </div>
                <div class="flex flex-row w-full gap-2 items-center mt-4"
                    *ngFor="let tag of cofigForm.manual_tags; trackBy: trackByFn; let i = index; let first = first; let last = last">
                    <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                        <mat-label *ngIf="first">Name</mat-label>
                        <input matInput [placeholder]="'Name'" name="ignoreName{{i}}" required autocomplete="off"
                            [(ngModel)]="tag.key">
                    </mat-form-field>
                    <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                        <mat-label *ngIf="first">Value</mat-label>
                        <input matInput [placeholder]="'Value'" pattern="^[^\s].*[^\s]$" name="ignoreValue{{i}}"
                            required autocomplete="off" [(ngModel)]="tag.value">
                    </mat-form-field>
                    <div class="" [ngClass]="{'mt-6': first}">
                        <button (click)="cofigForm.manual_tags.splice(i, 1)" type="button" mat-icon-button=""
                            matTooltip="Delete record">
                            <mat-icon class="icon-size-5 text-gray">delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div
                    class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('newDiscoverySetting');">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick
                        [disabled]="exNgForm.invalid || ipRangeError" [matTooltip]="'Save'" (click)="save()">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>


<!-- <app-company-onboarding></app-company-onboarding> -->