import {ChangeDetectorRef, Component, HostListener, OnDestroy} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {LoaderService} from "./_services/loader.service";
import {MatButtonModule} from "@angular/material/button";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {AsyncPipe} from "@angular/common";
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { CommonService } from './_services/common.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    // standalone : true,
    // imports: [RouterOutlet, MatButtonModule, NgIf, AsyncPipe, NgOptimizedImage],
})
export class AppComponent implements OnDestroy{
    contentText: any;
    showLoader = new BehaviorSubject<boolean>(false);
    loading$ = this.showLoader.asObservable();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    result: any;
    refresh:any;
    change : any;
    /**
     * Constructor
     */
    constructor(private loaderService: LoaderService, private splashService : FuseSplashScreenService, private cs : CommonService, private changeDetectionRef : ChangeDetectorRef) {
        this.loaderService.status.subscribe((val: boolean, text: string) => {
          this.result = val;
          setTimeout(() => { this.showLoader.next(this.result.value); });
          this.contentText = this.result.text;
        });
        this.refresh = this.cs.refreshed
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(change =>{
            this.splashService.show();
            this.change = change; this.changeDetectionRef.detectChanges();
            setTimeout(()=> {
                if(!(change == 'yes')){this.splashService.hide();}
            }, 1000)
          
          })
        // Create a MutationObserver instance
        const observer = new MutationObserver(mutationsList => {
            // Iterate through each mutation
            mutationsList.forEach(mutation => {
                // Check if nodes were added
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    // Iterate through added nodes
                    mutation.addedNodes.forEach(node => {
                        // Check if the added node is a label element
                        if (node instanceof HTMLElement && node.tagName.toLowerCase() === 'label') {
                            // Remove the aria-owns attribute
                            node.removeAttribute('aria-owns');
                        }
                    });
                }
            });
        });

        // Start observing mutations in the document's body and its descendants
        observer.observe(document.body, {
            childList: true, // Listen for changes to the children of the body
            subtree: true    // Listen for changes in the entire subtree of the body
        });
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.code === 'Escape') {
          this.loaderService.display(false);
          this.loaderService.Modeldisplay(false);
        }
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
      }
}
