<div *ngIf="cView=== 'asset'">
    <form #cveFrm="ngForm">
        <ng-container *ngIf="!tcve || !tcve.length">
            <div class="mt-4">
                <mat-form-field class="w-full mt-4 sm:w-72 sm:mt-0 sm:ml-4" [subscriptSizing]="'dynamic'">
                    <span matPrefix>CVE-</span>
                    <input autofocus type="text" trim="blur" [(ngModel)]="filterCVE" required matInput
                        pattern="((1999|2\d{3})-(0\d{2}[1-9]|[1-9]\d{3,}))" placeholder="YEAR-NNNNNN" name="filterCVE"
                        autocomplete="off">
                    <button *ngIf="filterCVE" matSuffix mat-icon-button aria-label="Clear"
                        (click)="filterCVE = undefined; filterCVE = ''" type="button">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-hint>Ex. 2021-21166</mat-hint>
                </mat-form-field>
                <button class="ml-4 e-btn e-primary e-qb-button" type="button" (click)="getCEV()"
                    [disabled]="!cveFrm.form.valid">Search</button>
            </div>
        </ng-container>
        <ng-container *ngIf="tcve && tcve.length">
            <div class="flex flex-row items-end justify-between w-full">
                <mat-form-field class="w-full mt-4 sm:w-72 sm:mt-0 sm:ml-4" [subscriptSizing]="'dynamic'">
                    <mat-label>Select CVE</mat-label>
                    <mat-select name="CVE" required [(ngModel)]="filterCVE" (selectionChange)="cveChange($event)">
                        <ng-container *ngFor="let item of tcve">
                            <mat-option [value]="item.cve"> {{item.cve}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="flex items-end justify-end pr-4">
                    <a class="inline-flex items-center p-2 leading-6 border rounded-full bg-card hover:underline"
                        href="javascript:" (click)="backFn()">
                        <span class="inline-flex items-center">
                            <mat-icon class="text-current icon-size-5"
                                [svgIcon]="'heroicons_solid:arrow-small-left'"></mat-icon>
                            <span class="ml-1.5 font-medium leading-5">Back to search</span>
                        </span>
                    </a>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<mat-divider class="mt-4"></mat-divider>
<ng-container *ngIf="cveDetails && cveDetails.cve && cView=== 'asset'">
    <div
        class="m-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div class="flex flex-col items-start justify-start w-3/4 bg-transparent bg-card">
            <div class="flex flex-row items-center justify-center mt-4">
                <div class="mr-2 text-xl font-semibold leading-tight">{{cveDetails.cve}}</div>
                <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                    [ngClass]="'cs-' + cveDetails.severity?.toLowerCase()">
                    {{cveDetails.severity}}
                </span>
            </div>
            <div class="mt-2 leading-tight text-secondary text-medium">{{cveDetails.description}}</div>
            <!-- <div class="flex items-center mt-2">
                    <mat-icon
                        class="mr-3 icon-size-5"
                        [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                    <span class="leading-none">{{cveDetails.published_date | utcToLocale}}</span>
                </div> -->
            <ng-container *ngIf="cveDetails">
                <hr class="w-full mt-4 mb-2 border-t">
                <div class="flex items-start justify-between w-full px-2 my-4">
                    <div class="flex flex-col items-center">
                        <span class="font-bold">
                            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.epss_score, 'epss_score')">
                                {{cveDetails.epss_score}}
                            </span>
                        </span>
                        <span class="mt-1 text-sm font-medium text-secondary">EPSS SCORE</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="font-bold">
                            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.base_score, 'base_score')">
                                {{cveDetails.base_score}}
                            </span>
                        </span>
                        <span class="mt-1 font-medium text-medium text-secondary">BASE SCORE</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="font-bold"><span
                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.impact_score, 'impact_score')">
                                {{cveDetails.impact_score}}
                            </span></span>
                        <span class="mt-1 font-medium text-medium text-secondary">IMPACT SCORE</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="font-bold"><span
                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.exploitability_score, 'exploitability_score')">
                                {{cveDetails.exploitability_score}}
                            </span></span>
                        <span class="mt-1 font-medium text-medium text-secondary">EXPLOITABILITY SCORE</span>
                    </div>
                </div>
                <hr class="w-full mt-2 mb-8 border-t">
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container>
    <div *ngIf="cView=== 'asset'">
        <stable class="" [sTableOptions]="asseTableOptions" (hyperlinkCallback)="linkCall($event)"
            *ngIf="asseTableOptions && asseTableOptions.tableOptions && cveDetails && cveDetails.cve"></stable>
    </div>
    <div *ngIf="cView=== 'details' && cAsset">
        <asset-details [cAsset]="cAsset" *ngIf="cAsset" (toggleFn)="back()"></asset-details>
    </div>
</ng-container>