<!-- Button -->
<!-- <button
    mat-icon-button
    [matTooltip]="tooltip || 'Toggle Fullscreen'"
    (click)="toggleFullscreen()">
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>


<ng-template #defaultIconTpl>
    <mat-icon [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
</ng-template> -->


<!-- Button -->
<!-- <button
    mat-icon-button
    [matTooltip]="tooltip || 'Toggle Fullscreen'"
    (click)="toggleFullscreen()">
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<ng-template #defaultIconTpl>
    <mat-icon [svgIcon]="'heroicons_outline:lifebuoy'"></mat-icon>
</ng-template> -->

<!-- Button -->
<!-- <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user?.avatar" [src]="user?.avatar">
        <mat-icon *ngIf="!showAvatar || !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button> -->


<!-- 
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full">
        <mat-icon [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
    </span>
</button> -->

<!-- <ng-template [matMenuTriggerFor]="userActions">
    <mat-icon [svgIcon]="'heroicons_outline:lifebuoy'"></mat-icon>
</ng-template> -->
<div class="flex flex-row items-center" *ngIf="showIcon">
    <div class="flex-auto cursor-pointer">
        <div class="leading-6 font-medium">Assessment</div>
    </div>
    <mat-slide-toggle class="ml-4" [color]="'primary'" (click)="changeAssessment();" [(ngModel)]="cs.is_assessment" id="twoStepToggle" name="twoStep" #twoStepToggle>
    </mat-slide-toggle>
</div>


<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item (click)="toggleFullscreen()">
        <mat-icon [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
        <span>Toggle Fullscreen</span>
    </button>
    <button mat-menu-item (click)="uploadAssessment()">
        <mat-icon [svgIcon]="'mat_solid:assessment'"></mat-icon>
        <span>Assessment Upload</span>
    </button>
    <button mat-menu-item (click)="downloadAssessmentPopup()">
        <mat-icon [svgIcon]="'mat_solid:download'"></mat-icon>
        <span>Assessment Agent</span>
    </button>
</mat-menu>




<!-- <s-modal id="uploadDoc">
    <div class="mx-auto w-[44vw] flex max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[65vh] max-w-md rounded-l-3xl
     bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">Upload Cover Page</p>
            <img src="/assets/images/releasenotes.png" alt="Upload Cover Page" class="w-50 mt-10" height="512"
                width="512">
        </div>
        <div class="w-2/3 h-[65vh] rounded-r-3xl bg-card px-2 py-2" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('uploadDoc');">
                <mat-icon>close</mat-icon>
            </button>
            <div class="font-bold text-lg pl-4 my-4">Upload Cover Page</div>
            <div class="m-4 flex flex-col gap-2">
                <p>
                    Download sample docx file
                    <a href="javascript:" (click)="downloadSampleDoc()"> <span class="text-primary">here</span></a>.
                </p>
                <input #newFileInput type="file" class="w-full my-2" accept=".docx" name="file"
                    (change)="uploadImage($event)" />
                <div class="flex items-center mt-10 py-4 pr-4 pl-1 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('uploadDoc');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="!uploadfile"
                        appPreventMultiClick [matTooltip]="'Upload'" (click)="uploadDocs()">
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </div>
</s-modal> -->

<!-- <s-modal id="uploadAssessment">
    <form #uploadAsm="ngForm">
          <mat-card class="modal bg-gray-400 dark text-blue-500 mat-elevation-z0 pr-0 w-full">
            <div class="flex items-center ml-6 justify-between w-full max-w-3xl mx-auto">
                <div class="text-center mt-4">
                    <div class="mat-title mb-2">Deploy Assessment Agent</div>
                </div>

                <button mat-icon-button aria-label="close modal icon" matTooltip="Close" appPreventMultiClick
                    class="mat-mdc-tooltip-trigger mr-4 mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
                    (click)="closemenu()">
                    <mat-icon color="primary">close</mat-icon>
                </button>
            </div>
          <div class="mat-title">Upload Assessment File</div>
          <mat-card-content class="">
            <mat-form-field appearance="outline" class="w-75">
              <mat-label>Company Name</mat-label>
              <input matInput autocomplete="off" [(ngModel)]="assessmentName" type="text" name="assessmentName"
                trim="blur" required placeholder="Enter Company Name">
            </mat-form-field>
            <div class="row">
              <div class="col-lg-12">
                <div class="w-100 p-4">
                  <p class="">Upload Assessment Zip File</p>
                  <input #newInput type="file" accept=".zip" name="file" (change)="uploadFile($event, 'uploadfile')" />
                  <mat-error class="mt-1">Max. limit is 256MB</mat-error>
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
            <button mat-raised-button class="mat-primary mr-2" (click)="saveAssessmentFile(assessmentName)"
              [disabled]="(!uploadAsm.form.valid || isLoading) || disbaleSave" id="saveasm">Save
            </button>
            <button mat-stroked-button class="mat-warn" (click)="closeAssessment()" id="cancelasm">Cancel </button>
          </mat-card-footer>
        </mat-card>
    </form>
  </s-modal> -->