import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'kernel-modules',
  standalone : true,
  imports:[NgIf,NgFor,TableComponent],
  templateUrl: './kernel-modules.component.html',
  styleUrls: ['./kernel-modules.component.scss']
})
export class KernelModulesComponent {
  @Input() asset: any;
  kernelTableOptions: any = {
    columns: [
        {
            "header": "Name",
            "columnDef": "name",
            isHyperlink: false,
            "cType": "string",
            "filter": "",
            "cell": "(element: any) => `${element.name}`",
            "order": 0,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
        }, {
            "header": "Status",
            "columnDef": "status",
            "cType": "string",
            "isHyperlink": false,
            "filter": "",
            "cell": "(element: any) => `${element.status}`",
            "order": 0,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
        },
        {
            "header": "Used By",
            "columnDef": "used_by",
            "cType": "string",
            "isHyperlink": false,
            "filter": "cleanString",
            "cell": "(element: any) => `${element.used_by}`",
            "order": 0,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
        },
    ],
    sortOptions: { active: 'name', direction: 'desc' },
    _pageData: [],
    tableOptions: {
        title: 'Kernel Modules',
        isServerSide: false,
        selectText: 'kernel',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
            url: '/report_queries/kernel_modules',
            condition: ''
        },
        id: 'kernel_modules'
    },
    changeValue: new Subject<any>(),
};
  
  ngOnInit(): void {
    this.kernelTableOptions.tableOptions.serverSide.condition = ` asset_id='${this.asset.id}'`;
}
}
