import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { TableComponent } from '../table/table.component';
import {
    FormsModule,
    NgForm,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseValidators } from '@fuse/validators';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { ModalService } from 'app/_services/modal.service';
import { ModalComponent } from '../modal.component';
import { CommonService } from 'app/_services/common.service';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseAlertComponent } from '@fuse/components/alert';
import { cloneDeep } from 'lodash';
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'app-user-management',
    standalone: true,
    imports: [CommonModule, TableComponent, AppFilterPipeModule, FuseAlertComponent, NgxMatSelectSearchModule, DirectivesModule, FormsModule, MaterialModule, ReactiveFormsModule, ModalComponent],
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() isCustomer: any = false;
    
    userTableOption: any;
    uView: any = 'table';
    contactForm: UntypedFormGroup;
    @ViewChild('updateForm') updateForm: NgForm;
    roles: any = [];
    currentUser: any = {};
    apiData: any = [];
    includeControl: UntypedFormControl = new UntypedFormControl();
    public searching = false;
    sourceCompany: any = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    showTable: boolean = false;
    companyModalTitle: any;
    userData: any;
    searchTerm: string = '';
    masterUserData: any;
    originalUserData: any;
    allCompanies: any;
    constructor(
        private _formBuilder: UntypedFormBuilder, public _cs: CommonService,
        public _bs: BaseRequestService, public modalService: ModalService,
        private ls: LoaderService, private toast: MyToastrService, public _userService: UserService,
        public confirmDialog: FuseConfirmationService, private _changeDetectorRef: ChangeDetectorRef,
    ) {
        if (this.isCustomer) {
            this.userTableOption.tableOptions.title = 'Customers';
            this.userTableOption.tableOptions.selectText = 'customers';
            this.userTableOption.tableOptions.actionMenuItems = [];
            this.userTableOption.tableOptions.actionMenuItems = [
                {
                    text: 'Edit User',
                    id: 'edit',
                    icon: 'edit',
                    callback: 'editFn',
                    hideLocal: false,
                    isGlobal: false,
                },
                {
                    text: 'Resend Code',
                    icon: 'restore',
                    callback: 'deleteFn',
                    isGlobal: true
                },
                {
                    text: 'Reset MFA',
                    icon: 'restore',
                    callback: 'deleteFn',
                    isGlobal: false
                },
                {
                    text: 'Delete',
                    id: 'delete',
                    icon: 'delete',
                    callback: 'editFunction',
                },
                {
                    text: 'API Key',
                    icon: 'vpn_key',
                    callback: 'deleteFn',
                    actionConditions: { key: 'state' },
                    allowedCondition: ['USER_STATE_ACTIVE']
                }
            ]
        }

        this._cs.userFilterCal
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                setTimeout(() => {
                    this.userFilterCall(res.value);
                });
            });
    }

    linkCall($event: any) {
        if ($event.row.included && $event.row.included !== '*' && $event.row.included.length) {
            this.companyModalTitle = "Allowed Companies";
        } else if ($event.row.included === "*") {
            this.companyModalTitle = "Allowed Companies";
        } else if ($event.row.excluded && $event.row.excluded !== '*' && $event.row.excluded.length) {
            this.companyModalTitle = "Denied Companies";
        }
        else if ($event.row.excluded === "*") {
            this.companyModalTitle = "Denied Companies";
        }
        this.userData = $event.row.company_names;
        this.masterUserData = $event.row.company_names;
        this._changeDetectorRef.detectChanges();
        this.modalService.open('CompanyDetails');
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this.getUsers();

        // Create the contact form
        this.contactForm = this._formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            mobile: ['', [Validators.required]],
            roles: [[], [Validators.required]],
            companies: [['*']],
            company_type: ['include'],

            // password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[@#%$!&*])[a-zA-Z0-9@#%$!&*]{8,50}$')]],
            // passwordConfirm: ['', Validators.required]
        },
            // {
            //   validators: FuseValidators.mustMatch('password', 'passwordConfirm')
            // }
        );

        this.contactForm.get('roles').valueChanges
            .pipe(debounceTime(500))
            .subscribe((val) => {
                /*if (val && val.indexOf('admin') === -1) {
                    this.contactForm.get('company_type').setValidators([Validators.required]);
                    this.contactForm.get('companies').setValidators([Validators.required]);
                } else {
                    this.contactForm.get('company_type').clearValidators();
                    this.contactForm.get('companies').clearValidators();
                }*/
                this.contactForm.get('company_type').setValidators([Validators.required]);
                this.contactForm.get('companies').setValidators([Validators.required]);
                this.contactForm.get('company_type').updateValueAndValidity();
                this.contactForm.get('companies').updateValueAndValidity();
            });

        this.contactForm.get('companies').valueChanges
            .pipe(debounceTime(500))
            .subscribe((val) => {
                if (val && val.indexOf('*') === -1) {
                    this.contactForm.get('companies').setValue(val);
                } else {
                    this.contactForm.get('companies').setValue(['*']);
                }
                this.contactForm.get('companies').updateValueAndValidity();
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    newUserCall(): void {
        this.contactForm = this._formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            mobile: ['', [Validators.required]],
            roles: [[], [Validators.required]],
            companies: [['*']],
            company_type: ['include'],
        }),
            this.uView = 'add';
        this.getRoles();
        this.getComp();
    }

    actioncallback($event: any): void {
        if ($event.action.text == 'Edit User') {
            this.getRoles();
            this.getComp();
            let sInclude: any = $event.row.included?.split(',').filter((x: any) => x !== '');
            const sExclude: any = $event.row.excluded?.split(',').filter((x: any) => x !== '');
            if (sInclude && sInclude.indexOf('*') === -1) {
                sInclude.forEach((a: any, i: any) => {
                    sInclude[i] = +a;
                });
            } else if (sInclude && sInclude.indexOf('*') > -1) {
                sInclude = ['*'];
            }
            sExclude.forEach((a: any, i: any) => {
                sExclude[i] = +a;
            });
            this.currentUser = {
                roles: $event.row.roles,
                email: $event.row.email,
                user_id: $event.row.id,
                first_name: $event.row.first_name,
                last_name: $event.row.last_name,
                company_type: (sInclude && sInclude.length) ? 'include' : (sExclude && sExclude.length) ? 'exclude' : '',
                companies: (sInclude && sInclude.length) ? sInclude : (sExclude && sExclude.length) ? sExclude : [],
            }
            if (this.currentUser.companies.length != $event.row.company_names.length && this.currentUser.companies[0] !== '*') {
                this.currentUser.companies = $event.row.matchingCompanyIds;
            }
            this.modalService.open('userRoleEdit');
        } else if ($event.action.text == 'Delete') {
            this.deleteUser($event.row);
        } else if ($event.action.text == 'Reset MFA') {
            this.resetMFAConfirmationDialog($event.row);
        } else if ($event.action.text == 'Resend Code') {
            this.resendCodeConfirmationDialog($event.row);
        } else if ($event.action.text === 'API Key') {
            this.currentUser = {
                email: $event.row.email,
                user_id: $event.row.id,
                first_name: $event.row.first_name,
                last_name: $event.row.last_name,
            }
            this.getAPI($event.row.id);
        }

    }

    getAPI(user_id: any): void {
        this.ls.display(true, 'Getting client api key');
        this._bs.doRequest(`/r/user/generate_secret/${user_id}`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.ls.display(false);
                this.apiData = [];
                if (result.status) {
                    this.apiData = [
                        {
                            icon: "mat_outline:developer_board",
                            name: "Client ID",
                            key: result.data.client_id
                        },
                        {
                            icon: "mat_outline:vpn_key",
                            name: "Client Secret",
                            key: result.data.client_secret
                        }
                    ]
                    this.modalService.open('userAPIKey');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }


    deleteAPIKey(): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to delete API key?',
            icon: { show: false, name: "", color: "accent" },
            actions: {
                confirm: { show: true, label: "Yes", color: "primary" },
                cancel: { show: true, label: "No" }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true, 'Deleting client api key');
                this._bs.doRequest(`/d/user/remove_secret/${this.currentUser.user_id}`, 'get')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', 'API key deleted successfully');
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });

    }

    resendCodeConfirmationDialog($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to resend code of this user ' + $event.first_name + '?',
            icon: { show: false, name: "", color: "accent" },
            actions: {
                confirm: { show: true, label: "Yes", color: "primary" },
                cancel: { show: true, label: "No" }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                let pid = localStorage.getItem('_pid');
                let tid = localStorage.getItem('xtid');
                this._bs.doRequest(`/w/user/resend_code`, 'post', { user_id: $event.id, tenant_id: tid, pod_id: pid })
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', result.message);
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }
    resetMFAConfirmationDialog($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to reset MFA of this user ' + $event.first_name + '?',
            icon: { show: false, name: "", color: "accent" },
            actions: {
                confirm: { show: true, label: "Yes", color: "primary" },
                cancel: { show: true, label: "No" }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                this._bs.doRequest(`/d/company/reset_mfa/${$event.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', result.message);
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }
    async getComp() {
        this.sourceCompany = await this._cs.getCompanies('');
    }

    getRoles(): void {
        this.ls.display(true);
        this._bs.doRequest(`/r/user/get_roles`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        if (this._bs.user().tenant_name === 'ajgcdc') {
                            res.data.push('customer');
                        }
                        this.roles = res.data;
                    } else {
                        this.roles = [];
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    saveUser(): void {
        const user: any = this.contactForm.getRawValue();
        user.included = '';
        user.excluded = '';
        // if (user.roles.indexOf('admin') === -1) {
        if (user.company_type === 'include') {
            user.included = user.companies.toString();
        } else {
            user.excluded = user.companies.toString();
        }
        if (user.excluded === '*') {
            this.toast.sToast('error', 'Kindly select at least one company to exclude.');
            return;
        }
        // }
        // if (user.roles.indexOf('admin') > -1) {
        //     user.included = '*';
        // }
        delete user.passwordConfirm;
        user.mobile = user.mobile.toString();
        delete user.company_type;
        delete user.companies;
        user.password = '';
        this.ls.display(true);
        this._bs.doRequest(`/w/user/create_user`, 'post', user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.toast.sToast('success', `User created successfully. An initialization email is sent to the created user's Email Address to set up the user.`);
                    setTimeout(() => this.showHideTable());
                    this.uView = 'table';
                } else {
                    const data = (res.message) ? res.message : res.data;
                    if (data.includes('User already exists')) {
                        this.toast.sToast('info', 'User already exists.');
                    } else {
                        this.toast.sToast('error', data);
                    }
                }
            });
    }

    updateUserRoles(): void {
        const user: any = Object.assign({}, this.currentUser);
        // delete user.first_name;
        // delete user.last_name;
        // delete user.email;
        user.included = '';
        user.excluded = '';
        // if (user.roles.indexOf('admin') === -1) {
        if (user.company_type === 'include') {
            user.included = user.companies.toString();
        } else {
            user.excluded = user.companies.toString();
        }
        // }
        // if (user.roles.indexOf('admin') > -1) {
        //     user.included = '*';
        // }
        if (user && user.company_type && user.company_type === 'exclude' && user.companies.length && user.companies[0] === '*') {
            this.toast.sToast('error', 'Please choose at least one company to exclude.');
            return;
        }
        delete user.company_type;
        delete user.companies;
        this.ls.display(true);
        this._bs.doRequest(`/w/user/update_role`, 'post', user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.toast.sToast('success', 'User updated successfully!');
                    this.modalService.close('userRoleEdit');
                    setTimeout(() => this.showHideTable());
                } else {
                    const data = (res.message) ? res.message : res.data;
                    if (data.includes('User already exists')) {
                        this.toast.sToast('info', 'User already exists.');
                    } else {
                        this.toast.sToast('error', data);
                    }
                }
            });
    }

    deleteUser($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to delete ${$event.user_name} user ?`,
            actions: {
                confirm: {
                    label: 'Delete'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                this._bs.doRequest(`/d/user/delete_user/${$event.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', 'User removed successfully');
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }

    showHideTable(): void {
        const data = Object.assign({}, this.userTableOption);
        this.userTableOption = {};
        this._changeDetectorRef.detectChanges();
        data.pageData = [];
        data.tableOptions.pageTotal = 0;
        this.userTableOption = data;
        this.userTableOption.pageData = [];
        this.userTableOption.tableOptions.pageTotal = 0;
        this.getUsers();
        this._changeDetectorRef.detectChanges();
    }

    getUsers(): void {
        this.userTableOption = {
            columns: [
                {
                    "header": "First Name",
                    "columnDef": "first_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.first_name}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Last Name",
                    "columnDef": "last_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.last_name}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Email",
                    "columnDef": "email",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.email}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "User Name",
                    "columnDef": "user_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.user_name}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Last Login",
                    "columnDef": "last_login",
                    "cType": "string",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => ${element.last_login}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                
                {
                    "header": "Role",
                    "columnDef": "roles",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.roles}",
                    "order": 0,
                    "width": "400px",
                    textBadge: true,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false,
                    noUserFilter: true
                },
                {
                    "header": "Company Access",
                    "columnDef": "included",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => `${element.included}`",
                    "order": 3,
                    "visible": true,
                    cHyperLink: true,
                    conditions: ['included', 'excluded'],
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    noUserFilter: true,
                },
                {
                    "header": "Companies",
                    "columnDef": "company_names",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => `${element.company_names}`",
                    "order": 3,
                    "visible": false,
                    // cHyperLink: true,
                    // conditions: ['included', 'excluded'],
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    isShowMoreData: true,
                },
                {
                    "header": "State",
                    "columnDef": "state",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.state}",
                    isColoredCntrl: true,
                    isCustomClass: true,
                    isCustomText: true,
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false,
                    noUserFilter: true,
                },
            ], sortOptions: { active: 'created', direction: 'desc' },
            _pageData: [],
            overridePageLoad: true,
            tableOptions: {
                title: 'Users',
                isServerSide: false,
                selectText: 'Users',
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                isCustomSort: true,
                showAction: true,
                apiDownload: true,
                hideDownload: true,
                actionMenuItems: [
                    {
                        text: 'Edit User',
                        id: 'edit',
                        icon: 'edit',
                        callback: 'editFn',
                        hideLocal: false,
                        isGlobal: false,
                    },
                    {
                        text: 'Resend Code',
                        icon: 'password',
                        callback: 'deleteFn',
                        isGlobal: false
                    },
                    {
                        text: 'Reset MFA',
                        icon: 'restore',
                        callback: 'deleteFn',
                        isGlobal: true
                    },
                    {
                        text: 'Delete',
                        id: 'delete',
                        icon: 'delete',
                        callback: 'editFunction',
                    },
                    {
                        text: 'API Key',
                        icon: 'vpn_key',
                        callback: 'deleteFn',
                        actionConditions: { key: 'state' },
                        allowedCondition: ['USER_STATE_ACTIVE']
                    }
                ],
                pagination: false,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showFilter: true,
                showTagFilter: false,
                showhideList: true,
                refreshData: true,
                isDefaultSearch: true,
                exportExcel: true,
                add: true,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                isNotSiteChange: true,
                filterEmitCal: 'userFilterCal',
                // serverSide: {
                //     url: '/r/user/get_users',
                //     condition: '',
                //     type: 'post',
                //     isGlobal: true,
                //     params: { customer: false }
                // },
                id: 'users'
            },
            changeValue: new Subject<any>(),
            customText: [
                {
                    status: true,
                    DisplayText: 'Yes',
                    class: 'ring-1 rounded text-xs px-1 py-0.5'
                },
                {
                    status: false,
                    DisplayText: 'No',
                    class: 'ring-1 rounded text-xs px-1 py-0.5'
                },
                {
                    status: 'USER_STATE_INITIAL',
                    DisplayText: 'Initial',
                    class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'
                },
                {
                    status: 'USER_STATE_ACTIVE',
                    DisplayText: 'Active',
                    class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
                },
                {
                    status: 'USER_STATE_LOCKED',
                    DisplayText: 'Locked',
                    class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
                },
                {
                    status: 'USER_STATE_INACTIVE',
                    DisplayText: 'Inactive',
                    class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
                },
            ],
        }
        this.showTable = false;
        this.ls.display(true);
        let url = '/r/user/get_users';
        let method = 'get';
        let params = {
            "customer": false,
            // "skip": 0,
            // "limit": "5",
            "order_by": "created desc",
            "condition": true
        };
        this._bs.doRequest(url, method, null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    this.ls.display(false);
                    this.showTable = true;
                    if (result.status) {
                        result.data.forEach((entry: any) => {
                            entry.company_names = []
                            if (entry.included && entry.included !== '*' && entry.included.length) {
                                let tempIncluded = entry.included.split(",");
                                const matchingCompanies = this._cs.allComp.filter((company: any) => tempIncluded.includes(company.id));
                                entry.company_names = matchingCompanies.map((company: any) => company.name);
                                entry.matchingCompanyIds = matchingCompanies.map((company: any) => parseInt(company.id, 10));
                            } else if (entry.included === "*") {
                                entry.company_names = this._cs.allComp.map((name: any) => name.name);
                            } else if (entry.excluded && entry.excluded !== '*' && entry.excluded.length) {
                                let tempExcluded = entry.excluded.split(",");
                                const matchingCompanies = this._cs.allComp.filter((company: any) => tempExcluded.includes(company.id));
                                entry.company_names = matchingCompanies.map((company: any) => company.name);
                                entry.matchingCompanyIds = matchingCompanies.map((company: any) => parseInt(company.id, 10));
                            }
                            else if (entry.excluded === "*") {
                                entry.company_names = this._cs.allComp.map((name: any) => name.name);
                            }
                            else {
                                entry.company_names = []; // Or any default value
                            }
                        });

                        this.allCompanies = cloneDeep(this._cs.allComp);
                        for (let i = 0; i < this._cs.allComp.length; i++) {
                            this.allCompanies[i].id = parseInt(this.allCompanies[i].id);
                        }

                        this.userTableOption.pageData = result.data;
                        this.originalUserData = result.data;
                        this.userTableOption.tableOptions.pageTotal = result.data.length;
                        this.userTableOption.tableOptions.pageSize = 0;
                        this.userTableOption.tableOptions.pagination = false;
                        this.userTableOption.tableOptions.loading = false;
                        this.showTable = true;
                    }
                    else {
                        this.userTableOption.pageData = [];
                        this.originalUserData = [];
                        this.userTableOption.tableOptions.pageTotal = 0;
                        this.userTableOption.tableOptions.pageSize = 0;
                        this.userTableOption.tableOptions.pagination = false;
                        this.userTableOption.tableOptions.loading = false;
                        this.showTable = true;
                        this.toast.sToast('error', `${result.message}`);
                    }
                }),
            (error: any) => {
                this.toast.sToast('error', `${error.message}`, `Error Code : ${error.status}`);
                console.log(error);
            }

    }

    convertArrayOfObjectsToCSV(data, columnMapping, dynamicKey, dynamicKey2) {
        const orderedKeys = Object.keys(columnMapping); // Get the ordered keys from columnMapping
        const escapeValue = (value) => {
            // Ensure value is a string, escape double quotes, and wrap in double quotes
            return `"${String(value).replace(/"/g, '""')}"`;
        };
        const orderedData = data.map(obj => {
            const orderedObj = {};
            orderedKeys.forEach(key => {
                const value = obj[key];
                if (key === dynamicKey) {
                    if (typeof value === 'string') {
                        orderedObj[columnMapping[key]] = escapeValue(value);
                    } else if (Array.isArray(value)) {
                        orderedObj[columnMapping[key]] = escapeValue(JSON.stringify(value));
                    } else {
                        orderedObj[columnMapping[key]] = '';
                    }
                }
                else if (key === dynamicKey2) {
                    if (obj.included !== 'All' && obj.excluded !== 'All Denied') {
                        if (typeof value === 'string') {
                            orderedObj[columnMapping[key]] = escapeValue(value);
                        } else if (Array.isArray(value)) {
                            orderedObj[columnMapping[key]] = escapeValue(JSON.stringify(value));
                        } else {
                            orderedObj[columnMapping[key]] = '';
                        }
                    }
                    else {
                        orderedObj[columnMapping[key]] = '';
                    }

                }
                else {
                    orderedObj[columnMapping[key]] = value !== undefined && value !== null ? escapeValue(value) : '';
                }
            });
            return orderedObj;
        });

        const header = orderedKeys.map(key => escapeValue(columnMapping[key])).join(','); // Use ordered keys for the header
        const rows = orderedData.map(obj => orderedKeys.map(key => obj[columnMapping[key]]).join(','));
        const csvContent = [header, ...rows].join('\n');

        // For downloading the CSV
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) { // Feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            const dateStr = new Date().toLocaleDateString().replace(/\//g, '_') + '_'
                + new Date().toLocaleTimeString().replace(/\:/g, '_');
            link.setAttribute('download', `${dateStr}_users.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    }
    apiDataDownload($event: any): void {
        const fieldmap = {};
        const columnsF = cloneDeep(this.userTableOption.columns);
        for (const col of columnsF) {
            if ((col.visible && !col.isNoVisible) || col.columnDef === 'roles' || col.columnDef === 'company_names') {
                // @ts-ignore
                fieldmap[col.columnDef] = col.header;
            }
        }
        const resp = cloneDeep(this.userTableOption.pageData);
        resp.map((x: any) => {
            x.included = (x.included === '*') ? 'All' : (x.included != '' && x.excluded === '') ? 'Allowed' : (x.excluded == '*') ? 'All Denied' : (x.included == '' && x.excluded !== '' && x.excluded !== '*') ? 'Denied' : 'All';
            // x.inclueded = (x.included === '*' ? 'All' : ( x.included !== '' && x.excluded ==='') ? 'Allowed' : (x.included === '' && x.excluded !=='' && x.excluded !=='*' ) ? 'Denied' : 'All Denied');
            const roles = (x.roles && x.roles.length) ? x.roles.filter((x: any) => x !== null) : [];
            if (!roles || !roles.length) {
                x.roles = ['admin']
            }
        })
        this.convertArrayOfObjectsToCSV(resp, fieldmap, 'roles', 'company_names');
    }

    ngAfterViewInit() { }

    filterCompany(): void {
        if (!this.searchTerm) {
            this.userData = this.masterUserData;
        } else {
            let lowerSearchTerm = this.searchTerm.toLowerCase();
            this.userData = this.masterUserData.filter((name: any) => name.toLowerCase().includes(lowerSearchTerm));
        }
    }

    userFilterCall(rule: any): void {
        this.showTable = true;
        const response = (rule) ? this._cs.filterConditionRule(this.originalUserData, rule) : this.originalUserData;
        this._cs.tableFilterCalBack.next({ value: response });
    }
}
