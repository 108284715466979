<mat-form-field class="w-full sm:w-1/2 mt-4 ml-4" [subscriptSizing]="'dynamic'">
    <mat-label>Select Credential</mat-label>
    <mat-select [(ngModel)]="credentialId" (selectionChange)="filterByCred($event)">
        <ng-container *ngFor="let cred of integrationCred; trackBy: trackByFn">
            <mat-option [value]="cred.id">{{cred.name}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
<div class="flex flex-col w-full min-w-0" *ngIf="cView === 'table' && integrationCred && currentIntegration">
    <stable [sTableOptions]="intProfileTableOptions" (addCallback)="addTableData()" (actionCallback)="actionCall($event)">
    </stable>
</div>

<div class="flex flex-col w-full min-w-0" *ngIf="cView === 'add'">
    <div class="flex overflow-y-auto flex-col-reverse">
        <div class="flex flex-col flex-auto shrink p-4 bg-card dark:bg-transparent">
            <app-dynamic-form [isSaveBtn]="false" [isCloseBtn]="false" [spanLayout]="2"
            *ngIf="formElements && formElements.length" (valueUpdate)="actionChange($event)"
                [listOfFormElements]="formElements" [Valuesoutput]="profile">
            </app-dynamic-form>
            <div class="mt-11 mb-10 border-t"></div>
            <div class="flex items-center justify-end">
                <button (click)="closeFn()" mat-stroked-button type="button">
                    Cancel
                </button>
                <button (click)="saveFn()" appPreventMultiClick [disabled]="!disabledSubmit" class="ml-4" mat-flat-button type="button" [color]="'primary'">Save
                </button>
            </div>

        </div>
    </div>
</div>
