<ng-container *transloco="let t">
  <mat-card class="w-full h-full m-4 overflow-hidden bg-card rounded-lg ring-1" *ngIf="isLoading">
    <mat-card-content>
      <p><i class="fa fa-spin fa-spinner fa-lg"></i> Loading...</p>
    </mat-card-content>
  </mat-card>
  <mat-card class="m-4 overflow-hidden bg-card rounded-lg ring-1" *ngIf="!isLoading">
    <mat-drawer-container class="flex-auto sm:h-full">
      <mat-drawer class="sm:w-96 dark:bg-gray-900" [autoFocus]="false" [mode]="drawerMode" [opened]="drawerOpened"
        position="end" #drawer>
        <div class="flex flex-row items-center mx-2 my-2 md:my-10">
          <div class="text-2xl font-bold leading-none tracking-tight">Settings</div>
          <div class="spacer"></div>
          <div class="sm:hidden">
            <button type="button" mat-icon-button (click)="drawer.close()">
              <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
          </div>
        </div>
        <div class="flex flex-col border-t border-b divide-y" *ngIf="tableOptions.floatingFilter">
          <div class="navigation-items gap-[15px]">
            <form class="flex flex-col items-center justify-center">
              <mat-form-field class="pl20" style="padding: 10px;font-size: 15px;" *ngIf="tableOptions.dropdwn">
                <mat-select [multiple]="false" placeholder="Asset" (selectionChange)="filterDrpLstChange($event.value)"
                  [formControl]="assetDrpCtrl">
                  <mat-option *ngFor="let item of tableOptions.filterDrpLstFilteredList; trackBy: trackByFn"
                    [value]="item._id">
                    {{item.ip}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never"
                class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-[64px]">
                <input matInput class="form-control" [(ngModel)]="filterText" type="text" trim="blur"
                  name="filterText{{tableOptions.id}}" id="{{tableOptions.id}}_filter"
                  (ngModelChange)="filterUpdate.next($event)" placeholder="Search">
                <button type="button" class="gray" matSuffix mat-icon-button *ngIf="!filterText || filterText === ''"
                  color="primary">
                  <mat-icon>search</mat-icon>
                </button>
                <button type="button" class="gray" matSuffix mat-icon-button *ngIf="filterText && filterText !== ''"
                  (click)="filterText = ''; doFilter(filterText);" color="primary">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                <button type="button" class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data "
                  (click)="RefreshTableData();" color="primary">
                  <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="mat_outline:refresh"></mat-icon>
                </button>
              </div>
              <div *ngIf="tableOptions.fontsizeData">
                <mat-form-field
                  class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                  <mat-select [(value)]="selectedFont" (selectionChange)="textSizeCallData()"
                    matTooltip="Change Font Size">
                    <mat-option value="small" selected>Small</mat-option>
                    <mat-option value="medium" id="mtext">Medium</mat-option>
                    <mat-option value="large" id="ltext">Large</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="tableOptions.refreshData">
                <mat-form-field
                  class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                  <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                    matTooltip="Refresh Interval">
                    <mat-option value="0" selected>No Refresh</mat-option>
                    <mat-option value="60" id="ref1">1 Min</mat-option>
                    <mat-option value="300" id="ref5">5 Min</mat-option>
                    <mat-option value="1800" id="ref30">30 Min</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                *ngIf="tableOptions.showColFilter && _sTableOptions.columns && _sTableOptions.columns.length"
                [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                <mat-icon>filter_list</mat-icon>
              </button>
              <mat-menu #filterMenu="matMenu" class="col-filters">
                <mat-card class="filter-card" (click)="$event.stopPropagation();">
                  <mat-card-header>
                    <mat-card-title>Filter</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <form *ngIf="_sTableOptions && _sTableOptions.columns">
                      <ng-container *ngFor="let column of _sTableOptions.columns; trackBy: trackByFn">
                        <mat-form-field *ngIf="column['iscolumnSearch']" class="w-full">
                          <input matInput type="text" trim="blur" [id]="column.id"
                            placeholder="Search {{column.header}}" *ngIf="!column['selectFilter']"
                            name="{{column['columnDef']}}" [(ngModel)]="colfilter[column['columnDef']]"
                            (input)='columnFilter($any($event.target).value,column["columnDef"])'
                            [disabled]="(column['iscolumnSearch'] === undefined || column['iscolumnSearch']===null) ? false : !column['iscolumnSearch'] ">
                          <mat-select placeholder="Choose filter value" name="{{column['columnDef']}}"
                            *ngIf="column['selectFilter']" [(ngModel)]="colfilter[column['columnDef']]"
                            [disabled]="(column['iscolumnSearch'] === undefined || column['iscolumnSearch']===null) ? false : !column['iscolumnSearch'] "
                            (selectionChange)="columnFilter($event.value, column['columnDef'])">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let val of column['selectFilterArr']; trackBy: trackByFn"
                              [value]="val.value" [id]="val.id">{{val.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                    </form>
                  </mat-card-content>
                </mat-card>
              </mat-menu>
              <button type="button" mat-icon-button menuOpen="false" (click)="openListColumns()"
                #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="belowMenu" color="primary"
                id="view{{tableOptions.id}}" matTooltip="View Column, Reorder Columns"
                *ngIf="tableOptions.showhideList">
                <mat-icon>view_column</mat-icon>
              </button>
              <mat-menu #belowMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                  <div class="flex flex-row items-center w-full"
                    *ngFor="let item of showhideList; let i = index; trackBy: trackByFn" cdkDrag>
                    <i class="mt-2 mr-1 cursor-move material-icons color_icon"> drag_handle </i>
                    <mat-checkbox color="primary" [checked]="item.visible == true ? item :''"
                      (click)="$event.stopPropagation()" (change)="showHideColumn(i, item)" class="left-align"
                      [id]="item.header"> {{item.header}}
                    </mat-checkbox>
                  </div>
                </div>
              </mat-menu>
              <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                *ngIf="sTableOptions.cFilter" color="primary" [matMenuTriggerFor]="filterMenu"
                #filterMenuTrigger="matMenuTrigger">
                <mat-icon class="">filter_list</mat-icon>
              </button>
              <mat-menu #filterMenu="matMenu" class="col-filters">
                <mat-card class="filter-card" (click)="$event.stopPropagation();">
                  <mat-card-header>
                    <mat-card-title>Filter</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <form #filterForm="ngForm" role="form">
                      <mat-form-field *ngIf="sTableOptions.columns && sTableOptions.columns.length" class="w-full"
                        appearance="outline">
                        <mat-label>Choose filter column</mat-label>
                        <mat-select placeholder="Choose filter column" name="chooseFilter" required
                          [(ngModel)]="cFilter.selectedCol">
                          <mat-option value="">Choose filter column</mat-option>
                          <mat-option *ngFor="let val of sTableOptions.columns; trackBy: trackByFn" [value]="val.col"
                            [id]="val.id">{{val.header}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field *ngIf="cFilter.selectedCol" class="w-full" appearance="outline">
                        <mat-label>Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                        <input matInput type="text" trim="blur"
                          *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                          placeholder="Search {{colHash[cFilter.selectedCol].header}}" required
                          name="{{colHash[cFilter.selectedCol].col}}s" [(ngModel)]="colHash[cFilter.selectedCol].val">
                        <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                          name="{{colHash[cFilter.selectedCol].col}}s"
                          *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'" required
                          [(ngModel)]="colHash[cFilter.selectedCol].val">
                          <mat-option
                            *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options; trackBy: trackByFn"
                            [value]="val.value">{{val.name}}</mat-option>
                        </mat-select>
                        <ng-container *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                          <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate name="startDate" [(ngModel)]="colHash[cFilter.selectedCol].start"
                              placeholder="Start date">
                            <input matEndDate name="startDate" [(ngModel)]="colHash[cFilter.selectedCol].end"
                              placeholder="End date">
                          </mat-date-range-input>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-date-range-picker #picker></mat-date-range-picker>
                        </ng-container>
                      </mat-form-field>
                      <button type="button" (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                        [disabled]="!filterForm.form.valid" color="primary" mat-raised-button="">Add Filter
                      </button>
                      <button type="button" class="ml-2" (click)="cFilter.selectedCol = ''" mat-stroked-button="">Clear
                      </button>
                    </form>
                  </mat-card-content>
                </mat-card>
              </mat-menu>
              <button type="button" id="gbtn{{sTableOptions.title}}" (click)="apiDownload('type')" color="primary"
                matSuffix mat-icon-button *ngIf="tableOptions.apiDownload" matTooltip="Download as CSV"
                aria-label="download csv menu">
                <mat-icon class="mat-24">file_download</mat-icon>
              </button>
              <button type="button" id="gbtn{{sTableOptions.title}}" color="primary" matSuffix mat-icon-button
                *ngIf="!tableOptions.hideDownload" matTooltip="Download as CSV" [matMenuTriggerFor]="xlsMenu"
                aria-label="download csv menu">
                <mat-icon class="mat-24">file_download</mat-icon>
              </button>
              <mat-menu #xlsMenu="matMenu">
                <button type="button" mat-menu-item id="cSelection" *ngIf="tableOptions.filterDownload"
                  (click)="downloadAsXls('filtered')">
                  <mat-icon class="mat-24">filter_list</mat-icon>
                  <span>Filtered Data</span>
                </button>
                <button type="button" mat-menu-item id="fData" (click)="downloadAsXls('full')">
                  <mat-icon class="mat-24">file_download</mat-icon>
                  <span>Full Data</span>
                </button>
              </mat-menu>
              <button type="button" mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data"
                color="primary" (click)="CompareData(compSelection.selected)">
                <mat-icon>compare</mat-icon>
              </button>
              <button type="button" mat-icon-button matTooltip="Save Settings" id="save{{tableOptions.id}}"
                (click)="SaveSettings()" color="primary">
                <mat-icon>save</mat-icon>
              </button>
              <button type="button" mat-raised-button color="primary" (click)="publish()" *ngIf="tableOptions.publish">
                Publish
              </button>
              <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]" target="_blank"
                color="primary"
                *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                <mat-icon>help</mat-icon>
              </a>
              <button type="button" *ngFor="let pbtn of tableOptions.parentCalls; trackBy: trackByFn" mat-icon-button
                [matTooltipPosition]="'right'" [matTooltip]="pbtn.tooltip" color="primary"
                (click)="ParentCalls(pbtn.id)">
                <mat-icon>{{pbtn.icon}}</mat-icon>
              </button>

              <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                <div>
                  <button type="button" mat-button (click)="exportAllData()">
                    Export All Data
                  </button>
                </div>
              </mat-menu>
            </form>
          </div>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <mat-toolbar class="bg-card border-b h-[47px] flex flex-row items-center overflow-auto" role="heading">
          <span class="text-lg font-medium leading-6 tracking-tight" *ngIf="!tableOptions.hideTitle">
            {{tableOptions.title }}
            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm "
              *ngIf="tableOptions.pageTotal !== 0 && tableOptions.title !== '' ">
              {{tableOptions.pageTotal}} </span>
          </span>
          <button class="text-lg py-1 px-4 rounded mini-form-field" mat-flat-button
            *ngIf="tableOptions.add && !tableOptions.addText" type="button" matTooltip="Add New Record" color="primary"
            (click)="AddTableRecord()" id="addbtn{{tableOptions.title}}">
            <mat-icon class="mat-18">add</mat-icon>
            Add
          </button>
          <button class="text-lg py-1 px-4 rounded rounded mini-form-field" mat-flat-button
            *ngIf="tableOptions.add && tableOptions.addText" type="button" [matTooltip]="tableOptions.addText"
            color="primary" (click)="AddTableRecord()" id="addbtn{{tableOptions.title}}">
            <mat-icon class="mat-18" *ngIf="tableOptions.addIcon">{{tableOptions.addIcon}}</mat-icon>
            <mat-icon class="mat-18" *ngIf="!tableOptions.addIcon">add</mat-icon>
            <span class="mr-1"> {{tableOptions.addText}}</span>
          </button>
          <div class="spacer"></div>
          <div class="flex flex-row items-center justify-end w-full" *ngIf="tableOptions.floatingFilter">
            <button type="button" class="-ml-2 sm:hidden" mat-icon-button (click)="drawer.toggle()">
              <mat-icon color="primary">settings</mat-icon>
            </button>
            <div class="flex gap-[15px] hidden sm:inline-flex">
              <form class="flex flex-row items-center justify-center w-full">
                <mat-form-field class="pl20" style="padding: 10px;font-size: 15px;" *ngIf="tableOptions.dropdwn">
                  <mat-select [multiple]="false" placeholder="Asset"
                    (selectionChange)="filterDrpLstChange($event.value)" [formControl]="assetDrpCtrl">
                    <mat-option *ngFor="let item of tableOptions.filterDrpLstFilteredList; trackBy: trackByFn"
                      [value]="item._id">
                      {{item.ip}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="never"
                  class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-[240px]">
                  <input matInput class="form-control" [(ngModel)]="filterText" type="text" trim="blur"
                    name="filterText{{tableOptions.id}}" id="{{tableOptions.id}}_filter_sub"
                    (ngModelChange)="filterUpdate.next($event)" placeholder="Search">
                  <button class="gray" matSuffix mat-icon-button type="button" *ngIf="!filterText || filterText === ''"
                    color="primary">
                    <mat-icon>search</mat-icon>
                  </button>
                  <button class="gray" matSuffix mat-icon-button type="button" *ngIf="filterText && filterText !== ''"
                    (click)="filterText = ''; doFilter(filterText);" color="primary">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                  <button type="button" class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data"
                    (click)="RefreshTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="mat_outline:refresh"></mat-icon>
                  </button>
                </div>
                <div class="flex flex-row items-end" *ngIf="tableOptions.showFilter">
                  <button type="button" class="gray" matSuffix mat-icon-button matTooltip="Filter Table Data"
                    (click)="FilterTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}">filter_alt</mat-icon>
                  </button>
                </div>
                <div class="flex flex-row items-end" *ngIf="tableOptions.showTagFilter">
                  <button type="button" class="gray" matSuffix mat-icon-button matTooltip="Filter by Tags"
                    (click)="FilterTagTableData();" color="primary">
                    <mat-icon id="rfrbtn{{tableOptions.title}}" svgIcon="heroicons_solid:tag"></mat-icon>
                  </button>
                </div>
                <div *ngIf="tableOptions.fontsizeData">
                  <mat-form-field
                    class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                    <mat-select [(value)]="selectedFont" (selectionChange)="textSizeCallData($event)"
                      matTooltip="Change Font Size">
                      <mat-option value="small" selected>Small</mat-option>
                      <mat-option value="medium" id="mediumtext">Medium</mat-option>
                      <mat-option value="large" id="largeText">Large</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="tableOptions.refreshData">
                  <mat-form-field
                    class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                    <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                      matTooltip="Refresh Interval">
                      <mat-option value="0" selected>No Refresh</mat-option>
                      <mat-option value="60" id="RefreshOnemin">1 Min</mat-option>
                      <mat-option value="300" id="RefreshFivemin">5 Min</mat-option>
                      <mat-option value="1800" id="RefreshThirtymin">30 Min</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                  *ngIf="tableOptions.showColFilter && _sTableOptions.columns && _sTableOptions.columns.length"
                  [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <mat-menu #filterMenu="matMenu" class="col-filters">
                  <mat-card class="filter-card" (click)="$event.stopPropagation();">
                    <mat-card-header>
                      <mat-card-title>Filter</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <form *ngIf="_sTableOptions && _sTableOptions.columns">
                        <ng-container *ngFor="let column of _sTableOptions.columns; trackBy: trackByFn">
                          <mat-form-field *ngIf="column.iscolumnSearch" class="w-full">
                            <input matInput type="text" trim="blur" [id]="column.id"
                              placeholder="Search {{column.header}}" *ngIf="!column.selectFilter"
                              name="{{column.columnDef}}" [(ngModel)]="colfilter[column.columnDef]"
                              (input)='columnFilter($any($event.target).value,column.columnDef)'
                              [disabled]="(column['iscolumnSearch'] === undefined || column['iscolumnSearch']===null) ? false : !column['iscolumnSearch'] ">
                            <mat-select placeholder="Choose filter value" name="{{column.columnDef}}"
                              *ngIf="column.selectFilter" [(ngModel)]="colfilter[column.columnDef]"
                              [disabled]="(column['iscolumnSearch'] === undefined || column['iscolumnSearch']===null) ? false : !column['iscolumnSearch'] "
                              (selectionChange)="columnFilter($event.value, column.columnDef)">
                              <mat-option value="">All</mat-option>
                              <mat-option *ngFor="let val of column.selectFilterArr; trackBy: trackByFn"
                                [value]="val.value" [id]="val.id">{{val.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>
                      </form>
                    </mat-card-content>
                  </mat-card>
                </mat-menu>
                <button type="button" mat-icon-button menuOpen="false" (click)="openListColumns()"
                  #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="belowMenu" color="primary"
                  id="view{{tableOptions.id}}" matTooltip="View Column, Reorder Columns"
                  *ngIf="tableOptions.showhideList">
                  <mat-icon>view_column</mat-icon>
                </button>
                <mat-menu #belowMenu="matMenu" [hasBackdrop]="true" yPosition="below" class="p-4">
                  <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class="flex flex-row items-center w-full"
                      *ngFor="let item of showhideList; let i = index; trackBy: trackByFn" cdkDrag>
                      <i class="mt-2 mr-1 cursor-move material-icons color_icon"> drag_handle </i>
                      <mat-checkbox color="primary" [checked]="item.visible" (click)="$event.stopPropagation()"
                        (change)="showHideColumn(i, item)" class="left-align" [id]="item.header">
                        {{item.header}} </mat-checkbox>
                    </div>
                  </div>
                </mat-menu>
                <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                  *ngIf="sTableOptions.cFilter" color="primary" [matMenuTriggerFor]="filterMenu"
                  #filterMenuTrigger="matMenuTrigger">
                  <mat-icon class="">filter_list</mat-icon>
                </button>
                <mat-menu #filterMenu="matMenu" class="col-filters">
                  <mat-card class="filter-card" (click)="$event.stopPropagation();">
                    <mat-card-header>
                      <mat-card-title>Filter</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <form #filterForm="ngForm" role="form">
                        <mat-form-field *ngIf="sTableOptions.columns && sTableOptions.columns.length" class="w-full"
                          appearance="outline">
                          <mat-label>Choose filter column</mat-label>
                          <mat-select placeholder="Choose filter column" name="chooseFilter" required
                            [(ngModel)]="cFilter.selectedCol">
                            <mat-option value="">Choose filter column</mat-option>
                            <mat-option *ngFor="let val of sTableOptions.columns; trackBy: trackByFn" [value]="val.col"
                              [id]="val.id">{{val.header}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="cFilter.selectedCol" class="w-full" appearance="outline">
                          <mat-label>
                            Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                          <input matInput type="text" trim="blur"
                            *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                            placeholder="Search {{colHash[cFilter.selectedCol].header}}" required
                            name="{{colHash[cFilter.selectedCol].col}}s" [(ngModel)]="colHash[cFilter.selectedCol].val">
                          <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                            name="{{colHash[cFilter.selectedCol].col}}s"
                            *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'" required
                            [(ngModel)]="colHash[cFilter.selectedCol].val">
                            <mat-option
                              *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options; trackBy: trackByFn"
                              [value]="val.value">{{val.name}}</mat-option>
                          </mat-select>
                          <ng-container *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                            <mat-date-range-input [rangePicker]="picker">
                              <input matStartDate name="startDate" [(ngModel)]="colHash[cFilter.selectedCol].start"
                                placeholder="Start date">
                              <input matEndDate name="startDate" [(ngModel)]="colHash[cFilter.selectedCol].end"
                                placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                          </ng-container>
                        </mat-form-field>
                        <button type="button" (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                          [disabled]="!filterForm.form.valid" color="primary" mat-raised-button="">Add Filter
                        </button>
                        <button type="button" class="ml-2" (click)="cFilter.selectedCol = ''"
                          mat-stroked-button="">Clear
                        </button>
                      </form>
                    </mat-card-content>
                  </mat-card>
                </mat-menu>
                <button type="button" id="gbtn{{sTableOptions.title}}" (click)="apiDownload('type')" color="primary"
                  matSuffix mat-icon-button *ngIf="tableOptions.apiDownload" matTooltip="Download as CSV"
                  aria-label="download csv menu">
                  <mat-icon class="mat-24">file_download</mat-icon>
                </button>
                <button type="button" id="gbtn{{sTableOptions.title}}" color="primary" matSuffix mat-icon-button
                  *ngIf="!tableOptions.hideDownload" matTooltip="Download as CSV" aria-label="download csv menu"
                  (click)="downloadAsXls('full')">
                  <mat-icon class="mat-24">file_download</mat-icon>
                </button>
                <mat-menu #xlsMenu="matMenu">
                  <button type="button" mat-menu-item id="currentSelection" *ngIf="tableOptions.filterDownload"
                    (click)="downloadAsXls('filtered')">
                    <mat-icon class="mat-24">filter_list</mat-icon>
                    <span>Filtered Data</span>
                  </button>
                  <button type="button" mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                    <mat-icon class="mat-24">file_download</mat-icon>
                    <span>Full Data</span>
                  </button>
                </mat-menu>
                <button type="button" mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data"
                  color="primary" (click)="CompareData(compSelection.selected)">
                  <mat-icon>compare</mat-icon>
                </button>
                <button type="button" mat-icon-button matTooltip="Save Settings" id="save{{tableOptions.id}}"
                  color="primary" [matMenuTriggerFor]="saveMenu">
                  <mat-icon>save</mat-icon>
                </button>
                <mat-menu #saveMenu="matMenu">
                  <button type="button" mat-menu-item id="saveSettingT" (click)="SaveSettings()">
                    <mat-icon class="mat-24">save_alt</mat-icon>
                    <span>Save Table Settings</span>
                  </button>
                  <button type="button" mat-menu-item id="restSetting" (click)="ResetSettings()"
                    *ngIf="sessionData && sessionData.id">
                    <mat-icon class="mat-24">refresh</mat-icon>
                    <span>Reset Table Settings</span>
                  </button>
                </mat-menu>
                <button type="button" mat-raised-button color="primary" (click)="publish()"
                  *ngIf="tableOptions.publish">
                  Publish
                </button>
                <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]"
                  target="_blank" color="primary"
                  *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                  <mat-icon>help</mat-icon>
                </a>
                <button type="button" *ngFor="let pbtn of tableOptions.parentCalls; trackBy: trackByFn" mat-icon-button
                  [matTooltip]="pbtn.tooltip" color="primary" (click)="ParentCalls(pbtn.id)">
                  <mat-icon>{{pbtn.icon}}</mat-icon>
                </button>

                <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                  <div>
                    <button type="button" mat-button (click)="exportAllData()">
                      Export All Data
                    </button>
                  </div>
                </mat-menu>
              </form>
            </div>
          </div>
        </mat-toolbar>
        <mat-toolbar class="select-tools h-[47px] rounded bg-[#d1e3ff] dark:bg-default"
          *ngIf="selection.selected.length > 0">
          <span>{{selection.selected.length}} {{tableOptions.selectText}} selected.</span>
          <div class="flex flex-1 place-content-end">
            <ng-container>
              <button type="button" mat-flat-button color="primary" id="gbtnSelction{{tableOptions.title}}"
                mat-raised-button [matMenuTriggerFor]="gactionSMenu" class="mini-form-field"
                [disabled]="selection.selected.length === 0" aria-label="global action with a menu">
                Global Actions
              </button>
              <mat-menu #gactionSMenu="matMenu">
                <ng-container *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                  <ng-container *ngIf="item.companyCondCheck">
                    <a id="ganchsel{{item.text}}" class="mat-primary"
                      *ngIf="item.isGlobal && commonService.currentScope !== '*'" mat-menu-item
                      (click)="globalActionCall(selection.selected, item)">
                      <mat-icon *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                      <mat-icon *ngIf="item.icon && item.icon.includes(':')" [svgIcon]="item.icon"></mat-icon>
                      <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                      <span>{{ item.text }}</span>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="!item.companyCondCheck">
                    <a id="ganchsel{{item.text}}" class="mat-primary" *ngIf="item.isGlobal" mat-menu-item
                      (click)="globalActionCall(selection.selected, item)">
                      <mat-icon *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                      <mat-icon *ngIf="item.icon && item.icon.includes(':')" [svgIcon]="item.icon"></mat-icon>
                      <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                      <span>{{ item.text }}</span>
                    </a>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </ng-container>
            <button class="gray" type="button" matSuffix mat-icon-button (click)="clearSelection();">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-toolbar>
        <filter-widget [showFilter]="showFilter" [sColumns]="sColumns" (resetRulesFn)="setFilterRules($event)"
          [table_id]="sTableOptions.tableOptions.id" (toggleFilterFn)="toggleFilterRules($event)"
          (setRulesFn)="setFilterRules($event)"></filter-widget>
        <app-filter *ngIf="showTagFilter" [showTagFilter]="showTagFilter"
          (toggleTagFilterFn)="toggleTagFilterRules($event)" (setTagRulesFn)="setTagFilterRules($event)"></app-filter>
        <mat-card-content [ngClass]="{'mt-2': colFilters.length}" class="p-0 mb-0">
          <mat-progress-bar mode="indeterminate" *ngIf="tableOptions.loading"></mat-progress-bar>
          <div #MAINTABLEDIV class="w-full h-full py-2 bg-card overflow-auto">
            <table id="mytable" class="w-full" [ngClass]="{'tablePadding': isTablePadding===true}" mat-table
              [dataSource]="dataSource" *ngIf="dataSource" matSort #MainTable
              [matSortActive]="_sTableOptions['sortOptions']['active']"
              [matSortDirection]="_sTableOptions['sortOptions']['direction']" (matSortChange)="customSort($event)"
              fixedLayout="true" [trackBy]="trackById">
              <ng-container matColumnDef="select" *ngIf="tableOptions.rowSelection" sticky>
                <th mat-header-cell *matHeaderCellDef class="py-2 dark:bg-gray-700">
                  <mat-checkbox color="primary" class="stc mini-form-field" (change)="$event ? masterToggle() : null"
                    id="_selectDeselectAll" [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row; let i = index" class="py-2  bg-white z-5 dark:bg-[#1e293b]">
                  <mat-checkbox color="primary" class="stc mini-form-field"
                    (click)="$event.stopPropagation(); shiftKeyDown($event, i)" [id]="row._id"
                    (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
                </td>
              </ng-container>
              <ng-container *ngFor="let column of _sTableOptions.columns; trackBy: trackByFn"
                [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef [style.width]="column.width" [style.max-width]="column.width"
                  [style.min-width]="column.width" class="font-medium dark:bg-gray-700" [ngClass]="fontSizeClassHed">
                  <mat-label mat-sort-header class=""
                    [disabled]="(column.isSort === undefined || column.isSort === null) ? 'false' : !column.isSort ">
                    <span class="font-bold leading-6 tracking-tight truncate text-small">
                      {{column.header }}</span></mat-label>
                </th>
                <td mat-cell *matCellDef="let row"
                  [ngClass]="{'text-center': column.align === 'center',fontSizeClass, 'text-left': !column.align || column.align === 'left', 'text-right': column.align === 'right'}"
                  class="text-sm wwbreak" [style.width]="column.width" [style.vertical-align]="'middle'"
                  [style.max-width]="column.width" [style.min-width]="column.width">

                  <ng-container *ngIf="column.filter">
                    <span [ngClass]="fontSizeClass"
                      *ngIf="!column.isHyperlink && column.isHtml && column.filter && column.htmlIcon">
                      <div [innerHTML]="getProperty(row, column.columnDef) | formatCell:column.filter">
                      </div>
                    </span>
                    <ng-container *ngIf="column.isHyperlink && !column.isClass">
                      <span [ngClass]="fontSizeClass" *ngIf="getProperty(row, column.columnDef)">
                        <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                          (click)="hyperLinkCall(row, column.columnDef)">
                          <span class="" [ngClass]="column.class"> {{getProperty(row, column.columnDef) |
                            formatCell:column.filter}} </span>
                        </a>
                      </span>
                    </ng-container>
                    <span
                      *ngIf="getProperty(row, column.columnDef) && !column.htmlIcon && !column.isEpssScore && !column.isHyperlink"
                      [ngClass]="fontSizeClass">
                      <span>{{ getProperty(row, column.columnDef) | formatCell:column.filter}}</span>
                    </span>
                    <ng-container *ngIf="column.isEpssScore">
                      <span class="label {{fontSizeClass}}" *ngIf="getProperty(row, column.columnDef)" [ngClass]="{
                        'text-black bg-[#67ace1]': 0.0 <= getProperty(row, column.columnDef) && 0.85 >= getProperty(row, column.columnDef),
                        'text-black bg-[#f8c851]': 0.85 <= getProperty(row, column.columnDef) && 0.90 >= getProperty(row, column.columnDef),
                        'text-black bg-[#f18c43]': 0.90 <= getProperty(row, column.columnDef) && 0.95 >= getProperty(row, column.columnDef),
                        'text-white bg-[#92243e]': 0.95 <= getProperty(row, column.columnDef) && 1 >= getProperty(row, column.columnDef),
                        }">
                        {{ getProperty(row, column.columnDef) | formatCell:column.filter}}
                      </span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!column.filter">
                    <ng-container *ngIf="column.isHyperlink && !column.isClass">
                      <span [ngClass]="fontSizeClass" *ngIf="getProperty(row, column.columnDef)">
                        <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                          (click)="hyperLinkCall(row, column.columnDef)">
                          <span class="" [ngClass]="column.class"> {{getProperty(row, column.columnDef)}} </span>
                        </a>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.isSeverity">
                      <span class="label {{fontSizeClass}}"
                        [ngClass]="'cs-'+ (getProperty(row, column.columnDef).toLowerCase() === 'informational' ? 'info' : getProperty(row, column.columnDef).toLowerCase())"
                        *ngIf="getProperty(row, column.columnDef)">
                        {{ getProperty(row, column.columnDef)}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.ishyperBold">
                      <span
                        class="whitespace-no-wrap hidden py-4 text-sm font-semibold text-blue-800 dark:text-blue-400 lg:table-cell">
                        <span (click)="hyperLinkCall(row, column.columnDef)" class="cursor-pointer"
                          matTooltip="View Details">{{getProperty(row, column.columnDef)}}</span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.isEpssScore">
                      <span class="label {{fontSizeClass}}" *ngIf="getProperty(row, column.columnDef)" [ngClass]="{
                        'text-black bg-[#67ace1]': 0.0 <= getProperty(row, column.columnDef) && 0.85 >= getProperty(row, column.columnDef),
                        'text-black bg-[#f8c851]': 0.85 <= getProperty(row, column.columnDef) && 0.90 >= getProperty(row, column.columnDef),
                        'text-black bg-[#f18c43]': 0.90 <= getProperty(row, column.columnDef) && 0.95 >= getProperty(row, column.columnDef),
                        'text-white bg-[#92243e]': 0.95 <= getProperty(row, column.columnDef) && 1 >= getProperty(row, column.columnDef),
                        }">
                        {{ getProperty(row, column.columnDef)}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.isBaseScore">
                      <span class="label {{fontSizeClass}}" *ngIf="getProperty(row, column.columnDef)" [ngClass]="{
                        'text-black bg-[#6fb8f9]': 0 <= getProperty(row, column.columnDef) && 0.4 >= getProperty(row, column.columnDef),
                        'text-black bg-[#FCE94E]': 0.5 <= getProperty(row, column.columnDef) && 0.74 >= getProperty(row, column.columnDef),
                        'text-black bg-[#f8c851]': 0.75 <= getProperty(row, column.columnDef) && 1 >= getProperty(row, column.columnDef),
                        'text-black bg-[#ffe24b]': 1 <= getProperty(row, column.columnDef) && 4.9 >= getProperty(row, column.columnDef),
                        'text-[#ffffff] bg-[#de4a50]': 5 <= getProperty(row, column.columnDef) && 8.9 >= getProperty(row, column.columnDef),
                        'text-[#ffffff] bg-[#92243e]': 9 <= getProperty(row, column.columnDef) && 10 >= getProperty(row, column.columnDef),
                        }">
                        {{ getProperty(row, column.columnDef)}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.isVulnerability">
                      <span class="label {{fontSizeClass}}"
                        *ngIf="getProperty(row, column.columnDef) && !column.isCusHyperlink" [ngClass]="{
                        'cs-critical': (column.is_total_problems || column.is_critical_problems),
                        'cs-high': column.is_high_problems,
                        'cs-medium': column.is_medium_problems,
                        'cs-low': column.is_low_problems,
                        }">
                        {{getProperty(row, column.columnDef)}}
                      </span>
                      <ng-container *ngIf="getProperty(row, column.columnDef) && column.isCusHyperlink">
                        <a class="cursor-pointer" (click)="hyperLinkCall(row, column.columnDef)">
                          <span class="label {{fontSizeClass}}" [ngClass]="{
                              'cs-critical': (column.is_total_problems || column.is_critical_problems),
                              'cs-high': column.is_high_problems,
                              'cs-medium': column.is_medium_problems,
                              'cs-low': column.is_low_problems,
                              }">
                            {{ getProperty(row, column.columnDef)}}
                          </span>
                        </a>
                      </ng-container>
                    </ng-container>
                    <span [ngClass]="fontSizeClass"
                      *ngIf="column.isHyperlink && !column.isToolTip && !column.isTargetLink && column.isClass">
                      <div class="flex flex-row items-center w-[110px]" [ngClass]="column.width">
                        <span *ngIf="column.img && column.imgPath && !column.customImg">
                          <img [src]="column.imgPath" alt="generic" class="w-[1.5rem]">
                        </span>
                        <a class="ml-2 text-blue-500 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                          *ngIf="column.img && column.iconPath" (click)="hyperLinkCall(row, column.columnDef)">
                          <span>
                            <ng-container *ngIf="!column.iconPathCondition">
                              <mat-icon [svgIcon]="column.iconPath"
                                class="w-[1.5rem] dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                [ngClass]=" column.class"></mat-icon></ng-container>
                            <ng-container *ngIf="column.iconPathCondition">
                              <mat-icon
                                *ngIf="getProperty(row, column.iconPathCondition.key) && getProperty(row, column.iconPathCondition.key).toLowerCase() === column.iconPathCondition.value.toLowerCase()"
                                [svgIcon]="column.iconPath"
                                class="w-[1.5rem] dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                [ngClass]=" column.class"></mat-icon>
                            </ng-container>
                          </span>
                        </a>
                        <span *ngIf="column.customImg">
                          <img [src]="column.imgPath+'generic.svg'" *ngIf="!getProperty(row, column.imgCol)"
                            [alt]="getProperty(row, column.imgCol)" class="w-[1.2rem]">
                          <img [src]="column.imgPath+getProperty(row, column.imgCol)"
                            *ngIf="getProperty(row, column.imgCol)" [alt]="getProperty(row, column.imgCol)"
                            class="w-[1.2rem]">
                        </span>
                        <a class="ml-2 text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                          *ngIf="!column.isCustomClass && !column.iconPath"
                          (click)="hyperLinkCall(row, column.columnDef)">
                          <span class="" [ngClass]="column.class">
                            {{getProperty(row, column.columnDef)}}
                          </span>
                        </a>
                        <a class="ml-2 text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                          *ngIf="column.isCustomClass" (click)="hyperLinkCall(row, column.columnDef)">
                          <!-- 'text-green-500': (getProperty(row, column.columnDef) > 0 && getProperty(row, column.columnDef) <= 40), -->
                          <span class="text-base font-bold"
                            [ngClass]="{
                            'tc-critical':  getProperty(row, column.columnDef) > 90,
                              'tc-low': (getProperty(row, column.columnDef) > 0 && getProperty(row, column.columnDef) <= 40),
                            'tc-medium': (getProperty(row, column.columnDef) > 40 && getProperty(row, column.columnDef) <= 70),
                              'tc-high': (getProperty(row, column.columnDef) > 70 && getProperty(row, column.columnDef) <= 90),
                            'tc-info': !getProperty(row, column.columnDef) || getProperty(row, column.columnDef) === 0,}">
                            {{getProperty(row, column.columnDef)}}
                          </span>
                        </a>
                      </div>
                    </span>
                    <ng-container *ngIf="column.isTicketsView">
                      <div class="w-[7rem]">
                        <div class="flex flex-row flex-wrap items-center gap-2 mt-2"
                          *ngIf="getProperty(row, column.isHyperlinkKey) && getProperty(row, column.isHyperlinkKey).length">
                          <span
                            *ngFor="let p of getProperty(row, column.isHyperlinkKey) | search: searchInput; let i = index;"
                            (click)="redirect(getProperty(row, column.isHyperlinkKey),p,i)" [class.hidden]="i >= 2"
                            class="cursor-pointer
                            inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg"
                            [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': p.status === 'Open',
                            'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': p.status === 'Closed'}">
                            <span *ngIf="p.ticket_number && p.ticket_id"
                              [matTooltip]="p.status">{{p.ticket_number}}</span>
                            <span *ngIf="p.ticket_id && !p.ticket_number" [matTooltip]="p.status">{{p.ticket_id}}</span>
                          </span>
                          <mat-icon color="primary" matTooltip="Show More" class="icon-size-4 cursor-pointer"
                            (click)="showTickets(row)"
                            *ngIf="getProperty(row, column.isHyperlinkKey) && getProperty(row, column.isHyperlinkKey).length > 2">more_horiz</mat-icon>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="column.isTicketCountView">
                      <span
                        (click)="getProperty(row, column.columnDef) > 0 ? showTickets(row, column.isOpenTicket): null"
                        class="label w-[3vw]"
                        [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': column.isOpenTicket,
                            'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': !column.isOpenTicket, 'cursor-pointer': getProperty(row, column.columnDef) > 0}">
                        <span [ngClass]="fontSizeClass">{{getProperty(row, column.columnDef)}}</span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.isPortView">
                      <div class="w-[6rem]">
                        <div class="flex flex-row flex-wrap items-center gap-2 mt-2"
                          *ngIf="getProperty(row, column.columnDef) && getProperty(row, column.columnDef).length">
                          <ng-container
                            *ngFor="let port of getProperty(row, column.columnDef) | search: searchInput; let i = index;">
                            <span [class.hidden]="i >= 2"
                              class="inline-flex text-xs items-center bg-yellow-200 text-black px-1 py-0.5 rounded"
                              *ngIf="port">
                              <span>{{port}}</span>
                            </span>
                          </ng-container>
                          <mat-icon color="primary" matTooltip="Show More" class="icon-size-4 cursor-pointer"
                            (click)="showPorts(row)"
                            *ngIf="getProperty(row, column.columnDef) && getProperty(row, column.columnDef).length > 2">more_horiz</mat-icon>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="column.isStatusView">
                      <span
                        *ngIf="getProperty(row, column.columnDef) != '-'  && getProperty(row, column.columnDef) !== null"
                        class="inline-flex items-center font-bold px-2.5 py-0.5 rounded-full tracking-wide" [ngClass]="{
                          'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': getProperty(row, column.columnDef).toLowerCase() === 'initiated',
                          'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': getProperty(row, column.columnDef).toLowerCase() === 'started',
                          'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': getProperty(row, column.columnDef).toLowerCase() === 'inprogress',
                          'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': getProperty(row, column.columnDef).toLowerCase() === 'in progress',
                          'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': getProperty(row, column.columnDef).toLowerCase() === 'completed'
                           || getProperty(row, column.columnDef).toLowerCase() === 'success',
                          'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': getProperty(row, column.columnDef).toLowerCase() === 'failed',
                          'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': getProperty(row, column.columnDef).toLowerCase() === 'terminated',
                      }">
                        <span class="leading-relaxed whitespace-nowrap">
                          {{status_list[getProperty(row, column.columnDef)]}}
                        </span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.isIconView">
                      <span
                        *ngIf="getProperty(row, column.columnDef) != '-'  && getProperty(row, column.columnDef) !== null">
                        <img class="vendor-logo {{column.class}}" [src]='getIconPath(row, column)'
                          [ngClass]="fontSizeClass" [matTooltip]='getIconTooltip(row, column)'
                          [matTooltipPosition]="'right'" [alt]='getIconTooltip(row, column)'>
                      </span>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="!column.filter && !column.isBaseScore && !column.isPortView && !column.isTicketsView && !column.isTicketCountView && !column.isHyperlink && !column.ishyperBold && !column.isSeverity &&
                    !column.isStatusView && !column.isIconView && !column.isEpssScore && !column.isVulnerability &&!column.isPatchable">
                    <span *ngIf="getProperty(row, column.columnDef)" [ngClass]="fontSizeClass">
                      <span>{{ getProperty(row, column.columnDef) }}</span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="column.isPatchable && !column.isGlobal && column.isHideGlobal">
                    <span *ngIf="getProperty(row, column.columnDef)"
                      class="blink text-green-700 font-extrabold text-xs animate-pulse">
                      PATCH
                    </span>
                  </ng-container>

                </td>
              </ng-container>
              <ng-container matColumnDef="action" *ngIf="tableOptions.showAction" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="wwbreak dark:bg-gray-700"
                  [ngClass]="tableOptions.showAction === true? 'color1' : 'color2' ">
                  <span [ngClass]="fontSizeClassHed">Action</span>
                  <mat-menu #gactionMenu="matMenu">
                    <ng-container *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                      <a id="ganch{{item.text}}" *ngIf=item.isGlobal mat-menu-item
                        (click)="globalActionCall(selection.selected, item)">
                        <mat-icon *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                        <mat-icon *ngIf="item.icon && item.icon.includes(':')" [svgIcon]="item.icon"></mat-icon>
                        <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                        <span [id]="item.text">{{ item.text }}</span>
                      </a>
                    </ng-container>
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let element; let i=index;" class="wwbreak bg-white z-5 dark:bg-[#1e293b]"
                  [ngClass]="tableOptions.showAction == true? 'color1' : 'color2' ">
                  <button type="button" id="btn{{tableOptions.id}}{{i}}" mat-icon-button color="primary"
                    [matMenuTriggerFor]="actionMenu" aria-label="row action with a menu"
                    [disabled]="selection.selected.length > 0">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionMenu="matMenu">
                    <ng-container *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                      <a id="anch{{item.text}}"
                        *ngIf="item.callback && !item.hideLocal && !item.allowedCondition && !item.actionConditions"
                        mat-menu-item (click)="actionCall(element, item)">
                        <mat-icon *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                        <mat-icon *ngIf="item.icon && item.icon.includes(':')" [svgIcon]="item.icon"></mat-icon>
                        <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                        <span [id]="item.text">{{ item.text }}</span>
                      </a>
                      <a id="anch{{item.text}}"
                        *ngIf="item.callback && !item.hideLocal && item.allowedCondition && item.allowedCondition.indexOf(getPropertyCondition(element, item.actionConditions.key)) !== -1"
                        mat-menu-item (click)="actionCall(element, item)">
                        <mat-icon *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                        <mat-icon *ngIf="item.icon && item.icon.includes(':')" [svgIcon]="item.icon"></mat-icon>
                        <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                        <span [id]="item.text">{{ item.text }}</span>
                      </a>
                      <a id="anch{{item.text}}"
                        *ngIf="item.callback && !item.hideLocal && item.isPatchable && item.allowedCondition && item.allowedCondition"
                        mat-menu-item (click)="actionCall(element, item)">
                        <mat-icon *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                        <mat-icon *ngIf="item.icon && item.icon.includes(':')" [svgIcon]="item.icon"></mat-icon>
                        <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                        <span [id]="item.text">{{ item.text }}</span>
                      </a>
                    </ng-container>
                  </mat-menu>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                [ngClass]="{'bg-blue-100 dark:bg-gray-500': tableOptions.rowClick && row.highlighted}"
                (click)="rowClick(row, i)"></tr>
            </table>
          </div>
          <div class="mt-2 py-[4%] w-full dark:bg-gray-700 items-center justify-center"
            *ngIf="!dataSource || !dataSource.data || !dataSource.data.length">
            <div *ngIf="tableOptions.loading || !sTableOptions.pageData"
              class="flex flex-col items-center justify-center gap-y-2">
              <img src="/assets/images/table/tl.gif" alt="" class="w-20">
              <div> Please wait!</div>
            </div>
            <div *ngIf="!tableOptions.loading && sTableOptions.pageData &&
                       !sTableOptions.pageData.length && tableOptions.pageTotal === 0 && !tableOptions.nomsg"
              class="flex flex-col items-center justify-center gap-y-2">
              <img src="/assets/images/table/empty.png" alt="" class="w-20">
              <div> No data available!</div>
            </div>
            <div
              *ngIf="!tableOptions.loading && sTableOptions.pageData && !sTableOptions.pageData.length && tableOptions.pageTotal === 0 && tableOptions.nomsg"
              class="flex flex-col items-center justify-center gap-y-2">
              <img src="/assets/images/table/empty.png" alt="" class="w-20">
              <div>{{tableOptions.nomsg}}</div>
            </div>
          </div>
          <mat-progress-bar mode="indeterminate"
            *ngIf="tableOptions.loading && dataSource &&  dataSource.data && dataSource.data.length > 25">
          </mat-progress-bar>
          <paginator-widget *ngIf="tableOptions.pagination && tableOptions.pageTotal"
            [pageOptions]="tableOptions.pageOptions" [pageSize]="tableOptions.pageSize"
            [pageTotal]="tableOptions.pageTotal" (pageChanged)="pageChanged($event)"></paginator-widget>
          <div class="flex justify-between items-center p-2 px-4 space-x-4 bg-white dark:bg-[#1e293b]"
            *ngIf="!tableOptions.pagination">
            <div class="flex flex-row pr-2 items-center gap-6">
              <div class="flex items-center space-x-2">
                <label for="items-page-number">Items per page:</label>
                <mat-form-field class="w-[7vw] mini-form-field">
                  <input id="items-page-number" type="number" name="items-page-number" matInput
                    [(ngModel)]="tableOptions.pageSize" (ngModelChange)="pageSizeUpdate.next($event)" min="1" max="100"
                    [matAutocomplete]="autoCom">
                </mat-form-field>
                <mat-autocomplete #autoCom="matAutocomplete">
                  <ng-container *ngFor="let page of tableOptions.pageOptions; let i = index; trackBy: trackByFn">
                    <mat-option [value]="page">{{page}}</mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>
              <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="tableOptions.pageSize"
                showFirstLastButtons hidePageSize aria-label="Select page of periodic elements"
                (page)="pageChangedManually($event)">
              </mat-paginator>
            </div>
            <div class="flex items-center space-x-2">
              <label for="page-number">Go to page:</label>
              <mat-form-field class="w-[4vw] mini-form-field">
                <input id="page-number" type="number" name="page-number" matInput [(ngModel)]="inputPageNumber"
                  (input)="onPageNumberInput($event)" (keypress)="validatePositiveNumber($event)" min="1"
                  [max]="maxPageNumber">
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-card>
</ng-container>

<s-modal id="ticketDetails">
  <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
    <div class="rounded-xl bg-card px-4 py-4 w-[600px]"
      *ngIf="ticket_details && ticket_details.ticket_status_map && ticket_details.ticket_status_map.length">
      <div class="flex flex-row items-center">
        <div class="text-xl font-semibold tracking-tight">{{ticket_details.product || port_details.name}}</div>
        <button class="ml-auto" mat-icon-button matTooltip="Close" (click)="modalService.close('ticketDetails');">
          <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
      </div>
      <mat-divider class="my-4"></mat-divider>
      <div class="flex flex-col h-[60vh]" fuseScrollbar>
        <ng-container *ngIf="ticket_details.open_ticket && ticket_details.open_ticket.length">
          <div class="text-md font-semibold">Open Tickets:</div>
          <div class="flex flex-row flex-wrap items-center gap-2 ml-6 my-2">
            <ng-container *ngFor="let p of ticket_details.open_ticket; let i = index; trackBy: trackByFn">
              <span (click)="redirect(ticket_details.open_ticket,p,i)"
                class="cursor-pointer inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50">
                <span *ngIf="p.ticket_number && p.ticket_id" [matTooltip]="p.status">{{p.ticket_number}}</span>
                <span *ngIf="p.ticket_id && !p.ticket_number" [matTooltip]="p.status">{{p.ticket_id}}</span>
              </span>
            </ng-container>
          </div>
          <mat-divider class="mt-2"></mat-divider>
        </ng-container>
        <ng-container *ngIf="ticket_details.closed_ticket && ticket_details.closed_ticket.length">
          <div class="text-md font-semibold">Closed Tickets:</div>
          <div class="flex flex-row flex-wrap items-center gap-2 ml-6 my-2">
            <ng-container *ngFor="let p of ticket_details.closed_ticket; let i = index; trackBy: trackByFn">
              <span (click)="redirect(ticket_details.closed_ticket,p,i)"
                class="cursor-pointer inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50">
                <span *ngIf="p.ticket_number && p.ticket_id" [matTooltip]="p.status">{{p.ticket_number}}</span>
                <span *ngIf="p.ticket_id && !p.ticket_number" [matTooltip]="p.status">{{p.ticket_id}}</span>
              </span>
            </ng-container>
          </div>
          <mat-divider class="mt-2"></mat-divider>
        </ng-container>
        <!-- <div class="flex items-center justify-end mb-2 pt-4 bg-transparent">
          <button class="ml-auto" mat-button [matTooltip]="'Close'" (click)="modalService.close('ticketDetails');">
            Close
          </button>
        </div> -->
      </div>
    </div>
  </div>
</s-modal>

<s-modal id="portsDetails">
  <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
    <div class="rounded-xl bg-card px-4 py-4 w-[600px]"
      *ngIf="port_details && port_details.ports && port_details.ports.length">
      <div class="flex flex-row items-center">
        <div class="text-xl font-semibold tracking-tight">{{port_details.product || port_details.name}}</div>
        <button class="ml-auto" mat-icon-button matTooltip="Close" (click)="modalService.close('portsDetails');">
          <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
      </div>
      <mat-divider class="my-4"></mat-divider>
      <div class="flex flex-col h-[60vh]" fuseScrollbar>
        <ng-container *ngIf="port_details.ports && port_details.ports.length">
          <div class="text-md font-semibold">Ports:</div>
          <div class="flex flex-row flex-wrap items-center gap-2 ml-6 my-2">
            <ng-container *ngFor="let ports of port_details.ports; let i = index; trackBy: trackByFn">
              <span *ngIf="ports" class="inline-flex text-xs items-center bg-yellow-200 text-black px-1 py-0.5 rounded">
                <span>{{ports}}</span>
              </span>
            </ng-container>
          </div>
          <mat-divider class="mt-2"></mat-divider>
        </ng-container>
        <!-- <div class="flex items-center justify-end mb-2 pt-4 bg-transparent">
          <button class="ml-auto" mat-button [matTooltip]="'Close'" (click)="modalService.close('portsDetails');">
            Close
          </button>
        </div> -->
      </div>
    </div>
  </div>
</s-modal>