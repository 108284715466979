import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit, Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    CommonModule,
    AsyncPipe,
    DatePipe,
    NgFor,
    NgIf,
    TitleCasePipe,
    DOCUMENT, NgOptimizedImage
} from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { Router, RouterLink } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { DateTime } from 'luxon';
import { CommonService } from '../../../_services/common.service';
import { BaseRequestService } from '../../../_services/base.service';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { LoaderService } from 'app/_services/loader.service';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { SkeletonComponent } from "../skeleton/skeleton.component";
import { FuseAlertComponent } from "../../../../@fuse/components/alert/alert.component";

const timeline = {
    asset_id: '',
    id: '',
    icon: '',
    img: '',
    image: '',
    description: '',
    action: '',
    conditionImage: false,
    date: '',
    extraContent: '',
    linkedContent: '',
    link: '',
    useRouter: '',
};
const propData = {
    title: '',
    description: '',
    timelines: [],
};
@Component({
    selector: 'timeline',
    standalone: true,
    templateUrl: './timeline.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        NgFor,
        MaterialModule,
        RouterLink,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AsyncPipe,
        TitleCasePipe,
        DatePipe,
        FuseScrollbarDirective,
        NgOptimizedImage,
        DirectivesModule,
        AppFilterPipeModule,
        SkeletonComponent,
        FuseAlertComponent
    ]
})
export class TimelineComponent implements OnInit, OnDestroy {
    @Input() inputData: any = [];
    @Input() dataMapping: any = [];
    @Input() props: any = {};
    today: Date = new Date();
    @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();
    showTimeline = new BehaviorSubject<any>(null);
    _timelineData: any;
    timelineData$ = this.showTimeline.asObservable();
    showData = false;
    fromDate: any = null;
    toDate: any = null;
    tline: Subscription;
    site: Subscription;
    sort: Subscription;
    getD: Subscription;
    expandedDescriptions: boolean[] = [];
    parentObj: any;
    currentStep = 0;
    totalSteps = 10;
    actionHash: any = {
        add: 'added',
        update: 'updated',
        delete: 'deleted',
        new: 'added',
        added: 'added',
        updated: 'updated',
        deleted: 'deleted'
    }
    colours: any = [
        "bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300",
        "bg-gray-100 text-gray-900 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300",
        "bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300",
        "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300",
        "bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300",
        "bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300",
        "bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300"
    ];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        public cs: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _bs: BaseRequestService, private router: Router,
        private loaderService: LoaderService
    ) {
        this.site = this.cs.selectedSiteChanged.subscribe((res) => {
            this.getData();
        });
        this.tline = this.timelineData$.subscribe((res) => {
            console.log(res);
        })
    }

    async routeUrl(id: any): Promise<any> {
        this.closeDrawer.next(true);
        const router = this.router.url.split('/');
        if (router.indexOf('asset') !== -1 && router.indexOf('assets') !== -1) {
            this.router.navigate([`/asset/assets/view/${id}`]);
            this.cs.assetSelect.emit(id);
        } else {
            this.router.navigate([`/asset/assets/view/${id}`]);
        }
        /*
        const active_asset = await this._bs.doRequest(`/r/report_queries/asset_view`, 'get', null, { condition: `id='${id}'` }).toPromise();
        const duprecated_asset = await this._bs.doRequest(`/r/asset/deprecated_assets`, 'get', null, { condition: `id='${id}'` }).toPromise();
        if (duprecated_asset.status && duprecated_asset.data && duprecated_asset.data.length) {
            const asset = duprecated_asset.data[0];
            if (router.indexOf('asset') !== -1 && router.indexOf('assets') !== -1) {
                this.router.navigate([`/asset/assets/view/${id}`]);
                this.cs.assetSelect.emit(id);
            } else {
                this.router.navigate([`/asset/assets/view/${id}`]);
            }
        } else if (active_asset.status && active_asset.data && active_asset.data.length) {
            const asset = active_asset.data[0];
            if (asset.is_firewall) {
                if (router.indexOf('asset') !== -1 && router.indexOf('assets') !== -1) {
                    this.router.navigate([`/asset/assets/view/${id}`]);
                    this.cs.assetSelect.emit(id);
                } else {
                    this.router.navigate([`/asset/assets/view/${id}`]);
                }
            } else {
                if (router.indexOf('asset') !== -1 && router.indexOf('assets') !== -1) {
                    this.router.navigate([`/asset/assets/view/${id}`]);
                    this.cs.assetSelect.emit(id);
                } else {
                    this.router.navigate([`/asset/assets/view/${id}`]);
                }
            }
        }
        */
    }

    isSameDay(current: string, compare: string): boolean {
        return DateTime.fromISO(current).hasSame(
            DateTime.fromISO(compare),
            'day'
        );
    }
    getRelativeFormat(date: string): string {
        return DateTime.fromISO(date).toRelativeCalendar();
    }
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    ngOnInit() {
        if (this.props.isServerSide) {
            this.getData();
        } else {
            this.processTimelineData();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.site.unsubscribe();
        this.getD.unsubscribe();
        this.tline.unsubscribe();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
    formatDate(date: any) {
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let day = String(date.getDate()).padStart(2, '0');
        const formattedDateString = `${year}-${month}-${day}`;
        const parsedDate = new Date(formattedDateString);
        const formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    }

    resetDate() {
        this.fromDate = null;
        this.toDate = null;
        this.getData()
    }
    
    getData(): void {
        this.loaderService.display(true);
        this._timelineData = [];
        let datavalue;
        let skip = this.currentStep * 20;
    
        const utcStartDate = this.fromDate ? this.formatDateToUTC(this.fromDate) : null;
        const utcEndDate = this.toDate ? this.formatDateToUTC(this.toDate) : null;
    
        if (utcStartDate && utcEndDate) {
            datavalue = `created::date BETWEEN '${utcStartDate}' AND '${utcEndDate}'`;
        } else if (utcStartDate) {
            datavalue = `created::date >= '${utcStartDate}'`;
        } else if (utcEndDate) {
            datavalue = `created::date <= '${utcEndDate}'`;
        }
        
        let condition: any =
            this.cs.currentScope === '*'
                ? { condition: (datavalue) ? datavalue : true, skip, limit: 20 }
                : { condition: (datavalue) ? `${datavalue} and company_id=${this.cs.currentScope.id}` : `company_id=${this.cs.currentScope.id}`, skip, limit: 20 };
        if (this.props.path && this.cs.currentScope === '*') {
            condition.condition = this.props.path.condition;
        } else if (this.props.path) {
            condition.condition += ' and ' + this.props.path.condition;
        }
        if (this.props.orderBy && this.props.orderBy.columnName && this.props.orderBy.direction) {
            condition.order_by = `${this.props.orderBy.columnName} ${this.props.orderBy.direction}`
        }
        this.getD = this._bs
            .doRequest(this.props.tablename, 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    if (result.total === 0) { this.currentStep = 0 };
                    this.totalSteps = Math.ceil(result.total / 20);
                    this.props.timelineCount = result.total;
                    result.data.forEach((obj: any) => {
                        if (obj.tickets && obj.tickets.length) {
                            obj.tickets = obj.tickets.filter((x: any) => x.ticket_id)
                            obj.tickets_details = this.removeDuplicate(obj.tickets);
                        }
                    });
                    try {
                        this.inputData = result;
                        this.processTimelineData();
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
    }
    
    formatDateToUTC(date: Date): string {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().slice(0, 10);
    }

    processTimelineData(): void {
        if (this.dataMapping) {
            this.dataMapping.forEach((field: any) => {
                if (field.isStatic) {
                    this.props[field.field] = field.value;
                } else if (field.isArray) {
                    if (
                        field.field === 'columns' &&
                        this.inputData[field.mapTo]
                    ) {
                        this.inputData[field.mapTo].forEach(
                            (m: any, index: any) => {
                                let obj = {};
                                field.objectMapping.forEach((f: any) => {
                                    let val =
                                        f.destKey.indexOf('.') > -1
                                            ? this.cs.getNestedProperty(
                                                m,
                                                f.destKey
                                            )
                                            : m[f.destKey];
                                    if (f.type === 'icon') {
                                        val = f.valueMap[val]
                                            ? f.valueMap[val]
                                            : f.valueMap['_default_'];
                                    } else if (f.type === 'img') {
                                        val = f.valueMap[val]
                                            ? f.valueMap[val]
                                            : f.valueMap['_default_'];
                                    } else if (f.type === 'link') {
                                        val = `${f.prefix}${val}${f.suffix}`;
                                    }
                                    obj[f.sourceKey] = val;
                                });
                                this._timelineData.push(obj);
                            }
                        );
                    }
                }
            });
            this.cs.sortFn(this._timelineData, 'date', 1).then((val: any) => {
                this._timelineData = val;
                this.showTimeline.next(this._timelineData.slice());
                this.props.timelineData = this._timelineData.slice();
                this.props.description = (this.props.timelineCount) ? '' : `Total ${this.props.timelineCount}.`;
                this.showData = true; this._changeDetectorRef.detectChanges();
            });
        }
    }

    isTruncated(description: string): boolean {
        const lines = description.split('\n');
        return lines.length > 4;
      }
      
    
      // Function to toggle between show more and show less
      toggleDescription(index: number): void {
        this.expandedDescriptions[index] = !this.expandedDescriptions[index];
      }
    
      // Function to return the truncated version of the descriptio

    getTruncatedDescription(description: string): string {
        const lines = description.split('\n');
        if (lines.length > 4) {
          return lines.slice(0, 4).join('\n') + '...';
        }
        return description;
      }

      

    /**
     * Go to previous step
     */
    goToPreviousStep(): void {
        // Return if we already on the first step
        if (this.currentStep === 0) {
            return;
        }

        // Go to step
        this.goToStep(this.currentStep - 1);

    }

    /**
     * Go to next step
     */
    goToNextStep(): void {
        // Return if we already on the last step
        if (this.currentStep === this.totalSteps - 1) {
            return;
        }
        
        // Go to step
        this.goToStep(this.currentStep + 1);
    }

    goToStep(step: number): void {
        // Set the current step
        this.currentStep = step;
        // Go to the step
        // this.courseSteps.selectedIndex = this.currentStep;
        this.getData();
        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

   

    getColorClass(index: number): string {
        const colorIndex = index % this.colours.length;
        return this.colours[colorIndex];
    }

    removeDuplicate(data: any) {
        if (!data || !data.length) {
            return data
        }
        data.map((x: any) => {
            if (x.ticket_url) {
                const url = (x.ticket_url && (x.ticket_url.startsWith('http://') || x.ticket_url.startsWith('https://'))) ? x.ticket_url : `https://${x.ticket_url}`;
                x.ticket_url = url.replace('${ticket_id}', (x.ticket_id) ? x.ticket_id.trim() : x.ticket_number.trim()).replace(/\\\"/g, '"');
            } else {
                x.ticket_url = null;
            }
        });
        return Object.values(data.reduce((acc: any, item: any) => (acc[item.ticket_id] = item, acc), {}));
    }

    redirect(item: any): void {
        const ticket_id = (item.ticket_id) ? item.ticket_id : item.ticket_number;
        const ticket = [item];
        if (ticket[0] && ticket[0].ticket_url) {
            let url = (ticket[0] && ticket[0].ticket_url && (ticket[0].ticket_url.startsWith('http://') || ticket[0].ticket_url.startsWith('https://'))) ? ticket[0].ticket_url : `https://${ticket[0].ticket_url}`;
            url = url.replace('${ticket_id}', ticket_id.trim()).replace(/\\\"/g, '"');
            window.open(url, '_blank');
        } else if (item.credential_id && ticket_id) {
            const params = {condition: `id=${item.credential_id}`};
            this._bs.doRequest('/r/company/integration_credentials', 'get', null, params)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                    if (res.status && res.data && res.data.length) {
                        const ticket = res.data;
                        let url = (ticket[0] && ticket[0].ticket_url && (ticket[0].ticket_url.startsWith('http://') || ticket[0].ticket_url.startsWith('https://'))) ? ticket[0].ticket_url : `https://${ticket[0].ticket_url}`;
                        url = url.replace('${ticket_id}', ticket_id.trim()).replace(/\\\"/g, '"');
                        window.open(url, '_blank');
                    }
                });
        }
    }
}
