import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from "../table/table.component";
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from 'app/_services/loader.service';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ModalService } from 'app/_services/modal.service';
import { ModalComponent } from '../modal.component';

@Component({
    selector: 'asset-compliance',
    standalone: true,
    imports: [CommonModule, ModalComponent, TableComponent, AppFilterPipeModule, MaterialModule, MatButtonToggleModule, FormsModule, ReactiveFormsModule,
    ],
    templateUrl: './asset-compliance.component.html',
    styleUrls: ['./asset-compliance.component.scss']
})
export class AssetComplianceComponent implements OnInit, OnDestroy {
    @Input() compliance_type: any;
    @Input() asset_id: any;
    @Input() maturity: any;
    @Input() platform: any;
    assetCompTableOptions: any = {
        columns: [
            {
                "header": "Compliance ID",
                "columnDef": "benchmark",
                "filter": "",
                "isHyperlink": true,
                "cell": "(element: any) => `${element.benchmark}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Section",
                "columnDef": "section",
                "filter": "",
                "isHyperlink": false,
                "cell": "(element: any) => `${element.section}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                "header": "GPO Path",
                "columnDef": "gpo_path",
                "filter": "",
                "isHyperlink": false,
                "cell": "(element: any) => `${element.gpo_path}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false,
                "isShowMoreData": true
            },
            {
                "header": "Command",
                "columnDef": "command",
                "filter": "",
                "isHyperlink": false,
                "cell": "(element: any) => `${element.command}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isHtml": true,
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false,
                "isShowMoreData": true
            },
            {
                "header": "Maturity",
                "columnDef": "maturity",
                "filter": "",
                "isHyperlink": false,
                "cell": "(element: any) => `${element.maturity}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }
        ],
        sortOptions: { active: 'compliance_id', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Compliance',
            isServerSide: false,
            selectText: 'compliance',
            loading: false,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/report_queries/compliance_details_by_asset',
                condition: '',
                type: 'post',
                params: {}
            },
        },
        changeValue: new Subject<any>(),
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    showTable: boolean = false;
    complianceList: any = [];
    selectedCompliance: any = '';
    complianceCount: any = {};
    currentStatus: any = '';
    SCompliance: any = {};
    complianceCheckMaster: any = [];
    Objectkeys = Object.keys;
    compliance_status: any = {
        compliant_count: "Compliant",
        manual_compliant_count: "ManualCompliant",
        manual_non_compliant_count: "ManualNonCompliant",
        non_compliant_count: "NonCompliant",
    }
    constructor(private _bs: BaseRequestService, private _cs: CommonService, private ls: LoaderService,
        private toast: MyToastrService, private _cdr: ChangeDetectorRef, public _ms: ModalService) {
    }
    ngOnInit() {
        this.getTypes();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getTypes(): void {
        this.ls.display(true);
        this.showTable = false;
        this._bs.doRequest(`/r/compliance/types`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.complianceList = res.data;
                    this.selectedCompliance = (this.selectedCompliance) ? this.selectedCompliance : this.complianceList[0].table_name;
                    this.initCategory();
                    this._cdr.detectChanges();
                } else {
                    this.toast.sToast('error', 'No compliance configured!')
                }
            });
    }

    initCategory(compliance?: any): void {
        this.ls.display(true);
        if (compliance) {
            this.selectedCompliance = compliance;
        }
        const params: any = {
            condition: `asset_id = ${this.asset_id}`,
            compliance_type: this.selectedCompliance
        }
        this._bs.doRequest(`/r/report_queries/compliance_count`, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.complianceCount = {};
                    if (res.data && res.data.length) {
                        this.complianceCount = res.data[0];
                        //this.currentStatus = this.Objectkeys(res.data[0])[0];
                        this.currentStatus = 'total_compliant_count';
                        this.getCompliance({ value: this.currentStatus });
                        this._cdr.detectChanges();
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    handleChangeType(item: any): void {
        this.initCategory(item);
    }

    getCompliance(item: any): void {
        this.currentStatus = item.value;
        this.showTable = false;
        let data = Object.assign({}, this.assetCompTableOptions);
        this.assetCompTableOptions = {}; this._cdr.detectChanges();
        data.pageData = []; data.tableOptions.pageTotal = 0;
        data.tableOptions.serverSide.params = {
            compliance_type: this.selectedCompliance
        }
        if (item.value !== 'total_compliant_count') {
            data.tableOptions.serverSide.condition = `asset_id = ${this.asset_id} and compliance_status = '${this.compliance_status[item.value]}'`;
        } else {
            data.tableOptions.serverSide.condition = `asset_id = ${this.asset_id}`
        }
        this.assetCompTableOptions = data; this.showTable = true; this._cdr.detectChanges();
    }

    linkCall($event: any): void {
        if ($event.col === 'benchmark') {
            this.SCompliance = $event.row;
            this.checkComplianceMaster($event.row);
        }
    }
    checkComplianceMaster($event: any): void {
        this.ls.display(true);
        let conditionS = `(compliance_id LIKE '${$event.benchmark},%' OR compliance_id LIKE '%,${$event.benchmark}' OR compliance_id LIKE '%,${$event.benchmark},%' OR compliance_id = '${$event.benchmark}') and description='${$event.description}'`;
        this._bs.doRequest(`/r/compliance/compliance_master`, 'get', null,
            { condition: conditionS })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.ls.display(false);
                if (result.status) {
                    if (result.data && result.data.length) {
                        if (result.data[0].command) {
                            result.data[0].command = result.data[0].command.replace(/\n/g, '<br>')
                        }
                        this.complianceCheckMaster = result.data;
                    } else {
                        this.complianceCheckMaster = [];
                    }
                    this._ms.open('assetComplianceChecks');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            })
    }
}
