<div class="flex flex-col w-full min-w-0 sm:inset-0 sm:overflow-hidden" [@fadeInLeft]="" *ngIf="!cIntegration">
    <div class="w-auto rounded-2xl dark:bg-default mb-2">
        <div class="flex flex-col flex-auto min-w-0">
            <div class="flex-auto -mt-px pt-1 sm:pt-2">
                <div class="w-full  mx-auto">
                    <div class="flex flex-wrap -m-2 mt-2" *ngIf="integrations && integrations.length">
                        <ng-container
                            *ngFor="let product of integrations; trackBy:trackByFn">
                            <div class="relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card">
                                <a class="z-10 absolute inset-0 flex flex-col p-4 cursor-pointer" href="javascript:"
                                    (click)="setCIntegration(product)">
                                    <div class="aspect-[9/6]">
                                        <div class="flex items-center justify-center h-full">
                                            <img alt="{{product.display}}" class="w-20 h-20 object-cover"
                                                src="/assets/images/providers/provider-{{ product.image }}.png">
                                        </div>
                                    </div>
                                    <div class="flex flex-col mt-1 justify-center text-center text-sm font-medium">
                                        <div class="truncate text-base" [matTooltip]="product.display">
                                            {{product.display}}</div>
                                        <ng-container *ngIf="product.contents">
                                            <div class="text-secondary truncate">
                                                {{product.contents}}</div>
                                        </ng-container>
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                    <app-skeleton *ngIf="!integrations || !integrations.length"></app-skeleton>
                    <mat-divider class="my-3"></mat-divider>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="cIntegration" [@fadeInRight] fusescrollbar id="integrationOverview" #integrationOverview>
    <div class="flex flex-col w-full min-w-0">
        <div class="flex flex-row items-center">
            <div class="text-2xl font-semibold tracking-tight">{{cIntegration.display}}</div>
            <button class="ml-auto" mat-icon-button (click)="cIntegration= undefined;">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <mat-divider class="my-4"></mat-divider>
        <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndex = $event" class=""
            mat-stretch-tabs="false" [animationDuration]="'0'">
            <mat-tab label="Credentials">
                <ng-template mat-tab-label>
                    <mat-icon class="mat-24 mr-2">vpn_key</mat-icon>
                    <span id="intCredentials"> Credentials </span>
                </ng-template>
                <ng-template matTabContent>
                    <import-integration-credentials [currentIntegration]="cIntegration"
                        (updateCredFn)="updateCredFn($event)"></import-integration-credentials>
                </ng-template>
            </mat-tab>
            <mat-tab label="Company Mapping" *ngIf="integrationCred && integrationCred.length">
                <ng-template mat-tab-label>
                    <mat-icon class="mat-24 mr-2">maps_home_work</mat-icon>
                    <span id="companyMapping"> Company Mapping </span>
                </ng-template>
                <ng-template matTabContent>
                    <ng-container>
                        <import-company-mapping [currentIntegration]="cIntegration"
                            [integrationCred]="integrationCred"></import-company-mapping>
                    </ng-container>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
