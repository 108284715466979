<div class="flex flex-row w-full min-w-0" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <div *ngIf="(!userShares || !userShares.length)">
        <app-no-data [message]="'No record found'" [color]="'black'"></app-no-data>
    </div>
    <div class="w-auto rounded-2xl" fuseScrollbar *ngIf="userShares">
        <div class="flex flex-row items-start" fuseScrollbar>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ml-4 mt-4 h-[94vh] w-[23vw]">
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead class="text-medium font-medium border-b tracking-tight leading-6  bg-card">
                        <tr>
                            <th scope="col" class="pl-4 cursor-pointer py-2" (click)="sortFn('name')">
                                Share Name
                                <dynamic-sort [sortReverse]="sortReverse" *ngIf="sortType === 'name'"></dynamic-sort>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let shareName of objectKeys(shareHash); let last = last" class=""
                            [ngClass]="{'bg-primary text-white': shareName === share, 'border-b': !last}">
                            <th scope="row" class="py-1 pl-4">
                                <div class="text-sm cursor-pointer" (click)="setCurrentShare(shareName)"
                                    [matTooltip]="shareName">{{shareName|ellipsis: 32}}</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-divider></mat-divider>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ml-4 mt-4 h-[94vh] w-[60vw]">
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead class="text-medium font-medium border-b tracking-tight leading-6  bg-card">
                        <tr>
                            <th scope="col" class="pl-4 py-2">
                                Access Control Type
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                File System Rights
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Groups/Users
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let share of currentShare let last = last" class="border-b">
                            <th scope="row" class="py-1 pl-4">
                                <span
                                    *ngIf="share.access_control_type && share.access_control_type.toLowerCase() === 'allow'"
                                    class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase
                                    bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50 ng-star-inserted"><span
                                        class="leading-relaxed whitespace-nowrap"> Allow </span>
                                </span>
                                <span
                                    *ngIf="share.access_control_type && share.access_control_type.toLowerCase() !== 'allow'"
                                    class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase
                                    bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50 ng-star-inserted"><span
                                        class="leading-relaxed whitespace-nowrap"> {{share.access_control_type}}
                                    </span>
                                </span>
                            </th>
                            <th scope="row" class="py-1 pl-4">
                                {{share.file_system_rights}}
                            </th>
                            <th scope="row" class="py-1 pl-4">
                                {{share.identity_reference}}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<app-loader [loadingCon]="loadingCon" *ngIf="!userShares"></app-loader>