<div class="py-2" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <div class="rounded ring-1  py-2 px-2 my-3">
        <i class="fas fa-key m-2"></i><span class="font-light text-xl">Ciphers</span>
        <div class="rounded p-2" *ngFor="let cip of ciphers;let i=index">
            <div class="flex flex-row items-center gap-2 py-2">
                <span class="font-bold text-lg">Port</span>
                <span class="bg-blue-600 text-white rounded-full px-2 py-1">
                    <i class="fas fa-ethernet text-white pr-2"></i> {{cip.port}} </span>
            </div>
            <div class="flex flex-row items-center gap-2 mt-1">
                <span class="font-light text-lg">Version</span>
                <span class="font-bold text-lg"> {{cip.version}} </span>
            </div>
            <table class="table-auto mb-4" *ngIf="cip.ciphers.length >0">
                <tbody class="text-sm font-light">
                    <div class="flex flex-row items-end justify-end mb-3">
                        <span class="bg-blue-600 text-white rounded px-2 py-1" *ngIf="cip.showTable === false">
                            <button type="button" name="showButton{{i}}" (click)="cip.showTable =true">Show
                                Data</button>
                        </span>
                        <span class="bg-blue-600 text-white rounded px-2 py-1" *ngIf="cip.showTable === true">
                            <button type="button" name="hideButton{{i}}" (click)="cip.showTable = false">Hide
                                Data</button>
                        </span>
                    </div>
                    <tr class="border-b">
                        <td colspan="2" class="py-2 text-left font-light w-full" *ngIf="cip.showTable ===true">
                            <table class="table-auto rounded ring-1 p-2 m-2">
                                <thead class="shadow">
                                    <tr class="bg-primary text-white">
                                        <th class="py-2 px-2">Name</th>
                                        <th class="py-2 px-2">Grade</th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm font-light ">
                                    <tr class="border-b" *ngFor="let cipe of cip.ciphers; let i = index;">
                                        <td class="py-1 px-4 text-left break-all">{{cipe[0]}}</td>
                                        <td class="py-1 px-4 text-left break-all">
                                            <div *ngIf="cipe[1]" matTooltip="Grade"
                                                class="max-w-xs flex relative right-1 items-center justify-center h-6 w-6 rounded-lg p-1 m-1"
                                                [ngClass]="{ 'bg-yellow-600 text-black': cipe[1] === '-' || cipe[1] === 'B' || cipe[1] === 'C',
                                        'bg-green-500': cipe[1] === 'A',
                                        'bg-orange-600 text-black': cipe[1] === 'D',
                                        'bg-red-600': cipe[1] === 'E' || cipe[1] === 'F'}">
                                                {{cipe[1]}}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<ng-container *ngIf="showNoData && ciphers && !ciphers.length">
    <app-no-data [message]="'No data available!'" [color]="'black'"></app-no-data>
</ng-container>