// scroll.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private isScrollDisabled = false;

  disableScroll() {
    this.isScrollDisabled = true;
    document.body.style.overflow = 'hidden';
  }

  enableScroll() {
    this.isScrollDisabled = false;
    document.body.style.overflow = 'auto';
  }

  isScrollingDisabled(): boolean {
    return this.isScrollDisabled;
  }
}
