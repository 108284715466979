<div class="flex flex-row my-2 items-center absolute w-auto justify-end top-[-.4rem] right-[1.5rem] z-10">
    <button mat-stroked-button class="flex ml-1 mr-2 mini-form-field" type="button" (click)="cView = 'allview'"
        *ngIf="cView === 'asset'">
        <mat-icon color="primary">arrow_back</mat-icon> &nbsp; Back
    </button>
</div>
<div class="mt-2">
    <ng-container *ngIf="cView === 'allview'">
        <stable aria-label="Suppresion Table" role="table" [loadtable]="loadtable" *ngIf="suppression.tableOptions" (globalActionCallback)="globalCall($event)"
            [sTableOptions]="suppression" (hyperlinkCallback)="linkCall($event)" (actionCallback)="suppressionActionCall($event)" ></stable>
    </ng-container>
    <ng-container *ngIf="cView === 'asset'">
        <stable [sTableOptions]="asseTableOptions"
            *ngIf="asseTableOptions.tableOptions"></stable>
    </ng-container>
    <div *ngIf="cAsset && (cAsset.host_name || cAsset.ip) && cView === 'asset-details'">
        <asset-details [cAsset]="cAsset" (toggleFn)="toggleChange($event)"></asset-details>
    </div>
</div>