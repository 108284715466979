<div class="flex flex-wrap pt-4 gap-2 items-center justify-start">
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'docxCustom';getReportSetting();" id="docxCustom" [ngClass]="{'bg-primary text-white': cView === 'docxCustom'}">
        DOCX Customization
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'xlsxCustom';getReportSetting();" id="xlsxCustom" [ngClass]="{'bg-primary text-white': cView === 'xlsxCustom'}">
        XLSX Customization
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer" *ngIf="cs.currentScope === '*'"
        (click)="cView = 'custom-report-cover';" id="customreportcover" [ngClass]="{'bg-primary text-white': cView === 'custom-report-cover'}">
        Custom Cover Page for Docx format
    </div>
</div>
<div class="border-t border-blue-400 my-4"></div>
<div class="flex flex-col w-full" *ngIf="cView === 'docxCustom'">
    <div class="px-6 mt-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Adjust header and footer image with text and alignment</div>
                <div>The maximum header/footer file format size is limited to 524kB.</div>
                <div>The file formats supported here are jpeg, jpg, png.</div>
                <div >If no Company Level Customization Settings are added, it will consider the settings from Global Customization Settings. However, if any company-level customization is added, it will prioritize the preferences set at the company level.
                    <a *ngIf="cs.currentScope !== '*'" class="ml-1 text-primary-500 hover:underline" href="javascript:" (click)="navGlobalsettings()">(View Global Settings)</a>
                </div>
            </div>
        </fuse-alert>
    </div>
    <form [formGroup]="reportSettingsForm">
        <ng-container>
            <div class="relative flex flex-col flex-auto items-center p-6">
                <div class="w-full">
                    <app-dynamic-form *ngIf="!isLoading" [listOfFormElements]="repSettings" [spanLayout]="2"
                        [Valuesoutput]="repParams" [isSaveBtn]="false" [isCloseBtn]="false">
                    </app-dynamic-form>
                    <div class="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 w-full">
                        <div class="">
                            <div class="py-4 font-semibold">Header Image</div>
                            <div
                                class="relative flex items-center justify-center h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container>
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20">
                                        <div>
                                            <input id="header"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'header')">
                                            <label
                                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                for="header" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button mat-icon-button (click)="removeImage('resetHeader')">
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.header" alt="">
                            </div>
                        </div>
                        <div class="">
                            <div class="py-4 font-semibold">Footer Image</div>
                            <div
                                class="relative flex items-center justify-center h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container>
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20">
                                        <div>
                                            <input id="footer"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'footer')">
                                            <label
                                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                for="footer" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button mat-icon-button (click)="removeImage('resetFooter')">
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.footer" alt="">
                            </div>
                        </div>
                        <div class="">
                            <div class="py-4 font-semibold">Company Logo</div>
                            <div
                                class="relative flex items-center justify-center h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container>
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20">
                                        <div>
                                            <input id="company_logo"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'company_logo')">
                                            <label
                                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                for="company_logo" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button mat-icon-button (click)="removeImage('resetCompanyLogo')">
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.company_logo" alt="">
                            </div>
                        </div>
                    </div>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="font-semibold text-md my-4">Cover Image</div>
                    <div class="grid grid-cols-4 gap-2">
                        <ng-container *ngFor="let item of Objectkeys(defaultCoverPage); let i = index;">
                            <div class="flex flex-col">
                                <div class="relative flex items-center justify-center h-40 rounded-xl overflow-hidden cursor-pointer"
                                    (click)="(repParams.cover_page === item ) ? repParams.cover_page = null : repParams.cover_page = item"
                                    [ngClass]="{'ring-offset-2 ring-4 ring-primary': repParams.cover_page === item, 'ring-1': repParams.cover_page !== item}">
                                    <img class="object-cover p-2 w-[200px] h-40" [src]="defaultCoverPage[item]" alt="">
                                </div>
                                <div class="text-md my-1 font-md text-center">Cover Page {{i+1}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end mt-4 py-4 pr-4 pl-1 border-t bg-gray-50 dark:bg-transparent">
                <ng-container>
                    <button class="ml-2" mat-flat-button [color]="'primary'" [matTooltip]="'Save'"
                        (click)="saveImage()">
                        Save
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </form>
</div>
<div class="flex flex-col w-full " *ngIf="cView === 'xlsxCustom'">
    <div class="px-6 mt-2">
        <div class="mb-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div class="flex flex-col gap-2">
                    <div>If no Company Level Customization Settings are added, it will consider the settings from Global Customization Settings. However, if any company-level customization is added, it will prioritize the preferences set at the company level.
                        <a *ngIf="cs.currentScope !== '*'" class="ml-1 text-primary-500 hover:underline" href="javascript:" (click)="navGlobalsettings()">(View Global Settings)</a>
                    </div> 
                </div>
            </fuse-alert>
        </div>
        <ng-container>
            <div class="grid grid-cols-2 gap-4 px-4 py-4 bg-card rounded-2xl shadow">
                <mat-form-field class="mr-4 mb-2">
                    <mat-label>XLSX Header Text Color</mat-label>
                    <input matInput type="color" name="xlsx_header_text_colour"
                        [(ngModel)]="xlsxReportCustomization.xlsx_header_text_colour">
                </mat-form-field>
                <mat-form-field class="mr-4 mb-2">
                    <mat-label>XLSX Header Text Size</mat-label>
                    <input matInput type="number" name="xlsx_header_text_size"
                        [(ngModel)]="xlsxReportCustomization.xlsx_header_text_size">
                </mat-form-field>
                <mat-form-field class="mr-4 mb-2">
                    <mat-label>XLSX Header Cell Color</mat-label>
                    <input matInput type="color" name="xlsx_header_cell_colour"
                        [(ngModel)]="xlsxReportCustomization.xlsx_header_cell_colour">
                </mat-form-field>
                <mat-form-field class="mr-4 mb-2">
                    <mat-label>XLSX Data Text Color</mat-label>
                    <input matInput type="color" name="xlsx_data_text_colour"
                        [(ngModel)]="xlsxReportCustomization.xlsx_data_text_colour">
                </mat-form-field>
                <mat-form-field class="mr-4 mb-2">
                    <mat-label>XLSX Data Cell Color</mat-label>
                    <input matInput type="color" name="xlsx_data_cell_colour"
                        [(ngModel)]="xlsxReportCustomization.xlsx_data_cell_colour">
                </mat-form-field>
                <mat-form-field class="mr-4 mb-2">
                    <mat-label>XLSX Data Text Size</mat-label>
                    <input matInput type="number" name="xlsx_data_text_size"
                        [(ngModel)]="xlsxReportCustomization.xlsx_data_text_size">
                </mat-form-field>
                <mat-slide-toggle class="mr-4 mb-2" name="xlsx_header_text_bold" [color]="'primary'" 
                    [(ngModel)]="xlsxReportCustomization.xlsx_header_text_bold">
                    <p class="mt-2 fw2">XLSX Header Text Bold</p>
                </mat-slide-toggle>
                <mat-slide-toggle class="mr-4 mb-2" name="xlsx_data_text_bold" [color]="'primary'" 
                    [(ngModel)]="xlsxReportCustomization.xlsx_data_text_bold">
                    <p class="mt-2 fw2">XLSX Data Text Bold</p>
                </mat-slide-toggle>
            </div>
        </ng-container>
    </div>
    <div class="flex items-center justify-end mt-4 py-4 pr-4 pl-1 border-t bg-gray-50 dark:bg-transparent">
        <ng-container>
            <button class="ml-2" mat-flat-button [color]="'primary'" [matTooltip]="'Save'"
                (click)="saveXlsxCustomization()">
                Save
            </button>
        </ng-container>
    </div>
</div>
<div *ngIf="cView === 'custom-report-cover' && cs.currentScope === '*'">
    <app-report-cover-page> </app-report-cover-page>
</div>