import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup, UntypedFormControl, NgForm } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { MaterialModule } from 'app/material.module';
import { ModalService } from 'app/_services/modal.service';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { Subscription, Subject, takeUntil, debounceTime, map } from 'rxjs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
  selector: 'integration-configurations',
  standalone: true,
  imports: [CommonModule, FuseAlertComponent, NgxMatSelectSearchModule, DirectivesModule, ModalComponent, TableComponent, MaterialModule, DynamicFormComponent, FormsModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './integration-configurations.component.html',
  styleUrls: ['./integration-configurations.component.scss']
})
export class IntegrationConfigurationsComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Input() integrationCred: any = [];
  subs: Subscription;
  formValid: Subscription;
  companyId: any;
  credentialId: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  cView: any = 'table';
  intConfigTableOptions: any = {}
  configuration: any = {};
  configurations: any = [];
  allConfigurations: any = [];
  questions: any = [];
  configAnswers: any = [];
  currentConfig: any = {};
  configurationNewQuestions: any = [];
  newQuestion:any;
  fieldtype:any;
  all_config_status:any = [];
  config_status:any ;
  public searching = false;
  default_fieldType: any= [
    {label: "Text", value: "Text"},{label: "Checkbox", value: "Checkbox"},{label: "Currency", value: "Currency"},{label: "Date", value: "Date"},{label: "Hyperlink", value: "Hyperlink"},{label: "IPAddress", value: "IPAddress"},{label: "Number", value: "Number"},{label: "Password", value: "Password"},{label: "Percent", value: "Percent"},{label: "TextArea", value: "TextArea"}
  ];
  @ViewChild('configNgForm') configNgForm: NgForm;
  searchSourceControl: UntypedFormControl = new UntypedFormControl();
  searchDestControl: UntypedFormControl = new UntypedFormControl();
  searchSourceImpControl: UntypedFormControl = new UntypedFormControl();
  copyCompanyMapControl: UntypedFormControl = new UntypedFormControl();
  searchConfigControl: UntypedFormControl = new UntypedFormControl();
  searchConfigStatusControl: UntypedFormControl = new UntypedFormControl();
  comMapForm: UntypedFormGroup;
  constructor(private cs: CommonService, private _bs: BaseRequestService, private _changeDetectorRef: ChangeDetectorRef,
    private toast: MyToastrService, private _ls: LoaderService, public confirmDialog: FuseConfirmationService, public _mS: ModalService) {
    this.subs = this.cs.selectedSiteChanged.subscribe((res) => {

    });
  }

  ngOnInit(): void {
    this.credentialId = '';
    if (this.integrationCred && this.integrationCred.length) {
      this.credentialId = this.integrationCred[0].id;
      this.initTable(this.integrationCred[0].id);
    }
    this.searchConfigControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          return value;
        })
      )
      .subscribe((value) => {
        if (value) {
          this.getConfigs(value);
        } else {
          this.configurations = this.allConfigurations
        }
      })

      this.searchConfigStatusControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          return value;
        })
      )
      .subscribe((value) => {
        this.get_config_status();
      })
  }

  initTable($event: any): void {
    const tableOption: any = {
      columns: [
        { "header": "Name", "columnDef": "configuration_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.configuration_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },

      ],
      sortOptions: { active: 'configuration_name', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Configurations',
        id: 'integration_configurations',
        isServerSide: false,
        selectText: '',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            id: 'edit',
            icon: 'edit',
            callback: 'editFn',
            hideLocal: false,
            isGlobal: false,
          },
          {
            text: 'Delete',
            id: 'delete',
            icon: 'delete',
            callback: 'deleteFn',
            hideLocal: false,
            isGlobal: false,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: '/r/integration/integration_configurations',
          condition: '',
          isGlobal: true,
        },
      },
      customText: [
        {
          status: true,
          DisplayText: 'Yes',
          color: '#16a34a',
          'text-color': '#000000'
        },
        {
          status: false,
          DisplayText: 'No',
          color: '#e48f8f',
          'text-color': '#000000'
        },
      ],
      changeValue: new Subject<any>(),

    };
    let data = Object.assign({}, tableOption);
    this.intConfigTableOptions = {}; this._changeDetectorRef.detectChanges();
    data.pageData = []; data.tableOptions.pageTotal = 0;
    data.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + $event + "'";
    this.intConfigTableOptions = data; this._changeDetectorRef.detectChanges();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
  }

  addTableData($event?: any): void {
    this.getConfigs();
    this.get_config_status();
    this.configuration = null;
    this.getConfigAnswers();
    this.cView = 'add';
    // window.scroll({ top: 0, left: 0 });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
     * Filter by category
     *
     * @param change
     */

  filterByCred(change: MatSelectChange): void {
    this.credentialId = change.value;
    if (this.cView === 'add') {
      this.credentialId = change.value;
      this.intConfigTableOptions.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
      this.addTableData();
    } else {
      this.credentialId = change.value;
      let data = Object.assign({}, this.intConfigTableOptions);
      this.intConfigTableOptions = {}; this._changeDetectorRef.detectChanges();
      data.pageData = []; data.tableOptions.pageTotal = 0;
      data.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
      this.intConfigTableOptions = data; this._changeDetectorRef.detectChanges();
    }
  }

  closeFn(): void {
    this.configuration = null;
    this.cView = 'table';
    this.cs.dynamicScrollTop.next({});
  }

  saveFn(): void {

  }
  openAddQuestion(){
      this.configNgForm.reset();
      this._mS.open('addNewQues');
  }

  actionCall($event: any): void {
    if ($event && $event !== '') {
      this.currentConfig = $event.row;
      if ($event.action.text == 'Edit') {
        this.getConfigs($event.row.configuration_name);
        this.getConfigAnswers();
        this.get_config_status()
        this.configuration = $event.row.configuration_id;
        this.questions = $event.row.questions;
        this.cView = 'add';
        this.config_status = $event.row.configuration_status_id;
      }
      
      if ($event.action.text === 'Delete') {
        this._bs.doRequest(`/r/integration/company_mapping_view`, 'get', null, { condition: `configuration_id = ${$event.row.configuration_id}` })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((item: any) => {
            if (item.status && item.data && item.data.length) {
              this.toast.sToast('error', 'The selected Integration Rule is mapped under the Company Mapping.');
              return;
            }
            const confirmation = this.confirmDialog.open({
              title: 'Confirmation',
              message: `Are you sure you want to delete this record ?`,
              actions: {
                confirm: {
                  label: 'Yes'
                }
              }
            });
            confirmation.afterClosed().subscribe((result) => {
              if (result === 'confirmed') {
                this._bs.doRequest(`/d/integration/integration_configurations/${$event.row.id}`, 'delete')
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe((result: any) => {
                    if (result.status) {
                      this.toast.sToast('success', 'Removed successfully');
                      setTimeout(() => this.cs.selectedTagChange.next({}));
                      this._changeDetectorRef.detectChanges();
                    } else {
                      const data = (result.message) ? result.message : result.data;
                      this.toast.sToast('error', data);
                    }
                  });
              }
            })
          })

      }
    }
  }

  getConfigs(search?: any): void {
    const params: any = {
      integration_id: this.credentialId,
      integration_name: this.currentIntegration.name,
      action_name: 'get_config_types',
      requestparams: {
        product_name: (search) ? search : ''
      }
    };
    try {
      this._ls.display(true);
      this.cs.executeAction(params).then((item: any) => {
        this._ls.display(false);
        if (item.status) {
          if (!search) {
            this.allConfigurations = item.data;
          } else {
            this.allConfigurations = Array.from(new Map([...this.allConfigurations, ...item.data].map(obj => [obj.id, obj])).values());
          }
          this.configurations = item.data;
        } else {
          this.configurations = [];
        }
      });
    } catch (error) {

    }

  }

  deleteQuestion(i:any):void{
      const qid = this.questions[i]["question_id"];
      const conid = this.configuration;
      console.log(qid);
      console.log(conid);
      const params: any = {
        integration_id: this.credentialId,
        integration_name: this.currentIntegration.name,
        action_name: 'delete_config_question',
        requestparams: {
          configuration_id: this.configuration,
          question_id: qid
        }
      };
      
      try {
        this._ls.display(true);
        this.cs.executeAction(params).then((item: any) => {
          this._ls.display(false);
            this.toast.sToast('success', 'Removed successfully');
            this.questions.splice(i,1)
        });
      } catch (error) {
        this.toast.sToast('error', 'Failed to successfully');
      }
  }

  get_config_status(){
    const params: any = {
      integration_id: this.credentialId,
      integration_name: this.currentIntegration.name,
      action_name: 'get_config_status',
      requestparams: {
      }
    };
    try {
      this._ls.display(true);
      this.cs.executeAction(params).then((item: any) => {
        this._ls.display(false);
        if (item.status) {
          this.all_config_status = Array.from(new Map([...this.all_config_status, ...item.data].map(obj => [obj.id, obj])).values());
        } else {
          this.all_config_status = [];
        }
      });
    } catch (error) {

    }
  }

  closeCurrentConfig($event: boolean) {
    if (this.allConfigurations && !$event) {
      this.configurations = this.allConfigurations;
    }
  }

  getConfigQuestions(event: any): void {
    const params: any = {
      integration_id: this.credentialId,
      integration_name: this.currentIntegration.name,
      action_name: 'get_config_question',
      requestparams: {
        configuration_id: event.value,
      }
    };
    try {
      this._ls.display(true);
      this.cs.executeAction(params).then((item: any) => {
        this._ls.display(false);
        if (item.status) {
          this.questions = item.data.map((questionItem: any) => ({
            question: questionItem.question || '',
            question_id: questionItem.id || '',
            answer: questionItem.answer || ''
          }));
        } else {
          this.configurations = [];
        }
      });
    } catch (error) {

    }
  }

  getConfigAnswers(): void {
    this._bs
      .doRequest('/r/company/jsonconfigs/configuration_questions', 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          this.configAnswers = result.data;
        } else {
          this.configAnswers = [];
        }
      })
  }

  createQuestion() {
    // Start with a resolved promise to chain subsequent promises sequentially
    let sequence = Promise.resolve();
  
    // Iterate through each question in configurationNewQuestions
    this.configurationNewQuestions.forEach(que => {
      const params = {
        integration_id: this.credentialId,
        integration_name: this.currentIntegration.name,
        action_name: 'create_config_question',
        requestparams: {
          configuration_id: this.configuration,
          question: que.question,
          fieldType: que.fieldType,
        }
      };
  
      // Chain the promise to ensure sequential execution
      sequence = sequence.then(() => {
        this._ls.display(true); // Display loader before executing action
        return this.cs.executeAction(params)
          .then(item => {
            this._ls.display(false); // Hide loader after action completes
  
            if (item.status) {
              this.questions.push({ question: que.question, question_id: item.data.id, answer: '' });
            } else {
              console.log("Else block");
            }
          })
          .catch(error => {
            console.log("Catch block", error);
            this.toast.sToast("error", error);
          });
      });
    });
  
    // After all questions are processed sequentially
    return sequence.then(() => {
      this.configurationNewQuestions = []; 
      this._mS.close("addNewQues"); 
      this.toast.sToast("success",'Added Successfully');
    });
  }
  

  saveConfiguration() {
    const selected_config = this.configurations.filter((x: any) => x.id === this.configuration);
    const selected_status = this.all_config_status.filter((x: any) => x.id === this.config_status)
    const params: any = {
      data: {
        questions: this.questions,
        configuration_name: selected_config[0].name,
        integration_name: this.currentIntegration.name,
        credential_id: this.credentialId,
        configuration_id: selected_config[0].id,
        configuration_status_id: selected_status[0].id,
        configuration_status_name: selected_status[0].description
      }
    }
    const method = (this.currentConfig && this.currentConfig.id) ? 'patch' : 'post';
    const msg = (this.currentConfig && this.currentConfig.id) ? 'updated' : 'added';
    (this.currentConfig && this.currentConfig.id) ? params.id = this.currentConfig.id : null;
    this._ls.display(true);
    this._bs
      .doRequest(`/w/integration/integration_configurations`, method, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._ls.display(false);
        if (result.status) {
          this.toast.sToast(
            "success",
            `Configuration ${msg} successfully`
          );
          this.cView = 'table'; this.currentConfig = {}; this.questions = []; this.config_status ={};
          setTimeout(() => this.cs.selectedTagChange.next({}));
          this._changeDetectorRef.detectChanges();
        } else {
          const data = result.message ? result.message : result.data;
          this.toast.sToast("error", data);
        }
      });
  }

  cancel(): void {
    this.cView = 'table'; this.currentConfig = {};
    this.questions = [];
    this.config_status = {};
  }
  addQuestion(): void {
    this.configurationNewQuestions.push( { question: this.newQuestion, fieldType: this.fieldtype});
    this.configNgForm.reset();
  }
}


