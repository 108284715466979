import {Directive, ElementRef, HostListener} from '@angular/core';
import {MyToastrService} from "../_services/toastr.service";

@Directive({
  selector: '[appPasswordCheck]'
})
export class PasswordCheckDirective {

  constructor(private el: ElementRef, private toast: MyToastrService) {
  }

  PASSWORD_REGX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_-])[0-9a-zA-Z!@#$%_-](?=.{8,128})');

  @HostListener('focusout')

  onFocusOut(): boolean {
    const current: string = this.el.nativeElement.value;
    if (current !== '') {
      if (current && !String(current).match(this.PASSWORD_REGX)) {
        this.toast.sToast('error', 'Incorrect Password! A password must be at least 8 characters long, contain no spaces, and include one uppercase letter, one lowercase letter, one number, and one special character (!@#$ % _-).');
        this.el.nativeElement.focus();
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
}
