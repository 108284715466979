import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ModalComponent } from '../modal.component';
import { CommonService } from 'app/_services/common.service';
import { MaterialModule } from "../../../material.module";
import { IntegrationActionsService } from "../../../_services/integration-actions.service";
import { ModalService } from "../../../_services/modal.service";
import { FormsModule, NgForm } from "@angular/forms";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { DynamicFormComponent } from "../forms/dynamic-form/dynamic-form.component";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { cloneDeep } from 'lodash';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
    selector: 'app-integration-action',
    standalone: true,
    imports: [CommonModule, ModalComponent, MaterialModule, FormsModule, AppFilterPipeModule, DynamicFormComponent, FuseScrollbarDirective, FuseAlertComponent],
    templateUrl: './integration-action.component.html',
    styleUrls: ['./integration-action.component.scss']
})
export class IntegrationActionComponent implements OnInit, OnDestroy {
    @Input() inputActionData: any;
    @Input() mode: any;
    @Input() currentAsset: any;
    @Input() productData?: any;
    @Input() integrationAsset?: any;
    integrationActionsWidth: any = 800;
    @ViewChild('ticNgForm') ticNgForm: NgForm;
    credListData: any = [];
    localActionData: any;
    subscribedKeys: any = {};
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    sView = 'getIntegrations';
    integration: any = { actionData: {}, name: '' };
    integrationHash: any = [];
    integrationMappingHash: any = [];
    formElements: any = [];
    actionParams: any;
    currentActions: any = [];
    Objectkeys = Object.keys;
    actionParamsList: any;
    actionTemplate: any;
    defaultActionData: any;
    disabledSubmit = false;
    public integrations: any;
    outputValue: any = {};
    selectedAction: any = [];
    selectedIng: any = [];
    integrationsData: any = [];
    integrationProfile: any = [];
    isEdit = false;
    ticketingTemplate: any = null;
    constructor(private bs: BaseRequestService, public cs: CommonService,
        private ls: LoaderService, private toast: MyToastrService,
        public modalService: ModalService,
        public integrationActionsService: IntegrationActionsService, private _cdr: ChangeDetectorRef) {
        this.integrationActionsService.integrationActionPopup
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.value) {
                    setTimeout(() => {
                        this.integration = { name: '' }; this.isEdit = false; this.sView = 'getIntegrations';
                        this.getAllIntegrations();
                    });
                }
            });
        this.cs.formValidationEVE
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res: any) => {
                    this.disabledSubmit = res;
                    this._cdr.detectChanges();
                }
            );

    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        this.localActionData = { ...this.inputActionData };
    }

    getAllIntegrations(): void {
        this.ls.Modeldisplay(true);
        try {
            this.bs.doRequest(`/r/integration/company_mappings`, 'get', null,
                { condition: `company_id=${this.cs.currentScope.id} `, skip: 0, limit: 100 })
                .pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
                    this.ls.Modeldisplay(false);
                    if (result.status && result.data && result.data.length) {
                        try {
                            const condition: any = {
                                condition: true,
                                skip: 0,
                                limit: 50,
                                order_by: 'updated desc'
                            };
                            const cred_id = result.data.map((x: any) => x.credential_id);
                            if (cred_id.length === 1) {
                                condition.condition = `id=${cred_id[0]}`;
                            } else if (cred_id.length > 1) {
                                condition.condition = `id IN (${cred_id.join(", ")})`;
                            }
                            this.bs.doRequest(`/r/integration/integration_credentials_view`, 'get', null, condition)
                                .pipe(takeUntil(this._unsubscribeAll))
                                .subscribe((cred: any) => {
                                    this.ls.Modeldisplay(false);
                                    if (cred.status) {
                                        this.integrationsData = [];
                                        if (cred.data && cred.data.length) {
                                            this.integrationsData = cred.data;
                                            try {
                                                this.bs.doRequest(`/r/integration/get_integrations`, "get")
                                                    .pipe(takeUntil(this._unsubscribeAll))
                                                    .subscribe((res: any) => {
                                                        if (res.status) {
                                                            this.integrations = [];
                                                            result.data.forEach((im: any) => {
                                                                res.data.forEach((obj: any) => {
                                                                    if (obj.name === im.integration_name && obj.notificationfunction) {
                                                                        const credential = this.integrationsData.filter((x: any) => x.id === im.credential_id);
                                                                        this.integrations.push({ ...obj, ...{ integration_rules: (im.integration_rules) ? im.integration_rules.toString() : "", credential_id: im.credential_id, credential_name: (credential && credential.length) ? credential[0].name : '' } })
                                                                    }
                                                                });
                                                            });
                                                            this.integration = {};
                                                            if (this.inputActionData.ticket_id && this.inputActionData.integration_name) {
                                                                const integration = this.integrations.filter((x: any) => x.name === this.inputActionData.integration_name);
                                                                this.integration.name = this.inputActionData.integration_name;
                                                                this.getActions({ value: this.inputActionData.integration_name });
                                                                this.integrations = integration;
                                                            } else {
                                                                if (this.integrations && this.integrations.length) {
                                                                    this.integration.name = this.integrations[0].name;
                                                                    this.getActions({ value: this.integrations[0].name });
                                                                }
                                                            }
                                                            this.isEdit = false; this.sView = 'getIntegrations'; this.outputValue = {};
                                                            this.modalService.open('integrationActions');
                                                        } else {
                                                            this.toast.sToast("error", "Integration not found!");
                                                        }
                                                    });
                                            } catch (error) {

                                            }
                                        }
                                    } else {
                                        const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
                                    }
                                })
                        } catch (error) {

                        }


                    } else {
                        this.toast.sToast('error', 'Integration not configured!');
                    }
                });
        } catch (error) {
            this.ls.Modeldisplay(false);
        }

    }

    getActions($event: any): void {
        this.selectedIng = this.integrations.filter((x: any) => x.name === $event.value);
        this.currentActions = [];
        if (this.selectedIng && this.selectedIng.length) {
            const action = this.selectedIng[0].integration_actions.split(',');
            this.currentActions = (this.inputActionData.ticket_id) ? action.filter((x: any) => x !== 'create_ticket') : action;
            const condition = {
                condition: `integration_name='${$event.value}' and credential_id=${this.selectedIng[0].credential_id}`,
                skip: 0,
                limit: 50,
                order_by: 'updated desc'
            };
            const integrationRules = (this.selectedIng[0] && this.selectedIng[0].integration_rules) ? cloneDeep(this.selectedIng[0].integration_rules.split(',')) : [];
            const removeEmpty = integrationRules.filter((x: any) => x !== '' && x !== null);
            if (removeEmpty && removeEmpty.length) {
                if (removeEmpty.length === 1) {
                    condition.condition += ` and id=${removeEmpty[0]}`;
                } else {
                    condition.condition += ` and id in (${removeEmpty.join(',')})`;
                }
            }
            this.ls.Modeldisplay(true);
            this.bs.doRequest(`/r/integration/integration_rules`, 'get', null, condition)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.ls.Modeldisplay(false);
                    if (result.status) {
                        this.integrationProfile = [];
                        if (result.data && result.data.length) {
                            /*if (this.selectedIng[0].integration_rules) {
                                const ids = this.selectedIng[0].integration_rules.split(',');
                                const iProfile = result.data.filter((x: any) => ids.indexOf(x.id) > -1);
                                this.integrationProfile = result.data;
                            } else {
                                this.integrationProfile = result.data;
                            }*/
                            this.integrationProfile = result.data;
                            this.isEdit = false;
                        } else {
                            this.isEdit = true;
                        }
                        this.getTicketingTemplate();
                        /*if (this.inputActionData.event_type) {
                            this.getTicketingTemplate();
                        } else {
                            this.ticketingTemplate = null;
                        }*/
                    } else {
                        const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
                    }
                })

        }

    }

    getAction(): void {
        this.selectedIng = this.integrations.filter((x: any) => x.name === this.integration.name);
        if (this.selectedIng && this.selectedIng.length) {
            this.integration.credential_id = this.selectedIng[0].credential_id;
            const selectedProfile = this.integrationProfile.filter((x: any) => x.id === this.integration.integration_rule_id);
            this.selectedAction = this.selectedIng[0].actions.filter((x: any) => x.name === this.integration.action);
            if (this.selectedAction && this.selectedAction.length) {
                // th
                // { action: { name: param.name, destination: param.destination, verb: param.verb }
                this.selectedAction[0].parameters = this.selectedAction[0].parameters.filter(
                    (x: any) => x.requiredforglobal !== false
                );
                this.selectedAction[0].parameters.map((x: any) => {
                    if (x.source && x.source.name) {
                        if (x.name === 'summary' || x.name === 'initial_description') {
                            x.schema_type = 'textarea';
                        }
                        const filterParams = this.selectedIng[0].actions.filter((s: any) => s.name === x.source.name);
                        if (filterParams && filterParams.length) {
                            x.parameters = filterParams[0];
                            // x.parameters.search = filterParams[0].search;
                        }
                    }
                });

                if (this.ticketingTemplate && this.ticketingTemplate.summary && this.ticketingTemplate.description) {
                    /*let initial_description = '';
                    let summary = '';
                    this.productData.map((rem: any) => {
                        const replacements = {
                            'product': rem?.product,
                            'fix': rem?.fix,
                            'epss_vuls': rem?.epss_vuls,
                            'host_name': this.integrationAsset,
                            'total_problems': rem.total_problems,
                            'critical_problems': rem?.critical_problems,
                            'high_problems': rem?.high_problems,
                            'medium_problems': rem?.medium_problems,
                            'low_problems': rem?.low_problems,
                            'url': rem?.url,
                        };

                        initial_description = this.ticketingTemplate.description;
                        summary = this.ticketingTemplate.summary;

                        for (const key in replacements) {
                            if (replacements.hasOwnProperty(key)) {
                                const regex = new RegExp('{{' + key + '}}', 'g');
                                initial_description = initial_description.replace(regex, replacements[key]);
                                summary = summary.replace(regex, replacements[key]);
                            }
                        }
                        initial_description += '\n';
                        initial_description += '---------------------------------------------------------------------\n';
                        initial_description += '\n';
                        initial_description += '\n';
                        summary += '\n';
                        summary += '---------------------------------------------------------------------\n';
                        summary += '\n';
                        summary += '\n';
                    });*/
                    this.inputActionData.description = this.ticketingTemplate.description;
                    this.inputActionData.summary = this.ticketingTemplate.summary ;
                }
                const isName = this.selectedAction[0].parameters.filter(
                    (x: any) => x.name === 'name'
                );
                if (!isName || !isName.length) {
                    const name: any = {
                        callback: null, default: '', depends: null, description: 'Name',
                        name: 'name', options: null, required: true, schema_type: 'text', source: null
                    }
                    this.selectedAction[0].parameters.unshift(name);
                }
                if (this.selectedAction && this.selectedAction.length) {
                    this.formElements = [];
                    if (this.selectedAction[0].getparamsfunc) {
                        const params: any = {
                            integration_id: this.integration.credential_id,
                            integration_name: this.selectedIng[0].name,
                            action_name: this.selectedAction[0].getparamsfunc,
                            requestparams: {}
                        };
                        this.ls.display(true);
                        this.cs.executeAction(params).then((res: any) => {
                            if (res.status) {
                                this.ls.display(false);
                                this.outputValue = (selectedProfile && selectedProfile.length) ? selectedProfile[0].integration_params : {};
                                this.outputValue = { ...this.outputValue, ...{ initial_description: this.inputActionData.description, summary: this.inputActionData.summary, ticket_id: this.inputActionData.ticket_id } };
                                this.cs.processFieldsParams(this.selectedAction[0].parameters, this.formElements, this.selectedIng[0].name, this.integration.credential_id, this.outputValue).then((subOut: any) => {
                                    this.formElements = subOut;
                                });
                            } else {
                                this.ls.display(false);
                                const data = (res.message) ? res.message : res.data;
                                this.toast.sToast('error', data);
                            }
                        });
                    } else {
                        this.outputValue = (selectedProfile && selectedProfile.length) ? selectedProfile[0].integration_params : {};
                        this.outputValue = { ...this.outputValue, ...{ initial_description: this.inputActionData.description, summary: this.inputActionData.summary, ticket_id: this.inputActionData.ticket_id } };
                        this.cs.processFieldsParams(this.selectedAction[0].parameters, this.formElements, this.selectedIng[0].name, this.integration.credential_id, this.outputValue).then((subOut: any) => {
                            this.formElements = subOut;
                        });
                    }
                }
            }

            this.sView = 'actionParams';
        }

    }


    actionChange($event: any): void {
        if ($event && $event !== '') {
            this.cs.processFieldsParams(this.selectedAction[0].parameters, this.formElements, this.selectedIng[0].name, this.integration.credential_id, this.outputValue).then((subOut: any) => {
                this.formElements = []; this.formElements = subOut;
            });
        }
    }


    saveAction(): void {
        delete this.outputValue["[object Object]"];
        const output = cloneDeep(this.outputValue);
        const requestData: any = {
            action: this.integration.action,
            description: this.inputActionData.description,
            summary: this.inputActionData.summary,
            company_id: (this.integration.cAsset) ? this.integration.cAsset.company_id : parseInt(this.cs.currentScope.id),
            asset_ids: undefined,
            solution_ids: (this.inputActionData.solution_ids && this.inputActionData.solution_ids.length) ? this.inputActionData.solution_ids.toString() : undefined,
            source_ids: (this.inputActionData.source_ids && this.inputActionData.source_ids.length) ? this.inputActionData.source_ids.toString() : undefined,
            ticketid: (output.ticket_id) ? output.ticket_id : (this.inputActionData.ticket_id) ?  this.inputActionData.ticket_id : undefined,
            integration_rule_id: this.integration.integration_rule_id,
            action_type: this.inputActionData.action_type,
            docs: this.productData
        }
        if (this.inputActionData.asset_id) {
            requestData.asset_ids = this.inputActionData.asset_id.toString();
        } else {
            const outputArray = [...new Set(this.productData.flatMap((obj: any) => obj.asset_ids))];
            requestData.asset_ids = outputArray.join(',');
        }
        requestData.summary = output.summary;
        requestData.description = output.initial_description;
        requestData.summary = (requestData.summary && requestData.summary.length > 100) ? requestData.summary.substring(0, 100) : requestData.summary;
        if (this.inputActionData.ticket_number && this.inputActionData.ticket_number.length) {
            const name = this.inputActionData.ticket_number.filter((x: any) => x.ticket_number === output.ticket_id || x.ticket_id === output.ticket_id)
            requestData.ticketid = (name && name.length) ? name[0].ticket_id : output.ticket_id;
        }
        if (this.integration.action === 'create_ticket' || this.integration.action === 'send_message' || this.integration.action === 'send_email') {
            const reqData: any = {}
            if (this.integration.integration_rule_id) {
                const integrationProfile: any = cloneDeep(this.integrationProfile);
                const selectedProfile = integrationProfile.filter((x: any) => x.id === this.integration.integration_rule_id);
                reqData.data = selectedProfile[0]; reqData.id = this.integration.integration_rule_id;
                reqData.data.integration_params = { ...reqData.data.integration_params, ...output }
            } else {
                reqData.data = {
                    name: output.name,
                    integration_name: this.selectedIng[0].name,
                    credential_id: this.integration.credential_id,
                    integration_params: output
                }
            }
            delete reqData.data.id;
            try {
                this.ls.Modeldisplay(true);
                const method = (this.integration.integration_rule_id) ? 'patch' : 'post';
                this.bs.doRequest(`/w/integration/integration_rules`, method, reqData)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.Modeldisplay(false);
                        if (result.status) {
                            requestData.integration_rule_id = (method === 'post') ? parseInt(result.id) : parseInt(result.data.id);
                            try {
                                this.ls.Modeldisplay(true);
                                this.bs.doRequest(`/w/integration/execute_manual_ticket`, 'post', requestData)
                                    .pipe(takeUntil(this._unsubscribeAll))
                                    .subscribe((result: any) => {
                                        this.ls.Modeldisplay(false);
                                        if (result.status) {
                                            this.toast.sToast('success', (result.data && result.data.id) ? `Successfully executed. ${result.data.id}.` : `Successfully executed`);
                                            this.onDismiss();
                                        } else {
                                            const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
                                        }
                                    })
                            } catch (error) {

                            }
                        } else {
                            const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
                        }
                    })
            } catch (error) {

            }

        } else {
            this.ls.Modeldisplay(true);
            this.bs.doRequest(`/w/integration/execute_manual_ticket`, 'post', requestData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.ls.Modeldisplay(false);
                    if (result.status) {
                        this.toast.sToast('success', (result.data && result.data.id) ? `Successfully executed. ${result.data.id}.` : `Successfully executed`);
                        this.onDismiss();
                    } else {
                        const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
                    }
                })
        }

    }

    onDismiss(): void {
        setTimeout(() => this.cs.remRefreshCalBack.next({}), 10);
        this.cs.selctedAsset = null;
        this.modalService.close('integrationActions');
        this.integrationActionsService.integrationActionShow = false;
        this.integrationActionsService.integrationAssetActionShow = false;
    }

    getTicketingTemplate(): void {
        /*
        this.ls.display(false);
        const params: any = { integration_name: this.integration.name, company_id: parseInt(this.cs.currentScope.id) }
        this.bs
            .doRequest(`/r/report_queries/get_ticket_templates`, "get", null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status && res.data && res.data.length) {
                    const filteredData = res.data.filter((obj: any) => obj.template_details.some((detail: any) => detail.type === 'Manual Ticket'));

                    const result = filteredData.map((obj: any) => {
                        const templateDetail = obj.template_details.find((detail: any) => detail.type === 'Manual Ticket');
                        return {
                            summary: templateDetail.summary,
                            description: templateDetail.description
                        };
                    });
                    this.ticketingTemplate = (result && result.length) ? result[0] : null;
                } else {
                    this.ticketingTemplate = null;
                }
            });
            */

        this.ls.display(false);
        const params: any = { integration: this.integration.name, company_id: parseInt(this.cs.currentScope.id),  source_ids: this.inputActionData.source_ids.toString(), asset_ids: (this.inputActionData.asset_id) ? [this.inputActionData.asset_id] : this.inputActionData.asset_ids};
        this.bs
            .doRequest(`/r/integration/get_render_template`, "post", params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status && res.data && res.data.length) {
                    this.ticketingTemplate = { summary: res.data[0].summary, description: ''};
                    res.data.map((x: any, i: any) => {
                        if(i === 0 && res.data[0].header_description){
                            const regex = /<p>(.*?)<\/p>/;
                            let header = res.data[0].header_description.match(regex);
                            if(header && header[1]){
                                this.ticketingTemplate.description = header[1];
                            }
                        }
                        this.ticketingTemplate.description += '\n';
                        this.ticketingTemplate.description += '\n';
                        this.ticketingTemplate.description += x.description;
                        this.ticketingTemplate.description += '\n';
                        this.ticketingTemplate.description += '\n';

                        if(i === (res.data.length -1) && res.data[0].footer_description){
                            const regex = /<p>(.*?)<\/p>/;
                            let footer = res.data[0].footer_description.match(regex);
                            if(footer && footer[1]){
                                this.ticketingTemplate.description += footer[1];
                            }
                            
                        }
                    });
                } else {
                    this.ticketingTemplate = null;
                }
            });
    }

    clearingData(): void {
        this.integrations = [];
        this.currentActions = [];
        this.integrationProfile = [];
        this.inputActionData = {};
        this.integrationActionsService.integrationActionShow = false;
        this.integrationActionsService.integrationAssetActionShow = false;
        this._cdr.detectChanges();
        this._cdr.markForCheck();
    }

}
