import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { fuseAnimations } from '@fuse/animations';
import { cloneDeep } from 'lodash';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { ShowMoreLessComponent } from 'app/modules/shared/show-more-less/show-more-less.component';
import { SkeletonComponent } from 'app/modules/shared/skeleton/skeleton.component';
import { IpvComponent } from 'app/modules/shared/ipv/ipv.component';

@Component({
  selector: 'app-asm-results',
  standalone: true,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IpvComponent,
    MaterialModule,
    AppFilterPipeModule,
    TableComponent,
    MatSidenavModule,
    FuseScrollbarDirective,
    ShowMoreLessComponent,
    SkeletonComponent,
    FormsModule],
  templateUrl: './asm-results.component.html',
  styleUrls: ['./asm-results.component.scss']
})
export class AsmResultsComponent implements OnInit, OnDestroy {
  @Input() currentJob: any;
  @Input() apiCallSuccessful: boolean = false;
  @Output() closeCallback = new EventEmitter();
  @Output() toggleFn = new EventEmitter();
  chunkedBuckets: string[][] = [];
  guessed_emails: string[][] = [];
  Emails_update: string[][] = [];
  hashes: string[][] = [];
  usernames_update: string[][] = [];
  employees_name: string[][] = [];
  subdomains_name: string[][] = [];
  creds_name: string[][] = [];
  chunkedSubdomains: string[][] = [];
  showMoreDnsRecords: boolean = false;
  showMoreRawHeaders: { [key: string]: boolean } = {};
  objectKeys = Object.keys;
  expandedDnsRecords = new Set<number>();
  data: any = {};
  summary: any[];
  showData = null;
  @ViewChild('targetip', { static: false }) targetip: ElementRef;
  @ViewChild('emails', { static: false }) emails: ElementRef;
  @ViewChild('usernames', { static: false }) usernames: ElementRef;
  @ViewChild('subdomains', { static: false }) subdomains: ElementRef;
  @ViewChild('scrollableDiv', { static: false }) scrollableDiv: ElementRef;

  constructor(private _bs: BaseRequestService, private _cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private _ls: LoaderService, private toast: MyToastrService, private renderer: Renderer2) {
  }

  ngOnDestroy(): void {
  }
  ngOnInit(): void {
    this.callResult();
    for (const header in this.data.raw_headers) {
      if (this.data.raw_headers.hasOwnProperty(header)) {
        this.showMoreRawHeaders[header] = false;
      }
    }
    if (this.currentJob) {
      this.showData = null;
      this.data = cloneDeep(this.currentJob);
      this.processData();
      this.showData = true; this._changeDetectorRef.detectChanges();
    }
  }

  callResult() {
    this.showData = null;
    this.data = cloneDeep(this.currentJob);
    this.processData();
    this.showData = true; this._changeDetectorRef.detectChanges();
  }

  processData(): void {
    if (this.data) {
      if (this.data && this.data.s3buckets && this.data.s3buckets[0]) {
        this.chunkedBuckets = this.chunkArray(this.data.s3buckets[0], 5);
      }

      if (this.data.guessed_emails && this.data.guessed_emails && this.data.guessed_emails[0]) {
        this.guessed_emails = this.chunkArray(this.data.guessed_emails[0], 5);
      }
      if (this.data.emails && this.data.emails && this.data.emails[0]) {
        this.Emails_update = this.chunkArray(this.data.emails[0], 5);

      }
      if (this.data.hashes && this.data.hashes && this.data.hashes[0]) {
        this.hashes = this.chunkArray(this.data.hashes[0], 5);
      }
      if (this.data.usernames && this.data.usernames && this.data.usernames[0]) {
        this.usernames_update = this.chunkArray(this.data.usernames[0], 5);

      }
      if (this.data.employees && this.data.employees && this.data.employees[0]) {
        this.employees_name = this.chunkArray(this.data.employees[0], 5);
      }

      if (this.data.subdomains && this.data.subdomains && this.data.subdomains[0]) {
        this.subdomains_name = this.chunkArray(this.data.subdomains[0], 5);
      }

      if (this.data.creds && this.data.creds && this.data.creds[0]) {
        this.creds_name = this.chunkArray(this.data.creds[0], 5);
      }
      let ports: any = [];
      let vuls: any = [];
      if (this.data.target_ips && this.data.dns_records) {
        this.data.target_ips.forEach((obj: any) => {
          obj['port_protocol'].split(',').forEach((port: any) => {
            if (port && port !== '') { ports.push(port); }
          });
          obj.Vulnerabities.split(',').forEach((vul: any) => {
            if (vul && vul !== '') { vuls.push(vul); }
          });
        });
        this.data.dns_records = this._cs.processTable(this.data.dns_records[0]);
        ports = [...new Set(ports)];
        vuls = [...new Set(vuls)];
        this.summary = [
          { title: 'Vulnerabilities', img: '/assets/images/network/vulbug.svg', count: vuls.length },
          { title: 'Open Ports', img: '/assets/images/network/ports.svg', count: ports.length },
          { title: 'Target IPs', img: '/assets/images/network/ip.svg', count: this.data.target_ips.length },
          {
            title: 'Emails',
            img: '/assets/images/network/email.svg',
            count: (this.data.emails[0].length && this.data.emails[0][0]) ? this.data.emails[0].length : 0
          },
          {
            title: 'Usernames',
            img: '/assets/images/network/user_name.svg',
            count: (this.data.usernames[0].length && this.data.usernames[0][0]) ? this.data.usernames[0].length : 0
          },
          {
            title: 'Subdomains',
            img: '/assets/images/network/domain.svg',
            count: (this.data.subdomains[0].length && this.data.subdomains[0][0]) ? this.data.subdomains[0].length : 0
          }
        ];
      }
      setTimeout(() => {
        this.showData = true; this._changeDetectorRef.detectChanges();
      }, 100);
    }
  }

  toggleShowMore(event: MouseEvent): void {
    event.preventDefault();
    this.showMoreDnsRecords = !this.showMoreDnsRecords;
  }

  toggleShowMoreRawHeaders(event: MouseEvent, header: string): void {
    event.preventDefault();
    this.showMoreRawHeaders[header] = !this.showMoreRawHeaders[header];
  }

  chunkArray(array: string[], size: number): string[][] {
    const chunkedArray: string[][] = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  }

  toggleShowMoreDnsRecords(index: number): void {
    if (this.expandedDnsRecords.has(index)) {
        this.expandedDnsRecords.delete(index);
    } else {
        this.expandedDnsRecords.add(index);
    }
}

  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  navigateTarget($event: any): void {
    try {
      if ($event.title === 'Target IPs' || $event.title === 'Vulnerabilities' || $event.title === 'Open Ports') {
        this.targetip.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.title === 'Emails') {
        this.emails.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.title === 'Usernames') {
        this.usernames.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.title === 'Subdomains') {
        this.subdomains.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    } catch (err) { }
  }
}
