import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, NgFor, NgForOf, NgIf } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { CommonService } from 'app/_services/common.service';
import { FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { Subject, ReplaySubject, debounceTime, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { v4 as uuidv4 } from 'uuid';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { ModalService } from 'app/_services/modal.service';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { DirectivesModule } from 'app/-directives/-directives.module';

@Component({
  selector: 'import-company-mapping',
  standalone: true,
  imports: [CommonModule, MaterialModule, TableComponent, FormsModule, NgFor, NgIf, NgForOf,
    ReactiveFormsModule, AppFilterPipeModule, FuseAlertComponent, AsyncPipe, MatSidenavModule,
    MatFormFieldModule, ModalComponent, DynamicFormComponent,
    NgxMatSelectSearchModule, DirectivesModule,
    MatInputModule,
    MatButtonModule,],
  templateUrl: './import-company-mapping.component.html',
  styleUrls: ['./import-company-mapping.component.scss']
})
export class ImportCompanyMappingComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Input() integrationCred: any = [];
  selectedCred: any = null;
  mappedComTableOptions: any = {};
  cView: any = 'table';
  aView: any = 'type';
  selectedType: any = '';
  destCompany: any = [];
  sourceCompany: any = [];
  mapCompanyList: any = [];
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public filteredSourceCompany: ReplaySubject<any> = new ReplaySubject<any>(1);


  searchSourceControl: UntypedFormControl = new UntypedFormControl();
  searchDestControl: UntypedFormControl = new UntypedFormControl();
  searchSourceImpControl: UntypedFormControl = new UntypedFormControl();
  comMapForm: UntypedFormGroup;
  comMapImpForm: any = {
    company_id: []
  };
  @ViewChild('comMapNgForm') comMapNgForm: NgForm;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  isConstentProvided: boolean = false;
  importProvideConsent: any = {};
  getCount = 0;

  editMapForm: UntypedFormGroup;
  @ViewChild('editMapNgForm') editMapNgForm: NgForm;
  updateCompany: any = {};

  editDestControl: UntypedFormControl = new UntypedFormControl();
  edit_update_company: any = {};


  companyActionParams: any = {};
  companyParams: any = {};
  companyFormElements: any = [];

  /**
     * Constructor
     */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef, private _ls: LoaderService,
    private _bs: BaseRequestService, public _cs: CommonService,
    private toast: MyToastrService, private _formBuilder: UntypedFormBuilder,
    public _mS: ModalService, public confirmDialog: FuseConfirmationService
  ) {
  }

  ngOnInit(): void {
    if (this.integrationCred && this.integrationCred.length) {
      this.selectedCred = this.integrationCred[0].id;
      this.initMappedCompany(this.integrationCred[0].id);
    }

    const get_conpany = this.currentIntegration.actions.filter((x: any) => x.name === 'get_companies');
    if (get_conpany && get_conpany.length) {
      this.companyActionParams = get_conpany[0];
    }

  }

  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }



  initMappedCompany($event: any): void {
    this.mappedComTableOptions = {
      columns: [
        {
          "header": "Source Company Name",
          "columnDef": "source_company_name",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.source_company_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },

        {
          "header": "Destination Company Name",
          "columnDef": "dest_company_name",
          "filter": "",
          "cell": "(element: any) => `${element.dest_company_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
      ],
      sortOptions: { active: 'updated', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Mapped Company',
        isServerSide: false,
        selectText: 'MappedCompany',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            id: 'edit',
            icon: 'edit',
            callback: 'editFu',
            isGlobal: false,
          },
          {
            text: 'Delete',
            id: 'delete',
            icon: 'delete',
            callback: 'editFu',
            isGlobal: false,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        id: 'MappedCompany',
        serverSide: {
          condition: "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + $event + "'",
          url: '/r/integration/company_mappings',
          type: 'get',
          params: {}
        },
      },
      changeValue: new Subject<any>(),
    }
    /*if (this.currentIntegration.name !== 'AzureADNonCSP') {
      const isCmp = this.mappedComTableOptions.tableOptions.actionMenuItems.filter(
        (x: any) => x.text === 'Edit'
      );
      if (!isCmp || !isCmp.length) {
        this.mappedComTableOptions.tableOptions.actionMenuItems.unshift(

        )
      }
    }*/
    // setTimeout(() => this.mappedComTableOptions.changeValue.next(true));
  }


  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
     * Filter by category
     *
     * @param change
     */
  filterByCred(change: MatSelectChange): void {
    this.mappedComTableOptions.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
    this.mappedComTableOptions.changeValue.next(true);
  }

  deleteRecord(data: any) {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Are you sure you want to delete ?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this._bs.doRequest(`/d/integration/company_mappings/${data.id}`, 'delete')
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => {
                this.mappedComTableOptions.changeValue.next(true);
              }, 3000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      }
    })
  }

  addTableData(): void {
    this.mapCompanyList = [];
    this.comMapImpForm = {
      company_id: []
    };
    this.cView = 'add';
    this.companyFormElements = this._cs.processFormElements(this.companyActionParams.parameters);
    this.companyFormElements.map((x: any) => { (x.name) ? this.companyParams[x.name] = x.required ? '' : x.default : null; })
    this.getCompany();
  }

  actionCall($event: any): void {
    if ($event.action.text == 'Edit') {
      this.updateCompany = $event.row;
      const params = {
        integration_id: this.selectedCred,
        integration_name: this.currentIntegration.name,
        action_name: 'get_companies',
        requestparams: {}
      }
      this._ls.display(true);
      this._cs.executeAction(params).then((item: any) => {
        this._ls.display(false);
        if (item.status) {
          this.destCompany = item.data;
          this.searching = false;
          const com = this.integrationCred.filter((x: any) => x.id === $event.row.dest_company_id);
          this.edit_update_company = com[0];
          this._mS.open('editImportNonPsaInt');
        } else {
          const data = (item.message) ? item.message : item.data;
          this.toast.sToast('error', data);
        }
      });
    }
    if ($event.action.text == 'Delete') {
      this.deleteRecord($event.row);
    }
  }

  getCompany($event?: any): void {
    this._ls.display(true);
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_companies',
      requestparams: {}
    };
    (this.companyParams) ? params.requestparams = this.companyParams : {};
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status) {
        this.destCompany = item.data;
      } else {
        const data = (item.message) ? item.message : item.data;
        this.toast.sToast('error', data);
      }
    });
  }


  updateComMapping(): void {
    const shortcut = this.edit_update_company;
    this.updateCompany.dest_company_id = shortcut.id; this.updateCompany.dest_company_name = shortcut.name;
    delete this.updateCompany.hovered;
    const reqData = { data: this.updateCompany, id: this.updateCompany.id };
    this._ls.display(true);
    this._bs.doRequest(`/w/integration/company_mappings`, 'patch', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._ls.display(false);
        if (result.status) {
          this._mS.close('editNonPsaInt');
          setTimeout(() => this.mappedComTableOptions.changeValue.next(true));
        }
      });
  }


  removeSelected(i: number): void {
    this.comMapImpForm.company_id = this.comMapImpForm.company_id.filter((obj: any) => obj.id !== this.comMapImpForm.company_id[i].id);
  }


  importSelectedCompanies(): void {
    this.comMapImpForm.company_id.forEach((obj: any, index: any) => {
      this._ls.display(true, `Importing ${obj.name}`);
      const reqData = { data: { name: obj.name, description: null } };
      this._bs.doRequest('/w/company/companies', 'post', reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          if (result.status) {
            const integrationData: any = { integration_name: this.currentIntegration.name, credential_id: this.selectedCred };
            const requestD: any = {
              data: {
                ...integrationData,
                ...{
                  company_id: parseInt(result.id),
                  source_company_name: obj.name,
                  dest_company_id: obj.id,
                  dest_company_name: obj.name,
                }
              }
            }
            this._bs.doRequest(`/w/integration/company_mappings`, 'post', requestD)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((result: any) => {
                if (result.status) {
                  if (index === this.comMapImpForm.company_id.length - 1) {
                    this._ls.display(false);
                    this.cView = 'table';
                    this._changeDetectorRef.markForCheck();
                    localStorage.setItem('cmp', '*');
                    this._cs.newCompanyCall.emit({});
                    setTimeout(() => this.mappedComTableOptions.changeValue.next(true));
                  }
                } else {
                  this._ls.display(false);
                  const data = (result.message) ? result.message : result.data;
                  this.toast.sToast('error', data);
                }
              });
          } else {
            const data = (result.message) ? result.message : result.data;
            this.toast.sToast('error', data);
          }
        })
    });
  }

}
