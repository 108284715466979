<div class="flex flex-col bg-card border m-4 border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
    <div class="mx-4" *ngIf="!loadingCon.loading && !loadingCon.nodata" fuseScrollbar>
        <table class="w-full bg-transparent mb-4" mat-table [dataSource]="windowDataSource" [trackBy]="trackByFn"
            #reportCardTable>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="">
                    Name
                </th>
                <td mat-cell *matCellDef="let report" class="w-[39%]">
                    <div class="flex flex-row items-center my-2">
                        <div class="flex px-2 py-1">
                            <div class="flex items-center justify-center">
                                <img *ngIf="windowsCheck[report.name] && windowsCheck[report.name].icon" src="./assets/images/asset-details/{{windowsCheck[report.name].icon}}.png" class="mr-3 w-6 h-6" alt="">
                                <span class="pr-6 my-2 font-medium text-md break-all">
                                    {{windowsCheck[report.name].name || report.name}}
                                </span>
                            </div>

                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="minimum_required">
                <th mat-header-cell *matHeaderCellDef>
                    Minimum Required
                </th>
                <td mat-cell *matCellDef="let report" class="w-[35%]">
                    <span class="pr-6 my-2 font-medium text-md break-all">
                        {{report.minimum_required}}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>
                    Value
                </th>
                <td mat-cell *matCellDef="let report" class="w-[35%]">

                    <span class="pr-6 my-2 font-medium text-md break-all">
                        {{report.evidence}}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    Status
                </th>
                <td mat-cell *matCellDef="let sw">
                    <span *ngIf="sw.status" matTooltip="Success">
                        <img src="./assets/images/auditLogins/tick.svg" class="w-4" alt="">
                    </span>
                    <span *ngIf="!sw.status" matTooltip="Failed">
                        <img src="./assets/images/auditLogins/cross.svg" class="w-4" alt="">
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="windowCompatibleTableColumns"></tr>
            <tr class="order-row h-[40px] p-1" mat-row *matRowDef="let row; columns: windowCompatibleTableColumns;">
            </tr>
        </table>
    </div>
    <app-loader [loadingCon]="loadingCon" *ngIf="windowCompatible.length === 0" class="pb-6"></app-loader>
</div>