<div>
  <!-- <div class="flex flex-row justify-between">
    <p class="mb-2 ml-4">{{formElement.label}}</p>
    <mat-icon (click)="addquery()"
    class="icon-size-5 text-current"
    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon></div> -->
  <div class="flex items-center">
    <div class="flex flex-row mr-1">
      <div class="text-xl font-extrabold tracking-tight leading-none">{{formElement.label}}</div>
    </div>
    <button mat-icon-button type="button" (click)="addquery()">
      <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
    </button>
  </div>
  <div class="flex flex-row justify-between gap-2 p-4 mb-3 w-full" *ngFor="let querydata of listofquery;let i = index">
    <mat-form-field class="w-full">
      <mat-label>Rule</mat-label>
      <mat-select id="rule{{i}}" name="rule{{i}}" required [(ngModel)]="querydata.rule"
        (selectionChange)="valuechanged();" matInput>
        <mat-option *ngFor="let option of collection_options" [value]="option.displayName">{{ option.displayName
          }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Attribute</mat-label>
      <mat-select id="attribute{{i}}" name="attribute{{i}}" required [(ngModel)]="querydata.attribute"
        (selectionChange)="ruleadd(querydata, $event, i);" matInput>
        <ng-container *ngFor="let item of collection_options">
          <ng-container *ngIf="item.displayName == querydata.rule">
            <mat-option *ngFor="let option of item.queryfields" [value]="option.field">{{ option.label }}</mat-option>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Condition</mat-label>
      <mat-select id="conditions{{i}}" name="conditions{{i}}" required [(ngModel)]="querydata.conditions"
        (selectionChange)="valuechanged();" matInput>
        <ng-container *ngIf="querydata && querydata.rulenow && querydata.rulenow.type">
          <ng-container *ngFor="let item of querydataoptions">
            <ng-container *ngIf="item.type.includes(querydata.rulenow.type)">
              <mat-option [value]="item.name">{{ item.name }}</mat-option>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Value</mat-label>
      <input [type]="querydata.values" id="values{{i}}" required (ngModelChange)="valuechanged($event)"
        name="values{{i}}" [(ngModel)]="querydata.values" matInput>
    </mat-form-field>

    <button mat-icon-button type="button" (click)="removeQuery(i)">
      <mat-icon [svgIcon]="'heroicons_outline:minus-circle'"></mat-icon>
    </button>
  </div>
</div>