<div [matTooltip]="'Back'" *ngIf="cView !== 'remediated_record'" (click)="viewCategory('remediated_record')"
    class="ml-auto flex items-end justify-end text-primary-900  w-[15vw] justify-center font-bold
                                                 cursor-pointer rounded-full px-2 py-2 ring-primary-400 ring-1 ring-inset mr-2">
    Back To Remediated View</div>

<div class="-mx-4">
    <ng-container *ngIf="cView === 'remediated_record'">
        <s-table *ngIf="remediatedTableOptions && remediatedTableOptions.tableOptions && showTable"
            (apiDataDownload)="apiDataDownload($event)" (refreshCallback)="viewCategory('remediated_record')" 
            (hyperlinkCallback)="linkCall($event)" aria-label="Remediated Record" role="table"
            [sTableOptions]="remediatedTableOptions">
        </s-table>
    </ng-container>
    <ng-container *ngIf="cView === 'asset'">
        <stable [sTableOptions]="asseTableOptions" (hyperlinkCallback)="linkCall($event)"
            *ngIf="currentData && asseTableOptions.tableOptions"></stable>
    </ng-container>
    <ng-container *ngIf="cView === 'company'">
        <stable [sTableOptions]="compTableOptions" (hyperlinkCallback)="linkCall($event)"
            *ngIf="currentData && compTableOptions.tableOptions"></stable>
    </ng-container>
</div>