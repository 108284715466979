<div class="flex-auto -mt-px" *ngIf="cView === 'jobs'">
  <div class="w-full">
    <mat-drawer-container class="flex-auto h-[88vh]">
      <!-- Drawer sm:w-96 -->
      <mat-drawer class="dark:bg-gray-900 z-999" [ngClass]="{ 'w-full lg:w-1/2': drawerOpened }" [autoFocus]="true"
        [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
        <!-- Main -->
        <div class="flex-auto" *ngIf="currentDataCheck">
          <ng-container *ngIf="currentData">
            <div class="relative my-2 flex h-full flex-col rounded-2xl shadow-lg">
              <div class="border-b p-6">
                <div class="flex flex-row flex-wrap items-center">
                  <div class="flex flex-row items-center">
                    <h6 class="text-xl font-semibold">
                      {{ currentData.product_name }}
                    </h6>
                    <p class="ml-2 text-sm font-light">
                      <span *ngIf="currentData.status"
                        class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                        [ngClass]="{
                          'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': currentData.status.toLowerCase() === 'initiated',
                          'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': currentData.status.toLowerCase() === 'running',
                          
                          'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': 
                            currentData.status.toLowerCase() === 'inprogress',
                            'bg-cyan-200 text-cyan-800 dark:bg-cyan-600 dark:text-cyan-50': currentData.status.toLowerCase() === 'scheduled',
                          
                          'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': 
                            currentData.status.toLowerCase() === 'completed' || currentData.status.toLowerCase() === 'success',
                          
                          'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': currentData.status.toLowerCase() === 'failed',
                          
                          'bg-yellow-200 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-50': 
                            currentData.status.toLowerCase() === 'partial',                
                        }">
                        <span class="leading-relaxed whitespace-nowrap">{{
                          currentData.status | enumString
                          }}</span>
                      </span>
                    </p>

                    <div class="flex-none ml-4">
                      <button type="button" 
                              color="primary"
                              mat-icon-button
                              id="fullData"
                              (click)="apiDownload()" 
                              aria-label="download csv menu"
                              matTooltip="Download as CSV"
                              matTooltipPosition="above"
                              class="w-10 h-10 p-0 bg-transparent rounded-md">
                          <mat-icon class="text-black text-3xl">file_download</mat-icon>
                      </button>
                  </div>

                  
                  </div>
                  <!-- (click)="drawer.toggle(); -->
                  <button class="ml-auto" mat-icon-button (click)="closeCurrentData()">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                  </button>
                </div>
              </div>
              <div class="flex-auto h-full p-6">
                <div class="relative flex flex-col justify-center">
                  <div class="flex flex-col items-end" fuseScrollbar>
                    <mat-form-field appearance="fill" class="w-1/2">
                      <mat-label>Search</mat-label>
                      <input type="text" matInput name="Search" [(ngModel)]="searchFilter" />
                    </mat-form-field>
                  </div>
                  <!-- <div class="absolute left-4 h-full border-r-2"></div> -->
                  <table class="text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
                    <tbody
                      *ngFor="let item of (sortPatchJobDetails(currentData.patchJobDetails | search: searchFilter))">
                      <tr *ngIf="item.host_name">
                        <td
                          class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                          Asset Name
                        </td>
                        <td class="border px-4 py-2 break-all" *ngIf="item.host_name">
                          {{ item.host_name }}
                        </td>
                      </tr>

                      <tr *ngIf="item.status">
                        <td
                          class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                          Status
                        </td>
                        <td class="border px-4 py-2 break-all">
                          <span
                            class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                            [ngClass]="{
                                  'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': item.status.toLowerCase() === 'success' || item.status.toLowerCase() === 'completed',
                                  'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': item.status.toLowerCase() === 'failed' || item.status.toLowerCase() === 'terminated',
                                  'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': item.status.toLowerCase() === 'running' || item.status.toLowerCase() === 'pushedtoqueue',
                                  'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': item.status.toLowerCase() === 'initiated',
                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': item.status.toLowerCase() === 'inprogress' || item.status.toLowerCase() === 'pending' || item.status.toLowerCase() === 'partial',
                                  'bg-cyan-200 text-cyan-800 dark:bg-cyan-600 dark:text-cyan-50': item.status.toLowerCase() === 'scheduled'
                                }">
                            <span class="leading-relaxed whitespace-nowrap">{{ item.status | enumString }}</span>
                          </span>
                        </td>
                      </tr>

                      <tr *ngIf="item.from_version">
                        <td
                          class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                          From Version
                        </td>
                        <td class="border px-4 py-2 break-all">
                          {{ item.from_version }}
                        </td>
                      </tr>

                      <tr *ngIf="item.to_version">
                        <td
                          class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                          To Version
                        </td>
                        <td class="border px-4 py-2 break-all">
                          {{ item.to_version }}
                        </td>
                      </tr>

                      <tr *ngIf="item.status_msg">
                        <td
                          class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                          Patch Response
                        </td>
                        <td class="border px-4 py-2 break-all">
                          {{ item.status_msg }}
                        </td>
                      </tr>

                      <tr *ngIf="item.ip_address && item.ip_address.length">
                        <td
                          class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                          IP Address
                        </td>
                        <td class="border px-4 py-2 break-all">
                          <div class="flex flex-wrap gap-1 w-auto pt-1">
                            <show-more-less [items]="item.ip_address" [showAllItems]="false" [type]="'list'"
                              [bgColor]="'bg-yellow-200'" [color]="'text-blue-900'" [maxItemsToShow]="10"
                              [showOrder]="'span'">
                            </show-more-less>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <mat-divider class="my-3"></mat-divider>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                  <!-- <div class="relative mb-4"
                                        *ngFor="let item of currentData.patchJobDetails; trackBy: trackByFn ">
                                        <div class="ml-12 w-auto pt-1">
                                            <h6 class="text-md font-semibold mb-3">
                                                <span *ngIf="item.host_name"
                                                    class="inline-flex font-bold rounded text-md text-gray-900 bg-blue-200 px-1.5 py-0.5 mr-2">Asset
                                                    Name: {{item.host_name}}</span>
                                            </h6>
                                            <p class="text-md font-light mb-2" *ngIf="item.status">
                                                Status:
                                                <span
                                                    class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                    [ngClass]="{
                                                            'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': item.status.toLowerCase() == 'initiated',
                                                            'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': item.status.toLowerCase() === 'started',
                                                            'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': item.status.toLowerCase() === 'inprogress',
                                                            'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': item.status.toLowerCase() === 'completed'
                                                             || item.status.toLowerCase() === 'success',
                                                            'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': item.status.toLowerCase() === 'failed',
                                                        }">
                                                    <span class="leading-relaxed whitespace-nowrap">{{item.status
                                                        |
                                                        enumString}}</span>
                                                </span>
                                            </p>
                                            <p class="mt-1 text-md mb-2" *ngIf="item.from_version">
                                                From Verion: {{item.from_version}}
                                            </p>
                                            <p class="mt-1 text-md mb-2" *ngIf="item.to_version">
                                                To Verion: {{item.to_version}}
                                            </p>

                                            <div class="flex flex-wrap gap-1 w-auto pt-1"
                                                *ngIf="item.ip_address && item.ip_address.length">
                                                <show-more-less [items]="item.ip_address" [showAllItems]="false"
                                                    [type]="'list'" [bgColor]="'bg-yellow-200'"
                                                    [color]="'text-blue-900'" [maxItemsToShow]="10"
                                                    [showOrder]="'span'"></show-more-less>
                                            </div>
                                            <mat-divider class="my-3"></mat-divider>
                                        </div>
                                    </div> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-drawer>
      <!-- Drawer content -->
      <mat-drawer-content class="flex flex-col">
        <div class="w-auto py-4 rounded-2xl">
          <stable aria-label="Scan Job Table" role="table" *ngIf="scanTableOptions"
            (actionCallback)="actionCall($event)" [sTableOptions]="scanTableOptions"
            (hyperlinkCallback)="linkClick($event)"></stable>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
