<div class="flex-auto -mt-px" *ngIf="cView === 'jobs'">
    <div class="w-full h-[88vh]">
        <mat-drawer-container class="flex-auto h-[88vh]">
            <!-- Drawer sm:w-96 -->
            <mat-drawer class="dark:bg-gray-900 z-999" [ngClass]="{'w-full lg:w-3/4': drawerOpened}" [autoFocus]="true"
                [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
                <div class="flex-auto" *ngIf="reportCurrentDataCheck">
                    <div class="flex flex-row items-center pl-4">
                        <div class="text-2xl font-semibold tracking-tight">Report Job Status</div>
                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <ng-container *ngIf="reportCurrentData">
                        <div class="flex-auto h-full p-1">
                            <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="dark:text-white">Report Name</th>
                                            <th class="dark:text-white">Report Type</th>
                                            <th class="dark:text-white">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let report of reportCurrentData|search:searchFilter; trackBy: trackByFn">
                                            <tr>
                                                <td>{{report.displayReportName}}</td>
                                                <td>
                                                    <span
                                                        class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase ng-tns-c2915104180-251 bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50"><span
                                                            class="leading-relaxed whitespace-nowrap">
                                                            {{reportsHash[report.fileType]}}
                                                        </span></span>
                                                </td>
                                                <td class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                    [ngClass]="{
                                                  'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': report.status.toLowerCase() == 'initiated',
                                                  'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': report.status.toLowerCase() === 'started',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': report.status.toLowerCase() === 'inprogress',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': report.status.toLowerCase() === 'in progress',
                                                   'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': report.status.toLowerCase() === 'completed'
                                                   || report.status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': report.status.toLowerCase() === 'failed',
                                                     'bg-slate-200 text-slate-800 dark:bg-slate-600 dark:text-slate-50': report.status.toLowerCase() === 'pushedtoqueue',
                                                 }">{{report.status}}</td>
                                            </tr>
                                        </ng-container>
                                        <div class="ml-6 mt-6" *ngIf="!reportCurrentData || !reportCurrentData.length">
                                            <div> No data available!</div>
                                        </div>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </mat-drawer>
            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col">
                <div class="w-auto py-4 rounded-2xl">
                    <stable aria-label="Scan Job Table" role="table" *ngIf="scanTableOptions"
                        (actionCallback)="actionCall($event)" [sTableOptions]="scanTableOptions"
                        (hyperlinkCallback)="linkClick($event)"></stable>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>