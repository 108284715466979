import { Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable, of, takeUntil } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { StatehandlerService } from './statehandler.service';
import { UserService } from 'app/core/user/user.service';
import { BaseRequestService } from "../_services/base.service";
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AuthenticationService {
    private _authenticated: boolean = false;
    private clientId; private orgId;
    private readonly _authenticationChanged: BehaviorSubject<boolean> =
        new BehaviorSubject(this.authenticated);

    constructor(
        private oauthService: OAuthService,
        private authConfig: AuthConfig,
        private statehandler: StatehandlerService,
        private _bs: BaseRequestService,
        private uService: UserService,
        private router: Router,
    ) {
        this.clientId = localStorage.getItem('_tid');
    }

    public get authenticated(): boolean {
        return this._authenticated;
    }
    updateAuth(state: boolean): void {
        this._authenticated = state;
        if (this._authenticated) {
            var token = localStorage.getItem('zitadel:id_token');
            this.uService.userd$ = of(jwt_decode(token));
            this.uService._user_roles = Object.keys(jwt_decode(token)["urn:zitadel:iam:org:project:roles"]);
        }
    }

    public get authenticationChanged(): Observable<boolean> {
        return this._authenticationChanged;
    }

    public getOIDCUser(): Observable<any> {
        return from(this.oauthService.loadUserProfile());
    }

    updateCID(result: any): void {
        this.clientId = result.message.split('#$#$')[0];
        localStorage.setItem('_tid', result.message.split('#$#$')[0]);
    }

    updateClientID(result: any, isIDP?: boolean): void {
        this.clientId = result.message.client_id;
        this.orgId = result.message.tenant_id;
        localStorage.setItem('_tid', this.clientId);
        localStorage.setItem('_oid', result.message.tenant_id);
        localStorage.setItem('_pid', result.message.pod_id);
        localStorage.setItem('_isIDP', (isIDP) ? 'yes' : 'no');
        localStorage.setItem('_tInfo', btoa(JSON.stringify(result.message)));
        this.authConfig.clientId = this.clientId;
        if (isIDP) {
            this.authConfig.scope += ' urn:zitadel:iam:org:id:' + this.orgId;
        }
    }

    public async authenticate(setState: boolean = true): Promise<boolean> {
        if (!this.clientId) {
            this.router.navigateByUrl('/sign-in');
            this._authenticated = false;
            localStorage.clear();
            sessionStorage.clear();
            return false;
        }
        const _isIDP = localStorage.getItem('_isIDP');
        const _oid = localStorage.getItem('_oid');
        if (environment.isFqdn && _isIDP === 'yes' && _oid && !this.orgId) {
            this.authConfig.scope += ' urn:zitadel:iam:org:id:' + _oid;
        }
        this.authConfig.clientId = this.clientId;
        this.oauthService.configure(this.authConfig);
        this.oauthService.setupAutomaticSilentRefresh();

        this.oauthService.strictDiscoveryDocumentValidation = false;
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();

        this._authenticated = this.oauthService.hasValidAccessToken();
        if (!environment.istest) {
            if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
                const newState = setState ? await this.statehandler.createState().toPromise() : undefined;
                this.oauthService.initCodeFlow(newState);
            }
        }
        if (environment.istest) {
            const newState = setState ? await this.statehandler.createState().toPromise() : undefined;
            this.oauthService.initCodeFlow(newState);
        }
        this._authenticationChanged.next(this.authenticated);
        return this.authenticated;

    }

    public signout(): void {
        const token = JSON.parse(localStorage.getItem('zitadel:id_token'));
        this._bs.doRequest(`/d/user/logout`, 'post', {}).subscribe((res: any) => {
            this.logout();
        }, (error: any) => {
            this.logout();
        });
    }


    logout() {
        const idToken = this.oauthService.getIdToken();  // Fetch ID token from the OAuthService
        this.oauthService.logOut();
        localStorage.clear(); sessionStorage.clear();
        this._authenticated = false;
        const redirect_uri = `${window.location.origin}/sign-in`
        const logoutUrl = `${this._bs.authURL}/oidc/v1/end_session?` +
            `id_token_hint=${idToken}` +
            `&post_logout_redirect_uri=${encodeURIComponent(redirect_uri)}`;

        // Perform the logout by redirecting to the ZITADEL end session URL
        window.location.href = logoutUrl;
    }
    check(): Observable<boolean> {
        if (this.authenticated) {
            return of(true);
        } else {
            this.authenticate();
        }
        return of(false);
    }
}

