import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TableComponent } from "../table/table.component";
import { Subject } from "rxjs";

@Component({
  selector: "app-selinux-settings",
  standalone: true,
  imports: [NgIf, NgFor, TableComponent],
  templateUrl: "./selinux-settings.component.html",
  styleUrls: ["./selinux-settings.component.scss"],
})
export class SelinuxSettingsComponent {
  @Input() asset: any;
  selinuxTableOptions: any = {
    columns: [
      {
        header: "Status",
        columnDef: "status",
        cType: "string",
        isHyperlink: false,
        filter: "",
        cell: "(element: any) => ${element.status}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "FS Mount",
        columnDef: "fs_mount",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.fs_mount}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Root Directory",
        columnDef: "root_directory",
        cType: "string",
        isHyperlink: false,
        filter: "",
        cell: "(element: any) => ${element.root_directory}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Policy Name",
        columnDef: "policy_name",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.policy_name}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Mode",
        columnDef: "mode",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.mode}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Config File Mode",
        columnDef: "config_file_mode",
        cType: "",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.config_file_mode}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "MLS Status",
        columnDef: "mls_status",
        cType: "",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.mls_status}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Deny Unknown Status",
        columnDef: "deny_unknown_status",
        cType: "",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.deny_unknown_status}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Memory Protection Checking",
        columnDef: "memory_protection_checking",
        cType: "",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.memory_protection_checking}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Max Kernel policy Version",
        columnDef: "max_kernel_policy_version",
        cType: "",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.max_kernel_policy_version}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
    ],
    sortOptions: { active: "status", direction: "desc" },
    _pageData: [],
    tableOptions: {
      title: "Selinux Settings",
      isServerSide: false,
      selectText: "firewall",
      loading: true,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showFilter: true,
      showTagFilter: false,
      showhideList: true,
      refreshData: true,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/selinux_settings",
        condition: "",
      },
      id: "selinux_settings",
    },
    changeValue: new Subject<any>(),
  };
  ngOnInit(): void {
    this.selinuxTableOptions.tableOptions.serverSide.condition = ` asset_id='${this.asset.id}'`;
  }
}
