import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit, ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatMenuTrigger } from '@angular/material/menu';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Router, RouterLink } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyToastrService } from 'app/_services/toastr.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/_services/common.service';
import { MatDrawer } from '@angular/material/sidenav';
import { CompanySharedService } from 'app/_services/company-shared.service';
import { CommonModule, DatePipe, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { DynamicComponent } from 'ng-dynamic-component';
import { MaterialModule } from 'app/material.module';
import { PaginatorWidgetComponent } from "../paginator-widget/paginator-widget.component";
import { ChangeDetectorRef } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { TranslocoModule } from '@ngneat/transloco';
import { FilterWidgetComponent } from "../filter-widget/filter-widget.component";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { FuseConfirmationService } from "../../../../@fuse/services/confirmation";
import { FilterComponent } from '../filter/filter.component';
import { fuseAnimations } from '@fuse/animations';
import { FormatCellPipe } from "../../../_filters/app.filter.pipe";
import { DomSanitizer } from "@angular/platform-browser";
import { ShowMoreLessComponent } from '../show-more-less/show-more-less.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalService } from 'app/_services/modal.service';
import { ModalComponent } from '../modal.component';

@Component({
    selector: 'stable',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    imports: [
        CommonModule,
        TranslocoModule,
        AppFilterPipeModule,
        ReactiveFormsModule,
        FormsModule,
        DynamicComponent,
        MaterialModule,
        RouterLink,
        MatAutocompleteModule,
        PaginatorWidgetComponent,
        FilterWidgetComponent,
        FuseScrollbarDirective,
        FilterComponent,
        NgOptimizedImage,
        ShowMoreLessComponent,
        MatPaginatorModule,
        ModalComponent
    ],
    providers: [NgIf, NgFor],
})
export class TableComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    showAllFlag: boolean = false
    sortOptions: any = '';
    // @ViewChild(MatSort, { static: false })
    // set sort(value: MatSort) {
    //     this.dataSource.sort = value;
    // }
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('drawer', { static: true }) drawer: MatDrawer;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    drawerMode: 'over' | 'side' = 'over';
    drawerOpened: boolean = false;
    showFilter: boolean = false;
    selectedchecks: any = 'yes';
    showTagFilter: boolean = false;
    sColumns: any = [];
    private tags: Subscription;
    private subs: Subscription;
    private change: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    manualPageSize: any = 5;
    manualPageIndex: any;
    manualPageLength: any;
    manualPreviousPageIndex: any;
    inputPageNumber: number = 1;
    port_details: any = {};
    selectedRows: any = null;
    tag_details: any[] = [];
    ticket_details: any = {};
    manualTags: any[] = [];

    constructor(
        private activeRoute: Router,
        private loaderService: LoaderService,
        public commonService: CommonService,
        public baseService: BaseRequestService,
        private eRef: ElementRef,
        public toast: MyToastrService,
        public cs: CompanySharedService,
        public cdr: ChangeDetectorRef,
        public confirmDialog: FuseConfirmationService,
        private formatCell: FormatCellPipe,
        public modalService: ModalService,
    ) {
        /*this.loaderService.selectedSiteChanged.subscribe(() => {
            localStorage.removeItem(this.tableOptions.id);
            this.filterText = '';
        });*/
        this.loaderService.tableProgressOff.subscribe(() => {
            this.tableOptions.loading = false;
            this.cdr.detectChanges()
        });
        this.baseService.doRequest(`${this.baseService.configURL}/agentBuildInfo`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.status) {
                    this.latest_agent_version = res.data.agent['agentVersion'];
                }
            });
        // Debounce search.
        this.filterUpdate
            .pipe(debounceTime(1500), distinctUntilChanged())
            .subscribe((value) => {
                this.isActionChanged = true;
                this.doFilter(value);
                this.cdr.markForCheck();
            });
        this.colfilterUpdate
            .pipe(debounceTime(1500), distinctUntilChanged())
            .subscribe((value) => {
                this.doColumFilter(value.value, value.col);
            });
        this.pageSizeUpdate
            .pipe(debounceTime(1500), distinctUntilChanged())
            .subscribe((value) => {
                if (!value) { value = 5 };
                if (typeof value !== 'number') { return; }
                if (value > 100) { value = 100 };
                this.tableOptions.pageSize = value;
                this.paginator.pageSize = value;
                this.sTableOptions.tableOptions.pageSize = value;
                this.paginator._changePageSize(value);
            });
        this.subs = this.commonService.selectedSiteChanged.subscribe((company: any) => {
            const lookup = {};
            (this.showhideList && this.showhideList.length) ? this.showhideList.forEach(item => lookup[item.columnDef] = item.visible) : null;
            this._sTableOptions.columns.forEach((obj: any) => {
                obj.cType = (obj.cType) ? obj.cType : 'string';
                const company_list = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id', 'company_ids'];
                const global_list = ['is_global'];
                if (lookup[obj.columnDef] !== undefined) {
                    obj.visible = lookup[obj.columnDef];
                }
                if ((company_list.indexOf(obj.columnDef) !== -1)) {
                    if (this.commonService.currentScope !== '*') {
                        obj.isNoVisible = true;
                        obj.visible = true;
                    } else {
                        obj.isNoVisible = false;
                    }
                }
                if ((global_list.indexOf(obj.columnDef) !== -1)) {
                    if (this.commonService.currentScope !== '*') {
                        obj.isNoVisible = false;
                    } else {
                        obj.isNoVisible = true;
                        obj.visible = true;
                    }
                }
            });
            this.showhideList = [];
            if (this.sTableOptions.tableOptions.isNotSiteChange) {
                return;
            }
            this.currentPageIndex = 0;
            this.getCollectionData();
            // this.initUpdateData();
        });
        this.tags = this.commonService.selectedTagChange.subscribe((tags: any) => {
            this.tagList = tags;
            if (tags && tags.api) {
                this.currentPageIndex = 0;
            }
            this.getCollectionData();
        });
        this.commonService.tableFilterCalBack
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.value) {
                    this.tableFilterCal(res.value);
                }
            })
    }
    lastSelectedRow: any = 0;
    public assetDrpCtrl: FormControl = new FormControl();
    public assetDrpFilterCtrl: FormControl = new FormControl();
    public filterDrpLstFilteredList: ReplaySubject<any> =
        new ReplaySubject<any>(1);
    @ViewChild('filterMenuTrigger', { static: true })
    filterMenuTrigger!: MatMenuTrigger;
    @ViewChild('listMenuTrigger', { static: true })
    listMenuTrigger!: MatMenuTrigger;
    @ViewChild('exportMenuTrigger', { static: true })
    exportMenuTrigger!: MatMenuTrigger;
    filterText!: string;
    currentPageIndex: any;
    isExport = false;
    tableId: any;
    // @ts-ignore
    //@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('paginator', { static: true }) paginatorWidget: PaginatorWidgetComponent;
    // tslint:disable-next-line:variable-name
    _sTableOptions: any;
    isLoading = true;
    isLoading1 = false;
    dataSource = new MatTableDataSource<any>();
    hiddenDataSource = new MatTableDataSource<any>();
    Objectkeys = Object.keys;
    @ViewChild('MAINTABLEDIV', { static: true })
    tableDiv!: ElementRef;
    @Input() loadtable: any;
    @Input() sTableOptions: any;
    @Input() hideDownload: any;
    @Output() filterCallback = new EventEmitter();
    @Output() manualfilterCallbackduplicate = new EventEmitter<{ selectedValue: string, viewcheck: any }>();
    @Output() colFilterCallback = new EventEmitter();
    @Output() publishCallback = new EventEmitter();
    @Output() sortCallback = new EventEmitter();
    @Output() actionCallback = new EventEmitter();
    @Output() globalActionCallback = new EventEmitter();
    @Output() pageCallback = new EventEmitter();
    @Output() pageCallbackTable = new EventEmitter();
    @Output() refreshCallback = new EventEmitter();
    @Output() hyperlinkCallback = new EventEmitter();
    @Output() addCallback = new EventEmitter();
    @Output() compareCallback = new EventEmitter();
    @Output() timerCallback = new EventEmitter();
    @Output() fontSizeCallback = new EventEmitter();
    @Output() checkBoxCallback = new EventEmitter();
    @Output() selectionchangeCallback = new EventEmitter();
    @Output() parentCallback = new EventEmitter();
    @Output() totalCallback = new EventEmitter();
    @Output() apiDataDownload = new EventEmitter();
    @Output() rowClickCallback = new EventEmitter();
    @Output() showmoredataCallback = new EventEmitter();
    tagList: any = '';
    colFilters: any = [];
    filterValues!: string;
    filterArray: any = [];
    selectedTimer = '0';
    selectedFont: string = "small";
    fontSizeClass = ''
    fontSizeClassHed = "text-sm"
    intervalId: any;
    called = false;
    cFilter: any = {};
    colHash: any = {};
    colFilterQuery: any;
    filterQuery: any;
    private tmpOption: any;
    columnsList: string[] = [];
    cpIndex = 0;
    latest_agent_version: string = '';
    tableOptions: any = {
        id: 'imaws',
        title: '',
        isServerSide: true,
        selectText: 'item(s)',
        floatingFilter: true,
        rowSelection: true,
        loading: true,
        showAction: false,
        actionMenuItems: [
            {
                text: 'Details',
                icon: 'info',
                callback: 'editFunction',
                hideLocal: false,
                isGlobal: false,
                actionConditions: { key: '' },
            },
        ],
        isActionCondition: false,
        actionConditionsList: {},
        pagination: true,
        pageOptions: [5, 10, 20, 30, 50, 100, 200],
        pageSize: 5,
        pageTotal: 0,
        search: true,
        showhideList: true,
        refreshData: true,
        fontsizeData: true,
        dropdwn: false,
        add: false,
        addText: '',
        showColFilter: false,
        exportExcel: true,
        compareData: false,
        publish: false,
        parentCalls: [],
        dataMapping: [],
        saveData: false,
        filterDownload: true,
        changeValue: new Subject<any>(),
        clipboard: true,
    };

    isMUltiple = true;
    initSelectedValues = [];
    selection = new SelectionModel<any>(
        this.isMUltiple,
        this.initSelectedValues
    );
    numSelected!: number;

    isCompMUltiple = true;
    initCompSelectedValues = [];
    compSelection = new SelectionModel<any>(
        this.isMUltiple,
        this.initCompSelectedValues
    );
    compNumSelected!: number;

    columns = [
        {
            columnDef: 'name',
            header: 'Name',
            cell: (element: any) => `${element.name}`,
        },
        {
            columnDef: 'dateOfBirth',
            header: 'Date of Birth',
            filter: 'date',
            cell: (element: any) => `${element.dateOfBirth}`,
        },
        {
            columnDef: 'address',
            header: 'Address',
            cell: (element: any) => `${element.address}`,
        },
    ];

    public displayedColumns!: Array<any>;
    public showhideList!: Array<any>;
    orderlist!: Array<any>;
    originalTableOptions: any = {};
    filterUpdate = new Subject<string>();
    colfilterUpdate = new Subject<any>();
    pageSizeUpdate = new Subject<any>();
    settingsObj = [];
    sessionData: any;
    searchedColName = '';
    colfilter: any = {};
    pastIndex: any;
    isPageLoad = true;
    selectedRow: any;

    isActionChanged = false;
    isTablePadding = false;
    columnsCnt = 0;
    exportCnt = 0;
    isExportClick = false;

    hasSelect = false;
    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (this.eRef.nativeElement.contains(event.target)) {
            if (this.isExportClick) {
                if (this.exportCnt > 1) {
                    if (this.exportMenuTrigger !== undefined) {
                        this.exportMenuTrigger.closeMenu();
                    }
                    this.exportCnt = 1;
                    this.isExportClick = false;
                } else {
                    if (this.listMenuTrigger !== undefined) {
                        this.listMenuTrigger.closeMenu();
                    }
                    this.exportCnt++;
                }
            } else {
                if (this.columnsCnt > 1) {
                    if (this.listMenuTrigger !== undefined) {
                        this.listMenuTrigger.closeMenu();
                    }
                    this.columnsCnt = 1;
                } else {
                    if (this.exportMenuTrigger !== undefined) {
                        this.exportMenuTrigger.closeMenu();
                    }
                    this.columnsCnt++;
                }
            }
        } else {
            this.columnsCnt = 0;
            if (this.listMenuTrigger !== undefined) {
                this.listMenuTrigger.closeMenu();
            }
        }
    }
    columnFilterxlsx(col: any): void {
        this.searchedColName = col.col;
        this.colFilters.push({
            key: col.col,
            name: col.header,
            value: col.val,
            hKey: col.colFilters.hKey,
        });
        this.processColFilterQuery();
    }
    removeFilterxlsx(rFilter: any): void {
        if (this.sTableOptions.gFilter) {
            delete this.sTableOptions.gFilter;
            this.colFilters = [];
            this.colFilterQuery = [];
            this.filterQuery = {};
            this.getCollectionData();
            return;
        }
        this.colFilters.forEach((obj: { key: string | number }, index: any) => {
            if (rFilter.key === obj.key) {
                this.colfilter[obj.key] = '';
                this.colFilters.splice(index, 1);
            }
        });
        this.processColFilterQuery();
    }
    processColFilterQuery(): void {
        this.colFilterQuery = [];
        for (const cl of this.colFilters) {
            const tmpObj1: any = { bool: { should: [{ match: {} }] } };
            let tmpObj: any = {};
            if (cl.hKey) {
                tmpObj = {
                    bool: {
                        should: [
                            {
                                query_string: {
                                    fields: [cl.key],
                                    query: `*${cl.value}*`,
                                },
                            },
                        ],
                    },
                };
            } else {
                tmpObj1.bool.should[0].match[cl.key] = cl.value;
                tmpObj = tmpObj1;
            }
            this.colFilterQuery.push(tmpObj);
        }
        this.getCollectionData();
    }
    apiDownload(type: any): void {
        const file_name = `${this.changeHeader(this.tableOptions.title)}.csv`;
        this.apiDataDownload.emit({ value: file_name });
    }
    downloadAsXls(type: string): void {
        if (!this.sTableOptions.tableOptions.pageTotal) {
            this.toast.sToast('error', 'No data available!');
            return;
        }
        const _sTableOptionsC = cloneDeep(this.sTableOptions);
        let skip = 0;
        let limit = (_sTableOptionsC.tableOptions.downloadlimit) ? this.tableOptions.pageTotal : _sTableOptionsC.tableOptions.pageSize;
        if (this.currentPageIndex) {
            skip = this.currentPageIndex * this.tableOptions.pageSize;
            limit = this.tableOptions.pageSize;
        }
        let order_by = `${this.convertToPostgresSyntax(_sTableOptionsC.sortOptions.active)} ${_sTableOptionsC.sortOptions.direction}`
        let condition: any = { condition: true, skip, limit, order_by };
        if (this.commonService.currentScope !== '*' && !_sTableOptionsC.tableOptions.serverSide.isGlobal && _sTableOptionsC.tableOptions.serverSide.type !== 'post') {
            condition.condition = "company_id=" + parseInt(this.commonService.currentScope.id) + "";
        }

        if (_sTableOptionsC.tableOptions.serverSide.condition && this.commonService.currentScope === '*') {
            if (_sTableOptionsC.tableOptions.customPagination) {
                const ids = _sTableOptionsC.tableOptions.customPagination.value;
                if (ids.length === 1) {
                    condition.condition = _sTableOptionsC.tableOptions.serverSide.condition + ' and ' + `id = ${ids[0]}`;
                } else if (ids.length > 1) {
                    condition.condition = _sTableOptionsC.tableOptions.serverSide.condition + ' and ' + `id IN (${ids.join(',')})`;
                }
            } else {
                condition.condition = _sTableOptionsC.tableOptions.serverSide.condition;
            }

        } else if (_sTableOptionsC.tableOptions.serverSide.condition) {

            if (condition.condition === true) {
                if (_sTableOptionsC.tableOptions.customPagination) {
                    const ids = _sTableOptionsC.tableOptions.customPagination.value;
                    if (ids.length === 1) {
                        condition.condition = _sTableOptionsC.tableOptions.serverSide.condition + ' and ' + `id = ${ids[0]}`;
                    } else if (ids.length > 1) {
                        condition.condition = _sTableOptionsC.tableOptions.serverSide.condition + ' and ' + `id IN (${ids.join(',')})`;
                    }
                } else {
                    condition.condition = _sTableOptionsC.tableOptions.serverSide.condition;
                }

            } else {
                if (_sTableOptionsC.tableOptions.customPagination) {
                    const ids = _sTableOptionsC.tableOptions.customPagination.value;
                    if (ids.length === 1) {
                        condition.condition += ' and ' + _sTableOptionsC.tableOptions.serverSide.condition + ' and ' + `id = ${ids[0]}`;
                    } else if (ids.length > 1) {
                        condition.condition += ' and ' + _sTableOptionsC.tableOptions.serverSide.condition + ' and ' + `id IN (${ids.join(',')})`;
                    }
                } else {
                    condition.condition += ' and ' + _sTableOptionsC.tableOptions.serverSide.condition;
                }
            }
        }

        if (this.filterQuery && this.filterQuery.length) {
            if (condition.condition === true) {
                condition.condition = this.filterQuery;
            } else {
                if (this.filterQuery.toLocaleLowerCase().indexOf('and') > -1 || this.filterQuery.toLocaleLowerCase().indexOf('or') > -1) {
                    condition.condition += ` and (${this.filterQuery})`;
                } else {
                    condition.condition += ` and ${this.filterQuery}`;
                }
            }
        }
        if (this.filterText && this.filterText.trim()) {
            const colLen = _sTableOptionsC.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch);
            let sColumns: any = [];
            if (_sTableOptionsC.sColumns) {
                sColumns = _sTableOptionsC.sColumns.filter((y: any) => !y.gloNoSearch).map((x: any) => x.columnDef)
            }
            const columns: any = [...sColumns, ..._sTableOptionsC.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef)].join(", ' ', ");
            const columnsF: any = [...sColumns, ..._sTableOptionsC.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef)];
            const contact = columnsF.map((item: any) => `${item} ILIKE '%${this.filterText.trim()}%'`).join(' OR ');
            if (condition.condition === true) {
                // condition.condition = (colLen.length > 1) ? `concat(${columns}) ILIKE '%${this.filterText.trim()}%'` : `${columns} ILIKE '%${this.filterText.trim()}%'`;
                condition.condition = (colLen.length > 1) ? `(${contact})` : `${columns} ILIKE '%${this.filterText.trim()}%'`;
            } else {
                // condition.condition += (colLen.length > 1) ? ` and concat(${columns}) ILIKE '%${this.filterText.trim()}%'` : ` and ${columns} ILIKE '%${this.filterText.trim()}%'`;
                condition.condition += (colLen.length > 1) ? ` and (${contact})` : ` and ${columns} ILIKE '%${this.filterText.trim()}%'`;
            }
        }
        if (this.selection.selected.length) {
            const columns = this.selection.selected.map((x: any) => x.id);
            if (condition.condition === true) {
                if (columns.length > 1) {
                    condition.condition = `id IN (${columns.join(",")})`
                } else {
                    condition.condition = `id=${columns.join(",")}`
                }
            } else {
                if (columns.length > 1) {
                    condition.condition += ` and id IN (${columns.join(",")})`
                } else {
                    condition.condition += ` and id=${columns.join(",")}`
                }

            }
        }
        if (this.tagList.api) {
            const url = `${_sTableOptionsC.tableOptions.serverSide.url}`;
            if (url.indexOf('/r/reports') > -1) {
                _sTableOptionsC.tableOptions.serverSide.params = { ..._sTableOptionsC.tableOptions.serverSide.params, ...this.tagList.report }
            } else {
                if (condition.condition === true) {
                    condition.condition = `${this.tagList.api}`;
                } else {
                    condition.condition += ` and (${this.tagList.api})`;
                }
            }
        } else {
            delete _sTableOptionsC.tableOptions.serverSide?.params?.tags_key_value;
            delete _sTableOptionsC.tableOptions.serverSide?.params?.manual_tags_key_value;
        }
        if (_sTableOptionsC.tableOptions.serverSide.type === 'post') {
            const con = Object.assign({}, _sTableOptionsC.tableOptions.serverSide.params);
            delete con.name;
            if (this.commonService.currentScope?.id && !_sTableOptionsC.tableOptions.serverSide.isGlobal) {
                _sTableOptionsC.tableOptions.serverSide.params.company_id = parseInt(this.commonService.currentScope.id);
            }
            _sTableOptionsC.tableOptions.serverSide.params.skip = skip;
            _sTableOptionsC.tableOptions.serverSide.params.limit = (_sTableOptionsC.tableOptions.downloadlimit) ? this.tableOptions.pageTotal : limit;
            _sTableOptionsC.tableOptions.serverSide.params.order_by = order_by;
            if (this.tagList.api) {
                const url = `${_sTableOptionsC.tableOptions.serverSide.url}`
                if (url.indexOf('/r/reports') > -1) {
                    _sTableOptionsC.tableOptions.serverSide.params = { ..._sTableOptionsC.tableOptions.serverSide.params, ...this.tagList.report };
                } else {
                    if (_sTableOptionsC.tableOptions.serverSide.params.condition === true) {
                        _sTableOptionsC.tableOptions.serverSide.params.condition = `${this.tagList.api}`;
                    } else {
                        _sTableOptionsC.tableOptions.serverSide.params.condition += ` and (${this.tagList.api})`;
                    }
                }
            } else {
                delete _sTableOptionsC.tableOptions.serverSide?.params?.tags_key_value;
                delete _sTableOptionsC.tableOptions.serverSide?.params?.manual_tags_key_value;
            }
            if (condition.condition) {
                _sTableOptionsC.tableOptions.serverSide.params.condition = condition.condition; // + this.commonService.objectToQueryString(con);
            }
            let url = `${_sTableOptionsC.tableOptions.serverSide.url}` // /${_sTableOptionsC.tableOptions.serverSide.params.name}

            _sTableOptionsC.tableOptions.serverSide.params.file_name = `${this.changeHeader(this.tableOptions.title)}.csv`;
            _sTableOptionsC.tableOptions.serverSide.params.fieldmap = [];
            for (const col of _sTableOptionsC.columns) {
                if ((col.visible && !col.isNoVisible) || col.isaddExcel) {
                    if (col.columnDef === 'ticket_ids' && col.header === 'Ticket ID') {
                        continue;
                    }

                    if (col.columnDef === 'fqdn_name') { col.columnDef = 'host_name' };
                    if (col.header === 'Confirmed') { col.header = 'Unconfirmed' };
                    // @ts-ignore
                    const field = {}; field[col.columnDef] = col.header;
                    _sTableOptionsC.tableOptions.serverSide.params.fieldmap.push(field);
                }
            }
            if (this.tagList.api && this.sTableOptions.tableOptions.serverSide.customTagFilterApi) {
                url = `${this.sTableOptions.tableOptions.serverSide.customTagFilterApi}`
            }
            let request;
            if (_sTableOptionsC.tableOptions.serverSide.method === 'post') {
                const requestBody = {
                    params: {
                        condition: _sTableOptionsC.tableOptions.serverSide.condition,
                        skip: _sTableOptionsC.tableOptions.serverSide.params.skip,
                        limit: (_sTableOptionsC.tableOptions.downloadlimit) ? this.tableOptions.pageTotal : _sTableOptionsC.tableOptions.serverSide.params.limit,
                        order_by: _sTableOptionsC.tableOptions.serverSide.params.order_by,
                        fieldmap: _sTableOptionsC.tableOptions.serverSide.params.fieldmap,
                        file_name: _sTableOptionsC.tableOptions.serverSide.params.file_name
                    },
                    query_name: _sTableOptionsC.tableOptions.serverSide.query_name
                };
                request = this.baseService.doRequest(url, 'post', requestBody);
            } else {
                request = this.baseService.doRequest(url, 'get', null, _sTableOptionsC.tableOptions.serverSide.params);
            }
            request.pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        window.open(result.message, '_blank');
                        this.RefreshTableData();
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                    this.cdr.detectChanges();
                });
        } else {
            condition.file_name = `${this.changeHeader(this.tableOptions.title)}.csv`;
            condition.fieldmap = [];
            for (const col of _sTableOptionsC.columns) {
                if ((col.visible && !col.isNoVisible) || col.isaddExcel) {
                    if (col.columnDef === 'ticket_ids' && col.header === 'Ticket ID') {
                        continue;
                    }
                    if (col.columnDef === 'fqdn_name') { col.columnDef = 'host_name' };
                    if (col.header === 'Confirmed') { col.header = 'Unconfirmed' };
                    // @ts-ignore
                    const field = {};
                    field[col.columnDef] = col.header;
                    condition.fieldmap.push(field);
                }
            }
            let url = `${_sTableOptionsC.tableOptions.serverSide.url}`
            if (this.tagList.api && this.sTableOptions.tableOptions.serverSide.customTagFilterApi) {
                url = `${this.sTableOptions.tableOptions.serverSide.customTagFilterApi}`
            }
            let request;
            if (this.sTableOptions.tableOptions.serverSide.method === 'post') {
                const requestBody = {
                    params: {
                        condition: condition.condition,
                        skip: condition.skip,
                        limit: (_sTableOptionsC.tableOptions.downloadlimit) ? this.tableOptions.pageTotal : condition.limit,
                        order_by: condition.order_by,
                        fieldmap: condition.fieldmap,
                        file_name: condition.file_name
                    },
                    query_name: this.sTableOptions.tableOptions.serverSide.query_name
                };
                request = this.baseService.doRequest(url, 'post', requestBody);
            } else {
                request = this.baseService.doRequest(url, 'get', null, condition);
            }
            request.pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        window.open(result.message, '_blank');
                        this.RefreshTableData();
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                    this.cdr.detectChanges();
                });
        }
    }

    columnFilter(val: any, col: any): void {
        this.searchedColName = col;
        this.colfilterUpdate.next({ value: val, col });
    }
    removeFilter(filter: any): void {
        this.colFilters.forEach((obj: { key: string | number }, index: any) => {
            if (filter.key === obj.key) {
                this.colfilter[obj.key] = '';
                this.colFilters.splice(index, 1);
            }
        });
        this.colFilterCallback.emit({ value: '', col: filter.key });
    }
    getProperty(object: any, propertyName: any): any {
        let parts: any[];
        let property: string;
        parts = propertyName.split('.');
        const length = parts.length;
        let i: number;
        property = object;
        for (i = 0; i < length; i++) {
            // @ts-ignore
            try {
                property = property[parts[i]];
            } catch (e) {
                return property;
            }
        }
        if (property === undefined || property === null) {
            const defaultValue = this._sTableOptions.columns.filter((x: any) => x.columnDef === propertyName);
            if (defaultValue && defaultValue.length) {
                property = (defaultValue[0].cType && defaultValue[0].cType === 'number') ? '0' : null;
            }
        }
        return property;
    }
    getPropertyCondition(object: any, propertyName: any): any {
        let parts: any[]; let property: string;
        parts = propertyName.split('.'); const length = parts.length;
        let i: number;
        property = object;
        for (i = 0; i < length; i++) {
            // @ts-ignore
            property = property[parts[i]];
        }
        return String(property);
    }
    rowClick(row: any, index: any): void {
        if (row.id) {
            this.dataSource.data.forEach((obj) => {
                obj.highlighted = obj.id === row.id;
            });
        } else {
            if (this.pastIndex !== undefined) {
                this.dataSource.data[this.pastIndex].highlighted = false;
            }
            this.pastIndex = index;
            this.dataSource.data[index].highlighted =
                !this.dataSource.data[index].highlighted;
        }
        this.rowClickCallback.emit(row);
    }

    setFilterRules($event: any): void {
        this.filterQuery = $event.query;
        this.currentPageIndex = 0;
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.getCollectionData(); return;
        }
        if (this.sTableOptions.tableOptions.filterEmitCal) {
            // @ts-ignore
            this.commonService[this.sTableOptions.tableOptions.filterEmitCal].next({ value: $event.rule });
            return;
        }
    }

    toggleFilterRules($event: any): void {
        this.showFilter = !this.showFilter;
        this.filterQuery = $event.query;
        this.currentPageIndex = 0;
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.getCollectionData(); return;
        }
        if (this.sTableOptions.tableOptions.filterEmitCal) {
            // @ts-ignore
            this.commonService[this.sTableOptions.tableOptions.filterEmitCal].next({ value: $event.rule });
            return;
        }
    }

    setTagFilterRules($event: any): void {
        if (this._sTableOptions.tableOptions.isDefaultTagFilter) {
            this.filterCallback.emit($event);
            return;
        }
        this.tagList = $event;
        this.currentPageIndex = 0;
        this.getCollectionData();
    }

    toggleTagFilterRules($event: any): void {
        if (this._sTableOptions.tableOptions.isDefaultTagFilter) {
            this.filterCallback.emit($event);
            return;
        }
        if (this._sTableOptions.pageData && this._sTableOptions.pageData.length) {
            this.showTagFilter = !this.showTagFilter;
            this.tagList = $event;
            this.currentPageIndex = 0;
            this.getCollectionData();
        }
    }

    FilterTagTableData(): void {
        if ((this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) || this.sTableOptions.tableOptions.isDefaultTagFilter) {
            this.showTagFilter = !this.showTagFilter;
        }
        // this.filterCallback.emit({});
    }
    /*getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
    )*/

    getCustomText(obj: any, key?: any): any {
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return '';
        }
        // @ts-ignore
        const comT = this.sTableOptions.customText.filter((x: any) => x.name === key);
        if (key && comT && comT.length) {
            const customText = comT[0].customText.filter(
                (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
            );
            if (customText.length > 0) {
                return customText[0].DisplayText;
            }
        } else {
            const customText = this.sTableOptions.customText.filter(
                (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
            );
            if (customText.length > 0) {
                return customText[0].DisplayText;
            }
        }

    }

    getCustomColor(obj: any): any {
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return '';
        }
        const customText = this.sTableOptions.customText.filter(
            (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
        );
        if (customText.length > 0) {
            return customText[0]['text-color']
                ? {
                    'background-color': customText[0].color,
                    color: customText[0]['text-color'],
                }
                : { 'background-color': customText[0].color };
        }
        return { 'background-color': '#ffffff' };
    }

    getCustomClass(obj: any, key?: any): any {
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return '';
        }
        const comT = this.sTableOptions.customText.filter((x: any) => x.name === key);
        if (key && comT && comT.length) {
            const customText = comT[0].customText.filter(
                (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
            );
            if (customText.length > 0) {
                return customText[0].class;
            }
        } else {
            const customText = this.sTableOptions.customText.filter(
                (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
            );
            if (customText.length > 0) {
                return customText[0].class;
            }
        }

        return 'bg-indigo-100 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50';
    }
    getRangeColor(obj: any, key: any): any {
        let style: any = { 'background-color': '#000000', 'color': '#c2ffc4' };
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return style;
        }
        const comT = this.sTableOptions.customText.filter((x: any) => x.name === key);
        if (comT && comT.length) {
            comT[0].customText.forEach((r: any) => {
                if (r.range && r.range.from <= obj && r.range && r.range.to >= obj) {
                    style = { 'background-color': r.color, color: r['text-color'] };
                }
            });
        } else {
            this.sTableOptions.customText.forEach((r: any) => {
                if (r.range && r.range.from <= obj && r.range && r.range.to >= obj) {
                    style = { 'background-color': r.color, color: r['text-color'] };
                }
            });
        }
        return style;
    }

    getIconPath(obj: any, path: any): any {
        if (path.condition) {
            if (path.condition(this.getProperty(obj, path.columnDef))) {
                return path.successIconPath;
            } else {
                return path.failureIconPath;
            }
        }
        if (this.getProperty(obj, path.columnDef) === path.success) {
            return path.successIconPath !== undefined
                ? path.successIconPath
                : 'assets/images/devices/online.svg';
        } else if (this.getProperty(obj, path.columnDef) === null) {
            return 'assets/images/devices/not_valid.svg';
        } else {
            return path.failureIconPath !== undefined
                ? path.failureIconPath
                : 'assets/images/devices/offline.svg';
        }
    }

    getIconTooltip(obj: any, path: any): any {
        if (path.condition) {
            if (path.condition(this.getProperty(obj, path.columnDef))) {
                return path.successToolTip;
            } else {
                return path.failureToolTip;
            }
        }
        if (this.getProperty(obj, path.columnDef) === path.success) {
            return path.successToolTip !== undefined
                ? path.successToolTip
                : 'Online';
        } else if (this.getProperty(obj, path.columnDef) === null) {
            return path.alertToolTip !== undefined ? path.alertToolTip : 'undefined';
        } else {
            return path.failureToolTip !== undefined
                ? path.failureToolTip
                : 'Offline';
        }
    }

    drop(event: CdkDragDrop<string[]>): any {
        this.isActionChanged = true;
        moveItemInArray(this.showhideList, event.previousIndex, event.currentIndex);

        this.showhideList[event.previousIndex].order = event.previousIndex;
        this.showhideList[event.currentIndex].order = event.currentIndex;

        this.showhideList.forEach((column, idx) => {
            column.order = idx;
        });
        this.initUpdateData();
        // this._sTableOptions.columns[event.previousIndex].order = event.previousIndex;
        // this._sTableOptions.columns[event.currentIndex].order = event.currentIndex;
        // this.setTableOptions(this._sTableOptions.tableOptions);
    }

    actionCall(row: any, action: any): void {
        delete row.highlighted;
        delete row.hovered;
        const resp = { row, action };
        if (
            this.tableOptions.showAction &&
            this.tableOptions.actionMenuItems.length > 0
        ) {
            if (this.tableOptions.serverSide) {
                if (action.id === 'delete' && this.tableOptions.serverSide.dUrl) { this.deleteRecord(row); } else {
                    this.actionCallback.emit(resp);
                }
            } else {
                this.actionCallback.emit(resp);
            }
        }
    }

    deleteRecord(data: any) {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to delete ?`,
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.baseService.doRequest(`${this.tableOptions.serverSide.dUrl}/${data.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result.status) {
                            this.toast.sToast('success', 'Removed successfully');
                            setTimeout(() => {
                                this.getCollectionData();
                            }, 3000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    globalActionCall(row: any, action: any): void {
        delete row.highlighted;
        delete row.hovered;
        const resp = { row, action };
        this.globalActionCallback.emit(resp);
        /*if (
            this.tableOptions.showAction &&
            this.tableOptions.actionMenuItems.length > 0
        ) {
            this.globalActionCallback.emit(resp);
        }*/
    }

    hyperLinkCall(row: any, col?: any): any {
        delete row.highlighted;
        delete row.hovered;
        const event = { row, col };
        this.hyperlinkCallback.emit(event);
    }

    showmoredataCall(data: any): void {
        this.showmoredataCallback.emit(data);
    }

    ngOnChanges(changes: SimpleChanges): any {
        const sTableOptions: SimpleChange = changes.sTableOptions;
        this._sTableOptions = sTableOptions?.currentValue;
        // if (!sTableOptions.firstChange) {
        this.isLoading = false
        // this.initUpdateData();
        // }
    }

    ngOnInit(): void {
        this.sTableOptions.columns.forEach((obj: any, index: any) => {
            obj.order = index;
            obj.cType = (obj.cType) ? obj.cType : 'string';
            const company = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id', 'company_ids'];
            const global = ['is_global'];
            if ((company.indexOf(obj.columnDef) !== -1)) {
                obj.visible = true;
                if (this.commonService.currentScope !== '*') {
                    obj.isNoVisible = true;
                } else {
                    obj.isNoVisible = false;
                }
            }
            if ((global.indexOf(obj.columnDef) !== -1)) {
                obj.visible = true;
                if (this.commonService.currentScope !== '*') {
                    obj.isNoVisible = false;
                } else {
                    obj.isNoVisible = true;
                }
            }
        });
        this.originalTableOptions = cloneDeep(this.sTableOptions);
        if (!this.sTableOptions.tableOptions.id) {
            this.sTableOptions.tableOptions.id = this.changeHeader(this.sTableOptions.tableOptions?.title);
        }
        if (this.sTableOptions && this.sTableOptions.changeValue) {
            this.change = this.sTableOptions.changeValue.subscribe((v: any) => {
                this.sTableOptions.pageData = [];
                this.sTableOptions.tableOptions.pageTotal = 0;
                this.currentPageIndex = 0;
                this.getCollectionData();
            });
        }
        this.selectedchecks = null;
        setTimeout(() => {
            this.selectedchecks = (this.sTableOptions.tableOptions && this.sTableOptions.tableOptions.selectedchecks) ? this.sTableOptions.tableOptions.selectedchecks : 'Yes';
            this.cdr.detectChanges();
        }, 100);
        this.sTableOptions.tableOptions.loading = true;
        this.manualPageLength = this.sTableOptions.tableOptions.pageTotal;
        if (this.sTableOptions.tableOptions.pageTotal) { this.sTableOptions.tableOptions.loading = false; }
        this.tmpOption = { ...this.sTableOptions };
        if (this.baseService.Tabelsetting().font) {
            this.selectedFont = this.baseService.Tabelsetting().font
            this.textSizeCallData();
        }
        if (this.baseService.Tabelsetting().page_size && !this.sTableOptions.tableOptions.customPageSize && !this.sTableOptions.tableOptions.isSettingCalled) {
            this._sTableOptions.tableOptions.pageSize = this.baseService.Tabelsetting().page_size
            this.tableOptions.pageSize = this.baseService.Tabelsetting().page_size
        }
        if (this._sTableOptions.tableOptions.isDefaultSearch && this._sTableOptions.tableOptions.isDefaultKeySearch) {
            const columns = this.sTableOptions.columns.filter((x: any) => (x.visible && x.cType !== 'agg' && !x.isNoVisible) || x.isCustomSearch).map((i: any) => i.columnDef);
            setTimeout(() => {
                this.dataSource.filterPredicate = function (data, filter: string): boolean {
                    return columns.some((column: any) => JSON.stringify(data[column])?.toLowerCase()?.includes(filter));
                    // return data.name.toLowerCase().includes(filter) || data.symbol.toLowerCase().includes(filter) || data.position.toString() === filter;
                };
            }, 1000);
        }
    }

    ngOnDestroy(): void {
        this.isPageLoad = false;
        if (this.intervalId !== undefined && this.intervalId !== null) {
            clearInterval(this.intervalId);
        }
        if (this.subs) { this.subs.unsubscribe(); }
        if (this.change) { this.change.unsubscribe(); }
        if (this.tags) { this.tags.unsubscribe(); }
        this.filterText = undefined;
        localStorage.removeItem(this.tableOptions.id);
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    initUpdateData(): void {

        this.tableOptions = Object.assign(this.tableOptions, this._sTableOptions.tableOptions);
        this.isPageLoad = this.sTableOptions.overridePageLoad
            ? false
            : this.isPageLoad;
        const filterK = localStorage.getItem(this.sTableOptions.tableOptions.id);
        if (filterK && filterK !== '') {
            this.filterText = filterK;
            this.cdr.detectChanges();
            if (!this.called) {
                setTimeout(() => {
                    localStorage.removeItem(this.sTableOptions.tableOptions.id);
                    this.doFilter(this.filterText);
                    this.cdr.detectChanges();
                    this.called = true;
                }, 2000);
            }
        }
        if ((this.isPageLoad && !this.tableOptions.pageTotal) || this.loadtable) {
            if (this.sTableOptions.tableOptions.isSettingCalled && this.sTableOptions.tableOptions.id !== 'assets') {
                if (this.isPageLoad) {
                    this.isPageLoad = false;
                }
                let filterColumn = this.sTableOptions.columns.filter((x: any) => x.visible && x.cType !== 'number' && x.cType !== 'agg');
                if (this.sTableOptions.sColumns) {
                    filterColumn = [...this.sTableOptions.sColumns, ...filterColumn];
                }
                this.sColumns = filterColumn.slice();
                this.getCollectionData(); return;
            }
            this.loaderService.display(true);
            let sub_category = this.sTableOptions.tableOptions.id;
            if (this.sTableOptions.tableOptions.id === 'assets') {
                sub_category = `global_${this.sTableOptions.tableOptions.id}`;
            }
            const params: any = {
                condition: `category='table_settings' and value IS NOT NULL and sub_category='${sub_category}' and name='${this.baseService.user().email}'`
            }
            this.baseService.doRequest(`/r/company/settings`, 'get', null, params)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    this.sTableOptions.tableOptions.isSettingCalled = true;
                    if (result.status && result.data && result.data.length) {
                        this.sessionData = result.data[0];
                        try {
                            const savedCols = JSON.parse(result.data[0].value.columnRepr);
                            const savedColsList = savedCols.filter((s: any) => !s.isNoVisible).map((x: any) => x.columnDef);
                            const orginalColsList = this._sTableOptions.columns.filter((s: any) => !s.isNoVisible).map((x: any) => x.columnDef);
                            const dif_company = ['company_name'];
                            const difference = orginalColsList.filter((value: any) => !savedColsList.includes(value) && !dif_company.includes(value));
                            if (savedCols && savedCols.length) {
                                if (result.data[0].value.columnRepr) {
                                    const orderOccurrences: { [key: number]: number } = {};
                                    this._sTableOptions.columns.forEach((obj: any, index: number) => {
                                        const b = savedCols.filter((x: any) => x.columnDef === obj.columnDef);
                                        if (b && b.length) {
                                            const { visible, order: newOrder } = b[0];
                                            if (orderOccurrences[newOrder] === undefined) {
                                                orderOccurrences[newOrder] = 0;
                                            }
                                            orderOccurrences[newOrder]++;
                                            if (orderOccurrences[newOrder] === 1) {
                                                Object.assign(obj, { visible, order: newOrder });
                                            } else {
                                                let uniqueOrder = newOrder;
                                                while (this._sTableOptions.columns.some((col: any) => col.order === uniqueOrder)) {
                                                    uniqueOrder++;
                                                }
                                                Object.assign(obj, { visible, order: uniqueOrder });
                                            }
                                        }
                                        const asset_view = (this.activeRoute.url.split('/').indexOf('assets') !== -1) ? true : false;
                                        const company = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id', 'company_ids'];
                                        const global = ['is_global'];
                                        if ((company.indexOf(obj.columnDef) !== -1)) {
                                            obj.visible = true;
                                            if (this.commonService.currentScope !== '*' || asset_view) {
                                                obj.isNoVisible = true;
                                            } else {
                                                obj.isNoVisible = false;
                                            }
                                        }
                                        if ((global.indexOf(obj.columnDef) !== -1)) {
                                            obj.visible = true;
                                            if (this.commonService.currentScope !== '*') {
                                                obj.isNoVisible = false;
                                            } else {
                                                obj.isNoVisible = true;
                                            }
                                        }
                                    });
                                }
                            }
                        } catch (e) {
                            e = null;
                        }
                        this._sTableOptions.columns.sort((a: any, b: any) => a.order - b.order);
                        this._sTableOptions.columns.forEach((obj: any, index: number) => {
                            obj.order = index;
                        });
                        this.selectedTimer = result.data[0].value.refreshInterval + '';
                        if (this.baseService.Tabelsetting().font) {
                            this.selectedFont = this.baseService.Tabelsetting().font
                        } else {
                            this.selectedFont = (result.data[0].value.selectedFont) ? result.data[0].value.selectedFont : "small";
                        }
                        this.textSizeCallData();
                        this._sTableOptions.sortOptions.direction = result.data[0].value.settings.sortOptions.direction === '' ? 'asc' : result.data[0].value.settings.sortOptions.direction;
                        this._sTableOptions.sortOptions.active = result.data[0].value.settings.sortOptions.active ? result.data[0].value.settings.sortOptions.active : this._sTableOptions.sortOptions.active;
                        this._sTableOptions.tableOptions.pageSize = result.data[0].value.settings.pageSize;
                        this.sTableOptions.tableOptions.pageSize = result.data[0].value.settings.pageSize;
                        setTimeout(() => this.getCollectionData(), 2000);
                        this.tableOptions.pageSize = result.data[0].value.settings.pageSize;
                        this.setTableOptions(this._sTableOptions.tableOptions);
                    } else {
                        if (this.baseService.Tabelsetting().page_size) {
                            this._sTableOptions.tableOptions.pageSize = this.baseService.Tabelsetting().page_size
                            this.tableOptions.pageSize = this.baseService.Tabelsetting().page_size
                        }
                        this.getCollectionData();
                    }
                });
        } else {
            if (this.sTableOptions.tableOptions.isNoSettingCall) {
                this.clearSelection();
                try {
                    const sTbleColumns = this.sTableOptions.columns.filter((x: any) => !x.isNoVisible);
                    this.sTableOptions.columns = (this.showhideList && this.showhideList.length === sTbleColumns.length) ? this.showhideList : this.sTableOptions.columns;
                } catch (e) {
                    console.log(e);
                }
                this.setTableOptions(this.tableOptions);
                setTimeout(() => this.tableOptions.loading = false)
                if (this.sTableOptions.tableOptions.pageTotal === 0) { this.sTableOptions.tableOptions.loading = false; };

            } else {
                this.loaderService.display(true);
                let sub_category = this.sTableOptions.tableOptions.id;
                if (this.sTableOptions.tableOptions.id === 'assets') {
                    sub_category = `global_${this.sTableOptions.tableOptions.id}`;
                }
                const params: any = {
                    condition: `category='table_settings' and value IS NOT NULL and sub_category='${sub_category}' and name='${this.baseService.user().email}'`
                }
                this.baseService.doRequest(`/r/company/settings`, 'get', null, params)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.loaderService.display(false);
                        this.sTableOptions.tableOptions.isSettingCalled = true;
                        if (result.status && result.data && result.data.length) {
                            this.sessionData = result.data[0];
                            try {
                                const savedCols = JSON.parse(result.data[0].value.columnRepr);
                                const savedColsList = savedCols.filter((s: any) => !s.isNoVisible).map((x: any) => x.columnDef);
                                const orginalColsList = this._sTableOptions.columns.filter((s: any) => !s.isNoVisible).map((x: any) => x.columnDef);
                                const dif_company = ['company_name', 'affected_companies'];
                                const difference = orginalColsList.filter((value: any) => !savedColsList.includes(value) && !dif_company.includes(value));
                                if (savedCols && savedCols.length) {
                                    const orderOccurrences: { [key: number]: number } = {};
                                    this._sTableOptions.columns.forEach((obj: any, index: number) => {
                                        const b = savedCols.filter((x: any) => x.columnDef === obj.columnDef);
                                        if (b && b.length) {
                                            const { visible, order: newOrder } = b[0];
                                            if (orderOccurrences[newOrder] === undefined) {
                                                orderOccurrences[newOrder] = 0;
                                            }
                                            orderOccurrences[newOrder]++;
                                            if (orderOccurrences[newOrder] === 1) {
                                                Object.assign(obj, { visible, order: newOrder });
                                            } else {
                                                let uniqueOrder = newOrder;
                                                while (this._sTableOptions.columns.some((col: any) => col.order === uniqueOrder)) {
                                                    uniqueOrder++;
                                                }
                                                Object.assign(obj, { visible, order: uniqueOrder });
                                            }
                                        }
                                        const asset_view = (this.activeRoute.url.split('/').indexOf('assets') !== -1) ? true : false;
                                        const company = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id', 'company_ids'];
                                        const global = ['is_global'];
                                        if ((company.indexOf(obj.columnDef) !== -1)) {
                                            obj.visible = true;
                                            if (this.commonService.currentScope !== '*' || asset_view) {
                                                obj.isNoVisible = true;
                                            } else {
                                                obj.isNoVisible = false;
                                            }
                                        }
                                        if ((global.indexOf(obj.columnDef) !== -1)) {
                                            obj.visible = true;
                                            if (this.commonService.currentScope !== '*') {
                                                obj.isNoVisible = false;
                                            } else {
                                                obj.isNoVisible = true;
                                            }
                                        }
                                    });
                                }
                            } catch (e) {
                                e = null;
                            }
                            this._sTableOptions.columns.sort((a: any, b: any) => a.order - b.order);
                            this._sTableOptions.columns.forEach((obj: any, index: number) => {
                                obj.order = index;
                            });
                            this.selectedTimer = result.data[0].value.refreshInterval + '';
                            if (this.baseService.Tabelsetting().font) {
                                this.selectedFont = this.baseService.Tabelsetting().font
                            } else {
                                this.selectedFont = (result.data[0].value.selectedFont) ? result.data[0].value.selectedFont : "small";
                            }
                            this.textSizeCallData();
                            this._sTableOptions.sortOptions.direction = result.data[0].value.settings.sortOptions.direction === '' ? 'asc' : result.data[0].value.settings.sortOptions.direction;
                            this._sTableOptions.sortOptions.active = result.data[0].value.settings.sortOptions.active ? result.data[0].value.settings.sortOptions.active : this._sTableOptions.sortOptions.active;
                            this._sTableOptions.tableOptions.pageSize = result.data[0].value.settings.pageSize;
                            this.sTableOptions.tableOptions.pageSize = result.data[0].value.settings.pageSize;
                            this.tableOptions.pageSize = result.data[0].value.settings.pageSize;
                            this.clearSelection();
                            try {
                                const sTbleColumns = this.sTableOptions.columns.filter((x: any) => !x.isNoVisible);
                                this.sTableOptions.columns = (this.showhideList && this.showhideList.length === sTbleColumns.length) ? this.showhideList : this.sTableOptions.columns;
                            } catch (e) {
                                console.log(e);
                            }
                            setTimeout(() => this.tableOptions.loading = false)
                            if (this.sTableOptions.tableOptions.pageTotal === 0) { this.sTableOptions.tableOptions.loading = false; };
                            this.setTableOptions(this._sTableOptions.tableOptions);
                        } else {
                            this.clearSelection();
                            try {
                                const sTbleColumns = this.sTableOptions.columns.filter((x: any) => !x.isNoVisible);
                                this.sTableOptions.columns = (this.showhideList && this.showhideList.length === sTbleColumns.length) ? this.showhideList : this.sTableOptions.columns;
                            } catch (e) {
                                console.log(e);
                            }
                            this.setTableOptions(this.tableOptions);
                            setTimeout(() => this.tableOptions.loading = false)
                            if (this.sTableOptions.tableOptions.pageTotal === 0) { this.sTableOptions.tableOptions.loading = false; };
                        }
                    });
            }

        }
        if (this.isPageLoad) {
            this.isPageLoad = false;
        }
        if (this.sTableOptions.tableOptions.isUserRestCheck) {
            this.sTableOptions.tableOptions.rowSelection = this.tableOptions.rowSelection = (!this.baseService.isRestrictedUser || this.commonService.currentScope !== '*') ? true : false;
            this.sTableOptions.tableOptions.showAction = this.tableOptions.showAction = (!this.baseService.isRestrictedUser || this.commonService.currentScope !== '*') ? true : false;
            if (!this.sTableOptions.tableOptions.isNoAdd) {
                this.sTableOptions.tableOptions.add = this.tableOptions.add = (!this.baseService.isRestrictedUser || this.commonService.currentScope !== '*') ? true : false;
            }
        }
        let filterColumn = this.sTableOptions.columns.filter((x: any) => x.visible && x.cType !== 'agg' && x.cType !== 'number' && !x.isNoVisible);
        if (this.sTableOptions.sColumns) {
            filterColumn = [...this.sTableOptions.sColumns, ...filterColumn];
        }
        this.sColumns = filterColumn.slice();
    }

    setTableOptions(tblOptions: any): any {
        this.displayedColumns = [];
        this.orderlist = [];
        const coulumns = this._sTableOptions.columns.filter((x: any) => !x.isNoVisible);
        this.showhideList = (this.showhideList && this.showhideList.length) ? this.showhideList : coulumns;
        this.dataSource.data = []; this.cdr.detectChanges();
        this.showhideList.map((c: any) => {
            if (c.visible) {
                this.displayedColumns.push(c.columnDef);
                this.orderlist.push(c);
            }
        });
        if (tblOptions.compareData) {
            this.displayedColumns.push('compare');
        }

        if (tblOptions.showAction) {
            this.displayedColumns.push('action');
        }

        if (tblOptions.rowSelection) {
            this.displayedColumns.unshift('select');
        }

        /*

        if (this.sort) {
            this.dataSource.sort = this.sort;
        }
        */

        this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
        ): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };

        if (!tblOptions.serverSide) {
            this.dataSource.paginator = this.paginatorWidget?.paginator;
        }
        if (!this.tableOptions.pagination) {
            this.dataSource.paginator = this.paginator;
            this.tableOptions.pageSize = this._sTableOptions.tableOptions.pageSize || 10;
        }
        this.dataSource.data = this._sTableOptions.pageData.slice();
        this.tableOptions.loading = false;
        this.cdr.detectChanges();
        // this.filterArray = this.dataSource.filteredData;
    }

    showHideColumn(i: any, val: any): void {
        val.visible = !val.visible;
        const index = this.orderlist.findIndex(
            (x) => x.columnDef === val.columnDef
        );
        if (index >= 0) {
            this.orderlist.splice(index, 1);
        } else {
            this.orderlist.push(val);
        }
        this.displayedColumns = [];
        this.orderlist = this.orderlist.sort((a, b) => a.order - b.order);

        if (this.tableOptions.compareData) {
            this.displayedColumns.push('compare');
        }

        if (this.tableOptions.rowSelection) {
            this.displayedColumns.push('select');
        }

        this.orderlist.forEach((element) => {
            this.displayedColumns.push(element.columnDef);
        });
        if (this.tableOptions.showAction) {
            this.displayedColumns.push('action');
        }
        this.isActionChanged = true;
        // this.initUpdateData();
    }

    ngAfterViewInit(): void {
        this.isLoading = false;
        this.filterText = '';
        this.cdr.detectChanges();
        setTimeout(() => {
            this.tableOptions.loading = true;
            this.initUpdateData();
        });
    }

    pageChanged(event: any): void {
        this.isActionChanged = true;
        this.currentPageIndex = event.pageIndex;
        this.cpIndex = event.pageIndex;
        this.tableOptions.pageSize = event.pageSize;
        this.sTableOptions.tableOptions.pageSize = event.pageSize;
        this.pageCallbackTable.emit(event);
        if (!this.tableOptions.serverSide) {
            this.pageCallback.emit(event);
        } else {
            this.getCollectionData();
        }
    }

    pageChangedManually($event: any): void {
        this.manualPageSize = $event.pageSize;
        this.manualPageIndex = $event.pageIndex;
        this.manualPageLength = $event.length;
        this.manualPreviousPageIndex = $event.previousPageIndex;
        this.inputPageNumber = $event.pageIndex + 1;
        console.log('event in page change ', $event);
    }

    RefreshTableData(): void {
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.tableOptions.loading = true;
            this.getCollectionData(); return;
        }
        this.refreshCallback.emit();
    }

    FilterTableData(): void {
        // if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
        this.showFilter = !this.showFilter;
        // }
        this.filterCallback.emit();
    }

    convertToPostgresSyntax(propertyPath: any) {
        // Check if the propertyPath is empty or has zero length
        if (!propertyPath || propertyPath.length === 0) {
            return propertyPath; // Return the original string unchanged
        }
        const segments = propertyPath.split('.');
        if (!segments || segments.length === 1) {
            return propertyPath; // Return the original string unchanged
        }
        const postgresSyntax = `(${segments[0]}->>'${segments.slice(1).join("->'")}')::numeric`;
        return postgresSyntax;
    }

    getCollectionData(search?: any): void {
        if (!this.sTableOptions.tableOptions.serverSide || !this.sTableOptions.tableOptions.serverSide.url) { return; }
        if (!this.sTableOptions.tableOptions.id) {
            this.sTableOptions.tableOptions.id = this.changeHeader(this.sTableOptions.tableOptions?.title);
        }
        const tablename = this.sTableOptions.tableOptions.title;
        delete this.commonService.selectedTableRows[tablename];
        this.sTableOptions.dataList = []; this.sTableOptions.pageData = [];
        this.tableOptions.loading = true;
        let skip = 0;
        let limit = this.tableOptions.pageSize;
        if (this.currentPageIndex) {
            skip = this.currentPageIndex * this.tableOptions.pageSize;
            limit = this.tableOptions.pageSize;
        } else {
            this.sTableOptions.tableOptions.pageTotal = 0;
        }
        this.selectedchecks = null;
        this.selectedchecks = this.sTableOptions.tableOptions.selectedchecks;
        this.cdr.detectChanges();
        // let order_by = `${this.sTableOptions.sortOptions.active} ${this.sTableOptions.sortOptions.direction}`;
        const active = (this.sTableOptions.sortOptions.active === 'fqdn_name') ? 'host_name' : this.sTableOptions.sortOptions.active
        let order_by = `${this.convertToPostgresSyntax(active)} ${this.sTableOptions.sortOptions.direction}`;
        let condition: any = { condition: true, skip, limit, order_by };
        if (this.sTableOptions.tableOptions.serverSide && this.commonService.currentScope !== '*' && !this.sTableOptions.tableOptions.serverSide.isGlobal && this.sTableOptions.tableOptions.serverSide.type !== 'post') {
            condition.condition = "company_id=" + parseInt(this.commonService.currentScope.id) + "";
        }
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.customCondition) {
            this.sTableOptions.tableOptions.serverSide.condition = this.sTableOptions.tableOptions.serverSide.customCondition.global;
            if (this.commonService.currentScope !== '*' && !this.sTableOptions.tableOptions.serverSide.isGlobal) {
                this.sTableOptions.tableOptions.serverSide.condition = this.sTableOptions.tableOptions.serverSide.customCondition.company;
            }
        }



        if (this.sTableOptions.tableOptions?.id === 'problem_details_update' || this.sTableOptions.tableOptions?.id === 'problem_details_vulnerability') {
            const condition = this.sTableOptions.tableOptions.serverSide.condition;
            if (condition && condition.trim() !== '') {
                const problemGroupIdPart = condition?.split('problem_group_id=')[1];
                const problemGroupId = problemGroupIdPart?.split(' ')[0];
                this.commonService.problemGroupId = problemGroupId
            } else {
                const problemGroupId = 'all'
                this.commonService.problemGroupId = problemGroupId
            }
            if (this.commonService.table_filter && this.commonService.table_filter[this.sTableOptions?.tableOptions?.id]) {
                this.filterQuery = this.commonService.table_filter[this.sTableOptions?.tableOptions?.id].query;
                this.showFilter = true;
                this.filterCallback.emit();
            }
        }

        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.condition && this.commonService.currentScope === '*') {
            condition.condition = this.sTableOptions.tableOptions.serverSide.condition;
        } else if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.condition) {
            if (condition.condition === true) {
                condition.condition = this.sTableOptions.tableOptions.serverSide.condition;
            } else {
                condition.condition += ' and ' + this.sTableOptions.tableOptions.serverSide.condition;
            }
        }
        if (this.filterQuery && this.filterQuery.length) {
            if (condition.condition === true) {
                condition.condition = this.filterQuery;
            } else {
                if (this.filterQuery.toLocaleLowerCase().indexOf('and') > -1 || this.filterQuery.toLocaleLowerCase().indexOf('or') > -1) {
                    condition.condition += ` and (${this.filterQuery})`;
                } else {
                    condition.condition += ` and ${this.filterQuery}`;
                }
            }
        }
        if (this.filterText && this.filterText.trim()) {
            const colLen = this.sTableOptions.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch);
            let sColumns: any = [];
            if (this.sTableOptions.sColumns) {
                sColumns = this.sTableOptions.sColumns.filter((y: any) => !y.gloNoSearch).map((x: any) => x.columnDef)
            }
            if (sColumns.indexOf('visible_name') > -1) {
                sColumns.push('host_name');
            }
            // const columns: any = [...sColumns, ...this.sTableOptions.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef)].join(", ' ', ");
            const columns: any = [...sColumns, ...this.sTableOptions.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef)].join(", ' ', "); //.join(", ' ', ")
            const columnsF: any = [...sColumns, ...this.sTableOptions.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef)];
            const contact = columnsF.map((item: any) => `${item} ILIKE '%${this.filterText.trim()}%'`).join(' OR ');
            if (condition.condition === true) {
                // condition.condition = (colLen.length > 1) ? `concat(${columns}) ILIKE '%${this.filterText.trim()}%'` : `${columns} ILIKE '%${this.filterText.trim()}%'`;
                condition.condition = (colLen.length > 1) ? `(${contact})` : `${columns} ILIKE '%${this.filterText.trim()}%'`;
            } else {
                // condition.condition += (colLen.length > 1) ? ` and concat(${columns}) ILIKE '%${this.filterText.trim()}%'` : ` and ${columns} ILIKE '%${this.filterText.trim()}%'`;
                condition.condition += (colLen.length > 1) ? ` and (${contact})` : ` and ${columns} ILIKE '%${this.filterText.trim()}%'`;
            }
        }

        this.clearSelection()
        if (this.tagList.api) {
            const url = `${this.sTableOptions.tableOptions.serverSide.url}`;
            // || url.indexOf('report_queries') > -1
            if (url.indexOf('/r/reports') > -1) {
                this.sTableOptions.tableOptions.serverSide.params = { ...this.sTableOptions.tableOptions.serverSide.params, ...this.tagList.report }
            } else {
                if (condition.condition === true) {
                    condition.condition = `${this.tagList.api}`;
                } else if (this.commonService.manualtagfilter) {
                    condition.condition += ` and not (${this.tagList.api})`;
                } else {
                    condition.condition += ` and (${this.tagList.api})`;
                }
            }
        } else {
            delete this.sTableOptions.tableOptions.serverSide?.params?.tags_key_value;
            delete this.sTableOptions.tableOptions.serverSide?.params?.manual_tags_key_value;
        }
        if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0) {
            const sortedArray = cloneDeep(this.sTableOptions.tableOptions.customPagination.value.slice().sort((a: any, b: any) => a - b));
            const skipN = (this.currentPageIndex * this.tableOptions.pageSize) || 0;
            const limitN = this.tableOptions.pageSize;
            const subarray = sortedArray.slice(skipN, (skipN + limitN));
            if (subarray && subarray.length) {
                let con = '';
                const contains = (this.sTableOptions.tableOptions.customPagination.condition) ? this.sTableOptions.tableOptions.customPagination.condition : 'IN';
                const containsZ = (this.sTableOptions.tableOptions.customPagination.condition === 'NOT IN') ? '!=' : '=';
                if (subarray.length > 1) {
                    con = `${this.sTableOptions.tableOptions.customPagination.key} ${contains} (${subarray.join(",")})`
                } else {
                    con = `${this.sTableOptions.tableOptions.customPagination.key} ${containsZ} ${subarray.join(",")}`
                }
                if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.type === 'post') {
                    const params: any = {};
                    params[this.sTableOptions.tableOptions.customPagination.key] = subarray.toString();
                    skip = 0;
                    this.sTableOptions.tableOptions.serverSide.params = { ...this.sTableOptions.tableOptions.serverSide.params, ...params };
                } else {
                    if (condition.condition === true) {
                        condition.condition = con
                    } else {
                        condition.condition += ` and ${con}`;
                    }
                }
                condition.skip = 0;
            }
        }
        if (this.sTableOptions.tableOptions.isUserRestCheck) {
            this.sTableOptions.tableOptions.rowSelection = this.tableOptions.rowSelection = (!this.baseService.isRestrictedUser || this.commonService.currentScope !== '*') ? true : false;
            this.sTableOptions.tableOptions.showAction = this.tableOptions.showAction = (!this.baseService.isRestrictedUser || this.commonService.currentScope !== '*') ? true : false;
            if (!this.sTableOptions.tableOptions.isNoAdd) {
                this.sTableOptions.tableOptions.add = this.tableOptions.add = (!this.baseService.isRestrictedUser || this.commonService.currentScope !== '*') ? true : false;
            }
        }
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.type === 'post') {
            const con = Object.assign({}, this.sTableOptions.tableOptions.serverSide.params);
            delete con.name;
            if (this.commonService.currentScope?.id && !this.sTableOptions.tableOptions.serverSide.isGlobal) {
                this.sTableOptions.tableOptions.serverSide.params.company_id = parseInt(this.commonService.currentScope.id);
            }
            this.sTableOptions.tableOptions.serverSide.params.skip = skip;
            this.sTableOptions.tableOptions.serverSide.params.limit = limit;
            this.sTableOptions.tableOptions.serverSide.params.order_by = order_by;
            if (this.tagList.api) {
                const url = `${this.sTableOptions.tableOptions.serverSide.url}`
                //|| url.indexOf('report_queries') > -1
                if (url.indexOf('/r/reports') > -1) {
                    this.sTableOptions.tableOptions.serverSide.params = { ...this.sTableOptions.tableOptions.serverSide.params, ...this.tagList.report };
                } else {
                    if (this.sTableOptions.tableOptions.serverSide.params.condition === true) {
                        this.sTableOptions.tableOptions.serverSide.params.condition = `${this.tagList.api}`;
                    } else {
                        this.sTableOptions.tableOptions.serverSide.params.condition += ` and (${this.tagList.api})`;
                    }
                }
            } else {
                delete this.sTableOptions.tableOptions.serverSide?.params?.tags_key_value;
                delete this.sTableOptions.tableOptions.serverSide?.params?.manual_tags_key_value;
            }
            // this.sTableOptions.tableOptions.serverSide.params.condition = this.commonService.objectToQueryString(con);
            if (condition.condition) {
                this.sTableOptions.tableOptions.serverSide.params.condition = condition.condition; // + this.commonService.objectToQueryString(con);
            }
            let url = `${this.sTableOptions.tableOptions.serverSide.url}` // /${this.sTableOptions.tableOptions.serverSide.params.name}
            if (this.tagList.api && this.sTableOptions.tableOptions.serverSide.customTagFilterApi) {
                url = `${this.sTableOptions.tableOptions.serverSide.customTagFilterApi}`
            }
            let request;
            if (this.sTableOptions.tableOptions.serverSide.method === 'post') {
                const requestBody = {
                    params: {
                        condition: this.sTableOptions.tableOptions.serverSide.condition,
                        skip: this.sTableOptions.tableOptions.serverSide.params.skip,
                        limit: this.sTableOptions.tableOptions.serverSide.params.limit,
                        order_by: this.sTableOptions.tableOptions.serverSide.params.order_by
                    },
                    query_name: this.sTableOptions.tableOptions.serverSide.query_name
                };
                request = this.baseService.doRequest(url, 'post', requestBody);
            } else {
                request = this.baseService.doRequest(url, 'get', null, this.sTableOptions.tableOptions.serverSide.params);
            }
            // this.baseService
            //     .doRequest(url, 'get', null, this.sTableOptions.tableOptions.serverSide.params)
            request.pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        if (this.sTableOptions.tableOptions.serverSide.isMergeApi) {
                            if (this.sTableOptions.tableOptions.serverSide.customFnCall) {
                                // @ts-ignore
                                const customFnResult = this.commonService.getTicketIds(result.data, this.sTableOptions.tableOptions.serverSide.requiredKeys);
                                if (customFnResult && customFnResult.length) {
                                    this.loaderService.display(false);
                                    this.sTableOptions.pageData = customFnResult;
                                    this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                                    this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                                    if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0 && !this.filterText && !this.filterText.trim() && !this.filterQuery) {
                                        this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                        this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                    }
                                    this.setTableOptions(this.sTableOptions.tableOptions);
                                    this.cdr.detectChanges();
                                    setTimeout(() => this.tableOptions.loading = false);
                                } else {
                                    this.loaderService.display(false);
                                    this.sTableOptions.pageData = result.data;
                                    this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                                    this.commonService.currentproblemcount = this.sTableOptions.tableOptions.pageTotal;
                                    this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                                    if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0 && !this.filterText && !this.filterText.trim() && !this.filterQuery) {
                                        this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                        this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                    }
                                    this.setTableOptions(this.sTableOptions.tableOptions);
                                    this.cdr.detectChanges();
                                    setTimeout(() => this.tableOptions.loading = false);
                                }

                            } else {
                                const mParams = cloneDeep(this.sTableOptions.tableOptions.serverSide.params);
                                delete mParams.name;
                                this.mergeData(result, mParams);
                            }

                        } else {
                            if (this._sTableOptions.tableOptions.dataMapping && this._sTableOptions.tableOptions.dataMapping.length) {
                                result.data.forEach((o: any) => {
                                    this._sTableOptions.tableOptions.dataMapping.forEach((d: any) => {
                                        if (d.filter) {
                                            o[d.d] = this.formatCell.transform(o[d.s], d.filter);
                                        } else {
                                            o[d.d] = o[d.s];
                                        }
                                    });
                                })
                            }
                            this.loaderService.display(false);
                            this.sTableOptions.pageData = result.data;
                            this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : (this.sTableOptions.tableOptions.total_key && result.data && result.data.length) ? result.data[0][this.sTableOptions.tableOptions.total_key] : 0;
                            this.tableOptions.pageTotal = (result.total) ? result.total : (this.sTableOptions.tableOptions.total_key && result.data && result.data.length) ? result.data[0][this.sTableOptions.tableOptions.total_key] : 0;
                            if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0 && !this.filterText && !this.filterText.trim() && !this.filterQuery) {
                                this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                            }
                            this.setTableOptions(this.sTableOptions.tableOptions);
                            this.cdr.detectChanges();
                            setTimeout(() => this.tableOptions.loading = false);
                        }
                    } else {
                        this.loaderService.display(false);
                        this.sTableOptions.pageData = [];
                        this.sTableOptions.tableOptions.pageTotal = 0;
                        this.tableOptions.pageTotal = 0;
                        this.setTableOptions(this.sTableOptions.tableOptions);
                        this.cdr.detectChanges();
                        setTimeout(() => this.tableOptions.loading = false)
                    }
                });

        } else {
            let url = `${this.sTableOptions.tableOptions.serverSide.url}` // /${this.sTableOptions.tableOptions.serverSide.params.name}
            if (this.tagList.api && this.sTableOptions.tableOptions.serverSide.customTagFilterApi) {
                url = `${this.sTableOptions.tableOptions.serverSide.customTagFilterApi}`
            }
            let request;
            if (this.sTableOptions.tableOptions.serverSide.method === 'post') {
                const requestBody = {
                    params: {
                        condition: condition.condition,
                        skip: condition.skip,
                        limit: condition.limit,
                        order_by: condition.order_by
                    },
                    query_name: this.sTableOptions.tableOptions.serverSide.query_name
                };
                request = this.baseService.doRequest(url, 'post', requestBody);
            } else {
                request = this.baseService.doRequest(url, 'get', null, condition);
            }
            // this.baseService
            //     .doRequest(url, 'get', null, condition)
            request.pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        if (this.sTableOptions.tableOptions.serverSide.isMergeApi) {
                            if (this.sTableOptions.tableOptions.serverSide.customFnCall) {
                                // @ts-ignore
                                const customFnResult = this.commonService.getTicketIds(result.data, this.sTableOptions.tableOptions.serverSide.requiredKeys);
                                if (customFnResult && customFnResult.length) {
                                    this.loaderService.display(false);
                                    this.sTableOptions.pageData = customFnResult;
                                    this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                                    this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                                    this.setTableOptions(this.sTableOptions.tableOptions);
                                    this.cdr.detectChanges();
                                    setTimeout(() => this.tableOptions.loading = false);
                                } else {
                                    this.loaderService.display(false);
                                    this.sTableOptions.pageData = result.data;
                                    this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : (this.sTableOptions.tableOptions.total_key && result.data && result.data.length) ? result.data[0][this.sTableOptions.tableOptions.total_key] : 0;
                                    this.tableOptions.pageTotal = (result.total) ? result.total : (this.sTableOptions.tableOptions.total_key && result.data && result.data.length) ? result.data[0][this.sTableOptions.tableOptions.total_key] : 0;
                                    this.setTableOptions(this.sTableOptions.tableOptions);
                                    this.cdr.detectChanges();
                                    setTimeout(() => this.tableOptions.loading = false);
                                }

                            } else {
                                const mParams = cloneDeep(condition);
                                delete mParams.name;
                                this.mergeData(result, mParams);
                            }
                        } else {
                            this.loaderService.display(false);
                            if (this._sTableOptions.tableOptions.dataMapping && this._sTableOptions.tableOptions.dataMapping.length) {
                                result.data.forEach((o: any) => {
                                    this._sTableOptions.tableOptions.dataMapping.forEach((d: any) => {
                                        if (d.filter) {
                                            o[d.d] = this.formatCell.transform(o[d.s], d.filter);
                                        } else {
                                            o[d.d] = o[d.s];
                                        }
                                    });
                                })
                            }
                            this.sTableOptions.pageData = result.data;
                            this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : (this.sTableOptions.tableOptions.total_key && result.data && result.data.length) ? result.data[0][this.sTableOptions.tableOptions.total_key] : 0;
                            this.tableOptions.pageTotal = (result.total) ? result.total : (this.sTableOptions.tableOptions.total_key && result.data && result.data.length) ? result.data[0][this.sTableOptions.tableOptions.total_key] : 0;
                            if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0 && !this.filterText && !this.filterText.trim() && !this.filterQuery) {
                                this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                            }
                            this.setTableOptions(this.sTableOptions.tableOptions);
                            this.cdr.detectChanges();
                            setTimeout(() => this.tableOptions.loading = false)
                        }
                    } else {
                        this.loaderService.display(false);
                        this.sTableOptions.pageData = [];
                        this.sTableOptions.tableOptions.pageTotal = 0;
                        this.tableOptions.pageTotal = 0;
                        this.setTableOptions(this.sTableOptions.tableOptions);
                        this.cdr.detectChanges();
                        setTimeout(() => this.tableOptions.loading = false)
                    }
                });

        }
    }

    mergeData(result: any, condition: any): void {
        if (result.data && result.data.length) {
            this.loaderService.display(true);
            let key = 'id'; let srcKey = 'id';
            let idValues = result.data.map((item: any) => item[key]);
            if (this.sTableOptions.tableOptions.serverSide.mergeCondition) {
                key = (this.sTableOptions.tableOptions.serverSide.mergeKey) ? this.sTableOptions.tableOptions.serverSide.mergeKey : key;
                srcKey = (this.sTableOptions.tableOptions.serverSide.srcKey) ? this.sTableOptions.tableOptions.serverSide.srcKey : srcKey;
                idValues = result.data.map((item: any) => item[srcKey]);
                condition[this.sTableOptions.tableOptions.serverSide.mergeCondition] = `${idValues.join(',')}`;
            } else {
                if (condition.condition === true) {
                    condition.condition = `id IN (${idValues.join(',')})`;
                } else {
                    condition.condition += ` and id IN (${idValues.join(',')})`;
                }
            }
            delete condition.order_by;
            this.baseService
                .doRequest(this.sTableOptions.tableOptions.serverSide.mergeurl, 'get', null, condition)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                    if (res.status) {
                        const mergedData = result.data.map((item1: any) => {
                            const matchingItem = res.data.find((item2: any) => item2[key] === item1[srcKey]);

                            // If a match is found, merge the properties
                            if (matchingItem) {
                                return { ...item1, ...matchingItem };
                            }

                            // If no match is found, just return the item from the first array
                            return item1;
                        });
                        if (this._sTableOptions.tableOptions.dataMapping && this._sTableOptions.tableOptions.dataMapping.length) {
                            mergedData.forEach((o: any) => {
                                this._sTableOptions.tableOptions.dataMapping.forEach((d: any) => {
                                    if (d.filter) {
                                        o[d.d] = this.formatCell.transform(o[d.s], d.filter);
                                    } else {
                                        o[d.d] = o[d.s];
                                    }
                                });
                            })
                        }
                        this.loaderService.display(false);
                        this.sTableOptions.pageData = mergedData;
                        this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                        this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                        if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0) {
                            this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                            this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                        }
                        this.setTableOptions(this.sTableOptions.tableOptions);
                        this.cdr.detectChanges();
                        setTimeout(() => this.tableOptions.loading = false)
                    } else {
                        this.loaderService.display(false);
                        this.sTableOptions.pageData = [];
                        this.sTableOptions.tableOptions.pageTotal = 0;
                        this.tableOptions.pageTotal = 0;
                        this.setTableOptions(this.sTableOptions.tableOptions);
                        this.cdr.detectChanges();
                        setTimeout(() => this.tableOptions.loading = false)
                    }
                });
        } else {
            this.loaderService.display(false);
            this.sTableOptions.pageData = result.data;
            this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
            this.tableOptions.pageTotal = (result.total) ? result.total : 0;
            this.setTableOptions(this.sTableOptions.tableOptions);
            this.cdr.detectChanges();
            setTimeout(() => this.tableOptions.loading = false);
        }
    }

    showAll(item: any) {
        if (!item.showAllFlag) {
            item.showAllFlag = true
        }
        else {
            item.showAllFlag = !item.showAllFlag
        }
    }

    initiateTable(dataList: any): void {
        const cols = dataList
            // tslint:disable-next-line:no-shadowed-variable
            .reduce((cols: any, row: {}) => {
                return [...cols, ...Object.keys(row)];
            }, [])
            // tslint:disable-next-line:no-shadowed-variable
            .reduce((cols: string | any[], column: any) => {
                return cols.includes(column) ? cols : [...cols, column];
            }, []);
        this.displayedColumns = this.columnsList.slice();
        this.dataSource = new MatTableDataSource<any>(dataList);
        this.dataSource.paginator = this.paginatorWidget.paginator;
        // this.dataSource.sort = this.sort;
        this.totalCallback.emit({
            value: this.sTableOptions.pTotal,
            id: this.sTableOptions.id,
        });
    }
    openListColumns(): void {
        this.isExportClick = false;
        this.columnsCnt++;
        if (this.columnsCnt > 1) {
            this.columnsCnt = 1;
        }
    }

    showHideLoading(status: any): void {
        /*const data = Object.assign({}, this.sTableOptions);
        this.sTableOptions = {};
        this.sTableOptions = data;*/
        this.sTableOptions.tableOptions.loading = status;
        this.cdr.detectChanges()
    }

    timerCallData(): void {
        // this.listMenuTrigger.closeMenu();
        clearInterval(this.intervalId);
        this.intervalId = undefined;

        if (
            this.selectedTimer !== undefined &&
            Number(this.selectedTimer) !== 0
        ) {
            clearInterval(this.intervalId);
            this.intervalId = setInterval(() => {
                this.timerCallback.emit(this.selectedTimer);
            }, Number(this.selectedTimer) * 1000);
        }
    }
    textSizeCallData(): void {
        switch (this.selectedFont) {
            case 'small':
                this.fontSizeClass = '';
                this.fontSizeClassHed = 'text-sm';
                break;
            case 'medium':
                this.fontSizeClass = 'text-md';
                this.fontSizeClassHed = 'text-md';
                break;
            case 'large':
                this.fontSizeClass = 'text-base';
                this.fontSizeClassHed = 'text-xl';
                break;
        }
    }
    filterDrpLstChange(data: any): void {
        console.log(data);
        this.selectionchangeCallback.emit(data);
    }

    ParentCalls(id: any): void {
        this.parentCallback.emit(id);
    }

    AddTableRecord(): void {
        // this.listMenuTrigger.closeMenu();
        this.addCallback.emit();
    }
    publish(): void {
        this.publishCallback.emit();
    }
    CompareData(row: any): any {
        if (row.length <= 1) {
            this.toast.sToast('info', 'Two files requires to compare');
            return false;
        }
        if (row.length > 2) {
            this.toast.sToast(
                'info',
                'Not allow to compare more than 2 records'
            );
            return false;
        }
        const resp = { row };
        this.compareCallback.emit(resp);
    }

    clearSelection(): void {
        this.selection.clear();
        // @ts-ignore
        this.numSelected = undefined;
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({ numSelected: 0 })
            : null;*/
    }
    clearCompCompareSelection(): void {
        this.compSelection.clear();
        // @ts-ignore
        this.compNumSelected = undefined;
    }

    isAllSelected(): any {
        this.numSelected = this.selection.selected.length;
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({ numSelected: this.numSelected })
            : null;*/
        const numRows = this.dataSource.data.length;
        if (this.sTableOptions.tableOptions.isCheckboxSelection) {
            this.checkBoxCallback.emit(this.selection.selected);
        }
        return this.numSelected === numRows;
    }

    isAllCompSelected(): any {
        this.compNumSelected = this.compSelection.selected.length;
        const numRows = this.dataSource.data.length;
        this.cdr.detectChanges()
        return this.compNumSelected === numRows;
    }

    Manualfilterchecks(selectedValue: string): void {
        this.selectedchecks = selectedValue;
        this.sTableOptions.tableOptions.selectedchecks = selectedValue;
        this.cdr.detectChanges();
        this.manualfilterCallbackduplicate.emit({ selectedValue, viewcheck: this.sTableOptions.tableOptions.viewcheck });
    }


    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({
                numSelected: this.selection.selected.length,
            })
            : null;*/
        if (this.sTableOptions.tableOptions.isCheckboxSelection) {
            this.checkBoxCallback.emit(this.selection.selected);
        }
        const tablename = this.sTableOptions.tableOptions.title;
        this.commonService.selectedRows.next({ row: this.selection.selected, table: tablename });
        // this.cdr.detectChanges();
    }

    shiftKeyDown(event: any, lastRow: any): void {
        if (event.shiftKey) {
            const obj: any[] = Object.assign([], this.dataSource.data).filter((val, i) => {
                return i > this.lastSelectedRow && i < lastRow;
            });
            obj.forEach(e => this.selection.select(e));
            const tablename = this.sTableOptions.tableOptions.title;
            this.commonService.selectedRows.next({ row: this.selection.selected, table: tablename });
        }
        this.lastSelectedRow = lastRow;
    }

    rowToggle(row: any): void {
        this.selection.toggle(row);
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({
                numSelected: this.selection.selected.length,
            })
            : null;*/
        if (this.sTableOptions.tableOptions.isCheckboxSelection) {
            this.checkBoxCallback.emit(this.selection.selected);
        }
        const tablename = this.sTableOptions.tableOptions.title;
        this.commonService.selectedRows.next({ row: this.selection.selected, table: tablename });
        // this.cdr.detectChanges();
    }

    masterCompToggle(): void {
        this.isAllCompSelected()
            ? this.compSelection.clear()
            : this.dataSource.data.forEach((row) =>
                this.compSelection.select(row)
            );
        this.cdr.detectChanges()
    }

    customSort(event: any): void {
        this.lastSelectedRow = 0;
        this._sTableOptions.sortOptions = event;
        if (event.direction !== '') { this.sortOptions = event.direction; };
        if (event.direction === '') { event.direction = (this.sortOptions === 'desc') ? 'asc' : 'desc'; };
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url && !this.sTableOptions.tableOptions.isCustomSort) {
            this.getCollectionData();
        } else if (this.sTableOptions.tableOptions.isCustomSort) {
            // this.sortCallback.emit(event);
            // if (this.sort.direction === '') { this.sort.direction = (this.sortOptions === 'desc') ? 'asc' : 'desc'; };
            // this.dataSource.sort = this.sort;
            // this.cdr.detectChanges();
            this.tableCustomSort(event.active, (event.direction === 'desc') ? 1 : -1);
        } else {
            this.sortCallback.emit(event);
        }
    }

    tableCustomSort(key: any, direction: any): void {
        this.commonService.sortFn(this.dataSource.data, key, direction).then((val: any) => {
            this.loaderService.display(true);
            this.dataSource.data = []; this.cdr.detectChanges();
            this.dataSource.data = val; this.cdr.detectChanges();
            this.loaderService.display(false);

        })
    }
    exportAllData(): void {
        const keymap = {};
        // @ts-ignore
        this._sTableOptions.columns.forEach((obj) => {
            // @ts-ignore
            keymap[obj.columnDef] = obj.header;
        });
        // @ts-ignore
        keymap._id = keymap._id ? keymap._id : '_id';
        this.loaderService.display(true);
        const name =
            this.tableOptions.title !== ''
                ? this.tableOptions.title.replace(/[^A-Z0-9]/gi, '_')
                : 'Report';
        this.baseService
            .doRequest('/api/standardreports/generateReport', 'post', {
                query: this._sTableOptions.query,
                keyMap: keymap,
                name,
            })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    this.loaderService.display(false);
                    if (result) {
                        window.open(result.msg, '_blank');
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                },
                () => {
                    this.loaderService.display(false);
                }
            );
    }

    SaveSettings(): void {
        let item: any;
        const columns: any = cloneDeep(this.showhideList);
        columns.forEach((obj: any, index: number) => {
            obj.order = index;
        });
        item = {
            columnRepr: JSON.stringify(columns),
            refreshInterval: +this.selectedTimer,
            selectedFont: this.selectedFont,
            settings: {
                filterText: this.filterText,
                gFilter: this._sTableOptions.gFilter
                    ? this._sTableOptions.gFilter
                    : [],
                sortOptions: this._sTableOptions.sortOptions,
                pageSize: this._sTableOptions.tableOptions.pageSize,
            },
        };
        let sub_category = this._sTableOptions.tableOptions.id;
        if (this._sTableOptions.tableOptions.id === 'assets') {
            sub_category = `global_${this._sTableOptions.tableOptions.id}`;
        }
        if (this._sTableOptions.tableOptions.showmanualfilter) {
            item.settings.vul_filter = this.selectedchecks;
        }
        const table_settings: any = { 'category': 'table_settings', 'sub_category': sub_category, 'name': this.baseService.user().email, 'value': item };
        item.settings.sortOptions.direction =
            this._sTableOptions.sortOptions.direction === ''
                ? 'asc'
                : this._sTableOptions.sortOptions.direction;
        const method =
            this.sessionData && this.sessionData.id ? 'patch' : 'post';
        const request: any = { data: table_settings };
        if (this.sessionData && this.sessionData.id) { request.id = this.sessionData.id; }
        this.baseService
            .doRequest(`/w/company/table_settings`, method, request)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.status) {
                    this.toast.sToast('success', 'Successfully updated!');
                    if (method === 'post') {
                        this.sessionData = (this.sessionData) ? this.sessionData : {};
                        this.sessionData.id = parseInt(res.id);
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    ResetSettings(): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to reset ?`,
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.baseService.doRequest(`/d/company/settings/${this.sessionData.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result.status) {
                            this.toast.sToast('success', 'Reseted successfully');
                            setTimeout(() => {
                                let sub_category = this._sTableOptions.tableOptions.id;
                                if (this._sTableOptions.tableOptions.id === 'assets') {
                                    sub_category = `global_${this._sTableOptions.tableOptions.id}`;
                                }
                                delete this.commonService.table_settings[sub_category];
                                this.RefreshTableData();
                                if (this._sTableOptions.tableOptions.showmanualfilter) {
                                    this.selectedchecks = "yes";
                                    this.manualfilterCallbackduplicate.emit({ selectedValue: "yes", viewcheck: this.sTableOptions.tableOptions.viewcheck });
                                }
                                this._sTableOptions.columns = this.originalTableOptions.columns;
                                this.showhideList = []; this.cdr.detectChanges();
                                this.setTableOptions(this.originalTableOptions.tableOptions);
                                this.cdr.detectChanges();
                            }, 3000);
                            delete this.sessionData.id;
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    doFilter = (value: string) => {
        // this.isActionChanged = true;
        if (this._sTableOptions.tableOptions.isDefaultSearch) {

            // if(this._sTableOptions.convertJson === true){

            // }
            // this.dataSource.filteredData.forEach(item => {
            //     // Check if the 'ports' key exists
            //     if (item.ports && Array.isArray(item.ports) && item.ports.length > 1) {
            //         // Convert the array of ports to a string with comma-separated values
            //         item.ports = item.ports.join(',');
            //     }

            // });
            this.dataSource.filter = value.trim().toLocaleLowerCase();
            this.tableOptions.pageTotal = this.dataSource.filteredData.length;
            this.cdr.detectChanges();
            return;
        }
        localStorage.setItem(this.sTableOptions.tableOptions.id, value);
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.currentPageIndex = 0;
            this.getCollectionData(value);
        } else if (!this.sTableOptions.tableOptions.serverSide || !this.sTableOptions.tableOptions.serverSide.url) {
            this.filterCallback.emit(value);
        } else {
            this.dataSource.filter = value.trim().toLocaleLowerCase();
            this.tableOptions.pageTotal = this.dataSource.filteredData.length;
            this.cdr.detectChanges();
        }
    };
    doColumFilter = (value: string, col: string): any => {
        if (this.tableOptions.isServerSide) {
            // @ts-ignore
            if (this.colFilters.filter((x) => x.key === col).length > 0) {
                // @ts-ignore
                this.colFilters.forEach((obj, index) => {
                    if (col === obj.key && value === '') {
                        this.colFilters.splice(index, 1);
                    } else {
                        obj.value = value;
                    }
                });
            } else {
                if (value === '') {
                    return false;
                }
                const colName = this._sTableOptions.columns.filter(
                    (x: any) => x.columnDef === col
                )[0].header;
                this.colFilters.push({ key: col, name: colName, value });
            }
            this.colFilterCallback.emit({ value, col });
        } else {
            this.filterValues = value.toLowerCase();
            this.dataSource.data = this.filterArray.filter((row: any) =>
                row[this.searchedColName]
                    .toLowerCase()
                    .includes(this.filterValues)
            );
        }
    };


    changeHeader(header: any): any {
        return header.replace(/\s+/g, '_').toLowerCase();
    }

    tableFilterCal($event: any): void {
        this.loaderService.display(true);
        this.currentPageIndex = 0;
        const tablename = this.sTableOptions.tableOptions.title;
        this.commonService.selectedRows.next({ row: this.selection.selected, table: tablename });
        this.sTableOptions.dataList = []; this.sTableOptions.pageData = [];
        this.sTableOptions.tableOptions.pageTotal = 0;
        this.tableOptions.loading = true;
        this.sTableOptions.pageData = $event;
        this.sTableOptions.tableOptions.pageTotal = $event.length
        this.tableOptions.pageTotal = $event.length;
        this.loaderService.display(false);
        this.setTableOptions(this.sTableOptions.tableOptions);
        this.cdr.detectChanges();
        setTimeout(() => this.tableOptions.loading = false);
    }

    get maxPageNumber(): number {
        return Math.ceil(this.manualPageLength / this.manualPageSize);
    }

    onPageNumberInput(event: any): void {
        const inputElement = event.target as HTMLInputElement;
        let pageNumber = Number(inputElement.value);
        pageNumber === 0 && inputElement.value !== '' ? pageNumber = 1 : pageNumber;
        const totalPages = Math.ceil(this.manualPageLength / this.manualPageSize);
        if (pageNumber > totalPages) {
            pageNumber = totalPages;
            inputElement.value = totalPages.toString(); // Reset input to the max page number
        }
        if (pageNumber > 0 && pageNumber <= totalPages) {
            this.paginator.pageIndex = pageNumber - 1;
            this.paginator._changePageSize(this.paginator.pageSize); // Trigger page event
        } else {
            // Reset inputPageNumber to a valid page number if out of bounds
            this.inputPageNumber = this.paginator.pageIndex + 1;
        }
    }

    validatePositiveNumber(event: any): void {
        // avoiding Negative Values
        if (event.key === '-') { event.preventDefault(); return }
        if ((event.target.value === '' || event.target.value === '0' || event.target.value === '00' || event.target.value === '000') && event.key === '0') { event.preventDefault(); return }
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    showPorts(items: any): void {
        this.port_details = {};
        this.port_details = items;
        this.modalService.open('portsDetails1');
    }

    redirect(data: any, item: any, index: any): void {
        const ticket = data.filter((a: any, i: any) => i === index);
        if (ticket && ticket.length) {
            const ticket_id = (item.ticket_id) ? item.ticket_id : item.ticket_number;
            if (ticket[0] && ticket[0].ticket_url) {
                let url = (ticket[0] && ticket[0].ticket_url && (ticket[0].ticket_url.startsWith('http://') || ticket[0].ticket_url.startsWith('https://'))) ? ticket[0].ticket_url : `https://${ticket[0].ticket_url}`;
                url = url.replace('${ticket_id}', ticket_id.trim()).replace(/\\\"/g, '"');
                window.open(url, '_blank');
            } else if (item.credential_id) {
                const params = { condition: `id=${item.credential_id}` };
                this.baseService.doRequest('/r/company/integration_credentials', 'get', null, params)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res: any) => {
                        if (res.status && res.data && res.data.length) {
                            const ticket = res.data;
                            let url = (ticket[0] && ticket[0].ticket_url && (ticket[0].ticket_url.startsWith('http://') || ticket[0].ticket_url.startsWith('https://'))) ? ticket[0].ticket_url : `https://${ticket[0].ticket_url}`;
                            url = url.replace('${ticket_id}', ticket_id.trim()).replace(/\\\"/g, '"');
                            window.open(url, '_blank');
                        }
                    });
            }
        }
    }

    showTickets(items: any, isOpenTicket: any): void {
        this.ticket_details = {};
        this.isLoading1 = true;
        this.ticket_details = items;

        this.ticket_details.open_ticket = []; this.ticket_details.closed_ticket = [];
        if (isOpenTicket) {
            this.ticket_details.open_ticket = items.ticket_status_map.filter((x: any) => x.status === 'Open');
        } else {
            this.ticket_details.closed_ticket = items.ticket_status_map.filter((x: any) => x.status === 'Closed');
        }
        this.cdr.detectChanges();
        this.modalService.open('ticketDetails1');

    }

    showMoreTags(row: any) {
        this.selectedRows = row;
        this.tag_details = this.getApiResponseTags(row, 'tags');
        this.modalService.open('TagMenu');
    }

    // Process the API response and return an array of tags
    getApiResponseTags(row: any, columnDef: string) {
        const tags = [];
        const response = row[columnDef];

        if (response) {
            for (const key in response) {
                if (response.hasOwnProperty(key)) {
                    const values = response[key];
                    values.forEach((value: string) => {
                        tags.push({ key: key, value: value });
                    });
                }
            }
        }
        return tags;
    }

    showMoreManualTags(row: any) {
        const manualTagsObject = this.getProperty(row, 'manual_tags');
        if (manualTagsObject && typeof manualTagsObject === 'object' && !Array.isArray(manualTagsObject)) {
            this.manualTags = Object.keys(manualTagsObject).map(key => ({
                key: key,
                value: manualTagsObject[key],
            }));
        } else {
            this.manualTags = manualTagsObject || [];
        }
        this.modalService.open('ManualTagMenu');
    }
}
