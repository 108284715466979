import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { CommonModule, DatePipe, NgOptimizedImage } from "@angular/common";
import { TableComponent } from "app/modules/shared/table/table.component";
import { ActivatedRoute } from "@angular/router";
import { CommonService } from "app/_services/common.service";
import { LoaderService } from "app/_services/loader.service";
import { MyToastrService } from "app/_services/toastr.service";
import { BaseRequestService } from "../../../_services/base.service";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { FuseAlertComponent } from "../../../../@fuse/components/alert";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MaterialModule } from "../../../material.module";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FuseDrawerComponent } from "@fuse/components/drawer";
import { TranslocoModule } from "@ngneat/transloco";
import { UserService } from "app/core/user/user.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ModalService } from "../../../_services/modal.service";
import { ModalComponent } from "../../shared/modal.component";
import { HighlightService } from "../../../_services/highlight.service";
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { FuseHighlightComponent } from "@fuse/components/highlight";
import { DirectivesModule } from "app/-directives/-directives.module";
import { fuseAnimations } from "@fuse/animations";
import { DynamicFormComponent } from "../../shared/forms/dynamic-form/dynamic-form.component";
import { cloneDeep, orderBy } from "lodash";
import { IntegrationActionsService } from "../../../_services/integration-actions.service";
import { IntegrationActionComponent } from "../../shared/integration-action/integration-action.component";
import { DateTime } from 'luxon';
import { UtcToLocaleHTML, UtcToLocaleRDate } from "app/_filters/app.filter.pipe";
import { RemediatedDetailsComponent } from "../companies/remediated-details/remediated-details.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SuppressionComponent } from "../companies/suppression/suppression.component";
import { STableComponent } from "app/modules/shared/s-table/s-table.component";

const moment = _rollupMoment || _moment;

@Component({
    selector: "remediation-plan",
    standalone: true,
    animations: fuseAnimations,
    imports: [
        FuseDrawerComponent,
        DirectivesModule,
        CommonModule,
        ModalComponent,
        TableComponent,
        SuppressionComponent,
        AppFilterPipeModule,
        FuseAlertComponent,
        FuseScrollbarDirective,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatSidenavModule,
        MatTooltipModule,
        MaterialModule,
        FormsModule,
        TranslocoModule,
        FuseHighlightComponent,
        DynamicFormComponent,
        IntegrationActionComponent,
        RemediatedDetailsComponent,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        STableComponent,
        NgOptimizedImage
    ],
    templateUrl: "./remediation-plan.component.html",
    styleUrls: ["./remediation-plan.component.scss"],
})
export class RemediationPlanComponent implements OnInit, OnDestroy {
    @Input() mode: any;
    includeCompany: string[] = ['*'];
    selectedCompanies: number[] = [];
    public companyCtrl: FormControl = new FormControl();
    public companyFilterCtrl: FormControl = new FormControl();
    @ViewChild("drawer") drawer: MatDrawer;
    @ViewChild('remData', { static: true }) remData: ElementRef;
    @Input() asset_id: any = undefined;
    @Input() asset_name: any = undefined;
    @Input() asset_type: any = undefined;
    @Input() cAsset: any = undefined;

    redhat_platform: any = ['rhel'];
    eppscore: any[] = [];
    selectedvalue: string = '*';
    drawerPosition: "start" | "end" = "end";
    drawerMode: "over" | "side" = "over";
    drawerOpened: boolean = false;
    isNoProblem: boolean = false;
    public searching = false;
    searchTxt: any = 'Search Company';
    reasonsList: string[] = [
        "Justified for business reasons",
        "False Positive",
        "Compensating control in place",
        "Other",
    ];
    internalUsers: any = [];
    daterange = { start: "", end: "" };
    suppressDataRegistry: any = {};
    solutions: any = [];
    actionData: any = {};
    descriptionType: any = '';
    productData: any = [];
    currentList: any;
    initial_description: any = "";
    companyId: any;
    allData: any = [];
    cView: any = "";
    sview: any = "";
    rView: any = "rp";
    osOptions: string[] = [];
    linuxosOptions: string[] = [];
    selectedOsName: string[] = ['*'];
    selectedOslinux: string[] = ['*'];
    highlighted = false;
    isRemedTable: any = true;
    showTable = false;
    remeTableOptions: any = {};
    SoftwareremediationsviewTableOptions: any = {};
    originalRemData: any = [];
    insideCompanies: boolean;
    companiesAffectedAssets: any = [];
    asset_online_status: any = '';
    currentData: any = {};
    selectedData: any = [];
    companiesId: any;
    minDate: any;
    maxDate: any;
    patch_type: any = "";
    patchLaterData: any = {};
    successIcon: any = "/assets/images/on.png";
    failureIcon: any = "/assets/images/off.png";
    appPatchTableOptions: any = {};
    patchingDetails: any = { assets: [], companies: [], excludecompany: [], exclude_tags: [], include_tags: [], included_application: [], execluded_application: [] };
    assets: any = [];
    severityFilter = [
        { name: 'All', value: '' },
        { name: 'Critical', value: 1 },
        { name: 'High', value: 2 },
        { name: 'Medium', value: 3 },
        { name: 'Low', value: 4 },
    ]
    pendingRemediationSoftwareTableOptions: any = {};

    pendingRemediationTableOptions: any = {};

    remediatedSoftwareTableOptions: any = {};

    remediatedTableOptions: any = {};

    // SoftwareremediationsviewTableOptions: any = {
    //     columns: [

    //         {
    //             header: "Action",
    //             columnDef: "actions",
    //             "width": "120px",
    //             align: "left",
    //             cType: "string",
    //             filter: "remeaction",
    //             cell: "(element: any) => `${element.action}`",
    //             order: 0,
    //             isHtml: true,
    //             htmlIcon: true,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //             isDropdown: true,
    //             dataSource: [
    //                 { text: 'OS Update', value: 'OS Update' },
    //                 { text: 'Run remediation script', value: 'Run remediation script' },
    //                 { text: 'Software Patch', value: 'Software Patch' },
    //                 { text: 'Uninstall', value: 'Uninstall' },
    //                 { text: 'Update', value: 'Update' }
    //             ],
    //         },
    //         {
    //             header: "Product",
    //             columnDef: "product",
    //             "width": "150px",
    //             cType: "string",
    //             filter: "",
    //             cell: "(element: any) => `${element.product}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isHtml: true,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             htmlIcon: true,
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "Affected Companies",
    //             columnDef: "affected_companies",
    //             cType: "number",
    //             filter: "",
    //             cell: "(element: any) => `${element.affected_companies}`",
    //             order: 0,
    //             align: "center",
    //             isHyperlink: true,
    //             visible: !this.asset_id,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //             width: '120px'
    //         },
    //         {
    //             header: "Affected Assets",
    //             columnDef: "affected_assets",
    //             cType: "number",
    //             filter: "",
    //             cell: "(element: any) => `${element.affected_assets}`",
    //             order: 0,
    //             align: "center",
    //             isHyperlink: true,
    //             visible: !this.asset_id,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //             width: '100px'
    //         },
    //         {
    //             header: "OS Name",
    //             columnDef: "os_name",
    //             cType: "string",
    //             width: '100px',
    //             isHyperlink: false,
    //             filter: "",
    //             cell: "(element: any) => `${element.os_name}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "EPSS Score",
    //             rangeColor: true,
    //             cType: "number",
    //             width: "w-[80px]",
    //             columnDef: "epss_score",
    //             filter: "tofixed",
    //             cell: "(element: any) => `${element.epss_score}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             isEpssScore: true,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: 'Severity',
    //             cType: '',
    //             columnDef: 'severity',
    //             iscolumnSearch: true,
    //             filter: '', width: '100px',
    //             isColoredCntrl: true,
    //             isCustomText: true,
    //             visible: true,
    //             order: 5,
    //             isSort: true,
    //             isDropdown: true,
    //             isSeverity: true,
    //             dataSource: [
    //                 { text: 'Critical', value: 'Critical' },
    //                 { text: 'High', value: 'High' },
    //                 { text: 'Medium', value: 'Medium' },
    //                 { text: 'Low', value: 'Low' }
    //             ],
    //         },
    //         {
    //             header: "KB/Fix",
    //             columnDef: "fix",
    //             width: '150px',
    //             cType: "string",
    //             isHyperlink: true,
    //             filter: "",
    //             cell: "(element: any) => `${element.fix}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             class: "",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "Total Vulnerabilities",
    //             cType: "number",
    //             width: "w-[80px]",
    //             align: "center",
    //             columnDef: "total_problems",
    //             isVulnerability: true,
    //             is_total_problems: true,
    //             filter: "",
    //             cell: "(element: any) => `${element.total_problems}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             isClass: true,
    //             class: "cs-critical",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             isCustomText: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "Critical",
    //             cType: "number",
    //             width: "w-[80px]",
    //             columnDef: "critical_problems",
    //             isVulnerability: true,
    //             is_critical_problems: true,
    //             customFilter: "",
    //             cell: "(element: any) => `${element.critical_problems}`",
    //             visible: true,
    //             order: 0,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             isClass: true,
    //             class: "cs-critical",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             isCustomText: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "High",
    //             cType: "number",
    //             width: "w-[80px]",
    //             columnDef: "high_problems",
    //             isVulnerability: true,
    //             is_high_problems: true,
    //             filter: "",
    //             cell: "(element: any) => `${element.high_problems}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             isClass: true,
    //             class: "cs-high",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             isCustomText: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "Medium",
    //             cType: "number",
    //             width: "w-[80px]",
    //             columnDef: "medium_problems",
    //             isVulnerability: true,
    //             is_medium_problems: true,
    //             filter: "",
    //             cell: "(element: any) => `${element.medium_problems}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             isClass: true,
    //             class: "cs-medium",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             isCustomText: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         },
    //         {
    //             header: "Low",
    //             cType: "number",
    //             width: "w-[80px]",
    //             columnDef: "low_problems",
    //             isVulnerability: true,
    //             is_low_problems: true,
    //             filter: "",
    //             cell: "(element: any) => `${element.low_problems}`",
    //             order: 0,
    //             visible: true,
    //             isToolTip: false,
    //             isToolTipCol: "",
    //             hasMultiData: false,
    //             isClass: true,
    //             class: "cs-low",
    //             color: "",
    //             isProgressCntrl: false,
    //             isColoredCntrl: false,
    //             isCustomText: false,
    //             colList: [],
    //             isfaicon: false,
    //             isAddingText: false,
    //             addingText: "",
    //             img: false,
    //             imgPath: "",
    //             isSort: true,
    //             iscolumnSearch: false,
    //         }
    //     ],
    //     sortOptions: { active: 'fix', direction: 'asc' },
    //     _pageData: [],
    //     tableOptions: {
    //         title: 'Linux Remediation Plan',
    //         isServerSide: false,
    //         selectText: 'plans',
    //         loading: true,
    //         floatingFilter: true,
    //         rowSelection: true,
    //         showAction: true,
    //         isHideGlobal: true,
    //         isCustomSort: true,
    //         apiDownload: true,
    //         hideDownload: true,
    //         isDefaultSearch: true,
    //         isDefaultTagFilter: true,
    //         isDefaultKeySearch: true,
    //         isUserRestCheck: (!this.asset_id) ? true : false,
    //         isNoAdd: (!this.asset_id) ? true : false,
    //         actionMenuItems: [
    //             {
    //                 text: 'Integration Action',
    //                 icon: "heroicons_outline:ticket",
    //                 callback: 'suppressFn',
    //                 isGlobal: true,
    //                 hideLocal: (this.cs.currentScope === '*') ? true : false,
    //                 target: "integration_action",
    //             }
    //         ],
    //         pagination: true,
    //         pageOptions: [5, 10, 25, 100],
    //         pageSize: 5,
    //         search: false,
    //         showhideList: true,
    //         refreshData: true,
    //         showFilter: true,
    //         showTagFilter: false,
    //         exportExcel: true,
    //         add: false,
    //         columnSearch: false,
    //         compareData: false,
    //         filterDownload: false,
    //         serverSide: {
    //             url: '',
    //             condition: ''
    //         },
    //     },
    //     customText: [
    //         {
    //             status: true,
    //             DisplayText: 'Yes',
    //             class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
    //         },
    //         {
    //             status: false,
    //             DisplayText: 'No',
    //             class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

    //         },
    //         {
    //             name: 'epss_vuls',
    //             customText: [
    //                 {
    //                     range: { from: 0.0, to: 0.85 },
    //                     color: '#67ace1',
    //                     'text-color': '#000000'
    //                 },
    //                 {
    //                     range: { from: 0.85, to: 0.90 },
    //                     color: '#f8c851',
    //                     'text-color': '#000000'
    //                 },
    //                 {
    //                     range: { from: 0.90, to: 0.95 },
    //                     color: '#f18c43',
    //                     'text-color': '#000000'
    //                 },
    //                 {
    //                     range: { from: 0.95, to: 1 },
    //                     color: '#92243e',
    //                     'text-color': '#ffffff'
    //                 },
    //             ]
    //         },
    //         {
    //             status: "Critical",
    //             DisplayText: "CRITICAL",
    //             color: "#92243e",
    //             "text-color": "#ffffff",
    //         },
    //         {
    //             status: "High",
    //             DisplayText: "HIGH",
    //             color: "#de4a50",
    //             "text-color": "#ffffff",
    //         },
    //         {
    //             status: "Medium",
    //             DisplayText: "MEDIUM",
    //             color: "#f18c43",
    //             "text-color": "#000000",
    //         },
    //         {
    //             status: "Low",
    //             DisplayText: "LOW",
    //             color: "#f8c851",
    //             "text-color": "#000000",
    //         },
    //         {
    //             status: "Info",
    //             DisplayText: "INFO",
    //             color: "#67ace1",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 0, to: 0 },
    //             color: "#c2ffc4",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 1, to: 4.9 },
    //             color: "#ffe24b",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 5, to: 8.9 },
    //             color: "#ec9b0c",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 9, to: 10 },
    //             color: "#ff6546",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 0, to: 0.49 },
    //             color: "#6fb8f9",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 0.5, to: 0.74 },
    //             color: "#FCE94E",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 0.75, to: 1 },
    //             color: "#EB973E",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 1, to: 4.9 },
    //             color: "#ffe24b",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 5, to: 8.9 },
    //             color: "#ec9b0c",
    //             "text-color": "#000000",
    //         },
    //         {
    //             range: { from: 9, to: 10 },
    //             color: "#ff6546",
    //             "text-color": "#000000",
    //         },
    //     ],
    //     changeValue: new Subject<any>(),
    // }

    SoftwareremediatedviewTableOptions: any = {
        columns: [
            {
                header: "Product",
                columnDef: "product",
                "width": "150px",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.product}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isHtml: true,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                htmlIcon: true,
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: "Affected Companies",
                columnDef: "affected_companies",
                cType: "number",
                filter: "",
                cell: "(element: any) => `${element.affected_companies}`",
                order: 0,
                align: "center",
                isHyperlink: true,
                visible: !this.asset_id,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
                width: '80px'
            },
            {
                header: "Affected Assets",
                columnDef: "affected_assets",
                cType: "number",
                filter: "",
                cell: "(element: any) => `${element.affected_assets}`",
                order: 0,
                align: "center",
                isHyperlink: true,
                visible: !this.asset_id,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
                width: '60px'
            },
            {
                header: 'Severity',
                cType: '',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '100px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 5,
                isSort: true,
                isDropdown: true,
                isSeverity: true,
                dataSource: [
                    { text: 'Critical', value: 'Critical' },
                    { text: 'High', value: 'High' },
                    { text: 'Medium', value: 'Medium' },
                    { text: 'Low', value: 'Low' }
                ],
            },
            {
                header: "KB/Fix",
                columnDef: "fix",
                width: '150px',
                cType: "string",
                isHyperlink: true,
                filter: "",
                cell: "(element: any) => `${element.fix}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: 'Severity', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Linux Remediated Records',
            isServerSide: false,
            selectText: 'linuxremediated',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '',
                condition: '',
            },
            id: 'softwareremediated'
        },
        customText: [
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0.49 },
                color: "#6fb8f9",
                "text-color": "#000000",
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: "#FCE94E",
                "text-color": "#000000",
            },
            {
                range: { from: 0.75, to: 1 },
                color: "#EB973E",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            }
        ],

        changeValue: new Subject<any>(),
    };


    SoftwareremediatedassetviewTableOptions: any = {
        columns: [
            {
                header: "Product",
                columnDef: "product",
                "width": "150px",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.product}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isHtml: true,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                htmlIcon: true,
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: 'Severity',
                cType: '',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '100px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 5,
                isSort: true,
                isDropdown: true,
                isSeverity: true,
                dataSource: [
                    { text: 'Critical', value: 'Critical' },
                    { text: 'High', value: 'High' },
                    { text: 'Medium', value: 'Medium' },
                    { text: 'Low', value: 'Low' }
                ],
            },
            {
                header: "KB/Fix",
                columnDef: "fix",
                width: '150px',
                cType: "string",
                isHyperlink: true,
                filter: "",
                cell: "(element: any) => `${element.fix}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: 'Severity', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Linux Remediated Records',
            isServerSide: false,
            selectText: 'linuxremediated',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '',
                condition: ''
            },
            id: 'softwareremediatedassetview'
        },
        customText: [
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0.49 },
                color: "#6fb8f9",
                "text-color": "#000000",
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: "#FCE94E",
                "text-color": "#000000",
            },
            {
                range: { from: 0.75, to: 1 },
                color: "#EB973E",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            }
        ],

        changeValue: new Subject<any>(),
    };


    SoftwareremediationsassetviewTableOptions: any = {
        columns: [
            {
                header: "Product",
                columnDef: "product",
                "width": "150px",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.product}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isHtml: true,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                htmlIcon: true,
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: 'Severity',
                cType: '',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '100px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 5,
                isSort: true,
                isDropdown: true,
                isSeverity: true,
                dataSource: [
                    { text: 'Critical', value: 'Critical' },
                    { text: 'High', value: 'High' },
                    { text: 'Medium', value: 'Medium' },
                    { text: 'Low', value: 'Low' }
                ],
            },
            {
                header: "KB/Fix",
                columnDef: "fix",
                width: '150px',
                cType: "string",
                isHyperlink: true,
                filter: "",
                cell: "(element: any) => `${element.fix}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: 'fix', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Linux Remediation Plan',
            isServerSide: false,
            selectText: '',
            loading: false,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '',
                condition: ''
            },
        },
        customText: [
            {
                status: true,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

            },
            {
                name: 'epss_vuls',
                customText: [
                    {
                        range: { from: 0.0, to: 0.85 },
                        color: '#67ace1',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.85, to: 0.90 },
                        color: '#f8c851',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.90, to: 0.95 },
                        color: '#f18c43',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.95, to: 1 },
                        color: '#92243e',
                        'text-color': '#ffffff'
                    },
                ]
            },
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0.49 },
                color: "#6fb8f9",
                "text-color": "#000000",
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: "#FCE94E",
                "text-color": "#000000",
            },
            {
                range: { from: 0.75, to: 1 },
                color: "#EB973E",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
        ],
        changeValue: new Subject<any>(),
    }

    companiesTableOptions: any = {
        columns: [
            {
                header: "Affected Assets",
                columnDef: "affected_assets",
                cType: "number",
                filter: "",
                isHyperlink: true,
                cell: "(element: any) => `${element.affected_assets}`",
                order: 0,
                visible: !this.asset_id,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: "Company Name",
                columnDef: "name",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.name}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: "name", direction: "asc" },
        _pageData: [],
        tableOptions: {
            title: "Affected Companies",
            isServerSide: false,
            selectText: "companies",
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: "/r/report_queries/remediation_companies",
                condition: "",
                isGlobal: true,
                type: "post",
                params: {},
            },
        },
        customText: [
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
        ],
        changeValue: new Subject<any>(),
    };

    importance: any = { 25: "LOW", 50: "MEDIUM", 75: "HIGH", 100: "CRITICAL" };
    assetsTableOptions: any = {
        columns: [
            {
                "header": "Status",
                "columnDef": "online_status",
                "filter": "",
                width: 'w-[80px]',
                "cType": "agg",
                "cell": "(element: any) => `${element.online_status}`",
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                selectFilter: true,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: true,
                iscolumnSearch: false,
                statusicon: true,
                success: true,
                failure: false,
                successIconPath: '/assets/images/on.png',
                successToolTip: 'Online',
                failureIconPath: '/assets/images/off.png',
                failureToolTip: 'Offline',
                isDropdown: true,
                clipboard: false,
                dataSource: [
                    { text: 'Online', value: 'true' },
                    { text: 'Offline', value: 'false' }
                ],
            },
            {
                "header": "Host Name",
                "columnDef": "host_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.host_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false,
                "clipboard": true
            },
            {
                header: 'Importance',
                cType: 'number',
                columnDef: 'importance',
                width: 'w-[80px]',
                iscolumnSearch: true,
                selectFilter: true,
                selectFilterArr: [
                    { name: 'Low', id: 'AssetLow', value: 25 },
                    { name: 'Medium', id: 'AssetMedium', value: 50 },
                    { name: 'High', id: 'AssetHigh', value: 75 },
                    { name: 'Critical', id: 'AssetCritical', value: 100 }
                ],
                filter: '',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 10,
                isSort: true
            },
            {
                "header": "IP",
                "columnDef": "ip",
                "filter": "",
                "cell": "(element: any) => `${element.ip}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false,
                "clipboard": false
            },
            {
                "header": "Company Name",
                "columnDef": "company_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.company_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: "host_name", direction: "asc" },
        _pageData: [],
        tableOptions: {
            title: "Affected Assets",
            isServerSide: false,
            selectText: "assets",
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            customPageSize: true,
            total_key: 'total_count',
            serverSide: {
                url: '/r/report_queries/remediation_velocity_application_asset_details',
                condition: "",
            },
        },
        customText: [
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
            {
                status: 25,
                DisplayText: "Low",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: 50,
                DisplayText: "Medium",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: 75,
                DisplayText: "High",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: 100,
                DisplayText: "Critical",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: true,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: false,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
        ],
        sColumns: [
            {
                header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                isCustomFilter: [
                    {
                        "value": "contains",
                        "key": "Equal"
                    },
                    {
                        "value": "notcontains",
                        "key": "Not Equal"
                    },
                ],
                dataSource: [
                    { text: 'Yes', value: 'false' },
                    { text: 'No', value: 'true' }
                ],
            },
        ],
        changeValue: new Subject<any>(),
        id: 'velocity_rem_by_app_asset'
    };

    companiesTableOptions1: any = {
        columns: [
            {
                header: "Affected Assets",
                columnDef: "affected_assets",
                cType: "number",
                filter: "",
                isHyperlink: true,
                cell: "(element: any) => `${element.affected_assets}`",
                order: 0,
                visible: !this.asset_id,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: "Company Name",
                columnDef: "name",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.name}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: "name", direction: "asc" },
        _pageData: [],
        tableOptions: {
            title: "Affected Companies",
            isServerSide: false,
            selectText: "companies",
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: "",
                condition: "",
                isGlobal: true,
                type: "post",
                params: {},
            },
        },
        customText: [
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
        ],
        changeValue: new Subject<any>(),
    };

    companiesTableOptions2: any = {
        columns: [
            {
                header: "Affected Assets",
                columnDef: "affected_assets",
                cType: "number",
                filter: "",
                isHyperlink: true,
                cell: "(element: any) => `${element.affected_assets}`",
                order: 0,
                visible: !this.asset_id,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: "Company Name",
                columnDef: "name",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.name}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: "name", direction: "asc" },
        _pageData: [],
        tableOptions: {
            title: "Affected Companies",
            isServerSide: false,
            selectText: "companies",
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: "",
                condition: "",
                isGlobal: true,
                type: "post",
                params: {},
            },
        },
        customText: [
            {
                status: "Critical",
                DisplayText: "CRITICAL",
                color: "#92243e",
                "text-color": "#ffffff",
            },
            {
                status: "High",
                DisplayText: "HIGH",
                color: "#de4a50",
                "text-color": "#ffffff",
            },
            {
                status: "Medium",
                DisplayText: "MEDIUM",
                color: "#f18c43",
                "text-color": "#000000",
            },
            {
                status: "Low",
                DisplayText: "LOW",
                color: "#f8c851",
                "text-color": "#000000",
            },
            {
                status: "Info",
                DisplayText: "INFO",
                color: "#67ace1",
                "text-color": "#000000",
            },
            {
                range: { from: 0, to: 0 },
                color: "#c2ffc4",
                "text-color": "#000000",
            },
            {
                range: { from: 1, to: 4.9 },
                color: "#ffe24b",
                "text-color": "#000000",
            },
            {
                range: { from: 5, to: 8.9 },
                color: "#ec9b0c",
                "text-color": "#000000",
            },
            {
                range: { from: 9, to: 10 },
                color: "#ff6546",
                "text-color": "#000000",
            },
        ],
        changeValue: new Subject<any>(),
    };


    tenantId: any = "";
    sups: any;
    suppressData: any;
    currentRemediation: any = {};
    currentRemediation1: any = {};
    currentFixlinuxremedation: any = {};
    currentFixlinuxremedated: any = {}
    viewchecking = false;
    linuxremeviewcheck = false;
    currentRecordType: string = '';
    solutiontabcheck = false;
    fromMinEndDate: any;
    fromDate: any = new Date();
    currentUser: any = "";
    @ViewChild("codeEle") codeEle!: ElementRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    company_id: any = "";
    currentFixRemediation: any;
    manualTicket = false;
    actEve: Subscription;
    sortedData: any = [];
    statusFilterKey: any = 'pending';
    mappedIntegrations: any = [];
    mappedIntegrationsTicketLinks: any = [];
    manualTicketDescription: any;
    manualTicketSummary: any;
    Objectkeys = Object.keys;
    currentStep = 0;
    onlinelinux: any;
    linuxfilterstatusviewchecking = false;
    totalSteps = 10;
    asset_list: any = [];
    formattedData: any[] = [];
    assetEvidence: any = [];
    timelineCount: any = null;
    epssCondition: any = '';
    epssscoreRange: string = '';
    eppscoreevidence = false;
    downloadcheckinggetdatafn = false;
    limitchangesdownload = false;
    integrationAsset: any = null;
    is_include_filter = true;
    isLoading = false;
    isTagFilterCalled = false;
    minEndDate: string;
    severity_filter_pending: any = '';
    severity_filter_software: any = '';
    tagfilter: any = {};
    isCompanyName = false;

    constructor(
        private highlightService: HighlightService,
        public modalService: ModalService,
        public cs: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private loaderService: LoaderService,
        private route: ActivatedRoute,
        public _bs: BaseRequestService,
        private toast: MyToastrService,
        public uS: UserService,
        private dateF: UtcToLocaleHTML,
        public integrationActionService: IntegrationActionsService,
        private datePipe: DatePipe
    ) {
        this.setMinEndDate();
        this.pendingRemediationSoftwareTableOptions = {
            columns: [
                {
                    header: "Product",
                    columnDef: "product",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.product}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "0 to 7 Days",
                    columnDef: "affected_software_lessthan_7_days",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_lessthan_7_days}`",
                    order: 0,
                    visible: true,
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_low_problems: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-low",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "8 to 15 Days",
                    columnDef: "affected_software_8_15_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_medium_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_8_15_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-medium",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "16 to 30 Days",
                    columnDef: "affected_software_16_30_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_high_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_16_30_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-high",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "> 30 Days",
                    columnDef: "affected_software_graterthan_30_days",
                    cType: "number",
                    filter: "",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_critical_problems: true,
                    cell: "(element: any) => `${element.affected_software_graterthan_30_days}`",
                    visible: true,
                    order: 0,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: 'Severity',
                    cType: 'agg',
                    columnDef: 'severity',
                    iscolumnSearch: true,
                    isSeverity: true,
                    filter: '', width: '100px',
                    isColoredCntrl: true,
                    isCustomText: true,
                    visible: true,
                    order: 5,
                    isSort: true
                },
            ],
            sortOptions: { active: "product", direction: "asc" },
            _pageData: [],
            tableOptions: {
                title: "Pending Remediation By Application",
                isServerSide: false,
                selectText: "remediation",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: false,
                actionMenuItems: [],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: false,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                total_key: 'total_count',
                serverSide: {
                    url: "/r/report_queries/remediation_velocity_application",
                    condition: `is_assessment is ${this.cs.is_assessment}`,
                    type: "get",
                },
                id: 'remediation_velocity_application'
            },
            customText: [
                {
                    range: { from: 0, to: 0 },
                    color: "#c2ffc4",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 40 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 41, to: 60 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 61, to: 90 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 91, to: 99999 },
                    color: "#92243e",
                    "text-color": "#ffffff",
                },
                {
                    status: 'Critical',
                    DisplayText: 'CRITICAL',
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
                {
                    status: 'High',
                    DisplayText: 'HIGH',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 'Medium',
                    DisplayText: 'MEDIUM',
                    color: '#f18c43',
                    'text-color': '#000000'
                },
                {
                    status: 'Low',
                    DisplayText: 'LOW',
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    status: 'Info',
                    DisplayText: 'INFO',
                    color: '#67ace1',
                    'text-color': '#000000'
                },
            ],
            changeValue: new Subject<any>()
        };
        this.pendingRemediationTableOptions = {
            columns: [
                {
                    header: "Company Name",
                    columnDef: "name",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.name}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "0 to 7 Days",
                    columnDef: "affected_software_lessthan_7_days",
                    cType: "number",
                    filter: "",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_low_problems: true,
                    cell: "(element: any) => `${element.affected_software_lessthan_7_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-low",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "8 to 15 Days",
                    columnDef: "affected_software_8_15_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_medium_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_8_15_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-medium",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "16 to 30 Days",
                    columnDef: "affected_software_16_30_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_high_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_16_30_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-high",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "> 30 Days",
                    columnDef: "affected_software_graterthan_30_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_critical_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_graterthan_30_days}`",
                    visible: true,
                    order: 0,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: 'Severity',
                    cType: 'agg',
                    columnDef: 'severity',
                    isSeverity: true,
                    iscolumnSearch: true,
                    filter: '', width: '100px',
                    isColoredCntrl: true,
                    isCustomText: true,
                    visible: true,
                    order: 5,
                    isSort: true
                },
            ],
            sortOptions: { active: "name", direction: "asc" },
            _pageData: [],
            tableOptions: {
                title: "Pending Remediation By Company",
                isServerSide: false,
                selectText: "remediation",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: false,
                actionMenuItems: [],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: false,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                total_key: 'total_count',
                serverSide: {
                    url: "/r/report_queries/remediation_velocity_company",
                    condition: `is_assessment is ${this.cs.is_assessment}`,
                    type: "get",
                },
                id: 'remediation_velocity_company'
            },
            customText: [
                {
                    range: { from: 0, to: 0 },
                    color: "#c2ffc4",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 40 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 41, to: 60 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 61, to: 90 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 91, to: 99999 },
                    color: "#92243e",
                    "text-color": "#ffffff",
                },
                {
                    status: 'Critical',
                    DisplayText: 'CRITICAL',
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
                {
                    status: 'High',
                    DisplayText: 'HIGH',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 'Medium',
                    DisplayText: 'MEDIUM',
                    color: '#f18c43',
                    'text-color': '#000000'
                },
                {
                    status: 'Low',
                    DisplayText: 'LOW',
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    status: 'Info',
                    DisplayText: 'INFO',
                    color: '#67ace1',
                    'text-color': '#000000'
                },
            ],
            changeValue: new Subject<any>(),
        };
        this.remediatedSoftwareTableOptions = {
            columns: [
                {
                    header: "Product",
                    columnDef: "product",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.product}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "0 to 7 Days",
                    columnDef: "affected_software_lessthan_7_days",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_lessthan_7_days}`",
                    order: 0,
                    visible: true,
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_low_problems: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-low",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "8 to 15 Days",
                    columnDef: "affected_software_8_15_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_medium_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_8_15_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-medium",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "16 to 30 Days",
                    columnDef: "affected_software_16_30_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_high_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_16_30_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-high",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "> 30 Days",
                    columnDef: "affected_software_graterthan_30_days",
                    cType: "number",
                    filter: "",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_critical_problems: true,
                    cell: "(element: any) => `${element.affected_software_graterthan_30_days}`",
                    visible: true,
                    order: 0,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                }
            ],
            sortOptions: { active: "product", direction: "asc" },
            _pageData: [],
            tableOptions: {
                title: "Remediated By Application",
                isServerSide: false,
                selectText: "remediation",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: false,
                actionMenuItems: [],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: false,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                total_key: 'total_count',
                serverSide: {
                    url: "/r/report_queries/remediated_velocity_application",
                    condition: `is_assessment is ${this.cs.is_assessment}`,
                    type: "get",
                },
                id: 'remediated_velocity_application'
            },
            customText: [
                {
                    range: { from: 0, to: 0 },
                    color: "#c2ffc4",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 40 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 41, to: 60 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 61, to: 90 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 91, to: 99999 },
                    color: "#92243e",
                    "text-color": "#ffffff",
                },
                {
                    status: 'Critical',
                    DisplayText: 'CRITICAL',
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
                {
                    status: 'High',
                    DisplayText: 'HIGH',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 'Medium',
                    DisplayText: 'MEDIUM',
                    color: '#f18c43',
                    'text-color': '#000000'
                },
                {
                    status: 'Low',
                    DisplayText: 'LOW',
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    status: 'Info',
                    DisplayText: 'INFO',
                    color: '#67ace1',
                    'text-color': '#000000'
                },
            ],
            changeValue: new Subject<any>()
        };
        this.remediatedTableOptions = {
            columns: [
                {
                    header: "Company Name",
                    columnDef: "name",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.name}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "0 to 7 Days",
                    columnDef: "affected_software_lessthan_7_days",
                    cType: "number",
                    filter: "",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_low_problems: true,
                    cell: "(element: any) => `${element.affected_software_lessthan_7_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-low",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "8 to 15 Days",
                    columnDef: "affected_software_8_15_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_medium_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_8_15_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-medium",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "16 to 30 Days",
                    columnDef: "affected_software_16_30_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_high_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_16_30_days}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-high",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "> 30 Days",
                    columnDef: "affected_software_graterthan_30_days",
                    cType: "number",
                    isCusHyperlink: true,
                    isVulnerability: true,
                    is_critical_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.affected_software_graterthan_30_days}`",
                    visible: true,
                    order: 0,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
            ],
            sortOptions: { active: "name", direction: "asc" },
            _pageData: [],
            tableOptions: {
                title: "Remediated By Company",
                isServerSide: false,
                selectText: "remediation",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: false,
                actionMenuItems: [],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: false,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                total_key: 'total_count',
                serverSide: {
                    url: "/r/report_queries/remediated_velocity_company",
                    condition: `is_assessment is ${this.cs.is_assessment}`,
                    type: "get",
                },
                id: 'remediated_velocity_company'
            },
            customText: [
                {
                    range: { from: 0, to: 0 },
                    color: "#c2ffc4",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 40 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 41, to: 60 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 61, to: 90 },
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    range: { from: 91, to: 99999 },
                    color: "#92243e",
                    "text-color": "#ffffff",
                },
                {
                    status: 'Critical',
                    DisplayText: 'CRITICAL',
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
                {
                    status: 'High',
                    DisplayText: 'HIGH',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 'Medium',
                    DisplayText: 'MEDIUM',
                    color: '#f18c43',
                    'text-color': '#000000'
                },
                {
                    status: 'Low',
                    DisplayText: 'LOW',
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    status: 'Info',
                    DisplayText: 'INFO',
                    color: '#67ace1',
                    'text-color': '#000000'
                },
            ],
            changeValue: new Subject<any>(),
        };
        this.cs.selectedSiteChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.companyId = res !== "*" ? res : undefined;
                if (this.drawer.opened) {
                    this.drawer.toggle().then((r) => console.log(r));
                }
                this.selectedvalue = "*";
                this.selectedOsName = ['*'];
                this.selectedOslinux = ['*'];
                this.epssCondition = '';
                this.tagfilter = {};
                this.includeCompany = res !== "*" ? [] : ['*'];
                if (this.isRedHatOs(this.cAsset)) {
                    this.rView = "sw_remediations_view";
                } else {
                    this.rView = "rp";
                }
                this.distinct_os_name();
                this.distinct_linuxos_name();
                // this.getRemediation();
                // this.softwareviewsw();
                this.Softwareremediatedassetview();
                this.Softwareremediationassetview();
                this.softwareremediatedview();
            });
        this.cs.remRefreshCalBack
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                setTimeout(() => {
                    this.getRemediation();
                    this.cs.selectedTableRows['Remediation Plan'] = [];
                    this.loaderService.display(false);
                }, 5000);

            });
        this.cs.remFilterCal
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                setTimeout(() => {
                    this.remediationFilterCal(res.value);
                });
            });
        this.cs.remediationSuppress
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                const tableName = this.remeTableOptions.tableOptions.title;
                if (
                    this.cs.selectedTableRows[tableName] &&
                    this.cs.selectedTableRows[tableName].length
                ) {
                    this.suppressData = {
                        problem_id: this.cs.selectedTableRows[tableName],
                        suppression_status: "Open",
                        internal_users: [],
                        reason: "",
                        approve_type: "external",
                        reasonId: "1",
                        external_user: [],
                        suppressed_on: "",
                        suppressed_till: "",
                        is_permanent: false
                    };
                    if (uS._user_roles.indexOf('admin') > -1 || uS._user_roles.indexOf('approver') > -1) {
                        this.getInternalUsernal();
                        this.suppressData.approve_type = '';
                    } else {
                        this.suppressData.internal_type === 'external';
                    }
                    this.modalService.open("suppress_problemss2");
                } else {
                    this.toast.sToast(
                        "info",
                        "Please select at least one problem for suppression."
                    );
                }
            });
    }


    datechange(data: any, event: any) {
        if (data == "start") {
            this.suppressData.suppressed_on = `${moment(event.value).format(
                "YYYY-MM-DD"
            )} 00:00:00`;
        } else {
            this.suppressData.suppressed_till = `${moment(event.value).format(
                "YYYY-MM-DD"
            )} 23:59:59`;
        }
    }

    changeEvent(event: any) {
        this.fromMinEndDate = this.cs.getLastDay(1, event.value);
    }

    getInternalUsernal(): void {
        const condition: any = {
            approved: true,
        };
        this._bs
            .doRequest(`/r/user/get_users`, "get")
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status) {
                    const filterUser: any = this.cs.filterUsers(result.data, (this.cs.currentScope !== '*') ? parseInt(this.cs.currentScope.id) : null);
                    if (filterUser && filterUser.length) {
                        this.internalUsers = filterUser.filter((x: any) => x.email !== this.currentUser);
                    }
                } else {
                    const data = result.message ? result.message : result.data;
                    this.toast.sToast("error", data);
                }
            });
    }

    setMinEndDate() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow
        this.minEndDate = this.datePipe.transform(tomorrow, 'yyyy-MM-dd')!;
    }
    suppressProblem(suppress: any): void {
        const reqData = [];
        const suppressData: any = Object.assign({}, suppress);
        suppressData.external_user =
            suppressData.external_user && suppressData.external_user.length
                ? suppressData.external_user.split(",")
                : [];
        if (suppressData.reasonId !== "Other") {
            suppressData.reason = suppressData.reasonId;
        }
        if (suppressData.approve_type === "self") {
            suppressData.internal_users = [this.currentUser];
        }
        const status = suppressData.approve_type === "self" ? "Approved" : "Open";
        if (suppressData.is_permanent) {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = null;
        } else {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = this.cs.dateChange(suppressData.suppressed_till, 'end');
        }
        delete suppressData.reasonId;
        delete suppressData.internal_type;
        delete suppressData.problem_id;
        delete suppressData.approve_type;
        this.suppressData.problem_id.forEach((i: any) => {
            reqData.push({
                ...suppressData,
                ...{
                    suppression_status: status,
                    solution_id: i.solution_id ? i.solution_id : i.id,
                    asset_id: this.asset_id,
                    asset_name: this.asset_name,
                    company_name:
                        this.cs.currentScope !== "*" ? this.cs.currentScope.name : (this.cAsset) ? this.cAsset.company_name : undefined,
                    company_id:
                        this.cs.currentScope !== "*" ? this.cs.currentScope.id : (this.cAsset) ? this.cAsset.company_id : undefined,
                    problem_name: i.problem_name ? i.problem_name : i.product,
                },
            });
        });
        this.loaderService.display(true);
        this._bs
            .doRequest(
                `/r/exception/suppress_vulnerability/createexception`,
                "post",
                { data: reqData }
            )
            .subscribe(
                (result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        this.toast.sToast(
                            "success",
                            "Suppression workflow initiated successfully. All tickets related to this software were get closed."
                        );
                        this.modalService.close("suppress_problemss2");
                        setTimeout(() => this.getRemediation());
                    } else {
                        const data = result.message ? result.message : result.data;
                        this.toast.sToast("error", data);
                    }
                },
                (error: any) => {
                    this.toast.sToast("error", `${error.message}`, `Error Code ${error.status}`);
                }
            );
    }

    cancelSuppress(): void {
        this.modalService.close("suppress_problemss2");
    }

    

    actionCalllinuxrem($event: any): void {
        this.globalCalllinuxrem({ row: [$event.row], action: $event.action });
    }

    globalCalllinuxrem($event: any): void {
        if ($event.action.target === 'suppressed_records') {
            this.suppressData = {
                problem_id: $event.row,
                suppression_status: "Open",
                internal_users: [],
                reason: "",
                approve_type: "external",
                reasonId: "1",
                external_user: [],
                suppressed_on: "",
                suppressed_till: "",
                is_permanent: false
            };
            if (this.uS._user_roles.indexOf('admin') > -1 || this.uS._user_roles.indexOf('approver') > -1) {
                this.getInternalUsernal();
                this.suppressData.approve_type = '';
            } else {
                this.suppressData.internal_type === 'external';
            }
            this.modalService.open("registrySuppress");
        }
    }


    suppressRegistry(suppress: any): void {
        const reqData = [];
        const suppressData: any = cloneDeep(suppress);
        suppressData.external_user = (suppressData.external_user && suppressData.external_user.length) ? suppressData.external_user.split(',') : [];
        if (suppressData.approve_type === 'self') {
            suppressData.internal_users = [this.currentUser]
        }
        if (suppressData.reasonId !== 'Other') {
            suppressData.reason = suppressData.reasonId;
        }
        const status = (suppressData.approve_type === 'self') ? 'Approved' : 'Open';
        if (suppressData.is_permanent) {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = null;
        } else {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = this.cs.dateChange(suppressData.suppressed_till, 'end');
        }
        delete suppressData.reasonId; delete suppressData.internal_type; delete suppressData.problem_id; delete suppressData.approve_type;
        this.suppressData.problem_id.forEach((item: any, index: number) => {
            const companyIds = Array.isArray(item.company_ids)
                ? item.company_ids.map((id: number) => `${id}`).join(', ')
                : '';
            reqData.push({
                ...suppressData,
                suppression_status: status,
                company_id: companyIds,
                vul_id: item.product 
            });
        });

        this.loaderService.display(true);
        this._bs.doRequest(`/r/exception/custom_vulnerabilities/createregistryexception`, 'post', { data: reqData })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast('success', 'Suppression workflow initiated successfully. All tickets related to this software were get closed.');
                    // this.cs.reloadRegistryProgram.emit('');
                    this.softwareviewsw();
                    this.resetSuppressDataRegistry();
                    this.modalService.close('registrySuppress');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                    this.resetSuppressDataRegistry();
                }
            });
    }


    resetSuppressDataRegistry(): void {
        this.suppressDataRegistry = {
            reasonId: '',
            reason: '',
            approve_type: '',
            internal_type: '',
            internal_users: [],
            external_user: '',
            suppress_comments: '',
            is_permanent: false,
            suppressed_on: null,
            suppressed_till: null,
            problem_id: []
        };
    }


    cancelSuppressRegistry(): void {
        this.modalService.close('registrySuppress')
        this.resetSuppressDataRegistry();
    }


    actionCall($event: any): void {
        this.globalCall({ row: [$event.row], action: $event.action });
    }
    globalCall($event: any): void {
        if ($event.action.target === 'suppressed_records') {
            this.suppressData = {
                problem_id: $event.row,
                suppression_status: "Open",
                internal_users: [],
                reason: "",
                approve_type: "external",
                reasonId: "1",
                external_user: [],
                suppressed_on: "",
                suppressed_till: "",
                is_permanent: false
            };
            if (this.uS._user_roles.indexOf('admin') > -1 || this.uS._user_roles.indexOf('approver') > -1) {
                this.getInternalUsernal();
                this.suppressData.approve_type = '';
            } else {
                this.suppressData.internal_type === 'external';
            }
            this.modalService.open("suppress_problemss2");
        }
        if ($event.action.target === 'integration_action') {
            if (this.rView !== 'rp' && this.rView !== 'sw_remediations_view') {
                return;
            }
            if ($event.row && $event.row.length) {
                {
                    this.loaderService.display(true, 'Preparing data...');
                    this.actionData.initial_description = '';
                    setTimeout(() => {
                        this.productData = [];
                        const len = cloneDeep($event.row);
                        let initial_description = '';
                        let summary = '';
                        let solution_ids = [];
                        let source_ids = [];
                        let asset_ids = []
                        let ticket_id = '';
                        let ticket_number = [];
                        let integration_name = '';
                        this.productData = len;
                        this.integrationAsset = ''
                        len?.map((rem: any) => {
                            if (rem.solution_id) {
                                solution_ids.push(rem.solution_id);
                                source_ids.push(rem.solution_id);
                            }

                            if (rem.problem_name) {
                                source_ids.push(rem.problem_name);
                            }

                            if (this.asset_name) {
                                const name = rem.ticket_status_map.filter((x: any) => (x.ticket_number || x.ticket_id) && x.status.toLowerCase() === 'open')
                                if (name && name.length) {
                                    const selectedTicket = name[name.length - 1];
                                    ticket_id = (selectedTicket.ticket_number && selectedTicket.ticket_id) ? selectedTicket.ticket_number : selectedTicket.ticket_id;
                                    integration_name = (selectedTicket.integration_name) ? selectedTicket.integration_name : '';
                                    ticket_number = [selectedTicket];
                                } else {
                                    ticket_id = '';
                                }
                                const assetNamesString = `Asset Name: ${this.asset_name}\n`;
                                this.integrationAsset = this.asset_name;

                                initial_description += `Product Name: ${rem.product}\n`;
                                initial_description += assetNamesString;
                                if (!rem.fix && !rem.url) {
                                    initial_description += `- Vulnerability Count: ${rem.total_problems}`
                                } else {
                                    initial_description += ` - Fix: ${rem.fix} (${rem.url}) - Vulnerability Count: ${rem.total_problems}`
                                }
                                if (rem.epss_vuls) {
                                    initial_description += ` - EPSS Score: ${+parseFloat(rem.epss_vuls)?.toFixed(2)}\n`;
                                }
                                initial_description += '\n';
                                initial_description += '---------------------------------------------------------------------\n';
                                initial_description += '\n';
                                initial_description += `Vulnerabilities Count: \n`;
                                initial_description += '\n';
                                initial_description += `CRITICAL: ${rem.critical_problems}\n`;
                                initial_description += `HIGH: ${rem.high_problems}\n`;
                                initial_description += `MEDIUM: ${rem.medium_problems}\n`;
                                initial_description += `LOW : ${rem.low_problems}\n`;
                                initial_description += '---------------------------------------------------------------------\n';
                                initial_description += '\n';
                                initial_description += '\n';

                                summary += `Asset Name: ${this.asset_name} - Product Name: ${rem.product}, - Fix: ${rem.fix} - Vulnerability Count: ${rem.total_problems}`;
                                if (rem.epss_vuls) {
                                    summary += `- EPSS Score: ${+parseFloat(rem.epss_vuls)?.toFixed(2)}`;
                                }
                                this.actionData = {
                                    asset_id: null, description: initial_description, summary: summary, solution_ids: solution_ids, source_ids: source_ids, ticket_id: ticket_id, event_type: "Solutions", action_type: 'Remediation Event', integration_name: integration_name, ticket_number: ticket_number, cAsset: this.cAsset
                                }
                                if (this.asset_id) {
                                    this.actionData.asset_id = this.asset_id
                                }

                            } else {
                                const name = rem.ticket_status_map.filter((x: any) => (x.ticket_number || x.ticket_id) && x.status.toLowerCase() === 'open')
                                if (name && name.length) {
                                    const selectedTicket = name[name.length - 1];
                                    ticket_id = (selectedTicket.ticket_number && selectedTicket.ticket_id) ? selectedTicket.ticket_number : selectedTicket.ticket_id;
                                    integration_name = (selectedTicket.integration_name) ? selectedTicket.integration_name : '';
                                    ticket_number = [selectedTicket];
                                } else {
                                    ticket_id = '';
                                }
                                // const params: any = { condition: (rem.asset_ids.length === 1) ? `id=${rem.asset_ids[0]}` : `id in (${rem.asset_ids?.join(', ')})` }
                                const params: any = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: 1000, order_by: 'host_name asc' };
                                if (params.condition === true) {
                                    if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                                        params.condition = `product='${rem.product}' and os_name='${rem.os_name}' and fix='${rem.fix}'  and company_id IN (${this.includeCompany.join(',')})`;
                                    } else {
                                        params.condition = `product='${rem.product}' and os_name='${rem.os_name}' and fix='${rem.fix}' and solution_id='${rem.solution_id}'`;
                                    }
                                } else {
                                    params.condition += ` and product='${rem.product}' and os_name='${rem.os_name}' and fix='${rem.fix}'`;
                                }
                                params.having = `NOT NULL`;
                                this._bs
                                    .doRequest(`/r/report_queries/remediation_plan_asset_details`, "get", null, params)
                                    .pipe(takeUntil(this._unsubscribeAll))
                                    .subscribe((result: any) => {
                                        if (result.status) {
                                            const assetNames = Array.from(new Set(result.data.map((s: any) => s.host_name).filter((x: any) => x !== '')));
                                            asset_ids = Array.from(new Set([...asset_ids, ...result.data.map((s: any) => s.id)]));
                                            this.integrationAsset = assetNames[0];
                                            const assetNamesString = `Asset Name: ${assetNames?.join(', ')}\n`;

                                            initial_description += `Product Name: ${rem.product}\n`;
                                            initial_description += assetNamesString;
                                            if (!rem.fix && !rem.url) {
                                                initial_description += `- Vulnerability Count: ${rem.total_problems}`
                                            } else {
                                                initial_description += ` - Fix: ${rem.fix} (${rem.url}) - Vulnerability Count: ${rem.total_problems}`
                                            }
                                            if (rem.epss_vuls) {
                                                initial_description += ` - EPSS Score: ${+parseFloat(rem.epss_vuls)?.toFixed(2)}\n`;
                                            }
                                            initial_description += '\n';
                                            initial_description += '---------------------------------------------------------------------\n';
                                            initial_description += '\n';
                                            initial_description += `Vulnerabilities Count: \n`;
                                            initial_description += '\n';
                                            initial_description += `CRITICAL: ${rem.critical_problems}\n`;
                                            initial_description += `HIGH: ${rem.high_problems}\n`;
                                            initial_description += `MEDIUM: ${rem.medium_problems}\n`;
                                            initial_description += `LOW : ${rem.low_problems}\n`;
                                            initial_description += '---------------------------------------------------------------------\n';
                                            initial_description += '\n';
                                            initial_description += '\n';

                                            summary += `Product Name: ${rem.product}, - Fix: ${rem.fix} - Vulnerability Count: ${rem.total_problems}`;
                                            if (rem.epss_vuls) {
                                                summary += `- EPSS Score: ${+parseFloat(rem.epss_vuls)?.toFixed(2)}`;
                                            }

                                        }
                                        this.actionData = {
                                            asset_id: null, asset_ids: asset_ids, description: initial_description, summary: summary, solution_ids: solution_ids, source_ids: source_ids, ticket_id: ticket_id, action_type: 'Remediation Event', event_type: "Solutions", integration_name: integration_name, ticket_number: ticket_number, cAsset: this.cAsset
                                        }
                                        if (this.asset_id) {
                                            this.actionData.asset_id = this.asset_id
                                        }

                                    })
                            }
                        });
                        this.integrationActionService.integrationAssetActionShow = false;
                        this.integrationActionService.integrationActionShow = true;
                        setTimeout(() => {
                            this.integrationActionService.integrationActionPopup.next({
                                value: true,
                            });
                            this.loaderService.display(false);
                        }, 4000);

                    }, 1000);
                }
            } else {
                this.toast.sToast(
                    "info", "Please select at least one record from Solutions."
                );
            }
        }

        if ($event.action.target === "patch_now" || $event.action.target === "patch_later") {
            let params = {
                software_type: 'installedprogram', agent_type: 'LIGHTWEIGHT', platform: 'windows',
                skip: 0,
                limit: 1000, order_by: `software_name asc`, condition: `company_id=${parseInt(this.cs.currentScope.id)}`
            };
            this._bs
                .doRequest(
                    `/r/report_queries/application_vulnerabilities_v2`,
                    "get",
                    null,
                    params
                )
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                    if (res.status && res.data && res.data.length) {
                        res.data.forEach((o: any) => {
                            if ($event.row[0].product == o.software_name) {
                                if ($event.action.target === "patch_now")
                                    this.patchActionCall(o, "Patch Now")
                                if ($event.action.target === "patch_later")
                                    this.patchActionCall(o, "Patch Later")
                            }
                        }
                        );
                    }

                });
        }
    }

    ngOnInit(): void {
        this.uS.userd$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                this.currentUser = user["email"];
            });
        if (this.isRedHatOs(this.cAsset)) {
            this.rView = "sw_remediations_view";
        } else {
            this.rView = "rp";
        }
        this.distinct_os_name();
        this.distinct_linuxos_name();
        // this.getRemediation();
        // this.softwareviewsw();
        this.Softwareremediatedassetview();
        this.Softwareremediationassetview();
    }

    distinct_os_name() {
        this._bs.doRequest('/r/report_queries/distinct_os_name', 'get', null, { condition: (this.cs.currentScope === '*') ? true : `company_id=${parseInt(this.cs.currentScope.id)}`, order_by: `os_name desc`, limit: 100 })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.osOptions = result.data.map((os: { os_name: string }) =>
                    os.os_name.trim() === '' ? 'No OS Name' : os.os_name
                );
                // if (this.osOptions.length > 0) {
                //     this.selectedOsName = [this.osOptions[0]]; // Select the first option
                //     this.getRemediation();
                // }
            });
        this.getRemediation();
    }

    distinct_linuxos_name() {
        this._bs.doRequest('/r/report_queries/distinct_linix_os_namex', 'get', null, { condition: (this.cs.currentScope === '*') ? true : `company_id=${parseInt(this.cs.currentScope.id)}`, order_by: `os_name desc`, limit: 100 })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.linuxosOptions = result.data.map((os: { os_name: string }) => os.os_name);
            });
        this.softwareviewsw();
    }

    getincludeCompany(event: any): void {
        if (event.length === 0) {
            return;
        }
        if (event.includes('*')) {
            this.includeCompany = ['*'];
            setTimeout(() => this.getRemediation());
            return;
        }
        this.includeCompany = event.filter(id => id !== '*');
        setTimeout(() => this.getRemediation());
    }

    getosname(selectedValues: string[]): void {
        if (selectedValues.includes('*')) {
            this.selectedOsName = ['*'];
        } else {
            this.selectedOsName = selectedValues.filter(value => value !== '*');
        }
        this.getRemediation();
    }

    getlinuxosname(selectedlinuxValues: any): void {
        if (selectedlinuxValues.includes('*')) {
            this.selectedOslinux = ['*'];
        } else {
            this.selectedOslinux = selectedlinuxValues.filter(value => value !== '*');
        }
        this.softwareviewsw();
    }

    getRemediation(): void {
        const tableOption: any = {
            columns: [
                {
                    header: "Action",
                    columnDef: "remediation_action",
                    "width": "150px",
                    align: "left",
                    cType: "string",
                    filter: "remeaction",
                    cell: "(element: any) => `${element.remediation_action}`",
                    order: 0,
                    isHtml: true,
                    htmlIcon: true,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                    isDropdown: true,
                    dataSource: [
                        { text: 'OS Update', value: 'OS Update' },
                        { text: 'Run remediation script', value: 'Run remediation script' },
                        { text: 'Software Patch', value: 'Software Patch' },
                        { text: 'Uninstall', value: 'Uninstall' },
                        { text: 'Update', value: 'Update' }
                    ],
                },
                {
                    header: "Product",
                    columnDef: "product",
                    "width": "150px",
                    cType: "string",
                    filter: "highlight",
                    cell: "(element: any) => `${element.product}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isHtml: true,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "bg-yellow-100 text-black px-1 py-0.5 rounded-lg",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    htmlIcon: true,
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Affected Companies",
                    columnDef: "affected_companies",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.affected_companies}`",
                    order: 0,
                    align: "center",
                    isHyperlink: true,
                    visible: !this.asset_id,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Affected Assets",
                    columnDef: "affected_assets",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.affected_assets}`",
                    order: 0,
                    align: "center",
                    isHyperlink: true,
                    visible: !this.asset_id,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "OS Name",
                    columnDef: "os_name",
                    cType: "string",
                    width: '100px',
                    isHyperlink: false,
                    filter: "",
                    cell: "(element: any) => `${element.os_name}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "EPSS Score",
                    rangeColor: true,
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "epss_vuls",
                    filter: "tofixed",
                    cell: "(element: any) => `${element.epss_vuls}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    isEpssScore: true,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: 'Severity',
                    cType: '',
                    columnDef: 'severity',
                    iscolumnSearch: true,
                    filter: '', width: '100px',
                    isColoredCntrl: true,
                    isCustomText: true,
                    visible: true,
                    order: 5,
                    isSort: true,
                    isDropdown: true,
                    isSeverity: true,
                    dataSource: [
                        { text: 'Critical', value: 'Critical' },
                        { text: 'High', value: 'High' },
                        { text: 'Medium', value: 'Medium' },
                        { text: 'Low', value: 'Low' }
                    ],
                },
                {
                    header: "Ports",
                    columnDef: "ports",
                    cType: "agg",
                    filter: "",
                    isPortView: true,
                    cell: "(element: any) => `${element.ports}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                    isShowMoreShowLess: true
                },
                {
                    header: "KB/Fix",
                    columnDef: "fix",
                    width: '150px',
                    cType: "string",
                    isHyperlink: true,
                    filter: "",
                    cell: "(element: any) => `${element.fix}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Total Vulnerabilities",
                    cType: "number",
                    width: "w-[80px]",
                    align: "center",
                    columnDef: "total_problems",
                    isVulnerability: true,
                    is_total_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.total_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Critical",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "critical_problems",
                    isVulnerability: true,
                    is_critical_problems: true,
                    customFilter: "",
                    cell: "(element: any) => `${element.critical_problems}`",
                    visible: true,
                    order: 0,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "High",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "high_problems",
                    isVulnerability: true,
                    is_high_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.high_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-high",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Medium",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "medium_problems",
                    isVulnerability: true,
                    is_medium_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.medium_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-medium",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Low",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "low_problems",
                    isVulnerability: true,
                    is_low_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.low_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-low",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    "header": "Last Vulnerability Discovered",
                    "columnDef": "last_vul_discovered",
                    "filter": "utcToLocale",
                    "cType": "agg",
                    "cell": "(element: any) => `${element.last_vul_discovered}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    header: "Patch",
                    columnDef: "is_patchable",
                    "width": "50px",
                    align: "center",
                    isPatchable: true,
                    visible: true,
                    order: 0,
                    isCustomSearch: true,
                    isGlobal: false,
                    isHideGlobal: true,
                },
                {
                    header: "Open Tickets",
                    columnDef: "open_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.open_ticket_count}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: true,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Closed Tickets",
                    columnDef: "closed_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.closed_ticket_count}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Evidence",
                    columnDef: "view_evidence",
                    cType: "agg",
                    filter: "",
                    cell: "(element: any) => `${element.view_evidence}`",
                    isHyperlink: true,
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                },
            ],
            sortOptions: { active: "epss_vuls", direction: "desc" },
            _pageData: [],
            overridePageLoad: true,
            tableOptions: {
                title: "Remediation Plan",
                isServerSide: false,
                selectText: "plans",
                loading: true,
                floatingFilter: true,
                rowSelection: true,
                showAction: true,
                isHideGlobal: true,
                isCustomSort: true,
                apiDownload: true,
                hideDownload: true,
                isDefaultSearch: true,
                isDefaultTagFilter: true,
                isDefaultKeySearch: true,
                isUserRestCheck: (!this.asset_id) ? true : false,
                isNoAdd: (!this.asset_id) ? true : false,
                actionMenuItems: [
                    {
                        text: 'Suppress',
                        icon: "feather:alert-triangle",
                        callback: 'suppressFn',
                        isGlobal: true,
                        target: "suppressed_records",
                    },
                    {
                        text: 'Integration Action',
                        icon: "heroicons_outline:ticket",
                        callback: 'suppressFn',
                        isGlobal: true,
                        hideLocal: (this.cs.currentScope === '*') ? true : false,
                        target: "integration_action",
                    },
                    {
                        text: 'Patch Now',
                        icon: "system_update_alt",
                        callback: 'supressFn',
                        isGlobal: false,
                        target: "patch_now",
                        hideLocal: (this.cs.currentScope === '*') ? true : false,
                        actionConditions: { key: "is_patchable" },
                        allowedCondition: ["true"],

                    },
                    {
                        text: 'Patch Later',
                        icon: "system_update_alt",
                        callback: 'supressFn',
                        isGlobal: false,
                        target: "patch_later",
                        hideLocal: (this.cs.currentScope === '*') ? true : false,
                        actionConditions: { key: "is_patchable" },
                        allowedCondition: ["true"],
                    }
                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 100,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: true,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                isNotSiteChange: true,
                filterEmitCal: 'remFilterCal',
                // serverSide: {
                //     url: "/r/report_queries/remediation_plan",
                //     condition: condition,
                //     isGlobal: true,
                //     isMergeApi: true,
                //     customFnCall: 'getTicketIds',
                //     requiredKeys: {
                //         asset_id: this.asset_id
                //     }
                // },
            },
            customText: [
                {
                    name: 'epss_vuls',
                    customText: [
                        {
                            range: { from: 0.0, to: 0.85 },
                            color: '#67ace1',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.85, to: 0.90 },
                            color: '#f8c851',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.90, to: 0.95 },
                            color: '#f18c43',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.95, to: 1 },
                            color: '#92243e',
                            'text-color': '#ffffff'
                        },
                    ]
                },
                {
                    status: "Critical",
                    DisplayText: "CRITICAL",
                    color: "#92243e",
                    "text-color": "#ffffff",
                },
                {
                    status: "High",
                    DisplayText: "HIGH",
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    status: "Medium",
                    DisplayText: "MEDIUM",
                    color: "#f18c43",
                    "text-color": "#000000",
                },
                {
                    status: "Low",
                    DisplayText: "LOW",
                    color: "#f8c851",
                    "text-color": "#000000",
                },
                {
                    status: "Info",
                    DisplayText: "INFO",
                    color: "#67ace1",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0, to: 0 },
                    color: "#c2ffc4",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 4.9 },
                    color: "#ffe24b",
                    "text-color": "#000000",
                },
                {
                    range: { from: 5, to: 8.9 },
                    color: "#ec9b0c",
                    "text-color": "#000000",
                },
                {
                    range: { from: 9, to: 10 },
                    color: "#ff6546",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0, to: 0.49 },
                    color: "#6fb8f9",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0.5, to: 0.74 },
                    color: "#FCE94E",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0.75, to: 1 },
                    color: "#EB973E",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 4.9 },
                    color: "#ffe24b",
                    "text-color": "#000000",
                },
                {
                    range: { from: 5, to: 8.9 },
                    color: "#ec9b0c",
                    "text-color": "#000000",
                },
                {
                    range: { from: 9, to: 10 },
                    color: "#ff6546",
                    "text-color": "#000000",
                },
            ],
            changeValue: new Subject<any>(),
        }
        tableOption.tableOptions.actionMenuItems.map((x: any) => {
            if (x.target === 'integration_action') {
                x.isGlobal = false; x.hideLocal = true;
                if (this.cs.currentScope !== '*' || this.asset_id) {
                    x.isGlobal = true; x.hideLocal = false;
                }
            }
        });
        this.remeTableOptions = cloneDeep(tableOption);
        (!this.isTagFilterCalled) ? this.showTable = false : null;
        this.loaderService.display(true);
        let condition: any = true;
        if (this.cs.currentScope !== '*' && !this.asset_id) {
            condition = `company_id=${parseInt(this.cs.currentScope.id)} and asset_id IS NULL`;
        } else if (this.asset_id) {
            condition = `asset_id=${this.asset_id}`;
        }
        // this._bs.doRequest('/r/report_queries/suppress_vulnerability_solution', 'get', null, { skip: 0, limit: 1000, condition })
        //     .pipe(takeUntil(this._unsubscribeAll)).subscribe((suppress: any) => {
        //         this.loaderService.display(false);
        //         if (suppress.status) {
        this.cs.selectedTableRows['Remediation Plan'] = [];
        const params: any = {
            condition: true
        };
        this.remeTableOptions.columns.forEach((obj: any) => {
            delete obj.isNoVisible;
            if (this.asset_id) {
                if (
                    obj.columnDef === "affected_companies" ||
                    obj.columnDef === "affected_assets"
                ) {
                    obj.isNoVisible = true;
                }
                if (obj.columnDef === 'view_evidence') {
                    delete obj.isNoVisible;
                }
                if (obj.columnDef === 'last_vul_discovered') {
                    delete obj.isNoVisible;
                }
            }
            if (!this.asset_id) {
                if (obj.columnDef === 'view_evidence' || obj.columnDef === 'last_vul_discovered') {
                    obj.isNoVisible = true;
                }
            }
            if (this.cs.currentScope === '*' && !this.asset_id) {
                if (obj.columnDef === "open_ticket_count" || obj.columnDef === "closed_ticket_count" || obj.columnDef === 'last_vul_discovered') {
                    obj.isNoVisible = true;
                }
            }
            if (this.cs.currentScope === '*') {
                if (obj.columnDef === "is_patchable") {
                    obj.isNoVisible = true;
                }
            }
        });
        if (this.asset_id) {
            params.asset_id = this.asset_id;
        } else if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
            params.limit = 3000;
            params.condition = true;
            if (this.cs.currentScope === "*" && this.includeCompany.length > 1) {
                params.condition = `company_id IN (${this.includeCompany.join(',')})`;
                params.having = `NOT NULL`;
            } else if (this.cs.currentScope === "*" && this.includeCompany.length === 1) {
                params.condition = `company_id = ${this.includeCompany.join(',')}`;
                params.having = `NOT NULL`;
            }
        } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue.length && this.selectedvalue !== '*') {
            params.limit = 3000;
            params.condition = true;
            if (this.cs.currentScope !== "*" && this.selectedvalue === 'online') {
                params.condition = `company_id IN (${this.cs.currentScope.id})`;
                params.having = true;
            } else {
                params.condition = `company_id IN (${this.cs.currentScope.id})`;
                params.having = false;
            }
        }
        else {
            if (this.tagfilter && this.tagfilter.api) {
                params.condition = (this.cs.currentScope !== "*") ? `company_id = ${parseInt(this.cs.currentScope.id)}` : true;
            } else {
                params.company_id = (this.cs.currentScope !== "*") ? parseInt(this.cs.currentScope.id) : undefined;
            }
        }

        let url: any = '';

        if (this.cs.currentScope === '*' && this.includeCompany && this.includeCompany.length && !this.asset_id) {
            if (!this.includeCompany.includes('*')) { // Check if '*' is not included in the array
                url = '/r/report_queries/remediation_plan_include_company';
            }
            else if (this.selectedOsName && this.selectedOsName.length) {
                if (!this.selectedOsName.includes('*')) {
                    url = '/r/report_queries/remediation_plan_include_company';
                    params.having = `NOT NULL`;
                } else {
                    url = '/r/get_data/remediation_plan_global';
                }
            }
            else {
                url = '/r/get_data/remediation_plan_global';
            }
        }
        // else if (this.cs.currentScope === '*' && this.selectedOsName && this.selectedOsName.length && !this.asset_id) {
        //     url = '/r/report_queries/remediation_plan_include_company';
        // }
        else if (this.cs.currentScope === '*' && !this.asset_id) {
            url = '/r/get_data/remediation_plan_global';
        } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue.length && this.selectedvalue !== '*') {
            url = '/r/report_queries/remediation_plan_include_company';
        }
        else if (this.cs.currentScope !== '*' && this.selectedOsName && this.selectedOsName.length && !this.asset_id) {
            if (!this.selectedOsName.includes('*')) {
                url = '/r/report_queries/remediation_plan_include_company';
                params.having = `NOT NULL`;
            }
            else {
                url = '/r/get_data/remediation_plan_companies';
            }
        }
        else if (this.cs.currentScope !== '*' && !this.asset_id) {
            url = '/r/get_data/remediation_plan_companies';
        }
        else {
            url = '/r/get_data/remediation_plan_asset';
        }
        if (this.tagfilter && this.tagfilter.api) {
            url = '/r/report_queries/remediation_plan_include_company';
            this.remeTableOptions.tableOptions.isSettingCalled = true;
            if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue === 'online') {
                params.having = true;
            } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue === 'offline') {
                params.having = false;
            } else {
                params.having = `NOT NULL`;
            }
            if (params.condition === true) {
                if (this.cs.manualtagfilter) {
                    params.condition = `not ${this.tagfilter.api}`;
                } else {
                    params.condition = `${this.tagfilter.api}`;
                }
            } else if (this.cs.manualtagfilter) {
                if (params.condition === true) {
                    params.condition = `not (${this.tagfilter.api})`;
                } else {
                    params.condition += ` and not (${this.tagfilter.api})`;
                }

            } else {
                params.condition += ` and (${this.tagfilter.api})`;
            }
        }

        if (this.epssCondition) {
            url = '/r/report_queries/remediation_plan_include_company';
            if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue === 'online') {
                params.having = true;
            } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue === 'offline') {
                params.having = false;
            } else {
                params.having = `NOT NULL`;
            }
            if (params.condition === true) {
                params.condition = this.epssCondition;
            } else {
                params.condition += ` and ${this.epssCondition}`;
            }
            if (this.cs.currentScope !== '*') {
                delete params.company_id;
                if (this.selectedvalue !== 'online' && this.selectedvalue !== 'offline') {
                    params.condition += ` and company_id = ${parseInt(this.cs.currentScope.id)}`;
                }
            }
            if (this.asset_id) {
                delete params.asset_id;
                params.condition = `asset_id = ${this.asset_id} and ${this.epssCondition}`;
            }
            if (this.asset_id && this.cs.currentScope !== '*') {
                delete params.asset_id;
                params.condition = ` asset_id = ${this.asset_id} and company_id = ${parseInt(this.cs.currentScope.id)} and ${this.epssCondition}`;

            }
        }
        params.skip = 0; params.limit = 3000;
        if ((this.cs.currentScope !== '*' || this.cs.currentScope === '*') && !this.asset_id) {
            if (!this.selectedOsName.includes('*')) {
                // Clear 'true' if it's the only condition
                if (params.condition === true) {
                    params.condition = '';
                }
                // Handle conditions based on the number of selected OS names
                if (this.selectedOsName.length === 1) {
                    const osCondition = this.selectedOsName[0] === 'No OS Name' ? `os_name = ''` : `os_name = '${this.selectedOsName[0]}'`;
                    params.condition += `${params.condition ? ' and ' : ''}${osCondition}`;
                } else if (this.selectedOsName.length > 1) {
                    const osConditions = this.selectedOsName.map(os =>
                        os === 'No OS Name' ? `''` : `'${os}'`
                    ).join(", ");
                    params.condition += `${params.condition ? ' and ' : ''}os_name IN (${osConditions})`;
                }
                // Trim extra whitespace or leading 'and'
                params.condition = params.condition.trim().replace(/^and\s+/, '');
            }
        }

        this.loaderService.display(true);
        (!this.isTagFilterCalled) ? this.showHideLoadingT(true) : null;
        this._bs.doRequest(url, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status && result.data && result.data.length) {
                    // const suppressed_record: any = suppress.data.map((x: any) => x.solution_id);
                    // result.data = result.data.filter((x: any) => suppressed_record.indexOf(x.solution_id) === -1);
                    // Note below code commented because direct count keys available.
                    /*result.data.map((x: any) => {
                        x.affected_companies = x.company_ids.length;
                        x.affected_assets = x.asset_ids.length;
                    })*/
                    result.data.sort((a: any, b: any) => {
                        if (a['epss_vuls'] < b['epss_vuls']) {
                            return 1;
                        } else if (a['epss_vuls'] > b['epss_vuls']) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    try {
                        if (this.cs.currentScope !== '*' || this.asset_id) {
                            try {
                                const asset_id = this.asset_id;
                                try {
                                    if (this.cs.currentScope !== '*' || asset_id) {
                                        const reqParams: any = {
                                            condition: true,
                                            skip: 0,
                                            limit: 2000
                                        }
                                        reqParams.condition = (this.cs.currentScope !== "*") ? `company_id=${parseInt(this.cs.currentScope.id)}` : null;
                                        try {
                                            this.loaderService.display(true);
                                            this._bs.doRequest(`/report_queries/event_tickets`, 'get', null, reqParams)
                                                .pipe(takeUntil(this._unsubscribeAll)).subscribe((ticket: any) => {
                                                    this.loaderService.display(false);
                                                    if (ticket.status && ticket.data && ticket.data.length) {
                                                        if (asset_id) {
                                                            ticket.data = ticket.data.filter((obj: any) => {
                                                                let assetIdsArray: number[] = [];
                                                                if (obj.asset_ids && obj.asset_ids !== null && obj.asset_ids !== undefined) {
                                                                    if (Array.isArray(obj.asset_ids)) {
                                                                        assetIdsArray = obj.asset_ids.map(item => parseInt(item));
                                                                    } else if (typeof obj.asset_ids === 'string') {
                                                                        assetIdsArray = obj.asset_ids.split(',').map(item => parseInt(item));
                                                                    } else {
                                                                        assetIdsArray = [parseInt(obj.asset_ids)];
                                                                    }
                                                                    return assetIdsArray.includes(asset_id);
                                                                } else if (obj.asset_id && obj.asset_id !== null) {
                                                                    assetIdsArray = obj.asset_id.toString().split(',').map(item => parseInt(item));
                                                                    return assetIdsArray.includes(asset_id);
                                                                }
                                                                return false;
                                                                // return assetIdsArray.includes(asset_id);
                                                            });
                                                        }
                                                        result.data.forEach((obj: any) => {
                                                            obj.view_evidence = (asset_id) ? 'View Evidence' : undefined;
                                                            if (!obj.ticket_ids || !obj.ticket_ids.length) {
                                                                obj.ticket_ids = [];
                                                            }
                                                            const data = { refid: obj.solution_id, asset_ids: obj.asset_ids };
                                                            const sortedData = ticket.data.sort((a: any, b: any) => (a.status === "Open" ? -1 : 1))
                                                            obj.ticket_ids = [];
                                                            const { tickets, open_ticket_count, closed_ticket_count } = this.searchTicketStatusMaps(data, sortedData);
                                                            obj.ticket_status_map = tickets; obj.open_ticket_count = open_ticket_count; obj.closed_ticket_count = closed_ticket_count;
                                                        });
                                                        this.originalRemData = result.data;
                                                        if (this.isTagFilterCalled) {
                                                            this.isTagFilterCalled = false;
                                                            this.cs.tableFilterCalBack.next({ value: result.data });
                                                        } else {
                                                            this.remeTableOptions.pageData = result.data;
                                                            this.remeTableOptions.tableOptions.pageTotal = result.data.length;
                                                            this.remeTableOptions.tableOptions.pageSize = 0;
                                                            this.remeTableOptions.tableOptions.pagination = false;
                                                            this.remeTableOptions.tableOptions.loading = false;
                                                            this.showHideLoadingT(false);
                                                            this.showTable = true;
                                                        }
                                                    } else {
                                                        result.data.forEach((obj: any) => {
                                                            obj.view_evidence = (asset_id) ? 'View Evidence' : undefined;
                                                            obj.ticket_ids = [];
                                                            obj.ticket_status_map = []; obj.open_ticket_count = 0; obj.closed_ticket_count = 0;
                                                        });
                                                        this.originalRemData = result.data;
                                                        if (this.isTagFilterCalled) {
                                                            this.isTagFilterCalled = false;
                                                            this.cs.tableFilterCalBack.next({ value: result.data });
                                                        } else {
                                                            this.remeTableOptions.pageData = result.data;
                                                            this.remeTableOptions.tableOptions.pageTotal = result.data.length;
                                                            this.remeTableOptions.tableOptions.pageSize = 0;
                                                            this.remeTableOptions.tableOptions.pagination = false;
                                                            this.remeTableOptions.tableOptions.loading = false;
                                                            this.showHideLoadingT(false);
                                                            this.showTable = true;
                                                        }
                                                    }
                                                })
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        this.originalRemData = result.data;
                                        if (this.isTagFilterCalled) {
                                            this.isTagFilterCalled = false;
                                            this.cs.tableFilterCalBack.next({ value: result.data });
                                        } else {
                                            this.remeTableOptions.pageData = result.data;
                                            this.remeTableOptions.tableOptions.pageTotal = result.data.length;
                                            this.remeTableOptions.tableOptions.pageSize = 0;
                                            this.remeTableOptions.tableOptions.pagination = false;
                                            this.remeTableOptions.tableOptions.loading = false;
                                            this.showHideLoadingT(false);
                                            this.showTable = true;
                                        }
                                    }

                                } catch (error) {
                                    console.log(error);
                                }

                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            this.originalRemData = result.data;
                            if (this.isTagFilterCalled) {
                                this.isTagFilterCalled = false;
                                this.cs.tableFilterCalBack.next({ value: result.data });
                            } else {
                                this.remeTableOptions.pageData = result.data;
                                this.remeTableOptions.tableOptions.pageTotal = result.data.length;
                                this.remeTableOptions.tableOptions.pageSize = 0;
                                this.remeTableOptions.tableOptions.pagination = false;
                                this.remeTableOptions.tableOptions.loading = false;
                                this.showHideLoadingT(false);
                                this.showTable = true;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    this.originalRemData = [];
                    if (this.isTagFilterCalled) {
                        this.isTagFilterCalled = false;
                        this.cs.tableFilterCalBack.next({ value: [] });
                    } else {
                        this.remeTableOptions.pageData = [];
                        this.remeTableOptions.tableOptions.pageTotal = 0;
                        this.remeTableOptions.tableOptions.pageSize = 0;
                        this.remeTableOptions.tableOptions.pagination = false;
                        this.remeTableOptions.tableOptions.loading = false;
                        this.showHideLoadingT(false);
                        this.showTable = true;
                    }
                }
            });
        //     }
        // })

    }
    removeDuplicateObjects(jsonArray: any) {
        const tickets: any = Object.values(jsonArray.reduce((acc: any, ticket: any) => {
            const { ticket_id, status } = ticket;
            if (!acc[ticket_id] || status === "Closed") {
                acc[ticket_id] = ticket;
            }
            return acc;
        }, {}));
        const open_tickets: any = tickets.filter((x: any) => x.status === "Open");
        const closed_tickets: any = tickets.filter((x: any) => x.status === "Closed");
        return { tickets: tickets, open_ticket_count: open_tickets.length, closed_ticket_count: closed_tickets.length }
    }


    searchTicketStatusMaps(data: any, jsonData: any) {
        const { refid, asset_ids } = data;
        const ticketStatusMaps: any = [];
        if (refid) {
            const refidArray: any = Array.isArray(refid) ? refid : refid.toString().split(',');
            jsonData.forEach((obj: any) => {
                if ((refidArray.some((id: any) => obj.source_ids && obj.source_ids.includes(id.toString().trim())) ||
                    refidArray.some((id: any) => obj.software_id && obj.software_id.toString() === id.toString().trim())) &&
                    refidArray.some((id: any) => (!obj.excluded_source || !obj.excluded_source.includes(id.toString().trim())))
                ) {
                    ticketStatusMaps.push({ credential_id: obj.credential_id, status: obj.status, ticket_id: obj.ticket_id, ticket_number: obj.ticket_number });

                }
            });
        }

        return this.removeDuplicateObjects(ticketStatusMaps);
    }

    mergeAndUnique(...arrays: any): any {
        const mergedArray: any = [].concat(...arrays);
        return [...new Set(mergedArray)];
    }

    showHideLoadingT(status: boolean): void {
        const data = Object.assign({}, this.remeTableOptions);
        this.remeTableOptions = {};
        this._changeDetectorRef.detectChanges();
        this.remeTableOptions = data;
        this.remeTableOptions.tableOptions.loading = status;
        this._changeDetectorRef.detectChanges();
    }

    initRemediData(): void {
        if (this.asset_id) {
            this.remeTableOptions.tableOptions.serverSide.condition = `asset_id=${this.asset_id}`;
            this.remeTableOptions.columns.forEach((obj: any) => {
                if (
                    obj.columnDef === "affected_companies" ||
                    obj.columnDef === "affected_assets"
                ) {
                    obj.visible = false;
                }
            });
        } else {
            this.remeTableOptions.tableOptions.serverSide.condition = "";
            if (this.cs.currentScope !== "*") {
                this.remeTableOptions.tableOptions.serverSide.condition = `company_id=${parseInt(
                    this.cs.currentScope.id
                )}`;
            }
        }
        if (!this.asset_id) {
            // this.initSummary();
        }
        const data = Object.assign({}, this.remeTableOptions);
        data.pageData = [];
        data.tableOptions.pageTotal = 0;
        this.remeTableOptions.pageData = [];
        this.remeTableOptions.tableOptions.pageTotal = 0;
        this.remeTableOptions = {};
        this._changeDetectorRef.detectChanges();
        this.remeTableOptions = data;
        this._changeDetectorRef.detectChanges();
    }

    initSummary(): void {
        this.loaderService.display(true);
        let params: any = {};
        if (this.companyId) {
            params = { company_id: this.companyId };
        }
        this._bs
            .doRequest(
                `/report_queries/pending_remediation_software_count`,
                "get",
                null,
                params
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    console.log(res);
                }
            });

        this.loaderService.display(true);
        this._bs
            .doRequest(`/report_queries/pending_remediation_count`, "get")
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    console.log(res);
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    remclick() {
        this.rView = 'rp';
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        this.currentRecordType = '';
    }

    remrecords1() {
        this.rView = 'rr';
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        this.currentRecordType = 'non-linux';
    }

    remrecordsassetview() {
        this.rView = 'rm';
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        // this.currentRecordType = 'non-linux';
    }

    remrecords() {
        // this.rView = 'rm';
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        this.currentRecordType = 'non-linux';
    }

    pendingremview() {
        this.rView = 'rv';
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        this.currentRecordType = '';
    }


    remvelocity() {
        this.rView = 'remv';
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        this.currentRecordType = '';
    }

    suppressrecord() {
        this.rView = 'suppress_records'
        this.viewchecking = false;
        this.linuxremeviewcheck = false;
        this.currentRecordType = '';
    }

    linkClick($event: any): void {
        this.currentRemediation = $event.row;
        const pendingRemApp = ['affected_software_lessthan_7_days', 'affected_software_8_15_days', 'affected_software_16_30_days', 'affected_software_graterthan_30_days'];
        this.currentFixRemediation = "";
        if ($event.col === "affected_companies") {
            if (!$event.row[$event.col]) {
                return;
            }
            this.cView = "affected_companies";
            const data = Object.assign({}, this.companiesTableOptions);
            data.pageData = [];
            data.tableOptions.pageTotal = 0;
            this.companiesTableOptions.pageData = [];
            this.companiesTableOptions.tableOptions.pageTotal = 0;
            this.companiesTableOptions = {};
            this._changeDetectorRef.detectChanges();
            if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                data.tableOptions.serverSide.params = { software_name: `${$event.row.product}` };
                data.tableOptions.serverSide.condition = `solution_id=${$event.row.solution_id} and company_id IN (${this.includeCompany.join(',')}) and os_name ='${$event.row.os_name}' and fix = '${$event.row.fix}'`;
            } else {
                data.tableOptions.serverSide.params = { software_name: `${$event.row.product}` };
                data.tableOptions.serverSide.condition = `solution_id=${$event.row.solution_id} and os_name='${$event.row.os_name}' and fix = '${$event.row.fix}'`;
            }
            if (this.epssCondition) {
                if (!data.tableOptions.serverSide.condition) {
                    data.tableOptions.serverSide.condition = this.epssCondition;
                } else {
                    data.tableOptions.serverSide.condition += ` and ${this.epssCondition}`
                }
            }
            if (this.tagfilter && this.tagfilter.api) {
                if (data.tableOptions.serverSide.condition) {
                    data.tableOptions.serverSide.condition = `${this.tagfilter.api}`;
                } else {
                    if (this.cs.manualtagfilter) {
                        data.tableOptions.serverSide.condition += ` and not ( ${this.tagfilter.api} )`;
                    } else {
                        data.tableOptions.serverSide.condition += ` and ( ${this.tagfilter.api} )`;
                    }

                }
            }
            this.companiesTableOptions = data;
            this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        } else if ($event.col === "affected_assets") {
            if ($event.row["affected_assets"] === 0) {
                return;
            }
            this.insideCompanies = false;
            this.asset_online_status = '';
            this.currentStep = 0;
            if (!this.epssCondition) {
                this.getData1();
            }
            this.getData();

        } else if ($event.col === "fix") {
            if ($event.row.remediation_action == "Run remediation script") {
                this.currentFixRemediation = "";
                $event.row.fix_script.map((x: any) => {
                    x.isCopied = false;
                    x.script = `\n${x.script}`;
                });
                this.currentFixRemediation = $event.row.fix_script;
                this.currentRemediation = {};
                this.currentRemediation = $event.row;
                this.modalService.open('remediation_script');
                setTimeout(() => {
                    let el = document.getElementById('remData');
                    el.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            } else {
                window.open($event.row.url, "_blank");
            }
        } else if ($event.col === "view_evidence") {
            this.getData($event.row["affected_assets"], $event);
        }

        else if (pendingRemApp.indexOf($event.col) > -1) {
            if (!$event.row[$event.col]) {
                return;
            }
            this.isCompanyName = false;
            const api_conditions: any = {
                affected_software_lessthan_7_days: `GREATEST(asm.created, psm.created) >= CURRENT_TIMESTAMP - INTERVAL '7 DAY'`,
                affected_software_8_15_days: `GREATEST(asm.created, psm.created) BETWEEN CURRENT_TIMESTAMP - INTERVAL '15 DAY' AND CURRENT_TIMESTAMP - INTERVAL '7 DAY'`,
                affected_software_16_30_days: `GREATEST(asm.created, psm.created) BETWEEN CURRENT_TIMESTAMP - INTERVAL '30 DAY' AND CURRENT_TIMESTAMP - INTERVAL '15 DAY'`,
                affected_software_graterthan_30_days: `GREATEST(asm.created, psm.created) <= CURRENT_TIMESTAMP - INTERVAL '30 DAY'`
            }
            this.cView = "velocity_assets";
            const data = Object.assign({}, this.assetsTableOptions);
            // data.columns = data.columns.filter((x: any) => x.columnDef !== 'company_name');
            data.pageData = []; data.tableOptions.pageTotal = 0;
            this.assetsTableOptions = {}; this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.url = '/r/report_queries/remediation_velocity_application_asset_details';
            data.tableOptions.serverSide.condition = `product='${$event.row.product}' and ${api_conditions[$event.col]}`;
            this.assetsTableOptions = data; this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }

    linuxremedationclick($event: any): void {
        this.viewchecking = true;
        this.linuxremeviewcheck = false;
        this.asset_list = [];
        this._changeDetectorRef.detectChanges()
        this.currentFixlinuxremedation = $event.row;
        if ($event.col === "fix") {
            window.open($event.row.fix_url, "_blank");
        } else if ($event.col === "affected_companies") {
            if (!$event.row[$event.col]) {
                return;
            }
            this.cView = "affected_companies";
            const data = Object.assign({}, this.companiesTableOptions1);
            data.pageData = [];
            data.tableOptions.pageTotal = 0;
            this.companiesTableOptions1.pageData = [];
            this.companiesTableOptions1.tableOptions.pageTotal = 0;
            this.companiesTableOptions1 = {};
            const productValueremclick = Array.isArray($event.row.product)
                ? $event.row.product
                : [String($event.row.product)];

            let productToSendremclick = Array.isArray($event.row.product) && $event.row.product.length === 1
                ? `'${$event.row.product[0]}'`
                : productValueremclick.map(product => `'${product}'`).join(', ');
            this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.url = '/r/report_queries/sw_remediations_affected_companies';
            if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                data.tableOptions.serverSide.params = { fix: `${$event.row.fix}`, product: productToSendremclick };
                data.tableOptions.serverSide.condition = `company_id IN (${this.includeCompany.join(',')})'`;
            } else {
                data.tableOptions.serverSide.params = { fix: `${$event.row.fix}`, product: productToSendremclick };
            }

            this.companiesTableOptions1 = data;
            this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        } else if ($event.col === "affected_assets") {
            if ($event.row["affected_assets"] === 0) {
                return;
            }
            this.linuxfilterstatusviewchecking = true;
            this.onlinelinux = $event;
            this.currentStep = 0;
            this.cView = "affected_assets";
            this.currentRemediation1 = $event.row.affected_assets
            let skip = this.currentStep * 20;
            let condition: any;
            const productValueasset = Array.isArray($event.row.product)
                ? $event.row.product
                : [String($event.row.product)];

            let productToSend = Array.isArray($event.row.product) && $event.row.product.length === 1
                ? `'${$event.row.product[0]}'`
                : productValueasset.map(product => `'${product}'`).join(', ');

            let having: boolean | string = '';

            if (this.cs.currentScope !== '*') {
                if (this.selectedvalue === 'online') {
                    having = true;
                } else if (this.selectedvalue === 'offline') {
                    having = false;
                } else {
                    having = 'NOT NULL';
                }
            } else {
                if (this.selectedvalue === 'online') {
                    having = true;
                } else if (this.selectedvalue === 'offline') {
                    having = false;
                } else {
                    having = 'NOT NULL';
                }
            }

            condition = (this.cs.currentScope === '*') ? { condition: true, skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSend, having }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSend, having };

            this._bs
                .doRequest('/r/report_queries/sw_remediations_affected_assets', 'get', null, condition)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    this.asset_list = []; this.assetEvidence = [];
                    if (result.status) {
                        this.totalSteps = Math.ceil(this.currentRemediation1 / 20);
                        this.timelineCount = this.currentRemediation1;
                        this.asset_list = result.data;
                        this.cView = "affected_assets";
                        this._changeDetectorRef.detectChanges();
                        this.drawerOpened = true;
                        if (!this.drawer.opened) {
                            this.drawer.toggle().then((r) => console.log(r));
                        }
                        setTimeout(() => {
                            let el = document.getElementById('remData');
                            el.scrollIntoView({ behavior: 'smooth' });
                        }, 100);
                    }
                });
        }
    }

    linuxremedatedclick($event: any): void {
        this.linuxremeviewcheck = true;
        this.viewchecking = false;
        this.currentFixlinuxremedated = $event.row;
        if ($event.col === "fix") {
            window.open($event.row.fix_url, "_blank");
        } else if ($event.col === "affected_companies") {
            if (!$event.row[$event.col]) {
                return;
            }
            this.cView = "affected_companies";
            const data = Object.assign({}, this.companiesTableOptions2);
            data.pageData = [];
            data.tableOptions.pageTotal = 0;
            this.companiesTableOptions2.pageData = [];
            this.companiesTableOptions2.tableOptions.pageTotal = 0;
            this.companiesTableOptions2 = {};
            const productValueremassetcomp = Array.isArray($event.row.product)
                ? $event.row.product
                : [String($event.row.product)];

            let productToSendcomp = Array.isArray($event.row.product) && $event.row.product.length === 1
                ? `'${$event.row.product[0]}'`
                : productValueremassetcomp.map(product => `'${product}'`).join(', ');

            this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.url = '/r/report_queries/sw_remediated_affected_companies';
            if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                data.tableOptions.serverSide.params = { fix: `${$event.row.fix}`, product: productToSendcomp };
                data.tableOptions.serverSide.condition = `company_id IN (${this.includeCompany.join(',')})'`;
            } else {
                data.tableOptions.serverSide.params = { fix: `${$event.row.fix}`, product: productToSendcomp };
            }
            this.companiesTableOptions2 = data;
            this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        } else if ($event.col === "affected_assets") {
            if ($event.row["affected_assets"] === 0) {
                return;
            }
            this.currentStep = 0;
            this.cView = "affected_assets";
            this.currentRemediation1 = $event.row.affected_assets
            const productValueremaffectedasset = Array.isArray($event.row.product)
                ? $event.row.product
                : [String($event.row.product)];

            let productToSendasset = Array.isArray($event.row.product) && $event.row.product.length === 1
                ? `'${$event.row.product[0]}'`
                : productValueremaffectedasset.map(product => `'${product}'`).join(', ');
            let skip = this.currentStep * 20;
            let condition: any;
            condition = (this.cs.currentScope === '*') ? { condition: true, skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSendasset }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSendasset };

            this._bs
                .doRequest('/r/report_queries/sw_remediated_affected_assets', 'get', null, condition)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    this.asset_list = []; this.assetEvidence = [];
                    if (result.status) {
                        this.totalSteps = Math.ceil(this.currentRemediation1 / 20);
                        this.timelineCount = this.currentRemediation1;
                        this.asset_list = result.data;
                        this.cView = "affected_assets";
                        this._changeDetectorRef.detectChanges();
                        this.drawerOpened = true;
                        if (!this.drawer.opened) {
                            this.drawer.toggle().then((r) => console.log(r));
                        }
                        setTimeout(() => {
                            let el = document.getElementById('remData');
                            el.scrollIntoView({ behavior: 'smooth' });
                        }, 100);
                    }
                });
        }
    }

    pendingRemlinkClick($event: any): void {
        this.currentRemediation = $event.row;
        const pendingRemApp = ['affected_software_lessthan_7_days', 'affected_software_8_15_days', 'affected_software_16_30_days', 'affected_software_graterthan_30_days'];
        if (pendingRemApp.indexOf($event.col) > -1) {
            if (!$event.row[$event.col]) {
                return;
            }
            this.isCompanyName = true;
            const api_conditions: any = {
                affected_software_lessthan_7_days: `GREATEST(asm.created, psm.created) >= CURRENT_TIMESTAMP - INTERVAL '7 DAY'`,
                affected_software_8_15_days: `GREATEST(asm.created, psm.created) BETWEEN CURRENT_TIMESTAMP - INTERVAL '15 DAY' AND CURRENT_TIMESTAMP - INTERVAL '7 DAY'`,
                affected_software_16_30_days: `GREATEST(asm.created, psm.created) BETWEEN CURRENT_TIMESTAMP - INTERVAL '30 DAY' AND CURRENT_TIMESTAMP - INTERVAL '15 DAY'`,
                affected_software_graterthan_30_days: `GREATEST(asm.created, psm.created) <= CURRENT_TIMESTAMP - INTERVAL '30 DAY'`
            }
            this.cView = "velocity_assets";
            const data = Object.assign({}, this.assetsTableOptions);
            data.pageData = []; data.tableOptions.pageTotal = 0;
            this.assetsTableOptions = {}; this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.url = '/r/report_queries/remediation_velocity_application_asset_details';
            data.tableOptions.serverSide.condition = `company_id='${$event.row.company_id}' and ${api_conditions[$event.col]}`;
            this.assetsTableOptions = data; this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }
    remediatedlinkClick($event: any): void {
        this.currentRemediation = $event.row;
        const pendingRemApp = ['affected_software_lessthan_7_days', 'affected_software_8_15_days', 'affected_software_16_30_days', 'affected_software_graterthan_30_days'];
        if (pendingRemApp.indexOf($event.col) > -1) {
            if (!$event.row[$event.col]) {
                return;
            }
            this.isCompanyName = false;
            const api_conditions: any = {
                affected_software_lessthan_7_days: `(dsm.created - dsm.updated) < INTERVAL '7 DAY'`,
                affected_software_8_15_days: `(dsm.created - dsm.updated) BETWEEN INTERVAL '7 DAY' AND INTERVAL '15 DAY`,
                affected_software_16_30_days: `(dsm.created - dsm.updated) BETWEEN INTERVAL '15 DAY' AND INTERVAL '30 DAY'`,
                affected_software_graterthan_30_days: `(dsm.created - dsm.updated) > INTERVAL '30 DAY'`
            }
            this.cView = "velocity_assets";
            const data = Object.assign({}, this.assetsTableOptions);
            data.pageData = []; data.tableOptions.pageTotal = 0;
            this.assetsTableOptions = {}; this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.condition = `product='${$event.row.product}' and ${api_conditions[$event.col]}`;
            data.tableOptions.serverSide.url = '/r/report_queries/remediated_velocity_application_asset_details';
            this.assetsTableOptions = data; this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }
    remediatedpendinglinkClick($event: any): void {
        this.currentRemediation = $event.row;
        const pendingRemApp = ['affected_software_lessthan_7_days', 'affected_software_8_15_days', 'affected_software_16_30_days', 'affected_software_graterthan_30_days'];
        if (pendingRemApp.indexOf($event.col) > -1) {
            if (!$event.row[$event.col]) {
                return;
            }
            this.isCompanyName = true;
            const api_conditions: any = {
                affected_software_lessthan_7_days: `(dsm.created - dsm.updated) < INTERVAL '7 DAY'`,
                affected_software_8_15_days: `(dsm.created - dsm.updated) BETWEEN INTERVAL '7 DAY' AND INTERVAL '15 DAY`,
                affected_software_16_30_days: `(dsm.created - dsm.updated) BETWEEN INTERVAL '15 DAY' AND INTERVAL '30 DAY'`,
                affected_software_graterthan_30_days: `(dsm.created - dsm.updated) > INTERVAL '30 DAY'`
            }
            this.cView = "velocity_assets";
            const data = Object.assign({}, this.assetsTableOptions);
            data.pageData = []; data.tableOptions.pageTotal = 0;
            this.assetsTableOptions = {}; this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.url = '/r/report_queries/remediated_velocity_application_asset_details';
            data.tableOptions.serverSide.condition = `company_id='${$event.row.company_id}' and ${api_conditions[$event.col]}`;
            this.assetsTableOptions = data; this._changeDetectorRef.detectChanges();
            this.drawerOpened = true;
            if (!this.drawer.opened) {
                this.drawer.toggle().then((r) => console.log(r));
            }
            setTimeout(() => {
                let el = document.getElementById('remData');
                el.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }

    copyCode(i: any): void {
        this.currentFixRemediation.map((x: any, index: any) => {
            if (index === i) {
                x.isCopied = true;
                this.copy(x.script);
                setTimeout(() => {
                    x.isCopied = false;
                }, 2000);
            }
        });
    }

    copy(text: string): void {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand("copy");
            this.toast.sToast("success", "Copied to clipboard");
        } catch (err) {
            this.copyClipApi(text);
            console.error("Unable to copy text to clipboard: ", err);
        } finally {
            document.body.removeChild(textarea);
        }
    }

    copyClipApi(text: string): void {
        const clipboardItem = new ClipboardItem({
            "text/plain": new Blob([text], { type: "text/plain" }),
        });

        // Access the Clipboard API and write the clipboard item
        navigator.clipboard
            .write([clipboardItem])
            .then(() => {
                this.toast.sToast("success", "Copied to clipboard");
            })
            .catch((err) => {
                this.toast.sToast("error", "Unable to copy text to clipboard: ", err);
            });
    }

    linkclickcompanies($event: any) {
        if ($event.row["asset_ids"] === 0) {
            return;
        }
        this.currentStep = 0;
        this.cView = "affected_assets";
        this.insideCompanies = true;
        this.companiesAffectedAssets = $event.row.asset_ids;
        this.getData1();
        this.getData();
    }

    linkclickcompanies1($event: any) {
        this.linuxfilterstatusviewchecking = true;
        this.onlinelinux = $event;
        this.currentStep = 0;
        this.cView = "affected_assets";
        this.currentRemediation1 = $event.row.affected_assets;
        const productValuelinckcomp1 = Array.isArray($event.row.product)
            ? $event.row.product
            : [String($event.row.product)];

        let productToSendlinkcomp1 = Array.isArray($event.row.product) && $event.row.product.length === 1
            ? `'${$event.row.product[0]}'`
            : productValuelinckcomp1.map(product => `'${product}'`).join(', ');

        let skip = this.currentStep * 20;
        // let having = `NOT NULL`;
        let having: boolean | string = '';

        if (this.cs.currentScope !== '*') {
            if (this.selectedvalue === 'online') {
                having = true;
            } else if (this.selectedvalue === 'offline') {
                having = false;
            } else {
                having = 'NOT NULL';
            }
        } else {
            if (this.selectedvalue === 'online') {
                having = true;
            } else if (this.selectedvalue === 'offline') {
                having = false;
            } else {
                having = 'NOT NULL';
            }
        }
        let condition: any;
        condition = (this.cs.currentScope === '*') ? { condition: true, skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSendlinkcomp1, having }
            : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSendlinkcomp1, having };

        this._bs
            .doRequest('/r/report_queries/sw_remediations_affected_assets', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                this.asset_list = []; this.assetEvidence = [];
                if (result.status) {
                    this.totalSteps = Math.ceil(this.currentRemediation1 / 20);
                    this.timelineCount = this.currentRemediation1;
                    this.asset_list = result.data;
                    this.cView = "affected_assets";
                    this._changeDetectorRef.detectChanges();
                    this.drawerOpened = true;
                    if (!this.drawer.opened) {
                        this.drawer.toggle().then((r) => console.log(r));
                    }
                    setTimeout(() => {
                        let el = document.getElementById('remData');
                        el.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }
            });
    }



    linkclickcompanies2($event: any) {
        this.currentStep = 0;
        this.cView = "affected_assets";
        this.currentRemediation1 = $event.row.affected_assets
        const productValuelinckcomp2 = Array.isArray($event.row.product)
            ? $event.row.product
            : [String($event.row.product)];

        let productToSendlinkcomp2 = Array.isArray($event.row.product) && $event.row.product.length === 1
            ? `'${$event.row.product[0]}'`
            : productValuelinckcomp2.map(product => `'${product}'`).join(', ');

        let skip = this.currentStep * 20;
        let condition: any;
        condition = (this.cs.currentScope === '*') ? { condition: true, skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSendlinkcomp2 }
            : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: skip, limit: 20, order_by: 'host_name asc', fix: `${$event.row.fix}`, product: productToSendlinkcomp2 };
        this._bs
            .doRequest('/r/report_queries/sw_remediated_affected_assets', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                this.asset_list = []; this.assetEvidence = [];
                if (result.status) {
                    this.totalSteps = Math.ceil(this.currentRemediation1 / 20);
                    this.timelineCount = this.currentRemediation1;
                    this.asset_list = result.data;
                    this.cView = "affected_assets";
                    this._changeDetectorRef.detectChanges();
                    this.drawerOpened = true;
                    if (!this.drawer.opened) {
                        this.drawer.toggle().then((r) => console.log(r));
                    }
                    setTimeout(() => {
                        let el = document.getElementById('remData');
                        el.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }
            });
    }

    filterEpss(lt: any, gte: any): void {
        this.epssCondition = '';
        if (lt === '') {
            this.epssCondition = `epss_score >= ${gte}`;
        } else if (lt === 0) {
            this.epssCondition = `epss_score <= ${gte}`;
        } else {
            this.epssCondition = `epss_score > ${lt} AND epss_score <= ${gte}`
        }
        if (this.rView === 'rp') {
            this.getRemediation();
        } else if (this.rView === 'sw_remediations_view') {
            this.softwareviewsw();
        }
    }
    removeEpssFilter(): void {
        this.epssCondition = '';
        // this.getRemediation();
        if (this.rView === 'rp') {
            this.getRemediation();
        } else if (this.rView === 'sw_remediations_view') {
            this.softwareviewsw();
        }
        this._changeDetectorRef.detectChanges();
    }

    downloadCVE(data: any, columns: any, file_name: any, dynamicKey?: any) {
        const csv = this.convertArrayOfObjectsToCSV(data, columns, dynamicKey);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        const dateStr = new Date().toLocaleDateString().replace(/\//g, '_') + '_'
            + new Date().toLocaleTimeString().replace(/\:/g, '_');
        link.setAttribute('download', `${dateStr}_${file_name}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    convertArrayOfObjectsToCSV(data: any, columnMapping: any, dynamicKey?: any) {
        const orderedKeys = Object.keys(columnMapping);
        const escapeValue = (value) => {
            return `"${String(value).replace(/"/g, '""')}"`;
        };
        const orderedData = data.map(obj => {
            const orderedObj = {};
            orderedKeys.forEach(key => {
                let value = obj[key];
                if (key === 'version') {
                    if (Array.isArray(obj.version_install_date) && obj.version_install_date.length > 0) {
                        const versionInfo = obj.version_install_date.filter((s: any) => s.version !== '').map((x: any) => x.version);
                        value = (versionInfo && versionInfo.length) ? versionInfo : '';
                    }
                }

                if (key === 'installed_date') {
                    if (Array.isArray(obj.version_install_date) && obj.version_install_date.length > 0) {
                        const versionInfo = obj.version_install_date.filter((s: any) => s.date !== '' && s.date !== 0 && s.date !== '0').map((x: any) => x.date);
                        value = (versionInfo && versionInfo.length) ? versionInfo : '';

                    }
                }
                if (key === 'unconfirmed') {
                    value = value !== undefined ? (value ? 'No' : 'Yes') : '';
                }
                if (dynamicKey && dynamicKey.indexOf(key) !== -1) {
                    if (typeof value === 'string') {
                        orderedObj[columnMapping[key]] = escapeValue(value);
                    } else if (Array.isArray(value)) {
                        orderedObj[columnMapping[key]] = escapeValue(JSON.stringify(value));
                    } else {
                        orderedObj[columnMapping[key]] = '';
                    }
                } else {
                    orderedObj[columnMapping[key]] = value !== undefined && value !== null ? escapeValue(value) : '';
                }
            });
            return orderedObj;
        });

        const header = orderedKeys.map(key => escapeValue(columnMapping[key])).join(','); // Use ordered keys for the header
        const rows = orderedData.map(obj => orderedKeys.map(key => obj[columnMapping[key]]).join(','));
        return [header, ...rows].join('\n');
    }
    apiDataDownload($event: any): void {
        const fieldmap = {};
        const columnsF = cloneDeep(this.remeTableOptions.columns);
        for (const col of columnsF) {
            if (col.visible && !col.isNoVisible && col.cType !== 'agg') {
                // @ts-ignore
                fieldmap[col.columnDef] = col.header;
            }
        }
        this.downloadCVE(this.remeTableOptions.pageData, fieldmap, 'remediation_plan');
        return;
        const params: any = { file_name: 'remediation_plan.csv' };
        let url: any = '';
        if (this.cs.currentScope === '*' && !this.asset_id) {
            url = '/r/get_data/remediation_plan_global';
        } else if (this.cs.currentScope !== '*' && !this.asset_id) {
            url = '/r/get_data/remediation_plan_companies';
        } else {
            url = '/r/get_data/remediation_plan_asset';
        }
        if (this.asset_id) {
            params.asset_id = this.asset_id;
        } else {
            params.company_id = (this.cs.currentScope !== "*") ? parseInt(this.cs.currentScope.id) : undefined;
        }
        if (this.asset_id) {
            this.remeTableOptions.columns.forEach((obj: any) => {
                if (
                    obj.columnDef === "affected_companies" ||
                    obj.columnDef === "affected_assets"
                ) {
                    obj.visible = false;
                }
            });
        }
        params.fieldmap = [];
        const columns = cloneDeep(this.remeTableOptions.columns);
        for (const col of columns) {
            if (col.visible && !col.isNoVisible && col.cType !== 'agg') {
                if (col.columnDef === 'affected_companies') { col.columnDef = 'company_ids' };
                if (col.columnDef === 'affected_assets') { col.columnDef = 'asset_ids' };
                // @ts-ignore
                const field = {}; field[col.columnDef] = col.header;
                params.fieldmap.push(field);
            }
        }
        this.loaderService.display(false);
        this._bs.doRequest(url, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    window.open(res.message, '_blank');
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }


    apiDataDownloadlinux($event: any): void {
        const fieldmap = {};
        const columnsF = cloneDeep(this.SoftwareremediationsviewTableOptions.columns);
        for (const col of columnsF) {
            if (col.visible && !col.isNoVisible && col.cType !== 'agg') {
                // @ts-ignore
                fieldmap[col.columnDef] = col.header;
            }
        }
        this.downloadCVE(this.SoftwareremediationsviewTableOptions.pageData, fieldmap, 'linuxremediation_plan');
        return;
        const params: any = { file_name: 'remediation_plan.csv' };
        let url: any = '';
        if (this.cs.currentScope === '*' && !this.asset_id) {
            url = '/r/get_data/remediation_plan_global';
        } else if (this.cs.currentScope !== '*' && !this.asset_id) {
            url = '/r/get_data/remediation_plan_companies';
        } else {
            url = '/r/get_data/remediation_plan_asset';
        }
        if (this.asset_id) {
            params.asset_id = this.asset_id;
        } else {
            params.company_id = (this.cs.currentScope !== "*") ? parseInt(this.cs.currentScope.id) : undefined;
        }
        if (this.asset_id) {
            this.remeTableOptions.columns.forEach((obj: any) => {
                if (
                    obj.columnDef === "affected_companies" ||
                    obj.columnDef === "affected_assets"
                ) {
                    obj.visible = false;
                }
            });
        }
        params.fieldmap = [];
        const columns = cloneDeep(this.remeTableOptions.columns);
        for (const col of columns) {
            if (col.visible && !col.isNoVisible && col.cType !== 'agg') {
                if (col.columnDef === 'affected_companies') { col.columnDef = 'company_ids' };
                if (col.columnDef === 'affected_assets') { col.columnDef = 'asset_ids' };
                // @ts-ignore
                const field = {}; field[col.columnDef] = col.header;
                params.fieldmap.push(field);
            }
        }
        this.loaderService.display(false);
        this._bs.doRequest(url, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    window.open(res.message, '_blank');
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    apiDownload(): void {
        if (this.downloadcheckinggetdatafn) {
            this.limitchangesdownload = true;
            this.getData();
        } else if (!this.downloadcheckinggetdatafn) {
            this.limitchangesdownload = true;
            this.onScoreClick(this.epssscoreRange);
        }
    }
    evidencedownload() {
        const columns = {
            host_name: "Host Name",
            ip: "IP",
            name: "Product Name",
            version: "Version",
            installed_date: "Installed Date",
            logged_in_user: "Last Logon Username",
            uninstall_string: "Uninstall Path",
            epss_score: "EPSS Score",
            company_name: "Company Name",
            total_problems: "Total Vulnerabilities",
            critical_problems: "Critical",
            high_problems: "High",
            medium_problems: "Medium",
            low_problems: "Low",
            extraContent: "Path",
            unconfirmed: "Confirmed"
        };
        if (this.asset_list && this.asset_list.length > 0) {
            this.downloadCVE(this.asset_list, columns, 'affected_assets', ['uninstall_string', 'extraContent', 'installed_date', 'version']);
        } else {
            this.toast.sToast("info", "No data available");
        }
    }

    apiDownloadlinuxrem(): void {
        this.evidencedownloadrem();
    }

    evidencedownloadrem() {
        const columns = {
            host_name: "Host Name",
            os_name: "OS Name",
            os_version: "OS Version",
            importance: "Importance",
            online_status: "Online Status",
            is_confirmed: "Confirmed",
            company_name: "Company Name"
        };
        if (this.asset_list && this.asset_list.length > 0) {
            this.downloadCVE(this.asset_list, columns, 'affected_assets', []);
        } else {
            this.toast.sToast("info", "No data available");
        }
    }

    goToPreviousStep(): void {
        if (this.currentStep === 0) {
            return;
        }
        this.goToStep(this.currentStep - 1);
    }


    goToNextStep(): void {
        if (this.currentStep === this.totalSteps - 1) {
            return;
        }
        this.goToStep(this.currentStep + 1);
    }

    goToStep(step: number): void {
        this.currentStep = step;
        if (!this.eppscoreevidence) {
            this.getData();
        } else {
            // this.currentStep = 0;
            this.onScoreClick(this.epssscoreRange);
        }
        this._changeDetectorRef.markForCheck();
    }

    getData(view_evidence?: any, evidence?: any): void {
        this.downloadcheckinggetdatafn = true;
        this.loaderService.display(true);
        this.asset_list = [];
        if (this.eppscoreevidence) {
            this.currentStep = 0;
        }
        let skip = this.currentStep * 20;
        let limit = this.limitchangesdownload ? 2000 : 20;

        let condition: any;

        if (this.insideCompanies) {
            condition = (this.cs.currentScope === '*')
                ? { condition: true, skip: 0, limit: limit, order_by: 'company_name asc', having: `NOT NULL` }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: limit, order_by: 'host_name asc', having: `NOT NULL` };
        }
        else if (this.cs.currentScope !== '*' && this.selectedvalue.length && this.selectedvalue !== '*') {
            if (this.selectedvalue === 'online') {
                condition = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: limit, order_by: 'host_name asc', having: true };
            } else {
                condition = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: limit, order_by: 'host_name asc', having: false };
            }
        }
        else {
            condition = (this.cs.currentScope === '*')
                ? { condition: true, skip: skip, limit: limit, order_by: 'company_name asc', having: `NOT NULL` }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: skip, limit: limit, order_by: 'host_name asc', having: `NOT NULL` };
        }

        if (condition.condition === true) {
            if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                condition.condition = `product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}'  and company_id IN (${this.includeCompany.join(',')})`;
            } else {
                condition.condition = `product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}' and solution_id='${this.currentRemediation.solution_id}'`;
            }
        } else {
            condition.condition += ` and product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}' and solution_id='${this.currentRemediation.solution_id}'`;
        }
        if (evidence && evidence.col === 'view_evidence') {
            condition.condition += ` and id='${this.asset_id}'`;
        }

        if (this.insideCompanies) {
            const sortedArray = cloneDeep(this.companiesAffectedAssets.slice().sort((a: any, b: any) => a - b));
            const subarray = sortedArray.slice(skip, skip + 20);
            if (subarray && subarray.length > 0) {
                if (subarray.length === 1) {
                    condition.condition += ` and id='${subarray[0]}'`; // Use '=' for single value
                } else {
                    condition.condition += ` and id IN (${subarray.map(item => `'${item}'`).join(", ")})`; // Use 'IN' for multiple values
                }
            }
        }

        if (this.epssCondition) {
            if (condition.condition === true) {
                condition.condition = this.epssCondition;
            } else {
                condition.condition += ` and ${this.epssCondition}`;
            }
        }
        if (this.tagfilter && this.tagfilter.api) {
            if (condition.condition === true) {
                condition.condition = `${this.tagfilter.api}`;
            } else {
                if (this.cs.manualtagfilter) {
                    condition.condition += ` and not ( ${this.tagfilter.api} )`;
                } else {
                    condition.condition += ` and ( ${this.tagfilter.api} )`;
                }
            }
        }
        if (this.selectedvalue === '*' && this.asset_online_status) {
            condition.having = (this.asset_online_status === 'online') ? true : false;
        }
        this._bs
            .doRequest('/r/report_queries/remediation_plan_asset_details', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.eppscoreevidence = false;
                this.loaderService.display(false);
                this.asset_list = []; this.assetEvidence = [];
                if (result.status) {
                    if (this.insideCompanies) {
                        this.totalSteps = Math.ceil(this.companiesAffectedAssets.length / 20);
                    } else {
                        this.totalSteps = Math.ceil(this.currentRemediation.affected_assets / 20);
                    }
                    this.timelineCount = this.currentRemediation.affected_assets;
                    result.data.map((obj: any) => {
                        const extra_content = obj.evidence.filter((x: any) => x !== '');
                        obj.extraContent = extra_content.toString();
                        obj.uninstall_string = obj.uninstall_string.filter((x: any) => x).join(',')
                        obj.evidence = obj.evidence.filter((x: any) => x).join(',')
                        if (obj.version_install_date && obj.version_install_date.length) {
                            const asset_list = obj.version_install_date.filter((obj: any, index: any, array: any) => array.findIndex((o: any) => o.date === obj.date && o.version === obj.version) === index);
                            asset_list.forEach((versionEntry: any) => {
                                if (versionEntry.date && versionEntry.date.length === 8) {
                                    const year = versionEntry.date.substring(0, 4);
                                    const month = versionEntry.date.substring(4, 6);
                                    const day = versionEntry.date.substring(6, 8);
                                    versionEntry.date = `${day}-${month}-${year}`;
                                }
                            });
                            obj.version_install_date = asset_list;
                        } else {
                            obj.install_date = '-';
                        }
                    });
                    if (view_evidence) {
                        this.assetEvidence = (result.data && result.data.length) ? [result.data[0]] : [];
                        this.modalService.open('view_asset_evidence');
                        return
                    }
                    this.asset_list = result.data;
                    if (this.limitchangesdownload) {
                        this.evidencedownload();
                        this.limitchangesdownload = false;
                    }
                    this.cView = "affected_assets";
                    this._changeDetectorRef.detectChanges();
                    this.drawerOpened = true;
                    if (!this.drawer.opened) {
                        this.drawer.toggle().then((r) => console.log(r));
                    }
                    setTimeout(() => {
                        let el = document.getElementById('remData');
                        el.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }
            });
    }


    getData1(): void {
        this.loaderService.display(true);
        let skip = this.currentStep * 20;

        let condition: any;

        if (this.insideCompanies) {
            condition = (this.cs.currentScope === '*')
                ? { condition: true, skip: 0, limit: 20, having: `NOT NULL` }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: 20, having: `NOT NULL` };
        } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue !== '*') {
            if (this.selectedvalue === 'online') {
                condition = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: 20, having: true };
            } else {
                condition = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: 20, having: false };
            }
        } else {
            condition = (this.cs.currentScope === '*')
                ? { condition: true, skip: 0, limit: 20, having: `NOT NULL` }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: 20, having: `NOT NULL` };
        }

        if (condition.condition === true) {
            if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                condition.condition = `product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}'  and company_id IN (${this.includeCompany.join(',')})`;
            } else {
                condition.condition = `product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}' and solution_id='${this.currentRemediation.solution_id}'`;
            }

        } else {
            condition.condition += ` and product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}' and solution_id='${this.currentRemediation.solution_id}'`;
        }
        if (this.insideCompanies) {
            const sortedArray = cloneDeep(this.companiesAffectedAssets.slice().sort((a: any, b: any) => a - b));
            const subarray = sortedArray.slice(skip, skip + 20);
            if (subarray && subarray.length > 0) {
                if (subarray.length === 1) {
                    condition.condition += ` and id='${subarray[0]}'`; // Use '=' for single value
                } else {
                    condition.condition += ` and id IN (${subarray.map(item => `'${item}'`).join(", ")})`; // Use 'IN' for multiple values
                }
            }
        }
        if (this.tagfilter && this.tagfilter.api) {
            if (this.cs.manualtagfilter) {
                condition.condition += ` and not ( ${this.tagfilter.api} )`;
            } else {
                condition.condition += ` and ( ${this.tagfilter.api} )`;
            }

        }
        if (this.selectedvalue === '*' && this.asset_online_status) {
            condition.having = (this.asset_online_status === 'online') ? true : false;
        }
        this._bs
            .doRequest('/r/report_queries/remediation_plan_asset_epss_details', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.eppscore = result.data
                }
            });
    }



    onScoreClick(scoreRange: string): void {
        this.downloadcheckinggetdatafn = false;
        let havingCondition: string;
        if (!this.eppscoreevidence) {
            this.currentStep = 0;
        }
        this.epssscoreRange = scoreRange
        switch (scoreRange) {
            case '>= 0.95':
                this.currentStep = 0;
                havingCondition = 'epss_score >= 0.95';
                break;
            case '0.90-0.95':
                havingCondition = 'epss_score < 0.95 AND epss_score >= 0.90';
                break;
            case '0.85-0.90':
                havingCondition = 'epss_score < 0.90 AND epss_score >= 0.85';
                break;
            case '0.00-0.85':
                havingCondition = 'epss_score < 0.85';
                break;
            default:
                return;
        }
        this.asset_list = [];
        let skip = this.currentStep * 20;
        let limit = this.limitchangesdownload ? 2000 : 20;

        let condition: any;
        if (this.insideCompanies) {
            condition = (this.cs.currentScope === '*')
                ? { condition: true, skip: 0, limit: limit, order_by: 'company_name asc', having: `NOT NULL` }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: limit, order_by: 'host_name asc', having: `NOT NULL` };
        } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue !== '*') {
            if (this.selectedvalue === 'online') {
                condition = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: limit, having: true };
            } else {
                condition = { condition: "company_id='" + this.cs.currentScope.id + "'", skip: 0, limit: limit, having: false };
            }
        } else {
            condition = (this.cs.currentScope === '*')
                ? { condition: true, skip: skip, limit: limit, order_by: 'company_name asc', having: `NOT NULL` }
                : { condition: "company_id='" + this.cs.currentScope.id + "'", skip: skip, limit: limit, order_by: 'host_name asc', having: `NOT NULL` };
        }

        if (condition.condition === true) {
            if (this.includeCompany && this.includeCompany.length && !this.includeCompany.includes('*')) {
                condition.condition = `product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}'  and company_id IN (${this.includeCompany.join(',')})`;
            } else {
                condition.condition = `product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}' and solution_id='${this.currentRemediation.solution_id}'`;
            }
        } else {
            condition.condition += ` and product='${this.currentRemediation.product}' and os_name='${this.currentRemediation.os_name}' and fix='${this.currentRemediation.fix}'`;
        }
        if (this.insideCompanies) {
            const sortedArray = cloneDeep(this.companiesAffectedAssets.slice().sort((a: any, b: any) => a - b));
            const subarray = sortedArray.slice(skip, skip + 20);
            if (subarray && subarray.length > 0) {
                if (subarray.length === 1) {
                    condition.condition += ` and id='${subarray[0]}'`; // Use '=' for single value
                } else {
                    condition.condition += ` and id IN (${subarray.map(item => `'${item}'`).join(", ")})`; // Use 'IN' for multiple values
                }
            }
        }
        const payload: any = {
            condition: condition.condition,
            skip: condition.skip,
            limit: condition.limit,
            having: condition.having
        };
        if (havingCondition) {
            if (payload.condition === true) {
                payload.condition = havingCondition;
            } else {
                payload.condition += ` and ${havingCondition}`;
            }
        }
        if (this.selectedvalue === '*' && this.asset_online_status) {
            payload.having = (this.asset_online_status === 'online') ? true : false;
        }
        this._bs.doRequest('/r/report_queries/remediation_plan_asset_details_by_epss', 'get', null, payload)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.eppscoreevidence = true;
                if (result && result.data) {
                    this.totalSteps = Math.ceil(result.total / 20);
                    result.data.forEach((obj: any) => {
                        const extra_content = obj.evidence.filter((x: any) => x !== '');
                        obj.extraContent = extra_content.toString();

                        if (obj.version_install_date && obj.version_install_date.length) {
                            const asset_list = obj.version_install_date.filter((item: any, index: any, array: any) =>
                                array.findIndex((o: any) => o.date === item.date && o.version === item.version) === index
                            );

                            asset_list.forEach((versionEntry: any) => {
                                if (versionEntry.date && versionEntry.date.length === 8) {
                                    const year = versionEntry.date.substring(0, 4);
                                    const month = versionEntry.date.substring(4, 6);
                                    const day = versionEntry.date.substring(6, 8);
                                    versionEntry.date = `${day}-${month}-${year}`;
                                }
                            });
                            obj.version_install_date = asset_list;
                        } else {
                            obj.install_date = '-';
                        }
                    });
                    this.asset_list = result.data;
                    if (this.limitchangesdownload) {
                        this.evidencedownload();
                        this.limitchangesdownload = false;
                    }
                    this.cView = "affected_assets";
                    this._changeDetectorRef.detectChanges();
                    this.drawerOpened = true;

                    if (!this.drawer.opened) {
                        this.drawer.toggle().then((r) => console.log(r));
                    }

                    setTimeout(() => {
                        const el = document.getElementById('remData');
                        el.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }
            });
    }




    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    isSameDay(current: string, compare: string): boolean {
        return DateTime.fromISO(current).hasSame(
            DateTime.fromISO(compare),
            'day'
        );
    }

    remediationFilterCal(rule: any): void {
        this.epssCondition = '';
        this.isLoading = true;
        const response = (rule) ? this.cs.filterConditionRule(this.originalRemData, rule) : this.originalRemData;
        this.cs.tableFilterCalBack.next({ value: response });
        setTimeout(() => { this.isLoading = false; }, 2000);
    }

    filterChangePending(value: any): void {
        const data = Object.assign({}, this.pendingRemediationTableOptions);
        this.pendingRemediationTableOptions = {};
        this._changeDetectorRef.detectChanges();
        data.pageData = []; data.tableOptions.pageTotal = 0;
        data.tableOptions.serverSide.condition = (value) ? `problem_group_id=${value}` : "";
        this.pendingRemediationTableOptions = data;
        this._changeDetectorRef.detectChanges();
    }

    filterChangeSoftware(value: any): void {
        const data = Object.assign({}, this.pendingRemediationSoftwareTableOptions);
        this.pendingRemediationSoftwareTableOptions = {};
        this._changeDetectorRef.detectChanges();
        data.pageData = []; data.tableOptions.pageTotal = 0;
        data.tableOptions.serverSide.condition = (value) ? `problem_group_id=${value}` : "";
        this.pendingRemediationSoftwareTableOptions = data;
        this._changeDetectorRef.detectChanges();
    }

    tagFilterCall($event: any): void {
        this.tagfilter = $event;
        this.isLoading = true;
        this.isTagFilterCalled = true;
        this.getRemediation();
        setTimeout(() => { this.isLoading = false; }, 2000);
    }
    tagFilterCalllin($event: any): void {
        this.tagfilter = $event;
        this.isLoading = true;
        this.isTagFilterCalled = true;
        this.softwareviewsw();
        setTimeout(() => { this.isLoading = false; }, 2000);
    }

    getfilterCompany(value: string) {
        if (value === '*') {
            this.selectedvalue = value
            setTimeout(() => this.getRemediation());
            return;
        } else if (value === 'online') {
            this.selectedvalue = value
            setTimeout(() => this.getRemediation())
        } else if (value === 'offline') {
            this.selectedvalue = value
            setTimeout(() => this.getRemediation());
        }
    }

    async handlesStatusFilter(status: any) {
        // if(!this.linuxremeviewcheck){
        //     await this.getData1();
        // (!this.eppscoreevidence) ? await this.getData() : await this.onScoreClick(this.epssscoreRange);
        // }else{
        //    this.linkclickcompanies1(this.onlinelinux);
        // }

        if(this.linuxfilterstatusviewchecking){
            this.selectedvalue = status;
            this.linkclickcompanies1(this.onlinelinux);
        }else{
            await this.getData1();
            (!this.eppscoreevidence) ? await this.getData() : await this.onScoreClick(this.epssscoreRange);
        }
    }


    setMinDateAndMaxDate(): void {
        let today = new Date();
        this.minDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
        this.maxDate = new Date(
            today.getFullYear(),
            today.getMonth() + 2,
            today.getDate()
        );
    }


    patchModal(data: any, type: any): void {
        this.assets = [];
        this.companiesId = data.company_ids;
        this.patch_type = type;
        let name = data.software_name;
        let company_id = data.company_ids[0];
        let asset_ids = data.asset_ids;
        let count = data.affected_assets;
        for (let i = 0; i < asset_ids.length; i++) {
            const params: any = {
                id: asset_ids[i],
                // limit: count,
                condition: `name='${name}' and company_id=${company_id}`,
                skip: 0,
            };
            this.currentData.date_time_stamp = null;
            this._bs
                .doRequest(
                    `/r/report_queries/application_vulnerabilities_patching_asset_details`,
                    "get",
                    null,
                    params
                )
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.patchLaterData = cloneDeep(result);

                    this.patchLaterData.data.forEach((item: any) => {
                        this.assets.push({
                            id: asset_ids[i],
                            name: item.host_name,
                            selected: false,
                            online_status: item.online_status,
                            image_path: item.online_status
                                ? this.successIcon
                                : this.failureIcon,
                        });
                    });
                });
        }
        if (type === "now") {
            delete this.currentData.date_time_stamp;
        }

        this.modalService.open("patchingSettings");
    }

    patchActionCall(data: any, type: any): void {
        this.currentData = cloneDeep(data);
        if (type === "Patch Now") {
            this.patchModal(this.currentData, "now");
        }
        if (type === "Patch Later") {
            this.patchModal(this.currentData, "later");
        }
    }

    patchGlobalCall(data: any): void {
        this.selectedData = cloneDeep(data);
        if (data.action.text === 'Patch') {

        }
    }

    patchingFn(): void {
        const reqData: any = cloneDeep(this.patchingDetails);
        reqData.assets = this.assets
            .filter((x: any) => x.selected)
            .map((y: any) => y.id);
        reqData.included_application = [this.currentData.software_name];
        reqData.companies = this.companiesId;
        if (this.patch_type === "later") {
            const day: number = this.currentData.patch_date.getDate();
            const month: number = this.currentData.patch_date.getMonth() + 1;
            const year: number = this.currentData.patch_date.getFullYear();
            const timeArray = this.currentData.patch_time.split(":");
            let hours = parseInt(timeArray[0], 10);
            let minutes = parseInt(timeArray[1], 10);
            reqData.date = {
                days: day,
                months: month,
                years: year,
            };
            reqData.time = {
                hours: hours,
                minutes: minutes,
            };

            reqData.patch_type = "app";
        }

        reqData.type = "application";
        reqData.patch_when = this.patch_type;
        this.loaderService.display(true);

        this._bs
            .doRequest("/w/company/patch_now", "post", reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast("success", result.message);
                    this.modalService.close("patchingSettings");
                } else {
                    const data = result.message ? result.message : result.data;
                    this.toast.sToast("error", data);
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    hasSelected(): boolean {
        // @ts-ignore
        const len = this.assets.filter((x: any) => x.selected);
        if (len && len.length) {
            return true;
        } else {
            return false;
        }
    }

    isAllSelected(): boolean {
        // @ts-ignore
        const len = this.assets.filter((x: any) => x.selected).length;
        // @ts-ignore
        if (this.assets.length === len) {
            return true;
        }
        return false;

    }

    selectAllToggle(event: any): void {
        // @ts-ignore
        this.assets.forEach((obj: any) => {
            obj.selected = event.checked;
        });
        this._changeDetectorRef.detectChanges();
    }

    softwareviewsw() {
        const tableOption: any = {
            columns: [
                {
                    header: "Action",
                    columnDef: "actions",
                    "width": "120px",
                    align: "left",
                    cType: "string",
                    filter: "remeaction",
                    cell: "(element: any) => `${element.action}`",
                    order: 0,
                    isHtml: true,
                    htmlIcon: true,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                    isDropdown: true,
                    dataSource: [
                        { text: 'OS Update', value: 'OS Update' },
                        { text: 'Run remediation script', value: 'Run remediation script' },
                        { text: 'Software Patch', value: 'Software Patch' },
                        { text: 'Uninstall', value: 'Uninstall' },
                        { text: 'Update', value: 'Update' }
                    ],
                },
                {
                    header: "Product",
                    columnDef: "product",
                    "width": "150px",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.product}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isHtml: true,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    htmlIcon: true,
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Affected Companies",
                    columnDef: "affected_companies",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.affected_companies}`",
                    order: 0,
                    align: "center",
                    isHyperlink: true,
                    visible: !this.asset_id,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                    width: '120px'
                },
                {
                    header: "Affected Assets",
                    columnDef: "affected_assets",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.affected_assets}`",
                    order: 0,
                    align: "center",
                    isHyperlink: true,
                    visible: !this.asset_id,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                    width: '100px'
                },
                {
                    header: "OS Name",
                    columnDef: "os_name",
                    cType: "string",
                    width: '100px',
                    isHyperlink: false,
                    filter: "",
                    cell: "(element: any) => `${element.os_name}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "EPSS Score",
                    rangeColor: true,
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "epss_score",
                    filter: "tofixed",
                    cell: "(element: any) => `${element.epss_score}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    isEpssScore: true,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: 'Severity',
                    cType: '',
                    columnDef: 'severity',
                    iscolumnSearch: true,
                    filter: '', width: '100px',
                    isColoredCntrl: true,
                    isCustomText: true,
                    visible: true,
                    order: 5,
                    isSort: true,
                    isDropdown: true,
                    isSeverity: true,
                    dataSource: [
                        { text: 'Critical', value: 'Critical' },
                        { text: 'High', value: 'High' },
                        { text: 'Medium', value: 'Medium' },
                        { text: 'Low', value: 'Low' }
                    ],
                },
                {
                    header: "KB/Fix",
                    columnDef: "fix",
                    width: '150px',
                    cType: "string",
                    isHyperlink: true,
                    filter: "",
                    cell: "(element: any) => `${element.fix}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Total Vulnerabilities",
                    cType: "number",
                    width: "w-[80px]",
                    align: "center",
                    columnDef: "total_problems",
                    isVulnerability: true,
                    is_total_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.total_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Critical",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "critical_problems",
                    isVulnerability: true,
                    is_critical_problems: true,
                    customFilter: "",
                    cell: "(element: any) => `${element.critical_problems}`",
                    visible: true,
                    order: 0,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-critical",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "High",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "high_problems",
                    isVulnerability: true,
                    is_high_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.high_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-high",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Medium",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "medium_problems",
                    isVulnerability: true,
                    is_medium_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.medium_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-medium",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Low",
                    cType: "number",
                    width: "w-[80px]",
                    columnDef: "low_problems",
                    isVulnerability: true,
                    is_low_problems: true,
                    filter: "",
                    cell: "(element: any) => `${element.low_problems}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "cs-low",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Open Tickets",
                    columnDef: "open_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.open_ticket_count}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: true,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Closed Tickets",
                    columnDef: "closed_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => `${element.closed_ticket_count}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                }
            ],
            sortOptions: { active: 'fix', direction: 'asc' },
            _pageData: [],
            overridePageLoad: true,
            tableOptions: {
                title: 'Linux Remediation Plan',
                isServerSide: false,
                selectText: 'plans',
                loading: true,
                floatingFilter: true,
                rowSelection: true,
                showAction: true,
                isHideGlobal: true,
                isCustomSort: true,
                apiDownload: true,
                hideDownload: true,
                isDefaultSearch: true,
                isDefaultTagFilter: true,
                isDefaultKeySearch: true,
                isUserRestCheck: (!this.asset_id) ? true : false,
                isNoAdd: (!this.asset_id) ? true : false,
                actionMenuItems: [
                    {
                        text: 'Suppress',
                        icon: "feather:alert-triangle",
                        callback: 'suppressFn',
                        isGlobal: true,
                        target: "suppressed_records",
                    },
                    {
                        text: 'Integration Action',
                        icon: "heroicons_outline:ticket",
                        callback: 'suppressFn',
                        isGlobal: true,
                        hideLocal: (this.cs.currentScope === '*') ? true : false,
                        target: "integration_action",
                    }
                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 100,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: true,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                isNotSiteChange: true,
                filterEmitCal: 'remFilterCal'
            },
            customText: [
                {
                    status: true,
                    DisplayText: 'Yes',
                    class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
                },
                {
                    status: false,
                    DisplayText: 'No',
                    class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

                },
                {
                    name: 'epss_vuls',
                    customText: [
                        {
                            range: { from: 0.0, to: 0.85 },
                            color: '#67ace1',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.85, to: 0.90 },
                            color: '#f8c851',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.90, to: 0.95 },
                            color: '#f18c43',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.95, to: 1 },
                            color: '#92243e',
                            'text-color': '#ffffff'
                        },
                    ]
                },
                {
                    status: "Critical",
                    DisplayText: "CRITICAL",
                    color: "#92243e",
                    "text-color": "#ffffff",
                },
                {
                    status: "High",
                    DisplayText: "HIGH",
                    color: "#de4a50",
                    "text-color": "#ffffff",
                },
                {
                    status: "Medium",
                    DisplayText: "MEDIUM",
                    color: "#f18c43",
                    "text-color": "#000000",
                },
                {
                    status: "Low",
                    DisplayText: "LOW",
                    color: "#f8c851",
                    "text-color": "#000000",
                },
                {
                    status: "Info",
                    DisplayText: "INFO",
                    color: "#67ace1",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0, to: 0 },
                    color: "#c2ffc4",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 4.9 },
                    color: "#ffe24b",
                    "text-color": "#000000",
                },
                {
                    range: { from: 5, to: 8.9 },
                    color: "#ec9b0c",
                    "text-color": "#000000",
                },
                {
                    range: { from: 9, to: 10 },
                    color: "#ff6546",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0, to: 0.49 },
                    color: "#6fb8f9",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0.5, to: 0.74 },
                    color: "#FCE94E",
                    "text-color": "#000000",
                },
                {
                    range: { from: 0.75, to: 1 },
                    color: "#EB973E",
                    "text-color": "#000000",
                },
                {
                    range: { from: 1, to: 4.9 },
                    color: "#ffe24b",
                    "text-color": "#000000",
                },
                {
                    range: { from: 5, to: 8.9 },
                    color: "#ec9b0c",
                    "text-color": "#000000",
                },
                {
                    range: { from: 9, to: 10 },
                    color: "#ff6546",
                    "text-color": "#000000",
                },
            ],
            changeValue: new Subject<any>()
        }
        tableOption.tableOptions.actionMenuItems.map((x: any) => {
            if (x.target === 'integration_action') {
                x.isGlobal = false; x.hideLocal = true;
                if (this.cs.currentScope !== '*' || this.asset_id) {
                    x.isGlobal = true; x.hideLocal = false;
                }
            }
        });
        this.SoftwareremediationsviewTableOptions = cloneDeep(tableOption);
        (!this.isTagFilterCalled) ? this.showTable = false : null;
        this.loaderService.display(true);
        let condition: string | boolean = true;
        if (this.cs.currentScope === '*') {
            if (!this.selectedOslinux.includes('*')) {
                if (this.selectedOslinux.length === 1) {
                    condition = `os_name = '${this.selectedOslinux[0]}'`;
                } else if (this.selectedOslinux.length > 1) {
                    condition = `os_name IN (${this.selectedOslinux.map(os => `'${os}'`).join(", ")})`;
                }
            } else {
                condition = true
            }
        } else {
            // If scope is not global
            if (!this.selectedOslinux.includes('*')) {
                if (this.selectedOslinux.length === 1) {
                    condition = `company_id = ${this.cs.currentScope.id} and os_name = '${this.selectedOslinux[0]}'`;
                } else if (this.selectedOslinux.length > 1) {
                    condition = `company_id = ${this.cs.currentScope.id} and os_name IN (${this.selectedOslinux.map(os => `'${os}'`).join(", ")})`;
                }
            } else {
                condition = `company_id = ${this.cs.currentScope.id}`

            }
        }

        if (typeof condition === 'string') {
            condition += ` and is_suppressed = false`;
        } else if (condition === true) {
            condition = `is_suppressed = false`;
        }

        // if (this.epssCondition) {
        //     if (condition === true) {
        //         condition = '';
        //     }
        //     if (typeof condition === 'string' && !condition.includes(this.epssCondition)) {
        //         condition += (condition ? ` and ${this.epssCondition}` : this.epssCondition);
        //     }
        // }

        if (this.epssCondition) {
            if (typeof condition === 'string' && !condition.includes(this.epssCondition)) {
                condition += (condition ? ` and ${this.epssCondition}` : this.epssCondition);
            }
        }

       

        

        let params: any = {
            skip: 0,
            limit: 3000,
            order_by: 'fix asc',
            condition: condition 
        };

        if (this.tagfilter && this.tagfilter.api) {
            if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue === 'online') {
                params.having = true;
            } else if (this.cs.currentScope !== '*' && this.selectedvalue && this.selectedvalue === 'offline') {
                params.having = false;
            } else {
                params.having = `NOT NULL`;
            }
            if (params.condition === true) {
                if (this.cs.manualtagfilter) {
                    params.condition = `not ${this.tagfilter.api}`;
                } else {
                    params.condition = `${this.tagfilter.api}`;
                }
            } else if (this.cs.manualtagfilter) {
                if (params.condition === true) {
                    params.condition = `not (${this.tagfilter.api})`;
                } else {
                    params.condition += ` and not (${this.tagfilter.api})`;
                }

            } else {
                params.condition += ` and (${this.tagfilter.api})`;
            }
        }
        const url = '/r/report_queries/sw_remediations_view';
        (!this.isTagFilterCalled) ? this.showHideLoadingL(true) : null;
        this._bs.doRequest(url, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);

                if (result.status && result.data && result.data.length) {
                    result.data.sort((a: any, b: any) => b['epss_vuls'] - a['epss_vuls']);
                    try {
                        if (this.cs.currentScope !== '*' || this.asset_id) {
                            try {
                                const asset_id = this.asset_id;
                                try {
                                    if (this.cs.currentScope !== '*' || asset_id) {
                                        const reqParams: any = {
                                            condition: true,
                                            skip: 0,
                                            limit: 2000
                                        }
                                        reqParams.condition = (this.cs.currentScope !== "*") ? `company_id=${parseInt(this.cs.currentScope.id)}` : null;
                                        try {
                                            this.loaderService.display(true);
                                            this._bs.doRequest(`/report_queries/event_tickets`, 'get', null, reqParams)
                                                .pipe(takeUntil(this._unsubscribeAll)).subscribe((ticket: any) => {
                                                    this.loaderService.display(false);
                                                    if (ticket.status && ticket.data && ticket.data.length) {
                                                        if (asset_id) {
                                                            ticket.data = ticket.data.filter((obj: any) => {
                                                                let assetIdsArray: number[] = [];
                                                                if (obj.asset_ids && obj.asset_ids !== null && obj.asset_ids !== undefined) {
                                                                    if (Array.isArray(obj.asset_ids)) {
                                                                        assetIdsArray = obj.asset_ids.map(item => parseInt(item));
                                                                    } else if (typeof obj.asset_ids === 'string') {
                                                                        assetIdsArray = obj.asset_ids.split(',').map(item => parseInt(item));
                                                                    } else {
                                                                        assetIdsArray = [parseInt(obj.asset_ids)];
                                                                    }
                                                                    return assetIdsArray.includes(asset_id);
                                                                } else if (obj.asset_id && obj.asset_id !== null) {
                                                                    assetIdsArray = obj.asset_id.toString().split(',').map(item => parseInt(item));
                                                                    return assetIdsArray.includes(asset_id);
                                                                }
                                                                return false;
                                                                // return assetIdsArray.includes(asset_id);
                                                            });
                                                        }
                                                        result.data.forEach((obj: any) => {
                                                            obj.view_evidence = (asset_id) ? 'View Evidence' : undefined;
                                                            if (!obj.ticket_ids || !obj.ticket_ids.length) {
                                                                obj.ticket_ids = [];
                                                            }
                                                            const data = { refid: obj.solution_id, asset_ids: obj.asset_ids };
                                                            const sortedData = ticket.data.sort((a: any, b: any) => (a.status === "Open" ? -1 : 1))
                                                            obj.ticket_ids = [];
                                                            const { tickets, open_ticket_count, closed_ticket_count } = this.searchTicketStatusMaps(data, sortedData);
                                                            obj.ticket_status_map = tickets; obj.open_ticket_count = open_ticket_count; obj.closed_ticket_count = closed_ticket_count;
                                                        });
                                                        this.originalRemData = result.data;
                                                        if (this.isTagFilterCalled) {
                                                            this.isTagFilterCalled = false;
                                                            this.cs.tableFilterCalBack.next({ value: result.data });
                                                        } else {
                                                            this.SoftwareremediationsviewTableOptions.pageData = result.data;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.pageTotal = result.data.length;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.pageSize = 0;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.pagination = false;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.loading = false;
                                                            this.showHideLoadingL(false);
                                                            this.showTable = true;
                                                        }
                                                    } else {
                                                        result.data.forEach((obj: any) => {
                                                            obj.view_evidence = (asset_id) ? 'View Evidence' : undefined;
                                                            obj.ticket_ids = [];
                                                            obj.ticket_status_map = []; obj.open_ticket_count = 0; obj.closed_ticket_count = 0;
                                                        });
                                                        this.originalRemData = result.data;
                                                        if (this.isTagFilterCalled) {
                                                            this.isTagFilterCalled = false;
                                                            this.cs.tableFilterCalBack.next({ value: result.data });
                                                        } else {
                                                            this.SoftwareremediationsviewTableOptions.pageData = result.data;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.pageTotal = result.data.length;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.pageSize = 0;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.pagination = false;
                                                            this.SoftwareremediationsviewTableOptions.tableOptions.loading = false;
                                                            this.showHideLoadingL(false);
                                                            this.showTable = true;
                                                        }
                                                    }
                                                })
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        this.originalRemData = result.data;
                                        if (this.isTagFilterCalled) {
                                            this.isTagFilterCalled = false;
                                            this.cs.tableFilterCalBack.next({ value: result.data });
                                        } else {
                                            this.SoftwareremediationsviewTableOptions.pageData = result.data;
                                            this.SoftwareremediationsviewTableOptions.tableOptions.pageTotal = result.data.length;
                                            this.SoftwareremediationsviewTableOptions.tableOptions.pageSize = 0;
                                            this.SoftwareremediationsviewTableOptions.tableOptions.pagination = false;
                                            this.SoftwareremediationsviewTableOptions.tableOptions.loading = false;
                                            this.showHideLoadingL(false);
                                            this.showTable = true;
                                        }
                                    }

                                } catch (error) {
                                    console.log(error);
                                }

                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            this.originalRemData = result.data;
                            if (this.isTagFilterCalled) {
                                this.isTagFilterCalled = false;
                                this.cs.tableFilterCalBack.next({ value: result.data });
                            } else {
                                this.SoftwareremediationsviewTableOptions.pageData = result.data;
                                this.SoftwareremediationsviewTableOptions.tableOptions.pageTotal = result.data.length;
                                this.SoftwareremediationsviewTableOptions.tableOptions.pageSize = 0;
                                this.SoftwareremediationsviewTableOptions.tableOptions.pagination = false;
                                this.SoftwareremediationsviewTableOptions.tableOptions.loading = false;
                                this.showHideLoadingL(false);
                                this.showTable = true;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    this.originalRemData = [];
                    if (this.isTagFilterCalled) {
                        this.isTagFilterCalled = false;
                        this.cs.tableFilterCalBack.next({ value: [] });
                    } else {
                        this.SoftwareremediationsviewTableOptions.pageData = [];
                        this.SoftwareremediationsviewTableOptions.tableOptions.pageTotal = 0;
                        this.SoftwareremediationsviewTableOptions.tableOptions.pageSize = 0;
                        this.SoftwareremediationsviewTableOptions.tableOptions.pagination = false;
                        this.SoftwareremediationsviewTableOptions.tableOptions.loading = false;
                        this.showHideLoadingL(false);
                        this.showTable = true;
                    }
                }
            });
    }

    showHideLoadingL(status: boolean): void {
        const data = Object.assign({}, this.SoftwareremediationsviewTableOptions);
        this.SoftwareremediationsviewTableOptions = {};
        this._changeDetectorRef.detectChanges();
        this.SoftwareremediationsviewTableOptions = data;
        this.SoftwareremediationsviewTableOptions.tableOptions.loading = status;
        this._changeDetectorRef.detectChanges();
    }

    softwareremediatedview() {
        const data = Object.assign({}, this.SoftwareremediatedviewTableOptions);
        data.pageData = []; data.tableOptions.pageTotal = 0;
        this.SoftwareremediatedviewTableOptions.pageData = []; this.SoftwareremediatedviewTableOptions.tableOptions.pageTotal = 0;
        this.SoftwareremediatedviewTableOptions = {}; this._changeDetectorRef.detectChanges();
        data.tableOptions.serverSide.url = '/r/report_queries/sw_remediated_view';
        if (this.cs.currentScope === '*') {
            data.tableOptions.serverSide.condition = true;
            data.tableOptions.serverSide.isGlobal = true;
        }
        else {
            data.tableOptions.serverSide.isGlobal = false;
        }
        this.SoftwareremediatedviewTableOptions = data; this._changeDetectorRef.detectChanges();
    }


    Softwareremediatedassetview() {
        const data = Object.assign({}, this.SoftwareremediatedassetviewTableOptions);
        data.pageData = []; data.tableOptions.pageTotal = 0;
        this.SoftwareremediatedassetviewTableOptions.pageData = []; this.SoftwareremediatedassetviewTableOptions.tableOptions.pageTotal = 0;
        this.SoftwareremediatedassetviewTableOptions = {}; this._changeDetectorRef.detectChanges();
        data.tableOptions.serverSide.url = '/r/report_queries/sw_remediated_view';
        if (this.cs.currentScope === '*' && this.asset_id) {
            data.tableOptions.serverSide.condition = `asset_id = ${this.asset_id}`;
            data.tableOptions.serverSide.isGlobal = true;
        }
        else {
            data.tableOptions.serverSide.condition = `asset_id = ${this.asset_id}`;
            data.tableOptions.serverSide.isGlobal = false;
        }
        this.SoftwareremediatedassetviewTableOptions = data; this._changeDetectorRef.detectChanges();
    }

    Softwareremediationassetview() {
        const data = Object.assign({}, this.SoftwareremediationsassetviewTableOptions);
        data.pageData = []; data.tableOptions.pageTotal = 0;
        this.SoftwareremediationsassetviewTableOptions.pageData = []; this.SoftwareremediationsassetviewTableOptions.tableOptions.pageTotal = 0;
        this.SoftwareremediationsassetviewTableOptions = {}; this._changeDetectorRef.detectChanges();
        data.tableOptions.serverSide.url = '/r/report_queries/sw_remediations_view';
        if (this.cs.currentScope === '*' && this.asset_id) {
            data.tableOptions.serverSide.condition = `asset_id = ${this.asset_id}`;
            data.tableOptions.serverSide.isGlobal = true;
        }
        else {
            data.tableOptions.serverSide.condition = `asset_id = ${this.asset_id}`;
            data.tableOptions.serverSide.isGlobal = false;
        }
        this.SoftwareremediationsassetviewTableOptions = data; this._changeDetectorRef.detectChanges();
    }

    isNotRedHatPlatform(cAsset: any): boolean {
        return cAsset && cAsset.platform && !this.redhat_platform.includes(cAsset.platform.toLowerCase());
    }

    isRedHatOs(cAsset: any): boolean {
        return cAsset && cAsset.os_name && cAsset.os_name.toLowerCase().includes('red hat');
    }

}
