<div class="flex flex-wrap items-center justify-start gap-2 pt-4">
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'antiransomeware'" id="antiransomeware"
        [ngClass]="{'bg-primary text-white': cView === 'antiransomeware'}">
        Anti Ransomware
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'backupSoftware'" id="backupSoftware"
        [ngClass]="{'bg-primary text-white': cView === 'backupSoftware'}">
        Backup Software
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'bruteforce'" id="bruteforce" [ngClass]="{'bg-primary text-white': cView === 'bruteforce'}">
        Brute Force Setting
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="complianceScan()" id="backupSoftware"
        [ngClass]="{'bg-primary text-white': cView === 'compliance-scan'}">
        Compliance Scan
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'deprecation'" id="deprecation" [ngClass]="{'bg-primary text-white': cView === 'deprecation'}">
        Deprecation Days
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'retention_period'" id="retention_period"
        [ngClass]="{'bg-primary text-white': cView === 'retention_period'}">
        Deprecated Asset Retention Period
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'domain-check'" id="domain-check" *ngIf="uS._user_roles.indexOf('admin') >-1"
        [ngClass]="{'bg-primary text-white': cView === 'domain-check'}">
        Domain Scan
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'edr-application'" id="edrapplication"
        [ngClass]="{'bg-primary text-white': cView === 'edr-application'}">
        EDR Application
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="excludeReport()" id="excludereportcard"
        [ngClass]="{'bg-primary text-white': cView === 'exc-secreport-card'}">
        Exclude Component(s) from Security Report Card
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'firewall'" id="firewall" [ngClass]="{'bg-primary text-white': cView === 'firewall'}">Firewall
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'patching-status'" id="patchingstatus"
        [ngClass]="{'bg-primary text-white': cView === 'patching-status'}">
        Patch Management
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'performanceM'" id="performanceM"
        [ngClass]="{'bg-primary text-white': cView === 'performanceM'}">
        Performance Management
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'port'" id="port" [ngClass]="{'bg-primary text-white': cView === 'port'}">
        Ports Policy
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'remote-agent-install'" id="remoteagentinstall"
        [ngClass]="{'bg-primary text-white': cView === 'remote-agent-install'}">Remote Agent Install
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'report-settings'" id="reportsettings"
        [ngClass]="{'bg-primary text-white': cView === 'report-settings'}">
        Report Settings
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'lwa-interval'" id="lwainterval"
        [ngClass]="{'bg-primary text-white': cView === 'lwa-interval'}">
        Scan Time Interval
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'microsoft_patches'" id="microsoft_patches"
        [ngClass]="{'bg-primary text-white': cView === 'microsoft_patches'}">
        Suppress Vulnerabilities Days
    </div>
    <!-- <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'other_suppress_vult'" id="other_suppress_vult"
        [ngClass]="{'bg-primary text-white': cView === 'other_suppress_vult'}">
        Other Suppress Vulnerabilities Days
    </div> -->
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'tag_management'" id="tag_management"
        [ngClass]="{'bg-primary text-white': cView === 'tag_management'}">
        Tag Management
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'timezone'" id="timezone" [ngClass]="{'bg-primary text-white': cView === 'timezone'}">
        Timezone Settings
    </div>
    <!-- <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'upload-asset-data'" id="retention_period"
        [ngClass]="{'bg-primary text-white': cView === 'upload-asset-data'}">
        Upload Asset Data
    </div> -->
    <!-- <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'suppress_vulnerabilities'" id="suppress_vulnerabilities"
        [ngClass]="{'bg-primary text-white': cView === 'suppress_vulnerabilities'}">
        Suppress Vulnerabilities Days
    </div> -->
    <!--
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'audit-scan'" [ngClass]="{'bg-primary text-white': cView === 'audit-scan'}">AD Audit Scan
    </div> -->
</div>
<div class="my-4 border-t border-blue-400"></div>
<div class="mt-4" *ngIf="cView === 'port'">
    <div class="my-2">
        <fuse-alert [type]="'info'" [appearance]="'border'">
            <span fuseAlertTitle>Ports Policy</span>
            <div class="flex flex-col">
                <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                    <b>Insecure Ports</b> = Asset scan will consider provided ports as insure and display as 'No' under the Is Secure column.
                </span><br>
                <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                    <b>Denied Ports</b> = Network ports explicitly blocked or restricted according to an organization's security policy or configuration. The agent will flag this in the compliance report card if it violates the policy.
                </span><br>
                <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                    <b>Excluded Ports</b>= Ports explicitly specifies certain ports that are exempt from agent scanning.
                </span><br>
                <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                    <b>Allowed Ports</b> = Network scan will consider provided ports as allowed for scanning.
                </span><br>
                <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                    <b>Secure Ports</b> = Asset scan will consider provided ports as secure and display as 'Yes' under the Is Secure column.
                </span>
            </div>
        </fuse-alert>
    </div>
    <app-dynamic-form [listOfFormElements]="ports" [spanLayout]="2" [Valuesoutput]="portsParams"
        (saveCallBack)="savePortPolicy(portsParams)" [isSaveBtn]="false" [isCloseBtn]="false">
    </app-dynamic-form>
    <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
        <!-- Save -->
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Save'" (click)="savePortPolicy(portsParams)">
            Save
        </button>
    </div>
</div>
<div class="w-full mt-4" *ngIf="cView === 'timezone'">
    <div class="p-4 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
            <mat-label>Timezone</mat-label>
            <mat-select [(ngModel)]="_timezone.value.value" name="Timezone" id="Timezone">
                <mat-option>
                    <ngx-mat-select-search [formControl]="timezoneSearchControl" [placeholderLabel]="'Search Zone'"
                        [noEntriesFoundLabel]="'No matching zone found'"
                        [searching]="searching"></ngx-mat-select-search>
                </mat-option>
                <mat-option [matTooltip]="zone"
                    *ngFor="let zone of cs.timeZoneList | search:timezoneSearchControl.value " [value]="zone">
                    {{zone}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveTimezone()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'deprecation'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Set Asset and Agents Deprecation days for the assets and agents which are offline and not
                    scanned for x number of days.</div>
                <div>If deprecated days are not provided, the system will automatically default to 90 days.</div>
            </div>
        </fuse-alert>
    </div>
    <app-dynamic-form [listOfFormElements]="deprecation_days" [spanLayout]="2" [Valuesoutput]="deprecationDaysParams"
        (saveCallBack)="saveSettings($deprecationDaysParams)" [isSaveBtn]="false" [isCloseBtn]="false">
    </app-dynamic-form>
    <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
        <!-- Save -->
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Save'" (click)="saveSettings(deprecationDaysParams)">
            Save
        </button>
    </div>
</div>
<div class="w-full mt-8" *ngIf="cView === 'patching-status'">
    <div class="my-2">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Patching Status" name="cmpPatchingStatus"
            [(ngModel)]="_enable_patching.value.patching_status">
            Enable Patch Management
        </mat-slide-toggle>
    </div>
    <div class=" mt-2 h-[40vh]" *ngIf="_enable_patching.value.patching_status" fuseScrollbar>
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div [innerHTML]="patchingContent| safeHTML"></div>
        </fuse-alert>
    </div>
    <mat-checkbox name="cmpAgreePatchCond" *ngIf="_enable_patching.value.patching_status" id="cmpAgreePatchCond"
        class="mt-3" [(ngModel)]="_enable_patching.value.is_eula_accepted" value="" color="primary">I ACCEPT
    </mat-checkbox>
    <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" type="button" appPreventMultiClick
            [matTooltip]="'Save'" (click)="enablePatchingStatus()"
            [disabled]="_enable_patching.value.patching_status && !_enable_patching.value.is_eula_accepted">
            Save
        </button>
    </div>
</div>
<div class="w-full mt-4" *ngIf="cView === 'default-tag'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div>Disable Default Tags removes the default tags applied for all the assets.</div>
        </fuse-alert>
    </div>
    <div class="mt-4">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Default Tags"
            [(ngModel)]="_default_tags.value.value" name="defaultTagsStatus">
            Enable Default Tags
        </mat-slide-toggle>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="enableDefaultTagStatus()">
                Save
            </button>
        </div>
    </div>
</div>
<div *ngIf="cView === 'edr-application'">
    <app-edr-app> </app-edr-app>
</div>
<div class="mt-4" *ngIf="cView === 'backupSoftware'">
    <app-backup-softwares></app-backup-softwares>
</div>
<div class="mt-4" *ngIf="cView === 'antiransomeware'">
    <app-antirandomware></app-antirandomware>
</div>
<div class="mt-4" *ngIf="cView === 'domain-check'">
    <app-domain-check></app-domain-check>
</div>
<div *ngIf="cView === 'report-settings'">
    <app-report-settings [global]="false"> </app-report-settings>
</div>
<div class="mt-4" *ngIf="cView === 'tag_management'">
    <app-tag-management></app-tag-management>
</div>
<div class="w-full mt-4" *ngIf="cView === 'audit-scan'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable AD Audit Scan"
            [(ngModel)]="_ad_audit_scan.value.value" name="adAuditScan">
            AD Audit Scan
        </mat-slide-toggle>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="enableAdAuditScan()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="w-full mt-4" *ngIf="cView === 'remote-agent-install'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="mt-2 mb-6">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div>By Enabling Remote Agent Install you can install Lightweight Agents on the Probe Discovered Assets.
                </div>
                <div class="my-1 font-semibold">Please Note: It is supported for,</div>
                <div class="flex flex-row items-center"><span
                        class="flex w-2 h-2 bg-gray-600 rounded-full me-2"></span>Windows Assets where SMB is Enabled.
                </div>
                <div class="flex flex-row items-center"><span
                        class="flex w-2 h-2 bg-gray-600 rounded-full me-2"></span>MAC Asset users with Enabled SSH port
                    (Based on the port mapped in the Asset credentials).</div>
                <div class="flex flex-row items-center"><span
                        class="flex w-2 h-2 bg-gray-600 rounded-full me-2"></span>Linux Asset users with Enabled SSH
                    port (Based on the port mapped in the Asset credentials).</div>
            </fuse-alert>
        </div>
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Remote Agent Install"
            [(ngModel)]="_remote_agent_install.value.value" name="RemoteAgentInstall">
            Enable Remote Agent Install
        </mat-slide-toggle>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="enableRemoteAgentInstall()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'exc-secreport-card'">
    <div class="px-4 py-6 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col ">
            <span class="mb-2 font-semibold">Select Component(s) to Exclude from Security Report Card</span>
            <div class="flex flex-col">
                <mat-checkbox class="mb-2" value="" (change)="selectAllToggle($event, 'excludeReportListAll')"
                    [checked]="isAllSelected('excludeReportListAll')"
                    [indeterminate]="hasSelected('excludeReportListAll') && !isAllSelected('excludeReportListAll')"
                    [color]="'primary'">
                    Select All
                </mat-checkbox>
                <div class="flex flex-col ml-4" *ngFor="let type of excludeReportListAll; let i = index">
                    <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected" name="selected{{i}}">
                        {{type.name}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="updateExcludeReport()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'compliance-scan'">
    <div class="px-4 py-6 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col ">
            <span class="mb-2 font-semibold">Choose Compliance Types</span>
            <div class="flex flex-col gap-2 rounded-lg ring-1">
                <mat-checkbox value="" (change)="selectAllToggle($event, 'complianceListAll')"
                    [checked]="isAllSelected('complianceListAll')"
                    [indeterminate]="hasSelected('complianceListAll') && !isAllSelected('complianceListAll')"
                    [color]="'primary'">
                    Select All
                </mat-checkbox>
                <div class="grid grid-cols-1 gap-2 p-4 border-t sm:grid-cols-4">
                    <div *ngFor="let type of complianceListAll; let i = index">
                        <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected" name="selected{{i}}">
                            {{type.name|uppercase |camelToHuman:true}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="updateComplianceType()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="w-full mt-4" *ngIf="cView === 'lwa-interval'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <mat-label class="block mb-1">Scan Time Interval</mat-label>
        <br>
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <ul class="space-y-1 list-disc list-inside marker:text-blue-600">
                <li>Self Scan is performed by the agent according to the selected time frequency.</li>
                <li>By adding the From Time and To Time one can exclude the Self Scan for the agent within the provided
                    time range.</li>
                <li>The Scan Time Interval will apply only for Lightweight Agent and Probe Self Agent.</li>
            </ul>
        </fuse-alert>

        <div class="flex flex-col w-full h-full mt-4">
            <div class="grid grid-cols-4 gap-4">

                <mat-form-field floatLabel="always" [subscriptSizing]="'dynamic'" class="mb-4 sForm">
                    <mat-label>Time Frequency</mat-label>
                    <mat-select required [multiple]="false" [(ngModel)]="_lwa_interval.value.minutes"
                        placeholder="Choose Interval">
                        <mat-option *ngFor="let interval of scanInterval" [value]="interval.value">{{ interval.name
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field floatLabel="always" [subscriptSizing]="'dynamic'" class="mb-4 sForm">
                    <mat-label>From Time</mat-label>
                    <input matInput required [(ngModel)]="_lwa_interval.value.from_time" type="time" name="form_time">
                </mat-form-field>

                <mat-form-field floatLabel="always" [subscriptSizing]="'dynamic'" class="mb-4 sForm">
                    <mat-label>To Time</mat-label>
                    <input matInput required [(ngModel)]="_lwa_interval.value.to_time" type="time" name="to_time">
                </mat-form-field>

            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="saveLWAInterval('reset')">
                    Reset
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" type="button"
                    appPreventMultiClick [matTooltip]="'Save'" (click)="saveLWAInterval()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<div class="w-full mt-4" *ngIf="cView === 'bruteforce'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <mat-slide-toggle name="bruteforce" class="my-4" [color]="'primary'"
                [(ngModel)]="_brute_force.value.brute_force" (change)="_brute_force.value.brute_force_ports = []">
                Enable Brute Force Setting
            </mat-slide-toggle>
            <div class="my-4" *ngIf="_brute_force.value.brute_force">
                <fuse-alert [appearance]="'border'" [type]="'info'">
                    <div>Default Brute Force Ports: 22, 20022 and 22022</div>
                </fuse-alert>
            </div>
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" *ngIf="_brute_force.value.brute_force">
                <mat-label>Brute Force Ports</mat-label>
                <mat-chip-grid #chipPort aria-label="Enter Keywords">
                    <mat-chip-row *ngFor="let key of _brute_force.value.brute_force_ports" (removed)="removePort(key)"
                        [editable]="true" (edited)="editPort(key, $event)"
                        [aria-description]="'press enter to edit ' + key">
                        {{key}}
                        <button matChipRemove [attr.aria-label]="'remove ' + key">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input [placeholder]="'Enter ports'" [matChipInputFor]="chipPort"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addPort($event)" />
                </mat-chip-grid>
                <mat-hint>Ex. 22, 443-450</mat-hint>
            </mat-form-field>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="enableBruteForce()">
                    Save
                </button>
            </div>
        </div>

    </div>
</div>
<div class="mt-4" *ngIf="cView === 'firewall'">
    <app-firewall></app-firewall>
</div>
<div class="w-full mt-4" *ngIf="cView === 'performanceM'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <div class="grid grid-cols-2 gap-4">
                <mat-form-field floatLabel="always" class="mb-4 sForm">
                    <mat-label>Set NMAP Performance Count</mat-label>
                    <input type="number" matInput name="count" [(ngModel)]="_performance_count.value.nmap" />
                </mat-form-field>
                <mat-form-field floatLabel="always" class="mb-4 sForm">
                    <mat-label>Set SMB Performance Count</mat-label>
                    <input type="number" matInput name="count" [(ngModel)]="_performance_count.value.smb" />
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="savePerformanceCount()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'retention_period'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Retain deprecated assets, which are under deprecation for x number of days, here</div>
            </div>
        </fuse-alert>
    </div>
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <div class="grid grid-cols-2 gap-4">
                <mat-form-field floatLabel="always" class="mb-4 sForm">
                    <mat-label>Deprecated Asset Retention Period</mat-label>
                    <input type="number" matInput name="count" [(ngModel)]="_asset_detention_days.value" />
                    <mat-hint>Ex. Min 3 days to Max 365 days</mat-hint>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveretentionperiodcount()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<div class="mt-4" *ngIf="cView === 'microsoft_patches'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <span fuseAlertTitle>Microsoft Suppress Vulnerabilities Days</span>
                <div *ngIf="!_microsoft_global_suppress_days || !_microsoft_global_suppress_days.value">The
                    Vulnerabilities for the respective assets are suppressed for the number of days entered here,
                    starting from the day after the initial release.</div>
                <div *ngIf="_microsoft_global_suppress_days && _microsoft_global_suppress_days.value">The Global
                    Suppress Vulnerability Days are now set to <span
                        class="bg-primary text-white py-0.5 px-2 rounded-full">{{_microsoft_global_suppress_days.value}}
                        days.</span>
                    Please update the Company Level Suppress Days if you need to override this setting.</div>
            </fuse-alert>
            <div class="grid grid-cols-2 gap-4 mt-2">
                <mat-form-field floatLabel="always" class="mb-4 sForm">
                    <mat-label>Microsoft Suppress Vulnerabilities Days</mat-label>
                    <input type="number" matInput name="count" [(ngModel)]="_microsoft_suppress_days.value">
                    <mat-hint>{{_microsoft_suppress_days.value}} day(s) to suppress vulnerabilities after initial
                        release.</mat-hint>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="resetSuppressVulnerabilities()"
                    *ngIf="_microsoft_suppress_days && _microsoft_suppress_days.id">
                    Reset
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveMicrosoftVulnerabilitiesdays()"
                    [disabled]="!_microsoft_suppress_days.value">
                    Save
                </button>
            </div>
        </div>
    </div>
    <mat-divider class="my-4"></mat-divider>
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <span fuseAlertTitle>Application Suppress Vulnerabilities Days</span>
                <div *ngIf="!_other_global_suppress_days || !_other_global_suppress_days.value">
                    The Application vulnerabilities for the respective assets are suppressed for the number of days
                    entered here, starting from the day after the initial release.
                </div>
                <div *ngIf="_other_global_suppress_days && _other_global_suppress_days.value">The Global Other
                    Vulnerability Days are now set to <span
                        class="bg-primary text-white py-0.5 px-2 rounded-full">{{_other_global_suppress_days.value}}
                        days.</span>
                    Please update the Company Level Suppress Days if you need to override this setting.</div>
            </fuse-alert>
            <div class="grid grid-cols-2 gap-4 my-2">
                <mat-form-field floatLabel="always" class="mb-4 sForm">
                    <mat-label>Application Suppress Vulnerabilities Days</mat-label>
                    <input type="number" matInput name="count" [(ngModel)]="_other_suppress_days.value">
                    <mat-hint>{{_other_suppress_days.value}} day(s) to suppress vulnerabilities after initial
                        release.</mat-hint>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="resetOtherVulnerabilities()"
                    *ngIf="_other_suppress_days && _other_suppress_days.id">
                    Reset
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveOtherVulnerabilitiesdays()"
                    [disabled]="!_other_suppress_days.value">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="mt-4" *ngIf="cView === 'suppress_vulnerabilities'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>The Application vulnerabilities for the respective assets are suppressed for the number of days entered here, starting from the day after the initial release.</div>
            </div>
        </fuse-alert>
    </div>
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <div class="grid grid-cols-2 gap-4">
                <mat-form-field floatLabel="always" class="mb-4 sForm">
                    <input type="number" matInput name="count" [(ngModel)]="_suppress_vulnerabilities_days.value.value" />
                    <mat-hint>{{_suppress_vulnerabilities_days.value.value}} day(s) to suppress vulnerabilities after initial release.</mat-hint>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick [matTooltip]="'Save'"
                    (click)="saveSuppressVulnerabilitiesdays()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="mt-4" *ngIf="cView === 'upload-asset-data'">
    <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col w-full h-full">
            <div class="flex items-center justify-center w-full">
                <label for="dropzonefile"
                    class="flex flex-col items-center justify-center w-full h-50 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click
                                to upload</span> or drag and drop</p>
                        <p class="file-name mb-2 text-sm text-gray-500 dark:text-gray-400">{{fileName}}</p>
                    </div>
                    <input id="dropzonefile" #dropzonefile type="file" class="hidden"
                        (change)="uploadFn($event, 'uploadfile')" />
                </label>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick [matTooltip]="'Upload'"
                    [disabled]="!uploadfile" (click)="uploadAssetData()">
                    Upload
                </button>
            </div>
        </div>
    </div>
</div> -->