<div class="flex flex-col w-full">
    <div class="flex flex-row items-center w-full gap-2">
        <div class="mt-3 text-xl font-bold truncate mb-4">Report Cover Page</div>
        <ng-container>

        </ng-container>
    </div>
    <div class="px-6 mt-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Please select only DOCX file.</div>
                <div>We can only customise Report Name <span ngNonBindable>{{</span>reportName<span
                        ngNonBindable>}}</span> and Company Name <span ngNonBindable>{{</span>companyName<span
                        ngNonBindable>}}</span> in the Cover Page.</div>
            </div>
        </fuse-alert>
    </div>
    <div class="px-6 grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-4">
        <div>
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>Choose Company</mat-label>
                <mat-select id="company" name="company" required [multiple]="true"
                    (selectionChange)="selectedCompanies = ($event.value.includes('*')) ? ['*'] : $event.value;"
                    [(ngModel)]="selectedCompanies">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="searchCompanyControl"
                            [placeholderLabel]="'Search Company'" [noEntriesFoundLabel]="'No matching company found'"
                            [searching]="searching"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [matTooltip]="'All Companies'" value="*">All Companies</mat-option>
                    <mat-option [matTooltip]="com.name"
                        *ngFor="let com of companies | search: searchCompanyControl.value" [value]="com.id">
                        {{com.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="mt-4 flex flex-row">
            <input type="file" class="my-2" accept=".docx" name="file" #fileInputValue
                (change)="onFileSelected($event)" />
            <button [matTooltip]="'Delete'" (click)="deletecoverpage()" [disabled]="!selectedCompanies.length">
                <mat-icon id="delCovPage" class="mat-24">delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="px-6 flex flex-row items-start gap-6 mt-4" *ngIf="cvrPageCount > 0">
        <div
            class="relative w-full w-full h-[50vh] bg-card pb-2 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-row text-lg font-bold leading-6 tracking-tight truncate">
                        Applied Companies
                        <span class="ml-2 px-2 bg-teal-400 text-black rounded text-sm">
                            {{cvrPageCount}} </span>
                    </div>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1 overflow-auto">
                    <mat-list-item class="border-b">
                        <div class="grid grid-cols-3">
                            <span class="text-md font-bold">Company</span>
                            <span class="text-md font-bold text-center">Cover Page</span>
                            <span class="text-md font-bold text-center">Action</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngFor="let item of coverPageDetails | keyvalue">
                        <mat-list-item class="border-b pointer">
                            <div class="grid grid-cols-3">
                                <span>
                                    {{ (item.key === 'global') ? 'All Companies' : (item.key | companyIdToName) }}
                                </span>
                                <button class="ml-1 mini-form-field" type="button" (click)="downloadCoverPage(item)">
                                    <mat-icon color="primary">download</mat-icon>
                                </button>
                                <button [matTooltip]="'Delete'" (click)="deletecoverpage(item.key)">
                                    <mat-icon id="delCovPage2" class="mat-24">delete</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t bg-gray-50 dark:bg-transparent">
        <ng-container>
            <button class="ml-auto" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveCoverPage()">
                Save
            </button>
        </ng-container>
    </div>
</div>