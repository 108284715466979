import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild, AfterViewInit, OnInit, OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { CommonService } from 'app/_services/common.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'paginator-widget',
  standalone: true,
  imports: [CommonModule, MatPaginatorModule, FormsModule, MaterialModule, ReactiveFormsModule, MatAutocompleteModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './paginator-widget.component.html',
  styleUrls: ['./paginator-widget.component.scss']
})
export class PaginatorWidgetComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator
  @Input() pageSize: any = 5;
  @Input() pageTotal: any;
  @Input() pageOptions: any = [5, 10, 20, 30, 50, 100, 200];
  @Output() pageChanged = new EventEmitter();
  refs: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  inputPageNumber: number = 1;
  pageSizeUpdate = new Subject<any>();
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _cS: CommonService,) {
    this.refs = this._cS.reloadServerSideTable.subscribe((company: any) => {
      console.log(company);
      this.paginator.pageIndex = 0;
      this._changeDetectorRef.detectChanges();
    });
    this._cS.selectedSiteChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.paginator.pageIndex = 0;
        this._changeDetectorRef.detectChanges();
      })
    this.pageSizeUpdate
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((value) => {
        if (!value) { value = 5 };
        if (typeof value !== 'number') { return; }
        if (value > 100) { value = 100 };
        this.paginator._changePageSize(value);
      });
  }

  pChanged(page: any): void {
    if((page.pageSize * page.pageIndex) > page.length){
      this.onPageNumberInput(1);
    } else {
      this.pageChanged.emit(page);
      this.inputPageNumber = page.pageIndex + 1;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
  * On init
  */
  ngOnInit(): void {
    // (this.paginator) ? this.paginator.pageIndex = 0 : null;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  get maxPageNumber(): number {
    return Math.ceil(this.pageTotal / this.pageSize);
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.markForCheck();
  }
  ngOnDestroy(): void {
    this.refs.unsubscribe();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  onPageNumberInput(value:any): void {
    let pageNumber = Number(value);
    pageNumber === 0 && value !== '' ? pageNumber = 1 : pageNumber;
    const totalPages = this.maxPageNumber;

    if (pageNumber > totalPages) {
      pageNumber = totalPages;
      value = totalPages.toString(); // Reset input to the max page number
    }

    if (pageNumber > 0 && pageNumber <= totalPages) {
      this.paginator.pageIndex = pageNumber - 1;
      this.paginator._changePageSize(this.paginator.pageSize); // Trigger page event
    } else {
      // Reset inputPageNumber to a valid page number if out of bounds
      this.inputPageNumber = this.paginator.pageIndex + 1;
    }
  }

  validatePositiveNumber(event: any): void {
    // avoiding Negative Values
    if (event.key === '-') { event.preventDefault(); return }
    if ((event.target.value === '' || event.target.value === '0' || event.target.value === '00' || event.target.value === '000') && event.key === '0') { event.preventDefault(); return }
  }

}
