import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild ,Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { UserService } from 'app/core/user/user.service';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseAlertComponent } from '@fuse/components/alert';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { DirectivesModule } from "../../../../-directives/-directives.module";
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { WhiteLabelComponent } from './white-label/white-label.component';
import { EdrAppComponent } from 'app/modules/settings/edr-app/edr-app.component';
import { BackupSoftwaresComponent } from 'app/modules/settings/backup-softwares/backup-softwares.component';
import { cloneDeep } from 'lodash';
import { AgentPerformanceManagementComponent } from 'app/modules/settings/agent-performance-management/agent-performance-management.component';
import { DisableRoamingAppComponent } from 'app/modules/settings/disable-roaming-app/disable-roaming-app.component';
import { ManageAgentVersionComponent } from 'app/modules/settings/manage-agent-version/manage-agent-version.component';
import { Subject, takeUntil } from 'rxjs';
import { ReportSettingsComponent } from "../../../shared/report-settings/report-settings.component";
import { CustomDomainComponent } from 'app/modules/shared/custom-domain/custom-domain.component';
import { ReportCoverPageComponent } from 'app/modules/settings/report-cover-page/report-cover-page.component';
import { AntirandomwareComponent } from 'app/modules/settings/antirandomware/antirandomware.component';
import { FirewallComponent } from 'app/modules/settings/firewall/firewall.component';
import { QuillModule } from 'ngx-quill';
import { TagManagementComponent } from 'app/modules/settings/tag-management/tag-management.component';
import { ICMPPingCheckComponent } from 'app/modules/settings/icmp-ping-check/icmp-ping-check.component';

@Component({
  selector: 'app-global-settings',
  standalone: true,
  imports: [CommonModule, WhiteLabelComponent, MaterialModule, DynamicFormComponent, FuseAlertComponent, FormsModule, TableComponent, AppFilterPipeModule, ModalComponent, NgxMatSelectSearchModule, ReactiveFormsModule, ReactiveFormsModule, MatFormFieldModule, FuseScrollbarDirective,
    DirectivesModule, EdrAppComponent, BackupSoftwaresComponent, ReportCoverPageComponent, AgentPerformanceManagementComponent, DisableRoamingAppComponent, ManageAgentVersionComponent, ReportSettingsComponent, CustomDomainComponent, AntirandomwareComponent, FirewallComponent, ICMPPingCheckComponent,
    QuillModule, TagManagementComponent],
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  comForm: any = {
    company_type: '',
    companies: [],
  };
  cView: any = 'asset_security_grade_customization';
  includeControl: UntypedFormControl = new UntypedFormControl();
  excludeControl: UntypedFormControl = new UntypedFormControl();
  timezoneSearchControl: UntypedFormControl = new UntypedFormControl();
  public searching = false;
  sourceCompany: any = [];
  currentUser: any = {};
  searchTerm: string = '';
  filteredResultSets: any[] = [];
  originalRemData: any = [];
  assetinfoTableOptions: any = {};
  selectedSuppressType: string | null = null;
  dateFormatList: any[] = [
    { name: 'MM/DD/YYYY', format: 'shortDate' },
    { name: 'DD/MM/YYYY', format: 'mediumDate' },
    { name: 'YYYY/MM/DD', format: 'yyyy-MM-dd' }
  ];
  _tags: any = [
    {
      "title": "Server",
      "value": "Server"
    },
    {
      "title": "Workstation",
      "value": "Workstation"
    },
    {
      "title": "Windows 11 Incompatible",
      "value": "Windows 11 Incompatible"
    }
  ];
  @Input() Wizzard: boolean = false;
  public expandedTag: any = null;
  selectedTags: any = [];
  resultSets: any[] = [];
  selectAll = false;
  selectedCount: number = 0;
  quillModules: any = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ["link"],
      ["clean"],
    ]
  };
  page_link: any = [
    { name: 'Agents View', link: '/agents' },
    { name: 'Assets View', link: '/asset/assets' },
    { name: 'Compliance View', link: '/compliance/compliance-standards' },
    { name: 'Metrics View', link: '/metrics' },
    { name: 'Overview View', link: '/overview' },
    { name: 'PII Scan View', link: '/pii' },
    { name: 'Problems View', link: '/asset/problems' },
    { name: 'Reports View', link: '/report-builder' },
    { name: 'Settings View', link: '/settings/view/companies' },
    { name: 'Solutions View', link: '/asset/remediation-plan' },
    { name: 'Vulnerabilities View', link: '/vulnerabilities' },
  ]
  roleList: any = [];
  complianceListAll: any = [];
  private targetKeyId: number | null = null;
  expandedIndex: number | null = null;
  constructor(
    public cs: CommonService,
    public _bs: BaseRequestService,
    private _changeDetectorRef: ChangeDetectorRef,
    public uS: UserService,
    public modalService: ModalService,
    private loaderService: LoaderService,
    private _formBuilder: UntypedFormBuilder,
    public toast: MyToastrService,
    public confirmDialog: FuseConfirmationService
  ) {
    
   }


  ports: any = [
    { label: "Insecure Ports", key: '_insecure_ports', required: false, example: 'Ex. 80, 8080, 9090', 'type': 'chiptext' },
    { label: "Denied Ports", key: '_denied_ports', required: false, example: 'Ex. 22, 443-450', 'type': 'chiptext' },
    { label: "Excluded Ports", key: '_excluded_ports', required: false, example: 'Ex. 80, 8080, 9090', 'type': 'chiptext' },
    { label: "Allowed Ports", key: '_allowed_ports', required: false, example: 'Ex. 22, 443-450', 'type': 'chiptext' },
    { label: "Secure Ports", key: '_secure_ports', required: false, example: 'Ex. 22, 443-450', 'type': 'chiptext' }
  ];

  portsParams: any = {
    _insecure_ports: [],
    _denied_ports: [],
    _excluded_ports: [],
    _allowed_ports: [],
    _secure_ports: [],
  };
  deprecation_days: any = [
    { label: "Asset Deprecation Days", key: '_asset_deprecation_days', required: false, example: 'Ex. Min 3 days to Max 365 days', 'type': 'number' },
    { label: "Agent Deprecation Days", key: '_agent_deprecation_days', required: false, example: 'Ex. Min 3 days to Max 365 days', 'type': 'number' },
    // { label: "Suppress Vulnerabilities Days for Microsoft Patches", key: '_microsoft_suppress_days', required: false, example: 'Ex. Min 3 days to Max 365 days', 'type': 'number' }
  ];
  deprecationDaysParams: any = {
    _asset_deprecation_days: 90,
    _agent_deprecation_days: 90,
    // _microsoft_suppress_days: 90
  };
  _global_table: any = { 'category': 'global', 'sub_category': 'settings', 'name': 'global_table', 'value': { font: '', page_size: 5 } };
  _lwa_interval: any = { 'category': 'global', 'sub_category': 'agents', 'name': 'lwa_interval', 'value': { minutes: 15, from_time: "", to_time: "" } };
  _asset_detention_days: any = { 'category': 'company', 'sub_category': 'assets', 'name': 'asset_detention_days', 'value': null };
  _insecure_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'insecure_ports', 'value': [] }
  _denied_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'denied_ports', 'value': [] };
  _excluded_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'excluded_ports', 'value': [] }
  _allowed_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'allowed_ports', 'value': [] };
  _secure_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'secure_ports', 'value': [] };
  _timezone: any = { 'category': 'global', 'sub_category': 'settings', 'name': 'timezone', 'value': { value: '' } };
  _date_format: any = { 'category': 'global', 'sub_category': 'settings', 'name': 'date_format', 'value': { value: '' } };
  _session_timeout: any = { 'category': 'global', 'sub_category': 'settings', 'name': 'session_timeout', 'value': { idle: 60, ping: 60, timeout: 60 } };
  _enable_patching: any = { 'category': 'patching', 'sub_category': 'permissions', 'name': 'enable_patching', 'value': { patching_status: false, is_eula_accepted: false } };
  _adaudit_status: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'adaudit_status', 'value': { value: false } };
  _self_approval: any = { 'category': 'global', 'sub_category': 'settings', 'name': 'self_approval', 'value': { value: false } };
  _default_tags: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'default_tags', 'value': { value: false } };
  _default_role: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'default_role', 'value': { value: '' } };
  _asset_deprecation_days: any = { 'category': 'assets', 'sub_category': 'settings', 'name': 'asset_deprecation_days', 'value': null };
  _agent_deprecation_days: any = { 'category': 'agents', 'sub_category': 'settings', 'name': 'agent_deprecation_days', 'value': null };
  _microsoft_suppress_days: any = { 'category': 'assets', 'sub_category': 'settings', 'name': 'microsoft_suppress_days', value: null };
  _other_suppress_days: any = { 'category': 'assets', 'sub_category': 'settings', 'name': 'other_suppress_days', value: null };
  _suppress_email_template: any = { 'category': 'template', 'sub_category': 'suppression', 'name': 'suppress_email_template', 'value': { value: '' } };
  _default_redirect_url: any = { 'category': 'global', 'sub_category': 'default_redirect_url', 'name': 'default_redirect_url', 'value': { value: '/overview' } };
  _asset_security_grade: any = {
    'category': 'assets',
    'sub_category': 'settings',
    'name': 'asset_security_grade',
    'value': {
      A: { from: 0, to: 40 },
      B: { from: 40, to: 45 },
      C: { from: 45, to: 60 },
      D: { from: 60, to: 75 },
      E: { from: 75, to: 90 },
      F: { from: 90, to: 100 },
    }
  };
  Objectkeys = Object.keys;
  // _suppress_vulnerabilities_days: any = { 'category': 'global', 'sub_category': 'suppress_vulnerabilities_days', 'name': 'suppress_vulnerabilities_days', 'value': { value: 0 } };
  _compliance_scan: any = {
    'category': 'global',
    'sub_category': 'settings',
    'name': 'compliance_scan',
    'value': []
  };

  _security_report: any = {
    'category': 'company',
    'sub_category': 'settings',
    'name': 'security_report',
    'value': []
  };
  scanInterval: any = [
    { name: '15 Minutes', value: 15 },
    { name: '30 Minutes', value: 30 },
    { name: '60 Minutes', value: 60 },
    { name: '90 Minutes', value: 90 },
    { name: '2 Hours', value: 120 },
    { name: '4 Hours', value: 240 },
    { name: '6 Hours', value: 360 },
    { name: '8 Hours', value: 480 },
    { name: '24 Hours', value: 1440 },
  ]
  excludeReportListAll: any = [];
  @ViewChild('gradeForm') gradeForm: NgForm;
  
  
  
  patchingContent = `<div class="flex flex-col gap-2">
<div>IMPORTANT!</div>
<div>PLEASE READ THE TERMS AND CONDITIONS OF THIS LICENSE AGREEMENT CAREFULLY BEFORE CONTINUING WITH THIS PROGRAM INSTALL.
</div>
<div>In plain English: ConnectSecure  LLC Patch Management
Solution is available as a  software feature that is provided free of
cost on a "As-is" and "As available" basis for use in conjunction with a ConnectSecure
  LLC Subscription or made available for evaluation during a free trial period. You may install and use updates provided by ConnectSecure  LLC during these periods; however, when your subscription or trial period terminates you must remove and destroy the software. Please contact ConnectSecure  LLC to obtain or renew your subscription.</div>
<div>ConnectSecure  LLC "PATCHING FEATURE" UPDATE END-USER LICENSE AGREEMENT 2.0</div>
<div>This ConnectSecure  LLC Update End-User License Agreement ("EULA") constitutes a
legal agreement between you, the "End User" (either an individual or a single entity)
and ConnectSecure  LLC for the ConnectSecure  LLC software updates you download and install
 (the Update). By installing, copying, or otherwise using this Update you agree to be bound by these terms. If you do not agree to the terms of this EULA, do not install or use the Update. The Update is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. The Update is licensed, not sold.</div>
<div>Definitions</div>
<div>A "Product" is a software package made available by ConnectSecure  LLC and subject to the terms thereof
</div>
<div>A "Licensed Product" is a Product that is covered by an authorized commercial agreement between the End User and ConnectSecure, LLC , or between the End User and a third party (Partner/OEM/MSP/MSSP) that has an
authorized commercial agreement with ConnectSecure, LLC .</div>
<div>"Term" means the term as defined in a license agreement for a Licensed Product, or the duration of any trial period extended to the End User</div>
<div>An "Update" is a software component made available by ConnectSecure  LLC that augments the capability or addresses an issue with an installed software.</div>
<div>Grant of License</div>
<div>Subject to the terms and conditions contained herein and any applicable Subscription Agreement, including the usage limits described therein, ConnectSecure  LLC grants the End User a non-exclusive,
non-transferrable, renewable license to use the Update as follows</div>
<div>Installation and Use: ConnectSecure  LLC grants you the right to install and use copies of the Update in a Licensed Product during the Term. You shall not exceed the number of licenses, tiers, nodes, or other use
 restrictions or authorizations agreed to and paid for in the commercial agreement.</div>
<div>Backup Copies: You may make copies of the Update as may be necessary for backup and archival purposes.</div>
<div>Other Limitations</div>
<div>Maintenance of Copyright Notices: You shall not remove or alter any copyright or license notices that appear in or on the Update.</div>
<div>Modification: You shall not modify, alter, decompile, decrypt, disassemble, translate, or reverse engineer the Update.</div>
<div>Distribution: You shall not distribute copies of the Update to third parties.</div>
<div>Rental: You shall not rent, lease, or lend the Update, including renting, leasing, or lending a Product that includes the Update.</div>
<div>SaaS: You shall not make the Update or Product that includes the Update available as commercial Software-as-a-Service.</div>
<div>Compliance with Applicable Laws: You shall comply with all applicable laws regarding the use of the Update.</div>
<div>Termination</div>
<div>These terms, including the Software License, shall expire at the end of the Term, unless Licensee and ConnectSecure  LLC agrees to renew such License. Without prejudice to any other rights, ConnectSecure  LLC may terminate this EULA if you fail to comply with the terms and conditions of this EULA.</div>
<div> Upon expiration or termination of these terms, you shall uninstall and destroy all copies of the ConnectSecure  LLC Software in your possession. Termination or expiration of this EULA shall not affect End Users' rights to use any Product in accordance with the terms and conditions of the applicable EULA, or to receive services in accordance
 with a support agreement, or other agreement as may be applicable.</div>
<div>Copyright</div>
<div>All right, title and interest, including but not limited to intellectual property rights such as copyrights, in and to the Update and any copies thereof, are owned by ConnectSecure  LLC or its suppliers. All right, title and interest, including but not limited to intellectual property rights such as copyrights, in and to the content which may be accessed through use of the Update is the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties. This EULA grants you no rights to use such content. All rights not expressly granted are reserved by ConnectSecure, LLC ..</div>
<div>No Warranties</div>
<div>UNLESS EXPRESSLY PROVIDED HEREIN TO THE CONTRARY, AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND ANY AND ALL SERVICES AND SOFTWARE INCLUDING PATCHES AND SERVICE PACKS PROVIDED BY ConnectSecure  LLC HEREUNDER ARE PROVIDED "AS IS" WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, INCLUDING IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. NO WARRANTY, WRITTEN OR ORAL, IS EXPRESSED OR IMPLIED BY CONNECTSECURE  LLC OR MAY BE INFERRED FROM A COURSE OF DEALING OR USAGE OF TRADE. NOTHING STATED IN THIS AGREEMENT WILL IMPLY THAT THE OPERATION OF ANY SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ERRORS WILL BE CORRECTED..</div>
<div>Limitation of Liability</div>
<div>IN NO EVENT WILL CONNECTSECURE LLC BE LIABLE FOR ANY INCIDENTAL INDIRECT, SPECIAL, OR CONSEQUENTIAL COSTS OR DAMAGES INCLUDING, WITHOUT LIMITATION, DOWNTIME COSTS; LOST BUSINESS, REVENUES, OR PROFITS; FAILURE TO REALIZE EXPECTED SAVINGS; LOSS OF OR DAMAGE TO DATA; OR SOFTWARE RESTORATION, REGARDLESS OF WHETHER ANY OF THE FOREGOING ARE FORESEEABLE, AND REGARDLESS OF WHETHER ConnectSecure  LLC HAS BEEN NOTIFIED OF THE POSSIBILITY OF ANY OF THE FOREGOING. THESE LIMITATIONS WILL APPLY REGARDLESS OF THE BASIS OF LIABILITY; INCLUDING NEGLIGENCE; MISREPRESENTATION; BREACH; LIBEL; INFRINGEMENT OF PUBLICITY, PRIVACY, OR INTELLECTUAL PROPERTY RIGHTS; OR ANY OTHER CONTRACT OR TORT CLAIM.</div>
<div>Disclaimer of Warranty. YOU ACKNOWLEDGE THAT THE PATCHING FEATURE  is "Beta" Software AND ANY AND ALL PARTS THEREOF ARE PROVIDED “AS IS” and "AS AVAILABLE". ConnectSecure  LLC MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE LICENSED MATERIALS AND OR PARTS THEREOF WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE THAT YOU HAVE RELIED ON NO WARRANTIES AND THAT NO WARRANTIES ARE MADE BY CONNECTSECURE  LLC OR GRANTED BY LAW WHENEVER IT IS PERMITTED BY LAW.
</div>
<div>BY CLICKING “I ACCEPT”, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT AND ACCEPT THE PRODUCT’S FUNCTIONS. DO NOT CLICK THE “I ACCEPT”BUTTON IF YOU DO NOT ACCEPT THIS AGREEMENT AND THE PRODUCT’S FUNCTIONS.</div>
</div>`
  ngOnInit(): void {
    this.cView = this.Wizzard ? 'compliance-scan' : 'asset_security_grade_customization';
    this.resultSets = Object.values(this.resultSets);
    this.getTypes();
    this.getExcludeSecurityReport();
    this.cs.getTimeZone();
    this.getRoles();
    this.getFqdnServer();
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getFqdnServer(): void {
    this._bs.doRequest(`/r/company/fqdn_server_list`, 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          this.cs.fqdn_ipaddress = res.data;
        }
      });
  }

  getCompanySetting(): void {
    this.loaderService.display(true);
    const params: any = { condition: true, skip: 0, limit: 100 }
    params.condition = "company_id IS NULL and category NOT IN ('table_settings', 'settings', 'tenant')"
    this._bs.doRequest(`/r/company/settings`, 'get', null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        // if (result.status) {
        //   result.data.forEach((key: any) => {
        if (result.status) {
          const targetKeyName = "global_table";
          let selectedSuppressType = '';
          result.data.forEach((key: any) => {
            if (key.name === targetKeyName) {
              this.targetKeyId = key.id;
            }
            if (key.name === 'microsoft_suppress_days') {
              selectedSuppressType = 'microsoft';
            } else if (key.name === 'other_suppress_days') {
              selectedSuppressType = 'other';
            }
            // @ts-ignore
            if (this[`_${key.name}`]) {
              if (this.portsParams.hasOwnProperty(`_${key.name}`)) {
                this.portsParams[`_${key.name}`] = key.value;
              } else if (this.deprecationDaysParams.hasOwnProperty(`_${key.name}`)) {
                this.deprecationDaysParams[`_${key.name}`] = key.value
              }
              if (`_${key.name}` === '_session_timeout') {
                type NumericObject = { [key: string]: number };
                const originalObject: NumericObject = key.value;
                const modifiedObject: NumericObject = Object.fromEntries(
                  Object.entries(originalObject).map(([key, value]) => [key, value / 60])
                );
                this[`_${key.name}`] = { ...this[`_${key.name}`], ...{ id: key.id, value: modifiedObject } }
              }
              /*else if (`_${key.name}` === '_compliance_scan') {
                this.complianceListAll.map((x: any) => {
                  if (key.value.indexOf(x.value) > -1) {
                    x.selected = true;
                  } else {
                    x.selected = false;
                  }
                });
                this[`_${key.name}`] = { ...this[`_${key.name}`], ...{ id: key.id, value: key.value } }
              } */
              else {
                this[`_${key.name}`] = { ...this[`_${key.name}`], ...{ id: key.id, value: key.value } };
              }
            }
          });
          this.selectedSuppressType = selectedSuppressType;
        }
        else {
          const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data)
        }
      })
  }


  isValidDays(day: any): boolean {
    return (day >= 3 && day <= 365);
  }
  isValidDaysNotZero(day: any): boolean {
    if (day === 0) { return true };
    return (day >= 0 && day <= 365);
  }
  saveTimezone(): void {
    this.saveSettings({ _timezone: this._timezone.value });
  }
  saveCustomDateFormat(): void {
    this.saveSettings({ _date_format: { value: this._date_format.value.value } });
    localStorage.setItem('customisedDateFormat', this._date_format?.value?.value);
  }
  saveSessionSettings(): void {
    const value = cloneDeep(this._session_timeout.value);
    type NumericObject = { [key: string]: number };
    const originalObject: NumericObject = value;
    const modifiedObject: NumericObject = Object.fromEntries(
      Object.entries(originalObject).map(([key, value]) => [key, value * 60])
    );
    this.saveSettings({ _session_timeout: modifiedObject });
  }
  enablePatchingStatus(): void {
    const value = cloneDeep(this._enable_patching.value);
    this.saveSettings({ _enable_patching: { patching_status: value.patching_status, is_eula_accepted: value.is_eula_accepted } });
  }
  enableLoginAuditStatus(): void {
    this.saveSettings({ _adaudit_status: this._adaudit_status.value });
  }
  enableDefaultTagStatus(): void {
    this.saveSettings({ _default_tags: this._default_tags.value });
  }
  saveretentionperiodcount(): void {
    this.saveSettings({ _asset_detention_days: this._asset_detention_days.value });
  }

  saveOtherVulnerabilitiesdays(): void {
    this.saveSettings({ _other_suppress_days: this._other_suppress_days.value });
  }

  saveMicrosoftVulnerabilitiesdays(): void {
    this.saveSettings({ _microsoft_suppress_days: this._microsoft_suppress_days.value });
  }
  savedefaulturl(): void {
    this.saveSettings({ _default_redirect_url: this._default_redirect_url.value });
  }
  resetdefaulturl(): void {
    this.loaderService.display(true);
    this._bs.doRequest(`/d/company/settings/${this._default_redirect_url.id}`, 'delete',)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Reset successfully');
          this._default_redirect_url.value.value = '';
        } else {
          this.toast.sToast('error', result.message);
        }
      });
  }
  globaltable(): void {
    const value = cloneDeep(this._global_table.value);
    if (value.page_size < 5 || value.page_size > 100) {
      this.toast.sToast('info', 'Please enter valid items.');
    } else {
      this.saveSettings({ _global_table: { font: value.font, page_size: value.page_size } });
      setTimeout(() => {
        this._bs.getWhiteLabelSetting();
      }, 5000);
    }

  }
  enableSelfApproval() {
    this.saveSettings({ _self_approval: this._self_approval.value });
  }
  saveDefaultRole(): void {
    this.saveSettings({ _default_role: this._default_role.value });
  }
  saveSuppression(): void {
    this.saveSettings({ _suppress_email_template: this._suppress_email_template.value });
  }
  saveLWAInterval(reset?: any): void {
    delete this._lwa_interval.value.value
    if (reset) {
      this._lwa_interval.value.minutes = 15;
      this._lwa_interval.value.from_time = "";
      this._lwa_interval.value.to_time = "";
    }
    this.saveSettings({ _lwa_interval: this._lwa_interval.value });
    setTimeout(() => {
      this.resetAgent(reset);
    }, 2000);
  }
  resetAgent(reset?: any): void {
    const reqData: any = {
      company_id: parseInt(this.cs.currentScope.id),
      message: 'lwt_settings',
      lwt_settings: {
        minutes: parseInt(this._lwa_interval.value.minutes),
        from_time: (reset) ? "-:-" : this._lwa_interval.value.from_time,
        to_time: (reset) ? "-:-" : this._lwa_interval.value.to_time
      }
    };
    this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }
  selectAllToggle1(): void {
    this.selectAll = this.selectAll; // Ensure toggling works correctly
    this.resultSets.forEach((prod: any) => {
      prod.selected = this.selectAll;
    });
    this.updateSelectedCount(); // Update selected count when select all toggled
    this._changeDetectorRef.detectChanges();
  }

  updateSelectAllState(): void {
    this.selectAll = this.isAllSelected1();
    this.updateSelectedCount(); // Update selected count when selection changes
  }

  hasSelected1(): boolean {
    return this.resultSets.some((x: any) => x.selected);
  }

  isAllSelected1(): boolean {
    return this.resultSets.length > 0 && this.resultSets.every((x: any) => x.selected);
  }

  updateSelectedCount(): void {
    this.selectedCount = this.resultSets.filter((x: any) => x.selected).length;
  }

  resetOtherVulnerabilities() {
    this.loaderService.display(true);
    this._bs.doRequest(`/d/company/settings/${this._other_suppress_days.id}`, 'delete',)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Reset successfully');
          this._other_suppress_days.value = null;
          delete this._other_suppress_days.id;
        } else {
          this.toast.sToast('error', result.message);
        }
      });
  }

  resetSuppressVulnerabilities() {
    this.loaderService.display(true);
    this._bs.doRequest(`/d/company/settings/${this._microsoft_suppress_days.id}`, 'delete',)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Reset successfully');
          this._microsoft_suppress_days.value = null;
          delete this._microsoft_suppress_days.id;
        } else {
          this.toast.sToast('error', result.message);
        }
      });
  }


  savePortPolicy(reset?: any): void {
    let finalObj: any = {};
     Object.keys(reset).forEach((obj: any, index: any) => {
       if (obj === '_allowed_ports' || obj === '_denied_ports' || obj === '_excluded_ports' || obj === '_insecure_ports' || obj === '_secure_ports') {
         let err = '';
         reset[obj].forEach((port: any) => {
           if (!this.cs.isValidPort(port)) {
             err += `Port ${port} is not valid. `
           }
         });
         if (err && err.length) {
           this.toast.sToast('error', err);
           return;
         }
       }
       finalObj[obj] = reset[obj]; 
     })
     this.saveSettings(reset) 
     setTimeout(() => {
       this.resetAgentPortPolicy(finalObj);
     }, 2000);
   }
   resetAgentPortPolicy(value){
     const reqData: any = {
       company_id: parseInt(this.cs.currentScope.id),
       message: 'agent_port_policy_reset',
       port_policy:value
     };
     this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
     .pipe(takeUntil(this._unsubscribeAll))
     .subscribe((result: any) => { });
   }
 
  saveSettings(value: any): void {
    Object.keys(value).forEach((obj: any, index: any) => {
      if (this[obj]) {
        const validateDaya: any = ['_asset_deprecation_days', '_agent_deprecation_days', '_asset_detention_days', '_microsoft_suppress_days', '_other_suppress_days'];
        if (validateDaya.indexOf(obj) > -1) {
          if (!this.isValidDays(cloneDeep(value[obj])) && obj !== '_microsoft_suppress_days' && obj !== '_other_suppress_days') {
            this.toast.sToast('info', 'Please enter valid days!');
            return;
          }
          if (!this.isValidDaysNotZero(cloneDeep(value[obj])) && (obj === '_microsoft_suppress_days' || obj === '_other_suppress_days')) {
            this.toast.sToast('info', 'Please enter valid days!');
            return;
          }
        }
  
        // @ts-ignore
        const $event: any = cloneDeep(this[obj]);
        $event.value = value[obj];
        this.loaderService.display(true);
        const method = ($event.id) ? 'patch' : 'post';
        const msg = ($event.id) ? 'Updated' : 'Saved';
        $event.company_id = null;
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        this._bs.doRequest('/w/company/settings', method, request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              if (method === 'post') {
                this[obj].id = parseInt(result.id);
              }
              setTimeout(() => {
                this.loaderService.display(false);
                this.toast.sToast('success', `${msg} successfully!`);
                this._changeDetectorRef.markForCheck();
              }, 3000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
              setTimeout(() => {
                this.loaderService.display(false);
              }, 3000);
            }
          })
      }

    })
  }

  getRoles(): void {
    this.loaderService.display(true);
    this._bs.doRequest(`/r/user/get_roles`, 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.roleList = res.data;
          } else {
            this.roleList = [];
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }

  selectAllToggle(event: any, key: any): void {
    // @ts-ignore
    this[key].forEach((obj: any) => {
      obj.selected = event.checked;
    });
  }
  isAllSelected(key: any): boolean {
    // @ts-ignore
    const len = this[key].filter((x: any) => x.selected).length;
    // @ts-ignore
    if (this[key].length === len) {
      return true;
    }
    return false;
  }
  hasSelected(key: any): boolean {
    // @ts-ignore
    const len = this[key].filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  getTypes(): void {
    this.loaderService.display(true);
    this._bs.doRequest(`/r/compliance/types`, 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          this.complianceListAll = [];
          res.data.map((obj: any) => {
            this.complianceListAll.push({ name: obj.name, value: obj.table_name, selected: false });
          });
          // [...new Set(res.data.flatMap((obj: any) => Object.keys(obj)))].forEach((obj: any) => {
          //     this.complianceListAll.push({name: obj, selected: false});
          // });
        } else {
          this.toast.sToast('error', 'No compliance configured!')
        }
      });
  }

  updateComplianceType(): void {
    const selectedItem = this.complianceListAll.filter((x: any) => x.selected).map((s: any) => s.value);
    if ((!selectedItem || !selectedItem.length) && !this._compliance_scan.id) {
      this.toast.sToast('error', 'Please select at least one compliance');
      return;
    }
    const newlyAddedValues = cloneDeep(selectedItem.filter((value: any) => !this._compliance_scan.value.includes(value)));
    this._compliance_scan.value = selectedItem;
    this.saveSettings({ _compliance_scan: selectedItem });
    if (newlyAddedValues && newlyAddedValues.length) {
      const reqData: any = { message: 'compliance_scan', compliance_added: newlyAddedValues };
      this.resetCompliance(reqData)
    }
  }

  resetCompliance(reqData: any): void {
    this._bs.doRequest('/w/company/reset_agents', 'post', reqData).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => { });
  }
  complianceScan(): void {
    // cView = 'compliance-scan'"
    try {
      this.loaderService.display(true);
      this.complianceListAll.map((x: any) => {
        if (this._compliance_scan.value.indexOf(x.value) > -1) {
          x.selected = true;
        } else {
          x.selected = false;
        }
      });
    } catch (error) {
      this.loaderService.display(false);
    }
    setTimeout(() => {
      this.loaderService.display(false);
      this.cView = 'compliance-scan';
    }, 500);
  }

  excludeReport(): void {
    // cView = 'compliance-scan'"
    try {
      this.loaderService.display(true);
      this.excludeReportListAll.map((x: any) => {
        if (this._security_report.value.indexOf(x.value) > -1) {
          x.selected = true;
        } else {
          x.selected = false;
        }
      });
    } catch (error) {
      this.loaderService.display(false);
    }
    setTimeout(() => {
      this.loaderService.display(false);
      this.cView = 'exc-secreport-card';
    }, 500);
  }

  resetSetting(): void {
    this.loaderService.display(true);
    this._bs.doRequest(`/d/company/settings/${this.targetKeyId}`, 'delete')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Removed successfully!');
          this.getCompanySetting()
          this._global_table.value = {};
          setTimeout(() => {
            this._bs.getWhiteLabelSetting();
          }, 5000);
        }
      });
  }

  resetUserSecret() {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Proceeding with this action will erase the existing user secret and generate a new one. To maintain functionality, it's crucial that you update your agent installation script(RMM script) with the newly generated user secret immediately after confirmation. Are you sure you want to proceed, as this change cannot be undone?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this._bs.doRequest(`/r/user/reset_user_token`, 'get', null)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            (res: any) => {
              if (res.status === true) {
                this.toast.sToast('success', 'Successfully reset!');
              } else {
                this.toast.sToast('error', 'Error fetching user token');
              }
            }
          );
      }
    });

  }


  updateExcludeReport(): void {
    const selectedItem = this.excludeReportListAll.filter((x: any) => x.selected).map((s: any) => s.value);
    if ((!selectedItem || !selectedItem.length) && !this._security_report.id) {
      this.toast.sToast('error', 'Please select at least one component');
      return;
    }
    this.saveSettings({ _security_report: selectedItem });
  }

  getExcludeSecurityReport(): void {
    this.loaderService.display(true);
    this._bs.doRequest('/r/company/jsonconfigs/security_report_card_supress', 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.excludeReportListAll = result.data;
          this.getCompanySetting();
          this._changeDetectorRef.markForCheck();
        } else {
          this.getCompanySetting();
          const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data);
        }
      })
  }

  saveSecurityGrade(): void {
    this.saveSettings({ _asset_security_grade: this._asset_security_grade.value });
  }
  resetSecurityGrade(): void {
    const value: any = {
      A: { from: 0, to: 40 },
      B: { from: 40, to: 45 },
      C: { from: 45, to: 60 },
      D: { from: 60, to: 75 },
      E: { from: 75, to: 90 },
      F: { from: 90, to: 100 },
    };
    this.saveSettings({ _asset_security_grade: value });
  }
  onToScoreChange(index: number, toScore: number) {
    if (toScore === null || toScore === undefined || isNaN(toScore)) {
      // Handle empty or invalid toScore value by skipping the update
      return;
    }

    const keys = Object.keys(this._asset_security_grade.value);
    if (index < keys.length - 1) {
      const nextRow = keys[index + 1];
      this._asset_security_grade.value[nextRow].from = toScore + 1;
    }
  }
}
