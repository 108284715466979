<div class="p-2 bg-card border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
    <div class="mt-4 flex flex-col items-start gap-6">
        <div class="flex flex-row">
            Latest Agent Version :
            <div
                class="ml-4 bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                {{agentVersions[0].present_version}}</div>
        </div>
        <div class="border-t border-blue-400 my-4"></div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full h-[60vh]">
            <div class="flex flex-col">
                <div class="mb-4 text-lg flex flex-row font-medium tracking-tight leading-6 truncate">
                    <div class="mt-2">Set specific agent version:</div>
                    <mat-form-field class="w-1/4 ml-6">
                        <mat-select [multiple]="false" placeholder="Choose agent version">
                            <mat-option *ngFor="let ver of agentVersions[0].agent_versions;let i =index;" [id]="format"
                                [value]="ver">{{ver}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick [matTooltip]="'Save'"
                        (click)="updateAgentVersion()">
                        Update
                    </button>
                </div>
                <div class="border-t border-blue-400 my-4"></div>
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead class="text-medium font-medium border-b tracking-tight leading-6  bg-card">
                        <tr>
                            <th>
                                <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                    id="_selectDeselectAllCompanies" [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Company Name
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Agent Version
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody fuseScrollbar>
                        <tr *ngFor="let comp of allCompanies.data">
                            <td>
                                <mat-checkbox color="primary" (click)="$event.stopPropagation()" [id]="comp._id"
                                    (change)="$event ? rowToggle(comp) : null"
                                    [checked]="selection.isSelected(comp)"></mat-checkbox>
                            </td>
                            <td scope="row" class="py-1 pl-4">
                                {{comp.name}}
                            </td>
                            <td>
                                new
                            </td>
                            <td class="py-1 pl-4">
                                <mat-form-field>
                                    <mat-select [multiple]="false" placeholder="Choose agent version"
                                        (selectionChange)="updateAgentVersion(comp)">
                                        <mat-option *ngFor="let ver of agentVersions[0].agent_versions;let i =index;"
                                            [id]="format" [value]="ver">{{ver}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>