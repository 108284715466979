import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let customerid: any;
    const domain = localStorage.getItem('cid');
    if (domain) {
      customerid = (localStorage.getItem('cid') !== null && localStorage.getItem('cid'))
       ? atob(domain) : '';
      const clonedRequest = req.clone({ headers: req.headers.set('customerid', customerid) });
      return next.handle(clonedRequest);
    }
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(req);
  }
}

