import { ChangeDetectorRef, ElementRef, Component, OnDestroy, OnInit, SimpleChanges, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "app/modules/shared/table/table.component";
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from "@angular/forms";
import { FuseAlertComponent } from "@fuse/components/alert";
import { MaterialModule } from "app/material.module";
import { DynamicFormComponent } from "app/modules/shared/forms/dynamic-form/dynamic-form.component";
import { GridsComponent } from "app/modules/shared/grids/grids.component";
import { ModalComponent } from "app/modules/shared/modal.component";
import { CommonService } from "app/_services/common.service";
import { BaseRequestService } from "app/_services/base.service";
import { LoaderService } from "app/_services/loader.service";
import { ModalService } from "app/_services/modal.service";
import { UserService } from "app/core/user/user.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DirectivesModule } from "app/-directives/-directives.module";
import { CredentialsComponent } from "../credentials/credentials.component";
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { cloneDeep, isArray } from "lodash";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";


@Component({
    selector: "discovery-settings",
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MatChipsModule, DirectivesModule,CredentialsComponent, ReactiveFormsModule, TableComponent, GridsComponent, ModalComponent, MaterialModule, FormsModule, FuseAlertComponent, DynamicFormComponent, FuseScrollbarDirective],
    templateUrl: "./discovery-settings.component.html",
    styleUrls: ["./discovery-settings.component.scss"],
})
export class DiscoverySettingsComponent implements OnInit, OnDestroy {
    @ViewChild('uploadFileInput', { static: false }) private uploadFileInput: ElementRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    discoverySettings: any = {
        name: '',
        address_type: '',
        address: '',
    };
    ipRangeError = false;
    showExclude = false;
    openDS: Subscription;
    loadtable: boolean = false
    user: any;
    form!: FormGroup;
    cofigForm: any = {};
    @ViewChild('exNgForm') exNgForm: NgForm;
    addressTypes: any = [
        {
            value: 'STATICIP',
            label: 'Static IP'
        },
        {
            value: 'CIDR',
            label: 'CIDR'
        },
        {
            value: 'DOMAIN',
            label: 'Domain'
        },
        {
            value: 'IPRANGE',
            label: 'IP Range'
        }
    ];
    private subs: Subscription;
    discoveryTableOptions: any = {};
    currentdisc: any = {}
    credForm: FormGroup;
    selectedIndex = 0;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    addOnBlur = true;
    objectKeys = Object.keys;
    csvUpload: any = {}
    /**
     * Constructor
     */
    constructor(public cs: CommonService, private _bs: BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef, public confirmDialog: FuseConfirmationService,
        public toast: MyToastrService, private _formBuilder: FormBuilder,
        private uS: UserService, public modalService: ModalService, private loaderService: LoaderService) {
        this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
            this.discoverySettings.companyId = this.cs.currentScope.id;
            this._changeDetectorRef.markForCheck();
        });
        this.cs.newDiscoverySettingCall.subscribe((trigger: any) => {
            this.discoverySettings = {
                name: '',
                address_type: '',
                address: '',
                is_excluded: false
            };
            this._changeDetectorRef.markForCheck();
            this.modalService.open('newDiscoverySetting');
        });
        this.openDS = this.cs.openDiscoverySettings.subscribe((res: any) => {
            setTimeout(() => {
                this.discoverySettings = {
                    name: '',
                    address_type: '',
                    address: '',
                    is_excluded: false
                };
                this._changeDetectorRef.markForCheck();
                this.init();
                this.modalService.open('newDiscoverySetting');
            }, 3000);
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.openDS.unsubscribe();
        this.subs.unsubscribe();
    }

    init(): void {
        this.uS.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            this.user = user;
            this._changeDetectorRef.markForCheck();
        });
        this.initialTable();
        this.cofigForm = {
            name: '',
            address_type: '',
            address: '',
            is_excluded: false,
            mac_address_exclude: [],
            target_ip: '',
            manual_tags: []
        };
    }

    ngOnInit(): void {
        this.init();
    }

    initialTable(): void {
        this.discoveryTableOptions = {}; this._changeDetectorRef.detectChanges();
        this.discoveryTableOptions = {
            columns: [
                {
                    header: "Name",
                    columnDef: "name",
                    "cType": "string",
                    filter: "",
                    cell: "(element: any) => ${element.name}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Address",
                    columnDef: "address",
                    "cType": "string",
                    filter: "",
                    cell: "(element: any) => ${element.address}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Address Type",
                    columnDef: "address_type",
                    "cType": "string",
                    filter: "",
                    cell: "(element: any) => ${element.address_type}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },{
                    "header": "Manual Tags",
                    "columnDef": "manual_tags",
                    "cType": "agg",
                    "filter": "",
                    "width": "w-[150px]",
                    isTags: true,
                    "cell": "(element: any) => `${element.manual_tags}`",
                    "order": 0,
                    "visible": false,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                  },
                { "header": "Is Excluded", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "is_excluded", "cType": "agg", "filter": "", "cell": "(element: any) => `${element.is_excluded}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
                {
                    header: "Company ID",
                    columnDef: "company_id",
                    "cType": "number",
                    filter: "",
                    cell: "(element: any) => ${element.company_id}",
                    order: 0,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Created On",
                    columnDef: "created",
                    "cType": "date",
                    filter: "utcToLocale",
                    cell: "(element: any) => ${element.created}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Updated On",
                    columnDef: "updated",
                    "cType": "date",
                    filter: "utcToLocale",
                    cell: "(element: any) => ${element.updated}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                }
            ],
            sortOptions: { active: "updated", direction: "desc" },
            _pageData: [],
            tableOptions: {
                title: "Discovery Settings",
                isServerSide: false,
                selectText: "discovery",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: true,
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showFilter: true,
                showTagFilter: false,
                actionMenuItems: [
                    {
                        text: 'Edit',
                        id: 'edit',
                        icon: 'edit',
                        callback: 'editFunction',
                        hideLocal: false,
                        isGlobal: false,
                    },
                    {
                        text: 'Delete',
                        id: 'delete',
                        icon: 'delete',
                        callback: 'deleteFunction',
                        hideLocal: false,
                        isGlobal: false,
                    }],
                parentCalls: [
                    { id: 'uploadBulkIpCsv', icon: 'upload', tooltip: 'Upload Bulk IPs CSV File', iconBtn: true, color: 'text-blue-500' },
                ],
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                add: true,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: "/r/company/discovery_settings",
                    dUrl: '/d/company/discovery_settings',
                    condition: "discovery_settings_type='assetscan'",
                },
                id: 'discovery'
            },
            customText: [
                {
                    status: false,
                    DisplayText: 'No',
                    class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
                },
                {
                    status: true,
                    DisplayText: 'Yes',
                    class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
                },
            ],
            changeValue: new Subject<any>(),
        }
        this._changeDetectorRef.detectChanges();
    }

    isIPInRange(ipAddress: any, startRange: any, endRange: any) {
        const ipParts = ipAddress.split('.').filter((x: any) => x !== '');
        const startRangeParts = startRange.split('.');
        const endRangeParts = endRange.split('.');

        const ipInt = ipParts.map(Number);
        const startRangeInt = startRangeParts.map(Number);
        const endRangeInt = endRangeParts.map(Number);
        if (ipParts.length == 4) {
            for (let i = 0; i < 4; i++) {
                const Pattern = /^\d+$/
                if (!Pattern.test(ipInt[i]) || ipInt[i] < startRangeInt[i] || ipInt[i] > endRangeInt[i]) {
                    return false;
                }
            }
        } else {
            return false
        }

        return true;
    }

    checkIP(data: any) {
        if (data.address_type.toLowerCase() == 'domain') {
            const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const valid = domainPattern.test(data.address);
            if (!valid) {
                this.toast.sToast('error', 'Invalid value for Domain');
                return false;
            }
        } else if (data.address_type.toLowerCase() == 'staticip') {
            const valid = this.isIPInRange(data.address, "0.0.0.0", "255.255.255.255");
            if (!valid) {
                this.toast.sToast('error', 'Invalid value for IP');
                return false;
            }
        } else if (data.address_type.toLowerCase() == 'iprange') {
            const ips = data.address.split("-");
            if (ips.length == 2) {
                for (let i = 0; i < ips.length; i++) {
                    const valid = this.isIPInRange(ips[i], "0.0.0.0", "255.255.255.255");
                    if (!valid) {
                        this.toast.sToast('error', 'Invalid value for IP Range');
                        return false;
                    }
                }
            } else {
                this.toast.sToast('error', 'Invalid value for IP Range');
                return false;
            }
        } else if (data.address_type.toLowerCase() == 'cidr') {
            const ipParts = data.address.split('/');
            const validIP = this.isIPInRange(ipParts[0], "0.0.0.0", "255.255.255.255");
            const cidrPattern = /^\d+$/;
            const validCIDR = cidrPattern.test(ipParts[1]) && parseInt(ipParts[1]) >= 1 && parseInt(ipParts[1]) <= 31;

            if (!validIP || !validCIDR) {
                this.toast.sToast('error', 'Invalid value for CIDR');
                return false;
            }
        }
        return true;
    }


    save(): void {
        const scan = cloneDeep(this.cofigForm);
        scan.is_excluded = (!scan.is_excluded) ? false : true;
        if (scan.mac_address_exclude && scan.mac_address_exclude.length) {
            scan.mac_address_exclude = [...new Set(scan.mac_address_exclude)].toString();
        } else {
            scan.mac_address_exclude = ''
        }
        const request = this.checkIP(scan);
        if (scan.address_type === 'DOMAIN') {
            scan.is_excluded = false;
        }
        if (scan.address_type === 'DOMAIN' || scan.address_type === 'STATICIP') {
            scan.target_ip = null;
        }
        const outputObject = scan.manual_tags.reduce((result: any, item: any) => {
            result[item.key] = item.value;
            return result;
        }, {});
        scan.manual_tags = outputObject;
        if (request) {
            scan.company_id = parseInt(this.cs.currentScope.id);
            delete scan["[object Object]"];
            var api = '/w/company/discovery_settings'
            var method = "post"
            var postdata: any = { data: scan }
            if (this.discoverySettings && this.discoverySettings.id) {
                method = "patch"
                api = '/w/company/update_discovery_settings'
                postdata = { id: this.discoverySettings.id, data: scan };
            }
            this._bs
                .doRequest(api, method, postdata)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        this.toast.sToast('success', 'Added Successfully');
                        this.modalService.close('newDiscoverySetting');
                        this.cofigForm = {};
                        try {
                            setTimeout(() => this.initialTable(), 3000);
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                });
        }
    }

    addTableData(): void {
        this.modalService.open('newDiscoverySetting');
        this.discoverySettings = {
            name: '',
            address_type: '',
            address: '',
            is_excluded: false,
            mac_address_exclude: [],
            target_ip: ''
        };
        this.cofigForm = {
            name: '',
            address_type: '',
            address: '',
            is_excluded: false,
            mac_address_exclude: [],
            target_ip: '',
            manual_tags: []
        };
        this.showExclude = false;
        this._changeDetectorRef.detectChanges();
    }

    discactionCall(data: any) {
        if (data.action.text == 'Edit') {
            const cloneData : any = cloneDeep(data.row)
            if (Object.keys(data.row.manual_tags).length === 0) {
                cloneData.manual_tags = []
            } else {
                const outputArray = Object.entries(cloneData.manual_tags).map(([key, value]) => ({ key, value }));
                cloneData.manual_tags = outputArray;
            }
            this.cofigForm = {
                name: cloneData.name,
                address_type: cloneData.address_type,
                address: cloneData.address,
                is_excluded: cloneData.is_excluded,
                mac_address_exclude: (cloneData.mac_address_exclude) ? cloneData.mac_address_exclude.split(',') : [],
                target_ip: cloneData.target_ip,
                manual_tags: cloneData.manual_tags
            };
            this.discoverySettings = cloneData;
            this.showOptions({ checked: cloneData.is_excluded }, true);
            this.modalService.open('newDiscoverySetting');
        } else if (data.action.text == 'Delete') {
            this.deletetag(data);
        }
    }

    deletetag(data: any) {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to delete this Discovery Setting?',
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._bs.doRequest(`/d/company/discovery_settings/${data.row.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result) {
                            this.toast.sToast('success', 'Removed successfully');
                            setTimeout(() => {
                                this.initialTable();
                            }, 3000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.cofigForm.mac_address_exclude.push(value);
        }
        event.chipInput!.clear();
    }

    remove(key: any): void {
        const index = this.cofigForm.mac_address_exclude.indexOf(key);
        if (index >= 0) {
            this.cofigForm.mac_address_exclude.splice(index, 1);
        }
    }

    edit(key: any, event: MatChipEditedEvent) {
        const value = event.value.trim();
        if (!value) {
            this.remove(key);
            return;
        }
        // Edit existing key
        const index = this.cofigForm.mac_address_exclude.indexOf(key);
        if (index >= 0) {
            this.cofigForm.mac_address_exclude[index] = value;
        }
    }
    showOptions(event: any, edit?: any): void {
        (!edit) ? this.cofigForm.mac_address_exclude = [] : null;
        if (event.checked) {
            this.showExclude = false;
        } else {
            this.showExclude = true;
        }
    }

    onAddressTypeChange(event: any) {
        this.cofigForm.address = '';
        this.validateIpRange();
    }

    validateIpRange() {
        const ipRange = this.cofigForm.address;
        if (/\s/.test(ipRange)) {
            this.ipRangeError = true; 
          this.toast.sToast('info','Please remove extra spaces from IP ranges')
        } else {
          this.ipRangeError = false; 
        }
        this._changeDetectorRef.detectChanges();
      }

    addressTypeChange(): void {
        this.cofigForm.address = '';
        this.cofigForm.mac_address_exclude = [];

        if (this.cofigForm.address_type === 'CIDR' || this.cofigForm.address_type === 'IPRANGE' || this.cofigForm.address_type === 'STATICIP') {
            this.showExclude = true;
        } else {
            this.showExclude = false;
        }
        this._changeDetectorRef.detectChanges();
    }
    addTag(): void {
        this.cofigForm.manual_tags.push({ key: null, value: null });
    }
    parentCallBack(event: string): void {
        if (event === 'uploadBulkIpCsv') {
            this.csvUpload = {};
            this.uploadFileInput.nativeElement.value = '';
            this.modalService.open('uploadBulkIP');
        }
    }
    downloadSampleCSV() {
        window.open('/assets/files/endpoints.csv', '_blank');
    }
    uploadBulkIPCSV() {
        this.loaderService.display(true);
        const formData = new FormData();
        this.objectKeys(this.csvUpload).forEach(obj => {
            console.log(obj)
            formData.append(obj, this.csvUpload[obj]);
        });
        console.log(formData)
        this._bs.uploadFormData("/w/company/upload_bulk_ip", formData).subscribe(
            (res: any) => {
                this.loaderService.display(false);
                console.log(res);
                if (res.status) {
                    console.log(res);
                    // let total = `Total records :${res.total_record} <br> Total Uploaded: ${res.successfull_uploaded} <br>  Already Uploaded: ${res.already_uploaded} <br>  Records Failed:${res.records_failed}`;
                    // this.toast.sToast('success', 'Uploaded Successfully', total);
                    let total = `Total Records: ${res.total_records}<br>Added Records: ${res.added_records}  <br>Updated Records : ${res.updated_records}<br>Duplicate Records: ${res.duplicates_discarded_records}<br>Failed Records: ${res.failed_records}`;
                    this.toast.sToast('success', total, 'Updated Successfully');
                    setTimeout(() => this.cs.selectedTagChange.next({}));
                    this.closeModel();
                    this.csvUpload = {};
                } else {
                    this.toast.sToast('error', res.message);
                }
            },
            (error: any) => {
                console.error("Error posting file to API:", error);
                this.loaderService.display(false);
            }
        );
    }
    closeModel() {
        this.csvUpload = {};
        this.modalService.close("uploadBulkIP");
        this.uploadFileInput.nativeElement.value = '';
    }
    uploadFile(event: any, key: any): void {
        console.log(event.target.files.length)
        console.log(key)
        if (event.target.files.length > 0) {
            this.csvUpload[key] = event.target.files[0];
            this.csvUpload.company_id = this.cs.currentScope.id;
        }
    }
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
