import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject } from 'rxjs';
import { MaterialModule } from 'app/material.module';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-asset-finger-print',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './asset-finger-print.component.html',
  styleUrls: ['./asset-finger-print.component.scss']
})
export class AssetFingerPrintComponent {
  @Input() asset: any;
  cAsset: any = {};
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  allKeysEmpty = true;
  Objectkeys = Object.keys;
  constructor(private _bs: BaseRequestService, private _cs: CommonService, private ls: LoaderService, private toast: MyToastrService,
    private _cdr: ChangeDetectorRef
  ) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.showFingerPrint();
  }

  formatInnerObjectsOnly(data: any) {
    for (const key in data) {
      const value = data[key];
      if (typeof value === 'object' && value !== null) {
        for (const innerKey in value) {
          if (typeof value[innerKey] === 'object' && !Array.isArray(value[innerKey]) && value[innerKey] !== null) {
            const formattedInnerObject = Object.entries(value[innerKey])
              .map(([k, v]) => `${k}: ${Array.isArray(v) ? v.join(', ') : v === null ? 'null' : v}`)
              .join(', ');

            value[innerKey] = formattedInnerObject;
          }
        }
      }
    }
    return data;
  }
  showFingerPrint(): void {
    this.cAsset = cloneDeep(this.asset);
    if (!this.asset.finger_print) { this.asset.finger_print = {}; };
    this.allKeysEmpty = Object.values(this.asset.finger_print).every(
      value => value && typeof value === 'object' && !Object.keys(value).length
    );
    if (!this.allKeysEmpty) {
      this.cAsset.finger_print = cloneDeep(this.formatInnerObjectsOnly(this.cAsset.finger_print));
    }
    this._cdr.detectChanges();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
