import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Scope } from 'app/layout/common/scope/scope.types';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import {CommonService} from "../../../_services/common.service";

@Injectable({providedIn: 'root'})
export class ScopeService
{
    private _scope: ReplaySubject<Scope[]> = new ReplaySubject<Scope[]>(1);
    private _company: ReplaySubject<Scope[]> = new ReplaySubject<Scope[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, public cs: CommonService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for scope
     */
    get scope$(): Observable<Scope[]>
    {
        return this._scope.asObservable();
    }

    get company$(): Observable<any> {
        return this._company.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all scope
     */
    getAll(): Observable<Scope[]>
    {
        return this._httpClient.get<Scope[]>('api/common/scope').pipe(
            tap((scope) =>
            {
                this._scope.next(scope);
            }),
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Scope): Observable<Scope>
    {
        return this.scope$.pipe(
            take(1),
            switchMap(scope => this._httpClient.post<Scope>('api/common/scope', {notification}).pipe(
                map((newScope) =>
                {
                    // Update the scope with the new notification
                    this._scope.next([...scope, newScope]);

                    // Return the new notification from observable
                    return newScope;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Scope): Observable<Scope>
    {
        return this.scope$.pipe(
            take(1),
            switchMap(scope => this._httpClient.patch<Scope>('api/common/scope', {
                id,
                notification,
            }).pipe(
                map((updatedScope: Scope) =>
                {
                    // Find the index of the updated notification
                    const index = scope.findIndex(item => item.id === id);

                    // Update the notification
                    scope[index] = updatedScope;

                    // Update the scope
                    this._scope.next(scope);

                    // Return the updated notification
                    return updatedScope;
                }),
            )),
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean>
    {
        return this.scope$.pipe(
            take(1),
            switchMap(scope => this._httpClient.delete<boolean>('api/common/scope', {params: {id}}).pipe(
                map((isDeleted: boolean) =>
                {
                    // Find the index of the deleted notification
                    const index = scope.findIndex(item => item.id === id);

                    // Delete the notification
                    scope.splice(index, 1);

                    // Update the scope
                    this._scope.next(scope);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all scope as read
     */
    markAllAsRead(): Observable<boolean>
    {
        return this.scope$.pipe(
            take(1),
            switchMap(scope => this._httpClient.get<boolean>('api/common/scope/mark-all-as-read').pipe(
                map((isUpdated: boolean) =>
                {
                    // Go through all scope and set them as read
                    scope.forEach((notification, index) =>
                    {
                        scope[index].read = true;
                    });

                    // Update the scope
                    this._scope.next(scope);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
