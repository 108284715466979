<button mat-icon-button (click)="openPanel()" #shortcutsOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
</button>

<ng-template #shortcutsPanel>
    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="flex items-center text-lg font-medium leading-10">
                <span class="">Favourites</span>
                <ng-container *ngIf="mode !== 'view'">
                    <span class="ml-1">
                        <ng-container *ngIf="mode === 'add'">- Add new</ng-container>
                        <ng-container *ngIf="mode === 'modify' || mode === 'edit'">- Editing</ng-container>
                    </span>
                </ng-container>
            </div>
            <div class="ml-auto">

                <ng-container *ngIf="mode === 'view'">
                    <button mat-icon-button (click)="changeMode('modify')" [matTooltip]="'Enter edit mode'">
                        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="newShortcut()" [matTooltip]="'Add shortcut'">
                        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    </button>
                </ng-container>

                <ng-container *ngIf="mode === 'modify'">
                    <button mat-icon-button (click)="changeMode('view')" [matTooltip]="'Exit edit mode'">
                        <mat-icon class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                    </button>
                </ng-container>

                <ng-container *ngIf="mode === 'add'">
                    <button mat-icon-button (click)="changeMode('view')" [matTooltip]="'Cancel'">
                        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                    </button>
                </ng-container>

                <ng-container *ngIf="mode === 'edit'">
                    <button mat-icon-button (click)="changeMode('modify')" [matTooltip]="'Cancel'">
                        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card">

            <ng-container *ngIf="mode === 'view' || mode === 'modify'">

                <div class="grid grid-cols-2 grid-flow-row">
                    <ng-container *ngFor="let shortcut of shortcuts; trackBy: trackByFn">
                        <div
                            class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                            <ng-container *ngIf="mode === 'modify'">
                                <div class="absolute inset-0 z-99 cursor-pointer" (click)="editShortcut(shortcut)">
                                </div>
                            </ng-container>
                            <a class="flex flex-col items-center justify-center w-full h-full py-6 no-underline"
                                [ngClass]="{'pointer-events-none': mode === 'modify'}" href="javascript:"
                                (click)="redirect(shortcut.link)">
                                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                            </a>
                            <ng-template #linkContent>
                                <div
                                    class="relative flex shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full bg-gray-200 dark:bg-gray-700">
                                    <mat-icon class="absolute opacity-0 group-hover:opacity-100 z-20 icon-size-5"
                                        *ngIf="mode === 'modify'" [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                                    <mat-icon class="z-10" [ngClass]="{'group-hover:opacity-0': mode === 'modify'}"
                                        [svgIcon]="'heroicons_outline:link'"></mat-icon>
                                </div>
                                <div class="font-medium text-center">{{shortcut.label}}</div>
                                <div class="text-md text-center text-secondary">{{shortcut.description}}</div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>

                <ng-container *ngIf="!shortcuts || !shortcuts.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div
                            class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                            <mat-icon class="text-primary-700 dark:text-primary-50"
                                [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">No favourites</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have favourites,
                            they will appear here.</div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="mode === 'add' || mode === 'edit'">
                <form class="p-6" [formGroup]="shortcutForm">
                    <mat-form-field class="w-full mb-2">
                        <mat-label>Label</mat-label>
                        <input matInput [formControlName]="'label'" required>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-2">
                        <mat-label>Description</mat-label>
                        <input matInput [formControlName]="'description'">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Link</mat-label>
                        <input matInput [formControlName]="'link'" required>
                        <mat-hint>Ex: /asset/assets</mat-hint>
                    </mat-form-field>
                    <div class="flex items-center justify-end mt-4">
                        <button class="mr-2" *ngIf="mode === 'edit'" mat-flat-button type="button" (click)="delete()">
                            Delete
                        </button>
                        <button mat-flat-button [color]="'primary'" [disabled]="!shortcutForm.valid" type="button"
                            (click)="save()">
                            <ng-container *ngIf="mode === 'add'">Add</ng-container>
                            <ng-container *ngIf="mode === 'edit'">Update</ng-container>
                        </button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</ng-template>