import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { MaterialModule } from 'app/material.module';
import { LoaderComponent } from 'app/modules/shared/loader/loader.component';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { cloneDeep } from 'lodash';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-disable-roaming-app',
  templateUrl: './disable-roaming-app.component.html',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AppFilterPipeModule, MatPaginatorModule, MaterialModule, FuseAlertComponent, ModalComponent, DirectivesModule, FuseScrollbarDirective, LoaderComponent, FormsModule, ReactiveFormsModule],
  styleUrls: ['./disable-roaming-app.component.scss']
})
export class DisableRoamingAppComponent implements OnInit, OnDestroy, AfterViewInit {
  securityReportCard: any = [];
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  global_value = true;
  searchFilter: any = '';
  pageTotal = 0;
  searchUpdate = new Subject<any>();
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ['select', 'name', 'status', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<any>(true, []);
  @ViewChild('roamingAppTable', { read: MatSort }) roamingAppTableMatSort: MatSort;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  _disabled_roaming_applications: any = { 'category': 'global', 'sub_category': 'settings', 'company_id': null, 'name': 'disabled_roaming_applications', 'value': [] };
  constructor(private _bs: BaseRequestService, public _cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private ls: LoaderService, private toast: MyToastrService, public _modalService: ModalService,
    public confirmDialog: FuseConfirmationService) {
    this.searchUpdate
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((value) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.pageTotal = this.dataSource.filteredData.length;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.getRoamingApplications();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  getRoamingApplications(): void {
    this.ls.display(true);
    this.pageTotal = this._cs.allComp.length;
    this.clearSelection();
    const params: any = { condition: `name='disabled_roaming_applications'`, skip: 0, limit: 1 };
    this._bs.doRequest(`/r/company/settings`, 'get', null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.ls.display(false);
        this.dataSource.data = [];
        if (result.status && result.data && result.data.length) {
          this._disabled_roaming_applications.id = result.data[0].id;
          this._disabled_roaming_applications.value = result.data[0].value;
          const response = this._cs.allComp.map((item: any) => {
            const isDisabled = result.data[0].value.includes(item.id);
            return {
              ...item,
              enabled: !isDisabled
            };
          });
          const global = response.filter((x: any) => !x.enabled);
          this.global_value = true;
          if (global && global.length) { this.global_value = false; }
          this.loadingCon.loading = null; this.loadingCon.nodata = null;
          this.dataSource.data = response;
          this._changeDetectorRef.detectChanges();
        } else {
          const response = this._cs.allComp.map((item: any) => {
            return {
              ...item,
              enabled: true
            };
          });
          this.dataSource.data = response;
          this.loadingCon.loading = null; this.loadingCon.nodata = null;
          this._changeDetectorRef.detectChanges();
        }
      })
  }

  saveSettings(value: any, check: any, company_id: any): void {
    const dra: any = cloneDeep(this[`_disabled_roaming_applications`]);
    dra.value = value;
    this.ls.display(true);
    const request: any = { data: dra };
    if (dra.id) {
      request.id = dra.id
    }
    const method = (request.id) ? 'patch' : 'post';
    const msg = (request.id) ? 'Updated' : 'Saved';
    this._bs.doRequest('/w/company/settings', method, request)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.ls.display(false);
        if (result.status) {
          this.sendRomingProfileSetting(check, company_id)
          if (method === 'post') {
            this['_disabled_roaming_applications'].id = parseInt(result.id);
          }
          this.toast.sToast('success', `${msg} successfully!`);
          this.clearSelection();
          setTimeout(() => { this.getRoamingApplications(); }, 1000);
        } else {
          const data = (result.message) ? result.message : result.data; this.toast.sToast('error', data);

        }
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  sendRomingProfileSetting(value: any, company_id: any): void {
    const setting_message = value ? "enable_roaming_profile" : "disable_roaming_profile"
    const reqData: any = { company_id: parseInt(company_id), message: setting_message, };
    console.log("reqData", reqData)
    this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        console.log("result");
      });
  }
  onChange = (event: any, company: any) => {
    if (company === '*') {
      const request = (event.checked) ? [] : this.dataSource.data.map((x: any) => x.id);
      const message = `Are you sure you want to ${(event.checked) ? 'Enable' : 'Disable'} Roaming Applictions?`;
      const confirmation = this.confirmDialog.open({
        title: 'Confirmation',
        message: message,
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: 'Yes', color: "primary" },
          cancel: { show: true, label: 'No' }
        },
      });
      confirmation.afterClosed().pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          if (result === 'confirmed') {
            this.saveSettings(request, (event.checked), company.id);
          } else {
            this.getRoamingApplications();
          }
        });
    } else {
      const old_value = cloneDeep(this._disabled_roaming_applications.value);
      const request = (event.checked) ? old_value.filter((x: any) => x !== company.id) : [...old_value, ...[company.id]];
      const message = `Are you sure you want to ${(event.checked) ? 'Enable' : 'Disable'} Roaming Applictions this company?`;
      const confirmation = this.confirmDialog.open({
        title: 'Confirmation',
        message: message,
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: 'Yes', color: "primary" },
          cancel: { show: true, label: 'No' }
        },
      });
      confirmation.afterClosed().pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          if (result === 'confirmed') {
            this.saveSettings(request, (event.checked), company.id);
          } else {
            this.getRoamingApplications();
          }
        });
    }
  }

  globalActionCall = (event: any, type: any) => {
    const selected_id = event.map((x: any) => x.id);
    const old_value = cloneDeep(this._disabled_roaming_applications.value);
    const request = (type === 'enable') ? old_value.filter((x: any) => selected_id.indexOf(x) === -1) : [...selected_id, ...old_value];
    const message = `Are you sure you want to ${(type === 'enable') ? 'Enable' : 'Disable'} Roaming Applictions for the selected company(s)?`;
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: message,
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: 'Yes', color: "primary" },
        cancel: { show: true, label: 'No' }
      },
    });
    confirmation.afterClosed().pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result === 'confirmed') {
          this.saveSettings(request, (type === 'enable'), 0);
        } else {
          this.getRoamingApplications();
        }
      });
  }

  clearSelection(): void {
    this.selection.clear();
  }
}
