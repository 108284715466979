import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    UntypedFormGroup,
    NgForm,
    UntypedFormControl,
    UntypedFormBuilder,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {FuseConfirmationService} from '@fuse/services/confirmation';
import {BaseRequestService} from 'app/_services/base.service';
import {CommonService} from 'app/_services/common.service';
import {LoaderService} from 'app/_services/loader.service';
import {ModalService} from 'app/_services/modal.service';
import {MyToastrService} from 'app/_services/toastr.service';
import {Subject, debounceTime, takeUntil} from 'rxjs';
import {FuseAlertComponent} from '@fuse/components/alert';
import {DirectivesModule} from 'app/-directives/-directives.module';
import {AppFilterPipeModule} from 'app/_filters/app.filter-pipe.module';
import {MaterialModule} from 'app/material.module';
import {ModalComponent} from 'app/modules/shared/modal.component';
import {TableComponent} from 'app/modules/shared/table/table.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

@Component({
selector: 'app-customers',
    standalone: true,
    imports: [CommonModule, TableComponent, AppFilterPipeModule, FuseAlertComponent, NgxMatSelectSearchModule,
        DirectivesModule, FormsModule, MaterialModule, ReactiveFormsModule, ModalComponent],
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {

    customerTableOption: any = {
        columns: [
            {
                "header": "First Name",
                "columnDef": "first_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.first_name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Last Name",
                "columnDef": "last_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.last_name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Email",
                "columnDef": "email",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.email}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Role",
                "columnDef": "roles",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.roles}",
                "order": 0,
                "width": "400px",
                textBadge: true,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "State",
                "columnDef": "state",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.state}",
                isColoredCntrl: true,
                isCustomClass: true,
                isCustomText: true,
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ], sortOptions: {active: 'created', direction: 'desc'},
        _pageData: [],
        tableOptions: {
            title: 'Customers',
            isServerSide: false,
            selectText: 'Customers',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: true,
            actionMenuItems: [
                {
                    text: 'Delete',
                    id: 'delete',
                    icon: 'delete',
                    callback: 'editFunction',
                    hideLocal: false,
                    isGlobal: false,
                }],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: true,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/user/get_users',
                condition: '',
                type: 'post',
                isGlobal: true,
                params: {customer: true, included: this._cs.currentScope.id}
            },
            id: 'customer'
        },
        changeValue: new Subject<any>(),
        customText: [
            {
                status: 'USER_STATE_INITIAL',
                DisplayText: 'Initial',
                class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'
            },
            {
                status: 'USER_STATE_ACTIVE',
                DisplayText: 'Active',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: 'USER_STATE_INACTIVE',
                DisplayText: 'Inactive',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
        ],
    }

    uView: any = 'table';
    contactForm: UntypedFormGroup;
    @ViewChild('updateForm') updateForm: NgForm;
    roles: any = [];
    apiData: any = [];
    currentUser: any = {};

    includeControl: UntypedFormControl = new UntypedFormControl();
    public searching = false;
    sourceCompany: any = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _formBuilder: UntypedFormBuilder, public _cs: CommonService,
        private _bs: BaseRequestService, public modalService: ModalService,
        private ls: LoaderService, private toast: MyToastrService,
        public confirmDialog: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the contact form
        this.contactForm = this._formBuilder.group({
                first_name: ['', [Validators.required]],
                last_name: ['', [Validators.required]],
                email: ['', [Validators.required]],
                mobile: ['', [Validators.required]],
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    newUserCall(): void {
        this.contactForm = this._formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            mobile: ['', [Validators.required]],
        });
        this.uView = 'add';
    }

    actioncallback($event: any): void {
        if ($event.action.text == 'Delete') {
            this.deleteUser($event.row)
        }
    }

    getAPI(user_id: any): void {
        this.ls.display(true, 'Getting client api key');
        this._bs.doRequest(`/r/user/generate_secret/${user_id}`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.ls.display(false);
                this.apiData = [];
                if (result.status) {
                    this.apiData = [
                        {
                            icon: "mat_outline:developer_board",
                            name: "Client ID",
                            key: result.data.client_id
                        },
                        {
                            icon: "mat_outline:vpn_key",
                            name: "Client Secret",
                            key: result.data.client_secret
                        }
                    ]
                    this.modalService.open('userAPIKey');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    resetMFAConfirmationDialog($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to reset MFA of this user ' + $event.first_name + '?',
            icon: {show: false, name: "", color: "accent"},
            actions: {
                confirm: {show: true, label: "Yes", color: "primary"},
                cancel: {show: true, label: "No"}
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                this._bs.doRequest(`/d/company/reset_mfa/${$event.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', result.message);
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }

    showHideTable(): void {
        const data = Object.assign({}, this.customerTableOption);
        data.pageData = [];
        data.tableOptions.pageTotal = 0;
        this.customerTableOption = {};
        this._changeDetectorRef.detectChanges();
        this.customerTableOption = data;
        this.customerTableOption.pageData = [];
        this.customerTableOption.tableOptions.pageTotal = 0;
        this._changeDetectorRef.detectChanges();
    }

    saveUser(): void {
        const user: any = this.contactForm.getRawValue();
        user.mobile = user.mobile.toString();
        user.password = '';
        user.excluded = '';
        user.included = this._cs.currentScope.id + '';
        user.roles = ["customer"];
        this.ls.display(true);
        this._bs.doRequest(`/w/user/create_user`, 'post', user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.toast.sToast('success', `User created successfully.
                     An initialization email is sent to the created user's Email Address to set up the user.`);
                    setTimeout(() => this.showHideTable());
                    this.uView = 'table';
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    deleteUser($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to delete this user ?`,
            actions: {
                confirm: {
                    label: 'Delete'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                this._bs.doRequest(`/d/user/delete_user/${$event.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', 'User removed successfully');
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }
}
