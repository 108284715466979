import { Component, Input, OnInit ,ChangeDetectorRef} from '@angular/core';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-asset-cpu-info',
  standalone: true,
  templateUrl: './asset-cpu-info.component.html',
  styleUrls: ['./asset-cpu-info.component.scss']
})
export class AssetCpuInfoComponent implements OnInit {

  @Input() asset: any;
  cpunformation: any;
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService,
    private ls: LoaderService, private toast: MyToastrService, private cdr: ChangeDetectorRef ) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getCPUinfo();
  }
  getCPUinfo(){
    if (!this.asset) {
      setTimeout(() => { this.getCPUinfo(); }, 2000);
      return;
    }
    //https://pod701.myconnectsecure.com/r/company/cpu_info?condition= company_id='7968' and asset_id='17132'
    this.ls.display(true);
    this._bs.doRequest(`/r/company/cpu_info`, 'get', null,
      { condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`, skip: 0, limit: 1000})
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          console.log(res)
          if (res.data && res.data.length) {
            this.cpunformation = res.data[0];
            this.cdr.detectChanges(); 
            this.loadingCon.loading = null; this.loadingCon.nodata = null;
          } else {
            this.loadingCon.loading = null; this.loadingCon.nodata = true;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
      console.log(this.cpunformation)
  }
}
