<div class="progress-container">
    <div *ngFor="let step of colorSteps" class="ansbox" [style.width]="step.width"
            [style.backgroundColor]="white">
            <div class="mt-1 text-sm">{{ step.label }}</div>
        </div>
    <div id="colorBar" class="relative">
        <div *ngFor="let step of colorSteps" class="ansbox" [style.width]="step.width"
            [style.backgroundColor]="step.color">
        </div>
    </div>
    <div class="pointer mt-1" [style.left]="getPointerPosition()">
    </div>
</div>