<div class="flex flex-col mt-4 w-full">
    <div class="mt-2 grid  grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 md:gap-8 px-4">
        <ng-container *ngFor="let d of pillCards">
            <div class="relative overflow-hidden rounded-xl shadow border-t-4 bg-card cursor-pointer" [ngClass]="d.border" (click)="changeAdDetails(d.title.toLowerCase())">
                <div class="py-6 px-6">
                    <div class="flex flex-row"><span><i class="fas text-2xl text-secondary ml-2"
                                [ngClass]="d.icon"></i></span>
                        <div class="text-lg font-bold tracking-tight leading-6 ml-2 mb-4 truncate">
                            {{d.title}}
                        </div>
                    </div>
                    <div class="flex items-center">
                        <h3 class="relative ml-2 inline-block text-2xl font-medium leading-none ml-1 p-2 bg-blue-400 text-black rounded">
                            {{d.count}}
                        </h3>
                        <div class="flex flex-col w-full">
                            <ng-container *ngFor="let item of d.data">
                                <div class="flex items-center justify-between ml-3 mb-1">
                                    <div class="text-base font-medium capitalize" [matTooltip]="">
                                        {{item.name | camelToHuman:true}}</div>
                                    <span class="ml-1 px-2 ring-1 test-white rounded py-0.5 text-sm min-w-[42px] max-w-[42px]">
                                        {{item.value| shortNumber}}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="isVisible && company && company.id">
        <mat-divider class="my-6"></mat-divider>
        <div class="flex flex-row justify-between">
            <div class="flex flex-row pl-6 pt-4 gap-4 items-center justify-start">
                <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                    (click)="changeAdDetails('ou')" [ngClass]="{'bg-primary text-white': cView === 'ou'}">OU</div>
                <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                    (click)="changeAdDetails('users')" [ngClass]="{'bg-primary text-white': cView === 'users'}">Users
                </div>
                <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                    (click)="changeAdDetails('computers')" [ngClass]="{'bg-primary text-white': cView === 'computers'}">Computers</div>
                <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                    (click)="changeAdDetails('groups')" [ngClass]="{'bg-primary text-white': cView === 'groups'}">Groups
                </div>
                <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                    (click)="changeAdDetails('gpo')" [ngClass]="{'bg-primary text-white': cView === 'gpo'}">GPO
                </div>
                <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                    (click)="changeAdDetails('passPolicy')" [ngClass]="{'bg-primary text-white': cView === 'passPolicy'}">Password Policy
                </div>
            </div>
            <div class="flex flex-col pr-6" *ngIf="lastScannedTime">
                <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
                    <div class="text-medium font-bold" *ngIf="!lastScannedTime">-</div>
                    <div class="text-medium font-bold" *ngIf="lastScannedTime">{{lastScannedTime | utcToLocale }} </div>
                </div>
                <div class="text-secondary text-medium">Last AD Scan Time</div>
            </div>
        </div>
        <stable [sTableOptions]="ouTableOptions" *ngIf="cView === 'ou'" (hyperlinkCallback)="linkCall($event)"></stable>
        <stable [sTableOptions]="userTableOptions" *ngIf="cView === 'users'" (hyperlinkCallback)="linkCall($event)">
        </stable>
        <stable [sTableOptions]="compuTableOptions" *ngIf="cView === 'computers'"
            (hyperlinkCallback)="linkCall($event)"></stable>
        <stable [sTableOptions]="gpoTableOptions" *ngIf="cView === 'gpo'" (hyperlinkCallback)="linkCall($event)">
        </stable>
        <stable [sTableOptions]="grouTableOptions" *ngIf="cView === 'groups'" (hyperlinkCallback)="linkCall($event)">
        </stable>
        <stable [sTableOptions]="passPoicyTableOptions" *ngIf="cView === 'passPolicy'" (hyperlinkCallback)="linkCall($event)">
        </stable>
    </ng-container>
</div>
