import {  Injectable, Inject, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MyToastrService {
  constructor(@Inject(Injector) private injector: Injector) { }
  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }
  sToast(type: string, message: string, title?: string | null): void {
    // @ts-ignore
    this._toastrService[type](message, title, {
      timeOut: (type === 'success') ? 4000 : 10000, // Set the timeout duration in milliseconds (3 seconds in this example)
      enableHtml: true
    });
  }
}
