<div class="py-2">
    <fuse-alert [type]="'info'" [appearance]="'border'">
        <span fuseAlertTitle>Firewall</span>
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            The Global Firewall contains the list of antivirus applications shown in the security
            report card for the respective assets.Any application that is not already listed as antivirus under Global
            Firewall can be
            added here.<br />Please run a scan after adding to reflect the application name in the security report card
            of
            the respective asset.<br />To Exclude any application from the Global Firewall list, please click on the
            '->' for
            the selected application.
        </span>
    </fuse-alert>
    <div class="flex flex-row justify-center items-center gap-6 mt-4" *ngIf="!hideEdrTable">
        <div
            class="relative w-[40vw] h-[50vh] bg-card pb-2 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-row text-lg font-medium leading-6 tracking-tight truncate">
                        <span class="mt-2">
                            Global Firewall
                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm"> {{globalEdr.length ||
                                0}} </span>
                        </span>
                        <button class="ml-4 mt-2" *ngIf="(hasSelected() || isAllSelected()) && globalEdr.length"
                            (click)="changeBulkExclude()" matTooltip="Bulk Exclude">
                            <mat-icon color="primary">arrow_forward</mat-icon>
                        </button>
                        <button class="px-6 text-lg font-semibold mini-form-field cursor-pointer" mat-stroked-button
                            *ngIf="!hideEdrTable && !hasSelected() && !isAllSelected()" type="button"
                            matTooltip="Add New Record" color="primary" (click)="toggleAddMode(true)">
                            Add
                        </button>
                    </div>
                    <div class="spcacer"></div>
                    <mat-form-field class="w-1/2 -mr-2 mini-form-field" appearance="fill">
                        <input type="text" matInput name="globalSearch" [(ngModel)]="globalSearch">
                    </mat-form-field>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1 overflow-auto" cdkScrollable>
                    <mat-list-item class="border-b">
                        <div class="flex flex-row items-center">
                            <mat-checkbox [(ngModel)]="selectAll" (change)="selectAllToggle()" class=""
                                [checked]="selectAll && isAllSelected()"
                                [indeterminate]="hasSelected() && !isAllSelected()" value="" color="primary">
                            </mat-checkbox>
                            <span class="spacer"></span>
                            <span class="text-sm font-bold">Name</span>
                            <div class="spacer">&nbsp;</div>
                            <span class="text-sm font-bold">Action</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngIf="globalEdr.length; else noData1">
                        <mat-list-item class="border-b pointer"
                            *ngFor="let edr of globalEdr | search: globalSearch;let i = index;">
                            <div class="flex flex-row items-center">
                                <mat-checkbox [(ngModel)]="edr.selected" value="" class="pt-2" name="select{{i}}"
                                    color="primary"></mat-checkbox>
                                <div class="spacer">&nbsp;</div>
                                <span>{{edr.name}}</span>
                                <div class="spacer">&nbsp;</div>
                                <button class="ml-1 mini-form-field" type="button" (click)="changeExclude(edr)"
                                    [disabled]="hasSelected() || isAllSelected()">
                                    <mat-icon color="primary">arrow_forward</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #noData1>
                        <div class="flex text-center h-20 items-center justify-center">No firewall found.</div>
                    </ng-template>
                </mat-list>
            </div>
        </div>
        <div
            class="relative w-[40vw] h-[50vh] bg-card border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-row text-lg font-medium leading-6 tracking-tight truncate">
                        <span class="mt-2">
                            Excluded Firewall
                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm"> {{excludedEdr.length
                                || 0}} </span>
                        </span>
                        <button class="ml-4 mt-2"
                            *ngIf="(hasExcludedSelected() || isAllExcludedSelected()) && excludedEdr.length"
                            (click)="removeBulkExclude()" matTooltip="Bulk Remove">
                            <mat-icon color="primary">cancel</mat-icon>
                        </button>
                    </div>
                    <div class="spacer"></div>
                    <mat-form-field class="w-1/2 -mr-2 mini-form-field" appearance="fill">
                        <input type="text" matInput name="exSearch" [(ngModel)]="excludedSearch">
                    </mat-form-field>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1 overflow-auto" cdkScrollable>
                    <mat-list-item class="border-b">
                        <div class="flex flex-row items-center">
                            <mat-checkbox [(ngModel)]="selectAllExcluded" (change)="selectAllExcludedToggle()" class=""
                                [checked]="selectAllExcluded && isAllExcludedSelected()"
                                [indeterminate]="hasExcludedSelected() && !isAllExcludedSelected()" value=""
                                color="primary">
                            </mat-checkbox>
                            <span class="spacer"></span>
                            <span class="text-sm font-bold">Name</span>
                            <div class="spacer">&nbsp;</div>
                            <span class="text-sm font-bold">Action</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngIf="excludedEdr.length; else noData1">
                        <mat-list-item class="border-b pointer"
                            *ngFor="let edr of excludedEdr | search: excludedSearch;let i = index;">
                            <div class="flex flex-row items-center">
                                <mat-checkbox [(ngModel)]="edr.selected" value="" class="pt-2" name="exselect{{i}}"
                                    color="primary"></mat-checkbox>
                                <div class="spacer">&nbsp;</div>
                                <span>{{edr.name}}</span>
                                <div class="spacer">&nbsp;</div>
                                <button class="ml-1 mini-form-field" type="button" (click)="removeExclude(edr.name)"
                                    [disabled]="hasExcludedSelected() || isAllExcludedSelected()">
                                    <mat-icon color="primary">cancel</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #noData1>
                        <div class="flex text-center h-20 items-center justify-center">No firewall found.</div>
                    </ng-template>
                </mat-list>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="hideEdrTable">
        <div
            class="grid grid-cols-2 gap-4 p-2 mt-4 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter name" type="text" required [(ngModel)]="newEdrDetails.name"
                    autocomplete="off" name="firewallName">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Manufacturer</mat-label>
                <input matInput placeholder="Enter manufacturer" type="text" [(ngModel)]="newEdrDetails.manufacturer"
                    autocomplete="off" name="firewallManufacturer">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Year Of Release</mat-label>
                <input matInput placeholder="Enter year of release" type="number"
                    [(ngModel)]="newEdrDetails.year_of_release" autocomplete="off" name="yearOfRelease">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput placeholder="Enter description" type="text" [(ngModel)]="newEdrDetails.edr_description"
                    autocomplete="off" name="firewallDesc">
            </mat-form-field>
            <mat-checkbox name="edrRegex" id="edrRegex" class="ml-2" [(ngModel)]="newEdrDetails.regex" value=""
                color="primary">Enable
                Regex
            </mat-checkbox>
        </div>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'default'" type="button" appPreventMultiClick
                [matTooltip]="'Cancel'" (click)="toggleAddMode(false)">
                Cancel
            </button>
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="savefirewallApp()">
                Save
            </button>
        </div>
    </div>
</div>