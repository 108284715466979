<ng-container *ngIf="showData && data">
  <div
    class="flex flex-col flex-auto w-full px-4 overflow-hidden bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
    <div class="my-2">
      <div class="flex flex-row items-center flex-shrink-0 gap-1 p-2 space-x-4">
        <div class="font-bold text-indigo-800 text-md dark:text-indigo-400" *ngIf="data['IP Address']">
          {{ data['IP Address'] }}
        </div>
        <div class="flex flex-row items-center text-center" *ngIf="data.Location">
          <mat-icon class="icon-size-5 text-hint" [svgIcon]="'mat_solid:location_on'"></mat-icon>
          <div class="ml-1.5 text-md text-secondary">
            {{ data.Location }}
          </div>
        </div>
        <div class="flex flex-row items-center text-center" *ngIf="data['ASN']">
          <mat-icon class="icon-size-5 text-hint" [svgIcon]="'heroicons_solid:cube'"></mat-icon>
          <div class="ml-1.5 text-md text-secondary">
            {{ data['ASN'] }}
          </div>
        </div>
        <div class="flex flex-row items-center text-center" *ngIf="data['ASN Description']">
          <mat-icon class="icon-size-5 text-hint" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
          <div class="ml-1.5 text-md text-secondary">
            {{ data['ASN Description'] }}
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="data['ports'] && data['ports'].length && data['ports'][0]">
      <div class="my-2 border-t">
        <div class="my-4">
          <div class="font-medium leading-6 tracking-tight truncate text-medium">Open Port/Protocol
            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
              {{data['ports'].length }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-wrap items-center mt-2">
        <span *ngFor="let item of data['ports']" class="text-sm font-semibold mb-2 me-2 px-2.5 py-0.5 rounded border"
          [ngClass]="{'bg-grey-100 text-green-800 dark:text-green-400 border-green-400': item === 443,
             'bg-grey-100 text-red-800  dark:text-red-400 border-red-400': item !== 443}">
          {{ item }} - <span [matTooltip]="commonService.portInfo[item].description"
            *ngIf="commonService.portInfo[item]">{{commonService.portInfo[item].protocol}}</span>
        </span>
      </div>
      <mat-divider class="my-2"></mat-divider>
    </ng-container>
    <ng-container *ngIf="data['Vulnerabilities'] && data['Vulnerabilities'].length && data['Vulnerabilities'][0]">
      <div class="my-2 border-b">
        <div class="my-4">
          <div class="font-medium leading-6 tracking-tight truncate text-medium">Vulnerabilities
            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
              {{data['Vulnerabilities'].length }}
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let cveDetails of data['Vulnerabilities']">
        <div class="flex flex-col items-start justify-start" *ngIf="cveDetails">
          <div class="flex flex-row items-center justify-center mt-4">
            <div class="mr-1 font-semibold leading-tight text-medium">{{cveDetails.cve}}</div>
            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
              [ngClass]="'cs-' + cveDetails.severity?.toLowerCase()">
              {{cveDetails.severity}}
            </span>
          </div>
          <div class="mt-2 leading-tight text-secondary text-medium">{{cveDetails.description}}</div>
          <ng-container *ngIf="cveDetails">
            <hr class="w-full mt-4 mb-2 border-t">
            <div class="flex items-start justify-between w-full px-2 my-4">
                <div class="flex flex-col items-center">
                    <span class="font-bold">
                        <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.epss_score, 'epss_score')">
                            {{cveDetails.epss_score}}
                        </span>
                    </span>
                    <span class="mt-1 text-sm font-medium text-secondary">EPSS SCORE</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="font-bold">
                        <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.base_score, 'base_score')">
                            {{cveDetails.base_score}}
                        </span>
                    </span>
                    <span class="mt-1 font-medium text-medium text-secondary">BASE SCORE</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="font-bold"><span
                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.impact_score, 'impact_score')">
                            {{cveDetails.impact_score}}
                        </span></span>
                    <span class="mt-1 font-medium text-medium text-secondary">IMPACT SCORE</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="font-bold"><span
                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngStyle]="getRangeColor(cveDetails.exploitability_score, 'exploitability_score')">
                            {{cveDetails.exploitability_score}}
                        </span></span>
                    <span class="mt-1 font-medium text-medium text-secondary">EXPLOITABILITY SCORE</span>
                </div>
            </div>
            <hr class="w-full mt-2 mb-8 border-t">
        </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
