import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from "../../../_services/base.service";
import { CommonService } from "../../../_services/common.service";
import { LoaderService } from "../../../_services/loader.service";
import { MaterialModule } from "../../../material.module";
import {Subject, Subscription, takeUntil} from 'rxjs';

@Component({
    selector: 'app-notify',
    standalone: true,
    imports: [CommonModule, MaterialModule],
    templateUrl: './notify.component.html',
    styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit, OnDestroy {
    show = false;
    cveList: any;
    subs: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private bs: BaseRequestService,
        public cs: CommonService, private ls: LoaderService) {
        this.subs = this.cs.selectedSiteChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
            if (res !== '*') {
                this.getTrendingCVEs();
            } else {
                this.init();
            }
        });
    }

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        // let cve = localStorage.getItem('tcve');
        // if (!cve) {
            this.getTrendingCVEs();
        // } else {
        //     try {
        //         this.cs.tcve = JSON.parse(cve);
        //         this.process(this.cs.tcve);
        //     } catch (e) {
        //         console.log(e);
        //     }
        // }
    }

    process(data: any): void {
        if (data && this.cs.currentScope && this.cs.currentScope.id) {
            this.getCompanyTrendingCVEs(data, this.cs.currentScope.id);
        } else {
            this.show = true;
        }
    }

    getTrendingCVEs(): void {
        const login = localStorage.getItem('initLogin');
        if (login === '1') {
            this.ls.display(true);
            this.bs.doRequest(`/r/company/jsonconfigs/trending_cve`, 'get')
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                    this.ls.display(false);
                    if (res.status) {
                        localStorage.setItem('tcve', JSON.stringify(res.data));
                        this.cs.tcve = res.data;
                        this.cveList = res.data;
                        if (this.cs.currentScope && this.cs.currentScope.id) {
                            this.getCompanyTrendingCVEs(res.data, this.cs.currentScope.id);
                        } else {
                            this.getCompanyTrendingCVEs(res.data, null);
                        }
                    } else {
                        this.show = false;
                    }
                });
        } else {
            setTimeout(() => this.getTrendingCVEs(), 3000);
        }
    }

    getCompanyTrendingCVEs(cveList: any, company_id: any): void {
        // report_queries/problems_asset_count?problem_name=CVE-1999-0236,CVE-2023-29059,CVE-2023-2136&condition=company_id=237
        let problem_name = cveList.map((x: any) => `'${x.cve}'`);
        this.ls.display(true);
        let params: any = {
            condition: (company_id) ? `company_id=${company_id} and problem_name IN (${problem_name.join(',')})` : `problem_name IN (${problem_name.join(',')})`
        }
        this.bs.doRequest(`/r/report_queries/problems_summary`, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status && res.data && res.data.length) {
                    const problemD = res.data.map((x: any) => x.problem_name);
                    this.cveList = this.cveList.filter((c: any) => problemD.indexOf(c.cve) > -1);
                    this.cs.tcve = this.cveList;
                    this.show = true;
                } else {
                    this.cveList = []; this.cs.tcve = [];
                    this.show = false;
                }
            })
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    searchCVE() {
        // TODO: emit call back to search cve page.
        this.cs.openSearchCVESettings.emit({});
    }

    closeNotify(): void {
        this.show = !this.show;
        this.cs.tcve = [];
    }
}
