import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { Router } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from "../../../_services/loader.service";

@Component({
  selector: 'app-getting-started',
  standalone: true,
  imports: [CommonModule, MaterialModule, AppFilterPipeModule],
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent {
  gettingStartedList: any = [];
  docLinks: any = [];
  constructor(private router: Router, private bs: BaseRequestService, private ls: LoaderService) {
    this.gettingStartedList = [
      {
        title: 'Tenant Info', // @ts-ignore
        description:
          `<div class="flex flex-row gap-2 mt-2"> <span class="text-[#22d3ee] w-[100px]">Tenant Name :  </span><strong class="text-[#e879f9] font-mono">${this.bs.user().tenant_name}</strong></div>\n` +
          `<div class="flex flex-row gap-2"> <span class="text-[#22d3ee] w-[100px]" >Base URL : </span><strong class="text-[#e879f9] font-mono">${window.location.host}</strong></div>\n` +
          `<div class="flex flex-row gap-2"> <span class="text-[#22d3ee] w-[100px]" >POD : </span><strong class="text-[#e879f9] font-mono uppercase">${localStorage.getItem('_pid')}</strong></div>\n`,
        highlight: '',
        linkDesc: 'Check out the ',
        linkText: 'V4 Docs',
        link: 'https://cybercns.atlassian.net/wiki/spaces/CVB/pages/2054029532/V4+Documentation+Home',
        img: '/assets/getting-started/code.png',
        time: '',
      },
      {
        title: 'Welcome to ConnectSecure',
        description: `<div class="mt-2 text-medium font-medium">Discover: </div>
        <div class="mt-0.5 line-clamp-2 text-secondary">Automatically discover all your Network assets with our deep asset discovery capability</div>
        <div class="mt-2 text-medium font-medium">Optimize: </div>
        <div class="mt-0.5 line-clamp-2 text-secondary"> Monitor your Network Assets for Uptime, Configuration issues and much more.</div>
        <div class="mt-2 text-medium font-medium">Secure: </div>
        <div class="mt-0.5 line-clamp-2 text-secondary">Track Network Vulnerabilities and Exploits including support for Active Directory, Office 365 and more.</div>
        <div class="mt-0.5 line-clamp-2 text-secondary">You can also submit a support request email to
            <a class="text-primary" href="mailto:support@connectsecure.com" target="_top"><strong>support@connectsecure.com</strong></a>
        </div>`,
        highlight: '',
        img: '/assets/getting-started/welcome.png',
        time: '',
      }
    ];
    if(this.bs.mssp_name){
      this.gettingStartedList[0].description += `<div class="flex flex-row gap-2"> <span class="text-[#22d3ee] w-[100px]" >MSSP : </span><strong class="text-[#e879f9] font-mono">${this.bs.mssp_name}</strong></div>\n`;
    }
  }

  ngOnInit(): void {
    this.getDocLinks();
  }

  getDocLinks(): void {
    this.ls.display(true);
    this.bs.doRequest(`${this.bs.configURL}/getDocLinks`, 'get').subscribe((result: any) => {
      this.ls.display(false);
      this.docLinks = result.data;
    });
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  reDirect(url: any): void {
    window.open(url);
  }
}
