<div class="flex flex-col w-full min-w-0">
    <mat-drawer-container class="flex-auto h-full">
        <mat-drawer class="w-full dark:bg-gray-900"
            [ngClass]="{'lg:w-3/4': drawerOpened && cView !== 'affected_assets', 'sm:w-[40%] sm:min-w-[40%]': drawerOpened && cView === 'affected_assets'}"
            [autoFocus]="false" [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
            <div class="flex-auto bg-default" #remData id="remData">
                <ng-container *ngIf="cView == 'affected_companies'">
                    <div class="flex flex-row items-center">
                        <div *ngIf="!viewchecking && !linuxremeviewcheck"
                            class="ml-3 text-2xl font-semibold tracking-tight">Product: {{currentRemediation.product}}
                        </div>
                        <div *ngIf="viewchecking && !linuxremeviewcheck"
                            class="ml-3 text-2xl font-semibold tracking-tight">Product:
                            {{currentFixlinuxremedation.product}}</div>
                        <div *ngIf="!viewchecking && linuxremeviewcheck"
                            class="ml-3 text-2xl font-semibold tracking-tight">Product:
                            {{currentFixlinuxremedated.product}}</div>
                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <div class="w-auto rounded-2xl" fuseScrollbar>
                        <div *ngIf="!viewchecking && !linuxremeviewcheck" class="flex flex-row items-start gap-4"
                            fuseScrollbar>
                            <stable [sTableOptions]="companiesTableOptions" class="w-full"
                                *ngIf="companiesTableOptions.tableOptions"
                                (hyperlinkCallback)="linkclickcompanies($event)">
                            </stable>
                        </div>

                        <div *ngIf="viewchecking && !linuxremeviewcheck" class="flex flex-row items-start gap-4"
                            fuseScrollbar>
                            <stable [sTableOptions]="companiesTableOptions1" class="w-full"
                                *ngIf="companiesTableOptions1.tableOptions"
                                (hyperlinkCallback)="linkclickcompanies1($event)">
                            </stable>
                        </div>

                        <div *ngIf="!viewchecking && linuxremeviewcheck" class="flex flex-row items-start gap-4"
                            fuseScrollbar>
                            <stable [sTableOptions]="companiesTableOptions2" class="w-full"
                                *ngIf="companiesTableOptions2.tableOptions"
                                (hyperlinkCallback)="linkclickcompanies2($event)">
                            </stable>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="!viewchecking && !linuxremeviewcheck">
                    <div class="w-auto rounded-2xl" *ngIf="cView == 'affected_assets'">
                        <div class="flex flex-row items-center">
                            <div class="pl-8 text-xl font-semibold tracking-tight">Evidence </div>
                            <mat-icon class="ml-2 text-blue-500 cursor-pointer dark:text-blue-400 hover:text-blue-600"
                                matTooltip="Download as CSV" [svgIcon]="'mat_solid:download_for_offline'"
                                (click)="apiDownload()"></mat-icon>
                            <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                            </button>
                        </div>
                        <mat-divider class="my-2"></mat-divider>
                        <div class="mini-form-field w-1/2 ml-auto" *ngIf="selectedvalue === '*'">
                            <mat-form-field class="w-full">
                                <mat-label>Filter by Asset Status</mat-label>
                                <mat-select placeholder="Select filter" [(value)]="asset_online_status"
                                    (selectionChange)="handlesStatusFilter($event.value)">
                                    <mat-option value="">All</mat-option>
                                    <mat-option value="online">Online</mat-option>
                                    <mat-option value="offline">Offline</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="!this.epssCondition">
                            <div *ngFor="let item of eppscore"
                                class="flex p-4 rounded-lg dark:bg-gray-800 dark:text-blue-100" role="alert">

                                <span class="sr-only">Info</span>
                                <div>
                                    <div class="flex flex-row items-center gap-2">
                                        <svg class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span class="font-bold text-md">EPSS Categorization</span>
                                        <span
                                            class="border bg-blue-700 text-sm rounded-lg px-1.5 py-0.5 text-white cursor-pointer ml-4"
                                            *ngIf="eppscoreevidence" (click)="getData()"> Show All </span>

                                    </div>
                                    <ul class="mt-1.5 list-disc list-inside grid grid-cols-4 gap-4 mt-4">
                                        <li class="flex flex-row items-center bg-card ring-1 rounded p-1"
                                            (click)="onScoreClick('>= 0.95')">
                                            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-critical">>=
                                                0.95</span>
                                            <span class="spacer"></span>
                                            <span
                                                class="border bg-blue-700 rounded-lg text-sm px-1.5 py-0.5 text-white cursor-pointer cs-critical-value">{{item.epss_score_grater_than_95}}</span>
                                        </li>
                                        <li class="flex flex-row items-center bg-card ring-1 rounded p-1"
                                            (click)="onScoreClick('0.90-0.95')">
                                            <span
                                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-medium">0.90-0.95</span>
                                            <span class="spacer"></span>
                                            <span
                                                class="border bg-blue-700 rounded-lg text-sm px-1.5 py-0.5 text-white cursor-pointer cs-medium-value">{{item.epss_score_between_95_90}}</span>
                                        </li>
                                        <li class="flex flex-row items-center bg-card ring-1 rounded p-1"
                                            (click)="onScoreClick('0.85-0.90')">
                                            <span
                                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-low">0.85-0.90</span>
                                            <span class="spacer"></span>
                                            <span
                                                class="border bg-blue-700 rounded-lg text-sm px-1.5 py-0.5 text-white cursor-pointer cs-low-value">{{item.epss_score_between_90_85}}</span>
                                        </li>
                                        <li class="flex flex-row items-center bg-card ring-1 rounded p-1"
                                            (click)="onScoreClick('0.00-0.85')">
                                            <span
                                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-info">0.00-0.85</span>
                                            <span class="spacer"></span>
                                            <span
                                                class="border bg-blue-700 rounded-lg text-sm px-1.5 py-0.5 text-white cursor-pointer cs-info-value">{{item.epss_score_less_than_85}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>

                        <mat-divider class="my-2"></mat-divider>
                        <div class="flex flex-row items-start gap-4 pl-8">
                            <div class="flex flex-col w-full h-full py-6 pl-0">
                                <div class="w-full">
                                    <ol *ngIf="asset_list.length; else noActivity">
                                        <ng-container
                                            *ngFor="let activity of asset_list; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                            <li class="relative flex py-2">
                                                <ng-container
                                                    *ngIf="!last && isSameDay(activity.last_vul_scan_time, asset_list[i + 1].last_vul_scan_time)">
                                                    <div
                                                        class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600">
                                                    </div>
                                                </ng-container>

                                                <div class="relative flex flex-auto">
                                                    <ng-container>
                                                        <div
                                                            class="flex items-center justify-center w-10 h-10 mr-4 bg-gray-400 rounded-full shrink-0">
                                                            <mat-icon class="text-white icon-size-5"
                                                                [svgIcon]="'heroicons_solid:star'">
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                    <div class="flex flex-col items-start flex-auto text-sm">
                                                        <ng-container *ngIf="activity.ip">
                                                            <div class="flex flex-row items-center gap-2 w-full">
                                                                <img ngSrc="assets/images/on.png" alt="Online"
                                                                    class="w-8" *ngIf="activity.online_status"
                                                                    height="512" width="512">
                                                                <img ngSrc="assets/images/off.png" alt="Offline"
                                                                    class="w-8" *ngIf="!activity.online_status"
                                                                    height="512" width="512">
                                                                <span
                                                                    class="text-base font-semibold">{{activity.host_name}}({{activity.ip}})</span>
                                                                <div matTooltip="Copy">
                                                                    <mat-icon color="primary"
                                                                        class="icon-size-4 cursor-pointer"
                                                                        [svgIcon]="'content_copy'"
                                                                        (click)="cs.copyClip(activity.host_name);">
                                                                    </mat-icon>
                                                                </div>
                                                            </div>

                                                        </ng-container>
                                                        <div class="flex flex-row items-center gap-2 text-secondary">
                                                            <span>{{activity.last_vul_scan_time |
                                                                utcToLocaleRDate}}</span>
                                                        </div>
                                                        <table class="mt-2 table-auto">
                                                            <tbody class="text-sm font-light ">
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Product Name</td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        <i
                                                                            class="pr-2 text-green-400 fas fa-laptop-code"></i>
                                                                        {{activity.name}}
                                                                    </td>
                                                                </tr>
                                                                <ng-container
                                                                    *ngIf="activity.version_install_date && activity.version_install_date.length > 1">
                                                                    <tr class="border-b">
                                                                        <td class="px-4 py-1 text-left">Version &
                                                                            Installed
                                                                            Date
                                                                        </td>
                                                                        <td class="px-4 py-1 font-light text-left">
                                                                            <table class="border border-collapse">
                                                                                <tr>
                                                                                    <th class="text-center border">
                                                                                        Version
                                                                                    </th>
                                                                                    <th class="text-center border">Date
                                                                                    </th>
                                                                                </tr>
                                                                                <ng-container
                                                                                    *ngFor="let versionEntry of activity.version_install_date; let j = index">
                                                                                    <tr>
                                                                                        <td class="text-center border">
                                                                                            {{
                                                                                            versionEntry.version === '0'
                                                                                            ?
                                                                                            '-' :
                                                                                            versionEntry.version }}</td>
                                                                                        <td class="text-center border">
                                                                                            {{
                                                                                            versionEntry.date === '0' ?
                                                                                            '-'
                                                                                            :
                                                                                            versionEntry.date }}</td>
                                                                                    </tr>
                                                                                </ng-container>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="activity.version_install_date && activity.version_install_date.length === 1">
                                                                    <tr class="border-b">
                                                                        <td class="px-4 py-1 text-left">Version</td>
                                                                        <td class="px-4 py-1 font-light text-left">
                                                                            {{
                                                                            activity.version_install_date[0].version ===
                                                                            '0'
                                                                            ?
                                                                            '-' :
                                                                            activity.version_install_date[0].version }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="border-b">
                                                                        <td class="px-4 py-1 text-left">Installed Date
                                                                        </td>
                                                                        <td class="px-4 py-1 font-light text-left">
                                                                            {{
                                                                            activity.version_install_date[0].date ===
                                                                            '0' ?
                                                                            '-'
                                                                            :
                                                                            activity.version_install_date[0].date }}
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>

                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Last Logon Username
                                                                    </td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        <i class="pr-2 text-green-400 fas fa-user"></i>
                                                                        {{activity.logged_in_user || '-'}}
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Uninstall Path</td>
                                                                    <td
                                                                        class="px-4 py-1 font-light text-left break-all">
                                                                        <i
                                                                            class="pr-2 text-green-400 fas fa-microchip"></i>
                                                                        {{activity.uninstall_string | cleanString }}
                                                                    </td>
                                                                </tr>

                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">EPSS Score</td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        <i
                                                                            class="pr-2 text-green-400 fas fa-chart-bar"></i>
                                                                        {{activity.epss_score || '-'}}
                                                                    </td>
                                                                </tr>

                                                                <tr class="border-b" *ngIf="cs.currentScope === '*'">
                                                                    <td class="px-4 py-1 text-left">Company Name</td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        <i
                                                                            class="pr-2 text-green-400 fas fa-building"></i>
                                                                        {{activity.company_name}}
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Total
                                                                        Vulnerabilities</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span
                                                                            class="cs-critical text-xs rounded px-2 py-0.5">{{activity.total_problems}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Critical</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span
                                                                            class="cs-critical text-xs rounded px-2 py-0.5">{{activity.critical_problems}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">High</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span
                                                                            class="cs-high text-xs rounded px-2 py-0.5">{{activity.high_problems}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Medium</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span
                                                                            class="cs-medium text-xs rounded px-2 py-0.5">{{activity.medium_problems}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">Low</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span
                                                                            class="cs-low text-xs rounded px-2 py-0.5">{{activity.low_problems}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b" *ngIf="activity.extraContent">
                                                                    <td class="px-4 py-1 text-left">Path</td>
                                                                    <td class="px-4 py-1 font-light text-left break-all">
                                                                        {{ activity.extraContent }}
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b"
                                                                    *ngIf="activity.unconfirmed !== null && activity.unconfirmed !== undefined  && activity.unconfirmed !== ''">
                                                                    <td class="px-4 py-1 text-left">Confirmed</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span *ngIf="activity.unconfirmed === true"
                                                                            class="bg-red-200 text-red-800 rounded px-2.5 py-0.5 dark:bg-red-600 dark:text-red-50">No
                                                                        </span>
                                                                        <span *ngIf="activity.unconfirmed === false"
                                                                            class="bg-green-200 text-green-800 rounded px-2.5 py-0.5 dark:bg-green-600 dark:text-green-50">Yes
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <mat-divider class="my-2"></mat-divider>
                                                </div>
                                            </li>
                                        </ng-container>
                                        <li class="relative flex py-4">
                                            <div
                                                class="flex items-center justify-center mx-auto rounded-full shadow-lg bg-primary">
                                                <button class="flex-0" mat-flat-button [color]="'primary'" type="button"
                                                    appPreventMultiClick (click)="goToPreviousStep()">
                                                    <span class="inline-flex items-center">
                                                        <mat-icon class="mr-2"
                                                            [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
                                                        <span class="mr-1">Prev</span>
                                                    </span>
                                                </button>
                                                <div
                                                    class="flex items-center justify-center mx-2.5 font-medium leading-5 text-on-primary">
                                                    <span *ngIf="timelineCount >0">{{currentStep + 1}}</span>
                                                    <span *ngIf="timelineCount ===0">0</span>
                                                    <span class="mx-0.5 text-hint">/</span>
                                                    <span>{{totalSteps}}</span>
                                                </div>
                                                <button class="flex-0" mat-flat-button [color]="'primary'" type="button"
                                                    appPreventMultiClick (click)="goToNextStep()">
                                                    <span class="inline-flex items-center">
                                                        <span class="ml-1">Next</span>
                                                        <mat-icon class="ml-2"
                                                            [svgIcon]="'heroicons_outline:arrow-narrow-right'"></mat-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </li>
                                    </ol>
                                    <ng-template #noActivity>
                                        <div class="w-full">
                                            <div class="flex flex-col items-center justify-center gap-y-2">
                                                <img src="assets/images/timeline.png" alt="" class="w-20" height="512"
                                                    width="512">
                                                <div> No data available!</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="viewchecking || linuxremeviewcheck">
                    <div class="w-auto rounded-2xl" *ngIf="cView == 'affected_assets'">
                        <div class="flex flex-row items-center">
                            <div class="pl-8 text-xl font-semibold tracking-tight">Evidence </div>
                            <mat-icon class="ml-2 text-blue-500 cursor-pointer dark:text-blue-400 hover:text-blue-600"
                                matTooltip="Download as CSV" [svgIcon]="'mat_solid:download_for_offline'"
                                (click)="apiDownloadlinuxrem()"></mat-icon>
                            <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                            </button>
                        </div>
                        <mat-divider class="my-2"></mat-divider>
                        <div class="mini-form-field w-1/2 ml-auto">
                            <mat-form-field class="w-full">
                                <mat-label>Filter by Asset Status</mat-label>
                                <mat-select placeholder="Select filter" [(value)]="asset_online_status"
                                    (selectionChange)="handlesStatusFilter($event.value)">
                                    <mat-option value="">All</mat-option>
                                    <mat-option value="online">Online</mat-option>
                                    <mat-option value="offline">Offline</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex flex-row items-start gap-4 pl-8">
                            <div class="flex flex-col w-full h-full py-6 pl-0">
                                <div class="w-full">
                                    <ol *ngIf="asset_list.length; else noActivity">
                                        <ng-container
                                            *ngFor="let activity of asset_list; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                            <li class="relative flex py-2">
                                                <div class="relative flex flex-auto">
                                                    <ng-container>
                                                        <div
                                                            class="flex items-center justify-center w-10 h-10 mr-4 bg-gray-400 rounded-full shrink-0">
                                                            <mat-icon class="text-white icon-size-5"
                                                                [svgIcon]="'heroicons_solid:star'">
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                    <div class="flex flex-col items-start flex-auto text-sm">
                                                        <ng-container *ngIf="activity.ip">
                                                            <div class="flex flex-row items-center gap-2 w-full">
                                                                <img ngSrc="assets/images/on.png" alt="Online"
                                                                    class="w-8" *ngIf="activity.online_status"
                                                                    height="512" width="512">
                                                                <img ngSrc="assets/images/off.png" alt="Offline"
                                                                    class="w-8" *ngIf="!activity.online_status"
                                                                    height="512" width="512">
                                                                <span
                                                                    class="text-base font-semibold">{{activity.host_name}}({{activity.ip}})</span>
                                                                <div matTooltip="Copy">
                                                                    <mat-icon color="primary"
                                                                        class="icon-size-4 cursor-pointer"
                                                                        [svgIcon]="'content_copy'"
                                                                        (click)="cs.copyClip(activity.host_name);">
                                                                    </mat-icon>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <table class="mt-2 table-auto">
                                                            <tbody class="text-sm font-light ">
                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">OS Name</td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        {{activity.os_name}}
                                                                    </td>
                                                                </tr>

                                                                <tr class="border-b">
                                                                    <td class="px-4 py-1 text-left">OS Version</td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        {{activity.os_version}}
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b" *ngIf="cs.currentScope === '*'">
                                                                    <td class="px-4 py-1 text-left">Company Name</td>
                                                                    <td class="px-4 py-1 font-light text-left">
                                                                        <i
                                                                            class="pr-2 text-green-400 fas fa-building"></i>
                                                                        {{activity.company_name}}
                                                                    </td>
                                                                </tr>
                                                                <tr class="border-b"
                                                                    *ngIf="activity.is_confirmed !== null && activity.is_confirmed !== undefined  && activity.is_confirmed !== ''">
                                                                    <td class="px-4 py-1 text-left">Confirmed</td>
                                                                    <td class="px-4 py-1 text-left">
                                                                        <span *ngIf="activity.is_confirmed === true"
                                                                            class="bg-red-200 text-red-800 rounded px-2.5 py-0.5 dark:bg-red-600 dark:text-red-50">No
                                                                        </span>
                                                                        <span *ngIf="activity.is_confirmed === false"
                                                                            class="bg-green-200 text-green-800 rounded px-2.5 py-0.5 dark:bg-green-600 dark:text-green-50">Yes
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <mat-divider class="my-2"></mat-divider>
                                                </div>
                                            </li>
                                        </ng-container>
                                        <li class="relative flex py-4">
                                            <div
                                                class="flex items-center justify-center mx-auto rounded-full shadow-lg bg-primary">
                                                <button class="flex-0" mat-flat-button [color]="'primary'" type="button"
                                                    appPreventMultiClick (click)="goToPreviousStep()">
                                                    <span class="inline-flex items-center">
                                                        <mat-icon class="mr-2"
                                                            [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
                                                        <span class="mr-1">Prev</span>
                                                    </span>
                                                </button>
                                                <div
                                                    class="flex items-center justify-center mx-2.5 font-medium leading-5 text-on-primary">
                                                    <span *ngIf="timelineCount >0">{{currentStep + 1}}</span>
                                                    <span *ngIf="timelineCount ===0">0</span>
                                                    <span class="mx-0.5 text-hint">/</span>
                                                    <span>{{totalSteps}}</span>
                                                </div>
                                                <button class="flex-0" mat-flat-button [color]="'primary'" type="button"
                                                    appPreventMultiClick (click)="goToNextStep()">
                                                    <span class="inline-flex items-center">
                                                        <span class="ml-1">Next</span>
                                                        <mat-icon class="ml-2"
                                                            [svgIcon]="'heroicons_outline:arrow-narrow-right'"></mat-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </li>
                                    </ol>
                                    <ng-template #noActivity>
                                        <div class="w-full">
                                            <div class="flex flex-col items-center justify-center gap-y-2">
                                                <img src="assets/images/timeline.png" alt="" class="w-20" height="512"
                                                    width="512">
                                                <div> No data available!</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="cView === 'velocity_assets'">
                    <div class="flex flex-row items-center">
                        <div class="ml-3 text-lg font-semibold tracking-tight" *ngIf="!isCompanyName">Product Name:
                            {{currentRemediation.product}}
                        </div>
                        <div class="ml-3 text-lg font-semibold tracking-tight" *ngIf="isCompanyName">Company Name:
                            {{currentRemediation.name}}
                        </div>
                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="w-auto rounded-2xl">
                        <stable [sTableOptions]="assetsTableOptions" class="w-full"
                            *ngIf="assetsTableOptions.tableOptions">
                        </stable>
                    </div>
                </ng-container>
            </div>
        </mat-drawer>
        <mat-drawer-content class="h-full">
            <div>
                <fuse-alert class="w-full p-8" [appearance]="'border'" [type]="'success'" *ngIf="isNoProblem">
                    <span fuseAlertTitle>All Good!</span>
                    No issues has been identified, or it has not been scanned yet.
                </fuse-alert>
                <div class="flex flex-col w-full gap-2" fuseScrollbar>
                    <div class="flex flex-wrap items-center justify-start gap-2 pt-4 mx-3">

                        <div *ngIf="!asset_id" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                          hover:text-white cursor-pointer" (click)="tagfilter = {};remclick();epssCondition = ''"
                            [ngClass]="{'bg-primary text-white': rView === 'rp'}">Remediation Plan</div>



                        <div *ngIf="asset_id && cAsset && 
                        (!cAsset.platform || cAsset.platform.toLowerCase() !== 'rhel') && 
                        (!cAsset.os_name || cAsset.os_name.toLowerCase().indexOf('red hat') === -1)" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                            hover:text-white cursor-pointer" (click)="tagfilter = {};remclick();epssCondition = ''"
                            [ngClass]="{'bg-primary text-white': rView === 'rp'}">Remediation Plan</div>


                        <div *ngIf="!asset_id" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                            hover:text-white cursor-pointer"
                            (click)="rView = 'sw_remediations_view'; softwareviewsw();currentRecordType = '';epssCondition = ''"
                            [ngClass]="{'bg-primary text-white': rView === 'sw_remediations_view'}">Linux Remediation
                            Plan
                        </div>

                        <div *ngIf="asset_id && cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;"
                            class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                        hover:text-white cursor-pointer" (click)="rView = 'sw_remediations_view'; softwareviewsw();epssCondition = ''"
                            [ngClass]="{'bg-primary text-white': rView === 'sw_remediations_view'}">Linux Remediation
                            Plan
                        </div>


                        <div *ngIf="!asset_id" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                            hover:text-white cursor-pointer" (click)="remrecords1()"
                            [ngClass]="{'bg-primary text-white': rView === 'rr'}">Remediated Records
                        </div>


                        <div *ngIf="asset_id && cAsset && 
                        (!cAsset.platform || cAsset.platform.toLowerCase() !== 'rhel') && 
                        (!cAsset.os_name || cAsset.os_name.toLowerCase().indexOf('red hat') === -1)" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                            hover:text-white cursor-pointer" (click)="remrecordsassetview()"
                            [ngClass]="{'bg-primary text-white': rView === 'rm'}">Remediated Records
                        </div>

                        <!-- <div  *ngIf="!asset_id" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                            hover:text-white cursor-pointer"
                            (click)="rView = 'sw_remediated_view'; softwareremediatedview()"
                            [ngClass]="{'bg-primary text-white': rView === 'sw_remediated_view'}">Linux Remediated
                        </div> -->

                        <div *ngIf="asset_id && cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;"
                            class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                        hover:text-white cursor-pointer"
                            (click)="rView = 'sw_remediated_view'; softwareremediatedview();"
                            [ngClass]="{'bg-primary text-white': rView === 'sw_remediated_view'}">Linux Remediated
                        </div>

                        <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                         hover:text-white cursor-pointer" *ngIf="!asset_id" (click)="pendingremview()"
                            [ngClass]="{'bg-primary text-white': rView === 'rv'}">Pending Remediation Velocity
                        </div>
                        <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                         hover:text-white cursor-pointer" *ngIf="!asset_id" (click)="remvelocity()"
                            [ngClass]="{'bg-primary text-white': rView === 'remv'}">Remediated Velocity
                        </div>

                        <div *ngIf="!asset_id">
                            <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                            hover:text-white cursor-pointer" (click)="suppressrecord()"
                                *ngIf="asset_type !== 'external_asset'"
                                [ngClass]="{'bg-primary text-white': rView === 'suppress_records'}">Suppressed Records
                            </div>
                        </div>

                        <div *ngIf="asset_id && cAsset && 
                        (!cAsset.platform || cAsset.platform.toLowerCase() !== 'rhel') && 
                        (!cAsset.os_name || cAsset.os_name.toLowerCase().indexOf('red hat') === -1)">
                            <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                        hover:text-white cursor-pointer" (click)="suppressrecord()"
                                *ngIf="asset_type !== 'external_asset'"
                                [ngClass]="{'bg-primary text-white': rView === 'suppress_records'}">Suppressed Records
                            </div>
                        </div>

                        <div class="flex gap-2 w-90 ml-auto" *ngIf="rView === 'sw_remediations_view' && !asset_id">
                            <div class="flex gap-4 w-1/2" *ngIf="linuxosOptions.length > 0">
                                <mat-form-field class="relative w-full z-10">
                                    <mat-select [(ngModel)]="selectedOslinux" [multiple]="true"
                                        class="mat-small w-full round-select" placeholder="Select linux OS"
                                        (selectionChange)="getlinuxosname($event.value)">
                                        <mat-option [value]="'*'">
                                            All OS Filters
                                        </mat-option>
                                        <mat-option *ngFor="let os of linuxosOptions" [value]="os" [matTooltip]="os"
                                            [matTooltipPosition]="'left'" [disabled]="selectedOslinux && selectedOslinux.includes('*')">
                                            <span>{{ os }}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex gap-2 w-90 ml-auto" *ngIf="rView === 'rp' && !asset_id">
                            <div class="flex gap-4 w-1/2" *ngIf="osOptions.length > 0">
                                <mat-form-field class="relative w-full z-10">
                                    <mat-select [(ngModel)]="selectedOsName" [multiple]="true"
                                        class="mat-small w-full round-select" placeholder="Select OS"
                                        (selectionChange)="getosname($event.value)">
                                        <mat-option [value]="'*'">
                                            All OS Filters
                                        </mat-option>
                                        <mat-option *ngFor="let os of osOptions" [value]="os" [matTooltip]="os"
                                            [matTooltipPosition]="'left'" [disabled]="selectedOsName && selectedOsName.includes('*')">
                                            <span>{{ os }}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="flex gap-4 w-1/2" *ngIf="cs.currentScope === '*'">
                                <mat-form-field class="relative w-full z-10">
                                    <mat-select #companySelect id="sSearchCompanyincludeInput" [multiple]="true"
                                        class="mat-small w-full round-select" [(ngModel)]="includeCompany"
                                        name="includeCompany" placeholder="Select include Company"
                                        (selectionChange)="getincludeCompany($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="companyFilterCtrl"
                                                [placeholderLabel]="searchTxt"
                                                [noEntriesFoundLabel]="'No matching company found'"
                                                [searching]="searching" [enableClearOnEscapePressed]="true">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="'*'">
                                            All Companies
                                        </mat-option>
                                        <mat-option [matTooltip]="company.name" [matTooltipPosition]="'left'"
                                            *ngFor="let company of cs.allComp | search: companyFilterCtrl.value"
                                            [id]="company.name" [value]="company.id"
                                            [disabled]="includeCompany && includeCompany.includes('*')">
                                            <span>{{ company.name }}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Mini Filter Options -->
                            <div class="flex gap-4 w-1/2" *ngIf="cs.currentScope !== '*' && !asset_id">
                                <mat-form-field class="w-full">
                                    <mat-select class="w-full" placeholder="Select filter" [(value)]="selectedvalue"
                                        (selectionChange)="getfilterCompany($event.value)">
                                        <mat-option value="*">All</mat-option>
                                        <mat-option value="online">Online</mat-option>
                                        <mat-option value="offline">Offline</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="rView === 'rp'">
                        <div class="flex p-4 mx-4 text-sm text-blue-900 bg-blue-100 rounded-lg dark:bg-gray-800 dark:text-blue-100"
                            role="alert" [ngClass]="{'w-[78vw] mr-0': !asset_id}">
                            <svg class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <span class="sr-only">Info</span>
                            <div>
                                <span class="font-bold text-md">EPSS Categorization</span>
                                <span class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer ml-4"
                                    *ngIf="epssCondition != ''" (click)="removeEpssFilter();"> Show
                                    All </span>
                                <ul class="mt-1.5 list-disc list-inside grid grid-cols-2 gap-4">
                                    <li class="flex flex-row items-center ">
                                        <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-critical">>=
                                            0.95</span>
                                        <span>Remediate within 3 days, 95% probability of exploitation</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss('', 0.95)"> View </span>
                                    </li>
                                    <li class="flex flex-row items-center ">
                                        <span
                                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-medium">0.90-0.95</span>
                                        <span>Remediate within 15 days, 90% probability of exploitation</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss(.90, 0.95)"> View </span>
                                    </li>
                                    <li class="flex flex-row items-center ">
                                        <span
                                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-low">0.85-0.90</span>
                                        <span>Remediate within 30 days, 85% probability of exploitation</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss(.85, .90)"> View </span>
                                    </li>
                                    <li class="flex flex-row items-center ">
                                        <span
                                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-info">0.00-0.85</span>
                                        <span>Remediate at your own schedule</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss(0.0, .85)"> View </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <s-table [sTableOptions]="remeTableOptions"
                            *ngIf="remeTableOptions && remeTableOptions.tableOptions && showTable" class="w-full"
                            (apiDataDownload)="apiDataDownload($event)" (filterCallback)="tagFilterCall($event)"
                            (refreshCallback)="getRemediation()" (actionCallback)="actionCall($event)"
                            (globalActionCallback)="globalCall($event)" (hyperlinkCallback)="linkClick($event)">
                        </s-table>
                    </div>

                    <div class="mx-4" *ngIf="rView === 'sw_remediations_view'">
                        <div class="flex p-4 mx-4 text-sm text-blue-900 bg-blue-100 rounded-lg dark:bg-gray-800 dark:text-blue-100"
                            role="alert" [ngClass]="{'w-[78vw] mr-0': !asset_id}">
                            <svg class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <span class="sr-only">Info</span>
                            <div>
                                <span class="font-bold text-md">EPSS Categorization</span>
                                <span class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer ml-4"
                                    *ngIf="epssCondition != ''" (click)="removeEpssFilter();"> Show
                                    All </span>
                                <ul class="mt-1.5 list-disc list-inside grid grid-cols-2 gap-4">
                                    <li class="flex flex-row items-center ">
                                        <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-critical">>=
                                            0.95</span>
                                        <span>Remediate within 3 days, 95% probability of exploitation</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss('', 0.95)"> View </span>
                                    </li>
                                    <li class="flex flex-row items-center ">
                                        <span
                                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-medium">0.90-0.95</span>
                                        <span>Remediate within 15 days, 90% probability of exploitation</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss(.90, 0.95)"> View </span>
                                    </li>
                                    <li class="flex flex-row items-center ">
                                        <span
                                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-low">0.85-0.90</span>
                                        <span>Remediate within 30 days, 85% probability of exploitation</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss(.85, .90)"> View </span>
                                    </li>
                                    <li class="flex flex-row items-center ">
                                        <span
                                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded cs-info">0.00-0.85</span>
                                        <span>Remediate at your own schedule</span>
                                        <span class="spacer"></span>
                                        <span
                                            class="border bg-blue-700 rounded-lg px-1.5 py-0.5 text-white cursor-pointer"
                                            (click)="filterEpss(0.0, .85)"> View </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div *ngIf=!asset_id>
                            <!-- <stable aria-label="linuxremediation Table" role="table"
                                *ngIf="SoftwareremediationsviewTableOptions && SoftwareremediationsviewTableOptions.tableOptions"
                                [sTableOptions]="SoftwareremediationsviewTableOptions" (filterCallback)="tagFilterCalllin($event)"
                                (refreshCallback)="softwareviewsw()" (actionCallback)="actionCall($event)"
                                (globalActionCallback)="globalCall($event)" (hyperlinkCallback)="linuxremedationclick($event)">
                            </stable> -->

                            <s-table [sTableOptions]="SoftwareremediationsviewTableOptions"
                            *ngIf="SoftwareremediationsviewTableOptions && SoftwareremediationsviewTableOptions.tableOptions && showTable" class="w-full"
                             (filterCallback)="tagFilterCalllin($event)" (apiDataDownload)="apiDataDownloadlinux($event)" 
                            (refreshCallback)="softwareviewsw()" (actionCallback)="actionCalllinuxrem($event)"
                            (globalActionCallback)="globalCalllinuxrem($event)" (hyperlinkCallback)="linuxremedationclick($event)">
                        </s-table>

                        </div>

                        <div
                            *ngIf="asset_id && cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;">
                            <!-- <stable aria-label="linuxremediationasset Table" role="table"
                                *ngIf="SoftwareremediationsassetviewTableOptions && SoftwareremediationsassetviewTableOptions.tableOptions"
                                [sTableOptions]="SoftwareremediationsassetviewTableOptions"
                                (refreshCallback)="Softwareremediationassetview()">
                            </stable> -->

                            <s-table [sTableOptions]="SoftwareremediationsviewTableOptions"
                            *ngIf="SoftwareremediationsviewTableOptions && SoftwareremediationsviewTableOptions.tableOptions && showTable" class="w-full"
                            (refreshCallback)="softwareviewsw()" >
                        </s-table>
                        </div>
                    </div>

                    <div *ngIf="rView === 'rv' && !asset_id">
                        <ng-container>
                            <div class="flex flex-col items-end w-full">
                                <mat-form-field appearance="fill" class="mini-form-field w-60"
                                    *ngIf="!cs.currentScope || !cs.currentScope.id">
                                    <mat-label>Filter by Severity</mat-label>
                                    <mat-select name="severityPending" [(ngModel)]="severity_filter_pending"
                                        (selectionChange)="filterChangePending($event.value)">
                                        <ng-container *ngFor="let status of severityFilter">
                                            <mat-option [value]="status.value"> {{status.name}} </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <s-table [sTableOptions]="pendingRemediationTableOptions"
                                *ngIf="pendingRemediationTableOptions && pendingRemediationTableOptions.tableOptions && (!cs.currentScope || !cs.currentScope.id)"
                                class="w-full" (hyperlinkCallback)="pendingRemlinkClick($event)">
                            </s-table>
                            <div class="flex flex-col items-end w-full mt-4">
                                <mat-form-field appearance="fill" class="mini-form-field w-60">
                                    <mat-label>Filter by Severity</mat-label>
                                    <mat-select name="severitySoftware" [(ngModel)]="severity_filter_software"
                                        (selectionChange)="filterChangeSoftware($event.value)">
                                        <ng-container *ngFor="let status of severityFilter">
                                            <mat-option [value]="status.value"> {{status.name}} </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <s-table [sTableOptions]="pendingRemediationSoftwareTableOptions"
                                *ngIf="pendingRemediationSoftwareTableOptions && pendingRemediationSoftwareTableOptions.tableOptions"
                                class="w-full" (hyperlinkCallback)="linkClick($event)">
                            </s-table>
                        </ng-container>
                    </div>
                    <div *ngIf="rView === 'remv' && !asset_id">
                        <ng-container>
                            <s-table [sTableOptions]="remediatedTableOptions"
                                *ngIf="remediatedTableOptions && remediatedTableOptions.tableOptions && (!cs.currentScope || !cs.currentScope.id)"
                                class="w-full" (hyperlinkCallback)="remediatedpendinglinkClick($event)">
                            </s-table>
                            <s-table [sTableOptions]="remediatedSoftwareTableOptions"
                                *ngIf="remediatedSoftwareTableOptions && remediatedSoftwareTableOptions.tableOptions"
                                class="w-full" (hyperlinkCallback)="remediatedlinkClick($event)">
                            </s-table>
                        </ng-container>
                    </div>

                    <div *ngIf="rView === 'rr'">
                        <ng-container>
                            <div class="flex flex-col w-full gap-2" fuseScrollbar>
                                <div class="flex flex-wrap items-center justify-start gap-2 pt-4 mx-3 mb-2">

                                    <div *ngIf="!asset_id" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                                        hover:text-white cursor-pointer"
                                        (click)="remrecords(); currentRecordType = 'non-linux'"
                                        [ngClass]="{'bg-primary text-white': currentRecordType === 'non-linux'}">Non
                                        Linux Remediated Records
                                    </div>

                                    <div *ngIf="asset_id && cAsset && isNotRedHatPlatform(cAsset)" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                                        hover:text-white cursor-pointer"
                                        (click)="remrecords(); currentRecordType = 'non-linux'"
                                        [ngClass]="{'bg-primary text-white': currentRecordType === 'non-linux'}">Non
                                        Linux Remediated Records
                                    </div>

                                    <div *ngIf="!asset_id" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                                        hover:text-white cursor-pointer"
                                        (click)="currentRecordType = 'linux'; softwareremediatedview()"
                                        [ngClass]="{'bg-primary text-white': currentRecordType === 'linux'}">Linux
                                        Remediated Records
                                    </div>

                                    <div *ngIf="asset_id && cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;"
                                        class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400
                                        hover:text-white cursor-pointer"
                                        (click)="currentRecordType = 'linux';softwareremediatedview()"
                                        [ngClass]="{'bg-primary text-white': currentRecordType === 'linux'}">Linux
                                        Remediated Records
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="currentRecordType === 'non-linux' ">
                        <div class="mx-4">
                            <app-remediated-details *ngIf="!asset_id"
                                [mode]="cs.currentScope === '*' ? 'global' : 'company'"></app-remediated-details>
                            <!-- <app-remediated-details *ngIf="asset_id" [asset_name]="asset_name" [cAsset]="cAsset"
                                [mode]="'asset'"></app-remediated-details> -->
                        </div>
                    </div>

                    <div *ngIf="currentRecordType === 'linux'">
                        <div class="mx-4" *ngIf="!asset_id">
                            <stable aria-label="Softwareremediated Table" role="table"
                                *ngIf="SoftwareremediatedviewTableOptions && SoftwareremediatedviewTableOptions.tableOptions"
                                [sTableOptions]="SoftwareremediatedviewTableOptions"
                                (refreshCallback)="softwareremediatedview()"
                                (hyperlinkCallback)="linuxremedatedclick($event)">
                            </stable>
                        </div>
                        <!-- <div class="mx-4"
                            *ngIf="asset_id && cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;">
                            <stable aria-label="Softwareremediatedasset Table" role="table"
                                *ngIf="SoftwareremediatedassetviewTableOptions && SoftwareremediatedassetviewTableOptions.tableOptions"
                                [sTableOptions]="SoftwareremediatedassetviewTableOptions"
                                (refreshCallback)="Softwareremediatedassetview()">
                            </stable>
                        </div> -->
                    </div>

                    <div *ngIf="rView === 'rm'">
                        <div class="mx-4">
                            <!-- <app-remediated-details *ngIf="!asset_id"
                                [mode]="cs.currentScope === '*' ? 'global' : 'company'"></app-remediated-details> -->
                            <app-remediated-details *ngIf="asset_id" [asset_name]="asset_name" [cAsset]="cAsset"
                                [mode]="'asset'"></app-remediated-details>
                        </div>
                    </div>

                    <div *ngIf="rView === 'sw_remediated_view'">
                        <!-- <div class="mx-4" *ngIf="!asset_id">
                            <stable aria-label="Softwareremediated Table" role="table"
                                *ngIf="SoftwareremediatedviewTableOptions && SoftwareremediatedviewTableOptions.tableOptions"
                                [sTableOptions]="SoftwareremediatedviewTableOptions"
                                (refreshCallback)="softwareremediatedview()"
                                (hyperlinkCallback)="linuxremedatedclick($event)">
                            </stable>
                        </div> -->
                        <div class="mx-4"
                            *ngIf="asset_id && cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;">
                            <stable aria-label="Softwareremediatedasset Table" role="table"
                                *ngIf="SoftwareremediatedassetviewTableOptions && SoftwareremediatedassetviewTableOptions.tableOptions"
                                [sTableOptions]="SoftwareremediatedassetviewTableOptions"
                                (refreshCallback)="Softwareremediatedassetview()">
                            </stable>
                        </div>
                    </div>

                    <div *ngIf="rView === 'suppress_records'">
                        <div class="mx-4">
                            <app-suppression *ngIf="!asset_id" [filterKey]="'solution_id'"></app-suppression>
                            <app-suppression *ngIf="asset_id" [mode]="'asset'"
                                [filterKey]="'solution_id'"></app-suppression>
                        </div>
                    </div>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
<s-modal id="suppress_problemss2">
    <mat-card class="modal w-[600px]" *ngIf="suppressData">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Suppress Solutions</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('suppress_problemss2');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4" fuseScrollbar>
            <form class="px-8 py-4 example-form" #suppressForm="ngForm">
                <label>Reason: </label>
                <mat-radio-group class="flex flex-col mb-4 ml-2 reason-radio-group" aria-label="Reason" name="reasonId"
                    [(ngModel)]="suppressData.reasonId">
                    <mat-radio-button class="reason-radio-button" *ngFor="let reason of reasonsList; let i=index"
                        [value]="reason" color="primary" id="readson{{i}}">
                        {{reason}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="w-full mb-4" *ngIf="suppressData.reasonId === 'Other'">
                    <mat-label>Snooze/Suppress Reason</mat-label>
                    <textarea matInput placeholder="Reason" type="text" [(ngModel)]="suppressData.reason"
                        autocomplete="off" name="reason" [rows]="1" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="1"></textarea>
                </mat-form-field>
                <mat-form-field class="w-full mb-4"
                    *ngIf="uS._user_roles.indexOf('admin') >-1 || (uS._user_roles.indexOf('approver') > -1 && _bs.selfApproval().self_approval)">
                    <mat-label>Approval Process</mat-label>
                    <mat-select name="ProcessAproval" [(ngModel)]="suppressData.approve_type" required
                        placeholder="Choose Approval Process">
                        <mat-option value="self">Self Approve</mat-option>
                        <mat-option value="external">Other User</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container
                    *ngIf="suppressData.approve_type !=='self' && (uS._user_roles.indexOf('admin') > -1 || uS._user_roles.indexOf('approver') > -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Choose User Type</mat-label>
                        <mat-select name="UsersType" [(ngModel)]="suppressData.internal_type" required
                            placeholder="Choose user type">
                            <mat-option value="internal">Internal User</mat-option>
                            <mat-option value="external">External User</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressData.internal_type === 'internal'">
                        <mat-label>Send To Internal User</mat-label>
                        <mat-select [multiple]="internalUsers.length !== 0 ? true : false" name="internalUsers"
                            [(ngModel)]="suppressData.internal_users" required placeholder="Internal Users">
                            <mat-option disabled *ngIf="internalUsers.length === 0">
                                <span class="dark:text-white">No Internal Approver User present</span>
                            </mat-option>
                            <mat-option *ngFor="let user of internalUsers"
                                [value]="user.email">{{user.user_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressData.internal_type === 'external'">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text" [(ngModel)]="suppressData.external_user"
                            required autocomplete="off" name="external_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <ng-container
                    *ngIf="(uS._user_roles.indexOf('admin') === -1 && uS._user_roles.indexOf('approver') === -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text" [(ngModel)]="suppressData.external_user"
                            required autocomplete="off" name="external_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="w-full mb-4">
                    <mat-label>Suppress Comments</mat-label>
                    <textarea matInput placeholder="Suppress Comments" type="text"
                        [(ngModel)]="suppressData.suppress_comments" required autocomplete="off"
                        name="suppress_comments"></textarea>
                </mat-form-field>
                <mat-checkbox class="mb-4" id="permanent" name="permanent" color="primary"
                    [(ngModel)]="suppressData.is_permanent">Permanently Suppress</mat-checkbox>
                <div class="relative flex" *ngIf="!suppressData.is_permanent">
                    <!-- <mat-form-field class="flex-auto">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="picker1" (dateChange)="changeEvent($event)"
                            [(ngModel)]="suppressData.suppressed_on" [min]="fromDate" required autocomplete="off"
                            name="startDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field> -->
                    <mat-form-field class="flex-auto ml-2">
                        <mat-label>End date</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="suppressData.suppressed_till"
                            [min]="minEndDate" required name="endDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <mat-divider class="my-4"></mat-divider>
                <div class="flex items-center justify-end px-8 -mx-8 form-row">
                    <button type="submit" id="saveButton" [disabled]="!suppressForm.form.valid" class="mr-2"
                        mat-flat-button color="primary" appPreventMultiClick
                        (click)="suppressProblem(suppressData)">Save
                    </button>
                    <button id="closeButton" (click)="cancelSuppress()" mat-flat-button>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>
<s-modal id="remediation_script">
    <div class="w-[900px] h-[80vh] rounded-2xl bg-card p-4" fuseScrollbar>
        <div class="flex flex-row items-center">
            <div class="text-2xl font-semibold tracking-tight">Remediation Script</div>
            <button class="ml-auto" mat-icon-button (click)="modalService.close('remediation_script');">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>
        <fuse-alert [appearance]="'border'" [type]="'warning'" class="w-full p-2">
            <div class="text-md">
                ***************** * WARNING: USE THIS SCRIPT AT YOUR OWN RISK * ******************<br />
                This script is provided "as is" without any warranty, express or implied, and its use is
                entirely at your own risk. Before running this script, please read and understand the following
                precautions:
                <br />
                <span>
                    1. Backup: Ensure you have a complete backup of your data and system before running this
                    script. Unintended consequences may result in data loss.<br />
                    2. Testing: Test the script in a controlled environment before using it on production systems.
                    Identify
                    and resolve any issues before deployment.<br />
                    3. Security: Review the script for potential security risks. Only run scripts from trusted
                    sources to
                    avoid malicious code.<br />
                    If you are unsure or uncomfortable,
                    seek assistance from a qualified professional before proceeding.
                </span>
            </div>
        </fuse-alert>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="mt-3"
            *ngIf="currentFixRemediation && currentFixRemediation.length">
            <mat-tab *ngFor="let t of currentFixRemediation; let i=index;" [label]="t">
                <ng-template mat-tab-label>
                    <mat-icon class="mr-2">code</mat-icon>
                    {{t.os_type | uppercase}}
                </ng-template>
                <ng-template matTabContent>
                    <fuse-alert [appearance]="'border'" [type]="'warning'" class="w-full p-2 my-2"
                        *ngIf="currentRemediation.product === 'smb-protocols'">
                        <div class="text-md">
                            ***************** * WARNING: USE THIS SCRIPT AT YOUR OWN RISK * ******************<br />
                            <br>
                            <span>
                                1. The first command will disable SMB 1.0.<br />
                                2. When the second command is executed, SMB 2.0 and SMB 3.0 will be disabled. It has the
                                ability to prevent the Probe Agent and any other communication via this protocol.<br />
                            </span>
                        </div>
                    </fuse-alert>
                    <div
                        class="relative flex items-center justify-between p-2 px-4 py-2 mx-1 mt-2 mb-0 font-bold text-gray-200 bg-gray-800 gizmo:dark:bg-token-surface-primary text-medium rounded-t-md">
                        <span>Script</span>
                        <button class="flex items-center gap-2 ml-auto gizmo:ml-0" appPreventMultiClick
                            (click)="copyCode(i)">
                            <ng-container *ngIf="!t.isCopied">
                                <i class="fas fa-clipboard"></i>
                                <span>Copy code</span>
                            </ng-container>
                            <ng-container *ngIf="t.isCopied">
                                <i class="fas fa-check"></i>
                                <span>Copied!</span>
                            </ng-container>
                        </button>
                    </div>
                    <div class="flex-auto max-w-full p-1 prose-sm prose">
                        <textarea fuse-highlight lang="typescript">
                                {{t.script}}
                            </textarea>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</s-modal>
<s-modal id="view_asset_evidence">
    <div class="w-[650px] h-[80vh] rounded-2xl bg-card p-4" fuseScrollbar>
        <div class="flex flex-row items-center">
            <div class="pl-8 text-2xl font-semibold tracking-tight">Evidence</div>
            <button class="ml-auto" mat-icon-button (click)="modalService.close('view_asset_evidence');">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row items-start gap-4 p-6 pt-0">
            <div class="flex flex-col w-full h-full">
                <div class="w-full">
                    <ol *ngIf="assetEvidence.length; else noActivity">
                        <ng-container
                            *ngFor="let activity of assetEvidence; let i = index; let first = first; let last = last; trackBy: trackByFn">
                            <li class="relative flex py-2">
                                <div class="relative flex flex-auto">
                                    <div class="flex flex-col items-start flex-auto text-sm">
                                        <ng-container *ngIf="activity.ip">
                                            <div class="flex flex-row items-center justify-between w-full">
                                                <span
                                                    class="text-base font-semibold">{{activity.host_name}}({{activity.ip}})</span>
                                            </div>
                                        </ng-container>
                                        <div class="flex flex-row items-center gap-2 text-secondary">
                                            <span>{{activity.last_vul_scan_time |
                                                utcToLocaleRDate}}</span>
                                        </div>
                                        <table class="mt-2 table-auto">
                                            <tbody class="text-sm font-light ">
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Product Name</td>
                                                    <td class="px-4 py-1 font-light text-left">
                                                        <i class="pr-2 text-green-400 fas fa-laptop-code"></i>
                                                        {{activity.name}}
                                                    </td>
                                                </tr>
                                                <ng-container
                                                    *ngIf="activity.version_install_date && activity.version_install_date.length > 1">
                                                    <tr class="border-b">
                                                        <td class="px-4 py-1 text-left">Version & Installed
                                                            Date
                                                        </td>
                                                        <td class="px-4 py-1 font-light text-left">
                                                            <table class="border border-collapse">
                                                                <tr>
                                                                    <th class="text-center border">Version
                                                                    </th>
                                                                    <th class="text-center border">Date</th>
                                                                </tr>
                                                                <ng-container
                                                                    *ngFor="let versionEntry of activity.version_install_date; let j = index">
                                                                    <tr>
                                                                        <td class="text-center border">{{
                                                                            versionEntry.version === '0' ?
                                                                            '-' :
                                                                            versionEntry.version }}</td>
                                                                        <td class="text-center border">{{
                                                                            activity.version_install_date[0].date ===
                                                                            '0' ? '-'
                                                                            :
                                                                            versionEntry.date }}</td>
                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="activity.version_install_date && activity.version_install_date.length === 1">
                                                    <tr class="border-b">
                                                        <td class="px-4 py-1 text-left">Version</td>
                                                        <td class="px-4 py-1 font-light text-left">
                                                            {{
                                                            activity.version_install_date[0].version === '0' ?
                                                            '-' :
                                                            activity.version_install_date[0].version }}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="px-4 py-1 text-left">Installed Date</td>
                                                        <td class="px-4 py-1 font-light text-left">
                                                            {{
                                                            activity.version_install_date[0].date === '0' ? '-'
                                                            :
                                                            activity.version_install_date[0].date }}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Last Logon Username</td>
                                                    <td class="px-4 py-1 font-light text-left">
                                                        <i class="pr-2 text-green-400 fas fa-user"></i>
                                                        {{activity.logged_in_user || '-'}}
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Uninstall Path</td>
                                                    <td class="px-4 py-1 font-light text-left">
                                                        <i class="pr-2 text-green-400 fas fa-microchip"></i>
                                                        {{activity.uninstall_string || '-'}}
                                                    </td>
                                                </tr>

                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">EPSS Score</td>
                                                    <td class="px-4 py-1 font-light text-left">
                                                        <i class="pr-2 text-green-400 fas fa-chart-bar"></i>
                                                        {{activity.epss_score || '-'}}
                                                    </td>
                                                </tr>

                                                <tr class="border-b" *ngIf="cs.currentScope === '*'">
                                                    <td class="px-4 py-1 text-left">Company Name</td>
                                                    <td class="px-4 py-1 font-light text-left">
                                                        <i class="pr-2 text-green-400 fas fa-building"></i>
                                                        {{activity.company_id | companyIdToName}}
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Total
                                                        Vulnerabilities</td>
                                                    <td class="px-4 py-1 text-left">
                                                        <span
                                                            class="cs-critical text-xs rounded px-2 py-0.5">{{activity.total_problems}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Critical</td>
                                                    <td class="px-4 py-1 text-left">
                                                        <span
                                                            class="cs-critical text-xs rounded px-2 py-0.5">{{activity.critical_problems}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">High</td>
                                                    <td class="px-4 py-1 text-left">
                                                        <span
                                                            class="cs-high text-xs rounded px-2 py-0.5">{{activity.high_problems}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Medium</td>
                                                    <td class="px-4 py-1 text-left">
                                                        <span
                                                            class="cs-medium text-xs rounded px-2 py-0.5">{{activity.medium_problems}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Low</td>
                                                    <td class="px-4 py-1 text-left">
                                                        <span
                                                            class="cs-low text-xs rounded px-2 py-0.5">{{activity.low_problems}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="border-b">
                                                    <td class="px-4 py-1 text-left">Path</td>
                                                    <td class="px-4 py-1 font-light text-left">
                                                        <span *ngIf="activity.extraContent">{{activity.extraContent |
                                                            camelToHuman:true}}</span>
                                                        <span *ngIf="!activity.extraContent">-</span>
                                                    </td>
                                                </tr>
                                                <tr class="border-b"
                                                    *ngIf="activity.unconfirmed !== null && activity.unconfirmed !== undefined  && activity.unconfirmed !== ''">
                                                    <td class="px-4 py-1 text-left">Confirmed</td>
                                                    <td class="px-4 py-1 text-left">
                                                        <span *ngIf="activity.unconfirmed === true"
                                                            class="bg-red-200 text-red-800 rounded px-2.5 py-0.5 dark:bg-red-600 dark:text-red-50">No
                                                        </span>
                                                        <span *ngIf="activity.unconfirmed === false"
                                                            class="bg-green-200 text-green-800 rounded px-2.5 py-0.5 dark:bg-green-600 dark:text-green-50">Yes
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <mat-divider class="my-2"></mat-divider>
                                </div>
                            </li>
                        </ng-container>
                    </ol>
                    <ng-template #noActivity>
                        <div class="w-full mt-2">
                            <div class="flex flex-col items-center justify-center gap-y-2">
                                <img src="assets/images/timeline.png" alt="" class="w-20" height="512" width="512">
                                <div> No data available!</div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</s-modal>
<app-integration-action [inputActionData]="actionData" [productData]="productData" [integrationAsset]="integrationAsset"
    *ngIf="integrationActionService.integrationActionShow">
</app-integration-action>
<div *ngIf="isLoading" class="loader-class custom-class">
    <img src="/assets/images/loading.gif" alt="" class=" w-6 mb-2">
    <p class="relative">Loading... </p>
</div>


<s-modal id="patchingSettings">
    <div class="mx-auto w-[50vw] flex flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[75vh] max-w-md rounded-l-3xl bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4"
            fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">Patching</p>
            <img src="/assets/images/patching.svg" alt="Release Notes" width="200px" class="mt-10" />
        </div>
        x
        <div class="w-2/3 h-[75vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('patchingSettings')">
                <mat-icon>close</mat-icon>
            </button>
            <h2 class="mb-4 text-2xl font-bold">Patching</h2>
            <form class="flex flex-col m-2 px-2 overflow-hidden">
                <div class="flex flex-col mt-2">
                    <span class="font-semibold mb-2">Select Asset(s)</span>
                    <div class="flex flex-col border-t border-gray-300 py-2">
                        <mat-checkbox class="mb-2" value="" (change)="selectAllToggle($event)"
                            [checked]="isAllSelected()" [indeterminate]="hasSelected() && !isAllSelected()"
                            [color]="'primary'">
                            Select All
                        </mat-checkbox>

                        <div class="ml-4 flex flex-col" *ngFor="let type of assets; let i = index">
                            <div>
                                <mat-checkbox class="mb-2 flex items-center" [color]="'primary'"
                                    [(ngModel)]="type.selected" name="selectedAss{{ i }}">
                                    <div class="flex items-center">
                                        <img class="w-6 h-6 mr-2" [src]="type.image_path" alt="status_icon" />
                                        <span> {{ type.name }} </span>
                                    </div>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="patch_type === 'later'">
                        <div class="flex space-x-4">
                            <mat-form-field class="flex-1">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [min]="minDate" [max]="maxDate" [(ngModel)]="currentData.patch_date"
                                    [matDatepicker]="picker" name="date" />
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field class="flex-1">
                                <mat-label>Choose a time</mat-label>
                                <input matInput type="time" placeholder="HH:MM" x format="24"
                                    [(ngModel)]="currentData.patch_time" name="time" />
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
                <div class="flex items-center justify-end mt-6 border-t border-gray-300 mb-2 pt-4 bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('patchingSettings')">
                        Cancel
                    </button>

                    <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="
                !hasSelected() ||
                !(currentData.patch_time && currentData.patch_date)
              " type="button" appPreventMultiClick [matTooltip]="'Patch'" (click)="patchingFn()"
                        *ngIf="patch_type === 'later'">
                        Patch
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="!hasSelected()" type="button"
                        appPreventMultiClick [matTooltip]="'Patch'" (click)="patchingFn()" *ngIf="patch_type === 'now'">
                        Patch
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>


<s-modal id="registrySuppress">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Suppress Registry Problems</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="cancelSuppressRegistry();">
                <!-- (click)="modalService.close('registrySuppress') -->
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4" fuseScrollbar>
            <fuse-alert class="mx-4 my-1" [appearance]="'border'" [type]="'info'">
                <span fuseAlertTitle>Note: </span>
                <span>When we suppress a vulnerability in a product, all related vulnerabilities associated with that
                    product will be suppressed as well.</span>
            </fuse-alert>
            <form class="example-form px-8 py-4" #suppressRegForm="ngForm">
                <label>Reason: </label>
                <mat-radio-group class="reason-radio-group ml-2 mb-4 flex flex-col" aria-label="Reason"
                    name="registryreasonId" [(ngModel)]="suppressDataRegistry.reasonId">
                    <mat-radio-button class="reason-radio-button" *ngFor="let reason of reasonsList; let i=index"
                        [value]="reason" color="primary" id="readson{{i}}">
                        {{reason}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="w-full mb-4" *ngIf="suppressDataRegistry.reasonId === 'Other'">
                    <mat-label>Snooze/Suppress Reason</mat-label>
                    <textarea matInput placeholder="Reason" type="text" [(ngModel)]="suppressDataRegistry.reason"
                        autocomplete="off" name="registryreason" [rows]="1" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="1"></textarea>
                </mat-form-field>
                <mat-form-field class="w-full mb-4"
                    *ngIf="uS._user_roles.indexOf('admin') >-1  || (uS._user_roles.indexOf('approver') > -1 && _bs.selfApproval().self_approval)">
                    <mat-label>Approval Process</mat-label>
                    <mat-select name="registryProcessAproval" [(ngModel)]="suppressDataRegistry.approve_type" required
                        placeholder="Choose Approval Process">
                        <mat-option value="self">Self Approve</mat-option>
                        <mat-option value="external">Other User</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container
                    *ngIf="suppressDataRegistry.approve_type !=='self' && (uS._user_roles.indexOf('admin') > -1 || uS._user_roles.indexOf('approver') > -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Choose User Type</mat-label>
                        <mat-select name="registryUsersType" [(ngModel)]="suppressDataRegistry.internal_type" required
                            placeholder="Choose user type">
                            <mat-option value="internal">Internal User</mat-option>
                            <mat-option value="external">External User</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressDataRegistry.internal_type === 'internal'">
                        <mat-label>Send To Internal User</mat-label>
                        <mat-select [multiple]="internalUsers.length !== 0 ? true : false" name="registryinternalUsers"
                            [(ngModel)]="suppressDataRegistry.internal_users" required placeholder="Internal Users">
                            <mat-option disabled *ngIf="internalUsers.length === 0">
                                <span class="dark:text-white">No Internal Approver User present</span>
                            </mat-option>
                            <mat-option *ngFor="let user of internalUsers"
                                [value]="user.email">{{user.user_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressDataRegistry.internal_type === 'external'">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text"
                            [(ngModel)]="suppressDataRegistry.external_user" required autocomplete="off"
                            name="registryexternal_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <ng-container
                    *ngIf="(uS._user_roles.indexOf('admin') === -1 && uS._user_roles.indexOf('approver') === -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text"
                            [(ngModel)]="suppressDataRegistry.external_user" required autocomplete="off"
                            name="registryexternal_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="w-full mb-4">
                    <mat-label>Suppress Comments</mat-label>
                    <textarea matInput placeholder="Suppress Comments" type="text"
                        [(ngModel)]="suppressDataRegistry.suppress_comments" required autocomplete="off"
                        name="registry_suppress_comments"></textarea>
                </mat-form-field>
                <mat-checkbox class="mb-4" id="permanent" name="registry_permanent" color="primary"
                    [(ngModel)]="suppressDataRegistry.is_permanent">Permanently Suppress
                </mat-checkbox>
                <div class="flex relative" *ngIf="!suppressDataRegistry.is_permanent">
                    <!-- <mat-form-field class="flex-auto">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="picker1" (dateChange)="changeEvent($event)"
                            [(ngModel)]="suppressDataRegistry.suppressed_on" [min]="fromDate" required autocomplete="off"
                            name="startDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field> -->
                    <mat-form-field class="flex-auto ml-2">
                        <mat-label>End date</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="suppressDataRegistry.suppressed_till"
                            [min]="minEndDate" required name="registryendDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- [disabled]="!suppressForm.form.valid" -->
                <mat-divider class="my-4"></mat-divider>
                <div class="form-row flex items-center justify-end -mx-8 px-8">
                    <button type="submit" id="saveButton" [disabled]="!suppressRegForm.form.valid" class="mr-2"
                        mat-flat-button color="primary" type="button" appPreventMultiClick
                        (click)="suppressRegistry(suppressDataRegistry)">Save
                    </button>
                    <button id="closeButton" (click)="cancelSuppressRegistry()" mat-flat-button>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>