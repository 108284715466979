<mat-form-field floatLabel="always" class="w-full mb-4 sForm" *ngIf="ff">
  <mat-label>{{ff?.label}}</mat-label>
  <mat-chip-grid #chipGrid aria-label="Enter Keywords">
    <mat-chip-row *ngFor="let key of keywords" (removed)="remove(key)" [editable]="true" (edited)="edit(key, $event)"
      [aria-description]="'press enter to edit ' + key">
      {{key}}
      <button matChipRemove [attr.aria-label]="'remove ' + key">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input [placeholder]="ff?.label" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
  </mat-chip-grid>
  <mat-hint *ngIf="ff.example">
    <mat-icon>info_outline</mat-icon>
    {{ff.example}}</mat-hint>
</mat-form-field>