<div class="py-2 grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2 pl-4 pr-2" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <div class="flex flex-row bg-card ring-1 rounded"
        *ngFor="let network of networkInterfaces">
        <div class="flex flex-wrap items-center px-3">
            <i class="fas fa-ethernet text-2xl"></i>
        </div>
        <div class="flex flex-row flex-wrap w-full break-all items-center py-1 px-1">
            <div class="w-full text-sm font-semibold">{{network.friendly_name}}</div>
            <mat-divider></mat-divider>
            <div class="flex flex-col flex-wrap">
                <div class="text-xs font-light" matTooltip="Address" [matTooltipPosition]="'right'">{{network.address}} </div>
                <span class="text-xs font-light" matTooltip="MAC" [matTooltipPosition]="'right'">{{network.mac}}</span>
                <span class="text-xs font-light" matTooltip="Mask" [matTooltipPosition]="'right'">{{network.mask}}</span>
                <span class="text-xs font-light" matTooltip="Broadcast" [matTooltipPosition]="'right'"
                      *ngIf="network.broadcast && network.broadcast !== ' , '">{{network.broadcast}}</span>
            </div>
        </div>
    </div>
</div>
<app-loader [loadingCon]="loadingCon" *ngIf="!networkInterfaces || networkInterfaces.length < 0"></app-loader>
