import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { CommonModule, NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tag-list',
  standalone: true,
  imports: [CommonModule, MatButtonModule,
    MatFormFieldModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatIconModule,],
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit{
  addOnBlur = true;
  @Input() keywords: any = [];
  @Input() ff: any;
  @Output() result = new EventEmitter();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor() {

    }

    ngOnInit(): void {
      //console.log(this.ff)
    }

    add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our key
    if (value) {
      this.keywords.push(value);
      this.result.emit(this.keywords);
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  remove(key: any): void {
    const index = this.keywords.indexOf(key);
    if (index >= 0) {
      this.keywords.splice(index, 1);
      this.result.emit(this.keywords);
    }
  }

  edit(key: any, event: MatChipEditedEvent) {
    const value = event.value.trim();
    // Remove key if it no longer has a name
    if (!value) {
      this.remove(key);
      return;
    }
    // Edit existing key
    const index = this.keywords.indexOf(key);
    if (index >= 0) {
      this.keywords[index] = value;
      this.result.emit(this.keywords);
    }
  }
}
