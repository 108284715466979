<div class="flex flex-col w-full min-w-0 sm:inset-0 sm:overflow-hidden" [@fadeInLeft]="" *ngIf="!cIntegration"
    fuseScrollbar>
    <div class="flex items-center">
        <div class="spacer"></div>
        <mat-form-field class="search-tool fs1 sm:w-1/2 lg:w-1/4 " floatLabel="never">
            <input matInput name="integsearch" [(ngModel)]="integrationSearch" placeholder="Search" autocomplete="off">
            <button *ngIf="!integrationSearch || integrationSearch === ''" aria-label="Search" mat-icon-button
                matSuffix>
                <mat-icon>search</mat-icon>
            </button>
            <button (click)="integrationSearch='';" *ngIf="integrationSearch" aria-label="Clear" mat-icon-button
                matSuffix>
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="w-auto rounded-2xl dark:bg-default mb-2" fuseScrollbar>
        <div class="flex flex-col flex-auto min-w-0">
            <div class="flex-auto -mt-px pt-1 sm:pt-2">
                <div class="w-full  mx-auto">
                    <ng-container *ngIf="categoryList && categoryList.length > 0">
                        <div class="p-6 md:p-8 space-y-8">
                            <ng-container>
                                <div *ngFor="let integrationtype of categoryList; trackBy:trackByFn">
                                    <ng-container
                                        *ngIf="(integrationTypeHash[integrationtype] | search: integrationSearch).length > 0">
                                        <div class="font-medium">{{integrationtype | uppercase}}</div>
                                        <div class="flex flex-wrap -m-2 mt-2">
                                            <ng-container
                                                *ngFor="let product of integrationTypeHash[integrationtype] | sortBy:'asc':'display' | search: integrationSearch; trackBy:trackByFn">
                                                <div class="relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card">
                                                     <mat-icon matTooltip="Integrated" class="absolute z-20 top-[0.5rem] left-[0.6rem] w-9 h-9 min-h-8
                                                      cursor-none icon-size-5 text-green-500" *ngIf="product.cred_count"
                                                            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                                                    <div class="absolute z-20 right-2 top-1.5 " *ngIf="product.cred_count">
                                                        <span matTooltip="Count of Companies Mapped"
                                                            class="inline-flex items-center justify-center px-1.5 py-0 ms-2 text-xs font-semibold bg-primary text-white rounded-full">
                                                            {{product.mapping_count}}
                                                        </span>
                                                    </div>
                                                    <a class="z-10 absolute inset-0 flex flex-col p-4 cursor-pointer"
                                                        href="javascript:" (click)="setCIntegration(product)" id="{{product.name}}">
                                                        <div class="aspect-[9/6] mt-4">
                                                            <div class="flex items-center justify-center h-full">
                                                                <img alt="{{product.display}}"
                                                                    class="w-20 h-20 object-cover" [ngClass]="{'w-[8rem]': product.image === 'ms-sccm' || product.image === 'o365_assessment'}"
                                                                    src="/assets/images/providers/provider-{{ product.image }}.png">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="flex flex-col mt-1 justify-center text-center text-sm font-medium">
                                                            <div class="truncate text-base"
                                                                [matTooltip]="product.display">
                                                                {{product.display}}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <mat-divider class="my-3"></mat-divider>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="cIntegration" [@fadeInRight] id="integrationOverview" #integrationOverview>
    <integrations-overview [currentIntegration]="cIntegration"
        (toggleFn)="toggleChange($event)"></integrations-overview>
</div>
