export const settings: any = [
  {
    id: "overview",
    settings: [
      /*{
                name: "Companies",
                text: "Companies",
                id: "company-menu",
                icon: "mat_outline:domain",
                isSubMenu: true,
                menuItems: [
                    {
                        name: "New Company",
                        id: "new-company",
                        icon: "heroicons_outline:folder-plus",
                        target: "new-company",
                        api: "companies",
                    },
                    {
                        name: "Delete Companies",
                        id: "delete-company",
                        icon: "mat_outline:domain_disabled",
                        target: "delete-company",
                        api: "companies",
                    },
                    {
                        name: "Companies",
                        id: "company-list",
                        icon: "mat_outline:domain",
                        target: "company-list",
                        api: "companies",
                    }
                ],
                api: "companies",
            },
            {
                name: "Integrations",
                text: "Integrations",
                id: "integrations",
                icon: "heroicons_outline:squares-plus",
                target: "integrations",
                api: "get_integrations",
            },
            {
                name: "Application Baseline",
                text: "App Baseline",
                id: "application_base_rule",
                icon: "heroicons_mini:clipboard-document-check",
                target: "application_base_rule",
                api: "application_baseline_rules",
            },
            {
                name: "Tags Rules",
                text: "Tags",
                id: "tags",
                icon: "heroicons_mini:tag",
                target: "tags",
                api: "tag_rules",
            },
            {
                name: "Settings",
                text: "Global Settings",
                id: "global-settings",
                icon: "settings_suggest",
                target: "global-settings",
                api: "settings",
                allowed: ["writer"],
            },
            {
                name: "Users",
                text: "Users",
                id: "user-menu",
                icon: "heroicons_outline:user",
                isSubMenu: true,
                menuItems: [
                    {
                        name: "Profile",
                        id: "profile",
                        icon: "heroicons_outline:user",
                        target: "profile",
                    },
                    {
                        name: "User Management",
                        id: "user-management",
                        icon: "heroicons_outline:users",
                        target: "user-management",
                    },
                ],
                api: "users",
            },
            {
                name: "Plan and Billing",
                text: "Plan",
                id: "plan-and-billing",
                icon: "heroicons_solid:currency-dollar",
                target: "plan-and-billing",
            },*/
      {
        name: "Search CVE",
        text: "Search",
        id: "search-cve",
        icon: "mat_outline:search",
        target: "search-cve",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "metrics",
    settings: [
      /* {
                name: "Companies",
                text: "Companies",
                id: "company-menu",
                icon: "mat_outline:domain",
                isSubMenu: true,
                menuItems: [
                    {
                        name: "New Company",
                        id: "new-company",
                        icon: "heroicons_outline:folder-plus",
                        target: "new-company",
                        api: "companies",
                    },
                    {
                        name: "Delete Companies",
                        id: "delete-company",
                        icon: "mat_outline:domain_disabled",
                        target: "delete-company",
                        api: "companies",
                    },
                    {
                        name: "Companies",
                        id: "company-list",
                        icon: "mat_outline:domain",
                        target: "company-list",
                        api: "companies",
                    }
                ],
                api: "companies",
            },
            {
                name: "Integrations",
                text: "Integrations",
                id: "integrations",
                icon: "heroicons_outline:squares-plus",
                target: "integrations",
                api: "get_integrations",
            },
            {
                name: "Application Baseline",
                text: "App Baseline",
                id: "application_base_rule",
                icon: "heroicons_mini:clipboard-document-check",
                target: "application_base_rule",
                api: "application_baseline_rules",
            },
            {
                name: "Settings",
                text: "Global Settings",
                id: "global-settings",
                icon: "settings_suggest",
                target: "global-settings",
                api: "settings",
                allowed: ["writer"],
            },
            {
                name: "Users",
                text: "Users",
                id: "user-menu",
                icon: "heroicons_outline:user",
                isSubMenu: true,
                menuItems: [
                    {
                        name: "Profile",
                        id: "profile",
                        icon: "heroicons_outline:user",
                        target: "profile",
                    },
                    {
                        name: "User Management",
                        id: "user-management",
                        icon: "heroicons_outline:users",
                        target: "user-management",
                    },
                ],
                api: "users",
            },
            {
                name: "Plan and Billing",
                text: "Plan",
                id: "plan-and-billing",
                icon: "heroicons_solid:currency-dollar",
                target: "plan-and-billing",
            },*/
      {
        name: "Search CVE",
        text: "Search",
        id: "search-cve",
        icon: "mat_outline:search",
        target: "search-cve",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "company",
    settings: [
      /*{
                name: "Tags Rules",
                text: "Tags",
                id: "tags",
                icon: "heroicons_mini:tag",
                target: "tags",
                api: "tag_rules",
            },*/
      /*{
                name: "Company Settings",
                text: "Company Settings",
                id: "company-settings",
                icon: "settings_suggest",
                target: "company-settings",
                api: "settings",
            },*/
      {
        name: "Update Company Details",
        text: "Update Company",
        id: "update-company",
        icon: "feather:database",
        target: "update-company",
        api: "companies",
      },
      /*{
                name: "Customer Management",
                text: "Customers",
                id: "customer-management",
                icon: "heroicons_outline:users",
                target: "customer-management",
                api: "customers",
            },*/
      /*{
                name: "Application Baseline",
                text: "Application Baseline",
                id: "application_base_rule",
                icon: "heroicons_mini:clipboard-document-check",
                target: "application_base_rule",
                api: "application_baseline_rule",
            },
            {
                name: "Integrations",
                text: "Integrations",
                id: "integrations",
                icon: "heroicons_outline:squares-plus",
                target: "integrations",
                api: "get_integrations",
            },*/
      {
        name: "Search CVE",
        text: "Search",
        id: "search-cve",
        icon: "mat_outline:search",
        target: "search-cve",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "agents",
    settings: [
      {
        name: "Discovery Settings and Credentials",
        text: "Discovery",
        id: "discovery-settings",
        icon: "feather:list",
        target: "discovery-settings",
        api: "discovery_settings",
      },
      {
        name: "Manage Mapping",
        text: "Mapping",
        id: "manage-mapping",
        icon: "feather:check-square",
        target: "manage-mapping",
        api: "agent_discovery_settings",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "firewalls",
    settings: [
      {
        name: "Asset Timeline",
        text: "Timeline",
        id: "asset-time-line",
        icon: "feather:clock",
        target: "asset-time-line",
        api: "system_time_line",
        isFirewall: true,
      },
      {
        name: "Remove Asset",
        text: "Delete",
        id: "remove-asset",
        icon: "mat_outline:delete_outline",
        target: "remove-asset",
        isFirewall: true,
        emitterAction: "assetEmitter",
        api: "assets",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "compliance-standards",
    settings: [
      // {
      //   name: "Upload Evidence",
      //   id: "upload-evidence",
      //   icon: "feather:upload",
      //   target: "upload-evidence",
      //   emitterAction: "complianceEmitter",
      //   api: "upload_evidence",
      // },
      /*{
                name: "Remediate Non Compliant",
                text: "Remediate Non Compliant",
                id: "remediate_non_compliant",
                icon: "feather:alert-triangle",
                target: "remediate_non_compliant",
                emitterAction: "complianceEmitter",
                api: "remediate",
            },*/
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "compliance-check-master",
    settings: [
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "azure",
    settings: [
      {
        name: "Sync Now",
        text: "Sync",
        id: "azure-sync",
        icon: "feather:sliders",
        target: "azure-sync",
        emitterAction: "azureSyncEmitter",
        api: "sync_now",
      },
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "m365-risk-summary",
    settings: [
      {
        name: "Sync Now",
        text: "Sync",
        id: "azure-sync",
        icon: "feather:sliders",
        target: "azure-sync",
        emitterAction: "o365SyncEmitter",
        api: "sync_now",
      },
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "microsoft",
    settings: [
      {
        name: "Sync Now",
        text: "Sync",
        id: "azure-sync",
        icon: "feather:sliders",
        target: "azure-sync",
        emitterAction: "microSyncEmitter",
        api: "sync_now",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "pii-scan-profile",
    settings: [
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "ad-details",
    settings: [
      {
        name: "Delete",
        text: "Delete All Records",
        id: "ad-details-delete-all-records",
        icon: "mat_outline:delete_outline",
        target: "ad-details-delete-all-records",
        emitterAction: "adetailsEmitter",
        api: "ad-details-delete",
      },
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "pii-scan-result",
    settings: [
      {
        name: "Jobs",
        text: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
      {
        name: "All Record's Delete",
        text: "Delete All Records",
        id: "pii-delete-all-records",
        icon: "mat_outline:delete_outline",
        target: "pii-delete-all-records",
        emitterAction: "piiScanEmitter",
        api: "pii-delete",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "patch-scheduler-job",
    settings: [
      {
        name: "Patch Scheduler Jobs",
        text: "Patch Scheduler  Jobs",
        id: "patch-scheduler-jobs",
        icon: "mat_outline:hourglass_top",
        target: "patch-jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "report-scheduler-job",
    settings: [
      {
        name: "Report Scheduler Jobs",
        text: "Report Scheduler Jobs",
        id: "report-scheduler-jobs",
        icon: "mat_outline:hourglass_top",
        target: "report-scheduler-job",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "report-builder-scheduler",
    settings: [
      {
        name: "Report Builder Scheduler Jobs",
        text: "Report Builder Scheduler Jobs",
        id: "report-builder-scheduler",
        icon: "mat_outline:hourglass_top",
        target: "report-builder-scheduler",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },

  {
    id: "scan-scheduler-job",
    settings: [
      {
        name: "Scan Scheduler Jobs",
        text: "Scan Scheduler Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
];

export const assessment_settings = [
  {
    id: "overview",
    settings: [
      {
        name: "Assessment Upload",
        text: "Assessment Upload",
        id: "assessment_upload",
        icon: "feather:upload",
        target: "assessment_upload",
      },
      {
        name: "Download",
        text: "Download",
        id: "assessment-download",
        icon: "download",
        target: "assessment-download",
      },
      {
        name: "Delete Companies",
        text: "Delete Companies",
        id: "delete-company",
        icon: "mat_outline:domain_disabled",
        target: "delete-company",
        api: "companies",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "metrics",
    settings: [
      {
        name: "Assessment Upload",
        text: "Assessment Upload",
        id: "assessment-upload",
        icon: "feather:upload",
        target: "assessment-upload",
        isActionOnly: true,
      },
      {
        name: "Download",
        text: "Download",
        id: "assessment-download",
        icon: "download",
        target: "assessment-download",
        isActionOnly: true,
      },
      {
        name: "Delete Companies",
        text: "Delete Companies",
        id: "delete-company",
        icon: "mat_outline:domain_disabled",
        target: "delete-company",
        api: "companies",
      },
    ],
    allowed: ["writer"],
  },
];
