import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FuseAlertComponent } from "../../../../@fuse/components/alert";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { Subject, Subscription, takeUntil } from "rxjs";
import { CommonService } from "../../../_services/common.service";
import { HttpClient } from "@angular/common/http";
import { BaseRequestService } from "../../../_services/base.service";
import { ModalService } from "../../../_services/modal.service";
import { LoaderService } from "../../../_services/loader.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { FuseConfirmationService } from "../../../../@fuse/services/confirmation";
import { DynamicFormComponent } from "../forms/dynamic-form/dynamic-form.component";
import { MaterialModule } from "../../../material.module";
import { Router } from "@angular/router";
import { ReportCoverPageComponent } from "app/modules/settings/report-cover-page/report-cover-page.component";

@Component({
  selector: "app-report-settings",
  standalone: true,
  imports: [
    CommonModule,
    FuseAlertComponent,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    DynamicFormComponent,
    MaterialModule,
    ReportCoverPageComponent
  ],
  templateUrl: "./report-settings.component.html",
  styleUrls: ["./report-settings.component.scss"],
})
export class ReportSettingsComponent implements OnInit, OnDestroy {
  @Input() global: any = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  editMode = false;
  images: any = {};
  isLoading = true;
  reportSettingsForm: UntypedFormGroup;
  cView: any = 'docxCustom';
  defaultImages: any = {
    header: "/assets/images/logo/cs_logo.png",
    footer: "/assets/images/logo/cs_logo.png",
    company_logo: "/assets/images/logo/cs_logo.png"
  };
  alignments: any = [
    {
      value: "",
      label: "--None--",
      isIcon: false,
    },
    {
      value: "left",
      label: "Left",
      isIcon: false,
    },
    {
      value: "right",
      label: "Right",
      isIcon: false,
    },
    {
      value: "center",
      label: "Center",
      isIcon: false,
    },
  ];
  repSettings: any = [
    {
      label: "Watermark Text",
      key: "watermark",
      required: false,
      example: "Ex. Watermark Text",
      type: "text",
    },
    {
      label: "Header Text",
      key: "headerText",
      min: 0,
      max: 34,
      required: false,
      example: "Ex. Header Text",
      type: "text",
    },
    {
      key: "headerAlignment",
      label: "Header Alignment",
      example: "Choose alignment",
      required: false,
      callback: true,
      dKey: "label",
      dValue: "value",
      type: "dropdown",
      options: this.alignments,
      multiple: false,
      schema: {
        type: "select",
      },
    },
    {
      label: "Footer Text",
      key: "footerText",
      min: 0,
      max: 34,
      required: false,
      example: "Ex. Footer Text",
      type: "text",
    },
    {
      key: "footerAlignment",
      label: "Footer Alignment",
      example: "Choose alignment",
      required: false,
      callback: true,
      dKey: "label",
      dValue: "value",
      type: "dropdown",
      options: this.alignments,
      multiple: false,
      schema: {
        type: "select",
      },
    },
    {
      key:"default_header_footer",
      label: "Default Header&Footer",
      example:"Choose Header or Footer",
      required: false,
      callback: true,
      ishideGobal: true,
      dKey: "label",
      dValue: "value",
      type: "dropdown",
      options: [
        { value: "yes", label: "Yes", isIcon: false },
        { value: "no", label: "No", isIcon: false }
      ],
      multiple: false,
      schema: {
        type: "select",
      },
    }
  ];

  xlsxReportCustomization: any = {
    id: "",
    reporttype: "xlsx",
    xlsx_header_text_colour: '#000081',
    xlsx_header_text_size: 14,
    xlsx_header_text_bold: true,
    xlsx_header_cell_colour: '#DAF7A6',
    xlsx_data_text_colour: '#000081',
    xlsx_data_text_size: 14,
    xlsx_data_text_bold: false,
    xlsx_data_cell_colour: '#FFFFFF',
    company_id: "",
  };
  repParams: any = {
    id: "",
    reporttype: "docx",
    watermark: "",
    headerText: "",
    headerAlignment: "center",
    footerText: "",
    footerAlignment: "center",
    company_id: "",
    default_header_footer: "yes"
  };
  defaultCoverPage: any = {};
  cover_page: any = '';
  subs: Subscription;
  Objectkeys = Object.keys;
  constructor(
    public cs: CommonService,
    private _http: HttpClient,
    private _bs: BaseRequestService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modalService: ModalService,
    private _ls: LoaderService,
    private _formBuilder: UntypedFormBuilder,
    public _toast: MyToastrService,
    public confirmDialog: FuseConfirmationService,
    private _router: Router,
  ) {
    this.cs.selectedSiteChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.getReportSetting();
      });
  }

  ngOnInit(): void {
    this.getDefaultCover();
    this.reportSettingsForm = this._formBuilder.group({
      header: [""],
      footer: [""],
      company_logo: [""],
      default_header_footer: ['yes'] // Set default value to 'yes'
    });
    this.repParams = {
      default_header_footer: 'yes', // Default value
    };
   
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getReportSetting(): void {
    this._ls.display(true);
    const params: any = {};
    this.isLoading = true;
    params.company_id = this.cs.currentScope.id;
    if (this.global) {
      try {
        delete params.company_id;
        params.is_global = true;
      } catch (e) {
        console.log(e);
      }
    }
    if (this.cView === 'docxCustom') {
      params.reporttype = "docx";
    } else if (this.cView === 'xlsxCustom') {
      params.reporttype = "xlsx";
    }
    this._bs
      .doRequest(
        `/report_builder/get_standard_report_settings`,
        "get",
        null,
        params
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._ls.display(false);
        if (result.status) {
          if (result.message) {
            if (result.message.reporttype === 'docx') {
              const img = result.message;
              this.images = {
                header: img.header_details[0].presignedurl,
                footer: img.footer_details[0].presignedurl,
                company_logo: img.company_logo_url,
              };
              this.repParams = {
                id: img.id,
                reporttype: "docx",
                watermark: img.watermark,
                headerText: img.header_details[0].headerText,
                headerAlignment: img.header_details[0].headerAlignment,
                footerText: img.footer_details[0].footerText,
                footerAlignment: img.footer_details[0].footerAlignment,
                company_id: img.company_id,
                cover_page: img.cover_page,
                default_header_footer: result.message.default_header_footer || 'yes', // Default to 'yes' if not present
              };
              this._changeDetectorRef.detectChanges();
            } else if (result.message.reporttype === 'xlsx') {
              const details = result.message;
              this.xlsxReportCustomization = {
                id: details.id,
                reporttype: "xlsx",
                xlsx_header_text_colour: details.header_details[0].xlsx_header_text_colour,
                xlsx_header_text_size: details.header_details[0].xlsx_header_text_size,
                xlsx_header_text_bold: details.header_details[0].xlsx_header_text_bold === "true" ? true : false,
                xlsx_header_cell_colour: details.header_details[0].xlsx_header_cell_colour,
                xlsx_data_text_colour: details.footer_details[0].xlsx_data_text_colour,
                xlsx_data_text_size: details.footer_details[0].xlsx_data_text_size,
                xlsx_data_text_bold: details.footer_details[0].xlsx_data_text_bold === "true" ? true : false,
                xlsx_data_cell_colour: details.footer_details[0].xlsx_data_cell_colour,
                company_id: details.company_id
              }
              this._changeDetectorRef.detectChanges();
            }
          } else {
            this.xlsxReportCustomization = {
              reporttype: "xlsx",
              xlsx_header_text_colour: '#000081',
              xlsx_header_text_size: 14,
              xlsx_header_text_bold: true,
              xlsx_header_cell_colour: '#DAF7A6',
              xlsx_data_text_colour: '#000081',
              xlsx_data_text_size: 14,
              xlsx_data_text_bold: false,
              xlsx_data_cell_colour: '#FFFFFF',
              company_id: "",
            };
            this.repParams = {
              id: "",
              reporttype: "docx",
              watermark: "",
              headerText: "",
              headerAlignment: "center",
              footerText: "",
              footerAlignment: "center",
              company_id: "",
            };
          }
        } else {
          this.images = {
            header: "/assets/images/logo/cs_logo.png",
            footer: "/assets/images/logo/cs_logo.png",
            company_logo: "/assets/images/logo/cs_logo.png",
          };
          this.repParams = {
            id: "",
            reporttype: "docx",
            watermark: "",
            headerText: "",
            headerAlignment: "center",
            footerText: "",
            footerAlignment: "center",
            company_id: "",
            default_header_footer: 'yes',  // Default to false
          };
          this.xlsxReportCustomization = {
            reporttype: "xlsx",
            xlsx_header_text_colour: '#000081',
            xlsx_header_text_size: 14,
            xlsx_header_text_bold: true,
            xlsx_header_cell_colour: '#DAF7A6',
            xlsx_data_text_colour: '#000081',
            xlsx_data_text_size: 14,
            xlsx_data_text_bold: false,
            xlsx_data_cell_colour: '#FFFFFF',
            company_id: "",
          };
        }
        this.isLoading = false;
      });
  }

  uploadImage($event: any, key: any): void {
    if (!$event.target.files.length) {
      return;
    }
    const allowedTypes = ["image/jpeg", "image/png"];
    const file = $event.target.files[0];

    if (file) {
      const maxSizeInBytes = 524288; // 524 kB (1 kB = 1024 bytes)
      if (file.size > maxSizeInBytes) {
        // Handle the case where the file size exceeds the limit
        this._toast.sToast("error", "File size exceeds the limit.");
        $event.target.value = null;
        return;
      }
    }
    if (!allowedTypes.includes(file.type)) {
      return;
    }
    // @ts-ignore
    const imageFormControl = this.reportSettingsForm.get(key);
    imageFormControl.setValue(file);

    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (ev) => {
      // @ts-ignore
      this.images[key] = reader.result;
    };
  }

  // Function to retrieve image from URL
  getImageFromURL(url: string) {
    return this._http.get(url, { responseType: "blob" });
  }

  removeImage(key: any) {
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: "Are you sure you would like to reset ?",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" },
      },
      dismissible: false,
    });
    confirmation.afterClosed().subscribe(async (result: any) => {
      if (result === "confirmed") {
        this._ls.display(true);
        const images = this.reportSettingsForm.getRawValue();
        const params: any = {
          company_id: this.cs.currentScope.id,
        };
        if (key === "resetHeader") {
          params.resetHeader = true;
        } else if (key === "resetFooter") {
          params.resetFooter = true;
        } else if (key === "resetCompanyLogo") {
          params.resetCompanyLogo = true;
        }
        const formData = new FormData();
        Object.keys(params).forEach((obj: any) => {
          if (params[obj]) {
            formData.append(obj, params[obj]);
          }
        });
        try {
          this._ls.display(true);
          const url = `/report_builder/update_standard_report_settings`;
          const response = await this._bs
            .uploadFormData(url, formData, true)
            .toPromise();
          this._ls.display(false);
          if (response.status) {
            if (key === "resetHeader") {
              this.images.header = "";
            } else if (key === "resetFooter") {
              this.images.footer = "";
            } else if (key === "resetCompanyLogo") {
              this.images.company_logo = "";
            }
            // this._toast.sToast("success", `Updated successfully!`);
            this.getReportSetting();
          } else {
            this._toast.sToast("error", response.message);
          }
          this._changeDetectorRef.markForCheck();
        } catch (error) {
          throw error;
        }
      }
    });
  }

  areAllValuesEmpty(obj: { [key: string]: any }): boolean {
    return Object.values(obj).every((value) => !value);
  }

  async saveImage(): Promise<any> {
    const images = this.reportSettingsForm.getRawValue();
    const params: any = {
      isGlobal: false,
      reporttype: "docx",
      watermark: this.repParams.watermark || '',
      company_id: this.cs.currentScope.id,
      headerImage: images.header || '',
      headerText: this.repParams.headerText || '',
      headerAlignment: this.repParams.headerAlignment || '',
      footerImage: images.footer || '',
      company_logo: images.company_logo || '',
      footerText: this.repParams.footerText || '',
      footerAlignment: this.repParams.footerAlignment || '',
      cover_page: this.repParams.cover_page || '',
      default_header_footer: this.repParams.default_header_footer || 'yes' // Use 'yes' or 'no' directly
    };
    let isPut = false;
    if (this.repParams.id) {
      isPut = true;
      params.id = this.repParams.id;
    }
    if (this.global) {
      try {
        delete params.company_id;
        params.is_global = true;
      } catch (e) {
        console.log(e);
      }
    }
    const formData = new FormData();
    Object.keys(params).forEach((obj: any) => {
      formData.append(obj, params[obj]);
    });
    try {
      this._ls.display(true);
      const url = isPut
        ? `/report_builder/update_standard_report_settings`
        : `/report_builder/create_standard_report_settings`;
      const response = await this._bs
        .uploadFormData(url, formData, isPut)
        .toPromise();
      this._ls.display(false);
      if (response.status) {
        this._toast.sToast("success", `Uploaded successfully!`);
        this.getReportSetting();
      } else {
        this._toast.sToast("error", response.message);
      }
      this._changeDetectorRef.markForCheck();
    } catch (error) {
      throw error;
    }
  }

  getDefaultCover(): void {
    this._ls.display(true);
    this._bs
      .doRequest(`/report_builder/get_default_coverpage`, "get")
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this._ls.display(false);
        if (res.status) {
          this.defaultCoverPage = res.message;
          this.getReportSetting();
        } else {
          this.getReportSetting();
        }
      })
  }
  async saveXlsxCustomization(): Promise<void> {
    const params: any = {
      isGlobal: false,
      reporttype: "xlsx",
      company_id: this.cs.currentScope.id,
      xlsx_header_text_colour: this.xlsxReportCustomization.xlsx_header_text_colour,
      xlsx_header_text_size: this.xlsxReportCustomization.xlsx_header_text_size,
      xlsx_header_text_bold: this.xlsxReportCustomization.xlsx_header_text_bold,
      xlsx_header_cell_colour: this.xlsxReportCustomization.xlsx_header_cell_colour,
      xlsx_data_text_colour: this.xlsxReportCustomization.xlsx_data_text_colour,
      xlsx_data_text_size: this.xlsxReportCustomization.xlsx_data_text_size,
      xlsx_data_text_bold: this.xlsxReportCustomization.xlsx_data_text_bold,
      xlsx_data_cell_colour: this.xlsxReportCustomization.xlsx_data_cell_colour,
    };
    let isPut = false;
    if (this.xlsxReportCustomization.id) {
      isPut = true;
      params.id = this.xlsxReportCustomization.id;
    }
    if (this.global) {
      try {
        delete params.company_id;
        params.is_global = true;
      } catch (e) {
        console.log(e);
      }
    }
    const formData = new FormData();
    Object.keys(params).forEach((obj: any) => {
      formData.append(obj, params[obj]);
    });
    try {
      this._ls.display(true);
      const url = isPut
        ? `/report_builder/update_standard_report_settings`
        : `/report_builder/create_standard_report_settings`;
      const response = await this._bs
        .uploadFormData(url, formData, isPut)
        .toPromise();
      this._ls.display(false);
      if (response.status) {
        this._toast.sToast("success", `Uploaded successfully!`);
        this.getReportSetting();
      } else {
        this._toast.sToast("error", response.message);
      }
      this._changeDetectorRef.markForCheck();
    } catch (error) {
      throw error;
    }
  }
  navGlobalsettings(): void {
    this.cs.currentScope = '*';
    this.cs.updateCompanyCall.emit('*');
    localStorage.removeItem('cmp');
    this._router.navigate(['/report-builder']);
    return;
  }
}
