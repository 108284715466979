import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MaterialModule } from 'app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { CommonService } from 'app/_services/common.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edr-app',
  standalone: true,
  imports: [CommonModule, FuseAlertComponent, AppFilterPipeModule, FuseScrollbarDirective, DynamicFormComponent, FormsModule, NgIf, NgFor, MaterialModule, ReactiveFormsModule],
  templateUrl: './edr-app.component.html',
  styleUrls: ['./edr-app.component.scss']
})
export class EdrAppComponent implements OnInit, OnDestroy {
  hideEdrTable: any = false;
  edr_regex: any = false;
  newEdrDetails: any = {
    name: '',
    manufacturer: '',
    year_of_release: null,
    edr_description: '',
    regex: false,
    operation: '',
    action: '',
    is_antirandsome: false
  };
  originalExcludedEdr: any = { 'category': 'assets', 'sub_category': 'edr', 'name': 'excluded_edrs', 'value': [] }
  originalGlobalEdr: any = [];
  excludedEdr: any = [];
  globalEdr: any = [];
  globalSearch: any = '';
  excludedSearch: any = '';
  selectAll = false;
  selectAllExcluded = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _bs: BaseRequestService, private _cs: CommonService,
    private ls: LoaderService, private toast: MyToastrService,
    public confirmDialog: FuseConfirmationService
  ) { }

  ngOnInit(): void {
    this.getEdrApplications();
  }
  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleAddMode(hideEdrTable: boolean | null = null): void {
    if (hideEdrTable === null) {
      this.hideEdrTable = !this.hideEdrTable;
    }
    else {
      this.hideEdrTable = hideEdrTable;
    }
    this.newEdrDetails = {
      name: '',
      manufacturer: '',
      year_of_release: null,
      edr_description: '',
      regex: false,
      operation: 'add',
      action: 'edr',
      is_antirandsome: false,
    };
    this._changeDetectorRef.markForCheck();
  }

  async saveEdrApp(): Promise<any> {
    const softwareNameExists = this.globalEdr.some((software: any) => software.name === this.newEdrDetails.name);
    if (softwareNameExists) {
      this.toast.sToast('info', 'Software Exists With Same Name');
      return;
    }
    const data = cloneDeep(this.newEdrDetails);
    delete data.operation;
    delete data.action;
    data.company_id = null;
    this.ls.display(true);
    if (this._cs.currentScope !== '*') {
      data.company_id = parseInt(this._cs.currentScope.id);
    }
    const reqData = { data: data };
    const res = await this._bs.doRequest(`/w/company/edr`, 'post', reqData).toPromise();
    this.ls.display(false);
    if (res.status) {
      this.toast.sToast('success', ' Added successfully!');
      this.updateedr();
      this.getEdrApplications();
      this.hideEdrTable = false; this._changeDetectorRef.detectChanges();
    } else {
      const data = (res.message) ? res.message : res.data;
      this.toast.sToast('error', data);
    }
  }

  async updateedr(): Promise<any> {
    const data = cloneDeep(this.newEdrDetails);
    delete data.manufacturer;
    delete data.year_of_release;
    delete data.edr_description;
    delete data.regex;
    delete data.is_antirandsome;

    data.company_id = null;
    this.ls.display(true);
    if (this._cs.currentScope !== '*') {
      data.company_id = parseInt(this._cs.currentScope.id);
    }
    const reqData = { data: data };
    const res = await this._bs.doRequest(`/w/company/update_backup`, 'post', reqData).toPromise();
    this.ls.display(false);
    if (res.status) {
      this.toast.sToast('success', ' Added successfully!');
      this.resetAgent(reqData.data.company_id);
      this.hideEdrTable = false; this._changeDetectorRef.detectChanges();
    } else {
      const data = (res.message) ? res.message : res.data;
      this.toast.sToast('error', data);
    }
  }

  async getEdrApplications(): Promise<any> {
    try {
      this.ls.display(true);
      const params = { company_id: (this._cs.currentScope !== '*') ? parseInt(this._cs.currentScope.id) : undefined };
      const response = await this._bs.doRequest(`/r/company/edr_list`, 'get', null, params).toPromise();
      this.ls.display(false);
      if (response.status) {
        this.originalGlobalEdr = response.data;
        try {
          const params: any = { condition: true, skip: 0, limit: 1 }
          if (this._cs.currentScope === '*') {
            params.condition = `company_id IS NULL and category='assets' and name='excluded_edrs'`;
          } else {
            params.condition = `company_id=${parseInt(this._cs.currentScope.id)} and category='assets' and name='excluded_edrs'`;
          }
          this.ls.display(true);
          const res = await this._bs.doRequest(`/r/company/settings`, 'get', null, params).toPromise();
          this.ls.display(false);
          if (res.status) {
            this.selectAll = false; this.globalSearch = null;
            this.selectAllExcluded = false; this.excludedSearch = null;
            if (res.data.length) {
              try {
                this.originalExcludedEdr = { ...this.originalExcludedEdr, ...{ id: res.data[0].id, value: res.data[0].value } };
                const value: any = cloneDeep(res.data[0].value);
                this.excludedEdr = value.map((item: any) => ({ name: item, selected: false }));
                this.globalEdr = this.originalGlobalEdr.filter((s: any) => value.indexOf(s.name) === -1);
                this.globalEdr.map((x: any) => x.selected = false);
                this._changeDetectorRef.detectChanges();
              } catch (e) {
                console.log(e);
              }
            } else {
              this.globalEdr = this.originalGlobalEdr;
              this.globalEdr.map((x: any) => x.selected = false);
              this.excludedEdr = [];
              this._changeDetectorRef.detectChanges();
            }
          } else {
            const data = (res.message) ? res.message : res.data;
            this.toast.sToast('error', data);
          }
        } catch (error) {
          // Handle errors here
          console.log(error);
        }
      } else {
        this.toast.sToast('error', response.message);
      }
    } catch (error) {
      // Handle errors here
      console.log(error);
    }
  }
  removeExclude(edr: any): void {
    const confirmation = this.confirmDialog.open({
      title: "EDR Application",
      message: "Please confirm if you want the EDR application removed from the excluded list.",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        this.originalExcludedEdr.value = this.originalExcludedEdr.value.filter((x: any) => x !== edr);
        const $event: any = this.originalExcludedEdr;
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', 'patch', request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              const eId = this.originalGlobalEdr.filter((x: any) => x.name === edr);
              if (eId && eId.length) {
                if (eId[0].id !== 'default') {
                  if ((this._cs.currentScope !== '*' && eId[0].company_id) && (this._cs.currentScope === '*' && !eId[0].company_id)) {
                    this.ls.display(false);
                  } else {
                    this._bs.doRequest(`/d/company/edr/${parseInt(eId[0].id)}`, 'delete')
                      .pipe(takeUntil(this._unsubscribeAll))
                      .subscribe((result: any) => { })
                  }
                }
              }
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected EDR application successfully removed from excluded list.');
                this.resetAgent($event.company_id);
                this.getEdrApplications();
                this._changeDetectorRef.markForCheck();
              }, 1000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }

    })
  }
  resetAgent(company_id: any): void {
    const reqData: any = {
      company_id: company_id,
      message: "edr_backup",
    };
    this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }
  changeExclude(edr: any) {
    const confirmation = this.confirmDialog.open({
      title: "EDR Application",
      message: "Please confirm if you want to exclude the selected EDR application",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        if (this.originalExcludedEdr.value && this.originalExcludedEdr.value.length) {
          this.originalExcludedEdr.value.push(edr.name);
        } else {
          this.originalExcludedEdr.value = [edr.name];
        }
        const $event: any = this.originalExcludedEdr;
        const method = ($event.id) ? 'patch' : 'post';
        const msg = ($event.id) ? 'Updated' : 'Saved';
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', method, request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'EDR application excluded successfully');
                this.getEdrApplications();
                this.resetAgent($event.company_id);
                this._changeDetectorRef.markForCheck();
              });
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }
    })
  }

  changeBulkExclude(): void {
    const confirmation = this.confirmDialog.open({
      title: "EDR Application",
      message: "Please confirm if you want to exclude the selected EDR application",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        if (this.originalExcludedEdr.value && this.originalExcludedEdr.value.length) {
          this.originalExcludedEdr.value = [...this.originalExcludedEdr.value, ...this.globalEdr.filter((x: any) => x.selected).map((y: any) => y.name)];
        } else {
          this.originalExcludedEdr.value = this.globalEdr.filter((x: any) => x.selected).map((y: any) => y.name);
        }
        const $event: any = this.originalExcludedEdr;
        const method = ($event.id) ? 'patch' : 'post';
        const msg = ($event.id) ? 'Updated' : 'Saved';
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', method, request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'EDR application excluded successfully');
                this.getEdrApplications();
                this.resetAgent($event.company_id);
                this._changeDetectorRef.markForCheck();
              });
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }
    })
  }
  hasSelected(): boolean {
    const len = this.globalEdr.filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  isAllSelected(): boolean {
    if (this.selectAll) {
      return true;
    }
    const len = this.globalEdr.filter((x: any) => x.selected).length;
    if (this.globalEdr.length === len) {
      return true;
    }
    return false;
  }

  selectAllToggle(): void {
    this.globalEdr.forEach((obj: any) => {
      obj.selected = this.selectAll;
    });
  }

  hasExcludedSelected(): boolean {
    const len = this.excludedEdr.filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  isAllExcludedSelected(): boolean {
    if (this.selectAllExcluded) {
      return true;
    }
    const len = this.excludedEdr.filter((x: any) => x.selected).length;
    if (this.excludedEdr.length === len) {
      return true;
    }
    return false;
  }

  selectAllExcludedToggle(): void {
    this.excludedEdr.forEach((obj: any) => {
      obj.selected = this.selectAllExcluded;
    });
  }

  removeBulkExclude(): void {
    const confirmation = this.confirmDialog.open({
      title: "EDR Application",
      message: "Please confirm if you want the EDR application removed from the excluded list.",
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        const edr: any = this.excludedEdr.filter((x: any) => x.selected).map((y: any) => y.name);
        this.originalExcludedEdr.value = this.originalExcludedEdr.value.filter((x: any) => edr.indexOf(x) === -1);
        const $event: any = this.originalExcludedEdr;
        $event.company_id = null;
        if (this._cs.currentScope !== '*') {
          $event.company_id = parseInt(this._cs.currentScope.id);
        }
        const request: any = { data: $event };
        if ($event.id) { request.id = $event.id };
        delete request.data.id;
        this._bs.doRequest('/w/company/settings', 'patch', request)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              const eId: any = this.originalGlobalEdr.filter((x: any) => edr.indexOf(x.name) !== -1 && x.id !== 'default');
              if (eId && eId.length) {
                const reqData: any = {
                  ids: eId.map((x: any) => parseInt(x.id))
                }
                if (this._cs.currentScope !== '*') {
                  reqData.ids.filter((x: any) => x.company_id === parseInt(this._cs.currentScope.id));
                }
                if (reqData.ids && reqData.ids.length) {
                  this._bs.doRequest(`/d/company/edr/bulk_delete`, 'post', reqData)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => { });
                }
              }
              setTimeout(() => {
                this.ls.display(false);
                this.toast.sToast('success', 'Selected EDR application successfully removed from excluded list.');
                this.resetAgent($event.company_id);
                this.getEdrApplications();
                this._changeDetectorRef.markForCheck();
              }, 1000);
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          })
      }

    })
  }
}
