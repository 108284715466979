import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';

@Component({
  selector: 'dynamic-sort',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './dynamic-sort.component.html',
  styleUrls: ['./dynamic-sort.component.scss']
})
export class DynamicSortComponent {
  @Input() sortReverse: boolean = false;
  @Input() isReport: boolean = false;
}