<form #domainNgForm="ngForm">
    <div class="flex flex-row items-center my-4">
        <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
            <mat-label>Domain Name</mat-label>
            <input matInput [placeholder]="'Enter domain name'" name="DomainName" required
                [(ngModel)]="domainInformation.domain"
                pattern="^(?!www\.|https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,5})(:[0-9]{1,5})?$">
        </mat-form-field>
        <button class="ml-2 mt-6" mat-flat-button [color]="'primary'" appPreventMultiClick
            [disabled]="domainNgForm.invalid" [matTooltip]="'Save'" (click)="save()">
            Scan
        </button>
        <!-- <button class="ml-2 mt-6" mat-flat-button [color]="'primary'" appPreventMultiClick
        [disabled]="!domainInformation.domain" [matTooltip]="'Refresh'" (click)="getDomainInformation()">
        Refresh
    </button> -->
        <button (click)="getDomainInformation()" mat-icon-button="" class="ml-2 mt-6" matTooltip="Refresh">
            <mat-icon class="icon-size-5 text-primary">refresh</mat-icon>
        </button>
    </div>
</form>

<mat-divider class="my-4"></mat-divider>
<ng-container *ngIf="!noData">
    <div
        class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
        <!-- <div class="flex flex-col sm:flex-row items-start justify-between">
            <div class="text-lg font-bold ml-2 tracking-tight leading-6 truncate">
                {{domainInformation.domain}}
            </div>
        </div>
        <mat-divider class="my-4"></mat-divider> -->

        <div class="flex flex-col items-start p-2">
            <table class="text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                    <tr *ngIf="domainInformation.spf_records && domainInformation.spf_records.length">
                        <td
                            class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                            SPF Records</td>
                        <td class="border px-4 py-2 break-all">{{domainInformation.spf_records[0]}}</td>
                    </tr>
                    <tr *ngIf="domainInformation.dkim_records && domainInformation.dkim_records.length">
                        <td
                            class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                            DKIM Records</td>
                        <td class="border px-4 py-2 break-all">{{domainInformation.dkim_records[0]}}</td>
                    </tr>
                    <tr *ngIf="domainInformation.dmarc_records && domainInformation.dmarc_records.length">
                        <td
                            class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                            DMARC Records</td>
                        <td class="border px-4 py-2 break-all">{{domainInformation.dmarc_records[0]}}</td>
                    </tr>
                    <tr *ngIf="domainInformation.mx_records && domainInformation.mx_records.length">
                        <td
                            class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                            MX Records</td>
                        <td class="border px-4 py-2 break-all">{{domainInformation.mx_records[0]}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>

<div *ngIf="noData" class="flex flex-col items-center justify-center gap-y-2 p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
    <img src="/assets/images/empty.png" alt="" class="w-20">
    <div> No data available!</div>
</div>