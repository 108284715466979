<div class="py-2" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <table class="table-auto">
        <tbody class="text-sm font-light">
            <tr class="border-b" *ngFor="let bios of biosInformation">
                <td class="py-1 px-4 text-left font-light">{{bios.name}}</td>
                <td class="py-1 px-4 text-left font-light">
                    <i class="fas fa-microchip text-blue-400 dark:text-white-400 pr-2"></i>
                    <span *ngIf="bios.name==='ReleaseDate' && bios.value > 0">{{bios.value|epochToDate}}</span>
                    <span *ngIf="bios.name!=='ReleaseDate'">{{bios.value}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-loader [loadingCon]="loadingCon" *ngIf="!biosInformation || biosInformation.length < 0"></app-loader>
