<ng-container *transloco="let t">
    <mat-card class="w-full h-full m-4 overflow-hidden bg-card rounded-2xl border-1" *ngIf="isLoading">
        <mat-card-content>
            <p><i class="fa fa-spin fa-spinner fa-lg"></i> Loading...</p>
        </mat-card-content>
    </mat-card>
    <mat-card class="m-4 overflow-hidden bg-card rounded-2xl border-1" *ngIf="!isLoading">
        <mat-drawer-container class="flex-auto sm:h-full">
            <!-- Drawer -->
            <mat-drawer class="sm:w-96 dark:bg-gray-900" [autoFocus]="false" [mode]="drawerMode" [opened]="drawerOpened"
                position="end" #drawer>
                <!-- Header -->
                <div class="flex flex-row items-center mx-2 my-2 md:my-10">
                    <!-- Title -->
                    <div class="text-2xl font-bold leading-none tracking-tight">Settings</div>
                    <div class="spacer"></div>
                    <!-- Close button -->
                    <div class="sm:hidden">
                        <button type="button" mat-icon-button (click)="drawer.close()">
                            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                </div>
                <!-- Panel links -->
                <div class="flex flex-col border-t border-b divide-y" *ngIf="tableOptions.floatingFilter">
                    <div class="navigation-items gap-[15px]">
                        <form class="flex flex-col items-center justify-center">
                            <mat-form-field class="pl20" style="padding: 10px;font-size: 15px;"
                                *ngIf="tableOptions.dropdwn">
                                <mat-select [multiple]="false" placeholder="Asset"
                                    (selectionChange)="filterDrpLstChange($event.value)" [formControl]="assetDrpCtrl">
                                    <mat-option
                                        *ngFor="let item of tableOptions.filterDrpLstFilteredList; trackBy: trackByFn"
                                        [value]="item._id">
                                        {{item.ip}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field floatLabel="never"
                                class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-[64px]">
                                <input matInput class="form-control" [(ngModel)]="filterText" type="text" trim="blur"
                                    name="filterText{{tableOptions.id}}" id="{{tableOptions.id}}_filter"
                                    (ngModelChange)="filterUpdate.next($event)" placeholder="Search">
                                <button type="button" class="gray" matSuffix mat-icon-button
                                    *ngIf="!filterText || filterText === ''" color="primary">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button type="button" class="gray" matSuffix mat-icon-button
                                    *ngIf="filterText && filterText !== ''"
                                    (click)="filterText = ''; doFilter(filterText);" color="primary">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                            <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                                <button type="button" class="gray" matSuffix mat-icon-button
                                    matTooltip="Reload Table Data " (click)="RefreshTableData();" color="primary">
                                    <mat-icon id="rfrbtn{{tableOptions.title}}"
                                        svgIcon="mat_outline:refresh"></mat-icon>
                                </button>
                            </div>
                            <div *ngIf="tableOptions.fontsizeData">
                                <mat-form-field
                                    class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                    <mat-select [(value)]="selectedFont" (selectionChange)="textSizeCallData()"
                                        matTooltip="Change Font Size">
                                        <mat-option value="small" selected>Small</mat-option>
                                        <mat-option value="medium" id="mediumtext">Medium</mat-option>
                                        <mat-option value="large" id="largeText">Large</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="tableOptions.refreshData">
                                <mat-form-field
                                    class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                    <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                                        matTooltip="Refresh Interval">
                                        <mat-option value="0" selected>No Refresh</mat-option>
                                        <mat-option value="60" id="RefreshOnemin">1 Min</mat-option>
                                        <mat-option value="300" id="RefreshFivemin">5 Min</mat-option>
                                        <mat-option value="1800" id="RefreshThirtymin">30 Min</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                                *ngIf="tableOptions.showColFilter && _sTableOptions.columns && _sTableOptions.columns.length"
                                [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <mat-menu #filterMenu="matMenu" class="col-filters">
                                <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                    <mat-card-header>
                                        <mat-card-title>Filter</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <form *ngIf="_sTableOptions && _sTableOptions.columns">
                                            <ng-container
                                                *ngFor="let column of _sTableOptions.columns; trackBy: trackByFn">
                                                <mat-form-field *ngIf="column.iscolumnSearch" class="w-full">
                                                    <input matInput type="text" trim="blur" [id]="column.id"
                                                        placeholder="Search {{column.header}}"
                                                        *ngIf="!column.selectFilter" name="{{column.columnDef}}"
                                                        [(ngModel)]="colfilter[column.columnDef]"
                                                        (input)='columnFilter($any($event.target).value,column.columnDef)'
                                                        [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch ">
                                                    <mat-select placeholder="Choose filter value"
                                                        name="{{column.columnDef}}" *ngIf="column.selectFilter"
                                                        [(ngModel)]="colfilter[column.columnDef]"
                                                        [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch "
                                                        (selectionChange)="columnFilter($event.value, column.columnDef)">
                                                        <mat-option value="">All</mat-option>
                                                        <mat-option
                                                            *ngFor="let val of column.selectFilterArr; trackBy: trackByFn"
                                                            [value]="val.value" [id]="val.id">{{val.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            </mat-menu>
                            <button type="button" mat-icon-button menuOpen="false" (click)="openListColumns()"
                                #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="belowMenu" color="primary"
                                id="view{{tableOptions.id}}" matTooltip="View Column, Reorder Columns"
                                *ngIf="tableOptions.showhideList">
                                <mat-icon>view_column</mat-icon>
                            </button>
                            <mat-menu #belowMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                                <div cdkDropList (cdkDropListDropped)="drop($event)">
                                    <div class="flex flex-row items-center w-full"
                                        *ngFor="let item of showhideList; let i = index; trackBy: trackByFn" cdkDrag>
                                        <i class="mt-2 mr-1 cursor-move material-icons color_icon"> drag_handle </i>
                                        <mat-checkbox color="primary" [checked]="item.visible == true ? item :''"
                                            (click)="$event.stopPropagation()" (change)="showHideColumn(i, item)"
                                            class="left-align" [id]="item.header"> {{item.header}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </mat-menu>
                            <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                                *ngIf="sTableOptions.cFilter" color="primary" [matMenuTriggerFor]="filterMenu"
                                #filterMenuTrigger="matMenuTrigger">
                                <mat-icon class="">filter_list</mat-icon>
                            </button>
                            <mat-menu #filterMenu="matMenu" class="col-filters">
                                <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                    <mat-card-header>
                                        <mat-card-title>Filter</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <form #filterForm="ngForm" role="form">
                                            <mat-form-field
                                                *ngIf="sTableOptions.columns && sTableOptions.columns.length"
                                                class="w-full" appearance="outline">
                                                <mat-label>Choose filter column</mat-label>
                                                <mat-select placeholder="Choose filter column" name="chooseFilter"
                                                    required [(ngModel)]="cFilter.selectedCol">
                                                    <mat-option value="">Choose filter column</mat-option>
                                                    <mat-option
                                                        *ngFor="let val of sTableOptions.columns; trackBy: trackByFn"
                                                        [value]="val.col" [id]="val.id">{{val.header}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field *ngIf="cFilter.selectedCol" class="w-full"
                                                appearance="outline">
                                                <mat-label>Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                                                <input matInput type="text" trim="blur"
                                                    *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                                                    placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                    required name="{{colHash[cFilter.selectedCol].col}}s"
                                                    [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                    name="{{colHash[cFilter.selectedCol].col}}s"
                                                    *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'"
                                                    required [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                    <mat-option
                                                        *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options; trackBy: trackByFn"
                                                        [value]="val.value">{{val.name}}</mat-option>
                                                </mat-select>
                                                <ng-container
                                                    *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                                                    <mat-date-range-input [rangePicker]="picker">
                                                        <input matStartDate name="startDate"
                                                            [(ngModel)]="colHash[cFilter.selectedCol].start"
                                                            placeholder="Start date">
                                                        <input matEndDate name="startDate"
                                                            [(ngModel)]="colHash[cFilter.selectedCol].end"
                                                            placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix
                                                        [for]="picker"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                                </ng-container>
                                            </mat-form-field>
                                            <button type="button"
                                                (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                                                [disabled]="!filterForm.form.valid" color="primary"
                                                mat-raised-button="">Add Filter
                                            </button>
                                            <button type="button" class="ml-2" (click)="cFilter.selectedCol = ''"
                                                mat-stroked-button="">Clear
                                            </button>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            </mat-menu>
                            <button type="button" id="gbtn{{sTableOptions.title}}" (click)="apiDownload('type')"
                                color="primary" matSuffix mat-icon-button *ngIf="tableOptions.apiDownload"
                                matTooltip="Download as CSV" aria-label="download csv menu">
                                <mat-icon class="mat-24">file_download</mat-icon>
                            </button>
                            <button type="button" id="gbtn{{sTableOptions.title}}" color="primary" matSuffix
                                mat-icon-button *ngIf="!tableOptions.hideDownload" matTooltip="Download as CSV"
                                [matMenuTriggerFor]="xlsMenu" aria-label="download csv menu">
                                <mat-icon class="mat-24">file_download</mat-icon>
                            </button>
                            <mat-menu #xlsMenu="matMenu">
                                <button type="button" mat-menu-item id="currentSelection"
                                    *ngIf="tableOptions.filterDownload" (click)="downloadAsXls('filtered')">
                                    <mat-icon class="mat-24">filter_list</mat-icon>
                                    <span>Filtered Data</span>
                                </button>
                                <button type="button" mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                                    <mat-icon class="mat-24">file_download</mat-icon>
                                    <span>Full Data</span>
                                </button>
                            </mat-menu>
                            <button type="button" mat-icon-button *ngIf="tableOptions.compareData"
                                matTooltip="Compare Data" color="primary" (click)="CompareData(compSelection.selected)">
                                <mat-icon>compare</mat-icon>
                            </button>
                            <button type="button" mat-icon-button matTooltip="Save Settings"
                                id="save{{tableOptions.id}}" (click)="SaveSettings()" color="primary">
                                <mat-icon>save</mat-icon>
                            </button>
                            <button type="button" mat-raised-button color="primary" (click)="publish()"
                                *ngIf="tableOptions.publish">
                                Publish
                            </button>
                            <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]"
                                target="_blank" color="primary"
                                *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                                <mat-icon>help</mat-icon>
                            </a>
                            <!--<button mat-icon-button menuOpen="false" (click)="openListExport()" matTooltip="Download"
                              #exportMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="exportMenu" *ngIf="tableOptions.exportExcel">
                              <mat-icon>cloud_download</mat-icon>
                            </button>-->
                            <button type="button" *ngFor="let pbtn of tableOptions.parentCalls; trackBy: trackByFn"
                                mat-icon-button [matTooltipPosition]="'right'" [matTooltip]="pbtn.tooltip"
                                color="primary" (click)="ParentCalls(pbtn.id)">
                                <mat-icon>{{pbtn.icon}}</mat-icon>
                            </button>

                            <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                                <!--<div>
                                  <button mat-button (click)="ExportToExcel()">
                                    Export to Excel
                                  </button></div>
                                <div>
                                  <button mat-button (click)="exportAs('pdf', 'l','current')">
                                    Export Current Page to PDF
                                  </button>
                                </div>
                                <div>
                                  <button mat-button (click)="exportAs('pdf', 'l','whole')">
                                    Export Whole Data to PDF
                                  </button>
                                </div>-->
                                <div>
                                    <button type="button" mat-button (click)="exportAllData()">
                                        Export All Data
                                    </button>
                                </div>
                            </mat-menu>
                        </form>
                    </div>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <mat-toolbar class="bg-card border-b h-[47px]" role="heading" fuseScrollbar>
                    <span class="text-lg font-medium leading-6 tracking-tight" *ngIf="!tableOptions.hideTitle">
                        {{t(changeHeader(tableOptions.title)) }}
                        <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm "
                            *ngIf="tableOptions.pageTotal !== 0 && tableOptions.title !== '' ">
                            {{tableOptions.pageTotal}} </span>
                    </span>
                    <button class="px-6 text-lg font-semibold mini-form-field" mat-stroked-button
                        *ngIf="tableOptions.add && !tableOptions.addText" type="button" matTooltip="Add New Record"
                        color="primary" (click)="AddTableRecord()" id="addbtn{{tableOptions.title}}">
                        <!--<mat-icon class="mb-1 mat-18">add</mat-icon>-->
                        Add
                    </button>
                    <button class="px-1 mini-form-field" mat-stroked-button
                        *ngIf="tableOptions.add && tableOptions.addText" type="button"
                        [matTooltip]="tableOptions.addText" color="primary" (click)="AddTableRecord()"
                        id="addbtn{{tableOptions.title}}">
                        <mat-icon class="mat-18" *ngIf="tableOptions.addIcon">{{tableOptions.addIcon}}</mat-icon>
                        <mat-icon class="mat-18" *ngIf="!tableOptions.addIcon">add</mat-icon>
                        <span class="mr-1"> {{tableOptions.addText}}</span>
                    </button>
                    <div class="spacer"></div>
                    <!-- For small screen -->
                    <div class="flex flex-row items-end" *ngIf="tableOptions.floatingFilter">
                        <button type="button" class="-ml-2 sm:hidden" mat-icon-button (click)="drawer.toggle()">
                            <!-- <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon> -->
                            <mat-icon color="primary">settings</mat-icon>
                        </button>
                        <div class="flex gap-[15px] hidden sm:inline-flex">
                            <form class="flex flex-row items-center justify-center">
                                <mat-form-field class="pl20" style="padding: 10px;font-size: 15px;"
                                    *ngIf="tableOptions.dropdwn">
                                    <mat-select [multiple]="false" placeholder="Asset"
                                        (selectionChange)="filterDrpLstChange($event.value)"
                                        [formControl]="assetDrpCtrl">
                                        <mat-option
                                            *ngFor="let item of tableOptions.filterDrpLstFilteredList; trackBy: trackByFn"
                                            [value]="item._id">
                                            {{item.ip}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!--<div class="flex flex-row items-end" *ngIf="tableOptions.add"></div>-->
                                <mat-form-field floatLabel="never"
                                    class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-[200px]">
                                    <input matInput class="form-control" [(ngModel)]="filterText" type="text"
                                        trim="blur" name="filterText{{tableOptions.id}}"
                                        id="{{tableOptions.id}}_filter_sub" (ngModelChange)="filterUpdate.next($event)"
                                        placeholder="Search">
                                    <button class="gray" matSuffix mat-icon-button type="button"
                                        *ngIf="!filterText || filterText === ''" color="primary">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button class="gray" matSuffix mat-icon-button type="button"
                                        *ngIf="filterText && filterText !== ''"
                                        (click)="filterText = ''; doFilter(filterText);" color="primary">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                                    <button type="button" class="gray" matSuffix mat-icon-button
                                        matTooltip="Reload Table Data" (click)="RefreshTableData();" color="primary">
                                        <mat-icon id="rfrbtn{{tableOptions.title}}"
                                            svgIcon="mat_outline:refresh"></mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row items-end" *ngIf="tableOptions.showFilter">
                                    <button type="button" class="gray" matSuffix mat-icon-button
                                        matTooltip="Filter Table Data" (click)="FilterTableData();" color="primary">
                                        <mat-icon id="rfrbtn{{tableOptions.title}}">filter_alt</mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row items-end" *ngIf="tableOptions.showTagFilter">
                                    <button type="button" class="gray" matSuffix mat-icon-button
                                        matTooltip="Filter by Tags" (click)="FilterTagTableData();" color="primary">
                                        <mat-icon id="rfrbtn{{tableOptions.title}}"
                                            svgIcon="heroicons_solid:tag"></mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="tableOptions.showmanualfilter">
                                    <mat-form-field
                                        class="mini-form-field w-[160px] min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                        <mat-select [(ngModel)]="selectedchecks" [ngModelOptions]="{standalone: true}"
                                            (selectionChange)="Manualfilterchecks($event.value)"
                                            matTooltip="Vulnerability Confirmed">
                                            <mat-option value="All" selected>All</mat-option>
                                            <mat-option value="yes" id="RefreshOnemin">Confirmed</mat-option>
                                            <mat-option value="No" id="RefreshFivemin">Unconfirmed</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="tableOptions.fontsizeData">
                                    <mat-form-field
                                        class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                        <mat-select [(value)]="selectedFont"
                                            (selectionChange)="textSizeCallData($event)" matTooltip="Change Font Size">
                                            <mat-option value="small" selected>Small</mat-option>
                                            <mat-option value="medium" id="mediumtext">Medium</mat-option>
                                            <mat-option value="large" id="largeText">Large</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="tableOptions.refreshData">
                                    <mat-form-field
                                        class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                        <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                                            matTooltip="Refresh Interval">
                                            <mat-option value="0" selected>No Refresh</mat-option>
                                            <mat-option value="60" id="RefreshOnemin">1 Min</mat-option>
                                            <mat-option value="300" id="RefreshFivemin">5 Min</mat-option>
                                            <mat-option value="1800" id="RefreshThirtymin">30 Min</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                                    *ngIf="tableOptions.showColFilter && _sTableOptions.columns && _sTableOptions.columns.length"
                                    [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                                    <mat-icon>filter_list</mat-icon>
                                </button>
                                <mat-menu #filterMenu="matMenu" class="col-filters">
                                    <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                        <mat-card-header>
                                            <mat-card-title>Filter</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <form *ngIf="_sTableOptions && _sTableOptions.columns">
                                                <ng-container
                                                    *ngFor="let column of _sTableOptions.columns; trackBy: trackByFn">
                                                    <mat-form-field *ngIf="column.iscolumnSearch" class="w-full">
                                                        <input matInput type="text" trim="blur" [id]="column.id"
                                                            placeholder="Search {{column.header}}"
                                                            *ngIf="!column.selectFilter" name="{{column.columnDef}}"
                                                            [(ngModel)]="colfilter[column.columnDef]"
                                                            (input)='columnFilter($any($event.target).value,column.columnDef)'
                                                            [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch ">
                                                        <mat-select placeholder="Choose filter value"
                                                            name="{{column.columnDef}}" *ngIf="column.selectFilter"
                                                            [(ngModel)]="colfilter[column.columnDef]"
                                                            [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch "
                                                            (selectionChange)="columnFilter($event.value, column.columnDef)">
                                                            <mat-option value="">All</mat-option>
                                                            <mat-option
                                                                *ngFor="let val of column.selectFilterArr; trackBy: trackByFn"
                                                                [value]="val.value"
                                                                [id]="val.id">{{val.name}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-container>
                                            </form>
                                        </mat-card-content>
                                    </mat-card>
                                </mat-menu>
                                <button type="button" mat-icon-button menuOpen="false" (click)="openListColumns()"
                                    #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="belowMenu" color="primary"
                                    id="view{{tableOptions.id}}" matTooltip="View Column, Reorder Columns"
                                    *ngIf="tableOptions.showhideList">
                                    <mat-icon>view_column</mat-icon>
                                </button>
                                <mat-menu #belowMenu="matMenu" [hasBackdrop]="true" yPosition="below" class="p-4">
                                    <div cdkDropList (cdkDropListDropped)="drop($event)">
                                        <div class="flex flex-row items-center w-full"
                                            *ngFor="let item of showhideList; let i = index; trackBy: trackByFn"
                                            cdkDrag>
                                            <i class="mt-2 mr-1 cursor-move material-icons color_icon"> drag_handle </i>
                                            <mat-checkbox color="primary" [checked]="item.visible"
                                                (click)="$event.stopPropagation()" (change)="showHideColumn(i, item)"
                                                class="left-align" [id]="item.header">
                                                {{item.header}} </mat-checkbox>
                                        </div>
                                    </div>
                                </mat-menu>
                                <button type="button" mat-icon-button menuOpen="false" matTooltip="Filter List"
                                    *ngIf="sTableOptions.cFilter" color="primary" [matMenuTriggerFor]="filterMenu"
                                    #filterMenuTrigger="matMenuTrigger">
                                    <mat-icon class="">filter_list</mat-icon>
                                </button>
                                <mat-menu #filterMenu="matMenu" class="col-filters">
                                    <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                        <mat-card-header>
                                            <mat-card-title>Filter</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <form #filterForm="ngForm" role="form">
                                                <mat-form-field
                                                    *ngIf="sTableOptions.columns && sTableOptions.columns.length"
                                                    class="w-full" appearance="outline">
                                                    <mat-label>Choose filter column</mat-label>
                                                    <mat-select placeholder="Choose filter column" name="chooseFilter"
                                                        required [(ngModel)]="cFilter.selectedCol">
                                                        <mat-option value="">Choose filter column</mat-option>
                                                        <mat-option
                                                            *ngFor="let val of sTableOptions.columns; trackBy: trackByFn"
                                                            [value]="val.col" [id]="val.id">{{val.header}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="cFilter.selectedCol" class="w-full"
                                                    appearance="outline">
                                                    <mat-label>
                                                        Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                                                    <input matInput type="text" trim="blur"
                                                        *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                                                        placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                        required name="{{colHash[cFilter.selectedCol].col}}s"
                                                        [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                    <mat-select
                                                        placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                        name="{{colHash[cFilter.selectedCol].col}}s"
                                                        *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'"
                                                        required [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                        <mat-option
                                                            *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options; trackBy: trackByFn"
                                                            [value]="val.value">{{val.name}}</mat-option>
                                                    </mat-select>
                                                    <ng-container
                                                        *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                                                        <mat-date-range-input [rangePicker]="picker">
                                                            <input matStartDate name="startDate"
                                                                [(ngModel)]="colHash[cFilter.selectedCol].start"
                                                                placeholder="Start date">
                                                            <input matEndDate name="startDate"
                                                                [(ngModel)]="colHash[cFilter.selectedCol].end"
                                                                placeholder="End date">
                                                        </mat-date-range-input>
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="picker"></mat-datepicker-toggle>
                                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                                    </ng-container>
                                                </mat-form-field>
                                                <button type="button"
                                                    (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                                                    [disabled]="!filterForm.form.valid" color="primary"
                                                    mat-raised-button="">Add Filter
                                                </button>
                                                <button type="button" class="ml-2" (click)="cFilter.selectedCol = ''"
                                                    mat-stroked-button="">Clear
                                                </button>
                                            </form>
                                        </mat-card-content>
                                    </mat-card>
                                </mat-menu>
                                <button type="button" id="gbtn{{sTableOptions.title}}" (click)="apiDownload('type')"
                                    color="primary" matSuffix mat-icon-button *ngIf="tableOptions.apiDownload"
                                    matTooltip="Download as CSV" aria-label="download csv menu">
                                    <mat-icon class="mat-24">file_download</mat-icon>
                                </button>
                                <button type="button" id="gbtn{{sTableOptions.title}}" color="primary" matSuffix
                                    mat-icon-button *ngIf="!tableOptions.hideDownload" matTooltip="Download as CSV"
                                    aria-label="download csv menu" (click)="downloadAsXls('full')">
                                    <mat-icon class="mat-24">file_download</mat-icon>
                                </button>
                                <mat-menu #xlsMenu="matMenu">
                                    <button type="button" mat-menu-item id="currentSelection"
                                        *ngIf="tableOptions.filterDownload" (click)="downloadAsXls('filtered')">
                                        <mat-icon class="mat-24">filter_list</mat-icon>
                                        <span>Filtered Data</span>
                                    </button>
                                    <button type="button" mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                                        <mat-icon class="mat-24">file_download</mat-icon>
                                        <span>Full Data</span>
                                    </button>
                                </mat-menu>
                                <button type="button" mat-icon-button *ngIf="tableOptions.compareData"
                                    matTooltip="Compare Data" color="primary"
                                    (click)="CompareData(compSelection.selected)">
                                    <mat-icon>compare</mat-icon>
                                </button>
                                <button type="button" mat-icon-button matTooltip="Save Settings"
                                    id="save{{tableOptions.id}}" color="primary" [matMenuTriggerFor]="saveMenu">
                                    <mat-icon>save</mat-icon>
                                </button>
                                <mat-menu #saveMenu="matMenu">
                                    <button type="button" mat-menu-item id="saveSettingT" (click)="SaveSettings()">
                                        <mat-icon class="mat-24">save_alt</mat-icon>
                                        <span>Save Table Settings</span>
                                    </button>
                                    <button type="button" mat-menu-item id="restSetting" (click)="ResetSettings()"
                                        *ngIf="sessionData && sessionData.id">
                                        <mat-icon class="mat-24">refresh</mat-icon>
                                        <span>Reset Table Settings</span>
                                    </button>
                                </mat-menu>
                                <button type="button" mat-raised-button color="primary" (click)="publish()"
                                    *ngIf="tableOptions.publish">
                                    Publish
                                </button>
                                <a mat-icon-button matTooltip="Help Link"
                                    [href]="baseService.helpLinks[tableOptions.id]" target="_blank" color="primary"
                                    *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                                    <mat-icon>help</mat-icon>
                                </a>
                                <!--<button mat-icon-button menuOpen="false" (click)="openListExport()" matTooltip="Download"
                                  #exportMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="exportMenu" *ngIf="tableOptions.exportExcel">
                                  <mat-icon>cloud_download</mat-icon>
                                </button>-->
                                <button type="button" *ngFor="let pbtn of tableOptions.parentCalls; trackBy: trackByFn"
                                    mat-icon-button [matTooltip]="pbtn.tooltip" color="primary"
                                    (click)="ParentCalls(pbtn.id)">
                                    <mat-icon>{{pbtn.icon}}</mat-icon>
                                </button>

                                <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                                    <!--<div>
                                      <button mat-button (click)="ExportToExcel()">
                                        Export to Excel
                                      </button></div>
                                    <div>
                                      <button mat-button (click)="exportAs('pdf', 'l','current')">
                                        Export Current Page to PDF
                                      </button>
                                    </div>
                                    <div>
                                      <button mat-button (click)="exportAs('pdf', 'l','whole')">
                                        Export Whole Data to PDF
                                      </button>
                                    </div>-->
                                    <div>
                                        <button type="button" mat-button (click)="exportAllData()">
                                            Export All Data
                                        </button>
                                    </div>
                                </mat-menu>
                            </form>
                        </div>
                    </div>
                </mat-toolbar>
                <mat-toolbar class="select-tools h-[47px] rounded bg-[#d1e3ff] dark:bg-default"
                    *ngIf="selection.selected.length > 0 && tableOptions.isHideGlobal">
                    <span>{{selection.selected.length}} {{tableOptions.selectText}} selected.</span>
                    <div class="flex flex-1 place-content-end">
                        <ng-container>
                            <button type="button" mat-flat-button color="primary"
                                id="gbtnSelction{{tableOptions.title}}" mat-raised-button
                                [matMenuTriggerFor]="gactionSMenu" class="mini-form-field"
                                [disabled]="selection.selected.length === 0" aria-label="global action with a menu">
                                Global Actions
                            </button>
                            <mat-menu #gactionSMenu="matMenu">
                                <ng-container *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                                    <ng-container *ngIf="item.companyCondCheck">
                                        <a id="ganchsel{{item.text}}" class="mat-primary"
                                            *ngIf="item.isGlobal && commonService.currentScope !== '*'" mat-menu-item
                                            (click)="globalActionCall(selection.selected, item)">
                                            <mat-icon
                                                *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                                            <mat-icon *ngIf="item.icon && item.icon.includes(':')"
                                                [svgIcon]="item.icon"></mat-icon>
                                            <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                                            <span>{{ item.text }}</span>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="!item.companyCondCheck">
                                        <a id="ganchsel{{item.text}}" class="mat-primary" *ngIf="item.isGlobal"
                                            mat-menu-item (click)="globalActionCall(selection.selected, item)">
                                            <mat-icon
                                                *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                                            <mat-icon *ngIf="item.icon && item.icon.includes(':')"
                                                [svgIcon]="item.icon"></mat-icon>
                                            <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                                            <span>{{ item.text }}</span>
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </mat-menu>
                        </ng-container>
                        <button class="gray" type="button" matSuffix mat-icon-button (click)="clearSelection();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-toolbar>
                <filter-widget [showFilter]="showFilter" [sColumns]="sColumns" (resetRulesFn)="setFilterRules($event)"
                    [table_id]="sTableOptions.tableOptions.id" (toggleFilterFn)="toggleFilterRules($event)"
                    (setRulesFn)="setFilterRules($event)"></filter-widget>
                <app-filter *ngIf="showTagFilter" [showTagFilter]="showTagFilter"
                    (toggleTagFilterFn)="toggleTagFilterRules($event)"
                    (setTagRulesFn)="setTagFilterRules($event)"></app-filter>
                <mat-card-content [ngClass]="{'mt-2': colFilters.length}" class="p-0 mb-0">
                    <mat-progress-bar mode="indeterminate" *ngIf="tableOptions.loading"></mat-progress-bar>
                    <div #MAINTABLEDIV class="w-full h-full py-2 overflow-auto bg-card">
                        <table id="mytable" class="w-full" [ngClass]="{'tablePadding': isTablePadding===true}" mat-table
                            [dataSource]="dataSource" *ngIf="dataSource" matSort #MainTable
                            [matSortActive]="_sTableOptions['sortOptions']['active']"
                            [matSortDirection]="_sTableOptions['sortOptions']['direction']"
                            (matSortChange)="customSort($event)" fixedLayout="true" [trackBy]="trackById">
                            <ng-container matColumnDef="select" *ngIf="tableOptions.rowSelection" sticky>
                                <th mat-header-cell *matHeaderCellDef class="py-2 dark:bg-gray-700">
                                    <mat-checkbox color="primary" class="stc mini-form-field"
                                        (change)="$event ? masterToggle() : null" id="_selectDeselectAll"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row; let i = index"
                                    class="py-2  bg-white z-5 dark:bg-[#1e293b]">

                                    <mat-checkbox color="primary" class="stc mini-form-field"
                                        (click)="$event.stopPropagation(); shiftKeyDown($event, i)" [id]="row._id"
                                        (change)="$event ? rowToggle(row) : null"
                                        [checked]="selection.isSelected(row)"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="compare" *ngIf="tableOptions.compareData">
                                <th mat-header-cell *matHeaderCellDef class="py-2">Compare
                                    <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let row; let i = index" class="py-2">
                                    <mat-checkbox class="stc mini-form-field" color="primary"
                                        (click)="$event.stopPropagation()"
                                        (change)="$event ? compSelection.toggle(row) : null"
                                        [checked]="compSelection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of _sTableOptions.columns; trackBy: trackByFn"
                                [matColumnDef]="column.columnDef">
                                <th mat-header-cell *matHeaderCellDef [style.width]="column.width"
                                    [style.max-width]="column.width" [style.min-width]="column.width"
                                    class="font-medium dark:bg-gray-700" [ngClass]="fontSizeClassHed">
                                    <mat-label mat-sort-header class=""
                                        [disabled]="(column.isSort === undefined || column.isSort === null) ? 'false' : !column.isSort ">
                                        <span class="font-bold leading-6 tracking-tight truncate text-small">{{
                                            t(changeHeader(column.header)) }}</span></mat-label>
                                </th>
                                <td mat-cell *matCellDef="let row"
                                    [ngClass]="{'text-center': column.align === 'center',fontSizeClass,
                                'text-left': !column.align || column.align === 'left', 'text-right': column.align === 'right'}"
                                    class="text-sm wwbreak" [style.width]="column.width"
                                    [style.vertical-align]="'middle'" [style.max-width]="column.width"
                                    [style.min-width]="column.width">
                                    <span *ngIf="column.filter">
                                        <ng-container *ngIf="!column.isCondition">
                                            <span
                                                *ngIf="column.isHyperlink && !column.isToolTip && !column.rangeColor && !column.isShowMoreData"
                                                [ngClass]="fontSizeClass">
                                                <ng-container
                                                    *ngIf="column.isTarget; then thenTemplate; else elseTemplate"></ng-container>
                                                <ng-template #thenTemplate>
                                                    <div
                                                        innerHTML="{{getProperty(row, column.columnDef) | formatCell:column.filter}}">
                                                    </div>
                                                </ng-template>
                                                <ng-template #elseTemplate>
                                                    <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                        (click)="hyperLinkCall(row, column.columnDef)"
                                                        [id]="getProperty(row, column.columnDef)">
                                                        {{getProperty(row, column.columnDef) |
                                                        formatCell:column.filter}}
                                                    </a>
                                                </ng-template>
                                            </span>
                                            <ng-container
                                                *ngIf="!column.isHyperlink && !column.isHtml && !column.rangeColor && !column.htmlIcon && !column.isShowMoreData">
                                                <ng-container *ngIf="!column.key_name">
                                                    <span *ngIf="column.isToolTip" matTooltipClass="example-tooltip-red"
                                                        [matTooltipPosition]="'right'" [ngClass]="fontSizeClass"
                                                        matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                                                        {{getProperty(row, column.columnDef) |
                                                        formatCell:column.filter}}</span>
                                                    <span *ngIf="!column.isToolTip"
                                                        [ngClass]="fontSizeClass">{{getProperty(row,
                                                        column.columnDef) |
                                                        formatCell:column.filter}}</span>
                                                </ng-container>
                                                <ng-container *ngIf="column.key_name">
                                                    <ng-container *ngIf="!column.clipboard">
                                                        <span [ngClass]="fontSizeClass">{{getProperty(row,
                                                            column.key_name)
                                                            |
                                                            formatCell:column.filter}}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="column.clipboard">
                                                        <div style="display: flex; align-items: center; width: 100%;">
                                                            <span
                                                                style="flex-grow: 1; text-align: left; position: relative; cursor: pointer;"
                                                                [ngClass]="fontSizeClass"
                                                                (click)="commonService.copyClip(getProperty(row, column.key_name))"
                                                                matTooltip='Copy Hostname' matTooltipPosition="above">
                                                                {{ getProperty(row, column.key_name) |
                                                                formatCell:column.filter }}
                                                            </span>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <span [ngClass]="fontSizeClass"
                                                *ngIf="!column.isHyperlink && column.isHtml && !column.rangeColor && !column.htmlIcon && !column.filter && !column.isShowMoreData">
                                                <div [innerHTML]="getProperty(row, column.columnDef) | safeHTML"></div>
                                                <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
                                            </span>
                                            <span [ngClass]="fontSizeClass"
                                                *ngIf="!column.isHyperlink && column.isHtml && column.filter && !column.htmlIcon && !column.rangeColor && !column.isShowMoreData">
                                                <div [innerHTML]="getProperty(row, column.columnDef) | safeHTML"></div>
                                                <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
                                            </span>
                                            <span [ngClass]="fontSizeClass"
                                                *ngIf="!column.isHyperlink && column.isHtml && column.filter && column.htmlIcon && !column.rangeColor && !column.isShowMoreData">
                                                <div
                                                    [innerHTML]="getProperty(row, column.columnDef) | formatCell:column.filter">
                                                </div>
                                                <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
                                            </span>
                                            <ng-container *ngIf="column.rangeColor && !column.isShowMoreData">
                                                <span class="label" [ngClass]="fontSizeClass"
                                                    *ngIf="getProperty(row, column.columnDef) != '-' && !column.isMultiRange"
                                                    [ngStyle]="getRangeColor(getProperty(row, column.columnDef), column.columnDef)">
                                                    {{getProperty(row, column.columnDef)| formatCell:column.filter}}
                                                </span>
                                                <ng-container *ngIf="column.isMultiRange">
                                                    <ng-container
                                                        *ngFor="let colu of sTableOptions.customScore; trackBy: trackByFn">
                                                        <span class="mr-2 label" [ngClass]="fontSizeClass"
                                                            [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                            *ngIf="getProperty(row, colu.key) != '-' && colu.filter"
                                                            [ngStyle]="getRangeColor(getProperty(row, colu.key), colu.key)">
                                                            {{getProperty(row, colu.key) | formatCell:colu.filter}}
                                                        </span>
                                                        <span class="mr-2 label" [ngClass]="fontSizeClass"
                                                            [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                            *ngIf="getProperty(row, colu.key) != '-' && !colu.filter"
                                                            [ngStyle]="getRangeColor(getProperty(row, colu.key), colu.key)">
                                                            {{getProperty(row, colu.key)}}
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="column.isSecurityGrade && !column.isShowMoreData">
                                                <div class="relative flex items-center justify-center w-4 h-4 max-w-xs p-1 m-1 ml-6 text-sm font-bold text-white rounded-full right-1"
                                                    [ngClass]="{
                                                'bg-yellow-600': (row | formatCell:column.filter) === '-' || (row | formatCell:column.filter) === 'B' || (row | formatCell:column.filter) === 'C',
                                                'bg-green-500': (row | formatCell:column.filter) === 'A',
                                                'cs-medium': (row | formatCell:column.filter) === 'D',
                                                'cs-high': (row | formatCell:column.filter) === 'E',
                                                'cs-critical': (row | formatCell:column.filter) === 'F',
                                                fontSizeClass
                                                }">
                                                    {{(row | formatCell:column.filter)}}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="column.isShowMoreData">
                                                <div class="p-1 break-normal w-[20vw]"
                                                    *ngIf="getProperty(row, column.columnDef)">
                                                    <div class="flex flex-wrap w-auto gap-1 pt-1"
                                                        [ngClass]="fontSizeClass">
                                                        <show-more-less
                                                            [inputString]="getProperty(row, column.columnDef)| formatCell:column.filter"
                                                            [showAllItems]="false" [type]="'string'" [wordBreak]="true"
                                                            [bgColor]="'bg-yellow-200'" [color]="'text-blue-900'"
                                                            [maxItemsToShow]="10" [showOrder]="'span'">
                                                        </show-more-less>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="column.isCondition">
                                            <span class=""
                                                *ngIf="column.operators && column.operators.condition && column.operators.condition(getProperty(row, column.operators.first), getProperty(row, column.operators.second))"
                                                [ngClass]="[fontSizeClass]">
                                                {{ getProperty(row, column.columnDef) | formatCell:column.filter}}
                                            </span>
                                            <span class=""
                                                *ngIf="column.operators && column.operators2 && column.operators.condition && column.operators2.condition && !column.operators.condition(getProperty(row, column.operators.first), getProperty(row, column.operators.second)) &&  column.operators2.condition(getProperty(row, column.operators2.first), getProperty(row, column.operators2.second), getProperty(row, column.operators2.third))"
                                                [ngClass]="[fontSizeClass]">
                                                {{ getProperty(row, column.columnDef) | formatCell:column.filter}}
                                            </span>
                                            <span
                                                *ngIf="!column.operators && getProperty(row, column.columnDef) != '-'  && getProperty(row, column.columnDef) !== null && column.condition.value?.indexOf(getProperty(row, column.condition.key)) > -1"
                                                class="" [ngClass]="[fontSizeClass]">
                                                <span class="leading-relaxed whitespace-nowrap">
                                                    {{getProperty(row, column.columnDef) | formatCell:column.filter}}
                                                </span>
                                            </span>
                                        </ng-container>
                                    </span>
                                    <ng-container *ngIf="column.hasMultiData">
                                        <div class="flex flex-wrap gap-2"
                                            *ngFor="let col of column.colList; trackBy: trackByFn">
                                            <span *ngIf="col.isHyperlink && !col.isToolTip" [ngClass]="fontSizeClass">
                                                <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                    (click)="hyperLinkCall(row, col.col)"
                                                    [id]="getProperty(row, column.columnDef)">
                                                    <span>{{ getProperty(row, col.col)}}</span>
                                                </a>
                                            </span>
                                            <span *ngIf="col.isToolTip && !col.isHyperlink" [ngClass]="fontSizeClass"
                                                matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                                matTooltip="{{ getProperty(row, col.isToolTipCol) }}">{{
                                                getProperty(row, col.col) }}</span>
                                            <span *ngIf="col.isHyperlink && col.isToolTip" [ngClass]="fontSizeClass"
                                                matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                                matTooltip="{{ getProperty(row, col.isToolTipCol) }}">
                                                <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                    [id]="getProperty(row, column.columnDef)"
                                                    (click)="hyperLinkCall(row, col.col)">
                                                    <span>{{ getProperty(row, col.col)}}</span>
                                                </a>
                                            </span>
                                            <span *ngIf="col.isProgressCntrl">
                                                <div class="mat-elevation-z2"
                                                    [ngClass]="{'progress-s': getProperty(row, 'warn') > getProperty(row, col.col),
                                        'progress-d':getProperty(row, 'warn') <= getProperty(row, col.col),fontSizeClass }">
                                                    <span class="percent-b">{{getProperty(row, col.col)}}%</span>
                                                    <div class="p-bar" [style.width]="getProperty(row, col.col)+'%'">
                                                    </div>
                                                </div>
                                            </span>
                                            <span *ngIf="col.isColoredCntrl" [ngClass]="fontSizeClass">
                                                <span *ngIf="getProperty(row, col.col) == '-'">{{getProperty(row,
                                                    col.col)}}</span>
                                                <span class="label" *ngIf="getProperty(row, col.col) != '-'"
                                                    [ngStyle]="{'background-color':col.color}">{{getProperty(row,
                                                    col.col)}}</span>
                                            </span>
                                            <span *ngIf="col.isfaicon" [ngClass]="fontSizeClass">
                                                <i class="{{col.class }}" [ngStyle]="{color: col.color}"></i>
                                            </span>
                                            <span *ngIf="col.filter" [ngClass]="fontSizeClass">
                                                {{getProperty(row, col.col) | formatCell:col.filter}}
                                            </span>
                                            <span *ngIf="col.isAddingText" [ngClass]="fontSizeClass">
                                                {{col.addingText }}{{ getProperty(row, col.col)}}
                                            </span>
                                            <span *ngIf="col.img && col.imgPath" [ngClass]="fontSizeClass">
                                                <img [src]="col.imgPath+'generic.svg'"
                                                    *ngIf="!getProperty(row, col.col)" alt="generic"
                                                    class="{{col.class}}">
                                                <img [src]="col.imgPath+getProperty(row, col.col)"
                                                    *ngIf="getProperty(row, col.col)" alt="logo" class="{{col.class}}">
                                            </span>
                                            <span *ngIf="col.img && col.iconPath" [ngClass]="fontSizeClass">
                                                <mat-icon [svgIcon]="col.iconPath" *ngIf="!getProperty(row, col.col)"
                                                    class="{{col.class}}"></mat-icon>
                                                <mat-icon [svgIcon]="col.iconPath+getProperty(row, col.col)"
                                                    *ngIf="!getProperty(row, col.col)" class="{{col.class}}"></mat-icon>
                                            </span>
                                            <span [ngClass]="fontSizeClass"
                                                *ngIf="!col.isHyperlink && !col.isProgressCntrl && !col.isColoredCntrl && !col.isToolTip
                                                        && !col.filter && !col.isfaicon && !col.isAddingText && !col.img" class="ml-1">
                                                {{ getProperty(row, col.col) }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container>
                                        <span *ngIf="column.lengthcontrol" [ngClass]="fontSizeClass">
                                            <span *ngIf="(getProperty(row,
                                        column.columnDef)).length>2 || !row.showAllFlag">{{getProperty(row,
                                                column.columnDef) | slice:0:2 }}</span>
                                            <span *ngIf="(getProperty(row,
                                        column.columnDef)).length<2 || row.showAllFlag">{{getProperty(row,
                                                column.columnDef)}}</span>
                                            <button type="button" class="text-primary-400" *ngIf="(getProperty(row,
                                        column.columnDef)).length>2 " (click)="showAll(row)">
                                                <p *ngIf="!row.showAllFlag">...</p>
                                                <p class="ml-1" *ngIf="row.showAllFlag"> close</p>
                                            </button>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="column.isProgressCntrl">
                                        <div class="mat-elevation-z2" [ngClass]="{'progress-s': getProperty(row, 'warn') > getProperty(row, column.columnDef),fontSizeClass,
                          'progress-d':getProperty(row, 'warn') <= getProperty(row, column.columnDef) }">
                                            <span class="percent-b">{{getProperty(row, column.columnDef)}}%</span>
                                            <div class="p-bar" [style.width]="getProperty(row, column.columnDef)+'%'">
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- <ng-container *ngIf="column.isDynamicCntrl">
                                          <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                                    </ng-container> -->

                                    <ng-container *ngIf="column.isColoredCntrl">
                                        <ng-container
                                            *ngIf="column.isCustomText; then thenTemplate; else elseTemplate"></ng-container>
                                        <ng-template #thenTemplate>
                                            <ng-container *ngIf="column.isCustomClass">
                                                <ng-container *ngIf="!column.isCondition">
                                                    <span
                                                        *ngIf="!column.isCustomFilter && getProperty(row, column.columnDef) != '-'  && getProperty(row, column.columnDef) !== null"
                                                        class="inline-flex items-center font-bold px-2.5 py-0.5 rounded-full tracking-wide"
                                                        [ngClass]="[getCustomClass(getProperty(row, column.columnDef), column.columnDef), fontSizeClass]">
                                                        <span class="leading-relaxed whitespace-nowrap">
                                                            {{getCustomText(getProperty(row, column.columnDef),
                                                            column.columnDef) }}
                                                        </span>
                                                    </span>
                                                    <span
                                                        *ngIf="column.isCustomFilter && getProperty(row, column.columnDef)  != '-'  && getProperty(row, column.columnDef)  !== null"
                                                        class="inline-flex items-center font-bold px-2.5 py-0.5 rounded-full tracking-wide"
                                                        [ngClass]="[getCustomClass(getProperty(row, column.columnDef) | formatCell:column.isCustomFilter), fontSizeClass]">
                                                        <span class="leading-relaxed whitespace-nowrap">
                                                            {{getCustomText(getProperty(row, column.columnDef) |
                                                            formatCell:column.isCustomFilter) }}
                                                        </span>
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="column.isCondition">
                                                    <span
                                                        class="inline-flex items-center font-bold px-2.5 py-0.5 rounded-full tracking-wide"
                                                        *ngIf="column.operators && column.operators.condition && column.operators.condition(getProperty(row, column.operators.first), getProperty(row, column.operators.second))"
                                                        [ngClass]="[getCustomClass(getProperty(row, column.columnDef)), fontSizeClass]">
                                                        {{ getCustomText(getProperty(row, column.columnDef)) }}
                                                    </span>
                                                    <span
                                                        class="inline-flex items-center font-bold px-2.5 py-0.5 rounded-full tracking-wide"
                                                        *ngIf="column.operators && column.operators2 && column.operators.condition && column.operators2.condition && !column.operators.condition(getProperty(row, column.operators.first), getProperty(row, column.operators.second)) &&  column.operators2.condition(getProperty(row, column.operators2.first), getProperty(row, column.operators2.second), getProperty(row, column.operators2.third))"
                                                        [ngClass]="[getCustomClass(getProperty(row, column.columnDef)), fontSizeClass]">
                                                        {{ getCustomText(getProperty(row, column.columnDef)) }}
                                                    </span>
                                                    <span
                                                        *ngIf="!column.operators && getProperty(row, column.columnDef) != '-'  && getProperty(row, column.columnDef) !== null && column.condition.value?.indexOf(getProperty(row, column.condition.key)) > -1"
                                                        class="inline-flex items-center font-bold px-2.5 py-0.5 rounded-full tracking-wide"
                                                        [ngClass]="[getCustomClass(getProperty(row, column.columnDef)), fontSizeClass]">
                                                        <span class="leading-relaxed whitespace-nowrap">
                                                            {{getCustomText(getProperty(row, column.columnDef)) }}
                                                        </span>
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="!column.isCustomClass">
                                                <!-- <span *ngIf="getProperty(row, column.columnDef) == '-'">{{getCustomText(getProperty(row, column.columnDef))}}</span> -->
                                                <span class="label" *ngIf="getProperty(row, column.columnDef) != '-'"
                                                    [ngClass]="fontSizeClass"
                                                    [ngStyle]="getCustomColor(getProperty(row, column.columnDef))">{{getCustomText(getProperty(row,
                                                    column.columnDef)) }}</span>
                                            </ng-container>
                                        </ng-template>
                                        <ng-template #elseTemplate>
                                            <span [ngClass]="fontSizeClass"
                                                *ngIf="getProperty(row, column.columnDef) == '-'">{{getProperty(row,
                                                column.columnDef)}}</span>
                                            <span [ngClass]="fontSizeClass" class="label"
                                                *ngIf="getProperty(row, column.columnDef) != '-'"
                                                [ngStyle]="{'background-color':column.color}">{{getProperty(row,
                                                column.columnDef)}}</span>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="column.statusicon">
                                        <img class="vendor-logo {{column.class}}" [src]='getIconPath(row, column)'
                                            [ngClass]="fontSizeClass"
                                            *ngIf="column.columnDef !== 'status' && column.columnDef !== 'asset_status' && column.columnDef !== 'online_status' && !column.customCondition && column.columnDef !== 'is_valid'"
                                            [matTooltip]='getIconTooltip(row, column)' [matTooltipPosition]="'right'"
                                            [alt]='getIconTooltip(row, column)'>

                                        <img [src]='getIconPath(row, column)' alt="Online" class="w-8"
                                            [ngClass]="fontSizeClass"
                                            *ngIf="(column.columnDef === 'status' || column.columnDef === 'asset_status' || column.columnDef === 'online_status') && getProperty(row, column.columnDef) !== 'NoData' && !column.customCondition && column.columnDef !== 'is_valid'"
                                            [matTooltip]='getIconTooltip(row, column)' [matTooltipPosition]="'right'"
                                            [alt]='getIconTooltip(row, column)' height="512" width="512">

                                        <img [src]='getIconPath(row, column)' alt="Online" class="w-8"
                                            [ngClass]="fontSizeClass"
                                            *ngIf="(column.columnDef === 'status' || column.columnDef === 'asset_status' || column.columnDef === 'online_status') && getProperty(row, column.columnDef) !== 'NoData' && column.customCondition && column.columnDef !== 'is_valid' && column.allowedCondition.indexOf(getPropertyCondition(row, column.customCondition.key)) !== -1"
                                            [matTooltip]='getIconTooltip(row, column)' [matTooltipPosition]="'right'"
                                            [alt]='getIconTooltip(row, column)' height="512" width="512">
                                        <div>
                                            <span [ngClass]="fontSizeClass" hidden
                                                [innerHTML]="getIconTooltip(row, column) | safeHTML"></span>
                                        </div>
                                        <ng-container *ngIf=" column.columnDef === 'is_valid'">
                                            <img class="vendor-logo {{column.class}}" [src]='getIconPath(row, column)'
                                                [ngClass]="fontSizeClass" [matTooltip]='getIconTooltip(row, column)'
                                                [matTooltipPosition]="'right'" [alt]='getIconTooltip(row, column)'>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!column.filter">
                                        <ng-container *ngIf="column.icon">
                                            <ng-container>
                                                <span [ngClass]="fontSizeClass">
                                                    <ng-container
                                                        *ngFor="let icon of column.iconList; trackBy: trackByFn">
                                                        <mat-icon [ngClass]="[icon.class]"
                                                            *ngIf="getProperty(row, column.columnDef) === icon.value">
                                                            {{icon._icon}}
                                                        </mat-icon>
                                                    </ng-container>
                                                </span>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngFor="let img of column.conditionList; trackBy: trackByFn">
                                            <img class="{{img.class}}" [src]="img._img" [alt]="img.value"
                                                [ngClass]="fontSizeClass"
                                                *ngIf="getProperty(row, column.columnDef) === img.value"
                                                [matTooltipPosition]="'right'" [matTooltip]="img.displaytext">
                                            <div>
                                                <span hidden *ngIf="getProperty(row, column.columnDef) === img.value"
                                                    [ngClass]="fontSizeClass"
                                                    [innerHTML]="getProperty(row, column.columnDef)"></span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="column.img && column.imgPath && !column.isHyperlink">
                                            <!--[src]="column.imgPath+'generic.svg'" alt="generic"-->
                                            <img [ngSrc]="column.imgPath+'generic.svg'" alt="generic" width="32"
                                                [ngClass]="fontSizeClass" height="32"
                                                *ngIf="getProperty(row, column.columnDef) === null || getProperty(row, column.columnDef) === undefined"
                                                class="{{column.class}}">

                                            <!--[src]="column.imgPath+getProperty(row, column.columnDef)"-->
                                            <img [ngSrc]="column.imgPath+getProperty(row, column.columnDef)"
                                                [ngClass]="fontSizeClass" alt="generic" width="32" height="32"
                                                [alt]="getProperty(row, column.columnDef)"
                                                title="{{column.isToolTipCol === undefined? '': getProperty(row, column.isToolTipCol)}}"
                                                *ngIf="getProperty(row, column.columnDef) !== null &&
                                                   getProperty(row, column.columnDef) !== undefined "
                                                class="{{column.class}}">
                                            <div>
                                                <span hidden [innerHTML]="getProperty(row, column.columnDef)"></span>
                                            </div>

                                        </ng-container>

                                        <!-- <ng-container *ngIf="column.isAddingText">
                                              {{ tableOptions.addingText}}
                                            </ng-container> -->

                                        <ng-container *ngIf="column.isAddingText" [ngClass]="fontSizeClass">
                                            {{ column.addingText }} {{ getProperty(row, column.columnDef) }}
                                        </ng-container>

                                        <span [ngClass]="fontSizeClass"
                                            *ngIf="column.isHyperlink && !column.isToolTip && !column.isTargetLink && !column.isClass">
                                            <ng-container *ngIf="column.hyperLinks && column.hyperLinks.hotLink">
                                                <ng-container *ngIf="getProperty(row, column.columnDef)">
                                                    <ng-container *ngIf="column.hyperLinks.hotLink">
                                                        <!-- *ngIf="getProperty(row, column.columnDef).indexOf(column.hyperLinks.match) > -1" -->
                                                        <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                            *ngIf="getProperty(row, column.columnDef) && getProperty(row, column.columnDef).toString().includes(column.hyperLinks.match)"
                                                            target="_blank"
                                                            [href]="column.hyperLinks.hotLink + getProperty(row, column.columnDef)">
                                                            <span>{{getProperty(row, column.columnDef)}}</span>
                                                        </a>
                                                        <div
                                                            *ngIf="getProperty(row, column.columnDef) && !getProperty(row, column.columnDef)?.toString()?.includes(column.hyperLinks.match)">
                                                            <span>{{getProperty(row, column.columnDef)}}</span>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="!column.hyperLinks || !column.hyperLinks.hotLink">
                                                <ng-container
                                                    *ngIf="column.isTarget; then thenTemplate; else elseTemplate"></ng-container>
                                                <ng-template #thenTemplate>
                                                    <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                                                </ng-template>
                                                <ng-template #elseTemplate>
                                                    <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                        (click)="hyperLinkCall(row, column.columnDef)"
                                                        [id]="getProperty(row, column.columnDef)">
                                                        <ng-container *ngIf="column.badge">
                                                            <span class="label"
                                                                [ngClass]="getProperty(row, column.columnDef) === 0 ? 'bg-red-600 text-white' : 'bg-green-600 text-white'"
                                                                *ngIf="getProperty(row, column.columnDef) != '-'">
                                                                {{getProperty(row, column.columnDef)}}
                                                            </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="!column.badge">
                                                            <span>{{
                                                                getProperty(row, column.columnDef) |
                                                                formatCell:column.filter}}</span>
                                                        </ng-container>
                                                    </a>
                                                </ng-template>
                                            </ng-container>
                                        </span>
                                        <span [ngClass]="fontSizeClass"
                                            *ngIf="column.isHyperlink && !column.isToolTip && !column.isTargetLink && column.isClass">
                                            <div class="flex flex-row items-center w-[110px]" [ngClass]="column.width">
                                                <span *ngIf="column.img && column.imgPath && !column.customImg">
                                                    <img [src]="column.imgPath" alt="generic" class="w-[1.5rem]">
                                                </span>
                                                <a class="ml-2 text-blue-500 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                    *ngIf="column.img && column.iconPath"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <span>
                                                        <ng-container *ngIf="!column.iconPathCondition">
                                                            <mat-icon [svgIcon]="column.iconPath"
                                                                class="w-[1.5rem] dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                                                [ngClass]=" column.class"></mat-icon></ng-container>
                                                        <ng-container *ngIf="column.iconPathCondition">
                                                            <mat-icon
                                                                *ngIf="getProperty(row, column.iconPathCondition.key) && getProperty(row, column.iconPathCondition.key).toLowerCase() === column.iconPathCondition.value.toLowerCase()"
                                                                [svgIcon]="column.iconPath"
                                                                class="w-[1.5rem] dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                                                [ngClass]=" column.class"></mat-icon>
                                                        </ng-container>
                                                    </span>
                                                </a>

                                                <span *ngIf="column.customImg">
                                                    <img [src]="column.imgPath+'generic.svg'"
                                                        *ngIf="!getProperty(row, column.imgCol)"
                                                        [alt]="getProperty(row, column.imgCol)" class="w-[1.2rem]">
                                                    <img [src]="column.imgPath+getProperty(row, column.imgCol)"
                                                        *ngIf="getProperty(row, column.imgCol)"
                                                        [alt]="getProperty(row, column.imgCol)" class="w-[1.2rem]">
                                                </span>
                                                <a class="ml-2 text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                    *ngIf="!column.isCustomClass && !column.iconPath"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <span class="break-all" [ngClass]="column.class">
                                                        {{getProperty(row, column.columnDef)}}
                                                    </span>
                                                </a>
                                                <a class="ml-2 text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                    *ngIf="column.isCustomClass"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <!-- 'text-green-500': (getProperty(row, column.columnDef) > 0 && getProperty(row, column.columnDef) <= 40), -->
                                                    <span class="text-base font-bold"
                                                        [ngClass]="{
                                                        'tc-critical':  getProperty(row, column.columnDef) > 90,
                                                         'tc-low': (getProperty(row, column.columnDef) > 0 && getProperty(row, column.columnDef) <= 40),
                                                        'tc-medium': (getProperty(row, column.columnDef) > 40 && getProperty(row, column.columnDef) <= 70),
                                                         'tc-high': (getProperty(row, column.columnDef) > 70 && getProperty(row, column.columnDef) <= 90),
                                                        'tc-info': !getProperty(row, column.columnDef) || getProperty(row, column.columnDef) === 0,}">
                                                        {{getProperty(row, column.columnDef)}}
                                                    </span>
                                                </a>
                                            </div>
                                        </span>
                                        <span *ngIf="column.isToolTip && !column.isHyperlink" [ngClass]="fontSizeClass"
                                            matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                            matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                                            {{ getProperty(row, column.columnDef) }}
                                        </span>
                                        <span *ngIf="column.isHyperlink && column.isToolTip" [ngClass]="fontSizeClass"
                                            matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                            matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                                            <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                (click)="hyperLinkCall(row, column.columnDef)"
                                                [id]="getProperty(row, column.columnDef)">
                                                <span>{{ getProperty(row, column.columnDef)}}</span>
                                            </a>
                                        </span>
                                        <span *ngIf="column.isHyperlink && column.isTargetLink && !column.hyperLinks"
                                            [ngClass]="fontSizeClass">
                                            <a class="text-blue-900 cursor-pointer font-base dark:text-blue-400 hover:text-blue-600"
                                                *ngIf="getProperty(row, column.isTargetLink)" target="_blank"
                                                [href]="getProperty(row, column.isTargetLink)">
                                                <span>{{getProperty(row, column.columnDef)}}</span>
                                            </a>
                                            <div *ngIf="!getProperty(row, column.isTargetLink)">
                                                <span>{{getProperty(row, column.columnDef)}}</span>
                                            </div>
                                        </span>
                                        <span *ngIf="column.isfaicon" [ngClass]="fontSizeClass">
                                            <i class="{{column.class }}" [ngStyle]="{color: column.color}"></i>{{
                                            getProperty(row, column.columnDef) }}
                                        </span>
                                        <ng-container *ngIf="column.isClass && column.class && !column.isHyperlink">
                                            <!-- <span class="{{column.class}}">
                                                {{getProperty(row, column.columnDef) }}
                                            </span> -->
                                            <span class="label"
                                                [ngClass]="[(getProperty(row, column.columnDef) > 0)? column.class : 'bg-[#c2ffc4] text-[#000000]', fontSizeClass]"
                                                *ngIf="getProperty(row, column.columnDef) != '-' && !column.isCusHyperlink">
                                                {{getProperty(row, column.columnDef)}}
                                            </span>
                                            <a target="_blank" (click)="hyperLinkCall(row, column.columnDef)"
                                                class="cursor-pointer">
                                                <span class="label"
                                                    [ngClass]="[(getProperty(row, column.columnDef) > 0)? column.class : 'bg-[#c2ffc4] text-[#000000]', fontSizeClass]"
                                                    *ngIf="getProperty(row, column.columnDef) != '-' && column.isCusHyperlink">
                                                    {{getProperty(row, column.columnDef)}}
                                                </span>
                                            </a>

                                        </ng-container>
                                        <ng-container *ngIf="column.isTags">
                                            <ng-container *ngIf="column.columnDef === 'tags'">
                                                <div class="flex flex-wrap items-center justify-start gap-1 -mx-1"
                                                    [ngClass]="[column.width, fontSizeClass]">
                                                    <ng-container
                                                        *ngFor="let label of getApiResponseTags(row, column.columnDef) | slice: 0:2; trackBy: trackByFn">
                                                        <div
                                                            class="w-auto px-1 m-1 text-xs text-purple-800 bg-purple-100 rounded dark:bg-purple-900 dark:text-purple-300">
                                                            {{ label.key }}: <span class="break-all">{{ label.value
                                                                }}</span>
                                                        </div>
                                                    </ng-container>
                                                    <mat-icon
                                                        *ngIf="getApiResponseTags(row, column.columnDef)?.length > 2"
                                                        color="primary" matTooltip="Show More"
                                                        class="icon-size-4 cursor-pointer" (click)="showMoreTags(row)">
                                                        more_horiz
                                                    </mat-icon>
                                                </div>
                                                <s-modal id="TagMenu">
                                                    <div
                                                        class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
                                                        <div class="rounded-xl bg-card px-4 py-4 w-[550px]">
                                                            <div class="flex flex-row items-center justify-between">
                                                                <div class="text-lg font-semibold">Auto Tags:</div>
                                                                <button class="ml-auto" mat-icon-button
                                                                    matTooltip="Close"
                                                                    (click)="modalService.close('TagMenu');">
                                                                    <mat-icon class="text-current"
                                                                        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                                                </button>
                                                            </div>
                                                            <mat-divider class="my-4"></mat-divider>
                                                            <div class="flex flex-col h-[40vh]" fuseScrollbar>
                                                                <div class="flex flex-row">
                                                                    <ng-container
                                                                        *ngFor="let label of tag_details; trackBy: trackByFn">
                                                                        <div
                                                                            class="w-fit h-fit px-1 m-1 text-xs text-purple-800 bg-purple-100 rounded dark:bg-purple-900 dark:text-purple-300">
                                                                            {{ label.key }}: <span class="break-all">{{
                                                                                label.value }}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <mat-divider class="w-full mt-2"></mat-divider>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </s-modal>
                                            </ng-container>
                                            <ng-container *ngIf="column.columnDef === 'manual_tags'">
                                                <div class="flex flex-wrap items-center justify-start gap-1 -mx-1"
                                                    [ngClass]="[column.width, fontSizeClass]">
                                                    <ng-container
                                                        *ngFor="let label of getProperty(row, column.columnDef) | manualtags | slice: 0:2; trackBy: trackByFn">
                                                        <div
                                                            class="w-auto px-1 m-1 text-xs text-purple-800 bg-purple-100 rounded dark:bg-purple-900 dark:text-purple-300">
                                                            {{ label.key }}: <span class="break-all">{{ label.value
                                                                }}</span>
                                                        </div>
                                                    </ng-container>
                                                    <mat-icon
                                                        *ngIf="(getProperty(row, column.columnDef) | manualtags)?.length > 2"
                                                        color="primary" matTooltip="Show More"
                                                        class="icon-size-4 cursor-pointer"
                                                        (click)="showMoreManualTags(row)">
                                                        more_horiz
                                                    </mat-icon>
                                                </div>
                                                <s-modal id="ManualTagMenu">
                                                    <div
                                                        class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
                                                        <div class="rounded-xl bg-card px-4 py-4 w-[550px]">
                                                            <div class="flex flex-row items-center justify-between">
                                                                <div class="text-lg font-semibold">Manual Tags:</div>
                                                                <button class="ml-auto" mat-icon-button
                                                                    matTooltip="Close"
                                                                    (click)="modalService.close('ManualTagMenu');">
                                                                    <mat-icon class="text-current"
                                                                        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                                                </button>
                                                            </div>
                                                            <mat-divider class="my-4"></mat-divider>
                                                            <div class="flex flex-col h-[40vh]" fuseScrollbar>
                                                                <div class="flex flex-row">
                                                                    <ng-container
                                                                        *ngFor="let label of manualTags; trackBy: trackByFn">
                                                                        <div
                                                                            class="w-auto px-1 m-1 text-xs text-purple-800 bg-purple-100 rounded dark:bg-purple-900 dark:text-purple-300">
                                                                            {{ label.key }}: <span class="break-all">{{
                                                                                label.value }}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <mat-divider class="w-full mt-2"></mat-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </s-modal>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="column.textBadge">
                                            <div class="flex flex-wrap items-center justify-start gap-1 -mx-1"
                                                [ngClass]="[column.width,fontSizeClass]">
                                                <ng-container
                                                    *ngFor="let label of getProperty(row, column.columnDef) | textbadge; trackBy: trackByFn">
                                                    <span
                                                        class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                        {{label | uppercase}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.cHyperLink">
                                            <div class="flex flex-wrap items-center justify-start gap-1 -mx-1"
                                                [ngClass]="fontSizeClass">
                                                <ng-container
                                                    *ngIf="column.conditions && column.conditions.length && getProperty(row, column.conditions[0]) === '*' && !getProperty(row, column.conditions[1])">
                                                    <a class="cursor-pointer"
                                                        (click)="hyperLinkCall(row, column.columnDef)">
                                                        <span
                                                            class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                            All
                                                        </span>
                                                    </a>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="column.conditions && column.conditions.length && getProperty(row, column.conditions[0]) !== '*' && (getProperty(row, column.conditions[0]) || getProperty(row, column.conditions[1]))">
                                                    <a class="cursor-pointer"
                                                        (click)="hyperLinkCall(row, column.columnDef)">
                                                        <span
                                                            class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                            Restricted
                                                        </span>
                                                    </a>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.sHyperLink">

                                            <ng-container *ngIf="column.conditions">
                                                <a class="cursor-pointer"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <span
                                                        class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                        Show All
                                                    </span>
                                                </a>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="column.isstatuscontrol">
                                            <div class="flex flex-row items-center w-full gap-1">
                                                <div class="flex items-center text-semibold">
                                                    <div class="font-medium truncate text-md">Success:</div>
                                                    <div class="ml-1.5 text-lg"
                                                        [ngClass]="{'text-green-600': getProperty(row, column.columnDef)[0] > 0,fontSizeClass}">
                                                        {{getProperty(row, column.columnDef)[0]}}</div>,
                                                </div>
                                                <div class="flex items-center text-semibold">
                                                    <div class="font-medium truncate text-md">Failed:</div>
                                                    <div class="ml-1.5 text-lg"
                                                        [ngClass]="{'text-red-600': getProperty(row, column.columnDef)[1] > 0,fontSizeClass}">
                                                        {{getProperty(row, column.columnDef)[1]}}</div>,
                                                </div>
                                                <div class="flex items-center text-semibold">
                                                    <div class="font-medium truncate text-md">Pending:</div>
                                                    <div class="ml-1.5 text-lg"
                                                        [ngClass]="{'text-orange-600': getProperty(row, column.columnDef)[2] > 0,fontSizeClass}">
                                                        {{getProperty(row, column.columnDef)[2]}}</div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isShowMoreShowLess">
                                            <div class="p-1 break-all w-[13rem]"
                                                *ngIf="getProperty(row, column.columnDef)">
                                                <div class="flex flex-wrap w-auto gap-1 pt-1" [ngClass]="fontSizeClass">
                                                    <show-more-less [items]="getProperty(row, column.columnDef)"
                                                        [showAllItems]="false" [type]="'list'"
                                                        [hyperlinkList]="column.isHyperlinkKey ? getProperty(row, column.isHyperlinkKey) : []"
                                                        [bgColor]="'bg-yellow-200'" [color]="'text-blue-900'"
                                                        [maxItemsToShow]="10" [showOrder]="'span'">
                                                    </show-more-less>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isPortView">
                                            <div class="w-[6rem]">
                                                <div class="flex flex-row flex-wrap items-center gap-2 mt-2"
                                                    *ngIf="getProperty(row, column.columnDef) && getProperty(row, column.columnDef).length">
                                                    <ng-container
                                                        *ngFor="let port of getProperty(row, column.columnDef) | search: searchInput; let i = index;">
                                                        <span [class.hidden]="i >= 2"
                                                            class="inline-flex text-xs items-center bg-yellow-200 text-black px-1 py-0.5 rounded"
                                                            *ngIf="port">
                                                            <span>{{port}}</span>
                                                        </span>
                                                    </ng-container>
                                                    <mat-icon color="primary" matTooltip="Show More"
                                                        class="icon-size-4 cursor-pointer" (click)="showPorts(row)"
                                                        *ngIf="getProperty(row, column.columnDef) && getProperty(row, column.columnDef).length > 2">more_horiz</mat-icon>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isTicketsView">
                                            <div class="w-[7rem]">
                                                <div class="flex flex-row flex-wrap items-center gap-2 mt-2"
                                                    *ngIf="getProperty(row, column.isHyperlinkKey) && getProperty(row, column.isHyperlinkKey).length">
                                                    <span
                                                        *ngFor="let p of getProperty(row, column.isHyperlinkKey) | search: searchInput; let i = index;"
                                                        (click)="redirect(getProperty(row, column.isHyperlinkKey),p,i)"
                                                        [class.hidden]="i >= 2" class="cursor-pointer
                                                  inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg"
                                                        [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': p.status === 'Open',
                                                  'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': p.status === 'Closed'}">
                                                        <span *ngIf="p.ticket_number && p.ticket_id"
                                                            [matTooltip]="p.status">{{p.ticket_number}}</span>
                                                        <span *ngIf="p.ticket_id && !p.ticket_number"
                                                            [matTooltip]="p.status">{{p.ticket_id}}</span>
                                                    </span>
                                                    <mat-icon color="primary" matTooltip="Show More"
                                                        class="icon-size-4 cursor-pointer" (click)="showTickets(row)"
                                                        *ngIf="getProperty(row, column.isHyperlinkKey) && getProperty(row, column.isHyperlinkKey).length > 2">more_horiz</mat-icon>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isTicketCountView">
                                            <span
                                                (click)="getProperty(row, column.columnDef) > 0 ? showTickets(row, column.isOpenTicket): null"
                                                class="label w-[3vw]"
                                                [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': column.isOpenTicket,
                                                  'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': !column.isOpenTicket, 'cursor-pointer': getProperty(row, column.columnDef) > 0}">
                                                <span [ngClass]="fontSizeClass">{{getProperty(row,
                                                    column.columnDef)}}</span>
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="column.isShowMoreData">
                                            <div class="p-1 break-normal w-[20rem]"
                                                *ngIf="getProperty(row, column.columnDef)">
                                                <div class="flex flex-wrap w-auto gap-1 pt-1" [ngClass]="fontSizeClass">
                                                    <show-more-less [inputString]="getProperty(row, column.columnDef)"
                                                        [showAllItems]="false" [type]="'string'"
                                                        [bgColor]="'bg-yellow-200'" [color]="'text-blue-900'"
                                                        [columnKey]="column.header" [maxItemsToShow]="10"
                                                        [showOrder]="'span'" [columnDetails]="column.showpopup">
                                                    </show-more-less>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isProgressBar">
                                            <div class="p-2 break-normal w-[20vw] flex flex-row items-center gap-1">
                                                <mat-progress-bar class="rounded-full" [mode]="'determinate'"
                                                    [value]="25">
                                                </mat-progress-bar>
                                                <div
                                                    class="mb-px font-mono text-sm font-bold leading-none text-green-500">
                                                    25%
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isAgentVersion">
                                            <span
                                                class="inline-block text-teal-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide bg-white border border-green-500"
                                                *ngIf=" getProperty(row, column.columnDef)  === latest_agent_version"
                                                [matTooltip]="'Agent Version is up to date'">
                                                <i
                                                    class="fas fa-solid fa-laptop-medical text-green-800 mx-1"></i>{{ getProperty(row, column.columnDef) }}<i
                                                    class="fas fa-check-circle mx-1 text-green-800"></i>
                                            </span>
                                            <span
                                                class="inline-bloc text-red-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide bg-white border border-red-500"
                                                *ngIf=" getProperty(row, column.columnDef)  !== latest_agent_version && getProperty(row, 'online_status')"
                                                [matTooltip]="'Agent is outdated and asset is online, update the agent under Action'">
                                                <i
                                                    class="fas fa-solid fa-laptop-medical text-red-800 mx-1"></i>{{  getProperty(row, column.columnDef) }}<i
                                                    class="fas fa-solid fa-download text-red-800 mx-1"></i>
                                            </span>
                                            <span
                                                class="inline-block text-red-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide bg-white border border-red-500 opacity-50"
                                                *ngIf=" getProperty(row, column.columnDef)  !== latest_agent_version && !getProperty(row, 'online_status')"
                                                [matTooltip]="'Agent is outdated and asset is offline'">
                                                <i
                                                    class="fas fa-laptop-medical text-red-800 mx-1"></i>{{ getProperty(row, column.columnDef) }}
                                            </span>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!column.icon && !column.img && !column.isHyperlink && !column.isProgressBar &&
                                           !column.isProgressCntrl && !column.isColoredCntrl && !column.rangeColor
                                            && !column.hasMultiData && !column.statusicon && !column.isfaicon
                                           && !column.isAddingText  && !column.isToolTip && !column.isClass
                                            && !column.isTags && !column.textBadge && !column.cHyperLink && !column.isSecurityGrade && !column.isstatuscontrol && !column.isShowMoreShowLess && !column.isShowMoreData && !column.sHyperLink && !column.isPortView && !column.isTicketsView  && !column.isTicketCountView && !column.isTicketsView && !column.isAgentVersion">
                                            <span *ngIf="getProperty(row, column.columnDef)" [ngClass]="fontSizeClass">
                                                <span *ngIf="!column.clipboard">{{ getProperty(row, column.columnDef)
                                                    }}</span>
                                                <div *ngIf="column.clipboard"
                                                    style="display: flex; align-items: center; width: 100%;">
                                                    <div style="display: flex; align-items: center; width: 100%;">
                                                        <span
                                                            style="flex-grow: 1; text-align: left; position: relative; cursor: pointer;"
                                                            [ngClass]="fontSizeClass"
                                                            (click)="commonService.copyClip(getProperty(row, column.columnDef));"
                                                            matTooltip='Copy Hostname' matTooltipPosition="above">
                                                            {{ getProperty(row, column.columnDef) }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                            <span *ngIf="!getProperty(row, column.columnDef) && column.fillEmpty"
                                                [ngClass]="fontSizeClass">{{
                                                getProperty(row, column.fillEmpty) |
                                                formatCell:column.filter}}</span>
                                            <span
                                                *ngIf="!getProperty(row, column.columnDef) && column.fillEmpty && !getProperty(row, column.fillEmpty) && column.secFillEmpty"
                                                [ngClass]="fontSizeClass">
                                                <span *ngIf="!column.clipboard">{{ getProperty(row, column.secFillEmpty)
                                                    |
                                                    formatCell:column.filter
                                                    }}</span>
                                                <div *ngIf="column.clipboard"
                                                    style="display: flex; align-items: center; width: 100%;">
                                                    <div style="display: flex; align-items: center; width: 100%;">
                                                        <span
                                                            style="flex-grow: 1; text-align: left; position: relative; cursor: pointer;"
                                                            [ngClass]="fontSizeClass"
                                                            (click)="commonService.copyClip(getProperty(row, column.secFillEmpty));"
                                                            matTooltip='Copy Hostname' matTooltipPosition="above">
                                                            {{ getProperty(row, column.secFillEmpty) | formatCell:
                                                            column.filter }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="column.rangeColor">
                                            <span class="label" [ngClass]="fontSizeClass"
                                                [matTooltip]="column.toolTipContent" [matTooltipPosition]="'right'"
                                                *ngIf="getProperty(row, column.columnDef) != '-' && !column.isMultiRange"
                                                [ngStyle]="getRangeColor(getProperty(row, column.columnDef), column.columnDef)">
                                                {{getProperty(row, column.columnDef)}}
                                            </span>
                                            <ng-container *ngIf="column.isMultiRange">
                                                <ng-container
                                                    *ngFor="let colu of sTableOptions.customScore; trackBy: trackByFn">
                                                    <span class="mr-2 label" [ngClass]="fontSizeClass"
                                                        [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                        *ngIf="getProperty(row, colu.key) != '-' && colu.filter"
                                                        [ngStyle]="getRangeColor(getProperty(row, colu.key), colu.key)">
                                                        {{getProperty(row, colu.key) | formatCell:colu.filter}}
                                                    </span>
                                                    <span class="mr-2 label" [ngClass]="fontSizeClass"
                                                        [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                        *ngIf="getProperty(row, colu.key) != '-' && !colu.filter"
                                                        [ngStyle]="getRangeColor(getProperty(row, colu.key),colu.key)">
                                                        {{getProperty(row, colu.key)}}
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action" *ngIf="tableOptions.showAction" stickyEnd>
                                <th mat-header-cell *matHeaderCellDef class="wwbreak dark:bg-gray-700"
                                    [ngClass]="tableOptions.showAction === true? 'color1' : 'color2' ">
                                    <!--<button id="gbtn{{tableOptions.title}}" mat-icon-button [matMenuTriggerFor]="gactionMenu" [disabled]="selection.selected.length === 0"
                                      aria-label="global action with a menu">
                                      <mat-icon>more_vert</mat-icon>
                                    </button>-->
                                    <span [ngClass]="fontSizeClassHed">Action</span>
                                    <mat-menu #gactionMenu="matMenu">
                                        <ng-container
                                            *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                                            <a id="ganch{{item.text}}" *ngIf=item.isGlobal mat-menu-item
                                                (click)="globalActionCall(selection.selected, item)">
                                                <mat-icon
                                                    *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf="item.icon && item.icon.includes(':')"
                                                    [svgIcon]="item.icon"></mat-icon>
                                                <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                        </ng-container>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element; let i=index;"
                                    class="wwbreak bg-white z-5 dark:bg-[#1e293b]"
                                    [ngClass]="tableOptions.showAction == true? 'color1' : 'color2' ">
                                    <button type="button" id="btn{{tableOptions.id}}{{i}}" mat-icon-button
                                        color="primary" [matMenuTriggerFor]="actionMenu"
                                        aria-label="row action with a menu" [disabled]="selection.selected.length > 0">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #actionMenu="matMenu">
                                        <!-- <ng-container *ngIf="!tableOptions.isActionCondition"> -->
                                        <ng-container
                                            *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                                            <a id="anch{{item.text}}"
                                                *ngIf="item.callback && !item.hideLocal && !item.allowedCondition && !item.actionConditions  && !item.isUpdateAgent"
                                                mat-menu-item (click)="actionCall(element, item)">
                                                <mat-icon
                                                    *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf="item.icon && item.icon.includes(':')"
                                                    [svgIcon]="item.icon"></mat-icon>
                                                <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                            <a id="anch{{item.text}}"
                                                *ngIf="item.callback && !item.hideLocal && item.allowedCondition && item.allowedCondition.indexOf(getPropertyCondition(element, item.actionConditions.key)) !== -1"
                                                mat-menu-item (click)="actionCall(element, item)">
                                                <mat-icon
                                                    *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf="item.icon && item.icon.includes(':')"
                                                    [svgIcon]="item.icon"></mat-icon>
                                                <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                            <a id="anch{{item.text}}"
                                                *ngIf="item.callback && !item.hideLocal && !item.allowedCondition && !item.actionCondition && latest_agent_version !== getProperty(element,'agent_version') && item.isUpdateAgent && getProperty(element,'online_status')"
                                                mat-menu-item (click)="actionCall(element, item)">
                                                <mat-icon
                                                    *ngIf="item.icon && !item.icon.includes(':')">{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf="item.icon && item.icon.includes(':')"
                                                    [svgIcon]="item.icon"></mat-icon>
                                                <mat-icon *ngIf="!item.icon">arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                        </ng-container>
                                        <!-- </ng-container> -->
                                        <!-- <ng-container
                                            *ngIf="tableOptions.isActionCondition && tableOptions.actionConditions && tableOptions.actionConditions.key">
                                            <ng-container *ngFor="let item of tableOptions.actionMenuItems; trackBy: trackByFn">
                                                <a id="anch{{item.text}}"
                                                    *ngIf="item.callback && !item.hideLocal && item.allowedCondition.indexOf(getPropertyCondition(element, tableOptions.actionConditions.key)) !== -1"
                                                    mat-menu-item (click)="actionCall(element, item)">
                                                    <mat-icon *ngIf=item.icon>{{item.icon}}</mat-icon>
                                                    <mat-icon *ngIf=!item.icon>arrow_forward_ios</mat-icon>
                                                    <span [id]="item.text">{{ item.text }}</span>
                                                </a>
                                            </ng-container>
                                        </ng-container> -->
                                    </mat-menu>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <!--hovered: row.hovered, (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"-->
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                                [ngClass]="{'bg-blue-100 dark:bg-gray-500': tableOptions.rowClick && row.highlighted}"
                                (click)="rowClick(row, i)"></tr>
                        </table>
                    </div>
                    <div class="mt-2 py-[4%] w-full dark:bg-gray-700 items-center justify-center"
                        *ngIf="!dataSource || !dataSource.data || !dataSource.data.length">
                        <div *ngIf="tableOptions.loading || !sTableOptions.pageData"
                            class="flex flex-col items-center justify-center gap-y-2">
                            <img src="/assets/images/tl.gif" alt="" class="w-20">
                            <div> Please wait!</div>
                        </div>
                        <div *ngIf="!tableOptions.loading && sTableOptions.pageData &&
                         !sTableOptions.pageData.length && tableOptions.pageTotal === 0 && !tableOptions.nomsg"
                            class="flex flex-col items-center justify-center gap-y-2">
                            <img src="/assets/images/empty.png" alt="" class="w-20">
                            <div> No data available!</div>
                        </div>
                        <div *ngIf="!tableOptions.loading && sTableOptions.pageData && !sTableOptions.pageData.length && tableOptions.pageTotal === 0 && tableOptions.nomsg"
                            class="flex flex-col items-center justify-center gap-y-2">
                            <img src="/assets/images/empty.png" alt="" class="w-20">
                            <div>{{tableOptions.nomsg}}</div>
                        </div>
                    </div>
                    <mat-progress-bar mode="indeterminate"
                        *ngIf="tableOptions.loading && dataSource &&  dataSource.data && dataSource.data.length > 25">
                    </mat-progress-bar>
                    <paginator-widget *ngIf="tableOptions.pagination && tableOptions.pageTotal"
                        [pageOptions]="tableOptions.pageOptions" [pageSize]="tableOptions.pageSize"
                        [pageTotal]="tableOptions.pageTotal" (pageChanged)="pageChanged($event)"></paginator-widget>

                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons
                        *ngIf="!tableOptions.pagination" aria-label="Select page of periodic elements" (page)="pageChangedManually($event)">
                    </mat-paginator> -->
                    <div class="flex justify-between items-center p-2 px-4 space-x-4 bg-white dark:bg-[#1e293b]"
                        *ngIf="!tableOptions.pagination">
                        <div class="flex flex-row pr-2 items-center gap-6">
                            <div class="flex items-center space-x-2">
                                <label for="items-page-number">Items per page:</label>
                                <mat-form-field class="w-[7vw] mini-form-field">
                                    <input id="items-page-number" type="number" name="items-page-number" matInput
                                        [(ngModel)]="tableOptions.pageSize"
                                        (ngModelChange)="pageSizeUpdate.next($event)" min="1" max="100"
                                        [matAutocomplete]="autoCom">
                                </mat-form-field>
                                <mat-autocomplete #autoCom="matAutocomplete">
                                    <ng-container
                                        *ngFor="let page of tableOptions.pageOptions; let i = index; trackBy: trackByFn">
                                        <mat-option [value]="page">{{page}}</mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>
                            <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="tableOptions.pageSize"
                                showFirstLastButtons hidePageSize aria-label="Select page of periodic elements"
                                (page)="pageChangedManually($event)">
                            </mat-paginator>
                        </div>
                        <div class="flex items-center space-x-2">
                            <label for="page-number">Go to page:</label>
                            <mat-form-field class="w-[4vw] mini-form-field">
                                <input id="page-number" type="number" name="page-number" matInput
                                    [(ngModel)]="inputPageNumber" (input)="onPageNumberInput($event)"
                                    (keypress)="validatePositiveNumber($event)" min="1" [max]="maxPageNumber">
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-drawer-content>
        </mat-drawer-container>
    </mat-card>
</ng-container>
<s-modal id="portsDetails1">
    <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
        <div class="rounded-xl bg-card px-4 py-4 w-[600px]">
            <div class="flex flex-row items-center">
                <div class="text-xl font-semibold tracking-tight">{{port_details.problem_name || 'Problem Name'}}</div>
                <button class="ml-auto" mat-icon-button matTooltip="Close"
                    (click)="modalService.close('portsDetails1');">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <mat-divider class="my-4"></mat-divider>
            <div class="flex flex-col h-[40vh]" fuseScrollbar>
                <ng-container *ngIf="port_details.port && port_details.port.length">
                    <div class="text-md font-semibold">Ports:</div>
                    <div class="flex flex-row flex-wrap items-center gap-2 ml-6 my-2">
                        <ng-container *ngFor="let port of port_details.port; let i = index; trackBy: trackByFn">
                            <span *ngIf="port"
                                class="inline-flex text-xs items-center bg-yellow-200 text-black px-1 py-0.5 rounded">
                                <span>{{port}}</span>
                            </span>
                        </ng-container>
                    </div>
                    <mat-divider class="mt-2"></mat-divider>
                </ng-container>
                <!-- <div class="flex items-center justify-end mb-2 pt-4 bg-transparent">
            <button class="ml-auto" mat-button [matTooltip]="'Close'" (click)="modalService.close('portsDetails1');">
              Close
            </button>
          </div> -->
            </div>
        </div>
    </div>
</s-modal>

<s-modal id="ticketDetails1" *ngIf="isLoading1">
    <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
        <div class="rounded-xl bg-card px-4 py-4 w-[600px]">
            <div class="flex flex-row items-center">
                <div class="text-xl font-semibold tracking-tight">{{ticket_details.problem_name|| port_details.name||
                    ticket_details.affected_operating_systems}}</div>
                <button class="ml-auto" mat-icon-button matTooltip="Close"
                    (click)="modalService.close('ticketDetails1');">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <mat-divider class="my-4"></mat-divider>
            <div class="flex flex-col h-[40vh]" fuseScrollbar>
                <ng-container *ngIf="ticket_details.open_ticket && ticket_details.open_ticket.length">
                    <div class="text-md font-semibold">Open Tickets:</div>
                    <div class="flex flex-row flex-wrap items-center gap-2 ml-6 my-2">
                        <ng-container *ngFor="let p of ticket_details.open_ticket; let i = index; trackBy: trackByFn">
                            <span (click)="redirect(ticket_details.open_ticket,p,i)"
                                class="cursor-pointer inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50">
                                <span *ngIf="p.ticket_number && p.ticket_id"
                                    [matTooltip]="p.status">{{p.ticket_number}}</span>
                                <span *ngIf="p.ticket_id && !p.ticket_number"
                                    [matTooltip]="p.status">{{p.ticket_id}}</span>
                            </span>
                        </ng-container>
                    </div>
                    <mat-divider class="mt-2"></mat-divider>
                </ng-container>
                <ng-container *ngIf="ticket_details.closed_ticket && ticket_details.closed_ticket.length">
                    <div class="text-md font-semibold">Closed Tickets:</div>
                    <div class="flex flex-row flex-wrap items-center gap-2 ml-6 my-2">
                        <ng-container *ngFor="let p of ticket_details.closed_ticket; let i = index; trackBy: trackByFn">
                            <span (click)="redirect(ticket_details.closed_ticket,p,i)"
                                class="cursor-pointer inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50">
                                <span *ngIf="p.ticket_number && p.ticket_id"
                                    [matTooltip]="p.status">{{p.ticket_number}}</span>
                                <span *ngIf="p.ticket_id && !p.ticket_number"
                                    [matTooltip]="p.status">{{p.ticket_id}}</span>
                            </span>
                        </ng-container>
                    </div>
                    <mat-divider class="mt-2"></mat-divider>
                </ng-container>
            </div>
        </div>
    </div>
</s-modal>