import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { ChartsComponent } from 'app/modules/shared/charts/charts.component';
import { FilterComponent } from 'app/modules/shared/filter/filter.component';
import { GridsComponent } from 'app/modules/shared/grids/grids.component';
import { ShowMoreLessComponent } from 'app/modules/shared/show-more-less/show-more-less.component';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { AppVulnerabilitiesComponent } from '../../admin/app-vulnerabilities/app-vulnerabilities.component';
import { PatchComplianceComponent } from '../../admin/patch-compliance/patch-compliance.component';
import { PortsComponent } from '../../admin/ports/ports.component';
import { ProblemsComponent } from '../../admin/problems/problems.component';
import { RemediationPlanComponent } from '../../admin/remediation-plan/remediation-plan.component';
import { SettingsAccountComponent } from '../../admin/settings/account/account.component';
import { SettingsNotificationsComponent } from '../../admin/settings/notifications/notifications.component';
import { SettingsPlanBillingComponent } from '../../admin/settings/plan-billing/plan-billing.component';
import { SettingsSecurityComponent } from '../../admin/settings/security/security.component';
import { TagsComponent } from '../../admin/settings/tags/tags.component';
import { SettingsTeamComponent } from '../../admin/settings/team/team.component';
import { ActivatedRoute } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, Subscribable, Subscription, takeUntil } from 'rxjs';
import { DateTime } from 'luxon';
import { AssetComplianceComponent } from "../asset-compliance/asset-compliance.component";
import { NgApexchartsModule } from 'ng-apexcharts';
import { fuseAnimations } from '@fuse/animations';
import { ModalComponent } from '../modal.component';
import { ModalService } from 'app/_services/modal.service';
import { DynamicSortComponent } from "../dynamic-sort/dynamic-sort.component";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StorageDevicesComponent } from "../storage-devices/storage-devices.component";
import { NetworkInterfacesComponent } from "../network-interfaces/network-interfaces.component";
import { BiosInfoComponent } from '../bios-info/bios-info.component';
import { FirewallPolicyComponent } from '../firewall-policy/firewall-policy.component';
import { MatTabGroup } from '@angular/material/tabs';
import { FuseHighlightComponent } from '@fuse/components/highlight';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { UserService } from 'app/core/user/user.service';
import { DynamicSettingsService } from 'app/layout/common/dynamic-settings/dynamic-settings.service';
import { UserSharesComponent } from "../user-shares/user-shares.component";
import { AssetEssentialComponent } from '../asset-essential/asset-essential.component';
import { CiphersComponent } from "../ciphers/ciphers.component";
import { CertificatesComponent } from "../certificates/certificates.component";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDrawer } from "@angular/material/sidenav";
import { GraphicsInfoComponent } from "../graphics-info/graphics-info.component";
import { ComplianceReportCardComponent } from '../compliance-report-card/compliance-report-card.component';
import { SecurityReportCardComponent } from '../security-report-card/security-report-card.component';
import { KernelModulesComponent } from '../kernel-modules/kernel-modules.component';
import { CronJobsComponent } from '../cron-jobs/cron-jobs.component';
import { SuidPermissionsComponent } from '../suid-permissions/suid-permissions.component';
import { FirewallRulesComponent } from '../firewall-rules/firewall-rules.component';
import { IpTableRulesComponent } from '../ip-table-rules/ip-table-rules.component';
import { SelinuxSettingsComponent } from '../selinux-settings/selinux-settings.component';
import { RiskScoreGaugeComponent } from '../risk-score-gauge/risk-score-gauge.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { WindowsCompatibleCheckComponent } from '../windows-compatible-check/windows-compatible-check.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatMenuTrigger } from '@angular/material/menu';
import { cloneDeep } from 'lodash';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SuppressionComponent } from 'app/modules/admin/companies/suppression/suppression.component';
import { NoDataComponent } from '../no-data/no-data.component';
import { IntegrationActionsService } from 'app/_services/integration-actions.service';
import { IntegrationActionComponent } from '../integration-action/integration-action.component';
import { AssetTagsComponent } from '../asset-tags/asset-tags.component';
import { AssetCpuInfoComponent } from '../asset-cpu-info/asset-cpu-info.component';
import { AssetFingerPrintComponent } from '../asset-finger-print/asset-finger-print.component';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { PaginatorWidgetComponent } from '../paginator-widget/paginator-widget.component';


@Component({
    selector: 'asset-details',
    standalone: true,
    animations: fuseAnimations,
    imports: [CommonModule, GridsComponent, AssetEssentialComponent, SkeletonComponent, ChartsComponent,
        SettingsNotificationsComponent, FuseHighlightComponent, ComplianceReportCardComponent, AssetTagsComponent,
        SettingsPlanBillingComponent, SettingsSecurityComponent, SettingsTeamComponent, NgApexchartsModule,
        TagsComponent, MaterialModule, NgOptimizedImage, AppFilterPipeModule, ShowMoreLessComponent, AsyncPipe,
        TableComponent, FuseScrollbarDirective, ProblemsComponent, RemediationPlanComponent, ModalComponent,
        SecurityReportCardComponent, KernelModulesComponent, CronJobsComponent, SuidPermissionsComponent, NoDataComponent,
        AppVulnerabilitiesComponent, PatchComplianceComponent, PortsComponent, FuseAlertComponent, FilterComponent, PaginatorWidgetComponent,
        AssetComplianceComponent, DynamicSortComponent, FormsModule, StorageDevicesComponent, NetworkInterfacesComponent,
        BiosInfoComponent, AssetCpuInfoComponent, FirewallPolicyComponent, FirewallRulesComponent, WindowsCompatibleCheckComponent, IntegrationActionComponent,
        SelinuxSettingsComponent, RiskScoreGaugeComponent, ProgressBarComponent, DirectivesModule, SuppressionComponent, AssetFingerPrintComponent,
        IpTableRulesComponent, UserSharesComponent, CiphersComponent, CertificatesComponent, GraphicsInfoComponent, NgxMatSelectSearchModule, ReactiveFormsModule],
    templateUrl: './asset-details.component.html',
    styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit, OnDestroy {
    selectedTab: string = 'registry';
    suppression: any = {};
    updatedProblems: any[] = [];
    @Input() is_suppressed: any
    @Input() filterKey: any;
    @Input() mode: any;
    @Input() cAsset: any;
    @Input() compliance_type: any;
    @Input() maturity: any;
    @Input() platform: any;
    @Input() asset_id: any;
    @Input() isColumnView: any;
    @Input() is_deprecated: any;
    @Output() toggleFn = new EventEmitter();
    @Output() refreshTbl = new EventEmitter();
    @ViewChild('drawer') drawer: MatDrawer;
    @ViewChild('tabGroup1', { static: true }) tabGroup1: ElementRef;
    @ViewChild('tabGroup2', { static: true }) tabGroup2: ElementRef;
    @ViewChild('tabGroup3', { static: true }) tabGroup3: ElementRef;
    @ViewChild('assetNameMenuTrigger', { static: false }) assetNameMenuTrigger: MatMenuTrigger;

    @ViewChild('matDocDrawer', { static: true }) matDocDrawer: MatDrawer;
    drawerDocMode: 'side' | 'over';
    drawerDocOpened: boolean = false;
    updatedProblemsScore: any[] = [];
    apiResultData: any[] = [];
    assetOwner: any;
    editing: boolean = false;
    drawerPosition: 'start' | 'end' = 'end';
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = false;
    isFullView = false;
    rsTitle = 'RISK SCORE';
    riskScoreCard: any = true;
    suppressedvulscount: any = 0;
    firewallDisplayShow: any = true;
    height: { px: number } = { px: 250 };
    maualselectfilter: any = 'yes';
    viewupdate: string;
    actionData: any = [];
    productData: any = [];
    agent_version: string = '';
    latest_agent_version: string = '';
    resetParam: any = {};
    reset_config: any = "update_agent";
    networkDocumentation: any = {};
    pageOptions: any = [5, 10, 20, 30, 50, 100];
    pageSize: any = 10;
    pageTotal: any = 0;
    pageIndex = 0;
    networkDocumentationSW: any = [];
    isEditNetworkDocumentation = false;
    _network_doc_data: any = {
        owner_name: '',
        owner_contact_number: '',
        owner_email: ''
    };
    vulstats = [
        {
            title: 'CISA',
            name: 'CISA Vulnerability',
            contents: '000',
            classes: { icon: 'text-red-500', ring: 'ring-red-700' },
            key: 'cisa_notified_vulnerabilities',
        },
        {
            title: 'EPSS>95',
            name: 'EPSS>95 Vulnerability',
            contents: '000',
            classes: { icon: 'text-red-500', ring: 'ring-red-700' },
            key: 'e95_vulnerabilities',
        },
        {
            title: 'CRITICAL',
            name: 'Critical Vulnerability',
            contents: '000',
            classes: { icon: 'text-red-500', ring: 'ring-red-700' },
            key: 'critical_vulnerabilities',
        },
        {
            title: 'HIGH',
            name: 'High Vulnerability',
            contents: '000',
            classes: { icon: 'text-amber-500', ring: 'ring-amber-700' },
            key: 'high_severity_vulnerabilities',
        }
    ];
    asset_name: any = '';
    Objectkeys = Object.keys;
    colors: any = [
        "text-blue-400",
        "text-gray-400",
        "text-red-400",
        "text-green-400",
        "text-yellow-300",
        "text-indigo-400",
        "text-purple-400",
        "text-pink-400"
    ]
    softTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Version",
                "columnDef": "version",
                "filter": "",
                "cell": "(element: any) => `${element.version}`",
                "order": 2,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.path}`",
                "order": 3,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Uninstall String",
                "columnDef": "uninstall_string",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.uninstall_string}`",
                "order": 3,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Publisher",
                "columnDef": "publisher",
                "filter": "",
                "cell": "(element: any) => `${element.publisher}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Release",
                "columnDef": "release",
                "filter": "",
                "cell": "(element: any) => `${element.release}`",
                "order": 6,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Installed Date",
                "columnDef": "install_date",
                "filter": "date-Format",
                "cell": "(element: any) => `${element.installed_date}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Critical  Vulnerabilities",
                "align": "center",
                "columnDef": "critical_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.critical_vuls_count}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-critical",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "High  Vulnerabilities",
                "align": "center",
                "columnDef": "high_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.high_vuls_count}`",
                "order": 6,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-high",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Medium Vulnerabilities",
                "align": "center",
                "columnDef": "medium_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.medium_vuls_count}`",
                "order": 7,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-medium",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Low Vulnerabilities",
                "align": "center",
                "columnDef": "low_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.low_vuls_count}`",
                "order": 8,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-medium",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Vulnerability Count",
                "align": "center",
                "columnDef": "vul_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.vul_count}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-critical",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            { "header": "Confirmed", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "unconfirmed", "cType": "agg", "filter": "", "cell": "(element: any) => `${element.unconfirmed}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Software',
            isServerSide: false,
            selectText: 'software',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/report_queries/asset_software',
                condition: '',
            },
            id: 'software'
        },
        customText: [
            {
                status: 1,
                DisplayText: 'Probe',
            },
            {
                status: 2,
                DisplayText: 'Lightweight Agent',
            },
            {
                status: 3,
                DisplayText: 'Lightweight Agent Installed',
            },
            {
                status: 4,
                DisplayText: 'External Scan Agent',
            },
            {
                range: { from: 0, to: 0 },
                color: '#c2ffc4',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 50 },
                color: '#ffcb70',
                'text-color': '#000000'
            },
            {
                range: { from: 51, to: 10000000 },
                color: '#ffb4a3',
                'text-color': '#000000'
            },
            {
                status: 25,
                DisplayText: 'Low',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 50,
                DisplayText: 'Medium',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 75,
                DisplayText: 'High',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 100,
                DisplayText: 'Critical',
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: true,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: false,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
        ],
        sColumns: [
            {
                header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                isCustomFilter: [
                    {
                        "value": "contains",
                        "key": "Equal"
                    },
                    {
                        "value": "notcontains",
                        "key": "Not Equal"
                    },
                ],
                dataSource: [
                    { text: 'Yes', value: 'false' },
                    { text: 'No', value: 'true' }
                ],
            },
        ],
        changeValue: new Subject<any>(),
    };

    rhelSoftTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Version",
                "columnDef": "version",
                "filter": "",
                "cell": "(element: any) => `${element.version}`",
                "order": 2,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Release",
                "columnDef": "release",
                "filter": "",
                "cell": "(element: any) => `${element.release}`",
                "order": 3,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Uninstall String",
                "columnDef": "uninstall_string",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.uninstall_string}`",
                "order": 3,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Publisher",
                "columnDef": "publisher",
                "filter": "",
                "cell": "(element: any) => `${element.publisher}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Installed Date",
                "columnDef": "install_date",
                "filter": "",
                "cell": "(element: any) => `${element.installed_date}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Critical  Vulnerabilities",
                "align": "center",
                "columnDef": "critical_vulnerabilities",
                "filter": "",
                "cell": "(element: any) => `${element.critical_vulnerabilities}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-critical",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "High  Vulnerabilities",
                "align": "center",
                "columnDef": "high_vulnerabilities",
                "filter": "",
                "cell": "(element: any) => `${element.high_vulnerabilities}`",
                "order": 6,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-high",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Medium Vulnerabilities",
                "align": "center",
                "columnDef": "medium_vulnerabilities",
                "filter": "",
                "cell": "(element: any) => `${element.medium_vulnerabilities}`",
                "order": 7,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-medium",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Low Vulnerabilities",
                "align": "center",
                "columnDef": "low_vulnerabilities",
                "filter": "",
                "cell": "(element: any) => `${element.low_vulnerabilities}`",
                "order": 8,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-medium",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Vulnerability Count",
                "align": "center",
                "columnDef": "total_vulnerabilities",
                "filter": "",
                "cell": "(element: any) => `${element.total_vulnerabilities}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-critical",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Software',
            isServerSide: false,
            selectText: 'software',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/report_queries/sw_software_vulnerabilities_view',
                condition: '',
            },
            id: 'rhel_software'
        },
        customText: [
            {
                status: 1,
                DisplayText: 'Probe',
            },
            {
                status: 2,
                DisplayText: 'Lightweight Agent',
            },
            {
                status: 3,
                DisplayText: 'Lightweight Agent Installed',
            },
            {
                status: 4,
                DisplayText: 'External Scan Agent',
            },
            {
                range: { from: 0, to: 0 },
                color: '#c2ffc4',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 50 },
                color: '#ffcb70',
                'text-color': '#000000'
            },
            {
                range: { from: 51, to: 10000000 },
                color: '#ffb4a3',
                'text-color': '#000000'
            },
            {
                status: 25,
                DisplayText: 'Low',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 50,
                DisplayText: 'Medium',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 75,
                DisplayText: 'High',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 100,
                DisplayText: 'Critical',
                color: '#92243e',
                'text-color': '#ffffff'
            }
        ],
        changeValue: new Subject<any>(),
    };

    rhelProblemTableOptions: any = {
        columns: [
            {
                "header": "Problem Name",
                "columnDef": "problem_name",
                "cType": "string",
                isHyperlink: true,
                hyperLinks: { hotLink: 'https://nvd.nist.gov/vuln/detail/', match: 'CVE' },
                "filter": "",
                "cell": "(element: any) => `${element.problem_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: 'Severity',
                cType: 'string',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '100px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: false,
                order: 5,
                isSort: true
            },
            {
                "header": "Software Name",
                "columnDef": "software_name",
                "filter": "",
                "cell": "(element: any) => `${element.software_name}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: true,
                iscolumnSearch: false,
                isShowMoreData: true,
                // showpopup: true,
                // width: '200px',
            },
            { "header": "Confirmed", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "is_confirmed", "cType": "agg", "filter": "", "cell": "(element: any) => `${element.is_confirmed}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
            {
                "header": "Fix",
                "columnDef": "fix",
                "filter": "",
                "cell": "(element: any) => `${element.fix}`",
                "order": 0,
                "visible": true,
                isHyperlink: true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: 'Base',
                cType: 'number',
                columnDef: 'base_score',
                iscolumnSearch: true,
                rangeColor: true,
                align: "center",
                filter: '', width: '50px',
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                header: 'Impact',
                cType: 'number',
                columnDef: 'impact_score',
                iscolumnSearch: true,
                rangeColor: true,
                align: "center",
                filter: '', width: '50px',
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                header: 'Exploitability',
                cType: 'number',
                columnDef: 'exploitability_score',
                iscolumnSearch: true,
                rangeColor: true,
                align: "center",
                filter: '', width: '100px',
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                header: 'EPSS',
                cType: 'number',
                columnDef: 'epss_score',
                iscolumnSearch: true,
                align: "center",
                filter: 'tofixed', width: '50px',
                rangeColor: true,
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                "header": "CVSS Score",
                "columnDef": "cvss_score",
                "cType": "number",
                iscolumnSearch: true,
                rangeColor: true,
                align: "center",
                filter: '', width: '100px',
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                "header": "CISA Name",
                "columnDef": "cisa_name",
                "filter": "",
                "cell": "(element: any) => `${element.cisa_name}`",
                "order": 0,
                "visible": true,
                isHyperlink: false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
        ],
        sortOptions: { active: 'problem_name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Problem Details',
            isServerSide: false,
            selectText: '',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            isHideGlobal: true,
            actionMenuItems: [
                {
                    text: 'Suppress',
                    icon: "feather:alert-triangle",
                    callback: 'suppressFn',
                    isGlobal: true,
                    target: "problem_solution_suppress",
                }
            ],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            isNotSiteChange: true,
            serverSide: {
                url: '/r/report_queries/sw_problems_remediations_view',
                condition: '',
            },
            id: 'asset_rhel_problem_details'
        },
        customText: [
            {
                name: 'epss_score',
                customText: [
                    {
                        range: { from: 0.0, to: 0.85 },
                        color: '#67ace1',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.85, to: 0.90 },
                        color: '#ffe24b',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.90, to: 0.95 },
                        color: '#f8c851',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.95, to: 1 },
                        color: '#92243e',
                        'text-color': '#ffffff'
                    },
                ]
            },
            {
                status: 'Critical',
                DisplayText: 'CRITICAL',
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 'High',
                DisplayText: 'HIGH',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 'Medium',
                DisplayText: 'MEDIUM',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 'Low',
                DisplayText: 'LOW',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 'Info',
                DisplayText: 'INFO',
                color: '#67ace1',
                'text-color': '#000000'
            },
            {
                range: { from: 0, to: 0.4 },
                color: '#6fb8f9',
                'text-color': '#000000'
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: '#FCE94E',
                'text-color': '#000000'
            },
            {
                range: { from: 0.75, to: 1 },
                color: '#EB973E',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 4.9 },
                color: '#ffe24b',
                'text-color': '#000000'
            },
            {
                range: { from: 5, to: 8.9 },
                color: '#ec9b0c',
                'text-color': '#000000'
            },
            {
                range: { from: 9, to: 10 },
                color: '#ff6546',
                'text-color': '#000000'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: true,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },

        ],
        sColumns: [
            { header: 'Base Score', columnDef: 'base_score', cType: 'number', gloNoSearch: true },
            {
                header: 'Confirmed', columnDef: 'is_confirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                isCustomFilter: [
                    {
                        "value": "contains",
                        "key": "Equal"
                    },
                    {
                        "value": "notcontains",
                        "key": "Not Equal"
                    },
                ],
                dataSource: [
                    { text: 'Yes', value: 'false' },
                    { text: 'No', value: 'true' }
                ],
            },
        ],
        customScore: [
            { name: 'Base Score', key: 'base_score' },
            { name: 'EPSS Score', key: 'epss_score' },
            { name: 'Exploitability Score', key: 'exploitability_score', filter: 'tofixed' },
            { name: 'Impact Score', key: 'impact_score' },
        ],
        changeValue: new Subject<any>(),
    };
    servTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Display Name",
                "columnDef": "display_name",
                "filter": "",
                "cell": "(element: any) => `${element.display_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.path}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Status",
                "columnDef": "status",
                "filter": "",
                "cell": "(element: any) => `${element.status}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                isColoredCntrl: true,
                isCustomText: true,
                isCustomClass: true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "User Account",
                "columnDef": "user_account",
                "filter": "",
                "cell": "(element: any) => `${element.user_account}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Services',
            isServerSide: false,
            selectText: 'service',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_services',
                condition: '',
            },
            id: 'service'
        },
        customText: [
            {
                status: 'RUNNING',
                DisplayText: 'RUNNING',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: 'STOPPED',
                DisplayText: 'STOPPED',
                class: 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-50'

            },
            {
                status: 'active',
                DisplayText: 'RUNNING',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'

            },
            {
                status: 'inactive',
                DisplayText: 'STOPPED',
                class: 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-50'

            },
        ],
        changeValue: new Subject<any>(),
    };
    storTableOptions: any = {
        columns: [
            {
                "header": "Device Type",
                "columnDef": "device_id",
                "filter": "",
                "cell": "(element: any) => `${element.device_id}`",
                "order": 1,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Used",
                "columnDef": "used",
                "filter": "bytesconvert",
                "cell": "(element: any) => `${element.used}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Free Space",
                "columnDef": "free_space",
                "filter": "bytesconvert",
                "cell": "(element: any) => `${element.free_space}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Total",
                "columnDef": "size",
                "filter": "bytesconvert",
                "cell": "(element: any) => `${element.size}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'device_id', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Storage Devices',
            isServerSide: false,
            selectText: 'service',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_storages',
                condition: '',
            },
            id: 'storage'
        },
        customText: [
            {
                status: '1',
                DisplayText: 'ENCRYPTED',
                color: '#ffcb70',
                'text-color': '#000000'
            },
            {
                status: '2',
                DisplayText: 'UNKNOWN ENCRYPTED',
                color: '#9facee',
                'text-color': '#000000'
            },
            {
                status: undefined,
                DisplayText: 'DECRYPTED',
                color: '#ffb4a3',
                'text-color': '#000000'
            }
        ],
        changeValue: new Subject<any>(),
    };
    netwTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => ${element.name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Address",
                "columnDef": "address",
                "filter": "",
                "cell": "(element: any) => ${element.address}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Mask",
                "columnDef": "mask",
                "filter": "",
                "cell": "(element: any) => ${element.mask}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Interface",
                "columnDef": "interface",
                "filter": "",
                "cell": "(element: any) => ${element.interface}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Broadcast",
                "columnDef": "broadcast",
                "filter": "",
                "cell": "(element: any) => ${element.broadcast}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Speed",
                "columnDef": "linkspeed",
                "filter": "",
                "cell": "(element: any) => ${element.linkspeed}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Asset ID",
                "columnDef": "asset_id",
                "filter": "",
                "cell": "(element: any) => ${element.asset_id}",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Company ID",
                "columnDef": "company_id",
                "filter": "",
                "cell": "(element: any) => ${element.company_id}",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },

        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Network Adapters',
            isServerSide: false,
            selectText: 'adapters',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_interface',
                condition: '',
            },
            id: 'network_adapters'
        },
        changeValue: new Subject<any>(),
    };
    sharTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => ${element.name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => ${element.path}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Status",
                "columnDef": "status",
                "filter": "",
                "cell": "(element: any) => ${element.status}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                "header": "Type",
                "columnDef": "type",
                "filter": "",
                "cell": "(element: any) => ${element.type}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Access Mask",
                "columnDef": "access_mask",
                "filter": "",
                "cell": "(element: any) => ${element.access_mask}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Allow Maximum",
                "columnDef": "allow_maximum",
                "filter": "",
                "cell": "(element: any) => ${element.allow_maximum}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Caption",
                "columnDef": "caption",
                "filter": "",
                "cell": "(element: any) => ${element.caption}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Install Date",
                "columnDef": "install_date",
                "filter": "",
                "cell": "(element: any) => ${element.install_date}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Maximum Allowed",
                "columnDef": "maximum_allowed",
                "filter": "",
                "cell": "(element: any) => ${element.maximum_allowed}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Asset ID",
                "columnDef": "asset_id",
                "filter": "",
                "cell": "(element: any) => ${element.asset_id}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Company ID",
                "columnDef": "company_id",
                "filter": "",
                "cell": "(element: any) => ${element.company_id}",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },

        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Asset Shares',
            isServerSide: false,
            selectText: 'shares',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_shares',
                condition: '',
            },
            id: 'asset_shares'
        },
        changeValue: new Subject<any>(),
    };
    userSharTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => ${element.name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "File System Rights",
                "columnDef": "file_system_rights",
                "filter": "",
                "cell": "(element: any) => ${element.file_system_rights}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Access Control Type",
                "columnDef": "access_control_type",
                "filter": "cleanString",
                "cell": "(element: any) => ${element.path}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Identity Reference",
                "columnDef": "identity_reference",
                "filter": "",
                "cell": "(element: any) => ${element.identity_reference}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Inheritance Flag",
                "columnDef": "inheritance_flag",
                "filter": "",
                "cell": "(element: any) => ${element.inheritance_flag}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Is Inherited",
                "columnDef": "is_inherited",
                "filter": "",
                "cell": "(element: any) => ${element.is_inherited}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Propagation Flags",
                "columnDef": "propagation_flags",
                "filter": "",
                "cell": "(element: any) => ${element.propagation_flags}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },


        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'User Shares',
            isServerSide: false,
            selectText: 'shares',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/company/asset_user_shares',
                condition: '',
            },
            id: 'user_shares'
        },
        customText: [
            {
                file_system_rights: '4',
                DisplayText: 'READ',
                color: '#95a7ff',
                'text-color': '#000000'
            },
            {
                file_system_rights: '2',
                DisplayText: 'WRITE',
                color: '#eaffc6',
                'text-color': '#000000'
            },
            {
                file_system_rights: '1',
                DisplayText: 'EXECUTE',
                color: '#7effee',
                'text-color': '#000000'
            },
            {
                file_system_rights: '268435456',
                DisplayText: 'READ_EX',
                color: '#1f40ff',
                'text-color': '#ffffff'
            },
        ],
        changeValue: new Subject<any>(),
    };
    videTableOptions: any = {
        columns: [
            {
                "header": "Video Mode",
                "columnDef": "video_mode",
                "filter": "",
                "cell": "(element: any) => ${element.video_mode}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Color Depth",
                "columnDef": "color_depth",
                "filter": "",
                "cell": "(element: any) => ${element.color_depth}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Driver",
                "columnDef": "driver",
                "filter": "",
                "cell": "(element: any) => ${element.driver}",
                "order": 0,
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Asset ID",
                "columnDef": "asset_id",
                "filter": "",
                "cell": "(element: any) => ${element.asset_id}",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Company ID",
                "columnDef": "company_id",
                "filter": "",
                "cell": "(element: any) => ${element.company_id}",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },

        ],
        sortOptions: { active: 'video_mode', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Asset Video Info',
            isServerSide: false,
            selectText: 'videos',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_video_info',
                condition: '',
            },
            id: 'asset_video_info'
        },
        changeValue: new Subject<any>(),
    };
    biosTableOptions: any = {
        columns: [
            {
                "header": "Vendor",
                "columnDef": "vendor",
                "filter": "",
                "cell": "(element: any) => ${element.vendor}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Version",
                "columnDef": "version",
                "filter": "",
                "cell": "(element: any) => ${element.version}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Install Date",
                "columnDef": "date",
                "filter": "utcToLocale",
                "cell": "(element: any) => ${element.date}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Revision",
                "columnDef": "revision",
                "filter": "",
                "cell": "(element: any) => ${element.revision}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Firmware Type",
                "columnDef": "firmware_type",
                "filter": "",
                "cell": "(element: any) => ${element.firmware_type}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Extra",
                "columnDef": "extra",
                "filter": "",
                "cell": "(element: any) => ${element.extra}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Company ID",
                "columnDef": "company_id",
                "filter": "",
                "cell": "(element: any) => ${element.company_id}",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'vendor', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'BIOS Info',
            isServerSide: false,
            selectText: 'bios',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_bios_info',
                condition: '',
            },
            id: 'bios_info'
        },
        changeValue: new Subject<any>(),
    };
    firepTableOptions: any = {
        columns: [
            {
                "header": "POLICY STATUS",
                "columnDef": "policy_status",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.policy_status}`",
                "order": 0,
                "visible": true,
                isColoredCntrl: true,
                isCustomText: true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "POLICY TYPE",
                "columnDef": "policy_type",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.policy_type}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "ASSET ID",
                "columnDef": "asset_id",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.asset_id}`",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Last Updated",
                "columnDef": "last_updated",
                "cType": "string",
                "isHyperlink": false,
                "filter": "epochToDate",
                "cell": "(element: any) => `${element.last_updated}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }],
        sortOptions: { active: 'policy_type', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'FIRWALL POLICY',
            isServerSide: false,
            selectText: 'bios',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_firewall_policy',
                condition: '',
            },
            id: 'firewall_policy'
        },
        customText: [
            {
                status: 0,
                DisplayText: 'DISABLED',
                color: '#ffe24b',
                'text-color': '#000000'
            },
            {
                status: 1,
                DisplayText: 'ENABLED',
                color: '#9facee',
                'text-color': '#000000'
            },
        ],
        changeValue: new Subject<any>(),
    };
    openPortTableOptions: any = {
        columns: [
            {
                "header": "PORT",
                "columnDef": "port",
                isHyperlink: true,
                hyperLinks: { hotLink: 'https://www.speedguide.net/port.php?port=', match: '' },
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.port}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "SERVICE",
                "columnDef": "name",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "cType": "string",
                "isHyperlink": false,
                "filter": "cleanString",
                "cell": "(element: any) => `${element.path}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Protocol",
                "columnDef": "protocol",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.protocol}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Address",
                "columnDef": "address",
                "cType": "string",
                "isHyperlink": false,
                isColoredCntrl: false,
                isCustomText: false,
                isCustomClass: false,
                "filter": "",
                "cell": "(element: any) => `${element.address}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }
        ],
        sortOptions: { active: 'port', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Internal Ports',
            isServerSide: false,
            selectText: 'ports',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_open_ports',
                condition: '',
            },
            id: 'internal_ports'
        },
        changeValue: new Subject<any>(),
    };
    assetPatchesTableOptions: any = {
        columns: [
            {
                "header": "Fix",
                "columnDef": "hotfix_id",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.hotfix_id}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                "header": "Installed On",
                "columnDef": "install_date",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.install_date}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Installed By",
                "columnDef": "installed_by",
                "cType": "string",
                "isHyperlink": false,
                "filter": "cleanString",
                "cell": "(element: any) => `${element.installed_by}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'hotfix_id', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Asset Patches',
            isServerSide: false,
            selectText: 'patches',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_patches_info',
                condition: '',
            },
            id: 'asset_patches'
        },
        changeValue: new Subject<any>(),
    };
    portTableOptions: any = {
        columns: [
            {
                "header": "PORT",
                "columnDef": "port",
                isHyperlink: true,
                hyperLinks: { hotLink: 'https://www.speedguide.net/port.php?port=', match: '' },
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.port}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "SERVICE",
                "columnDef": "service",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.service}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Product",
                "columnDef": "product",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.product}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Protocol",
                "columnDef": "protocol",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.protocol}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Status",
                "columnDef": "status",
                "cType": "string",
                "isHyperlink": false,
                isColoredCntrl: true,
                isCustomText: true,
                isCustomClass: true,
                "filter": "",
                "cell": "(element: any) => `${element.status}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "IS SECURE",
                "columnDef": "is_secure",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.is_secure}`",
                "order": 0,
                "visible": true,
                isColoredCntrl: true,
                isCustomText: true,
                isCustomClass: true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "VERSION",
                "columnDef": "version",
                "cType": "string",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.version}`",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                "header": "Vulnerability Count",
                "columnDef": "vul_count",
                "cType": "number",
                "isHyperlink": false,
                "filter": "",
                "cell": "(element: any) => `${element.vul_count}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }
        ],
        sortOptions: { active: 'is_secure', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'External Ports',
            isServerSide: false,
            selectText: 'ports',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/report_queries/asset_ports_view',
                condition: '',
            },
            id: 'external_ports'
        },
        customText: [
            {
                status: 'open',
                DisplayText: 'Open',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: 'closed',
                DisplayText: 'Closed',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: true,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

            }
        ],
        changeValue: new Subject<any>(),
    };
    probTableOptions: any = {};
    extAppTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Version",
                "columnDef": "version",
                "filter": "",
                "cell": "(element: any) => `${element.version}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Browser Type",
                "columnDef": "browser_type",
                "filter": "",
                "cell": "(element: any) => `${element.browser_type}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.path}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Extension Programs',
            isServerSide: false,
            selectText: 'ExtensionPrograms',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/company/browser_extensions',
                condition: '',
            },
            id: 'extension_programs'
        },
        changeValue: new Subject<any>(),
    };
    userTableOptions: any = {
        columns: [
            {
                "header": "User Name",
                "columnDef": "username",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.username}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "User Group",
                "columnDef": "group_name",
                "filter": "",
                "cell": "(element: any) => `${element.group_name}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Logon Time",
                "columnDef": "logged_in",
                "filter": "epochToDate",
                "cell": "(element: any) => `${element.logged_in}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                "header": "Directory",
                "columnDef": "directory",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.directory}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Shell",
                "columnDef": "shell",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.shell}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "UID",
                "columnDef": "uid",
                "filter": "",
                "cell": "(element: any) => `${element.uid}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                isColoredCntrl: false,
                isCustomText: false,
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "GID",
                "columnDef": "gid",
                "filter": "",
                "cell": "(element: any) => `${element.gid}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                isColoredCntrl: false,
                isCustomText: false,
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }
        ],
        sortOptions: { active: 'username', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Asset Users',
            isServerSide: false,
            selectText: 'AssetUsers',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_users',
                condition: '',
            },
            id: 'asset_users'
        },
        changeValue: new Subject<any>(),
    };
    registryTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Type",
                "columnDef": "type",
                "filter": "",
                "cell": "(element: any) => `${element.type}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Found",
                "columnDef": "found",
                "filter": "",
                "cell": "(element: any) => `${element.found}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Registry Misconfiguration',
            isServerSide: false,
            selectText: 'RegistryMisconfiguration',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_registry_misconfiguration',
                condition: '',
            },
            id: 'registry_misconfiguration'
        },
        changeValue: new Subject<any>(),
    };
    firewallRuleDirection = '';
    firewallShow = true;
    rulesTableOptions: any = {
        columns: [
            {
                "header": "Direction",
                "columnDef": "direction",
                "filter": "",
                "cell": "(element: any) => `${element.direction}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                isCustomClass: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Name",
                "columnDef": "name",
                "cType": "string",
                "filter": "",
                "width": "200px",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Action",
                "columnDef": "action",
                "filter": "",
                "cell": "(element: any) => `${element.action}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                isCustomClass: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Enabled",
                "columnDef": "enabled",
                cType: "agg",
                "filter": "",
                "cell": "(element: any) => `${element.enabled}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Grouping",
                "columnDef": "grouping",
                "filter": "",
                "cell": "(element: any) => `${element.grouping}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },

            {
                "header": "Protocol",
                "columnDef": "protocol",
                "filter": "",
                "cell": "(element: any) => `${element.protocol}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Local Addresses",
                "columnDef": "local_addresses",
                "filter": "",
                "cell": "(element: any) => `${element.local_addresses}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Remote Addresses",
                "columnDef": "remote_addresses",
                "filter": "",
                "cell": "(element: any) => `${element.remote_addresses}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Local Ports",
                "columnDef": "local_ports",
                "filter": "",
                "cell": "(element: any) => `${element.local_ports}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Service Name",
                "columnDef": "service_name",
                "filter": "",
                "cell": "(element: any) => `${element.service_name}`",
                "order": 0,
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "App Name",
                "columnDef": "app_name",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.app_name}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Remote Ports",
                "columnDef": "remote_ports",
                "filter": "",
                "cell": "(element: any) => `${element.remote_ports}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Icmp Type Codes",
                "columnDef": "icmp_types_codes",
                "filter": "",
                "cell": "(element: any) => `${element.icmp_types_codes}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Profile Domain",
                "columnDef": "profile_domain",
                cType: "agg",
                "filter": "",
                "cell": "(element: any) => `${element.profile_domain}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Profile Private",
                "columnDef": "profile_private",
                cType: "agg",
                "filter": "",
                "cell": "(element: any) => `${element.profile_private}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Profile Public",
                "columnDef": "profile_public",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.profile_public}`",
                "order": 0,
                "align": "left",
                "visible": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: true,
                isCustomText: true,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Inbound Firewall Rules',
            isServerSide: false,
            selectText: 'FirewallRules',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: false,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_firewall_rules',
                condition: `direction='${this.firewallRuleDirection}'`,
            },
            id: 'firewall_rules'
        },
        customText: [
            {
                status: 'Allow',
                DisplayText: 'Allow',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: 'Deny',
                DisplayText: 'Deny',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

            },
            {
                status: 'In',
                DisplayText: 'IN',
                class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'
            },
            {
                status: 'Out',
                DisplayText: 'OUT',
                class: 'bg-purple-200 text-purple-800 dark:bg-purple-600 dark:text-purple-50'
            },
            {
                status: 1,
                DisplayText: 'ENABLED',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: 0,
                DisplayText: 'DISABLED',
                class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'
            },
        ],
        changeValue: new Subject<any>(),
    };
    unquoted_service_path: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => ${element.name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Service Type",
                "columnDef": "service_type",
                "filter": "",
                "cell": "(element: any) => ${element.service_type}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Display Name",
                "columnDef": "display_name",
                "filter": "",
                "cell": "(element: any) => ${element.display_name}",
                "order": 0,
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Start Type",
                "columnDef": "start_type",
                "filter": "",
                "cell": "(element: any) => ${element.start_type}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => ${element.path}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },

        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Unquoted Service Path',
            isServerSide: false,
            selectText: 'UnquotedServicePath',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/asset/asset_unqouted_services',
                condition: '',
            },
            id: 'unquoted_service_path'
        },
        changeValue: new Subject<any>(),
    };
    assetInstalledDrivers: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Version",
                "columnDef": "version",
                "filter": "",
                "cell": "(element: any) => `${element.version}`",
                "order": 2,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.path}`",
                "order": 3,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Uninstall String",
                "columnDef": "uninstall_string",
                "filter": "cleanString",
                "cell": "(element: any) => `${element.uninstall_string}`",
                "order": 3,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Publisher",
                "columnDef": "publisher",
                "filter": "",
                "cell": "(element: any) => `${element.publisher}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Installed Date",
                "columnDef": "install_date",
                "filter": "",
                "cell": "(element: any) => `${element.installed_date}`",
                "order": 4,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Critical  Vulnerabilities",
                "align": "center",
                "columnDef": "critical_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.critical_vuls_count}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-critical",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "High  Vulnerabilities",
                "align": "center",
                "columnDef": "high_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.high_vuls_count}`",
                "order": 6,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-high",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Medium Vulnerabilities",
                "align": "center",
                "columnDef": "medium_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.medium_vuls_count}`",
                "order": 7,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-medium",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Low Vulnerabilities",
                "align": "center",
                "columnDef": "low_vuls_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.low_vuls_count}`",
                "order": 8,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-medium",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "Vulnerability Count",
                "align": "center",
                "columnDef": "vul_count",
                "filter": "",
                cType: "agg",
                "cell": "(element: any) => `${element.vul_count}`",
                "order": 5,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                isClass: true,
                "class": "cs-critical",
                "color": "",
                "isProgressCntrl": false,
                isColoredCntrl: false,
                isCustomText: false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            { "header": "Confirmed", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "unconfirmed", "cType": "agg", "filter": "", "cell": "(element: any) => `${element.unconfirmed}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },

        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Installed Drivers',
            isServerSide: false,
            selectText: 'assetInstalledDrivers',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/report_queries/asset_software',
                condition: '',
            },
            id: 'asset_installed_drivers'
        },
        customText: [
            {
                status: true,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: false,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
        ],
        sColumns: [
            {
                header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                isCustomFilter: [
                    {
                        "value": "contains",
                        "key": "Equal"
                    },
                    {
                        "value": "notcontains",
                        "key": "Not Equal"
                    },
                ],
                dataSource: [
                    { text: 'Yes', value: 'false' },
                    { text: 'No', value: 'true' }
                ],
            },
        ],
        changeValue: new Subject<any>(),
    };
    runningProcesses: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "filter": "",
                "cell": "(element: any) => ${element.name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Root",
                "columnDef": "root",
                "filter": "",
                "cell": "(element: any) => ${element.root}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Path",
                "columnDef": "path",
                "filter": "cleanString",
                "cell": "(element: any) => ${element.path}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "State",
                "columnDef": "state",
                "filter": "",
                "cell": "(element: any) => ${element.state}",
                "order": 0,
                "visible": true,
                "isToolTip": true,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Command Line",
                "columnDef": "cmdline",
                "filter": "",
                "cell": "(element: any) => ${element.cmdline}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Running Processes',
            isServerSide: false,
            selectText: 'runningProcesses',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/report_queries/asset_processes_running',
                condition: '',
            },
            id: 'runningProcesses'
        },
        changeValue: new Subject<any>(),
    };
    registryprobTableOptions: any = {
        columns: [
            {
                "header": "Problem Name",
                "columnDef": "name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Vul ID",
                "columnDef": "vul_id",
                "cType": "string",
                isHyperlink: true,
                hyperLinks: { hotLink: 'https://nvd.nist.gov/vuln/detail/', match: 'CVE' },
                "filter": "",
                "cell": "(element: any) => `${element.vul_id}`",
                "order": 0,
                width: "200px",
                isTargetLink: false,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Fix",
                columnDef: "fix",
                cType: "string",
                isHyperlink: true,
                isTargetLink: 'url',
                filter: "",
                cell: "(element: any) => `${element.fix}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                header: 'Severity',
                cType: 'string',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '100px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: false,
                order: 5,
                isSort: true
            },
            {
                header: 'Score',
                cType: 'number',
                columnDef: 'base_score',
                iscolumnSearch: true,
                rangeColor: true,
                isMultiRange: true,
                align: "center",
                filter: '',
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: false
            },
            {
                header: "Confirmed",
                cType: "agg",
                columnDef: "is_confirmed",
                filter: "",
                cell: "(element: any) => `${element.is_confirmed}`",
                order: 3,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                isClass: true,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: true,
                isCustomClass: true,
                isCustomText: true,
                colList: [],
                isfaicon: false,
                isAddingText: '',
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            }
            // {
            //     header: "Ticket ID",
            //     columnDef: "ticket_ids",
            //     cType: "agg",
            //     filter: "",
            //     cell: "(element: any) => `${element.ticket_ids}`",
            //     order: 0,
            //     visible: true,
            //     isToolTip: false,
            //     isHyperlinkKey: 'ticket_status_map',
            //     isToolTipCol: '',
            //     hasMultiData: false,
            //     class: '',
            //     color: '',
            //     isProgressCntrl: false,
            //     isColoredCntrl: false,
            //     colList: [],
            //     isfaicon: false,
            //     isAddingText: false,
            //     addingText: '',
            //     img: false,
            //     imgPath: '',
            //     isSort: false,
            //     iscolumnSearch: false,
            //     isShowMoreShowLess: true
            // },

        ],
        sortOptions: { active: 'severity', direction: 'desc' },
        _pageData: [],
        tableOptions: {
            title: 'Registry Problems',
            isServerSide: false,
            selectText: 'rules',
            loading: true,
            floatingFilter: true,
            rowSelection: true,
            showAction: true,
            isHideGlobal: true,
            actionMenuItems: [
                {
                    text: 'Suppress',
                    icon: "feather:alert-triangle",
                    callback: 'suppressFn',
                    isGlobal: true,
                    target: "registry_suppress",
                },
                // {
                //     text: 'Integration Action',
                //     icon: "heroicons_outline:ticket",
                //     callback: 'suppressFn',
                //     isGlobal: true,
                //     target: "registry_integration_action",
                // }
                // {
                //     text: 'Suppress',
                //     icon: 'remove_circle_outline',
                //     callback: 'suppressFn',
                //     isGlobal: true,
                // },
                // {
                //     text: 'Unsuppress',
                //     icon: 'find_replace',
                //     callback: 'unsuppressFn',
                //     isGlobal: true,
                // },
            ],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            isNotSiteChange: true,
            serverSide: {
                url: '/r/report_queries/registry_problems_remediation',
                condition: 'is_suppressed=false',
                type: 'post',
                isGlobal: true,
                isMergeApi: false,
                params: {},
                customFnCall: 'getTicketIds',
                requiredKeys: {
                    asset_id: null,
                    filter_key: 'vul_id',
                }
            },
            id: 'registry_problems_summary'
        },
        customText: [
            {
                name: 'epss_score',
                customText: [
                    {
                        range: { from: 0.0, to: 0.85 },
                        color: '#67ace1',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.85, to: 0.90 },
                        color: '#ffe24b',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.90, to: 0.95 },
                        color: '#f8c851',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.95, to: 1 },
                        color: '#92243e',
                        'text-color': '#ffffff'
                    },
                ]
            },
            {
                status: 'Critical',
                DisplayText: 'CRITICAL',
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 'High',
                DisplayText: 'HIGH',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 'Medium',
                DisplayText: 'MEDIUM',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 'Low',
                DisplayText: 'LOW',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 'Info',
                DisplayText: 'INFO',
                color: '#67ace1',
                'text-color': '#000000'
            },
            {
                range: { from: 0, to: 0.4 },
                color: '#6fb8f9',
                'text-color': '#000000'
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: '#FCE94E',
                'text-color': '#000000'
            },
            {
                range: { from: 0.75, to: 1 },
                color: '#EB973E',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 4.9 },
                color: '#ffe24b',
                'text-color': '#000000'
            },
            {
                range: { from: 5, to: 8.9 },
                color: '#ec9b0c',
                'text-color': '#000000'
            },
            {
                range: { from: 9, to: 10 },
                color: '#ff6546',
                'text-color': '#000000'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: true,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },

        ],
        sColumns: [
            { header: 'Base Score', columnDef: 'base_score', cType: 'number', gloNoSearch: true },
            {
                header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                isCustomFilter: [
                    {
                        "value": "contains",
                        "key": "Equal"
                    },
                    {
                        "value": "notcontains",
                        "key": "Not Equal"
                    },
                ],
                dataSource: [
                    { text: 'Yes', value: 'false' },
                    { text: 'No', value: 'true' }
                ],
            },
        ],
        customScore: [
            { name: 'Base Score', key: 'base_score' },
            { name: 'EPSS Score', key: 'epss_score' },
            { name: 'Exploitability Score', key: 'exploitability_score', filter: 'tofixed' },
            { name: 'Impact Score', key: 'impact_score' },
        ],
        changeValue: new Subject<any>(),
    };
    remediatedRegistryprobTableOptions: any = {
        columns: [
            {
                "header": "Problem Name",
                "columnDef": "name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Remediated On",
                "columnDef": "remediated_on",
                "filter": "utcToLocale",
                "cType": "agg",
                "cell": "(element: any) => `${element.remediated_on}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Fix",
                columnDef: "fix",
                cType: "string",
                isHyperlink: true,
                isTargetLink: 'url',
                filter: "",
                cell: "(element: any) => `${element.fix}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                header: 'Severity',
                cType: 'string',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '100px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: false,
                order: 5,
                isSort: true
            },
            {
                header: 'Score',
                cType: 'number',
                columnDef: 'base_score',
                iscolumnSearch: true,
                rangeColor: true,
                isMultiRange: true,
                align: "center",
                filter: '',
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: false
            },
            {
                header: "Confirmed",
                cType: "agg",
                columnDef: "is_confirmed",
                filter: "",
                cell: "(element: any) => `${element.is_confirmed}`",
                order: 3,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                isClass: true,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: true,
                isCustomClass: true,
                isCustomText: true,
                colList: [],
                isfaicon: false,
                isAddingText: '',
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            }
        ],
        sortOptions: { active: 'severity', direction: 'desc' },
        _pageData: [],
        tableOptions: {
            title: 'Remediated Registry Problems',
            isServerSide: false,
            selectText: '',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            isHideGlobal: true,
            actionMenuItems: [],
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            isNotSiteChange: true,
            serverSide: {
                url: '/r/report_queries/registry_problems_remediation',
                condition: 'is_remediated = true',
                type: 'post',
                isGlobal: true,
                params: {},
            },
            id: 'remediated_registry_problems_summary'
        },
        customText: [
            {
                name: 'epss_score',
                customText: [
                    {
                        range: { from: 0.0, to: 0.85 },
                        color: '#67ace1',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.85, to: 0.90 },
                        color: '#ffe24b',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.90, to: 0.95 },
                        color: '#f8c851',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.95, to: 1 },
                        color: '#92243e',
                        'text-color': '#ffffff'
                    },
                ]
            },
            {
                status: 'Critical',
                DisplayText: 'CRITICAL',
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 'High',
                DisplayText: 'HIGH',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 'Medium',
                DisplayText: 'MEDIUM',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 'Low',
                DisplayText: 'LOW',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 'Info',
                DisplayText: 'INFO',
                color: '#67ace1',
                'text-color': '#000000'
            },
            {
                range: { from: 0, to: 0.4 },
                color: '#6fb8f9',
                'text-color': '#000000'
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: '#FCE94E',
                'text-color': '#000000'
            },
            {
                range: { from: 0.75, to: 1 },
                color: '#EB973E',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 4.9 },
                color: '#ffe24b',
                'text-color': '#000000'
            },
            {
                range: { from: 5, to: 8.9 },
                color: '#ec9b0c',
                'text-color': '#000000'
            },
            {
                range: { from: 9, to: 10 },
                color: '#ff6546',
                'text-color': '#000000'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: true,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },

        ],
        sColumns: [
            { header: 'Base Score', columnDef: 'base_score', cType: 'number', gloNoSearch: true },
            {
                header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                isCustomFilter: [
                    {
                        "value": "contains",
                        "key": "Equal"
                    },
                    {
                        "value": "notcontains",
                        "key": "Not Equal"
                    },
                ],
                dataSource: [
                    { text: 'Yes', value: 'false' },
                    { text: 'No', value: 'true' }
                ],
            },
        ],
        customScore: [
            { name: 'Base Score', key: 'base_score' },
            { name: 'EPSS Score', key: 'epss_score' },
            { name: 'Exploitability Score', key: 'exploitability_score', filter: 'tofixed' },
            { name: 'Impact Score', key: 'impact_score' },
        ],
        changeValue: new Subject<any>(),
    };

    installvulsprobTableOptions: any = {
        columns: [
            {
                "header": "Name",
                "columnDef": "name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.name}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            }, {
                header: "Evidence",
                columnDef: "evidence",
                cType: "agg",
                filter: "",
                cell: "(element: any) => `${element.evidence}`",
                isHyperlink: false,
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
            },
            {
                header: "Description",
                columnDef: "description",
                cType: "string",
                filter: "",
                cell: "(element: any) => `${element.description}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
                isShowMoreData: true
            },
            {
                header: 'Severity',
                cType: 'string',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                "header": "Base",
                rangeColor: true,
                cType: "number",
                width: 'w-[80px]',
                "columnDef": "base_score",
                "filter": "",
                "cell": "(element: any) => `${element.base_score}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "EPSS Score",
                rangeColor: true,
                cType: "number",
                width: "w-[80px]",
                columnDef: "epss_score",
                filter: "",
                cell: "(element: any) => `${element.epss_score}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
            {
                "header": "Exploitability",
                rangeColor: true,
                cType: "number",
                width: 'w-[80px]',
                "columnDef": "exploitability_score",
                "filter": "",
                "cell": "(element: any) => `${element.exploitability_score}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Impact",
                rangeColor: true,
                cType: "number",
                width: 'w-[80px]',
                "columnDef": "impact_score",
                "filter": "",
                "cell": "(element: any) => `${element.impact_score}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },

            {
                "header": "Problem Group Id",
                "columnDef": "problem_group_id",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.problem_group_id}`",
                "order": 0,
                "visible": false,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Confirmed",
                cType: "agg",
                columnDef: "is_confirmed",
                filter: "",
                cell: "(element: any) => `${element.is_confirmed}`",
                order: 3,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                isClass: true,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: true,
                isCustomClass: true,
                isCustomText: true,
                colList: [],
                isfaicon: false,
                isAddingText: '',
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            },
        ],
        sortOptions: { active: 'vul_id', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Installed Driver Vulnerabilities',
            isServerSide: false,
            selectText: 'rules',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            isHideGlobal: true,
            actionMenuItems: false,
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            isNotSiteChange: true,
            serverSide: {
                url: '/r/asset/driver_vuls',
                condition: '',
            },
            id: 'install_driver_vuls'
        },
        customText: [
            {
                name: 'epss_score',
                customText: [
                    {
                        range: { from: 0.0, to: 0.85 },
                        color: '#67ace1',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.85, to: 0.90 },
                        color: '#ffe24b',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.90, to: 0.95 },
                        color: '#f8c851',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.95, to: 1 },
                        color: '#92243e',
                        'text-color': '#ffffff'
                    },
                ]
            },
            {
                status: 'Critical',
                DisplayText: 'CRITICAL',
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 'High',
                DisplayText: 'HIGH',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 'Medium',
                DisplayText: 'MEDIUM',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 'Low',
                DisplayText: 'LOW',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 'Info',
                DisplayText: 'INFO',
                color: '#67ace1',
                'text-color': '#000000'
            },
            {
                range: { from: 0, to: 0.4 },
                color: '#6fb8f9',
                'text-color': '#000000'
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: '#FCE94E',
                'text-color': '#000000'
            },
            {
                range: { from: 0.75, to: 1 },
                color: '#EB973E',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 4.9 },
                color: '#ffe24b',
                'text-color': '#000000'
            },
            {
                range: { from: 5, to: 8.9 },
                color: '#ec9b0c',
                'text-color': '#000000'
            },
            {
                range: { from: 9, to: 10 },
                color: '#ff6546',
                'text-color': '#000000'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: true,
                DisplayText: 'Yes',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },

        ],
        // sColumns: [
        //     { header: 'Base Score', columnDef: 'base_score', cType: 'number', gloNoSearch: true },
        //     {
        //         header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
        //         isCustomFilter: [
        //             {
        //                 "value": "contains",
        //                 "key": "Equal"
        //             },
        //             {
        //                 "value": "notcontains",
        //                 "key": "Not Equal"
        //             },
        //         ],
        //         dataSource: [
        //             { text: 'Yes', value: 'false' },
        //             { text: 'No', value: 'true' }
        //         ],
        //     },
        // ],
        // customScore: [
        //     { name: 'Base Score', key: 'base_score' },
        //     { name: 'EPSS Score', key: 'epss_score' },
        //     { name: 'Exploitability Score', key: 'exploitability_score', filter: 'tofixed' },
        //     { name: 'Impact Score', key: 'impact_score' },
        // ],
        changeValue: new Subject<any>(),
    };

    installremprobTableOptions: any = {
        columns: [
            {
                "header": "product Name",
                "columnDef": "product",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.product}`",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                header: "Fix",
                columnDef: "fix",
                cType: "string",
                isHyperlink: true,
                isTargetLink: 'url',
                filter: "",
                cell: "(element: any) => `${element.fix}`",
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: "",
                hasMultiData: false,
                class: "",
                color: "",
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: "",
                img: false,
                imgPath: "",
                isSort: true,
                iscolumnSearch: false,
            }, {
                header: 'Severity',
                cType: 'string',
                columnDef: 'severity',
                iscolumnSearch: true,
                filter: '', width: '120px',
                isColoredCntrl: true,
                isCustomText: true,
                visible: true,
                order: 5,
                isSort: true
            },
            {
                header: 'EPSS',
                cType: 'number',
                columnDef: 'epss_score',
                iscolumnSearch: true,
                isEpssScore: true,
                align: "center",
                filter: 'tofixed',
                rangeColor: true,
                isColoredCntrl: false,
                isCustomText: false,
                visible: true,
                order: 5,
                isSort: true
            }, {
                header: "Evidence",
                columnDef: "evidence",
                cType: "agg",
                filter: "",
                cell: "(element: any) => `${element.evidence}`",
                isHyperlink: false,
                order: 0,
                visible: true,
                isToolTip: false,
                isToolTipCol: '',
                hasMultiData: false,
                class: '',
                color: '',
                isProgressCntrl: false,
                isColoredCntrl: false,
                colList: [],
                isfaicon: false,
                isAddingText: false,
                addingText: '',
                img: false,
                imgPath: '',
                isSort: false,
                iscolumnSearch: false,
            }
        ],
        sortOptions: { active: 'updated', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Installed Driver Remediations',
            isServerSide: false,
            selectText: 'rules',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: false,
            isHideGlobal: true,
            actionMenuItems: false,
            pagination: true,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showhideList: true,
            refreshData: true,
            showFilter: true,
            showTagFilter: true,
            exportExcel: true,
            add: false,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            isNotSiteChange: true,
            serverSide: {
                url: '/r/asset/driver_rems',
                condition: '',
            },
            id: 'install_driver_rem'
        },
        customText: [
            {
                name: 'epss_score',
                customText: [
                    {
                        range: { from: 0.0, to: 0.85 },
                        color: '#67ace1',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.85, to: 0.90 },
                        color: '#ffe24b',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.90, to: 0.95 },
                        color: '#f8c851',
                        'text-color': '#000000'
                    },
                    {
                        range: { from: 0.95, to: 1 },
                        color: '#92243e',
                        'text-color': '#ffffff'
                    },
                ]
            },
            {
                status: 'Critical',
                DisplayText: 'CRITICAL',
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 'High',
                DisplayText: 'HIGH',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 'Medium',
                DisplayText: 'MEDIUM',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 'Low',
                DisplayText: 'LOW',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 'Info',
                DisplayText: 'INFO',
                color: '#67ace1',
                'text-color': '#000000'
            },
            {
                range: { from: 0, to: 0.4 },
                color: '#6fb8f9',
                'text-color': '#000000'
            },
            {
                range: { from: 0.5, to: 0.74 },
                color: '#FCE94E',
                'text-color': '#000000'
            },
            {
                range: { from: 0.75, to: 1 },
                color: '#EB973E',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 4.9 },
                color: '#ffe24b',
                'text-color': '#000000'
            },
            {
                range: { from: 5, to: 8.9 },
                color: '#ec9b0c',
                'text-color': '#000000'
            },
            {
                range: { from: 9, to: 10 },
                color: '#ff6546',
                'text-color': '#000000'
            },
        ],
        customScore: [
            { name: 'EPSS Score', key: 'epss_score' }
        ],
        changeValue: new Subject<any>(),
    };

    registryprobTableOptions1: any;
    public prbFilterCtrl: FormControl = new FormControl();
    public searching = false;
    prbid: any;
    prbHash: any = {};
    problems: any = [];
    isNoProblem = false;
    currentProblem: any;
    storedProblems: any[] = [];
    cView = 'problem';
    selectedIndex = 0;
    selectedSecurityIndex = 0;
    selectedAssetIndex = 0;
    reportCard: any = [];
    sortReverse: boolean = false;
    sortType: any = 'problem_count';
    importance: any = { 25: 'LOW', 50: 'MEDIUM', 75: 'HIGH', 100: 'CRITICAL' };
    @ViewChild('complianceTemplate') complianceTemplate: TemplateRef<any>;
    problemTemplate: any = [];
    solutionTemplate: any = [];
    overviewTemplates: any = [];
    currentRemediation: any = {};
    fingerPrintData: any;
    minEndDate: string;
    patchFilter: any = '';
    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    @ViewChild('securityTabGroup') securityTabGroup: MatTabGroup;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    currentUser: any = '';
    reasonsList: string[] = [
        'Justified for business reasons',
        'False Positive',
        'Compensating control in place',
        'Other',
    ];
    suppressData: any = {};
    suppressDataRegistry: any = {};
    internalUsers: any = [];
    fromDate = new Date();
    fromMinEndDate: any = null;
    showData = false;
    private subs: Subscription;
    pgc: any;
    pgcHash: any = {};
    firewall_rules_notreq: any = ['network_device', 'Storage', 'Hypervisor', 'Printer'];
    inbound_and_outbound: any = ['windows', 'Darwin', 'darwin'];
    selinux_settings: any = ['rhel', 'arch', 'kali', 'sles'];
    ubuntu_firewall_settings: any = ['Ubuntu', 'debian', 'ubuntu'];
    ip_tables: any = ['network_device', 'Storage', 'Hypervisor', 'Printer', 'rhel', 'arch', 'kali', 'sles', 'Ubuntu', 'debian', 'ubuntu'];
    snmp_issue: any = ['router', 'switch', 'firewall', 'layer 3 switch', 'network_device', 'printer', 'storage'];
    redhat_platform: any = ['rhel'];
    ubuntu_platform: any = ['ubuntu']
    cred: any = {};
    osType: any = [
        { key: "Windows", value: "WINDOWS" },
        { key: "MacOS", value: "MAC" },
        { key: "Linux", value: "LINUX" },
        { key: "VMware", value: "VMWARE" },
        { key: "Network Device", value: "NETWORKDEVICE" },
    ];
    credentialType: any = [
        { key: "AD", value: "AD", allowOS: ["WINDOWS"] },
        {
            key: "Asset Credential", value: "ASSETCREDENTIAL",
            allowOS: [
                "WINDOWS",
                "MAC",
                "LINUX",
                "VMWARE",
                "NETWORKDEVICE",
            ],
        },
        { key: "SNMP V1", value: "SNMPV1", allowOS: ["NETWORKDEVICE"] },
        { key: "SNMP V2", value: "SNMPV2", allowOS: ["NETWORKDEVICE"] },
        { key: "SNMP V3", value: "SNMPV3", allowOS: ["NETWORKDEVICE"] },
        {
            key: "Firewall Credential",
            value: "FIREWALLCREDENTIAL",
            allowOS: ["FIREWALLOS"],
        },
    ];
    authProtocol: any = [
        {
            value: "",
            label: "No Protocol",
        },
        {
            value: "md5",
            label: "MD5",
        },
        {
            value: "sha",
            label: "SHA",
        },
    ];
    privacyProtocol: any = [
        {
            value: "",
            label: "No Protocol",
        },
        {
            value: "aes128",
            label: "AES128",
        },
        {
            value: "aes256",
            label: "AES256",
        },
        {
            value: "des",
            label: "DES",
        },
    ];
    currentcred: any = {};
    isDisbleReport = false;
    problemsScore = [];
    credentialOs: any = [
        'vmware',
        'network_device',
        'linux',
        'windows',
        'darwin',
        'mac',
        'linux based os',
        'ubuntu',
        'netbsd',
        'freebsd',
        'linux_kernel',
        'debian',
        'debian_linux',
        'rhel',
        'redhat',
        'centos',
        'fedora',
        'Storage'
    ];
    table_settings: any = {};
    suppressed_table_settings: any = {};
    constructor(
        public cs: CommonService, private loaderService: LoaderService, private _ds: DynamicSettingsService, private _fuseMediaWatcherService: FuseMediaWatcherService,
        private route: ActivatedRoute, public modalService: ModalService, private _changeDetectorRef: ChangeDetectorRef,
        public _bs: BaseRequestService, public _userService: UserService, public confirmDialog: FuseConfirmationService,
        private toast: MyToastrService, private datePipe: DatePipe, public integrationActionService: IntegrationActionsService,) {
        this.setMinEndDate();
        this.cs.reloadProgram
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((trigger: any) => {
                this.reloadProgramSup();
                this._changeDetectorRef.markForCheck();
            });
        this.cs.reloadRegistryProgram
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((trigger: any) => {
                const data = Object.assign({}, this.registryprobTableOptions);
                data.pageData = []; data.tableOptions.pageTotal = 0;
                this.registryprobTableOptions = {}; this._changeDetectorRef.detectChanges();
                this.registryprobTableOptions = data; this._changeDetectorRef.detectChanges();
            });
    }

    // TODO:

    selectTab(tab: any, $event: any): void {
        if (tab === '1') {
            this.selectedIndex = $event;
        } else if (tab === '2') {
            this.selectedSecurityIndex = $event;
        }
        // setTimeout(() => {
        //     let el = document.getElementById('tabGroup' + tab);
        //     el.scrollIntoView({ behavior: 'smooth' });
        // }, 100);
    }

    reloadProgramSup(): void {
        const assetId = (this.cAsset.id) ? this.cAsset.id : this.cAsset.asset_id;
        this.loaderService.display(true);
        this._bs.doRequest((this.is_deprecated) ? '/r/asset/deprecated_assets_view' : `/r/report_queries/asset_view`, 'get', null,
            { condition: `id='${assetId}'` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.cAsset.problem_stats = res.data[0].problem_stats;
                        this.initCategory();
                    } else {
                        this.initCategory();
                    }
                }
            })
    }
    ngOnInit(): void {
        this.initAsset();
        setTimeout(() => this.initialTable());
        this.registryprobTableOptions1 = {
            columns: [
                {
                    "header": "Problem Name",
                    "columnDef": "vul_id",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => `${element.vul_id}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Product",
                    "columnDef": "os_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => `${element.os_name}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    header: "Fix",
                    columnDef: "fix",
                    cType: "string",
                    isHyperlink: true,
                    isTargetLink: 'url',
                    filter: "",
                    cell: "(element: any) => `${element.fix}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Description",
                    columnDef: "description",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.description}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                    isShowMoreData: true
                },
                {
                    header: 'Severity',
                    cType: 'string',
                    columnDef: 'severity',
                    iscolumnSearch: true,
                    filter: '', width: '100px',
                    isColoredCntrl: true,
                    isCustomText: true,
                    visible: false,
                    order: 5,
                    isSort: true
                },
                {
                    header: 'Score',
                    cType: 'number',
                    columnDef: 'base_score',
                    iscolumnSearch: true,
                    rangeColor: true,
                    isMultiRange: true,
                    align: "center",
                    filter: '',
                    isColoredCntrl: false,
                    isCustomText: false,
                    visible: true,
                    order: 5,
                    isSort: false
                },
                {
                    header: "Confirmed",
                    cType: "agg",
                    columnDef: "is_confirmed",
                    filter: "",
                    cell: "(element: any) => `${element.is_confirmed}`",
                    order: 3,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    isClass: true,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: true,
                    isCustomClass: true,
                    isCustomText: true,
                    colList: [],
                    isfaicon: false,
                    isAddingText: '',
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    "header": "Suppressed Till",
                    "columnDef": "suppressed_till",
                    "cType": "agg",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => `${element.suppressed_till}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    datePicker: true
                },
                // {
                //     header: "Ticket ID",
                //     columnDef: "ticket_ids",
                //     cType: "agg",
                //     filter: "",
                //     cell: "(element: any) => `${element.ticket_ids}`",
                //     order: 0,
                //     visible: true,
                //     isToolTip: false,
                //     isHyperlinkKey: 'ticket_status_map',
                //     isToolTipCol: '',
                //     hasMultiData: false,
                //     class: '',
                //     color: '',
                //     isProgressCntrl: false,
                //     isColoredCntrl: false,
                //     colList: [],
                //     isfaicon: false,
                //     isAddingText: false,
                //     addingText: '',
                //     img: false,
                //     imgPath: '',
                //     isSort: false,
                //     iscolumnSearch: false,
                //     isShowMoreShowLess: true
                // },

            ],
            sortOptions: { active: 'vul_id', direction: 'asc' },
            _pageData: [],
            tableOptions: {
                title: 'Suppression',
                isServerSide: false,
                selectText: 'rules',
                loading: true,
                floatingFilter: true,
                rowSelection: true,
                showAction: true,
                isHideGlobal: true,
                actionMenuItems: [
                    // {
                    //     text: 'Suppress',
                    //     icon: "feather:alert-triangle",
                    //     callback: 'suppressFn',
                    //     isGlobal: true,
                    //     target: "registry_suppress",
                    // },
                    // {
                    //     text: 'Integration Action',
                    //     icon: "heroicons_outline:ticket",
                    //     callback: 'suppressFn',
                    //     isGlobal: true,
                    //     target: "registry_integration_action",
                    // }
                    // {
                    //     text: 'Suppress',
                    //     icon: 'remove_circle_outline',
                    //     callback: 'suppressFn',
                    //     isGlobal: true,
                    // },
                    {
                        text: 'Unsuppress',
                        icon: 'delete_forever',
                        callback: 'unsuppressFn',
                        isGlobal: true,
                    },
                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: true,
                exportExcel: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                isNotSiteChange: true,
                serverSide: {
                    url: '/r/report_queries/registry_problems_remediation',
                    condition: 'is_suppressed=true and is_remediated = false',
                    type: 'post',
                    isGlobal: true,
                    isMergeApi: true,
                    params: {},
                    customFnCall: 'getTicketIds',
                    requiredKeys: {
                        asset_id: null,
                        filter_key: 'vul_id',
                    }
                },
                id: 'registry_problems_summary'
            },
            customText: [
                {
                    name: 'epss_score',
                    customText: [
                        {
                            range: { from: 0.0, to: 0.85 },
                            color: '#67ace1',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.85, to: 0.90 },
                            color: '#ffe24b',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.90, to: 0.95 },
                            color: '#f8c851',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.95, to: 1 },
                            color: '#92243e',
                            'text-color': '#ffffff'
                        },
                    ]
                },
                {
                    status: 'Critical',
                    DisplayText: 'CRITICAL',
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
                {
                    status: 'High',
                    DisplayText: 'HIGH',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 'Medium',
                    DisplayText: 'MEDIUM',
                    color: '#f18c43',
                    'text-color': '#000000'
                },
                {
                    status: 'Low',
                    DisplayText: 'LOW',
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    status: 'Info',
                    DisplayText: 'INFO',
                    color: '#67ace1',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0, to: 0.4 },
                    color: '#6fb8f9',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.5, to: 0.74 },
                    color: '#FCE94E',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.75, to: 1 },
                    color: '#EB973E',
                    'text-color': '#000000'
                },
                {
                    range: { from: 1, to: 4.9 },
                    color: '#ffe24b',
                    'text-color': '#000000'
                },
                {
                    range: { from: 5, to: 8.9 },
                    color: '#ec9b0c',
                    'text-color': '#000000'
                },
                {
                    range: { from: 9, to: 10 },
                    color: '#ff6546',
                    'text-color': '#000000'
                },
                {
                    status: false,
                    DisplayText: 'No',
                    class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
                },
                {
                    status: true,
                    DisplayText: 'Yes',
                    class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
                },

            ],
            sColumns: [
                { header: 'Base Score', columnDef: 'base_score', cType: 'number', gloNoSearch: true },
                {
                    header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                    isCustomFilter: [
                        {
                            "value": "contains",
                            "key": "Equal"
                        },
                        {
                            "value": "notcontains",
                            "key": "Not Equal"
                        },
                    ],
                    dataSource: [
                        { text: 'Yes', value: 'false' },
                        { text: 'No', value: 'true' }
                    ],
                },
            ],
            customScore: [
                { name: 'Base Score', key: 'base_score' },
                { name: 'EPSS Score', key: 'epss_score' },
                { name: 'Exploitability Score', key: 'exploitability_score', filter: 'tofixed' },
                { name: 'Impact Score', key: 'impact_score' },
            ],
            changeValue: new Subject<any>(),

        };

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                }
                else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    initAsset(): void {
        const assetD: any = Object.assign({}, this.cAsset);
        this.initData(assetD);
        this.getrebootRequired(assetD);
        this.showData = false;
        this._userService.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            this.currentUser = user['email'];
        });
    }

    resetConfig(): void {
        this.resetParam = {
            company_id: this.cAsset.company_id,
            agent_ids: [this.cAsset.agent_id]
        };
        const confirmation = this.confirmDialog.open({
            title: "Confirmation",
            message: `Are you sure you want to update the agent ?`,

            icon: {
                show: false,
                name: "",
                color: "success",
            },
            actions: {
                confirm: {
                    show: true,
                    label: "Yes",
                    color: "primary",
                },
                cancel: {
                    show: true,
                    label: "No",
                },
            },
            dismissible: true,
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === "confirmed") {
                const params: any = this.resetParam;
                params.message = this.reset_config;
                this.loaderService.display(true);
                this._bs
                    .doRequest("/w/company/reset_agents", "post", params)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.loaderService.display(false);
                        if (result.status) {
                            this.toast.sToast("success", result.message);
                            this.reset_config = "";
                            setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
                        } else {
                            const data = result.message ? result.message : result.data;
                            this.toast.sToast("error", data);
                        }
                    });
            }
        });
    }

    getrebootRequired(assetD: any): void {
        const assetId = (assetD.id) ? assetD.id : assetD.asset_id;
        this._bs.doRequest(`/r/company/asset_windows_reboot_required`, 'get', null,
            { condition: `asset_id=${assetId}` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                this.cAsset.reboot_required_since = 'Not_Required';
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.cAsset.reboot_required_since = (res.data[0] && res.data[0].data === 'true') ? 'Required' : 'Not_Required';
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }
    initData(assetD: any): void {
        if (!assetD) {
            let asset = Object.assign({}, this.cAsset);
            setTimeout(() => this.initData(asset));
            return;
        }
        this._bs.doRequest(`${this._bs.configURL}/agentBuildInfo`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.status) {
                    this.latest_agent_version = res.data.agent['agentVersion'];
                }
            });
        const assetId = (assetD.id) ? assetD.id : assetD.asset_id;
        this.loaderService.display(true);
        this._bs.doRequest((this.is_deprecated) ? '/r/asset/deprecated_assets_view' : `/r/report_queries/asset_view`, 'get', null,
            { condition: `id='${assetId}'` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        res.data[0].riskScore = (res.data[0] && res.data[0].vul_stats) ? this.cs.getRiskScore(res.data[0]) : undefined;
                        this.cAsset = { ...this.cAsset, ...res.data[0] };
                    } else {
                        this.cAsset.riskScore = (this.cAsset && this.cAsset.vul_stats) ? this.cs.getRiskScore(this.cAsset) : undefined;
                    }
                    if (this.inbound_and_outbound.indexOf(this.cAsset.platform) !== -1) {
                        this.toggleFirewallRulesDirection('In');
                    } else if (this.ubuntu_firewall_settings.indexOf(this.cAsset.platform) !== -1) {
                        this.toggleFirewallRulesDirection('UbuntuFireWall');
                    } else if (this.selinux_settings.indexOf(this.cAsset.platform) !== -1) {
                        this.toggleFirewallRulesDirection('Selinux');
                    }
                    this.asset_name = this.cAsset.visible_name || this.cAsset.host_name;
                    if (typeof this.cAsset.discovered_protocols === 'string') {
                        this.cAsset.discovered_protocols = JSON.parse(this.cAsset.discovered_protocols);
                    }
                    if (this.cAsset && this.cAsset.discovered_protocols && this.Objectkeys(this.cAsset.discovered_protocols).length) {
                        const discovered_protocols = cloneDeep(this.cAsset.discovered_protocols);
                        this.cAsset.discovered_protocols = discovered_protocols.filter((x: any) => x.status).map((y: any) => y.protocol).join(', ')
                    } else {
                        this.cAsset.discovered_protocols = null;
                    }
                    this.showData = true; this._changeDetectorRef.detectChanges();
                    if (this.cAsset.os_name && this.cAsset.platform && this.redhat_platform.indexOf(this.cAsset.platform.toLowerCase()) > -1 && this.cAsset.os_name.toLowerCase().indexOf('red hat') > -1) {
                        this.rhelProblemTableOptions.tableOptions.serverSide.condition = `asset_id=${this.cAsset?.id}`;
                        this.rhelSoftTableOptions.tableOptions.serverSide.condition = `asset_id=${this.cAsset?.id}`;
                    }
                    else if (this.cAsset.os_name && this.cAsset.platform && this.ubuntu_platform.indexOf(this.cAsset.platform.toLowerCase()) > -1 && this.cAsset.os_name.toLowerCase().indexOf('ubuntu') > -1) {
                        this.rhelProblemTableOptions.tableOptions.serverSide.condition = `asset_id=${this.cAsset?.id}`;
                        this.rhelSoftTableOptions.tableOptions.serverSide.condition = `asset_id=${this.cAsset?.id}`;
                    }
                    else {
                        this.getProblemGroup();
                        this.softTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}' and software_type = 'installedprogram'`;
                    }
                    this.cAsset.avg_risk_score = (this.cAsset.avg_risk_score) ? parseInt(this.cAsset.avg_risk_score) : null;
                    this.rulesTableOptions.tableOptions.serverSide.condition = `direction='${this.firewallRuleDirection}' and asset_id='${this.cAsset?.id}'`;
                    this.extAppTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.servTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.userTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.sharTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.registryTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.firepTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.portTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.openPortTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.userSharTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.assetPatchesTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.unquoted_service_path.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.assetInstalledDrivers.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}' and software_type = 'drivers'`;
                    this.runningProcesses.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.registryprobTableOptions.tableOptions.title = 'Registry Problems';
                    this.registryprobTableOptions.tableOptions.showAction = true;
                    this.registryprobTableOptions.tableOptions.rowSelection = true;
                    if (this.cAsset.platform && this.snmp_issue.indexOf(this.cAsset.platform.toLowerCase()) > -1) {
                        this.registryprobTableOptions.tableOptions.title = 'SNMP Issues';
                        this.registryprobTableOptions.tableOptions.showAction = false;
                        this.registryprobTableOptions.tableOptions.rowSelection = false;
                    }
                    this.registryprobTableOptions.tableOptions.serverSide.requiredKeys.asset_id = this.cAsset.id;
                    this.registryprobTableOptions.tableOptions.serverSide.requiredKeys.is_suppressed = this.is_suppressed;
                    this.registryprobTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}' and is_suppressed=false and is_remediated = false`;

                    this.installvulsprobTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;

                    this.installremprobTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}'`;
                    this.remediatedRegistryprobTableOptions.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}' and is_remediated = true`;
                    this.registryprobTableOptions1.tableOptions.serverSide.requiredKeys.asset_id = this.cAsset.id;
                    this.registryprobTableOptions1.tableOptions.serverSide.requiredKeys.is_suppressed = this.is_suppressed;
                    this.registryprobTableOptions1.tableOptions.serverSide.condition = `asset_id='${this.cAsset?.id}' and is_suppressed=true and is_remediated = false`;

                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                    this.drawerOpened = false;
                    this.drawer.toggle();
                }
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleFirewallRulesDirection(direction: any): void {
        if (direction === "In" || direction === "Out") {
            this.firewallDisplayShow = true;
            if (this.firewallRuleDirection !== direction) {
                this.firewallShow = false;
                this._changeDetectorRef.detectChanges();
                setTimeout(() => {
                    this.firewallRuleDirection = direction;
                    this.rulesTableOptions.tableOptions.title = `${direction}bound Firewall Rules`;
                    this.rulesTableOptions.tableOptions.serverSide.condition = `direction='${direction}' and asset_id='${this.cAsset?.id}'`;
                    this.rulesTableOptions.pageData = [];
                    this.rulesTableOptions.tableOptions.pageTotal = 0;
                    this.firewallShow = true;
                    this._changeDetectorRef.detectChanges();
                }, 100);
            }
        } else {
            this.firewallDisplayShow = false;
            this.firewallRuleDirection = direction;
        }
    }

    getProblemGroup(): void {
        const sub_category = ['asset_problem_details', 'asset_auto_suppressed'];
        try {
            const pgc = localStorage.getItem('pgc');
            if (pgc) {
                this.pgc = JSON.parse(pgc);
                this.pgc.forEach((o: any) => { this.pgcHash[o.id] = o; });
                this.cs.getTableSavedSettings(sub_category)
                    .then((res: any) => {
                        if (res.status && res.data && res.data.length) {
                            const problem: any = res.data.filter((x: any) => x.sub_category === 'asset_problem_details');
                            const suppressed: any = res.data.filter((x: any) => x.sub_category === 'asset_auto_suppressed');
                            this.table_settings = (problem && problem.length) ? problem[0] : {};
                            this.suppressed_table_settings = (suppressed && suppressed.length) ? suppressed[0] : {};
                            this.maualselectfilter = (this.table_settings && this.table_settings.value && this.table_settings.value.settings && this.table_settings.value.settings.vul_filter) ? this.table_settings.value.settings.vul_filter : 'yes';
                        }
                        this.initCategory();
                    }, (error: any) => {
                        this.initCategory();
                    });
            } else {
                this.loaderService.display(true);
                this._bs.doRequest(`/r/company/problem_groups`, 'get', null, { condition: true, skip: 0, limit: 2000 }).pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res: any) => {
                        this.loaderService.display(false);
                        if (res.status && res.data && res.data.length) {
                            res.data.forEach((o: any) => {
                                this.pgcHash[o.id] = o;
                            });
                            localStorage.setItem('pgc', JSON.stringify(res.data));
                            this.cs.getTableSavedSettings(sub_category)
                                .then((res: any) => {
                                    if (res.status && res.data && res.data.length) {
                                        const problem: any = res.data.filter((x: any) => x.sub_category === 'asset_problem_details');
                                        const suppressed: any = res.data.filter((x: any) => x.sub_category === 'asset_auto_suppressed');
                                        this.table_settings = (problem && problem.length) ? problem[0] : {};
                                        this.suppressed_table_settings = (suppressed && suppressed.length) ? suppressed[0] : {};
                                        this.maualselectfilter = (this.table_settings && this.table_settings.value && this.table_settings.value.settings && this.table_settings.value.settings.vul_filter) ? this.table_settings.value.settings.vul_filter : 'yes';
                                    }
                                    this.initCategory();
                                }, (error: any) => {
                                    this.initCategory();
                                });
                        }
                        // else {
                        //     this.toast.sToast('error', 'Problem Groups Not Found! Please contact support!');
                        // }
                    });
            }
        } catch (e) {
            console.log(e);
        }
    }

    initCategory(): void {
        this.loaderService.display(true);
        this.isNoProblem = this.cAsset.problem_stats && (this.Objectkeys(this.cAsset.problem_stats).length > 0) ? false : true;
        this.problems = [];
        if (this.cAsset.problem_stats && this.Objectkeys(this.cAsset.problem_stats).length > 0) {
            this.Objectkeys(this.cAsset.problem_stats).forEach((k: any) => {
                if (k !== 'custom_vul_count' && k !== 'custom_vul_score' && this.pgcHash[k] && this.pgcHash[k].problem_group_type !== 'AD Problems' && this.pgcHash[k].problem_group_type !== 'Asset problems') { //this.cAsset.problem_stats[k] > 0 &&
                    const a = {
                        problem_group_name: this.pgcHash[k].problem_group_name,
                        sequence: this.pgcHash[k].sequence,
                        problem_group_sub_type: this.pgcHash[k].problem_group_sub_type,
                        problem_count: this.cAsset.problem_stats[k],
                        problem_group_id: k,
                        problem_weightage: this.pgcHash[k].weightage,
                    }

                    this.problems.push(a);
                    this.problemsScore.push(a);
                    this.prbHash[a.problem_group_id] = a;
                }
                else if (k === 'custom_vul_count') {
                    const b = {
                        problem_group_name: 'Custom Vulnerability Count',
                        sequence: 0,
                        problem_group_sub_type: '',
                        problem_count: (this.cAsset.problem_stats['custom_vul_score']) ? (this.cAsset.problem_stats['custom_vul_score'] / 100) : 0,
                        problem_group_id: this.problems.length + 1,
                        problem_weightage: 0.5,
                    };
                    this.problemsScore.push(b);
                }
            });
            if (!this.problems.length) {
                this.isNoProblem = true;
                return;
            }
            this.problems.sort((a: any, b: any) => {
                const c = a['sequence'];
                const d = b['sequence'];
                if (c < d) {
                    return 1;
                } else if (c > d) {
                    return 0;
                } else {
                    return -1;
                }
            });
            this.problemsScore.sort((a: any, b: any) => b.problem_count - a.problem_count);
            this.problems.unshift({
                problem_group_name: 'All Vulnerabilities',
                sequence: 0,
                problem_group_id: 'all',
                company_count: this.problems.reduce((acc: any, obj: any) => acc + obj.company_count, 0),
                asset_count: this.problems.reduce((acc: any, obj: any) => acc + obj.asset_count, 0),
                company_ids: this.problems.flatMap((obj: any) => obj.company_ids),
                asset_ids: this.problems.flatMap((obj: any) => obj.asset_ids),
            })
            const response = this.problems.filter((x: any) => x.problem_group_id !== 'all');
            this.prbHash['all'] = {
                problem_group_name: 'All Vulnerabilities',
                problem_group_id: 'all',
                company_count: response.reduce((acc: any, obj: any) => acc + obj.company_count, 0),
                asset_count: response.reduce((acc: any, obj: any) => acc + obj.asset_count, 0),
                company_ids: response.flatMap((obj: any) => obj.company_ids),
                asset_ids: response.flatMap((obj: any) => obj.asset_ids),
            }
            this.currentProblem = this.problems[1];
            this.storedProblems = this.problems;
            this.problemCount();
            if (this.viewupdate === 'auto_suppressed') {
                this.viewCategory(this.currentProblem.problem_group_id, 'auto_suppressed');
            } else {
                this.viewCategory(this.currentProblem.problem_group_id, 'problem');
            }
        }

    }

    problemCount(): void {
        if (this.storedProblems && this.storedProblems.length) {
            let condition: string;

            if (this.maualselectfilter === 'No') {
                condition = `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and unconfirmed = 'true'`;
            } else if (this.maualselectfilter === 'All') {
                condition = `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id}`;
            } else {
                condition = `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and unconfirmed = 'false'`;
            }

            const params: any = { condition };

            this._bs.doRequest('/r/report_queries/problem_group_summary', 'get', null, params)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (result: any) => {
                        // console.log('Request successful:', result);

                        const apiData = result.data || [];

                        const apiProblemMap = new Map<number, number>();
                        apiData.forEach((item: any) => {
                            apiProblemMap.set(item.problem_group_id, item.problem_count);
                        });

                        this.storedProblems.forEach((problem: any) => {
                            const groupId = parseInt(problem.problem_group_id, 10);

                            if (apiProblemMap.has(groupId)) {
                                problem.problem_count = apiProblemMap.get(groupId);
                            } else {
                                problem.problem_count = 0;
                            }
                        });

                        // console.log('Updated storedProblems:', this.storedProblems);
                    },
                    (error: any) => {
                        console.error('Request failed:', error);
                    }
                );
        }
    }
    viewNW(): void {
        this.getSoftwareData();
        this.getUploadedDocDetails();
    }


    riskScore(): void {
        this.Scorestatement();
        const condition = `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id}`;
        const params: any = { condition };
        this.loaderService.display(true);
        this._bs
            .doRequest(`/r/report_queries/unconfirmed_key_check`, "get", null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.updatedProblemsScore = [];
                if (result.status && Array.isArray(result.data)) {
                    this._bs
                        .doRequest(`/r/report_queries/unconfirmed_custom_vulnerability_key_check`, "get", null, { condition: `asset_id=${this.cAsset.id}` })
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((custom: any) => {
                            this.loaderService.display(false);
                            // Create a map of problem_group_id to unconfirmed status from result.data
                            const unconfirmedStatus = result.data.reduce((acc, item) => {
                                acc[item.problem_group_id] = item.unconfirmed;
                                return acc;
                            }, {});

                            unconfirmedStatus[21] = true;
                            if (custom.status && custom.data && custom.data.length) {
                                unconfirmedStatus[21] = custom.data[0].is_confirmed ? false : true;
                            }
                            // Create a new array with the unconfirmed status updated
                            const problemScore: any = this.problemsScore
                                .filter((problem: any) => problem.problem_group_id !== '48').map((problem: any) => {
                                    return {
                                        ...problem,
                                        unconfirmed: unconfirmedStatus.hasOwnProperty(problem.problem_group_id)
                                            ? unconfirmedStatus[problem.problem_group_id]
                                            : true
                                    };
                                });
                            this.updatedProblemsScore = problemScore.sort((a: any, b: any) => {
                                const c = a['unconfirmed']; const d = b['unconfirmed'];
                                if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
                            });
                            this.modalService.open('showRiskScoreDetails');
                            this._changeDetectorRef.detectChanges();
                        })

                } else {
                    this.loaderService.display(false);
                    console.error('Unexpected result format:', result);
                }
            });
    }

    Scorestatement(): void {
        const condition = `asset_id=${this.cAsset.id}`;
        const params: any = { condition };
        this._bs
            .doRequest(`/r/report_queries/suppress_vulnerability_count`, "get", null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.suppressedvulscount = result.data[0].suppressed_vuls;
            });
    }

    viewCategory(problem: any, view: any): void {
        this.cView = view;
        this.currentProblem = this.prbHash[problem];
        // this.currentcountdata  =this.cs.currentproblemcount
        this.prbid = problem;
        let data: any;
        const probTableOptions: any = {
            columns: [
                {
                    "header": "Problem Name",
                    "columnDef": "problem_name",
                    "cType": "string",
                    isHyperlink: true,
                    hyperLinks: { hotLink: 'https://nvd.nist.gov/vuln/detail/', match: 'CVE' },
                    "filter": "",
                    "cell": "(element: any) => `${element.problem_name}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    header: 'Base',
                    cType: 'number',
                    columnDef: 'base_score',
                    iscolumnSearch: true,
                    rangeColor: true,
                    align: "center",
                    filter: '', width: '50px',
                    isColoredCntrl: false,
                    isCustomText: false,
                    visible: true,
                    order: 5,
                    isSort: true
                },
                {
                    header: 'Impact',
                    cType: 'number',
                    columnDef: 'impact_score',
                    iscolumnSearch: true,
                    rangeColor: true,
                    align: "center",
                    filter: '', width: '50px',
                    isColoredCntrl: false,
                    isCustomText: false,
                    visible: true,
                    order: 5,
                    isSort: true
                },
                {
                    header: 'Exploitability',
                    cType: 'number',
                    columnDef: 'exploitability_score',
                    iscolumnSearch: true,
                    rangeColor: true,
                    align: "center",
                    filter: '', width: '100px',
                    isColoredCntrl: false,
                    isCustomText: false,
                    visible: true,
                    order: 5,
                    isSort: true
                },
                {
                    header: 'EPSS',
                    cType: 'number',
                    columnDef: 'epss_score',
                    iscolumnSearch: true,
                    align: "center",
                    filter: 'tofixed', width: '50px',
                    rangeColor: true,
                    isColoredCntrl: false,
                    isCustomText: false,
                    visible: true,
                    order: 5,
                    isSort: true
                },
                {
                    "header": "Software",
                    "columnDef": "software_name",
                    "filter": "",
                    "cell": "(element: any) => `${element.software_name}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                    isShowMoreData: true,
                    showpopup: true,
                    width: '200px',
                },
                {
                    header: "Ports",
                    columnDef: "port",
                    cType: "agg",
                    filter: "",
                    cell: "(element: any) => `${element.port}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: false,
                    isShowMoreShowLess: false,
                    isPortView: true,
                },
                {
                    "header": "Script Output",
                    "columnDef": "script_output",
                    "filter": "ellipsis:28",
                    cType: "agg",
                    "cell": "(element: any) => `${element.script_output}`",
                    "order": 0,
                    "align": "left",
                    isHyperlink: true,
                    "visible": true,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    header: "Description",
                    columnDef: "description",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => `${element.description}`",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                    isShowMoreData: true
                },
                { "header": "Confirmed", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "unconfirmed", "cType": "agg", "filter": "", "cell": "(element: any) => `${element.unconfirmed}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },

                {
                    header: "Open Tickets",
                    columnDef: "open_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => ${element.open_ticket_count}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: true,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                },
                {
                    header: "Closed Tickets",
                    columnDef: "closed_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => ${element.closed_ticket_count}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                },

                {
                    "header": "First Vulnerability Discovered",
                    "columnDef": "first_vul_discovered",
                    "filter": "utcToLocale",
                    "cType": "agg",
                    "cell": "(element: any) => `${element.first_vul_discovered}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                }, {
                    "header": "Last Vulnerability Discovered",
                    "columnDef": "last_vul_discovered",
                    "filter": "utcToLocale",
                    "cType": "agg",
                    "cell": "(element: any) => `${element.last_vul_discovered}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                }
            ],
            sortOptions: { active: 'problem_name', direction: 'asc' },
            _pageData: [],
            tableOptions: {
                title: 'Problem Details',
                isServerSide: false,
                selectText: 'rules',
                loading: true,
                floatingFilter: true,
                rowSelection: true,
                showAction: true,
                isHideGlobal: true,
                actionMenuItems: [
                    {
                        text: 'Suppress',
                        icon: "feather:alert-triangle",
                        callback: 'suppressFn',
                        isGlobal: true,
                        target: "problem_solution_suppress",
                    },
                    {
                        text: 'Integration Action',
                        icon: "heroicons_outline:ticket",
                        callback: 'suppressFn',
                        isGlobal: true,
                        target: "integration_action",
                    }
                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                showFilter: true,
                showTagFilter: true,
                exportExcel: true,
                selectedchecks: 'yes',
                showmanualfilter: true,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                isNotSiteChange: true,
                serverSide: {
                    url: '/r/report_queries/',
                    condition: '',
                    type: 'post',
                    isGlobal: true,
                    isMergeApi: true,
                    customFnCall: 'getTicketIds',
                    requiredKeys: {
                        asset_id: this.cAsset.id,
                        filter_key: 'problem_name',
                    }
                },
                id: 'asset_problem_details'
            },
            customText: [
                {
                    name: 'epss_score',
                    customText: [
                        {
                            range: { from: 0.0, to: 0.85 },
                            color: '#67ace1',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.85, to: 0.90 },
                            color: '#ffe24b',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.90, to: 0.95 },
                            color: '#f8c851',
                            'text-color': '#000000'
                        },
                        {
                            range: { from: 0.95, to: 1 },
                            color: '#92243e',
                            'text-color': '#ffffff'
                        },
                    ]
                },
                {
                    status: 'Critical',
                    DisplayText: 'CRITICAL',
                    color: '#92243e',
                    'text-color': '#ffffff'
                },
                {
                    status: 'High',
                    DisplayText: 'HIGH',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 'Medium',
                    DisplayText: 'MEDIUM',
                    color: '#f18c43',
                    'text-color': '#000000'
                },
                {
                    status: 'Low',
                    DisplayText: 'LOW',
                    color: '#f8c851',
                    'text-color': '#000000'
                },
                {
                    status: 'Info',
                    DisplayText: 'INFO',
                    color: '#67ace1',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0, to: 0.4 },
                    color: '#6fb8f9',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.5, to: 0.74 },
                    color: '#FCE94E',
                    'text-color': '#000000'
                },
                {
                    range: { from: 0.75, to: 1 },
                    color: '#EB973E',
                    'text-color': '#000000'
                },
                {
                    range: { from: 1, to: 4.9 },
                    color: '#ffe24b',
                    'text-color': '#000000'
                },
                {
                    range: { from: 5, to: 8.9 },
                    color: '#ec9b0c',
                    'text-color': '#000000'
                },
                {
                    range: { from: 9, to: 10 },
                    color: '#ff6546',
                    'text-color': '#000000'
                },
                {
                    status: true,
                    DisplayText: 'No',
                    class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
                },
                {
                    status: false,
                    DisplayText: 'Yes',
                    class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
                },

            ],
            sColumns: [
                { header: 'Base Score', columnDef: 'base_score', cType: 'number', gloNoSearch: true },
                {
                    header: 'Confirmed', columnDef: 'unconfirmed', cType: 'string', isDropdown: true, gloNoSearch: true,
                    isCustomFilter: [
                        {
                            "value": "contains",
                            "key": "Equal"
                        },
                        {
                            "value": "notcontains",
                            "key": "Not Equal"
                        },
                    ],
                    dataSource: [
                        { text: 'Yes', value: 'false' },
                        { text: 'No', value: 'true' }
                    ],
                },
            ],
            customScore: [
                { name: 'Base Score', key: 'base_score' },
                { name: 'EPSS Score', key: 'epss_score' },
                { name: 'Exploitability Score', key: 'exploitability_score', filter: 'tofixed' },
                { name: 'Impact Score', key: 'impact_score' },
            ],
            changeValue: new Subject<any>(),
        };
        if (view === 'problem') {
            data = Object.assign({}, probTableOptions);
            data.columns.map((x: any) => {
                delete x.isNoVisible;
                if (x.columnDef === 'last_vul_discovered') {
                    x.isNoVisible = true;
                }
            });
            data.pageData = []; data.tableOptions.pageTotal = 0;
            this.probTableOptions = {}; this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.params = { name: 'problems_summary' };
            data.tableOptions.showAction = true; data.tableOptions.rowSelection = true;
            data.tableOptions.viewcheck = 'problem';
            data.tableOptions.id = 'asset_problem_details';
            this.maualselectfilter = (this.table_settings && this.table_settings.value && this.table_settings.value.settings && this.table_settings.value.settings.vul_filter) ? this.table_settings.value.settings.vul_filter : 'yes';
            data.tableOptions.selectedchecks = this.maualselectfilter;
            data.tableOptions.serverSide.isMergeApi = true;
            if (this.maualselectfilter === 'yes') {
                data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id} and unconfirmed = 'false'` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and unconfirmed = 'false'`;
            } else if (this.maualselectfilter === 'No') {
                data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id} and unconfirmed = 'true'` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and unconfirmed = 'true'`;
            } else {
                data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id}` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id}`;
            }
            // data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id}` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id}`;
            this.probTableOptions = data; this._changeDetectorRef.detectChanges();

        } else if (view === 'auto_suppressed') {
            data = Object.assign({}, probTableOptions);
            data.pageData = []; data.tableOptions.pageTotal = 0;
            this.probTableOptions = {}; this._changeDetectorRef.detectChanges();
            data.tableOptions.serverSide.params = { name: 'suppressed_problems' };
            data.tableOptions.showAction = false; data.tableOptions.rowSelection = false;
            data.tableOptions.viewcheck = 'auto_suppressed';
            data.tableOptions.id = 'asset_auto_suppressed';
            this.maualselectfilter = (this.suppressed_table_settings && this.suppressed_table_settings.value && this.suppressed_table_settings.value.settings && this.suppressed_table_settings.value.settings.vul_filter) ? this.suppressed_table_settings.value.settings.vul_filter : 'yes';
            data.tableOptions.selectedchecks = this.maualselectfilter;
            data.tableOptions.serverSide.isMergeApi = false;
            if (this.maualselectfilter === 'yes') {
                data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id} and unconfirmed = 'false'` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and unconfirmed = 'false'`;
            } else if (this.maualselectfilter === 'No') {
                data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id} and unconfirmed = 'true'` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and unconfirmed = 'true'`;
            } else {
                data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id}` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id}`;
            }
            // data.tableOptions.serverSide.condition = (this.currentProblem.problem_group_id !== 'all') ? `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id} and problem_group_id=${this.currentProblem.problem_group_id}` : `company_id=${this.cAsset.company_id} and asset_id=${this.cAsset.id}`;
            this.probTableOptions = data; this._changeDetectorRef.detectChanges();
        }
    }



    initialTable(): void {
        let conditionParams: any = '';
        //const url: any = this.filterKey === 'problem_id' ? 'suppress_vulnerability_problems' : 'suppress_vulnerability_solution';
        if (this.asset_id && this.mode === 'asset') {
            conditionParams = `suppression_status = 'Approved' and asset_id=${this.asset_id}`;
        } else {
            conditionParams = `suppression_status = 'Approved'`;
        }
        this.suppression = {
            columns: [
                {
                    "header": "Software Name",
                    "columnDef": "software_names",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => `${element.software_names}`",
                    "order": 0,
                    "visible": true,
                    // isNoVisible: (this.filterKey === 'problem_id') ? false : true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    "isShowMoreData": true,
                    // width: '200px',
                },
                {
                    "header": "vulnerabilities",
                    "columnDef": "vulnerabilities",
                    "cType": "string",
                    "filter": "",
                    isHyperlink: false,
                    "cell": "(element: any) => `${element.vulnerabilities}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    "isShowMoreData": true
                },
                {
                    "header": "Reason",
                    "columnDef": "reason",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => `${element.reason}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                },
                {
                    header: "Open Tickets",
                    columnDef: "open_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => ${element.open_ticket_count}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: true,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                },
                {
                    header: "Closed Tickets",
                    columnDef: "closed_ticket_count",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => ${element.closed_ticket_count}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isTicketCountView: true,
                    isOpenTicket: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                },

                {
                    "header": "Suppression Request Sent Time",
                    "columnDef": "created",
                    "cType": "agg",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => `${element.created}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    datePicker: true
                },
                {
                    "header": "Suppression Status",
                    "columnDef": "suppression_status",
                    "cType": "agg",
                    "filter": "",
                    "cell": "(element: any) => `${element.suppression_status}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    isColoredCntrl: true,
                    isCustomText: true,
                    isCustomClass: true,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                },
                {
                    "header": "Suppressed On",
                    "columnDef": "suppressed_on",
                    "cType": "date",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => `${element.suppressed_on}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    datePicker: true
                },
                {
                    "header": "Suppressed Till",
                    "columnDef": "suppressed_till",
                    "cType": "agg",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => `${element.suppressed_till}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    datePicker: true
                },
                {
                    "header": "Assets",
                    "columnDef": "affected_assets",
                    "cType": "agg",
                    "filter": "",
                    "isHyperlink": true,
                    "cell": "(element: any) => `${element.affected_assets}`",
                    "order": 0,
                    align: "left",
                    "visible": true,
                    isNoVisible: (this.mode === 'asset') ? true : false,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Suppress Comments",
                    "columnDef": "suppress_comments",
                    "cType": "agg",
                    "filter": "",
                    "cell": "(element: any) => `${element.suppress_comments}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                },
                {
                    "header": "Approver Comments",
                    "columnDef": "approver_comments",
                    "cType": "agg",
                    "filter": "",
                    "cell": "(element: any) => `${element.approver_comments}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                },
                {
                    "header": "Unsuppressed On",
                    "columnDef": "unsuppressed_on",
                    "cType": "date",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => `${element.unsuppressed_on}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false,
                    datePicker: true
                },
                {
                    "header": "Unsuppress Comments",
                    "columnDef": "unsuppress_comments",
                    "cType": "agg",
                    "filter": "",
                    "cell": "(element: any) => `${element.unsuppress_comments}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                },
                {
                    header: "Description",
                    columnDef: "description",
                    cType: "agg",
                    filter: "",
                    cell: "(element: any) => `${element.description}`",
                    order: 0,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false,
                    isShowMoreData: true
                },
            ],
            sortOptions: { active: 'suppressed_on', direction: 'desc' },
            _pageData: [],
            tableOptions: {
                id: 'suppression',
                title: 'Suppression',
                isServerSide: false,
                selectText: 'suppression',
                loading: true,
                floatingFilter: true,
                rowSelection: true,
                showAction: true,
                isHideGlobal: true,
                actionMenuItems: [
                    {
                        text: 'Unsuppress',
                        icon: 'delete_forever',
                        callback: 'editFunction',
                        hideLocal: false,
                        isGlobal: true,
                    }
                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                showFilter: true,
                showTagFilter: false,
                add: false,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: `/r/report_queries/registry_problems_remediation`,
                    condition: "is_suppressed =true",
                    isMergeApi: true,
                    customFnCall: 'getTicketIds',
                    requiredKeys: {
                        asset_id: this.asset_id,
                        filter_key: (this.filterKey === 'vul_id') ? 'problem_name' : 'solution_id'
                    }
                },
            },
            sColumns: [
                {
                    header: 'Suppression Status', columnDef: 'suppression_status', cType: 'string', isDropdown: true, gloNoSearch: true,
                    dataSource: [
                        { text: 'Open', value: 'Open' },
                        { text: 'Approved', value: 'Approved' },
                        { text: 'Closed', value: 'Closed' },
                        { text: 'Unsuppressed', value: 'Unsuppressed' },
                    ],
                },
            ],
            customText: [
                {
                    status: 'Approved',
                    DisplayText: 'Approved',
                    class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
                },
                {
                    status: 'Open',
                    DisplayText: 'Open',
                    class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'

                },
                {
                    status: 'Unsuppressed',
                    DisplayText: 'Unsuppressed',
                    class: 'bg-orange-200 text-orange-600 dark:bg-orange-600 dark:text-orange-50'

                },
                {
                    status: 'Rejected',
                    DisplayText: 'Rejected',
                    class: 'bg-red-200 text-red-600 dark:bg-red-600 dark:text-red-50'

                }, {
                    status: 'Closed',
                    DisplayText: 'Closed',
                    class: 'bg-gray-200 text-gray-600 dark:bg-gray-600 dark:text-gray-50'
                }
            ],
            changeValue: new Subject<any>(),
        };
        this._changeDetectorRef.detectChanges();
    }


    getRelativeFormat(date: string): string {
        const t = new Date(date).toISOString()
        return DateTime.fromISO(t).toRelativeCalendar();
    }

    getRangeColor(obj: any): any {
        let style: any = { 'color': '#c2ffc4' };

        const customText: any = [
            {
                range: { from: 0, to: 0 },
                color: '#c2ffc4',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 50 },
                color: '#ffcb70',
                'text-color': '#000000'
            },
            {
                range: { from: 51, to: 20000 },
                color: '#ffb4a3',
                'text-color': '#000000'
            },
        ]
        customText.forEach((r: any) => {
            if (r.range && r.range.from <= obj && r.range && r.range.to >= obj) {
                style = { 'color': r.color };
            }
        });
        return style;
    }

    toggleChangeFn(): void {
        this.toggleFn.emit({});
        setTimeout(() => { this.cs.selectedTagChange.next({}) }, 1000);
        this.cs.selctedAsset = null;
        this.cAsset = null;
        this._changeDetectorRef.detectChanges();
        this.cs.triggerToggle();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    sortFn(key: any): void {
        this.sortType = key; this.sortReverse = !this.sortReverse;
        const data = this.problems.slice();
        const isAsc = !this.sortReverse;
        this.problems = data.sort((a: any, b: any) => {
            // @ts-ignore
            return this.cs.compare(a[key], b[key], isAsc);
        });
    }

    linkCall($event: any): void {
        if ($event.col === 'script_output') {
            if (!$event.row.script_output || !$event.row.script_output.length) {
                return;
            }
            let lines = [];
            if (Array.isArray($event.row.script_output)) {
                lines = $event.row.script_output.filter((line: string) => line.trim() !== '');
            } else {
                lines = $event.row.script_output.split('\n').filter((line: string) => line.trim() !== '');
            }
            $event.row.script_output = lines.map((line: string) => `  ${line.trim()}`).join('\n');
            $event.row.script_output = $event.row.script_output.replaceAll('\\n', '<br>');
            $event.row.script_output = $event.row.script_output.replaceAll('\\t', '');
            this.currentRemediation = $event.row;
            this.modalService.open('asset_script_output');
        } else if ($event.col === 'finger_print_data') {
            this.fingerPrintData = $event.row.finger_print_data;
            if (this.fingerPrintData && this.fingerPrintData.length > 0) {
                this.drawerOpened = true;
                this.getObjectKeyValuePairs(this.fingerPrintData)
                if (!this.drawer.opened) {
                    this.drawer.toggle().then(r => console.log(r));
                }
            } else {
                this.toast.sToast('error', "No Finger Print Data Found");
            }
        } else if ($event.col === "fix") {
            if (!$event.row.url) { return; };
            window.open($event.row.url, "_blank");
        }
    }
    getObjectKeyValuePairs(obj: any): { key: string, value: any }[] {
        return Object.keys(obj).map(key => {
            const transformedKey = (key === 'fp.certainty') ? 'certainty' : key;
            return { key: transformedKey, value: obj[key] };
        });
    }
    onSearch($event: any): void {
        this.patchFilter = $event.target.value;
    }

    getInternalUsernal(): void {
        const condition: any = {
            approved: true
        }
        this._bs.doRequest(`/r/user/get_users`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status) {
                    const filterUser: any = this.cs.filterUsers(result.data, (this.cs.currentScope !== '*') ? parseInt(this.cs.currentScope.id) : null);
                    if (filterUser && filterUser.length) {
                        this.internalUsers = filterUser.filter((x: any) => x.email !== this.currentUser);
                    }
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }
    changeEvent(event: any) {
        this.fromMinEndDate = this.cs.getLastDay(1, event.value);
    }

    updateAssetImportance(iValue: any): void {
        // @ts-ignore
        const val = +iValue
        const importance: any = { id: this.cAsset.id, data: { importance: val } };
        this.loaderService.display(true);
        this._bs.doRequest(`/w/asset/assets`, 'patch', importance)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.cAsset.importance = val;
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }
    toggleCompleted(change: MatSlideToggleChange): void {
        this.cView = 'problem';
        this.isFullView = change.checked;
    }

    closeDraw() {
        this.drawer.toggle();
        this.fingerPrintData = undefined;
        this.currentRemediation = {};
    }

    generateReport(report: any, type: any): void {
        this.isDisbleReport = true;
        try {
            const reportsDetails: any = {
                full: {
                    "reportId": "17c923999fd14bae8fa6388aae09834d",
                    "reportName": "AssetLevelReports",
                },
                vul: {
                    "reportId": "b5ff84662d1c4a83a49923313a6e94d4",
                    "reportName": "VulnerabilitiesReport",
                }
            }
            const s = this.cs.arrayToObject(this.cs.allComp, "id", "name");
            const compName = s[this.cAsset.company_id];
            const requestData: any =
            {
                "reportType": "Standard",
                "company_id": this.cAsset.company_id,
                "company_name": compName,
                "fileType": type,
                "asset_id": this.cAsset.id,
                "assetLevel": true,
                ...reportsDetails[report]
            }
            this.loaderService.display(true);
            this._bs
                .doRequest(`/report_builder/generate_asset_report`, "post", requestData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                    this.loaderService.display(false);
                    if (res.status) {
                        window.open(res.message, "_blank");
                        this.isDisbleReport = false;
                        setTimeout(() => {
                            this.isDisbleReport = false;
                        }, 2000);
                    } else {
                        const data = res.message ? res.message : res.data;
                        this.toast.sToast("error", data);
                        setTimeout(() => {
                            this.isDisbleReport = false;
                        }, 2000);
                    }
                });
        } catch (error) {
            setTimeout(() => {
                this.isDisbleReport = false;
            }, 2000);
        }


    }

    saveCred(): void {
        const $event = this.cred;
        const reqData: any = {
            data: {
                credential_type: this.cred.credential_type,
                os_type: this.cred.os_type,
                address: this.cred.address,
                address_type: this.cred.address_type,
                name: this.cred.name,
                asset_id: this.cAsset.id,
                params: $event,
            },
        };
        const reqParams: any = this.cs.replaceEmptyWithNull(reqData);
        if ($event && $event !== "") {
            reqData.data.company_id = parseInt(this.cAsset.company_id);
            var api = "/w/company/credentials";
            var method = "post";
            if (this.currentcred && this.currentcred.id) {
                method = "patch";
                api = "/w/company/update_credentials";
                reqData.id = this.currentcred.id;
            }
            const reqParams: any = this.cs.replaceEmptyWithNull(reqData);
            this._bs
                .doRequest(api, method, reqParams)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        try {
                            this.currentcred = {};
                            const msg = result.id
                                ? "Credential added successfully"
                                : "Credential updated successfully";
                            this.toast.sToast("success", msg);
                            this.getAssetCredential();
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        const data = result.message ? result.message : result.data;
                        this.toast.sToast("error", data);
                    }
                });
        }
    }

    getAssetCredential(): void {
        const agentId = this.cAsset.agent_id;
        const company_id = this.cAsset.company_id;
        this.loaderService.display(true);
        // Getting credentials to call the API
        this._bs.doRequest(`/r/company/agent_credentials_mapping`, 'get', null,
            { condition: `agent_id=${agentId} and company_id = ${company_id}` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status && res.data) {
                    const mappingIds = res.data.map((item: any) => item.credentials_id);
                    // Second API call
                    this._bs.doRequest(`/r/company/credentials`, 'get', null, { condition: `company_id=${company_id} and asset_id=${this.cAsset.id}` })
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((credRes: any) => {
                            if (credRes.status) {
                                if (credRes.data && credRes.data.length) {
                                    // Accessing the ID from the second API call
                                    const additionalId = credRes.data[0].id;
                                    mappingIds.push(additionalId); // Push the additional ID
                                    const payload = {
                                        data: {
                                            agent_id: agentId,
                                            asset_id: this.cAsset.id,
                                            mapping_ids: mappingIds,
                                            company_id: this.cAsset.company_id,
                                            setting_type: "credentials",
                                        },
                                    };
                                    try {
                                        // Call the post endpoint
                                        this._bs.doRequest(`/w/company/creddiscoverymapping`, 'post', payload)
                                            .pipe(takeUntil(this._unsubscribeAll))
                                            .subscribe((result: any) => {
                                                if (result.status) {
                                                    this.modalService.close('assetNewCredential');
                                                } else {
                                                    const data = result.message ? result.message : result.data;
                                                    this.toast.sToast("error", data);
                                                }
                                            });
                                    } catch (error) {
                                        // Handle error
                                    }
                                }
                            }
                        });
                }
            });
    }


    getCredentialById() {
        const company_id = this.cAsset.company_id;
        this._bs.doRequest(`/r/company/credentials`, 'get', null, { condition: `company_id=${company_id} and asset_id=${this.cAsset.id}` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.cred = {};
                this.currentcred = {};
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.cred = res.data[0].params
                        this.currentcred = res.data[0];
                    }
                }
                setTimeout(() => {
                    this.modalService.open('assetNewCredential');

                }, 500);
            });
    }

    deleteCredentialById(): void {
        const company_id = this.cAsset.company_id;
        this._bs.doRequest(`/r/company/credentials`, 'get', null, { condition: `company_id=${company_id} and asset_id=${this.cAsset.id}` })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.deleteCred(res.data[0].id);
                    }
                } else {
                    this.toast.sToast("error", 'Credential not added.');
                }

            });
    }

    deleteCred(id: any) {
        const confirmation = this.confirmDialog.open({
            title: "Confirmation",
            message: "Are you sure you want to delete this Credentials?",
            actions: {
                confirm: {
                    label: "Yes",
                },
            },
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === "confirmed") {
                this._bs
                    .doRequest(`/d/company/credentials/${id}`, "delete")
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result) {
                            this.toast.sToast("success", "Removed successfully");
                        } else {
                            const data = result.message ? result.message : result.data;
                            this.toast.sToast("error", data);
                        }
                    });
            }
        });
    }
    SaveAssetOwner(): void {
        const params: any = { id: this.cAsset.id, data: { asset_owner: this.assetOwner } };
        this.loaderService.display(true);
        this._bs.doRequest(`/w/asset/assets`, 'patch', params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.cAsset.asset_owner = this.assetOwner;
                    this.editing = false;
                    this.toast.sToast('success', "Asset Owner Updated Successfully.");
                } else {
                    this.toast.sToast('error', "Please Enter Asset Owner!");
                }
            });
    }
    toggleEditing() {
        this.editing = !this.editing;
        if (this.editing && !this.assetOwner && this.cAsset.asset_owner) {
            this.assetOwner = this.cAsset.asset_owner;
        }
        if (!this.editing && this.assetOwner.trim() !== '') {
            this.SaveAssetOwner();
        }
    }
    updateAssetName(): void {
        // @ts-ignore
        const importance: any = { id: this.cAsset.id, data: { visible_name: this.asset_name } };
        this.loaderService.display(true);
        this._bs.doRequest((this.is_deprecated) ? '/w/asset/deprecated_assets_view' : `/w/asset/assets`, 'patch', importance)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast("success", "Asset name updated successfully");
                    this.cAsset.visible_name = this.asset_name; this._changeDetectorRef.detectChanges();
                    this.assetNameMenuTrigger.closeMenu();
                    this.refreshTbl.emit({ visible_name: this.asset_name, id: this.cAsset.id });
                    this.asset_name = this.asset_name;
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    pageCall(event: any): void {
        const defaultHeight = 250;
        if (event.length >= event.pageSize) {
            if (event.pageSize === 5) {
                this.height.px = defaultHeight * 1.5;
            } else if (event.pageSize === 10) {
                this.height.px = defaultHeight * 3;
            } else if (event.pageSize >= 25) {
                this.height.px = defaultHeight * 6;
            }
        }
    }

    actionCall(data: any) {
        const tableName: any = this.probTableOptions.tableOptions.title;
        this.cs.selectedTableRows[tableName] = [data.row];
        this.suppressionAction(data.action);
    }

    globalActionCall(data: any) {
        this.suppressionAction(data.action);
    }

    suppressionAction(res: any): void {
        console.log('res ---->', res)
        console.log('selectrd table row ---->', this.cs.selectedTableRows)
        if (res.target === 'problem_solution_suppress') {
            if ((this.cs.selectedTableRows['Remediation Plan'] && this.cs.selectedTableRows['Remediation Plan'].length) || (this.cs.selectedTableRows['Problem Details'] && this.cs.selectedTableRows['Problem Details'].length)) {
                this.suppressData = {
                    "problem_id": (this.cs.selectedTableRows['Remediation Plan'] && this.cs.selectedTableRows['Remediation Plan'].length) ? this.cs.selectedTableRows['Remediation Plan'] : this.cs.selectedTableRows['Problem Details'],
                    suppression_status: 'Open',
                    "internal_users": [],
                    reason: '',
                    'approve_type': 'external',
                    reasonId: '1',
                    "external_user": [],
                    "suppressed_on": "",
                    "suppressed_till": "",
                    is_permanent: false
                }
                if (this._userService._user_roles.indexOf('admin') > -1 || this._userService._user_roles.indexOf('approver') > -1) {
                    this.getInternalUsernal();
                    this.suppressData.approve_type = '';
                } else {
                    this.suppressData.internal_type === 'external';
                }
                this.modalService.open('assetDetailsSupScanModal');
            } else {
                this.toast.sToast('info', 'Please select at least one problem/solution.');
            }
        }
        if (res.target === 'integration_action') {
            const tableName = this.probTableOptions.tableOptions.title;
            if (
                this.cs.selectedTableRows[tableName] &&
                this.cs.selectedTableRows[tableName].length
            ) {
                this.loaderService.display(true, 'Preparing data...');
                this.actionData.initial_description = '';
                setTimeout(() => {
                    this.productData = [];
                    const len = cloneDeep(this.cs.selectedTableRows[tableName]);
                    let initial_description = '';
                    let summary = '';
                    let source_ids = [];
                    let asset_ids = [];
                    let ticket_id = '';
                    let ticket_number = [];
                    let integration_name = '';
                    this.productData = len;
                    len?.map((rem: any) => {
                        source_ids.push(rem.problem_name);
                        const name = rem.ticket_status_map.filter((x: any) => (x.ticket_number || x.ticket_id) && x.status.toLowerCase() === 'open')
                        if (name && name.length) {
                            const selectedTicket = name[name.length - 1];
                            ticket_id = (selectedTicket.ticket_number && selectedTicket.ticket_id) ? selectedTicket.ticket_number : selectedTicket.ticket_id;
                            integration_name = (selectedTicket.integration_name) ? selectedTicket.integration_name : '';
                            ticket_number = [selectedTicket];
                        } else {
                            ticket_id = '';
                        }
                        asset_ids = [this.cAsset.id];
                        const assetNamesString = `Asset Name: ${this.cAsset.host_name}\n`;
                        initial_description += `Problem Name: ${rem.problem_name}\n`;
                        initial_description += assetNamesString;
                        initial_description += `Severity: ${rem.severity} - Software Name: ${rem.software_name}\n`;
                        if (rem.epss_vuls) {
                            initial_description += `EPSS Score: ${+parseFloat(rem.epss_vuls)?.toFixed(2)}\n`;
                        }
                        initial_description += '---------------------------------------------------------------------\n';
                        initial_description += '\n';
                        initial_description += `Scores: \n`;
                        initial_description += `Base Score: ${rem.base_score}\n`;
                        initial_description += `Exploitability Score : ${rem.exploitability_score}\n`;
                        initial_description += `Impact Score: ${rem.impact_score}\n`;
                        initial_description += '---------------------------------------------------------------------\n';
                        initial_description += '\n';
                        initial_description += '\n';
                        summary += `Asset Name: ${this.cAsset.host_name} - Problem Name: ${rem.problem_name}, - Severity: ${rem.severity} - Software Name: ${rem.software_name}`;
                        if (rem.epss_score) {
                            summary += `- EPSS Score: ${+parseFloat(rem.epss_score)?.toFixed(2)}`;
                        }
                        this.actionData = {
                            asset_id: this.cAsset.id, asset_ids: [this.cAsset.id], description: initial_description, summary: summary, source_ids: source_ids, ticket_id: ticket_id, action_type: 'Vulnerability Event', event_type: "Problems", integration_name: integration_name, ticket_number: ticket_number
                        }
                    });

                    this.integrationActionService.integrationActionShow = false;
                    this.integrationActionService.integrationAssetActionShow = true;
                    setTimeout(() => {
                        this.integrationActionService.integrationActionPopup.next({
                            value: true,
                        });
                        this.loaderService.display(false);
                    }, 4000);

                }, 1000);

            } else {
                this.toast.sToast(
                    "info", "Please select at least one record from Problems."
                );
            }
        }
    }
    setMinEndDate() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow
        this.minEndDate = this.datePipe.transform(tomorrow, 'yyyy-MM-dd')!;
    }
    suppressProblem(suppress: any): void {

        const reqData = [];
        const suppressData: any = cloneDeep(suppress);
        suppressData.external_user = (suppressData.external_user && suppressData.external_user.length) ? suppressData.external_user.split(',') : [];
        if (suppressData.approve_type === 'self') {
            suppressData.internal_users = [this.currentUser]
        }
        if (suppressData.reasonId !== 'Other') {
            suppressData.reason = suppressData.reasonId;
        }
        const status = (suppressData.approve_type === 'self') ? 'Approved' : 'Open';
        if (suppressData.is_permanent) {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = null;
        } else {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = this.cs.dateChange(suppressData.suppressed_till, 'end');
        }
        delete suppressData.reasonId; delete suppressData.internal_type; delete suppressData.problem_id; delete suppressData.approve_type;
        this.suppressData.problem_id.forEach((i: any) => {
            const data: any = { ...suppressData, ...{ suppression_status: status, asset_id: this.cAsset.id, asset_name: this.cAsset.ip, company_name: this.cAsset.company_name, company_id: this.cAsset.company_id + '', problem_name: i.problem_name ? i.problem_name : i.product } };
            if (i.solution_id) {
                data.solution_id = i.solution_id;
            } else {
                data.problem_id = i.problem_id;
            }
            reqData.push(data);
        })
        this.loaderService.display(true);
        this._bs.doRequest(`/r/exception/suppress_vulnerability/createexception`, 'post', { data: reqData })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast('success', 'Suppression workflow initiated successfully. All tickets related to this software were get closed.');
                    this.cs.reloadProgram.emit('');
                    this.modalService.close('assetDetailsSupScanModal');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }
    cancelSuppress(): void {
        this.modalService.close('assetDetailsSupScanModal')
    }

    globalActionCall1(data: any) {
        // this.actionsuppress(data.action);



        if (data.action.text == 'Suppress') {
            this.actionsuppress(data.action);
        }
        if (data.action.text == 'Unsuppress') {

            const approveStatus = data.row.filter((x: any) => x.is_suppressed === true);


            this.cs.reason = '';
            const confirmation = this.confirmDialog.open({
                title: 'Confirmation',
                message: `Are you sure you want to unsuppress this record?`,
                actions: {
                    confirm: {
                        label: 'Yes'
                    }
                },
                isReason: true,
            });
            confirmation.afterClosed().subscribe((result) => {
                if (result === 'confirmed') {
                    this._bs.doRequest(`/w/exception/custom_vulnerabilities/updateexception`, 'post', {
                        suppression_status: 'Unsuppressed',
                        unsuppress_comments: this.cs.reason,
                        id: approveStatus.map((x: any) => x.id)
                        //id: [data.row.id]
                    })
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            if (result.status) {
                                this.toast.sToast('success', 'Successfully Unsuppressed');
                                setTimeout(() => this.cs.selectedTagChange.next({}));
                                if (this.filterKey === 'problem_id') {
                                    this.cs.reloadProgram.emit('');
                                }
                            } else {
                                const data = (result.message) ? result.message : result.data;
                                this.toast.sToast('error', data);
                            }
                        });
                }
            })
        }
    }

    actionCall1(data: any) {

        if (data.action.text == 'Suppress') {
            const tableName: any = this.registryprobTableOptions.tableOptions.title;
            this.cs.selectedTableRows[tableName] = [data.row];
            this.actionsuppress(data.action);
        }


        if (data.action.text == 'Unsuppress') {
            // if (data.row.suppression_status !== 'Approved') {
            //   this.toast.sToast('error', 'Please note that only records marked as "Approved" can be unsuppressed.');
            //   return;
            // }
            this.cs.reason = '';
            const confirmation = this.confirmDialog.open({
                title: 'Confirmation',
                message: `Are you sure you want to unsuppress this record?`,
                actions: {
                    confirm: {
                        label: 'Yes'
                    }
                },
                isReason: true,
            });
            confirmation.afterClosed().subscribe((result) => {
                if (result === 'confirmed') {
                    this._bs.doRequest(`/w/exception/custom_vulnerabilities/updateexception`, 'post', {
                        suppression_status: 'Unsuppressed',
                        id: [data.row.id],
                        unsuppress_comments: this.cs.reason
                    })
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            if (result.status) {
                                this.toast.sToast('success', 'Successfully Unsuppressed');
                                this.cs.reason = null
                                setTimeout(() => this.cs.selectedTagChange.next({}));
                                if (this.filterKey === 'problem_id') {
                                    this.cs.reloadProgram.emit('');
                                }
                            } else {
                                const data = (result.message) ? result.message : result.data;
                                this.toast.sToast('error', data);
                            }
                        });
                }
            })
        }



    }

    suppressRegistry(suppress: any): void {

        const reqData = [];
        const suppressData: any = cloneDeep(suppress);
        suppressData.external_user = (suppressData.external_user && suppressData.external_user.length) ? suppressData.external_user.split(',') : [];
        if (suppressData.approve_type === 'self') {
            suppressData.internal_users = [this.currentUser]
        }
        if (suppressData.reasonId !== 'Other') {
            suppressData.reason = suppressData.reasonId;
        }
        const status = (suppressData.approve_type === 'self') ? 'Approved' : 'Open';
        if (suppressData.is_permanent) {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = null;
        } else {
            suppressData.suppressed_on = null;
            suppressData.suppressed_till = this.cs.dateChange(suppressData.suppressed_till, 'end');
        }
        delete suppressData.reasonId; delete suppressData.internal_type; delete suppressData.problem_id; delete suppressData.approve_type;
        this.suppressData.problem_id.forEach((i: any) => {
            const data: any = { ...suppressData, ...{ suppression_status: status, asset_id: this.cAsset.id, asset_name: this.cAsset.ip, company_name: this.cAsset.company_name, company_id: this.cAsset.company_id + '', vul_id: i.vul_id ? i.vul_id : i.product } };
            if (i.solution_id) {
                data.solution_id = i.solution_id;
            } else {
                data.problem_id = i.problem_id;
            }
            reqData.push(data);
        })
        this.loaderService.display(true);
        this._bs.doRequest(`/r/exception/custom_vulnerabilities/createregistryexception`, 'post', { data: reqData })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast('success', 'Suppression workflow initiated successfully. All tickets related to this software were get closed.');
                    this.cs.reloadRegistryProgram.emit('');
                    this.resetSuppressDataRegistry();
                    this.modalService.close('registrySuppress');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                    this.resetSuppressDataRegistry();
                }
            });
    }


    resetSuppressDataRegistry(): void {
        this.suppressDataRegistry = {
            reasonId: '',
            reason: '',
            approve_type: '',
            internal_type: '',
            internal_users: [],
            external_user: '',
            suppress_comments: '',
            is_permanent: false,
            suppressed_on: null,
            suppressed_till: null,
            problem_id: []
        };
    }


    cancelSuppressRegistry(): void {
        this.modalService.close('registrySuppress')
        this.resetSuppressDataRegistry();
    }


    actionsuppress(res: any): void {
        console.log('res ---->', res)
        console.log('selectrd table row ---->', this.cs.selectedTableRows)
        if (res.target === 'registry_suppress') {
            if ((this.cs.selectedTableRows['Registry Problems'] && this.cs.selectedTableRows['Registry Problems'].length)) {
                this.suppressData = {
                    "problem_id": this.cs.selectedTableRows['Registry Problems'],
                    suppression_status: 'Open',
                    "internal_users": [],
                    reason: '',
                    'approve_type': 'external',
                    reasonId: '1',
                    "external_user": [],
                    "suppressed_on": "",
                    "suppressed_till": "",
                    is_permanent: false
                }
                if (this._userService._user_roles.indexOf('admin') > -1 || this._userService._user_roles.indexOf('approver') > -1) {
                    this.getInternalUsernal();
                    this.suppressData.approve_type = '';
                } else {
                    this.suppressData.internal_type === 'external';
                }
                this.modalService.open('registrySuppress');
            } else {
                this.toast.sToast('info', 'Please select at least one problem/solution.');
            }
        }

        if (res.target === 'registry_integration_action') {
            const tableName = this.registryprobTableOptions.tableOptions.title;
            if (
                this.cs.selectedTableRows[tableName] &&
                this.cs.selectedTableRows[tableName].length
            ) {
                this.loaderService.display(true, 'Preparing data...');
                this.actionData.initial_description = '';
                setTimeout(() => {
                    this.productData = [];
                    const len = cloneDeep(this.cs.selectedTableRows[tableName]);
                    let initial_description = '';
                    let summary = '';
                    let source_ids = [];
                    let asset_ids = [];
                    let ticket_id = '';
                    let ticket_number = [];
                    let integration_name = '';
                    this.productData = len;
                    len?.map((rem: any) => {
                        source_ids.push(rem.vul_id);
                        console.log("Source id----------->", rem.vul_id)
                        const name = rem.ticket_status_map.filter((x: any) => (x.ticket_number || x.ticket_id) && x.status.toLowerCase() === 'open')
                        if (name && name.length) {
                            const selectedTicket = name[name.length - 1];
                            ticket_id = (selectedTicket.ticket_number && selectedTicket.ticket_id) ? selectedTicket.ticket_number : selectedTicket.ticket_id;
                            integration_name = (selectedTicket.integration_name) ? selectedTicket.integration_name : '';
                            ticket_number = [selectedTicket];
                        } else {
                            ticket_id = '';
                        }
                        asset_ids = [this.cAsset.id];
                        const assetNamesString = `Asset Name: ${this.cAsset.host_name}\n`;
                        initial_description += `Problem Name: ${rem.vul_id}\n`;
                        initial_description += assetNamesString;
                        initial_description += `Severity: ${rem.severity} - Software Name: ${rem.software_name}\n`;
                        if (rem.epss_vuls) {
                            initial_description += `EPSS Score: ${+parseFloat(rem.epss_vuls)?.toFixed(2)}\n`;
                        }
                        initial_description += '---------------------------------------------------------------------\n';
                        initial_description += '\n';
                        initial_description += `Scores: \n`;
                        initial_description += `Base Score: ${rem.base_score}\n`;
                        initial_description += `Exploitability Score : ${rem.exploitability_score}\n`;
                        initial_description += `Impact Score: ${rem.impact_score}\n`;
                        initial_description += '---------------------------------------------------------------------\n';
                        initial_description += '\n';
                        initial_description += '\n';
                        //summary += `Asset Name: ${this.cAsset.host_name} - Problem Name: ${rem.problem_name}, - Severity: ${rem.severity} - Software Name: ${rem.software_name}`;
                        summary += `Asset Name: ${this.cAsset.host_name} - Problem Name: ${rem.vul_id}`;
                        if (rem.epss_score) {
                            summary += `- EPSS Score: ${+parseFloat(rem.epss_score)?.toFixed(2)}`;
                        }
                        this.actionData = {
                            asset_id: this.cAsset.id, asset_ids: [this.cAsset.id], description: initial_description, summary: summary, source_ids: source_ids, ticket_id: ticket_id, action_type: 'Vulnerability Event', event_type: "Problems", integration_name: integration_name, ticket_number: ticket_number
                        }
                    });

                    this.integrationActionService.integrationActionShow = false;
                    this.integrationActionService.integrationAssetActionShow = true;
                    setTimeout(() => {
                        this.integrationActionService.integrationActionPopup.next({
                            value: true,
                        });
                        this.loaderService.display(false);
                    }, 4000);

                }, 1000);

            } else {
                this.toast.sToast(
                    "info", "Please select at least one record from Problems."
                );
            }
        }
    }

    tagFilterCall(event: { selectedValue: string, viewcheck: any }): void {
        this.maualselectfilter = event.selectedValue;
        this.viewupdate = event.viewcheck;
        this.problemCount();
        if (this.viewupdate === 'auto_suppressed') {
            this.suppressed_table_settings = (this.suppressed_table_settings.value && this.suppressed_table_settings.value.settings && this.suppressed_table_settings.value.settings.vul_filter) ? this.suppressed_table_settings.value.settings.vul_filter = event.selectedValue : { value: { settings: { vul_filter: event.selectedValue } } };
            this.viewCategory(this.currentProblem.problem_group_id, 'auto_suppressed');
        } else {
            this.table_settings = (this.table_settings.value && this.table_settings.value.settings && this.table_settings.value.settings.vul_filter) ? this.table_settings.value.settings.vul_filter = event.selectedValue : { value: { settings: { vul_filter: event.selectedValue } } };
            this.viewCategory(this.currentProblem.problem_group_id, 'problem');
        }
    }

    handlePatchClick(patch: any) {
        const patchUrl = `https://catalog.update.microsoft.com/Search.aspx?q=${patch}`;
        window.open(`${patchUrl}`, '_blank');
    }

    rhelLinkCall($event: any): void {
        if ($event.col === "fix") {
            if (!$event.row.fix_url) { return; };
            window.open($event.row.fix_url, "_blank");
        }
    }

    toggleEditMode(val: any): void {
        this._network_doc_data = cloneDeep(this.networkDocumentation);
        this.isEditNetworkDocumentation = true;
    }

    saveNetworkDocumentation(): void {
        console.log(this._network_doc_data);
        const reqData: any = cloneDeep(this._network_doc_data);
        this.loaderService.display(true);
        const formData = new FormData();
        formData.append("asset_id", this.cAsset.id);
        formData.append("company_id", this.cAsset.company_id);
        formData.append('po_document', (this._network_doc_data['po_document'] && this._network_doc_data['po_document'].length) ? this._network_doc_data['po_document'][0].file : null);
        formData.append('warranty_document', (this._network_doc_data['warranty_document'] && this._network_doc_data['warranty_document'].length) ? this._network_doc_data['warranty_document'][0].file : null);
        if (this._network_doc_data['extra_document'] && this._network_doc_data['extra_document'].length) {
            this._network_doc_data['extra_document'].map((y: any, index: any) => {
                formData.append(`extra_documents${index + 1}`, y.file);
            })
        }
        this._bs.uploadFormData("/w/asset/upload_document", formData).subscribe(
            (res: any) => {
                this.loaderService.display(false);
                console.log(res);
                if (res.status) {
                    const post_data: any = {
                        data: {
                            asset_id: this.cAsset.id, company_id: this.cAsset.company_id, owner_name: reqData.owner_name, owner_contact_number: reqData.owner_contact_number,
                            owner_email: reqData.owner_email, category: reqData.category, type: reqData.type, system_type: reqData.system_type,
                            location: reqData.location, purchase_date: reqData.purchase_date, deployment_date: reqData.deployment_date, cost_center: reqData.cost_center,
                            po_number: reqData.po_number, department: reqData.department, warranty_start_date: reqData.warranty_start_date, warranty_end_date: reqData.warranty_end_date,
                            backup_status: reqData.backup_status, remote_access_status: reqData.remote_access_status, eol_status: reqData.eol_status
                        }
                    }
                    post_data.data.deployment_date = this.cs.dateChange(reqData.deployment_date, 'start');
                    post_data.data.purchase_date = this.cs.dateChange(reqData.purchase_date, 'start');
                    post_data.data.warranty_start_date = this.cs.dateChange(reqData.warranty_start_date, 'start');
                    post_data.data.warranty_end_date = this.cs.dateChange(reqData.warranty_end_date, 'start');
                    (reqData.id) ? post_data.id = reqData.id : null;
                    this.loaderService.display(true);
                    this._bs.doRequest(`/w/asset/asset_metadata`, (reqData.id) ? 'patch' : 'post', post_data)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            this.loaderService.display(false);
                            if (result.status) {
                                this.getUploadedDocDetails();
                            } else {
                                this.toast.sToast('error', result.message);
                            }
                        });
                } else {
                    this.toast.sToast('error', res.message);
                }
            },
            (error: any) => {
                console.error("Error posting file to API:", error);
                this.loaderService.display(false);
            }
        );
    }

    uploadImage(event: any): void {
        if (!event.target.files.length) {
            return;
        }
        this._network_doc_data.po_document = [
            {
                name: event.target.files[0].name,
                fileSize: (Math.round((event.target.files[0].size / (1024 * 1024)) * 100) / 100),
                file: event.target.files[0]
            }
        ];
    }
    uploadWarrantyImage(event: any): void {
        if (!event.target.files.length) {
            return;
        }
        this._network_doc_data.warranty_document = [
            {
                name: event.target.files[0].name,
                fileSize: (Math.round((event.target.files[0].size / (1024 * 1024)) * 100) / 100),
                file: event.target.files[0]
            }
        ]
    }
    uploadExtraImage(event: any): void {
        if (!event.target.files.length) {
            return;
        }
        if (!this._network_doc_data.extra_document) {
            this._network_doc_data.extra_document = [];
        }
        this._network_doc_data.extra_document.push(
            {
                name: event.target.files[0].name,
                fileSize: (Math.round((event.target.files[0].size / (1024 * 1024)) * 100) / 100),
                file: event.target.files[0]
            }
        )
    }

    pageChanged(event: any): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getSoftwareData();
    }


    async getUploadedDocDetails() {
        this.loaderService.display(true);
        const reqData: any = {
            asset_id: this.cAsset.id,
            company_id: this.cAsset.company_id
        }
        const params: any = {
            condition: `asset_id=${this.cAsset.id} and company_id=${this.cAsset.company_id}`
        }
        const doc_response: any = await this._bs.doRequest(`/w/asset/documents`, 'post', reqData).toPromise();
        const meta_response: any = await this._bs.doRequest(`/r/asset/asset_metadata`, 'get', null, params).toPromise();
        if (doc_response.status && meta_response.status) {
            this.networkDocumentation.host_name = this.cAsset.host_name;
            this.networkDocumentation.icon = this.cAsset.icon;
            this.networkDocumentation.mac = this.cAsset.mac;
            this.networkDocumentation.importance = this.cAsset.importance;
            this.networkDocumentation.ip = this.cAsset.ip;
            this.networkDocumentation.tags = this.cAsset.tags;
            this.networkDocumentation.manual_tags = this.cAsset.manual_tags;
            this.networkDocumentation.defaultTags = [];
            this.networkDocumentation.manualTags = [];
            if (typeof this.networkDocumentation.manual_tags === 'string') {
                this.networkDocumentation.manual_tags = JSON.parse(this.networkDocumentation.manual_tags);
            }
            const output_array = Object.entries(this.networkDocumentation.tags)
                .filter(([key, value]) => Array.isArray(value) && value.length > 0)
                .map(([key, value]) => ({
                    name: key,
                    value: value,
                }));
            this.networkDocumentation.defaultTags = output_array;
            if (Object.keys(this.networkDocumentation.manual_tags).length === 0) {
                this.networkDocumentation.manualTags = []
            } else {
                const outputArray = Object.entries(this.networkDocumentation.manual_tags).map(([name, value]) => ({ name, value }));
                this.networkDocumentation.manualTags = outputArray;
            }
            this.networkDocumentation = {
                ...this.networkDocumentation,
                ...meta_response.data[0]
            }
            const output: any = {};

            const poDoc = doc_response.data.find((d: any) => d.document_type === 'po_document');
            if (poDoc) {
                output.po_document = [{
                    name: poDoc.original_filename,
                    preview: 'doc',
                    downloadUrl: ''
                }];
            }

            const warrantyDoc = doc_response.data.find((d: any) => d.document_type === 'warranty_document');
            if (warrantyDoc) {
                output.warranty_document = [{
                    name: warrantyDoc.original_filename,
                    preview: 'doc',
                    downloadUrl: ''
                }];
            }

            const extraDocs = doc_response.data.filter((d: any) => d.document_type.startsWith('extra_document'));
            if (extraDocs.length > 0) {
                output.extra_document = extraDocs.map((d: any) => ({
                    name: d.original_filename,
                    preview: 'doc',
                    downloadUrl: ''
                }));
            }
            this.networkDocumentation = {
                ...this.networkDocumentation,
                ...output
            }
            console.log(this.networkDocumentation);
            this.drawerDocOpened = false; this.isEditNetworkDocumentation = false;
            this.isEditNetworkDocumentation = false;
            this.loaderService.display(false);
            if (!this.matDocDrawer.opened) {
                this.matDocDrawer.toggle();
            }
            this._changeDetectorRef.detectChanges();
        }
    }


    getSoftwareData(): void {
        this.loaderService.display(true);
        this.networkDocumentationSW = [];
        let skip = 0;
        let limit = this.pageSize;
        if (this.pageIndex) {
            skip = this.pageIndex * this.pageSize;
            limit = this.pageSize;
        }
        this._changeDetectorRef.detectChanges();
        let condition: any = { condition: `asset_id='${this.cAsset?.id}' and software_type = 'installedprogram'`, skip, limit, order_by: 'updated desc' }
        this._bs
            .doRequest(`/r/report_queries/asset_software`, "get", null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.networkDocumentationSW = result.data;
                    this.pageTotal = result.total;
                    this._changeDetectorRef.detectChanges();
                }
            });
    }
}
