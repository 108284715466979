// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;
export type Themes = { id: string; name: string }[];
export type Views = { id: string; name: string }[];
export type View = 'global' | string;
export type DynamicPosition = 'right' | 'left' 
export type TableView = false | true
/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface FuseConfig
{
    layout: string;
    scheme: Scheme;
    screens: Screens;
    theme: Theme;
    themes: Themes;
    views: Views;
    view: View;
    dynamiPosition:DynamicPosition;
    tableView:TableView;
}
