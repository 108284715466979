import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { BaseRequestService } from "../../../_services/base.service";
import { Subject, takeUntil } from "rxjs";
import { MatButtonModule } from '@angular/material/button';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { OnInit } from "@angular/core";
import { ModalComponent } from '../modal.component';
import { ModalService } from 'app/_services/modal.service';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'show-more-less',
    standalone: true,
    imports: [
        CommonModule,
        AppFilterPipeModule,
        MatButtonModule,
        MaterialModule,
        FuseScrollbarDirective,
        ModalComponent,
        MaterialModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MaterialModule,
        FormsModule,
        MatFormFieldModule
    ],
    templateUrl: './show-more-less.component.html',
    styleUrls: ['./show-more-less.component.scss']
})
export class ShowMoreLessComponent implements AfterViewInit, OnChanges, OnInit {
    @Input() items: string[] = [];
    @Input() maxItemsToShow = 3; // Adjust this value as needed
    @Input() showAllItems = false;
    showcard: boolean = false
    @Input() showOrder: 'list' | 'span' = 'span';
    @Input() bgColor: string = 'bg-yellow-200';
    @Input() color: string = 'text-blue-900';
    @Input() searchInput: string = '';
    @Input() type: 'list' | 'string' = 'list';
    @Input() inputString: string = '';
    @Input() hyperlinkList: any = [];
    @Input() wordBreak: any = '';
    @Input() columnDetails: any = false;
    @Input() columnKey: any = null;
    @Output() showmoreData = new EventEmitter()

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    darkBg = '';
    darkColor = '';
    last_index = 100;
    counter = 100;
    firstCount = 100;

    last_index1 = 30;  // Update to 40
    counter1 = 30;     // Set initial character count to 40
    firstCount1 = 30;
    showTxt = 'Show More';
    showFullText = false;
    searchTerm: any;
    softwareData: any = [];
    showmorepopData: any = [];
    masterpopupData: any = [];
    isloading: boolean = false;



    constructor(private bs: BaseRequestService, public cdr: ChangeDetectorRef, public modalService: ModalService,) {
        this.darkBg = 'dark:' + this.bgColor;
        this.darkColor = 'dark:' + this.color;
    }
    ngOnChanges(changes: SimpleChanges): void { }

    ngOnInit() {
        if(this.columnKey == 'Software Name'){
            // if (this.type === 'string') {
            //     if (Array.isArray(this.inputString)) {
            //         this.inputString = this.inputString.toString();
            //     }
            //     this.last_index1 = this.inputString.substring(0, this.firstCount1).lastIndexOf(' ');
            //     if (this.last_index1 > this.firstCount1 || this.last_index1 < this.firstCount1) {
            //         this.last_index1 = this.firstCount1;
            //     }
            //     this.counter1 = this.last_index1;
            //     if (this.inputString && this.inputString.length < this.firstCount1) {
            //         this.counter1 = this.firstCount1;
            //     }  
            // } else if (this.type === 'list' && (!this.hyperlinkList || !this.hyperlinkList.length)) {
            //     this.items = this.items.filter((x: any) => x);
            // }
            if (Array.isArray(this.inputString)) {
                this.inputString = this.inputString.toString();
              }
        }else{
            if (this.type === 'string') {
                    if (Array.isArray(this.inputString)) {
                        this.inputString = this.inputString.toString();
                    }
                    this.last_index = this.inputString.substring(0, 100).lastIndexOf(' ');
                    if (this.last_index > 100 || this.last_index < 100) {
                        this.last_index = 100;
                    }
                    this.counter = this.last_index;
                    if (this.inputString && this.inputString.length < 100) {
                        this.counter = 100;
                    }
                } else if (this.type === 'list' && (!this.hyperlinkList || !this.hyperlinkList.legnth)) {
                    this.items = this.items.filter((x: any) => x);
                }
        }
    }
    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }
    toggleShowItems() {
        this.showAllItems = !this.showAllItems;
    }


    toggleSkil(data: any) {
        if (this.columnDetails && this.inputString) {
            this.showmorepopData = [];
            this.masterpopupData = []
            this.cdr.detectChanges();
            this.isloading = true;
            this.showmorepopData = Object.assign([], this.inputString.split(','));
            this.masterpopupData = this.showmorepopData;
            this.cdr.detectChanges();
            this.modalService.open('sotwareDetailsPopup');
        } else if (this.counter < 101) {
            this.counter = this.inputString.length;
            this.showTxt = 'Show Less';
            this.showFullText = !this.showFullText;
        } else {
            this.counter = this.last_index;
            this.showTxt = 'Show More';
            this.showFullText = !this.showFullText;
        }
    }
    toggleSoftwareNameDisplay() {
        this.showFullText = !this.showFullText;
        this.showTxt = this.showFullText ? 'Show Less' : 'Show More';
    }

    clickOnDomain(url: any): void {
        url = (url.startsWith('http://') || url.startsWith('https://')) ? url : `https://${url}`;
        window.open(url, '_blank');
    }

    redirect(item: any, index: any): void {
        const ticket = this.hyperlinkList.filter((a: any, i: any) => i === index);
        if (ticket && ticket.length) {
            const ticket_id = (item.ticket_id) ? item.ticket_id : item.ticket_number;
            if (ticket[0] && ticket[0].ticket_url) {
                let url = (ticket[0] && ticket[0].ticket_url && (ticket[0].ticket_url.startsWith('http://') || ticket[0].ticket_url.startsWith('https://'))) ? ticket[0].ticket_url : `https://${ticket[0].ticket_url}`;
                url = url.replace('${ticket_id}', ticket_id.trim()).replace(/\\\"/g, '"');
                window.open(url, '_blank');
            } else if (item.credential_id) {
                const params = { condition: `id=${item.credential_id}` };
                this.bs.doRequest('/r/company/integration_credentials', 'get', null, params)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res: any) => {
                        if (res.status && res.data && res.data.length) {
                            const ticket = res.data;
                            let url = (ticket[0] && ticket[0].ticket_url && (ticket[0].ticket_url.startsWith('http://') || ticket[0].ticket_url.startsWith('https://'))) ? ticket[0].ticket_url : `https://${ticket[0].ticket_url}`;
                            url = url.replace('${ticket_id}', ticket_id.trim()).replace(/\\\"/g, '"');
                            window.open(url, '_blank');
                        }
                    });
            }
        }
    }

    filterCompany(): void {
        if (!this.searchTerm) {
            this.showmorepopData = this.masterpopupData;
        } else {
            let lowerSearchTerm = this.searchTerm.toLowerCase();
            this.showmorepopData = this.masterpopupData.filter((name: any) => name.toLowerCase().includes(lowerSearchTerm));
        }
    }

}
