<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Navigation -->
<fuse-vertical-navigation
    class="bg-card dark:bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center mx-2 lg:mr-8 mt-2">
            <div class="hidden lg:flex">
                <!-- Light version -->
                <img
                    class="dark:hidden  max-w-[12rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo" aria-label="light"
                    alt="Logo image">
                <!-- Dark version -->
                <img
                    class="hidden dark:flex  max-w-[12rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo_dark" aria-label="dark"
                    alt="Logo image">
                <!--<div class="flex flex-row items-end justify-end">
                <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
            </div>-->
            </div>
            <!-- Small version -->

            <div class="flex lg:hidden">
                <!-- Light version -->
                <img
                    class="dark:hidden  max-w-[10rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo" aria-label="light"
                    alt="Logo image">
                <!-- Dark version -->
                <img
                    class="hidden dark:flex  max-w-[10rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo_dark" aria-label="dark"
                    alt="Logo image">
                <!--<div class="flex flex-row items-end justify-end">
                <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
            </div>-->
            </div>

        </div>
        <!-- <div class="flex items-center justify-center h-20 p-2 mt-2">
            <img
                class="w-26 dark:hidden"
                [src]="_bs.whitelabel().logo" aria-label="logo"
                alt="Logo image">
            <img
                class="w-26 hidden dark:flex"
                [src]="_bs.whitelabel().logo_dark" aria-label="logo"
                alt="Logo image">
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <app-notify></app-notify>
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <scope  *ngIf="_cs.isChecked === 'left'"></scope>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <app-theme-switch class="hidden md:block"></app-theme-switch>
            <scope *ngIf="_cs.isChecked === 'right'"></scope>
            <shortcuts></shortcuts>
            <notifications  *ngIf="_us._user_roles.indexOf('admin') > -1"></notifications>
            <user></user>
        </div>
    </div>
    <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full bg-default">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
             <div class="flex flex-row items-center gap-1 text-gray-400 px-2 py-2 bg-card mb-1  shadow" *ngIf="currentPath && currentPath.length">
            <i (click)="redirectPage('/dashboard')" class="fas fa-home cursor-pointer hover:text-primary"></i>
            <span (click)="redirectPage('/metrics')" class="font-semibold uppercase hover:text-primary ng-star-inserted rounded border cursor-pointer text-md bg-green-100 px-1
             dark:bg-gray-800 dark:text-white py-0.5 text-green-900">
                <ng-container *ngIf="_cs.currentScope === '*'">GLOBAL</ng-container>
                <ng-container *ngIf="_cs.currentScope !== '*'">{{_cs.currentScope.name}}</ng-container>
            </span>
            <span *ngIf="!currentPath.includes('metrics') && !currentPath.includes('overview')">
                <span *ngFor="let s of currentPath; let last = last">
                    {{navHash[s]|uppercase}}
                    <i *ngIf="!last" class="ml-1 fas fa-chevron-right"></i>
                </span>
            </span>

        </div>
        <router-outlet *ngIf="_bs.user().email && !_cs.isComLoading" class="w-full">

        </router-outlet>
        <app-skeleton *ngIf="_cs.isComLoading">

        </app-skeleton>

    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">{{_bs.whitelabel().terms_of_service | ellipsis:50}}  &copy; {{currentYear}}</span>
    </div>

</div>
