<div class="flex flex-col flex-auto pt-4 -mx-4" *ngIf="showCom">
    <mat-form-field class="mini-form-field fuse-mat-no-subscript fuse-mat-rounded mb-4 checkcompanyfield"
                    [ngClass]="{'checkcompanyfieldWid': layouts.indexOf(cs.checkLayout) > -1 }">
        <mat-select #companySelect id="sSearchCompanyInput" class="mat-small w-full round-select" placeholder=""
            (openedChange)="closeCurrentCompany($event)" [formControl]="companyCtrl"
            (selectionChange)="updateCurrentCompany($event.value)">
            <mat-option>
                <ngx-mat-select-search [formControl]="companyFilterCtrl" [placeholderLabel]="searchTxt"
                    [noEntriesFoundLabel]="'No matching company found'" [searching]="searching"
                    [enableClearOnEscapePressed]="true">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option class="text-medium font-bold" [value]="'*'" *ngIf="!cs.is_assessment">Global</mat-option>
            <!--*ngIf="_us._user_roles.indexOf('admin') !== -1 || _us._user_roles.indexOf('itadmin') !== -1"-->
            <mat-option [matTooltip]="company.name" [matTooltipPosition]="'left'"
                *ngFor="let company of companies | search: companyFilterCtrl.value" [id]="company.name"
                [value]="company.id">
                <span> {{company.name }}</span>
                <span *ngIf="company.score"> - </span>
                <span *ngIf="company.score" matTooltip="Avg. Vulnerability Score"
                    class="mat-small scritical-line nbadge absolute right-0"> {{company.score| number:'1.0-0'}}</span>
                <span class="text-xs ml-auto absolute right-0 ml- text-gray-500"
                    *ngIf="!company.score && showTimeAgo && false">
                    {{company.created + 'Z' | timeAgo}} </span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
