<ng-container *transloco="let t">
    <div class="w-full mt-4 ">
        <ng-container *ngIf="aView === 'agent'">
            <div class="px-4">
                <fuse-alert [appearance]="'border'" [type]="'info'">
                    Initially the agent will be a <em>Lightweight</em> agent. Once the <b>Credentials</b> or <b>Discovery subnets</b> are mapped under the Discovery Settings/Credentials Mapping, the agent will be converted into a <em>Probe. </em>
                    The process will be completed within a maximum of 15 minutes.
                </fuse-alert>
            </div>
            <mat-tab-group class="px-4 mt-4 stab-normal" mat-stretch-tabs="false" mat-align-tabs="start"
                [selectedIndex]="selectedIndex" (selectedIndexChange)="tabFn($event)">
                <mat-tab class="py-1" label="LightWeight Agents">
                    <ng-template mat-tab-label>
                        <i class="mx-2 fas fa-desktop"></i><span id="LightWeight Agents">LightWeight Agents</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <stable
                            *ngIf="selectedIndex === 0 && selectedIndex !== null && agenTableOptions && agenTableOptions.tableOptions"
                            aria-label="Agents Table" role="table" (addCallback)="newAgentCall()"
                            [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)">
                        </stable>
                    </ng-template>
                </mat-tab>
                <mat-tab class="py-1" label="Probe Agents">
                    <ng-template mat-tab-label>
                        <i class="mx-2 fas fa-desktop"></i><span id="Probe Agents">Probe Agents</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <stable
                            *ngIf="selectedIndex === 1 && selectedIndex !== null && agenTableOptions && agenTableOptions.tableOptions"
                            aria-label="Agents Table" role="table" (addCallback)="newAgentCall()"
                            [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)">
                        </stable>
                    </ng-template>
                </mat-tab>
                <mat-tab class="py-1" label="Deprecated Agents">
                    <ng-template mat-tab-label>
                        <i class="mx-2 fas fa-desktop"></i><span id="Deprecated Agents">Deprecated Agents</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <stable
                            *ngIf="selectedIndex === 2 && selectedIndex !== null && agenTableOptions && agenTableOptions.tableOptions"
                            aria-label="Agents Table" role="table" (addCallback)="newAgentCall()"
                            [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)">
                        </stable>
                    </ng-template>
                </mat-tab>
                <mat-tab class="py-1" label="Retired Agents">
                    <ng-template mat-tab-label>
                        <i class="mx-2 fas fa-desktop"></i><span id="Retired Agents">Retired Agents</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <stable
                            *ngIf="selectedIndex === 3 && selectedIndex !== null && agenTableOptions && agenTableOptions.tableOptions"
                            aria-label="Agents Table" role="table" (addCallback)="newAgentCall()"
                            [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)">
                        </stable>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <install-probes-agents *ngIf="aView === 'download_agent'"
            (closeCallback)="closeCallBack($event)"></install-probes-agents>
    </div>
    <s-modal *ngIf="!isBulkMapping"  id="mapdiscoveryandcreds">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">MAPPING</p>
                <p class="mb-4 text-xl font-bold md:text-xl md:leading-snug">Map Agent<br> to Discovery
                    Settings/Credentials here,</p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        Mapping Agent to customized Discovery Settings enhances vulnerability scanning efficiency and
                        network security.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapdiscoveryandcreds');">
                    <mat-icon>close</mat-icon>
                </button>
                <div class="flex flex-row flex-wrap items-center w-full">
                    <div class="flex-wrap w-full">
                        <h2 class="mb-3 ml-3 text-xl font-bold"> Discovery Settings/Credentials Mapping </h2>
                        <fuse-alert *ngIf="!isBulkMapping" class="w-full mb-2" [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>Notification!</span>
                            Map Agent {{currentData.host_name}} with IP {{currentData.ip}} to Discovery
                            Settings/Credentials.
                        </fuse-alert>
                        <!-- <fuse-alert *ngIf="isBulkMapping" class="w-full mb-2" [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>Bulk Mapping</span>
                            Map Agent <br>
                            <ng-container *ngFor="let cData of selectedData">
                                <span class="text-sm font-light">{{cData.host_name}} with IP {{cData.ip}}</span><br>
                            </ng-container>
                            to Discovery Settings/Credentials.
                        </fuse-alert> -->
                        <!--<fuse-alert *ngIf="isBulkMapping" class="w-full mb-2" [appearance]="'border'" [type]="'warn'">
                            <span fuseAlertTitle>Note:</span>
                            <span class="text-sm font-light">Current selection will replace existing Discovery Settings/Credentials.</span>
                        </fuse-alert>-->
                        <app-dynamic-form *ngIf="showPo && agentdisco.formElements"
                            [listOfFormElements]="agentdisco.formElements" [Valuesoutput]="agentdiscoverymap"
                            (saveCallBack)="agentdiscosave($event)" [isSaveBtn]="false" [isCloseBtn]="false"
                            (cancelCallBack)="modalService.close('mapdiscoveryandcreds');"
                            [fnBtnName]="agentdisco.saveBtn"></app-dynamic-form>
                        <div class="flex items-center justify-end p-2 mt-4 bg-transparent border-t">
                            <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                                (click)="modalService.close('mapdiscoveryandcreds');">
                                Cancel
                            </button>
                            <!-- Save -->
                            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick
                                [matTooltip]="'Save'" (click)="agentDisCredSave()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </s-modal>

    <s-modal *ngIf="isBulkMapping"  id="mapdiscoveryandcreds">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">MAPPING</p>
                <p class="mb-4 text-xl font-bold md:text-xl md:leading-snug">Map Agent<br> to Discovery
                    Settings/Credentials here,</p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        Mapping Agent to customized Discovery Settings enhances vulnerability scanning efficiency and
                        network security.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapdiscoveryandcreds');">
                    <mat-icon>close</mat-icon>
                </button>
                <div class="flex flex-row flex-wrap items-center w-full">
                    <div class="flex-wrap w-full">
                        <h2 class="mb-3 ml-3 text-xl font-bold"> Discovery Settings/Credentials Mapping </h2>
                        <!-- <fuse-alert *ngIf="!isBulkMapping" class="w-full mb-2" [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>Notification!</span>
                            Map Agent {{currentData.host_name}} with IP {{currentData.ip}} to Discovery
                            Settings/Credentials.
                        </fuse-alert> -->
                        <fuse-alert *ngIf="isBulkMapping" class="w-full mb-2" [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>Bulk Mapping</span>
                            Map Agent <br>
                            <ng-container *ngFor="let cData of selectedData">
                                <span class="text-sm font-light">{{cData.host_name}} with IP {{cData.ip}}</span><br>
                            </ng-container>
                            to Discovery Settings/Credentials.
                        </fuse-alert>
                        <!--<fuse-alert *ngIf="isBulkMapping" class="w-full mb-2" [appearance]="'border'" [type]="'warn'">
                            <span fuseAlertTitle>Note:</span>
                            <span class="text-sm font-light">Current selection will replace existing Discovery Settings/Credentials.</span>
                        </fuse-alert>-->
                        <app-dynamic-form *ngIf="showPo && agentdisco.formElements"
                            [listOfFormElements]="agentdisco.formElements" [Valuesoutput]="agentdiscoverymap"
                            (saveCallBack)="agentdiscosave($event)" [isSaveBtn]="false" [isCloseBtn]="false"
                            (cancelCallBack)="modalService.close('mapdiscoveryandcreds');"
                            [fnBtnName]="agentdisco.saveBtn"></app-dynamic-form>
                        <div class="flex items-center justify-end p-2 mt-4 bg-transparent border-t">
                            <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                                (click)="modalService.close('mapdiscoveryandcreds');">
                                Cancel
                            </button>
                            <!-- Save -->
                            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick
                                [matTooltip]="'Save'" (click)="agentDisCredSave()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </s-modal>

    <s-modal id="mapdiscovery">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[50vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">MAPPING</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug">Map Agent<br> to Discovery
                    Settings/Credentials here, </p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        Mapping Agent to customized Discovery Settings enhances vulnerability scanning efficiency and
                        network security.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[50vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapdiscovery');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold">Discovery Settings Mapping</h2>
                <fuse-alert class="w-full px-4 py-8" [appearance]="'border'" [type]="'info'">
                    <span fuseAlertTitle>Notification!</span>
                    Map agent {{currentData.host_name}} with ip {{currentData.ip}} to discovery settings.
                </fuse-alert>
                <app-dynamic-form *ngIf="agentdisco.formElements" [listOfFormElements]="agentdisco.formElements"
                    [Valuesoutput]="agentdiscoverymap" (saveCallBack)="agentdiscosave($event)"
                    [closeBtnName]="agentdisco.closeBtn" (cancelCallBack)="modalService.close('mapdiscovery');"
                    [fnBtnName]="agentdisco.saveBtn"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="mapcredentials">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[50vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">MAPPING</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Map host<br> to credential mapping here,
                </p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        Mapping host/IP addresses to credentials for vulnerability scanning enables accurate and secure
                        assessment of each target by providing the necessary authentication information.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[50vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapcredentials');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold">Credential Mapping </h2>
                <fuse-alert class="w-full px-4 py-8" [appearance]="'border'" [type]="'info'">
                    <span fuseAlertTitle>Notification!</span>
                    Map agent {{currentData.host_name}} with ip {{currentData.ip}} to credentials.
                </fuse-alert>
                <app-dynamic-form *ngIf="agentcred.formElements" [listOfFormElements]="agentcred.formElements"
                    [Valuesoutput]="agentcredentialsymap" (saveCallBack)="agentcredsave($event)"
                    [closeBtnName]="agentcred.closeBtn" (cancelCallBack)="modalService.close('mapcredentials');"
                    [fnBtnName]="agentcred.saveBtn"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="scanagents">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[60vh] max-w-md rounded-l-3xl bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4"
                fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">Scan</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Scan agent <br> here, </p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        To perform an agent scan, you can choose from various scan types, such as a full scan or an AD
                        scan.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[60vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('scanagents');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold"> Scan Agent</h2>
                <fuse-alert class="mb-2" [appearance]="'border'" [type]="'info'"
                    *ngIf="scanassetoutput.scan_type === 'COMPLIANCE'">
                    To set up compliance scan preferences, click on the settings icon, choose the compliance scan tab,
                    select the desired compliance types, save your settings, and then initiate the scan.
                </fuse-alert>
                <app-dynamic-form *ngIf="scanasset.formElements && false" [listOfFormElements]="scanasset.formElements"
                    [Valuesoutput]="scanassetoutput" (saveCallBack)="scanadd($event)"
                    [closeBtnName]="scanasset.closeBtn" (cancelCallBack)="modalService.close('scanagents');"
                    [fnBtnName]="'Scan'"></app-dynamic-form>
                <ng-container>
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Select Scan Type</mat-label>
                        <mat-select name="SelectScanType" required [(ngModel)]="scanassetoutput.scan_type"
                            (selectionChange)="scanTypeChange($event.value)">
                            <ng-container *ngFor="let data of scan_type_options">
                                <mat-option [value]="data.label">{{data.field}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="scanassetoutput.scan_type === 'VALIDATECREDS'">
                        <mat-form-field class="w-full mt-4" [subscriptSizing]="'dynamic'">
                            <mat-label>Select Credential</mat-label>
                            <mat-select name="restConfig" required [(ngModel)]="scanassetoutput.credential">
                                <ng-container *ngFor="let data of credential_list">
                                    <mat-option [value]="data.id">{{data.name}} </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="w-full mt-4" [subscriptSizing]="'dynamic'">
                            <mat-label>Enter Device IP For Credential Validation</mat-label>
                            <input matInput required [placeholder]="'Enter comma seperated IPs'" name="target_ip"
                                name="target_ip" [(ngModel)]="scanassetoutput.target_ip">
                            <mat-hint>Example: 192.168.0.0,192.168.0.1</mat-hint>
                        </mat-form-field>
                    </ng-container>
                    <div class="flex items-center py-4 pl-1 pr-4 mt-4 border-t dark:bg-transparent">
                        <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                            (click)="modalService.close('scanagents');">
                            Cancel
                        </button>
                        <button class="ml-2" mat-flat-button [color]="'primary'"
                            *ngIf="scanassetoutput.scan_type !== 'VALIDATECREDS'" appPreventMultiClick
                            [matTooltip]="'Scan'" (click)="scanadd()">
                            Scan
                        </button>
                        <button class="ml-2" mat-flat-button [color]="'primary'"
                            *ngIf="scanassetoutput.scan_type === 'VALIDATECREDS'" appPreventMultiClick
                            [matTooltip]="'Validate'" (click)="scanadd()">
                            Validate
                        </button>
                    </div>
                </ng-container>

            </div>
        </div>
    </s-modal>
    <s-modal id="scanagentsMul">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[45vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">Scan</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug">Scan agent <br>here,</p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        To perform an agent scan, you can choose from various scan types, such as a full scan or an AD
                        scan.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[45vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('scanagentsMul');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold">Scan Agent</h2>
                <app-dynamic-form *ngIf="scanasset.formElements" [listOfFormElements]="scanasset.formElements"
                    [Valuesoutput]="scanassetoutput" (saveCallBack)="scanaddMul($event)"
                    [closeBtnName]="scanasset.closeBtn" (cancelCallBack)="modalService.close('scanagentsMul');"
                    [fnBtnName]="'Scan'"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="resetagent">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[55vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">Reset Agent Configuration</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Reset configuration <br>here,</p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        To reset an agent configuration, you can choose from various configurations.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[55vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('resetagent');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mt-4 mb-4 text-2xl font-bold">Reset Agent Configuration</h2>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Choose Reset Configuration</mat-label>
                    <mat-select name="restConfig" required [(ngModel)]="reset_config">
                        <ng-container *ngFor="let data of configTypes">
                            <mat-option [value]="data.value">{{data.key}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="flex items-center py-4 pl-1 pr-4 mt-4 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('resetagent');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button type="button" [disabled]="reset_config.length === 0" [color]="'primary'"
                        appPreventMultiClick [matTooltip]="'Save'" (click)="resetConfig()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </s-modal>

    <s-modal id="runCommand">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[55vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">Run Osquery Command</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Run a osquery command <br>here,</p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        Running an Osquery command involves executing queries on endpoints to retrieve data, providing
                        insights into system activity, configuration, and security.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[55vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('runCommand');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mt-4 mb-4 text-2xl font-bold">Run Osquery Command</h2>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Enter Command</mat-label>
                    <input matInput [(ngModel)]="osqueryCommand" type="text" required
                        placeholder="SELECT * FROM <table_name> WHERE <conditions>">
                </mat-form-field>
                <div class="flex items-center py-4 pl-1 pr-4 mt-4 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('runCommand');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button type="button" [disabled]="osqueryCommand.length < 16" [color]="'primary'"
                        appPreventMultiClick [matTooltip]="'Save'" (click)="runCommand()">
                        Run Command
                    </button>
                </div>
            </div>
        </div>
    </s-modal>

    <s-modal id="bulkresetagent">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[55vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">Reset Agent Configuration</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Reset configuration <br>here,</p>
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        To reset an agent configuration, you can choose from various configurations.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[55vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('bulkresetagent');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mt-4 mb-4 text-2xl font-bold">Reset Agent Configuration</h2>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Choose Reset Configuration</mat-label>
                    <mat-select name="restConfig" required [(ngModel)]="reset_config">
                        <ng-container *ngFor="let data of configTypes">
                            <mat-option [value]="data.value">{{data.key}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="flex items-center py-4 pl-1 pr-4 mt-4 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('bulkresetagent');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button type="button" [disabled]="reset_config.length === 0" [color]="'primary'"
                        appPreventMultiClick [matTooltip]="'Save'" (click)="bulkresetConfig()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </s-modal>

    <s-modal id="agentMigration">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[55vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="my-2 mb-10 font-bold tracking-wider">Agent Migration</p>
                <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px" class="mt-10">
            </div>
            <div class="w-2/3 h-[55vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <div class="flex flex-row items-center">
                    <div class="text-2xl font-semibold tracking-tight">Agent Migration</div>
                    <button class="ml-auto" mat-icon-button (click)="modalService.close('agentMigration');">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <mat-divider class="my-4"></mat-divider>
                <div class="my-2 font-semibold tracking-tight text-lg">Source Company: {{cs.currentScope.name}}</div>
                <fuse-alert [appearance]="'border'" [type]="'info'">
                    Note: Online Agents only will be migrated.
                </fuse-alert>
                <mat-form-field class="w-full mt-4" [subscriptSizing]="'dynamic'">
                    <mat-label>Select Destination Company</mat-label>
                    <mat-select name="Company" id="IncCmp" required [(ngModel)]="agentMigrationParam.dest_company_id">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="companyFilterCtrl"
                                [placeholderLabel]="'Search Company'"
                                [noEntriesFoundLabel]="'No matching company found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="company.name"
                            *ngFor="let company of allComp | search: companyFilterCtrl.value" [value]="company.id">
                            {{company.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="flex items-center py-4 pl-1 pr-4 mt-4 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('agentMigration');">
                        Cancel
                    </button>
                    <button class="ml-2" type="button" mat-flat-button [disabled]="!agentMigrationParam.dest_company_id"
                        [color]="'primary'" appPreventMultiClick [matTooltip]="'Migrate'" (click)="agentMigration()">
                        Migrate
                    </button>
                </div>
            </div>
        </div>
    </s-modal>

    <s-modal id="dependencyStatus">
        <div class="flex flex-col w-[50rem] max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-1/3 h-[55vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">Dependency Status</p>
                <!-- <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Reset configuration <br>here,</p> -->
                <div class="p-3 bg-blue-600/80 rounded-2xl">
                    <p class="mb-3 text-sm text-gray-200">
                        You can view an agent's Dependency Status and NATS Communication Status here.
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[55vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('dependencyStatus');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mt-4 mb-4 text-2xl font-bold">Dependency Status</h2>
                <table class="table-auto" *ngIf="dependencyStatus">
                    <tbody class="text-sm font-light">
                        <tr *ngFor="let key of getStatusKeys()" class="border-b">
                            <td class="py-1 px-4 text-left">{{ key }}</td>
                            <td class="py-1 px-4 text-left font-light">
                                <img *ngIf="dependencyStatus.status[key]" src="/assets/images/auditLogins/tick.svg"
                                    class="w-5" alt="Tick icon">
                                <img *ngIf="!dependencyStatus.status[key]" src="/assets/images/auditLogins/cross.svg"
                                    class="w-5" alt="Cross icon">
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2 class="mt-4 mb-4 text-2xl font-bold">NATS Status</h2>
                <table class="table-auto" *ngIf="dependencyStatus">
                    <tbody class="text-sm font-light ">

                        <tr class="border-b">
                            <td class="py-1 px-4 text-left">Communication Status</td>
                            <td class="py-1 px-4 text-left font-light">
                                <!-- <i class="fas fa-check text-green-400 pr-2"></i> -->
                                <img src="/assets/images/auditLogins/tick.svg" class="w-5" alt="Tickicon"
                                    *ngIf="dependencyStatus.nats_communication_status === true">
                                <img src="/assets/images/auditLogins/cross.svg" class="w-5" alt="Tickicon"
                                    *ngIf="dependencyStatus.nats_communication_status === false">
                            </td>
                        </tr>
                        <tr class="border-b">
                            <td class="py-1 px-4 text-left">Error Message</td>
                            <td class="py-1 px-4 text-left font-light">{{dependencyStatus.nats_error_message}}</td>
                        </tr>
                        <tr class="border-b" *ngIf="dependencyStatus.npcap_status">
                            <td class="py-1 px-4 text-left">Npcap Status</td>
                            <td class="py-1 px-4 text-left font-light">{{dependencyStatus.npcap_status}}</td>
                        </tr>
                        <tr class="border-b">
                            <td class="py-1 px-4 text-left">Status</td>
                            <td class="py-1 px-4 text-left font-light">
                                <ng-container *ngIf="dependencyStatus.reason">
                                    <ng-container *ngIf="dependencyStatus.reason.includes(',')">
                                        <div *ngFor="let reason of dependencyStatus.reason.split(','); let i = index">
                                            {{ reason.trim() }}
                                            <br *ngIf="i !== dependencyStatus.reason.split(',').length - 1">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!dependencyStatus.reason.includes(',')">
                                        {{ dependencyStatus.reason }}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <!-- <div class="flex items-center py-4 pl-1 pr-4 mt-4 dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('dependencyStatus');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [disabled]="reset_config.length === 0" [color]="'primary'"
                        appPreventMultiClick [matTooltip]="'Save'" (click)="resetConfig()">
                        Save
                    </button>
                </div> -->
            </div>
        </div>
    </s-modal>
    <s-modal id="agentPerformanceManagement">
        <div class="flex flex-col w-full max-w-lg mx-auto md:max-w-none md:flex-row">
            <div class="w-[600px] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute z-10 right-2 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('agentPerformanceManagement');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mt-4 mb-4 text-2xl font-bold">Performance Management</h2>
                <mat-divider class="my-2"></mat-divider>
                <div class="flex flex-col w-full">
                        <mat-form-field floatLabel="always" class="mb-4 sForm w-3/4">
                            <mat-label>Set NMAP Performance Count</mat-label>
                            <input type="number" matInput name="count" [(ngModel)]="_performance_count.nmap" />
                        </mat-form-field>
                        <mat-form-field floatLabel="always" class="mb-4 sForm w-3/4">
                            <mat-label>Set SMB Performance Count</mat-label>
                            <input type="number" matInput name="count" [(ngModel)]="_performance_count.smb" />
                        </mat-form-field>
                    </div>
                <div class="flex items-center py-4 pl-1 pr-4 mt-4 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('agentPerformanceManagement');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button type="button" [color]="'primary'"
                        appPreventMultiClick [matTooltip]="'Save'" (click)="savePerformanceCount()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </s-modal>
</ng-container>