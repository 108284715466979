import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { BaseRequestService } from "../../../_services/base.service";
import { LoaderService } from "../../../_services/loader.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { LoaderComponent } from "../loader/loader.component";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { Subject, takeUntil } from 'rxjs';
import { NoDataComponent } from '../no-data/no-data.component';

@Component({
  selector: 'app-certificates',
  standalone: true,
    imports: [CommonModule, LoaderComponent, AppFilterPipeModule, NgOptimizedImage,NoDataComponent],
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit, OnDestroy {
  @Input() asset: any;
  @Input() showNoData: any;
  certificates: any;
  notAllowedKeys: any = ['asset_id', 'asset_type', 'cert_info',
    'company_id', 'company_name', 'created', 'id', 'host_name', 'ip', 'port', 'tenantid', 'updated']
  objectKeys = Object.keys;
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getCertificates();
  }

  decodeHtmlEntities(encodedString: string): string {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = encodedString;
    return textarea.value;
  }

  getCertificates(): void {
    if (!this.asset) {
      setTimeout(() => { this.getCertificates(); }, 2000);
      return;
    }
    this.ls.display(true);
    this._bs.doRequest(`/r/report_queries/cert_info_view`, 'get', null,
      { condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`, skip: 0, limit: 1000, order_by: 'host_name asc' })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.certificates = res.data;
            this.loadingCon.loading = null; this.loadingCon.nodata = null;
          } else {
            this.certificates = [];
              this.loadingCon.loading = null; this.loadingCon.nodata = true;
          }
        } else {
          if (res && !res.length) {
              this.certificates = res;
              this.loadingCon.loading = null; this.loadingCon.nodata = true;
          } else {
              const data = (res.message) ? res.message : res.data;
              this.toast.sToast('error', data);
          }
        }
      });
  }
}
