import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "../../../shared/table/table.component";
import { Subject, Subscription, takeUntil } from "rxjs";
import { CommonService } from "../../../../_services/common.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { UserService } from "../../../../core/user/user.service";
import { ModalService } from "../../../../_services/modal.service";
import { LoaderService } from "../../../../_services/loader.service";
import { FormsModule, NgForm } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DynamicFormComponent } from "../../../shared/forms/dynamic-form/dynamic-form.component";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ModalComponent } from "../../../shared/modal.component";
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from "app/_services/toastr.service";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { MaterialModule } from "app/material.module";
import { DirectivesModule } from "app/-directives/-directives.module";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";
import { FuseAlertComponent } from "@fuse/components/alert";

@Component({
    selector: "credentials",
    standalone: true,
    imports: [
        CommonModule,
        DirectivesModule,
        TableComponent,
        MaterialModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        DynamicFormComponent,
        MatCardModule,
        MatDividerModule,
        MatTooltipModule,
        ModalComponent,
        FuseScrollbarDirective,
        AppFilterPipeModule,
        FuseAlertComponent
    ],
    templateUrl: "./credentials.component.html",
    styleUrls: ["./credentials.component.scss"],
})
export class CredentialsComponent implements OnInit, OnDestroy {
    @ViewChild("detailView", { static: true }) detailView: ElementRef;
    @ViewChild("credNgForm") credNgForm: NgForm;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    credTableOptions: any = {};
    loadtable: boolean = false;
    filterFirewallDetails: any = [];
    firewallFilter: any = {};
    Objectkeys = Object.keys;
    private subs: Subscription;
    fireWallDetails: any = {};
    cred: any = {};
    tempPassword: string = '';
    ipType: any = [
        { key: "Static IP", value: "STATICIP" },
        { key: "CIDR", value: "CIDR" },
        { key: "Domain", value: "DOMAIN" },
        { key: "IP Range", value: "IPRANGE" },
    ];
    osType: any = [
        { key: "Windows", value: "WINDOWS" },
        { key: "MacOS", value: "MAC" },
        { key: "Linux", value: "LINUX" },
        { key: "VMware", value: "VMWARE" },
        { key: "SNMP Credentials", value: "NETWORKDEVICE" },
        { key: "Firewall OS", value: "FIREWALLOS" },
    ];
    credentialType: any = [
        { key: "AD", value: "AD", allowOS: ["WINDOWS"] },
        {
            key: "Asset Credential", value: "ASSETCREDENTIAL",
            allowOS: [
                "WINDOWS",
                "MAC",
                "LINUX",
                "VMWARE"
            ],
        },
        { key: "SNMP V1", value: "SNMPV1", allowOS: ["NETWORKDEVICE"] },
        { key: "SNMP V2", value: "SNMPV2", allowOS: ["NETWORKDEVICE"] },
        { key: "SNMP V3", value: "SNMPV3", allowOS: ["NETWORKDEVICE"] },
        {
            key: "Firewall Credential",
            value: "FIREWALLCREDENTIAL",
            allowOS: ["FIREWALLOS"],
        },
    ];
    authProtocol: any = [
        {
            value: "",
            label: "No Protocol",
        },
        {
            value: "md5",
            label: "MD5",
        },
        {
            value: "sha",
            label: "SHA",
        },
    ];
    privacyProtocol: any = [
        {
            value: "",
            label: "No Protocol",
        },
        {
            value: "aes128",
            label: "AES128",
        },
        {
            value: "aes256",
            label: "AES256",
        },
        {
            value: "des",
            label: "DES",
        },
    ];
    currentcred: any = {};
    disableSubmit = false;

    constructor(
        public cs: CommonService,
        private _bs: BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef,
        public confirmDialog: FuseConfirmationService,
        public toast: MyToastrService,
        private uS: UserService,
        public modalService: ModalService,
        private loaderService: LoaderService
    ) {
        this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
            this.cred.companyId = this.cs.currentScope.id;
            this._changeDetectorRef.markForCheck();
        });
        this.cs.newCredentialCall
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((trigger: any) => {
            this.modalService.open("newCredential");
            if (this.detailView && this.detailView.nativeElement) {
                this.detailView.nativeElement.scrollTo(0, 0);
            }
            this._changeDetectorRef.detectChanges();
        });
        this.cs.formValidationEVE
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (res: any) => {
              this.disableSubmit = res;
            }
          );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.initialTable();
        this.uS.userd$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                this.cred.companyId = this.cs.currentScope.id;
                let s = Object.keys(user["urn:zitadel:iam:org:project:roles"]);
                let tenantid = Object.keys(
                    user["urn:zitadel:iam:org:project:roles"][s[0]]
                )[0];
                this.cred.tenantId = tenantid;
                this._changeDetectorRef.markForCheck();
            });
    }

    initialTable(): void {
        this.credTableOptions = {};
        this._changeDetectorRef.detectChanges();
        this.credTableOptions = {
            columns: [
                {
                    header: "Name",
                    columnDef: "name",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => ${element.name}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "OS Type",
                    columnDef: "os_type",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => ${element.os_type}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Credential Type",
                    columnDef: "credential_type",
                    cType: "string",
                    filter: "",
                    cell: "(element: any) => ${element.credential_type}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    "header": "Is Valid", align: "center", "columnDef": "is_valid", "filter": "", "cell": "(element: any) => ${element.is_valid}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false, statusicon: true,
                    success: true,
                    failure: false,
                    successIconPath: '/assets/images/auditLogins/tick.svg',
                    successToolTip: 'Valid',
                    failureIconPath: '/assets/images/auditLogins/cross.svg',
                    failureToolTip: 'Invalid',
                    alertIconPath: '/assets/images/devices/not_valid.svg', 
                    alertToolTip: 'Not Validated'
                }, 
                {
                    header: "Company ID",
                    columnDef: "company_id",
                    cType: "number",
                    filter: "",
                    cell: "(element: any) => ${element.company_id}",
                    order: 0,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
            ],
            sortOptions: { active: "updated", direction: "desc" },
            _pageData: [],
            tableOptions: {
                id: "credentials",
                title: "Credentials",
                isServerSide: false,
                selectText: "credential",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: true,
                actionMenuItems: [
                    {
                        text: "Edit",
                        id: "edit",
                        icon: "edit",
                        callback: "editFunction",
                        hideLocal: false,
                        isGlobal: false,
                    },
                    {
                        text: "Delete",
                        id: "delete",
                        icon: "delete",
                        callback: "editFunction",
                        hideLocal: false,
                        isGlobal: false,
                    },
                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                showFilter: true,
                showTagFilter: false,
                add: true,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: "/r/company/asset_credentials_view",
                    dUrl: "/d/company/credentials",
                    condition: "asset_id is null",
                },
            },
            changeValue: new Subject<any>(),
        };
        this._changeDetectorRef.detectChanges();
    }

    saveCred(): void {
        const $event = this.cred;
        if ($event.credential_type === 'ASSETCREDENTIAL' && $event.os_type === 'NETWORKDEVICE') {
            delete $event.port;
        }
        let paramsList = { ...$event, ...this.firewallFilter };
        if (paramsList.password === '*#560104#*' || paramsList.password === '' || paramsList.password === null) {
            delete paramsList.password;
            delete $event.password;
            // paramsList.password = null;
            // $event.password = null;
        }
        const reqData: any = {
            data: {
                credential_type: this.cred.credential_type,
                os_type: this.cred.os_type,
                address: this.cred.address,
                address_type: this.cred.address_type,
                name: this.cred.name,
                params: (this.firewallFilter) ? paramsList : $event,
                is_valid: null
            },
        };
        const reqParams: any = this.cs.replaceEmptyWithNull(reqData);
        if ($event && $event !== "") {
            reqData.data.company_id = parseInt(this.cs.currentScope.id);
            var api = "/w/company/credentials";
            var method = "post";
            if (this.currentcred && this.currentcred.id) {
                method = "patch";
                api = "/w/company/update_credentials";
                reqData.id = this.currentcred.id;
            }
            const reqParams: any = this.cs.replaceEmptyWithNull(reqData);
            this._bs
                .doRequest(api, method, reqParams)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        try {
                            this.currentcred = {};
                            this.filterFirewallDetails = [];
                            this.fireWallDetails = {};
                            const msg = result.id
                                ? "Credential added successfully"
                                : "Credential updated successfully";
                            this.toast.sToast("success", msg);
                            this.cancel();
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        const data = result.message ? result.message : result.data;
                        this.toast.sToast("error", data);
                    }
                });
        }
    }

    onSelectFirewall(data: any) {
        this.firewallFilter = {};
        this.filterFirewallDetails = this.fireWallDetails[data] ? this.fireWallDetails[data] : [];
    }

    cancel() {
        this.modalService.close("newCredential");
        setTimeout(() => {
            this.initialTable();
        }, 3000);
    }

    addTableData(): void {
        this.getFireWallDetails();
        this.modalService.open("newCredential");
        this.cred = { auth_protocol: "", privacy_protocol: "" };
        this.filterFirewallDetails = [];
        this.firewallFilter = {};
        if (this.detailView && this.detailView.nativeElement) {
            this.detailView.nativeElement.scrollTo(0, 0);
        }
        this._changeDetectorRef.detectChanges();
    }

    getFireWallDetails(params?: any) {
        this.loaderService.display(true);
        this._bs.doRequest(`/r/company/jsonconfigs/firewall`, "get")
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    this.fireWallDetails = res.data;
                    if (params) {
                        this.filterFirewallDetails = this.fireWallDetails[params.firewall_type];
                        this.firewallFilter = { ...this.firewallFilter, ...params };
                    }
                } else {
                    this.toast.sToast("error", res.message);
                }
            });
    }

    credactionCall(data: any) {
        delete data.row.company_id;
        this.currentcred = data.row;
        this.cred = data.row;
        if (data.action.text == "Edit") {
            // this.cred = data.row.params;
            this.cred = {
                ...this.cred.params, 
                name: data.row.name,
                credential_type: data.row.credential_type,
                os_type: data.row.os_type
            };
            this.disableSubmit = true;
            this.cred.password = '*#560104#*';
            if (data.row.params.credential_type !== 'FIREWALLCREDENTIAL') {
                this.getFireWallDetails();
            }
        if (data.row.params.credential_type === 'FIREWALLCREDENTIAL') {
            this.loaderService.display(true);
            this._bs.doRequest(`/r/company/jsonconfigs/firewall`, "get")
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                    this.loaderService.display(false);
                    if (res.status) {
                        this.fireWallDetails = res.data;
                        if (data.row.params) {
                            this.filterFirewallDetails = this.fireWallDetails[data.row.params.firewall_type] || [];
                            this.firewallFilter = { ...this.firewallFilter, ...data.row.params };
                        }
                    } else {
                        this.toast.sToast("error", res.message);
                    }
                    this._changeDetectorRef.detectChanges();
                });
        }
            this.modalService.open("newCredential");
            if (this.detailView && this.detailView.nativeElement) {
                this.detailView.nativeElement.scrollTo(0, 0);
            }
            this._changeDetectorRef.markForCheck();
        } else if (data.action.text == "Delete") {
            this.deletetag(data);
        }
    }

    clearPassword():void{
        this.tempPassword = this.cred.password;
        this.cred.password = '';
        this._changeDetectorRef.detectChanges();
        this._changeDetectorRef.markForCheck();
    }

    restorePassword():void{
        if (this.cred.password === '') {
            this.cred.password = this.tempPassword;
            this._changeDetectorRef.detectChanges();
            this._changeDetectorRef.markForCheck();
        }
    }

    deletetag(data: any) {
        const confirmation = this.confirmDialog.open({
            title: "Confirmation",
            message: "Are you sure you want to delete this Credentials?",
            actions: {
                confirm: {
                    label: "Yes",
                },
            },
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === "confirmed") {
                this._bs
                    .doRequest(`/d/company/credentials/${data.row.id}`, "delete")
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result) {
                            this.toast.sToast("success", "Removed successfully");
                            setTimeout(() => {
                                this.initialTable();
                            }, 3000);
                        } else {
                            const data = result.message ? result.message : result.data;
                            this.toast.sToast("error", data);
                        }
                    });
            }
        });
    }
}
