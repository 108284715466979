import { CommonModule, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { TableComponent } from "../table/table.component";
import { fuseAnimations } from "@fuse/animations";

@Component({
  selector: "app-firewall-rules",
  standalone: true,
  animations: fuseAnimations,
  imports: [CommonModule, TableComponent],
  templateUrl: "./firewall-rules.component.html",
  styleUrls: ["./firewall-rules.component.scss"],
})
export class FirewallRulesComponent {
  @Input() asset: any;
  firewallTableOptions: any = {
    columns: [
      {
        header: "Destination",
        columnDef: "destination",
        cType: "string",
        isHyperlink: false,
        filter: "",
        cell: "(element: any) => ${element.destination}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Action",
        columnDef: "action",
        cType: "string",
        isHyperlink: false,
        filter: "",
        cell: "(element: any) => ${element.action}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Source",
        columnDef: "source",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.source}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
    ],
    sortOptions: { active: "destination", direction: "desc" },
    _pageData: [],
    tableOptions: {
      title: "Firewall Rules",
      isServerSide: false,
      selectText: "firewall",
      loading: true,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showFilter: true,
      showTagFilter: false,
      showhideList: true,
      refreshData: true,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ufw_firewall_rules",
        condition: "",
      },
      id: "details_firewall_rules",
    },
    changeValue: new Subject<any>(),
  };
  ngOnInit(): void {
    this.firewallTableOptions.tableOptions.serverSide.condition = ` asset_id='${this.asset.id}'`;
  }
}
