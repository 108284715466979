import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CommonModule, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ShowMoreLessComponent } from 'app/modules/shared/show-more-less/show-more-less.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TimelineComponent } from 'app/modules/shared/timeline/timeline.component';
import { MaterialModule } from 'app/material.module';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from 'app/_services/modal.service';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Router } from '@angular/router';
import { DynamicSettingsService } from 'app/layout/common/dynamic-settings/dynamic-settings.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'app-report-scheduler-jobs',
  standalone: true,
  imports: [CommonModule,
    ShowMoreLessComponent,
    TranslocoModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    FuseScrollbarDirective,
    NgIf,
    ButtonModule,
    AppFilterPipeModule,
    MaterialModule,
    TableComponent,
    TimelineComponent,],
  templateUrl: './report-scheduler-jobs.component.html',
  styleUrls: ['./report-scheduler-jobs.component.scss']
})
export class ReportSchedulerJobsComponent {

  cView: any = "jobs";
  @ViewChild("drawer") drawer: MatDrawer;
  searchFilter: any = "";
  checkCompleted: boolean = false;
  checkInProcess: boolean = false;
  checkFailed: boolean = false;
  drawerPosition: "start" | "end" = "end";
  drawerMode: "over" | "side" = "over";
  drawerOpened: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  currentData: any = {};
  currentDataCheck: boolean = false;
  reportCurrentDataCheck: boolean = false;
  reportCurrentData: any = [];
  showtable: boolean = false;
  objectKeys = Object.keys;
  scanTableOptions: any = {};
  suppressiontable: any = {};
  tableData: any = {};
  pObj: any = {};
  reportsHash: any = {
    pdf: 'PDF',
    xlsx: 'XLSX',
    docx: 'DOCX',
    pptx: 'PPTX'
  }
  // `type='COMPLIANCE'`

  /**
   * Constructor
   */
  constructor(
    public modalService: ModalService,
    private _bs: BaseRequestService,
    public cs: CommonService,
    private ls: LoaderService,
    private toast: MyToastrService,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ds: DynamicSettingsService,
    public confirmDialog: FuseConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.initTable();
  }
  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }
  initTable(): void {
    this.scanTableOptions = {
      columns: [
        {
          header: "Created",
          columnDef: "created",
          filter: "utcToLocale",
          isHyperlink: true,
          cell: "(element: any) => ${element.created}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Updated",
          columnDef: "updated",
          filter: "utcToLocale",
          isHyperlink: true,
          cell: "(element: any) => ${element.updated}",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Id",
          columnDef: "job_id",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.job_id}`",
          order: 2,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Status",
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          columnDef: "status",
          cType: "agg",
          filter: "",
          cell: "(element: any) => `${element.status}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Description",
          columnDef: "description",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.description}`",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
          isShowMoreData: true
        },
      ],
      sortOptions: { active: "updated", direction: "desc" },
      _pageData: [],
      tableOptions: {
        title: "Report Jobs",
        isServerSide: false,
        selectText: "job(s)",
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        isNoAdd: true,
        isUserRestCheck: true,
        actionMenuItems: [
          {
            text: "Recreate Job",
            callback: "Regenerate Job",
            id: "recreate-job",
            icon: "autorenew",
            target: "recreate-job",
          },
          {
            text: "Download",
            callback: "Download",
            id: "report-download",
            icon: "download",
            target: "report-download",
            isGlobal: true,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        dataMapping: [
          {
            s: "job_details",
            d: "report_name",
            filter: "statstovuls:displayReportName",
          },
          {
            s: "job_details",
            d: "report_type",
            filter: "statstovuls:fileType",
          },
        ],
        serverSide: {
          url: "/r/company/jobs_view",
          condition: `type='ScheduleReportBuilder'`,
        },
      },
      customText: [
        {
          status: "initiated",
          DisplayText: "Initiated",
          class: "bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50",
        },
        {
          status: "inprogress",
          DisplayText: "In Progress",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "in progress",
          DisplayText: "In Progress",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "completed",
          DisplayText: "Completed",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "failed",
          DisplayText: "Failed",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pushedtoqueue",
          DisplayText: "Pushed To Queue",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
        {
          status: "terminated",
          DisplayText: "Terminated",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pdf",
          DisplayText: "PDF",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "xlsx",
          DisplayText: "XLSX",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "docx",
          DisplayText: "DOCX",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "pptx",
          DisplayText: "PPTX",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
      ],
      sColumns: [
        {
          header: 'Job Status', columnDef: 'status', cType: 'string', isDropdown: true, gloNoSearch: true,
          dataSource: [
            { text: 'Initiated', value: 'initiated' },
            { text: 'In Progress', value: 'in progress' },
            { text: 'Completed', value: 'completed' },
            { text: 'Failed', value: 'failed' },
            { text: 'Pushed To Queue', value: 'pushedtoqueue' },
            { text: 'Terminated', value: 'terminated' }
          ],
        },
      ],
      changeValue: new Subject<any>(),
    };
    this._changeDetectorRef.detectChanges();
  }
  trackByFn(index: number, item: any): any {
    return (item && item.id) || index;
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.objectKeys(this.pObj).forEach((obj: any) => {
      if (obj) {
        this.pObj[obj].unsubscribe();
      }
    });

    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  linkClick($event: any) {

    this.ls.display(true);
    this.pObj.jobs = this._bs
      .doRequest(`/r/company/jobs`, "get", null, {
        condition: `id=${$event.row.id}`,
        skip: 0,
        limit: 1,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.viewJobDeatils(res.data[0]);
            this._changeDetectorRef.detectChanges();
          }
        } else {
          const data = res.message ? res.message : res.data;
          this.toast.sToast("error", data);
        }
      });
  }

  viewDetails(job: any): void {
    this.drawerOpened = true;
    this.currentDataCheck = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();

      this.currentData = job;
      this._changeDetectorRef.detectChanges();
    }
  }
  viewJobDeatils(job: any) {
    this.searchFilter = "";
    this.reportCurrentData = job;
    this.reportCurrentDataCheck = true;
    this.drawerOpened = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();
      this.reportCurrentData = job.job_details;
    }
    this._changeDetectorRef.detectChanges();
  }
  toggleChange($event: any): void {
    this.cView = "jobs";
  }
  closeCallBack($event: any): void {
    this.cView = "jobs";
  }
  closeCurrentData() {
    this.currentDataCheck = false;
    this.drawer.toggle();
  }
  closeReportCurrentData() {
    this.reportCurrentDataCheck = false;
    this.reportCurrentData = [];
    this.drawer.toggle();
  }
  generatedReCreateJob(data: any) {
    const reGenCreateConfirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: "Are you sure you want to Re-Create Job these Job?",
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" },
      },
    });
    reGenCreateConfirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const reqData: any = {
          job_id: data[0].job_id,
          isGlobal: (this.cs.currentScope === '*') ? true : false,
        };
        this._bs
          .doRequest(`/report_builder/recreate_report_job`, "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast("success", "Re-Create successfully");
              setTimeout(() => this.cs.selectedTagChange.next({}));
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }
  generatedReportTerminate(data: any) {
    const tableName = this.scanTableOptions.tableOptions.title;
    const reportConfirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: "Are you sure you want to terminate these Job?",
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" },
      },
    });
    reportConfirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const reqData: any = {
          job_id: data.map(
            (obj: any) => obj.job_id
          ),
          isGlobal: (this.cs.currentScope === '*') ? true : false
        };
        this._bs
          .doRequest(`/report_builder/terminate_report_job`, "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast("success", "Terminated successfully");
              setTimeout(() => this.cs.selectedTagChange.next({}));
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }
  generatedDownloadReport(data: any) {
    const titleName = `Confirmation`;
    const message = `Are you sure you want to download the Report?`;
    const cancelText = "No";
    const acceptText = "Yes";
    const confirmation = this.confirmDialog.open({
      title: titleName,
      message: message,
      icon: { show: false, name: "", color: "primary" },
      actions: {
        confirm: { show: true, label: acceptText, color: "primary" },
        cancel: { show: true, label: cancelText },
      },
      dismissible: false,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const id = data.map((obj: any) => obj.job_id)
        const reqData: any = {
          job_id: id,
          company_id: (this.cs.currentScope !== '*') ? parseInt(this.cs.currentScope.id) : undefined,
          isGlobal: (this.cs.currentScope === '*') ? true : false
        };
        this.ls.display(true);
        this._changeDetectorRef.detectChanges();
        this._bs
          .doRequest(`/report_builder/get_report_link`, "get", null, reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.ls.display(false);
            if (res.status) {
              setTimeout(() => this.cs.selectedTagChange.next({}));
              this._changeDetectorRef.detectChanges();
              window.open(res.message, "_blank");
            } else {
              const data = res.message ? res.message : res.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }
  actionCall(data: any) {
    data.row = [data.row];
    this.jobActionCall(data);
  }

  globalActionCall(data: any) {
    this.jobActionCall(data);
  }
  jobActionCall(res: any): void {
    if (res.action.target === "recreate-job") {
      if (res.row && res.row.length) {
        if (res.row.length > 1) {
          this.toast.sToast("info", "Please select only one record.");
        } else {
          const checkFailed = res.row.every((report: any) => report.status === "failed" || report.status === "pushedtoqueue");
          if (checkFailed) {
            this.generatedReCreateJob(res.row);
          } else {
            this.toast.sToast(
              "info",
              'Re-Create can be done only for Failed job.'
            );
          }
        }
      } else {
        this.toast.sToast("info", "Please select at least one record.");
      }
    }
    if (res.action.target === "report-download") {
      if (res.row && res.row.length) {
        if (res.row.length > 10) {
          this.toast.sToast("info", "Please select a maximum of 10 reports only");
        } else {
          const checkCompleted = res.row.every((report: any) => report.status === "completed");
          if (checkCompleted) {
            this.generatedDownloadReport(res.row);
          } else {
            this.toast.sToast(
              "info",
              `Job status: Failed. Report download unavailable at the moment. Please Select Only Completed Reports.`
            );
          }
        }
      } else {
        this.toast.sToast("info", "Please select at least one record.");
      }
    }
  }

}
