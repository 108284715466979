<div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700"
    *ngIf="!domain && !updateIp">
    <div class="flex flex-row items-center"
        *ngIf="customDomainOriginal.cert_details && Objectkeys(customDomainOriginal.cert_details).length">
        <div class="text-2xl font-semibold tracking-tight"></div>
        <button class="ml-auto" mat-icon-button (click)="domain=true;">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <ng-container *ngIf="customDomainOriginal.cert_details">
        <div class="text-2xl font-semibold leading-tight">
            {{customDomainOriginal.configured_domain}}</div>
        <mat-divider class="my-2"></mat-divider>
    </ng-container>
    <mat-form-field class="w-1/2 mt-4 mb-3" *ngIf="!customDomainOriginal.cert_details">
        <mat-label class="text-base">Domain Name</mat-label>
        <input matInput name="Domain" [(ngModel)]="customDomain.domain_name" required [placeholder]="'Domain Name'">
    </mat-form-field>
    <fuse-alert [appearance]="'border'" [type]="'info'" *ngIf="!customDomain.cert_details" class="mb-3">
        <div class="flex flex-col gap-2">
            <div>Please configure DNS (Create A Record) for <span
                    class="font-bold">{{customDomainOriginal.configured_domain ||
                    'Domain Name'}}</span> with IP
                Address <span class="font-bold">{{_cs?.fqdn_ipaddress}}</span>.
            </div>
            <div>How to add A record - Documentation Link</div>
            <div class="flex-auto overflow-y-auto">
                <div class="grid grid-cols-2 sm:grid-cols-4 gap-2">
                    <ng-container *ngFor="let dns of dnsProviders;">
                        <a class="z-20 flex items-center py-1 px-2 cursor-pointer border rounded-lg" [href]="dns.url"
                            target="_blank" matTooltip="How to add A record in {{dns.name}}?">
                            <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                                <img alt="Contact avatar"
                                    class="w-full h-full rounded-full object-cover ng-star-inserted"
                                    src="/assets/images/dns/{{dns.img}}" [alt]="dns.img">
                            </div>
                            <div class="min-w-0 ml-4">
                                <div class="font-medium leading-5 truncate">{{dns.name}}</div>
                            </div>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </fuse-alert>

    <label class="block my-2 text-base font-medium text-gray-900 dark:text-white" for="file_input">SSL
        Certificate</label>
    <input
        class="block w-1/2 text-medium text-gray-900 p-1 mb-3 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        aria-describedby="file_input_help" id="file_input" #file_input type="file" [multiple]="false"
        (change)="uploadDomainFile($event, 'ssl_cert')">

    <label class="block my-2 text-base font-medium text-gray-900 dark:text-white" for="file_input_two">SSL Private
        Key</label>
    <input
        class="block w-1/2 text-medium text-gray-900 p-1 border mb-3 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        aria-describedby="file_input_help" id="file_input_two" #file_input_two type="file" [multiple]="false"
        (change)="uploadDomainFile($event, 'ssl_privkey')">
    <mat-form-field class="w-1/2 my-2" [subscriptSizing]="'dynamic'">
        <mat-label>Allowed IPs</mat-label>
        <mat-chip-grid #chip aria-label="Enter Keywords">
            <mat-chip-row *ngFor="let key of customDomain.allowed_ips" (removed)="remove(key)" [editable]="true"
                (edited)="edit(key, $event)" [aria-description]="'press enter to edit ' + key">
                {{key}}
                <button matChipRemove [attr.aria-label]="'remove ' + key">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="'Enter a IP and press comma or enter to add.'" [matChipInputFor]="chip"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" />
        </mat-chip-grid>
        <mat-hint>Ex. 192.168.0.0,192.168.0.1</mat-hint>
    </mat-form-field>
    <div class="flex items-center justify-end mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
        <button class=" ml-2" mat-flat-button [color]="'primary'" *ngIf="!customDomainOriginal.cert_details"
            [disabled]="!customDomain.domain_name || !customDomain.ssl_cert || !customDomain.ssl_privkey" type="button"
            appPreventMultiClick [matTooltip]="'Upload'" (click)="uploadDomain('upload')">
            Upload
        </button>
        <button class=" ml-2" mat-flat-button [color]="'primary'" *ngIf="customDomainOriginal.cert_details"
            [disabled]="!customDomain.ssl_cert || !customDomain.ssl_privkey" type="button" appPreventMultiClick
            [matTooltip]="'Upload'" (click)="uploadDomain('upload')">
            Upload
        </button>
    </div>

</div>

<div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700"
    *ngIf="customDomainOriginal && customDomainOriginal.configured_domain && domain && !updateIp">

    <div class="text-2xl font-semibold leading-tight">{{customDomainOriginal.configured_domain}}</div>

    <div class="mt-4 flex flex-col" *ngIf="Objectkeys(customDomainOriginal.cert_details).length >0">
        <mat-divider class="my-4"></mat-divider>
        <div class="text-lg font-semibold mb-2 leading-tight">Certificate Details</div>
        <mat-divider class="my-4"></mat-divider>
        <ng-container *ngFor="let k of  Objectkeys(customDomainOriginal.cert_details)">
            <div class="grid grid-cols-4 gap-2 my-1">
                <div class="font-semibold text-secondary" *ngIf="k !== 'valid_upto' && k !== 'serial_number'">
                    {{k|uppercase}}</div>
                <div class="font-semibold text-secondary" *ngIf="k === 'valid_upto'">VALID UPTO</div>
                <div class="font-semibold text-secondary" *ngIf="k === 'serial_number'">SERIAL NUMBER</div>

                <div class="font-semibold text-md text-primary dark:text-white">
                    {{customDomainOriginal.cert_details[k]}} </div>
                <!-- <div class="font-semibold text-md text-primary" *ngIf="k === 'valid_upto'">
                    {{customDomainOriginal.cert_details[k]|epochToDate}}
                    <ng-container *ngIf="(customDomainOriginal.cert_details[k] | dateToDays) > 0">
                        <code> - {{customDomainOriginal.cert_details[k] | dateToDays}} days.</code>
                    </ng-container>
                </div> -->
            </div>
        </ng-container>
    </div>
    <div class="grid grid-cols-4 gap-2 my-1" *ngIf="customDomainOriginal.status">
        <div class="font-semibold text-secondary">Configuration Status</div>
        <div class="text-sm font-light">
            <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                [ngClass]="{
                        'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': customDomainOriginal.status.toLowerCase() === 'success',
                        'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': customDomainOriginal.status.toLowerCase() === 'failed',
                    }">
                <span class="leading-relaxed whitespace-nowrap">{{customDomainOriginal.status}}</span>
            </span>
        </div>
    </div>
    <div class="grid grid-cols-4 gap-2 my-2"
        *ngIf="customDomainOriginal.status && customDomainOriginal.failure_reason && customDomainOriginal.status.toLowerCase() === 'failed'">
        <div class="font-semibold text-secondary">Failure Reason</div>
        <div class="font-semibold text-md text-primary dark:text-white">
            {{customDomainOriginal.failure_reason}}
        </div>
    </div>
    <div class="grid grid-cols-4 gap-2 my-2"
        *ngIf="customDomainOriginal.allowed_ips && customDomainOriginal.allowed_ips.length">
        <div class="font-semibold text-secondary">Allowed IPs</div>
        <div class="col-span-3">
            <div class="grid grid-cols-10 gap-1">
                <ng-container *ngFor="let ip of customDomainOriginal.allowed_ips">
                    <span
                        class="text-sm font-semibold me-2 px-1.5 py-0.5 rounded text-primary border border-primary">{{ip}}</span>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-4 gap-2 mt-2"
        *ngIf="!customDomainOriginal.allowed_ips || !customDomainOriginal.allowed_ips.length">
        <div class="font-semibold text-secondary">Allowed IPs</div>
        <div>
            <span class="text-sm font-semibold me-2 px-1.5 py-0.5 rounded text-primary border border-primary mr-2">All
                IPs</span>
        </div>
    </div>
    <div class="flex items-center justify-end mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Upload'" (click)="domain=false">
            Re-Import Certificate
        </button>
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Upload'" (click)="updateAllowededIp()">
            Update Allowed IPs
        </button>
        <button class="ml-2" mat-flat-button [color]="'warn'" type="button" appPreventMultiClick [matTooltip]="'Upload'"
            (click)="uploadDomain('remove')">
            Remove Domain
        </button>
    </div>
</div>

<div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700"
    *ngIf="customDomainOriginal && customDomainOriginal.configured_domain && updateIp">
    <div class="flex flex-row items-center">
        <div class="text-2xl font-semibold tracking-tight"></div>
        <button class="ml-auto" mat-icon-button (click)="updateIp=false;">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <mat-form-field class="w-1/2 my-2" [subscriptSizing]="'dynamic'">
        <mat-label>Allowed IPs</mat-label>
        <mat-chip-grid #chip aria-label="Enter Keywords">
            <mat-chip-row *ngFor="let key of customDomain.allowed_ips" (removed)="remove(key)" [editable]="true"
                (edited)="edit(key, $event)" [aria-description]="'press enter to edit ' + key">
                {{key}}
                <button matChipRemove [attr.aria-label]="'remove ' + key">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="'Enter a IP and press comma or enter to add.'" [matChipInputFor]="chip"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" />
        </mat-chip-grid>
        <mat-hint>Ex. 192.168.0.0,192.168.0.1</mat-hint>
    </mat-form-field>
    <div class="flex items-center justify-end mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
        <button class=" ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Update'" (click)="uploadDomain('update')">
            Update
        </button>
    </div>
</div>