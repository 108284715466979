import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { MaterialModule } from 'app/material.module';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { ManualTagsPipe, TagsPipe } from 'app/_filters/app.filter.pipe';
import cloneDeep from 'lodash-es/cloneDeep';
import { FormControl, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'app/_services/common.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-asset-tags',
  standalone: true,
  imports: [CommonModule, MaterialModule, FuseScrollbarDirective, ReactiveFormsModule, FormsModule, NgxMatSelectSearchModule],
  templateUrl: './asset-tags.component.html',
  styleUrls: ['./asset-tags.component.scss']
})
export class AssetTagsComponent implements OnInit, OnDestroy {
  @Input() asset: any;
  defaultTags: any = [];
  manualTags: any = [];
  addTags = false;
  addNewTags: any = [];
  selected_tag: any = '';
  public tagFilterCtrl: FormControl = new FormControl();
  public searching = false;
  allTags: any = [];
  filterTags: any = [];
  @ViewChild('tagNgForm') tagNgForm: NgForm;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService, private tagPipe: TagsPipe, private manualTagPipe: ManualTagsPipe, private _cs: CommonService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    // this.defaultTags = this.tagPipe.transform(this.asset.tags);
    // this.manualTags = this.tagPipe.transform(this.asset.manual_tags);
    this.getAssetTags();
  }

  getAssetTags(): void {
    const assetId = (this.asset.id) ? this.asset.id : this.asset.asset_id;
    this.ls.display(true);
    this._bs.doRequest(`/r/report_queries/asset_view`, 'get', null,
      { condition: `id='${assetId}'` })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.asset.tags = res.data[0].tags;
            if (typeof res.data[0].manual_tags === 'string') {
              res.data[0].manual_tags = JSON.parse(res.data[0].manual_tags);
            }
            this.asset.manual_tags = res.data[0].manual_tags;
            const output_array = Object.entries(res.data[0].tags)
              .filter(([key, value]) => Array.isArray(value) && value.length > 0)
              .map(([key, value]) => ({
                name: key,
                value: value,
              }));
            this.defaultTags = output_array;
            if (Object.keys(res.data[0].manual_tags).length === 0) {
              this.manualTags = []
            } else {
              const outputArray = Object.entries(res.data[0].manual_tags).map(([name, value]) => ({ name, value }));
              this.manualTags = outputArray;
            }
            this.addTags = false;
          } else {
            this.defaultTags = []; this.manualTags = [];
            this.addTags = false;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }

  removeManualTags($event: any, index: any): void {
    const clone = cloneDeep(this.asset);
    const reqData: any = { tags: {}, collection: 'assets', docs: [], action: 'edit' };
    delete clone.manual_tags[$event.name]; reqData['tags'] = clone.manual_tags;
    reqData.docs.push({ asset_id: parseInt(this.asset.id), company_id: parseInt(this.asset.company_id) })
    this.ls.display(true);
    this._bs.doRequest(`/w/company/edit_resource_tag`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.toast.sToast('success', 'Removed successfully');
          this.manualTags.splice(index, 1);
          this._cs.assetSelect.emit(parseInt(this.asset.id));
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }

  addManualTag(): void {
    this.ls.display(true);
    this.addNewTags = cloneDeep(this.manualTags);
    this.addTags = true;
    this.ls.display(false);
  }

  saveManualTags(): void {
    const reqData: any = { tags: {}, collection: 'assets', docs: [], action: 'edit' };
    const outputObject = this.addNewTags.reduce((result: any, item: any) => {
      result[item.name] = item.value;
      return result;
    }, {});
    reqData['tags'] = outputObject;
    reqData.docs.push({ asset_id: parseInt(this.asset.id), company_id: parseInt(this.asset.company_id) })
    this.ls.display(true);
    this._bs.doRequest(`/w/company/edit_resource_tag`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.toast.sToast('success', 'Updated successfully');
          this._cs.assetSelect.emit(parseInt(this.asset.id));
          this.getAssetTags();
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }

  addSelectedTag(): void {
    const duplicate = this.addNewTags.filter((x: any) => x.name === this.selected_tag.name && x.value === this.selected_tag.value);
    if (duplicate && duplicate.length) {
      this.toast.sToast('info', 'Duplicate tag detected. Please select a different tag name.');
    } else {
      this.addNewTags.push({ name: this.selected_tag.name, value: this.selected_tag.value });
      this.selected_tag = null;
    }
  }

  getTags(search?: any): void {
    const params: any = {};
    if (search) {
      if (search.indexOf(':') > -1) {
        const name = search.split(':')[0].trim();
        const valuePart = search.split(':')[1].trim();
        params.condition = "name ilike '%" + name + "%' and value ilike '%" + valuePart + "%'";
      } else {
        params.condition = "name ilike '%" + search + "%'";
      }
    } else {
      params.condition = true;
      params.skip = 0;
      params.limit = 1000;
      params.order_by = 'created desc';
    }
    if (this._cs.currentScope !== '*') {
      if (params.condition === true) {
        params.condition = `company_id=${parseInt(this._cs.currentScope.id)} or company_id is null`;
        ;
      } else {
        params.condition += ` and company_id=${parseInt(this._cs.currentScope.id)} or company_id is null`;
      }
    }
    this.searching = true;
    this._bs.doRequest('/r/report_queries/distinct_tags', 'get',
      null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.filterTags = [];
        this.searching = false;
        if (result.status) {
          const uniqueNames: any = new Set(this.addNewTags);
          const tasksResults = cloneDeep(result.data)
          const filteredData2 = (uniqueNames) ? tasksResults.filter((obj: any) => !uniqueNames.has(obj.value)) : tasksResults;
          this.filterTags = filteredData2;
          if (!search) {
            this.allTags = filteredData2;
          } else {
            this.allTags = Array.from(new Map([...this.allTags, ...result.data].map(obj => [obj.id, obj])).values());
          }
        }
      });
  }
  closeCurrentTag(data: any): void {
    if (data === true && !this.selected_tag) {
      this.getTags();
    }
  }
}
