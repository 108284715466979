<div class="p-2 grid grid-cols-1 sm:grid-cols-3 gap-2 mt-2" *ngIf="!loadingCon.loading && !loadingCon.nodata">
    <div class="flex flex-row items-center bg-card ring-1 rounded" *ngFor="let device of storageDevices">
        <div class="flex flex-wrap px-3">
           <i class="fas fa-hdd text-2xl"></i>
        </div>
        <div class="flex flex-col w-full gap-1">
            <div class="flex flex-row flex-wrap items-center py-1 px-1">
                <div class="text-sm font-semibold">
                    <i class="fas fa-lock mr-1 text-green-400" *ngIf="device.encrypted === '1'" matTooltip="Encrypted"></i>
                    <i class="fas fa-lock-open mr-1 text-red-400" *ngIf="device.encrypted ==='0' || !device.encrypted"  matTooltip="Not Encrypted"></i>
                    <i class="fas fa-lock mr-1 text-grey-400" *ngIf="device.encrypted === '2'" matTooltip="Drive Locked"></i>
                    {{device.device_id}}</div>
                <mat-progress-bar class="w-full h-2" [color]="'primary'" mode="determinate" [value]="device.v"></mat-progress-bar>
                <div class="flex flex-row flex-wrap items-center">
                    <span class="text-sm font-light" *ngIf="device.encrypted !== '2'">{{device.free_space | bytesConvert}} free of {{device.size | bytesConvert}}</span>
                    <span class="text-sm font-light" *ngIf="device.encrypted === '2'">Drive Locked</span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader [loadingCon]="loadingCon" *ngIf="!storageDevices || storageDevices.length < 0"></app-loader>
