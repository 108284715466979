<div class="flex flex-col items-center w-full border rounded-lg bg-card">
    <div class="px-4 pt-4 w-full">
        <div class="flex flex-col flex-auto lg:overflow-hidden">
            <div class="font-semibold"> Auto Tags </div>
            <mat-divider class="my-4"></mat-divider>
            <div class="flex flex-col flex-auto lg:overflow-hidden" *ngIf="!defaultTags || !defaultTags.length">
                <div class="flex flex-col flex-auto items-center justify-center">
                    <mat-icon class="icon-size-8" [svgIcon]="'heroicons_outline:tag'"></mat-icon>
                    <div class="mt-4 text-lg font-semibold tracking-tight text-secondary">No auto tags found</div>
                </div>
            </div>
            <div class="flex flex-row items-center flex-wrap gap-2" *ngIf="defaultTags && defaultTags.length">
                <ng-container *ngFor="let tag of defaultTags">
                    <div class="flex items-start justify-start">
                        <span
                            class="bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{tag.name}}:
                            {{tag.value}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="p-4 w-full" fuseScrollbar>
        <div class="flex flex-col flex-auto">
            <div class="flex flex-row items-center">
                <div class="font-semibold">Manual Tags </div>
                <div class="spacer"></div>
                <button *ngIf="!addTags" mat-stroked-button type="button" (click)="addManualTag()" color="primary"
                    aria-label="Manage">
                    Manage Tags
                </button>
            </div>
            <mat-divider class="my-4"></mat-divider>
            <div class="flex flex-col flex-auto " *ngIf="(!manualTags || !manualTags.length) && !addTags;">
                <div class="flex flex-col flex-auto items-center justify-center">
                    <mat-icon class="icon-size-8" [svgIcon]="'heroicons_outline:tag'"></mat-icon>
                    <div class="mt-4 text-lg font-semibold tracking-tight text-secondary">No manual tags found</div>
                </div>
            </div>
            <div *ngIf="manualTags && manualTags.length && !addTags;" class="flex flex-row items-center gap-2 flex-wrap">
                <ng-container *ngFor="let tag of manualTags let i = index;">
                    <div class="flex items-start justify-start">
                        <span
                            class="bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{tag.name}}:
                            {{tag.value}}
                            <button type="button" (click)="removeManualTags(tag, i)"
                                class="inline-flex items-center p-1 ms-2 text-sm text-purple-400 bg-transparent rounded-sm hover:bg-purple-200 hover:text-purple-900 dark:hover:bg-purple-800 dark:hover:text-purple-300"
                                data-dismiss-target="#badge-dismiss-purple" aria-label="Remove">
                                <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Remove Tag</span>
                            </button></span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="flex flex-col flex-auto" *ngIf="addTags">
            <form #tagNgForm="ngForm">
                <div class="w-full">
                    <div class="flex flex-row gap-2 items-center flex-wrap">
                        <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
                            <mat-label>Select Tags</mat-label>
                            <mat-select name="SelectedTags" id="Include Tags" [(ngModel)]="selected_tag"
                                (openedChange)="closeCurrentTag($event)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="tagFilterCtrl" [placeholderLabel]="'Search Tags'"
                                        [noEntriesFoundLabel]="'No matching tag found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let tag of filterTags" [value]="tag">{{tag.name}}:
                                    {{tag.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button (click)="addSelectedTag()" type="button" class="mt-4" mat-icon-button=""
                            [disabled]="!selected_tag" matTooltip="Add Tag">
                            <mat-icon [ngClass]="selected_tag ? 'text-blue-500' : ''"
                                [svgIcon]="'heroicons_outline:plus-circle'">
                            </mat-icon>
                        </button>
                    </div>
                    <ng-container *ngIf="addNewTags && addNewTags.length;">
                        <div class="font-semibold mt-2">Selected Tags</div>
                        <mat-divider class="my-2"></mat-divider>
                        <div class="flex flex-row items-center gap-2 flex-wrap">
                            <ng-container *ngFor="let tag of addNewTags let i = index;">
                                <div class="flex items-start justify-start">
                                    <span
                                        class="bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{tag.name}}:
                                        {{tag.value}}
                                        <button type="button" (click)="addNewTags.splice(i, 1)"
                                            class="inline-flex items-center p-1 ms-2 text-sm text-purple-400 bg-transparent rounded-sm hover:bg-purple-200 hover:text-purple-900 dark:hover:bg-purple-800 dark:hover:text-purple-300"
                                            data-dismiss-target="#badge-dismiss-purple" aria-label="Remove">
                                            <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span class="sr-only">Remove Tag</span>
                                        </button>
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div class="flex items-center mt-6  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                    <button class="ml-auto" mat-button type="button" [matTooltip]="'Cancel'" (click)="addTags = false;">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button type="button" [color]="'primary'" appPreventMultiClick
                        [disabled]="tagNgForm.invalid" [matTooltip]="'Save'" (click)="saveManualTags()">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
