import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BaseRequestService} from "../../../_services/base.service";
import {LoaderService} from "../../../_services/loader.service";
import {MyToastrService} from "../../../_services/toastr.service";
import {LoaderComponent} from "../loader/loader.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import { Subject, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash';
import { NoDataComponent } from '../no-data/no-data.component';

@Component({
  selector: 'app-ciphers',
  standalone: true,
  imports: [CommonModule, LoaderComponent, MatTooltipModule, NgOptimizedImage, NoDataComponent],
  templateUrl: './ciphers.component.html',
  styleUrls: ['./ciphers.component.scss']
})
export class CiphersComponent implements OnInit, OnDestroy {
  @Input() asset: any;
  @Input() showNoData: any;
  ciphers: any;
  objectKeys = Object.keys;
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
     // Unsubscribe from all subscriptions
     this._unsubscribeAll.next(null);
     this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getCiphers();
  }

  getCiphers(): void {
    if (!this.asset) {
      setTimeout(() => { this.getCiphers(); }, 2000);
      return;
    }
    this.ls.display(true);
    this._bs.doRequest(`/r/company/ciphers_view`, 'get', null,
      { condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`, skip: 0, limit: 1000, order_by: 'host_name asc' })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            res.data.forEach((obj: any) => {
              obj.showTable = false;
              const ciphers = cloneDeep(obj.ciphers);
              obj.ciphers = [];
              if(ciphers.length){
                ciphers.forEach((proto: any, index: any) => {
                  obj.ciphers[index] = proto.split(' - ');
                });
              }
            });
            this.ciphers = res.data;
            this.loadingCon.loading = null; this.loadingCon.nodata = null;
          } else {
              this.ciphers = [];
            this.loadingCon.loading = null; this.loadingCon.nodata = true;
          }
        } else {
            this.ciphers = [];
            this.loadingCon.loading = null; this.loadingCon.nodata = true;
          const data = (res.message)? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }
}
