<div class="h-96 w-full p-6 animate-pulse blink">
    <div class="mt-3 flex h-20 w-full flex-row items-center rounded-lg gap-2 shadow">
        <div class="flex flex-row gap-2">
            <div class="mt-2 h-10 w-10 rounded-full bg-card"></div>
        </div>
        <div class="mt-2 h-5 w-6 rounded bg-card"></div>
        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
    </div>
    <div class="mt-3 flex h-20 w-full flex-col rounded-lg gap-2 shadow">
        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
    </div>
    <div class="mt-3 flex h-20 w-full flex-col rounded-lg gap-2 shadow">
        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
    </div>
    <div class="mt-3 flex h-20 w-full flex-col rounded-lg gap-2 shadow">
        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
        <div class="mt-2 h-4 w-full rounded-lg bg-card"></div>
    </div>
</div>
