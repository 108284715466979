<div class="w-full max-w-5xl"
    *ngIf="(_userService._user_roles.indexOf('admin') >-1 && (_bs.cUser().included === '*' || _bs.cUser().included === '' ) && !_bs.cUser().excluded) || (!_bs.cUser().included && !_bs.cUser().excluded)">
    <form [formGroup]="planBillingForm">
        <div class="w-full">
            <div class="text-xl">Current Plan</div>
            <div class="text-secondary">Current plan indicates your total asset management and category.</div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <mat-radio-group class="invisible absolute w-0 h-0 pointer-events-none" [formControlName]="'plan'"
                #planRadioGroup="matRadioGroup">
                <ng-container *ngFor="let plan of plans; trackBy: trackByFn">
                    <mat-radio-button [value]="plan.value"></mat-radio-button>
                </ng-container>
            </mat-radio-group>
            <ng-container *ngFor="let plan of plans; trackBy: trackByFn">
                <div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card"
                    [ngClass]="{'ring ring-inset ring-primary': planRadioGroup.value === plan.value}">
                    <ng-container *ngIf="planRadioGroup.value === plan.value">
                        <mat-icon class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary"
                            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                    </ng-container>
                    <div class="font-semibold">{{plan.label}}</div>
                    <div class="mt-1 whitespace-normal text-secondary">{{plan.details}}</div>
                    <div class="flex-auto"></div>
                    <div class="mt-8 text-lg">
                        <span>{{plan.price | currency:'USD':'symbol':'1.0'}}</span>
                        <span class="text-secondary"> / month</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="w-full mt-8">
            <div class="text-xl">Billing Summary</div>
            <div class="text-secondary">Viewing an overview of current month costs.</div>
        </div>
        <div class="grid sm:grid-cols-3 gap-6 w-full mt-2">
            <div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card">
                <div class="font-semibold">{{assetTotal}}</div>
                <div class="mt-1 whitespace-normal text-secondary">Assets</div>
            </div>
            <div>
            </div>
           
        </div>
    </form>
    <div class="flex flex-col w-full mt-2 bg-card rounded-lg p-4 ring-1 mb-4">
        <div class="flex flex-row items-start">
            <div class="flex-auto">
                <table class="table-auto w-full">
                    <thead class="border-b">
                        <tr>
                            <th class="text-left">Company Name</th>
                            <th *ngFor="let s of stats;">
                                <span class="py-1 flex">{{ s.text }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of allComp | search: search; let last = last" class="py-1"
                            [ngClass]="{ 'border-b': !last }">
                            <td>
                                <span class="flex items-center text-secondary font-light text-sm" [matTooltip]="c.name">
                                    {{ c.name | ellipsis: 30 }}
                                </span>
                            </td>
                            <td *ngFor="let s of stats;">
                                <div class="mx-auto flex flex-row flex-shrink-0 items-center" [matTooltip]="s.title">
                                    <span class="mr-2">
                                        <i class="fas {{ s.icon }} text-sm"></i>
                                    </span>
                                    <span class="flex items-center text-secondary font-light text-sm">
                                        {{ c[s.key] }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex-none ml-4">
                <button type="button" 
                        color="primary"
                        mat-icon-button
                        id="fullData"
                        (click)="apiDownload()" 
                        aria-label="download csv menu"
                        matTooltip="Download as CSV"
                        matTooltipPosition="above"
                        class="w-10 h-10 p-0 bg-transparent rounded-md">
                    <mat-icon class="text-black text-3xl">file_download</mat-icon>
                </button>
            </div>
        </div>
    </div>
    
</div>
<div class="h-full"
    *ngIf="_userService._user_roles.indexOf('admin') >-1 && ((_bs.cUser().included !== '*' && _bs.cUser().included !== '' ) || _bs.cUser().excluded) && (_bs.cUser().included || _bs.cUser().excluded)">
    <div class="flex flex-col flex-auto items-center justify-center h-full">
        <div class="w-full max-w-md">
            <img src="/assets/images/forbidden.png" alt="Forbidden" width="100%" height="50%">
        </div>
        <div class="mb-4 text-4xl md:text-3xl font-extrabold tracking-tight leading-tight md:leading-none text-center">
            Unauthorized access.</div>
    </div>
</div>