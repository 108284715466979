<div class="flex flex-col bg-card border m-4 border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
    <div class="p-6 pb-0">
      <div class="flex flex-row items-center">
        <div class="mr-4 text-lg font-medium tracking-tight leading-6 runcate">
          ICMP PING CHECK
          <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
            {{pageTotal}}
          </span>
        </div>
        <div class="ml-auto flex flex-row items-center">
          <mat-form-field appearance="fill" class="w-full mr-2 mini-form-field">
            <mat-label>Search</mat-label>
            <input type="text" matInput name="Search" [(ngModel)]="globalValue"
              (ngModelChange)="searchUpdate.next($event)">
          </mat-form-field>
          <mat-slide-toggle class="w-[25vw] mt-4" [color]="'primary'" name="toggleGlobal" [(ngModel)]="globalValue"
            (change)="onChange($event, '*');">
            {{(globalValue) ? 'Disable' : 'Enable'}} Global
          </mat-slide-toggle>
        </div>
      </div>
      <mat-divider class="my-2"></mat-divider>
    </div>
    <div class="mx-4" fuseScrollbar>
      <mat-toolbar class="select-tools h-[47px] rounded bg-[#d1e3ff] dark:bg-default"
        *ngIf="selection.selected.length > 0 && pageTotal > 0">
        <span>{{selection.selected.length}} Company(s) selected.</span>
        <div class="flex flex-1 place-content-end">
          <ng-container>
            <button mat-flat-button color="primary" id="gbtnSelction" mat-raised-button [matMenuTriggerFor]="gactionSMenu"
              class="mini-form-field" [disabled]="selection.selected.length === 0" aria-label="global action with a menu">
              Global Actions
            </button>
            <mat-menu #gactionSMenu="matMenu">
              <ng-container>
                <a id="enable" class="mat-primary" mat-menu-item (click)="globalActionCall(selection.selected, 'enable')">
                  <span>Enable</span>
                </a>
                <a id="disable" class="mat-primary" mat-menu-item
                  (click)="globalActionCall(selection.selected, 'disable')">
                  <span>Disable</span>
                </a>
              </ng-container>
            </mat-menu>
          </ng-container>
          <button class="gray" matSuffix mat-icon-button (click)="clearSelection();">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <ng-container>
        <table class="w-full bg-transparent mb-4" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [color]="'primary'"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" [color]="'primary'"
                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="">
              Company Name
            </th>
            <td mat-cell *matCellDef="let company" class="w-[39%]">
              <div class="flex flex-row items-center my-2">
                <div class="flex flex-col justify-center  gap-1">
                  <span class="pr-6 whitespace-nowrap">{{company.name}}</span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              Status
            </th>
            <td mat-cell *matCellDef="let company">
              <span class="inline-flex items-center font-bold text-sm px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': !company.enabled,
                                                  'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': company.enabled}">
                <span class="leading-relaxed whitespace-nowrap">{{(!company.enabled) ? 'Disabled': 'Enabled'
                  }}</span>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              Action
            </th>
            <td mat-cell *matCellDef="let company; let index=index;">
              <mat-slide-toggle [color]="'primary'" name="toggle{{index}}" [checked]="company.enabled"
                [disabled]="selection.selected.length > 0" (change)="onChange($event, company);">
              </mat-slide-toggle>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="order-row h-[40px] p-1" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="pageTotal === 0" class="flex flex-col items-center justify-center gap-y-2">
          <img src="/assets/images/empty.png" alt="" class="w-20">
          <div> No company found.</div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
        <mat-divider class="my-2"></mat-divider>
      </ng-container>
    </div>
    <!-- <app-loader [loadingCon]="loadingCon" class="pb-6"></app-loader> -->
  </div>