import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { MaterialModule } from 'app/material.module';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-report-cover-page',
  standalone: true,
  animations: fuseAnimations,
  imports: [CommonModule, MaterialModule, FormsModule, NgxMatSelectSearchModule,
    FuseScrollbarDirective, MaterialModule, AppFilterPipeModule, ReactiveFormsModule,
    FuseAlertComponent, DynamicFormComponent],
  templateUrl: './report-cover-page.component.html',
  styleUrls: ['./report-cover-page.component.scss']
})
export class ReportCoverPageComponent {
  public searching = false;
  @ViewChild('fileInputValue') fileInputValue!: ElementRef;
  companies: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  searchCompanyControl: UntypedFormControl = new UntypedFormControl();
  company_id: any;
  selectedFile: any;
  selectedCompanies: any = [];
  reportSettingsForm: UntypedFormGroup;
  coverPageDetails: any = {};
  cvrPageCount: any = 0;
  /**
 * Constructor
 */
  constructor(
    public _cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private _bs: BaseRequestService, private ls: LoaderService, private _formBuilder: UntypedFormBuilder,
    private toast: MyToastrService, public confirmDialog: FuseConfirmationService) {

  }

  ngOnInit(): void {
    this.getCompanies();
    this.reportSettingsForm = this._formBuilder.group({
      header: [""],
      footer: [""],
      company_logo: [""],
    });
    this.getCoverPage();
  }

  getCompanies(search?: string): void {
    this.searching = true;
    const condition = search
      ? { condition: "name ilike '%" + search + "%'" }
      : {
        condition: true,
        skip: 0,
        limit: 10000,
        order_by: 'created desc'
      };
    this._bs
      .doRequest('/r/company/companies', 'get', null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.data.length) {
          result.data.sort((a: any, b: any) => {
            const c = a.name ? a.name.toLowerCase() : "";
            const d = b.name ? b.name.toLowerCase() : "";
            if (c < d) {
              return -1;
            } else if (c > d) {
              return 1;
            } else {
              return 0;
            }
          });
          this.companies = result.data;
          this.searching = false;
        } else {
          this.companies = [];
          this.searching = false;
        }
      }, (error: any) => {
        this.searching = false;
      }
      );
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.selectedFile = file;
      } else {
        this.toast.sToast("error", "Please select file in docx format");
      }
    }
  }

  saveCoverPage(): void {
    this.ls.display(true);
    const formData = new FormData();
    const isGlobal: any = this.selectedCompanies[0] === "*" ? true : false;
    formData.append("file", this.selectedFile);
    if (this.selectedCompanies[0] === "*") {
      formData.append("isGlobal", isGlobal);
    } else {
      const companyIdsString = this.selectedCompanies.join(",");
      formData.append("company_id", companyIdsString);
    }
    this._bs.uploadFormData("/report_builder/cover_page", formData).subscribe(
      (res: any) => {
        if (res.status) {
          this.ls.display(false);
          this.toast.sToast("success", `Cover Page Updated successfully`);
          this.selectedFile = "";
          this.selectedCompanies = [];
          this.getCoverPage();
          if (this.fileInputValue) {
            this.fileInputValue.nativeElement.value = null;
          }
        } else {
          this.toast.sToast("error", `${res.msg}`);
        }
      },
      (error: any) => {
        console.error("Error posting image to API:", error);
        this.ls.display(false);
      }
    );
  }

  deletecoverpage(cmp?:any): void {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Are you sure you want to delete Cover Page for selected Companies?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.ls.display(true);
        const isGlobal: boolean = this.selectedCompanies[0] === "*";
        let payload: any;

        if (isGlobal) {
          payload = { isGlobal: true };
        } else {
          payload = { company_id: (cmp) ? cmp : this.selectedCompanies.join(",") };
        }

        // Check if there is any data to send before making the request
        this._bs.doRequest("/report_builder/delete_cover_page", 'post', payload).subscribe(
          (res: any) => {
            if (res.status) {
              this.ls.display(false);
              this.toast.sToast("success", `Cover Page Deleted successfully`);

              // Clear selectedFile and selectedCompanies after successful deletion
              this.selectedFile = null;
              this.selectedCompanies = [];
              this.getCoverPage();
              const fileInput = document.getElementById('yourFileInputId') as HTMLInputElement;
              if (fileInput) {
                const newFileInput = document.createElement('input');
                newFileInput.type = 'file';
                newFileInput.id = 'yourFileInputId';
                newFileInput.addEventListener('change', (event) => this.onFileSelected(event));
                fileInput.parentNode?.replaceChild(newFileInput, fileInput);
              }
              if (this.fileInputValue) {
                this.fileInputValue.nativeElement.value = null;
              }
            } else {
              this.toast.sToast("error", `${res.msg}`);
            }
          },
          (error: any) => {
            console.error("Error posting image to API:", error);
            this.ls.display(false);
          }
        );
      }
    });
  }

  getCoverPage(): void {
    this._bs.doRequest('/report_builder/cover_page', 'get', null,)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          this.coverPageDetails = result.message;
          this.cvrPageCount = Object.keys(this.coverPageDetails).length;
        } else {
          this.coverPageDetails = {};
          this.toast.sToast("error", `${result.message}`);
        }
      });
  }

  downloadCoverPage(row: any): void {
    const newWindow = window.open('', '_blank');
    // Extract filename from the URL
    const filename = decodeURIComponent(row.value.split("filename=")[1]);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = row.value;
    link.setAttribute('download', filename);
    newWindow.document.body.appendChild(link);
    link.click();
  }

}
