import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseRequestService } from 'app/_services/base.service';
import { TableComponent } from "../../../shared/table/table.component";
import { LoaderService } from 'app/_services/loader.service';
import { CommonService } from 'app/_services/common.service';
import { AppFilterPipeModule } from "../../../../_filters/app.filter-pipe.module";
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Subject, takeUntil } from 'rxjs';
import { QueryBuilderComponent, QueryBuilderModule } from "@syncfusion/ej2-angular-querybuilder";
import { MaterialModule } from 'app/material.module';
import cloneDeep from 'lodash-es/cloneDeep';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { ModalService } from 'app/_services/modal.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
    selector: 'settings-tags',
    standalone: true,
    imports: [CommonModule, NgxMatSelectSearchModule, TableComponent, ModalComponent, QueryBuilderModule, AppFilterPipeModule, FormsModule, ReactiveFormsModule, MaterialModule],
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],

})
export class TagsComponent implements OnDestroy, OnInit {
    loading: boolean = false
    accountForm: UntypedFormGroup;
    selectedIndex = 0;
    customOperators: any = [
        {
            "value": "startswith",
            "key": "Starts With"
        },
        {
            "value": "endswith",
            "key": "Ends With"
        },
        {
            "value": "contains",
            "key": "Contains"
        },
        {
            "value": "equal",
            "key": "Equal"
        },
        {
            "value": "notequal",
            "key": "Not Equal"
        },
        {
            "value": "in",
            "key": "In"
        }
    ]
    tagRuleTableOptions: any = {};
    tagTableOptions: any = {};
    tagRules: any = {
        collection: '',
        tags: []
    };
    tag_details: any = {};
    collections: any = [];
    selected_tag: any = '';
    allTags: any = [];
    filterTags: any = [];
    sColumns: any = [];
    columns: any = [];
    public tagFilterCtrl: FormControl = new FormControl();
    public searching = false;
    @ViewChild('querybuilder') public qryBldrObj?: QueryBuilderComponent;
    currentRuleTag: any = {};
    currentTag: any = {};
    tagvalue: any = '';
    tagname: any = '';
    @ViewChild('tagNgForm') tagNgForm: NgForm;
    importRules = {
        'condition': 'and',
        'rules': []
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _bs: BaseRequestService,
        private loaderService: LoaderService,
        private cs: CommonService,
        public confirmDialog: FuseConfirmationService,
        public toast: MyToastrService,
        public _mS: ModalService,
    ) {
        this.cs.selectedSiteChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (this.selectedIndex === 0) {
                    this.getTagData();
                } else {
                    this.getTagRuleData();
                }
            });
    }




    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getCollectionSettings();
        if (this.selectedIndex === 0) {
            this.getTagData();
        } else {
            this.getTagRuleData();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    tabFn($event: any): void {
        if ($event === 0) {
            this.getTagData();
        } else {
            this.getTagRuleData();
        }
    }
    cancel() {
        this._mS.close('createTagsRule');
        this.getTagRuleData();
        this.getTagData();
        this._changeDetectorRef.detectChanges();
    }

    getTagRuleData(): void {
        let data: any = {
            columns: [
                { "header": "Name", "columnDef": "name", "filter": "", "cell": "(element: any) => ${element.name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
                { "header": "Collection", "columnDef": "collection", "filter": "", "cell": "(element: any) => ${element.collection}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
                { "header": "Risk score", "columnDef": "risk_score", "filter": "", "cell": "(element: any) => ${element.risk_score}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
                {
                    "header": "Is Global",
                    align: "center",
                    "columnDef": "is_global",
                    cType: "agg",
                    "filter": "",
                    "cell": "(element: any) => `${element.is_global}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "isHyperlink": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false,
                    statusicon: true,
                    success: true,
                    failure: false,
                    successIconPath: '/assets/images/auditLogins/tick.svg',
                    successToolTip: 'Yes',
                    failureIconPath: '/assets/images/auditLogins/alert.svg',
                    failureToolTip: 'No',
                },
                { 'header': "Description", 'columnDef': "description", 'cType': "string", 'filter': "", 'cell': "(element: any) => `${element.description}`", 'order': 0, 'visible': true, 'isToolTip': false, 'isToolTipCol': '', 'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false, 'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '', 'isSort': true, 'iscolumnSearch': false, 'isShowMoreData': true },
            ],
            sortOptions: { active: 'name', direction: 'asc' },
            _pageData: [],
            tableOptions: {
                title: 'Tag Rules',
                isServerSide: false,
                selectText: '',
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: true,
                isUserRestCheck: true,
                actionMenuItems: [
                    {
                        text: 'Edit',
                        icon: 'edit',
                        callback: 'editFunction',
                        hideLocal: false,
                        isGlobal: false,
                    },
                    {
                        text: 'Delete',
                        icon: 'delete',
                        id: 'delete',
                        callback: 'editFunction',
                    }
                ],
                pagination: true,
                pageTotal: 0,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: true,
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                add: true,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: '/r/company/tag_rules',
                    // dUrl: '/d/company/tag_rules',
                    isGlobal: true,
                }, id: 'tag_rules'

            },
            changeValue: new Subject<any>(),
        };
        this.tagRuleTableOptions = {}; this._changeDetectorRef.detectChanges();
        data.pageData = []; data.tableOptions.pageTotal = 0;
        if (this.cs.currentScope === '*') {
            data.tableOptions.serverSide.condition = `company_id is null`;
        } else {
            data.tableOptions.serverSide.condition = `company_id = ${parseInt(this.cs.currentScope.id)} or company_id is null`
        }
        this.tagRuleTableOptions = data;
        this._changeDetectorRef.detectChanges();
    }

    getTagData(): void {
        let data: any = {
            columns: [
                { "header": "Name", "columnDef": "name", "filter": "", "cell": "(element: any) => ${element.name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
                { "header": "Value", "columnDef": "value", "filter": "", "cell": "(element: any) => ${element.value}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
                {
                    "header": "Is Global",
                    align: "center",
                    "columnDef": "is_global",
                    cType: "agg",
                    "filter": "",
                    "cell": "(element: any) => `${element.is_global}`",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "isHyperlink": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false,
                    statusicon: true,
                    success: true,
                    failure: false,
                    successIconPath: '/assets/images/auditLogins/tick.svg',
                    successToolTip: 'Yes',
                    failureIconPath: '/assets/images/auditLogins/alert.svg',
                    failureToolTip: 'No',
                },
            ],
            sortOptions: { active: 'name', direction: 'asc' },
            _pageData: [],
            tableOptions: {
                title: 'Tags',
                isServerSide: false,
                selectText: 'tags',
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: true,
                actionMenuItems: [
                    {
                        text: 'Edit',
                        icon: 'edit',
                        callback: 'editFunction',
                        hideLocal: false,
                        isGlobal: false,
                    },
                    {
                        text: 'Delete',
                        icon: 'delete',
                        id: 'delete',
                        callback: 'editFunction',
                    }
                ],
                pagination: true,
                pageTotal: 0,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: true,
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                add: true,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: '/r/company/tags',
                    // dUrl: '/d/company/tags',
                    isGlobal: true,
                }, id: 'tags'

            },
            changeValue: new Subject<any>(),
        };
        this.tagTableOptions = {}; this._changeDetectorRef.detectChanges();
        data.pageData = []; data.tableOptions.pageTotal = 0;
        if (this.cs.currentScope === '*') {
            data.tableOptions.serverSide.condition = `company_id is null`;
        } else {
            data.tableOptions.serverSide.condition = `company_id = ${parseInt(this.cs.currentScope.id)} or company_id is null`
        }
        this.tagTableOptions = data;
        this._changeDetectorRef.detectChanges();
    }

    addTagData(): void {
        this.tag_details = {
            collection: 'Assets',
            name: '',
            value: '',
            type: 'manual',
        };
        this._mS.open('createTags');
        this._changeDetectorRef.detectChanges();
    }
    addTagRuleData() {
        this.tagRules = {
            collection: '',
            tags: []
        };
        this.currentRuleTag = {};
        this._mS.open('createTagsRule');
        this._changeDetectorRef.detectChanges();
    }

    tagactionCall(data: any): void {
        this.currentTag = data.row;
        if (data.row.is_global && this.cs.currentScope !== '*') {
            this.toast.sToast('info', 'It is not possible to edit/delete the selected Tag(s) in Company view because it has been added globally. Please select Tag in Global view in order to edit/delete the globally added data.');
            return;
        }
        if (data.action.text == 'Edit') {
            this.tag_details = {
                name: data.row.name,
                value: data.row.value,
                collection: data.row.collection,
            }
            this._mS.open('createTags');
            this._changeDetectorRef.detectChanges();
        }
        if (data.action.text == 'Delete') {
            this.deleteTag(data.row);
        }
    }

    ruleactionCall(data: any) {
        this.currentRuleTag = data.row;
        if (data.row.is_global && this.cs.currentScope !== '*') {
            this.toast.sToast('info', 'It is not possible to edit/delete the selected Tag Rule(s) in Company view because it has been added globally. Please select Tag Rule in Global view in order to edit/delete the globally added data.');
            return;
        }
        if (data.action.text == 'Edit') {
            this._mS.open('createTagsRule');
            this.tagRules = {
                name: data.row.name,
                description: data.row.description,
                risk_score: data.row.risk_score,
                collection: data.row.collection,
                tags: data.row.tags,
            }
            const col = cloneDeep(this.columns);
            this.sColumns = null; this._changeDetectorRef.detectChanges();
            this.sColumns = col.filter((x: any) => x.columns.indexOf(data.row.collection) !== -1);
            setTimeout(() => {
                if (this.qryBldrObj) {
                    this.qryBldrObj!.setRules(data.row.rules);
                    this._changeDetectorRef.detectChanges();
                }
            }, 1000);
        }
        if (data.action.text == 'Delete') {
            this.deleteRule(data.row);
        }
    }

    deleteTag(data: any) {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to delete this tag?',
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._bs.doRequest(`/d/company/tags/${data.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result.status) {
                            setTimeout(() => this.cs.selectedTagChange.next({}));
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }
    deleteRule(data: any) {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to delete this tag rule?',
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._bs.doRequest(`/d/company/tag_rules/${data.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result.status) {
                            setTimeout(() => this.cs.selectedTagChange.next({}));
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    changeCollection(change: any): void {
        const col = cloneDeep(this.columns);
        this.sColumns = null; this._changeDetectorRef.detectChanges();
        this.sColumns = col.filter((x: any) => x.columns.indexOf(change.value) !== -1);
        this._changeDetectorRef.detectChanges();
    }

    addTags(): void {
        if (!this.tagname || !this.tagvalue) { return; }
        if (this.tagRules && !this.tagRules.tags) {
            this.tagRules.tags = [];
        }
        this.tagRules.tags.push({
            name: this.tagname,
            value: this.tagvalue,
        });
        this.tagname = '';
        this.tagvalue = '';
        this._changeDetectorRef.detectChanges();
    }

    saveTagRule(): void {
        const filterQuery = this.qryBldrObj!.getRules();
        const filteredData: any = filterQuery.rules.filter((obj: any) => Object.values(obj).some(value => value !== ''));
        if (filteredData.length === 0) {
            this.toast.sToast('error', 'Please add at least one rule.');
            return;
        }
        if (filterQuery.condition === 'and' && this.cs.hasDuplicates(filterQuery.rules, ['field', 'label', 'operator'])) {
            this.toast.sToast('error', 'Duplicate rules not allowed.');
            return;
        }
        const duplicates = this.tagRules.tags.filter((item: any, index: any, array: any) => {
            return array.slice(index + 1).some((otherItem: any) =>
                otherItem.name === item.name && otherItem.value === item.value
            );
        });
        if (duplicates && duplicates.length) {
            this.toast.sToast('error', 'Please check for duplicates.');
            return;
        }
        const duplicatesName = this.tagRules.tags.filter((item: any, index: any, array: any) => {
            return array.slice(index + 1).some((otherItem: any) =>
                otherItem.name === item.name
            );
        });
        if (duplicatesName && duplicatesName.length) {
            this.toast.sToast('error', 'Please check for duplicate Name field entered.');
            return;
        }
        var method = 'post'; var api = '/w/company/tag_rules';
        const data: any = Object.assign({}, this.tagRules);
        data.is_global = true;
        if (this.cs.currentScope !== '*') {
            data.company_id = this.cs.currentScope.id;
            data.is_global = false;
        }
        data.description = data.description || null;
        data.rules = filterQuery;
        var postdata: any = { data: data }
        if (this.currentRuleTag && this.currentRuleTag.id) {
            method = 'patch'
            api = '/w/company/tag_rules'
            postdata = { id: this.currentRuleTag.id, data: data }
        }
        this.loaderService.display(true);
        this._bs
            .doRequest(api, method, postdata)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.currentRuleTag = {};
                    this._mS.close('createTagsRule');
                    this.toast.sToast('success', `Saved successfully!`);
                    this.cancel();
                    this._changeDetectorRef.detectChanges();
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    getCollectionSettings(): void {
        this.loaderService.display(true);
        this._bs.doRequest(`/r/company/jsonconfigs/tags_config`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    this.collections = res.data.collections;
                    this.columns = res.data.columns;
                    this._changeDetectorRef.detectChanges();
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    getTags(search?: any): void {
        const params: any = {};
        if (search) {
            if (search.indexOf(':') > -1) {
                const name = search.split(':')[0].trim();
                const valuePart = search.split(':')[1].trim();
                params.condition = "name ilike '%" + name + "%' and value ilike '%" + valuePart + "%'";
            } else {
                params.condition = "name ilike '%" + search + "%'";
            }
        } else {
            params.condition = true;
            params.skip = 0;
            params.limit = 1000;
            params.order_by = 'created desc';
        }
        if (this.cs.currentScope !== '*') {
            if (params.condition === true) {
                params.condition = `company_id=${parseInt(this.cs.currentScope.id)} or company_id is null`;
            } else {
                params.condition += ` and company_id=${parseInt(this.cs.currentScope.id)} or company_id is null`;
            }
        }
        this._bs.doRequest('/r/report_queries/distinct_tags', 'get',
            null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.filterTags = [];
                if (result.status) {
                    const uniqueNames: any = new Set(this.tagRules.tags);
                    const tasksResults = cloneDeep(result.data)
                    const filteredData2 = (uniqueNames) ? tasksResults.filter((obj: any) => !uniqueNames.has(obj.value)) : tasksResults;
                    this.filterTags = filteredData2;
                    if (!search) {
                        this.allTags = filteredData2;
                    } else {
                        this.allTags = Array.from(new Map([...this.allTags, ...result.data].map(obj => [obj.id, obj])).values());
                    }
                    this._changeDetectorRef.detectChanges();
                }
            });
    }

    addSelectedTag(): void {
        const duplicate = this.tagRules.tags.filter((x: any) => x.name === this.selected_tag.name && x.value === this.selected_tag.value);
        if (duplicate && duplicate.length) {
            this.toast.sToast('info', 'Duplicate tag detected. Please select a different tag name.');
        } else {
            this.tagRules.tags.push({ name: this.selected_tag.name, value: this.selected_tag.value });
            this.selected_tag = null;
        }
        this._changeDetectorRef.detectChanges();
    }

    saveTags(): void {
        var method = 'post'; var api = '/w/company/tags';
        var msg: any = 'Saved';
        const data: any = Object.assign({}, this.tag_details);
        data.is_global = true;
        if (this.cs.currentScope !== '*') {
            data.company_id = this.cs.currentScope.id;
            data.is_global = false;
        }
        var postdata: any = { data: data }
        if (this.currentTag && this.currentTag.id) {
            method = 'patch';
            api = '/w/company/tags';
            msg = 'Updated';
            postdata = { id: this.currentTag.id, data: data }
        }
        this.loaderService.display(true);
        this._bs
            .doRequest(api, method, postdata)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.currentTag = {};
                    this._mS.close('createTags');
                    this.getTagData();
                    this.toast.sToast('success', `${msg} successfully!`);
                    this._changeDetectorRef.detectChanges();
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    closeCurrentTag(data: any): void {
        if (data === true && !this.selected_tag) {
            this.getTags();
            this._changeDetectorRef.detectChanges();
        }
    }
}
