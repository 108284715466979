<div class="col-span-3 -mx-4
px-4 py-8 sm:col-span-2 sm:mx-0 sm:rounded-r-2xl sm:py-4 min-h-[32rem] max-h-[32rem] h-[32rem]" fuseScrollbar>
    <div class="">
        <div class="pr-8 -mx-4">
            <!-- <ng-template mat-tab-label>
                <i class="fas fa-desktop mr-2" id="CPU"></i> CPU
            </ng-template> -->
            <!-- <ng-template matTabContent> -->
                <table class="table-auto">
                    <tbody class="text-sm font-light ">
                        <tr class="border-b">
                            <td class="py-1 px-4 text-left">CPU Model</td>
                            <td class="py-1 px-4 text-left font-light">
                                <i class="fas fa-microchip text-green-400 pr-2"></i>
                                <!-- {{cpunformation.cpu_core}} --> 
                                {{cpunformation?.model}}
                            </td>
                        </tr>
                        <tr class="border-b">
                            <td class="py-1 px-4 text-left">CPU Core</td>
                            <td class="py-1 px-4 text-left font-light">
                                <i class="fas fa-microchip text-green-400 pr-2"></i>
                                {{cpunformation?.logical_processors}}
                            </td>
                        </tr>
                        <tr class="border-b">
                            <td class="py-1 px-4 text-left">CPU Speed</td>
                            <td class="py-1 px-4 text-left font-light">
                                <i class="fas fa-microchip text-green-400 pr-2"></i>
                                {{cpunformation?.current_clock_speed}} MHz
                            </td>
                        </tr>
                    </tbody>
                </table>
            <!-- </ng-template> -->
        </div>
    </div>