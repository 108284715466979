<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user?.avatar" [src]="user?.avatar">
        <mat-icon *ngIf="!showAvatar || !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <!-- <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"
        ></span> -->
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="viewAuthPage()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item (click)="viewReleaseNotes()">
        <mat-icon [svgIcon]="'mat_solid:new_releases'"></mat-icon>
        <span>Release Notes</span>
    </button>
    <button mat-menu-item (click)="viewVulsFeedInfo()">
        <mat-icon [svgIcon]="'heroicons_outline:shield-check'"></mat-icon>
        <span>Vuls Feed Info</span>
    </button>   
    <button mat-menu-item (click)="viewBuildInfo()">
        <mat-icon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
        <span>Build Info</span>
    </button>
    <button mat-menu-item (click)="getCompanies()" *ngIf="roles.indexOf('admin') > -1 || _baseService.user().email === 'support@connectsecure.com'">
        <mat-icon [svgIcon]="'mat_solid:file_copy'"></mat-icon>
        <span>Replication Status</span>
    </button>
    <button mat-menu-item (click)="apiDoc()">
        <mat-icon [svgIcon]="'mat_solid:integration_instructions'"></mat-icon>
        <span>API Documentation</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<s-modal id="releaseNotes">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row" *ngIf="releaseNotes">
        <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
     bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">{{releaseNotes.title}}</p>
            <span
                class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-xs font-bold me-2 px-2.5 py-1 rounded-full">{{releaseNotes.type}}</span>
            <span class="text-sm font-bold tracking-wider uppercase">{{releaseNotes.date}}</span>
            <div class="bg-blue-600/80 rounded-2xl p-3 mt-4">
                <p class="mb-3 text-sm">
                    New Features, Enhancements and Bug Fixes.
                </p>
            </div>
            <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px" class="mt-10">
        </div>
        <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="_modalService.close('releaseNotes');">
                <mat-icon>close</mat-icon>
            </button>
            <div
                class="col-span-12 space-y-12 relative px-4 ml-5 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-500 before:dark:bg-gray-700">
                <ng-container *ngFor="let sec of releaseNotes.sections">
                    <div
                        class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400">
                        <h3 class="text-xl font-semibold tracking-wide">{{sec.title}}</h3>
                        <span class="text-sm font-bold tracking-wider uppercase text-secondary" *ngIf="sec.date">{{sec.date}}</span>
                        <div class="flex flex-row items-start mt-3 gap-2" *ngFor="let des of sec.description">
                            <i class="fas fa-check-circle mt-1 text-green-500"></i>
                            <p class="ml-2"> {{des}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="flex items-center mt-4  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                <button (click)="viewLink()"
                    class="ml-auto px-3 py-2 text-xs font-medium text-center inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white border border-blue-500 hover:border-transparent rounded">
                    View All Release Notes
                </button>
                <button (click)="_modalService.close('releaseNotes');"
                        class="ml-2 px-3 py-2 text-xs font-medium text-center inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white border border-blue-500 hover:border-transparent rounded">
                    Got it
                </button>
            </div>

        </div>
    </div>
</s-modal>

<s-modal id="vulsFeedInfo">
    <div class="relative max-w-full mx-auto bg-white rounded-lg shadow-lg p-6 dark:bg-gray-800 dark:text-white" *ngIf="vulsFeedInfos && vulsFeedInfos.length">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-medium dark:text-gray-100">Vulnerability Live Feed Sync Information</h2>
        <button class="text-gray-600 ml-7 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100" mat-icon-button aria-label="close modal icon" matTooltip="Close" (click)="_modalService.close('vulsFeedInfo');">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="space-y-0">
        <div *ngFor="let feed of vulsFeedInfos" class="p-2 bg-white border border-gray-300 dark:border-gray-600 bg-blue-50 dark:bg-gray-700">
          <div class="flex flex-col space-y-2">
            <div class="flex  text-blue-600 text-sm dark:text-blue-400 text-lg">
              <span>{{ feed.update_source }}:</span>
              <span class="text-gray-700 dark:text-gray-300 text-sm">{{ feed.updated | utcToLocale }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </s-modal>
  
  
  
  
  

<s-modal id="buildInformation">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row" *ngIf="buildInfo && buildInfo.agent">
        <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
     bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">Build Information</p>
            <div class="flex flex-row items-center mb-2">
                <span class="w-100">Agent Version: </span>
                <span
                    class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-md font-bold me-2 px-2.5 py-1 rounded-full">{{buildInfo.agent.agentVersion}}</span>
            </div>
            <div class="flex flex-row items-center mb-2">
                <span class="w-100">UI Version: </span>
                <span class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-md font-bold me-2 px-2.5 py-1 rounded-full">{{buildInfo.ui.uiVersion}}</span>
            </div>
            <img src="/assets/images/buildinfo.svg" alt="Release Notes" width="200px" class="mt-10">
        </div>
        <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="_modalService.close('buildInformation');">
                <mat-icon>close</mat-icon>
            </button>
            <div
                class="col-span-12 space-y-12 relative px-4 ml-5 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-500 before:dark:bg-gray-700">
                <ng-container *ngFor="let row of Objectkeys(buildInfo.agent.agentBuildInfo)">
                    <div
                        class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-400">
                        <h3 class="text-xl font-semibold tracking-wide">{{row}}</h3>
                        <div class="flex flex-col my-2">
                            <div class="my-2 border-b pb-2">
                                <div class="flex flex-row items-center">
                                    <span class="w-3 h-3 border-2 border-blue-500 rounded-full mr-1"></span>
                                    <div class="text-md font-bold">MD5</div>
                                </div>
                                <div
                                    class="text-base font-mono break-all">{{buildInfo.agent.agentBuildInfo[row]['MD5']}}
                                </div>
                            </div>
                            <div class="my-2 border-b pb-2">
                                <div class="flex flex-row items-center">
                                    <span class="w-3 h-3 border-2 border-green-500 rounded-full mr-1"></span>
                                    <div class="text-md font-bold">SHA256</div>
                                </div>
                                <div
                                    class="text-base font-mono break-all">{{buildInfo.agent.agentBuildInfo[row]['SHA256']}}
                                </div>
                            </div>
                            <div class="my-2 border-b pb-2">
                                <div class="flex flex-row items-center">
                                    <span class="w-3 h-3 border-2 border-orange-500 rounded-full mr-1"></span>
                                    <div class="text-md font-bold">File Name</div>
                                </div>
                                <div class="text-base font-mono break-all">
                                    {{buildInfo.agent.agentBuildInfo[row]['filename']}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</s-modal>

<s-modal id="migrationStatus">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row" *ngIf="migrationInfo || showAlert">
        <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
     bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">Replication Status</p>
            <img ngSrc="/assets/images/migration.png" alt="Replication Status" class="w-50 mt-10" height="512"
                 width="512">
        </div>
        <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-2 py-2" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="_modalService.close('migrationStatus');">
                <mat-icon>close</mat-icon>
            </button>
            <div class="m-4 flex flex-col gap-2">
                <!-- Alert -->
                <fuse-alert
                    class="mt-8"
                    *ngIf="showAlert"
                    [appearance]="'outline'"
                    [showIcon]="false"
                    [type]="alert.type">
                    <span *ngIf="title" fuseAlertTitle>{{title}}</span>
                    {{alert.message}}
                </fuse-alert>

                <ng-container *ngIf="!showAlert">
                    <div class="mx-auto max-w-screen-lg">
                      <div class="flex items-center justify-between">
                        <div>
                          <h2 class="font-semibold">Agent Replication Status</h2>
                          <span class="text-xs text-secondary">View status for each company, including those that have replicated,
                              are pending, or are currently offline</span>
                        </div>
                      </div>
                      <div class="grid grid-cols-4 gap-2 mb-4 mt-4">
                        <div class="flex flex-col rounded-xl p-2 ring-1 ring-primary-400 bg-primary-400 text-black">
                            <span class="font-bold text-2xl">{{migrationInfo.companies}}</span>
                            <span class="text-xs uppercase">Companies</span>
                        </div>
                        <div class="flex flex-col rounded-xl p-2 ring-1 ring-green-400 bg-green-400 text-black">
                            <span class="font-bold text-2xl">{{migrationInfo.migrated || 0}}</span>
                            <span class="text-xs uppercase">Total Replicated</span>
                        </div>
                        <div class="flex flex-col rounded-xl p-2 ring-1 ring-yellow-400 bg-yellow-400 text-black">
                            <span class="font-bold text-2xl">{{migrationInfo.pending || 0}}</span>
                            <span class="text-xs uppercase">Total Pending</span>
                        </div>
                        <div class="flex flex-col rounded-xl p-2 ring-1 ring-red-400 bg-red-400 text-black">
                            <span class="font-bold text-2xl">{{migrationInfo.offline || 0}}</span>
                            <span class="text-xs uppercase">Total Offline</span>
                        </div>
                      </div>
                      <div class="overflow-y-hidden rounded-lg border">
                        <div class="overflow-x-auto">
                          <table class="w-full">
                            <thead>
                              <tr class="bg-blue-600 text-left text-xs font-semibold uppercase tracking-widest text-white">
                                <th class="px-5 py-3">Company</th>
                                <th class="px-5 py-3">Replicated</th>
                                <th class="px-5 py-3">Pending</th>
                                <th class="px-5 py-3">Offline</th>
                              </tr>
                            </thead>
                            <tbody class="text-xs">
                              <tr *ngFor="let row of Objectkeys(migrationInfo.data)">
                                <td class="border-b px-1 py-0.5 text-xs">
                                  <p class="whitespace-no-wrap">
                                      {{migrationInfo.data[row].name}}
                                  </p>
                                </td>
                                <td class="border-b px-1 py-0.5 text-sm text-center">
                                  <span class="rounded-full bg-green-200 px-3 py-1 text-xs font-semibold text-green-900">
                                      {{migrationInfo.data[row].migrated}}
                                  </span>
                                </td>
                                  <td class="border-b px-1 py-0.5 text-sm text-center">
                                  <span class="rounded-full bg-yellow-200 px-3 py-1 text-xs font-semibold text-black">
                                      {{migrationInfo.data[row].pending}}
                                  </span>
                                </td>
                                  <td class="border-b px-1 py-0.5 text-sm text-center">
                                  <span class="rounded-full bg-red-200 px-3 py-1 text-xs font-semibold text-red-900">
                                      {{migrationInfo.data[row].offline}}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="flex flex-col items-center border-t px-2 py-2 sm:flex-row sm:justify-between">
                          <span class="text-xs text-secondary sm:text-sm"> Showing 1 to {{Objectkeys(migrationInfo.data).length}} of {{Objectkeys(migrationInfo.data).length}} Entries </span>
                        </div>
                      </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</s-modal>
