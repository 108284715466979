import { NgIf, NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TableComponent } from '../table/table.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'suid-permissions',
  standalone: true,
  imports: [NgIf, NgFor, TableComponent],
  templateUrl: './suid-permissions.component.html',
  styleUrls: ['./suid-permissions.component.scss']
})
export class SuidPermissionsComponent {
  @Input() asset: any;
  suidTableOptions: any = {
    columns: [
      {
        "header": "Group Name",
        "columnDef": "group_name",
        "cType": "string",
        "isHyperlink": false,
        "filter": "",
        "cell": "(element: any) => `${element.group_name}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        "header": "Path",
        "columnDef": "path",
        "cType": "string",
        "isHyperlink": false,
        "filter": "cleanString",
        "cell": "(element: any) => `${element.path}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      /*{
        "header": "Permissions",
        "columnDef": "permissions",
        "cType": "string",
        "isHyperlink": false,
        "filter": "cleanString",
        "cell": "(element: any) => `${element.permissions}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },*/
      {
        header: "Permissions",
        isColoredCntrl: true,
        isCustomText: true,
        isCustomClass: true,
        columnDef: "permissions",
        cType: "string",
        filter: "",
        cell: "(element: any) => `${element.permissions}`",
        order: 3,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        "header": "User Name",
        "columnDef": "username",
        "cType": "string",
        "isHyperlink": false,
        "filter": "cleanString",
        "cell": "(element: any) => `${element.username}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      }
    ],
    sortOptions: { active: 'group_name', direction: 'desc' },
    _pageData: [],
    tableOptions: {
      title: 'SUID Permissions',
      isServerSide: false,
      selectText: 'suid',
      loading: true,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showFilter: true,
      showTagFilter: false,
      showhideList: true,
      refreshData: true,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/report_queries/suid_permissions',
        condition: '',
      },
      id: 'suid_permission'
    },
    customText: [
      {
        status: "G",
        DisplayText: "Group",
        class: "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
      },
      {
        status: "S",
        DisplayText: "Set User ID",
        class: "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
      },
      {
        status: "SG",
        DisplayText: "Set Group ID",
        class: "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
      },
    ],
    changeValue: new Subject<any>(),
  };
  ngOnInit(): void {
    this.suidTableOptions.tableOptions.serverSide.condition = ` asset_id='${this.asset.id}'`;
  }
}
