export const environment = {
    production: true,
    baseURL: 'https://pod701.myconnectsecure.com',
    configURL: 'https://configuration.myconnectsecure.com/api/v4/configuration',
    configHost: 'https://configuration.myconnectsecure.com',
    authURL: 'https://authz.myconnectsecure.com',
    authHost: 'authz.myconnectsecure.com',
    isFqdn: false,
    logo_dark: '/assets/images/logo/cs_logo_dark.png',
    logo: '/assets/images/logo/cs_logo_light.png',
    icon: 'assets/images/logo/cs_logo_only.png',
    icon_dark: '/assets/images/logo/White_Turtle.png',
    productName: 'ConnectSecure',
    customDashboardUrl:"https://testsuperset.myconnectsecure.com",
    m356Url:"https://csinspect365.myconnectsecure.com",
    onprem: false,
    istest: true
};
