<mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="matDocDrawer.toggle()">
    <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerDocMode" [opened]="false"
        [ngClass]="{'w-full lg:w-[69vw]': drawerDocOpened}" [position]="'end'" [disableClose]="true" #matDocDrawer>
        <div class="flex flex-col flex-auto shrink-0 lg:shrink p-3 w-full h-[80vh] rounded-r-3xl bg-card"
            *ngIf="networkDocumentation && networkDocumentation.ip">
            <div class="flex flex-row items-center">
                <div class="text-2xl font-semibold tracking-tight">Asset Documentation Overview</div>
                <button class="ml-auto" mat-icon-button (click)="matDocDrawer.toggle();">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <mat-divider class="my-4"></mat-divider>
            <div
                class="flex flex-col flex-0 w-full overflow-hidden shadow-lg rounded-lg dark:bg-black dark:bg-opacity-10">
                <div class="flex flex-col flex-auto px-6 pb-6">
                    <div class="mt-2">
                        <div
                            class="flex items-center justify-center h-full border p-8 rounded-lg bg-gray-50 dark:bg-card">
                            <ng-container *ngIf="!networkDocumentation.icon">
                                <img ngSrc="/assets/images/os/generic.svg" alt="" width="16" height="16"
                                    class="w-12 h-12 rounded-full">
                            </ng-container>
                            <ng-container *ngIf="networkDocumentation.icon">
                                <img ngSrc="/assets/images/os/{{networkDocumentation.icon}}" alt="" width="16"
                                    height="16" class="w-12 h-12 rounded-full">
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex flex-row items-start mt-8">
                        <div class="text-2xl font-semibold tracking-tight">
                            <span class="text-blue-500">{{networkDocumentation.ip}}</span>
                            - {{networkDocumentation.host_name}}
                        </div>
                        <button *ngIf="!isEditNetworkDocumentation" mat-stroked-button (click)="toggleEditMode(true)"
                            class="ml-auto pr-4">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
                            <span class="ml-2">Edit</span>
                        </button>
                    </div>
                    <ng-container *ngIf="!isEditNetworkDocumentation">
                        <div class="text-lg font-semibold my-4">Information</div>
                        <div class="flex flex-col border-t border-b divide-y font-medium">
                            <div class="flex items-center py-1">
                                <div class="text-secondary w-[20vw]">Category</div>
                                <div>{{networkDocumentation.category}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary w-[20vw]">Type</div>
                                <div>{{networkDocumentation.type}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary w-[20vw]">System Type</div>
                                <div>{{networkDocumentation.system_type}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary w-[20vw]">MAC Address</div>
                                <div>{{networkDocumentation.mac}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary w-[20vw]">Importance</div>
                                <div>
                                    <span [ngClass]="{'bg-blue-200 text-blue-600': networkDocumentation.importance === 25,
                        'bg-yellow-200 text-yellow-600': networkDocumentation.importance === 50,
                        'bg-orange-200 text-orange-600': networkDocumentation.importance === 75,
                        'bg-red-200 text-red-600': networkDocumentation.importance === 100
                        }" matTooltip="Importance"
                                        class="mb-1 py-1 px-2 rounded-full text-xs text-black font-bold pointer"
                                        [matMenuTriggerFor]="menutagRef" #menuRefTrigger="matMenuTrigger">
                                        {{importance[networkDocumentation.importance]}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-lg font-semibold mt-4">Asset Details</div>
                        <div class="flex flex-col border-t border-b divide-y font-medium">
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Purchase Date</div>
                                <div>{{networkDocumentation.purchase_date | date: 'MM/dd/yyyy'}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Deployment Date</div>
                                <div>{{networkDocumentation.deployment_date | date: 'MM/dd/yyyy'}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Location</div>
                                <div>{{networkDocumentation.location}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Owner</div>
                                <div>
                                    <span *ngIf="networkDocumentation.owner_name">
                                        {{networkDocumentation.owner_name}},
                                    </span>
                                    <span *ngIf="networkDocumentation.owner_contact_number">
                                        {{networkDocumentation.owner_contact_number}},
                                    </span>
                                    <span *ngIf="networkDocumentation.owner_email">
                                        {{networkDocumentation.owner_email}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="text-lg font-semibold mt-4">Financial Information</div>
                        <div class="flex flex-col border-t border-b divide-y font-medium">
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Cost Center</div>
                                <div>{{networkDocumentation.cost_center}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">PO Number</div>
                                <div>{{networkDocumentation.po_number}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Department</div>
                                <div>{{networkDocumentation.department}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Warranty Start Date</div>
                                <div>{{networkDocumentation.warranty_start_date | date: 'MM/dd/yyyy'}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Warranty End Date</div>
                                <div>{{networkDocumentation.warranty_end_date | date: 'MM/dd/yyyy'}}</div>
                            </div>
                        </div>
                        <div class="text-lg font-semibold mt-4">Extra Information</div>
                        <div class="flex flex-col mb-4 border-t border-b divide-y font-medium">
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Cost Center</div>
                                <div>{{networkDocumentation.cost_center}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Backup Status</div>
                                <div>{{networkDocumentation.backup_status}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">Remote Access Status</div>
                                <div>{{networkDocumentation.remote_access_status}}</div>
                            </div>
                            <div class="flex items-center py-1">
                                <div class="text-secondary  w-[20vw]">EOL Status</div>
                                <div>{{networkDocumentation.eol_status}}</div>
                            </div>
                        </div>
                        <div class="pt-4 w-full mt-4">
                            <div class="flex flex-col flex-auto lg:overflow-hidden">
                                <div class="font-semibold"> Auto Tags </div>
                                <mat-divider class="my-4"></mat-divider>
                                <div class="flex flex-col flex-auto lg:overflow-hidden"
                                    *ngIf="!networkDocumentation.defaultTags || !networkDocumentation.defaultTags.length">
                                    <div class="flex flex-row gap-2 flex-auto items-center justify-center">
                                        <mat-icon class="icon-size-8" [svgIcon]="'heroicons_outline:tag'"></mat-icon>
                                        <div class="text-lg font-semibold tracking-tight text-secondary">No auto tags
                                            found
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-row items-center flex-wrap gap-2"
                                    *ngIf="networkDocumentation.defaultTags && networkDocumentation.defaultTags.length">
                                    <ng-container *ngFor="let tag of networkDocumentation.defaultTags">
                                        <div class="flex items-start justify-start">
                                            <span
                                                class="bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{tag.name}}:
                                                {{tag.value}}
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="pt-4 w-full">
                            <div class="flex flex-col flex-auto lg:overflow-hidden">
                                <div class="font-semibold"> Manual Tags </div>
                                <mat-divider class="my-4"></mat-divider>
                                <div class="flex flex-col flex-auto lg:overflow-hidden"
                                    *ngIf="!networkDocumentation.manualTags || !networkDocumentation.manualTags.length">
                                    <div class="flex flex-row gap-2 flex-auto items-center justify-center">
                                        <mat-icon class="icon-size-8" [svgIcon]="'heroicons_outline:tag'"></mat-icon>
                                        <div class="text-lg font-semibold tracking-tight text-secondary">No manual tags
                                            found
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-row items-center flex-wrap gap-2"
                                    *ngIf="networkDocumentation.manualTags && networkDocumentation.manualTags.length">
                                    <ng-container *ngFor="let tag of networkDocumentation.manualTags">
                                        <div class="flex items-start justify-start">
                                            <span
                                                class="bg-purple-100 text-purple-800 text-sm font-bold me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{tag.name}}:
                                                {{tag.value}}
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <mat-divider
                            class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 my-2"></mat-divider>
                        <p class="flex-1 text-base font-bold my-2">Software</p>
                        <div class="mt-2 overflow-hidden rounded-xl bg-card shadow">
                            <table class="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                                <thead class="hidden border-b lg:table-header-group">
                                    <tr class="">
                                        <td class="whitespace-normal py-4 text-sm font-bold sm:px-3">Name</td>
                                        <td class="whitespace-normal py-4 text-sm font-bold sm:px-3">Version
                                        </td>
                                    </tr>
                                </thead>
                                <tbody class="bg-card lg:border-gray-300">
                                    <tr class="border-b"
                                        *ngFor="let items of networkDocumentationSW; let index = index; trackBy: trackByFn">
                                        <td
                                            class="hidden py-1 text-sm font-semibold text-blue-800 dark:text-white sm:px-3 lg:table-cell">
                                            <span>{{items.name}}</span>
                                        </td>
                                        <td class="hidden py-4 text-sm sm:px-3 lg:table-cell" align="rigth">
                                            <span>{{items.version}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <mat-paginator [pageSizeOptions]="pageOptions" [pageSize]="pageSize" [length]="pageTotal"
                                showFirstLastButtons aria-label="Select page of periodic elements"
                                (page)="pageChanged($event)">
                            </mat-paginator>
                        </div>
                        <mat-divider
                            class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 my-2"></mat-divider>
                        <div class="text-lg font-semibold mt-2">PO Document</div>
                        <div class="flex flex-col flex-auto lg:overflow-hidden mt-2"
                            *ngIf="!networkDocumentation.po_document || !networkDocumentation.po_document.length">
                            <div class="flex flex-row gap-2 flex-auto items-center justify-center">
                                <mat-icon class="icon-size-8" [svgIcon]="'mat_outline:attach_file'"></mat-icon>
                                <div class="text-lg font-semibold tracking-tight text-secondary">No PO document was found.
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap mt-2"
                            *ngIf="networkDocumentation.po_document && networkDocumentation.po_document.length">
                            <ng-container *ngFor="let attachment of networkDocumentation.po_document">
                                <div class="flex items-center m-3">
                                    <div
                                        class="flex items-center justify-center w-10 h-10 rounded-md overflow-hidden text-indigo-800 bg-indigo-100">
                                        <div class="flex items-center justify-center text-sm font-semibold">
                                            {{attachment.name.split('.')[1].trim().toUpperCase()}}
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <div class="text-md font-medium truncate" [title]="attachment.name">
                                            {{attachment.name.split('.')[0].trim()}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <mat-divider
                            class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 my-2"></mat-divider>
                        <div class="text-lg font-semibold mt-2">Warranty Document</div>
                        <div class="flex flex-col flex-auto lg:overflow-hidden mt-2"
                            *ngIf="!networkDocumentation.warranty_document|| !networkDocumentation.warranty_document.length">
                            <div class="flex flex-row gap-2 flex-auto items-center justify-center">
                                <mat-icon class="icon-size-8" [svgIcon]="'mat_outline:attach_file'"></mat-icon>
                                <div class="text-lg font-semibold tracking-tight text-secondary">No warranty document was
                                    found.
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap mt-2"
                            *ngIf="networkDocumentation.warranty_document && networkDocumentation.warranty_document.length">
                            <ng-container *ngFor="let attachment of networkDocumentation.warranty_document">
                                <div class="flex items-center m-3">
                                    <div
                                        class="flex items-center justify-center w-10 h-10 rounded-md overflow-hidden text-indigo-800 bg-indigo-100">
                                        <div class="flex items-center justify-center text-sm font-semibold">
                                            {{attachment.name.split('.')[1].trim().toUpperCase()}}
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <div class="text-md font-medium truncate" [title]="attachment.name">
                                            {{attachment.name.split('.')[0].trim()}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <mat-divider
                            class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 my-2"></mat-divider>
                        <div class="text-lg font-semibold mt-2">Other Documents</div>
                        <div class="flex flex-col flex-auto lg:overflow-hidden mt-2"
                            *ngIf="!networkDocumentation.extra_document || !networkDocumentation.extra_document.length">
                            <div class="flex flex-row gap-2 flex-auto items-center justify-center">
                                <mat-icon class="icon-size-8" [svgIcon]="'mat_outline:attach_file'"></mat-icon>
                                <div class="text-lg font-semibold tracking-tight text-secondary">No other document was
                                    found.
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap mt-2">
                            <ng-container *ngFor="let attachment of networkDocumentation.extra_document">
                                <div class="flex items-center m-3">
                                    <div
                                        class="flex items-center justify-center w-10 h-10 rounded-md overflow-hidden text-indigo-800 bg-indigo-100">
                                        <div class="flex items-center justify-center text-sm font-semibold">
                                            {{attachment.name.split('.')[1].trim().toUpperCase()}}
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <div class="text-md font-medium truncate" [title]="attachment.name">
                                            {{attachment.name.split('.')[0].trim()}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isEditNetworkDocumentation">
                        <div
                            class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
                            <div class="flex flex-col w-full h-full">
                                <div class="text-lg font-semibold my-2">Information</div>
                                <div class="grid grid-cols-2 gap-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Catergory</mat-label>
                                        <input matInput name="category" required
                                            [(ngModel)]="_network_doc_data.category" />
                                    </mat-form-field>
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Type</mat-label>
                                        <input matInput name="type" required [(ngModel)]="_network_doc_data.type" />
                                    </mat-form-field>
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>System Type</mat-label>
                                        <input matInput name="sytem_type" required
                                            [(ngModel)]="_network_doc_data.system_type" />
                                    </mat-form-field>
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Location</mat-label>
                                        <input matInput name="location" required
                                            [(ngModel)]="_network_doc_data.location" />
                                    </mat-form-field>
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Purchase Date</mat-label>
                                        <input matInput [matDatepicker]="purchase_date"
                                            [(ngModel)]="_network_doc_data.purchase_date" required
                                            name="registryendDate">
                                        <mat-datepicker-toggle matSuffix [for]="purchase_date"></mat-datepicker-toggle>
                                        <mat-datepicker #purchase_date></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Deployment Date</mat-label>
                                        <input matInput [matDatepicker]="deployment_date"
                                            [(ngModel)]="_network_doc_data.deployment_date" required
                                            name="registryendDate">
                                        <mat-datepicker-toggle matSuffix
                                            [for]="deployment_date"></mat-datepicker-toggle>
                                        <mat-datepicker #deployment_date></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                                <div class="flex flex-col w-full h-full">
                                    <div class="text-lg font-semibold">Owner Information</div>
                                    <div class="grid grid-cols-2 gap-2">
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Name</mat-label>
                                            <input matInput name="owner_name" required
                                                [(ngModel)]="_network_doc_data.owner_name" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Contact Number</mat-label>
                                            <input matInput name="owner_number" required
                                                [(ngModel)]="_network_doc_data.owner_contact_number" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Email</mat-label>
                                            <input matInput name="email" required
                                                [(ngModel)]="_network_doc_data.owner_email" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                                <div class="flex flex-col w-full h-full">
                                    <div class="text-lg font-semibold">Financial Information</div>
                                    <div class="grid grid-cols-2 gap-2">
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Cost Center</mat-label>
                                            <input matInput name="cost_center" required
                                                [(ngModel)]="_network_doc_data.cost_center" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>PO Number</mat-label>
                                            <input matInput name="po_number" required
                                                [(ngModel)]="_network_doc_data.po_number" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Department</mat-label>
                                            <input matInput name="department" required
                                                [(ngModel)]="_network_doc_data.department" />
                                        </mat-form-field>
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Warranty Start Date</mat-label>
                                            <input matInput [matDatepicker]="warranty_start_date"
                                                [(ngModel)]="_network_doc_data.warranty_start_date" [min]="minEndDate"
                                                required name="registryendDate">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="warranty_start_date"></mat-datepicker-toggle>
                                            <mat-datepicker #warranty_start_date></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Warranty End Date</mat-label>
                                            <input matInput [matDatepicker]="warranty_end_date"
                                                [(ngModel)]="_network_doc_data.warranty_end_date" [min]="minEndDate"
                                                required name="registryendDate">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="warranty_end_date"></mat-datepicker-toggle>
                                            <mat-datepicker #warranty_end_date></mat-datepicker>
                                        </mat-form-field>
                                        <div>
                                            <label class="text-sm ml-2">Backup Status</label>
                                            <mat-radio-group class="reason-radio-group flex flex-row items-center"
                                                name="backup_status" [(ngModel)]="_network_doc_data.backup_status">
                                                <mat-radio-button class="reason-radio-button"
                                                    *ngFor="let item of ['Yes', 'No']; let i=index" [value]="item"
                                                    color="primary" id="readson{{i}}">
                                                    {{item}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div>
                                            <label class="text-sm ml-2">Remote Access Status</label>
                                            <mat-radio-group class="reason-radio-group flex flex-row items-center"
                                                name="remote_access_status"
                                                [(ngModel)]="_network_doc_data.remote_access_status">
                                                <mat-radio-button class="reason-radio-button"
                                                    *ngFor="let item of ['Yes', 'No']; let i=index" [value]="item"
                                                    color="primary" id="readson{{i}}">
                                                    {{item}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div>
                                            <label class="text-sm ml-2">EOL Status</label>
                                            <mat-radio-group class="reason-radio-group flex flex-row items-center"
                                                name="eol_status" [(ngModel)]="_network_doc_data.eol_status">
                                                <mat-radio-button class="reason-radio-button"
                                                    *ngFor="let item of ['Yes', 'No']; let i=index" [value]="item"
                                                    color="primary" id="readson{{i}}">
                                                    {{item}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>

                                    </div>
                                </div>
                                <mat-divider class="my-2"></mat-divider>
                                <div class="flex flex-col w-full h-full">
                                    <div class="text-lg font-semibold my-2">PO Document</div>
                                    <div class="flex items-center justify-center w-full">
                                        <label for="dropzone-file"
                                            class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 20 16">
                                                    <path stroke="currentColor" stroke-linecap="round"
                                                        stroke-linejoin="round" stroke-width="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                        class="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                            </div>
                                            <input id="dropzone-file" type="file" class="hidden"
                                                (change)="uploadImage($event)" />
                                        </label>
                                    </div>

                                    <div class="bg-card mt-4 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700"
                                        *ngIf="_network_doc_data.po_document && _network_doc_data.po_document.length">
                                        <div class="" *ngFor="let item of _network_doc_data.po_document">
                                            <div class="flex items-center justify-between mb-2">
                                                <div class="flex items-center space-x-4">
                                                    <div
                                                        class="w-10 h-10 bg-gray-200 rounded-lg flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="h-6 w-6 text-gray-500" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3 class="text-gray-800 font-medium text-sm truncate max-w-md">
                                                            {{item.name}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <button class="text-gray-400 hover:text-gray-600 transition-colors"
                                                    (click)="_network_doc_data.po_document.splice(i, 1)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="my-2"></mat-divider>
                                <div class="flex flex-col w-full h-full">
                                    <div class="text-lg font-semibold my-2">Warranty Document</div>
                                    <div class="flex items-center justify-center w-full">
                                        <label for="warrany-file"
                                            class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 20 16">
                                                    <path stroke="currentColor" stroke-linecap="round"
                                                        stroke-linejoin="round" stroke-width="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                        class="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                            </div>
                                            <input id="warrany-file" type="file" class="hidden"
                                                (change)="uploadWarrantyImage($event)" />
                                        </label>
                                    </div>

                                    <div class="bg-card mt-4 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700"
                                        *ngIf="_network_doc_data.warranty_document && _network_doc_data.warranty_document.length">
                                        <div class="" *ngFor="let item of _network_doc_data.warranty_document">
                                            <div class="flex items-center justify-between mb-2">
                                                <div class="flex items-center space-x-4">
                                                    <div
                                                        class="w-10 h-10 bg-gray-200 rounded-lg flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="h-6 w-6 text-gray-500" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3 class="text-gray-800 font-medium text-sm truncate max-w-md">
                                                            {{item.name}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <button class="text-gray-400 hover:text-gray-600 transition-colors"
                                                    (click)="_network_doc_data.warranty_document.splice(i, 1)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="my-2"></mat-divider>
                                <div class="flex flex-col w-full h-full">
                                    <div class="text-lg font-semibold my-2">Other Documents</div>
                                    <div class="flex items-center justify-center w-full">
                                        <label for="extra-dropzone-file"
                                            class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 20 16">
                                                    <path stroke="currentColor" stroke-linecap="round"
                                                        stroke-linejoin="round" stroke-width="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                        class="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                            </div>
                                            <input id="extra-dropzone-file" type="file" class="hidden"
                                                (change)="uploadExtraImage($event)" />
                                        </label>
                                    </div>
                                    <div class="bg-card mt-4 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700"
                                        *ngIf="_network_doc_data.extra_document && _network_doc_data.extra_document.length">
                                        <div class="" *ngFor="let item of _network_doc_data.extra_document">
                                            <div class="flex items-center justify-between mb-2">
                                                <div class="flex items-center space-x-4">
                                                    <div
                                                        class="w-10 h-10 bg-gray-200 rounded-lg flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="h-6 w-6 text-gray-500" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h3 class="text-gray-800 font-medium text-sm truncate max-w-md">
                                                            {{item.name}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <button class="text-gray-400 hover:text-gray-600 transition-colors"
                                                    (click)="_network_doc_data.extra_document.splice(i, 1)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-row items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
                                        appPreventMultiClick [matTooltip]="'Save'" (click)="saveNetworkDocumentation()">
                                        Save
                                    </button>
                                    <button id="closeButton" (click)="isEditNetworkDocumentation=false"
                                        mat-flat-button>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="flex flex-col">
        <div class="flex-auto p-1">
            <div class="absolute right-[2%] top-[1%] z-10" *ngIf="!isColumnView">
                <button type="button" matTooltip="Toggle view" (click)="toggleChangeFn()"
                    class="relative rounded-md focus:outline-none focus:ring-2 focus:ring-white">
                    <span class="absolute -inset-2.5"></span>
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <ng-container *ngIf="cAsset && cAsset.ip && showData">
                <div
                    class="border-t max-w-full grid grid-cols-3 gap-y-4 bg-card border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                    <!--bg-gradient-to-t from-indigo-500 to-blue-500-->
                    <div class="col-span-3 -mx-4 px-4 py-8 sm:col-span-1 sm:mx-0 border-r
                     sm:rounded-l-2xl sm:py-4 min-h-[34rem] max-h-[34rem] h-[34rem]">
                        <div class="mb-2 font-medium  flex flex-wrap gap-2 items-center">
                            <img ngSrc="assets/images/on.png" alt="Online" class="w-8"
                                *ngIf="cAsset && cAsset.online_status && ['PROBE', 'LIGHTWEIGHT'].indexOf(cAsset.agent_type) > -1"
                                height="512" width="512">
                            <img ngSrc="assets/images/off.png" alt="Offline" class="w-8"
                                *ngIf="cAsset && !cAsset.online_status && ['PROBE', 'LIGHTWEIGHT'].indexOf(cAsset.agent_type) > -1"
                                height="512" width="512">
                            <div class="font-semibold cursor-pointer" [matMenuTriggerFor]="assetNameMenu"
                                (click)="asset_name = (cAsset.visible_name || cAsset.host_name)"
                                #assetNameMenuTrigger="matMenuTrigger">{{(cAsset.visible_name || cAsset.host_name ||
                                cAsset.ip)}}
                            </div>
                            <div matTooltip="Copy">
                                <mat-icon color="primary" class="icon-size-4 cursor-pointer" [svgIcon]="'content_copy'"
                                    (click)="cs.copyClip(cAsset.visible_name || cAsset.host_name ||
                                        cAsset.ip);">
                                </mat-icon>
                            </div>
                            <mat-menu #assetNameMenu="matMenu" [hasBackdrop]="false">
                                <div class="rounded bg-card p-4" (click)="$event.stopPropagation();"
                                    (keydown)="$event.stopPropagation()">
                                    <form #nameForm="ngForm" role="form">
                                        <mat-form-field class="w-full mt-2" [subscriptSizing]="'dynamic'">
                                            <mat-label>Name</mat-label>
                                            <input matInput placeholder="Enter name" type="text" trim="blur" required
                                                name="host_name" [(ngModel)]="asset_name" autocomplete="off">
                                            <mat-hint>Asset Name / Host Name</mat-hint>
                                        </mat-form-field>

                                        <div class="flex items-center mt-10 py-4 border-t">
                                            <button class="ml-auto" mat-button [matTooltip]="'Cancel'" type="button"
                                                (click)="assetNameMenuTrigger.closeMenu();">
                                                Cancel
                                            </button>
                                            <button class="ml-2" mat-flat-button [color]="'primary'"
                                                [matTooltip]="'Update'" type="submit" (click)="updateAssetName()"
                                                (keydown.enter)="$event.preventDefault(); updateAssetName()"
                                                [disabled]="!nameForm.form.valid">
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </mat-menu>
                            <!-- <div class="spacer"></div> -->
                            <!-- *ngIf="cAsset.vul_stats && cAsset.avg_risk_score" -->
                            <div matTooltip="Risk Score"
                                class="max-w-xs flex relative text-white right-1 items-center justify-center h-6 w-6 rounded-full p-1 m-1 font-bold text-lg"
                                [ngClass]="{
                                    'bg-yellow-600': cAsset.riskScore === '-' || cAsset.riskScore === 'B' || cAsset.riskScore === 'C',
                                    'bg-green-500': cAsset.riskScore === 'A',
                                    'bg-orange-600': cAsset.riskScore === 'D',
                                    'bg-red-600': cAsset.riskScore === 'E' || cAsset.riskScore === 'F'
                                    }">
                                <button (click)="riskScore()">
                                    {{cAsset.riskScore}}
                                </button>
                            </div>
                            <span [ngClass]="{'bg-blue-200 text-blue-600': cAsset.importance === 25,
                                'bg-yellow-200 text-yellow-600': cAsset.importance === 50,
                                'bg-orange-200 text-orange-600': cAsset.importance === 75,
                                'bg-red-200 text-red-600': cAsset.importance === 100
                                }" matTooltip="Importance" class="inline-flex items-center cursor-pointer
                                       rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                                [matMenuTriggerFor]="menuRef" #menuRefTrigger="matMenuTrigger">
                                {{importance[cAsset.importance]}}</span>
                            <mat-menu #menuRef="matMenu">
                                <ng-container *ngFor="let val of Objectkeys(importance)">
                                    <button mat-menu-item *ngIf="cAsset.importance !== +val"
                                        (click)="updateAssetImportance(val)">{{importance[val]}} </button>
                                </ng-container>
                            </mat-menu>
                            <span
                                class="inline-block bg-teal-200 text-teal-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide"
                                *ngIf="cAsset.logged_in_user">
                                <i class="fas fa-user mx-1"></i>
                                Last Logon User: {{cAsset.logged_in_user}}
                            </span>
                            <span
                                class="inline-block text-teal-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide bg-white border border-green-500"
                                *ngIf="cAsset.agent_version === latest_agent_version"
                                [matTooltip]="'Agent Version is up to date'">
                                <i
                                    class="fas fa-solid fa-laptop-medical text-green-800 mx-1"></i>{{cAsset.agent_version}}<i
                                    class="fas fa-check-circle mx-1 text-green-800"></i>
                            </span>
                            <span
                                class="inline-bloc text-red-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide bg-white border border-red-500"
                                *ngIf="cAsset.agent_version !== latest_agent_version && cAsset.online_status"
                                [matTooltip]="'Click here to update the agent'" (click)="resetConfig()">
                                <i
                                    class="fas fa-solid fa-laptop-medical text-red-800 mx-1"></i>{{cAsset.agent_version}}<i
                                    class="fas fa-solid fa-download text-red-800 mx-1"></i>
                            </span>
                            <span
                                class="inline-block text-red-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide bg-white border border-red-500 opacity-50"
                                *ngIf="cAsset.agent_version !== latest_agent_version && !cAsset.online_status"
                                [matTooltip]="'Agent is outdated and asset is offline'">
                                <i class="fas fa-laptop-medical text-red-800 mx-1"></i>{{cAsset.agent_version}}
                            </span>
                            <span
                                class="inline-block bg-purple-200 text-purple-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide"
                                *ngIf="cAsset.discovered_protocols">
                                <i class="fab fa-searchengin mx-1"></i>
                                Discovered Using {{cAsset.discovered_protocols}}
                            </span>
                            <span matTooltip="Serial No." *ngIf="cAsset.serial_number" class="px-2 text-sm font-light rounded-r font-mono
                                 ">{{cAsset.serial_number}}</span>
                            <button type="button" class="outline-none" [disabled]="isDisbleReport"
                                [matTooltip]="'Download Vulnerability Report'" (click)="generateReport('vul', 'xlsx')">
                                <img class="hover:scale-125 transition duration-500 w-6"
                                    src="/assets/images/reports/xlsx.png" alt="Download Vulnerability Report">
                            </button>
                            <button type="button" class="outline-none" [disabled]="isDisbleReport"
                                [matTooltip]="'Download Asset Reports'" (click)="generateReport('full', 'docx')">
                                <img class="hover:scale-125 transition duration-500 w-6"
                                    src="/assets/images/reports/docx.png" alt="Download Asset Reports">
                            </button>
                            <span id="Add/UpdateCredential" class="mr-2 py-2 px-2 text-primary cursor-pointer"
                                *ngIf="cAsset && cAsset.agent_type === 'PROBE'" matTooltip="Add/Update Credential"
                                (click)="getCredentialById()">
                                <i class="fas fa-key"></i>
                            </span>
                            <span id="DeleteCredential" class="mr-2 py-2 px-2 text-red-600 cursor-pointer"
                                *ngIf="cAsset && cAsset.agent_type === 'PROBE'" matTooltip="Delete Credential"
                                (click)="deleteCredentialById()">
                                <i class="fas fa-trash-alt"></i>
                            </span>

                            <span
                                *ngIf="cAsset && cAsset.agent_type === 'PROBE' && !cAsset.auth_scan_status && cAsset.platform && credentialOs.indexOf(cAsset.platform) > -1"
                                class="inline-block bg-red-200 text-red-800 px-1.5 py-0.5 text-xs rounded-full font-semibold tracking-wide">
                                <i class="fas fa-bug mx-1"></i>
                                <span class=""> Credentials not available for Scan</span>
                            </span>
                        </div>
                        <div class="mb-2 flex max-w-xs">
                            <div
                                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center bg-card rounded-lg sm:mx-0 sm:h-12 sm:w-12">
                                <img *ngIf="!cAsset.icon" ngSrc="/assets/images/os/generic.svg" alt="" width="16"
                                    height="16" class="w-10 h-10 sm:h-8 sm:w-8">
                                <img *ngIf="cAsset.icon" ngSrc="/assets/images/os/{{cAsset.icon}}" alt="" width="16"
                                    height="16" class="w-10 h-10 sm:h-8 sm:w-8">
                            </div>
                            <div class="">
                                <p class="text-2xl font-black ">{{cAsset.ip}}</p>
                                <p class="font-light text-sm font-mono ">{{cAsset.mac}}</p>
                            </div>
                            <div class="ml-4">
                                <button mat-icon-button class="primary" (click)="viewNW()">
                                    <mat-icon class="mat-18">insert_drive_file</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 sm:grid-cols-4 gap-2">
                            <ng-container *ngFor="let s of vulstats;">
                                <div class="flex flex-col bg-red-200 rounded px-2 py-0.5" [matTooltip]="s.name"
                                    *ngIf="cAsset[s.key]">
                                    <p class="text-xl font-bold text-red-700 ">{{cAsset[s.key] | shortNumber}}</p>
                                    <p class="text-xs font-medium text-red-500">{{s.title | uppercase}}</p>
                                </div>
                            </ng-container>
                        </div>
                        <div class="border-t border-blue-400 my-2" *ngIf="false"></div>
                        <div class="grid grid-cols-2 sm:grid-cols-3 gap-2" *ngIf="false">
                            <div class="dark:bg-gray-700 border border-blue-400 rounded px-2 py-0.5">
                                <p class="text-xl font-bold">{{cAsset.software_count | shortNumber}}</p>
                                <p class="text-xs">Software</p>
                            </div>
                            <div class="dark:bg-gray-700 border border-blue-400 rounded px-2 py-0.5">
                                <p class="text-xl font-bold" matTooltip="Open Internal Ports">
                                    {{cAsset.open_port_internal |
                                    shortNumber}}</p>
                                <p class="text-xs">Internal Ports</p>
                            </div>
                            <div class="dark:bg-gray-700 border border-blue-400 rounded px-2 py-0.5">
                                <p class="text-xl font-bold" matTooltip="Open External Ports">
                                    {{cAsset.open_port_external |
                                    shortNumber}}</p>
                                <p class="text-xs">External Ports</p>
                            </div>
                            <div class="dark:bg-gray-700 border border-blue-400 rounded px-2 py-0.5">
                                <p class="text-xl font-bold">{{cAsset.total_vul_count | shortNumber}}</p>
                                <p class="text-xs">Vulnerabilities</p>
                            </div>
                            <div class="dark:bg-gray-700 border border-blue-400 rounded px-2 py-0.5">
                                <p class="text-xl font-bold">{{cAsset.risk_score}}</p>
                                <p class="text-xs">Risk Score</p>
                            </div>
                        </div>
                        <div class="border-t border-blue-400 mt-2"></div>
                        <div class="flex flex-row justify-between mt-4 gap-2 my-2 ">
                            <div>
                                <span
                                    class="bg-slate-200 text-slate-600 rounded-full px-2 py-0.5 text-xs font-medium">Last
                                    Scanned Time </span>
                                <p class="text-md  font-bold">{{cAsset.last_discovered_time | utcToLocale}}</p>
                            </div>
                            <div>
                                <span
                                    class="bg-slate-200 text-slate-600 rounded-full px-2 py-0.5 text-xs font-medium">Discovered
                                    Time</span>
                                <p class="text-md  font-bold">{{cAsset.discovered | utcToLocale}}</p>
                            </div>
                        </div>
                        <div class="flex flex-row justify-between mt-4 gap-2 my-2">
                            <div
                                *ngIf="cAsset && cAsset.agent_type === 'PROBE' && cAsset.auth_scan_status && cAsset.last_discovered_time">
                                <span class="bg-slate-200 text-slate-600 rounded-full px-2 py-0.5 text-xs font-medium">
                                    Last Vulnerability Scanned Time </span>
                                <p class="text-md  font-bold">{{cAsset.last_discovered_time | utcToLocale}}</p>
                            </div>
                            <div *ngIf="cAsset && cAsset.agent_type && cAsset.agent_type === 'LIGHTWEIGHT'">
                                <span class="bg-slate-200 text-slate-600 rounded-full px-2 py-0.5 text-xs font-medium">
                                    Last Ping Time </span>
                                <p class="text-md  font-bold">{{cAsset.last_ping_time | utcToLocale}}</p>
                            </div>
                        </div>
                        <div class="border-t border-blue-400 mt-2"></div>
                        <div class="w-full mt-4 gap-2 my-2 ">
                            <div>
                                <div class="flex flex-row items-center">
                                    <div>
                                        <span
                                            class="bg-slate-200 text-slate-600 rounded-full px-2 py-0.5 text-xs font-medium">
                                            Asset Owner
                                        </span>
                                    </div>
                                    <ng-container *ngIf="!editing && cAsset.asset_owner">
                                        <button id="editOwner" matTooltip="Edit" [color]="'primary'" class="pt-1"
                                            (click)="toggleEditing()">
                                            <mat-icon class="ml-2 icon-size-4">edit</mat-icon>
                                        </button>
                                    </ng-container>
                                    <button id="SaveAssetOwner" class="pt-1" matTooltip="Save"
                                        *ngIf="editing || (!cAsset.asset_owner && !editing)" (click)="SaveAssetOwner()"
                                        [color]="'primary'">
                                        <mat-icon class="ml-2 icon-size-4">save</mat-icon>
                                    </button>
                                </div>
                                <mat-form-field *ngIf="editing || !cAsset.asset_owner" class="w-full mt-2"
                                    [subscriptSizing]="'dynamic'">
                                    <input matInput [placeholder]="'Enter Asset Owner'" name="name"
                                        [(ngModel)]="assetOwner" [spellcheck]="false">
                                </mat-form-field>
                                <p class="ml-2 text-md font-bold" *ngIf="!editing && cAsset.asset_owner">
                                    {{ cAsset.asset_owner}}</p>
                            </div>
                        </div>
                        <span
                            class="inline-block bg-blue-200 mt-2 text-blue-800 px-1.5 py-0.5 text-md rounded-full font-semibold tracking-wide"
                            *ngIf="cs.currentScope === '*'">
                            <i class="fa fa-building mx-1"></i>
                            Company Name: {{cAsset.company_name}}
                        </span>
                    </div>
                    <div class="col-span-3 -mx-4
                     px-4 py-8 sm:col-span-2 sm:mx-0 sm:rounded-r-2xl sm:py-4 min-h-[34rem] max-h-[34rem] h-[34rem]">
                        <div class="">
                            <div class="-mx-4">
                                <mat-tab-group class="stab" mat-stretch-tabs="false" mat-align-tabs="start">
                                    <mat-tab class="py-1" label="System">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-desktop mr-2" id="System"></i> System
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <table class="table-auto">
                                                <tbody class="text-sm font-light ">
                                                    <tr class="border-b" *ngIf="cAsset.reboot_required_since">
                                                        <td class="py-3 px-4 text-left">Reboot Required</td>
                                                        <td class="py-3 px-4 text-left font-light">
                                                            <ng-container
                                                                *ngIf="cAsset.reboot_required_since !== 'Not_Required'">
                                                                <span><i
                                                                        class="fas fa-power-off text-red-400 animate-pulse blink"></i></span>
                                                                <span
                                                                    class="px-2 font-mono">{{cAsset.reboot_required_since}}</span>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="cAsset.reboot_required_since === 'Not_Required'">
                                                                <span><i
                                                                        class="fas fa-power-off text-green-400"></i></span>
                                                                <span
                                                                    class="px-2 font-mono">{{cAsset.reboot_required_since
                                                                    |
                                                                    camelToHuman:true}}</span>
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">CPU Cores</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-microchip text-green-400 pr-2"></i>
                                                            {{cAsset.cpu_core}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">RAM</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-memory text-green-400 pr-2"></i>
                                                            {{cAsset.physical_memory | bytesConvert}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">Platform</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-laptop-code text-green-400 pr-2"></i>
                                                            {{cAsset.platform | uppercase}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">Architecture</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-microchip text-green-400 pr-2"></i>
                                                            {{cAsset.architecture}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">OS</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-laptop-code text-green-400 pr-2"></i>
                                                            {{cAsset.os_name}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">OS Version</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-desktop text-green-400 pr-2"></i>
                                                            {{cAsset.os_version}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">OS Build</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-code-branch text-green-400 pr-2"></i>
                                                            {{cAsset.os_build}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b" *ngIf="cAsset.platform === 'windows'">
                                                        <td class="py-1 px-4 text-left">Full OS Build</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-code-branch text-green-400 pr-2"></i>
                                                            {{cAsset.full_os_build}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">Kernel</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-microchip text-green-400 pr-2"></i>
                                                            {{cAsset.kernel}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">OS Autoupdate</td>
                                                        <td class="py-1 px-4 text-left">
                                                            <i
                                                                class="fas fa-arrow-alt-circle-up text-green-400 pr-2"></i>
                                                            <span class=" text-gray-700 text-sm rounded-2xl px-1 py-0.5"
                                                                [ngClass]="{'bg-red-200': cAsset.os_autoupdate && cAsset.os_autoupdate.toLowerCase() === 'error',
                                                                  'bg-green-200': cAsset.os_autoupdate && cAsset.os_autoupdate.toLowerCase() === 'good'
                                                                  }">
                                                                <span class="text-xs"
                                                                    *ngIf=" cAsset.os_autoupdate && cAsset.os_autoupdate.toLowerCase() === 'error'">Disabled</span>
                                                                <span class="text-xs"
                                                                    *ngIf=" cAsset.os_autoupdate && cAsset.os_autoupdate.toLowerCase() !== 'error'">{{cAsset.os_autoupdate}}</span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">OS Install Date</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-calendar-day text-green-400 pr-2"></i>
                                                            {{cAsset.os_install_date | epochToDate}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">Uptime</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-clock text-green-400 pr-2"></i>
                                                            {{cAsset.uptime | daysHoursSeconds}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">Manufacturer</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-industry text-green-400 pr-2"></i>
                                                            {{cAsset.manufacturer}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">HW Model</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-desktop text-green-400 pr-2"></i>
                                                            {{cAsset.hardware_model}}
                                                        </td>
                                                    </tr>
                                                    <tr class="border-b">
                                                        <td class="py-1 px-4 text-left">Domain Status</td>
                                                        <td class="py-1 px-4 text-left font-light">
                                                            <i class="fas fa-server text-green-400 pr-2"></i>
                                                            {{cAsset.ad_check}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab class="py-1" label="Finger Print">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-industry mr-2"></i> Finger Print
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <div [@fadeInLeft]>
                                                <div class="overflow-auto h-[30rem]">
                                                    <app-asset-finger-print *ngIf="cAsset"
                                                        [asset]="cAsset"></app-asset-finger-print>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab class="py-1" label="Storage">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-hdd mr-2" id="Storage"></i> Storage
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-storage-devices [asset]="cAsset"></app-storage-devices>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab class="py-1" label="Network">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-ethernet mr-2" id="Network"></i> Network
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-network-interfaces [asset]="cAsset"></app-network-interfaces>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab class="py-1" label="BIOS">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-microchip mr-2" id="CPU"></i> CPU
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-asset-cpu-info [asset]="cAsset"></app-asset-cpu-info>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab class="py-1" label="BIOS">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-server mr-2" id="BIOS"></i> BIOS
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-bios-info [asset]="cAsset"></app-bios-info>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab class="py-1" label="Firewalls">
                                        <ng-template mat-tab-label>
                                            <i class="fas fa-fire mr-2" id="Firewalls"></i> Firewall Policy
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-firewall-policy [asset]="cAsset"></app-firewall-policy>
                                        </ng-template>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div #tabGroup1 id="tabGroup1" class="mt-4">
                    <mat-tab-group class="stab-normal" mat-stretch-tabs="false" mat-align-tabs="start"
                        [animationDuration]="'0'" [(selectedIndex)]="selectedIndex"
                        (selectedIndexChange)="selectTab('1', $event)">
                        <mat-tab class="py-1" label="Problem">
                            <ng-template mat-tab-label>
                                <i class="fas fa-exclamation-triangle mr-2"></i>Problems
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="">
                                    <mat-drawer-container class="flex-auto sm:h-full">
                                        <mat-drawer class="dark:bg-gray-900" fuseScrollbar
                                            [ngClass]="{'w-full lg:w-3/4': drawerOpened}" [autoFocus]="false"
                                            [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened"
                                            #drawer>
                                            <div class="flex flex-row items-center p-4">
                                                <div *ngIf="currentRemediation.script_output"
                                                    class="text-2xl font-semibold tracking-tight">Script Output
                                                </div>
                                                <div *ngIf="fingerPrintData"
                                                    class="text-2xl font-semibold tracking-tight">
                                                    Finger Print Data
                                                </div>
                                                <button class="ml-auto" mat-icon-button (click)="closeDraw()">
                                                    <mat-icon class="text-current"
                                                        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                                </button>
                                            </div>
                                            <!-- Main -->
                                            <div *ngIf="currentRemediation.script_output" class="p-4">
                                                <div class="flex-auto max-w-full p-6 sm:p-10 prose prose-sm">
                                                    <!--<div
                                                        class="terminal p-4 font-mono bg-gray-900 rounded-lg w-full h-full leading-4 text-lime-400">
                                                        {{currentRemediation.script_output}}</div>-->
                                                    <div class="terminal p-4 font-mono rounded-lg
                                                         w-full h-full leading-4 bg-gray-900 text-lime-400"
                                                        [innerHTML]="currentRemediation.script_output | safeHTML">
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="fingerPrintData" class="p-4">
                                                <table class="table-auto">
                                                    <tbody class="text-sm font-light">
                                                        <ng-container *ngFor="let item of fingerPrintData">
                                                            <mat-card class="mb-4">
                                                                <mat-card-content>
                                                                    <table class="table-auto">
                                                                        <tbody class="text-sm font-light">
                                                                            <ng-container
                                                                                *ngFor="let keyValuePair of getObjectKeyValuePairs(item)">
                                                                                <ng-container
                                                                                    *ngIf="keyValuePair.value !== ''">
                                                                                    <tr class="border-b">
                                                                                        <td class="py-1 px-4 text-left">
                                                                                            {{
                                                                                            keyValuePair.key
                                                                                            | keyTransform |
                                                                                            camelToHuman:true
                                                                                            }}
                                                                                        </td>
                                                                                        <td
                                                                                            class="py-1 px-4 text-left font-light">
                                                                                            {{ keyValuePair.value }}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                        </tbody>
                                                                    </table>
                                                                </mat-card-content>
                                                            </mat-card>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                                <div
                                                    class="flex items-center mt-4  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                                                    <button class="ml-auto" mat-button [matTooltip]="'Close'"
                                                        (click)="drawer.toggle();">
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-drawer>
                                        <mat-drawer-content class="flex flex-col">
                                            <ng-container
                                                *ngIf="cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1; else ubuntuCheck">
                                                <stable
                                                    *ngIf="cAsset && rhelProblemTableOptions.tableOptions.serverSide.condition"
                                                    aria-label="Rhel Problem Table" role="table"
                                                    (hyperlinkCallback)="rhelLinkCall($event)"
                                                    [id]="rhelProblemTableOptions.tableOptions.id"
                                                    [sTableOptions]="rhelProblemTableOptions">
                                                </stable>
                                            </ng-container>
                                            <ng-template #ubuntuCheck>
                                                <ng-container
                                                    *ngIf="cAsset && cAsset.os_name && cAsset.platform && ubuntu_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('ubuntu') > -1; else elseProblem">
                                                    <stable
                                                        *ngIf="cAsset && rhelProblemTableOptions.tableOptions.serverSide.condition"
                                                        aria-label="Rhel Problem Table" role="table"
                                                        (hyperlinkCallback)="rhelLinkCall($event)"
                                                        [id]="rhelProblemTableOptions.tableOptions.id"
                                                        [sTableOptions]="rhelProblemTableOptions">
                                                    </stable>
                                                </ng-container>
                                            </ng-template>

                                            <ng-template #elseProblem>
                                                <div class="w-auto rounded-2xl mb-4" *ngIf="!isNoProblem">
                                                    <div class="flex flex-row items-start ml-2" *ngIf="!isFullView">
                                                        <ng-container> <!-- [style.height.px]="height.px"-->
                                                            <div class="flex flex-col w-full" [@fadeInRight]>
                                                                <div class="mt-2 ml-4 flex flex-row items-center">
                                                                    <mat-form-field class=" w-100 z-10"
                                                                        [subscriptSizing]="'dynamic'">
                                                                        <mat-select [multiple]="false"
                                                                            (ngModelChange)="viewCategory($event, 'problem')"
                                                                            placeholder="Choose problem category view"
                                                                            [(ngModel)]="prbid">
                                                                            <mat-select-trigger
                                                                                class="flex flex-row items-center gap-2">
                                                                                <mat-icon
                                                                                    color="primary">error_outline</mat-icon>
                                                                                <span
                                                                                    *ngIf="currentProblem && currentProblem.problem_group_name">{{currentProblem.problem_group_name}}
                                                                                </span>
                                                                                <span class="spacer"></span>
                                                                                <span
                                                                                    *ngIf="cs.currentproblemcount !== null && cs.currentproblemcount !== undefined"
                                                                                    class="rounded-full px-2 text-white"
                                                                                    [ngClass]="{'bg-red-500': cs.currentproblemcount > 0, 'bg-green-500': cs.currentproblemcount === 0}">
                                                                                    {{cs.currentproblemcount |
                                                                                    shortNumber}}
                                                                                </span>
                                                                            </mat-select-trigger>
                                                                            <mat-option>
                                                                                <ngx-mat-select-search
                                                                                    [formControl]="prbFilterCtrl"
                                                                                    [placeholderLabel]="'Search Problem Category'"
                                                                                    [noEntriesFoundLabel]="'No matching problem found'"
                                                                                    [searching]="searching"></ngx-mat-select-search>
                                                                            </mat-option>

                                                                            <mat-option
                                                                                *ngFor="let prb of storedProblems | search:prbFilterCtrl.value"
                                                                                [value]="prb.problem_group_id">
                                                                                <div class="flex flex-col">
                                                                                    <div
                                                                                        class="flex flex-row items-center gap-2">
                                                                                        <mat-icon
                                                                                            color="primary">error_outline</mat-icon>
                                                                                        <div class="flex flex-col">
                                                                                            <span
                                                                                                *ngIf="prb.problem_group_name !== 'All Vulnerabilities'"
                                                                                                class="text-sm">
                                                                                                {{prb.problem_group_name}}
                                                                                                <span
                                                                                                    class="bg-teal-400 px-2 py-0.5 rounded-full text-black">{{prb.problem_count|shortNumber}}</span>
                                                                                            </span>
                                                                                            <span
                                                                                                *ngIf="prb.problem_group_name === 'All Vulnerabilities'"
                                                                                                class="text-sm">
                                                                                                {{prb.problem_group_name}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                    <div matTooltip="Suppressed Records"
                                                                        (click)="viewCategory(currentProblem.problem_group_id, 'supress_records')"
                                                                        [matTooltipPosition]="'right'"
                                                                        class="text-center font-bold w-[13vw] ml-2 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                                        [ngClass]="{ 'bg-primary text-white': cView === 'supress_records' }">
                                                                        Suppressed Records
                                                                    </div>
                                                                    <div matTooltip="Auto Suppressed"
                                                                        (click)="maualselectfilter = 'yes';viewCategory(currentProblem.problem_group_id, 'auto_suppressed')"
                                                                        [matTooltipPosition]="'right'"
                                                                        class="text-center font-bold w-[13vw] ml-2 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                                        [ngClass]="{ 'bg-primary text-white': cView === 'auto_suppressed' }">
                                                                        Auto Suppressed
                                                                    </div>
                                                                    <div [matTooltip]="'Back'"
                                                                        *ngIf="cView !== 'problem'"
                                                                        (click)="maualselectfilter = 'yes';viewCategory(currentProblem.problem_group_id, 'problem')"
                                                                        class="inline-flex items-center text-primary-900  w-[12vw] justify-center font-bold
                                                             cursor-pointer rounded-full px-2 py-2 ring-primary-400 ring-1 ring-inset mr-2">
                                                                        Back To Problem View</div>
                                                                </div>
                                                                <ng-container *ngIf="cView === 'problem'">
                                                                    <stable [sTableOptions]="probTableOptions"
                                                                        (hyperlinkCallback)="linkCall($event)"
                                                                        (pageCallbackTable)="pageCall($event)"
                                                                        (actionCallback)="actionCall($event)"
                                                                        (globalActionCallback)="globalActionCall($event)"
                                                                        (manualfilterCallbackduplicate)="tagFilterCall($event)"
                                                                        *ngIf="currentProblem && probTableOptions.tableOptions">
                                                                    </stable>
                                                                </ng-container>
                                                                <ng-container *ngIf="cView === 'auto_suppressed'">
                                                                    <stable [sTableOptions]="probTableOptions"
                                                                        (hyperlinkCallback)="linkCall($event)"
                                                                        (manualfilterCallbackduplicate)="tagFilterCall($event)"
                                                                        *ngIf="currentProblem && probTableOptions.tableOptions">
                                                                    </stable>
                                                                </ng-container>
                                                                <ng-container *ngIf="cView === 'supress_records'">
                                                                    <app-suppression [filterKey]="'problem_id'"
                                                                        [mode]="'asset'"></app-suppression>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        (click)="selectedTab = 'registry' "
                                                        class="text-center font-bold w-[8vw] ml-5 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                        [ngClass]="{ 'bg-primary text-white': selectedTab === 'registry' }">
                                                        Registry
                                                    </button>
                                                    <button *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        (click)="selectedTab = 'remediated-registry'"
                                                        class="text-center font-bold w-[12vw] ml-2 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                        [ngClass]="{ 'bg-primary text-white': selectedTab === 'remediated-registry' }">
                                                        Remediated Registry
                                                    </button>
                                                    <button *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        (click)="selectedTab = 'suppressed'"
                                                        class="text-center font-bold w-[12vw] ml-2 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                        [ngClass]="{ 'bg-primary text-white': selectedTab === 'suppressed' }">
                                                        Suppressed Records
                                                    </button>

                                                    <button *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        (click)="selectedTab = 'installdrivervuls' "
                                                        class="text-center font-bold w-[16vw] ml-1 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                        [ngClass]="{ 'bg-primary text-white': selectedTab === 'installdrivervuls' }">
                                                        Installed Driver Vulnerabilities
                                                    </button>

                                                    <button *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        (click)="selectedTab = 'installdriverrem'"
                                                        class="text-center font-bold w-[16vw] ml-1 ring-inset mr-2 px-2 py-2 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                                        [ngClass]="{ 'bg-primary text-white': selectedTab === 'installdriverrem' }">
                                                        Installed Driver Remediations
                                                    </button>
                                                </div>
                                                <div
                                                    *ngIf="selectedTab === 'registry' && (cAsset.platform === 'windows' || snmp_issue.indexOf(cAsset.platform.toLowerCase()) > -1)">
                                                    <stable
                                                        *ngIf="cAsset && registryprobTableOptions && registryprobTableOptions.tableOptions && registryprobTableOptions.tableOptions.serverSide && registryprobTableOptions.tableOptions.serverSide.condition"
                                                        aria-label="Registry Problem Summary" role="table"
                                                        (hyperlinkCallback)="linkCall($event)"
                                                        (globalActionCallback)="globalActionCall1($event)"
                                                        (actionCallback)="actionCall1($event)"
                                                        [sTableOptions]="registryprobTableOptions"></stable>
                                                </div>
                                                <div *ngIf="selectedTab === 'remediated-registry'">
                                                    <stable
                                                        *ngIf="cAsset && cAsset.platform === 'windows' && remediatedRegistryprobTableOptions && remediatedRegistryprobTableOptions.tableOptions && remediatedRegistryprobTableOptions.tableOptions.serverSide && remediatedRegistryprobTableOptions.tableOptions.serverSide.condition"
                                                        aria-label="Remediated Registry Problem Summary" role="table"
                                                        [sTableOptions]="remediatedRegistryprobTableOptions"></stable>
                                                </div>
                                                <div *ngIf="selectedTab === 'suppressed'">
                                                    <stable
                                                        *ngIf="cAsset && cAsset.platform === 'windows' && registryprobTableOptions1.tableOptions.serverSide.condition"
                                                        aria-label="Registry Problem Summary" role="table"
                                                        (hyperlinkCallback)="linkCall($event)"
                                                        (actionCallback)="actionCall1($event)"
                                                        (globalActionCallback)="globalActionCall1($event)"
                                                        [sTableOptions]="registryprobTableOptions1">
                                                    </stable>
                                                </div>
                                                <div *ngIf="selectedTab === 'installdrivervuls'">
                                                    <stable *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        aria-label="Install Driver Vulnerabilities" role="table"
                                                        [sTableOptions]="installvulsprobTableOptions">
                                                    </stable>
                                                </div>
                                                <div *ngIf="selectedTab === 'installdriverrem'">
                                                    <stable *ngIf="cAsset && cAsset.platform === 'windows'"
                                                        aria-label="Install Driver Remediations" role="table"
                                                        [sTableOptions]="installremprobTableOptions">
                                                    </stable>
                                                </div>
                                            </ng-template>
                                        </mat-drawer-content>
                                    </mat-drawer-container>
                                </div>

                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Registry Problems Summary" *ngIf="false">
                            <ng-template mat-tab-label>
                                <i class="fas fa-tv mr-2"></i> <span
                                    *ngIf="cAsset.platform && snmp_issue.indexOf(cAsset.platform.toLowerCase()) > -1">SNMP
                                    Issues</span> <span
                                    *ngIf="!cAsset.platform || snmp_issue.indexOf(cAsset.platform.toLowerCase()) === -1">Registry
                                    Problems Summary</span>
                            </ng-template>
                            <ng-template matTabContent>
                                <stable *ngIf="cAsset && registryprobTableOptions.tableOptions.serverSide.condition"
                                    aria-label="Registry Problem Summary" role="table"
                                    (hyperlinkCallback)="linkCall($event)" (actionCallback)="actionCall1($event)"
                                    (globalActionCallback)="globalActionCall1($event)"
                                    [sTableOptions]="registryprobTableOptions"></stable>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Solution">
                            <ng-template mat-tab-label>
                                <i class="fas fa-info-circle mr-2"></i>Solutions
                            </ng-template>
                            <ng-template matTabContent>
                                <div>
                                    <remediation-plan [mode]="'assets'" [asset_id]="cAsset.id"
                                        *ngIf="cAsset && cAsset.id"
                                        [asset_name]="cAsset.visible_name ? cAsset.visible_name : (cAsset.host_name ? cAsset.host_name : cAsset.ip)"
                                        [cAsset]="cAsset"></remediation-plan>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Compliance">
                            <ng-template mat-tab-label>
                                <i class="fas fa-shield-alt mr-2"></i>Compliance
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <div class="w-full">
                                        <asset-compliance [asset_id]="cAsset.id"></asset-compliance>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Firewall Rules"
                            *ngIf="cAsset && cAsset.platform && firewall_rules_notreq.indexOf(cAsset.platform) === -1">
                            <ng-template mat-tab-label>
                                <i class="fas fa-fire mr-2"></i>Firewall Rules
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInRight]>
                                    <div class="flex flex-row flex-wrap px-4 pt-4 gap-4 items-center justify-start">
                                        <div *ngIf="cAsset && cAsset.platform && ip_tables.indexOf(cAsset.platform) !== -1"
                                            class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                            (click)="toggleFirewallRulesDirection('Ip')" [ngClass]="{
                                        'bg-primary text-white': firewallRuleDirection === 'Ip' }">IP Table Rules
                                        </div>
                                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                            *ngIf="cAsset && cAsset.platform && inbound_and_outbound.indexOf(cAsset.platform) !== -1"
                                            (click)="toggleFirewallRulesDirection('In')" [ngClass]="{
                                        'bg-primary text-white': firewallRuleDirection === 'In' }">
                                            Inbound Firewall Rules
                                        </div>
                                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                            *ngIf="cAsset && cAsset.platform && inbound_and_outbound.indexOf(cAsset.platform) !== -1"
                                            (click)="toggleFirewallRulesDirection('Out')" [ngClass]="{
                                        'bg-primary text-white': firewallRuleDirection === 'Out' }">
                                            Outbound Firewall Rules
                                        </div>
                                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                            *ngIf="cAsset && cAsset.platform && ubuntu_firewall_settings.indexOf(cAsset.platform) !== -1"
                                            (click)="toggleFirewallRulesDirection('UbuntuFireWall')" [ngClass]="{
                                        'bg-primary text-white':
                                          firewallRuleDirection === 'UbuntuFireWall' }">
                                            Ubuntu FireWall Rules
                                        </div>
                                        <div *ngIf="cAsset && cAsset.platform && selinux_settings.indexOf(cAsset.platform) !== -1"
                                            class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                                            (click)="toggleFirewallRulesDirection('Selinux')" [ngClass]="{
                                        'bg-primary text-white': firewallRuleDirection === 'Selinux'}">
                                            Selinux Settings
                                        </div>
                                    </div>
                                    <app-skeleton *ngIf="!firewallShow"></app-skeleton>
                                    <stable *ngIf="cAsset && cAsset.platform && inbound_and_outbound.indexOf(cAsset.platform) !== -1 &&
                                      firewallShow &&
                                      firewallDisplayShow &&
                                      cAsset &&
                                      rulesTableOptions.tableOptions.serverSide.condition
                                    " aria-label="Firewall Rules Table" role="table"
                                        [id]="rulesTableOptions.tableOptions.id" [sTableOptions]="rulesTableOptions">
                                    </stable>
                                    <app-firewall-rules [asset]="cAsset" *ngIf="
                                    cAsset && cAsset.platform && ubuntu_firewall_settings.indexOf(cAsset.platform) !== -1 &&
                                      firewallRuleDirection == 'UbuntuFireWall' && !firewallDisplayShow
                                    "></app-firewall-rules>
                                    <app-selinux-settings [asset]="cAsset" *ngIf="
                                    cAsset && cAsset.platform && selinux_settings.indexOf(cAsset.platform) !== -1 &&
                                      firewallRuleDirection == 'Selinux' && !firewallDisplayShow
                                    "></app-selinux-settings>
                                    <app-ip-table-rules [asset]="cAsset" *ngIf=" cAsset && cAsset.platform && ip_tables.indexOf(cAsset.platform) !== -1 &&
                                        firewallRuleDirection == 'Ip' && !firewallDisplayShow"></app-ip-table-rules>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Internal Ports">
                            <ng-template mat-tab-label>
                                <i class="fas fa-ethernet mr-2"></i> Internal Ports
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <stable *ngIf="cAsset && openPortTableOptions.tableOptions.serverSide.condition"
                                        aria-label="Internal Ports Table" role="table"
                                        [id]="openPortTableOptions.tableOptions.id"
                                        [sTableOptions]="openPortTableOptions">
                                    </stable>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="External Ports">
                            <ng-template mat-tab-label>
                                <i class="fas fa-ethernet mr-2"></i> External Ports
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <stable *ngIf="cAsset && portTableOptions.tableOptions.serverSide.condition"
                                        aria-label="External Ports Table" role="table"
                                        [id]="portTableOptions.tableOptions.id" [sTableOptions]="portTableOptions">
                                    </stable>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Software">
                            <ng-template mat-tab-label>
                                <i class="fas fa-memory mr-2"></i>Software
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInRight]>
                                    <ng-container
                                        *ngIf="cAsset && cAsset.os_name && cAsset.platform && redhat_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('red hat') > -1;else ubuntuCheck">
                                        <stable *ngIf="cAsset && rhelSoftTableOptions.tableOptions.serverSide.condition"
                                            aria-label="Software Table" role="table"
                                            [id]="rhelSoftTableOptions.tableOptions.id"
                                            [sTableOptions]="rhelSoftTableOptions"></stable>
                                    </ng-container>
                                    <ng-template #ubuntuCheck>
                                        <ng-container
                                            *ngIf="cAsset && cAsset.os_name && cAsset.platform && ubuntu_platform.indexOf(cAsset.platform.toLowerCase()) > -1 && cAsset.os_name.toLowerCase().indexOf('ubuntu') > -1; else elseSotfware">
                                            <stable
                                                *ngIf="cAsset && rhelSoftTableOptions.tableOptions.serverSide.condition"
                                                aria-label="Software Table" role="table"
                                                [id]="rhelSoftTableOptions.tableOptions.id"
                                                [sTableOptions]="rhelSoftTableOptions"></stable>
                                        </ng-container>
                                    </ng-template>

                                    <ng-template #elseSotfware>
                                        <stable *ngIf="cAsset && softTableOptions.tableOptions.serverSide.condition"
                                            aria-label="Software Table" role="table"
                                            [id]="softTableOptions.tableOptions.id" [sTableOptions]="softTableOptions">
                                        </stable>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <mat-divider class="my-4 "></mat-divider>
                <div #tabGroup2 id="tabGroup2">
                    <mat-tab-group class="stab-normal" mat-stretch-tabs="false" mat-align-tabs="start"
                        [(selectedIndex)]="selectedSecurityIndex" (selectedIndexChange)="selectTab('2', $event)">
                        <mat-tab class="py-1" label="Ciphers">
                            <ng-template mat-tab-label>
                                <i class="fas fa-key mr-2"></i> Ciphers
                            </ng-template>
                            <ng-template matTabContent>
                                <app-ciphers [showNoData]="true" [asset]="cAsset"></app-ciphers>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Patches" *ngIf="false">
                            <ng-template mat-tab-label>
                                <i class="fas fa-cogs mr-2"></i>Patches
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <fuse-alert class="w-full m-8" [appearance]="'border'" [type]="'success'"
                                        *ngIf="!cAsset.os_patches || !cAsset.os_patches.length">
                                        <span fuseAlertTitle>No Installed Patches available</span>
                                    </fuse-alert>
                                    <div class="mt-4 p-2" *ngIf="cAsset.os_patches && cAsset.os_patches.length">
                                        <div class="flex flex-col items-end mb-4" fuseScrollbar>
                                            <mat-form-field appearance="fill" class="mini-form-field w-100">
                                                <span matPrefix class="mx-2">KB</span>
                                                <input matInput id="KBnumber" name="patchFilter"
                                                    [(ngModel)]="patchFilter" [placeholder]="'Number'"
                                                    autocomplete="off" (input)="onSearch($event)">
                                                <button *ngIf="patchFilter" matSuffix mat-icon-button aria-label="Clear"
                                                    (click)="patchFilter='';">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <show-more-less *ngIf="cAsset.os_patches && cAsset.os_patches.length"
                                            [type]="'list'" [items]="cAsset.os_patches" [showAllItems]="false"
                                            [searchInput]="patchFilter" [bgColor]="'bg-slate-600'"
                                            [color]="'text-green-400'" [maxItemsToShow]="10"
                                            [showOrder]="'span'"></show-more-less>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Extension Programs">
                            <ng-template mat-tab-label>
                                <i class="fas fa-memory mr-2"></i>Extension Programs
                            </ng-template>
                            <ng-template matTabContent>
                                <stable *ngIf="cAsset && extAppTableOptions.tableOptions.serverSide.condition"
                                    aria-label="Extension Program Table" role="table"
                                    [id]="extAppTableOptions.tableOptions.id" [sTableOptions]="extAppTableOptions">
                                </stable>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Services">
                            <ng-template mat-tab-label>
                                <i class="fas fas fa-dungeon mr-2"></i>Services
                            </ng-template>
                            <ng-template matTabContent>
                                <stable *ngIf="cAsset && servTableOptions.tableOptions.serverSide.condition"
                                    aria-label="Services Table" role="table" [sTableOptions]="servTableOptions">
                                </stable>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="User Shares">
                            <ng-template mat-tab-label>
                                <i class="fas fa-share mr-2"></i>User Shares
                            </ng-template>
                            <ng-template matTabContent>
                                <app-user-shares [asset]="cAsset"></app-user-shares>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Users">
                            <ng-template mat-tab-label>
                                <i class="fas fa-users mr-2"></i>Users
                            </ng-template>
                            <ng-template matTabContent>
                                <stable *ngIf="cAsset && userTableOptions.tableOptions.serverSide.condition"
                                    aria-label="User Table" role="table" [id]="userTableOptions.tableOptions.id"
                                    [sTableOptions]="userTableOptions">
                                </stable>
                            </ng-template>
                        </mat-tab>
                        <!-- *ngIf="cAsset.platform === 'windows' || cAsset.platform === 'darwin'" -->
                        <mat-tab class="py-1" label="Security Report Card">
                            <ng-template mat-tab-label>
                                <i class="fas fa-shield-alt mr-2"></i>Security Report Card
                            </ng-template>
                            <ng-template matTabContent>
                                <app-security-report-card [asset]="cAsset"></app-security-report-card>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Compliance Report Card" *ngIf="cAsset.platform === 'windows'">
                            <ng-template mat-tab-label>
                                <i class="fas fa-clipboard-list mr-2"></i>Compliance Report Card
                            </ng-template>
                            <ng-template matTabContent>
                                <app-compliance-report-card [asset]="cAsset"></app-compliance-report-card>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Certificates">
                            <ng-template mat-tab-label>
                                <i class="fas fa-certificate mr-2" id="Certificates"></i> Certificates
                            </ng-template>
                            <ng-template matTabContent>
                                <app-certificates [showNoData]="true" [asset]="cAsset"></app-certificates>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <mat-divider class="my-4 "></mat-divider>
                <div #tabGroup3 id="tabGroup3">
                    <mat-tab-group class="stab-normal" mat-stretch-tabs="false" mat-align-tabs="start"
                        [(selectedIndex)]="selectedAssetIndex" (selectedIndexChange)="selectTab('3', $event)">
                        <mat-tab class="py-1" label="Unquoted Service Path" *ngIf="cAsset.platform === 'windows'">
                            <ng-template mat-tab-label>
                                <i class="fas fa-folder-open mr-2"></i>Unquoted Service Path
                            </ng-template>
                            <ng-template matTabContent>
                                <stable *ngIf="cAsset && unquoted_service_path.tableOptions.serverSide.condition"
                                    aria-label="Unquoted Service Path" role="table"
                                    [id]="unquoted_service_path.tableOptions.id"
                                    [sTableOptions]="unquoted_service_path">
                                </stable>
                            </ng-template>
                        </mat-tab>
                        <ng-container
                            *ngIf="cAsset.platform !== 'windows' && cAsset.platform !== 'darwin' && cAsset.platform">
                            <mat-tab class="py-1" label="Kernel Modules">
                                <ng-template mat-tab-label>
                                    <i class="fas fa-ethernet mr-2"></i> Kernel Modules
                                </ng-template>
                                <ng-template matTabContent>
                                    <div [@fadeInLeft]>
                                        <kernel-modules *ngIf="cAsset" [asset]="cAsset"></kernel-modules>
                                    </div>
                                </ng-template>
                            </mat-tab>
                            <mat-tab class="py-1" label="Cron Jobs">
                                <ng-template mat-tab-label>
                                    <i class="fas fa-ethernet mr-2"></i> Cron Jobs
                                </ng-template>
                                <ng-template matTabContent>
                                    <div [@fadeInLeft]>
                                        <cron-jobs *ngIf="cAsset" [asset]="cAsset"></cron-jobs>
                                    </div>
                                </ng-template>
                            </mat-tab>
                            <mat-tab class="py-1" label="SUID Permission">
                                <ng-template mat-tab-label>
                                    <i class="fas fa-ethernet mr-2"></i> SUID Permission
                                </ng-template>
                                <ng-template matTabContent>
                                    <div [@fadeInLeft]>
                                        <suid-permissions *ngIf="cAsset" [asset]="cAsset"></suid-permissions>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        </ng-container>
                        <mat-tab class="py-1" label="Asset Patches">
                            <ng-template mat-tab-label>
                                <i class="fas fa-cogs mr-2"></i>Installed Patches
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <!-- Use ng-container to handle both data and no data cases -->
                                    <ng-container *ngIf="cAsset; else noData">
                                        <!-- Check if os_patches exists and has items -->
                                        <ng-container
                                            *ngIf="cAsset.os_patches && cAsset.os_patches.length > 0; else noData">
                                            <div class="flex justify-between items-center w-full">
                                                <h4 class="text-lg ml-4">Installed Patches</h4>
                                                <mat-form-field class="mt-4 mb-0 mr-6">
                                                    <span matPrefix>KB </span>
                                                    <input matInput id="KBnumber" name="patchFilter"
                                                        [(ngModel)]="patchFilter" [placeholder]="' Number'"
                                                        autocomplete="off">
                                                    <button mat-button *ngIf="!patchFilter || patchFilter === ''"
                                                        aria-label="Search" matSuffix mat-icon-button>
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                    <button mat-button *ngIf="patchFilter" matSuffix mat-icon-button
                                                        aria-label="Clear" (click)="patchFilter=''">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="flex flex-wrap gap-3 mt-5 mb-5">
                                                <a *ngFor="let patch of cAsset.os_patches?.sort() | search: patchFilter"
                                                    (click)="handlePatchClick(patch)" matTooltip="{{patch}}"
                                                    class="px-3 py-1.5 my-3 badge badge-lightsuccess text-black bg-green-50 cursor-pointer">
                                                    KB{{patch}}
                                                </a>
                                            </div>
                                        </ng-container>
                                    </ng-container>

                                    <!-- Template for no data message -->
                                    <ng-template #noData>
                                        <div class="flex flex-col items-center justify-center h-screen gap-y-2">
                                            <img src="/assets/images/empty.png" alt="" class="w-20">
                                            <div>No data available!</div>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <!-- <mat-tab class="py-1" label="Asset Patches">
                            <ng-template mat-tab-label>
                                <i class="fas fa-cogs mr-2"></i>Asset Patches
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <stable *ngIf="cAsset && assetPatchesTableOptions.tableOptions.serverSide.condition"
                                        aria-label="Ports Table" role="table" [id]="assetPatchesTableOptions.tableOptions.id"
                                        [sTableOptions]="assetPatchesTableOptions"></stable>
                                </div>
                            </ng-template>
                        </mat-tab> -->
                        <mat-tab class="py-1" label="Tags">
                            <ng-template mat-tab-label>
                                <i class="fas fa-tags mr-2"></i>Tags
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <app-asset-tags *ngIf="cAsset" [asset]="cAsset"></app-asset-tags>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Installed Drivers">
                            <ng-template mat-tab-label>
                                <i class="fas fa-memory mr-2"></i> Installed Drivers
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <stable *ngIf="cAsset && assetInstalledDrivers.tableOptions.serverSide.condition"
                                        aria-label="Installed Drivers" role="table"
                                        [sTableOptions]="assetInstalledDrivers">
                                    </stable>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Running Processes">
                            <ng-template mat-tab-label>
                                <i class="fas fa-microchip mr-2"></i> Running Processes
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <stable *ngIf="cAsset && runningProcesses.tableOptions.serverSide.condition"
                                        aria-label="Running Processes" role="table" [sTableOptions]="runningProcesses">
                                    </stable>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="py-1" label="Windows 11 Compatible Check"
                            *ngIf="cAsset && cAsset.platform === 'windows' && cAsset.os_name && cAsset.os_name.toLowerCase().indexOf('server') === -1">
                            <ng-template mat-tab-label>
                                <i class="fab fa-windows mr-2"></i> Windows 11 Compatible Check
                            </ng-template>
                            <ng-template matTabContent>
                                <div [@fadeInLeft]>
                                    <app-windows-compatible-check *ngIf="cAsset"
                                        [asset]="cAsset"></app-windows-compatible-check>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-container>
            <div *ngIf="!cAsset || !cAsset.ip || !showData">
                <div class="h-96 w-full px-2">
                    <div class="mt-3 flex h-20 w-full flex-row items-center rounded-lg gap-2 shadow">
                        <div class="flex flex-row gap-2">
                            <div class="mt-2 h-10 w-10 rounded-full bg-card animate-pulse blink "></div>
                        </div>
                        <div class="mt-2 h-5 w-6 rounded bg-card"></div>
                        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                    </div>
                    <div class="mt-3 flex h-20 w-full flex-col rounded-lg gap-2 shadow">
                        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                    </div>
                    <div class="mt-3 flex h-20 w-full flex-col rounded-lg gap-2 shadow">
                        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                    </div>
                    <div class="mt-3 flex h-20 w-full flex-col rounded-lg gap-2 shadow">
                        <div class="mt-2 h-4 w-1/4 rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                        <div class="mt-2 h-4 w-full rounded-lg bg-card animate-pulse blink "></div>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<app-integration-action [inputActionData]="actionData" [productData]="productData"
    *ngIf="integrationActionService.integrationAssetActionShow">
</app-integration-action>
<!-- ShowRiskScoreDetails - S-Modal -->
<s-modal id="showRiskScoreDetails">
    <div class="mx-auto w-full flex  max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-[65vw] h-[80vh] rounded-r-3xl bg-card py-4" fuseScrollbar>
            <!-- Close Button -->
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('showRiskScoreDetails');">
                <mat-icon>close</mat-icon>
            </button>
            <!-- Data in S-Modal -->
            <div class="col-span-12 relative px-4 ml-2 sm:col-span-8" *ngIf="cAsset">
                <div class="flex flex-col">

                    <!-- Risk Score Calculation and First Table-->
                    <ng-container>
                        <div class="flex flex-col flex-wrap">
                            <h3 class="text-xl font-semibold tracking-wide mt-2 font-light">
                                Risk Score Details
                                <a class="font-light mini-form-field ml-2 text-white px-2 rounded-lg py-0.5 bg-primary text-xs ring-1"
                                    href="https://cybercns.atlassian.net/wiki/x/JoCZgQ" target="_blank">HELP LINK</a>
                            </h3>
                            <fuse-alert class="w-full my-2" [appearance]="'border'" [type]="'info'">
                                <span fuseAlertTitle class="text-sm">
                                    <div class="flex flex-col">
                                        <div> Base Score = Weightage * Type Base Multiplier</div>
                                        <div> Count Factor = 1 + (Count * Weightage)</div>
                                        <div> Log Score = log10(Count Factor) * 3</div>
                                        <div> Count Final Score = Log Score * Type Count Multiplier * Weightage</div>
                                        <div>Asset Risk Score = Base Score + Count Final Score</div>
                                    </div>
                                </span>
                            </fuse-alert>

                            <fuse-alert class="w-full my-2" [appearance]="'border'" [type]="'info'">
                                <span fuseAlertTitle>
                                    Score altered by
                                    <span class="text-black dark:text-white">
                                        {{ suppressedvulscount }}
                                    </span>
                                    of suppressed vulnerabilities.
                                </span>
                            </fuse-alert>
                            <!-- Problem Category Table -->
                            <div class="w-full rounded py-0.5 ring-1 mt-4"
                                *ngIf="updatedProblemsScore && updatedProblemsScore.length">
                                <table class="table-auto">
                                    <thead>
                                        <tr class="border-b">
                                            <th class="px-4 text-left text-sm">Problem Category</th>
                                            <th class="px-4 text-left text-sm">Weightage</th>
                                            <th class="px-4 text-left text-sm">Type Base Multiplier
                                            </th>
                                            <th class="px-4 text-left text-sm">Base Score</th>
                                            <th class="px-4 text-left text-sm">Count</th>
                                            <th class="px-4 text-left text-sm">Count Factor</th>
                                            <th class="px-4 text-left text-sm">Log Score</th>
                                            <th class="px-4 text-left text-sm">Type Count Multiplier
                                            </th>
                                            <th class="px-4 text-left text-sm">Exists?</th>
                                            <th class="px-4 text-left text-sm">Final Score</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-xs font-light overflow-y-auto"
                                        *ngIf="updatedProblemsScore && updatedProblemsScore.length">
                                        <ng-container *ngFor="let item of updatedProblemsScore; trackBy: trackByFn">
                                            <tr class="border-b">
                                                <td class="py-1 px-4 text-left font-light">{{item.problem_group_name}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.problem_weightage}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.base_multiplier}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.base_score}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.problem_count}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.count_factor}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.log_score}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{item.count_multiplier}}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light">
                                                    {{ item.unconfirmed === false ? 'Y' : 'N' }}
                                                </td>
                                                <td class="py-1 px-4 text-left font-light" align="center"
                                                    *ngIf="item.unconfirmed === false && cAsset?.vul_stats">
                                                    <span
                                                        *ngIf="(item.problem_weightage) * 100 === cAsset.avg_risk_score"
                                                        class="max-w-xs relative text-white me-2 px-2.5 py-1 rounded-full"
                                                        [ngClass]="{
                                                        'bg-green-500': cAsset.avg_risk_score <= 40,
                                                        'bg-yellow-600': cAsset.avg_risk_score <= 60 && cAsset.avg_risk_score > 40,
                                                        'bg-orange-600': cAsset.avg_risk_score <= 75 && cAsset.avg_risk_score > 60,
                                                        'bg-red-600': cAsset.avg_risk_score <= 100 && cAsset.avg_risk_score > 75
                                                        }">
                                                        {{(item.problem_weightage) * 100}}
                                                    </span>

                                                    <span
                                                        *ngIf="(item.problem_weightage) * 100 !== cAsset.avg_risk_score">
                                                        {{item.count_final_score}}
                                                    </span>
                                                </td>
                                                <td class="py-1 px-4 text-left font-light"
                                                    *ngIf="item.unconfirmed === true">
                                                    {{(item.problem_weightage) * 0}}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="flex flex-col items-center gap-y-2 mt-8"
                                    *ngIf="!updatedProblemsScore || !updatedProblemsScore.length">
                                    <img src="/assets/images/empty.png" alt="" class="w-20" height="512" width="512">
                                    <div class="mt-1 font-medium text-md"> No Problem Category score present for the
                                        asset.</div>
                                </div>
                            </div>
                            <fuse-alert class="w-full my-2" [appearance]="'border'" [type]="'info'"
                                *ngIf="!updatedProblemsScore || !updatedProblemsScore.length">
                                <span fuseAlertTitle>No Problem Category score present for the asset.</span>
                            </fuse-alert>
                        </div>
                    </ng-container>
                    <!-- Asset Risk Score and Progress Indication -->
                    <ng-container>
                        <div class="flex flex-col flex-wrap" *ngIf="cAsset">

                            <div class=" w-full rounded px-6 py-0.5 ring-1 mt-4 h-[150px]">
                                <div class="mt-2 flex flex--row items-center">
                                    Asset Risk Score: <span
                                        class="max-w-xs flex ml-2 relative text-white text-md font-bold me-2 px-2.5 py-1 rounded-full"
                                        [ngClass]="{
                                                        'bg-green-500': cAsset.avg_risk_score <= 40,
                                                        'bg-yellow-600': cAsset.avg_risk_score <= 60 && cAsset.avg_risk_score > 40,
                                                        'bg-orange-600': cAsset.avg_risk_score <= 75 && cAsset.avg_risk_score > 60,
                                                        'bg-red-600': cAsset.avg_risk_score <= 100 && cAsset.avg_risk_score > 75
                                                        }"> <span
                                            *ngIf="cAsset.avg_risk_score">{{cAsset.avg_risk_score}}</span>
                                        <span *ngIf="!cAsset.avg_risk_score">0</span>
                                    </span>
                                </div>
                                <div class="flex flex-col gap-6">
                                    <div class="items-center mt-2">
                                        <app-progress-bar [barValue]="cAsset.avg_risk_score"></app-progress-bar>
                                    </div>
                                    <div>
                                        <ul class="flex flex-row">
                                            <li class="text-xs m-1">
                                                <span class=" flex flex-row">
                                                    <div
                                                        class="mt-1 mr-1 w-2 h-2 flex items-center justify-center rounded-full font-bold text-xs bg-green-500">
                                                    </div>
                                                    <b>A</b> :
                                                    {{_bs.assetSecurityGrade().A.from}}-{{_bs.assetSecurityGrade().A.to}}
                                                </span>
                                            </li>
                                            <li class="text-xs m-1">
                                                <span class="flex flex-row">
                                                    <div
                                                        class="mt-1 mr-1 w-2 h-2 flex items-center justify-center rounded-full font-bold text-xs bg-yellow-600">
                                                    </div>
                                                    <b>B</b> :
                                                    {{_bs.assetSecurityGrade().B.from}}-{{_bs.assetSecurityGrade().B.to}}
                                                </span>
                                            </li>
                                            <li class="text-xs m-1">
                                                <span class="flex flex-row">
                                                    <div
                                                        class="mt-1 mr-1 w-2 h-2 flex items-center justify-center rounded-full font-bold text-xs bg-yellow-600">
                                                    </div>
                                                    <b>C</b> :
                                                    {{_bs.assetSecurityGrade().C.from}}-{{_bs.assetSecurityGrade().C.to}}
                                                </span>
                                            </li>
                                            <li class="text-xs m-1">
                                                <span class="flex flex-row">
                                                    <div
                                                        class="mt-1 mr-1 w-2 h-2 flex items-center justify-center rounded-full font-bold text-xs bg-yellow-600">
                                                    </div>
                                                    <b>D</b> :
                                                    {{_bs.assetSecurityGrade().D.from}}-{{_bs.assetSecurityGrade().D.to}}
                                                </span>
                                            </li>
                                            <li class="text-xs m-1">
                                                <span class="flex flex-row">
                                                    <div
                                                        class="mt-1 mr-1 w-2 h-2 flex items-center justify-center rounded-full font-bold text-xs bg-red-600">
                                                    </div>
                                                    <b>E</b> :
                                                    {{_bs.assetSecurityGrade().E.from}}-{{_bs.assetSecurityGrade().E.to}}
                                                </span>
                                            </li>
                                            <li class="text-xs m-1">
                                                <span class="flex flex-row">
                                                    <div
                                                        class="mt-1 mr-1 w-2 h-2 flex items-center justify-center rounded-full font-bold text-xs bg-red-600">
                                                    </div>
                                                    <b>F</b> :
                                                    {{_bs.assetSecurityGrade().F.from}}-{{_bs.assetSecurityGrade().F.to}}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Note for Asset Risk Score -->
                    <!-- <ng-container>
                        <fuse-alert class="w-full my-4" [appearance]="'border'" [type]="'info'" *ngIf="cAsset?.vul_stats && cAsset.avg_risk_score">
                            <span fuseAlertTitle>Note : The asset's average risk score is determined by taking the
                                problem with the average risk score.</span>
                        </fuse-alert>
                    </ng-container> -->
                </div>
            </div>
        </div>
    </div>
</s-modal>
<s-modal id="networkDocumentationPopup">
    <div class="mx-auto w-full flex  max-w-lg flex-col md:max-w-none md:flex-row" *ngIf="false">
        <div class="w-full h-[80vh] rounded-r-3xl bg-card py-4" fuseScrollbar>
            <!-- Close Button -->
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('networkDocumentationPopup');">
                <mat-icon>close</mat-icon>
            </button>
            <!-- Data in S-Modal -->
            <div class="col-span-12 relative px-4 ml-2 sm:col-span-8" *ngIf="cAsset">
                <div class="flex flex-col">
                    <ng-container>
                        <div class="flex flex-col flex-wrap">
                            <h3 class="text-xl font-semibold tracking-wide mt-2">
                                Asset Documentation Overview
                            </h3>
                            <fuse-alert class="w-full my-2 text-sm" [appearance]="'border'" [type]="'info'">
                                <span fuseAlertTitle>Access essential details about your network assets, configurations,
                                    and security measures. Keeping this documentation up-to-date ensures smooth
                                    operations, streamlined vulnerability management, and enhanced incident response.
                                    Click here to view or update asset records.</span>
                            </fuse-alert>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="relative block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8">
                            <span
                                class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>
                            <div class="sm:flex sm:justify-between sm:gap-4">
                                <div>
                                    <h3 class="text-lg font-bold text-gray-900 sm:text-xl">
                                        {{cAsset.host_name}}
                                    </h3>
                                    <p class="mt-1 text-xs font-medium text-gray-600">{{cAsset.ip}}</p>
                                </div>
                                <div class="hidden sm:block sm:shrink-0">
                                    <img *ngIf="!cAsset.icon" ngSrc="/assets/images/os/generic.svg" alt="" width="16"
                                        height="16" class="size-16 rounded-lg object-cover shadow-sm">
                                    <img *ngIf="cAsset.icon" ngSrc="/assets/images/os/{{cAsset.icon}}" alt="" width="16"
                                        height="16" class="size-16 rounded-lg object-cover shadow-sm">
                                </div>
                            </div>
                            <!--<div class="mt-4">
                                <p class="text-pretty text-sm text-gray-500">
                                    Summary
                                </p>
                            </div>-->

                            <dl class="mt-6 flex gap-4 sm:gap-6">
                                <div class="flex flex-col-reverse">
                                    <dt class="text-sm font-medium text-gray-600">
                                        <span [ngClass]="{'bg-blue-200 text-blue-600': cAsset.importance === 25,
                                            'bg-yellow-200 text-yellow-600': cAsset.importance === 50,
                                            'bg-orange-200 text-orange-600': cAsset.importance === 75,
                                            'bg-red-200 text-red-600': cAsset.importance === 100
                                            }" matTooltip="Importance" class="inline-flex items-center cursor-pointer
                                                   rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                                            [matMenuTriggerFor]="menuRef1" #menuRefTrigger="matMenuTrigger">
                                            {{importance[cAsset.importance]}}</span>
                                    </dt>
                                    <dd class="text-xs text-gray-500">Importance</dd>
                                    <mat-menu #menuRef1="matMenu">
                                        <ng-container *ngFor="let val of Objectkeys(importance)">
                                            <button mat-menu-item *ngIf="cAsset.importance !== +val"
                                                (click)="updateAssetImportance(val)">{{importance[val]}} </button>
                                        </ng-container>
                                    </mat-menu>
                                </div>
                                <div class="flex flex-col-reverse">
                                    <dt class="text-sm font-medium text-gray-600">MAC</dt>
                                    <dd class="text-xs text-gray-500">{{cAsset.mac}}</dd>
                                </div>
                            </dl>
                        </div>
                        <div class="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
                            <dl class="-my-3 divide-y divide-gray-100 text-sm">
                                <div class="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                    <dt class="font-medium text-gray-900">Category</dt>
                                    <dd class="text-gray-700 sm:col-span-2">Mr</dd>
                                </div>
                                <div class="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                    <dt class="font-medium text-gray-900">Type</dt>
                                    <dd class="text-gray-700 sm:col-span-2">John Frusciante</dd>
                                </div>
                                <div class="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                    <dt class="font-medium text-gray-900">System Type</dt>
                                    <dd class="text-gray-700 sm:col-span-2">Guitarist</dd>
                                </div>
                                <div class="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                    <dt class="font-medium text-gray-900">Purchase Date</dt>
                                    <dd class="text-gray-700 sm:col-span-2">$1,000,000+</dd>
                                </div>
                                <div class="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                    <dt class="font-medium text-gray-900">Deployment Date</dt>
                                    <dd class="text-gray-700 sm:col-span-2">$1,000,000+</dd>
                                </div>
                                <div class="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                    <dt class="font-medium text-gray-900">Location</dt>
                                    <dd class="text-gray-700 sm:col-span-2">$1,000,000+</dd>
                                </div>
                            </dl>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</s-modal>
<s-modal id="asset_script_output">
    <div class="w-[600px] h-[80vh] rounded-2xl bg-card p-4" fuseScrollbar>
        <div class="flex flex-row items-center">
            <div class="text-xl font-semibold tracking-tight">Script Output</div>
            <button class="ml-auto" mat-icon-button (click)="modalService.close('asset_script_output');">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>
        <div class="flex break-all text-sm">
            <div class="terminal w-full p-4 text-xs" *ngIf="currentRemediation.script_output"
                [innerHTML]="currentRemediation.script_output | safeHTML">
            </div>
            <div class="text-xs" *ngIf="!currentRemediation.script_output">No data</div>
        </div>
    </div>
</s-modal>
<s-modal id="assetNewCredential">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[75vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-10 font-bold tracking-wider">ASSET CREDENTIAL</p>
            <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Update your <br> assets credentials here,
            </p>
            <p class="mb-4 text-sm text-gray-200">Specify asset and credential types to refresh valid vulnerability
                scanning credentials.
                For Windows-Active Directory, input Domain Controller name,
                username, and password for authentication. Enhance security assessments with accurate configuration.</p>
            <div class="bg-blue-600/80 rounded-2xl p-3">
                <p class="mb-3 text-sm text-gray-200">
                    Vulnerability scanning is a proactive approach to identifying potential weaknesses and security
                    flaws in
                    an IT environment, enabling timely mitigation of risks.</p>
            </div>
        </div>
        <div #detailView class="w-2/3 h-[75vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('assetNewCredential');">
                <mat-icon>close</mat-icon>
            </button>
            <h2 class="mb-4 text-3xl font-bold">Asset Credentials</h2>
            <form class="flex flex-col m-2 px-2 overflow-hidden" #credNgForm="ngForm">
                <div class="flex mt-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Name</mat-label>
                        <input matInput [placeholder]="'Enter Name'" name="name" required [(ngModel)]="cred.name"
                            [spellcheck]="false">
                    </mat-form-field>
                </div>
                <div class="flex mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>OS Type</mat-label>
                        <mat-select name="OsType" (selectionChange)="cred.credential_type = ''" required
                            [(ngModel)]="cred.os_type">
                            <ng-container *ngFor="let data of osType">
                                <mat-option [value]="data.value"> {{data.key}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Credential Type</mat-label>
                        <mat-select name="credType" required [(ngModel)]="cred.credential_type">
                            <ng-container *ngFor="let data of credentialType">
                                <mat-option [value]="data.value" *ngIf="data.allowOS.indexOf(cred.os_type) !== -1">
                                    {{data.key}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="cred.credential_type === 'ASSETCREDENTIAL'">
                    <div class="flex mt-4" *ngIf="cred.os_type !== 'WINDOWS'">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Port</mat-label>
                            <input matInput [placeholder]="" text="number" name="port" required [(ngModel)]="cred.port"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4" *ngIf="cred.os_type === 'WINDOWS'">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain Name</mat-label>
                            <input matInput [placeholder]="" name="DomainNameW" [(ngModel)]="cred.domain_name"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>User Name</mat-label>
                            <input matInput [placeholder]="" name="username" required [(ngModel)]="cred.user_name"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Password</mat-label>
                            <input matInput [placeholder]="" [required]="!cred.private_key" type="password"
                                name="PasswordKey" [(ngModel)]="cred.password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4" *ngIf="cred.os_type !== 'WINDOWS' && cred.os_type !== 'NETWORKDEVICE'">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Private Key</mat-label>
                            <input matInput [placeholder]="" type="password" name="PrivateKey"
                                [required]="!cred.password" [(ngModel)]="cred.private_key" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="cred.credential_type === 'AD'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain Name</mat-label>
                            <input matInput [placeholder]="" name="domainName" required [(ngModel)]="cred.domain_name"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain Controller Name</mat-label>
                            <input matInput [placeholder]="" name="ActiveDirectoryDCName" required
                                [(ngModel)]="cred.ad_dc_name" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>User Name</mat-label>
                            <input matInput [placeholder]="" name="UserName" required [(ngModel)]="cred.user_name"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="ADCredPassword" required
                                [(ngModel)]="cred.password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="cred.credential_type === 'SNMPV1' || cred.credential_type === 'SNMPV2'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Community String</mat-label>
                            <input matInput [placeholder]="" type="password" name="CommunityString" required
                                [(ngModel)]="cred.community_string" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="cred.credential_type === 'SNMPV3'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Security Name</mat-label>
                            <input matInput [placeholder]="" name="securityName" required
                                [(ngModel)]="cred.security_name" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Auth Protocol</mat-label>
                            <mat-select name="authProtocol" required [(ngModel)]="cred.auth_protocol">
                                <ng-container *ngFor="let data of authProtocol">
                                    <mat-option [value]="data.value"> {{data.label}} </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4" *ngIf="cred.auth_protocol">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Auth Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="Passwordauth"
                                [(ngModel)]="cred.auth_password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Privacy Protocol</mat-label>
                            <mat-select name="privacyProtocol" required [(ngModel)]="cred.privacy_protocol">
                                <ng-container *ngFor="let data of privacyProtocol">
                                    <mat-option [value]="data.value"> {{data.label}} </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex mt-4" *ngIf="cred.privacy_protocol">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Privacy Password</mat-label>
                            <input matInput [placeholder]="" type="password" name="Passwordpri"
                                [(ngModel)]="cred.privacy_password" [spellcheck]="false">
                        </mat-form-field>
                    </div>
                </ng-container>
                <div
                    class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('assetNewCredential');">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button [disabled]="credNgForm.invalid" [color]="'primary'"
                        type="button" appPreventMultiClick [matTooltip]="'Save'" (click)="saveCred()">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>
<s-modal id="assetDetailsSupScanModal">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Suppress Problems</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('assetDetailsSupScanModal');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4" fuseScrollbar>
            <fuse-alert class="mx-4 my-1" [appearance]="'border'" [type]="'info'">
                <span fuseAlertTitle>Note: </span>
                <span>When we suppress a vulnerability in a product, all related vulnerabilities associated with that
                    product will be suppressed as well.</span>
            </fuse-alert>
            <form class="example-form px-8 py-4" #suppressForm="ngForm">
                <label>Reason: </label>
                <mat-radio-group class="reason-radio-group ml-2 mb-4 flex flex-col" aria-label="Reason" name="reasonId"
                    [(ngModel)]="suppressData.reasonId">
                    <mat-radio-button class="reason-radio-button" *ngFor="let reason of reasonsList; let i=index"
                        [value]="reason" color="primary" id="readson{{i}}">
                        {{reason}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="w-full mb-4" *ngIf="suppressData.reasonId === 'Other'">
                    <mat-label>Snooze/Suppress Reason</mat-label>
                    <textarea matInput placeholder="Reason" type="text" [(ngModel)]="suppressData.reason"
                        autocomplete="off" name="reason" [rows]="1" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="1"></textarea>
                </mat-form-field>
                <mat-form-field class="w-full mb-4"
                    *ngIf="_userService._user_roles.indexOf('admin') >-1  || (_userService._user_roles.indexOf('approver') > -1 && _bs.selfApproval().self_approval)">
                    <mat-label>Approval Process</mat-label>
                    <mat-select name="ProcessAproval" [(ngModel)]="suppressData.approve_type" required
                        placeholder="Choose Approval Process">
                        <mat-option value="self">Self Approve</mat-option>
                        <mat-option value="external">Other User</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container
                    *ngIf="suppressData.approve_type !=='self' && (_userService._user_roles.indexOf('admin') > -1 || _userService._user_roles.indexOf('approver') > -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Choose User Type</mat-label>
                        <mat-select name="UsersType" [(ngModel)]="suppressData.internal_type" required
                            placeholder="Choose user type">
                            <mat-option value="internal">Internal User</mat-option>
                            <mat-option value="external">External User</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressData.internal_type === 'internal'">
                        <mat-label>Send To Internal User</mat-label>
                        <mat-select [multiple]="internalUsers.length !== 0 ? true : false" name="internalUsers"
                            [(ngModel)]="suppressData.internal_users" required placeholder="Internal Users">
                            <mat-option disabled *ngIf="internalUsers.length === 0">
                                <span class="dark:text-white">No Internal Approver User present</span>
                            </mat-option>
                            <mat-option *ngFor="let user of internalUsers"
                                [value]="user.email">{{user.user_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressData.internal_type === 'external'">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text" [(ngModel)]="suppressData.external_user"
                            required autocomplete="off" name="external_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <ng-container
                    *ngIf="(_userService._user_roles.indexOf('admin') === -1 && _userService._user_roles.indexOf('approver') === -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text" [(ngModel)]="suppressData.external_user"
                            required autocomplete="off" name="external_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="w-full mb-4">
                    <mat-label>Suppress Comments</mat-label>
                    <textarea matInput placeholder="Suppress Comments" type="text"
                        [(ngModel)]="suppressData.suppress_comments" required autocomplete="off"
                        name="suppress_comments"></textarea>
                </mat-form-field>
                <mat-checkbox class="mb-4" id="permanent" name="permanent" color="primary"
                    [(ngModel)]="suppressData.is_permanent">Permanently Suppress
                </mat-checkbox>
                <div class="flex relative" *ngIf="!suppressData.is_permanent">
                    <!-- <mat-form-field class="flex-auto">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="picker1" (dateChange)="changeEvent($event)"
                            [(ngModel)]="suppressData.suppressed_on" [min]="fromDate" required autocomplete="off"
                            name="startDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field> -->
                    <mat-form-field class="flex-auto ml-2">
                        <mat-label>End date</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="suppressData.suppressed_till"
                            [min]="minEndDate" required name="endDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <mat-divider class="my-4"></mat-divider>
                <div class="form-row flex items-center justify-end -mx-8 px-8">
                    <button type="submit" id="saveButton" [disabled]="!suppressForm.form.valid" class="mr-2"
                        mat-flat-button color="primary" type="button" appPreventMultiClick
                        (click)="suppressProblem(suppressData)">Save
                    </button>
                    <button id="closeButton" (click)="cancelSuppress()" mat-flat-button>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>
<s-modal id="registrySuppress">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Suppress Registry Problems</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="cancelSuppressRegistry();">
                <!-- (click)="modalService.close('registrySuppress') -->
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4" fuseScrollbar>
            <fuse-alert class="mx-4 my-1" [appearance]="'border'" [type]="'info'">
                <span fuseAlertTitle>Note: </span>
                <span>When we suppress a vulnerability in a product, all related vulnerabilities associated with that
                    product will be suppressed as well.</span>
            </fuse-alert>
            <form class="example-form px-8 py-4" #suppressRegForm="ngForm">
                <label>Reason: </label>
                <mat-radio-group class="reason-radio-group ml-2 mb-4 flex flex-col" aria-label="Reason"
                    name="registryreasonId" [(ngModel)]="suppressDataRegistry.reasonId">
                    <mat-radio-button class="reason-radio-button" *ngFor="let reason of reasonsList; let i=index"
                        [value]="reason" color="primary" id="readson{{i}}">
                        {{reason}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="w-full mb-4" *ngIf="suppressDataRegistry.reasonId === 'Other'">
                    <mat-label>Snooze/Suppress Reason</mat-label>
                    <textarea matInput placeholder="Reason" type="text" [(ngModel)]="suppressDataRegistry.reason"
                        autocomplete="off" name="registryreason" [rows]="1" cdkTextareaAutosize
                        [cdkAutosizeMinRows]="1"></textarea>
                </mat-form-field>
                <mat-form-field class="w-full mb-4"
                    *ngIf="_userService._user_roles.indexOf('admin') >-1  || (_userService._user_roles.indexOf('approver') > -1 && _bs.selfApproval().self_approval)">
                    <mat-label>Approval Process</mat-label>
                    <mat-select name="registryProcessAproval" [(ngModel)]="suppressDataRegistry.approve_type" required
                        placeholder="Choose Approval Process">
                        <mat-option value="self">Self Approve</mat-option>
                        <mat-option value="external">Other User</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container
                    *ngIf="suppressDataRegistry.approve_type !=='self' && (_userService._user_roles.indexOf('admin') > -1 || _userService._user_roles.indexOf('approver') > -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Choose User Type</mat-label>
                        <mat-select name="registryUsersType" [(ngModel)]="suppressDataRegistry.internal_type" required
                            placeholder="Choose user type">
                            <mat-option value="internal">Internal User</mat-option>
                            <mat-option value="external">External User</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressDataRegistry.internal_type === 'internal'">
                        <mat-label>Send To Internal User</mat-label>
                        <mat-select [multiple]="internalUsers.length !== 0 ? true : false" name="registryinternalUsers"
                            [(ngModel)]="suppressDataRegistry.internal_users" required placeholder="Internal Users">
                            <mat-option disabled *ngIf="internalUsers.length === 0">
                                <span class="dark:text-white">No Internal Approver User present</span>
                            </mat-option>
                            <mat-option *ngFor="let user of internalUsers"
                                [value]="user.email">{{user.user_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4" *ngIf="suppressDataRegistry.internal_type === 'external'">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text"
                            [(ngModel)]="suppressDataRegistry.external_user" required autocomplete="off"
                            name="registryexternal_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <ng-container
                    *ngIf="(_userService._user_roles.indexOf('admin') === -1 && _userService._user_roles.indexOf('approver') === -1)">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>Send To External User</mat-label>
                        <input matInput placeholder="External User" type="text"
                            [(ngModel)]="suppressDataRegistry.external_user" required autocomplete="off"
                            name="registryexternal_user">
                        <mat-hint>Enter comma seperated emails</mat-hint>
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="w-full mb-4">
                    <mat-label>Suppress Comments</mat-label>
                    <textarea matInput placeholder="Suppress Comments" type="text"
                        [(ngModel)]="suppressDataRegistry.suppress_comments" required autocomplete="off"
                        name="registry_suppress_comments"></textarea>
                </mat-form-field>
                <mat-checkbox class="mb-4" id="permanent" name="registry_permanent" color="primary"
                    [(ngModel)]="suppressDataRegistry.is_permanent">Permanently Suppress
                </mat-checkbox>
                <div class="flex relative" *ngIf="!suppressDataRegistry.is_permanent">
                    <!-- <mat-form-field class="flex-auto">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="picker1" (dateChange)="changeEvent($event)"
                            [(ngModel)]="suppressDataRegistry.suppressed_on" [min]="fromDate" required autocomplete="off"
                            name="startDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field> -->
                    <mat-form-field class="flex-auto ml-2">
                        <mat-label>End date</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="suppressDataRegistry.suppressed_till"
                            [min]="minEndDate" required name="registryendDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- [disabled]="!suppressForm.form.valid" -->
                <mat-divider class="my-4"></mat-divider>
                <div class="form-row flex items-center justify-end -mx-8 px-8">
                    <button type="submit" id="saveButton" [disabled]="!suppressRegForm.form.valid" class="mr-2"
                        mat-flat-button color="primary" type="button" appPreventMultiClick
                        (click)="suppressRegistry(suppressDataRegistry)">Save
                    </button>
                    <button id="closeButton" (click)="cancelSuppressRegistry()" mat-flat-button>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>