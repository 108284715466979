<div class="flex justify-between items-center overflow-auto p-2 space-x-4 bg-white dark:bg-[#1e293b]">
  <div class="flex flex-row pr-2 items-center gap-6">
    <div class="flex items-center space-x-2">
      <label for="items-page-number">Items per page:</label>
      <mat-form-field class="w-[7vw] mini-form-field">
        <input id="items-page-number" type="number" name="items-page-number" matInput [(ngModel)]="pageSize"
          (ngModelChange)="pageSizeUpdate.next($event)" min="1" max="100" [matAutocomplete]="auto">
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <ng-container *ngFor="let page of pageOptions; let i = index">
          <mat-option [value]="page">{{page}}</mat-option>
        </ng-container>
      </mat-autocomplete>
    </div>
    <mat-paginator #paginator class="" [pageSize]="pageSize" showFirstLastButtons hidePageSize [length]="pageTotal"
      [pageSizeOptions]="pageOptions" (page)="pChanged($event)">
    </mat-paginator>
  </div>
  <div class="flex items-center space-x-2">
    <label for="page-number">Go to page:</label>
    <mat-form-field class="w-[4vw] mini-form-field">
      <input id="page-number" type="number" name="page-number" matInput [(ngModel)]="inputPageNumber" (input)="onPageNumberInput($any($event.target).value)"
        (keypress)="validatePositiveNumber($event)" min="1" [max]="maxPageNumber">
    </mat-form-field>
  </div>
</div>