import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from "../../../shared/table/table.component";
import { Subject, Subscription, takeUntil } from "rxjs";
import { CommonService } from "../../../../_services/common.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { UserService } from "../../../../core/user/user.service";
import { ModalService } from "../../../../_services/modal.service";
import { LoaderService } from "../../../../_services/loader.service";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DynamicFormComponent } from "../../../shared/forms/dynamic-form/dynamic-form.component";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ModalComponent } from "../../../shared/modal.component";
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'app-suppression',
  standalone: true,
  imports: [
    CommonModule, TableComponent, FormsModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, DynamicFormComponent, MatCardModule, MatDividerModule, MatTooltipModule, ModalComponent, FuseScrollbarDirective,

  ],
  templateUrl: './suppression.component.html',
  styleUrls: ['./suppression.component.scss']
})
export class SuppressionComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  suppression: any = {};
  loadtable: boolean = false;
  private subs: Subscription;
  @Input() filterKey: any;
  @Input() mode: any;
  asset_id: any;
  cView: 'allview' | 'asset' | 'asset-details' = 'allview';
  cAsset: any = {};
  asseTableOptions: any = {
    columns: [
      {
        "header": "Status",
        "columnDef": "online_status",
        "filter": "",
        width: 'w-[80px]',
        "cType": "agg",
        "cell": "(element: any) => `${element.online_status}`",
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        selectFilter: true,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false,
        statusicon: true,
        success: true,
        failure: false,
        successIconPath: '/assets/images/on.png',
        successToolTip: 'Online',
        failureIconPath: '/assets/images/off.png',
        failureToolTip: 'Offline',
        isDropdown: true,
        // customCondition: { key: 'agent_type' },
        // allowedCondition: ['LIGHTWEIGHT'],
        dataSource: [
          { text: 'Online', value: 'true' },
          { text: 'Offline', value: 'false' }
        ],
      },
      {
        "header": "IP",
        "columnDef": "ip",
        "filter": "",
        "cell": "(element: any) => `${element.ip}`",
        "order": 0,
        "visible": true,
        "isHyperlink": false,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        "header": "Host Name",
        "columnDef": "host_name",
        "cType": "string",
        "filter": "",
        "cell": "(element: any) => `${element.host_name}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false,
        "clipboard": true
      },
      {
        header: 'Importance',
        cType: 'number',
        columnDef: 'importance',
        iscolumnSearch: true,
        selectFilter: true,
        selectFilterArr: [
          { name: 'Low', id: 'AssetLow', value: 25 },
          { name: 'Medium', id: 'AssetMedium', value: 50 },
          { name: 'High', id: 'AssetHigh', value: 75 },
          { name: 'Critical', id: 'AssetCritical', value: 100 }
        ],
        filter: '', width: '200px',
        isColoredCntrl: true,
        isCustomText: true,
        visible: true,
        order: 5,
        isSort: true
      },
      {
        "header": "Company Name",
        "columnDef": "company_id",
        "cType": "string",
        "filter": "companyidtoname",
        "cell": "(element: any) => `${element.company_id}`",
        "order": 0,
        "visible": false,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
    ],
    sortOptions: { active: 'host_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Assets',
      isServerSide: false,
      selectText: 'asset',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/r/report_queries/asset_view',
        condition: '',
        isGlobal: true,
        isMergeApi: false,
        mergeurl: '/api/agent_status',
        mergeCondition: 'agent_ids',
        mergeKey: 'agent_id',
        srcKey: 'agent_id'
      },
      id: 'suppress_affected_assets'
    },
    customText: [
      {
        status: 1,
        DisplayText: 'Probe',
      },
      {
        status: 2,
        DisplayText: 'Lightweight Agent',
      },
      {
        status: 3,
        DisplayText: 'Lightweight Agent Installed',
      },
      {
        status: 4,
        DisplayText: 'External Scan Agent',
      },
      {
        range: { from: 0, to: 0 },
        color: '#c2ffc4',
        'text-color': '#000000'
      },
      {
        range: { from: 1, to: 40 },
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        range: { from: 41, to: 60 },
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        range: { from: 61, to: 90 },
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        range: { from: 91, to: 99999 },
        color: '#92243e',
        'text-color': '#ffffff'
      },
      {
        status: 25,
        DisplayText: 'Low',
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        status: 50,
        DisplayText: 'Medium',
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        status: 75,
        DisplayText: 'High',
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        status: 100,
        DisplayText: 'Critical',
        color: '#92243e',
        'text-color': '#ffffff'
      }
    ],
    changeValue: new Subject<any>(),
  };
  constructor(public cs: CommonService, private _bs: BaseRequestService,
    private _changeDetectorRef: ChangeDetectorRef,
    public confirmDialog: FuseConfirmationService,
    public toast: MyToastrService, private _baseRequestService: BaseRequestService,
    private uS: UserService, public modalService: ModalService, private loaderService: LoaderService) {
    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
      this._changeDetectorRef.markForCheck();
      this.initialTable();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (this.cs.selctedAsset) {
      this.asset_id = this.cs.selctedAsset;
    } else if (this.cs.selectedTableRows['Assets'] && this.cs.selectedTableRows['Assets'].length) {
      this.asset_id = this.cs.selectedTableRows['Assets'][0].id;
    }
    setTimeout(() => this.initialTable());
  }

  initialTable(): void {
    let conditionParams: any = '';
    const url: any = this.filterKey === 'problem_id' ? 'suppress_vulnerability_problems' : 'suppress_vulnerability_solution';
    if (this.asset_id && this.mode === 'asset') {
      conditionParams = `suppression_status = 'Approved' and asset_id=${this.asset_id}`;
    } else {
      conditionParams = `suppression_status = 'Approved'`;
    }
    this.suppression = {
      columns: [
        {
          "header": "Software Name",
          "columnDef": "software_names",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.software_names}`",
          "order": 0,
          "visible": true,
          // isNoVisible: (this.filterKey === 'problem_id') ? false : true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false,
          "isShowMoreData": true,
          // showpopup: true
        },
        {
          "header": "Vulnerabilities",
          "columnDef": "vulnerabilities",
          "cType": "string",
          "filter": "",
          isHyperlink: false,
          "cell": "(element: any) => `${element.vulnerabilities}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false,
          "isShowMoreData": true,
          showpopup: true
        },
        {
          "header": "Reason",
          "columnDef": "reason",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.reason}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        // {
        //   header: "Ticket ID",
        //   columnDef: "ticket_ids",
        //   cType: "agg",
        //   filter: "",
        //   cell: "(element: any) => `${element.ticket_ids}`",
        //   order: 0,
        //   visible: true,
        //   isToolTip: false,
        //   isHyperlinkKey: 'ticket_status_map',
        //   isToolTipCol: '',
        //   hasMultiData: false,
        //   isNoVisible: this.filterKey === 'problem_id' ? true : false,
        //   class: '',
        //   color: '',
        //   isProgressCntrl: false,
        //   isColoredCntrl: false,
        //   colList: [],
        //   isfaicon: false,
        //   isAddingText: false,
        //   addingText: '',
        //   img: false,
        //   imgPath: '',
        //   isSort: false,
        //   iscolumnSearch: false,
        //   isShowMoreShowLess: true
        // },
        {
          header: "Open Tickets",
          columnDef: "open_ticket_count",
          cType: "number",
          filter: "",
          cell: "(element: any) => ${element.open_ticket_count}",
          order: 0,
          visible: true,
          isToolTip: false,
          isTicketCountView: true,
          isOpenTicket: true,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
        header: "Closed Tickets",
        columnDef: "closed_ticket_count",
        cType: "number",
        filter: "",
        cell: "(element: any) => ${element.closed_ticket_count}",
        order: 0,
        visible: true,
        isToolTip: false,
        isTicketCountView: true,
        isOpenTicket: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false,
       },
        {
          "header": "Suppression Request Sent Time",
          "columnDef": "created",
          "cType": "agg",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.created}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false,
          datePicker: true
        },
        {
          "header": "Suppression Status",
          "columnDef": "suppression_status",
          "cType": "agg",
          "filter": "",
          "cell": "(element: any) => `${element.suppression_status}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Suppressed On",
          "columnDef": "suppressed_on",
          "cType": "date",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.suppressed_on}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false,
          datePicker: true
        },
        {
          "header": "Suppressed Till",
          "columnDef": "suppressed_till",
          "cType": "agg",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.suppressed_till}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false,
          datePicker: true
        },
        {
          "header": "Assets",
          "columnDef": "affected_assets",
          "cType": "agg",
          "filter": "",
          "isHyperlink": true,
          "cell": "(element: any) => `${element.affected_assets}`",
          "order": 0,
          align: "left",
          "visible": true,
          isNoVisible: (this.mode === 'asset') ? true : false,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Suppress Comments",
          "columnDef": "suppress_comments",
          "cType": "agg",
          "filter": "",
          "cell": "(element: any) => `${element.suppress_comments}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Approver Comments",
          "columnDef": "approver_comments",
          "cType": "agg",
          "filter": "",
          "cell": "(element: any) => `${element.approver_comments}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Unsuppressed On",
          "columnDef": "unsuppressed_on",
          "cType": "date",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.unsuppressed_on}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false,
          datePicker: true
        },
        {
          "header": "Unsuppress Comments",
          "columnDef": "unsuppress_comments",
          "cType": "agg",
          "filter": "",
          "cell": "(element: any) => `${element.unsuppress_comments}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          header: "Description",
          columnDef: "description",
          cType: "agg",
          filter: "",
          cell: "(element: any) => `${element.description}`",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
          isShowMoreData: true
        },
      ],
      sortOptions: { active: 'suppressed_on', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'suppression',
        title: 'Suppression',
        isServerSide: false,
        selectText: 'suppression',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        isHideGlobal: true,
        isUserRestCheck: (this.asset_id && this.mode === 'asset') ? false : true,
        actionMenuItems: [
          {
            text: 'Unsuppress',
            icon: 'delete_forever',
            callback: 'editFunction',
            hideLocal: false,
            isGlobal: true,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        showFilter: true,
        showTagFilter: false,
        add: false,
        isNoAdd: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: `/r/report_queries/${url}`,
          condition: conditionParams,
          isMergeApi: true,
          customFnCall: 'getTicketIds',
          requiredKeys: {
            asset_id: this.asset_id,
            filter_key: (this.filterKey === 'problem_id') ? 'problem_name' : 'solution_id'
          }
        },
      },
      sColumns: [
        {
          header: 'Suppression Status', columnDef: 'suppression_status', cType: 'string', isDropdown: true, gloNoSearch: true,
          dataSource: [
            { text: 'Open', value: 'Open' },
            { text: 'Approved', value: 'Approved' },
            { text: 'Closed', value: 'Closed' },
            { text: 'Unsuppressed', value: 'Unsuppressed' },
          ],
        },
      ],
      customText: [
        {
          status: 'Approved',
          DisplayText: 'Approved',
          class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
        },
        {
          status: 'Open',
          DisplayText: 'Open',
          class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'

        },
        {
          status: 'Unsuppressed',
          DisplayText: 'Unsuppressed',
          class: 'bg-orange-200 text-orange-600 dark:bg-orange-600 dark:text-orange-50'

        },
        {
          status: 'Rejected',
          DisplayText: 'Rejected',
          class: 'bg-red-200 text-red-600 dark:bg-red-600 dark:text-red-50'

        }, {
          status: 'Closed',
          DisplayText: 'Closed',
          class: 'bg-gray-200 text-gray-600 dark:bg-gray-600 dark:text-gray-50'
        }
      ],
      changeValue: new Subject<any>(),
    };
    this._changeDetectorRef.detectChanges();
  }
  linkCall($event: any): void {
    if ($event.col === 'affected_assets' || $event.col === 'asset_count') {
      if ($event.row['affected_assets'] === 0) { return };
      const data = Object.assign({}, this.asseTableOptions);
      data.pageData = []; data.tableOptions.pageTotal = 0;
      this.asseTableOptions = {}; this._changeDetectorRef.detectChanges();
      data.tableOptions.serverSide.isGlobal = true;
      const filteredAssetIds = $event.row['asset_ids'].filter((x: any) => x !== null);
      data.tableOptions.customPagination = {
        key: 'id',
        value: filteredAssetIds,
      }
      data.tableOptions.serverSide.condition = true;
      this.asseTableOptions = data; this.cView = 'asset'; this._changeDetectorRef.detectChanges();
    } else if ($event.col === 'host_name' || $event.col === 'ip') {
      this.cAsset = $event.row;
      this.cView = 'asset-details';
    }
  }

  toggleChange($event: any): void {
    this.cAsset = {};
    this.cView = 'asset';
  }

  suppressionActionCall($event: any) {
    if ($event.row.suppression_status !== 'Approved') {
      this.toast.sToast('error', 'Please note that only records marked as "Approved" can be unsuppressed.');
      return;
    }
    this.cs.reason = '';
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Are you sure you want to unsuppress this record?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      },
      isReason: true,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this._bs.doRequest(`/w/exception/suppress_vulnerability/updateexception`, 'post', {
          suppression_status: 'Unsuppressed',
          id: [$event.row.id],
          unsuppress_comments: this.cs.reason
        })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast('success', 'Successfully Unsuppressed');
              this.cs.reason = null
              setTimeout(() => this.cs.selectedTagChange.next({}));
              if (this.filterKey === 'problem_id') {
                this.cs.reloadProgram.emit('');
              }
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      }
    })
  }

  globalCall($event: any): void {
    const approveStatus = $event.row.filter((x: any) => x.suppression_status === 'Approved');
    const otherStatus = $event.row.filter((x: any) => x.suppression_status !== 'Approved');
    if (approveStatus.length === 0) {
      this.toast.sToast('error', 'Please note that only records marked as "Approved" can be unsuppressed.');
      return;
    }
    this.cs.reason = '';
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: (otherStatus && otherStatus.length) ? `Are you sure you want to unsuppress these selected record ? Please note that only records marked as "Approved" can be unsuppressed.` : `Are you sure you want to unsuppress these selected record?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      },
      isReason: true,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this._bs.doRequest(`/w/exception/suppress_vulnerability/updateexception`, 'post', {
          suppression_status: 'Unsuppressed',
          unsuppress_comments: this.cs.reason,
          id: approveStatus.map((x: any) => x.id)
        })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast('success', 'Successfully Unsuppressed');
              setTimeout(() => this.cs.selectedTagChange.next({}));
              if (this.filterKey === 'problem_id') {
                this.cs.reloadProgram.emit('');
              }
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      }
    })
  }
}
