<!-- Notifications toggle -->
<button
    *ngIf="(msspTenant().details && msspTenant().details.length > 0) || (hasAdminRole && adminNotification.description.length > 0 && !isAgentInstallationotification)"
    mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container>
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-500 text-indigo-50 text-xs font-medium">
                1
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="text-lg font-medium leading-10">Notifications</div>
            <div class="ml-auto">
                <button mat-icon-button [matTooltip]="'Close'" (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">

            <!-- Notifications -->
            <ng-container *ngIf="hasAdminRole && !isAgentInstallationotification">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 unread">
                    <div class="flex flex-auto py-3 pl-2 mr-3">
                        <div
                            class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-200 dark:bg-gray-700">
                            <mat-icon class="icon-size-5" [svgIcon]="adminNotification.icon">
                            </mat-icon>
                        </div>
                        <div class="flex flex-col flex-auto">
                            <div class="font-semibold line-clamp-1" [innerHTML]="adminNotification.title"></div>
                            <div [innerHTML]="adminNotification.description"></div>
                            <div class="mt-2">
                                <a [href]="adminNotification.docsLink" target="_blank"
                                    class="text-blue-600 hover:underline">View Documentation</a>
                            </div>
                            <div class="flex items-center mt-2">
                                <input type="checkbox" id="acknowledgeCheckbox"
                                    (change)="onAcknowledgeChange($event.target.checked)" class="mr-2 cursor-pointer">
                                <label for="acknowledgeCheckbox" class="text-sm">I acknowledge this information</label>
                            </div>

                            <button (click)="acknowledgeAdminNotification()"
                                [disabled]="!adminNotification.acknowledged"
                                [ngClass]="adminNotification.acknowledged ? 
                   'mt-2 px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' : 
                   'mt-2 px-3 py-2 text-xs font-medium text-center text-gray-400 bg-gray-300 rounded-lg cursor-not-allowed'">
                                Acknowledge
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Notifications -->
            <ng-container *ngFor="let notification of msspTenant().details; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !notification.read}">

                    <!-- Notification without a link -->
                    <ng-container *ngIf="!notification.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>

                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <ng-container *ngIf="notification.icon && !notification.image">
                        <div
                            class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-200 dark:bg-gray-700">
                            <mat-icon class="icon-size-5" [svgIcon]="notification.icon">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="notification.image">
                        <img class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="notification.image" [alt]="'Notification image'">
                    </ng-container>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="notification.title">
                            <div class="font-semibold line-clamp-1" [innerHTML]="notification.title"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.description">
                            <div class="" [innerHTML]="notification.description"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
            <div class="flex items-center mt-2  py-4 pr-4 pl-1 sm:pl-7 border-t"
                *ngIf="msspTenant().details && msspTenant().details.length">
                <button type="button" (click)="actionChange('Rejected')"
                    class="ml-auto px-3 py-2 text-xs font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Reject</button>
                <button type="button" (click)="actionChange('Approved')"
                    class="ml-2 px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Approve</button>
            </div>
            <!-- No notifications -->

            <ng-container
                *ngIf="(!msspTenant().details || !msspTenant().details.length) && (!hasAdminRole && !adminNotification.description.length && isAgentInstallationotification)">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have notifications,
                        they will appear here.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>