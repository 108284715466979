<div class="flex-auto -mt-px" *ngIf="cView === 'jobs'">
    <div class="w-full">
        <mat-drawer-container class="flex-auto h-[88vh]">
            <!-- Drawer sm:w-96 -->
            <mat-drawer class="dark:bg-gray-900 z-999" [ngClass]="{'w-full lg:w-[50vw]': drawerOpened}"
                [autoFocus]="true" [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
                <!-- Main -->
                <div class="flex-auto" *ngIf="currentDataCheck">
                    <ng-container *ngIf="currentData">
                        <div class="relative flex flex-col h-full my-2 rounded-2xl">
                            <div class="p-6 border-b">
                                <div class="flex flex-row flex-wrap items-center">
                                    <div class="flex flex-row items-center">
                                        <h6 class="text-xl font-semibold">{{currentData.type | enumString}}</h6>
                                        <p class="ml-2 text-sm font-light">
                                            <span *ngIf="currentData.status"
                                                class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                [ngClass]="{
                                                    'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': currentData.status.toLowerCase() == 'initiated',
                                                    'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': currentData.status.toLowerCase() === 'started',
                                                    'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': currentData.status.toLowerCase() === 'inprogress',
                                                    'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': currentData.status.toLowerCase() === 'in progress',
                                                    'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': currentData.status.toLowerCase() === 'completed'
                                                     || currentData.status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': currentData.status.toLowerCase() === 'failed',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': currentData.status.toLowerCase() === 'terminated',
                                                }">
                                                <span class="leading-relaxed whitespace-nowrap">{{currentData.status |
                                                    enumString}}</span>
                                            </span>
                                        </p>
                                        <mat-icon
                                            class="ml-2 text-blue-500 cursor-pointer dark:text-blue-400 hover:text-blue-600"
                                            [svgIcon]="'mat_solid:download_for_offline'" (click)="downloadJobs()"
                                            *ngIf="currentData.job_details && currentData.job_details.length"></mat-icon>
                                        <mat-icon
                                            class="ml-2 text-blue-500 cursor-pointer dark:text-blue-400 hover:text-blue-600"
                                            [svgIcon]="'mat_solid:download_for_offline'" (click)="downloadJobsDetails()"
                                            [class.disabled-icon]="disableDownloadButton"
                                            [ngClass]="{'pointer-events-none': disableDownloadButton, 'opacity-50' : disableDownloadButton}"
                                            *ngIf="_reportDetails && _reportDetails.length"></mat-icon>
                                    </div>
                                    <button class="ml-auto" mat-icon-button (click)="closeCurrentData()">
                                        <mat-icon class="text-current"
                                            [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <ng-container *ngIf="jobDetails.indexOf(currentData.type)>-1">
                                <div class="flex-auto h-full p-6"
                                    *ngIf="currentData.job_details && currentData.job_details.length && !loading">
                                    <div class="relative flex flex-col justify-center">
                                        <div class="absolute h-full border-r-2 left-4"></div>
                                        <div class="relative mb-4"
                                            *ngFor="let item of currentData.job_details; trackBy: trackByFn ">
                                            <span
                                                class="absolute inline-flex items-center justify-center w-6 h-6 p-4 text-base font-semibold text-center text-white rounded-full shadow"
                                                [ngClass]="{
                                                'bg-green-500': item.success === true,
                                                'bg-blue-500': !item.type || item.type.toLowerCase() === 'info',
                                                'bg-orange-500': item.type && item.type.toLowerCase() === 'warning',
                                                 'bg-red-500': item.type && item.type.toLowerCase() === 'error'
                                                 }">
                                                <i class="far fa-clock"></i>
                                            </span>
                                            <div class="w-auto pt-1 ml-12">
                                                <h6 class="text-md font-semibold">
                                                    {{item.type}}
                                                </h6>
                                                <h6 class="text-secondary font-sm">
                                                    {{item.description | enumString: true}}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!currentData.job_details || !currentData.job_details.length"
                                    class="flex flex-col items-center justify-center gap-y-2 h-1/4">
                                    <img src="/assets/images/empty.png" alt="" class="w-20">
                                    <div> No data available!</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="jobDetails.indexOf(currentData.type) === -1">
                                <div class="flex-auto h-full p-6">
                                    <div class="relative flex flex-col justify-center">
                                        <div class="absolute h-full border-r-2 left-4"></div>
                                        <div class="relative mb-4"
                                            *ngFor="let item of _reportDetails; trackBy: trackByFn ">
                                            <span *ngIf="objectKeys(item.job_detail).length"
                                                class="absolute inline-flex items-center justify-center w-6 h-6 p-4 text-base font-semibold text-center text-white rounded-full shadow"
                                                [ngClass]="{
                                                'bg-blue-500': !item.job_detail.type || item.job_detail.type.toLowerCase() === 'info',
                                                'bg-orange-500': item.job_detail.type && item.job_detail.type.toLowerCase() === 'warning',
                                                 'bg-red-500': item.job_detail.type && item.job_detail.type.toLowerCase() === 'error'
                                                 }">
                                                <i class="far fa-clock"></i>
                                            </span>
                                            <div class="w-auto pt-1 ml-12">
                                                <h6 class="text-sm font-semibold">
                                                    <span *ngIf="item.job_detail.protocol"
                                                        class="inline-flex font-bold rounded text-xs text-gray-900 bg-blue-200 px-1.5 py-0.5 mr-2">{{item.job_detail.protocol
                                                        | enumString}}</span>
                                                    <div [innerHTML]="item.job_detail.description | enumString: true">
                                                    </div>
                                                </h6>
                                                <p class="mt-1 text-xs" *ngIf="item.job_detail.reason">
                                                    {{item.job_detail.reason | enumString: true}}
                                                </p>
                                                <p class="mt-1 text-xs text-gray-500" *ngIf="item.job_detail.job_time">
                                                    <!-- {{item.job_time|iso8601ToLocalTime}} -->
                                                    {{item.job_detail.job_time | utcToLocaleRDate}}
                                                </p>
                                                <div class="flex flex-wrap w-auto gap-1 pt-1"
                                                    *ngIf="item.job_detail.ip_address && item.job_detail.ip_address.length">
                                                    <show-more-less [items]="item.job_detail.ip_address"
                                                        [showAllItems]="false" [type]="'list'"
                                                        [bgColor]="'bg-yellow-200'" [color]="'text-blue-900'"
                                                        [maxItemsToShow]="10" [showOrder]="'span'"></show-more-less>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="loading" class="flex flex-col items-center justify-center gap-y-2 h-1/4">
                            <img src="/assets/images/tl.gif" alt="" class="w-20">
                            <div> Please wait!</div>
                        </div>
                        <div class="shadow-lg rounded p-1 mb-6" *ngIf="jobDetails.indexOf(currentData.type) === -1">
                            <mat-divider class="mt-4 mb-2"></mat-divider>
                            <paginator-widget [pageOptions]="pageOptions" [pageSize]="pageSize" [pageTotal]="pageTotal"
                                (pageChanged)="pageChanged($event)"></paginator-widget>
                        </div>
                    </ng-container>
                </div>
                <div class="flex-auto" *ngIf="reportCurrentDataCheck">
                    <ng-container *ngIf="reportCurrentData && reportCurrentData.length">
                        <div class="relative flex flex-col h-full my-2 shadow-lg rounded-2xl">
                            <div class="p-6 border-b">
                                <div class="flex flex-row flex-wrap items-center">
                                    <div class="flex flex-row items-center">
                                        <h6 class="text-xl font-semibold">
                                            {{reportCurrentData[0].job_details[0].displayReportName
                                            }}
                                        </h6>
                                        <p class="ml-2 text-sm font-light">
                                            <span *ngIf="reportCurrentData[0].status"
                                                class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                [ngClass]="{
                                                  'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': reportCurrentData[0].status.toLowerCase() == 'initiated',
                                                  'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': reportCurrentData[0].status.toLowerCase() === 'started',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': reportCurrentData[0].status.toLowerCase() === 'inprogress',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': reportCurrentData[0].status.toLowerCase() === 'in progress',
                                                   'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': reportCurrentData[0].status.toLowerCase() === 'completed'
                                                   || reportCurrentData[0].status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': reportCurrentData[0].status.toLowerCase() === 'failed',
                                                    'bg-slate-200 text-slate-800 dark:bg-slate-600 dark:text-slate-50': reportCurrentData[0].status.toLowerCase() === 'pushedtoqueue'
                                                 }">
                                                <span
                                                    class="leading-relaxed whitespace-nowrap">{{reportCurrentData[0].status
                                                    |
                                                    enumString}}</span>
                                            </span>
                                        </p>
                                    </div>
                                    <button class="ml-auto" mat-icon-button (click)="closeReportCurrentData()">
                                        <mat-icon class="text-current"
                                            [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="flex-auto h-full max-h-screen p-6">
                                <div class="w-auto py-4 rounded-2xl">
                                    <div class="p-4 mt-3 border bg-default rounded-2xl">
                                        <div class="max-w-full px-6 py-2 mt-4 prose-sm prose bg-card rounded-xl"
                                            fuseScrollbar>
                                            <h3>Report Job Status</h3>
                                            <table class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th class="dark:text-white">Report Name</th>
                                                        <th class="dark:text-white">Report Type</th>
                                                        <th class="dark:text-white">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let report of reportCurrentData|search:searchFilter; trackBy: trackByFn">
                                                        <tr>
                                                            <td>{{report.job_details[0].displayReportName}}</td>
                                                            <td>{{report.type}}</td>
                                                            <td class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                                [ngClass]="{
                                                  'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': reportCurrentData[0].status.toLowerCase() == 'initiated',
                                                  'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': reportCurrentData[0].status.toLowerCase() === 'started',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': reportCurrentData[0].status.toLowerCase() === 'inprogress',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': reportCurrentData[0].status.toLowerCase() === 'in progress',
                                                   'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': reportCurrentData[0].status.toLowerCase() === 'completed'
                                                   || reportCurrentData[0].status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': reportCurrentData[0].status.toLowerCase() === 'failed',
                                                     'bg-slate-200 text-slate-800 dark:bg-slate-600 dark:text-slate-50': reportCurrentData[0].status.toLowerCase() === 'pushedtoqueue',
                                                 }">{{report.status}}</td>
                                                        </tr>
                                                    </ng-container>
                                                    <div class="mt-6 ml-6"
                                                        *ngIf="!reportCurrentData || !reportCurrentData.length">
                                                        <div> No data available!</div>
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>
            </mat-drawer>
            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col">
                <div class="w-auto py-4 rounded-2xl">
                    <stable aria-label="Scan Job Table" role="table"
                        *ngIf="scanTableOptions && scanTableOptions.tableOptions" (actionCallback)="actionCall($event)"
                        [sTableOptions]="scanTableOptions" (globalActionCallback)="globalActionCall($event)"
                        (hyperlinkCallback)="linkClick($event)"></stable>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
<div class="px-4 dark:bg-transparent" style="background-color: #e2e8f0" *ngIf="cView === 'results'">
    <app-asm-results (closeCallback)="closeCallBack($event)" [apiCallSuccessful]="apiCallSuccessful"
        [currentJob]="currentData"></app-asm-results>
</div>