import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { BaseRequestService } from 'app/_services/base.service';
import { cloneDeep } from 'lodash';
import { MyToastrService } from 'app/_services/toastr.service';
import { LoaderService } from 'app/_services/loader.service';
import { Router } from '@angular/router';

@Component({
    selector: 'shortcuts',
    templateUrl: './shortcuts.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'shortcuts'
})
export class ShortcutsComponent implements OnInit, OnDestroy {
    @ViewChild('shortcutsOrigin') private _shortcutsOrigin: MatButton;
    @ViewChild('shortcutsPanel') private _shortcutsPanel: TemplateRef<any>;

    mode: 'view' | 'modify' | 'add' | 'edit' = 'view';
    shortcutForm: UntypedFormGroup;
    shortcuts: any = [];
    favourite: any = {};
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _loaderService: LoaderService,
        private _overlay: Overlay,
        private router: Router,
        private _viewContainerRef: ViewContainerRef,
        private _bs: BaseRequestService,
        private _toast: MyToastrService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Initialize the form
        this.shortcutForm = this._formBuilder.group({
            id: [null],
            label: ['', Validators.required],
            description: [''],
            link: ['', Validators.required],
        });
    }

    getShortcuts(): void {
        this._loaderService.display(true);
        const params: any = { condition: `company_id IS NULL and category='global' and sub_category='favorite_settings' and name='${this._bs.user().email}'`, skip: 0, limit: 1 }
        this._bs.doRequest(`/r/company/settings`, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((shortcuts: any) => {
                this._loaderService.display(false);
                this.favourite = { 'category': 'global', 'sub_category': 'favorite_settings', 'name': this._bs.user().email, 'value': [] };
                this.shortcuts = [];
                if (shortcuts.status && shortcuts.data && shortcuts.data.length) {
                    this.favourite = shortcuts.data[0];
                    this.shortcuts = shortcuts.data[0].value;
                }
                // Make sure to start in 'view' mode
                this.mode = 'view';

                // Create the overlay if it doesn't exist
                if (!this._overlayRef) {
                    this._createOverlay();
                }

                // Attach the portal to the overlay
                this._overlayRef.attach(new TemplatePortal(this._shortcutsPanel, this._viewContainerRef));
                // Mark for check
                this._changeDetectorRef.markForCheck();
            })
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the shortcuts panel
     */
    openPanel(): void {
        // Return if the shortcuts panel or its origin is not defined
        if (!this._shortcutsPanel || !this._shortcutsOrigin) {
            return;
        }
        this.getShortcuts()
    }

    /**
     * Close the shortcuts panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Change the mode
     */
    changeMode(mode: 'view' | 'modify' | 'add' | 'edit'): void {
        // Change the mode
        this.mode = mode;
    }

    /**
     * Prepare for a new shortcut
     */
    newShortcut(): void {
        // Reset the form
        this.shortcutForm.reset();

        // Enter the add mode
        this.mode = 'add';
    }

    /**
     * Edit a shortcut
     */
    editShortcut(shortcut: any): void {
        // Reset the form with the shortcut
        this.shortcutForm.reset(shortcut);

        // Enter the edit mode
        this.mode = 'edit';
    }

    /**
     * Save shortcut
     */
    save(): void {
        // Get the data from the form
        const shortcut = cloneDeep(this.shortcutForm.value);
        const duplicate = this.shortcuts.filter(item => item.link === shortcut.link);
        if (duplicate && duplicate.length && !shortcut.id) {
            this._toast.sToast('info', 'Already added to Favourites.');
            return;
        }
        // If there is an id, update it...
        if (this.favourite && this.favourite.id) {
            const reqdata: any = { data: cloneDeep(this.favourite) };
            let is_new = false;
            if (shortcut.id) {
                const index = this.favourite.value.findIndex(item => item.id === shortcut.id);
                reqdata.data.value[index] = shortcut; is_new = false;
            } else {
                is_new = true;
                reqdata.data.value.push(shortcut);
            }
            reqdata.data.value.map((x: any, i: any) => x.id = i + 1);
            reqdata.id = this.favourite.id;
            delete reqdata.data.id;
            this._loaderService.display(true);
            this._bs.doRequest(`/w/company/settings`, 'patch', reqdata).pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this._loaderService.display(false);
                    if (result.status) {
                        this.shortcuts = reqdata.data.value;
                        this.favourite.value = reqdata.data.value;
                        // Go back the view mode
                        this.mode = 'view';
                        this._toast.sToast('success', (!is_new) ? 'Favourite updated successfully.' : 'Favourite addded successfully.');
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this._toast.sToast('error', data);
                    }
                });
        }
        // Otherwise, create a new shortcut...
        else {
            const reqdata: any = { data: cloneDeep(this.favourite) };
            reqdata.data.value.push(shortcut);
            reqdata.data.value.map((x: any, i: any) => x.id = i + 1);
            this._loaderService.display(true);
            this._bs.doRequest(`/w/company/settings`, 'post', reqdata).pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this._loaderService.display(false);
                    if (result.status) {
                        this.shortcuts = reqdata.data.value;
                        this.favourite.value = reqdata.data.value;
                        this.favourite.id = parseInt(result.id);
                        // Go back the view mode
                        this.mode = 'view';
                        this._toast.sToast('success', 'Favourite added successfully.');
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this._toast.sToast('error', data);
                    }
                });
        }

    }

    /**
     * Delete shortcut
     */
    delete(): void {
        // Get the data from the form
        const shortcut = this.shortcutForm.value;
        const reqdata: any = { data: cloneDeep(this.favourite) };
        reqdata.data.value = reqdata.data.value.filter(item => item.id !== shortcut.id);;
        reqdata.data.value.map((x: any, i: any) => x.id = i + 1);
        delete reqdata.data.id; reqdata.id = this.favourite.id;
        this._loaderService.display(true);
        this._bs.doRequest(`/w/company/settings`, 'patch', reqdata).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this._loaderService.display(false);
                if (result.status) {
                    this.shortcuts = reqdata.data.value;
                    this.favourite.value = reqdata.data.value;
                    // Go back the view mode
                    this.mode = 'view';
                    this._toast.sToast('success', 'Favourite removed successfully.');
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this._toast.sToast('error', data);
                }
            });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._shortcutsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    redirect(url: any): void {
        this.closePanel();
        this.router.navigate([url]).then(r => console.log(r));
    }
}
