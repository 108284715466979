<ng-container *ngFor="let item of gettingStartedList; let i = index;">
    <mat-card class="w-full mat-elevation-z0 pull-up p-0 mb-2">
        <div class="flex flex-row items-center ring-1 rounded-lg p-0 shadow-lg">
            <div class="w-3/4 p-3 border-r">
                <div class="flex flex-col p-4">
                    <div class="text-lg font-medium">{{item.title}}</div>
                    <div [innerHTML]="item.description|safeHTML"></div>
                    <div class="mt-0.5 line-clamp-2 text-secondary" *ngIf="item.highlight">{{item.highlight}}</div>
                    <div class="leading-5 truncate text-medium text-primary dark:text-primary-500">{{item.time}}
                    </div>
                    <ng-container *ngIf="item.link">
                        <div class="w-50 h-1 my-4 border-t-2"></div>
                        <div class="flex flex-row">
                            <mat-icon class="icon-size-5 mr-2"
                                [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                            <div class="line-clamp-2 text-secondary mr-1">{{item.linkDesc}}</div>
                            <a class="text-primary" [href]="item.link" target="_blank">{{item.linkText}}</a>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="w-1/4 p-3 flex items-center justify-center">
                <img [src]="item.img" alt="CyberCNS" class="w-24 h-24">
            </div>
        </div>
    </mat-card>
</ng-container>
<ng-container>
    <mat-card class="w-full mat-elevation-z0 pull-up p-0 mb-2">
        <div class="flex flex-row ring-1 gap-2 p-1 rounded">
            <div class="w-3/4 border-r">
                <div class="flex flex-col p-4">
                    <div class="text-lg border-b mb-2 font-medium">Documentation Links</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="flex flex-row ring-1 cursor-pointer rounded p-1 gap-1"
                            *ngFor="let d of docLinks; trackBy: trackByFn" (click)="reDirect(d.link)">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                            <div class="tracking-tight leading-tight text-blue-600 dark:text-blue-400">{{d.name}}</div>
                            <div class="spacer"></div>
                            <a class="font-light mini-form-field mr-2 text-white px-2 rounded-lg py-0.5 bg-primary text-xs ring-1"
                                href="javascript:" target="_blank">LINK</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/4 p-3 flex items-center justify-center">
                <img [src]="'/assets/getting-started/worldwide.png'" alt="CyberCNS" class="w-24 h-24">
            </div>
        </div>
    </mat-card>
</ng-container>
<h1>&nbsp;</h1>