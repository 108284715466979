<div class="flex flex-wrap items-center flex-auto m-4 rounded-2xl overflow-auto" *ngIf="showFilter">
    <ejs-querybuilder #querybuilder class="row overflow-auto max-h-60" width="100%">
        <e-columns class="overflow-auto">
            <ng-container *ngFor="let c of sColumns; let i = index">
                <!-- First Column Selection -->
                <e-column [field]="c.columnDef" [label]="c.header" type="string" [operators]="customOperators"
                    *ngIf="c.isDropdown && !c.isCustomFilter">
                    <ng-template #template let-data>
                        <ejs-dropdownlist [dataSource]='c.dataSource' [value]='data.rule.value'
                            (change)="filterChange($event, data.ruleID, c.dataSource)">
                        </ejs-dropdownlist>
                    </ng-template>
                </e-column>
                <e-column [field]="c.columnDef" [label]="c.header" type="string" [operators]="c.isCustomFilter"
                    *ngIf="c.isDropdown && c.isCustomFilter">
                    <ng-template #template let-data>
                        <ejs-dropdownlist [dataSource]='c.dataSource' [value]='data.rule.value'
                            (change)="filterChange($event, data.ruleID, c.dataSource)">
                        </ejs-dropdownlist>
                    </ng-template>
                </e-column>
                <!-- Second Column -->
                <e-column [field]="c.columnDef" [label]="c.header" type="string" [operators]="customOperators"
                    *ngIf="c.isCheckbox">
                    <ng-template #template let-data>
                        <ejs-checkbox [label]="c.header" [checked]='data.rule.value === c.successValue ? true: false'
                            (change)="checkboxChange($event, data.ruleID)">
                        </ejs-checkbox>
                    </ng-template>
                </e-column>
                <!-- Third Column Selection -->
                <e-column [field]="c.columnDef" [label]="c.header" type="boolean" [values]="c.dataSource"
                    *ngIf="c.radiobutton"></e-column>
                <!-- Fourth Column Selection  -->
                <e-column [field]="c.columnDef" [label]="c.header" [type]="c.cType" *ngIf="c.datePicker"
                    [format]="'dd/MM/yyyy'"></e-column>
                <!-- <e-column [field]="c.columnDef" [label]="c.header"
                    *ngIf="!c.isDropdown && !c.isCheckbox && !c.radiobutton && !c.datePicker"
                    [operators]="stringCusOperators" [type]="c.cType"></e-column> -->

                <!--Sixth Column Custom Filter JSON's -->
                <e-column [field]="c.columnDef" [label]="c.header" *ngIf="c.isCustomFilter && !c.isDropdown" [operators]="c.isCustomFilter" [type]="c.cType">
                </e-column>

                <!-- Fifth Column Selection  -->
                <e-column [field]="c.columnDef" [label]="c.header"
                    *ngIf="!c.isDropdown && !c.isCheckbox && !c.radiobutton && !c.datePicker && !c.isCustomFilter && !c.noUserFilter" [operators]="shouldUseDataOperators(c.header, c.columnDef)? dataOperators : stringCusOperators"
                    [type]="c.cType">
                </e-column>
            </ng-container>
        </e-columns>
    </ejs-querybuilder>
    <div class="flex flex-row items-center mt-4">
        <button class="e-btn e-primary e-qb-button" (click)="setRules()">Submit</button>
        <button class="mx-2 e-btn e-warning e-qb-button" (click)="resetRules()">Reset</button>
        <button class="mx-2 e-btn e-secondary e-qb-button" (click)="toggleFilter()">Close</button>
    </div>
</div>