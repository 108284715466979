import { Directive, HostListener, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appIPCheck]'
})
export class IPCheckDirective {

  constructor(private el:ElementRef, private _toastr: ToastrService) {
   }

  @HostListener('focusout')
  onFocusOut(){
    let current: string = this.el.nativeElement.value;
    if (current != "") {
      if (current && !this.isPrivateIP(current) && !this.isPublicDNSIP(current)) {
        this._toastr.error("Invalid IP");
        this.el.nativeElement.focus();
      }
    }
  }

  // Function to check if an IP is private
 isPrivateIP(ip: any): boolean {
  // Private IP address ranges
  const privateIPRanges = [
    /^10\./,
    /^192\.168\./,
    /^172\.(1[6-9]|2[0-9]|3[0-1])\./,
  ];

  return privateIPRanges.some((range) => range.test(ip));
}

// Function to check if an IP is a public DNS IP
 isPublicDNSIP(ip: any): boolean {
  // Public DNS IP addresses
  const publicDNSIPs = ['8.8.8.8', '8.8.4.4', '1.1.1.1', '1.0.0.1'];
  return publicDNSIPs.includes(ip);
}

}
