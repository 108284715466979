import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from "../../../_services/base.service";
import { LoaderService } from "../../../_services/loader.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { AssetDetailsComponent } from "../asset-details/asset-details.component";
import { DynamicSortComponent } from "../dynamic-sort/dynamic-sort.component";
import { FuseAlertComponent } from "../../../../@fuse/components/alert";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TableComponent } from "../table/table.component";
import { Subject, takeUntil } from "rxjs";
import { CommonService } from 'app/_services/common.service';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { NoDataComponent } from '../no-data/no-data.component';

@Component({
    selector: 'app-user-shares',
    standalone: true,
    imports: [CommonModule, AppFilterPipeModule, NoDataComponent, SkeletonComponent, AssetDetailsComponent, DynamicSortComponent, FuseAlertComponent, FuseScrollbarDirective, MatButtonModule, MatDividerModule, MatIconModule, MatListModule, MatSidenavModule, MatTooltipModule, TableComponent],
    templateUrl: './user-shares.component.html',
    styleUrls: ['./user-shares.component.scss']
})
export class UserSharesComponent implements OnInit, OnDestroy {
    @Input() asset: any;
    userShares: any;
    loadingCon: any = {
        loading: true,
        nodata: false
    };
    objectKeys = Object.keys;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    drawerPosition: 'start' | 'end' = 'end';
    drawerMode: 'over' | 'side' = 'over';
    drawerOpened: boolean = false;
    currentShare: any;
    share: any;
    shareHash: any = {};
    shares: any = [];
    sortType = 'name';
    sortReverse = false;
    constructor(private _bs: BaseRequestService, public _cs: CommonService,
        private ls: LoaderService, private toast: MyToastrService) {
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    sortFn(key: any): void {
        this.sortType = key; this.sortReverse = !this.sortReverse;
        const isAsc = !this.sortReverse;
        if (isAsc) {
            const sortedAscData = Object.fromEntries(
                Object.entries(this.shareHash).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            );
            this.shareHash = sortedAscData;
            this.setCurrentShare(this.objectKeys(this.shareHash)[0]);
        } else {
            const sortedDescData = Object.fromEntries(
                Object.entries(this.shareHash).sort(([keyA], [keyB]) => keyB.localeCompare(keyA))
            );
            this.shareHash = sortedDescData;
            this.setCurrentShare(this.objectKeys(this.shareHash)[0]);
        }

    }

    ngOnInit(): void {
        this.getUserShares();
    }

    setCurrentShare(share: any): void {
        this.share = share;
        this.currentShare = undefined;
        setTimeout(() => {
            this.currentShare = this.shareHash[share].slice();
        });
    }

    getUserShares(): void {
        if (!this.asset) {
            setTimeout(() => {
                this.getUserShares();
            }, 2000);
            return;
        }
        this.ls.display(true);
        this._bs.doRequest(`/r/company/asset_user_shares`, 'get', null,
            {
                condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`,
                skip: 0,
                limit: 1000,
                order_by: 'name asc'
            })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.shares = [];
                        res.data.forEach((obj: any) => {
                            if (this.shareHash[obj.name]) {
                                this.shareHash[obj.name].push(obj);
                            } else {
                                this.shareHash[obj.name] = [];
                                this.shareHash[obj.name].push(obj);
                            }
                        });
                        this.userShares = res.data;
                        this.loadingCon.loading = null;
                        this.loadingCon.nodata = null;
                        this.setCurrentShare(this.objectKeys(this.shareHash)[0]);
                    } else {
                        this.loadingCon.loading = null;
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }
}



