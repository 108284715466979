<div class="py-2">
    <fuse-alert [type]="'info'" [appearance]="'border'">
        <span fuseAlertTitle>Backup Software</span>
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            The Global Backup Software contains the list of Backup Software shown in the security report card for
            the
            respective assets and also to reflect as complaint for the asset under the Essential Eight Backup
            Software
            Compliance rule.
        </span><br />
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            Any Backup Software that is not already listed under Global Backup Software can be added here. Please
            run a
            scan after adding here to reflect the software name in the security report card for the respective
            asset.
        </span><br />
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            To Exclude any of the Backup Software from the Global Backup Software list, please click on the '->' for
            the
            selected software.
        </span>
    </fuse-alert>
    <div class="flex flex-row justify-center items-center gap-6 mt-4" *ngIf="!hideTable">
        <div
            class="relative w-[40vw] h-[50vh] bg-card pb-2 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-row text-lg font-medium leading-6 tracking-tight truncate">
                        <span class="mt-2">
                            Global Backup Software
                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                {{globalSoftware.length ||
                                0}} </span>
                        </span>
                        <button class="ml-4 mt-2" *ngIf="(hasSelected() || isAllSelected()) && globalSoftware.length"
                            (click)="changeBulkExclude()" matTooltip="Bulk Exclude">
                            <mat-icon color="primary">arrow_forward</mat-icon>
                        </button>
                        <button class="px-6 text-lg font-semibold mini-form-field cursor-pointer" mat-stroked-button
                            *ngIf="!hideTable && !hasSelected() && !isAllSelected()" type="button"
                            matTooltip="Add New Record" color="primary" (click)="toggleAddMode(true)">
                            Add
                        </button>
                    </div>
                    <div class="spcacer"></div>
                    <mat-form-field class="w-1/2 -mr-2 mini-form-field" appearance="fill">
                        <input type="text" matInput name="globalSearch" [(ngModel)]="globalSearch">
                    </mat-form-field>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1 overflow-auto" cdkScrollable>
                    <mat-list-item class="border-b">
                        <div class="flex flex-row items-center">
                            <mat-checkbox [(ngModel)]="selectAll" (change)="selectAllToggle()" class=""
                                [checked]="selectAll && isAllSelected()"
                                [indeterminate]="hasSelected() && !isAllSelected()" value="" color="primary">
                            </mat-checkbox>
                            <span class="spacer"></span>
                            <span class="text-sm font-bold">Name</span>
                            <div class="spacer">&nbsp;</div>
                            <span class="text-sm font-bold">Action</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngIf="globalSoftware.length; else noData1">
                        <mat-list-item class="border-b pointer"
                            *ngFor="let sw of globalSoftware | search: globalSearch;let i = index;">
                            <div class="flex flex-row items-center">
                                <mat-checkbox [(ngModel)]="sw.selected" value="" class="pt-2" name="select{{i}}"
                                    color="primary"></mat-checkbox>
                                <div class="spacer">&nbsp;</div>
                                <span>{{sw.name}}</span>
                                <div class="spacer">&nbsp;</div>
                                <button class="ml-1 mini-form-field" type="button" (click)="changeExclude(sw)"
                                    [disabled]="hasSelected() || isAllSelected()">
                                    <mat-icon color="primary">arrow_forward</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #noData1>
                        <div class="flex items-center justify-center h-20 text-center">No software found.</div>
                    </ng-template>
                </mat-list>
            </div>
        </div>
        <div
            class="relative w-[40vw] h-[50vh] bg-card border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-row text-lg font-medium leading-6 tracking-tight truncate">
                        <span class="mt-2">
                            Excluded Backup Software
                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                {{excludedSoftware.length
                                || 0}} </span>
                        </span>
                    </div>
                    <button class="ml-4 mt-2"
                        *ngIf="(hasExcludedSelected() || isAllExcludedSelected()) && excludedSoftware.length"
                        (click)="removeBulkExclude()" matTooltip="Bulk Remove">
                        <mat-icon color="primary">cancel</mat-icon>
                    </button>
                    <div class="spcacer"></div>
                    <mat-form-field class="w-1/2 -mr-2 mini-form-field" appearance="fill">
                        <input type="text" matInput name="exSearch" [(ngModel)]="excludedSearch">
                    </mat-form-field>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1 overflow-auto">
                    <mat-list-item class="border-b">
                        <div class="flex flex-row items-center">
                            <mat-checkbox [(ngModel)]="selectAllExcluded" (change)="selectAllExcludedToggle()" class=""
                                [checked]="selectAllExcluded && isAllExcludedSelected()"
                                [indeterminate]="hasExcludedSelected() && !isAllExcludedSelected()" value=""
                                color="primary">
                            </mat-checkbox>
                            <span class="spacer"></span>
                            <span class="text-sm font-bold">Name</span>
                            <div class="spacer">&nbsp;</div>
                            <span class="text-sm font-bold">Action</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngIf="excludedSoftware.length; else noData1">
                        <mat-list-item class="border-b pointer"
                            *ngFor="let sw of excludedSoftware | search: excludedSearch;let i = index;">
                            <div class="flex flex-row items-center">
                                <mat-checkbox [(ngModel)]="sw.selected" value="" class="pt-2" name="exselect{{i}}"
                                    color="primary"></mat-checkbox>
                                <div class="spacer">&nbsp;</div>
                                <span>{{sw.name}}</span>
                                <div class="spacer">&nbsp;</div>
                                <button class="ml-1 mini-form-field" type="button" (click)="removeExclude(sw.name)"
                                    [disabled]="hasExcludedSelected() || isAllExcludedSelected()">
                                    <mat-icon color="primary">cancel</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </ng-container>

                    <ng-template #noData1>
                        <div class="flex items-center justify-center h-20 text-center">No software found.</div>
                    </ng-template>
                </mat-list>

            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="hideTable">
        <div
            class="grid grid-cols-2 gap-4 p-2 mt-4 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <mat-form-field>
                <mat-label>Software Name</mat-label>
                <input matInput placeholder="Enter name" type="text" required [(ngModel)]="backupSoftware.name"
                    autocomplete="off" name="softwareName">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Manufacturer</mat-label>
                <input matInput placeholder="Enter manufacturer" type="text" [(ngModel)]="backupSoftware.manufacturer"
                    autocomplete="off" name="softwareManufacturer">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Year Of Release</mat-label>
                <input matInput placeholder="Enter year of release" type="number"
                    [(ngModel)]="backupSoftware.year_of_release" autocomplete="off" name="yearOfRelease">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput placeholder="Enter description" type="text" [(ngModel)]="backupSoftware.description"
                    autocomplete="off" name="softwareDesc">
            </mat-form-field>
            <mat-checkbox name="softwareRegex" id="softwareRegex" class="ml-2" [(ngModel)]="backupSoftware.regex"
                value="" color="primary">Enable
                Regex
            </mat-checkbox>
        </div>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'default'" type="button" appPreventMultiClick
                [matTooltip]="'Cancel'" (click)="toggleAddMode(false)">
                Cancel
            </button>
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveBackupSoftware()">
                Save
            </button>
        </div>
    </div>
</div>