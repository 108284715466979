import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { AsyncPipe, CommonModule, NgClass, NgForOf, NgIf } from "@angular/common";
import { FormControlService } from "app/_services/form-control.service";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { debounceTime, takeUntil } from "rxjs/operators";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { LoaderService } from "../../../../_services/loader.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { MyToastrService } from "../../../../_services/toastr.service";
import { DynamicControlComponent } from "../dynamic-control/dynamic-control.component";
import { MaterialModule } from "../../../../material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { DirectivesModule } from "app/-directives/-directives.module";
import { NgxTurnstileModule } from "@feargannicus/ngx-turnstile";
import { CommonService } from "app/_services/common.service";
import { cloneDeep } from "lodash";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";

@Component({
    selector: "app-dynamic-form",
    standalone: true,
    templateUrl: "./dynamic-form.component.html",
    styleUrls: ["./dynamic-form.component.scss"],
    imports: [
        ReactiveFormsModule,
        DynamicControlComponent,
        MaterialModule,
        NgxMatSelectSearchModule,
        AsyncPipe,
        NgIf,
        NgForOf,
        NgClass,
        DirectivesModule,
        NgxTurnstileModule,
        CommonModule,
        AppFilterPipeModule
    ],
    providers: [FormControlService],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() spanLayout: 1;
    @Input() fnBtnName = "Save";
    @Input() closeBtnName = "Close";
    @Input() isCloseBtn = true;
    @Input() isSaveBtn = true;
    @Input() listOfFormElements: any = [];
    @Output() saveCallBack = new EventEmitter();
    @Output() cancelCallBack = new EventEmitter();
    // @Input() selectedValue: any;
    @Output() valueUpdate = new EventEmitter();
    form!: FormGroup;
    @Input() Valuesoutput: any = {};
    @Output() outputValue: any = {};
    payLoad = "";
    @ViewChild("companySelect", { static: true }) companySelect!: MatSelect;
    public companyCtrl: FormControl = new FormControl();
    public companyFilterCtrl: FormControl = new FormControl();
    public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
    public searching = false;
    protected onDestroySearch = new Subject<void>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    agentList: any = [];
    company: any;
    companies: any;
    companyHash: any = {};
    allComp: any = [];
    captchaControl: any;

    constructor(
        private formService: FormControlService,
        public toastr: ToastrService,
        public _changeDetectorRef: ChangeDetectorRef,
        private loaderService: LoaderService,
        private baseService: BaseRequestService,
        private toast: MyToastrService,
        private _cs: CommonService
    ) {
    }

    sendCaptchaResponse(captchaResponse: string): void {
        this.captchaControl = captchaResponse;
    }

    ngOnInit(): void {
        if (this._cs.currentScope === '*') {
            this.listOfFormElements = this.listOfFormElements.filter(element => !element.ishideGobal);
            this.form = this.formService.toFormGroup(this.listOfFormElements);
        }else{
            this.form = this.formService.toFormGroup(this.listOfFormElements);
        }
        /*this.companyFilterCtrl.valueChanges
            .pipe(debounceTime(300), takeUntil(this.onDestroySearch))
            .subscribe(() => {
                this.filterCompanies();
            });*/
        const cmppicker = this.listOfFormElements.filter(
            (x: any) => x.type === "companypicker"
        );
        const agtpicker = this.listOfFormElements.filter(
            (x: any) => x.type === "agentpicker"
        );
        if (cmppicker.length) {
            this.allComp = cloneDeep(this._cs.allComp);
            this.allComp.map((x: any) => {
                x.id = parseInt(x.id);
            });
            this.Valuesoutput.company_id = "";
        }
        if (agtpicker && this.Valuesoutput.company_id) {
            this.updateCurrentCompany(this.Valuesoutput.company_id, "company_id");
        }
        let selectpicker: any = this.listOfFormElements.filter((x: any) => x.type === 'select_with_options');
        if (selectpicker && selectpicker.length && !this.Valuesoutput[selectpicker[0].key]) {
            let defaultData = selectpicker[0]?.options.filter((x: any) => x.default);
            this.Valuesoutput[selectpicker[0].key] = defaultData[0].value;
            this.form.controls[selectpicker[0].key].setValue(defaultData[0].value);
        }

    }

    getAgents(id: any): void {
        this.loaderService.display(true, "Getting agent data...");
        const condition = {
            condition: `agent_type='PROBE' and is_deprecated=FALSE and company_id=${id}`,
            skip: 0,
            limit: 1000,
        };
        this.baseService
            .doRequest("/r/company/agents", "get", null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    const agentList: { key: string; value: any }[] = [];
                    result.data.map((x: any) => {
                        x.name = (x.name) ? x.name : x.host_name;
                    });
                    result.data.forEach((obj: any) => {
                        agentList.push({
                            key: obj.name,
                            value: obj.id,
                        });
                    });
                    this.agentList = agentList;
                } else {
                    this.toast.sToast("error", result.data);
                }
            });
    }

    Save(form: any): void {
        const tokenControl = this.listOfFormElements.filter(
            (x: any) => x.type === "s_captcha"
        );

        const frmValues = this.Valuesoutput;
        var object: any = {}
        if (tokenControl.length) {
            object.tokenControl = this.captchaControl;
        }
        for (const key in frmValues) {
            if (frmValues.hasOwnProperty(key) && key !== '[object Object]' && key !== 'undefined') {
                // Split the key by '.' to navigate through nested properties
                const keys = key.split(".");
                let obj = object;
                // Iterate through the keys to create nested objects
                for (let i = 0; i < keys.length; i++) {
                    const currentKey = keys[i];
                    if (!obj[currentKey]) {
                        // Create a nested object if it doesn't exist
                        obj[currentKey] = {};
                    }

                    if (i === keys.length - 1 && currentKey != 'rulenow') {
                        // Assign the value to the final nested object property
                        obj[currentKey] = frmValues[key];
                    } else {
                        // Move to the next nested object
                        obj = obj[currentKey];
                    }
                }
            }
        }
        if (frmValues.netaName && !frmValues.netaName.replace(/\s/g, "").length) {
            this.toastr.error("Input cannot contain only whitespaces");
        } else {
            this.saveCallBack.emit(object);
        }
    }

    Cancel(): void {
        this.cancelCallBack.emit();
    }

    fieldValChange($event: any): void {
        this.valueUpdate.emit($event);
    }

    onSubmit(): void {
        // this.form.value["SelectedDropdown"] = this.selectedValue.value;
        // delete  this.form.value.undefined;
        // @ts-ignore
        this.payLoad = JSON.stringify(this.form.value);
    }

    ngOnDestroy(): void {
        this.onDestroySearch.next();
        this.onDestroySearch.complete();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private filterCompanies(): void {
        if (!this.companies) {
            return;
        }
        // get the search keyword
        let search = this.companyFilterCtrl.value;
        if (!search) {
            this.getCompanies();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.getCompanies(search);
    }

    closeCurrentCompany(event: any, key: any): void {
        if (!event && !this.Valuesoutput[key]) {
            this.getCompanies();
        }
    }

    getCompanies(search?: string): void {
        if (
            !this.baseService.user() ||
            !this.baseService.user().email
        ) {
            setTimeout(() => {
                this.getCompanies(search);
            }, 1000);
            return;
        }
        this.searching = true;
        const condition: any = search
            ? { condition: `name ilike '%${search}%' and is_assessment=${this._cs.is_assessment}` }
            : {
                condition: `is_assessment=${this._cs.is_assessment}`,
                skip: 0,
                limit: 50,
                order_by: 'created desc'
            };
        this.baseService
            .doRequest('/r/company/companies', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.data.length) {
                    for (const c of result.data) {
                        if (c._id) {
                            this.companyHash[c._id] = c;
                        }
                    }
                    result.data.sort((a: any, b: any) => {
                        const c = a.name ? a.name.toLowerCase() : "";
                        const d = b.name ? b.name.toLowerCase() : "";
                        if (c < d) {
                            return -1;
                        } else if (c > d) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    this.companies = result.data;
                    if (!search) {
                        this.allComp = result.data;
                    } else {
                        this.allComp = Array.from(new Map([...this.allComp, ...result.data].map(obj => [obj.id, obj])).values());
                    }
                    this.filteredCompanies.next(result.data.slice());
                    this._changeDetectorRef.detectChanges();
                    if (this.Valuesoutput.company_id) {
                        this.updateCurrentCompany(
                            this.Valuesoutput.company_id,
                            "company_id"
                        );
                    }
                    this.searching = false;
                } else {
                    this.filteredCompanies.next([]);
                    this.searching = false;
                    this._changeDetectorRef.detectChanges();
                }
            }, (error: any) => {
                this.searching = false;
            }
            );
    }


    updateCurrentCompany(event: any, key: any): void {
        this.Valuesoutput[key] = event;
        if (this.form.controls && this.form.controls[key]) {
            this.form.controls[key].setValue(event);
        }
        const keys = Object.keys(this.Valuesoutput);
        if (keys.indexOf('agent_id') > -1) {
            this.getAgents(event);
        }
        // this._changeDetectorRef.detectChanges();
    }

    ngOnChanges(): void {
        let selectpicker: any = this.listOfFormElements.filter((x: any) => x.type === 'select_with_options');
        if (selectpicker && selectpicker.length && !this.Valuesoutput[selectpicker[0].key]) {
            let defaultData = selectpicker[0]?.options.filter((x: any) => x.default);
            this.Valuesoutput[selectpicker[0].key] = defaultData[0].value;
            this.form.controls[selectpicker[0].key].setValue(defaultData[0].value);
        }
        this.form = this.formService.toFormGroup(this.listOfFormElements);
        if (this.Valuesoutput.company_id) {
            this.updateCurrentCompany(this.Valuesoutput.company_id, "company_id");
        }
    }
}
