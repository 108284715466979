import { NgIf, NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'cron-jobs',
  standalone : true,
  imports:[NgIf,NgFor,TableComponent],
  templateUrl: './cron-jobs.component.html',
  styleUrls: ['./cron-jobs.component.scss']
})
export class CronJobsComponent {
  @Input() asset: any;
  cronJobsTableOptions: any = {
    columns: [
        {
            "header": "Command",
            "columnDef": "command",
            "cType": "string",
            "isHyperlink": false,
            "filter": "",
            "cell": "(element: any) => `${element.command}`",
            "order": 0,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
        },
        {
            "header": "Cron File",
            "columnDef": "cron_file",
            "cType": "string",
            "isHyperlink": false,
            "filter": "cleanString",
            "cell": "(element: any) => `${element.cron_file}`",
            "order": 0,
            "visible": true,
            "isToolTip": false,
            "isToolTipCol": "",
            "hasMultiData": false,
            "class": "",
            "color": "",
            "isProgressCntrl": false,
            "isColoredCntrl": false,
            "colList": [],
            "isfaicon": false,
            "isAddingText": false,
            "addingText": "",
            "img": false,
            "imgPath": "",
            "isSort": true,
            "iscolumnSearch": false
        },
    ],
    sortOptions: { active: 'command', direction: 'desc' },
    _pageData: [],
    tableOptions: {
        title: 'Cron Jobs',
        isServerSide: false,
        selectText: 'cron',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
            url: '/report_queries/cron_jobs',
            condition: '',
        },
        id: 'cron_jobs'
    },
    changeValue: new Subject<any>(),
};
ngOnInit(): void {
  this.cronJobsTableOptions.tableOptions.serverSide.condition = ` asset_id='${this.asset.id}'`;
}
}
