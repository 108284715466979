import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { TableComponent } from "../table/table.component";

@Component({
  selector: "app-ip-table-rules",
  standalone: true,
  imports: [NgIf, NgFor, TableComponent],
  templateUrl: "./ip-table-rules.component.html",
  styleUrls: ["./ip-table-rules.component.scss"],
})
export class IpTableRulesComponent {
  @Input() asset: any;
  ipTableTableOptions: any = {
    columns: [
      {
        header: "Filter Name",
        columnDef: "filter_name",
        cType: "string",
        isHyperlink: false,
        filter: "",
        cell: "(element: any) => ${element.filter_name}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Chain",
        columnDef: "chain",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.chain}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Interface Name",
        columnDef: "interface_name",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.interface_name}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Policy",
        columnDef: "policy",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.policy}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Source Address",
        columnDef: "source_address",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.source_address}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Destination Addresses",
        columnDef: "destination_addresses",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.destination_addresses}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Source Port",
        columnDef: "source_port",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.source_port}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: "Destination Port",
        columnDef: "destination_port",
        cType: "string",
        isHyperlink: false,
        filter: "cleanString",
        cell: "(element: any) => ${element.destination_port}",
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: "",
        hasMultiData: false,
        class: "",
        color: "",
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: "",
        img: false,
        imgPath: "",
        isSort: true,
        iscolumnSearch: false,
      },
    ],
    sortOptions: { active: "interface_name", direction: "desc" },
    _pageData: [],
    tableOptions: {
      title: "IP Table",
      isServerSide: false,
      selectText: "firewall",
      loading: true,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showFilter: true,
      showTagFilter: false,
      showhideList: true,
      refreshData: true,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/asset_iptables_rules",
        condition: "",
      },
      id: "ip_table",
    },
    changeValue: new Subject<any>(),
  };
  ngOnInit(): void {
    this.ipTableTableOptions.tableOptions.serverSide.condition = ` asset_id='${this.asset.id}'`;
  }
}
