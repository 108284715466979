import { CommonModule, DOCUMENT, NgClass, NgFor, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FSDocument, FSDocumentElement } from '@fuse/components/fullscreen/fullscreen.types';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { MaterialModule } from 'app/material.module';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { FuseAlertComponent } from '../alert';
import { ModalService } from 'app/_services/modal.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MyToastrService } from 'app/_services/toastr.service';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'app/_services/loader.service';
import { FormsModule } from '@angular/forms';
import { CommonService } from 'app/_services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fuse-fullscreen',
  templateUrl: './fullscreen.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'fuseFullscreen',
  standalone: true,
  imports: [MatButtonModule, MatTooltipModule, NgTemplateOutlet, MatIconModule, CommonModule,
    MatButtonModule,
    MatMenuModule,
    NgIf,
    NgFor,
    MatIconModule,
    NgClass,
    MatDividerModule,
    MaterialModule,
    ModalComponent,
    FuseScrollbarDirective,
    FuseAlertComponent,
    NgOptimizedImage, FormsModule],
})
export class FuseFullscreenComponent implements OnInit {
  @Input() iconTpl: TemplateRef<any>;
  @Input() tooltip: string;
  private _fsDoc: FSDocument;
  private _fsDocEl: FSDocumentElement;
  private _isFullscreen: boolean = false;
  uploadfile: any = "";
  assessmentName: any = '';
  assessmentFile: any = {};
  disbaleSave = true;
  addCompanyWidth = 800;
  isLoading = false;
  showIcon = false;
  /**
   * Constructor
   */
  constructor(@Inject(DOCUMENT) private _document: Document, public modalService: ModalService, private toast: MyToastrService, private httpClient: HttpClient,
    private loaderService: LoaderService, public cs: CommonService, private _router: Router) {
    this._fsDoc = _document as FSDocument;
    const router = this._router.url.split('/');
    this.showIcon = router.indexOf('onboarding') === -1;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._fsDocEl = document.documentElement as FSDocumentElement;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle the fullscreen mode
   */
  toggleFullscreen(): void {
    // Check if the fullscreen is open
    this._isFullscreen = this._getBrowserFullscreenElement() !== null;

    // Toggle the fullscreen
    if (this._isFullscreen) {
      this._closeFullscreen();
    }
    else {
      this._openFullscreen();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get browser's fullscreen element
   *
   * @private
   */
  private _getBrowserFullscreenElement(): Element {
    if (typeof this._fsDoc.fullscreenElement !== 'undefined') {
      return this._fsDoc.fullscreenElement;
    }

    if (typeof this._fsDoc.mozFullScreenElement !== 'undefined') {
      return this._fsDoc.mozFullScreenElement;
    }

    if (typeof this._fsDoc.msFullscreenElement !== 'undefined') {
      return this._fsDoc.msFullscreenElement;
    }

    if (typeof this._fsDoc.webkitFullscreenElement !== 'undefined') {
      return this._fsDoc.webkitFullscreenElement;
    }

    throw new Error('Fullscreen mode is not supported by this browser');
  }

  /**
   * Open the fullscreen
   *
   * @private
   */
  private _openFullscreen(): void {
    if (this._fsDocEl.requestFullscreen) {
      this._fsDocEl.requestFullscreen();
      return;
    }

    // Firefox
    if (this._fsDocEl.mozRequestFullScreen) {
      this._fsDocEl.mozRequestFullScreen();
      return;
    }

    // Chrome, Safari and Opera
    if (this._fsDocEl.webkitRequestFullscreen) {
      this._fsDocEl.webkitRequestFullscreen();
      return;
    }

    // IE/Edge
    if (this._fsDocEl.msRequestFullscreen) {
      this._fsDocEl.msRequestFullscreen();
      return;
    }
  }

  /**
   * Close the fullscreen
   *
   * @private
   */
  private _closeFullscreen(): void {
    if (this._fsDoc.exitFullscreen) {
      this._fsDoc.exitFullscreen();
      return;
    }

    // Firefox
    if (this._fsDoc.mozCancelFullScreen) {
      this._fsDoc.mozCancelFullScreen();
      return;
    }

    // Chrome, Safari and Opera
    if (this._fsDoc.webkitExitFullscreen) {
      this._fsDoc.webkitExitFullscreen();
      return;
    }

    // IE/Edge
    else if (this._fsDoc.msExitFullscreen) {
      this._fsDoc.msExitFullscreen();
      return;
    }
  }

  downloadAssessmentPopup(): void {
    // this.isLoading = false;
    // this.loaderService.display(true);
    // this.baseService.doRequest(`/api/company/dummy/downloadAssessmentAgent`, 'post', {}).subscribe((r: any) => {
    //   this.loaderService.display(false);
    //   r.forEach((obj: any) => {
    //     this.stepsList[obj.name] = obj;
    //   });
    //   this.agentList = r;
    this.modalService.open('downloadAssessmentAgent');
    // });
  }

  downloadLink(link: any): void {
    // window.open(link, '_blank'); this.modalService.close('downloadAssessmentAgent');
  }

  selectedTab($event: MatTabChangeEvent): void {
    let type = ($event.tab.textLabel.toLocaleLowerCase() === 'mac') ? 'darwin' : $event.tab.textLabel.toLocaleLowerCase();
    // this.updateCommand(type);
  }
  uploadAssessment(): void {
    this.modalService.open('uploadAssessment');
  }

  uploadDocs() {

  }

  closemenu() {
    this.modalService.close('downloadAssessmentAgent');
  }

  closeAssessment() {
    this.modalService.close('uploadAssessment');
  }

  uploadFile(event: any, key: string): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 256 * 1024 * 1024; // 256MB
      if (fileSizeInBytes < maxSizeInBytes) {
        this.assessmentFile[key] = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (ev) => {
          // @ts-ignore
          this[key] = reader.result;
        };
        this.disbaleSave = false;
      } else {
        this.disbaleSave = true;
        this.toast.sToast('error', "File size exceeds the limit of 256MB");
      }
    }
  }

  saveAssessmentFile(companyName: string): void {
    if (this.isLoading) { return; }
    this.isLoading = true;
    this.loaderService.display(true, 'Uploading assessment file...');
    const formData = new FormData();
    // this.ObjectKeys(this.assessmentFile).forEach((obj: any) => {
    //   formData.append(obj, this.assessmentFile[obj]);
    // });
    // formData.append('assessmentname', companyName);
    this.httpClient.post<any>('/api/agent/upload_assessment',
      formData).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          this.isLoading = false;
          this.toast.sToast('success', result[1]);
          this.closeAssessment();
        } else {
          this.isLoading = false;
          this.toast.sToast('error', result[1]);
        }
      }, (error: any) => {
        this.isLoading = false;
        this.loaderService.display(false);
        this.toast.sToast('error', error.error.message);
      });
  }

  changeAssessment(): void {
    localStorage.setItem('assessent', 'true');
    this.cs.newCompanyCall.emit({});
  }
}
