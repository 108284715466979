<div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div *ngIf="sView === 'create-ticket'" class="flex flex-col flex-auto items-center">
        <div class="flex flex-col w-full">
            <div class="-ml-4">
                <button mat-button (click)="closeTicket()" [color]="'primary'">
                    <mat-icon [svgIcon]="'heroicons_outline:arrow-long-left'"></mat-icon>
                    <span class="ml-2">Back to Support Center</span>
                </button>
            </div>
            <div class="mt-2 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
                Contact support
            </div>
            <!-- Form -->
            <div class="mt-8 sm:mt-12 p-6 pb-7 sm:p-10 sm:pb-7 shadow rounded-2xl bg-card">
                <!-- Alert -->
                <fuse-alert
                    class="mb-8"
                    *ngIf="alert"
                    [type]="alert.type"
                    [showIcon]="false">
                    {{alert.message}}
                </fuse-alert>
                <form
                    class="space-y-3"
                    [formGroup]="supportForm"
                    #supportNgForm="ngForm">
                    <div class="mb-6">
                        <div class="text-2xl font-bold tracking-tight">Submit your request</div>
                        <div class="text-secondary">Your request will be processed and our support staff will get back
                            to you in 24 hours.
                        </div>
                    </div>
                    <!-- Name -->
                    <mat-form-field class="w-full">
                        <input
                            matInput
                            [formControlName]="'name'"
                            [required]="true">
                        <mat-label>Name</mat-label>
                        <mat-error *ngIf="supportForm.get('name').hasError('required')">
                            Required
                        </mat-error>
                    </mat-form-field>
                    <!-- Email -->
                    <mat-form-field class="w-full">
                        <input
                            type="email"
                            matInput
                            [formControlName]="'email'"
                            [required]="true">
                        <mat-label>Email</mat-label>
                        <mat-error *ngIf="supportForm.get('email').hasError('required')">
                            Required
                        </mat-error>
                        <mat-error *ngIf="supportForm.get('email').hasError('email')">
                            Enter a valid email address
                        </mat-error>
                    </mat-form-field>
                    <!-- Subject -->
                    <mat-form-field class="w-full">
                        <input
                            matInput
                            [formControlName]="'subject'"
                            [required]="true">
                        <mat-label>Subject</mat-label>
                        <mat-error *ngIf="supportForm.get('subject').hasError('required')">
                            Required
                        </mat-error>
                    </mat-form-field>
                    <!-- Message -->
                    <mat-form-field class="w-full">
                        <textarea
                            matInput
                            [formControlName]="'message'"
                            [required]="true"
                            [rows]="5"
                            cdkTextareaAutosize></textarea>
                        <mat-label>Message</mat-label>
                        <mat-error *ngIf="supportForm.get('message').hasError('required')">
                            Required
                        </mat-error>
                    </mat-form-field>
                    <div class="mt-4">
                        <img [src]="cs.currentScreen" alt="attachment">
                    </div>
                    <!-- Actions -->
                    <div class="flex items-center justify-end">
                        <button
                            mat-button
                            [color]="'accent'"
                            [disabled]="supportForm.pristine || supportForm.untouched"
                            (click)="clearForm()">
                            Clear
                        </button>
                        <button
                            class="ml-2"
                            mat-flat-button
                            [color]="'primary'" appPreventMultiClick
                            [disabled]="supportForm.pristine || supportForm.invalid"
                            (click)="sendForm()">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="sView === 'support-center'" class="flex flex-col flex-auto items-center">
        <div class="flex flex-col w-full">
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3 md:gap-8">
                <div class="relative overflow-hidden rounded-xl shadow border-t-4 border-blue-600 bg-card">
                    <div class="py-10 px-6">
                        <div class="flex items-center">
                            <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                                <span class="absolute -top-4 h-2 w-full bg-blue-400"></span>
                                {{summary.total}}
                            </h3>
                            <span class="ml-3 text-xl font-bold capitalize">All</span>
                        </div>
                    </div>
                </div>
                <div class="relative overflow-hidden rounded-xl shadow border-t-4 border-red-600 bg-card">
                    <div class="py-10 px-6">
                        <div class="flex items-center">
                            <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                                <span class="absolute -top-4 h-2 w-full bg-red-400"></span>
                                {{summary.open}}
                            </h3>
                            <span class="ml-3 text-xl font-bold capitalize">Pending</span>
                        </div>
                    </div>
                </div>
                <div class="relative overflow-hidden rounded-xl shadow border-t-4 border-green-600 bg-card">
                    <div class="py-10 px-6">
                        <div class="flex items-center">
                            <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                                <span class="absolute -top-4 h-2 w-full bg-green-400"></span>
                                {{summary.closed}}
                            </h3>
                            <span class="ml-3 text-xl font-bold capitalize">Solved/Closed</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 text-2xl sm:text-2xl font-extrabold tracking-tight leading-tight">
                Support Center
            </div>
            <stable class="-mx-4" [sTableOptions]="tickTableOptions"></stable>
        </div>
    </div>
</div>
