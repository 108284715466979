<router-outlet>
    <div *ngIf="(loading$ | async) && change !== 'yes'" class="loader-class custom-class">
        <img src="/assets/images/loading.gif" alt="" class=" w-6 mb-2">
        <p class="relative" *ngIf="!contentText || contentText === ''">Loading... </p>
        <p class="relative break-words px-4">{{contentText}}</p>
    </div>

    <!-- <div *ngIf="change === 'yes'" class="bg-[#0f172a] w-full h-full">
        <div  class="loader-classs custom-class">
            <img class="w-[12rem]" src="/assets/images/logo/cs_logo_dark.png" aria-label="logo" alt="logo">
            <img src="/assets/images/loading.gif" alt="" class=" w-6 mb-2">
        </div>
    </div>     -->

    <fuse-splash-screen *ngIf="change === 'yes'">
        <img class="w-[12rem]" src="/assets/images/logo/cs_logo_dark.png" aria-label="logo" alt="logo">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <!-- <div class="mt-4 text-center text-3xl font-extrabold leading-tight tracking-tight"> Just a moment, we're getting everything set up for you. </div> -->
    </fuse-splash-screen>
    <!--<div *ngIf="authService.sessionTimeout$ | async " class="loader-class custom-class p-3">
        <span class="mb-3 mt-3">Session about to expire in {{authService.timeoutSec}}s. Do you want to continue current session?</span>
        <div class="flex flex-row items-center">
            <span class="mr-2">
                <button (click)="authService.resetSession()" mat-raised-button
                                       color="primary">Yes</button>
            </span>
            <span>
                <button (click)="authService.logout()" mat-stroked-button
                          color="warn">Logout</button>
            </span>
        </div>
        <img ngSrc="/assets/images/loadings.gif" alt="" class="absolute w-50 px-[10%] inset-x-[14%] inset-y-[14%] h-auto" fill>
    </div>-->
</router-outlet>