export interface User
{
    id: string;
    name: string;
    email: string;
    avatar?: string;
    status?: string;
}

export const ACCESS_ROLES = ["admin", "itadmin", "approver", "assetviewer", "assetwriter", "vulnerabilityviewer","vulnerabilitywriter","complianceviewer","compliancewriter","activedirectoryviewer","activedirectorywriter"];

export const permissions = {
    asset_read_only:  [
        "companies", "agents", "compliance_checks", "software", "asset_ports", "asset_storages", "services", "assetsdata",
        "cve_details", "asset_problems", "asset_solutions", "asset_network_adapters",
        "asset_shares", "asset_firewall_policy", "asset_running_process", "registry_mis_configuration",
        "interfaces", "log4j_info", "unquoted_service", "ntfs_permissions", "user_permissions", "msdt",
        "asset_users", "platform_info", "cpu_info", "browser_extensions", "asset_video_info", "asset_bios_info",
        "windows_updates", "getcsvreport", "asset_services", "assets_overview",
        "asset_services", "asset_software", "assetview", "asset_companies", "assetsview",
        "asset_interface", "asset_registry_misconfiguration", "asset_msdt", "ports_view",
        "asset_windows_reboot_required", "asset_windows_security_products", "asset_firewall_rules",
        "platforms", "platforms_companies", "operating_systems", "operating_systems_companies",
        "asset_breakdown_by_os", "asset_breakdown_by_os_companies", "windows_patches", "windows_patches_companies",
        "macos_patches", "macos_patches_companies", "ports_view_companies", "ports_assets", "ports_assets_companies",
        "remediation_plan_for_asset", "remediation_plan", "remediation_plan_assets_for_company", "remediation_plan_assets",
        "remediation_by_company", "remediation_plan_companies", "problems_count_by_category_for_asset",
        "problems_by_category_for_asset", "problems_count_by_category", "problems_count_by_category_for_company",
        "companies_by_problem_group", "companies_by_problem", "problems_asset_count_by_category",
        "assets_by_problem_group", "assets_by_problem_group_for_company",
        "assets_by_problem", "assets_by_problem_for_company", "assetoverview",
        "application_vulnerabilities_view", "application_vulnerabilities_view_companies", "application_vulnerabilities_view_by_assets"
    ],
    ad_read_only: [
        "companies", "ad_computers", "ad_users", "ad_ous", "ad_groups", "ad_gpos", "ad_password_policies", "ad_stats", "ad_domains",
        "azure_secure_score", "users_groups_map_views", "users_groups_map_views", "computers_groups_map_views",
        "ous_gpos_map_views", "ad_problems_count_by_category", "ad_problems_count_by_company", "ad_problems_by_category",
        "ad_count", "ad_details", "get_ous_details", "get_groups_details", "get_user_details", "get_computer_details",
        "getAllActiveSources", "azure_licenses", "ad_user_licenses", "azure_ad_logs", "ad_roles"
    ],
    vul_read_only: [
        "companies", "vulnerability", "problem_groups", "problems", "remediation_plan", "suppress_vulnerability", "getsuppressiondata",
        "application_vulnerabilities_view", "application_vulnerabilities_view_companies",
        "problems_count_by_category", "problems_asset_count_by_category"
    ],
    compliance_read_only: [
        "companies", "compliance_master", "cis_compliance_status", "pcidss_compliance_status", "hipaa_compliance_status",
        "cyber_essentials_compliance_status", "nist_50083_compliance_status", "compliance",
        "asset_compliance_details", "compliance_check_asset_count", "compliance_check_company_count", "compliance_maturity",
        "compliance_check_count", "compliance_check_count_by_section", "types"
    ],
    pii_read_only: ["pii_scan"]
}
