import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { registerLicense } from '@syncfusion/ej2-base';
import { appConfig } from 'app/app.config';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5ec3VRRmheV0R0Wko=');
// bootstrapApplication(AppComponent, appConfig).catch((err) => {
//     console.error(err);
// });


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));