<div class="p-2">
    <div class="mt-4 flex flex-col items-start gap-6">
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-[33vw] h-[60vh]">
            <div class="flex flex-col">
                <div class="mb-4 text-sm flex flex-row font-medium tracking-tight leading-6 truncate">
                    <div class="mt-2">Set global performance count:</div>
                    <mat-form-field class=" ml-6">
                        <input matInput matTooltip="Agent performance management count" type="number"
                            [(ngModel)]="globalPerCount" autocomplete="off" placeholder="count" name="gloPerCount">
                    </mat-form-field>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick [matTooltip]="'Save'"
                        (click)="updateGlobalPerformanceCount(globalPerCount)">
                        Update
                    </button>
                </div>
                <div class="mb-4 text-sm flex flex-row font-medium tracking-tight leading-6 truncate">
                    <div class="mt-2">Set company performance count:</div>
                    <mat-form-field class=" ml-6">
                        <input matInput matTooltip="Agent performance management count" type="number"
                            [(ngModel)]="compPerCount" autocomplete="off" placeholder="count" name="compPerCount">
                    </mat-form-field>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick [matTooltip]="'Save'"
                        (click)="updateComapanyPerformanceCount(compPerCount)">
                        Update
                    </button>
                </div>
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead class="text-medium font-medium border-b tracking-tight leading-6  bg-card">
                        <tr>
                            <th>
                                <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                    id="_selectDeselectAllCompanies" [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Company Name
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Performance Count
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody fuseScrollbar>
                        <tr *ngFor="let comp of allCompanies.data">
                            <td>
                                <mat-checkbox color="primary" (click)="$event.stopPropagation()" [id]="comp._id"
                                    (change)="$event ? rowToggle(comp) : null"
                                    [checked]="selection.isSelected(comp)"></mat-checkbox>
                            </td>
                            <td scope="row" class="py-1 pl-4">
                                {{comp.name}}
                            </td>
                            <td>
                                new
                            </td>
                            <td class="py-1 pl-4">
                                <mat-form-field>
                                    <input matInput matTooltip="Agent performance management count" type="number"
                                        autocomplete="off" placeholder="count" name="cmpPerCount">
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
