<mat-form-field class="w-full sm:w-1/2 mt-4 ml-4" [subscriptSizing]="'dynamic'">
    <mat-label>Choose Credential</mat-label>
    <mat-select [(ngModel)]="selectedCred" (selectionChange)="filterByCred($event)">
        <ng-container *ngFor="let cred of integrationCred; trackBy: trackByFn">
            <mat-option [value]="cred.id">{{cred.name}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
<ng-container *ngIf="cView === 'table'">
    <ng-container *ngIf="currentIntegration.name === 'AzureADCSP'">
        <fuse-alert [appearance]="'border'" [type]="'info'" *ngIf="reAuthenticate" class="mx-4 mt-4">
            <div>Azure token expired, click <a href="javascript:" class="cursor-pointer text-blue-600"
                    (click)="oAuthCredValidationCSPReauth()">here</a>
                for Microsoft Entra ID<b> Re-authentication</b></div>
        </fuse-alert>
    </ng-container>
    <div class="" fuseScrollbar>
        <stable [sTableOptions]="mappedComTableOptions" (addCallback)="addTableData()"
            (actionCallback)="actionCall($event)" *ngIf="mappedComTableOptions.tableOptions && selectedCred"></stable>
    </div>
</ng-container>

<ng-container *ngIf="cView === 'add'">
    <div class="flex-auto p-4 sm:p-4">
        <div class="max-w-full">
            <div class="prose prose-sm max-w-full">
                <div class="flex flex-row items-center">
                    <h2 class="">New Company Mapping</h2>
                    <button class="ml-auto" mat-icon-button (click)="newcompanyclosing()">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="aView === 'type'">
                <form class="flex flex-col mt-4 px-8 pt-10 bg-card shadow rounded overflow-hidden">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12">
                            <p class="text-lg font-medium">Would you like to</p>
                        </div>
                        <div class="flex-auto min-w-64 -ml-2">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" [(ngModel)]="selectedType"
                                name="type">
                                <mat-radio-button [value]="'import'">
                                    Import Companies from {{currentIntegration.display}}
                                </mat-radio-button>
                                <mat-radio-button class="ml-2" [value]="'exist'">
                                    Map Existing Company to a {{currentIntegration.display}} Company
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5">
                        <button mat-button (click)="cView = 'table'">
                            Cancel
                        </button>
                        <button class="px-6 ml-3" mat-flat-button [color]="'primary'" [disabled]="!selectedType"
                            (click)="aView = selectedType; getCompany()">
                            Next
                        </button>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="aView === 'import'">
                <fuse-alert [appearance]="'border'" [type]="'info'">
                    <div>1. Currently showing 25 list of companies. If you can't find your company in this list. Please
                        use the search option to filter.</div>
                    <div>2. Click on Add and then Finish to map all the selected companies.</div>
                </fuse-alert>
                <form class="flex flex-col p-8 bg-card shadow rounded overflow-hidden">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12">
                            <p class="text-lg font-medium">Import Companies from {{currentIntegration.display}}</p>
                        </div>
                        <div class="w-full mt-2">
                            <div class="flex items-start w-full mt-4">
                                <div class="flex flex-col w-9/12 pr-2">
                                    <mat-form-field [subscriptSizing]="'dynamic'">
                                        <mat-label>Choose Company</mat-label>
                                        <mat-select [multiple]="true" name="importCmp"
                                            (ngModelChange)="updateSelected($event)" [(ngModel)]="selectedImportcompany"
                                            id="sourceCmp">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="searchSourceImpControl"
                                                    [placeholderLabel]="'Search Company'"
                                                    [noEntriesFoundLabel]="'No matching company found'"
                                                    [searching]="searching"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option [matTooltip]="company.name"
                                                *ngFor="let company of destCompany | search: searchSourceImpControl.value"
                                                [value]="company">
                                                {{company.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container
                                        *ngIf="comMapImpForm.company_id && comMapImpForm.company_id.length && !currentIntegration.import_sites">
                                        <div class="flex flex-wrap items-center -m-1.5 mt-2">
                                            <ng-container
                                                *ngFor="let cmp of comMapImpForm.company_id; let i = index; trackBy: trackByFn">
                                                <div
                                                    class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-black bg-gray-300 dark:text-gray-300 dark:bg-gray-700">
                                                    <span
                                                        class="text-md font-medium whitespace-nowrap">{{cmp.name}}</span>
                                                    <mat-icon class="icon-size-4 ml-2 cursor-pointer"
                                                        (click)="removeSelected(i)"
                                                        [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                                </div>
                                            </ng-container>
                                            <div
                                                class="flex items-center justify-end py-4 pr-4 mt-6 pl-1 border-t w-full">
                                                <!-- Save -->
                                                <button class="" mat-flat-button [color]="'primary'"
                                                    appPreventMultiClick
                                                    [disabled]="!comMapImpForm.company_id || !comMapImpForm.company_id.length"
                                                    [matTooltip]="'Save'" (click)="importSelectedCompanies()">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="comMapImpForm.company_id && comMapImpForm.company_id.length && currentIntegration.import_sites">
                                        <mat-divider class="my-4"></mat-divider>
                                        <div class="text-lg font-medium my-2"> Selected Companies</div>
                                        <div class="relative overflow-x-auto mt-2 sm:rounded-lg" fuseScrollbar>
                                            <table
                                                class="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <thead
                                                    class="font-medium tracking-tight text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" class="px-2 py-4">
                                                            Company Name
                                                        </th>
                                                        <th scope="col" class="px-2 py-4"
                                                            *ngIf="currentIntegration.import_sites">
                                                            Map Archive
                                                        </th>
                                                        <th scope="col" class="px-2 py-4"
                                                            *ngIf="currentIntegration.import_agents">
                                                            Choose Agent
                                                        </th>
                                                        <th scope="col" class="px-2 py-4">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let cmp of comMapImpForm.company_id; let i=index;"
                                                        class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <td class="px-2 py-2">
                                                            {{cmp.name}}
                                                        </td>
                                                        <td class="px-2 py-2" *ngIf="currentIntegration.import_sites">
                                                            <mat-form-field class="w-full"
                                                                [subscriptSizing]="'dynamic'">
                                                                <mat-select name="integrationSites{{i}}"
                                                                    [(ngModel)]="cmp.site_id" [multiple]="false">
                                                                    <ng-container
                                                                        *ngFor="let data of cmp.integrationSites">
                                                                        <mat-option [value]="data.id"> {{data.name}}
                                                                        </mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </td>
                                                        <td class="px-2 py-2" *ngIf="currentIntegration.import_agents">
                                                            <mat-form-field class="w-full" required
                                                                [subscriptSizing]="'dynamic'">
                                                                <mat-select name="integrationAgent{{i}}"
                                                                    [(ngModel)]="cmp.site_id" [multiple]="false">
                                                                    <ng-container
                                                                        *ngFor="let data of cmp.integrationSites">
                                                                        <mat-option [value]="data.id"> {{data.name}}
                                                                        </mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </td>
                                                        <td class="px-2 py-2">
                                                            <button (click)="removeSelected(i)" mat-icon-button=""
                                                                matTooltip="Delete record">
                                                                <mat-icon
                                                                    class="icon-size-5 text-primary">delete</mat-icon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="flex items-center justify-end mt-8 px-8 py-5">
                                                <button class="" mat-flat-button [color]="'primary'"
                                                    appPreventMultiClick
                                                    [disabled]="!comMapImpForm.company_id || !comMapImpForm.company_id.length"
                                                    [matTooltip]="'Save'" (click)="importSelectedCompanies()">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="w-1/4 border-l pl-2" *ngIf="!currentIntegration.hide_search">
                                    <span class="w-full">
                                        <app-dynamic-form [isCloseBtn]="false" (saveCallBack)="getCompany($event)"
                                            [fnBtnName]="'Search Companies'" [isCloseBtn]="false"
                                            *ngIf="companyFormElements && companyFormElements.length"
                                            [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                                        </app-dynamic-form>
                                    </span>
                                </div>
                                <div class="w-1/4 border-l pl-2" *ngIf="currentIntegration.name==='DattoRMM'">
                                    <span class="w-full">
                                        <app-dynamic-form [isCloseBtn]="false" (saveCallBack)="getCompany($event)"
                                            [fnBtnName]="'Search Companies'" [isCloseBtn]="false"
                                            *ngIf="companyFormElements && companyFormElements.length"
                                            [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                                        </app-dynamic-form>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </ng-container>
            <ng-container *ngIf="aView === 'exist'">
                <form class="flex flex-col p-8 bg-card shadow rounded overflow-hidden" [formGroup]="comMapForm"
                    #comMapNgForm="ngForm">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12" *ngIf="currentIntegration.import_companies">
                            <p class="text-lg font-medium">Map Existing Company to {{currentIntegration.display}}
                                Company</p>
                        </div>
                        <div class="w-full mt-2">
                            <fuse-alert [appearance]="'border'" [type]="'info'"
                                *ngIf="currentIntegration.name === 'AzureADCSP'">
                                Provide Admin Consent using the Global Admin user under the Customer Tenant.
                            </fuse-alert>
                            <fuse-alert class="mt-8" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                                [type]="alert.type" [@shake]="alert.type === 'error'">
                                {{alert.message}}
                            </fuse-alert>
                            <div class="grid grid-cols-2 grid-cols-2 gap-3 mt-4 flex items-center justify-center"
                                [ngClass]="{'grid-cols-3': currentIntegration.import_companies }">
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                                    *ngIf="_cs.currentScope === '*'">
                                    <mat-label>Select Local Company</mat-label>
                                    <mat-select [formControlName]="'company_id'" id="sourceCmp"
                                        (openedChange)="closeCurrentCompany($event)"
                                        (selectionChange)="updateSourceCompany($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="searchSourceControl"
                                                [placeholderLabel]="'Search Company'"
                                                [noEntriesFoundLabel]="'No matching company found'"
                                                [searching]="searching"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [matTooltip]="company.name" *ngFor="let company of sourceCompany"
                                            [value]="company">
                                            {{company.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                                    *ngIf="_cs.currentScope !== '*'">
                                    <mat-label>Select Local Company</mat-label>
                                    <mat-select [formControlName]="'company_id'">
                                        <mat-option [matTooltip]="company.name" *ngFor="let company of sourceCompany"
                                            [value]="company">
                                            {{company.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                                    *ngIf="currentIntegration.import_companies">
                                    <mat-label>Select {{currentIntegration.display}} Company</mat-label>
                                    <mat-select [formControlName]="'dest_company_id'" id="destCmp"
                                        (selectionChange)="getIntegrationSite($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="searchDestControl"
                                                [placeholderLabel]="'Search Company'"
                                                [noEntriesFoundLabel]="'No matching company found'"
                                                [searching]="searching"></ngx-mat-select-search>
                                        </mat-option>
                                        <ng-container *ngIf="!currentIntegration.hide_search">
                                            <mat-option [matTooltip]="company.name" *ngFor="let company of destCompany;"
                                                [value]="company">
                                                {{company.name}}
                                            </mat-option>
                                        </ng-container>
                                        <ng-container *ngIf="currentIntegration.hide_search">
                                            <mat-option [matTooltip]="company.name"
                                                *ngFor="let company of destCompany |search: searchDestControl.value;"
                                                [value]="company">
                                                {{company.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <button *ngIf="currentIntegration.name==='DattoRMM'" class="gray mt-5" matSuffix mat-icon-button matTooltip="Search" color="primary"
                                    [matMenuTriggerFor]="menuSearchNow" #menuSearchMenuTrigger="matMenuTrigger">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <mat-menu #menuSearchNow="matMenu" [hasBackdrop]="false" class="p-3">
                                    <div (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                                        <div class="flex flex-row items-center">
                                            <div class="text-lg font-semibold tracking-tight">Search</div>
                                            <button class="ml-auto" mat-icon-button (click)="closesearchoption()">
                                                <mat-icon class="text-current"
                                                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                            </button>
                                        </div>
                                        <mat-divider class="my-2"></mat-divider>
                                        <div class="flex flex-col items-center w-full">
                                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                                <mat-label>Page Size</mat-label>
                                                <input matInput placeholder="Enter Page Size" [(ngModel)]="companyParams.pageSize"
                                                    [ngModelOptions]="{standalone: true}" type="number">
                                            </mat-form-field>
                                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                                <mat-label>Page</mat-label>
                                                <input matInput placeholder="Enter Page" [(ngModel)]="companyParams.page"
                                                    [ngModelOptions]="{standalone: true}" type="number" >
                                            </mat-form-field>
                                        </div>
                                        <mat-divider class="my-4"></mat-divider>
                                        <div class="flex items-center justify-end">
                                            <button (click)="getCompany()" mat-stroked-button="" type="button"
                                                color="primary">Search</button>
                                        </div>
                                    </div>
                                </mat-menu>

                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" required
                                    *ngIf="currentIntegration.import_agents">
                                    <mat-label>Choose Agent</mat-label>
                                    <mat-select [formControlName]="'sites'" id="agent">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="searchAgentControl"
                                                [placeholderLabel]="'Search Agent'"
                                                [noEntriesFoundLabel]="'No matching agent found'"
                                                [searching]="searching"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [matTooltip]="agent.name"
                                            *ngFor="let agent of agentList | search: searchAgentControl.value ;"
                                            [value]="agent">
                                            {{agent.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="w-full" *ngIf="currentIntegration.import_sites">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Map {{currentIntegration.display}} Archive </mat-label>
                                        <mat-select [formControlName]="'sites'" [multiple]="false">
                                            <ng-container *ngFor="let data of integrationSite">
                                                <mat-option [value]="data"> {{data.name}} </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex items-center justify-end mt-4 pr-4 pl-1 border-t">
                                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button"
                                    appPreventMultiClick
                                    [disabled]="!comMapForm.get('company_id').value || !comMapForm.get('dest_company_id').value"
                                    *ngIf="currentIntegration.import_companies" (click)="addToMapList()">
                                    Add
                                </button>
                                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button"
                                    appPreventMultiClick [disabled]="!comMapForm.get('company_id').value"
                                    *ngIf="!currentIntegration.import_companies" (click)="addToMapList()">
                                    Add
                                </button>
                            </div>
                            <ng-container *ngIf="mapCompanyList && mapCompanyList.length">
                                <mat-divider class="my-4"></mat-divider>
                                <div class="prose prose-sm max-w-full mx-auto sm:my-2 lg:mt-4" fuseScrollbar>
                                    <p class="text-lg font-medium">
                                        Selected Companies
                                    </p>
                                    <table class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="dark:text-white">Local Company</th>
                                                <th class="dark:text-white" *ngIf="currentIntegration.import_companies">
                                                    {{currentIntegration.display}} Company</th>
                                                <th scope="col" class="dark:text-white"
                                                    *ngIf="currentIntegration.import_sites">
                                                    Map Archive
                                                </th>
                                                <th scope="col" class="dark:text-white"
                                                    *ngIf="currentIntegration.import_agents">
                                                    Agent Name
                                                </th>
                                                <th class="dark:text-white"
                                                    *ngIf="currentIntegration.name === 'AzureADCSP'">Provide Admin
                                                    Consent</th>
                                                <th class="dark:text-white">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let com of mapCompanyList">
                                                <td>{{com.source_company_name}}</td>
                                                <td *ngIf="currentIntegration.import_companies">
                                                    {{com.dest_company_name}}</td>
                                                <td *ngIf="currentIntegration.import_sites">
                                                    {{com.site_name}}
                                                </td>
                                                <td *ngIf="currentIntegration.import_agents">
                                                    {{com.site_name}}
                                                </td>
                                                <td *ngIf="currentIntegration.name === 'AzureADCSP'">
                                                    Please click
                                                    <a href="javascript:" rel="noreferrer" appPreventMultiClick
                                                        (click)="provideConsent(com.dest_company_id)"> here
                                                    </a>
                                                    to provide consent
                                                </td>
                                                <td>
                                                    <button *ngIf="currentIntegration.name === 'AzureADCSP'"
                                                        appPreventMultiClick
                                                        (click)="provideConsent(com.dest_company_id, true)"
                                                        mat-icon-button="" matTooltip="Copy tenant admin consent link">
                                                        <mat-icon class="icon-size-5 text-primary">file_copy</mat-icon>
                                                    </button>
                                                    <button (click)="mapCompanyList.splice(i, 1); getCompany()"
                                                        mat-icon-button="" matTooltip="Delete record">
                                                        <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5">
                                        <button class="px-6 ml-3" mat-flat-button [color]="'primary'" type="button"
                                            appPreventMultiClick (click)="mapSelectedCompanies()">
                                            Finish
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </form>

            </ng-container>
        </div>
    </div>
</ng-container>

<s-modal id="editNonPsaInt">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Update Mapped Company</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('editNonPsaInt');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form class="example-form px-4" #editMapNgForm="ngForm">
                <div class="prose prose-sm max-w-3xl">
                    <h3 class="mt-2">Souce Company Name: {{ updateCompany.source_company_name}}</h3>
                </div>

                <div class="flex flex-row items-center w-full my-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                      <mat-label>Choose Destination Company</mat-label>
                      <mat-select name="destEditCmp" id="destEditCmp" [(ngModel)]="edit_update_company">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="editDestControl" [placeholderLabel]="'Search Company'"
                            [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                          </ngx-mat-select-search>
                        </mat-option>
                        <ng-container *ngIf="!currentIntegration.hide_search">
                          <mat-option [matTooltip]="company.name" *ngFor="let company of destCompany;" [value]="company">
                            {{company.name}}
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="currentIntegration.hide_search">
                          <mat-option [matTooltip]="company.name" *ngFor="let company of destCompany | search: editDestControl.value;"
                            [value]="company">
                            {{company.name}}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  
                    <button *ngIf="currentIntegration.name === 'DattoRMM' && !currentIntegration.import_agents && !currentIntegration.import_sites" class="ml-2 gray" matSuffix mat-icon-button matTooltip="Search" color="primary"
                      [matMenuTriggerFor]="menuSortNow" #menuSearchMenuTrigger="matMenuTrigger">
                      <mat-icon>search</mat-icon>
                    </button>
                </div>
                
                <mat-menu #menuSortNow="matMenu" [hasBackdrop]="false" class="p-3">
                    <div (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                        <div class="flex flex-row items-center">
                            <div class="text-lg font-semibold tracking-tight">Search</div>
                            <button class="ml-auto" mat-icon-button (click)="closesearchoption()">
                                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                            </button>
                        </div>
                        <mat-divider class="my-2"></mat-divider>
                        <div class="flex flex-col items-center w-full">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Page Size</mat-label>
                                <input matInput placeholder="Enter Page Size" [(ngModel)]="companyParams.pageSize" name="pageSize"
                                    type="number">
                            </mat-form-field>
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Page</mat-label>
                                <input matInput placeholder="Enter Page" [(ngModel)]="companyParams.page" name="page" type="number"
                                    autocomplete="off">
                            </mat-form-field>

                        </div>
                        <mat-divider class="my-4"></mat-divider>
                        <div class="flex items-center justify-end">
                            <button (click)="getCompany()" mat-stroked-button="" type="button"
                                color="primary">Search</button>
                        </div>
                    </div>
                </mat-menu>

                <mat-form-field class="w-full mt-4" [subscriptSizing]="'dynamic'" required
                    *ngIf="currentIntegration.import_agents">
                    <mat-label>Choose Agent</mat-label>
                    <mat-select [(ngModel)]="updateCompany.site_id" name="agentEdit">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="searchAgentControl"
                                [placeholderLabel]="'Search Agent'" [noEntriesFoundLabel]="'No matching agent found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="agent.name"
                            *ngFor="let agent of agentList | search: searchAgentControl.value;" [value]="agent.id">
                            {{agent.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="w-full mt-4" *ngIf="currentIntegration.import_sites">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Map Archive</mat-label>
                        <mat-select [(ngModel)]="updateCompany.site_id" name="sites" [multiple]="false">
                            <ng-container *ngFor="let data of integrationSite">
                                <mat-option [value]="data.id"> {{data.name}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t">
                    <button type="submit" id="saveButton" (click)="updateComMapping()" appPreventMultiClick class="mr-2"
                        mat-flat-button color="primary">Update
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>
