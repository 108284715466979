import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { DynamicPosition, FuseConfig, FuseConfigService, Scheme, TableView, Theme, Themes, Views } from '@fuse/services/config';
import { Subject, takeUntil } from 'rxjs';
import { FuseScrollResetDirective } from "../../../../@fuse/directives/scroll-reset";
import { CommonService } from "../../../_services/common.service";
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { DirectivesModule } from 'app/-directives/-directives.module';
import cloneDeep from 'lodash-es/cloneDeep';
import { MatSlideToggleModule, _MatSlideToggleRequiredValidatorModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styles: [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
                fuse-drawer {
                    z-index: 499;
                }
            }

            @media (screen and min-width: 1280px) {

                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, DirectivesModule, FuseDrawerComponent, MatButtonModule, NgFor, NgClass, MatTooltipModule, NgIf, FuseScrollbarDirective, FuseScrollResetDirective, MatSlideToggleModule,
        FormsModule,
        _MatSlideToggleRequiredValidatorModule,],
})
export class SettingsComponent implements OnInit, OnDestroy {
    config: FuseConfig;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    views: Views;
    tableView: Boolean = false;
    dynamiPosition: 'right' | 'left'
    view: string;
    defaultConfig: any = {};
    Objectkeys = Object.keys;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        public cs: CommonService,
        private _bs: BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef,
        private loaderService: LoaderService,
        public toast: MyToastrService,
    ) {
        this.cs.isCheckedChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((isVaue: any) => {
            this.cs.isChecked = isVaue;
            this._changeDetectorRef.detectChanges();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                // Store the config
                if (!this.config) { this.config = config; }
            });
        if (this.cs.sConfig && !this.cs.sConfig.id) {
            setTimeout(() => this.getLayoutSettings(), 2000);
        }
    }
    onToggleChange(dynamiPosition: any) {
        const toggleCheck = dynamiPosition;
        this.cs.isCheckedChanged.emit(toggleCheck);
        this._fuseConfigService.config = { dynamiPosition };
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void {
        this.cs.checkLayout = layout;
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams: {
                layout: null,
            },
            queryParamsHandling: 'merge',
        }).then(() => {
            // Set the config
            this._fuseConfigService.config = { layout };
        });
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this.config.scheme = scheme;
        this._fuseConfigService.config = { scheme };
        if (scheme === 'light') {
            this.cs.logo = '/assets/images/logo/cs_logo_light.png';
            this.cs.dashURL = `/r/company/jsonconfigs/dashboard_${localStorage.getItem('_pid')}`;
        } else {
            this.cs.logo = '/assets/images/logo/cs_logo_dark.png';
            this.cs.dashURL = `/r/company/jsonconfigs/dashboard_${localStorage.getItem('_pid')}_dark`;
        }
        this.cs.schemeChange.emit(scheme);
        // this.cs.checkScheme = scheme;
        this.cs.setscheme(scheme)
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void {
        this.config.theme = theme;
        this._fuseConfigService.config = { theme };
    }

    setView(view: any): void {
        this._fuseConfigService.config = { view }
    }
    saveSetting(): void {
        if (this.cs.idValuesCheck.length > 1) {
            const index: number = this.cs.idValuesCheck.indexOf(this.cs.sConfig.id);
            if (index !== -1) {
                this.cs.idValuesCheck.splice(index, 1);
            }
            const reqData: any = {
                ids: this.cs.idValuesCheck.map((obj: any) => obj)
            }
            this._bs.doRequest(`/d/company/settings/bulk_delete`, 'post', reqData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        this.cs.sConfig.value = this.config;
                        this.cs.sConfig.name = (this.cs.isDefault) ? 'default' : this._bs.user().email;
                        const request: any = { data: this.cs.sConfig };
                        if (this.cs.sConfig.id) { request.id = this.cs.sConfig.id };
                        const method = (this.cs.sConfig.id) ? 'patch' : 'post';
                        this.loaderService.display(true);
                        this._bs.doRequest('/w/company/settings', method, request)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe((result: any) => {
                                if (result.status) {
                                    setTimeout(() => {
                                        this.loaderService.display(false);
                                        this.toast.sToast('success', `Saved successfully!`);
                                        if (result.id) {
                                            this.cs.sConfig.id = parseInt(result.id)
                                        }
                                        this.cs.assetViewEmitter.emit(this.config.tableView)
                                        this.cs.tableViewData = this.config.tableView;
                                        this._changeDetectorRef.detectChanges();
                                        this.cs.schemeChange.emit(this.config.scheme);
                                        // this.cs.checkScheme = this.config.scheme;
                                        this.cs.setscheme(this.config.scheme)
                                    }, 3000);
                                } else {
                                    const data = (result.message) ? result.message : result.data;
                                    this.toast.sToast('error', data);
                                }
                            })
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                });
        } else {
            this.cs.sConfig.value = this.config;
            this.cs.sConfig.name = (this.cs.isDefault) ? 'default' : this._bs.user().email;
            const request: any = { data: this.cs.sConfig };
            if (this.cs.sConfig.id) { request.id = this.cs.sConfig.id };
            const method = (this.cs.sConfig.id) ? 'patch' : 'post';
            this.loaderService.display(true);
            this._bs.doRequest('/w/company/settings', method, request)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        setTimeout(() => {
                            this.loaderService.display(false);
                            this.toast.sToast('success', `Saved successfully!`);
                            if (result.id) {
                                this.cs.sConfig.id = parseInt(result.id)
                            }
                            this.cs.assetViewEmitter.emit(this.config.tableView)
                            this.cs.tableViewData = this.config.tableView;
                            this._changeDetectorRef.detectChanges();
                            this.cs.schemeChange.emit(this.config.scheme);
                            // this.cs.checkScheme = this.config.scheme;
                            this.cs.setscheme(this.config.scheme)
                        }, 3000);
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                })
        }
    }
    getDefaultLayoutSettings(): void {
        const params: any = {
            condition: `category='settings' and value IS NOT NULL and sub_category='layout' and name='default'`,
            order_by: `updated desc`,
            skip: 0,
            limit: 10,
        }
        this._bs.doRequest(`/r/company/settings`, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status && result.data && result.data.length) {
                    this.cs.idValuesCheck = result.data.map(item => item.id);
                }
                if (result.status && result.data && result.data.length && result.data[0].value && this.Objectkeys(result.data[0].value).length) {
                    this.cs.sConfig = result.data[0];
                    if (result.data[0].value.scheme) {
                        this.config = cloneDeep(result.data[0].value);
                    } else {
                        this._fuseConfigService.config$
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe((config: FuseConfig) => {
                                this.config = config;
                            });
                        this.setTableView(false)
                    }
                    if (this.config && this.config.dynamiPosition) {
                        this.onToggleChange(this.config.dynamiPosition);
                    } else {
                        this.onToggleChange('right');
                    }
                    this.cs.tableViewData = this.config.tableView;
                    this.tableView = this.config.tableView
                    this.setTableView(this.config.tableView);
                    this.cs.schemeChange.emit(this.config.scheme);
                    this.setLayout(this.config.layout); this.setScheme(this.config.scheme); this.setTheme(this.config.theme); this.setView(this.config.view);
                    this._changeDetectorRef.detectChanges();
                    this.cs.checkLayout = this.config.layout;
                    this.cs.isDefault = (result.data[0].name === 'default') ? true : false;
                } else {
                    this._fuseConfigService.config$
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((config: FuseConfig) => {
                            this.config = config;
                        });
                    this.cs.schemeChange.emit(this.config.scheme);
                    this.setScheme("light");
                    this.setTableView(false);
                    this.cs.checkLayout = this.config.layout;
                    this.cs.isDefault = false;
                }
            }, (error: any) => {
                this._fuseConfigService.config$
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((config: FuseConfig) => {
                        this.config = config;
                    });
                this.cs.schemeChange.emit(this.config.scheme);
                this.setScheme("light");
                this.setTableView(false);
                this.cs.checkLayout = this.config.layout;
                this.cs.isDefault = false;
            });
    }

    getLayoutSettings(): void {
        if (!this._bs.user().email) {
            setTimeout(() => this.getLayoutSettings(), 1000);
            return;
        }
        const params: any = {
            condition: `category='settings' and value IS NOT NULL and sub_category='layout' and name='${this._bs.user().email}'`,
            order_by: `updated desc`,
            skip: 0,
            limit: 1
        }
        this._bs.doRequest(`/r/company/settings`, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status && result.data && result.data.length && this.Objectkeys(result.data[0].value).length) {
                    if (result.data[0].value.scheme) {
                        this.config = cloneDeep(result.data[0].value);
                        if (!this.config.tableView) {
                            this.setTableView(false)
                        }
                    } else {
                        this._fuseConfigService.config$
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe((config: FuseConfig) => {
                                this.config = config;
                            });
                        this.setTableView(false)
                    }
                    this.cs.sConfig = result.data[0];
                    if (this.config && this.config.dynamiPosition) {
                        this.onToggleChange(this.config.dynamiPosition);
                    } else {
                        this.onToggleChange('right');
                    }
                    this.cs.tableViewData = this.config.tableView;
                    this.cs.schemeChange.emit(this.config.scheme);
                    this.tableView = this.config.tableView;
                    this.setTableView(this.config.tableView);
                    this.setLayout(this.config.layout); this.setScheme(this.config.scheme); this.setTheme(this.config.theme); this.setView(this.config.view);
                    this._changeDetectorRef.detectChanges();
                    this.cs.checkLayout = this.config.layout;
                    this.cs.isDefault = (result.data[0].name === 'default') ? true : false;
                } else {
                    this.getDefaultLayoutSettings();
                }
            }, (error: any) => {
                this.getDefaultLayoutSettings();
            });
    }

    resetSetting(): void {
        if (!this.cs.sConfig || !this.cs.sConfig.id) { return; }
        this.loaderService.display(true);
        this._bs.doRequest(`/d/company/settings/${this.cs.sConfig.id}`, 'delete')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast('success', 'Removed successfully!');
                    this.onToggleChange("right");
                    this.setTableView(false)
                    this.setLayout('dense'); this.setScheme('light'); this.setTheme('theme-default');
                    delete this.cs.sConfig.id;
                    this.cs.isDefault = false;
                } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                }
            });
    }
    changeTableView() {
        this.setTableView(this.config.tableView);
    }
    setTableView(tableView: any): void {
        this.config.tableView = tableView;
        this.cs.assetViewEmitter.emit(tableView);
        this.cs.tableViewData = tableView;
        this._fuseConfigService.config = { tableView };
    }
}
