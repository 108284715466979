import { DOCUMENT, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseConfig, FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FusePlatformService } from '@fuse/services/platform';
import { FUSE_VERSION } from '@fuse/version';
import {combineLatest, filter, map, Observable, of, Subject, switchMap, takeUntil} from 'rxjs';
import { SettingsComponent } from './common/settings/settings.component';
import { EmptyLayoutComponent } from './layouts/empty/empty.component';
import { CenteredLayoutComponent } from './layouts/horizontal/centered/centered.component';
import { EnterpriseLayoutComponent } from './layouts/horizontal/enterprise/enterprise.component';
import { MaterialLayoutComponent } from './layouts/horizontal/material/material.component';
import { ModernLayoutComponent } from './layouts/horizontal/modern/modern.component';
import { ThinLayoutComponent } from './layouts/vertical/thin/thin.component';
import { BaseRequestService } from '../_services/base.service';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from "@angular/common";
import { DynamicSettingsComponent } from "./common/dynamic-settings/dynamic-settings.component";
import { CommonService } from 'app/_services/common.service';
import { DenseLayoutComponent } from "./layouts/vertical/dense/dense.component";
import { ClassicLayoutComponent } from './layouts/vertical/classic/classic.component';
import { ClassyLayoutComponent } from "./layouts/vertical/classy/classy.component";
import { FuturisticLayoutComponent } from './layouts/vertical/futuristic/futuristic.component';
import jwt_decode from "jwt-decode";
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        EmptyLayoutComponent,
        CenteredLayoutComponent,
        EnterpriseLayoutComponent,
        MaterialLayoutComponent,
        ModernLayoutComponent,
        ThinLayoutComponent,
        SettingsComponent,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        NgForOf,
        DynamicSettingsComponent,
        AsyncPipe,
        TitleCasePipe,
        DenseLayoutComponent,
        ClassicLayoutComponent,
        ClassyLayoutComponent,
        FuturisticLayoutComponent
    ]
})
export class LayoutComponent implements OnInit, OnDestroy {
    config: FuseConfig;
    routeData$: Observable<any>;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    private syncInterva;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public companies: any = [];
    tenant_details: any = {};
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _renderer2: Renderer2,
        private _router: Router,
        private _ls: LoaderService, 
        private _fuseConfigService: FuseConfigService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fusePlatformService: FusePlatformService,
        private _baseRequestService: BaseRequestService,
        private _toast: MyToastrService,
        private _cs: CommonService,
        public _userService: UserService
    ) {
        this.routeData$ = _router.events.pipe(
            filter(routeEvent => routeEvent instanceof NavigationEnd),
            map(() => _activatedRoute),
            map(activatedRoute => activatedRoute.firstChild),
            switchMap(firstChild => firstChild.data),
            map((data: any) => data)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        //Get logos
        // this.getWhiteLabelSetting();
        // Set the theme and scheme based on the configuration
        combineLatest([
            this._fuseConfigService.config$,
            this._fuseMediaWatcherService.onMediaQueryChange$([
                '(prefers-color-scheme: dark)',
                '(prefers-color-scheme: light)',
            ]),
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map(([config, mql]) => {
                    const options = {
                        scheme: config.scheme,
                        theme: config.theme,
                    };

                    // If the scheme is set to 'auto'...
                    if (config.scheme === 'auto') {
                        // Decide the scheme using the media query
                        options.scheme = mql.breakpoints[
                            '(prefers-color-scheme: dark)'
                        ]
                            ? 'dark'
                            : 'light';
                    }

                    return options;
                })
            )
            .subscribe((options) => {
                // Store the options
                this.scheme = options.scheme;
                this.theme = options.theme;

                // Update the scheme and theme
                this._updateScheme();
                this._updateTheme();
            });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.config = config;

                // Update the layout
                this._updateLayout();
            });

        // Subscribe to NavigationEnd event
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                // Update the layout
                this._updateLayout();
            });
        // Set the app version
        this._renderer2.setAttribute(
            this._document.querySelector('[ng-version]'),
            'fuse-version',
            FUSE_VERSION
        );

        // Set the OS name
        this._renderer2.addClass(
            this._document.body,
            this._fusePlatformService.osName
        );
        this.initPG();
    }

    initPG(): void {
        const login = localStorage.getItem('initLogin');
        if (login === '1') {
            this._cs.getProblemGroup();
        } else {
            var token = localStorage.getItem('zitadel:id_token');
            if (token) {
                /*this._baseRequestService.doRequest(`/w/user/login`, 'post', {})
                    .pipe(takeUntil(this._unsubscribeAll)).subscribe((r: any) => {
                    localStorage.setItem('initLogin', '1');
                    setTimeout(() => this.initPG(), 1000);
                });*/
                const data: any = this.setup();
            } else {
                setTimeout(() => this.initPG(), 1000);
            }
        }
    }

    async setup(): Promise<any> {
        try {
            var token = localStorage.getItem('zitadel:id_token');
            if (token) {
                const params: any = {};
                const response = await this._baseRequestService.doRequest(`/w/user/login`, 'post',  params, null).toPromise();
                if (response.status) {
                    localStorage.setItem('initLogin', '1');
                    setTimeout(() => this.initPG(), 1000);
                }
            } else {
                setTimeout(() => this.initPG(), 1000);
            }
        } catch (error) {
            throw error;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        clearInterval(this.syncInterva);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    scope: any = '*';

    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout');
        if (layoutFromQueryParam) {
            this.layout = layoutFromQueryParam;
            if (this.config) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {
            // Check if there is a 'layout' data
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.layout
            ) {
                // Set the layout
                this.layout = path.routeConfig.data.layout;
            }
        });
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(
                    className,
                    className.split('-')[1]
                );
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.theme);
    }

    checkSync(): void {
        const parentElements = document.querySelectorAll('div');
        const searchText = 'Claim';
        parentElements.forEach((parent) => {
            const childElements = parent.querySelectorAll('div');
            let shouldRemove = false;
            childElements.forEach((child) => {
                if (child.textContent.includes(searchText)) {
                    shouldRemove = true;
                    return; // No need to check other children if a match is found
                }
            });
            if (shouldRemove) {
                parent.remove();
            }
        });
    }
    checkPop(): void {
        const parentElements = document.querySelectorAll('div');
        const searchText = 'Syncfusion';
        parentElements.forEach((parent) => {
            const childElements = parent.querySelectorAll('span');
            let shouldRemove = false;
            childElements.forEach((child) => {
                if (child.textContent.includes(searchText)) {
                    shouldRemove = true;
                    return; // No need to check other children if a match is found
                }
            });
            if (shouldRemove) {
                parent.remove();
            }
        });
    }

    getCompanies(): void {
        this._baseRequestService
            .doRequest('/r/company/companies', 'get', null, { condition: true })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status) {
                    this.companies = result.data;
                    this._baseRequestService.companies$.next(result.data);
                }
            });
    }
}
