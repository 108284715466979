<div class="flex items-center justify-end w-full max-w-3xl mx-auto" *ngIf="showClose">
    <button mat-icon-button aria-label="close modal icon" matTooltip="Close" type="button" appPreventMultiClick
        (click)="closeCallback.emit({})">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-tab-group (selectedTabChange)="selectedTab($event)" class="sm:px-2 max-w-3xl mx-auto" mat-stretch-tabs="false"
    [animationDuration]="'0'">
    <mat-tab label="Windows">
        <ng-template mat-tab-label>
            <i class="fab fa-windows fa-2x text-primary mr-3" id="Windows"></i><span id="Windows"
                class="text-lg">Windows</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan
                        fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">
                        <div class="flex flex-col w-full border-r">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" required name="typesmsi"
                                [(ngModel)]="selectedValueType" (change)="radioButtonChange($event)">
                                <mat-radio-button class="mr-2" [value]="'exe'">Exe</mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'msi'">Msi</mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'scan'">Scan</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <ng-template matTabContent [ngIf]="isScanSelected">
                            <div class="mt-2 mb-4 ml-2 mat-title">Steps</div>
                            <!-- <div class="mt-0 mb-4"> -->
                            <!-- <mat-card class="mb-4 mat-mdc-card mdc-card">
                                    <mat-card-content>
                                        <div class="text-left">
                                            <p class="mb-2">1. Download the Windows file.</p>
                                            <p class="mb-2">2. Extract the zip file.</p>
                                            <p class="mb-2">3. Open PowerShell as Administrator.</p>
                                            <p class="mb-2">4. Please navigate to the downloaded zip file.</p>
                                            <p class="mb-2">5. Run ./cybercnsagent.exe -o output.json -n true</p>
                                            <p class="mb-2">6. Upload the zip file "output.json" to the portal.</p>
                                        </div>
                                    </mat-card-content>
                                </mat-card> -->
                            <div class="border mb-4 rounded-lg overflow-hidden w-full">
                                <div class="p-4">
                                    <p class="mb-2">1. Download the Windows file.</p>
                                    <p class="mb-2">2. Extract the zip file.</p>
                                    <p class="mb-2">3. Open PowerShell as Administrator.</p>
                                    <p class="mb-2">4. Please navigate to the extracted folder.</p>
                                    <p class="mb-2">5. Run <b>./cybercnsagent.exe -o output.json -n true -c {{cs?.currentScope?.id}} -e {{tenantId}} -y {{podid}}  </b></p>
                                    <p class="mb-2">7. If the machine doesn’t have internet access, upload the <b>"output.json"</b> file manually to the portal. </p>
                                    <p class="mb-2">   If the machine has internet access, <b>"output.json"</b> will be uploaded automatically to the company you are currently in.</p>
                                </div>
                            </div>
                            <div class="flex flex-row items-center justify-end gap-2 mb-2 mr-4"
                                style="margin-left: 50PX;">
                                <button mat-stroked-button type="button" color="primary"
                                    (click)="upload_onetime_scan()">Upload</button>
                                <button mat-raised-button color="primary" type="button"
                                    (click)="downloadscan('Windows')">Download</button>
                            </div>
                            <!-- </div> -->
                        </ng-template>

                        <div *ngIf="!isScanSelected">
                            <div class="terminal br-4 p-4">
                                <div [innerHTML]="probeCmdHtml | safeHTML">
                                </div>
                                <hr class="border-b mt-4 mb-4">
                                <div class="flex items-end justify-end mx-3 sm:mx-5">
                                    <button class="p-5 mr-1" *ngIf="selectedValueType === 'exe' " mat-raised-button
                                        [disabled]="isLoading" color="primary" type="button" appPreventMultiClick
                                        (click)="dWIAgent();">
                                        <mat-icon class="text-primary dark:text-primary-500" *ngIf="!isLoading"
                                            [svgIcon]="'file_download'">
                                        </mat-icon> <span class="ml-1 text-white" *ngIf="!isLoading">Download Web
                                            Installer</span>
                                        <div class="flex flex-row items-center" *ngIf="isLoading">
                                            <mat-progress-spinner [diameter]="24"
                                                [mode]="'indeterminate'"></mat-progress-spinner>
                                            <span class="ml-1 text-white">Downloading Web Installer</span>
                                        </div>

                                    </button>
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="dAgent(true);">
                                        <mat-icon class="text-primary dark:text-primary-500"
                                            [svgIcon]="'file_download'">
                                        </mat-icon> <span class="ml-1 text-white">Download</span>
                                    </button>

                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="copyCmdClip();">
                                        <span>
                                            <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                        </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 divide-y border-t border-b">
                                <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                    <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                        <div class="flex items-center">
                                            <div
                                                class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                                <ng-container>
                                                    <mat-icon class="text-primary dark:text-primary-500"
                                                        [svgIcon]="api.icon">
                                                    </mat-icon>
                                                </ng-container>
                                            </div>
                                            <div class="ml-4">
                                                <div class="font-medium">
                                                    {{api.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                            <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                                {{api.key | ellipsis:25}}
                                            </div>
                                            <div class="order-1 sm:order-2 sm:ml-3 mb-1">
                                                <button mat-icon-button type="button" appPreventMultiClick
                                                    (click)="copyClip(api.key)">
                                                    <span>
                                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Mac">
        <ng-template mat-tab-label>
            <i class="fab fa-apple fa-2x text-primary mr-3" id="Mac"></i><span id="Mac" class="text-lg">Mac</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan
                        fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">

                        <div class="flex flex-col w-full border-r">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" required name="typesexe"
                                [(ngModel)]="selectedValueType" (change)="radioButtonChange1($event)">
                                <mat-radio-button class="mr-2" [value]="'exe'">Pkg</mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'scan'">Scan</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <ng-template matTabContent [ngIf]="isScanSelected">
                            <div class="mt-2 mb-4 ml-2 mat-title">Steps</div>
                            <div class="border mb-4 rounded-lg overflow-hidden w-full">
                                <div class="p-4">
                                    <p class="mb-2">1. Download the Darwin file.</p>
                                    <p class="mb-2">2. Extract the zip file.</p>
                                    <p class="mb-2">3. Open Terminal.</p>
                                    <p class="mb-2">4. Please navigate to the extracted folder.</p>
                                    <p class="mb-2">5. Run 'chmod +x cybercnsagent_darwin'.</p>
                                    <p class="mb-2">6. Run 'sudo su'.</p>
                                    <p class="mb-2">7. Run <b>./cybercnsagent_darwin -o output.json -n true -c {{cs?.currentScope?.id}} -e {{tenantId}} -y {{podid}}  </b></p>
                                    <p class="mb-2">7. If the machine doesn’t have internet access, upload the "output.json" file manually to the portal. </p>
                                    <p class="mb-2">   If the machine has internet access, "output.json" will be uploaded automatically to the company you are currently in.</p>
                                </div>
                            </div>
                            <div class="flex flex-row items-center justify-end gap-2 mb-2 mr-4"
                                style="margin-left: 50PX;">
                                <button mat-stroked-button type="button" color="primary"
                                    (click)="upload_onetime_scan()">Upload</button>
                                <button mat-raised-button color="primary" type="button"
                                    (click)="downloadscan('Darwin')">Download</button>
                            </div>
                        </ng-template>

                        <div *ngIf="!isScanSelected">
                            <div class="terminal br-4 p-4">
                                <div [innerHTML]="probeCmdHtml | safeHTML">
                                </div>
                                <hr class="border-b mt-4 mb-4">
                                <div class="flex items-end justify-end mx-3 sm:mx-5">
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="dAgent();">
                                        <mat-icon class="text-primary dark:text-primary-500"
                                            [svgIcon]="'file_download'">
                                        </mat-icon> <span class="ml-1 text-white">Download</span>
                                    </button>
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="copyCmdClip();">
                                        <span>
                                            <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                        </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                    </button>
                                    <!-- <button
                                        class="flex items-center px-4 py-2 text-xl text-primary dark:text-primary-500"
                                        mat-button type="button" appPreventMultiClick (click)="reset()">
                                        <div class="mr-1 gap-2 flex items-center">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58 4 4 7.58 4 12H1L5 16L9 12H6C6 8.69 8.69 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13 11H22V2L17.65 6.35ZM12 18C10.34 18 8.86 17.31 7.78 16.22L11 13H2V22L6.35 17.65C7.8 19.1 9.79 20 12 20C16.42 20 20 16.42 20 12H23L19 8L15 12H18C18 15.31 15.31 18 12 18Z"
                                                    fill="currentColor" />
                                            </svg>
                                            <span
                                                class="text-base font-medium text-white hover:text-red-500">Reset</span>
                                        </div>
                                    </button> -->
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 divide-y border-t border-b">
                                <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                    <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                        <div class="flex items-center">
                                            <div
                                                class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                                <ng-container>
                                                    <mat-icon class="text-primary dark:text-primary-500"
                                                        [svgIcon]="api.icon">
                                                    </mat-icon>
                                                </ng-container>
                                            </div>
                                            <div class="ml-4">
                                                <div class="font-medium">
                                                    {{api.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                            <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                                {{api.key | ellipsis:25}}
                                            </div>
                                            <div class="order-1 sm:order-2 sm:ml-3">
                                                <button mat-icon-button type="button" appPreventMultiClick
                                                    (click)="copyClip(api.key)">
                                                    <span>
                                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Linux">
        <ng-template mat-tab-label>
            <i class="fab fa-linux fa-2x text-primary mr-3" id="Linux"></i><span id="Linux" class="text-lg">Linux</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan
                        fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">

                        <div class="flex flex-col w-full border-r">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" required name="typesexe"
                                [(ngModel)]="selectedValueType" (change)="radioButtonChange2($event)">
                                <mat-radio-button class="mr-2" [value]="'exe'">Exe</mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'scan'">Scan</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <ng-template matTabContent [ngIf]="isScanSelected">
                            <div class="mt-2 mb-4 ml-2 mat-title">Steps</div>
                            <div class="border mb-4 rounded-lg overflow-hidden w-full">
                                <div class="p-4">
                                    <p class="mb-2">1. Download the Linux file.</p>
                                    <p class="mb-2">2. Extract the zip file.</p>
                                    <p class="mb-2">3. Open Terminal.</p>
                                    <p class="mb-2">4. Please navigate to the extracted folder.</p>
                                    <p class="mb-2">5. Run 'chmod +x cybercnsagent_linux'.</p>
                                    <p class="mb-2">6. Run 'sudo su'.</p>
                                    <p class="mb-2">7. Run <b>./cybercnsagent_linux -o output.json -n true -c {{cs?.currentScope?.id}} -e {{tenantId}} -y {{podid}}  </b></p>
                                    <p class="mb-2">7. If the machine doesn’t have internet access, upload the "output.json" file manually to the portal. </p>
                                    <p class="mb-2">   If the machine has internet access, "output.json" will be uploaded automatically to the company you are currently in.</p>
                                </div>
                            </div>
                            <div class="flex flex-row items-center justify-end gap-2 mb-2 mr-4"
                                style="margin-left: 50PX;">
                                <button mat-stroked-button type="button" color="primary"
                                    (click)="upload_onetime_scan()">Upload</button>
                                <button mat-raised-button color="primary" type="button"
                                    (click)="downloadscan('Linux')">Download</button>
                            </div>
                        </ng-template>

                        <div *ngIf="!isScanSelected">
                            <div class="terminal br-4 p-4">
                                <div [innerHTML]="probeCmdHtml | safeHTML">
                                </div>
                                <hr class="border-b mt-4 mb-4">
                                <div class="flex items-end justify-end mx-3 sm:mx-5">
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="dAgent();">
                                        <mat-icon class="text-primary dark:text-primary-500"
                                            [svgIcon]="'file_download'">
                                        </mat-icon> <span class="ml-1 text-white">Download</span>
                                    </button>
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="copyCmdClip();">
                                        <span>
                                            <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                        </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 divide-y border-t border-b">
                                <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                    <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                        <div class="flex items-center">
                                            <div
                                                class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                                <ng-container>
                                                    <mat-icon class="text-primary dark:text-primary-500"
                                                        [svgIcon]="api.icon">
                                                    </mat-icon>
                                                </ng-container>
                                            </div>
                                            <div class="ml-4">
                                                <div class="font-medium">
                                                    {{api.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                            <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                                {{api.key | ellipsis:25}}
                                            </div>
                                            <div class="order-1 sm:order-2 sm:ml-3">
                                                <button mat-icon-button type="button" appPreventMultiClick
                                                    (click)="copyClip(api.key)">
                                                    <span>
                                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="ARM">
        <ng-template mat-tab-label>
            <i class="fas fa-microchip fa-2x text-primary mr-3" id="ARM"></i><span id="ARM" class="text-lg">ARM</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan
                        fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">

                        <div class="flex flex-col w-full border-r">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" required name="typesexe"
                                [(ngModel)]="selectedValueType" (change)="radioButtonChange3($event)">
                                <mat-radio-button class="mr-2" [value]="'exe'">Exe</mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'scan'">Scan</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <ng-template matTabContent [ngIf]="isScanSelected">
                            <div class="mt-2 mb-4 ml-2 mat-title">Steps</div>
                            <div class="border mb-4 rounded-lg overflow-hidden w-full">
                                <div class="p-4">
                                    <p class="mb-2">1. Download the ARM file.</p>
                                    <p class="mb-2">2. Extract the zip file.</p>
                                    <p class="mb-2">3. Open Terminal.</p>
                                    <p class="mb-2">4. Please navigate to the extracted folder.</p>
                                    <p class="mb-2">5. Run <b>chmod +x cybercnsagent_arm</b></p>
                                    <p class="mb-2">6. Run <b>./cybercnsagent_arm -o output.json -n true -c {{cs?.currentScope?.id}} -e {{tenantId}} -y {{podid}}  </b></p>
                                    <p class="mb-2">7. If the machine doesn’t have internet access, upload the "output.json" file manually to the portal. </p>
                                    <p class="mb-2">   If the machine has internet access, "output.json" will be uploaded automatically to the company you are currently in.</p>
                                </div>
                            </div>
                            <!-- -c <COMPANY_ID> -e <TENANT_ID> -y <POD_ID> -->
                            <div class="flex flex-row items-center justify-end gap-2 mb-2 mr-4"
                                style="margin-left: 50PX;">
                                <button mat-stroked-button type="button" color="primary"
                                    (click)="upload_onetime_scan()">Upload</button>
                                <button mat-raised-button color="primary" type="button"
                                    (click)="downloadscan('ARM')">Download</button>
                            </div>
                        </ng-template>

                        <div *ngIf="!isScanSelected">
                            <div class="terminal br-4 p-4">
                                <div [innerHTML]="probeCmdHtml | safeHTML">
                                </div>
                                <hr class="border-b mt-4 mb-4">
                                <div class="flex items-end justify-end mx-3 sm:mx-5">
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="dAgent();">
                                        <mat-icon class="text-primary dark:text-primary-500"
                                            [svgIcon]="'file_download'">
                                        </mat-icon> <span class="ml-1 text-white">Download</span>
                                    </button>
                                    <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                        (click)="copyCmdClip();">
                                        <span>
                                            <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                        </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 divide-y border-t border-b">
                                <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                    <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                        <div class="flex items-center">
                                            <div
                                                class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                                <ng-container>
                                                    <mat-icon class="text-primary dark:text-primary-500"
                                                        [svgIcon]="api.icon">
                                                    </mat-icon>
                                                </ng-container>
                                            </div>
                                            <div class="ml-4">
                                                <div class="font-medium">
                                                    {{api.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                            <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                                {{api.key | ellipsis:25}}
                                            </div>
                                            <div class="order-1 sm:order-2 sm:ml-3">
                                                <button mat-icon-button type="button" appPreventMultiClick
                                                    (click)="copyClip(api.key)">
                                                    <span>
                                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="ARM-32">
        <ng-template mat-tab-label>
            <i class="fas fa-microchip fa-2x text-primary mr-3" id="ARM-32"></i><span id="ARM-32"
                class="text-lg">ARM-32</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan
                        fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">
                        <div class="terminal br-4 p-4">
                            <div [innerHTML]="probeCmdHtml | safeHTML">
                            </div>
                            <hr class="border-b mt-4 mb-4">
                            <div class="flex items-end justify-end mx-3 sm:mx-5">
                                <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                    (click)="dAgent();">
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="'file_download'">
                                    </mat-icon> <span class="ml-1 text-white">Download</span>
                                </button>
                                <button class="px-3 mr-1" mat-button type="button" appPreventMultiClick
                                    (click)="copyCmdClip();">
                                    <span>
                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                    </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8 divide-y border-t border-b">
                            <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container>
                                                <mat-icon class="text-primary dark:text-primary-500"
                                                    [svgIcon]="api.icon">
                                                </mat-icon>
                                            </ng-container>
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium">
                                                {{api.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                        <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                            {{api.key | ellipsis:25}}
                                        </div>
                                        <div class="order-1 sm:order-2 sm:ml-3">
                                            <button mat-icon-button type="button" appPreventMultiClick
                                                (click)="copyClip(api.key)">
                                                <span>
                                                    <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                        aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<s-modal id="uploadAssessment1">
    <div class="mt-4" class="w-[600px]">
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="flex justify-between items-center">
                <div class="text-xl font-medium tracking-tight">Upload File</div>
                <button class="ml-auto" mat-icon-button (click)="_modalService.close('uploadAssessment1');">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <br />
            <div class="flex flex-col w-full h-full">
                <div class="flex items-center justify-center w-full">
                    <label for="dropzonefile"
                        class="flex flex-col items-center justify-center w-full h-50 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click
                                    to upload</span> or drag and drop</p>
                            <p class="file-name mb-2 text-sm text-gray-500 dark:text-gray-400">{{fileName}}</p>
                        </div>
                        <input id="dropzonefile" #dropzonefile type="file" class="hidden" accept=".json"
                            (change)="uploadFn($event, 'uploadfile')" />
                    </label>
                </div>
                <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                        [matTooltip]="'Upload'" [disabled]="!uploadfile" (click)="uploadAssetData()">
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </div>
</s-modal>