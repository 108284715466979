import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from "../../../_services/base.service";
import { BytesConvertFilterPipe } from "../../../_filters/app.filter.pipe";
import { LoaderService } from "../../../_services/loader.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LoaderComponent } from '../loader/loader.component';
import { MatListModule } from "@angular/material/list";
import { Subject, takeUntil } from 'rxjs';
import {MaterialModule} from "../../../material.module";

@Component({
    selector: 'app-network-interfaces',
    standalone: true,
    imports: [CommonModule, AppFilterPipeModule, MatProgressBarModule, LoaderComponent, MatListModule, MaterialModule],
    templateUrl: './network-interfaces.component.html',
    styleUrls: ['./network-interfaces.component.scss']
})
export class NetworkInterfacesComponent implements OnInit, OnDestroy {
    @Input() asset: any;
    networkInterfaces: any;
    loadingCon: any = {
        loading: true,
        nodata: false
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private bs: BaseRequestService,
        private bytesConvert: BytesConvertFilterPipe,
        private ls: LoaderService, private toast: MyToastrService) {
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        this.getNetworkInterfaces();
    }

    getNetworkInterfaces(): void {
        if (!this.asset) {
            setTimeout(() => { this.getNetworkInterfaces(); }, 2000);
            return;
        }
        this.ls.display(true);
        this.loadingCon.loading = true;
        this.bs.doRequest(`/r/asset/asset_interface`, 'get', null,
            { condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`, skip: 0, limit: 1000, order_by: 'name asc' })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.networkInterfaces = res.data;
                        this.loadingCon.loading = null; this.loadingCon.nodata = null;
                    } else {
                        this.loadingCon.loading = null; this.loadingCon.nodata = true;
                    }
                } else {
                    const data = (res.message)? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }
}
