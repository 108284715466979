import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonService } from "../../../../_services/common.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { GridsComponent } from "../../../shared/grids/grids.component";
import { ModalComponent } from "../../../shared/modal.component";
import { MaterialModule } from "../../../../material.module";
import { ModalService } from "../../../../_services/modal.service";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FuseAlertComponent } from "../../../../../@fuse/components/alert";
import { DynamicFormComponent } from "../../../shared/forms/dynamic-form/dynamic-form.component";
import { LoaderService } from "../../../../_services/loader.service";
import { UserService } from "../../../../core/user/user.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ExpandTableComponent } from "../../../shared/expand-table/expand-table.component";
import { TableComponent } from "../../../shared/table/table.component";
import { TranslocoModule } from "@ngneat/transloco";
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from "app/_services/toastr.service";
import { InstallProbesAgentsComponent } from "app/modules/shared/install-probes-agents/install-probes-agents.component";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { DirectivesModule } from "app/-directives/-directives.module";
import { DynamicSettingsService } from "app/layout/common/dynamic-settings/dynamic-settings.service";
import { cloneDeep } from "lodash";
import { MatChipInputEvent, MatChipEditedEvent } from "@angular/material/chips";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";

@Component({
  selector: "agents",
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    AppFilterPipeModule,
    DirectivesModule,
    NgxMatSelectSearchModule,
    InstallProbesAgentsComponent,
    GridsComponent,
    ModalComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    DynamicFormComponent,
    ExpandTableComponent,
    TableComponent,
    FuseScrollbarDirective,
  ],
  templateUrl: "./agents.component.html",
  styleUrls: ["./agents.component.scss"],
})
export class AgentsComponent implements OnInit, OnDestroy {
  settingsdata: any = [];
  @Input() isGlobal: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  agent: any = {
    osType: "windows",
    command: "",
    companyId: "",
    tenantId: "",
  };
  aView: any = "agent";
  osCommands = {};
  agentTypes: any = [
    {
      value: "windows",
      label: "WINDOWS",
      details: "Install agent service in windows.",
      isIcon: true,
      icon: "fa-windows",
    },
    {
      value: "linux",
      label: "LINUX",
      details: "Install agent service in linux.",
      isIcon: true,
      icon: "fa-linux",
    },
    {
      value: "darwin",
      label: "Mac",
      details: "Install agent service in mac.",
      isIcon: true,
      icon: "fa-apple",
    },
  ];
  user: any;
  agentConf: any = {
    showAction: false,
    formElements: [
      {
        name: "osType",
        description: "OS Type",
        required: true,
        callback: true,
        schema: {
          type: "custom-radiogroup",
          radioList: this.agentTypes,
          span: 3,
        },
      },
      {
        name: "command",
        title: "Commandline Script",
        alertType: "basic",
        appearance: "soft",
        schema: {
          type: "copy-to-clipboard",
        },
      },
      {
        name: "companyId",
        title: "Company ID",
        alertType: "basic",
        appearance: "soft",
        schema: {
          type: "alert",
        },
      },
      {
        name: "tenantId",
        title: "Tenant ID",
        alertType: "basic",
        appearance: "soft",
        schema: {
          type: "alert",
        },
      },
    ],
    closeBtn: "Close",
    saveBtn: "Download",
  };
  private urlHash: any = {};
  private windowsUrl: any;
  private linuxUrl: any;
  private macUrl: any;
  loadtable: boolean = false;
  isBulkMapping = false;
  bulkIndex: any;
  agentdiscoverymap: any = {};
  agentcredentialsymap: any = {};
  agenTableOptions: any = {};
  _performance_count: any = {};
  reset_config: any = "";
  configTypes: any = [
    { key: "Reset AD Data", value: "reset_ad_data" },
    { key: "Update Agent", value: "update_agent" },
    { key: "Reset Agent", value: "reset_agent" },
  ];
  scan_types = [
    { field: "Full Scan", label: "FULLSCAN", type: "string" },
    { field: "Network Scan", label: "NETWORKSCAN", type: "string" },
    { field: "Active Directory Scan", label: "ADSCAN", type: "string" },
    { field: "Compliance Scan", label: "COMPLIANCE", type: "string" },
    { field: "Firewall Scan", label: "FIREWALL", type: "string" },
    { field: "Validate Credential", label: "VALIDATECREDS", type: "string" },
  ];
  light_scan_types = [
    { field: "Compliance Scan", label: "COMPLIANCE", type: "string" },
    { field: "LightWeight Agent Scan", label: "NETWORKSCAN", type: "string" },
  ];
  agentcred = {
    formElements: [
      {
        name: "credentials_id",
        label: "Credentials",
        description: "Credentials",
        example: "Select Credentials",
        required: true,
        callback: true,
        key: "credentials_id",
        type: "dropdown",
        options: [],
        dValue: "dValue",
        dKey: "dKey",
        multiple: true,
        schema: {
          type: "select",
        },
      },
    ],
    saveBtn: "Save",
    closeBtn: "Cancel",
    valueOutput: {
      name: "",
      address_type: "",
      address: "",
    },
  };
  scanassetoutput: any = {};
  scanasset = {
    formElements: [
      {
        name: "scan_type",
        label: "Scan Type",
        description: "Scan Type",
        example: "Select Scan Type",
        required: true,
        callback: true,
        key: "scan_type",
        type: "dropdown",
        dValue: "label",
        dKey: "field",
        multiple: false,
        options: this.scan_types,
      },
      {
        label: "Scan Profile",
        key: "scan_profile",
        name: "scan_profile",
        description: "",
        type: "dropdown",
        condition: "scan_type",
        condition_values: ["PII Scan"],
        multiple: false,
        example: "select Scan Profile",
        required: false,
        dValue: "displayName",
        dKey: "name",
        callback: "scan_profile",
        options: [],
      },
    ],
    saveBtn: "Scan",
    closeBtn: "Cancel",
    valueOutput: {
      name: "",
      address_type: "",
      address: "",
    },
  };
  agentdisco: any = {
    formElements: [
      {
        name: "discovery_settings_id",
        label: "Discovery Settings",
        description: "Discovery Settings",
        example: "Select Discovery Settings",
        callback: true,
        key: "discovery_settings_id",
        dValue: "dValue",
        dKey: "dKey",
        type: "dropdown",
        options: [],
        multiple: true,
        schema: {
          type: "select",
        },
      },
      {
        name: "credentials_id",
        label: "Credentials",
        description: "Credentials",
        example: "Select Credentials",
        callback: true,
        key: "credentials_id",
        type: "dropdown",
        options: [],
        dValue: "dValue",
        dKey: "dKey",
        multiple: true,
        schema: {
          type: "select",
        },
      },
    ],
    saveBtn: "Save",
    closeBtn: "Cancel",
    valueOutput: {
      name: "",
      address_type: "",
      address: "",
    },
  };
  currentData: any = {};
  private subs: Subscription;
  private action: Subscription;
  resetParam: any = {};
  selectedIndex = 0;
  selectedData: any = [];
  agentMigrationParam: any = {
    dest_company_id: "",
  };
  public companyFilterCtrl: FormControl = new FormControl();
  public searching = false;
  allComp: any = [];
  showPo = false;
  osqueryCommand: any = "";
  isRemoteInstall = false;
  dependencyStatus: any;
  scan_type_options: any = [];
  credential_list: any = [];
  it_admin: any = [
    "activedirectorywriter",
    "assetwriter",
    "compliancewriter",
    "piiwriter",
    "vulnerabilitywriter",
    "admin",
  ];
  constructor(
    public cs: CommonService,
    private _bs: BaseRequestService,
    public confirmDialog: FuseConfirmationService,
    private _changeDetectorRef: ChangeDetectorRef,
    public toast: MyToastrService,
    private _ds: DynamicSettingsService,
    private uS: UserService,
    public modalService: ModalService,
    private loaderService: LoaderService
  ) {
    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
      this.agent.companyId = this.cs.currentScope.id;
      this.aView = "agent";
      this._changeDetectorRef.markForCheck();
      this.initTable();
      this.remoteScanInstall();
    });
    this.action = this._ds.agentEmitter.subscribe((res: any) => {
      const tableName = this.agenTableOptions.tableOptions.title;
      if (res.target === "reset-configuration") {
        if (
          this.cs.selectedTableRows[tableName] &&
          this.cs.selectedTableRows[tableName].length
        ) {
          const lW = this.cs.selectedTableRows[tableName].filter(
            (x: any) => x.agent_type === "LIGHTWEIGHT"
          );
          if (!lW || !lW.length) {
            this.toast.sToast("error", "Please select Lightweight agent");
            return;
          }
          const reqParams = {
            company_id: this.cs.selectedTableRows[tableName][0].company_id,
            agent_ids: lW.map((l: any) => l.id),
            message: [],
          };
          // this.resetConfig(reqParams);
          this.modalService.open("resetagent");
        } else {
          this.toast.sToast("info", "Please select at least one agent.");
        }
      }
    });
    this.cs.newAgentCall.subscribe((trigger: any) => {
      this.aView = "download_agent";
      this._changeDetectorRef.markForCheck();
    });
    this.isGlobal = this.cs.currentScope === "*";
  }

  newAgentCall(): void {
    this.cs.newAgentCall.emit("");
  }

  ngOnInit(): void {
    this.initTable();
    this.remoteScanInstall();
    this.uS.userd$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: any) => {
        this.user = user;
        let s = Object.keys(user["urn:zitadel:iam:org:project:roles"]);
        let tenantid = Object.keys(
          user["urn:zitadel:iam:org:project:roles"][s[0]]
        )[0];
        this.agent.companyId = this.cs.currentScope.id;
        this.agent.tenantId = tenantid;
        this.agent.command = this.osCommands[this.agent.osType];
        this._changeDetectorRef.markForCheck();
      });
  }

  initTable() {
    const selectedIdx = this.selectedIndex;
    const tableOptions: any = {
      columns: [
        {
          header: "Status",
          columnDef: "online_status",
          filter: "",
          width: "w-[80px]",
          cType: "agg",
          cell: "(element: any) => `${element.online_status}`",
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          statusicon: true,
          success: true,
          failure: false,
          successIconPath: "/assets/images/on.png",
          successToolTip: "Online",
          failureIconPath: "/assets/images/off.png",
          failureToolTip: "Offline",
        },
        {
          header: "Host Name",
          columnDef: "host_name",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.host_name}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          clipboard: true,
        },
        {
          header: "IP",
          columnDef: "ip",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.ip}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Version",
          columnDef: "agent_version",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.version}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Agent Type",
          columnDef: "agent_type",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.agent_type}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          isCustomText: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "OS Name",
          columnDef: "os_name",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.os_name}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "OS Platform",
          columnDef: "os_platform",
          cType: "string",
          filter: "cameltohuman",
          cell: "(element: any) => ${element.os_platform}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "OS Version",
          columnDef: "os_version",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.os_version}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Kernel",
          columnDef: "kernel",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.kernel}",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Installed Date",
          columnDef: "created",
          cType: "date",
          filter: "utcToLocale",
          cell: "(element: any) => ${element.created}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true,
        },
        {
          header: "Last Ping Time",
          columnDef: "last_ping_time",
          cType: "date",
          filter: "utcToLocale",
          cell: "(element: any) => ${element.last_ping_time}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true,
        },
        {
          header: "Last Scanned Time",
          columnDef: "last_scanned_time",
          cType: "date",
          filter: "utcToLocale",
          cell: "(element: any) => ${element.last_scanned_time}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true,
        },
        {
          header: "Max Thread Count",
          columnDef: "max_thread_count",
          cType: "number",
          filter: "",
          cell: "(element: any) => ${element.max_thread_count}",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Deprecated Time",
          columnDef: "deprecated_time",
          cType: "date",
          filter: "utcToLocale",
          cell: "(element: any) => ${element.deprecated_time}",
          order: 0,
          visible: true,
          isNoVisible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          datePicker: true,
        },
        {
          header: "Is AD Audit Enabled",
          columnDef: "ad_audit_enabled",
          cType: "number",
          filter: "",
          cell: "(element: any) => `${element.ad_audit_enabled}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
          isCondition: true,
          operators: {
            condition: (field: any, value: any) => field && value,
            first: "ad_audit_enabled",
            second: "ad_controller",
          },
          operators2: {
            condition: (field: any, value: any, keys: any) =>
              !field &&
              value &&
              (keys == "Windows"
                ? true
                : keys === "Windows Desktop"
                  ? true
                  : false),
            first: "ad_audit_enabled",
            second: "ad_controller",
            third: "os_name",
          },
          // condition: [
          //     { key: 'os_name', value: ['Windows', 'Windows Desktop'] },
          //     { key: 'ad_controller', value: true }
          // ],
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
        },
        {
          header: "Company Name",
          columnDef: "company_name",
          cType: "string",
          filter: "",
          cell: "(element: any) => ${element.company_name}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Company ID",
          columnDef: "company_id",
          cType: "number",
          filter: "",
          cell: "(element: any) => ${element.company_id}",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: "host_name", direction: "asc" },
      _pageData: [],
      tableOptions: {
        title: "Probe Agents",
        isServerSide: false,
        selectText: "agents",
        loading: true,
        floatingFilter: true,
        isHideGlobal: true,
        rowSelection: true,
        showAction: true,
        isActionCondition: true,
        actionMenuItems: [
          {
            text: "Map Discovery & Credentials",
            icon: "map",
            callback: "editFunction",
            isGlobal:
              this.cs.currentScope === "*" && selectedIdx !== 2
                ? false
                : selectedIdx === 2
                  ? false
                  : true,
            actionConditions: { key: "online_status" },
            allowedCondition: ["true"],
            hideLocal: selectedIdx === 2 ? true : false,
          },
          {
            text: "Reset Agent Configuration",
            icon: "restart_alt",
            callback: "resetFunction",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
            isGlobal: true,
            hideLocal: false,
          },
          {
            text: "Dependency Status",
            icon: "analytics",
            callback: "statusFunction",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
            isGlobal: false,
            hideLocal: false,
          },
          {
            text: "Scan",
            icon: "scanner",
            callback: "editFunction",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
            isGlobal: true,
            hideLocal: false,
          },
          {
            text: "Uninstall",
            id: "uninstall",
            icon: "delete_forever",
            callback: "editFunction",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
            isGlobal: true,
            hideLocal: false,
          },
          {
            text: "Deprecate",
            icon: "repeat",
            callback: "deprecateFn",
            actionConditions: { key: "is_deprecated" },
            allowedCondition: ["false"],
            isGlobal: selectedIdx !== 2 ? true : false,
          },
          {
            text: "Restore",
            icon: "repeat",
            callback: "deprecateFn",
            actionConditions: { key: "is_deprecated" },
            allowedCondition: ["true"],
            isGlobal: selectedIdx === 2 ? true : false,
          },
          {
            text: "Activate AD Audit",
            icon: "play_circle_outline",
            callback: "startDebug",
            actionConditions: { key: "ad_controller" },
            allowedCondition: ["true"],
          },
          {
            text: "Deactivate AD Audit",
            icon: "motion_photos_paused",
            callback: "startDebug",
            actionConditions: { key: "ad_controller" },
            allowedCondition: ["true"],
          },
          {
            text: "Performance Management",
            icon: "settings_suggest",
            callback: "performanceManagement",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE"],
          },
          {
            text: "Start Debug",
            icon: "outlined_flag",
            callback: "startDebug",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
          {
            text: "End Debug",
            icon: "stop_circle",
            callback: "endDebug",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
          {
            text: "Retire",
            icon: "change_circle",
            callback: "retiredFunction",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
            isGlobal: false,
            hideLocal: false,
          },
          {
            text: "Fetch Logs",
            icon: "upload_file",
            callback: "fetchLogs",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
          {
            text: "Restart Agent",
            icon: "restart_alt",
            callback: "restartAgent",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
          {
            text: "Download Logs",
            icon: "download_for_offline",
            callback: "downloadLogs",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
          {
            text: "Agent Migration",
            icon: "find_replace",
            callback: "migrateLogs",
            isGlobal: true,
            hideLocal: true,
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add:
          this.cs.currentScope !== "*" &&
            this.uS._user_roles.some((id: any) => this.it_admin.includes(id))
            ? true
            : false,
        addText: "Agent",
        addIcon: "file_download",
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: "/r/company/agent_discovery_credentials",
          condition: `agent_type='PROBE' and is_deprecated=FALSE`,
          type: "get",
        },
        id: "agents",
      },
      customText: [
        {
          status: "PROBE",
          DisplayText: "Probe",
        },
        {
          status: "LIGHTWEIGHT",
          DisplayText: "LightWeight",
        },
        {
          range: { from: 0, to: 0 },
          color: "#c2ffc4",
          "text-color": "#000000",
        },
        {
          range: { from: 1, to: 50 },
          color: "#ffcb70",
          "text-color": "#000000",
        },
        {
          range: { from: 51, to: 10000 },
          color: "#ffb4a3",
          "text-color": "#000000",
        },
        {
          status: 25,
          DisplayText: "Low",
          color: "#ffff00",
          "text-color": "#000000",
        },
        {
          status: 50,
          DisplayText: "Medium",
          color: "#ff8c00",
        },
        {
          status: 75,
          DisplayText: "High",
          color: "#de4a50",
          "text-color": "#ffffff",
        },
        {
          status: 100,
          DisplayText: "Critical",
          color: "#92243e",
          "text-color": "#ffffff",
        },
        {
          status: true,
          DisplayText: "Yes",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: false,
          DisplayText: "No",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
      ],
      sColumns: [
        {
          header: "Status",
          columnDef: "online_status",
          cType: "string",
          isDropdown: true,
          gloNoSearch: true,
          dataSource: [
            { text: "Online", value: "true" },
            { text: "Offline", value: "false" },
          ],
        },
      ],
      changeValue: new Subject<any>(),
    };
    this.agenTableOptions = {};
    this._changeDetectorRef.detectChanges();
    tableOptions.pageData = [];
    tableOptions.tableOptions.pageTotal = 0;
    this.selectedIndex = null;
    if (
      this._bs.user() &&
      this._bs.user().email === "support@connectsecure.com"
    ) {
      const runCmd = tableOptions.tableOptions.actionMenuItems.filter(
        (x: any) => x.text === "Run Command" || x.text === "Clear Pending Jobs"
      );
      if (!runCmd || !runCmd.length) {
        tableOptions.tableOptions.actionMenuItems.push(
          {
            text: "Clear Pending Jobs",
            icon: "clear_all",
            callback: "clearPendingJobs",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          },
          {
            text: "Run Command",
            icon: "terminal",
            callback: "resetFunction",
            actionConditions: { key: "agent_type" },
            allowedCondition: ["PROBE", "LIGHTWEIGHT"],
          }
        );
      }
    }

    const name =
      this.cs.currentScope === "*"
        ? "agent_discovery_credentials_global"
        : "agent_discovery_credentials";
    if (this.cs.currentScope !== "*") {
      const isMigrate = tableOptions.tableOptions.actionMenuItems.filter(
        (x: any) => x.text !== "Agent Migration"
      );
      if (!isMigrate || !isMigrate.length) {
        tableOptions.tableOptions.actionMenuItems.push({
          text: "Agent Migration",
          icon: "find_replace",
          callback: "migrateLogs",
          isGlobal: true,
          hideLocal: true,
          actionConditions: { key: "agent_type" },
          allowedCondition: ["PROBE", "LIGHTWEIGHT"],
        });
      }
    } else {
      tableOptions.tableOptions.actionMenuItems =
        tableOptions.tableOptions.actionMenuItems.filter(
          (x: any) => x.text !== "Agent Migration"
        );
    }
    tableOptions.tableOptions.add =
      this.cs.currentScope !== "*" &&
        this.uS._user_roles.some((id: any) => this.it_admin.includes(id))
        ? true
        : false;
    tableOptions.columns.map((x: any) => {
      if (x.columnDef === "deprecated_time") {
        x.isNoVisible = true;
      }
    });
    if (selectedIdx === 1) {
      tableOptions.title = "Probe Agents";
      tableOptions.pageData = [];
      tableOptions.tableOptions.pageTotal = 0;
      tableOptions.tableOptions.add = false;
      tableOptions.tableOptions.serverSide = {
        url: "/r/company/agent_discovery_credentials",
        condition: `agent_type='PROBE' and is_deprecated=FALSE and is_retired=FALSE`,
        type: "get",
      };

      this.selectedIndex = selectedIdx;
      this._changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.agenTableOptions = tableOptions;
        this._changeDetectorRef.detectChanges();
      });
    } else if (selectedIdx === 0) {
      tableOptions.tableOptions.title = "LightWeight Agents";
      tableOptions.pageData = [];
      tableOptions.tableOptions.pageTotal = 0;
      tableOptions.tableOptions.serverSide = {
        url: "/r/company/agent_discovery_credentials",
        condition: `agent_type='LIGHTWEIGHT' and is_deprecated=FALSE and is_retired=FALSE`,
        type: "get",
      };
      this.selectedIndex = selectedIdx;
      this._changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.agenTableOptions = tableOptions;
        this._changeDetectorRef.detectChanges();
      });
    } else if (selectedIdx === 2) {
      tableOptions.columns.map((x: any) => {
        delete x.isNoVisible;
      });
      tableOptions.tableOptions.title = "Deprecated Agents";
      tableOptions.pageData = [];
      tableOptions.tableOptions.pageTotal = 0;
      tableOptions.tableOptions.add = false;
      tableOptions.tableOptions.serverSide = {
        url: "/r/company/agent_discovery_credentials",
        condition: `is_deprecated=TRUE and is_retired=FALSE`,
        type: "get",
      };
      this.selectedIndex = selectedIdx;
      this._changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.agenTableOptions = tableOptions;
        this._changeDetectorRef.detectChanges();
      });
    } else if (selectedIdx === 3) {
      tableOptions.tableOptions.title = 'Retired Agents';
      tableOptions.pageData = [];
      tableOptions.tableOptions.pageTotal = 0;
      tableOptions.tableOptions.add = false;
      tableOptions.tableOptions.serverSide = {
        url: '/r/company/agent_discovery_credentials',
        condition: `is_retired=TRUE`,
        type: 'get',
      };
      this.selectedIndex = selectedIdx; this._changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.agenTableOptions = tableOptions; this._changeDetectorRef.detectChanges();
      }
      );
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  updateCode($event: any) { }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
  }

  actioncallback(data: any): void {
    this.currentData = data.row;
    this.isBulkMapping = false;
    this.selectedData = [];
    if (data.action.text == "Map Discovery Settings") {
      this.getsettings(data.row);
    } else if (data.action.text == "Reset Agent Configuration") {
      this.resetParam = {
        company_id: data.row.company_id,
        agent_ids: [data.row.id],
      };
      this.modalService.open("resetagent");
    } else if (data.action.text === 'Retire') {
      const titleName = "Retire Agent";
      const message = `Agents retired from Lightweight/Probe section will be uninstalled and moved to Retired Agents. However all the asset data associated with the Retired agent will be retained. Retired agents can be completely deleted by uninstalling them from Retired section.`;
      const cancelText = "No";
      const acceptText = "Yes";
      const confirmation = this.confirmDialog.open({
        title: titleName,
        message: message,
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: acceptText, color: "primary" },
          cancel: { show: true, label: cancelText },
        },
        dismissible: false,
      });
      confirmation.afterClosed().subscribe(result => {
        if (result === 'confirmed') {
          const reqData = {
            data: {
              is_retired: true
            },
            id: data.row.id
          };
          this._bs.doRequest('/w/company/agents', 'Patch', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this.loaderService.display(false);

              if (result.status) {
                this.toast.sToast('success', 'Agent has been retired successfully');
              } else {
                const errorMessage = result.message ?? result.data;
                this.toast.sToast('error', errorMessage);
              }

              this._changeDetectorRef.detectChanges();
            });
        }
      });
    } else if (data.action.text === "Dependency Status") {
      this.dependencyStatus = data.row.dependency_status;
      if (this.dependencyStatus) {
        this.modalService.open("dependencyStatus");
      } else {
        this.toast.sToast(
          "error",
          "Not Available for the Selected Agent",
          "Dependency Status"
        );
        // this.modalService.open('noDependencyStatus');
      }
    } else if (data.action.text == "Map Discovery & Credentials") {
      this.getCredentials(data.row);
      this.getsettings(data.row);
    } else if (data.action.text == "Scan") {
      this.getScanProfiles(data.row);
    } else if (data.action.text == "Uninstall") {
      this.deleteAgent(data.row);
    } else if (data.action.text == "Deprecate") {
      this.deprecateRestoreAgent([data.row], true);
    } else if (data.action.text == "Restore") {
      this.deprecateRestoreAgent([data.row], false);
    } else if (data.action.text == "Start Debug") {
      this.agentDebug(data.row, "start_debug");
    } else if (data.action.text == "Run Command") {
      this.agentRunCommand(data.row, "run_command");
    } else if (data.action.text == "End Debug") {
      this.agentDebug(data.row, "end_debug");
    } else if (data.action.text == "Activate AD Audit") {
      this.agentDebug(data.row, "activate_ad_audit");
    } else if (data.action.text == "Deactivate AD Audit") {
      this.agentDebug(data.row, "deactivate_ad_audit");
    } else if (data.action.text == "Activate AD") {
      this.agentDebug(data.row, "activate_ad");
    } else if (data.action.text == "Deactivate AD") {
      this.agentDebug(data.row, "deactivate_ad");
    } else if (data.action.text == "Download Logs") {
      this.agentDebug(data.row, "download_logs");
    } else if (data.action.text == "Fetch Logs") {
      this.agentDebug(data.row, "fetch_logs");
    } else if (data.action.text == "Clear Pending Jobs") {
      this.agentDebug(data.row, "clear_pending_jobs");
    } else if (data.action.text == "Restart Agent") {
      this.agentDebug(data.row, "restart_agent");
    } else if (data.action.text == "Performance Management") {
      this._performance_count = {
        nmap: data.row.probe_setting.nmap,
        smb: data.row.probe_setting.smb
      }
      this.modalService.open('agentPerformanceManagement')
    }
  }

  agentRunCommand(agent: any, action: any): void {
    this.modalService.open("runCommand");
  }

  runCommand(): void {
    if (
      !this.osqueryCommand.length ||
      this.osqueryCommand.indexOf("rm ") > -1 ||
      this.osqueryCommand.indexOf("delete") > -1
    ) {
      this.toast.sToast("error", "Command not allowed!");
      return;
    }
    const titleName = "Run Osquery Command";
    const message = `Would you like to run osquery command for the chosen Agent?`;
    const cancelText = "No";
    const acceptText = "Yes";
    const confirmation = this.confirmDialog.open({
      title: titleName,
      message: message,
      icon: { show: false, name: "", color: "primary" },
      actions: {
        confirm: { show: true, label: acceptText, color: "primary" },
        cancel: { show: true, label: cancelText },
      },
      dismissible: false,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const reqData: any = {
          company_id: this.currentData.company_id,
          agent_ids: [this.currentData.id],
          message: "get_osquery",
          os_query: this.osqueryCommand,
        };
        this.loaderService.display(true);
        this._bs
          .doRequest("/w/company/reset_agents", "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast("success", result.message);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
            this._changeDetectorRef.detectChanges();
          });
      }
    });
  }

  agentDebug(agent: any, action: any): void {
    const agentAction = {
      start_debug: "Start Debugging Process",
      end_debug: "End Debugging Process",
      fetch_logs: "Fetch Logs",
      clear_pending_jobs: "Clear Pending Jobs",
      download_logs: "Download Logs",
      restart_agent: "Restart Agent",
      activate_ad_audit: "Activate AD Audit",
      deactivate_ad_audit: "Deactivate AD Audit",
      activate_ad: "Activate AD",
      deactivate_ad: "Deactivate AD",
    };
    const titleName = `${agentAction[action]}:`;
    const message = `Would you like to ${agentAction[action]} for the chosen Agent?`;
    const cancelText = "No";
    const acceptText = "Yes";
    const confirmation = this.confirmDialog.open({
      title: titleName,
      message: message,
      icon: { show: false, name: "", color: "primary" },
      actions: {
        confirm: { show: true, label: acceptText, color: "primary" },
        cancel: { show: true, label: cancelText },
      },
      dismissible: false,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        if (action === "download_logs") {
          const reqData: any = {
            company_id: agent.company_id,
            agent_id: agent.id,
            region: "use",
          };
          this.loaderService.display(true);
          this._bs
            .doRequest("/w/company/download_agent_logs", "post", reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this.loaderService.display(false);
              if (result.status) {
                window.open(result.message);
              } else {
                const data = result.message ? result.message : result.data;
                this.toast.sToast("error", data);
              }
              this._changeDetectorRef.detectChanges();
            });
        } else {
          const reqData: any = {
            company_id: agent.company_id,
            agent_ids: [agent.id],
            message: action,
          };
          this.loaderService.display(true);
          this._bs
            .doRequest("/w/company/reset_agents", "post", reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this.loaderService.display(false);
              if (result.status) {
                this.toast.sToast("success", result.message);
                if (
                  action === "activate_ad_audit" ||
                  action === "deactivate_ad_audit"
                ) {
                  const req = {
                    id: agent.id,
                    data: {
                      ad_audit_enabled:
                        action === "activate_ad_audit" ? true : false,
                    },
                  };
                  this._bs
                    .doRequest(`/w/company/agents`, "patch", req)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res: any) => {
                      console.log(res);
                    });
                  setTimeout(() => this.cs.selectedTagChange.next({}), 1000);
                }
              } else {
                const data = result.message ? result.message : result.data;
                this.toast.sToast("error", data);
              }
              this._changeDetectorRef.detectChanges();
            });
        }
      }
    });
  }

  deprecateRestoreAgent(idata: any, is_deprecated: any) {
    this.cs.reason = "";
    const titleName = !is_deprecated ? "Restore" : "Deprecate";
    const message =
      idata.length === 1 && idata[0].online_status
        ? `Are you sure that you need to ${titleName} an Online Agent(s)?`
        : `Do you want to ${titleName} the selected Agent(s)?`;
    const cancelText = "No";
    const acceptText = "Yes";
    const confirmation = this.confirmDialog.open({
      title: titleName,
      message: message,
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: acceptText, color: "primary" },
        cancel: { show: true, label: cancelText },
      },
      dismissible: false,
      isReason: true,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const params: any = {
          agent_ids: idata.map((x: any) => x.id),
          is_deprecated: is_deprecated,
          reason: this.cs.reason,
        };
        this.loaderService.display(true);
        this._bs
          .doRequest("/w/company/bulk_deprecate", "post", params)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast(
                "success",
                !is_deprecated
                  ? "Agent Restored Successfully."
                  : "Agent Deprecated Successfully."
              );
              setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }

  bulkresetConfig(): void {
    this.modalService.close("bulkresetagent");
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: `Are you sure you want to reset these configurations to the selected agent ?`,

      icon: {
        show: false,
        name: "",
        color: "success",
      },
      actions: {
        confirm: {
          show: true,
          label: "Yes",
          color: "primary",
        },
        cancel: {
          show: true,
          label: "No",
        },
      },
      dismissible: true,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const payload = {
          bulk_update: [],
          message: this.reset_config,
        };
        this.selectedData.forEach((data) => {
          const bulkUpdateItem = {
            company_id: data.company_id,
            agent_ids: [data.id],
          };
          payload.bulk_update.push(bulkUpdateItem);
        });
        this.loaderService.display(true);
        this._bs
          .doRequest("/w/company/reset_agents", "post", payload)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast("success", result.message);
              this.reset_config = "";
              setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }

  resetConfig(): void {
    this.modalService.close("resetagent");
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: `Are you sure you want to reset these configurations to the selected agent ?`,

      icon: {
        show: false,
        name: "",
        color: "success",
      },
      actions: {
        confirm: {
          show: true,
          label: "Yes",
          color: "primary",
        },
        cancel: {
          show: true,
          label: "No",
        },
      },
      dismissible: true,
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        const params: any = this.resetParam;
        params.message = this.reset_config;
        this.loaderService.display(true);
        this._bs
          .doRequest("/w/company/reset_agents", "post", params)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast("success", result.message);
              this.reset_config = "";
              setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }

  deleteAgent($event: any): void {
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: `Are you sure you want to uninstall the selected agent?`,
      actions: {
        confirm: {
          label: "Uninstall",
        },
      },
    });
    confirmation.afterClosed().subscribe((result) => {
      const reqData: any = {
        agent_ids: [$event.id],
        company_id: parseInt($event.company_id),
        message: "uninstallservice"
      };
      if (result === "confirmed") {
        this.loaderService.display(true);
        this._bs
          .doRequest("/w/company/reset_agents", "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast("success", "Agent Uninstall Initiated");
              setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    });
  }

  getScanProfiles(data: any) {
    this.scanassetoutput = {
      scan_type: "",
      scan_profile: "",
    };
    if (data.agent_type === "LIGHTWEIGHT") {
      this.scanassetoutput.scan_type = "COMPLIANCE";
      this.scan_type_options = this.light_scan_types;
    } else {
      this.scan_type_options = this.scan_types;
    }
    this.modalService.open("scanagents");
  }

  scanadd() {
    const event: any = cloneDeep(this.scanassetoutput);
    if (event.scan_type === "REMOTEINSTALL") {
      if (!this.isRemoteInstall) {
        this.toast.sToast(
          "info",
          "Remote Agent Install configuration is not found for this company. To enable, navigate to Company Settings and enable Remote Install agent."
        );
        return;
      }
      const confirmation = this.confirmDialog.open({
        title: "Confirmation",
        message:
          "Please confirm, if you want to Remotely Install Lightweight agents on all the discovered assets.",
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: "Yes", color: "primary" },
          cancel: { show: true, label: "No" },
        },
        dismissible: false,
      });
      confirmation.afterClosed().subscribe((result) => {
        if (result === "confirmed") {
          const reqData: any = {
            data: {},
          };
          reqData.data.agent_id = this.currentData.id;
          reqData.data.company_id = this.currentData.company_id;
          reqData.data.scan_type = event.scan_type;
          this.loaderService.display(true);
          this._bs
            .doRequest("/w/company/scan", "post", reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this.loaderService.display(false);
              if (result.status) {
                this.modalService.close("scanagents");
                this.toast.sToast("success", result.data);
              } else {
                const data = result.message ? result.message : result.data;
                this.toast.sToast("error", data);
              }
              this._changeDetectorRef.detectChanges();
            });
        }
      });
    } else {
      const reqData: any = {
        data: {},
      };
      reqData.data.agent_id = this.currentData.id;
      reqData.data.company_id = this.currentData.company_id;
      reqData.data.scan_type = event.scan_type;
      this.loaderService.display(true);
      this._bs
        .doRequest("/w/company/scan", "post", reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result.status) {
            this.modalService.close("scanagents");
            this.toast.sToast("success", result.data);
          } else {
            const data = result.message ? result.message : result.data;
            this.toast.sToast("error", data);
          }
          this._changeDetectorRef.detectChanges();
        });
    }
  }

  getCredentials(data: any) {
    this.settingsdata = [];
    this.loaderService.display(
      true,
      `Getting credential for company id ${data.company_id}`
    );
    this._bs
      .doRequest("/r/company/credentials", "get", null, {
        condition: `company_id=${parseInt(
          data.company_id
        )} and asset_id is null`,
        skip: 0,
        limit: 1000,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.settingsdata = result.data;
        var listdisc = [];
        result.data.forEach((ele: any) => {
          var temp = { dKey: ele.name, dValue: ele.id };
          listdisc.push(temp);
        });
        if (
          data.credentials_ids &&
          data.credentials_ids.length &&
          data.credentials_ids[0] !== null
        ) {
          this.agentdiscoverymap.credentials_id = data.credentials_ids;
        } else {
          this.agentdiscoverymap.credentials_id = [];
        }
        this.agentdisco.formElements[1].options = listdisc;
        this._changeDetectorRef.detectChanges();
      });
  }

  getsettings(data: any) {
    this.showPo = false;
    this.settingsdata = [];
    this.agentdiscoverymap.nmap_interface_id = data.nmap_interface
      ? data.nmap_interface
      : "";
    this.agentdisco.formElements = this.agentdisco.formElements.filter(
      (x: any) => x.name !== "nmap_interface_id"
    );
    this.loaderService.display(
      true,
      `Getting discovery setting for company id ${data.company_id}`
    );
    const skip = 0;
    const limit = 1;
    this._bs
      .doRequest("/r/company/discovery_settings", "get", null, {
        condition: `discovery_settings_type='assetscan' and company_id=${parseInt(
          data.company_id
        )}`,
        skip: 0,
        limit: 1000,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.settingsdata = result.data;
        var listdisc = [];
        result.data.forEach((ele) => {
          var temp = { dKey: `${ele.name} [${ele.address}]`, dValue: ele.id };
          listdisc.push(temp);
        });
        if (!this.isBulkMapping && data.agent_type !== "LIGHTWEIGHT") {
          var listinterface = [{ dKey: `None`, dValue: "" }];
          this.currentData.interfaces.forEach((ele: any) => {
            var temp = { dKey: `${ele.name} - ${ele.ip}`, dValue: ele.name };
            listinterface.push(temp);
          });
          this.agentdisco.formElements[2] = {
            name: "nmap_interface_id",
            label: "NMAP Interface",
            description: "NMAP Interface",
            example: "NMAP Interface",
            required: false,
            callback: true,
            key: "nmap_interface_id",
            dKey: "dKey",
            dValue: "dValue",
            type: "dropdown",
            options: listinterface,
            multiple: false,
            schema: {
              type: "select",
            },
          };
        } else if (this.isBulkMapping && this.agentdisco.formElements[2]) {
          this.agentdisco.formElements = this.agentdisco.formElements.filter(
            (x: any) => x.name !== "nmap_interface_id"
          );
        }
        this.agentdisco.formElements[0].options = listdisc;
        if (
          data.discovery_settings_ids &&
          data.discovery_settings_ids.length &&
          data.discovery_settings_ids[0] !== null
        ) {
          this.agentdiscoverymap.discovery_settings_id =
            data.discovery_settings_ids;
        } else {
          this.agentdiscoverymap.discovery_settings_id = [];
        }
        this.showPo = true;
        this.modalService.open("mapdiscoveryandcreds");
        this._changeDetectorRef.detectChanges();
      });
  }

  agentcredsave(data: any): void {
    const reqData: any = {
      data: {},
    };
    reqData.data.agent_id = this.currentData.id;
    reqData.data.company_id = this.currentData.company_id;
    reqData.data.mapping_ids = data.credentials_id;
    reqData.data.setting_type = "credentials";
    this.loaderService.display(true);
    this._bs
      .doRequest("/w/company/creddiscoverymapping", "post", reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          this.toast.sToast("success", "Mapped successfully.");
          this.initTable();
        } else {
          const data = res.message ? res.message : res.data;
          this.toast.sToast("error", data);
        }
      });
  }

  agentdiscosave(data: any): void {
    const reqData: any = {
      data: {},
    };
    reqData.data.agent_id = this.currentData.id;
    reqData.data.company_id = this.currentData.company_id;
    reqData.data.mapping_ids = data.discovery_settings_id;
    reqData.data.setting_type = "discovery_settings";
    this.loaderService.display(true);
    this._bs
      .doRequest("/w/company/creddiscoverymapping", "post", reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          this.toast.sToast("success", "Mapped successfully.");
          this.initTable();
        }
      });
  }

  closeCallBack($event: any): void {
    this.aView = "agent";
  }

  agentDisCredSave(): void {
    if (!this.isBulkMapping) {
      this._bs
        .doRequest(`/w/company/agents`, "patch", {
          id: this.currentData.id,
          data: { nmap_interface: this.agentdiscoverymap.nmap_interface_id },
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          console.log(res);
        });
    }
    if (
      (!this.agentdiscoverymap.discovery_settings_id ||
        !this.agentdiscoverymap.discovery_settings_id.length) &&
      (!this.agentdiscoverymap.credentials_id ||
        !this.agentdiscoverymap.credentials_id.length)
    ) {
      const confirmation = this.confirmDialog.open({
        title: "Confirmation",
        message:
          "Please Acknowledge that saving the Agent Discovery Settings and Credentials without any selections will result in a conversion of the agent type to LightWeight. Additionally, all Assets discovered by this Agent will be removed from the portal. Kindly confirm to proceed further.",
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: "Confirm", color: "primary" },
          cancel: { show: true, label: "Cancel" },
        },
        dismissible: false,
      });
      confirmation.afterClosed().subscribe((result) => {
        if (result === "confirmed") {
          this.processAgentDisCredSave();
        }
      });
    } else {
      this.processAgentDisCredSave();
    }
  }

  processAgentDisCredSave(): void {
    this._changeDetectorRef.detectChanges();
    if (this.isBulkMapping && this.selectedData && this.selectedData.length) {
      // this.bulkIndex = 0;
      // this.selectedData.forEach((cData: any) => {
      //     this.bulkIndex++;
      //     if(cData.online_status){
      //         this.agentDisCredSaveCall(cData);
      //     }
      // });
      const reqData = this.selectedData.filter(
        (cData: any) => cData.online_status
      );
      this.agentBulkDisCredSaveCall(reqData);
    } else {
      this.agentDisCredSaveCall();
    }
  }
  validateCred(ids: any, agent_id: any, company_id: any): void {
    const old = this.currentData.credentials_ids.filter((x: any) => x !== null);
    const newlyAddedValues = cloneDeep(
      ids.filter((value: any) => !old.includes(value))
    );
    if (newlyAddedValues && newlyAddedValues.length) {
      const reqData: any = {
        data: {
          agent_id: agent_id,
          company_id: company_id,
          scan_type: "VALIDATECREDS",
        },
      };
      this._bs
        .doRequest("/w/company/scan", "post", reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => { });
    }
  }
  async agentDisCredSaveCall(cData?: any): Promise<void> {
    console.log(this.agentdiscoverymap.nmap_interface_id);
    this.currentData = cData ? cData : this.currentData;
    const reqData: any = {
      data: {
        agent_id: this.currentData.id,
        company_id: this.currentData.company_id,
        mapping_ids: this.agentdiscoverymap.discovery_settings_id,
        setting_type: "discovery_settings",
      },
    };
    this.loaderService.display(true, "Saving discovery settings...");
    await this._bs
      .doRequest("/w/company/creddiscoverymapping", "post", reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          const reqData: any = {
            data: {
              agent_id: this.currentData.id,
              company_id: this.currentData.company_id,
              mapping_ids: this.agentdiscoverymap.credentials_id,
              setting_type: "credentials",
            },
          };
          // this.validateCred(this.agentdiscoverymap.credentials_id, this.currentData.id, this.currentData.company_id);
          this.loaderService.display(false);
          this.loaderService.display(true, "Updating credentials...");
          this._bs
            .doRequest("/w/company/creddiscoverymapping", "post", reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
              this.loaderService.display(false);
              if (res.status) {
                this.toast.sToast("success", "Mapping completed successfully!");
                if (
                  (this.isBulkMapping &&
                    this.bulkIndex === this.selectedData.length) ||
                  !this.isBulkMapping
                ) {
                  this.modalService.close("mapdiscoveryandcreds");
                  this.showPo = false;
                  setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
                  this.bulkIndex = 0;
                }
              } else {
                const data = res.message ? res.message : res.data;
                this.toast.sToast("error", data);
              }
            });
        } else {
          const data = res.message ? res.message : res.data;
          this.toast.sToast("error", data);
        }
      });
  }

  async agentBulkDisCredSaveCall(cData?: any): Promise<void> {
    const reqData: any = {
      data: [],
    };
    cData.map((item: any) => {
      reqData.data.push({
        agent_id: item.id,
        company_id: item.company_id,
        mapping_ids: this.agentdiscoverymap.discovery_settings_id,
        setting_type: "discovery_settings",
      });
      reqData.data.push({
        agent_id: item.id,
        company_id: item.company_id,
        mapping_ids: this.agentdiscoverymap.credentials_id,
        setting_type: "credentials",
      });
    });

    this.loaderService.display(true, "Saving settings...");
    await this._bs
      .doRequest("/w/company/creddiscoverymappingbulk", "post", reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status) {
          this.toast.sToast("success", "Mapping completed successfully!");
          this.modalService.close("mapdiscoveryandcreds");
          this.showPo = false;
          setTimeout(() => this.cs.selectedTagChange.next({}), 1000);
          // cData.map((item: any) => {
          //     this.validateCred(this.agentdiscoverymap.credentials_id, item.id, this.currentData.company_id);
          // })
        } else {
          const data = res.message ? res.message : res.data;
          this.toast.sToast("error", data);
        }
      });
  }
  tabFn($event: any): void {
    this.selectedIndex = null;
    this.agenTableOptions.tableOptions.add =
      this.cs.currentScope !== "*" &&
        this.uS._user_roles.some((id: any) => this.it_admin.includes(id))
        ? true
        : false;
    this.agenTableOptions.columns.map((x: any) => {
      if (x.columnDef === 'deprecated_time') {
        x.isNoVisible = true;
      }
    })
    this.agenTableOptions.tableOptions.actionMenuItems = this.agenTableOptions.tableOptions.actionMenuItems.map((x: any) => {
      const actions = [
        'Map Discovery & Credentials',
        'Reset Agent Configuration',
        'Restore',
        'Scan',
        'Deprecate',
        'Deprecate',
        'Dependency Status',
        'Restore Scan',
        'Activate AD Audit',
        'Deactivate AD Audit',
        'Start Debug',
        'End Debug',
        'Retire',
        'Uninstall',
        'Fetch Logs',
        'Restart Agent',
        'Download Logs',
        'Agent Migration'
      ];
      if (actions.includes(x.text)) {
        x.hideLocal = false;
      }
      return x;
    });

    const name =
      this.cs.currentScope === "*"
        ? "agent_discovery_credentials_global"
        : "agent_discovery_credentials";
    this.agenTableOptions.tableOptions.actionMenuItems.map((x: any) => {
      if (x.text === "Deprecate") {
        x.isGlobal = true;
      }
      if (x.text === "Restore") {
        x.isGlobal = false;
      }
    });
    if (this.cs.currentScope !== "*") {
      const isMigrate =
        this.agenTableOptions.tableOptions.actionMenuItems.filter(
          (x: any) => x.text !== "Agent Migration"
        );
      if (!isMigrate || !isMigrate.length) {
        this.agenTableOptions.tableOptions.actionMenuItems.push({
          text: "Agent Migration",
          icon: "find_replace",
          callback: "migrateLogs",
          isGlobal: true,
          hideLocal: true,
          actionConditions: { key: "agent_type" },
          allowedCondition: ["PROBE", "LIGHTWEIGHT"],
        });
      }
    } else {
      this.agenTableOptions.tableOptions.actionMenuItems =
        this.agenTableOptions.tableOptions.actionMenuItems.filter(
          (x: any) => x.text !== "Agent Migration"
        );
    }
    if ($event === 1) {
      this.agenTableOptions.tableOptions.title = "Probe Agents";
      this.agenTableOptions.pageData = [];
      this.agenTableOptions.tableOptions.pageTotal = 0;
      this.agenTableOptions.tableOptions.add = false;
      this.agenTableOptions.tableOptions.serverSide = {
        url: "/r/company/agent_discovery_credentials",
        condition: `agent_type='PROBE' and is_deprecated=FALSE and is_retired=FALSE`,
        type: "get",
      };
      this.selectedIndex = $event;
    }
    if ($event === 0) {
      this.agenTableOptions.tableOptions.title = "LightWeight Agents";
      this.agenTableOptions.pageData = [];
      this.agenTableOptions.tableOptions.pageTotal = 0;
      this.agenTableOptions.tableOptions.serverSide = {
        url: "/r/company/agent_discovery_credentials",
        condition: `agent_type='LIGHTWEIGHT' and is_deprecated=FALSE and is_retired=FALSE`,
        type: "get",
      };
      this.selectedIndex = $event;
    }
    if ($event === 2) {
      this.agenTableOptions.columns.map((x: any) => {
        delete x.isNoVisible;
      });
      this.agenTableOptions.tableOptions.actionMenuItems.map((x: any) => {
        if (x.text === "Deprecate") {
          x.isGlobal = false;
        }
        if (x.text === "Restore" || x.text === "Delete") {
          x.isGlobal = true;
        }
      });
      this.agenTableOptions.tableOptions.title = "Deprecated Agents";
      this.agenTableOptions.pageData = [];
      this.agenTableOptions.tableOptions.pageTotal = 0;
      this.agenTableOptions.tableOptions.add = false;
      this.agenTableOptions.tableOptions.serverSide = {
        url: "/r/company/agent_discovery_credentials",
        condition: `is_deprecated=TRUE and is_retired=FALSE`,
        type: "get",
      };
      this.selectedIndex = $event;
    }
    if ($event === 3) {
      this.agenTableOptions.tableOptions.actionMenuItems = this.agenTableOptions.tableOptions.actionMenuItems.map((x: any) => {
        const actions = [
          'Reset Agent Configuration',
          'Scan',
          'Deprecate',
          'Dependency Status',
          'Restore Scan',
          'Activate AD Audit',
          'Deactivate AD Audit',
          'Start Debug',
          'End Debug',
          'Retire',
          'Fetch Logs',
          'Restart Agent',
          'Download Logs',
          'Agent Migration'
        ];

        if (actions.includes(x.text)) {
          x.hideLocal = true;
        }
        return x;
      });
      this.agenTableOptions.tableOptions.title = 'Retired Agents';
      this.agenTableOptions.pageData = [];
      this.agenTableOptions.tableOptions.pageTotal = 0;
      this.agenTableOptions.tableOptions.add = false;
      this.agenTableOptions.tableOptions.serverSide = {
        url: '/r/company/agent_discovery_credentials',
        condition: `is_retired=TRUE`,
        type: 'get',
      };
      this.selectedIndex = $event;
    }

  }

  globalActionCall(data: any): void {
    if (data.action.text === "Uninstall") {
      const confirmation = this.confirmDialog.open({
        title: "Confirmation",
        message: `Are you sure you want to uninstall the selected agent?`,
        actions: {
          confirm: {
            label: "Uninstall",
          },
        },
      });
      confirmation.afterClosed().subscribe((result) => {
        if (result === "confirmed") {
          this.loaderService.display(true);
          data.row.forEach((obj: any, index: number) => {
            const reqData: any = {
              agent_ids: [obj.id],
              company_id: parseInt(obj.company_id),
              message: "uninstallservice"
            };
            this._bs
              .doRequest("/w/company/reset_agents", "post", reqData)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((result: any) => {
                if (index === data.row.length - 1) {
                  this.loaderService.display(false);
                  if (result.status) {
                    this.toast.sToast("success", "Agent Uninstall Initiated");
                    setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
                  } else {
                    const data = result.message ? result.message : result.data;
                    this.toast.sToast("error", data);
                  }
                }
              });
          });
        }
      });
    } else if (data.action.text == "Map Discovery & Credentials") {
      const agent_ids = data.row
        .filter((i: any) => i.online_status)
        .map((x: any) => x.id);
      if (agent_ids.length === 0) {
        this.toast.sToast("error", "Please select only online agents.");
        return;
      }
      this.isBulkMapping = true;
      this.selectedData = data.row;
      this.getCredentials(data.row[0]);
      this.getsettings(data.row[0]);
    } else if (data.action.text === "Scan") {
      this.selectedData = data.row;
      this.scanassetoutput = {
        scan_type: "",
        scan_profile: "",
      };
      if (this.selectedData[0].agent_type === "LIGHTWEIGHT") {
        this.scanasset.formElements[0].options = this.light_scan_types;
      } else {
        this.scanasset.formElements[0].options = this.scan_types;
      }
      this.modalService.open("scanagentsMul");
    } else if (data.action.text === "Agent Migration") {
      const agent_ids = data.row
        .filter((i: any) => i.online_status)
        .map((x: any) => x.id);
      if (agent_ids.length === 0) {
        this.toast.sToast("error", "Please select only online agents.");
        return;
      }
      this.agentMigrationParam = {
        company_id: parseInt(this.cs.currentScope.id),
        dest_company_id: "",
        message: "migrate_company",
      };
      this.agentMigrationParam.agent_ids = data.row
        .filter((i: any) => i.online_status)
        .map((x: any) => x.id);
      const company = cloneDeep(this.cs.allComp);
      this.allComp = company.filter(
        (x: any) => parseInt(x.id) !== parseInt(this.cs.currentScope.id)
      );
      this.modalService.open("agentMigration");
    } else if (data.action.text === "Reset Agent Configuration") {
      this.selectedData = data.row;
      this.modalService.open("bulkresetagent");
    } else if (data.action.text == "Deprecate") {
      this.deprecateRestoreAgent(data.row, true);
    } else if (data.action.text == "Restore") {
      this.deprecateRestoreAgent(data.row, false);
    }
  }

  scanaddMul(event: any) {
    this.loaderService.display(true);
    this.selectedData.forEach((obj: any, index: number) => {
      const reqData: any = {
        data: {},
      };
      reqData.data.agent_id = obj.id;
      reqData.data.company_id = obj.company_id;
      reqData.data.scan_type = event.scan_type;

      this._bs
        .doRequest("/w/company/scan", "post", reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          if (index === this.selectedData.length - 1) {
            this.loaderService.display(false);
            if (result.status) {
              this.modalService.close("scanagentsMul");
              this.toast.sToast("success", result.data);
              setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
            this._changeDetectorRef.detectChanges();
          }
        });
    });
  }

  agentMigration(): void {
    const dest = this.allComp.filter(
      (x: any) => x.id === this.agentMigrationParam.dest_company_id
    );
    if (dest && dest.length) {
      const confirmation = this.confirmDialog.open({
        title: "Confirmation",
        message: `Agents will be migrated from from ${this.cs.currentScope.name} to ${dest[0].name}. Please note: Only the Online agents will be migrated now, you will have to initiate migration for Offline agents once they are back Online.`,
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: "Migrate", color: "primary" },
          cancel: { show: true, label: "Cancel" },
        },
        dismissible: false,
      });
      confirmation.afterClosed().subscribe((result) => {
        if (result === "confirmed") {
          this.loaderService.display(true);
          this.agentMigrationParam.dest_company_id = parseInt(
            this.agentMigrationParam.dest_company_id
          );
          this._bs
            .doRequest(
              "/w/company/reset_agents",
              "post",
              this.agentMigrationParam
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              if (result.status) {
                this.modalService.close("agentMigration");
                const data = result.message ? result.message : result.data;
                this.toast.sToast("success", data);
                setTimeout(() => this.cs.selectedTagChange.next({}), 2000);
              } else {
                const data = result.message ? result.message : result.data;
                this.toast.sToast("error", data);
              }
            });
        }
      });
    }
  }
  remoteScanInstall(): void {
    const scan_types: any = [
      { field: "Full Scan", label: "FULLSCAN", type: "string" },
      { field: "Network Scan", label: "NETWORKSCAN", type: "string" },
      { field: "Active Directory Scan", label: "ADSCAN", type: "string" },
      { field: "Compliance Scan", label: "COMPLIANCE", type: "string" },
      { field: "Firewall Scan", label: "FIREWALL", type: "string" },
      { field: "Remote Install", label: "REMOTEINSTALL", type: "string" },
      { field: "Validate Credential", label: "VALIDATECREDS", type: "string" },
    ];
    if (this.cs.currentScope === "*") {
      this.scan_types = scan_types.filter(
        (x: any) => x.label !== "REMOTEINSTALL"
      );
      return;
    }
    this.scan_types = scan_types;
    this.isRemoteInstall = false;
    let params = {
      condition: `company_id=${this.cs.currentScope.id}  and category='company' and name='remote_agent_install'`,
      skip: 0,
      limit: 1,
    };
    this._bs
      .doRequest("/r/company/settings", "get", null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status && result.data && result.data.length) {
          if (result.data[0].value && result.data[0].value.value) {
            this.isRemoteInstall = result.data[0].value.value ? true : false;
          }
        } else {
          this.isRemoteInstall = false;
        }
        this._changeDetectorRef.detectChanges();
      });
  }

  getStatusKeys(): string[] {
    return Object.keys(this.dependencyStatus.status || {});
  }
  scanTypeChange(event: any): void {
    if (event === "VALIDATECREDS") {
      const credentials_ids = this.currentData.credentials_ids.filter(
        (x: any) => x !== null
      );
      if (!credentials_ids || !credentials_ids.length) {
        this.scanassetoutput.scan_type = null;
        return;
      }
      const condition: any = {
        condition: "",
        skip: 0,
        limit: 1000,
      };
      if (credentials_ids && credentials_ids.length === 1) {
        condition.condition = `id=${credentials_ids[0]} and asset_id is null`;
      } else if (credentials_ids && credentials_ids.length > 1) {
        condition.condition = `id IN (${credentials_ids.join(
          ","
        )}) and asset_id is null`;
      }
      this.loaderService.display(true, `Getting Credentials`);
      this._bs
        .doRequest("/r/company/credentials", "get", null, condition)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result.status) {
            this.credential_list = result.data;
          }
        });
    }
  }

  savePerformanceCount(): void {
    const maxNmap = 4;
    const maxSmb = 40;
    if (this._performance_count) {
      const nmapValue = this._performance_count.nmap;
      const smbValue = this._performance_count.smb;
      if (nmapValue > maxNmap || smbValue > maxSmb) {
        this.toast.sToast('error', 'Maximum value exceeded for NMAP or SMB.');
        return;
      } else {
        const reqData: any = {
          message: 'nmap_smb_threads',
          agent_probe_settings: [],
          company_id: parseInt(this.currentData.company_id)
        };
        reqData.agent_probe_settings.push(
          {
            [this.currentData.id.toString()]: this._performance_count
          }
        )
        this.loaderService.display(true);
        this._bs
          .doRequest("/w/company/reset_agents", "post", reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast("success", "Saved Successfully");
              this.modalService.close('agentPerformanceManagement');
              setTimeout(() => this.cs.selectedTagChange.next({}), 1000);
            } else {
              const data = result.message ? result.message : result.data;
              this.toast.sToast("error", data);
            }
          });
      }
    }

  }
}
