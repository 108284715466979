<div class="flex flex-col w-full min-w-0">
    <div class="flex flex-row items-center">
        <div class="text-2xl font-semibold tracking-tight">{{currentIntegration.display}}</div>
        <button class="ml-auto" mat-icon-button (click)="toggleChangeFn();">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <mat-divider class="my-4"></mat-divider>
    <mat-tab-group #matTabGroup [selectedIndex]="selectedIndex" (selectedTabChange)="onMatTabChanged();"
        (selectedIndexChange)="selectedIndex = $event" class="w-full min-h-[800px] overflow-auto"
        mat-stretch-tabs="false" [animationDuration]="'0'">
        <mat-tab label="Credentials">
            <ng-template mat-tab-label>
                <mat-icon class="mat-24 mr-2">vpn_key</mat-icon>
                <span id="intCredentials"> Credentials </span>
            </ng-template>
            <ng-template matTabContent>
                <integration-credentials [currentIntegration]="currentIntegration"
                    (updateCredFn)="updateCredFn($event)"></integration-credentials>
            </ng-template>
        </mat-tab>
        <ng-container *ngIf="currentIntegration.notificationfunction && integrationCred && integrationCred.length">
            <mat-tab label="Event Set">
                <ng-template mat-tab-label>
                    <mat-icon class="mat-24 mr-2">event_note</mat-icon>
                    <span id="eventSet"> Event Set </span>
                </ng-template>
                <ng-template matTabContent>
                    <app-event-set [currentIntegration]="currentIntegration" (tiggerFn)="addNewData($event)"
                        [integrationCred]="integrationCred"></app-event-set>
                </ng-template>
            </mat-tab>
            <mat-tab label="Integration Rules">
                <ng-template mat-tab-label>
                    <mat-icon class="mat-24 mr-2">integration_instructions</mat-icon>
                    <span id="integrationRules"> Integration Rules </span>
                </ng-template>
                <ng-template matTabContent>
                    <integration-profile [currentIntegration]="currentIntegration" [triggerNew]="triggerNew"
                        (tiggerFn)="addNewData($event)" [integrationCred]="integrationCred"></integration-profile>
                </ng-template>
            </mat-tab>
        </ng-container>
        <mat-tab label="Configurations" *ngIf="currentIntegration.enable_configuration && integrationCred && integrationCred.length && configIntegration.indexOf(currentIntegration.name) !== -1">
            <ng-template mat-tab-label>
                <mat-icon class="mat-24 mr-2">description</mat-icon>
                <span id="integrationRules"> Configurations </span>
            </ng-template>
            <ng-template matTabContent>
                <integration-configurations [currentIntegration]="currentIntegration"
                    [integrationCred]="integrationCred"></integration-configurations>
            </ng-template>
        </mat-tab>
        <mat-tab label="Company Mapping"
            *ngIf="currentIntegration.company_mapping_required && integrationCred && integrationCred.length && !currentIntegration.script_creation">
            <ng-template mat-tab-label>
                <mat-icon class="mat-24 mr-2">maps_home_work</mat-icon>
                <span id="companyMapping"> Company Mapping </span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container>
                    <app-psa-company-mapping [currentIntegration]="currentIntegration"
                        *ngIf="currentIntegration.notificationfunction" [triggerNew]="triggerNew"
                        (tiggerFn)="addNewData($event)" [integrationCred]="integrationCred"></app-psa-company-mapping>
                    <app-nonpsa-company-mapping [currentIntegration]="currentIntegration"
                        *ngIf="!currentIntegration.notificationfunction"
                        [integrationCred]="integrationCred"></app-nonpsa-company-mapping>
                </ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab label="Create Script"
            *ngIf="!currentIntegration.company_mapping_required && integrationCred && integrationCred.length && currentIntegration.script_creation">
            <ng-template mat-tab-label>
                <mat-icon class="mat-24 mr-2">code</mat-icon>
                <span id="CreateScript"> Create Script </span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container>
                    <app-script-creation [currentIntegration]="currentIntegration"
                        *ngIf="currentIntegration.script_creation"
                        [integrationCred]="integrationCred"></app-script-creation>
                </ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>