<div class="flex flex-col bg-card border m-4 border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
  <div class="p-6 pb-0">
    <div class="flex flex-row items-center">
      <div class="mr-4 text-lg font-medium tracking-tight leading-6 runcate">Security Report Card</div>
      <span class="text-md ml-auto font-medium me-2 px-2.5 py-1 rounded cursor-pointer bg-blue-600 text-white"
        (click)="addUnsuppress()"
        *ngIf="unSuppressData && unSuppressData.length">Add</span>
    </div>
    <mat-divider class="my-2"></mat-divider>
  </div>
  <div class="mx-4" *ngIf="!loadingCon.loading && !loadingCon.nodata" fuseScrollbar>
    <ng-container *ngIf="showReportCard">
      <table class="w-full bg-transparent mb-4" mat-table [dataSource]="reportCardDataSource"
        [trackBy]="trackByFn" #reportCardTable>
        <ng-container matColumnDef="name">
          <th mat-header-cell  *matHeaderCellDef class="">
            Name
          </th>
          <td mat-cell *matCellDef="let report" class="w-[39%]">
            <div class="flex flex-row items-center my-2">
              <i class="fas fa-shield-alt mr-2"></i>
              <div class="flex flex-col justify-center  gap-1">
                <span class="pr-6 whitespace-nowrap">{{report.name | camelToHuman:true | uppercase}}</span>
                <span class="font-medium text-sm text-secondary"
                  *ngIf="report.name && report.value && _cs.securityMatrix[report.name]">
                  {{_cs.securityMatrix[report.name?.toLowerCase()][report.value]}}</span>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="evidence">
          <th mat-header-cell  *matHeaderCellDef>
            Evidence
          </th>
          <td mat-cell *matCellDef="let report" class="w-[35%]">
            <span class="pr-6 my-2 font-medium text-sm text-secondary break-all">
              {{report.evidence}}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="suppress">
          <th mat-header-cell  *matHeaderCellDef>
            Suppress
          </th>
          <td mat-cell align="center" *matCellDef="let report">
            <mat-icon  (click)="updateExcludeReport(report,'suppress')"  [matTooltip]="'Suppress'"
                      color="primary" class="ml-4 cursor-pointer">visibility_off</mat-icon>
            <!--<span (click)="updateExcludeReport(report,'suppress')" [matTooltip]="'Suppress'"
              class="bg-yellow-100 text-yellow-800 cursor-pointer text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-400 border border-yellow-400">
              <i class="fas fa-arrow-right w-2.5 h-2.5"></i>
            </span>-->
          </td>
        </ng-container>
        <ng-container matColumnDef="grade">
          <th mat-header-cell  *matHeaderCellDef>
            Grade
          </th>
          <td mat-cell *matCellDef="let sw">
            <div class="my-2">
              <span class="inline-flex items-center font-bold text-sm px-6 py-0.5 rounded tracking-wide"
                [ngClass]="secGrade[sw.value]" *ngIf="sw.name && sw.value && _cs.securityMatrix[sw.name]">
                <span class="leading-relaxed whitespace-nowrap">{{sw.value}}</span>
              </span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="reportCardTableColumns"></tr>
        <tr class="order-row h-[40px] p-1" mat-row *matRowDef="let row; columns: reportCardTableColumns;"></tr>
      </table>
      <mat-divider class="my-2"></mat-divider>
    </ng-container>
  </div>
  <app-loader [loadingCon]="loadingCon" *ngIf="securityReportCard.length === 0" class="pb-6"></app-loader>
</div>
<s-modal id="suppressionSecurity">
  <div class="mx-auto w-[55vw] flex max-w-lg flex-col md:max-w-none md:flex-row">
    <div class="w-1/3 h-[70vh] max-w-md rounded-l-3xl
   bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4">
      <p class="mb-4 ml-2font-bold text-xl tracking-wider">
        <span *ngIf="suppressType === 'suppress'">Suppress</span> <span *ngIf="suppressType !== 'suppress'">Add to</span>
        Security Report Card
      </p>
      <img src="/assets/images/security_suppress.png" alt="Security Report Card" class="w-50 mt-10" height="512"
        width="512">
    </div>
    <div class="w-2/3 h-[70vh] rounded-r-3xl bg-card px-2 py-2 mb-4">
      <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
        matTooltip="Close" (click)="_modalService.close('suppressionSecurity');">
        <mat-icon>close</mat-icon>
      </button>
      <div class="mt-8">
        <span class="font-semibold ml-2 text-lg mb-4">
          <span *ngIf="suppressType === 'suppress'">Suppress : {{this.suppressItem | camelToHuman:true |
            uppercase}}</span> <span *ngIf="suppressType !== 'suppress'">Add to Security Report Card</span>
        </span>
        <mat-divider class="my-4"></mat-divider>
        <ng-container *ngIf="suppressType !== 'suppress'">
          <ng-container *ngIf="cStep === 0">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg h-[50vh]"
              *ngIf="unSuppressData && unSuppressData.length && suppressType !== 'suppress'">
              <table class="w-full text-sm text-left rtl:text-right">
                <thead class="text-xs uppercase bg-primary text-white">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Excluded
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Add
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b"
                    *ngFor="let item of unSuppressData let i = index;">
                    <td class="px-6 py-4">
                      {{item | camelToHuman:true | uppercase}}
                    </td>
                    <td class="px-6 py-4">
                      <div class="flex flex-row items-center">
                      <span class="text-sm font-md me-2 px-1.5 py-0.5 mr-1 rounded bg-blue-600 text-white"
                      *ngIf="(_security_report_global.value &&  _security_report_global.value.length && _security_report_global.value.indexOf(item) > -1)"
                      >Global</span>
                      <span class="text-sm font-md me-2 px-1.5 py-0.5 mr-1 rounded bg-blue-600 text-white"
                      *ngIf="(_security_report_company.value &&  _security_report_company.value.length && _security_report_company.value.indexOf(item) > -1)">Company</span>
                      <span class="text-sm font-md me-2 px-1.5 py-0.5 mr-1 rounded bg-blue-600 text-white"
                      *ngIf="(_security_report_asset.value &&  _security_report_asset.value.length && _security_report_asset.value.indexOf(item) > -1)">Asset</span>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <span class="text-md font-medium me-2 px-2.5 py-1 rounded cursor-pointer bg-blue-600 text-white"
                        (click)="selectChange(item)">+</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="cStep === 1">
            <span class="font-semibold ml-2 text-lg mb-4"> {{this.suppressItem | camelToHuman:true |
              uppercase}}
            </span>
            <div class="text-lg font-medium mt-4 mb-2 ml-2">Apply to</div>
            <mat-radio-group class="flex flex-col mt-2" [color]="'primary'" [(ngModel)]="sSuppressType"
              name="Suppression">
              <mat-radio-button class="mb-2" [value]="'global'"
                *ngIf="(_security_report_global.value &&  _security_report_global.value.length && _security_report_global.value.indexOf(suppressItem) > -1) || suppressType === 'suppress'">
                Global
              </mat-radio-button>
              <mat-radio-button class="mb-2" [value]="'company'"
                *ngIf="(_security_report_company.value &&  _security_report_company.value.length >0 && _security_report_company.value.indexOf(suppressItem) > -1) || suppressType === 'suppress'">
                Company
              </mat-radio-button>
              <mat-radio-button [value]="'asset'"
                *ngIf="(_security_report_asset.value &&  _security_report_asset.value.length >0 && _security_report_asset.value.indexOf(suppressItem) > -1) || suppressType === 'suppress'">
                Asset
              </mat-radio-button>
            </mat-radio-group>
            <div class="flex items-center mt-10 p-4 border-t dark:bg-transparent">
              <button class="ml-auto" mat-button type="button" [matTooltip]="'Back'" (click)="gotoBack()">
                Back
              </button>
              <button class="ml-2" mat-flat-button type="button" [color]="'primary'" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveSettings()" [disabled]="sSuppressType === ''">
                Apply
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="suppressType === 'suppress'">
          <div class="text-lg font-medium my-2 ml-2">Apply to</div>
          <mat-radio-group class="flex flex-row mr-2" [color]="'primary'" [(ngModel)]="sSuppressType"
            name="Suppression">
            <mat-radio-button class="mb-2" [value]="'global'"
              *ngIf="(_security_report_global.value &&  _security_report_global.value.length && _security_report_global.value.indexOf(suppressItem) > -1) || suppressType === 'suppress'">
              Global
            </mat-radio-button>
            <mat-radio-button class="mb-2" [value]="'company'"
              *ngIf="(_security_report_company.value &&  _security_report_company.value.length >0 && _security_report_company.value.indexOf(suppressItem) > -1) || suppressType === 'suppress'">
              Company
            </mat-radio-button>
            <mat-radio-button [value]="'asset'"
              *ngIf="(_security_report_asset.value &&  _security_report_asset.value.length >0 && _security_report_asset.value.indexOf(suppressItem) > -1) || suppressType === 'suppress'">
              Asset
            </mat-radio-button>
          </mat-radio-group>
          <div class="flex items-center mt-10 p-4 border-t dark:bg-transparent">
            <button class="ml-auto" mat-button type="button" [matTooltip]="'Cancel'" (click)="_modalService.close('suppressionSecurity');">
              Cancel
            </button>
            <button class="ml-2" mat-flat-button type="button" [color]="'primary'" appPreventMultiClick
              [matTooltip]="'Save'" (click)="saveSettings()" [disabled]="sSuppressType === ''">
              Apply
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</s-modal>
