<div class="w-auto h-auto absolute" [ngClass]="{'mt-8': cs.checkLayout === 'enterprise', 'right-10' : cs.checkLayout === 'enterprise' || cs.checkLayout === 'material'  ,  'right-6' : cs.checkLayout !== 'enterprise' && cs.checkLayout !== 'material' } "   *ngIf="!cs.hideDynamic">
    <div class="flex flex-row w-full sm:h-auto sm:w-full transition-transform duration-400 ease-drawer">
        <div class="flex-0 w-full h-auto overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto" [fuseScrollbarOptions]="{wheelPropagation: true}"><!--fuseScrollbar -->
            <div class="flex flex-row items-center">
                <ng-container *ngFor="let action of actions; trackBy: trackByFn">
                    <div class="flex items-center py-1 px-2 cursor-pointer"
                         [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedAction || selectedAction.id !== action.id,
                                    'bg-primary-50 dark:bg-hover': selectedAction && selectedAction.id === action.id}"
                        (click)="selectAction(action)">
                        <div class="relative flex flex-0 items-center justify-center h-8">
                            <ng-container *ngIf="action.unreadCount > 0">
                                <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5
                                 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                    [class.ring-primary-50]="selectedAction && selectedAction.id === action.id"></div>
                            </ng-container>
                            <ng-container *ngIf="!action.isSubMenu">
                                <ng-container *ngIf="action.icon">
                                    <div class="flex items-center justify-center gap-1
                                     w-full h-full rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        <mat-icon color="primary" class="icon-size-4"
                                            [ngClass]="{'primary': selectedAction && selectedAction.id === action.id}"
                                            [svgIcon]="action.icon"></mat-icon>
                                        <span class="text-xs">{{action.text}}</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!action.icon">
                                    <div class="mat-primary flex items-center justify-center w-full h-full
                                     rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        {{action.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="action.isSubMenu">
                                <app-dynamic-menu [data]="action"></app-dynamic-menu>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="flex items-center py-1 px-2 cursor-pointer">
                    <settings></settings>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fixed lg:sticky sm:sticky md:sticky xs:sticky top-0 bottom-0 w-16 max-h-[88vh] shadow" *ngIf="false && !cs.hideDynamic">
    <div class="flex flex-col w-full sm:w-16 h-full transition-transform duration-400 ease-drawer bg-card">
        <div class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
            fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: true}">
            <div class="flex items-center justify-center w-full h-14 border-b text-lg uppercase">
                <!--<mat-icon [svgIcon]="'tune'"></mat-icon> -->
                <settings></settings>
            </div>
            <div class="flex-auto ">
                <ng-container *ngFor="let action of actions; trackBy: trackByFn">
                    <div class="flex items-center py-3 px-4 cursor-pointer" [id]="action.id"
                         [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedAction || selectedAction.id !== action.id,
                                    'bg-primary-50 dark:    bg-hover': selectedAction && selectedAction.id === action.id}"
                         (click)="selectAction(action)">
                        <div class="relative flex flex-0 items-center justify-center w-8 h-8" id="{{action.action.name}}action">
                            <ng-container *ngIf="action.unreadCount > 0">
                                <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                    [class.ring-primary-50]="selectedAction && selectedAction.id === action.id"></div>
                            </ng-container>
                            <ng-container *ngIf="!action.isSubMenu">
                                <ng-container *ngIf="action.icon">
                                    <div class="flex items-center justify-center
                                     w-full h-full rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        <mat-icon
                                            [ngClass]="{'primary': selectedAction && selectedAction.id === action.id}"
                                            [svgIcon]="action.icon"></mat-icon>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!action.icon">
                                    <div class="mat-primary flex items-center justify-center w-full h-full
                                     rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        {{action.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="action.isSubMenu">
                                <app-dynamic-menu [data]="action"></app-dynamic-menu>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Select action or start new template -->
<ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon class="icon-size-24" [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">Select an action</div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->

<fuse-drawer class="w-screen min-w-screen z-999 fixed sm:w-3/4 sm:min-w-3/4" [mode]="'over'"
             [ngClass]="{'sm:w-3/4 sm:min-w-3/4': allowedId.indexOf(selectedAction?.id) === -1,
                     'sm:w-[40%] sm:min-w-[40%]': allowedId.indexOf(selectedAction?.id) > -1}"
    [name]="'dynamicSettingsDrawer'" [position]="'right'" (openedChanged)="onOpenedChange($event)" #dynamicSettingsDrawer>
    <div class="flex flex-col w-full bg-card">
        <div id="swami" class="flex flex-row items-center px-4 h-20 min-h-20 text-white bg-primary z-10"
            *ngIf="selectedAction">
            <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                <ng-container *ngIf="selectedAction.icon">
                    <div
                        class="flex items-center justify-center text-white w-full h-full rounded-full text-lg uppercase">
                        <mat-icon [svgIcon]="selectedAction.icon" class="text-white"></mat-icon>
                    </div>
                </ng-container>
                <ng-container *ngIf="!selectedAction.icon">
                    <div class="flex items-center justify-center w-full h-full rounded-full text-lg
                     uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                        {{selectedAction.name.charAt(0)}}
                    </div>
                </ng-container>
            </div>
            <div class="ml-3 text-2xl font-semibold tracking-tight">{{selectedAction.name}}</div>
            <button class="ml-auto" mat-icon-button (click)="closeDrawer()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <div class="flex flex-row w-full">
            <div *ngIf="!cs.hideDynamic && false" class="flex-0 w-16 border-r max-h-[88vh]" fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: true}">
                <div class="flex-auto">
                    <ng-container *ngFor="let action of actions; trackBy: trackByFn">
                        <div class="flex items-center py-3 px-4 cursor-pointer" *ngIf="selectedAction" [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedAction || selectedAction.id !== action.id,
                                        'bg-primary': selectedAction && selectedAction.id === action.id}"
                            (click)="selectAction(action)">
                            <div class="relative flex flex-0 items-center justify-center w-8 h-8">
                                <ng-container *ngIf="action.unreadCount > 0">
                                    <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                        [class.ring-primary-50]="selectedAction && selectedAction.id === action.id">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="action.icon">
                                    <div class="flex items-center justify-center
                                     w-full h-full rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        <mat-icon
                                            [ngClass]="{'text-white': selectedAction && selectedAction.id === action.id}"
                                            [svgIcon]="action.icon"></mat-icon>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!action.icon">
                                    <div class="mat-primary flex items-center justify-center w-full h-full
                                     rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        {{action.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- For new component please add the target name in the validTargets -->
            <div #detailView class="flex flex-col dark:bg-default h-[88vh] inset-0"
             [ngClass]="{'w-[74vw]': ( !cs.isNavigationOpen && (cs.checkLayout === 'classic' || cs.checkLayout === 'classy' || cs.checkLayout === 'futuristic')),
              'w-[94vw]': ( !cs.isNavigationOpen && !(cs.checkLayout === 'classic' || cs.checkLayout === 'classy' || cs.checkLayout === 'futuristic')),
               'w-[95vw]':cs.isNavigationOpen }" fuseScrollbar>
                <ng-container *ngIf="selectedAction; else selectChatOrStartNew">
                        <ng-container [ngSwitch]="selectedAction.id">
                            <div class="px-4">
                            <ng-container *ngSwitchCase="'new-company'">
                                <new-company></new-company>
                            </ng-container>
                            <ng-container *ngSwitchCase="'plan-and-billing'">
                                <app-plan-and-billing></app-plan-and-billing>
                            </ng-container>
                            <ng-container *ngSwitchCase="'tags'">
                                <settings-tags></settings-tags>
                            </ng-container>
                            <ng-container *ngSwitchCase="'download-agent'">
                                <agents [isGlobal]="false"></agents>
                            </ng-container>
                            <ng-container *ngSwitchCase="'integrations'">
                                <app-integrations></app-integrations>
                            </ng-container>
                            <ng-container *ngSwitchCase="'remediated_record'">
                                <app-remediated-details></app-remediated-details>
                            </ng-container>
                            <ng-container *ngSwitchCase="'asset_remediated_record'">
                                <app-remediated-details [mode]="'asset'"></app-remediated-details>
                            </ng-container>
                            <ng-container *ngSwitchCase="'update-company'">
                                <div class="items-start mt-6 mb-4">
                                    <app-dynamic-form *ngIf="comConf.elementList"
                                        [listOfFormElements]="comConf.elementList" [Valuesoutput]="com"
                                        (saveCallBack)="saveCompany($event)" [fnBtnName]="'Save'" [isCloseBtn]="false">
                                    </app-dynamic-form>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'discovery-settings'">
                                <discovery-settings></discovery-settings>
                            </ng-container>
                            <ng-container *ngSwitchCase="'asset-time-line'">
                                <asset-time-line></asset-time-line>
                            </ng-container>
                            <ng-container *ngSwitchCase="'remove-psa'">
                                <div class="w-full">
                                    <div class="flex flex-col items-center justify-center gap-y-2">
                                        <img ngSrc="/assets/images/empty.png" alt="" class="w-20" height="512"
                                            width="512">
                                        <div> No data available!</div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'manage-mapping'">
                                <app-mapping></app-mapping>
                            </ng-container>
                            <ng-container *ngSwitchCase="'company-settings'">
                                <app-company-settings></app-company-settings>
                            </ng-container>
                            <ng-container *ngSwitchCase="'suppressed_records'">
                                <app-suppression [filterKey]="'solution_id'"></app-suppression>
                            </ng-container>
                            <ng-container *ngSwitchCase="'global-settings'">
                                <app-global-settings></app-global-settings>
                            </ng-container>
                            <ng-container *ngSwitchCase="'problem_suppress_record'">
                                <app-suppression [filterKey]="'problem_id'"></app-suppression>
                            </ng-container>
                            <ng-container *ngSwitchCase="'vulnerability_suppressed_records'">
                                <app-suppression [filterKey]="'problem_id'"></app-suppression>
                            </ng-container>
                            <ng-container *ngSwitchCase="'asset_suppressed_records'">
                                <mat-tab-group mat-stretch-tabs="false" [animationDuration]="'0'">
                                    <mat-tab label="Suppressed Problem">
                                        <ng-template mat-tab-label>
                                            <span id="Suppressed Problem"> Suppressed Problem </span>
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-suppression [filterKey]="'problem_id'" [mode]="'asset'"></app-suppression>
                                        </ng-template>
                                    </mat-tab>
                                    <mat-tab label="Suppressed Solution">
                                        <ng-template mat-tab-label>
                                            <span id="suppressed_solution"> Suppressed Solution </span>
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <app-suppression [filterKey]="'solution_id'" [mode]="'asset'"></app-suppression>
                                        </ng-template>
                                    </mat-tab>
                                </mat-tab-group>
                            </ng-container>
                            <ng-container *ngSwitchCase="'delete-company'">
                                <div>
                                    <ng-container>
                                        <app-dynamic-form *ngIf="comDelConf.elementList"
                                            [listOfFormElements]="comDelConf.elementList" [Valuesoutput]="cmp"
                                            (saveCallBack)="deleteCompany($event)" [isCloseBtn]="false"
                                            [fnBtnName]="comDelConf.saveBtn">
                                        </app-dynamic-form>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'help'">
                                <iframe src="https://www.youtube.com/@connectsecure" frameborder="0" width="100%"
                                    height="100%"></iframe>
                            </ng-container>
                            <ng-container *ngSwitchCase="'support'">
                                <app-support-ticket></app-support-ticket>
                            </ng-container>
                            <ng-container *ngSwitchCase="'active_directory'">
                                <app-ad-details></app-ad-details>
                            </ng-container>
                            <ng-container *ngSwitchCase="'azure_active_directory'">
                                <app-azure-ad-details></app-azure-ad-details>
                            </ng-container>
                            <ng-container *ngSwitchCase="'user-management'">
                                <app-user-management></app-user-management>
                            </ng-container>
                            <ng-container *ngSwitchCase="'customer-management'">
                                <app-customers></app-customers>
                            </ng-container>
                            <ng-container *ngSwitchCase="'alerts'">
                                <app-system-events (callbackClose)="closeDrawer()"></app-system-events>
                            </ng-container>
                            <ng-container *ngSwitchCase="'getting-started'">
                                <app-getting-started></app-getting-started>
                            </ng-container>
                            <ng-container *ngSwitchCase="'search-cve'">
                                <app-search-cve></app-search-cve>
                            </ng-container>
                            <ng-container *ngSwitchCase="'global-agent'">
                                <agents [isGlobal]="true"></agents>
                            </ng-container>
                            <ng-container *ngSwitchCase="'add-customers'">
                                <app-customers></app-customers>
                            </ng-container>
                            <ng-container *ngSwitchCase="'patching-jobs'">
                                <app-patching-jobs> </app-patching-jobs>
                            </ng-container>
                            <ng-container *ngSwitchCase="'patch-scheduler-jobs'">
                                <app-patching-jobs> </app-patching-jobs>
                            </ng-container>
                            <ng-container *ngSwitchCase="'report-scheduler-jobs'">
                                <app-report-jobs> </app-report-jobs>
                            </ng-container>
                            <ng-container *ngSwitchCase="'report-builder-scheduler'">
                                <app-report-scheduler-jobs> </app-report-scheduler-jobs>
                            </ng-container>
                            <ng-container *ngSwitchCase="'company-list'">
                                <stable aria-label="Company Table" role="table" [sTableOptions]="companyTableOptions"
                                    (hyperlinkCallback)="linkClick($event)"></stable>
                            </ng-container>
                            <ng-container *ngSwitchCase="'scan-scheduler-jobs'">
                                <app-job-view [jobCondition]="jobCondition" [actionMenuItems]="actionMenuItems"
                                        [isShowHyperLink]="isShowHyperLink"></app-job-view>
                            </ng-container>
                            </div>
                            <ng-container *ngSwitchCase="'jobs'">
                                <app-job-view [jobCondition]="jobCondition" [actionMenuItems]="actionMenuItems"
                                        [isShowHyperLink]="isShowHyperLink"
                                        [isActionButton]="isActionButton" class="dark:bg-transparent" style="background-color: #e2e8f0"></app-job-view>
                            </ng-container>
                            <ng-container *ngSwitchCase="'application_base_rule'">
                                <app-application-baseline></app-application-baseline>
                            </ng-container>
                        </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</fuse-drawer>

<s-modal id="uploadAssessment">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[75vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-10 font-bold tracking-wider">Upload Assessment</p>
        </div>
        <div class="w-2/3 h-[75vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <div class="flex flex-row items-center mb-2">
                <div class="text-2xl font-semibold tracking-tight">Upload Assessment File</div>
                <button class="ml-auto" mat-icon-button (click)="modalService.close('uploadAssessment');">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <form class="flex flex-col m-2 px-2 overflow-hidden" #uploadAsm="ngForm">
                <div class="flex mt-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Company Name</mat-label>
                        <input matInput autocomplete="off" [(ngModel)]="assessmentName" type="text"
                            name="assessmentName" trim="blur" required placeholder="Enter Company Name">
                    </mat-form-field>
                </div>
                <h2 class="mb-3 text-md mt-4 font-bold">Upload Assessment Zip File</h2>
                <input #uploadFileInput type="file" class="w-100 mb10" name="file"
                    (change)="uploadFile($event, 'uploadfile')" required />

                <div
                    class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('uploadAssessment');">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button mat-raised-button class="mat-primary mr-2" (click)="saveAssessmentFile(assessmentName)"
                        [disabled]="(!uploadAsm.form.valid || isLoading) || disbaleSave" id="saveasm">Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>


<s-modal id="downloadAssessmentAgent">
    <mat-card class="modal bg-card w-full p-4">
        <div class="flex flex-row items-center mb-2">
            <div class="text-2xl font-semibold tracking-tight">Deploy Assessment Agent</div>
            <button class="ml-auto" mat-icon-button (click)="closemenu();">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <!-- Windows tab -->
        <mat-tab-group (selectedTabChange)="selectedTab($event)" class="sm:px-2 max-w-3xl mx-auto"
            mat-stretch-tabs="false" [animationDuration]="'0'">
            <mat-tab label="Windows">
                <ng-template mat-tab-label>
                    <i class="fab fa-windows fa-2x text-primary mr-3"></i><span id="Windows"
                        class="text-lg">Windows</span>
                </ng-template>
                <ng-template matTabContent>
                    <div class="mat-title mt-2 ml-2 mb-4">Steps</div>
                    <div class="text-center mt-4">
                        <mat-card class="mat-mdc-card mdc-card mb-4">
                            <mat-card-content>
                                <div class="text-left">
                                    <p class="mb-2">1. Download CyberCNSAssessment_Windows.zip
                                        file</p>
                                    <p class="mb-2">2. Extract the zip file</p>
                                    <p class="mb-2">3. Open CyberCNSAssessment_Windows Folder
                                    </p>
                                    <p class="mb-2">4. Run StartAssessment.bat file as
                                        Administrator</p>
                                    <p class="mb-2">5. Browse https://localhost:8088; Login
                                        and Step through the
                                        Wizard</p>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <div class="mb-2 flex flex-row items-center gap-2 justify-end" style="margin-left: 50PX;">
                            <button mat-raised-button color="primary" type="button" (click)="downloadAgent('Windows')">Download</button>
                            <button mat-stroked-button (click)="closemenu()" type="button" color="warn">Cancel</button>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Mac">
                <ng-template mat-tab-label>
                    <i class="fab fa-apple fa-2x text-primary mr-3"></i><span id="Mac" class="text-lg">Mac</span>
                </ng-template>
                <ng-template matTabContent>
                    <div class="mat-title mt-2 ml-2 mb-4">Steps</div>
                    <div class="text-center mt-4">
                        <mat-card class="mat-mdc-card mdc-card mb-4">
                            <mat-card-content>
                                <div class="text-left">
                                    <p class="mb-2">1. Download CyberCNSAssessment_Darwin.zip file</p>
                                    <p class="mb-2">2. Extract the zip file</p>
                                    <p class="mb-2">3. Open Terminal</p>
                                    <p class="mb-2">4. sudo su</p>
                                    <p class="mb-2">5. brew install nmap</p>
                                    <p class="mb-2">6. mkdir -p /opt/CyberCNSAssessment_Darwin</p>
                                    <p class="mb-2">7. cd <'DownloadPath'></p>
                                    <p class="mb-2">7. installer -pkg ./CyberCNS_Assessment_darwin.pkg -target /</p>
                                    <p class="mb-2">7. cd /opt/CyberCNSAssessment_Darwin; chmod +x StartAssessment.sh</p>
                                    <p class="mb-2">8. ./StartAssessment.sh</p>
                                    <p class="mb-2">9. Browse https://localhost:8088; Login and Step through the
                                        Wizard</p>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <div class="mb-2 flex flex-row items-center gap-2 justify-end" style="margin-left: 50PX;">
                            <button mat-raised-button color="primary" type="button" (click)="downloadAgent('Darwin')">Download</button>
                            <button mat-stroked-button (click)="closemenu()" type="button" color="warn">Cancel</button>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Linux">
                <ng-template mat-tab-label>
                    <i class="fab fa-linux fa-2x text-primary mr-3"></i><span id="Linux" class="text-lg">Linux</span>
                </ng-template>
                <ng-template matTabContent>
                    <div class="mat-title mt-2 ml-2 mb-4">Steps</div>
                    <div class="text-center mt-4">
                        <mat-card class="mat-mdc-card mdc-card mb-4">
                            <mat-card-content>
                                <div class="text-left">
                                    <p class="mb-2">1. Download CyberCNSAssessment_Linux.zip file</p>
                                    <p class="mb-2">2. Extract the zip file</p>
                                    <p class="mb-2">3. Open Terminal</p>
                                    <p class="mb-2">4. sudo su</p>
                                    <p class="mb-2">5. For Ubuntu/Debian install Nmap by
                                        running `apt install -y nmap`</p>
                                    <p class="mb-2">6. For RHEL/Centos install Nmap by running
                                        `yum install nmap`</p>
                                    <p class="mb-2">7. cd < DownloadPath>/CyberCNSAssessment_Linux</p>
                                    <p class="mb-2">8. chmod +x StartAssessment.sh</p>
                                    <p class="mb-2">9. ./StartAssessment.sh</p>
                                    <p class="mb-2">10. Browse https://localhost:8088; Login
                                        and Step through the
                                        Wizard</p>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <div class="mb-2 flex flex-row items-center gap-2 justify-end" style="margin-left: 50PX;">
                            <button mat-raised-button color="primary" type="button" (click)="downloadAgent('Linux')">Download</button>
                            <button mat-stroked-button (click)="closemenu()" type="button" color="warn">Cancel</button>
                        </div>
                    </div>

                </ng-template>
            </mat-tab>
            <mat-tab label="ARM">
                <ng-template mat-tab-label>
                    <i class="fas fa-microchip fa-2x text-primary mr-3"></i><span id="ARM" class="text-lg">ARM</span>
                </ng-template>
                <ng-template matTabContent>

                    <div class="mat-title mt-2 ml-2 mb-4">Steps</div>
                    <div class="text-center mt-4">
                        <mat-card class="mat-mdc-card mdc-card mb-4">
                            <mat-card-content>
                                <div class="text-left">
                                    <p class="mb-2">1. Download CyberCNSAssessment_ARM.zip
                                        file</p>
                                    <p class="mb-2">2. Extract the zip file</p>
                                    <p class="mb-2">3. Open Terminal</p>
                                    <p class="mb-2">4. sudo su</p>
                                    <p class="mb-2">5. For Ubuntu/Debian install Nmap by
                                        running `apt install -y nmap`</p>
                                    <p class="mb-2">6. For RHEL/Centos install Nmap by running
                                        `yum install nmap`</p>
                                    <p class="mb-2">7. cd < DownloadPath>
                                            /CyberCNSAssessment_Linux</p>
                                    <p class="mb-2">8. chmod +x StartAssessment.sh</p>
                                    <p class="mb-2">9. ./StartAssessment.sh</p>
                                    <p class="mb-2">10. Browse https://localhost:8088; Login
                                        and Step through the
                                        Wizard</p>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <div class="mb-2 flex flex-row items-center gap-2 justify-end" style="margin-left: 50PX;">
                            <button mat-raised-button color="primary" type="button" (click)="downloadAgent('ARM')">Download</button>
                            <button mat-stroked-button (click)="closemenu()"  type="button" color="warn">Cancel</button>
                        </div>
                    </div>

                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</s-modal>
