import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from "@angular/material/paginator";
import { QueryBuilderComponent, QueryBuilderModule } from "@syncfusion/ej2-angular-querybuilder";
import { GridModule } from "@syncfusion/ej2-angular-grids";
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { cloneDeep } from 'lodash';
import { CommonService } from 'app/_services/common.service';

@Component({
    selector: 'filter-widget',
    standalone: true,
    imports: [CommonModule, MatPaginatorModule, GridModule, QueryBuilderModule, CheckBoxModule, DropDownListModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './filter-widget.component.html',
    styleUrls: ['./filter-widget.component.scss']
})

export class FilterWidgetComponent implements AfterViewInit {
    @ViewChild('querybuilder') public qryBldrObj?: QueryBuilderComponent;
    @Input() showFilter: boolean = false;
    @Input() sColumns: any = [];
    @Output() setRulesFn = new EventEmitter();
    @Output() resetRulesFn = new EventEmitter();
    @Output() toggleFilterFn = new EventEmitter();
    @Input() filterQuery = '';
    @Input() table_id = '';

    public customOperators?: any;
    list_keys: any = ['member_of']
    stringCusOperators: any = [
        {
            "value": "startswith",
            "key": "Starts With"
        },
        {
            "value": "endswith",
            "key": "Ends With"
        },
        {
            "value": "contains",
            "key": "Contains"
        },
        {
            "value": "notcontains",
            "key": "Does Not Contain"
        },
        {
            "value": "equal",
            "key": "Equal"
        },
        {
            "value": "notequal",
            "key": "Not Equal"
        },
        { value: 'isnull', key: 'Is Null' },
        { value: 'isnotnull', key: 'Is Not Null' }
    ]

    dataOperators: any = [
        {
            "value": "contains",
            "key": "Contains"
        },
        { value: 'isnull', key: 'Is Null' },
        { value: 'isnotnull', key: 'Is Not Null' }
    ]

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _cs: CommonService) {
    }

    ngOnInit(): void {
        this.customOperators = [
            { value: 'equal', key: 'Equal' },
            { value: 'notequal', key: 'Not Equal' }
        ];
        this.initallyload();
    }

    initallyload() {
        if (this._cs.table_filter && this._cs.table_filter[this.table_id]) {
            setTimeout(() => {
                if (this.qryBldrObj) {
                    this.qryBldrObj!.setRules(this._cs.table_filter[this.table_id].rule);
                    this._changeDetectorRef.detectChanges();
                    this.setRules()
                }
            }, 5000);
        }
    }

    ngAfterViewInit(): void {
        this._changeDetectorRef.markForCheck();
    }

    shouldUseDataOperators(header: string, columnDef: string): boolean {
        const mappings = [
            { header: 'Issuer', columnDef: 'issuer' },
            { header: 'Not Valid After', columnDef: 'not_valid_after' },
            { header: 'Not Valid Before', columnDef: 'not_valid_before' },
            { header: 'Subject', columnDef: 'subject' },
            { header: 'Public Key Bits', columnDef: 'pubkey_bits' },
            { header: 'Public Key Type', columnDef: 'pubkey_type' }
        ];

        return mappings.some(mapping => mapping.header === header && mapping.columnDef === columnDef);
    }

    toggleFilter(): void {
        delete this._cs.table_filter[this.table_id];
        this.showFilter = !this.showFilter;
        if (!this.showFilter) {
            this.filterQuery = '';
            this.toggleFilterFn.emit({ query: this.filterQuery, rule: null });
        }
    }
    // Old Methode
    /*
    setRules(): void {
        this.filterQuery = cloneDeep(this.qryBldrObj!.getSqlFromRules(
            this.qryBldrObj!.getRules()
        ));
        if (this.filterQuery) {
            const datePattern = /(\w+) = '(\d{1,2}\/\d{1,2}\/\d{4})'/;
            let filterQuery = this.filterQuery.replace(/'true'/g, 'true').replace(/'false'/g, 'false').replace(/LIKE/gi, 'ILIKE'); // .replace(/!=/g, 'NOT ILIKE').replace(/=/g, 'ILIKE');
            try {
                filterQuery = filterQuery.replace(datePattern, (match, key, originalDate) => {
                    const [day, month, year] = originalDate.split('/');
                    const formattedDateString = `${year}-${month}-${day}`;
                    const parsedDate = new Date(formattedDateString);
                    const formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;
                    return `DATE(${key}) = '${formattedDate}'`;
                });
                this.setRulesFn.emit({ query: filterQuery });
            } catch (error) {
                console.log(error);
                this.setRulesFn.emit({ query: filterQuery });
            }
        }
    }*/
    // New Methode based on Value Type (It will work for multiple conditions also)
    setRules(): void {
        this._cs.table_filter = {};
        this.filterQuery = cloneDeep(this.qryBldrObj!.getSqlFromRules(
            this.qryBldrObj!.getRules()
        ));

        if (this.filterQuery) {
            const datePattern = /(\w+) = '(\d{1,2}\/\d{1,2}\/\d{4})'/;
            let filterQuery = this.filterQuery
                .replace(/'true'/g, 'true')
                .replace(/'false'/g, 'false')
                .replace(/LIKE/gi, 'ILIKE');
            const conditions = filterQuery.split(' AND ');
            for (let i = 0; i < conditions.length; i++) {
                const [key, operator, value] = conditions[i].split(/\s+/);

                if (value.startsWith("'") && value.endsWith("'")) {
                    const isDateString = /^(\d{1,2}\/\d{1,2}\/\d{4})$/.test(value.slice(1, -1));

                    if (isDateString) {
                        const formattedDate = value.slice(1, -1).split('/').reverse().join('-');
                        conditions[i] = `DATE(${key}) ${operator} '${formattedDate}'`;
                    } else {
                        if (this.list_keys.indexOf(key) > -1) {
                            const newOperator = (operator === '=') ? 'ILIKE' : 'NOT ILIKE';
                            conditions[i] = `${key} ${newOperator} '%${value.replace(/'/g, '')}%'`;
                        }
                    }
                } else if (this.list_keys.indexOf(key) > -1) {
                    if (operator === 'ILIKE') {
                        const valueN: any = value.replace("('", "").replace("')", "").replace("%", "");
                        conditions[i] = `${key} ${operator} '%${valueN}%'`;
                    } else {
                        const newOperator = (operator === '=') ? 'ILIKE' : 'NOT ILIKE';
                        conditions[i] = `${key} ${newOperator} '%${value.replace(/'/g, '')}%'`;
                    }
                }

                if (key === 'visible_name') {
                    conditions[i] = `(${key} ${operator} ${value} OR host_name  ${operator} ${value})`;
                }
            }
            filterQuery = conditions.join(' AND ');
            try {
                this.setRulesFn.emit({ query: filterQuery, rule: this.qryBldrObj!.getRules() });
                this._cs.table_filter[this.table_id] = { rule: this.qryBldrObj!.getRules(), query: filterQuery };
            } catch (error) {
                console.log(error);
                this.setRulesFn.emit({ query: filterQuery, rule: this.qryBldrObj!.getRules(), });
                this._cs.table_filter[this.table_id] = { rule: this.qryBldrObj!.getRules(), query: filterQuery };
            }
        }
    }

    resetRules(): void {
        delete this._cs.table_filter[this.table_id];
        this.qryBldrObj!.reset();
        this.filterQuery = '';
        this.setRulesFn.emit({ query: '', rule: null });
    }

    filterChange(e: any, ruleID: string, values: any): void {
        if (!e.itemData || !e.itemData.value) { return; }
        const valueHash: any = {}; values.map((x: any) => valueHash[x.name] = x.value);
        let elem: HTMLElement = document.getElementById(ruleID)!.querySelector('.e-rule-value') as HTMLElement;
        this.qryBldrObj!.notifyChange(e.itemData.value as string, elem, 'value');
    }

    checkboxChange(e: any, ruleID: string): void {
        let elem: HTMLElement = document.getElementById(ruleID)!.querySelector('.e-rule-value') as HTMLElement;
        this.qryBldrObj!.notifyChange(e.checked as boolean, elem, 'value');
    }

    getDataSourceValues($event: any): any {
        const res = [];
        $event.map((x: any) => res.push({ text: x.name, value: x.value }));
        return res;
    }
}
