import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'app/_services/common.service';
import { MaterialModule } from 'app/material.module';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'app/-directives/-directives.module';

@Component({
  selector: 'app-domain-check',
  standalone: true,
  imports: [CommonModule, MaterialModule, FuseAlertComponent, FormsModule, ReactiveFormsModule, DirectivesModule],
  templateUrl: './domain-check.component.html',
  styleUrls: ['./domain-check.component.scss']
})
export class DomainCheckComponent implements OnInit, OnDestroy {
  domainInformation: any = {
    domain: ''
  };
  noData = undefined;
  @ViewChild('domainNgForm') domainNgForm: NgForm;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService, private _cs: CommonService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getDomainInformation();
  }

  getDomainInformation(): void {
    this.ls.display(true);
    this._bs.doRequest(`/r/company/company_domain_check`, 'get', null,
      { condition: `company_id='${this._cs.currentScope.id}' `, skip: 0, limit: 1 })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.domainInformation = {};
          if (res.data && res.data.length) {
            this.domainInformation = res.data[0];
            this.noData = ['mx_records', 'spf_records', 'dmarc_records', 'dkim_records']
              .every(key => res.data[0][key].length === 0);;
          } else {
            this.domainInformation = { domain: this._cs.currentScope.domain };
            this.noData = true;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
          this.noData = true;
        }
      });
  }


  save(): void {
    const reqData: any = {
      data: { domain: this.domainInformation.domain },
      id: parseInt(this._cs.currentScope.id)
    }
    delete reqData.data.id;
    this._bs.doRequest('/w/company/companies', 'patch', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.ls.display(false);
        if (result.status) {
          this.toast.sToast('success', `Updated successfully!`);
          this._cs.currentScope.domain = this.domainInformation.domain;
          this._bs.doRequest('/w/company/scan_domain', 'post', { company_id: reqData.id })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => { });
          setTimeout(() => {
            this.ls.display(false);
            this.getDomainInformation();
          }, 6000);
        } else {
          this.ls.display(false);
          const data = (result.message) ? result.message : result.data;
          this.toast.sToast('error', data);
        }
      })
  }
}