import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { UserService } from 'app/core/user/user.service';
import { MaterialModule } from 'app/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MyToastrService } from 'app/_services/toastr.service';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { EdrAppComponent } from 'app/modules/settings/edr-app/edr-app.component';
import { BackupSoftwaresComponent } from 'app/modules/settings/backup-softwares/backup-softwares.component';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { DomainCheckComponent } from '../domain-check/domain-check.component';
import { AntirandomwareComponent } from 'app/modules/settings/antirandomware/antirandomware.component';
import { MatChipInputEvent, MatChipEditedEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FirewallComponent } from 'app/modules/settings/firewall/firewall.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ReportSettingsComponent } from 'app/modules/shared/report-settings/report-settings.component';
import { TagManagementComponent } from 'app/modules/settings/tag-management/tag-management.component';

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
    standalone: true,
    imports: [CommonModule, FuseAlertComponent, NgxMatSelectSearchModule, DirectivesModule, AppFilterPipeModule, FuseScrollbarDirective, ReactiveFormsModule, TableComponent, MaterialModule, FormsModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, DynamicFormComponent, MatCardModule, MatDividerModule, MatTooltipModule, ModalComponent, FuseScrollbarDirective,
        EdrAppComponent, BackupSoftwaresComponent, DomainCheckComponent, AntirandomwareComponent, FirewallComponent, ReportSettingsComponent, TagManagementComponent],
    styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    step = 0;
    alert: { type: FuseAlertType; message: string } = {
        type: 'info',
        message: '',
    };
    cView: any = 'antiransomeware';
    showAlert: boolean = false;
    defaultTagsStatus: any = true;
    adAuditScanStatus: any = false;
    patchingStatus: any = false;
    acceptPatchCond: any = false;
    excludeReportList: any = {};
    excludeReportListAll: any = [];
    complianceListAll: any = [];
    selectedCount: number = 0;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    selectedSuppressType: string | null = null;
    addOnBlur = true;
    selectAll = false;
    searchTerm: string = '';
    filteredResultSets: any[] = [];
    originalRemData: any = [];
    assetinfoTableOptions: any = {};
    expandedIndex: number | null = null;
    uploadfile: any = null;
    ports: any = [
        {
            label: "Insecure Ports",
            key: '_insecure_ports',
            required: false,
            example: 'Ex. 80, 8080, 9090',
            'type': 'chiptext'
        },
        { label: "Denied Ports", key: '_denied_ports', required: false, example: 'Ex. 22, 443-450', 'type': 'chiptext' },
        {
            label: "Excluded Ports",
            key: '_excluded_ports',
            required: false,
            example: 'Ex. 80, 8080, 9090',
            'type': 'chiptext'
        },
        { label: "Allowed Ports", key: '_allowed_ports', required: false, example: 'Ex. 22, 443-450', 'type': 'chiptext' },
        { label: "Secure Ports", key: '_secure_ports', required: false, example: 'Ex. 22, 443-450', 'type': 'chiptext' }
    ];
    _tags: any = [
        {
            "title": "Server",
            "value": "Server"
        },
        {
            "title": "Workstation",
            "value": "Workstation"
        },
        {
            "title": "Windows 11 Incompatible",
            "value": "Windows 11 Incompatible"
        }
    ];
    selectedTags: any = [];
    resultSets: any[];
    portsParams: any = {
        _insecure_ports: [],
        _denied_ports: [],
        _excluded_ports: [],
        _allowed_ports: [],
        _secure_ports: []
    };

    _brute_force: any = { 'category': 'company', 'sub_category': 'agents', 'name': 'brute_force', 'value': { message: 'brute_force_enabled', brute_force: false, brute_force_ports: [] } };
    _performance_count: any = { 'category': 'company', 'sub_category': 'agents', 'name': 'performance_count', 'value': { nmap: 8, smb: 40 } };
    _lwa_interval: any = { 'category': 'company', 'sub_category': 'agents', 'name': 'lwa_interval', 'value': { minutes: 15, from_time: "", to_time: "" } };
    _asset_detention_days: any = { 'category': 'company', 'sub_category': 'assets', 'name': 'asset_detention_days', 'value': [] };
    _insecure_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'insecure_ports', 'value': [] }
    _denied_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'denied_ports', 'value': [] };
    _excluded_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'excluded_ports', 'value': [] }
    _allowed_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'allowed_ports', 'value': [] };
    _secure_ports: any = { 'category': 'assets', 'sub_category': 'ports', 'name': 'secure_ports', 'value': [] };
    _timezone: any = { 'category': 'company', 'sub_category': 'settings', 'name': 'timezone', 'value': { value: '' } };
    _enable_patching: any = {
        'category': 'patching',
        'sub_category': 'permissions',
        'name': 'enable_patching',
        'value': { patching_status: false, is_eula_accepted: false }
    };
    _default_tags: any = {
        'category': 'company',
        'sub_category': 'settings',
        'name': 'default_tags',
        'value': { value: false }
    };
    _ad_audit_scan: any = {
        'category': 'company',
        'sub_category': 'settings',
        'name': 'ad_audit_scan',
        'value': { value: false }
    };
    _remote_agent_install: any = {
        'category': 'company',
        'sub_category': 'settings',
        'name': 'remote_agent_install',
        'value': { value: false }
    };
    _security_report: any = {
        'category': 'company',
        'sub_category': 'settings',
        'name': 'security_report',
        'value': []
    };

    _suppress_vulnerabilities_days: any =
        {
            'category': 'company',
            'sub_category': 'suppress_vulnerabilities_days',
            'name': 'suppress_vulnerabilities_days',
            'value': { value: 0 }
        };

    _compliance_scan: any = {
        'category': 'company',
        'sub_category': 'settings',
        'name': 'compliance_scan',
        'value': []
    };

    scanInterval: any = [
        { name: '15 Minutes', value: 15 },
        { name: '30 Minutes', value: 30 },
        { name: '60 Minutes', value: 60 },
        { name: '90 Minutes', value: 90 },
        { name: '2 Hours', value: 120 },
        { name: '4 Hours', value: 240 },
        { name: '6 Hours', value: 360 },
        { name: '8 Hours', value: 480 },
        { name: '24 Hours', value: 1440 },
    ]
    deprecation_days: any = [
        {
            label: "Asset Deprecation Days",
            key: '_asset_deprecation_days',
            required: false,
            example: 'Ex. Min 3 days to Max 365 days',
            'type': 'number'
        },
        {
            label: "Agent Deprecation Days",
            key: '_agent_deprecation_days',
            required: false,
            example: 'Ex. Min 3 days to Max 365 days',
            'type': 'number'
        },
        // {
        //     label: "Suppress Vulnerabilities Days",
        //     key: '_microsoft_suppress_days',
        //     required: false,
        //     example: 'Ex. Min 3 days to Max 365 days',
        //     'type': 'number'
        // }
    ];
    deprecationDaysParams: any = {
        _asset_deprecation_days: 90,
        _agent_deprecation_days: 90,
        // _microsoft_suppress_days: 90
    };
    _asset_deprecation_days: any = {
        'category': 'assets',
        'sub_category': 'settings',
        'name': 'asset_deprecation_days',
        'value': null
    };
    _agent_deprecation_days: any = {
        'category': 'agents',
        'sub_category': 'settings',
        'name': 'agent_deprecation_days',
        'value': null
    };
    _microsoft_suppress_days: any = {
        'category': 'assets',
        'sub_category': 'settings',
        'name': 'microsoft_suppress_days',
        'value': null
    };
    _other_suppress_days: any = {
        'category': 'assets',
        'sub_category': 'settings',
        'name': 'other_suppress_days',
        'value': null
    };
    _microsoft_global_suppress_days: any = { id: 'microsoft_suppress_days', value: null };
    _other_global_suppress_days: any = { id: 'other_suppress_days', value: null };
    discardGloabal: any = ['microsoft_suppress_days', 'other_suppress_days'];
    timezoneSearchControl: UntypedFormControl = new UntypedFormControl();
    public searching = false;

    @ViewChild('dropzonefile', { static: false }) private dropzonefile: ElementRef;
    fileName: any = '';

    patchingContent = `<div class="flex flex-col gap-2">
<div>IMPORTANT!</div>
<div>PLEASE READ THE TERMS AND CONDITIONS OF THIS LICENSE AGREEMENT CAREFULLY BEFORE CONTINUING WITH THIS PROGRAM INSTALL.
</div>
<div>In plain English: ConnectSecure  LLC Patch Management
Solution is available as a  software feature that is provided free of
cost on a "As-is" and "As available" basis for use in conjunction with a ConnectSecure
  LLC Subscription or made available for evaluation during a free trial period. You may install and use updates provided by ConnectSecure  LLC during these periods; however, when your subscription or trial period terminates you must remove and destroy the software. Please contact ConnectSecure  LLC to obtain or renew your subscription.</div>
<div>ConnectSecure  LLC "PATCHING FEATURE" UPDATE END-USER LICENSE AGREEMENT 2.0</div>
<div>This ConnectSecure  LLC Update End-User License Agreement ("EULA") constitutes a
legal agreement between you, the "End User" (either an individual or a single entity)
and ConnectSecure  LLC for the ConnectSecure  LLC software updates you download and install
 (the Update). By installing, copying, or otherwise using this Update you agree to be bound by these terms. If you do not agree to the terms of this EULA, do not install or use the Update. The Update is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. The Update is licensed, not sold.</div>
<div>Definitions</div>
<div>A "Product" is a software package made available by ConnectSecure  LLC and subject to the terms thereof
</div>
<div>A "Licensed Product" is a Product that is covered by an authorized commercial agreement between the End User and ConnectSecure, LLC , or between the End User and a third party (Partner/OEM/MSP/MSSP) that has an
authorized commercial agreement with ConnectSecure, LLC .</div>
<div>"Term" means the term as defined in a license agreement for a Licensed Product, or the duration of any trial period extended to the End User</div>
<div>An "Update" is a software component made available by ConnectSecure  LLC that augments the capability or addresses an issue with an installed software.</div>
<div>Grant of License</div>
<div>Subject to the terms and conditions contained herein and any applicable Subscription Agreement, including the usage limits described therein, ConnectSecure  LLC grants the End User a non-exclusive,
non-transferrable, renewable license to use the Update as follows</div>
<div>Installation and Use: ConnectSecure  LLC grants you the right to install and use copies of the Update in a Licensed Product during the Term. You shall not exceed the number of licenses, tiers, nodes, or other use
 restrictions or authorizations agreed to and paid for in the commercial agreement.</div>
<div>Backup Copies: You may make copies of the Update as may be necessary for backup and archival purposes.</div>
<div>Other Limitations</div>
<div>Maintenance of Copyright Notices: You shall not remove or alter any copyright or license notices that appear in or on the Update.</div>
<div>Modification: You shall not modify, alter, decompile, decrypt, disassemble, translate, or reverse engineer the Update.</div>
<div>Distribution: You shall not distribute copies of the Update to third parties.</div>
<div>Rental: You shall not rent, lease, or lend the Update, including renting, leasing, or lending a Product that includes the Update.</div>
<div>SaaS: You shall not make the Update or Product that includes the Update available as commercial Software-as-a-Service.</div>
<div>Compliance with Applicable Laws: You shall comply with all applicable laws regarding the use of the Update.</div>
<div>Termination</div>
<div>These terms, including the Software License, shall expire at the end of the Term, unless Licensee and ConnectSecure  LLC agrees to renew such License. Without prejudice to any other rights, ConnectSecure  LLC may terminate this EULA if you fail to comply with the terms and conditions of this EULA.</div>
<div> Upon expiration or termination of these terms, you shall uninstall and destroy all copies of the ConnectSecure  LLC Software in your possession. Termination or expiration of this EULA shall not affect End Users' rights to use any Product in accordance with the terms and conditions of the applicable EULA, or to receive services in accordance
 with a support agreement, or other agreement as may be applicable.</div>
<div>Copyright</div>
<div>All right, title and interest, including but not limited to intellectual property rights such as copyrights, in and to the Update and any copies thereof, are owned by ConnectSecure  LLC or its suppliers. All right, title and interest, including but not limited to intellectual property rights such as copyrights, in and to the content which may be accessed through use of the Update is the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties. This EULA grants you no rights to use such content. All rights not expressly granted are reserved by ConnectSecure, LLC ..</div>
<div>No Warranties</div>
<div>UNLESS EXPRESSLY PROVIDED HEREIN TO THE CONTRARY, AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND ANY AND ALL SERVICES AND SOFTWARE INCLUDING PATCHES AND SERVICE PACKS PROVIDED BY ConnectSecure  LLC HEREUNDER ARE PROVIDED "AS IS" WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, INCLUDING IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. NO WARRANTY, WRITTEN OR ORAL, IS EXPRESSED OR IMPLIED BY CONNECTSECURE  LLC OR MAY BE INFERRED FROM A COURSE OF DEALING OR USAGE OF TRADE. NOTHING STATED IN THIS AGREEMENT WILL IMPLY THAT THE OPERATION OF ANY SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ERRORS WILL BE CORRECTED..</div>
<div>Limitation of Liability</div>
<div>IN NO EVENT WILL CONNECTSECURE LLC BE LIABLE FOR ANY INCIDENTAL INDIRECT, SPECIAL, OR CONSEQUENTIAL COSTS OR DAMAGES INCLUDING, WITHOUT LIMITATION, DOWNTIME COSTS; LOST BUSINESS, REVENUES, OR PROFITS; FAILURE TO REALIZE EXPECTED SAVINGS; LOSS OF OR DAMAGE TO DATA; OR SOFTWARE RESTORATION, REGARDLESS OF WHETHER ANY OF THE FOREGOING ARE FORESEEABLE, AND REGARDLESS OF WHETHER ConnectSecure  LLC HAS BEEN NOTIFIED OF THE POSSIBILITY OF ANY OF THE FOREGOING. THESE LIMITATIONS WILL APPLY REGARDLESS OF THE BASIS OF LIABILITY; INCLUDING NEGLIGENCE; MISREPRESENTATION; BREACH; LIBEL; INFRINGEMENT OF PUBLICITY, PRIVACY, OR INTELLECTUAL PROPERTY RIGHTS; OR ANY OTHER CONTRACT OR TORT CLAIM.</div>
<div>Disclaimer of Warranty. YOU ACKNOWLEDGE THAT THE PATCHING FEATURE  is "Beta" Software AND ANY AND ALL PARTS THEREOF ARE PROVIDED “AS IS” and "AS AVAILABLE". ConnectSecure  LLC MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE LICENSED MATERIALS AND OR PARTS THEREOF WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE THAT YOU HAVE RELIED ON NO WARRANTIES AND THAT NO WARRANTIES ARE MADE BY CONNECTSECURE  LLC OR GRANTED BY LAW WHENEVER IT IS PERMITTED BY LAW.
</div>
<div>BY CLICKING “I ACCEPT”, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT AND ACCEPT THE PRODUCT’S FUNCTIONS. DO NOT CLICK THE “I ACCEPT”BUTTON IF YOU DO NOT ACCEPT THIS AGREEMENT AND THE PRODUCT’S FUNCTIONS.</div>
</div>`

    constructor(public cs: CommonService, private _bs: BaseRequestService, private _toast: MyToastrService, private _formBuilder: UntypedFormBuilder,
        private _changeDetectorRef: ChangeDetectorRef, public uS: UserService, public modalService: ModalService, private loaderService: LoaderService, public confirmDialog: FuseConfirmationService) {
        this.cs.selectedSiteChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                // this.getExcludeSecurityReport();
                // this.getTypes();
                this._enable_patching = {
                    'category': 'patching',
                    'sub_category': 'permissions',
                    'name': 'enable_patching',
                    'value': { patching_status: false, is_eula_accepted: false }
                };
                this._microsoft_suppress_days = {
                    'category': 'assets',
                    'sub_category': 'settings',
                    'name': 'microsoft_suppress_days',
                    'value': null
                };
                this._other_suppress_days = {
                    'category': 'assets',
                    'sub_category': 'settings',
                    'name': 'other_suppress_days',
                    'value': null
                };
                this._remote_agent_install = {
                    'category': 'company',
                    'sub_category': 'settings',
                    'name': 'remote_agent_install',
                    'value': { value: false }
                };
                this.getCompanySetting();
            });
    }


    ngOnInit(): void {
        this.getExcludeSecurityReport();
        this.cs.getTimeZone();
        this.getTypes();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    selectAllToggle1(): void {
        this.selectAll = this.selectAll; // Ensure toggling works correctly
        this.resultSets.forEach((prod: any) => {
            prod.selected = this.selectAll;
        });
        this.updateSelectedCount(); // Update selected count when select all toggled
        this._changeDetectorRef.detectChanges();
    }

    updateSelectAllState(): void {
        this.selectAll = this.isAllSelected1();
        this.updateSelectedCount(); // Update selected count when selection changes
    }

    hasSelected1(): boolean {
        return this.resultSets.some((x: any) => x.selected);
    }

    isAllSelected1(): boolean {
        return this.resultSets.length > 0 && this.resultSets.every((x: any) => x.selected);
    }

    updateSelectedCount(): void {
        this.selectedCount = this.resultSets.filter((x: any) => x.selected).length;
    }

    getCompanySetting(): void {
        this.loaderService.display(true);
        const params: any = { condition: true, skip: 0, limit: 100 }
        if (this.cs.currentScope !== '*') {
            params.condition = `(company_id='${parseInt(this.cs.currentScope.id)}' or company_id IS NULL)
             and asset_id IS NULL and category NOT IN ('table_settings', 'tenant', 'settings')`
        }
        this._bs.doRequest(`/r/company/settings`, 'get', null, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    const settingKeys: any = ['_insecure_ports', '_denied_ports', '_excluded_ports', '_allowed_ports', '_secure_ports', '_lwa_interval', '_brute_force', '_performance_count', '_asset_detention_days', '_timezone',
                        '_asset_deprecation_days', '_agent_deprecation_days', '_microsoft_suppress_days', '_other_suppress_days', '_security_report', '_compliance_scan', '_remote_agent_install', '_enable_patching', '_suppress_vulnerabilities_days'];
                    settingKeys.forEach((key: any) => {
                        const data = result.data.filter((x: any) => `_${x.name}` === key);
                        // console.log('data --->',data)
                        // @ts-ignore
                        if (this[key]) {
                            const filterCmp = data.filter((s: any) => s.company_id);
                            const filterGlo = data.filter((s: any) => !s.company_id);
                            if (this.portsParams.hasOwnProperty(key)) {
                                this.portsParams[key] = (filterCmp && filterCmp.length)
                                    ? filterCmp[0].value : (filterGlo && filterGlo.length) ? filterGlo[0].value : this.portsParams[key];
                            } else if (this.deprecationDaysParams.hasOwnProperty(key)) {
                                this.deprecationDaysParams[key] = (filterCmp && filterCmp.length)
                                    ? filterCmp[0].value : (filterGlo && filterGlo.length) ? filterGlo[0].value : this.deprecationDaysParams[key];
                            }
                            if (this.discardGloabal.indexOf(this[key].name) > -1) {
                                if (this[key].name === 'microsoft_suppress_days' && filterCmp && !filterCmp.length) {
                                    this._microsoft_global_suppress_days = { id: 'microsoft_suppress_days', value: (data[0] && data[0].value !== undefined) ? data[0].value : null };
                                } else if (this[key].name === 'other_suppress_days' && filterCmp && !filterCmp.length) {
                                    this._other_global_suppress_days = { id: 'other_suppress_days', value: (data[0] && data[0].value !== undefined) ? data[0].value : null };
                                }
                                this[key] = {
                                    ...this[key], ...{
                                        id: (filterCmp && filterCmp.length) ? filterCmp[0].id : null,
                                        value: (filterCmp && filterCmp.length) ? filterCmp[0].value : this[key].value
                                    }
                                };
                            } else {
                                this[key] = {
                                    ...this[key], ...{
                                        id: (filterCmp && filterCmp.length) ? filterCmp[0].id : null,
                                        value: (filterCmp && filterCmp.length)
                                            ? filterCmp[0].value : (filterGlo && filterGlo.length) ? filterGlo[0].value : this[key].value
                                    }
                                };
                            }

                            /*if (key === '_security_report' && filterCmp && filterCmp.length) {
                                this.excludeReportListAll.map((x: any) => {
                                    if (filterCmp[0].value && filterCmp[0].value.indexOf(x.value) !== -1) {
                                        x.selected = true;
                                    } else {
                                        x.selected = false;
                                    }
                                })
                            }
                            if (key === '_compliance_scan') {
                                if (filterCmp && filterCmp.length) {
                                    this.complianceListAll.map((x: any) => {
                                        if (filterCmp[0].value && filterCmp[0].value.indexOf(x.value) !== -1) {
                                            x.selected = true;
                                        } else {
                                            x.selected = false;
                                        }
                                    })
                                } else if (filterGlo && filterGlo.length) {
                                    this.complianceListAll.map((x: any) => {
                                        if (filterGlo[0].value && filterGlo[0].value.indexOf(x.value) !== -1) {
                                            x.selected = true;
                                        } else {
                                            x.selected = false;
                                        }
                                    })
                                }
                            }*/
                        }
                    });

                    let selectedSuppressType: string | null = null;

                    result.data.forEach((setting: any) => {
                        const key = `_${setting.name}`;
                        if (settingKeys.includes(key)) {
                            // Check for specific settings to set the dropdown value
                            if (setting.name === 'microsoft_suppress_days') {
                                selectedSuppressType = 'microsoft';
                            } else if (setting.name === 'other_suppress_days') {
                                selectedSuppressType = 'other';
                            }
                        }
                    });

                    // Set the selected type based on the data received
                    this.selectedSuppressType = selectedSuppressType;

                    if (this.cView === 'compliance-scan') {
                        this.loaderService.display(true);
                        setTimeout(() => {
                            this.complianceScan();
                        }, 1000);
                    }

                } else {
                    const data = (result.message) ? result.message : result.data; this._toast.sToast('error', data)
                }
            })
    }

    isValidDays(day: any): boolean {
        return (day >= 3 && day <= 365);
    }

    isValidDaysNotZero(day: any): boolean {
        if (day === 0) { return true };
        return (day >= 0 && day <= 365);
    }

    enablePatchingStatus(): void {
        const value = cloneDeep(this._enable_patching.value);
        this.saveSettings({
            _enable_patching: {
                patching_status: value.patching_status,
                is_eula_accepted: (!value.patching_status) ? false : value.is_eula_accepted
            }
        });
    }

    enableDefaultTagStatus(): void {
        this.saveSettings({ _default_tags: this._default_tags.value });
    }

    enableAdAuditScan(): void {
        this.saveSettings({ _ad_audit_scan: this._ad_audit_scan.value });
    }

    enableBruteForce(): void {
        this.saveSettings({ _brute_force: this._brute_force.value });
        setTimeout(() => {
            this.sendBruteForce();
        }, 2000);
    }

    sendBruteForce(): void {
        const ports = (this._brute_force.value.brute_force_ports && this._brute_force.value.brute_force_ports.length) ? this._brute_force.value.brute_force_ports.map((x: any) => x = parseInt(x)) : []
        const reqData: any = { company_id: parseInt(this.cs.currentScope.id), brute_force: this._brute_force.value.brute_force, brute_force_ports: ports, message: 'brute_force_enabled' };
        this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => { });
    }

    savePerformanceCount(): void {
        const maxNmap = 4;
        const maxSmb = 40;

        if (this._performance_count && this._performance_count.value) {
            const nmapValue = this._performance_count.value.nmap;
            const smbValue = this._performance_count.value.smb;

            if (nmapValue > maxNmap || smbValue > maxSmb) {
                this._toast.sToast('error', 'Maximum value exceeded for NMAP or SMB.');
                return;
            } else {
                this.saveSettings({ _performance_count: this._performance_count.value });
                setTimeout(() => {
                    this.savePerformanceM();
                }, 2000);
            }
        }
    }

    saveOtherVulnerabilitiesdays(): void {
        this.saveSettings({ _other_suppress_days: this._other_suppress_days.value });
        this._other_global_suppress_days = { id: '', value: null };
    }

    saveMicrosoftVulnerabilitiesdays(): void {
        this.saveSettings({ _microsoft_suppress_days: this._microsoft_suppress_days.value });
        this._microsoft_global_suppress_days = { id: '', value: null };
    }

    // saveSuppressVulnerabilitiesdays(): void {
    //     this.saveSettings({ _suppress_vulnerabilities_days: this._suppress_vulnerabilities_days.value });
    //   }

    saveretentionperiodcount(): void {
        this.saveSettings({ _asset_detention_days: this._asset_detention_days.value });
    }

    savePerformanceM(): void {
        const reqData: any = { company_id: parseInt(this.cs.currentScope.id), message: "probe_setting", probe_setting: this._performance_count.value };
        this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                console.log("result");
            });
    }


    enableRemoteAgentInstall(): void {
        this.saveSettings({ _remote_agent_install: this._remote_agent_install.value });
        setTimeout(() => {
            this.remoteAgentInstall();
        }, 2000);
    }
    remoteAgentInstall(): void {
        const reqData: any = { company_id: parseInt(this.cs.currentScope.id), remote_agent_install: this._remote_agent_install.value.value };
        this._bs.doRequest('/w/company/remote_install', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => { });
    }
    saveTimezone(): void {
        this.saveSettings({ _timezone: this._timezone.value });
    }
    updateExcludeReport(): void {
        const selectedItem = this.excludeReportListAll.filter((x: any) => x.selected).map((s: any) => s.value);
        if ((!selectedItem || !selectedItem.length) && !this._security_report.id) {
            this._toast.sToast('error', 'Please select at least one component');
            return;
        }
        this.saveSettings({ _security_report: selectedItem });
    }

    resetOtherVulnerabilities() {
        this.loaderService.display(true);
        this._bs.doRequest(`/d/company/settings/${this._other_suppress_days.id}`, 'delete',)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this._toast.sToast('success', 'Reset successfully');
                    this._other_suppress_days.value = null;
                    delete this._other_suppress_days.id;
                    this.getCompanySetting();
                } else {
                    this._toast.sToast('error', result.message);
                }
            });
    }


    resetSuppressVulnerabilities() {
        this.loaderService.display(true);
        this._bs.doRequest(`/d/company/settings/${this._microsoft_suppress_days.id}`, 'delete',)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this._toast.sToast('success', 'Reset successfully');
                    this._microsoft_suppress_days.value = null;
                    delete this._microsoft_suppress_days.id;
                    this.getCompanySetting();
                } else {
                    this._toast.sToast('error', result.message);
                }
            });
    }

    saveLWAInterval(reset?: any): void {
        delete this._lwa_interval.value.value
        if (reset) {
            this._lwa_interval.value.minutes = 15;
            this._lwa_interval.value.from_time = "";
            this._lwa_interval.value.to_time = "";
        }
        this.saveSettings({ _lwa_interval: this._lwa_interval.value });
        setTimeout(() => {
            this.resetAgent(reset);
        }, 2000);
    }

    // resetAgent(): void {
    //     const reqData: any = { message: 'lwt_settings', minutes: parseInt(this._lwa_interval.value.minutes,this._lwa_interval.value.from_time,this._lwa_interval.value.to_time), company_id: parseInt(this.cs.currentScope.id) };
    //     this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((result: any) => { });
    // }

    resetAgent(reset?: any): void {
        const reqData: any = {
            company_id: parseInt(this.cs.currentScope.id),
            message: 'lwt_settings',
            lwt_settings: {
                minutes: parseInt(this._lwa_interval.value.minutes),
                from_time: (reset) ? "-:-" : this._lwa_interval.value.from_time,
                to_time: (reset) ? "-:-" : this._lwa_interval.value.to_time
            }
        };
        this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => { });
    }

    updateComplianceType(): void {
        const selectedItem = this.complianceListAll.filter((x: any) => x.selected).map((s: any) => s.value);
        if ((!selectedItem || !selectedItem.length) && !this._compliance_scan.id) {
            this._toast.sToast('error', 'Please select at least one compliance');
            return;
        }
        const newlyAddedValues = cloneDeep(selectedItem.filter((value: any) => !this._compliance_scan.value.includes(value)));
        this._compliance_scan.value = selectedItem;
        this.saveSettings({ _compliance_scan: selectedItem });
        if (newlyAddedValues && newlyAddedValues.length) {
            const reqData: any = { message: 'compliance_scan', compliance_added: newlyAddedValues, company_id: parseInt(this.cs.currentScope.id) };
            this.resetCompliance(reqData)
        }
    }

    resetCompliance(reqData: any): void {
        this._bs.doRequest('/w/company/reset_agents', 'post', reqData).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => { });
    }




  savePortPolicy(reset?: any): void {
    let finalObj: any = {};
     Object.keys(reset).forEach((obj: any, index: any) => {
       if (obj === '_allowed_ports' || obj === '_denied_ports' || obj === '_excluded_ports' || obj === '_insecure_ports' || obj === '_secure_ports') {
         let err = '';
         reset[obj].forEach((port: any) => {
           if (!this.cs.isValidPort(port)) {
             err += `Port ${port} is not valid. `
           }
         });
         if (err && err.length) {
           this._toast.sToast('error', err);
           return;
         }
       }
       finalObj[obj] = reset[obj]; 
     })
     this.saveSettings(reset) 
     setTimeout(() => {
       this.resetAgentPortPolicy(finalObj);
     }, 2000);
   }
   resetAgentPortPolicy(value){
     const reqData: any = {
       company_id: parseInt(this.cs.currentScope.id),
       message: 'agent_port_policy_reset',
       port_policy:value
     };
     this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
     .pipe(takeUntil(this._unsubscribeAll))
     .subscribe((result: any) => { });
   }
 
    saveSettings(value: any): void {
        const valuesArray = Object.keys(value);
        Object.keys(value).forEach((obj: any, index: any) => {
            if (value[obj] !== '' || value[obj].length > 0) {
                if (this[obj]) {
                    const validateDaya: any = ['_asset_deprecation_days', '_agent_deprecation_days', '_asset_detention_days', '_microsoft_suppress_days', '_other_suppress_days'];
                    if (validateDaya.indexOf(obj) > -1) {
                        if (!this.isValidDays(cloneDeep(value[obj])) && obj !== '_microsoft_suppress_days' && obj !== '_other_suppress_days') {
                            this._toast.sToast('info', 'Please enter valid days!');
                            return;
                        }
                        if (!this.isValidDaysNotZero(cloneDeep(value[obj])) && (obj === '_microsoft_suppress_days' || obj === '_other_suppress_days')) {
                            this._toast.sToast('info', 'Please enter valid days!');
                            return;
                        }
                    }
                    if (obj === '_allowed_ports' || obj === '_denied_ports' || obj === '_excluded_ports' || obj === '_insecure_ports') {
                        let err = '';
                        value[obj].forEach((port: any) => {
                            if (!this.cs.isValidPort(port)) {
                                err += `Port ${port} is not valid.`
                            }
                        });
                        if (err && err.length) {
                            this._toast.sToast('info', err);
                            return;
                        }
                    }
                    // @ts-ignore
                    const $event: any = cloneDeep(this[obj]);
                    if ($event.id === null) {
                        delete $event.id
                    }
                    $event.value = value[obj];
                    this.loaderService.display(true);
                    const method = ($event.id) ? 'patch' : 'post';
                    const msg = ($event.id) ? 'Updated' : 'Saved';
                    $event.company_id = this.cs.currentScope.id;
                    const request: any = { data: $event };
                    if ($event.id) {
                        request.id = $event.id
                    }
                    this._bs.doRequest('/w/company/settings', method, request)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            if (result.status) {
                                if (index === valuesArray.length - 1) {
                                    if (method === 'post') {
                                        this[obj].id = parseInt(result.id);
                                    }
                                    setTimeout(() => {
                                        this.loaderService.display(false);
                                        this._toast.sToast('success', `${msg} successfully!`);
                                        this._changeDetectorRef.detectChanges();
                                    }, 3000);
                                }

                            } else {
                                const data = (result.message) ? result.message : result.data; this._toast.sToast('error', data);
                                setTimeout(() => {
                                    this.loaderService.display(false);
                                }, 3000);
                            }
                        })
                }
            }
        })
    }

    saveCompany($event: any) {
        this.loaderService.display(true);
        this._bs.doRequest('/w/company/companies', 'post', { data: $event })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this._toast.sToast('success', `Company ${$event.name} updated successfully!`);
                    this._changeDetectorRef.markForCheck();
                } else {
                    const data = (result.message) ? result.message : result.data; this._toast.sToast('error', data);
                }
            })
    }

    hasSelected(key: any): boolean {
        // @ts-ignore
        const len = this[key].filter((x: any) => x.selected);
        if (len && len.length) {
            return true;
        } else {
            return false;
        }
    }

    isAllSelected(key: any): boolean {
        // @ts-ignore
        const len = this[key].filter((x: any) => x.selected).length;
        // @ts-ignore
        if (this[key].length === len) {
            return true;
        }
        return false;
    }

    selectAllToggle(event: any, key: any): void {
        // @ts-ignore
        this[key].forEach((obj: any) => {
            obj.selected = event.checked;
        });
    }

    getExcludeSecurityReport(): void {
        this.loaderService.display(true);
        this._bs.doRequest('/r/company/jsonconfigs/security_report_card_supress', 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.excludeReportListAll = result.data;
                    this.getCompanySetting();
                    this._changeDetectorRef.markForCheck();
                } else {
                    this.getCompanySetting();
                    const data = (result.message) ? result.message : result.data; this._toast.sToast('error', data);
                }
            })
    }

    getTypes(): void {
        this.loaderService.display(true);
        this._bs.doRequest(`/r/compliance/types`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    this.complianceListAll = [];
                    res.data.map((obj: any) => {
                        this.complianceListAll.push({ name: obj.name, value: obj.table_name, selected: false });
                    });
                    // [...new Set(res.data.flatMap((obj: any) => Object.keys(obj)))].forEach((obj: any) => {
                    //     this.complianceListAll.push({name: obj, selected: false});
                    // });
                } else {
                    this._toast.sToast('error', 'No compliance configured!')
                }
            });
    }

    complianceScan(): void {
        // cView = 'compliance-scan'"
        try {
            this.loaderService.display(true);
            this.complianceListAll.map((x: any) => {
                if (this._compliance_scan.value.indexOf(x.value) > -1) {
                    x.selected = true;
                } else {
                    x.selected = false;
                }
            });
        } catch (error) {
            this.loaderService.display(false);
        }
        setTimeout(() => {
            this.loaderService.display(false);
            this.cView = 'compliance-scan';
        }, 500);
    }

    excludeReport(): void {
        // cView = 'compliance-scan'"
        try {
            this.loaderService.display(true);
            this.excludeReportListAll.map((x: any) => {
                if (this._security_report.value.indexOf(x.value) > -1) {
                    x.selected = true;
                } else {
                    x.selected = false;
                }
            });
        } catch (error) {
            this.loaderService.display(false);
        }
        setTimeout(() => {
            this.loaderService.display(false);
            this.cView = 'exc-secreport-card';
        }, 500);
    }
    addPort(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        // Add our key
        if (value) {
            this._brute_force.value.brute_force_ports.push(value);
        }
        // Clear the input value
        event.chipInput!.clear();
    }

    removePort(key: any): void {
        const index = this._brute_force.value.brute_force_ports.indexOf(key);
        if (index >= 0) {
            this._brute_force.value.brute_force_ports.splice(index, 1);
        }
    }

    editPort(key: any, event: MatChipEditedEvent) {
        const value = event.value.trim();
        // Remove key if it no longer has a name
        if (!value) {
            this.removePort(key);
            return;
        }
        // Edit existing key
        const index = this._brute_force.value.brute_force_ports.indexOf(key);
        if (index >= 0) {
            this._brute_force.value.brute_force_ports[index] = value;
        }
    }

    // uploadFn($event: any, key: any): void {
    //     if (!$event.target.files.length) {
    //         this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
    //         return;
    //     }
    //     const allowedTypes = ["json", 'application/json'];
    //     const file = $event.target.files[0];

    //     if (file) {
    //         const maxSizeInBytes = 524288; // 524 kB (1 kB = 1024 bytes)
    //         if (file.size > maxSizeInBytes) {
    //             // Handle the case where the file size exceeds the limit
    //             this._toast.sToast("error", "File size exceeds the limit.");
    //             $event.target.value = null; this.fileName = ""; this.uploadfile = null;
    //             return;
    //         }
    //     }
    //     if (!allowedTypes.includes(file.type)) {
    //         this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
    //         return;
    //     }
    //     try {
    //         this.fileName = $event.target.files[0].name;
    //         // @ts-ignore
    //         this[key] = $event.target.files[0];
    //     } catch (error) {
    //         this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
    //     }

    // }

    // uploadAssetData(): void {
    //     if (!this.uploadfile) {
    //         this._toast.sToast("error", `No file selected.`);
    //         return;
    //     }
    //     this.loaderService.display(true);
    //     const formData = new FormData();
    //     formData.append("uploadfile", this.uploadfile);
    //     formData.append("company_id", this.cs.currentScope.id);
    //     this._bs.uploadFormData("/w/company/upload_asset_data", formData)
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe(
    //             (responseData: any) => {
    //                 this.loaderService.display(false);
    //                 if (responseData.status) {
    //                     this._toast.sToast("success", `Asset data has been updated successfully.`);
    //                     this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
    //                     this._changeDetectorRef.detectChanges();
    //                 } else {
    //                     const data = (responseData.message) ? responseData.message : responseData.data; this._toast.sToast('error', data);
    //                 }
    //             },
    //             (error: any) => {
    //                 console.error("Error posting file to API:", error);
    //                 this.loaderService.display(false);
    //                 this.uploadfile = null; this.fileName = ""; this.dropzonefile.nativeElement.value = '';
    //                 this._changeDetectorRef.detectChanges();
    //             }
    //         );
    // }
}
