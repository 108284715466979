<ng-container *ngIf="type === 'list'">
  <div *ngIf="showOrder === 'list' && darkBg">
    <ul>
      <li *ngFor="let item of items | search: searchInput; let i = index"
        [class.hidden]="i >= maxItemsToShow && !showAllItems">
        {{ item }}
      </li>
    </ul>
    <button (click)="toggleShowItems()">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>

  <div *ngIf="showOrder === 'span' && darkBg">
    <div class="flex flex-row flex-wrap items-center gap-2 mt-2"
      *ngIf="items && (!hyperlinkList || !hyperlinkList.length)">
      <span *ngFor="let p of items | search: searchInput;; let i = index"
        [class.hidden]="i >= maxItemsToShow && !showAllItems" class="{{bgColor}} {{color}}
        inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg {{darkBg}} {{darkColor}}">{{p}}</span>
    </div>
    <div class="flex flex-row flex-wrap items-center gap-2 mt-2" *ngIf="items && hyperlinkList && hyperlinkList.length">
      <span *ngFor="let p of hyperlinkList | search: searchInput; let i = index" (click)="redirect(p,i)"
        [class.hidden]="i >= maxItemsToShow && !showAllItems" class="cursor-pointer
        inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg"
        [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': p.status === 'Open',
                                                    'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': p.status === 'Closed'}">
        <span *ngIf="p.ticket_number && p.ticket_id" [matTooltip]="p.status">{{p.ticket_number}}</span>
        <span *ngIf="p.ticket_id && !p.ticket_number" [matTooltip]="p.status">{{p.ticket_id}}</span>
      </span>
    </div>
    <button (click)="toggleShowItems()" class="mt-2 text-sm text-blue-600"
      *ngIf="items && items.length > maxItemsToShow">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>

  <div *ngIf="showOrder === 'href' && darkBg">
    <div class="flex flex-row flex-wrap items-center mt-2" *ngIf="items">
      <span *ngFor="let p of items | search: searchInput;; let i = index" (click)="clickOnDomain(p)"
        [class.hidden]="i >= maxItemsToShow && !showAllItems"
        class="cursor-pointer bg-blue-100 text-black text-md font-semibold ml-0 mb-2 me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white border border-blue-400 inline-flex items-center justify-center">{{p}}</span>
    </div>
    <button (click)="toggleShowItems()" class="mt-2 text-sm text-blue-600"
      *ngIf="items && items.length > maxItemsToShow">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>

  <div *ngIf="showOrder === 'asm' && darkBg">
    <div class="flex flex-row flex-wrap items-center mt-2" *ngIf="items">
      <span *ngFor="let p of items | search: searchInput;; let i = index"
        [class.hidden]="i >= maxItemsToShow && !showAllItems"
        class="bg-blue-100 text-black text-md font-semibold ml-0 mb-2 me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white border border-blue-400 inline-flex items-center justify-center">{{p}}</span>
    </div>
    <button (click)="toggleShowItems()" class="mt-2 text-sm text-blue-600"
      *ngIf="items && items.length > maxItemsToShow">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="type === 'string'">
  <div *ngIf="columnKey === 'Software Name'">
    <span class="text-md">
      {{ showFullText ? inputString : (inputString.length > 30 ? (inputString | slice: 0: 30) + '...' : inputString) }}
    </span>
    <span class="text-blue-500 cursor-pointer" *ngIf="inputString.length > 30" (click)="toggleSoftwareNameDisplay()">
      {{ showTxt }}
    </span>
  </div>
</ng-container>

<ng-container *ngIf="type === 'string' && columnKey !== 'Software Name'">
  <div class="text-md">
    <span [ngClass]="{'break-all': wordBreak}">
      {{ inputString.length > firstCount ? (inputString | slice: 0:counter) + '...' : inputString }}
    </span>
    <span class="text-blue-500 cursor-pointer" *ngIf="inputString.length > firstCount" (click)="toggleSkil($event)">
      {{ showTxt }}
    </span>
  </div>
</ng-container>

<!-- Software Detail -->
<s-modal id="sotwareDetailsPopup" *ngIf="isloading">
  <mat-card class="modal w-[600px] p-0">
    <mat-card-content fuseScrollbar>
      <div
        class="flex flex-col flex-auto p-4 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col sm:flex-row items-center">
          <div class="text-lg font-medium tracking-tight leading-6 truncate">
            {{columnKey}}
            <span class="ml-1 px-2 bg-primary text-white rounded py-0.5 text-sm"
              *ngIf="showmorepopData&& showmorepopData.length > 0">{{
              showmorepopData.length }}</span>
          </div>
          <mat-form-field class="ml-auto w-50 mini-form-field ml-1" floatLabel="never">
            <input matInput name="search" [(ngModel)]="searchTerm" (ngModelChange)="filterCompany()"
              placeholder="Search Software" autocomplete="off">
            <button *ngIf="!searchTerm || searchTerm === ''" aria-label="Search" mat-icon-button matSuffix>
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <button class="" mat-icon-button
            (click)="modalService.close('sotwareDetailsPopup');searchTerm = '';isloading = false">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
          </button>
        </div>
        <mat-divider class="my-2"></mat-divider>
        <div class="flex flex-col items-start p-2" *ngIf="showmorepopData&& showmorepopData.length > 0">
          <table class="table-auto w-full">
            <tbody class="text-sm font-light">
              <tr class="border-b" *ngFor="let rec of showmorepopData | search:searchTerm; let i = index">
                <td class="py-1 px-4 text-left text-md">{{i+1}}.</td>
                <td class="py-1 px-4 text-left font-light">
                  <span class="whitespace-no-wrap py-4 text-md font-semibold text-blue-600 dark:text-white">{{ rec
                    }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!showmorepopData || showmorepopData.length === 0">
          <div class="flex flex-col items-center justify-center gap-y-2">
            <img src="/assets/images/empty.png" alt="" class="w-20">
            <div> No data available!</div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</s-modal>