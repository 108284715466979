import { Directive, Input, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: 'img[appImgFallback]'
})
export class ImageFallbackDirective {

  @Input() appImgFallback: string;

  constructor(private eRef: ElementRef) { }

  /*
  * Usage:
  * <img src="some_image_url" appImgFallback />
  * <img src="some_image_url" appImgFallback="fallback_image_url" />
  * <img src="some_image_url" [appImgFallback]="some_variable" />
  */

  @HostListener('error')
  // tslint:disable-next-line:typedef
  loadFallbackOnError() {
    const element: HTMLImageElement = this.eRef.nativeElement as HTMLImageElement;
    element.src = this.appImgFallback || '/assets/images/cs_logo.png';
  }
}
