import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import { BaseRequestService } from './base.service';

@Injectable({
  providedIn: 'root'
})

export class IntegrationActionsService {
  integrationActionShow = false;
  integrationAssetActionShow = false;
  assetSelectedTab = 0;
  integrationRuleListHash: any = {};
  alertRuleListHash: any = {};
  integrationActionPopup = new EventEmitter();
  integrationActionEvent: Subject<any> = new Subject<any>();
  checkTabEvent: Subject<any> = new Subject<any>();
  integrationAssetActionEvent: Subject<any> = new Subject<any>();
  integrationOauthEvent = new EventEmitter();
  applicationActionEvent: Subject<any> = new Subject<any>();
  integrationValidationEVE = new EventEmitter();
  singleClick = false;
  constructor( private baseService: BaseRequestService, ) {
  }

  triggerAction(desc?: any, mode?: any): void {
    if (!this.singleClick) {
      this.singleClick = true;
      if (this.assetSelectedTab !== 2 && !mode){
        this.checkTabEvent.next(2);
      } else {
        this.integrationActionEvent.next(desc ? desc : null);
      }
      setTimeout(() => {this.singleClick = false; }, 100);
    }
  }

  triggerAssetAction(): void {
    if (!this.singleClick) {
      this.singleClick = true;
      this.integrationAssetActionEvent.next({});
      setTimeout( () => { this.singleClick = false; }, 100);
    }
  }

  processAction(): void {
    if (!this.singleClick) {
      this.singleClick = true;
      this.applicationActionEvent.next({});
      setTimeout(() => {this.singleClick = false; }, 100);
    }
  }

  executeActionParams(param: any): Promise<any> {
    const params: any = {
      integrationName: param.name,
      integrationId: param.credentialId,
      params: { action: { name: param.getparamsfunc, destination: param.getparamsfunc, verb: 'ASYNCMETHOD'},
        params: {
          requestparams: { integration_name: param.name, credential_id: param.credentialId,
          }
        }
      }
    };
    return this.baseService.doRequest(`/api/integrations/executeAction`,
        'post', params).toPromise();
  }
}
