import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from 'app/_services/common.service';
import { BaseRequestService } from 'app/_services/base.service';
import { UserService } from 'app/core/user/user.service';
import { ModalService } from 'app/_services/modal.service';
import { LoaderService } from 'app/_services/loader.service';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash';
import { MaterialModule } from 'app/material.module';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { QuillModule } from 'ngx-quill';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-tag-management',
  standalone: true,
  imports: [CommonModule, MaterialModule, DynamicFormComponent, FuseAlertComponent, FormsModule, TableComponent, AppFilterPipeModule, ModalComponent, NgxMatSelectSearchModule, ReactiveFormsModule, ReactiveFormsModule, MatFormFieldModule, FuseScrollbarDirective,
    DirectivesModule, QuillModule],
  templateUrl: './tag-management.component.html',
  styleUrls: ['./tag-management.component.scss']
})
export class TagManagementComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  selection = new SelectionModel<any>(true, []);
  expandedIndex: number | null = null;
  filteredResultSets: any[] = [];
  searchTerm: string = '';
  public expandedTag: any = null;
  selectedTags: any = [];
  resultSets: any[] = [];
  selectAll = false;
  selectedCount: number = 0;
  currentSortKey: string = '';
  currentSortDirection: 'asc' | 'desc' = 'asc';

  _tags: any = [
    {
      "title": "Server",
      "value": "Server"
    },
    {
      "title": "Workstation",
      "value": "Workstation"
    },
    {
      "title": "Windows 11 Incompatible",
      "value": "Windows 11 Incompatible"
    }
  ];

  assetinfoTableOptions: any = {};
  constructor(
    public cs: CommonService,
    private _bs: BaseRequestService,
    private _changeDetectorRef: ChangeDetectorRef,
    private uS: UserService,
    public modalService: ModalService,
    private loaderService: LoaderService,
    private _formBuilder: UntypedFormBuilder,
    public toast: MyToastrService,
    public confirmDialog: FuseConfirmationService
  ) { }

  ngOnInit(): void {
    this.resultSets = Object.values(this.resultSets);
    this.getTags();
    this.filteredResultSets = this.resultSets;
  }

  isDetailsVisible(index: number): boolean {
    return this.expandedIndex === index;
  }

  sortData(sortKey: string) {
    if (this.currentSortKey === sortKey) {
      this.currentSortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortKey = sortKey;
      this.currentSortDirection = 'asc';
    }

    this.filteredResultSets.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return this.currentSortDirection === 'asc' ? -1 : 1;
      } else if (a[sortKey] > b[sortKey]) {
        return this.currentSortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  getTags(value?: any): void {
    const params: any = {};
    if (value) {
      if (value.indexOf(':') > -1) {
        const name = value.split(':')[0].trim();
        const valuePart = value.split(':')[1].trim();
        params.condition = "name ilike '%" + name + "%' and value ilike '%" + valuePart + "%'";
      } else {
        params.condition = "name ilike '%" + value + "%'";
      }
    } else {
      params.condition = true;
      params.skip = 0;
      params.limit = 3000;
      params.order_by = 'created desc';
    }
    if (this.cs.currentScope !== '*') {
      if (params.condition === true) {
        params.condition = `company_id=${parseInt(this.cs.currentScope.id)}`;
      } else {
        params.condition += ` and company_id=${parseInt(this.cs.currentScope.id)}`;
      }
    }
    this._bs.doRequest('/r/report_queries/tags_view', 'get',
      null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._tags = [];
        if (result.status) {
          result.data.forEach((obj: any) => {
            this._tags.push({ title: obj.name, value: obj.value, id: obj.id, company_name: obj.company_name, type: obj.type });


          });
          const uniqueNames: any = new Set(this.selectedTags);
          const tasksResults = cloneDeep(this._tags)
          // .filter((task: any) => task.title.toLowerCase().includes(value.toLowerCase()));
          const results = [];
          const filteredData2 = (uniqueNames) ? tasksResults.filter((obj: any) => !uniqueNames.has(obj.value)) : tasksResults;
          if (filteredData2.length > 0) {
            results.push({
              id: 'tags',
              label: 'Tags',
              results: filteredData2
            });
          }
          this.resultSets = filteredData2;
          this.filterTags();
        }
      });
  }

  filterTags(): void {
    if (!this.searchTerm) {
      this.filteredResultSets = this.resultSets;
    } else {
      const lowerSearchTerm = this.searchTerm.toLowerCase();
      this.filteredResultSets = this.resultSets.filter(tag => {
        const fullString = `${tag.title}:${tag.value}:${tag.company_name}`.toLowerCase();
        return fullString.includes(lowerSearchTerm);
      });
    }
  }



  deleteConfirm(prod: any): void {

    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this tag?',
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        const reqData: any = {
          ids: [prod.id]
        }
        this._bs.doRequest(`/d/asset/tags/bulk_delete`, 'post', reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => this.getTags());
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      }
    })

  }

  isSelectable(): boolean {
    return this.resultSets.some((item: any) => item.type !== 'auto');
  }

  isDeletable(): boolean {
    return this.resultSets.some((item: any) => item.selected && item.type !== 'auto');
  }

  mulDeleteConfirm(): void {
    const selectedIds = this.resultSets
      .filter(prod => prod.selected && prod.type !== 'auto')
      .map(prod => prod.id);
    const selectedCount = selectedIds.length;
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Are you sure you want to delete these (${selectedCount}) tags?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        const reqData: any = {
          ids: selectedIds
        };
        this._bs.doRequest(`/d/asset/tags/bulk_delete`, 'post', reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => this.getTags());
              this.selectedCount = 0;
              this.selectAll = false;
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      }
    })

  }
  
  selectAllToggle1(): void {
    this.selectAll = this.selectAll; // Ensure toggling works correctly
    this.resultSets.forEach((prod: any) => {
      prod.selected = this.selectAll;
    });
    this.updateSelectedCount(); // Update selected count when select all toggled
    this._changeDetectorRef.detectChanges();
  }

  updateSelectAllState(): void {
    this.selectAll = this.isAllSelected1();
    this.updateSelectedCount(); // Update selected count when selection changes
  }

  hasSelected1(): boolean {
    return this.resultSets.some((x: any) => x.selected);
  }

  isAllSelected1(): boolean {
    return this.resultSets.length > 0 && this.resultSets.every((x: any) => x.selected);
  }

  updateSelectedCount(): void {
    this.selectedCount = this.resultSets.filter((x: any) => x.selected && x.type !== 'auto').length;
  }

  toggleDetails(index: number, prod: any): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
    if (this.expandedIndex === index) {
      const prodTitle = prod.title; 
      const prodValue = prod.value; 
      let condition: string;
      if(this.cs.currentScope.id){
         condition = ` asset_type = 'discovered' AND company_id = '${parseInt(this.cs.currentScope.id)}' AND ( tags @> '{"${prodTitle}": ["${prodValue}"]}' OR manual_tags->>'${prodTitle}' = '${prodValue}')`
      }else{
         condition = ` asset_type = 'discovered' AND ( tags @> '{"${prodTitle}": ["${prodValue}"]}' OR manual_tags->>'${prodTitle}' = '${prodValue}')`
      }
      const tableOption: any = {
        columns: [
          {
            header: "Host Name",
            columnDef: "host_name",
            cType: "string",
            isHyperlink: false,
            filter: "",
            cell: "(element: any) => `${element.host_name}`",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: true,
            clipboard: true,
          },
          {
            header: "IP",
            columnDef: "ip",
            cType: "string",
            isHyperlink: false,
            filter: "",
            cell: "(element: any) => `${element.ip}`",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: true,
          },
          {
            header: "Agent Type",
            columnDef: "agent_type",
            cType: "string",
            isHyperlink: false,
            filter: "",
            cell: "(element: any) => `${element.agent_type}`",
            order: 0,
            visible: true,
            isToolTip: false,
            isToolTipCol: "",
            hasMultiData: false,
            class: "",
            color: "",
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: "",
            img: false,
            imgPath: "",
            isSort: true,
            iscolumnSearch: true,
          }
        ],
        sortOptions: { active: "agent_type", direction: "desc" },
        _pageData: [],
        tableOptions: {
          title: "Asset Information",
          isServerSide: false,
          selectText: "plans",
          loading: true,
          floatingFilter: false,
          rowSelection: false,
          showAction: false,
          isHideGlobal: false,
          isCustomSort: false,
          apiDownload: false,
          hideDownload: false,
          isDefaultSearch: false,
          isDefaultKeySearch: false,
          pagination: true,
          pageOptions: [5, 10, 25, 100],
          pageSize: 5,
          search: false,
          showhideList: true,
          refreshData: true,
          showFilter: true,
          showTagFilter: false,
          exportExcel: true,
          add: false,
          columnSearch: true,
          compareData: false,
          filterDownload: false,
          isNotSiteChange: true,
          serverSide: {
            url: '/r/report_queries/asset_view',
            type: 'get',
            isGlobal: true,
            condition: condition
          }
        },
        changeValue: new Subject<any>()
      };

      this.assetinfoTableOptions = cloneDeep(tableOption);
      const data = Object.assign({}, this.assetinfoTableOptions);
      data.pageData = [];
      data.tableOptions.pageTotal = 0;
      this.assetinfoTableOptions.pageData = [];
      this.assetinfoTableOptions.tableOptions.pageTotal = 0;
      this.assetinfoTableOptions = {};
      this._changeDetectorRef.detectChanges();
      this.assetinfoTableOptions = data;
      this._changeDetectorRef.detectChanges();
    }
  }


  clearSelection(): void {
    this.resultSets.forEach((prod: any) => {
      prod.selected = false;
    });
    this.updateSelectedCount(); // Update the selected count
    this.selectAll = false; // Uncheck the select all checkbox
    this._changeDetectorRef.detectChanges(); // Ensure UI is updated
  }

}
