<ng-container
    *ngIf="(uS._user_roles.indexOf('admin') >-1 && (_bs.cUser().included === '*' || _bs.cUser().included === '' ) && !_bs.cUser().excluded) || (!_bs.cUser().included && !_bs.cUser().excluded)">
    <div class="flex flex-wrap items-center justify-start gap-2 pt-4">
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'asset_security_grade_customization'" id="AssetSecurityGradeCustomization"
            [ngClass]="{'bg-primary text-white': cView === 'asset_security_grade_customization'}">
            Asset Security Grade Customization
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'antiransomeware'" [ngClass]="{'bg-primary text-white': cView === 'antiransomeware'}">
            Anti Ransomware
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'backupSoftware'" id="backupSoftware"
            [ngClass]="{'bg-primary text-white': cView === 'backupSoftware'}">
            Backup Software
        </div>

        <div *ngIf="Wizzard===true" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="complianceScan()" id="compliancescan"
            [ngClass]="{'bg-primary text-white': cView === 'compliance-scan'}">
            Compliance Scan
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'dateformat'" id="dateformat"
            [ngClass]="{'bg-primary text-white': cView === 'dateformat'}">
            Custom Date format
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'custom-domain'" id="customdomain"
            [ngClass]="{'bg-primary text-white': cView === 'custom-domain'}">
            Custom Domain
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'default-redirect-url'" id="default-redirect-url"
            [ngClass]="{'bg-primary text-white': cView === 'default-redirect-url'}">
            Default Redirect URL
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'retention_period'" id="retention_period"
            [ngClass]="{'bg-primary text-white': cView === 'retention_period'}">
            Deprecated Asset Retention Period
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'deprecation'" id="deprecation"
            [ngClass]="{'bg-primary text-white': cView === 'deprecation'}">
            Deprecation Days
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'edr-application'" id="edrapplication"
            [ngClass]="{'bg-primary text-white': cView === 'edr-application'}">
            EDR Application
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'enable-self-approval'" id="EnableSelfApproval"
            [ngClass]="{'bg-primary text-white': cView === 'enable-self-approval'}">
            Enable Self Approval
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="excludeReport()" id="excludereportcard"
            [ngClass]="{'bg-primary text-white': cView === 'exc-secreport-card'}">
            Exclude Component(s) from Security Report Card
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'firewall'" [ngClass]="{'bg-primary text-white': cView === 'firewall'}">
            Firewall
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'globaltable'" [ngClass]="{'bg-primary text-white': cView === 'globaltable'}">
            Global Table Setting
        </div>
        <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'enable_icmp_ping_check'"
            [ngClass]="{'bg-primary text-white': cView === 'enable_icmp_ping_check'}">
            ICMP Ping Check
        </div>
        <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'patching-status'" id="patchingstatus" *ngIf="uS._user_roles.indexOf('admin') >-1"
            [ngClass]="{'bg-primary text-white': cView === 'patching-status'}">
            Patch Management
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'port'" id="port" [ngClass]="{'bg-primary text-white': cView === 'port'}">
            Ports Policy
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'report-settings'" id="reportsettings"
            [ngClass]="{'bg-primary text-white': cView === 'report-settings'}">
            Report Settings
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'reset_view'" id="reset_view"
            [ngClass]="{'bg-primary text-white': cView === 'reset_view'}">
            Reset User secret
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'disable_roaming_applications'"
            [ngClass]="{'bg-primary text-white': cView === 'disable_roaming_applications'}">
            Roaming Applications
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'enable_icmp_ping_check'"
            [ngClass]="{'bg-primary text-white': cView === 'enable_icmp_ping_check'}">
            ICMP Ping Check
        </div>
        <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'lwa-interval'" id="lwainterval"
            [ngClass]="{'bg-primary text-white': cView === 'lwa-interval'}">
            Scan Time Interval
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'session-timeout'" id="sessiontimeout"
            [ngClass]="{'bg-primary text-white': cView === 'session-timeout'}">
            Session Timeout
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'microsoft_patches'" id="microsoft_patches"
            [ngClass]="{'bg-primary text-white': cView === 'microsoft_patches'}">
            Suppress Vulnerabilities Days
        </div>

        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'suppression'" id="suppression"
            [ngClass]="{'bg-primary text-white': cView === 'suppression'}">
            Suppression Email Template
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'tag_management'" id="tag_management"
            [ngClass]="{'bg-primary text-white': cView === 'tag_management'}">
            Tag Management
        </div>
        <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'timezone'" id="timezone" [ngClass]="{'bg-primary text-white': cView === 'timezone'}">
            Timezone Settings
        </div>
        <div *ngIf="Wizzard===false" class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
            (click)="cView = 'white-label'" id="whitelabel"
            [ngClass]="{'bg-primary text-white': cView === 'white-label'}">
            White Label Settings
        </div>
        <!-- <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'login-audit'" [ngClass]="{'bg-primary text-white': cView === 'login-audit'}">Login Audit
        Status
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'default-role'" [ngClass]="{'bg-primary text-white': cView === 'default-role'}">Default
        Role Settings
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'default-tag'" [ngClass]="{'bg-primary text-white': cView === 'default-tag'}">Default Tags
        Status
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'manage-agent-version'"
        [ngClass]="{'bg-primary text-white': cView === 'manage-agent-version'}">Manage Agent Version
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'agent-performance-management'"
        [ngClass]="{'bg-primary text-white': cView === 'agent-performance-management'}">Agent Performance Management
    </div>
     <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'coverPage'" [ngClass]="{'bg-primary text-white': cView === 'coverPage'}">Custom Report Cover
        Page
    </div> 
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'suppress_vulnerabilities'" id="suppress_vulnerabilities"
        [ngClass]="{'bg-primary text-white': cView === 'suppress_vulnerabilities'}">
        Suppress Vulnerabilities Days
    </div> -->
    </div>
    <div class="my-4 border-t border-blue-400"></div>
    <div class="w-full mt-4" *ngIf="cView === 'timezone'">
        <div class="p-4 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
                <mat-label>Timezone</mat-label>
                <mat-select [(ngModel)]="_timezone.value.value" name="Timezone" id="Timezone">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="timezoneSearchControl" [placeholderLabel]="'Search Zone'"
                            [noEntriesFoundLabel]="'No matching zone found'"
                            [searching]="searching"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [matTooltip]="zone"
                        *ngFor="let zone of cs.timeZoneList | search:timezoneSearchControl.value " [value]="zone">
                        {{zone}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveTimezone()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="cView === 'dateformat'">
        <div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700">
            <mat-form-field class="w-1/2">
                <mat-label>Set Date format</mat-label>
                <mat-select [multiple]="false" [(ngModel)]="_date_format.value.value" placeholder="Choose Dateformat">
                    <mat-option *ngFor="let format of dateFormatList" [id]="format"
                        [value]="format.format">{{format.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveCustomDateFormat()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <div class="w-full" *ngIf="cView === 'session-timeout'">
        <div class="mt-4 bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700">
            <div class="grid grid-cols-3 gap-3">
                <mat-form-field>
                    <mat-label>Idle for</mat-label>
                    <input matInput placeholder="Idle (minutes)" type="number" [(ngModel)]="_session_timeout.value.idle"
                        autocomplete="off" name="Idlefor">
                    <mat-hint>If Idle for {{_session_timeout.value.idle}} minutes</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Wait for</mat-label>
                    <input matInput placeholder="Countdown (minutes)" type="number"
                        [(ngModel)]="_session_timeout.value.timeout" autocomplete="off" name="Waitfor">
                    <mat-hint>wait for another {{_session_timeout.value.timeout}} minutes</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Logout in</mat-label>
                    <input matInput placeholder="Logout (minutes)" type="number" [disabled]="true"
                        [value]="_session_timeout.value.idle + _session_timeout.value.timeout" autocomplete="off"
                        name="Logout">
                    <mat-hint>logout in {{_session_timeout.value.idle + _session_timeout.value.timeout}}
                        minutes.</mat-hint>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="saveLWAInterval('reset')">
                    Reset
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveSessionSettings()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="cView === 'port'">
        <div class="my-2">
            <fuse-alert [type]="'info'" [appearance]="'border'">
                <span fuseAlertTitle>Ports Policy</span>
                <div class="flex flex-col">
                    <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                        <b>Insecure Ports</b> = Asset scan will consider provided ports as insure and display as 'No'
                        under the Is Secure column.
                    </span><br>
                    <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                        <b>Denied Ports</b> = Network ports explicitly blocked or restricted according to an
                        organization's security policy or configuration. The agent will flag this in the compliance
                        report card if it violates the policy.
                    </span><br>
                    <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                        <b>Excluded Ports</b>= Ports explicitly specifies certain ports that are exempt from agent
                        scanning.
                    </span><br>
                    <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                        <b>Allowed Ports</b> = Network scan will consider provided ports as allowed for scanning.
                    </span><br>
                    <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
                        <b>Secure Ports</b> = Asset scan will consider provided ports as secure and display as 'Yes'
                        under the Is Secure column.
                    </span>
                </div>
            </fuse-alert>
        </div>
        <app-dynamic-form [listOfFormElements]="ports" [spanLayout]="2" [Valuesoutput]="portsParams"
            (saveCallBack)="savePortPolicy(portsParams)" [isSaveBtn]="false" [isCloseBtn]="false">
        </app-dynamic-form>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="savePortPolicy(portsParams)">
                Save
            </button>
        </div>
    </div>
    <div class="w-full mt-4" *ngIf="cView === 'lwa-interval'">
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <mat-label class="block mb-1">Scan Time Interval</mat-label>
            <br>
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <ul class="space-y-1 list-disc list-inside marker:text-blue-600">
                    <li><b>Self Scan</b> is performed by the agent according to the selected time frequency.</li>
                    <li>By adding the From Time and To Time one can exclude the Self Scan for the agent within the
                        provided
                        time range.</li>
                    <li>The Scan Time Interval will apply only for Lightweight Agent and Probe Self Agent.</li>
                </ul>
            </fuse-alert>
            <div class="flex flex-col w-full h-full mt-4">
                <div class="grid grid-cols-4 gap-4">
                    <mat-form-field floatLabel="always" [subscriptSizing]="'dynamic'" class="mb-4 sForm">
                        <mat-label>Minutes</mat-label>
                        <mat-select required [multiple]="false" [(ngModel)]="_lwa_interval.value.minutes"
                            placeholder="Choose Interval">
                            <mat-option *ngFor="let interval of scanInterval" [value]="interval.value">{{ interval.name
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" [subscriptSizing]="'dynamic'" class="mb-4 sForm">
                        <mat-label>From Time</mat-label>
                        <input matInput required [(ngModel)]="_lwa_interval.value.from_time" type="time"
                            name="form_time">
                    </mat-form-field>

                    <mat-form-field floatLabel="always" [subscriptSizing]="'dynamic'" class="mb-4 sForm">
                        <mat-label>To Time</mat-label>
                        <input matInput required [(ngModel)]="_lwa_interval.value.to_time" type="time" name="to_time">
                    </mat-form-field>

                </div>
                <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="saveLWAInterval('reset')">
                        Reset
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                        [matTooltip]="'Save'" (click)="saveLWAInterval()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="cView === 'deprecation'">
        <div class="my-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div class="flex flex-col gap-2">
                    <div>Set Asset and Agents Deprecation days for the assets and agents which are offline and not
                        scanned
                        for x number of days.</div>
                    <div>If deprecated days are not provided, the system will automatically default to 90 days.</div>
                </div>
            </fuse-alert>
        </div>
        <app-dynamic-form [listOfFormElements]="deprecation_days" [spanLayout]="2"
            [Valuesoutput]="deprecationDaysParams" (saveCallBack)="saveSettings(deprecationDaysParams)"
            [isSaveBtn]="false" [isCloseBtn]="false">
        </app-dynamic-form>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveSettings(deprecationDaysParams)">
                Save
            </button>
        </div>
    </div>
    <div class="w-full mt-4" *ngIf="cView === 'login-audit'">
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Login Audit Status"
                name="loginAuditStatus" [(ngModel)]="_adaudit_status.value.value">
                Enable Login Audit
            </mat-slide-toggle>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="enableLoginAuditStatus()">
                    Save
                </button>
            </div>
        </div>

    </div>
    <div class="w-full mt-8" *ngIf="cView === 'patching-status'">
        <div class="my-2">
            <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Patching Status" name="patchingStatus"
                [(ngModel)]="_enable_patching.value.patching_status">
                Enable Patch Management
            </mat-slide-toggle>
        </div>
        <div class=" mt-2 h-[40vh]" *ngIf="_enable_patching.value.patching_status" fuseScrollbar>
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div [innerHTML]="patchingContent| safeHTML"></div>
            </fuse-alert>
        </div>
        <mat-checkbox name="agreePatchCond" *ngIf="_enable_patching.value.patching_status" id="agreePatchCond"
            class="mt-3" [(ngModel)]="_enable_patching.value.is_eula_accepted" value="" color="primary">I ACCEPT
        </mat-checkbox>
        <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="enablePatchingStatus()"
                [disabled]="_enable_patching.value.patching_status && !_enable_patching.value.is_eula_accepted">
                Save
            </button>
        </div>
    </div>
    <div class="w-full mt-4" *ngIf="cView === 'default-tag'">
        <div class="my-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div>Disable Default Tags removes the default tags applied for all the assets.</div>
            </fuse-alert>
        </div>
        <div class="mt-4">
            <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Default Tags"
                [(ngModel)]="_default_tags.value.value" name="defaultTagsStatus">
                Enable Default Tags
            </mat-slide-toggle>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="enableDefaultTagStatus()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <div class="w-full mt-4" *ngIf="cView === 'default-role'">
        <div class="my-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                The present Default Role for new users which are created through authentication providers has been
                designated as "Norole". If the default role is changed, all the newly created users via
                authentication providers will be assigned to the new Default Role set..
            </fuse-alert>
        </div>
        <div class="mt-4">
            <mat-form-field class="w-1/2 mb-4">
                <mat-label>Set Default Role</mat-label>
                <mat-select [multiple]="false" name="roles" [(ngModel)]="_default_role.value.value" required
                    placeholder="Select default role">
                    <mat-option *ngFor="let role of roleList" [value]="role">{{role | uppercase}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="saveDefaultRole()" [disabled]="!_default_role.value.value">
                    Save
                </button>
            </div>
        </div>
    </div>

    <div class="w-full mt-4" *ngIf="cView === 'reset_view'">
        <div class="my-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                Proceeding with this action will erase the existing user secret and generate a new one. To maintain
                functionality, it's crucial that you update your agent installation script ( RMM script) with the newly
                generated user secret immediately after confirmation!. Please read through the document before taking
                action.
                <a class="font-light mini-form-field ml-2 text-white px-2 rounded-lg py-0.5 bg-primary text-xs ring-1"
                    href="https://cybercns.atlassian.net/wiki/x/nYA_f" target="_blank">HELP LINK</a>
            </fuse-alert>

        </div>

        <div class="flex items-center justify-end p-4 mt-4 border-t bg-gray-50 dark:bg-gray-800">
            <button
                class="ml-0 px-4 py-2 text-red-500 bg-transparent red-500  hover:bg-red-500 hover:text-white transition ease-in-out duration-300 shadow-md hover:shadow-lg"
                mat-button [matTooltip]="'Reset'" (click)="resetUserSecret();">
                Reset
            </button>
        </div>
    </div>


    <div *ngIf="cView === 'white-label'">
        <app-white-label> </app-white-label>
    </div>
    <div *ngIf="cView === 'edr-application'">
        <app-edr-app> </app-edr-app>
    </div>
    <div *ngIf="cView === 'report-settings'">
        <app-report-settings [global]="true"> </app-report-settings>
    </div>
    <div *ngIf="cView === 'backupSoftware'">
        <app-backup-softwares> </app-backup-softwares>
    </div>
    <div *ngIf="cView === 'manage-agent-version'">
        <app-manage-agent-version> </app-manage-agent-version>
    </div>
    <div *ngIf="cView === 'agent-performance-management'">
        <app-agent-performance-management> </app-agent-performance-management>
    </div>
    <div *ngIf="cView === 'disable_roaming_applications'">
        <app-disable-roaming-app> </app-disable-roaming-app>
    </div>
    <div *ngIf="cView === 'enable_icmp_ping_check'">
        <app-icmp-ping-check></app-icmp-ping-check>
    </div>
    <div class="mt-4" *ngIf="cView === 'firewall'">
        <app-firewall></app-firewall>
    </div>
    <div *ngIf="cView === 'custom-domain'">
        <app-custom-domain> </app-custom-domain>
    </div>
    <div class="mt-4" *ngIf="cView === 'antiransomeware'">
        <app-antirandomware></app-antirandomware>
    </div>

    <div class="mt-4" *ngIf="cView === 'tag_management'">
        <app-tag-management></app-tag-management>
    </div>

    <div class="mt-4" *ngIf="cView === 'compliance-scan'">
        <div class="px-4 py-6 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col ">
                <fuse-alert [appearance]="'border'" [type]="'info'">
                    <ul class="space-y-1 list-disc list-inside marker:text-blue-600">
                        One of the best practices is to adhere to CIS Compliance Scan
                    </ul>
                </fuse-alert>
                <br>
                <span class="mb-2 font-semibold">Choose Compliance Types</span>
                <div class="flex flex-col gap-2 rounded-lg ring-1">
                    <mat-checkbox value="" (change)="selectAllToggle($event, 'complianceListAll')"
                        [checked]="isAllSelected('complianceListAll')"
                        [indeterminate]="hasSelected('complianceListAll') && !isAllSelected('complianceListAll')"
                        [color]="'primary'">
                        Select All
                    </mat-checkbox>
                    <div class="grid grid-cols-1 gap-2 p-4 border-t sm:grid-cols-4">
                        <div *ngFor="let type of complianceListAll; let i = index">
                            <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected"
                                name="selected{{i}}">
                                {{type.name |uppercase |camelToHuman:true}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="updateComplianceType()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="cView === 'coverPage'">
        <app-report-cover-page> </app-report-cover-page>
    </div>

    <div class="w-full mt-4" *ngIf="cView === 'globaltable'">
        <div class="p-4 mt-4 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="grid grid-cols-2 gap-3">
                <mat-form-field>
                    <mat-label>Font Size</mat-label>
                    <mat-select [(ngModel)]="_global_table.value.font" matTooltip="Change Font Size" name="font">
                        <mat-option value="small" id="smalltext">Small</mat-option>
                        <mat-option value="medium" id="mediumtext">Medium</mat-option>
                        <mat-option value="large" id="largeText">Large</mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Items Per Page</mat-label>
                    <input matInput required [(ngModel)]="_lwa_interval.value.from_time"
                        [(ngModel)]="_global_table.value.page_size" matTooltip="Change Page Size" name="pagesize">
                    <mat-hint>Ex. Min 5 items to Max 100 items</mat-hint>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-auto" mat-button [matTooltip]="'Reset'" (click)="resetSetting();">
                    Reset
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="globaltable()">
                    Save
                </button>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="cView === 'exc-secreport-card'">
        <div class="px-4 py-6 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col ">
                <span class="mb-2 font-semibold">Select Component(s) to Exclude from Security Report Card</span>
                <div class="flex flex-col">
                    <mat-checkbox class="mb-2" value="" (change)="selectAllToggle($event, 'excludeReportListAll')"
                        [checked]="isAllSelected('excludeReportListAll')"
                        [indeterminate]="hasSelected('excludeReportListAll') && !isAllSelected('excludeReportListAll')"
                        [color]="'primary'">
                        Select All
                    </mat-checkbox>
                    <div class="flex flex-col ml-4" *ngFor="let type of excludeReportListAll; let i = index">
                        <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected" name="selected{{i}}">
                            {{type.name}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="updateExcludeReport()">
                    Save
                </button>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="cView === 'retention_period'">
        <div class="my-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div class="flex flex-col gap-2">
                    <div>Retain deprecated assets, which are under deprecation for x number of days, here</div>
                </div>
            </fuse-alert>
        </div>
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col w-full h-full">
                <div class="grid grid-cols-2 gap-4">
                    <mat-form-field floatLabel="always" class="mb-4 sForm">
                        <mat-label>Deprecated Asset Retention Period</mat-label>
                        <input type="number" matInput name="count" [(ngModel)]="_asset_detention_days.value.value" />
                        <mat-hint>Ex. Min 3 days to Max 365 days</mat-hint>
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                        [matTooltip]="'Save'" (click)="saveretentionperiodcount()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="cView === 'suppression'">
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col w-full h-full">
                <quill-editor class="mt-2" [(ngModel)]="_suppress_email_template.value.value" name="suppression"
                    [placeholder]="'create template here...'" [bounds]="'self'" [modules]="quillModules"></quill-editor>
                <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                        [matTooltip]="'Save'" (click)="saveSuppression()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="cView === 'default-redirect-url'">
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col w-full h-full">
                <div class="grid grid-cols-2 gap-4">
                    <mat-form-field>
                        <mat-label>Select Default Redirect Page</mat-label>
                        <mat-select [(ngModel)]="_default_redirect_url.value.value" name="defaultredirecturl">
                            <mat-option *ngFor="let page of page_link" [value]="page.link">{{page.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Reset'" (click)="resetdefaulturl();"
                        *ngIf="_default_redirect_url && _default_redirect_url.id">
                        Reset
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                        [matTooltip]="'Save'" [disabled]="!_default_redirect_url.value.value"
                        (click)="savedefaulturl()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="cView === 'microsoft_patches'">
        <div>
            <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
                <div class="flex flex-col w-full h-full">
                    <div class="my-2">
                        <fuse-alert [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>Microsoft Suppress Vulnerabilities Days</span>
                            The Vulnerabilities for the respective assets are suppressed for the number of days entered
                            here, starting from the day after the initial release.
                        </fuse-alert>
                        <div class="grid grid-cols-2 gap-4 mt-2">
                            <mat-form-field floatLabel="always" class="mb-4 sForm">
                                <mat-label>Microsoft Suppress Vulnerabilities Days</mat-label>
                                <input type="number" matInput name="count"
                                    [(ngModel)]="_microsoft_suppress_days.value" />
                                <mat-hint>{{_microsoft_suppress_days.value}} day(s) to suppress vulnerabilities after
                                    initial
                                    release.</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                        <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="resetSuppressVulnerabilities()"
                            *ngIf="_microsoft_suppress_days && _microsoft_suppress_days.id">
                            Reset
                        </button>
                        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                            [matTooltip]="'Save'" (click)="saveMicrosoftVulnerabilitiesdays()"
                            [disabled]="!_microsoft_suppress_days.value">
                            Save
                        </button>
                    </div>
                    <mat-divider class="my-4"></mat-divider>
                    <div class="my-2">
                        <fuse-alert [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>Application Suppress Vulnerabilities Days</span>
                            The Application vulnerabilities for the respective assets are suppressed for the number of
                            days entered here, starting from the day after the initial release.
                        </fuse-alert>
                        <div class="grid grid-cols-2 gap-4 mt-2">
                            <mat-form-field floatLabel="always" class="mb-4 sForm">
                                <mat-label>Application Suppress Vulnerabilities Days</mat-label>
                                <input type="number" matInput name="count" [(ngModel)]="_other_suppress_days.value" />
                                <mat-hint>{{_other_suppress_days.value}} day(s) to suppress vulnerabilities after
                                    initial
                                    release.</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                        <button class="ml-2" mat-button [matTooltip]="'Reset'" (click)="resetOtherVulnerabilities()"
                            *ngIf="_other_suppress_days && _other_suppress_days.id">
                            Reset
                        </button>
                        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                            [matTooltip]="'Save'" (click)="saveOtherVulnerabilitiesdays()"
                            [disabled]="!_other_suppress_days.value">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div  class="mt-4" *ngIf="cView === 'asset_security_grade_customization'">
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 pb-0">
                <div class="mr-4 text-lg font-medium tracking-tight leading-6 runcate">Asset Security Grade
                    Customization
                </div>
                <mat-divider class="my-2"></mat-divider>
            </div>
            <div class="flex flex-col w-full h-full py-6 px-20">
                <form #gradeForm="ngForm">
                    <div class="grid grid-cols-2 grid-cols-2 gap-4 mt-6 px-10">
                        <ng-container
                            *ngFor="let row of Objectkeys(_asset_security_grade.value); let index=index; let last = last; let first = first;">
                            <div class="flex w-full flex-row items-center gap-2">
                                <div class="max-w-xs flex relative mt-8 mr-2 text-white right-1 items-center justify-center h-6 w-6 rounded-full p-1 m-1 font-bold text-lg"
                                    [ngClass]="{
                                    'bg-yellow-600': row === '-' || row === 'B' || row === 'C',
                                    'bg-green-500': row === 'A',
                                    'bg-orange-600': row === 'D',
                                    'bg-red-600': row === 'E' || row === 'F'
                                    }">
                                    {{row}}
                                </div>
                                <div class="flex w-[15vw]">
                                    <mat-form-field class="w-full">
                                        <mat-label>From Score</mat-label>
                                        <input type="number" matInput name="fromScore{{index}}" required
                                            [readonly]="true" [(ngModel)]="_asset_security_grade.value[row].from">
                                    </mat-form-field>
                                </div>
                                <div class="flex w-[15vw]">
                                    <mat-form-field class="w-full">
                                        <mat-label>To Score</mat-label>
                                        <input type="number" matInput name="toScore{{index}}" required [readonly]="last"
                                            [min]="_asset_security_grade.value[row].from"
                                            [(ngModel)]="_asset_security_grade.value[row].to"
                                            (ngModelChange)="onToScoreChange(index, $event)">
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                        <button class="ml-auto" mat-button type="button" [matTooltip]="'Reset'"
                            (click)="resetSecurityGrade();" *ngIf="_asset_security_grade && _asset_security_grade.id">
                            Reset
                        </button>
                        <button class="ml-2" mat-flat-button type="button" [color]="'primary'" type="button"
                            appPreventMultiClick [matTooltip]="'Save'" [disabled]="gradeForm.invalid"
                            (click)="saveSecurityGrade()">
                            Save
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <div class="w-full mt-4" *ngIf="cView === 'enable-self-approval'">
        <div class="my-2">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                Upon enabling the <strong>Enable Self Approval</strong> option, users with the Approver role will have
                the
                ability to perform self-approval for suppression.
            </fuse-alert>
        </div>
        <div class="px-4 py-6 my-2 border border-gray-200 rounded-lg bg-card dark:bg-gray-800 dark:border-gray-700">
            <mat-slide-toggle class="" [color]="'primary'" name="EnableSelfApproval"
                [(ngModel)]="_self_approval.value.value">
                Enable Self Approval
            </mat-slide-toggle>
            <div class="flex items-center justify-end p-2 mt-4 border-t bg-gray-50 dark:bg-transparent">
                <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                    [matTooltip]="'Save'" (click)="enableSelfApproval()">
                    Save
                </button>
            </div>
        </div>

    </div>
</ng-container>

<div class="flex-auto"
    *ngIf="uS._user_roles.indexOf('admin') >-1 && ((_bs.cUser().included !== '*' && _bs.cUser().included !== '' ) || _bs.cUser().excluded) && (_bs.cUser().included || _bs.cUser().excluded)">
    <div class="flex flex-col flex-auto items-center justify-center h-full">
        <div class="w-full max-w-md">
            <img src="/assets/images/forbidden.png" alt="Forbidden" width="100%" height="50%">
        </div>
        <div class="mb-4 text-4xl md:text-3xl font-extrabold tracking-tight leading-tight md:leading-none text-center">
            Unauthorized access.</div>
    </div>
</div>