<div class="relative flex flex-auto w-full bg-card dark:bg-transparent">
  <mat-drawer-container class="flex-auto h-full" [hasBackdrop]="false">
    <!-- Drawer content -->
    <mat-drawer-content class="flex overflow-hidden">
      <!-- Chats list -->
      <ng-container *ngIf="integrationsData && integrationsData.length > 0 && !newCred;">
        <div class="relative flex flex-auto flex-col min-w-0 w-1/2 bg-card dark:bg-transparent current-step">
          <!-- Header -->
          <div class="flex flex-col flex-0 p-4 border-b bg-gray-50 dark:bg-transparent">
            <div class="flex items-center">
              <div class="flex items-center mr-1">
                <div class="w-10 h-10">
                  <ng-container *ngIf="!currentIntegration.image">
                    <div
                      class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                      {{currentIntegration.name.charAt(0)}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="currentIntegration.image">
                    <div
                      class="flex items-center py-1 px-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-hover ng-star-inserted">
                      <div class="relative flex flex-0 items-center justify-center w-8 h-8">
                        <img alt="Contact avatar" class="w-full h-full rounded-full object-cover ng-star-inserted"
                          src="/assets/images/providers/provider-{{ currentIntegration.image }}.png">
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="ml-4 font-medium text-3xl font-semibold tracking-tight leading-8 truncate">
                  {{currentIntegration.display }}</div>
              </div>
              <button class="ml-auto" mat-icon-button (click)="addCred()">
                <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
              </button>
            </div>
          </div>
          <div class="flex-auto overflow-y-auto h-[600px]">
            <div *ngIf="integrationsData.length > 0;">
              <ng-container *ngFor="let cred of integrationsData; trackBy: trackByFn">
                <a class="z-20 flex items-center p-4 cursor-pointer border-b" (click)="setCurrentCred(cred);" [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !credData || credData.id !== cred.id,
                                                'bg-primary-50 dark:bg-hover': credData && credData.id === cred.id}">
                  <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                    <ng-container>
                      <mat-icon svgIcon="vpn_key"></mat-icon>
                    </ng-container>
                  </div>
                  <div class="min-w-0 ml-4">
                    <div class="font-medium leading-5 truncate">
                      {{cred.name | ellipsis:28}}</div>
                  </div>
                  <div class="flex flex-col items-end self-start ml-auto pl-2">
                    <span class="cursor-pointer px-1 mt-2" (click)="delCred(cred)">
                      <mat-icon id="{{cred.name}}" class="mat-24">delete</mat-icon>
                    </span>
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="integrationsData && integrationsData.length > 0 && !newCred">
        <div [ngClass]="{'z-20 absolute inset-0 flex-col w-full p-1 border-l lg:static lg:inset-auto flex': hide == 'No',
                                'hidden md:block flex-col w-full p-1 border-l lg:flex': hide == 'Yes'}">
          <div class="flex flex-col flex-auto overflow-y-auto lg:overflow-hidden bg-card dark:bg-default">
            <mat-drawer-container class="flex-auto h-full" [hasBackdrop]="false">
              <mat-drawer-content class="flex flex-col overflow-hidden">
                <div class="flex items-center p-4">
                  <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                    <ng-container>
                      <mat-icon svgIcon="mat_solid:computer"></mat-icon>
                    </ng-container>
                  </div>
                  <div class="ml-4 text-lg font-medium leading-5 truncate">{{credData.name |
                    ellipsis:28}}</div>
                </div>
                <div
                  class="flex flex-0 items-center h-18 lg:hidden px-4 md:px-6 border-b bg-gray-50 dark:bg-transparent">
                  <button type="button" class="lg:hidden md:-ml-2" (click)="closeside(true)" mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
                  </button>
                </div>
                <div class="flex overflow-y-auto flex-col-reverse">
                  <div class="flex flex-col flex-auto shrink px-6 bg-card dark:bg-transparent">
                    <app-dynamic-form *ngIf="credConf.formElements" [listOfFormElements]="credConf.formElements"
                      [Valuesoutput]="cred" (saveCallBack)="saveCred($event)" (valueUpdate)="updateVal($event)" [isCloseBtn]="false"
                      [fnBtnName]="'Update'"></app-dynamic-form>
                  </div>
                </div>
              </mat-drawer-content>
            </mat-drawer-container>
          </div>
        </div>
      </ng-container>
      <div class="items-center w-full p-2" *ngIf="newCred">
        <form class="flex flex-col mt-4 px-8 pt-10 bg-card shadow rounded overflow-hidden">
          <p class="text-lg font-medium ml-3 mb-3">New Credential</p>
          <ng-container fuseScrollbar>
            <app-dynamic-form *ngIf="credConf.formElements" [listOfFormElements]="credConf.formElements"
              [Valuesoutput]="cred" (saveCallBack)="saveCred($event)"
              (cancelCallBack)="cancel()" (valueUpdate)="updateVal($event)"
              [fnBtnName]="credConf.saveBtn"></app-dynamic-form>
          </ng-container>
        </form>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>