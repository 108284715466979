<mat-form-field class="w-full sm:w-2/5 mt-4 ml-4" [subscriptSizing]="'dynamic'">
    <mat-label>Select Credential</mat-label>
    <mat-select [(ngModel)]="selectedCred" (selectionChange)="filterByCred($event)" name="selectedCred">
        <ng-container *ngFor="let cred of integrationCred; trackBy: trackByFn">
            <mat-option [value]="cred.id">{{cred.name}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
<mat-form-field class="w-full sm:w-2/5 mt-4 ml-2" [subscriptSizing]="'dynamic'"
    *ngIf="cView === 'add' && currentIntegration.company_search_type">
    <mat-label>Select Company Type</mat-label>
    <mat-select [(ngModel)]="selectedComType" (selectionChange)="getCompany()" name="selectedComType">
        <mat-option value="" *ngIf="integrationComType && integrationComType.length>2">All Type</mat-option>
        <ng-container *ngFor="let type of integrationComType; trackBy: trackByFn">
            <mat-option [value]="type.id">{{type.name}}</mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
<ng-container *ngIf="cView === 'table'">
    <div class="" fuseScrollbar>
        <stable [sTableOptions]="mappedComTableOptions" (addCallback)="addTableData()"
            (globalActionCallback)="globalActionCall($event)" (actionCallback)="actionCall($event)"
            *ngIf="mappedComTableOptions.tableOptions && selectedCred"></stable>
    </div>
</ng-container>
<ng-container *ngIf="cView === 'add'">
    <div class="flex-auto p-4 sm:p-4">
        <div class="max-w-full">
            <div class="prose prose-sm max-w-full">
                <div class="flex flex-row items-center">
                    <h2 class="">New Company Mapping</h2>
                    <button class="ml-auto" mat-icon-button (click)="cView = 'table'; tiggerFn.emit({});">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="aView === 'type'">
                <form class="flex flex-col mt-4 px-8 pt-10 bg-card shadow rounded-xl overflow-hidden">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12">
                            <p class="text-lg font-medium">Would you like to</p>
                        </div>
                        <div class="flex-auto min-w-64 -ml-2">
                            <mat-radio-group class="flex flex-row" [color]="'primary'" [(ngModel)]="selectedType"
                                name="type">
                                <mat-radio-button [value]="'import'">
                                    Import Companies from {{this.currentIntegration.display}}
                                </mat-radio-button>
                                <mat-radio-button class="ml-2" [value]="'exist'">
                                    Map Existing Company to a {{this.currentIntegration.display}} Company
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5">
                        <button mat-button (click)="cView = 'table'; tiggerFn.emit({});">
                            Cancel
                        </button>
                        <button class="px-6 ml-3" mat-flat-button [color]="'primary'" [disabled]="!selectedType"
                            (click)="aView = selectedType; getCompany()">
                            Next
                        </button>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="aView === 'import'">
                <form class="flex flex-col p-4 bg-card shadow rounded overflow-hidden">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12">
                            <p class="text-lg font-medium">Import Companies from {{this.currentIntegration.display}}</p>
                        </div>
                        <div class="w-full mt-2">
                            <div class="flex items-start w-full mt-4">
                                <div class="flex flex-col w-[80%] pr-2">
                                    <div class="flex flex-row items-center">
                                        <mat-form-field [subscriptSizing]="'dynamic'" class="w-full">
                                            <mat-label>Select Company</mat-label>
                                            <mat-select [multiple]="true" name="importCmp"
                                                (ngModelChange)="updateSelected($event)"
                                                [(ngModel)]="selectedImportcompany" id="sourceCmp">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="searchSourceImpControl"
                                                        [placeholderLabel]="'Search Company'"
                                                        [noEntriesFoundLabel]="'No matching company found'"
                                                        [searching]="searching"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option [matTooltip]="company.name"
                                                    *ngFor="let company of destCompany | search: searchSourceImpControl.value"
                                                    [value]="company">
                                                    {{company.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button class="mt-6 ml-2" matSuffix mat-icon-button matTooltip="Reload Company"
                                            *ngIf="currentIntegration.company_search_type" (click)="getCompany('all');"
                                            color="primary">
                                            <mat-icon id="rfrbtn" svgIcon="mat_outline:refresh"></mat-icon>
                                        </button>
                                    </div>
                                    <ng-container *ngIf="comMapImpForm.company_id && comMapImpForm.company_id.length">
                                        <mat-divider class="my-4"></mat-divider>
                                        <div class="text-lg font-medium my-2"> Selected Companies</div>
                                        <div class="relative overflow-x-auto mt-2 sm:rounded-lg" fuseScrollbar>
                                            <table
                                                class="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <thead
                                                    class="font-medium tracking-tight text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" class="px-2 py-4">
                                                            Company Name
                                                        </th>
                                                        <th scope="col" class="px-2 py-4"
                                                            *ngIf="currentIntegration.import_sites">
                                                            Map Site
                                                        </th>
                                                        <th scope="col" class="px-2 py-4"
                                                            *ngIf="currentIntegration.notificationfunction">
                                                            <span
                                                                *ngIf="currentIntegration.integrationtype !== 'psa'">Create
                                                                {{currentIntegration.display}} Alert</span>
                                                            <span
                                                                *ngIf="currentIntegration.integrationtype === 'psa'">Create
                                                                Ticket</span>
                                                        </th>
                                                        <th scope="col" class="px-2 py-4"
                                                            *ngIf="currentIntegration.notificationfunction">
                                                            Integration Rule
                                                        </th>
                                                        <th scope="col" class="px-2 py-4"
                                                            *ngIf="currentIntegration.enable_configuration">
                                                            Enable Configuration
                                                        </th>
                                                        <th scope="col" class="px-2 py-4">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let cmp of comMapImpForm.company_id; let i=index;"
                                                        class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <td class="px-2 py-2">
                                                            {{cmp.name}}
                                                        </td>
                                                        <td class="px-2 py-2" *ngIf="currentIntegration.import_sites">
                                                            <mat-form-field class="w-full"
                                                                [subscriptSizing]="'dynamic'">
                                                                <mat-select name="integrationSites{{i}}"
                                                                    [(ngModel)]="cmp.site_id" [multiple]="false">
                                                                    <ng-container
                                                                        *ngFor="let data of cmp.integrationSites">
                                                                        <mat-option [value]="data.id"> {{data.name}}
                                                                        </mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </td>
                                                        <td class="px-2 py-2"
                                                            *ngIf="currentIntegration.notificationfunction">
                                                            <mat-checkbox [color]="'primary'"
                                                                [(ngModel)]="cmp.no_notification"
                                                                name="no_notification{{i}}">
                                                            </mat-checkbox>
                                                        </td>
                                                        <td class="px-2 py-2"
                                                            *ngIf="currentIntegration.notificationfunction">
                                                            <mat-form-field class="w-full"
                                                                [subscriptSizing]="'dynamic'">
                                                                <mat-select name="IntegrationProfile{{i}}"
                                                                    [(ngModel)]="cmp.integration_rules"
                                                                    [multiple]="true">
                                                                    <ng-container
                                                                        *ngFor="let data of integrationProfile">
                                                                        <mat-option [value]="data.id"> {{data.name}}
                                                                        </mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </td>
                                                        <td class="px-2 py-2"
                                                            *ngIf="currentIntegration.enable_configuration">
                                                            <mat-checkbox [color]="'primary'"
                                                                [(ngModel)]="cmp.configuration"
                                                                name="configuration{{i}}">
                                                            </mat-checkbox>
                                                        </td>
                                                        <td class="px-2 py-2">
                                                            <button (click)="removeSelected(i)" mat-icon-button=""
                                                                matTooltip="Delete record">
                                                                <mat-icon
                                                                    class="icon-size-5 text-primary">delete</mat-icon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="flex items-center justify-end mt-8 px-8 py-5">
                                                <button class="" mat-flat-button [color]="'primary'"
                                                    appPreventMultiClick
                                                    [disabled]="!comMapImpForm.company_id || !comMapImpForm.company_id.length"
                                                    [matTooltip]="'Save'" (click)="importSelectedCompanies()">
                                                    Save
                                                </button>
                                            </div>
                                        </div>

                                    </ng-container>
                                </div>
                                <div class="w-[20%] border-l">
                                    <span class="w-full">
                                        <app-dynamic-form [isCloseBtn]="false" (saveCallBack)="getCompany($event)"
                                            [fnBtnName]="'Get Companies'" [isCloseBtn]="false"
                                            *ngIf="companyFormElements && companyFormElements.length"
                                            [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                                        </app-dynamic-form>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="aView === 'exist'">
                <form class="flex flex-col py-8 px-4 bg-card shadow rounded-xl overflow-hidden" [formGroup]="comMapForm"
                    #comMapNgForm="ngForm">
                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
                        <div class="gt-xs:max-w-80 gt-xs:pr-12" *ngIf="currentIntegration.import_companies">
                            <p class="text-lg font-medium">Map Existing Company to {{this.currentIntegration.display}}
                                Company</p>
                        </div>
                        <div class="w-full mt-2">
                            <fuse-alert [appearance]="'border'" [type]="'info'"
                                *ngIf="currentIntegration.name === 'AzureADCSP'">
                                Provide Admin Consent using the Global Admin user under the Customer Tenant.
                            </fuse-alert>
                            <fuse-alert class="mt-8" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                                [type]="alert.type" [@shake]="alert.type === 'error'">
                                {{alert.message}}
                            </fuse-alert>
                            <div class="grid grid-cols-2 grid-cols-2 gap-3 mt-4 flex items-center justify-center"
                                [ngClass]="{'grid-cols-3': currentIntegration.import_companies }">
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                                    *ngIf="_cs.currentScope === '*'">
                                    <mat-label>Select Local Company</mat-label>
                                    <mat-select [formControlName]="'company_id'" id="sourceCmp"
                                        (openedChange)="closeCurrentCompany($event)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="searchSourceControl"
                                                [placeholderLabel]="'Search Company'"
                                                [noEntriesFoundLabel]="'No matching company found'"
                                                [searching]="searching"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [matTooltip]="company.name" *ngFor="let company of sourceCompany"
                                            [value]="company">
                                            {{company.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                                    *ngIf="_cs.currentScope !== '*'">
                                    <mat-label>Choose Source Company</mat-label>
                                    <mat-select [formControlName]="'company_id'">
                                        <mat-option [matTooltip]="company.name" *ngFor="let company of sourceCompany"
                                            [value]="company">
                                            {{company.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                                    *ngIf="currentIntegration.import_companies">
                                    <mat-label>Select {{currentIntegration.display}} Company</mat-label>
                                    <mat-select [formControlName]="'dest_company_id'" id="destCmp"
                                        (selectionChange)="getIntegrationSite($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="searchDestControl"
                                                [placeholderLabel]="'Search Company'"
                                                [noEntriesFoundLabel]="'No matching company found'"
                                                [searching]="searching"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [matTooltip]="company.name" *ngFor="let company of destCompany;"
                                            [value]="company">
                                            {{company.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="w-full" *ngIf="currentIntegration.import_sites">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Map {{currentIntegration.title}} Site </mat-label>
                                        <mat-select [formControlName]="'sites'" [multiple]="false">
                                            <ng-container *ngFor="let data of integrationSite">
                                                <mat-option [value]="data"> {{data.name}} </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="w-full" *ngIf="currentIntegration.notificationfunction">
                                    <mat-checkbox class="w-full pt-6" [color]="'primary'"
                                        [formControlName]="'no_notification'">
                                        <span *ngIf="currentIntegration.integrationtype !== 'psa'">Create
                                            {{currentIntegration.display}} Alert</span>
                                        <span *ngIf="currentIntegration.integrationtype === 'psa'">Create Ticket</span>
                                    </mat-checkbox>
                                </div>
                                <div class="w-full" *ngIf="currentIntegration.notificationfunction">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>Select Integration Rule</mat-label>
                                        <mat-select [formControlName]="'integration_rules'" [multiple]="true">
                                            <ng-container *ngFor="let data of integrationProfile">
                                                <mat-option [value]="data"> {{data.name}} </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <ng-container *ngIf="currentIntegration.enable_configuration && configIntegration.indexOf(currentIntegration.name) !== -1">
                                    <div class="w-full">
                                        <mat-checkbox class="w-full pt-6" [color]="'primary'" (change)="getConfigs($event)"
                                            [formControlName]="'configuration'">
                                            Enable Configuration
                                        </mat-checkbox>
                                    </div>
                                    <ng-container *ngIf="comMapForm.get('configuration').value === true">
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Select {{currentIntegration.display}} Configuration</mat-label>
                                            <mat-select [formControlName]="'configuration_id'" id="configurationId">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="searchConfigControl"
                                                        [placeholderLabel]="'Search Configuration'"
                                                        [noEntriesFoundLabel]="'No matching configuration found'"
                                                        [searching]="searching"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option [matTooltip]="config.configuration_name"
                                                    *ngFor="let config of configurations;"
                                                    [value]="config.configuration_id">
                                                    {{config.configuration_name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="currentIntegration.enable_configuration && configIntegration.indexOf(currentIntegration.name) === -1">
                                    <div class="w-full">
                                        <mat-checkbox class="w-full pt-6" [color]="'primary'"
                                            [formControlName]="'configuration'">
                                            Enable Configuration
                                        </mat-checkbox>
                                    </div>
                                </ng-container>
                                
                            </div>
                            <!-- </div> -->
                            <div class="flex items-center justify-end mt-4 pr-4 pl-1 border-t">
                                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button"
                                    appPreventMultiClick
                                    [disabled]="!comMapForm.get('company_id').value || !comMapForm.get('dest_company_id').value"
                                    *ngIf="currentIntegration.import_companies && !comMapForm.get('configuration').value === true" (click)="addToMapList()">
                                    Add
                                </button>
                                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button"
                                    appPreventMultiClick [disabled]="!comMapForm.get('company_id').value"
                                    *ngIf="!currentIntegration.import_companies" (click)="addToMapList()">
                                    Add
                                </button>
                                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button"
                                    appPreventMultiClick
                                    [disabled]="!comMapForm.get('company_id').value || !comMapForm.get('dest_company_id').value || !comMapForm.get('configuration_id').value"
                                    *ngIf="currentIntegration.import_companies && comMapForm.get('configuration').value === true && currentIntegration.enable_configuration && configIntegration.indexOf(currentIntegration.name) !== -1" (click)="addToMapList()">
                                    Add
                                </button>
                                <button class="ml-4 mt-5" mat-flat-button [color]="'primary'" type="button"
                                    appPreventMultiClick
                                    [disabled]="!comMapForm.get('company_id').value || !comMapForm.get('dest_company_id').value"
                                    *ngIf="currentIntegration.import_companies && comMapForm.get('configuration').value === true && currentIntegration.enable_configuration && configIntegration.indexOf(currentIntegration.name) === -1" (click)="addToMapList()">
                                    Add
                                </button>
                            </div>
                            <ng-container *ngIf="mapCompanyList && mapCompanyList.length">
                                <mat-divider class="my-4"></mat-divider>
                                <div class="text-lg font-medium"> Selected Companies</div>
                                <div class="relative overflow-x-auto mt-2 sm:rounded-lg" fuseScrollbar>
                                    <table class="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead
                                            class="font-medium tracking-tight text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" class="px-2 py-4">
                                                    Local Company
                                                </th>
                                                <th scope="col" class="px-2 py-4"
                                                    *ngIf="currentIntegration.import_companies">
                                                    {{currentIntegration.display}} Company
                                                </th>
                                                <th scope="col" class="px-2 py-4"
                                                    *ngIf="currentIntegration.import_sites">
                                                    Map Site
                                                </th>
                                                <th scope="col" class="px-2 py-4"
                                                    *ngIf="currentIntegration.notificationfunction">
                                                    <span *ngIf="currentIntegration.integrationtype !== 'psa'">Create
                                                        {{currentIntegration.display}} Alert</span>
                                                    <span *ngIf="currentIntegration.integrationtype === 'psa'">Create
                                                        Ticket</span>
                                                </th>
                                                <th scope="col" class="px-2 py-4"
                                                    *ngIf="currentIntegration.notificationfunction">
                                                    Integration Rule
                                                </th>
                                                <th scope="col" class="px-2 py-4"
                                                    *ngIf="currentIntegration.enable_configuration">
                                                    Enable Configuration
                                                </th>
                                                <th scope="col" class="px-2 py-4"
                                                    *ngIf="currentIntegration.enable_configuration">
                                                    Configuration Name
                                                </th>
                                                <th scope="col" class="px-2 py-4">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let com of mapCompanyList"
                                                class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <td class="px-2 py-2">
                                                    {{com.source_company_name}}
                                                </td>
                                                <td class="px-2 py-2" *ngIf="currentIntegration.import_companies">
                                                    {{com.dest_company_name}}
                                                </td>
                                                <td class="px-2 py-2" *ngIf="currentIntegration.import_sites">
                                                    {{com.site_name}}
                                                </td>
                                                <td class="px-2 py-2" *ngIf="currentIntegration.notificationfunction">
                                                    <span *ngIf="com.no_notification"
                                                        class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Yes</span>
                                                    <span *ngIf="!com.no_notification"
                                                        class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">No</span>
                                                </td>
                                                <td class="px-2 py-2" *ngIf="currentIntegration.notificationfunction">
                                                    {{com.integartionProfileName}}
                                                </td>
                                                <td class="px-2 py-2" *ngIf="currentIntegration.enable_configuration">
                                                    <span *ngIf="com.configuration"
                                                        class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Yes</span>
                                                    <span *ngIf="!com.configuration"
                                                        class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">No</span>
                                                </td>
                                                <td class="px-2 py-2" *ngIf="currentIntegration.enable_configuration">
                                                    {{com.configuration_name}}
                                                </td>
                                                <td class="px-2 py-2">
                                                    <button (click)="mapCompanyList.splice(i, 1)" mat-icon-button=""
                                                        matTooltip="Delete record">
                                                        <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="flex items-center justify-end mt-8 px-8 py-5">
                                        <button class="px-6 ml-3" mat-flat-button [color]="'primary'" type="button"
                                            appPreventMultiClick (click)="mapSelectedCompanies()">
                                            Finish
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</ng-container>

<s-modal id="editNonPsaInt">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="text-lg font-bold">Update Mapped Company</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('editNonPsaInt');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form class="example-form px-4" #editMapNgForm="ngForm">
                <div class="prose prose-sm max-w-3xl">
                    <h3 class="mt-2">Souce Company Name: {{ updateCompany.source_company_name}}</h3>
                </div>
                <mat-form-field class="w-full mt-4" [subscriptSizing]="'dynamic'"
                    *ngIf="currentIntegration.import_companies">
                    <mat-label>Select {{currentIntegration.display}} Company</mat-label>
                    <mat-select name="destEditCmp" id="destEditCmp" required [(ngModel)]="edit_update_company"
                        (selectionChange)="getIntegrationSite($event.value)">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="searchDestControl"
                                [placeholderLabel]="'Search Company'"
                                [noEntriesFoundLabel]="'No matching company found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>

                        <ng-container *ngIf="!currentIntegration.hide_search">
                            <mat-option [matTooltip]="company.name" *ngFor="let company of destCompany;"
                                [value]="company">
                                {{company.name}}
                            </mat-option>
                        </ng-container>
                        <ng-container *ngIf="currentIntegration.hide_search">
                            <mat-option [matTooltip]="company.name"
                                *ngFor="let company of destCompany |search: searchDestControl.value;" [value]="company">
                                {{company.name}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="w-full mt-4" *ngIf="currentIntegration.import_sites">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Map Site </mat-label>
                        <mat-select [(ngModel)]="updateCompany.site_id" name="sites" [multiple]="false">
                            <ng-container *ngFor="let data of integrationSite">
                                <mat-option [value]="data.id"> {{data.name}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-full mt-4 flex flex-row">
                    <div *ngIf="currentIntegration.notificationfunction" class="w-1/2">
                        <mat-checkbox [color]="'primary'" [(ngModel)]="updateCompany.no_notification"
                            name="no_notification">
                            <span *ngIf="currentIntegration.integrationtype !== 'psa'">Create
                                {{currentIntegration.display}} Alert</span>
                            <span *ngIf="currentIntegration.integrationtype === 'psa'">Create Ticket</span>
                        </mat-checkbox>
                    </div>
                    <div class="w-1/2" *ngIf="currentIntegration.enable_configuration">
                        <mat-checkbox [color]="'primary'" [(ngModel)]="updateCompany.configuration"
                            name="configuration">
                            Enable Configuration
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="configIntegration.indexOf(currentIntegration.name) !== -1 && updateCompany.configuration === true">
                    <div class="flex items-center w-full mt-4" >
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Select configuration Name</mat-label>
                            <mat-select [(ngModel)]="updateCompany.configuration_id" name="updateconfigration">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="searchConfigControl"
                                        [placeholderLabel]="'Search Configuration'"
                                        [noEntriesFoundLabel]="'No matching configuration found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [matTooltip]="config.configuration_name"
                                    *ngFor="let config of configurations;" [value]="config.configuration_id">
                                    {{config.configuration_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex items-center w-full mt-4" *ngIf="currentIntegration.notificationfunction">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Select Integration Rule</mat-label>
                        <mat-select [(ngModel)]="updateCompany.integration_rules" name="integration_rules"
                            [multiple]="true">
                            <ng-container *ngFor="let data of integrationProfile">
                                <mat-option [value]="data.id"> {{data.name}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t">
                    <button type="submit" id="saveButton" (click)="updateComMapping()"
                        [disabled]="editMapNgForm.invalid" appPreventMultiClick class="mr-2" mat-flat-button
                        color="primary">Update
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>

<s-modal id="companyMapping">
    <mat-card class="modal w-[600px]" *ngIf="comapanyMappingData && companumappedData">
        <mat-card-header>
            <mat-card-title class="text-lg font-medium">Update Integration Profile</mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="_mS.close('companyMapping');comapanyMappingData=[]">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="my-4">
            <form class="example-form px-4" #companyMapNgForm="ngForm">
                <div class="w-full">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Select Integration Rule</mat-label>
                        <mat-select [(ngModel)]="companumappedData" [multiple]="true" name="companymapping">
                            <ng-container *ngFor="let data of integrationProfile">
                                <mat-option [value]="data.id"> {{data.name}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="_mS.close('companyMapping')">
                        Cancel
                    </button>
                    <button type="button" id="saveButton"
                        (click)="UpdateComapnyMapping(companumappedData);comapanyMappingData=[]"
                        [disabled]="companyMapNgForm.invalid" appPreventMultiClick class="mr-2" mat-flat-button
                        color="primary">Update
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>

<s-modal id="copyCompanyMapping">
    <div class="w-[600px]">
        <form class="flex flex-col m-2 p-4 bg-card shadow rounded overflow-hidden">
            <div class="flex flex-row items-center">
                <div class="text-2xl font-semibold tracking-tight">Copy Settings to other Mapped Company</div>
                <button class="ml-auto" mat-icon-button (click)="_mS.close('copyCompanyMapping');">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
            <mat-divider class="my-2"></mat-divider>
            <div class="w-full">
                <div class="flex flex-col gap-2">
                    <div class="text-md font-medium text-secondary mb-2">Integration Rules - <span
                            class="font-bold">{{copyEvents.integration_rules_names}}</span></div>
                    <div class="text-md font-medium text-secondary mb-2" *ngIf="copyEvents.event_set_name">Event Set -
                        <span class="font-bold">{{copyEvents.event_set_name}}</span>
                    </div>
                    <div class="text-md font-medium text-secondary mb-2">Create Ticket -
                        <ng-container class="px-2 py-2" *ngIf="currentIntegration.notificationfunction">
                            <span *ngIf="!copyEvents.no_notification"
                                class="inline-flex items-center font-bold text-sm px-2.5 py-0.5 rounded-full tracking-wide uppercase ng-tns-c1063616881-211 bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50"><span
                                    class="leading-relaxed whitespace-nowrap"> Yes </span></span>
                            <span *ngIf="copyEvents.no_notification"
                                class="inline-flex items-center font-bold text-sm px-2.5 py-0.5 rounded-full tracking-wide uppercase ng-tns-c1063616881-211 bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50"><span
                                    class="leading-relaxed whitespace-nowrap"> No </span></span>
                        </ng-container>
                    </div>
                    <div class="text-md font-medium text-secondary mb-2">Enable Configuration -
                        <ng-container class="px-2 py-2" *ngIf="currentIntegration.enable_configuration">
                            <span *ngIf="copyEvents.enable_configuration"
                                class="inline-flex items-center font-bold text-sm px-2.5 py-0.5 rounded-full tracking-wide uppercase ng-tns-c1063616881-211 bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50"><span
                                    class="leading-relaxed whitespace-nowrap"> Yes </span></span>
                            <span *ngIf="!copyEvents.enable_configuration"
                                class="inline-flex items-center font-bold text-sm px-2.5 py-0.5 rounded-full tracking-wide uppercase ng-tns-c1063616881-211 bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50"><span
                                    class="leading-relaxed whitespace-nowrap"> No </span></span>
                        </ng-container>
                    </div>
                </div>
                <mat-divider class="my-4"></mat-divider>
                <mat-form-field [subscriptSizing]="'dynamic'" class="w-full">
                    <mat-label>Select Mapped Company</mat-label>
                    <mat-select [multiple]="true" name="SelectCompanyMapping"
                        [(ngModel)]="copyEvents.company_mapping_id" id="SelectCompanyMapping">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="copyCompanyMapControl"
                                [placeholderLabel]="'Search Mapped Company'"
                                [noEntriesFoundLabel]="'No matching mapped company found'"
                                [searching]="searching"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option matTooltip="{{company.source_company_name}}-{{company.dest_company_name}}"
                            *ngFor="let company of copyCompanyMappingList | sortBy:'asc':'name' | search: copyCompanyMapControl.value"
                            [value]="company">
                            {{company.source_company_name}}-{{company.dest_company_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex items-center justify-end mt-6 py-4 pr-4 pl-1 border-t">
                <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="_mS.close('copyCompanyMapping')">
                    Cancel
                </button>
                <button type="button" id="CopySetting" (click)="copyCompanyMappingFn()"
                    [disabled]="!copyEvents.company_mapping_id || !copyEvents.company_mapping_id.length"
                    appPreventMultiClick class="mr-2" mat-flat-button color="primary">Copy Setting
                </button>
            </div>
        </form>
    </div>
</s-modal>