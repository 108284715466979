import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { LoaderComponent } from '../loader/loader.component';
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bios-info',
  standalone: true,
  imports: [CommonModule, LoaderComponent, AppFilterPipeModule],
  templateUrl: './bios-info.component.html',
  styleUrls: ['./bios-info.component.scss']
})
export class BiosInfoComponent implements OnInit, OnDestroy {
  @Input() asset: any;
  biosInformation: any;
  loadingCon: any = {
    loading: true,
    nodata: false
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getBiosInformation();
  }

  getBiosInformation(): void {
    if (!this.asset) {
      setTimeout(() => { this.getBiosInformation(); }, 2000);
      return;
    }
    this.ls.display(true);
    this._bs.doRequest(`/r/company/bios_info`, 'get', null,
      { condition: `company_id='${this.asset.company_id}' and asset_id='${this.asset.id}'`, skip: 0, limit: 1000, order_by: 'name asc' })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.biosInformation = res.data;
            this.loadingCon.loading = null; this.loadingCon.nodata = null;
          } else {
            this.loadingCon.loading = null; this.loadingCon.nodata = true;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
      });
  }
}
